/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/saved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetySavedRoute;

import Ember from 'ember';

import toTop from '../../../mixins/reset-scroll';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SiteSafetySavedRoute = Ember.Route.extend(toTop, AuthenticatedRouteMixin, {
    store: Ember.inject.service(),
    model: function (params) {
        return this.get('store').findRecord(params.type, params.id, {
            include: true,
        });
    },
});

//    actions:
//        willTransition: ->
//            @get('store').unloadAll('tr')
export default SiteSafetySavedRoute;
