import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
    classNameBindings: ['dragClass'],
    dragClass: 'inactive',

    dragLeave(event) {
        event.preventDefault();
        set(this, 'dragClass', 'inactive');
    },

    dragOver(event) {
        event.preventDefault();
        set(this, 'dragClass', 'active');
    },

    dragEnter(event) {
        event.preventDefault();
    },

    drop(event) {
        set(this, 'dragClass', 'inactive');

        const data = event.dataTransfer.getData('text/data');
        if (!data) return;
        this.dropped(this.name, data, event);
    },
});
