/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/code/route.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserCodeRoute;

import Ember from 'ember';

import config from '../../../../config/environment';

RegisterUserCodeRoute = Ember.Route.extend({
    actions: {
        goToRegisterUrl: function () {
            var encoded_url, key;
            key = this.get('controller.magicKey');
            if (!config.APP.SELFONBOARDURL) {
                alert('env config.APP.SELFONBOARDURL not setted!');
                return;
            }
            encoded_url = window.btoa(config.APP.OWN_URL);
            return (window.location = config.APP.SELFONBOARDURL + key + '&return=' + encoded_url);
        },
    },
});

export default RegisterUserCodeRoute;
