/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/addsaved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerAddSavedController;

import Ember from 'ember';

import savedController from '../../../controllers/saved';

TimetrackerAddSavedController = savedController.extend({
    Collector: Ember.inject.service('collector-service'),
    customerSettings: Ember.inject.service(),
    session: Ember.inject.service(),
    showSelfOrientationLink: Em.computed('model.project.id', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                if (
                    this.get('customerSettings').checkSetting('self_orientation') &&
                    !this.get('model.user.no_orientation') &&
                    parseInt(this.get('session.currentUser.userlevel')) === 1
                ) {
                    return this.get('store')
                        .query('orientation', {
                            ori_user: this.get('model.user.id'),
                            ori_site: this.get('model.project.id'),
                        })
                        .then((orientations) => {
                            if (orientations.get('meta.count') === 0) {
                                return resolve(true);
                            } else {
                                return resolve(false);
                            }
                        });
                } else {
                    return resolve(false);
                }
            }),
        });
    }),
    currentSaldo: Ember.computed('model', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('Collector')
                    .ttapi({
                        url: 'saldo/get_saldo?userid=' + this.get('model.user.id'),
                    })
                    .then((back) => {
                        return resolve({
                            value: back['saldo'] / 60,
                            from: back['from'],
                            to: back['to'],
                        });
                    });
            }),
        });
    }),
});

export default TimetrackerAddSavedController;
