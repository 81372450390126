declare const window: any;
declare const device: any;

/**
 * Provides functions and properties to deal with the gui
 *
 * @module gui
 */

export function isMobile(width = 760) {
    return window.matchMedia('only screen and (max-width: ' + width + 'px)').matches;
}

/**
 * Is the user's browser Internet Explorer?
 * @const isIE
 * @type {Boolean}
 */
export const isIE = (() => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) return true;
    return false;
})();

/**
 * Is the device an Android phone?
 * @const isAndroid
 * @type {Boolean}
 */
export const isAndroid = (() => {
    return window.cordova && device.platform === 'Android';
})();

/**
 * Returns the modal url from a url
 * @function modalUrl
 * @param {String} url
 * @return {String}
 */
export const modalUrl = (url: string) => {
    const base = window.location.href;
    const split = base.split('?');

    if (split[1]! && split[1].substr(0, 6) === 'modal=') delete split[1];

    url = encodeURIComponent(url);

    url = url.replace('%3D', '=');

    if (url.startsWith('http')) return url;

    return split[0] + '?' + (split[1] != null ? split[1] + '&' + url : url);
};
// list all exports here
export default { isMobile, isIE, isAndroid, modalUrl };
