import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
export default Component.extend({
    value: 0,

    defaultValue: 0,

    freeAmount: computed('amount', 'value', function () {
        return get(this, 'amount') - get(this, 'value');
    }),

    didReceiveAttrs() {
        let amounts = get(this, 'amounts');
        if (amounts && amounts.length > 0) {
            amounts.forEach((amount) => {
                if (get(this, 'id') === get(amount, 'id')) {
                    set(this, 'value', get(amount, 'amount'));
                }
            });
        }
    },

    actions: {
        handleButtons(value) {
            value = parseInt(value, 10);
            const v = parseInt(get(this, 'value'), 10);
            if (v === 0 && value === -1) return;
            if (v === get(this, 'amount') && value === 1) return;
            set(this, 'value', v + value);
            this.change(v + value, get(this, 'tooltype'), get(this, 'status'), get(this, 'id'));
        },
        handleInput(value) {
            if (value < 0) {
                set(this, 'value', 0);
                this.change(0, get(this, 'tooltype'), get(this, 'status'), get(this, 'id'));
            } else if (value >= get(this, 'amount')) {
                set(this, 'value', get(this, 'amount'));
                this.change(
                    get(this, 'amount'),
                    get(this, 'tooltype'),
                    get(this, 'status'),
                    get(this, 'id'),
                );
            } else {
                this.change(value, get(this, 'tooltype'), get(this, 'status'), get(this, 'id'));
            }
        },
    },
});
