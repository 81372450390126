import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, set } from '@ember/object';

export default Component.extend({
    localstorage: service(),
    analytics: service(),

    tagName: '',

    hiddenColumns: computed('columns', 'columns.[]', function () {
        return this.allColumns.filter((column) => !this.columns.includes(column));
    }),

    actions: {
        columnDropped(target, dropped) {
            if (target == dropped) return;
            this.moveElementInArray(this.columns, this.columns[dropped], target - dropped);
            this.columnsChanged(this.columns);
            this.saveToLS(this.columns);
        },

        moveCol(col, dir) {
            this.moveElementInArray(this.columns, col, dir);
            this.columnsChanged(this.columns);
            this.saveToLS(this.columns);
        },
        hideColumn(col) {
            this.analytics.trackEvent({
                category: this.form + ' report hide column',
                action: col,
                label: 'Form-report',
            });
            this.columns.removeObject(col);
            this.columnsChanged(this.columns);
            this.saveToLS(this.columns);
        },
        showColumn(col) {
            this.analytics.trackEvent({
                category: this.form + ' report show column',
                action: col,
                label: 'Form-report',
            });
            this.columns.pushObject(col);
            this.columnsChanged(this.columns);
            this.saveToLS(this.columns);
        },

        setRowsPerPage(RowsPerPage) {
            this.setRowsPerPage(RowsPerPage);
        },

        deactivateTemplate() {
            const visibleList = this.localstorage.getItem(this.form + '_defaultColumns');
            const columns = this.allColumns.filter(
                (column) => !visibleList || visibleList.includes(column.external_type),
            );
            this.columnsChanged(columns);
            set(this, 'currentTemplate', null);
        },

        activateTemplate(template) {
            const columns = template.columns.split(',').reduce((accumulator, column) => {
                let columnObject = this.allColumns.find(
                    (object) => object.external_type === column,
                );
                if (columnObject) accumulator.push(columnObject);
                return accumulator;
            }, []);

            this.columnsChanged(columns);
        },
    },

    moveElementInArray(obj, col, offset) {
        let index = obj.indexOf(col);
        obj.removeObject(col);
        let newIndex = index + offset;
        newIndex = newIndex > obj.length ? 0 : newIndex < 0 ? obj.length : newIndex;
        obj.insertAt(newIndex, col);
    },

    saveToLS(columns) {
        this.localstorage.setItem(
            this.form + '_defaultColumns',
            columns.map((column) => column.external_type).filter((item) => item),
        );
    },
});
