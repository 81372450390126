import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"{{@disabledClass}}\">\n    <div role=\"button\" data-anchor=\"report-more-qrcode\" {{on 'click' this.generate}}>{{t \"general.generate_qr_code\"}}</div>\n</li>", {"contents":"<li class=\"{{@disabledClass}}\">\n    <div role=\"button\" data-anchor=\"report-more-qrcode\" {{on 'click' this.generate}}>{{t \"general.generate_qr_code\"}}</div>\n</li>","moduleName":"tt4/components/addon/form-report/qr-code.hbs","parseOptions":{"srcName":"tt4/components/addon/form-report/qr-code.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'tt4/config/environment';
import { assert } from '@ember/debug';
import { A } from '@ember/array';
import { generateQrCode } from 'tt4/utils/qrcode-generator';

export default class FormReportQrCode extends Component {
    @service apiservice;
    @service router;

    constructor() {
        super(...arguments);
        assert(
            'You need to give @editRoute for component (eg. /timetracker/edit/ or /machines/machine?modal=tooltype%2C)',
            this.args.editRoute,
        );
    }

    @action
    async generate() {
        let urls = this.getEditRouteUrls();
        let ownUrl = this.getOwnUrl();
        const editUrls = this.getEditUrls(ownUrl, urls);

        const returnUrl = await this.apiservice.request('ttapi', '/qr_codes', 'POST', {
            urls: editUrls,
        });
        const asHTML = await returnUrl.text();

        const w = window.open('about:blank');
        if (!w) return;

        w.document.open();
        w.document.write(asHTML);
        w.document.close();
    }

    // Method to get edit route urls (eg. /timetracker/edit/12345) in array based on selected rows

    getEditRouteUrls() {
        const urlArr = [];
        this.args.selected.forEach((row) => {
            urlArr.push(this.args.editRoute + row.id);
        });
        return urlArr;
    }

    // Method to get own url (localhost:4200 or app.movenium.com)
    /* istanbul ignore next */
    getOwnUrl() {
        if (config.environment !== 'development' && config.environment !== 'next') {
            return 'https://app.movenium.com/';
        } else {
            return config.APP.OWN_URL.slice(-1) === '/'
                ? config.APP.OWN_URL
                : config.APP.OWN_URL + '/';
        }
    }

    getEditUrls(ownUrl, urls) {
        const arr = A([]);
        for (const url of urls) {
            const editUrl = ownUrl + '#' + url;

            const qrImageUrl = generateQrCode(editUrl);

            arr.pushObject({ url: qrImageUrl });
        }
        return arr;
    }
}

