import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isArray } from '@ember/array';
import { isPresent } from '@ember/utils';
import { computed, get } from '@ember/object';

const DevelopmentOwnrolesRoleController = Controller.extend({
    Collector: service('collector-service'),
    init() {
        this._super(...arguments);
        this.hideDenyAccess = [
            'wage_period',
            'salarytype',
            'holidaypay',
            'bank',
            'bankdeducter',
            'holiday',
            'saldodeducter',
            'rounding_rule_v2',
        ];
        this.privilegeTypes = {
            deny_access: {
                type: 'form-setting',
                setting: 'access_denied',
            },
            deny_add: {
                type: 'form-setting',
                setting: 'hide_addrow',
            },
            deny_edit: {
                type: 'form-setting',
                setting: 'hide_edit',
            },
            deny_remove: {
                type: 'form-setting',
                setting: 'hide_remove',
            },
            deny_approving: {
                type: 'form-field-setting',
                field: 'status',
                settings: [{ name: 'readonly', value: 'true' }],
            },
            ownrows: {
                type: 'form-field-setting',
                link_to: 'user',
                settings: [
                    { name: 'filter', value: '$userProfile->primary_key' },
                    { name: 'set', value: '$userProfile->primary_key' },
                ],
            },
            ownrows_user: {
                type: 'form-field-setting',
                field: 'username',
                settings: [{ name: 'filter', value: '$userProfile->username' }],
            },
            load_profile: {
                type: 'form-setting',
                setting: 'load_profile',
                value: '1',
            },
        };
    },

    content: alias('model'),
    forms: computed(function () {
        return this.store.peekAll('form').filter((form) => {
            return this.Collector.formExists(form.external_type);
        });
    }),

    formSettingsForRole: computed('model.{formSettings.[],role}', function () {
        const id = this.model.role;
        return this.model.formSettings.filter((item) => item.userlevel === id);
    }),

    formFieldSettingsForRole: computed('model.{formFieldSettings.[],role}', function () {
        const id = this.model.role;
        return this.model.formFieldSettings.filter((item) => item.userlevel === id);
    }),

    privilegeArray: computed('forms', 'model', function () {
        const forms = this.forms;

        const arr = {};

        forms.forEach((item) => {
            const form = item.external_type;
            return (arr[form] = {
                deny_access: this.isPrivilegeOn(form, 'deny_access'),
                load_profile: this.isPrivilegeOn(form, 'load_profile'),
                ownrows:
                    form === 'user'
                        ? this.isPrivilegeOn(form, 'ownrows_user')
                        : this.isPrivilegeOn(form, 'ownrows'),
                deny_add: this.isPrivilegeOn(form, 'deny_add'),
                deny_edit: this.isPrivilegeOn(form, 'deny_edit'),
                deny_remove: this.isPrivilegeOn(form, 'deny_remove'),
                deny_approving: this.isPrivilegeOn(form, 'deny_approving'),
            });
        });
        return arr;
    }),

    actions: {
        toggle(form, type) {
            if (this.isPrivilegeOn(form, type)) {
                return this.setPrivilege(form, type, false);
            } else {
                return this.setPrivilege(form, type, true);
            }
        },
    },
    isPrivilegeOn(form, type) {
        let privilege = this.privilegeTypes[type];

        if (isArray(privilege)) {
            privilege = privilege[0];
        }

        if (privilege && privilege.type === 'form-setting') {
            return this.isFormPrivilegeOn(form, privilege.setting);
        } else if (privilege && privilege.type === 'form-field-setting') {
            if (isPresent(privilege.link_to)) {
                const possible_fields = this.Collector.fieldArray(form).filterBy(
                    'options.form',
                    privilege.link_to,
                );
                if (possible_fields.length > 0) {
                    return this.isFormFieldSettingOn(
                        form,
                        possible_fields[0].name,
                        privilege.settings,
                    );
                }
            } else {
                return this.isFormFieldSettingOn(form, privilege.field, privilege.settings);
            }
        } else {
            return console.error('unknown privilege type ' + type); // eslint-disable-line
        }
    },

    isFormFieldSettingOn(form, field, settings) {
        const field_settings = this.formFieldSettingsForRole.filter(
            (record) =>
                get(record, 'field.form.external_type') === form &&
                get(record, 'field.external_type') === field,
        );

        let count = 0;
        settings.forEach((setting) => {
            if (field_settings.findBy('name', setting.name)) {
                return count++;
            }
        });

        if (count === 0) {
            return false;
        } else if (count === settings.length) {
            return true;
        } else {
            console.error(JSON.stringify(settings) + ' has different states'); // eslint-disable-line
            return true;
        }
    },

    isFormPrivilegeOn(form, settingName) {
        const form_settings = this.formSettingsForRole.filter(
            (record) => get(record, 'form.external_type') === form,
        );

        if (form_settings.findBy('name', settingName)) {
            return true;
        } else {
            return false;
        }
    },

    // support for arrays
    setPrivilege(form, type, onoff) {
        const privilege = this.privilegeTypes[type];

        if (isArray(privilege)) {
            return privilege.forEach((item) => {
                return this._setPrivilege(form, type, onoff, item);
            });
        } else {
            return this._setPrivilege(form, type, onoff, privilege);
        }
    },

    _setPrivilege(form, type, onoff, privilege) {
        if (privilege && privilege.type === 'form-setting') {
            const value = privilege.value ? privilege.value : 'true';
            return this.setFormPrivilege(form, privilege.setting, value, onoff);
        } else if (privilege && privilege.type === 'form-field-setting') {
            if (isPresent(privilege.link_to)) {
                const possible_fields = this.Collector.fieldArray(form).filterBy(
                    'options.form',
                    privilege.link_to,
                );
                if (possible_fields.length > 1 || possible_fields.length === 0) {
                    alert(
                        'Could not find suitable field for setting, found ' +
                            possible_fields.length +
                            ' fields',
                    );
                    return;
                }

                return this.setFormFieldSettings(
                    form,
                    possible_fields[0].name,
                    privilege.settings,
                    onoff,
                );
            } else {
                return this.setFormFieldSettings(form, privilege.field, privilege.settings, onoff);
            }
        } else {
            return console.error('unknown privilege type ' + type); // eslint-disable-line
        }
    },

    setFormFieldSettings(form, field, settings, onoff) {
        const field_record = this.store
            .peekAll('form-field')
            .find(
                (item) => item.external_type === field && get(item, 'form.external_type') === form,
            );

        if (!field_record) {
            alert('this privilege cannot be used with form ' + form);
            return;
        }

        const field_settings = this.formFieldSettingsForRole.filter(
            (record) =>
                get(record, 'field.form.external_type') === form &&
                get(record, 'field.external_type') === field,
        );

        if (onoff) {
            return settings.forEach((setting) => {
                return this.store
                    .createRecord('form-field-setting', {
                        name: setting.name,
                        value: setting.value,
                        userlevel: this.model.role,
                        field: field_record,
                    })
                    .save();
            });
        } else {
            return settings.forEach((setting) => {
                return field_settings.findBy('name', setting.name).destroyRecord();
            });
        }
    },

    setFormPrivilege(form, settingName, value, onoff) {
        const form_record = this.store.peekAll('form').findBy('external_type', form);

        const form_settings = this.formSettingsForRole.filter(
            (record) => get(record, 'form.external_type') === form,
        );

        if (onoff) {
            return this.store
                .createRecord('form-setting', {
                    name: settingName,
                    value,
                    userlevel: this.model.role,
                    form: form_record,
                })
                .save();
        } else {
            return form_settings.findBy('name', settingName).destroyRecord();
        }
    },
});

export default DevelopmentOwnrolesRoleController;
