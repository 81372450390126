/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/linked-users/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsLinkedUsersRoute;

import Ember from 'ember';

ProjectsLinkedUsersRoute = Ember.Route.extend({
    setupController: function (controller, model) {
        return controller.updatePath(model);
    },
});

export default ProjectsLinkedUsersRoute;
