/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/add/measurement/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyTrAddMeasurementController,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from '../../../../../classes/collector';

import RSVP from 'rsvp';

SiteSafetyTrAddMeasurementController = Ember.Controller.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    applicationController: Ember.inject.controller('application'),
    saved: Ember.inject.controller('site-safety/saved'),
    measurementController: Ember.inject.controller('site-safety/measurement'),
    session: Em.inject.service(),
    dialogs: Ember.inject.service(),
    intl: Em.inject.service(),
    plus: 0,
    minus: 0,
    savedNotices: [],
    disableAutosave: false,
    saveText: Ember.computed(function () {
        return (
            '<span class="glyphicon glyphicon-floppy-disk"></span> ' +
            this.get('intl').t('measurement.save')
        );
    }),
    //    measurementController: Ember.computed.alias 'controllers.site-safety/measurement'
    init: function () {
        this._super(...arguments);
        return document.addEventListener(
            'deviceready',
            () => {
                document.addEventListener(
                    'pause',
                    () => {
                        return this.eventListener('pause');
                    },
                    false,
                );
                return document.addEventListener(
                    'resume',
                    () => {
                        return this.eventListener('resume');
                    },
                    false,
                );
            },
            false,
        );
    },
    eventListener: function (event) {
        if (event === 'pause') {
            return this.set('disableAutosave', true);
        } else if (event === 'resume') {
            this.set('disableAutosave', false);
            return this.autosave();
        }
    },
    fields: Em.computed('model.form', function () {
        var fields, form, mc, self;
        self = this;
        mc = this.get('measurementController');
        form = this.get('model.form');
        fields = mc.parseFields(form);
        fields.forEach(function (item) {
            item.notices = Em.A([]);
            self.addObserver('model.record.' + item.ext + '_plus', self, 'recalculateTotal');
            return self.addObserver(
                'model.record.' + item.ext + '_minus',
                self,
                'recalculateTotal',
            );
        });
        Ember.run.later(() => {
            return self.recalculateTotal();
        }, 1);
        return fields;
    }),
    signatureFields: Ember.computed('model.form', function () {
        return this.get('Collector')
            .fieldArray(this.get('model.form'))
            .filter((field) => {
                return field.type === 'signature' || field.name.slice(-4) === 'sign';
            });
    }),
    extrafields: Em.computed(function () {
        var field, fieldname, fields, form, return_fields, skip_these;
        form = this.get('model.form');
        fields = this.Collector.fields(form);
        return_fields = [];
        skip_these = ['date', 'project', 'user', 'tr_rating', 'mvr_rating'].concat(
            this.get('signatureFields').mapBy('name'),
        );
        for (fieldname in fields) {
            field = fields[fieldname];
            if (
                !(indexOf.call(skip_these, fieldname) >= 0) &&
                fieldname.indexOf('_plus') < 0 &&
                fieldname.indexOf('_notice') < 0 &&
                fieldname.indexOf('_minus') < 0
            ) {
                if (!Ember.get(field, 'features.hide')) {
                    return_fields.push(fieldname);
                }
            }
        }
        return return_fields;
    }),
    textClass: Em.computed('model.record.mvr_rating', 'model.record.tr_rating', function () {
        if (this.get('model.record.' + this.get('model.form') + '_rating') >= 95) {
            return 'alert-success';
        } else if (this.get('model.record.' + this.get('model.form') + '_rating') >= 90) {
            return 'alert-warning';
        } else {
            return 'alert-danger';
        }
    }),
    /*
  ratingClass: (->
      if @get('model.record.tr_rating') >= 95
          @set('textClass', "alert-success")
      else if @get('model.record.tr_rating') >= 90
          @set('textClass', "alert-warning")
      else
          @set('textClass', "alert-danger")
  ).observes('model.record.tr_rating')

  */
    // observers are added in 'fields' property
    recalculateTotal: function () {
        var arr, i, item, len, m, mval, p, pval, rating, self;
        self = this;
        arr = this.get('fields');
        pval = 0;
        mval = 0;
        rating = 0;
        for (i = 0, len = arr.length; i < len; i++) {
            item = arr[i];
            p = self.get('model.record.' + item.ext + '_plus');
            m = self.get('model.record.' + item.ext + '_minus');
            if (p !== null && !isNaN(p) && p !== '') {
                pval += parseInt(p, 10);
            }
            if (m !== null && !isNaN(m) && m !== '') {
                mval += parseInt(m, 10);
            }
        }
        if (pval !== 0 || mval !== 0) {
            rating = ((pval / (pval + mval)) * 100).toFixed(2);
        }
        if (this.get('model.form') === 'mvr') {
            this.set('model.record.mvr_rating', rating);
        } else {
            this.set('model.record.tr_rating', rating);
        }
        this.set('plus', pval);
        return this.set('minus', mval);
    },
    saveNotice: function (notice) {
        var email, projectString, promise, record, self, userString;
        record = notice;
        //if !@get('error')
        self = this;
        userString = '';
        if (this.get('session.currentUser.firstname')) {
            userString += this.get('session.currentUser.firstname') + ' ';
        }
        if (this.get('session.currentUser.lastname')) {
            userString += this.get('session.currentUser.lastname') + '\n';
        }
        if (this.get('session.currentUser.email')) {
            userString += this.get('session.currentUser.email') + '\n';
        }
        if (this.get('session.currentUser.phone')) {
            userString += this.get('session.currentUser.phone') + '\n';
        }
        projectString = '';
        if (record.get('project.number')) {
            projectString += record.get('project.number') + ' ';
        }
        projectString += record.get('project.name');
        email = {
            title:
                this.get('session.currentUser.company') +
                ' - ' +
                this.get('intl').t('site_safety.notice.email_content.title'),
            content:
                this.get('intl').t('site_safety.notice.email_content.content') +
                '\n' +
                this.get('intl').t('site_safety.notice.project') +
                ': ' +
                projectString +
                '\n' +
                this.get('intl').t('site_safety.notice.place') +
                ': ' +
                record.get('place') +
                '\n' +
                this.get('intl').t('site_safety.notice.desc') +
                ': ' +
                record.get('desc') +
                '\n' +
                this.get('intl').t('site_safety.notice.noticedesc') +
                ': ' +
                record.get('noticedesc') +
                '\n' +
                this.get('intl').t('site_safety.notice.person') +
                ': ' +
                record.get('user.firstname') +
                ' ' +
                record.get('user.lastname') +
                '\n' +
                this.get('intl').t('site_safety.notice.fixdate') +
                ': ' +
                moment(record.get('date')).format('L') +
                '\n\n' +
                userString,
        };
        if (record.get('pic') && record.get('pic').length > 0) {
            email.pic = {
                name: record.get('pic')[0].name,
                data: record.get('pic')[0].data,
            };
        }
        record.set('email_content', email);
        record.set('project', this.get('model.record.project'));
        record.set('type', this.get('model.record.constructor.modelName'));
        record.set('creator', this.get('session.currentUser'));
        promise = record.save();
        promise.then(function () {
            if (self.get('model.record.' + notice.get('name') + '_notices')) {
                return self
                    .get('model.record.' + notice.get('name') + '_notices')
                    .pushObject(record.get('id'));
            } else {
                return self.set(
                    'model.record.' + notice.get('name') + '_notices',
                    Em.A([record.get('id')]),
                );
            }
        });
        return promise;
    },
    errors: Em.A([]),
    handleRequestError: function (e) {
        var d;
        d = this.get('dialogs');
        return d.alert(
            this.get('intl').t('site-safety.request.error') + '<hr>' + JSON.stringify(e.errors),
        );
    },
    autosave: function () {
        var timer;
        if (!Ember.testing) {
            timer = Ember.run.later(
                this,
                () => {
                    if (!this.get('disableAutosave')) {
                        this.send('add', true);
                        return this.autosave();
                    }
                },
                this.get('Collector').testNeedsOne('development') ? 10000 : 300000,
            );
            return this.set('timer', timer);
        }
    },
    actions: {
        add: function (autosave = false) {
            var c, errors, field, i, len, promises, req_fields, row, valid;
            row = this.get('model.record');
            row.set('project', this.store.peekRecord('project', this.get('model.projectid')));
            c = this.get('Collector');
            req_fields = [c.field(this.get('model.form'), 'project')].concat(
                c.fieldArray(this.get('model.form')).filterBy('features.mandatory', true),
            );
            valid = true;
            errors = Em.A([]);
            for (i = 0, len = req_fields.length; i < len; i++) {
                field = req_fields[i];
                if (!row.get(field.name)) {
                    errors.pushObject(field.translated_name);
                    valid = false;
                }
            }
            promises = [];
            if (!valid && autosave) {
                return;
            }
            if (autosave) {
                this.get('savedNotices').forEach((notice) => {
                    if (notice.get('hasDirtyAttributes')) {
                        return promises.push(this.saveNotice(notice));
                    }
                });
            } else {
                this.get('fields').forEach((field) => {
                    return field.notices.forEach((notice) => {
                        if (notice.get('hasDirtyAttributes')) {
                            return promises.push(this.saveNotice(notice));
                        }
                    });
                });
            }
            return Promise.all(promises)
                .then(() => {
                    var autoSavePromise;
                    this.set('errors', errors);
                    if (valid) {
                        row.set('date', moment().format('YYYY-MM-DD'));
                        if (autosave) {
                            autoSavePromise = row.save();
                            this.set('autosaved', moment());
                            this.set('autoSavePromise', autoSavePromise);
                            return autoSavePromise
                                .then(() => {
                                    return this.set('autosaved', moment());
                                })
                                .catch((e) => {
                                    console.log(e);
                                    return this.handleRequestError(e);
                                })
                                .finally(() => {
                                    return this.set('spinIsLoading', false);
                                });
                        } else {
                            autoSavePromise = this.get('autoSavePromise');
                            if (!autoSavePromise) {
                                autoSavePromise = RSVP.Promise.resolve();
                            }
                            return autoSavePromise.then(() => {
                                return row
                                    .save()
                                    .then(() => {
                                        return this.transitionToRoute(
                                            'site_safety.saved',
                                            row.get('constructor.modelName'),
                                            row.get('id'),
                                        );
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        return this.handleRequestError(e);
                                    })
                                    .finally(() => {
                                        return this.set('spinIsLoading', false);
                                    });
                            });
                        }
                    } else {
                        return this.set('spinIsLoading', false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.handleRequestError(e);
                    this.set('spinIsLoading', false);
                    return this.set('approvedSpinIsLoading', false);
                });
        },

        empty: function (field) {
            return this.set('model.record.' + field, null);
        },
        setSignature: function (value, field) {
            return this.get('model.record').set(field, value);
        },
        markAsApproved: function () {
            this.set('model.record.status', 'approved');
            return this.send('save');
        },
        addToSavedNotices: function (notice) {
            return this.get('savedNotices').push(notice);
        },
        //console.log notice
        cancelExtrainfoModal: function () {
            return this.set('showExtrainfoModal', false);
        },
        saveExtrainfoModal: function () {
            this.set('showExtrainfoModal', false);
            return this.send('add');
        },
        save: function () {
            if (this.get('extrafields.length') === 0) {
                return this.send('add');
            } else {
                return this.set('showExtrainfoModal', true);
            }
        },
    },
});

export default SiteSafetyTrAddMeasurementController;
