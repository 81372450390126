import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import collector from '../../../classes/collector';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { isPresent } from '@ember/utils';

export default Route.extend({
    Collector: service('collector-service'),
    capi: service(),
    ttapi: service(),
    gui: service(),
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
    },
    AuthenticatedRouteMixin,

    beforeModel() {
        this._super(...arguments);
        set(this, 'gui.sidebar', true);
    },
    model(params) {
        const c = get(this, 'Collector');
        const capi = get(this, 'capi');
        const ttapi = get(this, 'ttapi');
        if (!c.testNeedsOne('employer')) delete params.employer;
        if (!params.user) delete params.user;
        delete params.page;
        params.sideload = true;
        params.order = 'date';

        let aparams = Object.assign({}, params);
        if (c.testNeedsOne('abcense')) {
            const split_date = params.date.split('_');
            delete aparams.date;
            aparams.startdate = split_date[0];
            aparams.enddate = split_date[1];
            aparams.order = 'startdate';
            aparams.tt3flag = get(collector, 'tt3flag');
            aparams.sideload = c.getSideloads('abcense');
            aparams.raw = true;
        }

        return hash({
            dailyWorktimeSums: c.testNeedsOne('worktime')
                ? capi.findAll('worktime', Object.assign(params, { groupBy: 'date' }))
                : [],
            absences: c.testNeedsOne('abcense')
                ? ttapi
                      ._get('/api/absences/splitted', aparams)
                      .then((data) => this._returnData(data, c))
                : [],
            worktimeSums: c.testNeedsOne('worktime')
                ? capi.findAll('worktime', Object.assign(params, { groupBy: 'user' }))
                : [],
        });
    },
    resetController(controller, isExiting) {
        if (isExiting) {
            get(this, 'gui').resetSidebar();
        }
    },
    actions: {
        refreshModel(record) {
            this.refresh();
            record.off('didUpdate');
        },
    },

    _returnData(data, c) {
        if (isPresent(data.abcenses)) {
            c.pushToStore(data);
            return data;
        } else {
            return { abcenses: [] };
        }
    },
});
