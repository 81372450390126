/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryReportRoute;

import Ember from 'ember';

DiaryReportRoute = Ember.Route.extend({
    session: Ember.inject.service(),
    redirect: function (model, transition) {
        if (
            transition.targetName !== 'diary.report.date' &&
            transition.targetName !== 'diary.report.date.index' &&
            transition.targetName !== 'diary.report.date.project'
        ) {
            return this.transitionTo('diary.report.date', moment().format('YYYY-MM-DD'));
        }
    },
});

export default DiaryReportRoute;
