import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n    @dropdownClass=\"power-select-add-field\"\n    @options={{this.options}}\n    @selected={{this.value}}\n    @disabled={{or @disabled this.disabled}}\n    @onChange={{this.onChange}}\n    @searchEnabled={{false}}\n    @allowClear={{true}}\n    @onOpen={{fn (mut this.isopen) true}}\n    @onClose={{fn (mut this.isopen) false}}\n    as |opt|\n>\n    {{opt.value}}\n</PowerSelect>\n{{#if this.isopen}}\n    <div class=\"overlay\"></div>\n{{/if}}", {"contents":"<PowerSelect\n    @dropdownClass=\"power-select-add-field\"\n    @options={{this.options}}\n    @selected={{this.value}}\n    @disabled={{or @disabled this.disabled}}\n    @onChange={{this.onChange}}\n    @searchEnabled={{false}}\n    @allowClear={{true}}\n    @onOpen={{fn (mut this.isopen) true}}\n    @onClose={{fn (mut this.isopen) false}}\n    as |opt|\n>\n    {{opt.value}}\n</PowerSelect>\n{{#if this.isopen}}\n    <div class=\"overlay\"></div>\n{{/if}}","moduleName":"tt4/components/addon/fields/dropdown-values.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/dropdown-values.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { IOptions } from 'tt4/ember-movenium/interfaces/forms';
import { IFieldComponent } from 'tt4/ember-movenium/interfaces/fields';
import { sortBy } from 'tt4/ember-movenium/utils/sort';

interface IKeyValue {
    id: string | number;
    name: string;
}

export default class FieldsDropdownValuesComponent extends Component<IFieldComponent> {
    public form: string | undefined;
    public name: string | undefined;
    @service intl: any;
    @tracked protected selected: string | number | boolean | null = null;
    // private _options: string = ''

    constructor(owner: unknown, args: IFieldComponent) {
        super(owner, args);

        this.name = this.args.name;
        this.form = this.args.form;
    }

    @action
    public onChange(value: any) {
        this.args.onChange(value?.id);
    }

    get value(): IKeyValue | null {
        if (this.args.value) {
            return this.options.find((opt: IKeyValue) => opt.id === this.args.value) as IKeyValue;
        }
        return null;
    }

    // power select wants [{id:id, value:value}]
    get options(): IOptions {
        const options = this.args.options;
        if (!options) return [];

        const getName = (opt: any) => {
            if (this.intl.exists(this.form + '.' + this.name + '.' + opt)) {
                return this.intl.t(this.form + '.' + this.name + '.' + opt);
            } else {
                return opt;
            }
        };

        let realOptions = [];

        // we have legacy reporsed values from collector class
        if ((options as IOptions[])[0].id) {
            realOptions = (options as []).map((opt: any) => {
                return { id: opt.id, value: getName(opt.value) };
            });
        } else {
            const splitOptions = (options as string).split('\n');

            realOptions = splitOptions?.map((opt: any) => {
                return { id: opt, value: getName(opt) };
            });
        }

        return this.args.sortBy ? sortBy(this.args.sortBy, realOptions) : realOptions;
    }
}
