/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/edit/add-file/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersEditAddFileRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

EmployersEditAddFileRoute = GeneralAddRoute.extend({
    modelName: 'employerfile',
    afterSave: Ember.computed('form', function () {
        return {
            route: 'employers.edit.employer-files',
            params: this.modelFor('employers.edit').get('id'),
        };
    }),
    model: function (params) {
        var employer;
        employer = this.modelFor('employers.edit');
        return this.store.createRecord('employerfile', {
            employer: employer,
        });
    },
});

export default EmployersEditAddFileRoute;
