/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/get-back.coffee.old
// Generated by CoffeeScript 2.6.1
var GetBackMixin;

import Ember from 'ember';

GetBackMixin = Ember.Mixin.create({
    camefrom: null,
    obsCameform: Ember.observer('camefrom', function () {
        return this.refresh();
    }),
    afterModel: function (model, transition) {
        this.set('form', model.form);
        if (transition.from != null && transition.from.name !== 'massedit') {
            this.set('camefrom', transition.from.name);
        } else {
            this.set('camefrom', 'index');
        }
        return true;
    },
    actions: {
        back: function () {
            var camefrom, form;
            camefrom = this.get('camefrom');
            form = this.get('form');
            if (camefrom === 'thereport.page') {
                return this.transitionTo('thereport.index');
            } else if (camefrom === 'forms.form-report') {
                return this.transitionTo('forms.form-report', {
                    form: form,
                });
            } else if (camefrom === 'timetracker.aggregated.rows') {
                return this.transitionTo('timetracker.aggregated.rows', 'all');
            } else {
                return this.transitionTo(this.get('camefrom'));
            }
        },
    },
});

export default GetBackMixin;
