import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Helper.extend({
    collector: service('collector-service'),

    compute(params, hash) {
        let fields = get(this, 'collector').fieldArray(params[0]);
        if (hash.onlyTypes)
            fields = fields.filter((item) => hash.onlyTypes.split(',').includes(item.type));
        return fields;
    },
});
