/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/mvr/graphs/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMvrGraphsController;

import Ember from 'ember';

import formattedMixin from 'tt4/mixins/sitesafety-formatted-export';

import chartComp from 'tt4/components/chart-comp';

SiteSafetyMvrGraphsController = Ember.Controller.extend(formattedMixin, {
    mvrController: Ember.inject.controller('site_safety/report/mvr'),
    queryParams: ['project', 'date', 'group'],
    project: Ember.computed.alias('mvrController.project'),
    date: Ember.computed.alias('mvrController.date'),
    group: Ember.computed.alias('mvrController.group'),
    intl: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                form: 'mvr',
                type: 'database',
                value: this.get('project'),
                singleselect: true,
            }),
            Em.Object.create({
                field: 'date',
                form: 'mvr',
                type: 'date',
                value: this.get('date'),
            }),
            Em.Object.create({
                field: 'group',
                form: 'mvr',
                type: 'database',
                value: this.get('group'),
                singleselect: true,
            }),
        ]);
    }),
    pie_options: {
        pieHole: 0.9,
        chartArea: {
            width: '90%',
            height: '95%',
        },
        height: 200,
        width: 350,
        pieSliceTextStyle: {
            color: 'black',
            fontSize: 20,
        },
    },
    //        slices: {
    //            1: {offset: 0.02}
    //            2: {offset: 0.02}
    //            3: {offset: 0.02}
    //        }
    dataSetter: function () {
        var data,
            data_rating,
            data_rating_pie,
            pie_over90under95,
            pie_over95,
            pie_under90,
            self,
            trs;
        self = this;
        data = Em.A([
            [
                this.get('intl').t('date'),
                this.get('intl').t('mvr.title.working'),
                this.get('intl').t('mvr.title.equipment'),
                this.get('intl').t('mvr.title.protection'),
                this.get('intl').t('mvr.title.road'),
                this.get('intl').t('mvr.title.order'),
            ],
        ]);
        data_rating = Em.A([[this.get('intl').t('date'), this.get('intl').t('mvr.rating')]]);
        data_rating_pie = Em.A([
            [this.get('intl').t('mvr.rating'), this.get('intl').t('mvr.rating')],
        ]);
        pie_under90 = 0;
        pie_over90under95 = 0;
        pie_over95 = 0;
        trs = this.get('model.mvr');
        if (!trs) {
            return;
        }
        trs.forEach(function (tr, idx) {
            var field, fields, i, len, minus, plus, row, tr_minus, tr_plus;
            if (moment(tr.get('date')).isAfter(moment().subtract(1, 'years'))) {
                row = [moment(tr.get('date')).format('L')];
                tr_plus = tr.getProperties(
                    'working_plus',
                    'equipment_plus',
                    'protection_plus',
                    'road_plus',
                    'order_plus',
                );
                tr_minus = tr.getProperties(
                    'working_minus',
                    'equipment_minus',
                    'protection_minus',
                    'road_minus',
                    'order_minus',
                );
                fields = ['working', 'equipment', 'protection', 'road', 'order'];
                for (i = 0, len = fields.length; i < len; i++) {
                    field = fields[i];
                    plus = 0;
                    minus = 0;
                    if (tr_plus[field + '_plus']) {
                        plus = parseInt(tr_plus[field + '_plus'], 10);
                    }
                    if (tr_minus[field + '_minus']) {
                        minus = parseInt(tr_minus[field + '_minus'], 10);
                    }
                    row.push(parseFloat(((plus / (plus + minus)) * 100).toFixed(2)));
                }
                if (Ember.isEmpty(self.get('selectedIndex')) || self.get('selectedIndex') === idx) {
                    data.push(row);
                }
                if (!isNaN(tr.get('mvr_rating')) && tr.get('mvr_rating') !== null) {
                    data_rating.push([
                        moment(tr.get('date')).format('L'),
                        parseFloat(tr.get('mvr_rating')),
                    ]);
                    if (tr.get('mvr_rating') < 90) {
                        pie_under90++;
                    }
                    if (tr.get('mvr_rating') >= 90 && tr.get('mvr_rating') < 95) {
                        pie_over90under95++;
                    }
                    if (tr.get('mvr_rating') >= 95) {
                        return pie_over95++;
                    }
                } else {
                    return data_rating.push([moment(tr.get('date')).format('L'), 0]);
                }
            }
        });
        data_rating_pie.push([this.get('intl').t('mvr_rating_pie_under90'), pie_under90]);
        data_rating_pie.push([
            this.get('intl').t('mvr_rating_pie_over90under95'),
            pie_over90under95,
        ]);
        data_rating_pie.push([this.get('intl').t('mvr_rating_pie_over95'), pie_over95]);
        self.set('data', data);
        self.set('data_rating', data_rating);
        return self.set('data_rating_pie', data_rating_pie);
    }.observes('model.mvr.[]', 'intl.primaryLocale', 'selectedIndex'),
    actions: {
        handleSelection: function (sel) {
            if (this.get('selectedIndex') === sel[0].row) {
                return this.set('selectedIndex', null);
            } else {
                return this.set('selectedIndex', sel[0].row);
            }
        },
        filterChanged: function (value, field) {
            if (value == null) {
                return this.set(field, '');
            } else if (field === 'date') {
                return this.set(field, value);
            } else {
                return this.set(field, value.id);
            }
        },
        exportNewestPdf: function (project) {
            var charts, fields, newest;
            // get newest mvr record
            newest = this.store
                .peekAll('mvr')
                .filter((record) => {
                    return record.get('project.id') === project;
                })
                .sortBy('date')
                .reverse()
                .get('firstObject');
            if (!newest) {
                this.get('dialogs').alert(
                    this.get('intl').t('tr.chart.export_newest_pdf_not_found'),
                );
            }
            // export as measurement pdf
            fields = this.get('Collector').fields('mvr');
            charts = [
                {
                    url: this.get('piechart').getImageURI(),
                    width: 300,
                    title: this.get('intl').t('mvr.chart.mvr_rating_pie'),
                },
                {
                    url: this.get('datachart').getImageURI(),
                    title: this.get('intl').t('mvr.chart.measurement_ratings'),
                },
                {
                    url: this.get('dataratingchart').getImageURI(),
                    title: this.get('intl').t('mvr.chart.mvr_rating'),
                },
            ];
            return this.createMeasurementPdf(fields, [newest], 'download', charts);
        },
    },
});

export default SiteSafetyMvrGraphsController;
