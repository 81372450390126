import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    intl: service(),

    translated_headers: computed(function () {
        let headers = get(this, 'headers');
        return headers.map((header) =>
            this.intl.t('widget.' + get(this, 'form') + '.table-header.' + header),
        );
    }),
});
