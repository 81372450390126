import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
    valid: computed('status', 'date', function () {
        if (get(this, 'status') === 'approved' && moment(get(this, 'expires')).isAfter(moment())) {
            return true;
        } else {
            return false;
        }
    }),
});
