/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/diary-modal/component.coffee.old
// Generated by CoffeeScript 2.6.1
var MyModalComponent;

import Ember from 'ember';

MyModalComponent = Ember.Component.extend({
    hideCloseButtons: false,
    okButtonText: null,
    backdrop: 'static',
    actions: {
        ok: function () {
            $('.modal', this.element).modal('hide');
            return this.sendAction('ok');
        },
        clear: function () {
            return this.sendAction('clear');
        },
    },
    show: function () {
        this.sendAction('didinsert');
        return $('.modal', this.element)
            .modal()
            .on(
                'hidden.bs.modal',
                function () {
                    return this.sendAction('close');
                }.bind(this),
            );
    }.on('didInsertElement'),
    hide: Em.on('willDestroyElement', function () {
        return $('.modal', this.element).modal('hide');
    }),
});

export default MyModalComponent;
