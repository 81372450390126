import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

/*
This component wants    
timecleanProjects = array of {id:project.id, name:project.name}
showTimecleanModal = true/false
*/
export default Component.extend({
    ttapi: service(),

    init() {
        this._super();
        //if gatelist projects are found, automatically select the first to the dropdown
        let firstProject = get(this, 'timecleanProjects.firstObject');
        if (firstProject) set(this, 'project', firstProject.id);
    },

    actions: {
        syncTimeclean(project = null) {
            set(this, 'timecleanSynced', false);
            if (!project) return;
            set(this, 'loading', true);
            set(this, 'error', false);
            get(this, 'ttapi')
                ._post('/api/gateList/updateGateList', { project: project })
                .then(this.completeSyncing.bind(this))
                .catch(this.handleError.bind(this));
        },

        close() {
            set(this, 'showTimecleanModal', false);
            set(this, 'timecleanSynced', false);
            set(this, 'loading', false);
            set(this, 'error', false);
        },

        projectChanged() {
            set(this, 'timecleanSynced', false);
            set(this, 'loading', false);
            set(this, 'error', false);
        },
    },

    completeSyncing() {
        set(this, 'loading', false);
        set(this, 'timecleanSynced', true);
    },

    handleError(e) {
        set(this, 'loading', false);
        if (e.message) {
            set(this, 'error', e.message);
        } else {
            set(this, 'error', e);
        }
        set(this, 'timecleanSynced', false);
    },
});
