import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";

export default class CollectorFormAddFormsOrientationComponent extends Component {
    @service store;
    @service("collector-service") Collector;
    @tracked record;

    constructor() {
        super(...arguments);
        this.record = this.args.record;
    }
    @action
    onFieldChange(field, value) {
        this.record[field] = value;
    }
}
