import { inject as service } from '@ember/service';
import recordComp from '../../record-comp/component';
import { set } from '@ember/object';

const CollectorAddModalCardComponent = recordComp.extend({
    store: service(),
    session: service(),

    layoutName: 'collector/record-comp',

    init() {
        this._super();
        this.store.createRecord('card', {
            taxnumber: this.session.currentUser.taxnumber,
            name: this.session.currentUser.firstname + ' ' + this.session.currentUser.lastname,
            owner: 'partner',
        });

        set(this, 'record_id', 'new');
    },
});

export default CollectorAddModalCardComponent;
