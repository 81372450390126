import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CustomExportComponent extends Component {
    @service('collector-service') Collector;
    @service notifications;
    @service settings;
    @service intl;

    @tracked selected;
    @tracked isLoading;

    constructor() {
        super(...arguments);

        this.args.options.forEach((option) => {
            if (this.Collector.testNeeds(option.products)) this.selected = option;
        });
    }

    @action
    async save(opt) {
        this.selected = opt;
        this.isLoading = true;

        await this.settings.setProducts(opt.products);

        if (this.args.saved) await this.args.saved(opt);

        this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        this.isLoading = false;
    }
}
