define("tt4/utils/country", ["exports", "@movenium/ember-movenium/utils/country"], function (_exports, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _country.default;
    }
  });
});
