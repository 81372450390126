/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/history-modal/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorHistoryModalComponent;

import Ember from 'ember';

CollectorHistoryModalComponent = Ember.Component.extend({
    actions: {
        removeModal: function () {
            return this.sendAction('removeModal');
        },
    },
});

export default CollectorHistoryModalComponent;
