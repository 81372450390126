/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/general-v2/components/number-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompNumberFieldComponent;

import Ember from 'ember';

CollectorFieldSettingCompNumberFieldComponent = Ember.Component.extend({
    customerSettings: Ember.inject.service(),
    value: Em.computed(function () {
        return (
            this.get('customerSettings').checkSetting(this.get('configs.setting')) ||
            this.get('configs.defaultVal')
        );
    }),
    actions: {
        save: function () {
            this.set('loading', true);
            return this.get('customerSettings')
                .setSetting(this.get('configs.setting'), this.get('value'))
                .then(() => {
                    this.set('loading', false);
                    if (this.get('settingSaved')) {
                        return this.settingSaved();
                    }
                });
        },
        validateInput: function () {
            if (this.get('configs.value') < this.get('configs.minVal')) {
                return this.set('configs.value', this.get('configs.minVal'));
            }
        },
    },
});

export default CollectorFieldSettingCompNumberFieldComponent;
