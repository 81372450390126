import Component from '@ember/component';
import { get } from '@ember/object';

export default Component.extend({
    classNames: ['setting-card'],

    init() {
        this._super();
        if (get(this, 'size')) {
            get(this, 'classNames').push('fixed-height-' + get(this, 'size'));
        }
    },
});
