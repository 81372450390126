/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isArray } from '@ember/array';
import { computed } from '@ember/object';

import { throttle } from '@ember/runloop';
import { inject as service } from '@ember/service';
import RESTAdapter from 'ember-data/adapters/rest';
import collector from '../classes/collector';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { get, set } from '@ember/object';
import config from 'tt4/config/environment';

export default RESTAdapter.extend(DataAdapterMixin, {
    onlineChecker: service(),
    dialogs: service(),
    intl: service(),
    session: service(),
    flags: service(),
    sessionhelper: service(),

    // list of all errors that are handled somewhere else so error popup is not needed
    init() {
        this._super(...arguments);
        this.handledErrors = [
            'overlap',
            'validation_error',
            'not_allowed',
            'mandatory_field_missing',
        ];
    },

    ensureResponseAuthorized(status, headers, payload, requestData) {
        if (
            status === 401 &&
            payload.error_description === 'The access token provided has expired'
        ) {
            this.sessionhelper.expiredRecovery(`${requestData.method} ${requestData.url}`);
        } else {
            return this._super(...arguments);
        }
    },

    headers: computed('session.data.authenticated.access_token', function () {
        let headers = {};
        if (this.session.isAuthenticated) {
            // OAuth 2
            headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
        }
        return headers;
    }),

    host: computed('session.currentUser.partnerid', function () {
        if (config.environment !== 'development' && get(this, 'flags').test('capi_url'))
            return get(this, 'flags').test('capi_url');
        else return collector.api_url();
    }),

    isSuccess(status, headers, payload) {
        const handledErrors = get(this, 'handledErrors');

        if (payload && payload.error && !Array.from(handledErrors).includes(payload.error)) {
            const error_obj = {};
            error_obj.responseText = payload.error;
            error_obj.status = status;
            error_obj.statusText = payload.error_msg;
            set(this, 'last_error', error_obj);
        }

        return this._super(status, headers, payload);
    },

    showErrorDialog() {
        return get(this, 'dialogs').alert(get(this, 'intl').t('general.error.too_many_calls'));
    },

    normalizeErrorResponse(status, headers, payload) {
        if (payload && typeof payload === 'object' && payload.error) {
            if (payload.error === 'too_many_calls') {
                throttle(this, 'showErrorDialog', 10000);
            }
            payload.errors = {
                error: payload.error,
                error_msg: payload.error_msg,
                errorMeta: payload.meta,
            };
            delete payload.error;
            delete payload.error_msg;
            delete payload.meta;
        }
        if (payload && typeof payload === 'object' && isArray(payload.errors)) {
            payload.errors = {
                error: payload.errors[0].title,
                error_msg: payload.errors[0].details,
            };
        }

        payload.errors = [payload.errors];

        return this._super(status, headers, payload);
    },

    generatedDetailedMessage(status, headers, payload, requestData) {
        return this._super(status, headers, JSON.stringify(payload.errors), requestData);
    },

    urlForQuery(query, modelName) {
        if (query.form && query.field) {
            modelName = query.form + '/' + query.field;
            delete query.form;
            delete query.field;
        }

        if (query.endpoint) {
            const endpoint = query.endpoint;
            delete query.endpoint;
            return endpoint;
        }

        return this._super(query, modelName);
    },

    urlForUpdateRecord(id, modelName, snapshot) {
        let url = this._super(id, modelName, snapshot);
        if (snapshot.adapterOptions && snapshot.adapterOptions.validation === 'off') {
            url += '?validation=off';
        }
        return url;
    },
});
