/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/reports/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

OrientationController = GeneralReportController.extend({
    filters: Em.A([
        Em.Object.create({
            field: 'ori_site',
        }),
        Em.Object.create({
            field: 'ori_date',
        }),
    ]),
    projectObserver: function () {
        return this.set('page', 1);
    }.observes('project'),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
        }),
        sortByColumn: 'ori_date',
    }),
    ori_date:
        moment().subtract(2, 'year').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    queryParams: ['page', 'user', 'ori_site', 'ori_employer', 'ori_user', 'ori_date'],
    filter: false,
    search: false,
    user: null,
    ori_site: null,
    project: null,
    ori_employer: null,
    ori_user: null,
    //    status: 'all'
    form: 'orientation',
    //Could not get this from model for some reason
    localStorageKey: 'Tt4.Orientation',
    // These should probably go to general report controller and be loaded from settings api
    showColumns: [
        'ori_date',
        'special_warning',
        'ori_place',
        'ori_user',
        'ori_site',
        'ori_employer',
        'sign_emp_img',
        'sign_per_img',
    ],
    cancelRoute: 'orientations.reports',
    actions: {
        closeEditModal: function () {
            this.editRecord.rollbackAttributes();
            return this.set('editRecord', null);
        },
    },
});

export default OrientationController;
