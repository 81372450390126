import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
const { alias } = computed;

export default Controller.extend({
    addController: controller('orientations/add'),
    init() {
        this._super(...arguments);
        this.selectedParentsStates = [];
    },
    selected_parents: alias('addController.selected_parents'),
    users: alias('addController.users'),
    content: alias('model'),

    showButtonNext: computed('selectedParentsStates.@each.parentSelected', function () {
        //First time in page when nothing is selected...
        if (get(this, 'selectedParentsStates').length == 0) {
            return false;
        }
        const usersWithoutParent = get(this, 'selectedParentsStates').filter((item) => {
            return !item.parentSelected;
        });
        if (usersWithoutParent.length == 0) return true;
        else return false;
    }),

    actions: {
        parentSelected(user, value) {
            // super ugly hax but orientations just are
            if (get(this, 'selectedParentsStates').length == 0) {
                const arr = [];
                get(this, 'users').forEach((userid) => {
                    arr.push({ user: userid, parentSelected: false });
                });
                set(this, 'selectedParentsStates', arr);
            }
            const item = get(this, 'selectedParentsStates').findBy('user', user);
            set(item, 'parentSelected', value ? true : false);
        },
    },
});
