import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';

export default Component.extend({
    store: service(),
    collector: service('collector-service'),
    ttapi: service(),
    flags: service(),
    session: service(),

    form: 'abcense',

    fields: computed('formtype', function () {
        const fields = this.collector.fieldArray(this.form).mapBy('name');
        if (this.formtype === 'multi')
            return fields
                .filter((field) => !['starttime', 'endtime', 'absence_hours'].includes(field))
                .join(',');
        else
            return fields
                .filter((field) => !['dayamount', 'status', 'enddate'].includes(field))
                .join(',');
    }),

    absencetypes: computed('formtype', function () {
        if (this.formtype === 'multi') return 'both||days||(empty)';
        else return 'both||hours||(empty)';
    }),

    didReceiveAttrs() {
        if (!this.record) this.record = this.store.createRecord('abcense', this.defaults);
        // set user always my self when adding new
        if (this.record && this.record.isNew) {
            if (!this.record.user) {
                this.record.user = this.store.peekRecord('user', this.session.currentUser.id);
            }
            set(this, 'formtype', 'multi'); // also when adding, always show multi-day absenceform first
        } else {
            if (get(this, 'record.dayamount')) set(this, 'formtype', 'multi');
            else set(this, 'formtype', 'single');
        }
    },

    actions: {
        /**
         * when formtype is changed we need to replace some values so they wouldn't save to db
         */
        changeType(type) {
            set(this, 'formtype', type);
            if (type === 'multi') {
                set(this, 'record.starttime', null);
                set(this, 'record.endtime', null);
                // instead of setting dayamount to 1 we use fieldChanged to set enddate to startdate so it will fetch the days from ttapi
                if (get(this, 'record.startdate')) {
                    this.send('fieldChanged', 'enddate', get(this, 'record.startdate'));
                }
            } else {
                set(this, 'record.enddate', get(this, 'record.startdate'));
                set(this, 'record.dayamount', null);
            }
        },

        fieldChanged(value, field) {
            if (this.flags.test('ember-movenium-form-add-absence')) {
                // addon form-add returns (field, value) so we have to swap them around
                [value, field] = [field, value];
                set(this.record, field, value);
            }

            // when single adding is on startdate and enddate must be the same all the time
            if (field === 'startdate' && this.formtype === 'single')
                set(this, 'record.enddate', value);

            if (field === 'startdate') {
                let endDate = get(this, 'record.enddate');

                if (!endDate || new Date(value) > new Date(endDate)) {
                    set(this, 'record.enddate', value);
                }
            }

            if (
                (field === 'startdate' || field === 'enddate' || field === 'abcensetype') &&
                this.formtype === 'multi'
            ) {
                if (get(this, 'record.startdate') && get(this, 'record.enddate')) {
                    set(this, 'disableForm', true);
                    debounce(this, this.fetchAbsenceDays, 1000);
                }
            }
        },
    },

    /**
     * fetch absence days from ttapi and set value to record.dayamount
     */
    async fetchAbsenceDays() {
        const country =
            '&country=' + get(get(this, 'store').peekAll('company-info'), 'firstObject.country');

        let url =
            'absence/getDays?date=' +
            get(this, 'record.startdate') +
            '_' +
            get(this, 'record.enddate') +
            country;
        url +=
            '&userid=' +
            (get(this, 'record.user')
                ? get(this, 'record.user.id')
                : get(this, 'session.currentUser.id'));
        url += get(this, 'record.abcensetype.id')
            ? '&absencetype=' + get(this, 'record.abcensetype.id')
            : '';
        const back = await this.ttapi.request(url);
        set(this, 'record.dayamount', back.days);
        set(this, 'disableForm', false);
    },
});
