/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/checkboxes/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var checkboxesController;

import Ember from 'ember';

checkboxesController = Ember.Controller.extend({
    //    needs: 'orientations/add'
    addController: Ember.inject.controller('orientations/add'),
    json: function () {
        var key, model, self, temp;
        model = this.get('addController.model.record');
        if (!model) {
            return;
        }
        temp = Ember.A([]);
        self = this;
        this.get('checkboxes').forEach(function (item) {
            if (item.checked) {
                return temp.pushObject(item.key);
            }
        });
        key = this.get('model.checkboxes.key');
        return model.set(key, temp);
    }.observes('checkboxes.@each.checked'),
    checkboxes: Ember.computed.alias('model.checkboxes.options'),
    title: Ember.computed.alias('model.checkboxes.title'),
    group: Ember.computed.alias('model.group'),
    firstGroup: Ember.computed.equal('group', 0),
    lastGroup: Ember.computed('group', function () {
        return this.get('group') === this.get('model.count') - 1;
    }),
    previousGroup: Ember.computed('group', function () {
        return this.get('group') - 1;
    }),
    nextGroup: Ember.computed('group', function () {
        return this.get('group') + 1;
    }),
    mandatory: Ember.computed('model', function () {
        return this.get('addController.mandatoryArr')[this.get('model.checkboxes.key')];
    }),
    valid: Ember.computed('mandatory', 'checkboxes.@each.checked', function () {
        var checkboxes;
        if (this.get('mandatory')) {
            checkboxes = this.get('checkboxes');
            return checkboxes.get('length') === checkboxes.filterBy('checked').get('length');
        }
        return true;
    }),
    actions: {
        toggleCheckbox: function (box) {
            box.toggleProperty('checked');
            return false;
        },
    },
});

export default checkboxesController;
