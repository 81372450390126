/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/general-report.coffee.old
// Generated by CoffeeScript 2.6.1
var GeneralReportRoute,
    indexOf = [].indexOf;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import collector from '../classes/collector';

import initRoute from '../mixins/init-route';

GeneralReportRoute = Ember.Route.extend(AuthenticatedRouteMixin, initRoute, {
    localStorageWrapper: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function () {
        var queryParams;
        this._super();
        queryParams = this.get('queryParams');
        queryParams.page = {
            refreshModel: true,
        };
        queryParams.order = {
            refreshModel: true,
        };
        queryParams.limit = {
            refreshModel: true,
        };
        queryParams.row_info_status = {
            refreshModel: true,
        };
        queryParams.more = {
            refreshModel: true,
        };
        return this.set('queryParams', queryParams);
    },
    offset: 0,
    //    renderTemplate: ->
    //        @render 'general-report', {controller: @get('parentController')}
    paramsSet: false,
    more: null,
    model: function (params) {
        var c, fields, form, ls_key_name, page, param, queryParams, split, v, valuefromLS;
        if (params.limit > 100) {
            params.limit = 100;
        } else if (!params.limit) {
            params.limit = 25;
        }
        if (this.get('localStorageKey')) {
            ls_key_name = this.get('localStorageKey') + '_defaultLimit';
        } else {
            ls_key_name = this.get('form') + '_defaultLimit';
        }
        valuefromLS = this.get('localStorageWrapper').getUserSetting(ls_key_name);
        if (!Em.isEmpty(valuefromLS)) {
            params.limit = valuefromLS;
        }
        if (!this.get('paramsSet')) {
            queryParams = this.get('queryParams');
            c = this.get('Collector');
            form = this.get('form');
            fields = c.fieldArray(form);
            fields.forEach(function (field) {
                var ref;
                if (
                    (ref = field.type) === 'date' ||
                    ref === 'database' ||
                    ref === 'option_values'
                ) {
                    return (queryParams[field.name] = {
                        refreshModel: true,
                    });
                }
            });
            this.set('queryParams', queryParams);
            this.set('paramsSet', true);
        }
        page = params.page;
        page = isNaN(page) || page <= 0 ? 1 : Math.floor(page);
        //        if @get('controller.content.meta.count') > @get('limit')
        this.set('offset', (page - 1) * params.limit);
        params.offset = this.get('offset');
        delete params.page;
        if (params.status === 'all') {
            delete params.status;
        }
        for (param in params) {
            v = params[param];
            if (param.includes('-')) {
                if (Ember.isPresent(v)) {
                    split = param.split('-');
                    if (Ember.isEmpty(params[split[0]])) {
                        params[split[0]] = {
                            [`${split[1]}`]: v,
                        };
                    }
                }
                delete params[param];
            }
            if (v === 'null' || v === null || v === 'undefined') {
                delete params[param];
            }
        }
        if (params.more != null) {
            params.more.split('|').forEach(function (param) {
                var splitted;
                splitted = param.split(':');
                if (splitted[0].includes('-')) {
                    if (Ember.isPresent(splitted[1])) {
                        split = splitted[0].split('-');
                        if (Ember.isEmpty(params[split[0]])) {
                            return (params[split[0]] = {
                                [`${split[1]}`]: splitted[1],
                            });
                        }
                    }
                } else {
                    return (params[splitted[0]] = splitted[1]);
                }
            });
            this.set('more', params.more);
        } else {
            this.set('more', null);
        }
        delete params.more; // just to make sure this doesn't bleed into capi
        return params;
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        this.set('localStorageKey', controller.localStorageKey);
        if (this.get('more')) {
            this.get('more')
                .split('|')
                .forEach(function (param) {
                    var splitted;
                    splitted = param.split(':');
                    return controller.set(splitted[0], splitted[1]);
                });
        }
        // there is a high probability that this doesn't work on some report/s
        /*
    if controller.get('filters') && Ember.typeOf(controller.get('filters')) is "array"
        for filter in controller.get 'filters'
            if filter.type != "searchbox" && (!controller.get(filter.field) || controller.get(filter.field) == "null")
                if controller.get(filter.field) != null
                    controller.set filter.field, undefined

    */
        return controller.setProperties({
            offset: this.get('offset'),
        });
    },
    actions: {
        next_date: function () {
            return this.set(
                'controller.date',
                moment(this.get('controller.date')).add(1, 'days').format('YYYY-MM-DD'),
            );
        },
        prev_date: function () {
            return this.set(
                'controller.date',
                moment(this.get('controller.date')).subtract(1, 'days').format('YYYY-MM-DD'),
            );
        },
        showColumn: function (col) {
            var arr, field, i, key, len, ref, ref1;
            key = this.get('controller.model').type + '_defaultColumns';
            this.get('controller.defaultColumns').pushObject(col);
            this.get('controller.extraColumns').removeObject(col);
            if (localStorage) {
                if (localStorage[key]) {
                    arr = JSON.parse(localStorage[key]);
                    if (((ref = col.ext), indexOf.call(arr, ref) < 0)) {
                        arr.push(col.ext);
                    }
                    return (localStorage[key] = JSON.stringify(arr));
                } else {
                    arr = [];
                    ref1 = this.get('controller.defaultColumns');
                    for (i = 0, len = ref1.length; i < len; i++) {
                        field = ref1[i];
                        arr.push(field.ext);
                    }
                    return (localStorage[key] = JSON.stringify(arr));
                }
            }
        },
        hideColumn: function (col) {
            var arr, field, i, key, len, ref;
            key = this.get('controller.model').type + '_defaultColumns';
            this.get('controller.defaultColumns').removeObject(col);
            this.get('controller.extraColumns').pushObject(col);
            if (localStorage) {
                arr = [];
                ref = this.get('controller.defaultColumns');
                for (i = 0, len = ref.length; i < len; i++) {
                    field = ref[i];
                    arr.push(field.ext);
                }
                return (localStorage[key] = JSON.stringify(arr));
            }
        },
        status: function (status) {
            return this.set('controller.status', status);
        },
        toggleCalender: function () {
            return this.toggleProperty('controller.showCalender');
        },
    },
});

//        willTransition: (transition)->
//            @get('controller.model').every (row) =>
//                if !row.get('isValid') || row.get('isError') || row.get('hasDirtyAttributes')
//                    transition.abort()
//                    @get('dialogs').alert(@get('intl').t('report.alert_unsaved'))
//                    return
//            return true
export default GeneralReportRoute;
