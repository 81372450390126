/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/devices/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerDevicesController;

import Ember from 'ember';

import $ from 'jquery';

AdmintoolCustomerDevicesController = Ember.Controller.extend({
    dialogs: Ember.inject.service(),
    session: Em.inject.service(),
    intl: Em.inject.service(),
    formName: 'timecleandevice',
    queryParams: Em.computed(function () {
        return [
            {
                name: 'customer',
                value: this.get('model.id'),
            },
        ];
    }),
    settings: Ember.computed(function () {
        return {
            form: null,
            defaultValues: {},
        };
    }),
    filters: Em.A([
        Em.Object.create({
            type: 'searchbox',
        }),
    ]),
    init_stuff: function () {
        if (!this.get('model.country')) {
            return;
        }
        this.set('show_tc_popup', false);
        this.set('selected_device', false);
        this.set('adding_new_device', false);
        this.store
            .query('timecleandevice', {
                customer: this.get('model.id'),
                limit: 'all',
                sideload: true,
            })
            .then((tcs) => {
                return this.set('own_devices', tcs.filterBy('customer.id', this.get('model.id')));
            });
        //            @set 'customerless_devices', tcs.filterBy 'customer.id', null
        this.store
            .query('productcampaign', {
                sideload: true,
            })
            .then((pcs) => {
                return this.set('productCampaigns', pcs);
            });
        return this.store
            .query('customerproduct', {
                sideload: true,
            })
            .then((customerproduct) => {
                var rfid_product;
                this.set('customerproducts', customerproduct);
                rfid_product = customerproduct.filter((product) => {
                    if (
                        product.get('name') === 'rfid' &&
                        product.get('countries') === this.get('model.country')
                    ) {
                        return true;
                    }
                });
                return this.set('currentProduct', rfid_product[0]);
            });
    },
    //productCampaigns: Em.computed ->
    //    @get('store').query('productcampaign', {sideload:true})
    priceTypes: Em.computed(function () {
        return [
            {
                id: 'purchase',
                name: this.get('intl').t('timecleandevice.type.purchase'),
            },
            {
                id: 'rental',
                name: this.get('intl').t('timecleandevice.type.rental'),
            },
        ];
    }),
    refreshListAndCloseModal: function () {
        return this.store
            .query('timecleandevice', {
                customer: this.get('model.id'),
                sideload: true,
            })
            .then((tcs) => {
                this.set('own_devices', tcs.filterBy('customer.id', this.get('model.id')));
                //            @set 'customerless_devices', tcs.filterBy 'customer.id', null
                this.set('show_tc_popup', false);
                this.set('selected_device', false);
                return this.set('new_form', false);
            });
    },
    //Em.set(@get('settings'), 'form', null)
    //Em.set(@get('settings'), 'defaultValues', {})
    customerless_devices: Em.computed('searchvalue_delayed', function () {
        return this.store.query('timecleandevice', {
            q: this.get('searchvalue_delayed'),
            customer: '(empty)',
            sideload: true,
        });
    }),
    actions: {
        save_and_add_bill: function (device) {
            return this.get('dialogs')
                .confirm("Are you sure you wan't to connect this device to customer")
                .then((answer) => {
                    var bill;
                    if (!answer) {
                        return;
                    }
                    bill = this.get('settings');
                    device.set('customer', this.get('model'));
                    this.set('recordSaved', 'refreshListAndCloseModal');
                    return device.save().then(() => {
                        bill['defaultValues']['amount'] = 1;
                        bill['defaultValues']['customer'] = this.get('model');
                        bill['defaultValues']['customerproduct'] = this.get('currentProduct');
                        bill['defaultValues']['description'] = 'imei:' + device.get('imei');
                        //                    bill['defaultValues']['price'] = @get('currentProduct').get 'price'
                        // if user has bought device, add one time bill
                        if (device.get('type') === 'purchase') {
                            bill['form'] = 'bill';
                            bill['defaultValues']['campaign'] = this.get('productCampaigns').findBy(
                                'id',
                                this.get('selectedCampaign'),
                            );
                            bill['defaultValues']['country'] = this.get('model.country');
                            bill['defaultValues']['date'] = moment().format('YYYY-MM-DD');
                            bill['defaultValues']['salesman'] = this.store
                                .peekAll('user')
                                .findBy('id', this.get('session.currentUser.id'));
                        } else {
                            bill['form'] = 'rcbill';
                            bill['defaultValues']['startdate'] =
                                moment().add(1, 'months').format('YYYY-MM') + '-01';
                        }
                        this.set('popup_title', this.get('intl').t('admintool.add_bill'));
                        this.set('new_form', true);
                        return this.set('show_tc_popup', false);
                    });
                });
        },
        refreshListAndCloseModal: function () {
            return this.refreshListAndCloseModal();
        },
        removeModal: function () {
            this.set('show_tc_popup', false);
            this.set('selected_device', false);
            this.set('new_form', false);
            $('.modal').modal('hide');
            this.set('show_modal', false);
            return this.set('record', null);
        },
        close: function () {
            this.set('show_tc_popup', false);
            this.set('selected_device', false);
            this.set('new_form', false);
            return this.set('searchvalue', null);
        },
        show_add_popup: function () {
            this.set('show_tc_popup', true);
            this.set('popup_title', this.get('intl').t('admintool.link_device'));
            return false;
        },
        select_device: function (dev) {
            this.set('selected_device', dev);
            return false;
        },
        add_device: function () {
            var form;
            this.set('popup_title', this.get('intl').t('admintool.add_device'));
            form = this.get('settings');
            form['form'] = 'timecleandevice';
            form['defaultValues']['customer'] = this.get('model');
            form['defaultValues']['description'] = null;
            this.set('new_form', true);
            this.set('recordSaved', 'addNewBill');
            return false;
        },
        addNewBill: function (settings, record) {
            var form;
            this.set('new_form', false);
            form = this.get('settings');
            this.set('recordSaved', 'refreshListAndCloseModal');
            Em.set(form, 'defaultValues.amount', 1);
            Em.set(form, 'defaultValues.customer', this.get('model'));
            Em.set(form, 'defaultValues.customerproduct', this.get('currentProduct'));
            Em.set(form, 'defaultValues.description', 'imei:' + record.get('imei'));
            Em.set(form, 'defaultValues.price', this.get('currentProduct').get('price'));
            // if user has bought device, add one time bill
            if (record.get('type') === 'purchase') {
                Em.set(form, 'form', 'bill');
                Em.set(
                    form,
                    'defaultValues.campaign',
                    this.get('productCampaigns').findBy('id', this.get('selectedCampaign')),
                );
                Em.set(form, 'defaultValues.country', this.get('model.country'));
                Em.set(form, 'defaultValues.date', moment().format('YYYY-MM-DD'));
                Em.set(
                    form,
                    'defaultValues.salesman',
                    this.store.peekAll('user').findBy('id', this.get('session.currentUser.id')),
                );
            } else {
                Em.set(form, 'form', 'rcbill');
                Em.set(
                    form,
                    'defaultValues.startdate',
                    moment().add(1, 'months').format('YYYY-MM') + '-01',
                );
            }
            this.set('new_form', true);
            //@refreshListAndCloseModal()
            return false;
        },
        changeCampaign: function (campaign) {
            return this.set('selectedCampaign', campaign);
        },
        save: function () {
            var camp, dev;
            // first add device to customer
            dev = this.get('selected_device');
            dev.set('customer', this.get('model'));
            camp = this.get('productCampaigns').findBy('id', this.get('selectedCampaign'));
            return dev.save().then(() => {
                var rec;
                // next add campaign to this device
                if (camp) {
                    rec = this.store.createRecord('customercampaign', {
                        campaign: camp,
                        customer: this.get('model'),
                    });
                    rec.save();
                    return this.refreshListAndCloseModal();
                } else {
                    return this.refreshListAndCloseModal();
                }
            });
        },
        edit_device: function (device) {
            this.set('record', device);
            this.set('show_modal', true);
            return false;
        },
        checkBilling: function () {
            var billtype;
            if (this.get('record.customer') === null) {
                if (this.get('record.type') === 'rental') {
                    billtype = 'rcbill';
                } else {
                    billtype = 'bill';
                }
                //console.log @get('currentProduct')
                this.get('store')
                    .query(billtype, {
                        customerproduct: this.get('currentProduct.id'),
                        customer: this.get('model.id'),
                    })
                    .then((bills) => {
                        return bills.filter((bill) => {
                            if (bill.get('description').indexOf(this.get('record.imei') > -1)) {
                                return bill.destroyRecord();
                            }
                        });
                    });
            }
            this.refreshListAndCloseModal();
            return this.set('show_modal', false);
        },
        filterChanged: function (value) {
            return this.store
                .query('timecleandevice', {
                    sideload: true,
                    q: value,
                    customer: this.get('model.id'),
                })
                .then((tcs) => {});
        },
        searchChanged: function () {
            return this.set('searchvalue_delayed', this.get('searchvalue'));
        },
    },
});

export default AdmintoolCustomerDevicesController;
