/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/google-map/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import $ from 'jquery';

Component = Ember.Component.extend({
    intl: Ember.inject.service(),
    mapMarkers: Ember.A([]),
    didInsertElement: function () {
        var container, lat, latlng, lng, map, options;
        container = $('.canvas', this.element)[0];
        if (this.get('center')) {
            latlng = this.get('center');
        } else {
            //@setMarker @get 'center'
            lat = 61.084857;
            lng = 26.087036;
            latlng = new window.google.maps.LatLng(lat, lng);
        }
        options = {
            center: latlng,
            zoom: 15,
        };
        map = new window.google.maps.Map(container, options);
        this.set('map', map);
        this.setMarker(latlng);
        if (this.get('markers.length') > 0) {
            return this.setMarkers();
        }
    },
    didUpdateAttrs: function () {
        if (this.get('markers.length') > 0) {
            return this.setMarkers();
        }
    },
    setMarker: function (latlng) {
        if (latlng.accuracy) {
            this.setAccuracyCircle(latlng);
        }
        if (this.get('marker')) {
            return this.get('marker').setPosition(latlng);
        } else {
            return this.set(
                'marker',
                new google.maps.Marker({
                    position: latlng,
                    map: this.get('map'),
                    title: this.get('intl').t('gps.your_location'),
                }),
            );
        }
    },
    setAccuracyCircle: function (latlng) {
        if (this.get('accuracyCircle')) {
            this.get('accuracyCircle').setCenter(latlng);
            return this.get('accuracyCircle').setRadius(latlng.accuracy);
        } else {
            return this.set(
                'accuracyCircle',
                new google.maps.Circle({
                    strokeColor: '#0000ff',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: '#0000ff',
                    fillOpacity: 0.35,
                    map: this.get('map'),
                    center: latlng,
                    radius: latlng.accuracy,
                }),
            );
        }
    },
    setMarkers: function () {
        this.get('mapMarkers').forEach(function (mrk) {
            return mrk.setMap(null);
        });
        return this.get('markers').forEach((marker) => {
            var circle, mrk;
            marker.map = this.get('map');
            mrk = new google.maps.Marker(marker);
            if (marker.position.accuracy) {
                circle = new google.maps.Circle({
                    map: this.get('map'),
                    radius: marker.position.accuracy,
                    fillColor: '#AA0000',
                    fillOpacity: 0.35,
                    strokeColor: '#AA0000',
                    strokeOpacity: 0.8,
                });
                circle.bindTo('center', mrk, 'position');
            }
            return this.get('mapMarkers').pushObject(mrk);
        });
    },
    observeCenter: Ember.observer('center', function () {
        this.get('map').setCenter(this.get('center'));
        return this.setMarker(this.get('center'));
    }),
    actions: {
        test: function () {
            return this.get('map').setCenter({
                lat: 60,
                lng: 26,
            });
        },
    },
});

export default Component;
