/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/history/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsHistoryRoute;

import Ember from 'ember';

import collector from '../../../classes/collector';

OrientationsHistoryRoute = Ember.Route.extend({
    model: function (params) {
        return params;
    },
});

export default OrientationsHistoryRoute;
