/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import config from '../../../config/environment';

import { htmlSafe } from '@ember/string';

UsersIndexController = GeneralReportController.extend({
    flags: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    cc: Ember.inject.service(),
    error: Ember.inject.service(),
    form: 'user',
    showColumns: [
        'lastname',
        'firstname',
        'username',
        'taxnumber',
        'id06kort',
        'employer',
        'userlevel',
        'password',
        'email',
        'phone',
    ],
    queryParams: ['page', 'employer', 'q', 'group', 'userlevel'],
    userStatuses: ['new', 'ok', 'blocked'],
    employer: null,
    group: null,
    userlevel: null,
    filters: Em.A([
        Em.Object.create({
            field: 'employer',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    settings: Em.Object.create({
        rowActions: [],
        massedit: true,
        sortByColumn: 'lastname',
    }),
    showUserSmsModal: false,
    showUserEmailModal: false,
    userSmsValidPhones: Em.A([]),
    userSmsNotValidPhones: Em.A([]),
    userEmailValid: Em.A([]),
    userEmailNotValid: Em.A([]),
    showId06Checker: false,
    userform: 'worktime',
    init_functions: function () {
        var c;
        if (
            !this.get('flags').test('form-report-users') &&
            !this.get('flags').test('ember-movenium-form-report-users')
        ) {
            this._super();
        }
        c = this.get('Collector');
        if (c.fieldExists('worktime', 'user')) {
            this.set('userform', 'worktime');
        } else if (c.fieldExists('presence', 'user')) {
            this.set('userform', 'presence');
        }
        return this.addRowActions();
    },
    addRowActions: function () {
        var c, fields, intl, self2;
        self2 = this;
        intl = this.get('intl');
        c = this.get('Collector');
        if (!c) {
            return;
        }
        fields = c.fields('user');
        if (fields) {
            if ('status' in fields) {
                this.set('settings.rowActions', [
                    {
                        text: 'status.new_text',
                        action: function (self, items) {
                            return items.forEach(function (row) {
                                if (row.get('status') === 'new') {
                                    return;
                                }
                                row.set('status', 'new');
                                return row.save();
                            });
                        },
                    },
                    {
                        text: 'status.ok_text',
                        action: function (self, items) {
                            return items.forEach(function (row) {
                                if (row.get('status') === 'ok') {
                                    return;
                                }
                                row.set('status', 'ok');
                                return row.save();
                            });
                        },
                    },
                    {
                        text: 'status.blocked_text',
                        action: function (self, items) {
                            return items.forEach(function (row) {
                                if (row.get('status') === 'blocked') {
                                    return;
                                }
                                row.set('status', 'blocked');
                                return row.save();
                            });
                        },
                    },
                ]);
            }
        }
        this.get('settings.rowActions').push({
            text: this.get('intl').t('user.action.sendsms'),
            action: (self, users) => {
                this.set('userSmsModalTitle', htmlSafe(intl.t('user.action.sendsms.modal_title')));
                this.set('userSmsModalText', htmlSafe(intl.t('user.action.sendsms.modal_text')));
                this.set('showUserSmsModal', true);
                return this.set('smsUsers', users);
            },
        });
        this.get('settings.rowActions').push({
            text: this.get('intl').t('user.action.sendemail'),
            action: (self, users) => {
                this.set('userEmailModalTitle', intl.t('user.action.email.modal_title'));
                this.set('userEmailModalText', htmlSafe(intl.t('user.action.email.modal_text')));
                this.set('showUserEmailModal', true);
                return this.set('emailUsers', users);
            },
        });
        if (c.fieldExists('employer', 'tv_status') && c.fieldExists('user', 'image')) {
            this.get('settings.rowActions').push({
                text: this.get('intl').t('user.action.fetch_tv_image'),
                action: (self, users) => {
                    return users.forEach((user) => {
                        var employer, promise, taxnumber;
                        taxnumber = user.get('taxnumber');
                        employer = user.get('employer');
                        if (!taxnumber || !employer) {
                            return;
                        }
                        promise = new Ember.RSVP.Promise((resolve, reject) => {
                            if (!user.get('employer.emp_id')) {
                                return this.store
                                    .findRecord('employer', user.get('employer.id'), {
                                        reload: true,
                                    })
                                    .then(
                                        function (employer) {
                                            return resolve(employer);
                                        },
                                        function (e) {
                                            return reject(e);
                                        },
                                    );
                            } else {
                                return resolve(user.get('employer'));
                            }
                        });
                        return promise.then(
                            (employer) => {
                                var emp_id;
                                emp_id = employer.get('emp_id');
                                if (!taxnumber || !emp_id) {
                                    return;
                                }
                                return $.ajax({
                                    url: this.get('cc').host + config.APP.CC_TVCARDIMAGE,
                                    data: {
                                        taxnumber: taxnumber,
                                        emp_id: emp_id,
                                    },
                                    success: function (data) {
                                        if (data && data != null && !data.error) {
                                            user.set('image', 'data:image/png;base64,' + data);
                                            return user.save();
                                        } else {
                                            return console.log(data);
                                        }
                                    },
                                    fail: function (e) {
                                        return console.log(e);
                                    },
                                });
                            },
                            function (e) {
                                return console.log(e);
                            },
                        );
                    });
                },
            });
        }
        this.addRowActionSetUserPrivileges();
        if (c.testNeeds(['user.taxnumber', 'employer.tv_status'])) {
            this.addRowActionCheckWithTaxnumber();
        }
        return this.addRowActionCheckID06();
    },
    sendUserSms: function (users) {
        var c, intl, promises;
        intl = this.get('intl');
        promises = [];
        c = this.get('Collector');
        users.forEach((user) => {
            //send only to users without secured password
            if (user.get('password') !== 'secured') {
                if (
                    !/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[^0]\d{1,14}$/i.test(
                        user.get('phone'),
                    )
                ) {
                    return this.get('userSmsNotValidPhones').pushObject(user);
                } else {
                    return this.get('userSmsValidPhones').pushObject(user);
                }
            }
        });
        this.set('userSmsModalTitle', intl.t('user.action.sendsms.modal_title'));
        //if nothing selected
        if (
            this.get('userSmsValidPhones.length') === 0 &&
            this.get('userSmsNotValidPhones.length') === 0
        ) {
            this.set('userSmsModalText', intl.t('user.action.sendsms.nothing_found'));
            //                @set("showUserSmsModal", true)
            return promises;
        }
        //if only not valid phones
        if (this.get('userSmsValidPhones.length') === 0) {
            this.set('userSmsModalSent', true);
            //                @set("showUserSmsModal", true)
            return promises;
        }
        //send sms to valid phone numbers
        this.get('userSmsValidPhones').forEach((user) => {
            promises.push(
                c.ttapi({
                    url: 'sendSms',
                    data: {
                        phone: user.get('phone'),
                        messageid: 'new_user_added',
                        values: {
                            userid: user.get('id'),
                            product: config.brand === 'visma' ? 'Visma Entry' : 'Visma Movenium',
                        },
                        language: moment.locale(),
                    },
                    method: 'post',
                }),
            );
            return this.set('userSmsModalSent', true);
        });
        return promises;
    },
    //            self2.set "showUserSmsModal", true
    sendUserEmail: function (users) {
        var c, intl, promises;
        intl = this.get('intl');
        promises = [];
        c = this.get('Collector');
        users.forEach((user) => {
            //send only to users without secured password
            if (user.get('password') !== 'secured') {
                if (
                    !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
                        user.get('email'),
                    )
                ) {
                    return this.get('userEmailNotValid').push(user);
                } else {
                    return this.get('userEmailValid').push(user);
                }
            }
        });
        this.set('userEmailModalTitle', intl.t('user.action.email.modal_title'));
        //if nothing selected
        if (this.get('userEmailValid.length') === 0 && this.get('userEmailNotValid.length') === 0) {
            this.set('userEmailModalText', intl.t('user.action.email.nothing_found'));
            this.set('showUserEmailModal', true);
            return promises;
        }
        //if only not valid emails
        if (this.get('userEmailValid.length') === 0) {
            this.set('userEmailModalSent', true);
            this.set('showUserEmailModal', true);
            return promises;
        }
        //send credentials to valid email
        this.get('userEmailValid').forEach((user) => {
            var content;
            content =
                this.get('intl').t('user.action.email.email_content', {
                    company: this.get('session.currentUser.company'),
                    username: user.get('username'),
                    psw: user.get('password'),
                    url: window.location.origin,
                }) +
                '<br><br>' +
                this.get('intl').t('user.action.email.email_username') +
                ': ' +
                user.get('username') +
                '<br>' +
                this.get('intl').t('user.action.email.email_password') +
                ': ' +
                user.get('password') +
                '<br>';
            promises.push(
                c.ttapi({
                    url: 'sendEmail',
                    data: {
                        email: user.get('email'),
                        title:
                            this.get('session.currentUser.company') +
                            ' - ' +
                            this.get('intl').t('user.action.email.email_title'),
                        content: content.replace(/<br ?\/?>/g, '\n'),
                    },
                    method: 'post',
                }),
            );
            return this.set('userEmailModalSent', true);
        });
        return promises;
    },
    selectProjectsModalTitle: Em.computed(function () {
        return this.get('intl').t('user.action.invite.title');
    }),
    selectedProject: null,
    sendInvitationTo: null,
    addRowActionSetUserPrivileges: function () {
        if (!this.get('Collector').testNeeds(['presence'])) {
            return;
        }
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('user.action.invite'),
            action: (self, users) => {
                this.set('sendInvitationTo', users);
                return this.set('showSelectProjects', true);
            },
        });
    },
    addRowActionCheckWithTaxnumber: function () {
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('user.action.tvcheck'),
            action: (self, users) => {
                var promises;
                this.set('showLoader', true);
                this.set('showTvcardChecker', true);
                promises = [];
                users.forEach((user) => {
                    return promises.push(this.setUserInfoFromTilaajavastuu(user));
                });
                return Ember.RSVP.Promise.all(promises).then((changes) => {
                    this.set(
                        'tvcardChanges',
                        changes.filter((item) => {
                            return item !== null && Object.keys(item)[0] === 'user';
                        }),
                    );
                    this.set(
                        'notFoundUsers',
                        changes.filter((item) => {
                            return item !== null && Object.keys(item)[0] === 'not_found_user';
                        }),
                    );
                    return this.set('showLoader', false);
                });
            },
        });
    },
    setUserInfoFromTilaajavastuu: function (user) {
        var c;
        c = this.get('Collector');
        return new Ember.RSVP.Promise((resolve, reject) => {
            var promise;
            if (!user.get('taxnumber')) {
                return resolve(null);
            }
            promise = c.ccapi({
                url: 'tvpersonapi',
                data: {
                    country: 'fi',
                    'id-country': 'fi',
                    'id-type': 'tax_number',
                    'id-value': user.get('taxnumber'),
                },
            });
            return promise.then((back) => {
                var empId;
                if (back.message === 'no person found') {
                    return resolve({
                        not_found_user: user,
                    });
                }
                if (back.length < 1) {
                    return resolve(null);
                }
                empId = user.get('employer.id');
                return this.get('store')
                    .query('employer', {
                        emp_id: back.employers[0].gov_org_ids[0].gov_org_id,
                    })
                    .then((employers) => {
                        if (back.date_of_birth) {
                            user.set('birthdate', back.date_of_birth);
                        }
                        if (back.names[0].given_names[0]) {
                            user.set('firstname', back.names[0].given_names[0]);
                        }
                        if (back.names[0].surnames[0]) {
                            user.set('lastname', back.names[0].surnames[0]);
                        }
                        if (back.residences && back.residences[0]) {
                            user.set('homecountry', back.residences[0].toLowerCase());
                        }
                        if (employers.get('firstObject')) {
                            // user.set("phone", back[0].phone) if back[0].phone
                            user.set('employer', employers.get('firstObject'));
                        }
                        if (back.employers.length > 1) {
                            user.set('status', 'check');
                        }
                        if (user.get('hasDirtyAttributes') || user.get('employer.id') !== empId) {
                            return user.save().then(() => {
                                return resolve({
                                    user: user,
                                });
                            });
                        } else {
                            return resolve(null);
                        }
                    });
            });
        });
    },
    addRowActionCheckID06: function () {
        var c;
        if (!this.get('Collector').testNeeds(['modules.id06'])) {
            return;
        }
        c = this.get('Collector');
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('user.action.id06check'),
            action: (self, users) => {
                this.set('showLoader', true);
                this.set('showId06Checker', true);
                // first we need to get all the employers over the api if not found from store already
                return this.fetchAllNeededEmployers(users).then(() => {
                    var changes, promises;
                    promises = [];
                    changes = [];
                    users.forEach((user) => {
                        var promise;
                        promise = c.ccapi({
                            url: 'id06cardstatus',
                            data: {
                                id: user.get('id06kort'),
                            },
                        });
                        promise.then((back) => {
                            var status;
                            status = JSON.parse(back).status_code === '205' ? 'ok' : 'blocked';
                            console.info(
                                'status for',
                                user.get('personid'),
                                status,
                                'status was',
                                user.get('status'),
                            );
                            if (status === 'ok' && user.get('status') !== 'ok') {
                                console.info(
                                    'change status of user',
                                    user.get('personid'),
                                    ':',
                                    user.get('status'),
                                    '->',
                                    'ok',
                                );
                                changes.push({
                                    user: user,
                                    from_class: 'label-m-' + user.get('status'),
                                    from_text: this.get('intl').t(
                                        'status.' + user.get('status') + '_text',
                                    ),
                                    to_class: 'label-m-ok',
                                    to_text: this.get('intl').t('status.ok_text'),
                                    reason_code: JSON.parse(back).reason_code,
                                });
                                user.set('status', 'ok');
                                return promises.push(user.save());
                            } else if (status !== 'ok' && user.get('status') !== 'blocked') {
                                console.info(
                                    'change status of user',
                                    user.get('personid'),
                                    ':',
                                    user.get('status'),
                                    '->',
                                    'blocked',
                                );
                                changes.push({
                                    user: user,
                                    from_class: 'label-m-' + user.get('status'),
                                    from_text: this.get('intl').t(
                                        'status.' + user.get('status') + '_text',
                                    ),
                                    to_class: 'label-m-blocked',
                                    to_text: this.get('intl').t('status.blocked_text'),
                                    reason_code: JSON.parse(back).reason_code,
                                });
                                user.set('status', 'blocked');
                                return promises.push(user.save());
                            }
                        });
                        return promises.push(promise);
                    });
                    return Ember.RSVP.Promise.all(promises).then(() => {
                        this.set('id06StatusChanges', changes);
                        return this.set('showLoader', false);
                    });
                });
            },
        });
    },
    fetchAllNeededEmployers: function (users) {
        return new Ember.RSVP.Promise((resolve) => {
            var need_to_be_fetched;
            need_to_be_fetched = [];
            users.forEach((user) => {
                if (!user.get('employer.emp_id') || !user.get('employer.country')) {
                    return need_to_be_fetched.push(user.get('employer.id'));
                }
            });
            if (need_to_be_fetched.length === 0) {
                resolve();
                return;
            }
            return this.get('store')
                .query('employer', {
                    id: need_to_be_fetched.join(','),
                })
                .then(function () {
                    return resolve();
                });
        });
    },
    projectsmultifield: Em.computed(function () {
        return Ember.Object.create({
            type: 'multiselect',
            features: {},
            form: this.get('userform'),
            name: 'project',
            options: {
                fields: ['name'],
            },
            ext: 'project',
        });
    }),
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        transitionToEditRoute: function (row) {
            return this.transitionToRoute('users.edit', row);
        },
        statusAction: function (status, items) {
            return items.forEach(function (row) {
                if (row.get('status') === status) {
                    return;
                }
                row.set('status', status);
                return row.save();
            });
        },
        openSmsModal: function (users) {
            this.set(
                'userSmsModalTitle',
                htmlSafe(this.get('intl').t('user.action.sendsms.modal_title')),
            );
            this.set(
                'userSmsModalText',
                htmlSafe(this.get('intl').t('user.action.sendsms.modal_text')),
            );
            this.set('showUserSmsModal', true);
            return this.set('smsUsers', users);
        },
        openEmailModal: function (users) {
            this.set('userEmailModalTitle', this.get('intl').t('user.action.email.modal_title'));
            this.set(
                'userEmailModalText',
                htmlSafe(this.get('intl').t('user.action.email.modal_text')),
            );
            this.set('showUserEmailModal', true);
            return this.set('emailUsers', users);
        },
        fetchImagesFromTv: function (users) {
            return users.forEach((user) => {
                var employer, promise, taxnumber;
                taxnumber = user.get('taxnumber');
                employer = user.get('employer');
                if (!taxnumber || !employer) {
                    return;
                }
                promise = new Ember.RSVP.Promise((resolve, reject) => {
                    if (!user.get('employer.emp_id')) {
                        return this.store
                            .findRecord('employer', user.get('employer.id'), {
                                reload: true,
                            })
                            .then(
                                function (employer) {
                                    return resolve(employer);
                                },
                                function (e) {
                                    return reject(e);
                                },
                            );
                    } else {
                        return resolve(user.get('employer'));
                    }
                });
                promise.then(
                    (employer) => {
                        var emp_id;
                        emp_id = employer.get('emp_id');
                        if (!taxnumber || !emp_id) {
                            return;
                        }
                        this.set('loading', true);
                        return $.ajax({
                            url: this.get('cc').host + config.APP.CC_TVCARDIMAGE,
                            data: {
                                taxnumber: taxnumber,
                                emp_id: emp_id,
                            },
                            success: (data) => {
                                if (data && data != null && !data.error) {
                                    user.set('image', 'data:image/png;base64,' + data);
                                    user.set('validation', 'off');
                                    user.save();
                                } else {
                                    this.error.notify(data.error);
                                }
                                return this.set('loading', false);
                            },
                            fail: (e) => {
                                this.error.notify(e);
                                return this.set('loading', false);
                            },
                        });
                    },
                    (e) => {
                        return this.error.notify(e);
                    },
                );
                return {
                    finally: () => {
                        return this.set('loading', false);
                    },
                };
            });
        },
        verifyUsersTaxnumbers: function (users) {
            var promises;
            this.set('showLoader', true);
            this.set('showTvcardChecker', true);
            promises = [];
            users.forEach((user) => {
                return promises.push(this.setUserInfoFromTilaajavastuu(user));
            });
            return Ember.RSVP.Promise.all(promises).then((changes) => {
                this.set(
                    'tvcardChanges',
                    changes.filter((item) => {
                        return item !== null && Object.keys(item)[0] === 'user';
                    }),
                );
                this.set(
                    'notFoundUsers',
                    changes.filter((item) => {
                        return item !== null && Object.keys(item)[0] === 'not_found_user';
                    }),
                );
                return this.set('showLoader', false);
            });
        },
        id06CheckAction: function (users) {
            var c;
            this.set('showLoader', true);
            this.set('showId06Checker', true);
            c = this.get('Collector');
            // first we need to get all the employers over the api if not found from store already
            return this.fetchAllNeededEmployers(users).then(() => {
                var changes, promises;
                promises = [];
                changes = [];
                users.forEach((user) => {
                    // If user has no card, add error to be displayed and skip check.
                    // The error is "bad request" due to missing card info.
                    if (!user.get('id06kort')) {
                        changes.push({
                            user: user,
                            error: this.get('intl').t('user.add.id06.card_status.bad_request'),
                        });
                        return;
                    }

                    var promise;
                    promise = c.ccapi({
                        url: 'id06cardstatus',
                        data: {
                            id: user.get('id06kort'),
                        },
                    });
                    promise.then((back) => {
                        var status, reasonCode;

                        // If an ok status code isn't returned, set user's status to blocked.
                        // Here we also catch a malformed response; in this case
                        // the user will also be blocked.
                        try {
                            status = JSON.parse(back).status_code === '205' ? 'ok' : 'blocked';
                            reasonCode = JSON.parse(back).reason_code;
                        } catch (error) {
                            console.error(error);
                            status = 'blocked';
                            reasonCode = '300'; // Code 300 - delisted. Applicable enough
                        }

                        console.info(
                            'status for',
                            user.get('personid'),
                            status,
                            'status was',
                            user.get('status'),
                        );
                        if (status === 'ok' && user.get('status') !== 'ok') {
                            console.info(
                                'change status of user',
                                user.get('personid'),
                                ':',
                                user.get('status'),
                                '->',
                                'ok',
                            );
                            changes.push({
                                user: user,
                                from_class: 'label-m-' + user.get('status'),
                                from_text: this.get('intl').t(
                                    'status.' + user.get('status') + '_text',
                                ),
                                to_class: 'label-m-ok',
                                to_text: this.get('intl').t('status.ok_text'),
                                reason_code: reasonCode,
                            });
                            user.set('status', 'ok');
                            return promises.push(user.save());
                        } else if (status !== 'ok' && user.get('status') !== 'blocked') {
                            console.info(
                                'change status of user',
                                user.get('personid'),
                                ':',
                                user.get('status'),
                                '->',
                                'blocked',
                            );
                            changes.push({
                                user: user,
                                from_class: 'label-m-' + user.get('status'),
                                from_text: this.get('intl').t(
                                    'status.' + user.get('status') + '_text',
                                ),
                                to_class: 'label-m-blocked',
                                to_text: this.get('intl').t('status.blocked_text'),
                                reason_code: reasonCode,
                            });
                            user.set('status', 'blocked');
                            return promises.push(user.save());
                        }
                    });
                    return promises.push(promise);
                });
                return Ember.RSVP.Promise.all(promises).then(() => {
                    this.set('id06StatusChanges', changes);
                    return this.set('showLoader', false);
                });
            });
        },
        removeSmsModal: function () {
            this.set('userSmsModalText', null);
            this.set('showUserSmsModal', false);
            this.set('userSmsModalSent', false);
            this.set('userSmsValidPhones', Em.A([]));
            this.set('userSmsNotValidPhones', Em.A([]));
            return this.set('smsUsers', false);
        },
        removeEmailModal: function () {
            this.set('userEmailModalText', null);
            this.set('showUserEmailModal', false);
            this.set('userEmailModalSent', false);
            this.set('userEmailValid', Em.A([]));
            this.set('userEmailNotValid', Em.A([]));
            return this.set('emailUsers', false);
        },
        removeSelectProjectsModal: function () {
            this.set('inviteSendDone', false);
            this.set('showLoader', false);
            this.set('selectedProject', null);
            return this.set('showSelectProjects', false);
        },
        removeId06CheckerModal: function () {
            this.set('checkUsersfromId06', Em.A([]));
            this.set('showLoader', false);
            this.set('showId06Checker', false);
            return this.set('id06StatusChanges', Em.A([]));
        },
        removeTvcardCheckerModal: function () {
            this.set('showLoader', false);
            this.set('showTvcardChecker', false);
            return this.set('tvcardChanges', Em.A([]));
        },
        sendUserSms: function () {
            var promises;
            this.set('showLoader', true);
            if (this.get('sendToAllUsers')) {
                return this.get('dialogs')
                    .confirm(this.get('intl').t('users.sms.send_all.confirmation'))
                    .then((con) => {
                        if (con) {
                            return this.get('store')
                                .query('user', {
                                    limit: 'all',
                                })
                                .then((users) => {
                                    var promises;
                                    promises = this.sendUserSms(users);
                                    return Ember.RSVP.Promise.all(promises).then(() => {
                                        this.set('sendToAllUsers', false);
                                        return this.set('showLoader', false);
                                    });
                                });
                        } else {
                            this.set('showLoader', false);
                            return this.send('removeSmsModal');
                        }
                    });
            } else {
                promises = this.sendUserSms(this.get('smsUsers'));
                console.log(promises);
                return Ember.RSVP.Promise.all(promises).then(() => {
                    return this.set('showLoader', false);
                });
            }
        },
        sendUserEmail: function () {
            var promises;
            this.set('showLoader', true);
            if (this.get('sendToAllUsers')) {
                return this.get('store')
                    .query('user', {
                        limit: 'all',
                    })
                    .then((users) => {
                        var promises;
                        promises = this.sendUserEmail(users);
                        return Ember.RSVP.Promise.all(promises).then(() => {
                            this.set('sendToAllUsers', false);
                            return this.set('showLoader', false);
                        });
                    });
            } else {
                promises = this.sendUserEmail(this.get('emailUsers'));
                return Ember.RSVP.Promise.all(promises).then(() => {
                    return this.set('showLoader', false);
                });
            }
        },
        inviteNow: function () {
            var projects, promises, to;
            this.set('showLoader', true);
            to = this.get('sendInvitationTo');
            projects = this.get('selectedProject');
            promises = [];
            projects.forEach((projectid) => {
                return this.get('store')
                    .find('project', projectid)
                    .then((project_info) => {
                        return to.forEach((user) => {
                            return promises.push(
                                this.get('Collector')
                                    .ttapi({
                                        url: 'get_linker',
                                        data: {
                                            rowid: user.get('id'),
                                            form: 'project',
                                        },
                                        method: 'get',
                                    })
                                    .then((data) => {
                                        if (data['linker']) {
                                            promises.push(
                                                this.get('Collector').ajax({
                                                    url: 'users/' + user.get('id'),
                                                    method: 'put',
                                                    data: JSON.stringify({
                                                        user: {
                                                            'link.projects': {
                                                                add: [projectid],
                                                            },
                                                        },
                                                    }),
                                                }),
                                            );
                                            return promises.push(
                                                this.get('Collector').ttapi({
                                                    url: 'addmysite/' + user.get('id'),
                                                    method: 'post',
                                                    data: {
                                                        id: project_info.get('id'),
                                                        name: project_info.get('name'),
                                                        image: project_info.get('image'),
                                                        company: this.get(
                                                            'session.currentUser.company',
                                                        ),
                                                        used: moment()
                                                            .subtract(2, 'days')
                                                            .format('YYYY-MM-DD HH:mm:ss'),
                                                    },
                                                }),
                                            );
                                        }
                                    }),
                            );
                        });
                    });
            });
            return Em.RSVP.Promise.all(promises).then(() => {
                this.set('showLoader', false);
                return this.set('inviteSendDone', true);
            });
        },
        changeSelectedProject: function (project) {
            return this.set('selectedProject', project);
        },
        setInviteUser: function (users) {
            this.set('sendInvitationTo', users);
            return this.set('showSelectProjects', true);
        },
    },
});

//    formatter: (column, value) ->

//        if column == "password"
//            if value == "secured"
//                return new Ember.String.htmlSafe '<span class="label-m label-m-approved">'+value.toUpperCase()+'</span>'
//            else
//                return new Ember.String.htmlSafe '<span class="label-m label-m-open">'+value+'</span>'
//        return @_super column, value
export default UsersIndexController;
