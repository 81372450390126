/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/route.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

AdmintoolCustomerRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    model: function (params) {
        return this.get('store').findRecord('customer', params.id, {
            include: true,
        });
    },
    beforeModel: function () {
        return this.transitionTo('admintool.customer.overview');
    },
});

export default AdmintoolCustomerRoute;
