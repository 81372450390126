import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import GeneralReportRoute from '../../../routes/general-report';

const MachinesTransactionsHistoryRoute = GeneralReportRoute.extend({
    store: service(),
    flags: service(),

    queryParams: {
        project: {
            refreshModel: true,
        },
        tooltype: {
            refreshModel: true,
        },
    },

    form: 'transaction',
    model(params) {
        params = this._super(params);

        if (params.page) {
            delete params.page;
        }
        return set(this.controllerFor('machines.transactions-history'), 'qParams', params);
    },
});

export default MachinesTransactionsHistoryRoute;
