/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/user-select.coffee.old
// Generated by CoffeeScript 2.6.1
var UserSelect;

import Ember from 'ember';

UserSelect = Ember.Component.extend({
    users: null,
    currentUser: Ember.computed('users.[]', 'user', 'filterEmployer', function () {
        var filterEmployer, first, found, last, next, previous, temp, userid, users;
        users = this.get('users');
        if (!users) {
            return;
        }
        filterEmployer = this.get('filterEmployer');
        if (filterEmployer) {
            users = users.filter(function (user) {
                return user.get('employer.id') === filterEmployer;
            });
        }
        userid = this.get('user');
        last = temp = next = previous = null;
        found = false;
        if (!userid) {
            first = users.get('firstObject');
            if (!first) {
                return;
            }
            userid = first.get('id');
        }
        users.forEach(function (user) {
            if (found && !next) {
                next = user;
            }
            if (user.get('id') === userid) {
                found = true;
                temp = user;
                previous = last;
            }
            return (last = user);
        });
        //        unless found
        //            @set 'user', null
        // Todo should be written as computeds
        Ember.run.next(this, function () {
            if (this.get('isDestroyed')) {
                return;
            }
            this.set('nextUser', next);
            return this.set('previousUser', previous);
        });
        return temp;
    }),
    nextUser: null,
    previousUser: null,
    actions: {
        gotoNextUser: function () {
            //@set 'page', 1
            return this.set('user', this.get('nextUser.id'));
        },
        gotoPreviousUser: function () {
            //@set 'page', 1
            return this.set('user', this.get('previousUser.id'));
        },
    },
});

export default UserSelect;
