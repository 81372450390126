import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { computed, get, set } from '@ember/object';

export default Service.extend({
    tour: service(),
    intl: service(),
    gui: service(),
    collector: service('collector-service'),
    localStorageWrapper: service(),
    screenLimit: 768,

    isMobile: computed(function () {
        if (window.innerWidth >= get(this, 'screenLimit')) {
            return false;
        } else {
            return true;
        }
    }),

    plusButton: computed(function () {
        return window.innerWidth >= this.screenLimit ? '#add-menu-desktop' : '#add-menu-button';
    }),

    async start() {
        // skip this if mobile
        const hasRequiredElements =
            document.querySelector(this.plusButton) !== null &&
            document.querySelector('.hamburger-link') !== null;
        if (
            hasRequiredElements &&
            !get(this, 'isMobile') &&
            !this.collector.testNeedsOne('products.mini_entry')
        ) {
            await this.createTourSteps();
            this.tour.start();
            this.localStorageWrapper.setUserSetting('tourShown', true);
            return document.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    return this.tour.next();
                } else if (e.keyCode === 27) {
                    return this.tour.cancel();
                }
            });
        } else if (get(this, 'isMobile')) {
            return set(this.gui, 'mobileWelcomeTour', true);
        }
    },

    async createTourSteps() {
        set(this.tour, 'defaultStepOptions', {
            scrollTo: false,
            showCancelLink: true,
        });
        let steps = [];
        steps.push({
            id: 'plus',
            attachTo: { element: this.plusButton, on: 'bottom' },
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: this.intl.t('tour.exit'),
                    type: 'cancel',
                },
                {
                    classes: 'shepherd-button-primary',
                    text: this.intl.t('tour.next'),
                    type: 'next',
                },
            ],
            classes: 'shepherd-plus-button',
            highlightClass: 'highlight',
            scrollTo: true,
            title: this.intl.t('tour.headers.first_time'),
            text: this.intl.t('tour.steps.plus'),
        });
        if (window.innerWidth >= get(this, 'screenLimit')) {
            steps.push({
                id: 'left-menu',
                attachTo: { element: '.sidebar', on: 'right' },
                buttons: [
                    {
                        classes: 'shepherd-button-secondary',
                        text: this.intl.t('tour.exit'),
                        type: 'cancel',
                    },
                    {
                        classes: 'shepherd-button-primary',
                        text: this.intl.t('tour.back'),
                        type: 'back',
                    },
                    {
                        classes: 'shepherd-button-primary',
                        text: this.intl.t('tour.next'),
                        type: 'next',
                    },
                ],
                classes: 'shepherd-side-menu',
                highlightClass: 'highlight',
                showCancelLink: true,
                title: this.intl.t('tour.headers.first_time'),
                text: this.intl.t('tour.steps.left_menu'),
            });
        }
        steps.push({
            id: 'hamburger',
            attachTo: { element: '.hamburger-link span', on: 'right' },
            buttons: [
                {
                    classes: 'shepherd-button-primary',
                    text: this.intl.t('tour.exit'),
                    type: 'cancel',
                },
                {
                    classes: 'shepherd-button-secondary',
                    text: this.intl.t('tour.back'),
                    type: 'back',
                },
            ],
            classes: 'shepherd-hamburger-menu',
            copyStyles: false,
            highlightClass: 'highlight',
            showCancelLink: true,
            title: this.intl.t('tour.headers.first_time'),
            text: this.intl.t('tour.steps.hamburger'),
        });
        set(this.tour, 'modal', true);
        return this.tour.addSteps(steps);
    },
});
