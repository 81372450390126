/*eslint-disable ember/named-functions-in-promises */
import { inject as service } from '@ember/service';

import Route from '@ember/routing/route';

const AbcensesAddRoute = Route.extend({
    wtService: service(),

    beforeModel() {
        return this.wtService.unlessOpenWt().catch(() => {
            return this.wtService.showOpenWtError();
        });
    },
});

export default AbcensesAddRoute;
