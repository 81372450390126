/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

TaxmanTaxreportRoute = Ember.Route.extend(AuthenticatedRouteMixin);

export default TaxmanTaxreportRoute;
