/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/tasks/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditTasksController;

import Ember from 'ember';

import collector from '../../../../classes/collector';

ProjectsEditTasksController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    form: 'task',
    show_linker_modal: false,
    hide_linker: false,
    actions: {
        modalRecordSaved: function (settings, record) {
            var c, id;
            this.set('hide_linker', true);
            id = this.get('model.record.id');
            c = this.get('Collector');
            return c
                .getLinks(id, this.get('model.record.constructor.modelName'), 'task')
                .then((tmp_links) => {
                    var all_links, i, len, link, links;
                    links = tmp_links['tasks'];
                    all_links = [];
                    all_links.push(record.id);
                    for (i = 0, len = links.length; i < len; i++) {
                        link = links[i];
                        all_links.push(link['id']);
                    }
                    return c
                        .saveLinks(
                            id,
                            this.get('model.record.constructor.modelName'),
                            'task',
                            all_links,
                        )
                        .then((links) => {
                            this.set('hide_linker', false);
                            this.set('show_linker_modal', false);
                            return false;
                        });
                });
        },
        removeModal: function () {
            return this.set('show_linker_modal', false);
        },
        openDialog: function () {
            this.set('show_linker_modal', true);
            return false;
        },
    },
});

export default ProjectsEditTasksController;
