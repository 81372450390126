import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';
import EmberObject, { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Controller.extend({
    ttapi: service(),

    queryParams: ['date', 'user'],
    user: null,
    tests: null,
    tolerance: 15,
    anomalies: alias('model'),

    filters: computed(function () {
        return A([
            EmberObject.create({
                field: 'date',
                type: 'date',
                form: 'worktime',
                value: get(this, 'date'),
            }),
            EmberObject.create({
                field: 'user',
                type: 'database',
                form: 'worktime',
                value: get(this, 'user'),
            }),
        ]);
    }),

    actions: {
        filterChanged(value, field) {
            set(this, field, value);
        },

        showAddModal(type, day, user) {
            set(this, 'type', type);
            let params = {};

            if (type === 'abcense') {
                params.startdate = day;
                params.enddate = day;
            } else {
                params.date = day;
            }

            if (user) {
                params.user = get(this, 'store').peekRecord('user', user);
            }

            get(this, 'store').createRecord(type, params);

            this.transitionToRoute({ queryParams: { modal: [type, 'new'] } });
        },

        showEditModal(type, rows) {
            let row = get(rows, 'firstObject');
            let params = [type, get(row, 'id')];
            this.transitionToRoute({ queryParams: { modal: params } });
        },

        updateTests(tests) {
            //need to convert over from emberobject to normal json
            tests = JSON.parse(JSON.stringify(tests));

            new Promise((resolve) => {
                let params = {};

                params['date'] = get(this, 'date');
                params['user'] = get(this, 'user');
                params['page'] = get(this, 'page');
                params['anomalies'] = tests;
                get(this, 'ttapi')
                    ._post('/api/anomalyReport', params)
                    .then(this._reloadModel.bind(this)) //eslint: Use named functions defined on objects to handle promises
                    .then(resolve());
            });
        },

        toggleSettingsPanel() {
            this.toggleProperty('showSettings');
        },
    },

    _reloadModel(anomalies) {
        set(this, 'model', anomalies);
    },

    date:
        moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD') +
        '_' +
        moment().format('YYYY-MM-DD'),
});
