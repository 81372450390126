/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/add-file/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditAddFileRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

ProjectsEditAddFileRoute = GeneralAddRoute.extend({
    modelName: 'projectfile',
    afterSave: Ember.computed('form', function () {
        return {
            //route: "forms.form-report"
            route: 'projects.edit.project-files',
            params: this.modelFor('projects.edit').get('id'),
        };
    }),
    model: function (params) {
        var project;
        project = this.modelFor('projects.edit');
        return this.store.createRecord('projectfile', {
            project: project,
        });
    },
});

export default ProjectsEditAddFileRoute;
