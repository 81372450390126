/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsReportController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import $ from 'jquery';

ProjectsReportController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    queryParams: ['project', 'date'],
    date: moment().subtract(1, 'month').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    columns: Em.A([
        Em.Object.create({
            field: 'user',
            form: 'worktime',
        }),
        Em.Object.create({
            field: 'project',
            form: 'worktime',
        }),
        Em.Object.create({
            field: 'work_hours',
            form: 'worktime',
        }),
        Em.Object.create({
            field: 'absence_hours',
            form: 'abcense',
        }),
    ]),
    summableFields: {
        worktime: 'work_hours',
        abcense: 'absence_hours',
    },
    hasRequiredFilters: Em.computed('project', function () {
        return !!this.get('project');
    }),
    filters: Em.computed(function () {
        var params, rangesPromise;
        rangesPromise = $.Deferred();
        if (this.get('Collector').formExists('wage_period')) {
            params = {
                limit: 20,
                order: 'period_end_date-,period_start_date-',
                period_start_date:
                    moment().subtract(1, 'years').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
            };
            this.get('store')
                .query('wage-period', params)
                .then(function (periods) {
                    var ranges;
                    ranges = {};
                    periods.forEach(function (period) {
                        var name;
                        if (period.get('period_name')) {
                            name = period.get('period_name');
                        } else {
                            name =
                                moment(period.get('period_start_date')).format('L') +
                                ' - ' +
                                moment(period.get('period_end_date')).format('L');
                        }
                        return (ranges[name] = [
                            moment(period.get('period_start_date')),
                            moment(period.get('period_end_date')),
                        ]);
                    });
                    return rangesPromise.resolve(ranges);
                });
        } else {
            rangesPromise.resolve({});
        }
        return Em.A([
            Em.Object.create({
                field: 'date',
                form: 'worktime',
                ranges: rangesPromise,
            }),
            Em.Object.create({
                field: 'project',
                form: 'worktime',
            }),
        ]);
    }),
});

export default ProjectsReportController;
