import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    session: service(),
    dialogs: service(),
    intl: service(),
    flags: service(),
    router: service(),

    formTemplates: computed('reportTemplates.[]', function () {
        return get(this, 'reportTemplates').filterBy('form', get(this, 'form'));
    }),

    templateAddingIsDisabled: computed('form', function () {
        if (this.router.currentRouteName === 'timetracker.comp-report') return false; // a small haxx to get old template saving to work on comp-report
        if (this.form === 'worktime' && this.flags.test('form-report-worktimes')) return true;
        if (this.form === 'abcense' && this.flags.test('ember-movenium-form-report-absence-views'))
            return true;
        return false;
    }),

    init() {
        this._super(...arguments);
        const templates = get(this, 'store').peekAll('reportTemplate');
        if (get(templates, 'length') == 0) {
            get(this, 'store').query('reportTemplate', {
                user: get(this, 'session.currentUser.id'),
            });
        }
        set(this, 'reportTemplates', templates);
    },

    actions: {
        saveTemplate() {
            set(this, 'error', false);
            if (!get(this, 'templateName')) set(this, 'error', true);
            let ext = this.columns.firstObject.hasOwnProperty('ext') ? 'ext' : 'external_type';
            get(this, 'store')
                .createRecord('reportTemplate', {
                    name: get(this, 'templateName'),
                    form: get(this, 'form'),
                    columns: get(this, 'columns').mapBy(ext).join(','),
                    order: get(this, 'settings.sortByColumn') || get(this, 'settings.order'),
                    rows: get(this, 'settings.limit'),
                })
                .save()
                .then((template) => this._setTemplate(template));
        },
        openTemplateModal() {
            set(this, 'templateModal', true);
        },
        closeTemplateModal() {
            set(this, 'templateModal', false);
        },

        activateTemplate(template) {
            set(this, 'currentTemplate', template);
            if (this.activateTemplate) this.activateTemplate(template);
        },
        deactivateTemplate() {
            this.deactivateTemplate();
            //set(this, 'currentTemplate', null)
        },
        removeTemplate(template) {
            get(this, 'dialogs')
                .confirm(get(this, 'intl').t('report.template.confirm_remove'))
                .then((con) => this._removeTemplate(con, template));
        },
        selectTemplate(id) {
            const templates = get(this, 'reportTemplates').filterBy('id', id);
            if (get(templates, 'length') == 0) {
                set(this, 'currentTemplate', null);
            } else {
                set(this, 'currentTemplate', get(templates, 'firstObject'));
            }
        },
    },

    _setTemplate(template) {
        set(this, 'templateName', false);
        set(this, 'templateModal', false);
        this.send('activateTemplate', template);
    },
    _removeTemplate(con, template) {
        if (con) {
            template.destroyRecord();
        }
    },
});
