/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/password/recovery/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PasswordRecoveryController;

import Ember from 'ember';

PasswordRecoveryController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    queryParams: ['a', 'u', 'c', 'h'],
    disabled: true,
    showPasswordChange: false,
    paramObs: Ember.observer('a', 'u', 'c', 'h', function () {
        return Ember.run.once(this, this.checkHash);
    }),
    checkHash: function () {
        var intl, req, self;
        self = this;
        intl = this.get('intl');
        req = this.Collector.ttapi({
            url: 'password_recovery/checkParams',
            data: {
                a: this.get('a'),
                u: this.get('u'),
                c: this.get('c'),
                h: this.get('h'),
            },
            method: 'post',
        });
        return req.then(
            function (resp) {
                return self.set('showPasswordChange', true);
            },
            function (reason) {
                return self.set(
                    'errorMessage',
                    intl.t('password.recovery.' + reason.payload.error),
                );
            },
        );
    },
    passwordObs: Ember.observer('new_password', function () {
        if (this.get('new_password').length < 8) {
            this.set('showLengthError', true);
            return this.set('disabled', true);
        } else {
            this.set('showLengthError', false);
            return this.set('disabled', false);
        }
    }),
    actions: {
        changePassword: function () {
            var intl, req, self;
            intl = this.get('intl');
            this.set('loadingMessage', intl.t('password.recovery.changing_password'));
            this.set('showPasswordChange', false);
            self = this;
            req = this.Collector.ttapi({
                url: 'password_recovery/setPassword',
                data: {
                    a: this.get('a'),
                    u: this.get('u'),
                    c: this.get('c'),
                    h: this.get('h'),
                    password: this.get('new_password'),
                },
                method: 'post',
            });
            return req.then(
                function (resp) {
                    self.set('okMessage', intl.t('password.recovery.password_changed'));
                    return self.set('loadingMessage', null);
                },
                function (reason) {
                    self.set(
                        'errorMessage',
                        intl.t('password.recovery.' + reason.responseJSON.error),
                    );
                    return self.set('loadingMessage', null);
                },
            );
        },
        cancel: function () {
            return this.transitionToRoute('login');
        },
    },
});

export default PasswordRecoveryController;
