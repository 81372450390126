/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/reports/bi/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolTimecleanController;

import Ember from 'ember';

AdmintoolTimecleanController = Ember.Controller.extend({
    subMenus: [
        {
            link: 'admintool.reports.bi.timecleanlog',
            text: 'menu.admintool.timecleanlog',
            needs: [],
        },
        {
            link: 'admintool.reports.bi.findtimeclean',
            text: 'menu.admintool.find_timeclean',
            needs: [],
        },
    ],
});

export default AdmintoolTimecleanController;
