import GeneralReportRoute from '../../routes/general-report';

const GroupsReportRoute = GeneralReportRoute.extend({
    queryParams: {
        name: {
            refreshModel: true,
        },
        type: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },

    form: 'group',
    model(params) {
        params = this._super(params);
        params.sideload = true;
        return this.store.query('group', params);
    },
});

export default GroupsReportRoute;
