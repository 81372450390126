import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IMessAgesTypesSuperiorReplacementComponent {
    close(): void;
}

export default class MessAgesTypesSuperiorReplacementComponent extends Component<IMessAgesTypesSuperiorReplacementComponent> {
    @tracked protected show: boolean = true;

    @action
    closeModal() {
        if (this.args.close) {
            this.args.close();
        }
        return (this.show = false);
    }
}
