/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/diary-events/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryEventsController;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

WorksiteDiaryEventsController = Ember.Controller.extend(AuthenticatedRouteMixin, {
    addController: Ember.inject.controller('worksitediary.report.add'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    show_modal: false,
    started_event_list: Ember.computed.alias('model.parent.events_started'),
    current_event_list: Ember.computed.alias('model.parent.events_work_in_progress'),
    completed_event_list: Ember.computed.alias('model.parent.events_finished'),
    interrupted_event_list: Ember.computed.alias('model.parent.events_interrupted'),
    additional_event_list: Ember.computed.alias('model.parent.events_altered'),
    project: Ember.computed.alias('model.parent.project'),
    event_type: 'event',
    filteredEvents: Ember.computed('model.events.[]', function () {
        return this.get('model.events').filterBy('project', this.get('model.parent.project'));
    }),
    actions: {
        addStatusToRecord: function (status, event_id) {
            var diary;
            diary = this.get('store').peekRecord('worksitediary', event_id);
            this.get('started_event_list').removeObject(
                this.get('started_event_list').findBy('id', diary.get('id')),
            );
            if (this.get('Collector').fieldExists('diaryreport', 'events_work_in_progress')) {
                this.get('current_event_list').removeObject(
                    this.get('current_event_list').findBy('id', diary.get('id')),
                );
            }
            this.get('completed_event_list').removeObject(
                this.get('completed_event_list').findBy('id', diary.get('id')),
            );
            this.get('interrupted_event_list').removeObject(
                this.get('interrupted_event_list').findBy('id', diary.get('id')),
            );
            this.get('additional_event_list').removeObject(
                this.get('additional_event_list').findBy('id', diary.get('id')),
            );
            if (status !== 'returnToStore') {
                diary.set('state', status);
                diary.save();
                if (status === 'initiated') {
                    return this.get('started_event_list').pushObject(diary);
                } else if (status === 'started') {
                    return this.get('current_event_list').pushObject(diary);
                } else if (status === 'finished') {
                    return this.get('completed_event_list').pushObject(diary);
                } else if (status === 'interrupted') {
                    return this.get('interrupted_event_list').pushObject(diary);
                } else if (status === 'altered') {
                    return this.get('additional_event_list').pushObject(diary);
                }
            } else {
                return diary.set('state', null);
            }
        },
        updateField: function (value, field) {
            return this.set('model.' + field, value);
        },
        edit_event: function (event) {
            this.set('record', event);
            this.set('show_modal', true);
            return false;
        },
        show_add_event_popup: function () {
            this.set('show_modal', true);
            return false;
        },
        closeModal: function (settings, record) {
            this.set('show_modal', false);
            return this.send('addStatusToRecord', record.get('state'), record.get('id'));
        },
        removeField: function (id) {
            var event, record;
            this.set('show_modal', false);
            record = this.get('record');
            this.set('record', null);
            event = this.store.peekRecord('worksitediary', id);
            if (event) {
                return event.destroyRecord();
            }
        },
        closeModalNotes: function (settings, record) {
            var list;
            list = this.get('addController.model.diaryreport_other_note');
            list.pushObject(record);
            return this.set('show_modal', false);
        },
    },
});

export default WorksiteDiaryEventsController;
