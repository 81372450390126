/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-setting-comp/number-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompNumberFieldComponent;

import Ember from 'ember';

CollectorFieldSettingCompNumberFieldComponent = Ember.Component.extend({
    customerSettings: Ember.inject.service(),
    value: Em.computed(function () {
        return (
            this.get('customerSettings').checkSetting(this.get('configs.setting')) ||
            this.get('configs.defaultVal')
        );
    }),
    actions: {
        save: function (value) {
            this.get('customerSettings').setSetting(this.get('configs.setting'), value);
            return this.sendAction('settingSaved');
        },
        validateInput: function () {
            if (this.get('configs.value') < this.get('configs.minVal')) {
                return this.set('configs.value', this.get('configs.minVal'));
            }
        },
    },
});

export default CollectorFieldSettingCompNumberFieldComponent;
