/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/calendar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryCalendarController, service;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import filterModel from '../../../mixins/filter-model';

({
    inject: { service },
} = Ember);

WorksitediaryCalendarController = GeneralReportController.extend(filterModel, {
    dialogs: service(),
    intl: service(),
    Collector: service('collector-service'),
    queryParams: ['date', 'project', 'user', 'event'],
    skip_params: ['page', 'order'],
    project: null,
    form: 'diary',
    user: null,
    event: false,
    date:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('month').format('YYYY-MM-DD'),
    localStorageKey: 'diarycalendar_defaultColumns',
    // do not use limiting with calender
    limit: null,
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'date',
            }),
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'user',
            }),
        ]);
    }),
    filteredEvents: Ember.computed('model.[]', 'filters.@each.value', function () {
        return this._filterThese(this.get('model'), ['page', 'order', 'event']);
    }),
    eventFields: Ember.computed(function () {
        var c, col_arr, fields;
        c = this.get('Collector');
        col_arr = ['user', 'project', 'status', 'description', 'weather'];
        fields = c.fieldArray('worksitediary', col_arr);
        return fields;
    }),
    actions: {
        catchActions: function (action, params) {
            var c, cols, fields;
            if (action === 'fieldsUpdate') {
                c = this.get('Collector');
                cols = [];
                params.forEach((col) => {
                    return cols.push(col.ext);
                });
                fields = c.fieldArray(this.get('form'), cols);
                return this.set('calFields', fields);
            }
        },
        openEditor: function (row) {
            return this.transitionToRoute(
                'diary.report.date.project',
                row.get('date'),
                row.get('project.id'),
            );
        },
        addDiary: function (day) {
            return this.transitionToRoute('diary.report.date', {
                date: day,
            });
        },
        remove: function (row) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('general.remove_row'))
                .then(function (con) {
                    if (con) {
                        return row.destroyRecord();
                    }
                });
        },
        eventDropped: function (day, event) {
            var c, dropped_record, duplicates, id, model, record;
            c = this.get('Collector');
            [id, model] = event.split(':');
            dropped_record = this.get('store').peekRecord(model, id);
            if (dropped_record.get('date') === day) {
                return;
            }
            record = c.copyRecord(dropped_record);
            record.set('date', day);
            record.set('attachments', null); // don't copy photos
            duplicates = this.get('filteredModel').filter((item) => {
                return (
                    day === item.get('date') && record.get('project.id') === item.get('project.id')
                );
            });
            if (duplicates.length > 0) {
                record.unloadRecord();
                return this.get('dialogs').alert(
                    this.get('intl').t('diary.calendar.duplicate_error'),
                );
            } else {
                return record.save().catch((row) => {
                    var msg;
                    msg = '<h4>' + this.get('intl').t('worktime.calendar.error') + '</h4><br>';
                    if (
                        this.get('intl').exists('worktime.calendar.error_type.' + row.errors.error)
                    ) {
                        msg += this.get('intl').t(
                            'worktime.calendar.error_type.' + row.errors.error,
                        );
                    } else if (this.get('intl').exists('errors.' + row.errors.error)) {
                        msg += this.get('intl').t('errors.' + row.errors.error);
                    } else {
                        msg += row.errors.error_msg;
                    }
                    record.destroyRecord();
                    return this.get('dialogs').alert(msg);
                });
            }
        },
    },
});

export default WorksitediaryCalendarController;
