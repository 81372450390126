/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/form-edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

FormsFormEditRoute = GeneralAddRoute.extend({
    controllerName: 'forms/form-edit',
    afterSave: Ember.computed('form', function () {
        return {
            route: 'forms.form-report',
            params: this.get('form'),
        };
    }),
    edit: true,
    //controllerName: 'forms/edit-form'
    model: function (params) {
        this.set('modelName', params.form.dasherize());
        this.set('form', params.form);
        return this.store.findRecord(this.get('modelName'), params.id, {
            include: true,
        });
    },
});

export default FormsFormEditRoute;
