import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';

export default Component.extend({
    errorService: service('error'),
    store: service(),

    parsedError: computed('error', function () {
        return get(this, 'errorService').toError(get(this, 'error'));
    }),

    message: computed('error', function () {
        return get(this, 'errorService').toString(get(this, 'error'));
    }),

    hittingWorktimes: computed('error', function () {
        if (get(this, 'parsedError.meta.hits') === 'worktime') {
            return get(this, 'store').query('worktime', {
                id: get(this, 'parsedError.meta.ids').join(','),
                sideload: true,
            });
        }
        return null;
    }),

    hittingAbsences: computed('error', function () {
        if (get(this, 'parsedError.meta.hits') === 'absence') {
            return get(this, 'store').query('abcense', {
                id: get(this, 'parsedError.meta.ids').join(','),
                sideload: true,
            });
        }
        return null;
    }),

    hittingComps: computed('error', function () {
        if (get(this, 'parsedError.meta.hits') === 'comp') {
            return get(this, 'store').query('worktime', {
                id: get(this, 'parsedError.meta.ids').join(','),
                sideload: true,
            });
        }
        return null;
    }),
});
