import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

const helper = Helper.extend({
    flags: service(),
    compute(params) {
        return get(this, 'flags').test(params[0]);
    },
});

export default helper;
