import Component from '@glimmer/component';

export default class SettingsGeneralV2ComponentsSalarytypesWorktypesModalComponent extends Component {
    /**
     * never allow editing worktimegroup
     */
    get disabledFields() {
        const ret = {};

        if (!this.args.record.isNew) ret.worktimegroup = true;

        // Disable worktimegroup when adding worktype in worktimegroup settings
        if (this.args.worktimeGroupLocked) ret.worktimegroup = true;

        return ret;
    }
}
