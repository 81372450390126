/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/checkboxgroup-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsCheckboxgroupFieldComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import collectorField from '../../../../mixins/collector-field';

CollectorFieldsCheckboxgroupFieldComponent = Ember.Component.extend(collectorField, {
    intl: Ember.inject.service(),
    checkboxes: Ember.computed('field.options', 'field.value', function () {
        var checked, key, options_arr, ref, text, value;
        options_arr = Ember.A([]);
        ref = this.get('field.options');
        for (key in ref) {
            value = ref[key];
            checked = false;
            if (Array.isArray(this.get('model.' + this.get('field.name')))) {
                if (indexOf.call(this.get('model.' + this.get('field.name')), key) >= 0) {
                    checked = true;
                }
            }
            text =
                value.substring(0, 1) === '$'
                    ? this.get('intl').t(
                          this.get('field.form') + 's.' + this.get('field.name') + '.' + key,
                      )
                    : value;
            if (text === value && this.get('intl').exists(value)) {
                text = this.get('intl').t(value);
            }
            options_arr.pushObject(
                Ember.Object.create({
                    checked: checked,
                    key: key,
                    text: text,
                }),
            );
        }
        return options_arr;
    }),
    actions: {
        check: function (box) {
            box.toggleProperty('checked');
            return this.sendAction(
                'bubbleChange',
                this.get('checkboxes').filterBy('checked').mapBy('key'),
                this.get('field.name'),
            );
        },
    },
});

export default CollectorFieldsCheckboxgroupFieldComponent;
