/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/withoutori/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsWithoutoriController;

import Ember from 'ember';

import config from '../../../config/environment';

OrientationsWithoutoriController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    dialogs: Em.inject.service(),
    store: Em.inject.service(),
    addController: Ember.inject.controller('orientations/add'),
    columns: ['project', 'user'],
    rowsPerPage: 25,
    pageCount: Ember.computed('model.count', function () {
        return Math.ceil(this.get('model.count') / this.get('rowsPerPage'));
    }),
    page: 1,
    form: 'orientation',
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'ori_site',
                form: 'orientation',
                value: this.get('ori_site'),
            }),
            Em.Object.create({
                field: 'employer',
                form: 'user',
                value: this.get('employer'),
            }),
        ]);
    }),
    queryParams: ['ori_site', 'employer', 'page'],
    ori_site: null,
    employer: null,
    // get translation for every column
    translated_columns: Em.computed('columns', function () {
        var c, columns, intl, translated_columns;
        c = this.get('Collector');
        intl = this.get('intl');
        columns = this.get('columns');
        translated_columns = [];
        $.each(columns, (key, item) => {
            return translated_columns.push(intl.t('report.userswithoutori.' + item));
        });
        return translated_columns;
    }),
    message: Em.computed('model', function () {
        if (this.get('model.error') === 'too_many_users') {
            return this.get('intl').t('orientations.message_too_many_users');
        }
    }),
    data: Em.computed('model', function () {
        var columns, data, formatted_data, ids;
        data = this.get('model.users');
        if (!data) {
            return null;
        }
        ids = [];
        columns = this.get('columns');
        formatted_data = Em.A([]);
        data.forEach((unformatted_row) => {
            var row;
            row = Ember.Object.create();
            if (unformatted_row.id) {
                row.id = unformatted_row.id;
            }
            row.id = unformatted_row['projectid'] + '_' + unformatted_row['userid'];
            $.each(columns, (key, column) => {
                var cell;
                cell = unformatted_row[column];
                if (!Number.isInteger(cell)) {
                    return (row[column] = new Ember.String.htmlSafe(cell));
                } else {
                    return (row[column] = cell);
                }
            });
            return formatted_data.pushObjects([row]);
        });
        return formatted_data;
    }),
    checkedActions: Em.computed(function () {
        return [
            {
                name: 'orientateUsers',
            },
        ];
    }),
    actions: {
        catchActions: function (action, row) {
            var addController,
                data,
                i,
                ids_array,
                lastprojectid,
                len,
                projectid,
                promise,
                promises,
                tmp_rows,
                userObjs,
                userids;
            if (action === 'checkedRowsAction') {
                userids = [];
                userObjs = [];
                tmp_rows = row.rows;
                addController = this.get('addController');
                lastprojectid = '';
                projectid = tmp_rows[0].id.split('_')[0];
                promises = [];
                for (i = 0, len = tmp_rows.length; i < len; i++) {
                    data = tmp_rows[i];
                    ids_array = data.id.split('_'); // projectid, userid
                    userids.push(ids_array[1]);
                    promise = this.get('store').findRecord('user', ids_array[1]);
                    promises.push(promise);
                    promise.then((user) => {
                        return userObjs.push(user);
                    });
                    if (lastprojectid !== '' && lastprojectid !== ids_array[0]) {
                        this.get('dialogs').alert(
                            this.get('intl').t('orientations.warning_multiple_projects'),
                        );
                        return;
                    }
                    lastprojectid = ids_array[0];
                }
                Promise.all(promises).then(() => {
                    return this.get('store')
                        .findRecord('project', projectid)
                        .then((project) => {
                            addController.set('users', userids);
                            addController.set('userData', userObjs);
                            this.set('session.currentUser.currentMySite', project);
                            // support new orientation user selector
                            addController.set('projectRecord', project);
                            addController.set('userRecords', userObjs);
                            return this.transitionToRoute('orientations.add');
                        });
                });
            }
        },
        filterChanged: function (value, fieldName) {
            this.set('page', 1);
            if (Em.isEmpty(value)) {
                return this.set(fieldName, null);
            } else {
                return this.set(fieldName, value);
            }
        },
    },
});

export default OrientationsWithoutoriController;
