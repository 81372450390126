import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import podNames from 'ember-component-css/pod-names';
import { A } from '@ember/array';

export default class saldoInsightsComponent extends Component {
    @service store;
    @service('collector-service') Collector;
    @service flags;

    @tracked showAllRows;
    @tracked dataLoaded;
    @tracked shownDetailsArr;
    @tracked showAllRows;

    constructor() {
        super(...arguments);
        this.shownDetailsArr = A();
        this.showAllRows = false;
        this.insightsData = this.getInsightsData();
        this.dataStructure = {
            worktime: ['field', 'minutes', 'saldo'],
            abcense: ['description', 'absence_hours', 'dayamount', 'saldo'],
            saldodeducter: ['description', 'saldo'],
        };

        this.dataStructure.worktime.splice(2, 0, 'multiplier');
    }

    /**
     * get all worktimes and absences and sort them together
     */
    async getAllDataRows() {
        let joined = A();
        let worktimes = await this.store.query('worktime', {
            user: this.args.user,
            date: this.args.date + '_' + this.args.date,
            sideload: true,
        });
        if (this.Collector.testNeedsOne('products.absences')) {
            let absences = await this.store.query('abcense', {
                user: this.args.user,
                startdate_enddate: this.args.date + '_' + this.args.date,
                sideload: true,
            });
            joined = worktimes.toArray().concat(absences.toArray());
        } else {
            joined = worktimes.toArray();
        }
        let deducters = await this.store.query('saldodeducter', {
            user: this.args.user,
            date: this.args.date + '_' + this.args.date,
        });
        joined.pushObjects(deducters.toArray());

        return joined;
    }

    getRowType(row) {
        if (row.absence_hours) return 'abcense';
        else if (row.work_hours) return 'worktime';
        else return 'saldodeducter';
    }

    // need to sort details by custom order
    sortDetailsForWorktimes(item_order, details) {
        let order_arr = [];
        for (var order of item_order) {
            order_arr.pushObject(order.name);
        }
        details.sort((a, b) => {
            return order_arr.indexOf(a.field) - order_arr.indexOf(b.field);
        });
        return details;
    }

    /**
     * This is a bit ugly method, but it iterates saldo-insights and adds datarows and groups manual-edits so they can be nicely looped through
     * ..also adds sums to data
     */
    async getInsightsData() {
        this.dataLoaded = false;
        let dataRows = await this.getAllDataRows();
        let data = await this.args.data;
        if (!data.rows || !data.rows.length || !dataRows.length) {
            this.dataLoaded = true;
            return 'no_data';
        }
        let manualEdits = { type: 'saldodeducter', saldo: 0, details: [] };
        let sums = { minutes: 0, saldo: 0 };
        let removeThese = [];

        // iterate all datarows and attach them to correct rows
        for (const datarow of dataRows) {
            let row = data.rows.findBy('id', parseInt(datarow.id));
            if (row) {
                row.datarow = datarow;
                row.id = datarow.id;
                if (this.getRowType(datarow) == 'abcense') {
                    set(datarow, 'saldo', row.saldo * -1);
                    row.details = [datarow];
                    sums.minutes += row.minutes;
                    sums.saldo += datarow.saldo;
                    row.saldo = row.saldo * -1;
                } else if (this.getRowType(datarow) == 'saldodeducter') {
                    set(
                        datarow,
                        'saldo',
                        (datarow.addhours ? datarow.hours : datarow.hours * -1) * 60,
                    );
                    set(datarow, 'minutes', datarow.saldo);
                    manualEdits.details.pushObject(datarow);
                    manualEdits.datarow = row;
                    removeThese.pushObject(row);
                    manualEdits.saldo += datarow.saldo;
                    sums.saldo += datarow.saldo;
                    manualEdits.id = 'deducters';
                } else if (this.getRowType(datarow) == 'worktime') {
                    if (row.details)
                        row.details = this.sortDetailsForWorktimes(data.rules, row.details);
                    sums.minutes += datarow.work_hours * 60;
                    sums.saldo += row.saldo;
                }
                row.starttime = datarow.starttime; // we have to give starttime to every row so we can sort by it
                row.hasSaldo = true; // only rows from insights has saldos
            } else {
                data.rows.pushObject({
                    type: this.getRowType(datarow),
                    id: parseInt(datarow.id),
                    datarow: datarow,
                    saldo: 0,
                    minutes: (datarow.work_hours ? datarow.work_hours : datarow.absence_hours) * 60,
                    details: [datarow],
                    starttime: datarow.starttime,
                });
                sums.minutes +=
                    (datarow.work_hours ? datarow.work_hours : datarow.absence_hours) * 60;
            }
        }

        // sort by starttime
        data.rows.sort((a, b) => (a.starttime > b.starttime ? 1 : -1));

        // find shortage
        let shortage = data.rows.findBy('type', 'shortage');
        if (shortage) {
            shortage.saldo = shortage.minutes;
            shortage.datarow = {
                saldo: shortage.minutes,
            };
            data.rows.removeObject(shortage);
            sums.saldo += shortage.minutes;
            //sums.minutes += shortage.minutes;
            shortage.hasSaldo = true;
            data.rows.pushObject(shortage);
        }

        if (manualEdits['details'].length > 0) {
            data.rows.removeObjects(removeThese);
            data.rows.pushObject(manualEdits);
        }

        // handle daily max cut if found
        let dailymaxCut = data.rows.findBy('type', 'dailymaximum');
        if (dailymaxCut) {
            sums.saldo += dailymaxCut.saldo;
            dailymaxCut.hasSaldo = true;
            // dailymaxCut item needs to be last in array
            data.rows.push(data.rows.splice(data.rows.indexOf(dailymaxCut), 1)[0]);
        }

        // add cumulative max cut if needed
        if (this.args.saldo.cut) {
            let cumulativeMaxCut = {
                type: 'cumulativemaximum',
                saldo: -this.args.saldo.cut,
                hasSaldo: true,
            };
            sums.saldo -= this.args.saldo.cut;
            data.rows.pushObject(cumulativeMaxCut);
        }

        data.sums = sums;
        this.dataLoaded = true;
        return data;
    }

    findCorrectDataRow(rows, rowid) {
        return rows.findBy('id', rowid.toString());
    }

    get styleNamespace() {
        return podNames['saldo-insights'];
    }

    get dayLength() {
        return this.args.workday_list[this.args.date].workday_length;
    }

    @action
    async toggleAllRows() {
        let data = await this.args.data;
        if (!this.showAllRows) {
            this.showAllRows = true;
            for (const row of data.rows) {
                this.shownDetailsArr.pushObject(row.id);
            }
        } else {
            this.showAllRows = false;
            for (const row of data.rows) {
                this.shownDetailsArr.removeObject(row.id);
            }
        }
    }

    @action
    toggleRowDetails(row) {
        if (this.shownDetailsArr.includes(row)) this.shownDetailsArr.removeObject(row);
        else this.shownDetailsArr.pushObject(row);
        this.shownDetailsArr = this.shownDetailsArr;
    }
}
