/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/project-files/component.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectFilesComponent;

import Ember from 'ember';

ProjectFilesComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    data: Em.computed(function () {
        var params;
        params = {};
        params['project'] = this.get('project');
        return this.get('store').query('projectfile', params);
    }),
});

export default ProjectFilesComponent;
