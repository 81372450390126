import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { A } from '@ember/array';

export default Component.extend({
    store: service(),

    tags: null,
    options: A([]),

    init: function () {
        this._super(...arguments);
        set(this, 'tags', []);
    },

    actions: {
        addTag(tag) {
            set(this, 'tag', tag);
            debounce(this, this.addTagToList, 120);
        },

        removeTagAtIndex(index) {
            get(this, 'tags').removeAt(index);
        },

        queryForUser(currentInputValue) {
            if (currentInputValue === '') {
                return;
            }
            set(this, 'currentInputValue', currentInputValue);
            debounce(this, this.queryBackend, 700);
        },
    },

    queryBackend() {
        set(this, 'options', A([]));

        let users = get(this, 'store').query(get(this, 'form'), {
            q: get(this, 'currentInputValue'),
        });
        users.then((results) => {
            set(this, 'showDropdown', true);
            let field = get(this, 'field');
            results.forEach((result) => {
                if (get(result, field)) {
                    get(this, 'options').pushObject(get(result, field));
                }
            });
        });
    },

    addTagToList() {
        let tag = get(this, 'tag');
        let tagObj = { tag: tag, error: false };

        set(this, 'showDropdown', false);

        if (this.validateEmail(tag)) {
            tagObj.error = true;
        }

        get(this, 'tags').pushObject(tagObj);

        this.tagListChanged(get(this, 'tags'));
    },

    validateEmail(email) {
        return !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
            email,
        );
    },
});
