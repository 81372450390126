import Component from '@ember/component';
import { get, set } from '@ember/object';
import { A } from '@ember/array';

export default Component.extend({
    didInsertElement() {
        let rowOrder = ['extralong', 'extralong', 'long', 'short', 'empty'];
        let arr = A();

        let i = 0;

        if (!get(this, 'rows')) {
            set(this, 'rows', 4);
        }

        let currentRow = 0;

        while (i < get(this, 'rows')) {
            if (currentRow >= rowOrder.length) {
                currentRow = 0;
            }
            arr.pushObject(rowOrder[currentRow]);
            currentRow++;
            i++;
        }
        set(this, 'skeletonRows', arr);
    },
});
