/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-container/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldContainerComponent;

import Ember from 'ember';

import { isArray } from '@ember/array';

import { computed } from '@ember/object';

import validator from 'tt4/classes/validations';

CollectorFieldContainerComponent = Ember.Component.extend({
    errors: Em.computed('model.errors', 'model.errors.[]', function () {
        return this.get('model.errors.' + this.get('_field.name'));
    }),
    hasErrors: Em.computed.gt('errors.length', 0),
    fieldLabel: Em.computed.oneWay('_field.translated_name'),
    firstError: Em.computed('errors.[]', function () {
        return this.get('errors.firstObject');
    }),
    init: function () {
        this._super();
        if (isArray(this.get('field.features.mandatory'))) {
            return this.get('field.features.mandatory').forEach((item) => {
                var i, k, results;
                results = [];
                for (k in item) {
                    i = item[k];
                    results.push(
                        Ember.defineProperty(
                            this,
                            'mandatoryObservable',
                            Ember.computed.alias('model.' + k),
                        ),
                    );
                }
                return results;
            });
        }
    },
    isMandatory: computed('field', 'mandatoryObservable', function () {
        if (
            this.get('field.features.mandatory') &&
            !isArray(this.get('field.features.mandatory'))
        ) {
            return true;
        } else if (isArray(this.get('field.features.mandatory')) && this.get('model')) {
            return validator._is_visible(this.get('model'), this.get('field.features.mandatory'));
        }
        return false;
    }),
    actions: {
        change: function (value, fieldName, validated) {
            if (!fieldName) {
                fieldName = this.get('_field.name');
            }
            return this.sendAction('change', value, fieldName, validated);
        },
    },
    _field: Ember.computed('field', function () {
        var c, field;
        field = this.get('field');
        if (typeof field === 'string') {
            c = this.get('Collector');
            return c.field(field.split('.')[0], field.split('.')[1]);
        } else {
            return field;
        }
    }),
});

CollectorFieldContainerComponent.reopenClass({
    positionalParams: ['field'],
});

export default CollectorFieldContainerComponent;
