import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';
import csvExport from '../../../../mixins/csv-export';

export default Route.extend(csvExport, {
    Collector: service('collector-service'),
    intl: service(),
    startYear: 2006,
    init() {
        this._super(...arguments);
        this.blacklist = ['holiday'];
    },

    model() {
        return this.modelFor('users.edit');
    },

    actions: {
        get_data() {
            set(this.controller, 'loadingData', true);
            let collector = get(this, 'Collector');
            let all_forms = get(collector, 'forms')();
            var userid = get(this.modelFor('users.edit'), 'id');
            var loopYearByYear = [];
            var formDateFields = [];
            var loopOnce = [];
            var startYear = 2006;
            var endYear = parseInt(moment().format('YYYY'));
            var formUserFields = {};

            // do not fetch from blacklisted-forms
            for (let formname in all_forms) {
                if (get(this, 'blacklist').indexOf(formname) != -1) continue;

                // first we check all forms for user-field
                if (all_forms[formname]['objects']['user']) {
                    formUserFields[formname] = 'user';
                } else {
                    // take first field that is database-field and has user as form
                    for (var field in all_forms[formname]['objects']) {
                        var fieldObj = all_forms[formname]['objects'][field];
                        if (
                            fieldObj['type'] == 'database' &&
                            fieldObj['options']['form'] == 'user'
                        ) {
                            formUserFields[formname] = field;
                            break;
                        }
                    }
                }
            }

            for (var formname in formUserFields) {
                var currentForm = all_forms[formname];

                if (currentForm) {
                    for (let field in currentForm['objects']) {
                        // if it does have use_index-setting we need to pull data in one year increments
                        if (
                            currentForm['objects'][field]['features']['use_index'] &&
                            currentForm['objects'][field]['features']['use_index'] == 'index1'
                        ) {
                            loopYearByYear.push(formname);
                            formDateFields[formname] = field;
                            break;
                        }
                    }
                    // if there was not index-setting, push this form to array that doesn't need to be iterated year-by-year
                    if (loopYearByYear.indexOf(formname) == -1) {
                        loopOnce.push(formname);
                    }
                }
            }

            var promises = [];

            // a bit of a haxxor, get user-info first from user-form
            loopOnce.push('user');
            formUserFields['user'] = 'id';

            // the easy part
            for (let formname of loopOnce) {
                var promise = new EmberPromise((resolve) => {
                    var params = {
                        limit: 'all',
                        sideload: true,
                    };
                    params[formUserFields[formname]] = userid;

                    this.store
                        .query(formname, params)
                        .then((data) => this._setFormObject(data, collector, resolve));
                });
                promises.push(promise);
            }

            // now we need to get those index1-forms and iterate year by year
            for (let formname of loopYearByYear) {
                var outer_promise = new EmberPromise((resolve) => {
                    var translatedColumns = [];
                    //var formName = get(data, 'modelName');
                    var columnsObject = collector.fields(formname);
                    var columns = [];

                    for (var columnName in columnsObject) {
                        translatedColumns.push(
                            collector.getTranslationForColumn(formname, columnName),
                        );
                        columns.push(columnName);
                    }

                    var formObject = {
                        name: formname,
                        columns: columns,
                        translated_columns: translatedColumns,
                    };
                    var formData = [];
                    for (var i = startYear; i <= endYear; i++) {
                        var promise = new EmberPromise((resolve) => {
                            var start = moment(i + '-01').format('YYYY-MM-DD');
                            var end = moment(start).add(1, 'year').format('YYYY-MM-DD');

                            var params = {
                                limit: 'all',
                                sideload: true,
                            };
                            params[formDateFields[formname]] = start + '_' + end;
                            params[formUserFields[formname]] = userid;

                            this.store.query(formname, params).then((data) => resolve(data));
                        });
                        formData.push(promise);
                        //formObject
                    }
                    //console.log(formData);
                    EmberPromise.all(formData).then((data) =>
                        this._setFormObjectData(data, formObject, collector, resolve),
                    );
                    //
                });
                promises.push(outer_promise);
            }
            return EmberPromise.all(promises).then((data) => this._createCsv(data));
        },
    },

    _setFormObject(data, collector, resolve) {
        var translatedColumns = [];
        var formName = get(data, 'modelName');
        var columnsObject = collector.fields(formName);
        var columns = [];

        for (var columnName in columnsObject) {
            translatedColumns.push(collector.getTranslationForColumn(formName, columnName));
            columns.push(columnName);
        }

        var rows = [];
        data.forEach((record) => {
            var row = [];
            for (var col of columns) {
                row[col] = collector._format_(get(record, col), formName, col, 'csv');
                //console.log(get(record, col));
            }
            rows.push(row);
        });

        var formObject = {
            name: get(data, 'modelName'),
            data: rows,
            columns: columns,
            translated_columns: translatedColumns,
        };
        resolve(formObject);
    },

    _setFormObjectData(data, formObject, collector, resolve) {
        for (var recordArr of data) {
            var rows = [];
            recordArr.forEach((record) => {
                var row = [];
                for (var col of formObject['columns']) {
                    row[col] = collector._format_(get(record, col), formObject['name'], col, 'csv');
                    //console.log(get(record, col));
                }
                rows.push(row);
            });
        }
        formObject['data'] = rows;
        resolve(formObject);
    },

    _createCsv(data) {
        var csvFull = [];
        var csvHeaders = [];
        var name = '';
        for (var form of data) {
            const key = 'form.' + form['name'];
            if (get(this, 'intl').exists(key)) name = get(this, 'intl').t(key).toLowerCase();
            else name = form['name'];

            csvHeaders.push(name);
            var element = this.createCsvElement(
                form['columns'],
                form['data'],
                form['translated_columns'],
            );
            csvFull.push(element);
        }
        set(this.controller, 'loadingData', false);
        this.createMultiTableCsv(
            csvFull,
            csvHeaders,
            get(this.modelFor('users.edit'), 'firstname') +
                ' ' +
                get(this.modelFor('users.edit'), 'lastname'),
        );
    },
});
