import { helper } from '@ember/component/helper';

export function number(params /*, hash*/) {
    let val = params[0];
    if (val === null) return null;
    val = Number(val);
    if (isNaN(val)) {
        return NaN;
    }
    return val;
}

export default helper(number);
