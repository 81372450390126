/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/tt3tott4/route.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerDetailsRoute;

import Ember from 'ember';

AdmintoolCustomerDetailsRoute = Ember.Route.extend({
    admintool: Ember.inject.service(),
    model: function () {
        var parent;
        parent = this.modelFor('admintool.customer');
        return Ember.RSVP.hash({
            partnerid: parent.get('partnerid'),
            forms: this.get('admintool').ajax({
                url: 'get_form_data',
                method: 'get',
                data: {
                    form: 'forms',
                    partnerid: parent.get('partnerid'),
                    rawoutput: true,
                },
            }),
            tt3tott4: this.get('admintool').ajax({
                url: 'tt3tott4',
                method: 'get',
                data: {
                    partnerid: parent.get('partnerid'),
                },
            }),
        });
    },
    actions: {
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default AdmintoolCustomerDetailsRoute;
