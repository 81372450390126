import { helper } from '@ember/component/helper';
import { isEqual } from '@ember/utils';
// This function receives the params `params, hash`

export function isNth(params) {
    let index = params[0] + 1;
    let nth = params[1];

    if (isEqual(index % nth, 0)) {
        return true;
    } else return false;
}
export default helper(isNth);
