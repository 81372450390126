/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/hours-and-minutes-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsTimeFieldComponent;

import Ember from 'ember';

import baseField from '../text-field/component';

CollectorFieldsTimeFieldComponent = baseField.extend({
    _hours: '',
    _minutes: '',
    hours: Em.computed('value', function () {
        var hours, valarr;
        if (Em.isPresent(this.get('value'))) {
            if (typeof this.get('value') === 'string') {
                valarr = this.get('value').split(':');
                hours = parseInt(Number(valarr[0]));
            } else {
                hours = parseInt(Number(this.get('value')));
            }
            if (hours <= 9) {
                hours = '0' + hours;
            }
        }
        this.set('_hours', hours);
        return hours;
    }),
    minutes: Em.computed('value', function () {
        var hours, mins, valarr;
        if (Em.isPresent(this.get('value'))) {
            if (typeof this.get('value') === 'string') {
                valarr = this.get('value').split(':');
                hours = parseInt(Number(valarr[0]));
                mins = parseInt(Number(valarr[1]));
            } else {
                hours = parseInt(Number(this.get('value')));
                mins = Math.round((Number(this.get('value')) - hours) * 60);
            }
            if (mins <= 9) {
                mins = '0' + mins;
            }
        } else {
            mins = null;
        }
        this.set('_minutes', mins);
        return mins;
    }),
    minute_options: Em.computed(function () {
        var i, j, ret, value;
        ret = [];
        for (i = j = 0; j <= 59; i = ++j) {
            if (i < 10) {
                value = '0' + i;
            } else {
                value = i;
            }
            ret.push({
                id: value,
                name: value,
            });
        }
        ret.max = ret.length - 1;
        return ret;
    }),
    hour_options: Em.computed(function () {
        var hours, i, j, ref, ret, value;
        if (this.get('field.features.validate_check') === 'hour24Check') {
            hours = 24;
        } else {
            hours = 45;
        }
        ret = [];
        for (i = j = 0, ref = hours; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
            if (i < 10) {
                value = '0' + i;
            } else {
                value = i;
            }
            ret.push({
                id: value,
                name: value,
            });
        }
        return ret;
    }),
    actions: {
        setHours: function (value) {
            var hours;
            value = Number(parseInt(event.target.value));
            if (value < 0) {
                value = 0;
            }
            hours = '';
            if (value < 10) {
                hours = '0' + value;
            } else {
                hours = value;
            }
            this.set('_hours', hours);
            if (
                this.get('_minutes') == null ||
                this.get('_minutes') == '' ||
                isNaN(this.get('_minutes'))
            ) {
                this.set('_minutes', '00');
            }
            return this.set('value', hours + ':' + this.get('_minutes'));
        },
        setMins: function (value) {
            var minutes;
            value = Number(parseInt(event.target.value));
            if (value > this.get('minute_options.max') || value < 0) {
                value = 0;
            }
            minutes = '';
            if (value < 10) {
                minutes = '0' + value;
            } else {
                minutes = value;
            }
            this.set('_minutes', minutes);
            if (this.get('_hours') === '' || isNaN(this.get('_hours'))) {
                this.set('_hours', '00');
            }
            return this.set('value', this.get('_hours') + ':' + minutes);
        },
    },
});

export default CollectorFieldsTimeFieldComponent;
