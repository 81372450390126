/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/general-v2/components/time-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorSettingCompTimeSettingComponent;

import Ember from 'ember';

import collector from '../../../../../classes/collector';

CollectorSettingCompTimeSettingComponent = Ember.Component.extend({
    store: Em.inject.service(),
    session: Em.inject.service(),
    intl: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    notifications: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    setting_default: '16:00',
    time: Em.computed(function () {
        return (
            this.get('customerSettings').checkSetting(this.get('configs.name')) ||
            this.setting_default
        );
    }),
    second_time: Em.computed(function () {
        return this.get('customerSettings').checkSetting(
            this.get('configs.secondary_setting_name'),
        );
    }),
    field1Translation: Em.computed(function () {
        if (
            this.get('configs.name').indexOf('_fi') > 0 ||
            this.get('configs.name').indexOf('_se') > 0
        ) {
            return this.get('intl').t(
                'settings.' + this.get('configs.name').slice(0, -3) + '.field',
            );
        } else {
            return this.get('intl').t('settings.' + this.get('configs.name') + '.field');
        }
    }),
    field2Translation: Em.computed(function () {
        if (
            this.get('configs.secondary_setting_name').indexOf('_fi') > 0 ||
            this.get('configs.secondary_setting_name').indexOf('_se') > 0
        ) {
            return this.get('intl').t(
                'settings.' + this.get('configs.secondary_setting_name').slice(0, -3) + '.field',
            );
        } else {
            return this.get('intl').t('settings.' + this.get('configs.name') + '.field');
        }
    }),
    /*
  is_dirty: Em.computed 'setting', ->
      if @get('setting')  != @get('setting_default')
          return true
      else
          return false
  */
    actions: {
        saveSetting: function () {
            return this.get('customerSettings')
                .setSetting(this.get('configs.name'), this.get('time'))
                .then(() => {
                    var promise;
                    promise = new Em.RSVP.Promise((resolve, reject) => {
                        if (this.get('configs.secondary_setting_name')) {
                            return this.get('customerSettings')
                                .setSetting(
                                    this.get('configs.secondary_setting_name'),
                                    this.get('second_time'),
                                )
                                .then(() => {
                                    return resolve();
                                });
                        } else {
                            return resolve();
                        }
                    });
                    return promise.then(() => {
                        if (this.get('settingSaved')) {
                            return this.settingSaved();
                        }
                    });
                });
        },
    },
});

export default CollectorSettingCompTimeSettingComponent;
