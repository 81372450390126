/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/working-now/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditWorkingNowRoute;

import Ember from 'ember';

ProjectsEditWorkingNowRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    queryParams: {
        project: {
            refreshModel: true,
            replace: true,
        },
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var project, prparams, wtparams;
        //params = @._super(params)
        project = this.modelFor('projects.edit');
        wtparams = {};
        prparams = {};
        wtparams.project = project.get('id').toString();
        wtparams.sideload = ['user.firstname', 'user.lastname', 'user.phone', 'user.image'];
        wtparams.endtime = '(empty)';
        prparams.project = project.get('id').toString();
        prparams.sideload = ['user.firstname', 'user.lastname', 'user.phone', 'user.image'];
        prparams.out = '(empty)';
        prparams.groupBy = 'user';
        return new Ember.RSVP.Promise((resolve, reject) => {
            var c, promarr;
            promarr = [];
            c = this.get('Collector');
            return Ember.RSVP.hash({
                presences: c.testNeedsOne('presence')
                    ? this.store.query('presence', prparams)
                    : void 0,
                worktimes: c.testNeedsOne('worktime')
                    ? this.store.query('worktime', wtparams)
                    : void 0,
            }).then(function (values) {
                if (values['worktimes']) {
                    values['worktimes'].forEach(function (item) {
                        return promarr.push(item.get('user'));
                    });
                }
                if (values['presences']) {
                    values['presences'].forEach(function (item) {
                        return promarr.push(item.get('user'));
                    });
                }
                return resolve(promarr.uniq());
            });
        });
    },
});

export default ProjectsEditWorkingNowRoute;
