/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/devices/route.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerOverviewDevicesRoute;

import Ember from 'ember';

AdmintoolCustomerOverviewDevicesRoute = Ember.Route.extend({
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.init_stuff();
    },
});

export default AdmintoolCustomerOverviewDevicesRoute;
