import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n    @dropdownClass=\"power-select-add-field\"\n    @options={{this.dropdownOptions}}\n    @selected={{this.value}}\n    @disabled={{or @disabled this.disabled}}\n    @onChange={{this.onChange}}\n    @searchEnabled={{false}}\nas |opt|>\n    {{opt.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n    @dropdownClass=\"power-select-add-field\"\n    @options={{this.dropdownOptions}}\n    @selected={{this.value}}\n    @disabled={{or @disabled this.disabled}}\n    @onChange={{this.onChange}}\n    @searchEnabled={{false}}\nas |opt|>\n    {{opt.name}}\n</PowerSelect>","moduleName":"tt4/components/addon/fields/dropdown-userlevel.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/dropdown-userlevel.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { IFieldComponent } from 'tt4/ember-movenium/interfaces/fields';
import { sortBy } from 'tt4/ember-movenium/utils/sort';

interface IUserlevelOption {
    id: string | number;
    name: string;
}

export default class FieldsDropdownUserlevelComponent extends Component<IFieldComponent> {
    public options: any;
    @service private intl: any;

    constructor(owner: unknown, args: IFieldComponent) {
        super(owner, args);
        this.options = this.args.options;
    }

    get value(): IUserlevelOption | null {
        if (this.args.value) {
            return this.dropdownOptions.find(
                (opt) => opt.id === this.args.value,
            ) as IUserlevelOption;
        }
        return null;
    }

    get dropdownOptions(): IUserlevelOption[] {
        const options = this.options as [];
        const parsedOptions: IUserlevelOption[] = options.map((option: string) => {
            if (parseInt(option, 10) > 9) {
                return {
                    id: parseInt(option as string, 10),
                    name: option.substring(2),
                } as IUserlevelOption;
            } else {
                const id = parseInt(option, 10);
                return {
                    id,
                    name: this.intl.t(this.args.form + '.' + this.args.name + '.' + id, {
                        default: option,
                    }),
                } as IUserlevelOption;
            }
        });

        return this.args.sortBy ? sortBy(this.args.sortBy, parsedOptions) : parsedOptions;
    }

    @action
    public onChange(value: any) {
        this.args.onChange(value.id);
    }
}
