/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/report/date/project/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryReportDateController, computed, get, service, set;

import Ember from 'ember';

import DS from 'ember-data';

import config from 'tt4/config/environment';

import $ from 'jquery';

({
    computed,
    inject: { service },
    set,
    get,
} = Ember);

DiaryReportDateController = Ember.Controller.extend({
    Collector: service('collector-service'),
    GpsLocator: service(),
    fetchingWeather: false,
    ownWeather: {},
    translations: {
        temp: '°C',
        wspeed: 'm/s',
        percent: '%',
    },
    skip: ['date', 'project'],
    workforce_fields: computed('model',function () {
        var allFields;
        allFields = this.get('Collector').fieldArray('diary');
        return allFields.filter(function (item) {
            return item.name.substring(0, 9) === 'workforce';
        });
    }),
    workforce_by_names: computed('workforce_fields','model', function () {
        return this.get('workforce_fields').mapBy('name');
    }),
    /*
  MOVED weather-calculation to afterModel in route, check it out!
  weather: Ember.computed 'model.project', 'model.date','model.id', ->
      if !@get('model.isNew') && @get('model.weather')
          return JSON.parse(@get('model.weather'))
      return DS.PromiseObject.create({
          promise: @fetchWeatherPosition()
      })
  */
    fetchWeatherPosition: function (model) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var projectLocation;
            projectLocation = model.get('project.location_map');
            if (projectLocation) {
                projectLocation = JSON.parse(projectLocation);
                return this.fetchWeather(
                    projectLocation.latitude,
                    projectLocation.longitude,
                    model.get('date'),
                )
                    .then(function (json) {
                        return resolve(json);
                    })
                    .catch(function (e) {
                        return reject(e);
                    });
            } else {
                return this.get('GpsLocator')
                    .getLocation()
                    .then(
                        (position) => {
                            return this.fetchWeather(
                                position.coords.latitude,
                                position.coords.longitude,
                                model.get('date'),
                            )
                                .then(function (json) {
                                    return resolve(json);
                                })
                                .catch(function (e) {
                                    return reject(e);
                                });
                        },
                        function (error) {
                            return reject(error);
                        },
                    );
            }
        });
    },
    fetchWeather: function (lat, lon, date = false) {
        var hour, url;
        hour = 12;
        url = config.APP.WEATHER_HISTORY_API;
        if (date) {
            date = moment(date).format('YYYYMMDD');
            if (date === moment().format('YYYYMMDD')) {
                url = config.APP.WEATHER_API;
                // if current time is < 12:00, use current hour for fetching weather
                if (moment().format('HH') < 12) {
                    hour = moment().format('HH');
                }
            }
            // if trying to fetch weather-info from future, just return empty promise
            if (date > moment().format('YYYYMMDD')) {
                return Em.RSVP.Promise.resolve();
            }
        } else {
            date = moment(this.get('model.date')).format('YYYYMMDD');
        }
        this.set('fetchingWeather', true);
        return $.getJSON(
            url + '?lat=' + lat + '&lon=' + lon + '&date=' + date + '&hour=' + hour,
            (json) => {
                this.set('fetchingWeather', false);
                this.set('weather', json);
                if (!json.dew_point) {
                    json.dew_point = this.calcDewPoint(json.temperature, json.humidity);
                }
                this.set('model.weather', JSON.stringify(json));
                this.set('model.weather_fetched', moment().format('YYYY-MM-DD HH:mm:ss'));
                return json;
            },
        );
    },
    calcDewPoint: function (temp, humidity) {
        var dew_point;
        dew_point = temp - (100 - humidity) / 5;
        return Math.round(dew_point * 100) / 100;
    },
    actions: {
        recordAdded: function (record) {
            return this.transitionToRoute('diary.index');
        },
        cancel: function () {
            return this.transitionToRoute('diary.index');
        },
        setWorkforceValue: function (value, field) {
            if (field) {
                return this.set('model.' + field, value);
            }
        },
        updateWeather: function () {
            var json;
            // if weather-datatype changes, change this also
            json = {};
            json.temperature = this.get('ownWeather.temperature');
            json.humidity = this.get('ownWeather.humidity');
            json.windspeed = this.get('ownWeather.windspeed');
            json.weather = this.get('ownWeather.weather');
            if (this.get('ownWeather.temperature') && this.get('ownWeather.humidity')) {
                json.dew_point = this.calcDewPoint(json.temperature, json.humidity);
                this.set('ownWeather.dew_point', json.dew_point);
            }
            return this.set('model.weather', JSON.stringify(json));
        },
        toggleOverrideWeather: function () {
            if (this.get('weather.temperature')) {
                this.set('ownWeather', this.get('weather'));
            }
            this.toggleProperty('overrideWeather');
            return false;
        },
        changeWeatherType: function (type) {
            this.set('ownWeather.weather', type);
            return this.send('updateWeather');
        },
    },
});

export default DiaryReportDateController;
