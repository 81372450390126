/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/salary-period-adder.coffee.old
// Generated by CoffeeScript 2.6.1
var SalaryPeriodAdderComponent;

import Ember from 'ember';

SalaryPeriodAdderComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    multi: false,
    amount: 2,
    period: 'month',
    from: moment().startOf('month').format('YYYY-MM-DD'),
    amount_options: Ember.computed(function () {
        return [
            {
                id: 2,
                name: this.get('intl').t('salary_period.adder.amount.2'),
            },
            {
                id: 4,
                name: this.get('intl').t('salary_period.adder.amount.4'),
            },
            {
                id: 6,
                name: this.get('intl').t('salary_period.adder.amount.6'),
            },
            {
                id: 8,
                name: this.get('intl').t('salary_period.adder.amount.8'),
            },
            {
                id: 12,
                name: this.get('intl').t('salary_period.adder.amount.12'),
            },
        ];
    }),
    period_options: Ember.computed(function () {
        return [
            {
                id: 'month',
                name: this.get('intl').t('salary_period.adder.period.month'),
            },
            {
                id: 'week2',
                name: this.get('intl').t('salary_period.adder.period.week2'),
            },
            {
                id: 'week4',
                name: this.get('intl').t('salary_period.adder.period.week4'),
            },
        ];
    }),
    actions: {
        create: function () {
            var amount, end, from, i, period, promises, records, ref, store;
            this.set('saving', true);
            this.set('disabled', true);
            store = this.get('store');
            amount = this.get('amount');
            period = this.get('period');
            from = this.get('from');
            records = [];
            for (i = 1, ref = amount; 1 <= ref ? i <= ref : i >= ref; 1 <= ref ? i++ : i--) {
                end = (function () {
                    switch (period) {
                        case 'month':
                            return moment(from)
                                .add(1, 'month')
                                .subtract(1, 'day')
                                .format('YYYY-MM-DD');
                        case 'week2':
                            return moment(from)
                                .add(2, 'week')
                                .subtract(1, 'day')
                                .format('YYYY-MM-DD');
                        case 'week4':
                            return moment(from)
                                .add(4, 'week')
                                .subtract(1, 'day')
                                .format('YYYY-MM-DD');
                    }
                })();
                records.push(
                    store.createRecord('wage-period', {
                        period_name: moment(from).format('L') + ' - ' + moment(end).format('L'),
                        period_start_date: from,
                        period_end_date: end,
                    }),
                );
                from = (function () {
                    switch (period) {
                        case 'month':
                            return moment(from).add(1, 'month').format('YYYY-MM-DD');
                        case 'week2':
                            return moment(from).add(2, 'week').format('YYYY-MM-DD');
                        case 'week4':
                            return moment(from).add(4, 'week').format('YYYY-MM-DD');
                    }
                })();
            }
            promises = records.invoke('save');
            return Ember.RSVP.Promise.all(promises).then(() => {
                if (this.get('closeEditor')) {
                    this.closeEditor(this.get('settings'));
                }
                this.set('saving', true);
                return this.set('disabled', true);
            });
        },
        setmulti: function (multi) {
            return this.set('multi', multi);
        },
        closeEditor: function () {
            if (this.get('closeEditor')) {
                return this.closeEditor(this.get('settings'));
            }
        },
        recordSaved: function (settings, record) {
            if (this.get('closeEditor')) {
                return this.closeEditor(settings, record);
            }
        },
    },
});

export default SalaryPeriodAdderComponent;
