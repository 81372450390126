/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/rows-modal/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorAddModalComponent;

import Ember from 'ember';
import { set } from '@ember/object';

CollectorAddModalComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    gui: Ember.inject.service(),
    intl: Ember.inject.service(),
    //rows: Ember.computed 'form', 'rowids.[]', ->
    //    @get('store').query @get('form'), {id: this.get('rowids').join(",")}
    async didReceiveAttrs() {
        set(this, 'loading', true);
        let parent_row_found = this.store.peekRecord(this.parent_form, this.parent_rowid);
        if (!parent_row_found) await this.store.query(this.parent_form, { id: this.parent_rowid });
        set(this, 'loading', false);
    },
    form: Ember.computed('field', function () {
        return this.get('field').options.form;
    }),
    parent_form: Ember.computed('modal', function () {
        var formfield;
        formfield = this.get('modal').split(',')[1];
        return formfield.split('.')[0];
    }),
    parent_field: Ember.computed('modal', function () {
        var formfield;
        formfield = this.get('modal').split(',')[1];
        return formfield.split('.')[1];
    }),
    field: Ember.computed('modal', function () {
        return this.get('Collector').formField(this.get('parent_form'), this.get('parent_field'));
    }),
    rows: Ember.computed('form', 'field', 'parent_rowid', function () {
        var child, parent_row;
        parent_row = this.get('store').peekRecord(
            this.get('parent_form'),
            this.get('parent_rowid'),
        );
        child = parent_row.get(this.get('parent_field'));
        if (this.get('field.type') === 'multiselect') {
            return this.get('store').query(this.get('form'), {
                id: child.join(','),
            });
        } else {
            if (this.get('field.type') === 'databasearray') {
                // for now we reload all databasearrayfields instead of material only
                this.reloadChilds(child);
            }
            return child;
        }
    }),
    reloadChilds: function (child) {
        var ids;
        ids = [];
        child.forEach((record) => {
            return ids.push(record.get('id'));
        });
        return this.get('store').query(this.get('form'), {
            id: ids.join(','),
        });
    },
    modalHeader: Ember.computed('parent_form', 'parent_field', function () {
        if (this.get('parent_form').substring(0, 18) === 'measurement_custom') {
            return this.get('intl').t('site_safety.notices');
        }
        return this.get('intl').t(
            'report.' + this.get('parent_form') + '.' + this.get('parent_field'),
        );
    }),
    main_columns: Ember.computed('field', function () {
        return this.get('field').options.fields;
    }),
    all_columns: Ember.computed('field', function () {
        var key, temp;
        temp = [];
        for (key in this.get('Collector').fields(this.get('form'))) {
            temp.push(key);
        }
        return temp;
    }),
    parent_rowid: Ember.computed('modal', function () {
        return this.get('modal').split(',').slice(2).join();
    }),
    actions: {
        modalRecordSaved: function (settings, record) {},
        toggle: function (row) {
            return row.set('show', !row.get('show'));
        },
        removeModal: function () {
            return this.set('modal', []);
        },
    },
});

export default CollectorAddModalComponent;
