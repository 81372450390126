/* eslint-disable ember/alias-model-in-controller */
import Controller from '@ember/controller';
import { get, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
    application: controller(),
    settingsController: controller('settings'),
    modelCreator: service('model-creator'),
    queryParams: ['main', 'sub'],

    qParams: computed('main', 'sub', function () {
        if (get(this, 'main') && get(this, 'sub')) {
            return get(this, 'main') + '.' + get(this, 'sub');
        }
        return false;
    }),

    actions: {
        async refreshAll(worktimegroup) {
            get(this, 'settingsController').refreshNeeded();
            if (!worktimegroup) await get(this, 'modelCreator').createEmberModels();
            this.toggleProperty('application.refreshMenus');
        },
        setQParams(params) {
            if (params) {
                let split = params.split('.');
                this.transitionToRoute({ queryParams: { main: split[0], sub: split[1] } });
            }
        },
    },
});
