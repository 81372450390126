/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexRoute, service;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

({
    inject: { service },
} = Ember);

WorksitediaryIndexRoute = GeneralReportRoute.extend({
    store: service(),
    flags: service(),
    form: 'diary',
    queryParams: {
        page: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    beforeModel: function (transition) {
        if (!this.get('Collector').testNeeds(['diary@access'])) {
            transition.abort();
            return this.transitionTo('diary.events');
        }
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-diary')) {
            params.sideload = true;
            return this.get('store').query('diary', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('diary.index').set('qParams', params);
        }
    },
});

export default WorksitediaryIndexRoute;
