/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/mvr/notices/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMvrNoticesController;

import Ember from 'ember';

import GeneralReportController from 'tt4/controllers/general-report';

import { computed } from '@ember/object';

SiteSafetyMvrNoticesController = GeneralReportController.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    intl: Em.inject.service(),
    flags: Em.inject.service(),
    mvrController: Ember.inject.controller('site_safety/report/mvr'),
    queryParams: ['status', 'page', 'employer', 'user', 'groupBy', 'project'],
    filters: computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'date',
            }),
            Em.Object.create({
                field: 'employer',
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
            Em.Object.create({
                field: 'groupBy',
                type: 'groupByFilter',
            }),
        ]);
    }),
    arrangedContent: Ember.computed('model.[]', function () {
        return this.get('content').sortBy('date');
    }),
    date: moment().subtract(3, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({}),
        //            editRoute: 'site_safety.mvr.edit'
        //            historyRoute: 'site_safety.mvr.history'
        //            hideEdit: true
        //            hideHistory: true
        massedit: true,
        rowActions: [],
    }),
    statusSelector: Ember.computed.alias('mvrController.statusSelector'),
    search: false,
    status: 'all',
    form: 'trnotice',
    showColumns: ['date', 'status', 'desc', 'user', 'project', 'pic'],
    emailValid: Em.A([]),
    emailNotValid: Em.A([]),
    sentToEmails: Em.A([]),
    extraData: {
        type: 'mvr',
    },
    init_functions: function () {
        this._super();
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            return this.addRowActions();
        }
    },
    addRowActions: function () {
        var c, fields, intl, self2;
        self2 = this;
        intl = this.get('intl');
        if (this.Collector == null) {
            return;
        }
        c = this.get('Collector');
        fields = c.fields('trnotice');
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('site_safety.action.send_notice_email'),
            action: function (self, notifications) {
                return self2.emailAction(self, self2, notifications);
            },
        });
    },
    emailAction: function (self, self2, notifications) {
        var c, emailNotValid, emailsArr, fields, intl;
        c = this.get('Collector');
        intl = this.get('intl');
        fields = c.fields('trnotice');
        emailNotValid = self2.get('emailNotValid');
        notifications.forEach(function (notification) {
            var user, username;
            if (!notification.get('email')) {
                user = notification.get('user');
                username = user.get('lastname') + ' ' + user.get('firstname');
                if (emailNotValid.indexOf(username) < 0) {
                    return emailNotValid.push(username);
                }
            } else {
                //if emailNotValid.indexOf(notification.get('email')) < 0
                //    emailNotValid.push(notification.get('user'))
                return self2.get('emailValid').push(notification);
            }
        });
        self2.set('emailModalTitle', intl.t('site_safety.action.email.modal_title'));
        //if nothing selected
        if (self2.get('emailNotValid.length') === 0 && self2.get('emailValid.length') === 0) {
            self2.set('emailModalText', intl.t('site_safety.action.email.nothing_found'));
            self2.set('showEmailModal', true);
            return;
        }
        //if only not valid emails
        if (self2.get('emailValid.length') === 0) {
            self2.set('emailModalSent', true);
            self2.set('showEmailModal', true);
            return;
        }
        //send credentials to valid email
        emailsArr = self2.get('sentToEmails');
        self2.get('emailValid').forEach(function (notification) {
            var email,
                emailContent,
                field,
                file,
                i,
                len,
                linebreak,
                obj,
                pics,
                ref,
                tmp_file,
                user,
                userString,
                username,
                value;
            user = notification.get('user');
            username =
                user.get('lastname') +
                ' ' +
                user.get('firstname') +
                ' (' +
                notification.get('email') +
                ')';
            if (emailsArr.indexOf(username) < 0) {
                emailsArr.push(username);
            }
            emailContent = [];
            emailContent.push(self2.get('intl').t('site_safety.notice.email_content.content'));
            pics = [];
            linebreak = '<br>';
            for (field in fields) {
                obj = fields[field];
                if (
                    obj['features'] != null &&
                    (obj['features']['hide'] || obj['features']['hide_column'])
                ) {
                    continue;
                }
                if (obj['type'] === 'hdfile' && notification.get(field)[0]['name']) {
                    ref = notification.get(field);
                    for (i = 0, len = ref.length; i < len; i++) {
                        file = ref[i];
                        tmp_file = {};
                        tmp_file['name'] = file['name'];
                        //tmp_file['url'] = notification.get(field)[0]['url']
                        tmp_file['s3url'] = file['s3url'];
                        tmp_file['type'] = file['type'];
                        pics.push(tmp_file);
                    }
                } else {
                    value = c.format(notification.get(field), obj, null, null, true);
                    emailContent.push(
                        self2.get('intl').t('report.trnotice.' + field) + ': ' + value,
                    );
                }
            }
            if (pics.length > 0) {
                linebreak = '\r\n';
            }
            userString = '';
            if (self2.get('session.currentUser.firstname')) {
                userString += self2.get('session.currentUser.firstname') + ' ';
            }
            if (self2.get('session.currentUser.lastname')) {
                userString += self2.get('session.currentUser.lastname') + '\n';
            }
            if (self2.get('session.currentUser.email')) {
                userString += self2.get('session.currentUser.email') + '\n';
            }
            if (self2.get('session.currentUser.phone')) {
                userString += self2.get('session.currentUser.phone') + '\n';
            }
            if (userString !== '') {
                userString = '\r\n' + userString;
                emailContent.push(userString);
            }
            email = emailContent.join(linebreak);
            return self2.Collector.ttapi({
                url: 'sendEmailWithImages',
                data: {
                    email: notification.get('email'),
                    title:
                        self2.get('session.currentUser.company') +
                        ' - ' +
                        self2.get('intl').t('site_safety.notice.email_content.title'),
                    content: email,
                    attachments: JSON.stringify(pics),
                    rowid: notification.get('id'),
                    add_link: true,
                },
                method: 'post',
            });
        });
        self2.set('emailModalSent', true);
        return self2.set('showEmailModal', true);
    },
    actions: {
        removeEmailModal: function () {
            this.set('emailModalText', null);
            this.set('showEmailModal', false);
            this.set('emailModalSent', false);
            this.set('emailValid', Em.A([]));
            this.set('emailNotValid', Em.A([]));
            return this.set('sentToEmails', Em.A([]));
        },
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        sendEmailAction: function (selected) {
            return this.emailAction(this, this, selected);
        },
    },
});

export default SiteSafetyMvrNoticesController;
