/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { typeOf, isEmpty } from '@ember/utils';

import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import configurations from '../config';
import menu from 'tt4/mixins/menu';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import Ember from 'ember';

const indexRoute = Route.extend(menu, AuthenticatedRouteMixin, {
    session: service(),
    initapp: service(),
    router: service('router'),
    localStorageWrapper: service(),
    PushNotification: service(),

    beforeModel() {
        get(this, 'PushNotification').setupPermission();

        const isLogged = get(this, 'session.isAuthenticated') ? true : false;

        if (!isLogged) {
            get(this, 'router').transitionTo('login');
            return;
        }

        get(this, 'PushNotification').register();

        // right after login tt4 is logged but not ready .. then we wait that appReady is triggered
        if (!get(this, 'initapp.appReady')) {
            return get(this, 'initapp').on('appReady', () => {
                //console.log("ok app now ready .. lets transit")
                return this.firstPageTransition();
            });
        } else {
            return this.firstPageTransition();
        }
    },

    firstPageTransition() {
        this.initapp.clearInit();

        if (
            get(this, 'session.currentUser.password') &&
            get(this, 'session.currentUser.password') !== 'secured' &&
            !this.session.session.content.authenticated.scope
        ) {
            get(this, 'router').transitionTo('changepassword');
            return;
        }

        const firstPage = get(this, 'localStorageWrapper').getUserSetting('last_page');
        if (Ember.testing && (isEmpty(firstPage) || firstPage === 'dashboard'))
            return get(this, 'router').transitionTo('my-sites');
        if (
            firstPage &&
            firstPage.length !== 0 &&
            firstPage !== 'index' &&
            this.pageInPossibleFirstPages(firstPage)
        ) {
            if (typeOf(firstPage) === 'object') {
                return get(this, 'router').transitionTo(firstPage.page, firstPage.param);
            } else {
                return get(this, 'router').transitionTo(firstPage);
            }
        } else {
            return get(this, 'router').transitionTo(this.getMenus()[0].link[0]);
        }
    },

    pageInPossibleFirstPages(page) {
        const searchPage = typeOf(page) === 'object' ? page.page : page;
        return configurations.possibleFirstPages.indexOf(searchPage) !== -1;
    },
});

export default indexRoute;
