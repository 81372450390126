/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import { computed, observer, set } from '@ember/object';

const ImportFieldMapperComponent = Component.extend({
    useAsPK: false,

    _schemaFields: computed('schemaFields', 'schemaFields.[]', function () {
        const fields = [];
        if (!this.schemaFields) {
            return;
        }
        this.schemaFields.forEach((field, key) =>
            fields.push({
                id: key,
                name: field,
            }),
        );
        return fields;
    }),

    _targetFields: computed('targetFields', 'targetFields.[]', 'usedFields.[]', function () {
        if (!this.targetFields) {
            return;
        }
        const fields = this.targetFields.filter((field) => {
            let needle;
            if (
                ((needle = field.id), Array.from(this.usedFields).includes(needle)) &&
                field.id !== this.to
            ) {
                return false;
            } else {
                return true;
            }
        });
        return fields;
    }),

    pkObserver: observer('useAsPK', function () {
        if (this.valueChanged) {
            return this.valueChanged(
                this.useAsPK,
                this.useAsPK !== this.useAsPK,
                'pk',
                this.number,
            );
        }
    }),
    init() {
        this._super(...arguments);
        this.usedFields = [];
        this.schemaFields = [];
        this.targetFields = [];
    },

    actions: {
        valueChangedTo(field) {
            const old = this.to;
            if (this.valueChanged) {
                this.valueChanged(field, old, 'to', this.number);
            }
            return set(this, 'to', field);
        },
        valueChangedFrom(field) {
            const old = this.from;
            if (this.valueChanged) {
                this.valueChanged(field, old, 'from', this.number);
            }
            return set(this, 'from', field);
        },

        valueChangedPk() {
            return console.log(this.get('useAsPK')); // eslint-disable-line
        },
    },
});

export default ImportFieldMapperComponent;
