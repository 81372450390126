/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/contract-list/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanContractListEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

TaxmanContractListEditRoute = GeneralAddRoute.extend({
    modelName: 'urakkaraportointi',
    controllerName: 'taxman/contract-list/edit',
    edit: true,
    afterSave: 'taxman.contract-list.index',
});

export default TaxmanContractListEditRoute;
