/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/removed/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersRemovedRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

UsersRemovedRoute = GeneralReportRoute.extend({
    flags: Ember.inject.service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
    },
    model: function (params) {
        if (
            !this.get('flags').test('form-report-users') &&
            !this.get('flags').test('ember-movenium-form-report-users')
        ) {
            params = this._super(params);
            params.sideload = true;
            return this.store.query('user', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('users.removed').set('qParams', params);
        }
    },
});

export default UsersRemovedRoute;
