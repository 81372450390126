import DS from 'ember-data';

export default DS.Model.extend({
    name: DS.attr('string'),
    columns: DS.attr('obj'),
    encoding: DS.attr('string'),
    separator: DS.attr('string'),
    decimal: DS.attr('string'),
    linebreak: DS.attr('string'),
    quotes: DS.attr('boolean'),
    headers: DS.attr('boolean'),
    integrationtype: DS.attr('string'),
    absences: DS.attr('boolean'),
    absencetypes: DS.attr('obj'),
    onerowabsences: DS.attr('boolean'),
});
