import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
    fieldsByName: computed('fields', function () {
        const fieldsByName = {};
        get(this, 'fields').forEach((field) => (fieldsByName[get(field, 'name')] = field));
        return fieldsByName;
    }),

    template: computed('record.user', function () {
        if (get(this, 'record.user.worktimegroup.id') === '238996')
            return [
                {
                    col1: {
                        header: 'Perusjutut',
                        fields: ['date', 'user', 'project'],
                    },
                    col2: {
                        header: 'Työaika',
                        fields: ['starttime', 'endtime'],
                    },
                },
            ];
        else
            return [
                {
                    col1: {
                        header: 'Perusjutut',
                        help: 'paljon kaikkee leipä tekstiä. paljon kaikkee leipä tekstiä. paljon kaikkee leipä tekstiä.',
                        fields: ['date', 'user', 'project'],
                    },
                    col2: {
                        header: 'Työaika',
                        fields: ['starttime', 'endtime'],
                    },
                },
                {
                    col1: {
                        header: 'Korvaukset',
                        closed: true,
                        fields: ['task'],
                    },
                    col2: {
                        header: 'Lisätiedot',
                        closed: true,
                        fields: ['multitask', 'description'],
                    },
                },
            ];
    }),
});
