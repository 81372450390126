import GeneralReportController from '../../../controllers/general-report';
import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { alias } from '@ember/object/computed';

export default GeneralReportController.extend({
    queryParams: ['page', 'q'],
    form: 'task',

    projecttask: alias('model'),

    filters: A([EmberObject.create({ type: 'searchbox', bindto: 'q' })]),
    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            editRoute: {
                modal: true,
            },
        }),
    }),
});
