/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/token.coffee.old
// Generated by CoffeeScript 2.6.1
var TokenRoute;

import Ember from 'ember';

import config from '../config/environment';

TokenRoute = Ember.Route.extend({
    queryParams: {
        url: true,
    },
    session: Em.inject.service(),
    initapp: Em.inject.service(),
    model: function (params) {
        var secure;
        this.get('initapp').clearInit();
        secure = {
            authenticated: {
                authenticator: 'authenticator:collector-auth',
                access_token: params.token,
                expires_in: 3600,
                token_type: 'Bearer',
                scope: null,
                expires_at: new Date(new Date().getTime()).getTime(),
                refresh_token: params.token,
            },
        };
        localStorage['ember_simple_auth-session'] = JSON.stringify(secure);
        this.transitionTo('loading');
        // HÄX for refreshing page after we refreshed correct auth in localstorage
        return Em.run.later(
            this,
            function () {
                this.get('initapp').clearInit();
                if (params.url) {
                    location.href = params.url;
                    return location.reload();
                } else {
                    return (location.href = config.rootURL);
                }
            },
            1000,
        );
    },
});

export default TokenRoute;
