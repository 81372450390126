/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/object-input.coffee.old
// Generated by CoffeeScript 2.6.1
var ObjectInputComponent;

import Ember from 'ember';

import { t } from 'ember-intl';

ObjectInputComponent = Ember.Component.extend({
    // Where the objects live
    objects: Em.A([]),
    session: Em.inject.service(),
    intl: Em.inject.service(),
    impersonate: Em.computed(function () {
        return this.get('session.session.content.authenticated.scope');
    }),
    // We have to convert to objects instead of plain array so we can bind in template
    placeholderObjects: Em.computed('objects.[]', function () {
        var i, len, locked, object, objects, obs;
        objects = this.get('objects');
        obs = Em.A([]);
        for (i = 0, len = objects.length; i < len; i++) {
            object = objects[i];
            if (object['id'] !== '') {
                locked = true;
            } else {
                locked = false;
            }
            obs.push({
                name: object['name'],
                id: object['id'],
                locked: locked,
                check_id: Math.random().toString(32).substr(7),
            });
        }
        return obs;
    }),
    // Save button text
    saveText: null,
    // Add button text
    addText: null,
    // You can disable all inputs and buttons
    disabled: false,
    actions: {
        // Add objects to our array
        add: function () {
            return this.get('placeholderObjects').pushObject({
                id: '',
                name: '',
            });
        },
        // Remove objects from our array
        remove: function (obj) {
            this.get('placeholderObjects').removeObject(obj);
            return this.send('save');
        },
        // Send save action with our array
        save: function () {
            var arr, i, k, len, obj, ref;
            //console.log @get('placeholderObjects')
            arr = [];
            ref = this.get('placeholderObjects');
            for (k = i = 0, len = ref.length; i < len; k = ++i) {
                obj = ref[k];
                arr.push({
                    id: obj['id'],
                    name: obj['name'],
                });
            }
            this.set('objects', arr);
            if (this.get('save')) {
                return this.save(this.get('objects'));
            }
        },
        // validate external
        validateKeys: function (obj) {
            var i, len, name, object, objects, ref, string;
            if (obj['name'] === '') {
                return Ember.set(obj, 'id', '');
            } else {
                if (!obj['locked']) {
                    name = obj['name'];
                    string = name
                        .toString()
                        .underscore()
                        .replace(/[^\w\s]/gi, '')
                        .substr(0, 20);
                    if (string === '') {
                        string = Math.random()
                            .toString(32)
                            .replace(/[^a-z]+/g, '')
                            .substr(2, 5);
                    }
                    // check if key already exists and add some random crap
                    objects = this.get('objects');
                    ref = this.get('placeholderObjects');
                    for (i = 0, len = ref.length; i < len; i++) {
                        object = ref[i];
                        if (object['check_id'] !== obj['check_id']) {
                            if (object['id'] === string) {
                                string +=
                                    '_' +
                                    Math.random()
                                        .toString(32)
                                        .replace(/[^a-z]+/g, '')
                                        .substr(2, 5);
                                break;
                            }
                        }
                    }
                    return Ember.set(obj, 'id', string);
                }
            }
        },
    },
    init: function () {
        this._super();
        this.set('saveText', this.get('intl').t('general.save'));
        return this.set('addText', this.get('intl').t('general.add'));
    },
});

export default ObjectInputComponent;
