import { isEmpty, isPresent } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { decamelize, underscore } from '@ember/string';
import { assert } from '@ember/debug';
import EmberError from '@ember/error';
import {
    IFormsInterface,
    IForms,
    IField,
    IFormField,
    IFormObjects,
} from 'tt4/ember-movenium/interfaces/forms';

/**
 * Handles all operations that have to do with collector forms
 *
 * <strong>Forms must be set for this service to function</strong>
 *
 * From your application, assign your forms to this service's forms property
 * ```js
 * @service forms;
 * this.forms.forms = <yourForms>
 * ```
 *
 * @class FormsService
 * @constructor
 * @public
 */
export default class FormsService extends Service implements IForms {
    @service private intl: any;

    /**
     * Internal property to store the forms for future use.
     * To get or set forms use `this.forms`
     * @property { object } _forms
     * @private
     */
    private _forms = {};

    constructor() {
        super(...arguments);
    }

    get forms(): IFormsInterface {
        return this._forms;
    }

    /**
     * Forms must be set for this service to function
     * From your application, assign your forms to this property
     * ```js
     * @service forms;
     * this.forms.forms = <yourForms>
     * ```
     * @property { object } forms
     */
    set forms(forms: IFormsInterface) {
        this._forms = forms;
    }

    /**
     * Returns all fields of a form
        @method fields
        @param {string} form
        @return {object}
    */
    public fields(form: string): IFormObjects {
        if (isEmpty(this.forms)) {
            return {};
        }
        if (!form) {
            {
                return {};
            }
        }

        form = decamelize(form);
        form = underscore(form);

        if (!this.forms.hasOwnProperty(form)) {
            throw new EmberError("forms service fields: form '" + form + "' not found");
        }

        return this.forms[form].objects;
    }

    /**
     * Returns the field of a form with additional properties
     * @method field
     * @param {string} form
     * @param {string} fieldName
     * @return {object}
     */
    public field(form: string, fieldName: string): IFormField {
        form = decamelize(underscore(form));
        const field = this.formField(form, fieldName);
        assert('Field ' + fieldName + ' is not found from form ' + form, field);
        return {
            form,
            name: fieldName,
            type: field.type,
            translated_name: this.intl.t(form + '.' + fieldName, { default: field.name }),
            options: field.options,
            features: field.features,
        };
    }

    /**
     * Returns the <strong>raw, unaltered</strong> field of a form
     * @method formField
     * @param {string} form
     * @param {string} field
     * @return {object}
     */
    public formField(form: string, field: string): IField {
        return this.fields(form)[field];
    }

    public fieldArray(form: string): IFormField[] {
        form = decamelize(underscore(form));
        const fields: IFormObjects = this.fields(form);
        const ret: IFormField[] = [];
        Object.keys(fields).forEach((key) => {
            const field = fields[key];
            ret.push({
                form,
                name: key,
                translated_name: this.intl.t(form + '.' + key, { default: fields[key].name }),
                type: field.type,
                options: field.options,
                features: field.features,
            });
        });
        return ret;
    }

    public formExists(name: string) {
        if (!this.forms) return;
        return this.forms[name];
    }

    public fieldExists(form: string, name: string): boolean {
        if (!this.formExists(form)) return false;
        return isPresent(this.forms[form].objects[name]);
    }
}
