/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/base-validation.coffee.old
// Generated by CoffeeScript 2.6.1
var BaseValidationMixin;

import Ember from 'ember';

import validator from '../classes/validations';

const { getOwner } = Ember;

BaseValidationMixin = Ember.Mixin.create({
    // Properties

    // Validations rules to validate fields
    // format:
    // validations = {
    //       < field name >:
    //               < validation name >: boolean|array|number|string < whatever options the validator needs/supports >
    // }
    validations: null,
    // Path to validation object. If field to validate are not on mixed class set this to the path ie 'objects' => @get('objects') and @get('objects.'+field') for validation
    validationObject: null,

    // Methods

    // The actual validation method
    validate: function (fields = []) {
        var field, options, promises, ret, rule, rules, self, validations;
        self = this;
        this.clearErrors(fields);
        promises = [];
        // Get the validation rules
        validations = this.getValidationRules(fields);
        // Loop trough the validation rules for each field
        for (field in validations) {
            rules = validations[field];
            // Execute every rule for this field
            for (rule in rules) {
                options = rules[rule];
                ret = this.executeValidations(field, rule, options);
                promises.push(ret);
            }
        }
        // When all rules are resolved we resolve the promise so you can use ex user.validate().then ->
        return Ember.RSVP.all(promises);
    },
    executeValidations: function (field, rule, options) {
        var ret, scope, validationObject, value;
        // Check if we have a validation function by same name as the rule and execute it
        if (typeof validator.get('validate_' + rule) === 'function') {
            // Get the scope and value from which we are validating
            validationObject = this.get('validationObject');
            if (validationObject) {
                scope = this.get(validationObject);
                value = this.get(validationObject + '.' + field);
            } else {
                // or get the default scope
                scope = this;
                value = this.get(field);
            }
            ret = validator['validate_' + rule](scope, value, options, field, this);
            // All validations should return a promise
            return ret.then((opt) => {
                var message;
                // If the promise returns something we assume its an error and add this to the model
                if (opt) {
                    message = this.getMessage(rule, opt);
                    return this.setError(field, message, rule);
                }
            });
        }
    },
    // We get the error message here
    getMessage: function (rule, ret) {
        var validations;
        validations = getOwner(this).lookup('service:intl');
        return validations.t('validation.error.' + rule, ret);
    },
    // Set the errors
    setError: function (field, message, rule) {
        var arr, errors;
        errors = this.get('errors');
        if (errors.get(field)) {
            return errors.set(field + '.' + rule, message);
        } else {
            arr = Em.Object.create();
            arr.set(rule, message);
            return this.set('errors.' + field, arr);
        }
    },
    // Clear the errors
    clearErrors: function (fields) {
        var field, i, len, results;
        if (fields.length > 0) {
            results = [];
            for (i = 0, len = fields.length; i < len; i++) {
                field = fields[i];
                results.push(this.set('errors.' + field, void 0));
            }
            return results;
        } else {
            return this.set('errors', Em.Object.create());
        }
    },
    // Get the validation rules and if we have fields then only get the specific validation rules
    getValidationRules: function (fields) {
        var field, i, k, len, validateThese, validations;
        validations = this.get('validations');
        // Throw error if we don't have validations on the object
        Em.assert('You need to set the validations rule on the object', validations);
        // Loop trough the given fields and only return validation rules for these fields
        if (fields.length > 0) {
            validateThese = {};
            for (k = i = 0, len = fields.length; i < len; k = ++i) {
                field = fields[k];
                validateThese[field] = validations[field];
            }
            return validateThese;
        }
        return validations;
    },
});

export default BaseValidationMixin;
