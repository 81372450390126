/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/company/route.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserCompanyRoute;

import Ember from 'ember';

RegisterUserCompanyRoute = Ember.Route.extend({
    model: function () {
        return this.modelFor('register.user');
    },
});

export default RegisterUserCompanyRoute;
