/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/comp-report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var CompReportController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import collector from '../../../classes/collector';

CompReportController = GeneralReportController.extend({
    queryParams: [
        'user',
        'employer',
        'worktime_page',
        'absence_page',
        'project',
        'group',
        'status',
        'absence_limit',
        'worktime_limit',
        'aborder',
        'row_info_status',
        'ab_row_info_status',
    ],
    gui: Ember.inject.service(),
    exportFile: Ember.inject.service(),
    worktime_page: 1,
    absence_page: 1,
    aborder: Ember.computed.alias('abcenseSettings.sortByColumn'),
    row_info_status: Ember.computed.alias('settings.showStatus'),
    ab_row_info_status: Ember.computed.alias('abcenseSettings.showStatus'),
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create(
                {
                    field: 'date',
                    type: 'date',
                    form: 'worktime',
                },
                {
                    wagePeriods: true,
                },
            ),
            Em.Object.create({
                field: 'project',
                form: 'worktime',
            }),
            Em.Object.create({
                field: 'employer',
                form: 'worktime',
            }),
            Em.Object.create({
                field: 'user',
                form: 'worktime',
            }),
            Em.Object.create({
                field: 'group',
                form: 'worktime',
            }),
        ]);
    }),
    date:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('month').format('YYYY-MM-DD'),
    project: null,
    employer: null,
    settings: Em.Object.create({
        massedit: true,
        rowSettings: Em.Object.create({
            editRoute: 'timetracker.edit',
            historyRoute: 'timetracker.history',
        }),
    }),
    init_functions: function () {
        this._super();
        return this.hideColumns();
    },
    abcenseSettings: Ember.computed('filters.@each.value', function () {
        var abFilters, c, filters;
        c = this.get('Collector');
        filters = this.get('filters');
        abFilters = Em.A([]);
        filters.forEach(function (filter) {
            var copy_filter;
            if (filter.get('field') === 'date') {
                copy_filter = Ember.Object.create({
                    field: 'startdate',
                    form: 'abcense',
                    name: 'startdate',
                    value: filter.get('value'),
                });
                return abFilters.pushObject(copy_filter);
            } else if (c.fieldExists('abcense', filter.get('name'))) {
                copy_filter = Ember.Object.create({
                    field: filter.get('field'),
                    form: 'abcense',
                    name: filter.get('name'),
                    value: filter.get('value'),
                });
                return abFilters.pushObject(copy_filter);
            }
        });
        return Em.Object.create({
            massedit: true,
            limit: this.get('abcense_limit') || 25,
            rowSettings: Em.Object.create({
                editRoute: 'abcenses.edit',
                historyRoute: {
                    modal: true,
                },
            }),
            filters: abFilters,
        });
    }),
    hideColumns: function () {
        var fields;
        fields = collector.getFormObjects('worktime');
        if (
            fields.starttime.features.hide &&
            fields.endtime.features.hide &&
            fields.work_hours.features.mandatory
        ) {
            return this.set('settings.hideColumns', ['starttime', 'endtime']);
        }
    },
    showColumnsWorktimes: ['project', 'user', 'date', 'status'],
    showColumnsAbsences: ['abcensetype', 'user', 'startdate', 'status'],
    users: Ember.computed('userSelector', 'userSelector2', function () {
        var params, tmp_users;
        tmp_users = Ember.A([
            Ember.Object.create({
                fullname: '',
            }),
        ]);
        params = {};
        params.sideload = true;
        if (this.get('employer')) {
            params.employer = this.get('employer');
        }
        params.form = 'worktime';
        params.field = 'user';
        params.limit = 'all'; // just get all users, this is only used for user-select in filter so we need to get all users, not just 100 first
        this.store.query('user', params).then((users) => {
            var userid;
            users.forEach(function (user) {
                user.set('fullname', user.get('firstname') + ' ' + user.get('lastname'));
                return tmp_users.pushObject(user);
            });
            //use first user as query parameter
            userid = tmp_users.objectAt(0)['id'];
            return this.set('user', userid);
        });
        return tmp_users;
    }),
    // filters
    userSelector2: true, // show custom user-selector
    //userSelector: true # show custom user-selector
    //    employerSelector: true
    //dateSelector: true
    filterToggler: true, // show button to toggle filters visibility on/off
    status: 'all',
    filterValues: Ember.computed('filters.@each.value', function () {
        var filterVals, filters;
        filters = this.get('filters');
        filterVals = [];
        filters.forEach(function (filter) {
            if (filter.get('value')) {
                return filterVals.push(filter.get('name') + '=' + filter.get('value'));
            }
        });
        return filterVals;
    }),
    pdfColumns: Ember.computed('absencePdfColumns.[]', 'worktimePdfColumns.[]', function () {
        var absence, worktime;
        worktime = [];
        if (this.get('worktimePdfColumns')) {
            this.get('worktimePdfColumns').forEach(function (column) {
                return worktime.push(column.ext);
            });
        }
        absence = [];
        if (this.get('absencePdfColumns')) {
            this.get('absencePdfColumns').forEach(function (column) {
                return absence.push(column.ext);
            });
        }
        return { worktime, absence };
    }),
    actions: {
        export_pdf: function () {
            var columns, params;
            params = this.get('filterValues');
            columns = this.get('pdfColumns');
            this.set('showPDFLoader', true);
            return this.get('Collector')
                .ttapi({
                    dataType: 'text',
                    data: {
                        absenceColumns: JSON.stringify(columns.absence),
                        worktimeColumns: JSON.stringify(columns.worktime),
                        lang: moment.locale(),
                    },
                    url:
                        'pdf/comboreport?type=base64&limit=all&__paper=A4-L' +
                        (params.length > 0 ? '&' + params.join('&') : ''),
                })
                .then((data) => {
                    this.set('showPDFLoader', false);
                    return this.get('exportFile').exportFile(data, 'export', 'pdf', true);
                });
        },
        filterChanged: function () {
            this.set('worktime_page', 1);
            return this.set('absence_page', 1);
        },
        setRowsPerPage: function (form, limit) {
            //key = @get('defaultLimitKey')
            this.set(form + '_page', 1);
            return this.set(form + '_limit', limit);
        },
        //@get('localStorageWrapper').setUserSetting key, limit
        setNewColumns: function (columns, form) {
            if (form === 'worktime') {
                return this.set('worktimePdfColumns', columns);
            } else {
                return this.set('absencePdfColumns', columns);
            }
        },
    },
});

export default CompReportController;
