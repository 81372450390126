import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { assert } from '@ember/debug';

export default Component.extend({
    current: 0,

    steps: computed(function () {
        var list = [];
        let pages = Array.isArray(get(this, 'pageNames'))
            ? get(this, 'pageNames').length
            : get(this, 'pages');
        assert('Wizard must have either pages(number) or pageNames(array)', pages != null);
        for (var i = 1; i <= pages; i++) {
            list.push(i);
        }
        return list;
    }),

    translatedPageNames: computed('pageNames', function () {
        if (get(this, 'pageNames')) {
            let pages = get(this, 'pageNames').map((name) => {
                return 'wizard.page.' + name;
            });
            return pages;
        } else {
            return get(this, 'steps');
        }
    }),
});
