/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/form-field-setting.coffee.old
// Generated by CoffeeScript 2.6.1
var FormFieldSetting;

import DS from 'ember-data';

import formSettings from '../mixins/form-settings';

FormFieldSetting = DS.Model.extend(formSettings, {
    intl: Ember.inject.service(),
    field: DS.belongsTo('form-field'),
    name: DS.attr('string'),
    value: DS.attr('string'),
    userlevel: DS.attr('string'),
    editable: DS.attr(),
    condition: DS.attr('string'),
    translatedName: Em.computed('name', function () {
        return this.get('intl').t('setting.type.' + this.get('name'));
    }),
});

export default FormFieldSetting;
