import Component from '@ember/component';
import { computed, get } from '@ember/object';
import collector from 'tt4/classes/collector';

export default Component.extend({
    disabledClass: computed('selected', function () {
        if (!get(this, 'selected')) return 'disabled';
        return get(this, 'selected').length > 0 ? '' : 'disabled';
    }),

    canChangeStatus: computed('form', function () {
        let field = collector.getFormObject(this.form + '.status');

        if (field && !field.features.readonly) {
            return true;
        }

        return false;
    }),

    actions: {
        toggleInlineEdit() {
            this.toggleInlineEdit();
        },

        exportPdf() {
            this.exportPdf();
        },

        exportCsv() {
            this.exportCsv();
        },

        openExportModal(type) {
            this.openExportModal(type);
        },

        saveRows() {
            this.saveRows();
        },

        showOrHideRemoved() {
            this.showOrHideRemoved();
        },

        setRowsPerPage(RowsPerPage) {
            this.setRowsPerPage(RowsPerPage);
        },

        deactivateTemplate() {
            this.deactivateTemplate();
        },

        activateTemplate(template) {
            this.activateTemplate(template);
        },

        refreshReport() {
            this.refreshReport();
        },

        changeStatus(status) {
            this.changeStatus(status);
        },

        handleLock(action) {
            this.handleLock(action);
        },

        handleRemove(action) {
            this.handleRemove(action);
        },
    },
});
