/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/my-sites.coffee.old
// Generated by CoffeeScript 2.6.1
var mySitesRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import collector from '../classes/collector';

import config from '../config/environment';

mySitesRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    beforeModel: function (transition) {
        this._super(transition);
        if (config.APP.KIOSK) {
            return this.transitionTo('my-sites');
        }
    },
});

export default mySitesRoute;
