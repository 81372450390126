/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from '@ember/object';

export default Route.extend({
    store: service(),
    flags: service(),

    queryParams: {
        page: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        selectedYear: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        worktimegroup: {
            refreshModel: true,
        },
        superior: {
            refreshModel: true,
        },
    },

    async model(params) {
        const rowsPerPage = 25;
        const offset = params.page != null ? (params.page - 1) * rowsPerPage : 0;
        delete params.page;
        params.limit = 25;
        params.offset = offset;
        params.order = 'lastname';

        params.country = get(this.store.peekAll('companyInfo'), 'firstObject.country');

        if (params.country === 'se') {
            params.start = params.selectedYear + '-04-01';
        } else {
            params.start = params.selectedYear + '-05-02';
        }

        delete params.selectedYear;

        if (!params.employer) {
            delete params.employer;
        }

        if (params.user) {
            params.id = params.user;
        }
        delete params.user;
        const url = 'api/getUsersWithHolidays';
        return {
            params: params,
            data: await this.Collector.ttapi({
                url: url,
                data: params,
            }),
        };
    },

    actions: {
        refreshModel() {
            return this.refresh();
        },
    },
});
