/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/permissions/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditPermissionsRoute;

import Ember from 'ember';

UsersEditPermissionsRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    model: function () {
        var c, filteredLinks, links, record;
        c = this.get('Collector');
        record = this.modelFor('users.edit');
        links = [
            {
                link: 'user',
                fields: ['firstname', 'lastname'],
            },
            {
                link: 'project',
                fields: ['number', 'name'],
                needs: ['project'],
            },
            {
                link: 'task',
                fields: ['name'],
                needs: ['task'],
            },
            {
                link: 'employer',
                fields: ['name'],
                needs: ['employer'],
                disabled: ['!employer@edit'], // skip employer-permissions editing if user doesn't have permissions to edit employers (ie subcontractor user)
            },
        ];
        filteredLinks = links.filter((item) => {
            var ref;
            if (item.disabled) {
                item.disabled = c.testNeeds(item.disabled);
            }
            if (item.link === 'user' && c.testNeeds(['employer'])) {
                item.fields.push('employer.name');
            }
            if (
                item.link === 'user' &&
                (ref = record.get('userlevel')) !== '6' &&
                ref !== '5' &&
                ref !== '4' &&
                ref !== '3'
            ) {
                return false;
            }
            if (item.needs) {
                return c.testNeeds(item.needs);
            }
            return true;
        });
        return Ember.RSVP.hash({
            record: record,
            linkers: filteredLinks,
        });
    },
});

export default UsersEditPermissionsRoute;
