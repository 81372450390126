/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/register-field-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserInfoComponent;

import Ember from 'ember';

RegisterUserInfoComponent = Ember.Component.extend({
    countryLists: Ember.inject.service(),
    options: Ember.computed.alias('countryLists.fullList'),
    actions: {
        change: function () {
            if (this.get('change')) {
                return this.change(this.get('field'), this.get('value'));
            }
        },
        clearErrors: function () {
            if (this.get('clearErrors')) {
                return this.clearErrors(this.get('field'));
            }
        },
    },
});

export default RegisterUserInfoComponent;
