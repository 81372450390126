import GeneralAddRoute from '../../../routes/general-add';
import { set } from '@ember/object';

const AbcensesEditRoute = GeneralAddRoute.extend({
    controllerName: 'abcenses/edit',
    modelName: 'abcense',
    edit: true,

    beforeModel(transition) {
        this._super();
        const controller = this.controllerFor('abcenses/edit');
        if (transition.from && transition.from.name === 'timetracker.comp-report') {
            return set(controller, 'afterSave', 'timetracker.comp-report');
        } else if (transition.from && transition.from.name === 'timetracker.aggregated.rows') {
            return set(controller, 'afterSave', {
                route: 'timetracker.aggregated.rows',
                params: transition.from.params.date,
            });
        } else {
            if (this.Collector.testNeeds(['mobileView', 'userlevel=1'])) {
                return set(controller, 'afterSave', 'timetracker.week-report');
            } else {
                return set(controller, 'afterSave', 'abcenses');
            }
        }
    },
});

export default AbcensesEditRoute;
