/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/menu.coffee.old
// Generated by CoffeeScript 2.6.1
var MenuMixin,
    indexOf = [].indexOf;

import Ember from 'ember';

import config from '../config';

import { singularize, pluralize } from 'ember-inflector';

MenuMixin = Ember.Mixin.create({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    flags: Ember.inject.service(),
    staticMenu: Em.computed(function () {
        var i, item, len, ref, staticMenuItems;
        staticMenuItems = [];
        ref = config.staticMenus;
        for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            staticMenuItems.push(item.link[0]);
        }
        return staticMenuItems;
    }),
    getMenus: function () {
        var active, c, dashboard, index, ls, menus, savedHideLeftMenus;
        c = this.get('Collector');
        ls = this.get('localStorageWrapper');
        menus = Em.A(this.translate(config.staticMenus).concat(this.getOwnForms()));
        active = this.get('active_menu');
        savedHideLeftMenus = ls.getUserSetting('hidden_menus');
        if ([101304].includes(this.session.currentUser.partnerid)) {
            // neste
            dashboard = menus.findBy('id', 'dashboard');
            index = menus.indexOf(dashboard);
            if (index > -1) {
                menus.splice(index, 1);
            }
        }
        menus = menus.map(function (item) {
            var current, ref, ref1;
            if (((ref = item.name), indexOf.call(savedHideLeftMenus, ref) >= 0)) {
                item.hide_leftmenu = true;
            }
            if (((ref1 = item.name), indexOf.call(savedHideLeftMenus, ref1) < 0)) {
                item.hide_leftmenu = false;
            }
            current = item.link[0].split('.')[0];
            if (current === 'site_safety') {
                current = item.link[0].split('.')[1];
                if (item.link.length === 2) {
                    current = item.link[1];
                }
            }
            if (current === 'forms') {
                current = item.link[1];
            }
            item.active = active === current;
            return item;
        });
        return c.needsOfArray(
            menus.map(function (item) {
                return Ember.Object.create(item);
            }),
        );
    },
    getSubMenus: function (route) {
        var c, current_menu, normalizedRoute, normalizedRouteArray;
        c = this.get('Collector');
        normalizedRouteArray = Em.A(route).reject(function (item) {
            return item === 'index';
        });
        normalizedRoute = normalizedRouteArray.join('.');
        if (normalizedRoute.slice(-7) === 'loading') {
            normalizedRoute = normalizedRoute.slice(0, -8);
        }
        current_menu = this.matchRoutes(normalizedRoute, this.getMenus());
        if (!current_menu) {
            current_menu = this.matchRoutes(normalizedRoute, this.getAddLinks());
        }
        if (!current_menu) {
            return;
        }
        return c.needsOfArray(current_menu.submenus).map(function (item) {
            var id;
            id = Em.isArray(item.link)
                ? item.link[0].replace(/\./g, '-')
                : item.link.replace(/\./g, '-');
            if (!item.id) {
                item.id = id;
            }
            if (!Em.isArray(item.link)) {
                item.link = [item.link];
            }
            return item;
        });
    },
    matchRoutes: function (normalizedRoute, menus) {
        var current_menu;
        current_menu = menus.find(function (item) {
            var isMainMenuRoute, isSomeOfSubroutes;
            //           test that we are in main menu component route or in some subroutes
            isMainMenuRoute = item.link[0] === normalizedRoute;
            isSomeOfSubroutes = Em.A(item.submenus).find(function (item) {
                var i, len, link, ref, subroute;
                link = Em.isArray(item.link) ? item.link[0] : item.link;
                // We can also be in a subroutes subroute
                if (link !== normalizedRoute && Em.isArray(item.subroutes)) {
                    ref = item.subroutes;
                    for (i = 0, len = ref.length; i < len; i++) {
                        subroute = ref[i];
                        if (subroute === normalizedRoute) {
                            return true;
                        }
                    }
                }
                return link === normalizedRoute;
            });
            return isMainMenuRoute || isSomeOfSubroutes;
        });
        return current_menu;
    },
    getAddLinks: function () {
        var c, links;
        c = this.get('Collector');
        links = this.translate(config.addLinkList).concat(this.getOwnForms(true));
        return c.needsOfArray(
            links.map(function (item) {
                return Ember.Object.create(item);
            }),
        );
    },
    translate: function (menus) {
        var c, intl;
        intl = this.get('intl');
        c = this.get('Collector');
        return menus.map(function (item) {
            var form, name, split;
            if (Ember.typeOf(item.text) !== 'string') {
                return item;
            }
            split = item.text.split('.');
            form = false;
            name = split[1];
            if (Ember.typeOf(name) === 'string') {
                if (name.slice(0, 4) === 'add_') {
                    name = name.slice(4);
                }
                if (c.formExists(name)) {
                    form = c.forms()[name];
                } else if (c.formExists(singularize(name))) {
                    form = c.forms()[singularize(name)];
                }
            }
            item.name = item.text;
            if (form && form.name) {
                Ember.set(item, 'translated', form.name);
            } else {
                Ember.set(item, 'translated', intl.t(item.text));
            }
            return item;
        });
    },
    saveLeftmenuHidings: function (menus) {
        var ls;
        if (!menus) {
            return;
        }
        ls = this.get('localStorageWrapper');
        return ls.setUserSetting(
            'hidden_menus',
            menus.filterBy('hide_leftmenu', true).mapBy('name'),
        );
    },
    getOwnForms: function (asAddingLinks) {
        var c, item, key, name, ref, ret, short_key;
        c = this.get('Collector');
        ret = [];
        ref = c.forms();
        for (key in ref) {
            item = ref[key];
            name = this.parseMeasurementName(key, item);
            if (
                item.privileges != null &&
                item.privileges.access != null &&
                item.privileges.access !== true
            ) {
                continue;
            }
            // if item is already found from staticMenus do not add it again here..
            if (
                this.get('staticMenu').indexOf(pluralize(key)) > -1 ||
                this.get('staticMenu').indexOf(key) > -1
            ) {
                continue;
            }
            if (name) {
                short_key = key.substring(12).toLowerCase();
                if (asAddingLinks) {
                    ret.push({
                        needs: [key + '@add'],
                        text: name,
                        translated: name,
                        link: ['site_safety.measurement.add', 'add', short_key],
                        icon: 'move move-Length',
                        id: key,
                    });
                } else {
                    //add_link_param: key.substring(12).toLowerCase()
                    ret.push({
                        needs: [key + '@add'],
                        link: ['site_safety.measurement.graphs', short_key],
                        //link_param: key.substring(12).toLowerCase()
                        icon: 'move move-Length',
                        text: name,
                        translated: name,
                        name: name,
                        id: key,
                        submenus: [
                            {
                                text: 'own_measurement.graphs',
                                link: 'site_safety.measurement.graphs',
                            },
                            {
                                text: 'own_measurement.data',
                                link: 'site_safety.measurement.data',
                            },
                            {
                                text: 'own_measurement.notices',
                                link: 'site_safety.measurement.notices',
                            },
                        ],
                    });
                }
            } else if (item.name != null) {
                if (asAddingLinks) {
                    ret.push({
                        needs: [key + '@add'],
                        text: item.name.toLowerCase(),
                        translated: item.name,
                        link: ['forms.form-add', key],
                        icon: 'move move-CopyRow',
                        id: key,
                    });
                } else {
                    //add_link_param: key
                    ret.push({
                        link: ['forms.form-report', key],
                        //param: [key]
                        icon: 'move move-CopyRow',
                        text: item.name,
                        name: item.name,
                        translated: item.name,
                        id: key,
                    });
                }
            }
        }
        return ret;
    },
    parseMeasurementName: function (key, item) {
        var name;
        if (key.substring(0, 11) !== 'measurement') {
            return;
        }
        name = key.substring(12).toLowerCase();
        if (item.name != null) {
            name = item.name;
        }
        return name;
    },
});

/*
shared_report:
    link: 'shared.report'
    icon: 'icon-TodoList'
    text: @get('intl').t("menu.shared.report")
    route: 'report'
 *        shared_stamp:
 *            link: 'shared.index'
 *            icon: 'icon-POSTerminalFilled'
 *            text: @get('intl').t("menu.shared.stamp")
 *            route: 'index'
shared_accident:
    link: 'shared.accident'
    icon: 'icon-TodoList'
    text: @get('intl').t("menu.shared.accident")
    route: 'accident'
shared_users:
    link: 'shared.users'
    icon: 'icon-TodoList'
    text: @get('intl').t("menu.shared.users")
    route: 'users'
shared_project:
    link: 'shared.project'
    icon: 'icon-GeoFence'
    text: @get('intl').t("menu.shared.project")
    route: 'project'

}
 */
export default MenuMixin;
