/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/sub/route.coffee.old
// Generated by CoffeeScript 2.6.1
var subRoute;

import Ember from 'ember';

import ResetScrollMixin from '../../../../mixins/reset-scroll';

subRoute = Ember.Route.extend(ResetScrollMixin, {
    beforeModel: function () {
        var orientations;
        orientations = this.controllerFor('orientations.add');
        // this situation occurs when orientation is saved and trying to add another one
        if (orientations.get('orientations_was_saved')) {
            return;
        }
        if (!orientations.get('users').length) {
            return this.transitionTo('orientations.add');
        }
    },
});

export default subRoute;
