/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/checkboxes/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAddCheckboxesRoute;

import Ember from 'ember';

import OrientationsSubRoute from '../sub/route';

OrientationsAddCheckboxesRoute = OrientationsSubRoute.extend({
    model: function (params) {
        var group_key, groups;
        groups = this.modelFor('orientations.add').get('checkbox_groups');
        group_key = params.group;
        if (group_key === 'last') {
            group_key = groups.length - 1;
        }
        return Ember.Object.create({
            checkboxes: groups[group_key],
            group: parseInt(group_key, 10),
            count: groups.length,
        });
    },
});

export default OrientationsAddCheckboxesRoute;
