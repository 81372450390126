import Component from '@ember/component';
import { get, set } from '@ember/object';

export default Component.extend({
    show: true,
    showHelp: false,

    didReceiveAttrs() {
        if (get(this, 'col.closed')) set(this, 'show', false);
    },
});
