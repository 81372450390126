import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { isEmpty } from '@ember/utils';
import { debounce } from '@ember/runloop';

export default Component.extend({
    store: service(),
    session: service(),
    collector: service('collector-service'),
    ttapi: service(),
    intl: service(),
    dialogs: service(),
    router: service(),
    needs: service(),
    flags: service(),

    layoutName: 'collector/form-add',
    form: 'worktime',

    _defaults: computed('defaults', async function () {
        const defaults = {};

        defaults.date = moment().format('YYYY-MM-DD');
        defaults.user = get(this, 'session.currentUser');

        const hoursAdding = this.collector.testNeedsOne('products.timetracker_hours');

        if (!hoursAdding) defaults.starttime = await this.getStarttime();
        if (!hoursAdding) defaults.endtime = moment().format('HH:mm');
        defaults.affects_overtime = 'off';

        const pauseField = this.collector
            .fieldArray('worktime')
            .find((item) => item.name === 'pause');
        // support for worktime-groups
        if (pauseField) {
            if (typeof get(pauseField, 'features.default_value') == 'object')
                defaults.pause = get(pauseField, 'features.default_value.value');
            else defaults.pause = get(pauseField, 'features.default_value');
        }

        if (this.session.currentUser.currentMySite)
            defaults.project = this.session.currentUser.currentMySite;

        return Object.assign(defaults, await get(this, 'defaults'));
    }),

    endtimeField: computed('form', function () {
        return get(this, 'collector').field('worktime', 'endtime');
    }),

    didReceiveAttrs() {
        if (!this.record) {
            !this.flags.test('ember-movenium-form-add-worktime')
                ? this.createRecord()
                : (this.record = new Promise((resolve) => {
                      resolve(this.createRecord());
                  }));
        } else if (this.record && this.record.isNew && !this.preserveRecordOnDestroy) {
            this.setRecordDefaults();
        }
    },

    actions: {
        rowAdded(record) {
            this.sendWorktimeAdded(record);
            this.alertMissingOrientation(record);
            // If we still have trailing new records, rollback them... form-add adds sometimes three of them :D
            // Addon form-add does this correctly and don't create records on its own and only assumes a record to be given
            // The real fix should be that form-add shoudn't create any record at all but it's a bit too risky to change now
            const newRecords = this.store.peekAll('worktime')?.filterBy('isNew', true);
            newRecords?.forEach((rec) => rec.rollbackAttributes());
            if (get(this, 'rowAdded')) get(this, 'rowAdded')(record);
        },

        async onFieldChange(field, value) {
            set(await this.record, field, value);
        },

        addNew() {
            this.createRecord();
        },

        async addSame() {
            set(this, 'record', this.collector.copyRecord(this.record));
        },

        touchMove() {
            // If the user touches this field and starts scrolling, we actually want to scroll and not snap to this field and open options
            debounce(this, this.touchMove, 50);
        },

        touchEnd() {
            // We have ended fiddling with this field,
            // if we were moving then blur to not snap scroll back to this field because of mobile shenanigans
            if (this.touchMoving) {
                set(this, 'touchMoving', false);
                this.element.querySelector('textarea').blur();
            }
        },

        textareaAction(event) {
            let value = event.target.value;
            set(
                this,
                'tooLongError',
                value.length > 256 ? this.intl.t('validation.too_long_error') : null,
            );
        },
    },

    touchMove() {
        set(this, 'touchMoving', true);
    },

    async setRecordDefaults() {
        const defaults = await this._defaults;
        const record = this.record;
        // only apply defaults if record value is not set already
        for (const def in defaults) {
            if (!record[def]) record[def] = defaults[def];
        }
    },

    async createRecord() {
        const defaults = await this._defaults;
        set(this, 'record', this.store.createRecord('worktime', defaults));
    },

    async getStarttime() {
        let worktimes = this.store.peekAll('worktime');
        // Try to query worktimes again with todays date if store is just empty
        if (!worktimes.length)
            worktimes = await this.store.query('worktime', { date: moment().format('YYYY-MM-DD') });
        const filtered = worktimes
            .filter((worktime) => moment(worktime.date).isSame(moment(), 'day'))
            .sortBy('endtime');
        const storeEndtime = !isEmpty(filtered) ? filtered.lastObject.endtime : null;
        if (storeEndtime) return storeEndtime;

        const starttime = await get(this, 'ttapi').request('get_starttime', { dataType: 'text' });
        if (starttime) return starttime;
        return '07:00';
    },

    sendWorktimeAdded(row) {
        get(this, 'ttapi').request('/worktimeAdded', {
            method: 'post',
            data: {
                username: get(row, 'user.username'),
                userid: get(row, 'user.id'),
                starttime: get(row, 'starttime'),
                date: get(row, 'date'),
            },
        });
    },

    async alertMissingOrientation(row) {
        // Alert for missing orientation or outdated pass
        if (
            this.collector.testNeedsOne('modules.orientations') &&
            get(this, 'session.currentUser.userlevel') == '1' &&
            !get(this, 'session.currentUser.no_orientation')
        ) {
            const project = get(row, 'project.id');
            if (this.collector.testNeedsOne('products.rakentajanpaketti')) {
                if (get(row, 'project.proj_type') === '2' || get(row, 'project.proj_type') === null)
                    return;
            }

            const user = get(row, 'user.id');
            const data = await get(this, 'store').query('orientation', {
                ori_site: project,
                ori_user: user,
                limit: 1,
            });

            if (
                this.collector.testNeedsOne('products.orientations_self') &&
                get(data, 'meta.count') == 0
            ) {
                await get(this, 'dialogs').alert(
                    get(this, 'intl').t('worktime.after_save.orientation.self_orientation'),
                );
                get(this, 'router').transitionTo(
                    'orientations.own-orientation',
                    get(row, 'project.id'),
                );
            } else if (get(data, 'meta.count') == 0) {
                get(this, 'dialogs').alert(
                    get(this, 'intl').t('worktime.after_save.orientation.not_found'),
                );
            } else if (
                get(data, 'firstObject.pass_end') &&
                moment().isAfter(moment(get(data, 'firstObject.pass_end')))
            ) {
                get(this, 'dialogs').alert(
                    get(this, 'intl').t('worktime.after_save.orientation.outdated'),
                );
            }
        }
    },
});
