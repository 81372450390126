import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class MaponRoute extends Route.extend(AuthenticatedRouteMixin, {}) {
    @service('collector-service') Collector;

    queryParams = {
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
    };

    async model(params) {
        const { date } = params;

        if (!isPresent(params.user)) return;
        if (params.user === 'null') return;

        const user = await this.store.findRecord('user', params.user);
        let from, to;
        const unit_id = get(user, 'mapon_unit_id');

        if (!unit_id) return;

        if (!date) {
            from = moment().startOf('week').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
            to = moment().endOf('week').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        } else {
            const date_parsed = date.split('_');
            from = moment(date_parsed[0]).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
            to = moment(date_parsed[1]).format('YYYY-MM-DDT') + '23:59:59Z';
        }

        const url = 'mapon?unit_id=' + unit_id + '&from=' + from + '&till=' + to;
        const data = await this.Collector.ccapi({ url });

        return data.data.units[0].routes;
    }
}
