/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/graphs/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMeasurementGraphsRoute;

import Ember from 'ember';

SiteSafetyMeasurementGraphsRoute = Ember.Route.extend({
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var parentParams;
        //        form = @modelFor 'site-safety.measurement'
        parentParams = this.paramsFor('site-safety.measurement');
        if (params.project === 'undefined') {
            delete params.project;
        }
        params.sideload = true;
        return this.store.query('measurement_' + parentParams.measurement, params);
    },
});

export default SiteSafetyMeasurementGraphsRoute;
