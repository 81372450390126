import GeneralReportRoute from 'tt4/routes/general-report';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

const WorktaskReportRoute = GeneralReportRoute.extend({
    flags: service(),
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    form: 'worktask',

    model(params) {
        params = this._super(params);

        if (!this.flags.test('ember-movenium-form-report-worktasks')) {
            params.sideload = true;
            return this.store.query('worktask', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return set(this.controllerFor('worktasks.report'), 'qParams', params);
        }
    },
});

export default WorktaskReportRoute;
