import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Addon::Modal @close={{@close}} @classes={{array \"ember-movenium-wizard\"}} as |M|>\n    <M.Header>\n        {{yield (hash\n            Header=(component \"addon/wizard/header\" )\n        )}}\n    </M.Header>\n    <M.Body>\n        {{yield (hash\n            Steps=(component \"addon/wizard/steps\" pages=@pages currentPage=this.currentPage pageChange=this.pageChange )\n            Content=(component \"addon/wizard/content\" )\n        )}}\n    </M.Body>\n    <M.Footer>\n        {{yield (hash\n            Buttons=(component \"addon/wizard/buttons\" pages=@pages close=@close currentPage=this.currentPage pageChange=this.pageChange skipCloseOnDone=@skipCloseOnDone saving=@saving )\n        )}}\n    </M.Footer>\n</Addon::Modal>\n", {"contents":"<Addon::Modal @close={{@close}} @classes={{array \"ember-movenium-wizard\"}} as |M|>\n    <M.Header>\n        {{yield (hash\n            Header=(component \"addon/wizard/header\" )\n        )}}\n    </M.Header>\n    <M.Body>\n        {{yield (hash\n            Steps=(component \"addon/wizard/steps\" pages=@pages currentPage=this.currentPage pageChange=this.pageChange )\n            Content=(component \"addon/wizard/content\" )\n        )}}\n    </M.Body>\n    <M.Footer>\n        {{yield (hash\n            Buttons=(component \"addon/wizard/buttons\" pages=@pages close=@close currentPage=this.currentPage pageChange=this.pageChange skipCloseOnDone=@skipCloseOnDone saving=@saving )\n        )}}\n    </M.Footer>\n</Addon::Modal>\n","moduleName":"tt4/components/addon/wizard.hbs","parseOptions":{"srcName":"tt4/components/addon/wizard.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IWizard {
    pages: number;
    currentPage: number;
    pageChange?(args0: number): void;
}

export default class WizardComponent extends Component<IWizard> implements IWizard {
    public pages!: number;
    @tracked public currentPage: number = 1;

    @action
    public pageChange(page: number) {
        this.currentPage = page;
        if (this.args.pageChange) this.args.pageChange(page);
    }
}
