import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
    valid: computed('record.date', function () {
        return get(this, 'record.date') ? moment(get(this, 'record.date')).format('MM/YYYY') : null;
    }),

    cardType: computed('record.type', function () {
        let type = 'work';
        let color = '#4AC94A';

        for (var item1 of this.firstAid) {
            if (this.record && this.record.type.toLowerCase().indexOf(item1) > -1) {
                type = 'firstaid';
                color = '#E64444';
            }
        }
        for (var item2 of this.safety) {
            if (this.record && this.record.type.toLowerCase().indexOf(item2) > -1) {
                type = 'safety';
                color = '#3B9C8B';
            }
        }
        for (var item3 of this.vehicle) {
            if (this.record && this.record.type.toLowerCase().indexOf(item3) > -1) {
                type = 'vehicle';
                color = '#4353B2';
            }
        }
        return {
            type: type,
            color: color,
        };
    }),
    didReceiveAttrs() {
        this.firstAid = ['Ensiavun', 'Ensiapu', 'ea1', 'ea2'];
        this.safety = ['turva', 'pelastus', 'road', 'fire', 'safety'];
        this.vehicle = ['ajo-oikeus', 'ajolupa', 'liikenteen', 'driver'];
    },
});
