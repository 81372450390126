/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterController;

import Ember from 'ember';
import config from 'tt4/config/environment';

RegisterController = Ember.Controller.extend({
    RegisterServiceController: Ember.inject.controller('register/service'),
    RegisterUserController: Ember.inject.controller('register/user'),
    logoUrl: Ember.computed(function () {
        return config.APP.CDN_URL + '/images/movenium/movenium_logo_bw_small.png';
    }),
    actions: {
        continue: function () {
            if (this.get('registerType') === 'user') {
                return this.get('RegisterUserController').send('continue');
            } else {
                return this.get('RegisterServiceController').send('continue');
            }
        },
        cancel: function () {
            return this.transitionToRoute('index');
        },
    },
});

export default RegisterController;
