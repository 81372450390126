import DS from 'ember-data';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import config from 'tt4/config/environment';
import { computed } from '@ember/object';

export default DS.JSONAPIAdapter.extend(DataAdapterMixin, {
    headers: computed('session.data.authenticated.access_token', function () {
        let headers = {};
        if (this.session.isAuthenticated) {
            // OAuth 2
            headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
        }
        return headers;
    }),

    authorizer: 'authorizer:application',
    namespace: 'cardregister',
    host: config.APP.TTAPI2,
});
