import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';


export default class WarningsComponent extends Component {
    @tracked hideAlertContent = false;

    get styleNamespace() {
        return podNames['settings/general-v2/setting-comp/components/warnings'];
    }

    @action
    toggleAlertContent() {
        this.hideAlertContent = !this.hideAlertContent;
    }

}
