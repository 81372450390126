import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { set, get } from '@ember/object';
import { cancel } from '@ember/runloop';

export default Route.extend({
    dialogs: service(),
    collector: service('collector-service'),
    store: service(),
    intl: service(),
    model(params) {
        let form = ('measurement_' + this.modelFor('site-safety.measurement.add')).camelize();

        if (get(this, 'collector').formExists(this.modelFor('site-safety.measurement.add'))) {
            form = this.modelFor('site-safety.measurement.add');
        }
        const addParams = this.paramsFor('site-safety.measurement.add');

        let record = null;
        if (addParams.id != 'add') {
            record = get(this, 'store').findRecord(form, addParams.id);
        } else {
            record = get(this, 'store').createRecord(form);
            let project = get(this, 'store').peekRecord('project', params.project);
            if (!project) {
                project = get(this, 'store').findRecord('project', params.project);
            }
            set(record, 'project', project);
        }

        return hash({
            record: record,
            form: form,
            projectid: params.project,
        });
    },
    setupController(controller) {
        this._super(...arguments);
        controller.autosave();
    },

    resetController(controller, isExiting) {
        if (isExiting && get(controller, 'timer')) {
            cancel(get(controller, 'timer'));
        }
    },

    actions: {
        willTransition(transition) {
            if (get(this, 'controller.model.record.isNew') && !get(this, 'allowTransition')) {
                transition.abort();
            } else {
                return true;
            }
            if (document.querySelector('.dialog') === null) {
                get(this, 'dialogs')
                    .confirm(get(this, 'intl').t('site_safety.measurement.confirm_leave'))
                    .then((con) => this._setTransition(con, transition));
            }
        },
    },

    _setTransition(con, transition) {
        if (con) {
            set(this, 'allowTransition', true);
            transition.retry();
        }
        set(this, 'allowTransition', false);
    },
});
