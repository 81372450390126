/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/integrationcontroller/route.coffee.old
// Generated by CoffeeScript 2.6.1
var IntegrationcontrollerRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

IntegrationcontrollerRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    model: function () {
        return this.store.findAll('integrationcontroller');
    },
});

export default IntegrationcontrollerRoute;
