import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CollectorFormAddFormsPresenceComponent extends Component {
    @service store;
    @service('collector-service') Collector;

    @tracked record;

    constructor() {
        super(...arguments);
        this.record = this.args.record
            ? this.args.record
            : this.store.createRecord('presence', { date: moment().format('YYYY-MM-DD') });
        this.filters = {
            project: { presence_project: 'on' },
        };
    }

    @action
    onFieldChange(field, value) {
        this.record[field] = value;
    }

    @action
    getField(field) {
        if (
            field.name === 'description' &&
            this.Collector.testNeedsOne('products.personalliggareandid06')
        ) {
            set(field, 'features.mandatory', true);
        }
        return field;
    }
}
