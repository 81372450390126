/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport-contract/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportAddRoute;

import Ember from 'ember';

TaxmanTaxreportAddRoute = Ember.Route.extend({
    model: function (params) {
        return params;
    },
    actions: {
        fileCreated: function () {
            return this.transitionTo('taxman.taxreport-contract');
        },
        cancel: function () {
            return this.transitionTo('taxman.taxreport-contract');
        },
    },
});

export default TaxmanTaxreportAddRoute;
