/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/form.coffee.old
// Generated by CoffeeScript 2.6.1
var Form;

import DS from 'ember-data';

import formSettings from '../mixins/form-settings';

Form = DS.Model.extend(formSettings, {
    name: DS.attr('string'),
    external_type: DS.attr('string'),
    fields: DS.hasMany('form-field'),
    settings: DS.hasMany('form-setting'),
});

export default Form;
