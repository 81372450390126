/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/date-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDateFieldComponent;

import Ember from 'ember';

import { inject as service } from '@ember/service';

import baseField from '../text-field/component';

CollectorFieldsDateFieldComponent = baseField.extend({
    evented: service(),
    dropdown_date: Ember.computed(function () {
        var arr, date, date_interval, end, i, j, ref, ref1, splitted, start;
        date_interval = this.get('field.features.date_interval');
        if (!date_interval) {
            return null;
        }
        if (date_interval === 'locked') {
            return 'locked';
        }
        arr = Em.A();
        splitted = ('' + date_interval).split(',');
        if (splitted.length > 1) {
            start = splitted[1] * -1;
            end = splitted[0];
        } else {
            start = 0;
            end = date_interval;
        }
        for (
            i = j = ref = start, ref1 = end;
            ref <= ref1 ? j < ref1 : j > ref1;
            i = ref <= ref1 ? ++j : --j
        ) {
            date = moment().subtract(i, 'days');
            arr.push(
                Ember.Object.create({
                    id: date.format('YYYY-MM-DD'),
                    name: date.format('dddd L'),
                }),
            );
        }
        return arr;
    }),
    init: function () {
        this._super();
        return this.evented.on('onResumed', this, 'updateDate');
    },
    willDestroyElement: function () {
        this._super();
        return this.evented.off('onResumed', this, 'updateDate');
    },
    updateDate: function () {
        return this.notifyPropertyChange('dropdown_date');
    },
});

export default CollectorFieldsDateFieldComponent;
