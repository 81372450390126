/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/fast-select.coffee.old
// Generated by CoffeeScript 2.6.1
var FastSelectComponent;

import Ember from 'ember';

import $ from 'jquery';

FastSelectComponent = Ember.Component.extend({
    didInsertElement: function () {
        var self;
        self = this;
        this.renderOptions();
        return $('select', this.element).change(function () {
            var optionid;
            optionid = $(this).find('option:selected').get(0).id;
            return self.set('value', optionid);
        });
    },
    renderOptions: function () {
        var content, first, prompt, select, self, value;
        self = this;
        content = this.get('content');
        select = $('select', this.element);
        prompt = this.get('prompt');
        value = this.get('value');
        if (prompt) {
            select.append('<option id="">' + prompt + '</option>');
        }
        //try to get object id
        first = content.get('firstObject.id');
        //try to get string
        if (!first) {
            first = content.get('firstObject.string');
        }
        //get whole object
        if (!first) {
            first = content.get('firstObject');
        }
        if (!prompt && !value) {
            self.set('value', first);
        }
        return content.forEach(function (item) {
            if (!item.id) {
                return select.append(
                    '<option id="' +
                        item +
                        '"' +
                        (value && (value === item || value === item.string)
                            ? ' selected="selected"'
                            : '') +
                        '>' +
                        item +
                        '</option>',
                );
            } else {
                return select.append(
                    '<option id="' +
                        item.id +
                        '"' +
                        (value && value === item.id ? ' selected="selected"' : '') +
                        '>' +
                        item.value +
                        '</option>',
                );
            }
        });
    },
    didReceiveAttrs: function () {
        if (!this.get('class')) {
            if (this.get('parentView.edit')) {
                return this.set('class', 'form-control inline-input');
            } else {
                return this.set('class', 'form-control');
            }
        }
    },
    obs: function () {
        var select;
        select = $('select', this.element);
        if (select) {
            return select.find("option[id='" + this.get('value') + "']").prop('selected', true);
        }
    }.observes('value'),
});

export default FastSelectComponent;
