import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import podNames from 'ember-component-css/pod-names';

export default class smslogsTableComponent extends Component {
    @service ttapi;

    get styleNamespace() {
        return podNames['development/notifications/log-table'];
    }

    get logs() {
        let startdate = this.args.date ? this.args.date.split('_')[0] + ':00:00' : null;
        let enddate = this.args.date ? this.args.date.split('_')[1] + ':24:00' : null;

        return this.getLogs(startdate, enddate);
    }

    async getLogs(startdate, enddate) {
        return this.ttapi.request('/messages/smslogs', {
            method: 'GET',
            data: { startdate, enddate },
        });
    }
}
