/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/dropdown-userlevel-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDropdownCountryFieldComponent;

import Ember from 'ember';

import base from '../base-field/component';

CollectorFieldsDropdownCountryFieldComponent = base.extend({
    intl: Ember.inject.service(),
    options: Ember.computed('field.options', function () {
        var i, len, new_option, option, options, real_options;
        options = this.get('field.options');
        real_options = [];
        for (i = 0, len = options.length; i < len; i++) {
            option = options[i];
            if (option) {
                if (typeof option !== 'object') {
                    new_option = {};
                    new_option.id = option;
                    option = new_option;
                }
                if (parseInt(option.id) > 9) {
                    option.name = option.id.substring(2);
                    option.id = parseInt(option.id).toString(); // to remove all but numbers (in case of own userroles)
                } else {
                    option.name = this.get('intl').t(
                        this.get('field.form') + '.' + this.get('field.name') + '.' + option.id,
                    );
                }
                real_options.push(option);
            }
        }
        return real_options;
    }),
    prompt: Ember.computed('field.prompt', function () {
        if (this.get('field.prompt.string')) {
            return this.get('field.prompt');
        }
        if (this.get('field.prompt')) {
            return this.get('intl').t(this.get('field.prompt'));
        } else {
            return this.get('intl').t('general.select_choose');
        }
    }),
    actions: {
        bubbleChange: function (value) {
            return this.sendAction('bubbleChange', value, this.get('field.name'));
        },
    },
});

export default CollectorFieldsDropdownCountryFieldComponent;
