/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-direktiv/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddNotesDirektivRoute;

import Ember from 'ember';

import baseRoute from '../base-route/route';

WorksitediaryReportAddNotesDirektivRoute = baseRoute.extend({
    templateName: 'worksitediary/report/add/notes-direktiv',
});

export default WorksitediaryReportAddNotesDirektivRoute;
