/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/cc-report.coffee.old
// Generated by CoffeeScript 2.6.1
var CcReportComponent;

import Ember from 'ember';

import pdfExport from '../mixins/pdf-export';

import chartComp from './chart-comp';

import $ from 'jquery';

import { isArray } from '@ember/array';

import { htmlSafe } from '@ember/template';

import collector from 'tt4/classes/collector';

CcReportComponent = Ember.Component.extend(pdfExport, {
    Collector: Ember.inject.service('collector-service'),
    sessionGlobals: Ember.inject.service(),
    //# integration key in CloudConnector
    key: null,
    //# Query parameters for fetching data from api
    query: null,
    //# Show data as chart
    isChart: false,
    rowsPerPage: 25,
    currentPage: 1,
    didInsertElement: function () {
        return this.refreshSettings();
    },
    refreshSettings: function () {
        var getSettings, self, settings;
        getSettings = ['key', 'query'];
        settings = this.get('settings');
        if (!settings) {
            return;
        }
        self = this;
        return $.each(getSettings, function (key, item) {
            var new_value, old_value;
            if ((!item) in settings) {
                return;
            }
            old_value = self.get(item);
            new_value = settings[item];
            if (old_value !== new_value) {
                return self.set(item, new_value);
            }
        });
    }.observes('settings.key', 'settings.query'),
    isHtml: Ember.computed('type', function () {
        return 'html' === this.get('type');
    }),
    data: Ember.computed(function () {}),
    dataFromCC: Ember.on(
        'init',
        Ember.observer('query', 'currentPage', function () {
            var c, key, promise;
            key = this.get('key');
            if (!key) {
                return null;
            }
            c = this.get('Collector');
            this.set('loading', true);
            this.set('error', false);
            this.set('error_description', null);
            this.set('data', null);
            this.set('chartData', null);
            this.set('isChart', false);
            this.set('html', null);
            promise = c.ccapi({
                url: this.createUrl(),
                method: this.requestMethod ? this.requestMethod : 'GET',
            });
            promise.then((data) => {
                var array, error;
                this.set('loading', false);
                if (this.get('type') === 'html') {
                    this.set('html', new Ember.String.htmlSafe(data));
                    return;
                }
                if (!data) {
                    this.set('data', []);
                    this.set('chartData', null);
                    this.set('isChart', false);
                    return;
                }
                try {
                    array = JSON.parse(data);
                } catch (error1) {
                    error = error1;
                    this.set('error', 'parse_error');
                    this.set('error_description', data);
                    throw new Error(
                        'Could not parse CC response for integration ' + key + ' ' + error,
                    );
                }
                this.set('pageCount', Math.ceil(array.count / this.get('rowsPerPage')));
                if (array.chart_type === 'table') {
                    this.set('data', this.getFormattedData(array.data));
                    this.set('chartData', null);
                    return this.set('isChart', false);
                } else {
                    this.set('data', null);
                    this.set('chartData', array);
                    return this.set('isChart', true);
                }
            });
            return promise;
        }),
    ),
    asPdfElement: Ember.computed(function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise;
            promise = this.dataFromCC();
            return promise.then(() => {
                var component;
                if (this.get('isChart')) {
                    component = chartComp.create({
                        api_url: this.get('url'),
                        type: 'table',
                        usecontainer: $('<div></div>'),
                    });
                    component.set('data', this.get('chartData'));
                    return resolve({
                        image: component.get('chart').getImageURI(),
                        width: 250,
                    });
                } else {
                    return resolve(
                        this.createPdfTableElement(this.get('columns'), this.get('data')),
                    );
                }
            });
        });
    }),
    asCsvArray: Ember.computed(function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise, rows;
            promise = this.dataFromCC();
            rows = [];
            return promise.then(() => {
                var column, columns, csv_data, i, j, len, len1, newrow, row;
                rows.push(this.get('columns'));
                csv_data = this.get('data');
                columns = this.get('columns');
                for (i = 0, len = csv_data.length; i < len; i++) {
                    row = csv_data[i];
                    newrow = [];
                    for (j = 0, len1 = columns.length; j < len1; j++) {
                        column = columns[j];
                        newrow.push(row[column]);
                    }
                    rows.push(newrow);
                }
                return resolve(rows);
            });
        });
    }),
    //        header: @get 'settings.header'
    createUrl: function () {
        var i, item, len, query, query_str_arr, url;
        url = this.get('key');
        if (!url) {
            return;
        }
        query = this.get('query');
        if (!query) {
            query = this.getDefaultQuery();
        }
        if (query) {
            query_str_arr = [];
            for (i = 0, len = query.length; i < len; i++) {
                item = query[i];
                if (!item.value) {
                    return;
                }
                query_str_arr.push(item.name + '=' + item.value);
            }
            url += '?' + query_str_arr.join('&');
            if (!this.get('isChart')) {
                url +=
                    '&limit=' +
                    this.get('rowsPerPage') +
                    '&offset=' +
                    this.get('rowsPerPage') * (this.get('currentPage') - 1);
            }
        }
        return url;
    },
    getDefaultQuery: function () {
        var param, param_arr, params;
        if (!this.get('query')) {
            // this is not the correct way to do this but only way I figured out :|
            // form-filters should be able to update these automatically - Veikko
            params = this.get('sessionGlobals').get('filters_worktime') || {};
            if (!params.date) {
                params.date =
                    moment().startOf('isoweek').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD');
            }
            param_arr = Em.A([]);
            for (param in params) {
                if (Em.isPresent(params[param])) {
                    param_arr.push({
                        name: param,
                        value: params[param],
                    });
                }
            }
            return param_arr;
        }
    },
    // This function formats hdfile fields as htmlSafe so they are shown correctly in report and not "[Object object]"
    getFormattedData: function (data) {
        return data.map((row) => {
            var obj;
            obj = {};
            Object.entries(row).forEach((val) => {
                var html;
                html = '';
                if (isArray(val[1])) {
                    val[1].forEach((file) => {
                        var previewFile;
                        if (file.s3thumb) {
                            return (html +=
                                '<div class="img-thumb" style="background-image: url(' +
                                file.s3thumb +
                                ')"></div>');
                        } else if (file.url) {
                            previewFile = this.get('Collector').makeFileUrl(
                                collector.api_url() + '/',
                                file.url,
                            );
                            return (html +=
                                '<a class="file-preview" href=' +
                                previewFile +
                                '>' +
                                file.name +
                                '</a>');
                        }
                    });
                    return (obj[val[0]] = html ? htmlSafe(html) : val[1]);
                } else {
                    return (obj[val[0]] = val[1]);
                }
            });
            return obj;
        });
    },
    columns: Em.computed('data', function () {
        var columns, firstRow;
        if (!this.get('data')) {
            return Em.A([]);
        }
        firstRow = this.get('data.firstObject');
        if (!firstRow) {
            return Em.A([]);
        }
        columns = Em.A([]);
        $.each(firstRow, function (key, item) {
            return columns.pushObject(key);
        });
        return columns;
    }),
});

export default CcReportComponent;
