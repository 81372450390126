import Component from '@ember/component';
import { get, set } from '@ember/object';
export default Component.extend({
    toggled: false,
    showLabels: true,
    didRender() {
        this._super();
        if (!get(this, 'toggleSize')) set(this, 'toggleSize', 'medium');
    },
    actions: {
        sendChange(value) {
            value = !value;
            set(this, 'toggled', value);
            this.changeValue(value);
        },
    },
});
