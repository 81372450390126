/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/changepassword.coffee.old
// Generated by CoffeeScript 2.6.1
var ChangepasswordRoute;

import Ember from 'ember';

import config from '../config/environment';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import configurations from '../config';

ChangepasswordRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    mySites: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    welcomeTour: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    resetController: function (controller, isExiting, transition) {
        controller.set('showModal', true);

        if (isExiting) {
            controller.set('password_changed', false);
            controller.set('currentPassword', null);
            controller.set('password', null);
            controller.set('passwordAgain', null);
        }

        return this._super;
    },
    startTour: function () {
        if (!Em.isEmpty(this.get('localStorageWrapper').getUserSetting('tourShown'))) {
            return;
        }
        if (this.get('session.currentUser.password') === 'secured') {
            return;
        }
        return this.get('welcomeTour').start();
    },
    actions: {
        changePassword: function () {
            var again, c, currentPassword, model, password, promise, userid;
            currentPassword = this.get('controller.securedPassword')
                ? this.get('controller.currentPassword')
                : this.get('session.currentUser.password');
            password = this.get('controller.password');
            again = this.get('controller.passwordAgain');
            if (!currentPassword) {
                this.set(
                    'controller.validation_error',
                    this.get('intl').t('validation.currentpasswordempty'),
                );
                return;
            }
            if (!password || !again) {
                this.set(
                    'controller.validation_error',
                    this.get('intl').t('validation.passwordempty'),
                );
                return;
            }
            if (password.length < 8) {
                this.set(
                    'controller.validation_error',
                    this.get('intl').t('validation.passwordlength'),
                );
                return;
            }
            if (password !== again) {
                this.set(
                    'controller.validation_error',
                    this.get('intl').t('validation.passwordagain'),
                );
                return;
            }
            this.set('controller.validation_error', null);
            //everything is ok so let's change the password over api
            userid = this.get('session.saveUserId');
            if (userid == null) {
                alert('changePassword: current user not found');
                return;
            }
            model = this.get('controller.user');
            model.set('password', password);
            c = this.get('Collector');
            this.set('controller.password_changed', true);
            promise = c.ajax({
                method: 'POST',
                url: 'changePassword',
                data: {
                    currentPassword: currentPassword,
                    newPassword: password,
                },
            });
            promise.then(() => {
                this.set('controller.password_changed', false);
                this.set('controller.securedPassword', true);
                model.set('password', 'secured');
                return this.transitionTo('my-sites').then(() => {
                    return this.startTour();
                });
            });
            return promise.catch((err) => {
                this.set('controller.password_changed', false);
                if (err.payload.error_msg) {
                    return this.set('controller.validation_error', 'general.error.passwordchanged');
                } else {
                    return alert('changePassword: fail ' + err);
                }
            });
        },
        change: function (value, field) {
            var model;
            model = this.get('controller.user');
            model.set(field, value);
            return Ember.run.debounce(
                this,
                function () {
                    return model.validate([field]).then(() => {
                        return this.set(
                            'controller.' + field + 'Errors',
                            model.get('errors').errorsFor(field),
                        );
                    });
                },
                150,
            );
        },
    },
});

export default ChangepasswordRoute;
