/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/own-orientation/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OwnOrientationsController;

import Ember from 'ember';

import collector from '../../../classes/collector';

OwnOrientationsController = Ember.Controller.extend({
    //    needs: ['application','orientations/add']
    session: Em.inject.service(),
    intl: Em.inject.service(),
    addController: Ember.inject.controller('orientations/add'),
    applicationController: Ember.inject.controller('application'),
    orientations: Ember.computed.alias('addController.orientations'),
    errors: 0,
    has_errors: false,
    validate_fields: ['ori_place', 'sign_per_img'],
    signatureField: Ember.computed(function () {
        return this.get('Collector').field('orientation', 'sign_per_img');
    }),
    fileField: Ember.computed('model.record.ori_site', function () {
        var field;
        field = this.get('Collector').field('project', 'file');
        field.value = this.get('model.record.ori_site.file');
        return field;
    }),
    ori_place: Em.computed(function () {
        if (this.get('model.project.city')) {
        }
    }),
    checkBoxes: Em.computed(function () {
        var arr, groups, intl;
        intl = this.get('intl');
        groups = this.get('model.checkbox_groups');
        arr = [];
        groups.forEach(function (obj) {
            var i, subArr;
            subArr = [];
            i = 0;
            obj.options.forEach(function (item) {
                var name;
                if (item) {
                    name =
                        item.get('text.string') && item.get('text.string').substring(0, 1) === '$'
                            ? intl.t('orientations.' + obj.key + '.' + item.get('key'))
                            : item.get('text');
                    return subArr.push({
                        name: name,
                    });
                }
            });
            if (subArr.length > 0) {
                return arr.push({
                    title: obj.title,
                    checkboxes: subArr,
                    length: obj.options.length,
                });
            }
        });
        return arr;
    }),
    extraFields: Ember.computed(function () {
        var extras, extras_tmp, self;
        extras_tmp = collector.forms.orientation.objects;
        extras = [];
        self = this;
        $.each(extras_tmp, function (index, obj) {
            if (obj.type !== 'checkboxgroup') {
                if (index.indexOf('addon_') >= 0) {
                    return extras.push({
                        name: obj.name,
                        value: self.get('addController.orientations.firstObject.' + index),
                    });
                }
            }
        });
        return extras;
    }),
    mandatoryArr: Em.computed('model', function () {
        var arr, form, k, v;
        form = collector.forms.orientation.objects;
        arr = {};
        for (k in form) {
            v = form[k];
            if (v.features.mandatory) {
                arr[k] = true;
            }
        }
        return arr;
    }),
    actions: {
        validate: function () {
            var field, j, len, ref;
            ref = this.get('validate_fields');
            for (j = 0, len = ref.length; j < len; j++) {
                field = ref[j];
                if (
                    (!this.get('model.record.' + field) ||
                        this.get('model.record.' + field) === null ||
                        this.get('model.record.' + field) === '') &&
                    !this.get('error_' + field)
                ) {
                    this.set('error_' + field, true);
                    this.incrementProperty('errors');
                } else if (this.get('model.record.' + field) && this.get('error_' + field)) {
                    this.set('error_' + field, false);
                    this.decrementProperty('errors');
                }
            }
            if (this.get('errors') === 0) {
                this.set('has_errors', false);
            } else {
                this.set('has_errors', true);
            }
            return false;
        },
        setSignature: function (value) {
            this.set('model.record.sign_per_img', value);
            return this.send('validate');
        },
        setFile: function (file, field) {
            return this.set('model.record.file', file);
        },
    },
});

export default OwnOrientationsController;
