import Service from '@ember/service';
import { inject as service } from '@ember/service';
import config from '../config/environment';
import { get } from '@ember/object';
/* global cordova */

export default Service.extend({
    session: service(),
    moment: service(),
    flags: service(),
    //initapp: service(),
    collector: service('collector-service'),

    // This would IMO be the more elegant solution but did not trigger at any point. Dunno why...
    // init() {
    //     this._super(...arguments)
    //     this.initapp.one('appReady', this.start())
    // },
    start() {
        this.setContext();
    },

    setContext() {
        window.dap = {};
        this.setLanguageMethod();
        this.setUserContext();
        this.setCompanyContext();
        this.getCustomerProduct();
    },

    setLanguageMethod() {
        if (!window.get_language) {
            window.get_language = function () {
                const lang = localStorage.getItem('lang')
                    ? localStorage.getItem('lang')
                    : moment.locale();
                if (lang === 'en' || !lang) {
                    return '';
                } else {
                    return lang;
                }
            };
        }
    },

    getCustomerProduct() {
        if (!window.getProduct) {
            window.getProduct = function (product) {
                return window.dap.company.products.indexOf(product) != -1 ? true : false;
            };
        }
    },

    async setUserContext() {
        const userData = this.session.currentUser;

        const userParsedData = {
            key: userData.partnerid + '' + userData.id,
            id: userData.id,
            userlevel: parseInt(userData.userlevel),
            application: window.cordova ? true : false,
            version: config.APP.version,
            userCreated: moment(get(userData.row_info, 'created')).valueOf(),
            flags: this.flags.allFlags(),
            firstname: userData.firstname,
            lastname: userData.lastname,
            language: localStorage.getItem('lang') ? localStorage.getItem('lang') : moment.locale(),
        };

        if (window.cordova) {
            userParsedData.appVersion = await cordova.getAppVersion.getVersionNumber();
        }

        window.dap.user = userParsedData;

        return;
    },

    setCompanyContext() {
        const userData = this.session.currentUser;

        let customProducts = {};
        const companyParsedData = {
            partnerid: userData.partnerid,
            company_name: userData.company,
            vatin: userData.company_info.company_vatin,
            country: userData.company_info.country,
            serviceType: config.brand,
            serviceCreated: moment(get(userData.company_info.row_info, 'created')).valueOf(),
            products: this.collector.products,
        };
        for (var product of this.collector.products) {
            customProducts[product] = 1;
        }
        window.dap.product_objects = customProducts;
        window.dap.company = companyParsedData;

        return;
    },
});
