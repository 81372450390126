import { A } from '@ember/array';
import { isPresent } from '@ember/utils';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import EmberObject, { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import AjaxService from 'ember-ajax/services/ajax';
import config from 'tt4/config/environment';

// const {inject: {service}, get, Object, ObjectProxy, PromiseProxyMixin, isPresent, A} = Ember;

export default AjaxService.extend({
    flags: service(),
    collector: service('collector-service'),
    session: service(),

    host: computed('session.currentUser.partnerid', function () {
        if (config.environment !== 'development' && get(this, 'flags').test('capi_url'))
            return get(this, 'flags').test('capi_url');
        else return config.APP.COLLECTOR_API_URL;
    }),

    headers: computed('session.session.authenticated.access_token', {
        get() {
            let headers = {};
            let token = get(this, 'session.session.authenticated.access_token');
            headers['Authorization'] = 'Bearer ' + token;
            return headers;
        },
    }),
    findAll(form, params = {}) {
        const promiseObject = ObjectProxy.extend(PromiseProxyMixin);
        const c = get(this, 'collector');
        const pluralForm = c.pluralize(form);
        return promiseObject.create({
            promise: this.request(pluralForm, { data: params }).then((data) =>
                this._returnData(form, data, params, c, pluralForm),
            ),
        });
    },
    _returnData(form, data, params, c, pluralForm) {
        let rows = [];
        if (isPresent(params.sideload)) {
            rows = c.injectSideload(pluralForm, data);
        } else if (isPresent(data[form])) {
            rows = A(data[form]);
        } else {
            rows = A(data[pluralForm]);
        }
        const EmberRows = A([]);

        rows.forEach((row) => EmberRows.pushObject(EmberObject.create(row)));

        return {
            content: EmberRows,
            meta: { count: get(data, 'count') },
        };
    },
});
