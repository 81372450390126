/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/index.coffee.old
// Generated by CoffeeScript 2.6.1
var IndexController;

import Ember from 'ember';

import configurations from '../config';

IndexController = Ember.Controller.extend({
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    LocalStorageWrapper: Ember.inject.service(),
    application: Ember.inject.controller(),
    intl: Ember.inject.service(),
    welcomeMessage: Ember.computed(
        'session.currentUser.firstname',
        'session.currentUser.lastname',
        'intl.primaryLocale',
        function () {
            var firstname, greeting, lastname;
            greeting = this.Collector.get_greeting();
            firstname = this.get('session.currentUser.firstname');
            lastname = this.get('session.currentUser.lastname');
            if (firstname) {
                return greeting + ' ' + firstname + '!';
            } else if (lastname) {
                return greeting + ' ' + lastname + '!';
            } else {
                return greeting + '!';
            }
        },
    ),
});

export default IndexController;
