import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Route.extend({
    store: service(),

    queryParams: {
        ori_site: {
            refreshModel: true,
        },
    },

    model: function (params) {
        if (!params.ori_site) return []; // we do not want to even load the data if no project is selected

        params.sideload = true;
        params.groupBy = 'parent,ori_employer';
        params.limit = 'all';
        const data = get(this, 'store').query('orientation', params);
        return data;
    },
});
