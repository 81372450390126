/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/overview/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditOverviewRoute;

import Ember from 'ember';

import DS from 'ember-data';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

ProjectsEditOverviewRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    gui: Ember.inject.service(),
    //localStorageWrapper: Ember.inject.service()
    beforeModel: function () {
        this._super(...arguments);
        return this.set('gui.hideMobileHeader', false);
    },
    model: function () {
        var c, project, sideload, store;
        //lsDbHide = @get('localStorageWrapper').getUserSetting 'hideDashboard'
        project = this.modelFor('projects.edit');
        sideload = [
            'user.firstname',
            'user.lastname',
            'user.image',
            'user.personid',
            'user.userlevel',
        ];
        store = this.get('store');
        c = this.get('Collector');
        return Ember.RSVP.hash({
            project: project,
            tr: c.testNeedsOne('tr')
                ? store.query('tr', {
                      project: project.get('id'),
                      date:
                          moment().subtract(1, 'months').format('YYYY-MM-DD') +
                          '_' +
                          moment().format('YYYY-MM-DD'),
                  })
                : void 0,
            mvr: c.testNeedsOne('mvr')
                ? store.query('mvr', {
                      project: project.get('id'),
                      date:
                          moment().subtract(1, 'months').format('YYYY-MM-DD') +
                          '_' +
                          moment().format('YYYY-MM-DD'),
                  })
                : void 0,
            worktime: c.testNeedsOne('worktime')
                ? store.query('worktime', {
                      date: moment().format('YYYY-MM-DD'),
                      project: project.get('id'),
                      sideload: sideload,
                      limit: 'all',
                  })
                : void 0,
            presence: c.testNeedsOne('presence')
                ? store.query('presence', {
                      date: moment().format('YYYY-MM-DD'),
                      project: project.get('id'),
                      sideload: sideload,
                      limit: 'all',
                      groupBy: 'user',
                      out: '(empty)',
                  })
                : void 0,
            worktask: c.testNeedsOne('worktask')
                ? store.query('worktask', {
                      date:
                          moment().startOf('week').format('YYYY-MM-DD') +
                          '_' +
                          moment().endOf('week').format('YYYY-MM-DD'),
                      project: project.get('id'),
                      sideload: ['user.image'],
                  })
                : void 0,
            projectUsers: c.testNeedsOne('employer')
                ? this.get('ttapi').request('api/project/users', {
                      data: {
                          project: project.get('id'),
                          sideload: ['employer.name', 'employer.tv_status'],
                          limit: 'all',
                      },
                  })
                : void 0,
        });
    },
});

export default ProjectsEditOverviewRoute;
