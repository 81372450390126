import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n\n    <div>\n        {{#if this.gpsWeather}}\n            {{#if this.weatherLoading}}\n                <img src=\"img/ajax-loader.gif\" alt={{t \"general.loading\"}} /> {{t \"worksitediary.fetching_weather\"}}\n            {{else}}\n                <span class=\"move move-{{@value.weather}} weather-icon\"></span>\n                    {{@value.temperature}}{{this.translations.temp}}, {{@value.windspeed}}{{this.translations.wspeed}}, {{@value.humidity}}{{this.translations.percent}}, {{t \"worksitediary.abbr_dew_point\"}}: {{@value.dew_point}}{{this.translations.temp}}\n            {{/if}}\n        {{/if}}\n    </div>\n    <div>\n        <span class=\"weather-brand\">&#8212;{{t \"general.weather_provider\"}}</span>\n    </div>\n\n    <br /><br />\n</div>", {"contents":"<div>\n\n    <div>\n        {{#if this.gpsWeather}}\n            {{#if this.weatherLoading}}\n                <img src=\"img/ajax-loader.gif\" alt={{t \"general.loading\"}} /> {{t \"worksitediary.fetching_weather\"}}\n            {{else}}\n                <span class=\"move move-{{@value.weather}} weather-icon\"></span>\n                    {{@value.temperature}}{{this.translations.temp}}, {{@value.windspeed}}{{this.translations.wspeed}}, {{@value.humidity}}{{this.translations.percent}}, {{t \"worksitediary.abbr_dew_point\"}}: {{@value.dew_point}}{{this.translations.temp}}\n            {{/if}}\n        {{/if}}\n    </div>\n    <div>\n        <span class=\"weather-brand\">&#8212;{{t \"general.weather_provider\"}}</span>\n    </div>\n\n    <br /><br />\n</div>","moduleName":"tt4/components/addon/fields/weather.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/weather.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { fetchWeather } from 'tt4/ember-movenium/utils/weather';
import GpsService from 'tt4/services/gps2';

interface IWeather {
    gps: GpsService;
    weatherLoading: boolean;
    gpsWeather: boolean;
    value: { [key: string]: string };
    onChange(args0: JSON): void;
    shouldAutomaticallyFetchNewWeather?: boolean;
}

export default class FieldsWeatherComponent extends Component<IWeather> {
    @tracked public weatherLoading: boolean = false;

    protected translations = { temp: '°C', wspeed: 'm/s', percent: '%' };
    @service('gps2' as any) declare gps: GpsService;

    constructor(owner: unknown, args: IWeather) {
        super(owner, args);
        this.gps.start();
    }

    get gpsWeather(): Promise<any> | { [key: string]: string } | null {
        if (this.args.shouldAutomaticallyFetchNewWeather === false) {
            return this.args.value;
            // return new Promise(resolve=>resolve(this.args.value))
        }

        if (this.gps.lockedPosition) {
            this.weatherLoading = true;
            const position = this.gps.lockedPosition;

            return new Promise(async (resolve) => {
                const weather = await fetchWeather(position.latitude, position.longitude);
                this.weatherLoading = false;
                this.args.onChange(weather);
                resolve(weather);
            });
        } else {
            return this.args.value;
        }
    }
}
