/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/export-abcense/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerExportAbcenseIndexController;

import Ember from 'ember';

import config from '../../../../config/environment';

TimetrackerExportAbcenseIndexController = Ember.Controller.extend({
    dialogs: Ember.inject.service(),
    capi: Ember.inject.service(),
    intl: Em.inject.service(),
    rowActions: Ember.computed('settings.row_info_status', function () {
        var actions;
        if (this.get('settings.row_info_status')) {
            actions = [
                {
                    action: 'restoreRow',
                    icon: 'move move-ReuseFilled',
                },
            ];
        } else {
            actions = [
                {
                    action: 'removeRow',
                    icon: 'move move-Delete',
                },
                {
                    action: 'historyRow',
                    icon: 'move move-Search',
                },
            ];
        }
        return actions;
    }),
    actions: {
        historyRow: function (row) {
            return this.transitionToRoute({
                queryParams: {
                    modal: ['exportAbsence', row.id, 'history'],
                },
            });
        },
        restoreRow: function (row) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_restore'))
                .then((con) => {
                    if (con) {
                        return this.get('capi')
                            .request('/exportAbsences/' + row.id + '?validation=off', {
                                dataType: 'json',
                                type: 'PUT',
                                data: '{"exportAbsence":{"row_info.status":"normal"}}',
                            })
                            .then((data) => {
                                row = this.get('store').peekRecord('export-absence', row.id);
                                return row.unloadRecord();
                            });
                    }
                });
        },
        toggleRowStatus: function () {
            if (this.get('settings.row_info_status') === 'removed') {
                return this.set('settings.row_info_status', false);
            } else {
                return this.set('settings.row_info_status', 'removed');
            }
        },
    },
    settings: Em.Object.create({
        row_info_status: false,
        form: 'export_absence',
    }),
});

export default TimetrackerExportAbcenseIndexController;
