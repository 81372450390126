/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/notice-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyNoticeComponent;

import Ember from 'ember';

SiteSafetyNoticeComponent = Ember.Component.extend({
    store: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    intl: Em.inject.service(),
    isSaved: false,
    didReceiveAttrs: function () {
        var found, i, len, option, ref;
        if (this.get('model.desc') && this.get('options')) {
            found = false;
            ref = this.get('options');
            for (i = 0, len = ref.length; i < len; i++) {
                option = ref[i];
                if (option.string === this.get('model.desc')) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                return this.set(
                    'selectedDesc',
                    this.get('intl').t('site_safety.notice.desc.other'),
                );
            } else {
                return this.set('selectedDesc', this.get('model.desc'));
            }
        }
    },
    showDropdown: Ember.computed.gt('options.length', 0),
    showDesc: Em.computed('selectedDesc', 'showDropdown', function () {
        if (!this.get('showDropdown')) {
            return true;
        }
        if (this.get('selectedDesc') === this.get('intl').t('site_safety.notice.desc.other')) {
            return true;
        }
    }),
    /*
  descObserver: Em.observer 'selectedDesc', ->
      desc = @get 'selectedDesc'
      if desc != @get('intl').t("site_safety.notice.desc.other")
          @set 'model.desc', @get 'selectedDesc'
      else
          @set 'model.desc', ""

  descObserver: Em.on('init', Em.observer('selectedDesc','showDropdown', ->
      desc =  @get 'selectedDesc'
      if @get('showDropdown')
          if desc == @get('intl').t("site_safety.notice.desc.other").string
              @set 'showDesc', true
              @set 'model.desc', ""
          else
              @set 'showDesc',false
              @set 'model.desc', desc
  ))
  */
    fields: Em.computed(function () {
        var fields, fieldsObject, key, value;
        fieldsObject = this.Collector.fields('trnotice');
        fields = [];
        for (key in fieldsObject) {
            value = fieldsObject[key];
            if ((!value.features.hide && key !== 'project') || key === 'noticedesc') {
                Em.set(value, 'ext', key);
                //Em.set value, 'label', value.name || @get('intl').t('trnotice.' + value.ext)
                switch (key) {
                    case 'desc':
                        Em.set(value, 'sortOrder', 0);
                        break;
                    case 'employer':
                        Em.set(value, 'sortOrder', 1);
                        break;
                    case 'user':
                        Em.set(value, 'sortOrder', 2);
                        break;
                    case 'date':
                        Em.set(value, 'sortOrder', 3);
                        break;
                    case 'place':
                        Em.set(value, 'sortOrder', 4);
                        break;
                    case 'noticedesc':
                        Em.set(value, 'sortOrder', 5);
                        break;
                    case 'email':
                        Em.set(value, 'sortOrder', 6);
                        break;
                    case 'pic':
                        Em.set(value, 'sortOrder', 7);
                        break;
                    default:
                        Em.set(value, 'sortOrder', 8);
                }
                fields.push(value);
            }
        }
        return fields.sortBy('sortOrder');
    }),
    setProject: Em.on(
        'init',
        Em.observer('measurement.project', function () {
            return this.set('model.project', this.get('measurement.project'));
        }),
    ),
    start: Ember.computed('model', function () {
        return moment().format('YYYY-MM-DD');
    }),
    saveText: Ember.computed(function () {
        return this.get('intl').t('site_safety.notice.save');
    }),
    fieldRoute: Em.computed('model.pic', function () {
        return {
            form: this.get('model.constructor.modelName'),
            type: 'hdfile',
            value: this.get('model.pic'),
            features: this.get('Collector').field(this.get('model.constructor.modelName'), 'pic')
                .features,
            name: 'pic',
        };
    }),
    actions: {
        customChangeValue: function (value, fieldName) {
            return this.set('model.pic', value);
        },
        changeValue: function (value, field, set = true) {
            if (field === 'email') {
                this.send('validateEmail');
            } else if (field === 'user' && this.get('model').get('user.id')) {
                this.get('store')
                    .query('user', {
                        id: this.get('model').get('user.id'),
                    })
                    .then((user) => {
                        return this.get('model').set('email', user.get('firstObject.email'));
                    });
            }
            if (set && !this.get('model.isDeleted')) {
                return this.get('model').set(field, value);
            }
        },
        validateDesc: function () {
            if (this.get('model.desc')) {
                this.set('error', false);
                return this.set('disabled', false);
            } else {
                this.set('error', this.get('intl').t('site_safety.notice.desc_missing'));
                return this.set('disabled', true);
            }
        },
        validateEmail: function () {
            var re, valid;
            this.set('error', false);
            if (this.get('model.email')) {
                re =
                    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                valid = re.test(this.get('model.email'));
                if (!valid) {
                    this.set('error', this.get('intl').t('site_safety.notice.email.not_valid'));
                }
                return this.set('saveText', this.get('intl').t('site_safety.notice.save_and_send'));
            } else {
                return this.set('saveText', this.get('intl').t('site_safety.notice.save'));
            }
        },
        saveNotice: function () {
            if (!this.get('showDesc')) {
                this.set('model.desc', this.get('selectedDesc'));
            }
            this.send('validateDesc');
            if (this.get('error')) {
                return;
            }
            this.set('isSaved', true);
            this.set('show_add_notice', true);
            this.set('notice', false);
            if (this.get('addToSavedNotices')) {
                return this.addToSavedNotices(this.get('model'));
            }
        },
        removeNotice: function () {
            var record, self;
            record = this.get('model');
            self = this;
            this.get('noticesArr').removeObject(record);
            record.rollbackAttributes();
            if (this.get('noticesArr.length') === 0) {
                self.set('notice', false);
                return self.set('edit', false);
            } else {
                return self.set('show_add_notice', true);
            }
        },
        setUser: function (user) {
            this.set('model.user', user);
            if (user.get('email')) {
                return this.set('model.email', user.get('email'));
            } else {
                return this.set('model.email', void 0);
            }
        },
    },
});

export default SiteSafetyNoticeComponent;
