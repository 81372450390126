/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saldos/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSaldosRoute;

import Ember from 'ember';

UsersSaldosRoute = Ember.Route.extend({
    localStorageWrapper: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
        all_users: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        limit: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        worktimegroup: {
            refreshModel: true,
        },
        superior: {
            refreshModel: true,
        },
        userlevel: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var defaultLimit, offset, param, param_value, qparams, rowsPerPage;
        for (param in params) {
            param_value = params[param];
            if (param_value === null || param_value === 'null' || param_value === 'undefined') {
                delete params[param];
            }
        }
        if (params.limit > 100) {
            params.limit = 100;
        } else if (!params.limit) {
            defaultLimit = this.get('localStorageWrapper').getUserSetting('saldo_defaultLimit');
            if (Ember.isPresent(defaultLimit)) {
                params.limit = defaultLimit;
            } else {
                params.limit = 25;
            }
        }
        rowsPerPage = params.limit;
        offset = params.page != null ? (params.page - 1) * rowsPerPage : 0;

        qparams = {
            offset: offset,
            limit: rowsPerPage,
            order: 'lastname',
        };
        if (params.user) {
            qparams.id = params.user;
        }
        if (params.employer) {
            qparams.employer = params.employer;
        }
        if (params.group) {
            qparams.group = params.group;
        }
        if (params.worktimegroup) {
            qparams.worktimegroup = params.worktimegroup;
        }
        if (params.superior) {
            qparams.superior = params.superior;
        }
        if (params.userlevel) {
            qparams.userlevel = params.userlevel;
        }
        if (params.date) {
            qparams.startdate = params.date.split('_')[0];
            qparams.enddate = params.date.split('_')[1];
        }
        if (params.all_users === false) {
            qparams.use_limits = true;
        }
        //@store.query 'user', qparams
        return this.ttapi.request('saldo/usersWithSaldos', {
            data: qparams,
        });
    },
    setupController: function (controller, model) {
        var defaultLimit;
        this._super(controller, model);
        defaultLimit = this.get('localStorageWrapper').getUserSetting('saldo_defaultLimit');
        if (Ember.isPresent(defaultLimit)) {
            return controller.set('limit', defaultLimit);
        }
    },
    actions: {
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default UsersSaldosRoute;
