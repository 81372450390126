/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-setting-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompComponent;

import Ember from 'ember';

CollectorFieldSettingCompComponent = Ember.Component.extend({
    store: Em.inject.service(),
    intl: Em.inject.service(),
    notifications: Ember.inject.service(),
    date_interval_setting: Ember.computed.equal('configs.setting.name', 'date_interval'),
    showSaveButton: Ember.computed(
        'setting.value',
        'setting.hasDirtyAttributes',
        'date_interval_future',
        function () {
            if (
                this.get('setting.value') >= 0 &&
                (this.get('setting.hasDirtyAttributes') || this.get('date_interval_future'))
            ) {
                return true;
            }
            return false;
        },
    ),
    didReceiveAttrs: function () {
        var configs;
        this._super();
        configs = this.get('configs');
        return this.get('store')
            .findAll('form', {
                reload: true,
            })
            .then((forms) => {
                var arr, field, form, setting;
                form = forms.filterBy('external_type', configs.form);
                field = form.get('firstObject.fields').filterBy('external_type', configs.field);
                setting = field.get('firstObject.settings').filterBy('name', configs.setting.name);
                setting.get('firstObject').rollbackAttributes(); // revert all possible changes
                if (setting.get('firstObject')) {
                    this.set('setting', setting.get('firstObject'));
                } else {
                    configs.setting.field = field.get('firstObject');
                    this.set(
                        'setting',
                        this.get('store').createRecord('form-field-setting', configs.setting),
                    );
                }
                if (this.get('setting').get('name') === 'date_interval') {
                    if (this.get('setting').get('value').split(',').length > 1) {
                        arr = this.get('setting').get('value').split(',');
                        this.get('setting').set('value', arr[0]);
                        this.set('date_interval_future', arr[1]);
                        if (arr[1]) {
                            this.set('date_interval_future_check', true);
                        }
                    }
                    if (this.get('setting').get('value') === 'locked') {
                        return this.get('setting').set('value', 1);
                    }
                }
            });
    },
    description: Em.computed('configs', function () {
        var string;
        string = `settings.${this.get('configs.form')}.${this.get('configs.field')}.${this.get('configs.setting')}`;
        if (
            this.get('intl').exists(
                `settings.${this.get('configs.form')}.${this.get('configs.field')}.${this.get('configs.setting')}`,
            )
        ) {
            return this.get('intl').t(
                `settings.${this.get('configs.form')}.${this.get('configs.field')}.${this.get('configs.setting')}`,
            );
        }
    }),
    actions: {
        save: function () {
            var old_value, setting, tmp_value, val;
            setting = this.get('setting');
            if (setting.get('value')) {
                old_value = setting.get('value');
                tmp_value = setting.get('value');
                if (this.get('configs').setting.name === 'date_interval') {
                    if (setting.get('value') === '0' || setting.get('value') === '1') {
                        tmp_value = 'locked';
                    }
                    if (
                        this.get('date_interval_future') &&
                        this.get('date_interval_future_check')
                    ) {
                        val = Math.abs(this.get('date_interval_future'));
                        tmp_value += ',' + val;
                    }
                }
                setting.set('value', tmp_value);
                return setting.save().then(() => {
                    setting.set('value', old_value);
                    return this.get('notifications').success(this.get('intl').t('general.saved'), {
                        autoClear: true,
                    });
                });
            }
        },
    },
});

export default CollectorFieldSettingCompComponent;
