import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get, set } from '@ember/object';
import { A } from '@ember/array';
import { later } from '@ember/runloop';

export default Component.extend({
    capi: service(),
    intl: service(),
    evented: service(),
    activeSetting: 'general',
    openSettings: A(),
    worktimegroupfield: computed('worktimegroup', function () {
        let field = get(this, 'Collector').field('user', 'worktimegroup');
        field.value = get(this, 'worktimegroup');
        return field;
    }),
    dropdownSettings: computed('settingGroups', function () {
        let options = [];
        this.settingGroups.forEach((group) => {
            let optionGroupOptions = [
                {
                    id: `${group.name}.settings`,
                    name: group.translation,
                },
            ];

            group.subsettingTranslations.forEach((subsetting) => {
                if (subsetting.name !== 'settings' && subsetting.name !== 'info') {
                    optionGroupOptions.push({
                        id: `${group.name}.${subsetting.name}`,
                        name: subsetting.translation,
                    });
                }
            });

            options.push({
                label: '---',
                options: optionGroupOptions,
            });
        });
        return options;
    }),

    worktimegroups: computed(async function () {
        let wtgroups = [];
        let groups = await get(this, 'capi').request('user/worktimegroups');
        wtgroups.pushObject({
            id: 'default',
            name: get(this, 'intl').t('settings.no-worktimegroup-selection'),
        });
        groups.worktimegroups.forEach((wtgroup) => {
            wtgroups.pushObject({ id: wtgroup.id, name: wtgroup.text });
        });
        return wtgroups;
    }),

    didInsertElement() {
        this._super(...arguments);
        this.evented.on('storeEvent', this, 'onStoreEvent');

        //Open all settings by default
        let settings = this.settingGroups.mapBy('name');
        set(this, 'openSettings', A(settings));
    },
    willDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
    },
    actions: {
        async changeWorktimegroup(group) {
            await this.worktimegroupChanged(group);
            if (group && group == 'default') {
                let settings = this.settingGroups.mapBy('name');
                set(this, 'openSettings', A(settings));
            }
            this.send('changeDropdownSetting', this.settingGroups[0].name);
        },

        changeDropdownSetting(value) {
            // Extract main setting and sub-setting from the value
            let [mainSetting, subSetting] = value.split('.');
            // Set the active main setting and sub-setting
            set(this, 'activeSetting', mainSetting);

            this.setOpenSettingsPage(value);

            // If it's the main setting being selected, open the default sub-setting
            if (!subSetting || subSetting === 'settings') {
                this.send('openSubSettingsPage', mainSetting, 'settings');
            } else {
                // If a specific sub-setting is selected, open it
                this.send('openSubSettingsPage', mainSetting, subSetting);
            }
        },
        searchChanged(term) {
            if (term.length >= 3) this.searchSettings(term);
        },
        clearSearch() {
            set(this, 'searchvalue', null);
            this.searchSettings(null);
        },
        gotoSetting(setting) {
            this.gotoSetting(setting.category);
            //let arr = setting.category.split(".");
            //set(this,'openSettings', [arr[0]]);
            this.send('closeSearchResults');
        },
        resumeSearch() {
            if (this.searchvalue) {
                this.send('searchChanged', this.searchvalue);
            }
        },
        closeSearchResults() {
            later(
                this,
                () => {
                    this.searchSettings(null);
                },
                250,
            );
        },

        toggleSetting(settingname) {
            // Check if the setting is already open
            if (this.openSettings.indexOf(settingname) > -1) {
                this.openSettings.removeObject(settingname);
                // Do nothing
            } else {
                // Open the setting by adding it to the array of open settings.
                this.openSettings.pushObject(settingname);
            }
        },
        openSubSettingsPage(settingsName, subsettingsName) {
            this.toggleHelpContainerVisibility('hideHelpContainer');
            if (settingsName == 'companyProfile') {
                subsettingsName = 'info';
            }
            this.setOpenSettingsPage(settingsName + '.' + subsettingsName);

            set(this, 'activeSetting', settingsName);
            settingsName;
            if (subsettingsName !== 'settings' && subsettingsName !== 'info') {
                set(this, 'activeSetting', subsettingsName);
            }
        },
    },

    onStoreEvent(params) {
        if (params.type == 'worktimegroup') {
            this.notifyPropertyChange('worktimegroups');
        }
    },
});
