import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CollectorFormAddFormsWorksitediaryComponent extends Component {
    @service store;
    @service notifications;
    @service('collector-service') Collector;

    @tracked record;
    @tracked saving;
    @tracked savedView;

    constructor() {
        super(...arguments);
        this.record = this.store.createRecord('worksitediary');
        this.fields = this.Collector.fieldArray('worksitediary');
    }

    @action
    onFieldChange(field, value) {
        this.record[field] = value;
    }

    @action
    async save() {
        this.saving = true;
        await this.record.validate();
        if (!this.record.isValid) {
            return (this.saving = false);
        }

        this.record['weather'] = JSON.stringify(this.record['weather']);
        await this.record.save();
        this.saving = false;
        this.savedView = true;
    }
}
