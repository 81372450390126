import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ember-movenium-number-input\">\n<Input\n    @type=\"number\"\n    @step=\"any\"\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @class={{@class}}\n    {{on 'change' (fn @onChange @value)}}\n/>\n<span class=\"ember-movenium-input-unit\">{{this.unit}}</span>\n</div>\n", {"contents":"<div class=\"ember-movenium-number-input\">\n<Input\n    @type=\"number\"\n    @step=\"any\"\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @class={{@class}}\n    {{on 'change' (fn @onChange @value)}}\n/>\n<span class=\"ember-movenium-input-unit\">{{this.unit}}</span>\n</div>\n","moduleName":"tt4/components/addon/fields/price.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/price.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class FieldsPriceComponent extends Component {
    @service private session: any;

    get unit(): string | null {
        if (this.session.currentUser.company_info.country === 'fi') return '€';
        else if (this.session.currentUser.company_info.country === 'se') return 'SEK';
        else return null;
    }
}
