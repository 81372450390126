import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n    @options={{this.options}}\n    @dropdownClass=\"power-select-add-field\"\n    @selected={{this.value}}\n    @onChange={{this.handleSelect}}\n    @searchEnabled={{true}}\nas |opt|>\n    {{opt.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n    @options={{this.options}}\n    @dropdownClass=\"power-select-add-field\"\n    @selected={{this.value}}\n    @onChange={{this.handleSelect}}\n    @searchEnabled={{true}}\nas |opt|>\n    {{opt.name}}\n</PowerSelect>","moduleName":"tt4/components/addon/fields/dropdown-country.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/dropdown-country.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { countries } from 'tt4/ember-movenium/utils/country';
import { sortBy } from 'tt4/ember-movenium/utils/sort';
import { IFieldComponent } from 'tt4/ember-movenium/interfaces/fields';

interface IOption {
    id: string;
    name: string;
}
export default class FieldsDropdownCountryComponent extends Component<IFieldComponent> {
    @tracked protected selected: string | null = null;
    protected _value: { id: string; name: string } | null = null;

    constructor(owner: unknown, args: IFieldComponent) {
        super(owner, args);
    }

    get options() {
        let _countries = countries;
        if (this.args.sortBy) _countries = sortBy(this.args.sortBy, _countries);
        return _countries;
    }

    get value(): IOption {
        if (this.args.value)
            return this.options.find((opt) => opt.id === this.args.value) as IOption;
        else return (this._value as IOption) || null;
    }

    set value(value: IOption) {
        this._value = value;
    }

    @action
    public handleSelect(value: any) {
        this.value = value;
        this.args.onChange(value.id);
    }
}
