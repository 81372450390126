import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n    <Input\n        @type='number'\n        @min='0'\n        @name={{concat @name '-hours'}}\n        @class='hours-and-minutes'\n        @focus-out={{fn this.changeHours}}\n        {{on 'focus' this.selectAll}}\n        @value={{readonly this.hours}}\n        @disabled={{@disabled}}\n    />\n    {{t 'general.hour_abbr'}}\n    <Input\n        @type='number'\n        @min='0'\n        @max='59'\n        @name={{concat @name '-minutes'}}\n        @class='hours-and-minutes'\n        @focus-out={{fn this.changeMinutes}}\n        {{on 'focus' this.selectAll}}\n        @value={{readonly this.minutes}}\n        @disabled={{@disabled}}\n    />\n    {{t 'general.min_abbr'}}\n</div>", {"contents":"<div>\n    <Input\n        @type='number'\n        @min='0'\n        @name={{concat @name '-hours'}}\n        @class='hours-and-minutes'\n        @focus-out={{fn this.changeHours}}\n        {{on 'focus' this.selectAll}}\n        @value={{readonly this.hours}}\n        @disabled={{@disabled}}\n    />\n    {{t 'general.hour_abbr'}}\n    <Input\n        @type='number'\n        @min='0'\n        @max='59'\n        @name={{concat @name '-minutes'}}\n        @class='hours-and-minutes'\n        @focus-out={{fn this.changeMinutes}}\n        {{on 'focus' this.selectAll}}\n        @value={{readonly this.minutes}}\n        @disabled={{@disabled}}\n    />\n    {{t 'general.min_abbr'}}\n</div>","moduleName":"tt4/components/addon/fields/hours.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/hours.hbs"}});
import Component from '@glimmer/component';
import { action, notifyPropertyChange } from '@ember/object';
import { isMobile } from 'tt4/ember-movenium/utils/gui';

interface IHoursAndMinutes {
    value: string;
    onChange(args0: string): void;
}

export default class FieldsHoursAndMinutesComponent extends Component<IHoursAndMinutes> {
    get isMobile(): boolean {
        return isMobile();
    }

    // select all numbers in input
    @action
    public selectAll(event: Event): void {
        const target = event.target as HTMLInputElement;
        target.select();
    }

    @action
    public changeHours(value: string): void {
        let val = parseInt(value, 10);

        if (isNaN(val)) val = 0;

        if (val < 0) val = 0;

        if (val < 10) {
            value = '0' + val;
        }

        const minutes = this.minutes || '00';

        this.args.onChange(value + ':' + minutes);
        notifyPropertyChange(this, 'hours');
    }

    @action
    public changeMinutes(value: string): void {
        let val = parseInt(value, 10);

        if (isNaN(val)) val = 0;

        if (val < 0 || val > 59) val = 0;

        if (val < 10) {
            value = '0' + val;
        }

        const hours = this.hours || '00';

        this.args.onChange(hours + ':' + value);
        notifyPropertyChange(this, 'minutes');
    }

    get hours(): string | null {
        if (!this.args.value) return null;
        if (this.args.value.toString().includes(':')) {
            return this.args.value?.split(':')[0];
        } else {
            return Math.floor(parseInt(this.args.value, 10)).toString();
        }
    }

    get minutes(): string | null {
        if (!this.args.value) return null;
        if (this.args.value.toString().includes(':')) {
            return this.args.value?.split(':')[1];
        } else {
            const minutes = Math.round((parseFloat(this.args.value) % 1) * 60);
            return minutes < 10 ? '0' + minutes.toString() : minutes.toString();
        }
    }
}
