/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saldocalendar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoController;

import Ember from 'ember';

const { get, set } = Ember;

UsersEditSaldoController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    queryParams: ['date', 'user', 'employer'],
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    user: null,
    form: 'worktime',
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'date',
                type: 'date',
                form: 'worktime',
                name: 'date',
                value: this.get('date'),
            }),
            Em.Object.create(
                {
                    field: 'employer',
                    type: 'database',
                    form: 'user',
                    name: 'employer',
                },
                {
                    singleselect: true,
                },
            ),
            Em.Object.create(
                {
                    field: 'user',
                    type: 'database',
                    form: 'worktime',
                    name: 'user',
                },
                {
                    singleselect: true,
                },
            ),
        ]);
    }),
    saldos: Ember.computed.alias('model.list'),
    currentSaldo: Ember.computed.alias('model.currentSaldo'),
    modalTitle: Ember.computed('showDate', function () {
        return moment(this.get('showDate')).format('dd L');
    }),
    observeFilters: Ember.observer('filters.@each.value', function () {
        return this.get('filters').forEach((item) => {
            return this.set(item.name, item.value);
        });
    }),
    insightsData: Ember.computed('showDate', function () {
        return this.get('Collector').ttapi({
            url: 'saldo/insight?date=' + this.get('showDate') + '&user=' + this.get('model.user'),
        });
    }),
    actions: {
        dayClicked: function (date) {
            if (this.get('model.list.' + date + '.saldo') !== 'not_started') {
                return this.set('showDate', date);
            }
        },
        closeModal: function () {
            return this.set('showDate', null);
        },
    },
});

export default UsersEditSaldoController;
