import Component from '@glimmer/component';
import { get } from '@ember/object';

export default class RowCellComponent extends Component {
    get isWeekend() {
        const weekday = moment(this.args.date).format('d');
        if (weekday == 0 || weekday == 6) return true;
        return false;
    }

    get colorString() {
        let row = this.args.row.firstObject;
        let string = this.args.colorFields.reduce((acc, current) => {
            acc += get(row, current);
            return acc;
        }, '');

        return string;
    }
}
