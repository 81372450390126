/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/country-flag.coffee.old
// Generated by CoffeeScript 2.6.1
var CountryFlagHelper;

import Ember from 'ember';

CountryFlagHelper = Ember.Helper.extend({
    countryLists: Ember.inject.service(),
    compute: function (params, hash) {
        var flag;
        if (!params[0]) {
            return Ember.String.htmlSafe(
                '<span class="glyphicon glyphicon-globe" aria-hidden="true"></span>',
            );
        }
        flag = false;
        if (params[1] === 'number') {
            this.get('countryLists.phoneList').forEach(function (country) {
                if (params[0].substring(0, country.number.length) === country.number) {
                    return (flag = country.id);
                }
            });
            if (!flag) {
                return Ember.String.htmlSafe(
                    '<span class="glyphicon glyphicon-globe" aria-hidden="true"></span>',
                );
            }
        } else {
            console.info(
                "We don't have flags for every country, you might want to check if the country flag is included",
            );
            flag = params[0];
        }
        return Ember.String.htmlSafe('<span class="flag-icon flag-icon-' + flag + '"></span>');
    },
});

export default CountryFlagHelper;
