/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/password/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PasswordIndexController;

import Ember from 'ember';

import config from '../../../config/environment';

import initController from '../../../mixins/init-controller';

PasswordIndexController = Ember.Controller.extend(initController, {
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    localStorageWrapper: Ember.inject.service(),
    errorMessage: null,
    loadingMessage: null,
    okMessage: null,
    init_functions: function () {
        var ls, username;
        this._super();
        ls = this.get('localStorageWrapper');
        username = ls.getSavedUsername();
        return this.set('savedUsername', username);
    },
    actions: {
        cancel: function () {
            return this.transitionToRoute('login');
        },
        sendLink: function (type) {
            var intl, req, self, username;
            intl = this.get('intl');
            this.set('errorMessage', null);
            this.set('loadingMessage', intl.t('password.sending_recovery_link'));
            self = this;
            username = this.get('savedUsername');
            req = this.Collector.ttapi({
                url: 'password_recovery/send',
                data: {
                    username: username,
                    type: type,
                    language: moment.locale(),
                    callback_url: config.APP.password_recovery_callbackurl,
                },
                method: 'post',
            });
            return req.then(
                function (resp) {
                    var message;
                    self.set('loadingMessage', null);
                    if (resp.sms_sent) {
                        message = intl.t('password.link_sent_to_number');
                        self.set('okMessage', message);
                    }
                    if (resp.email_sent) {
                        message = intl.t('password.link_sent_to_email');
                        return self.set('okMessage', message);
                    }
                },
                (reason) => {
                    var message;
                    message = intl.t('password.link_sending_failed');
                    return this.set('errorMessage', message);
                },
            );
        },
    },
});

export default PasswordIndexController;
