import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const DevelopmentOwnrolesRoute = Route.extend({
    dialogs: service(),
    intl: service(),

    beforeModel() {
        return this.transitionTo('development.ownroles');
    },

    model() {
        return this.store.findAll('form');
    },

    actions: {
        willTransition(transition) {
            if (transition.targetName.substring(0, 11) !== 'development') {
                return transition.then(() => {
                    if (this.controller._refreshNeeded) {
                        return this.dialogs
                            .alert(this.intl.t('general.settings_refresh_needed'))
                            .then(() => location.reload());
                    }
                });
            }
        },
    },
});

export default DevelopmentOwnrolesRoute;
