import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';

export default class CollectorFormAddFormsWorktimegroupChangeHelpComponent extends Component {
    @service('collector-service') Collector;
    @service ajax;

    @tracked help;
    @tracked loading = false;

    constructor() {
        super(...arguments);
        this.help = this.getHelp();
        Notification.requestPermission();
    }

    async getHelp() {
        this.loading = true;
        let helpText = '';
        const key = 'help-worktimegroup-changes-' + moment.locale();
        const fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;
        try {
            helpText = htmlSafe(await this.ajax.request(fileUrl, { dataType: 'text' }));
        } catch (e) {
            if (this.Collector.testNeeds(['development'])) {
                helpText = `cannot find help for ${key}`;
            }
        }
        this.loading = false;
        return helpText;
    }
}
