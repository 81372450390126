/* eslint-disable ember/no-observers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import EmberObject, { set, computed, observer } from '@ember/object';
import { A } from '@ember/array';
import { on } from '@ember/object/evented';

const MessageboardController = Controller.extend({
    Collector: service('collector-service'),
    intl: service(),
    session: service(),
    dialogs: service(),
    ttapi: service(),
    error: service(),

    rowsPerPage: 30,
    currentPage: 1,

    showRemoved: false,

    init() {
        this._super(...arguments);
        this.columns = ['date', 'header', 'forced'];
        this.queryParams = ['showRemoved'];
        this.rowActions = [
            { action: 'viewRow', icon: 'move move-View' },
            { action: 'showHistory', icon: 'move move-Search' },
        ];
    },

    popupArr: A([]),
    messageboards: alias('model.messageboards'),
    data: computed('messageboards.[]', 'popupArr.[]', function () {
        const data = this.messageboards;
        if (!data) {
            return null;
        }

        const columns = this.columns;
        const formatted_data = A([]);
        data.forEach((unformatted_row) => {
            const row = EmberObject.create();
            if (unformatted_row.id) {
                row.id = unformatted_row.id;
            }

            if (!unformatted_row.read) {
                row.class = 'warning';
            }

            if (unformatted_row.row_info.status === 'removed') {
                row.class = 'danger';
            }

            columns.forEach((column) => {
                let cell = unformatted_row[column];

                if (column === 'date') {
                    cell = moment(cell).format('L');
                }

                if (column === 'forced') {
                    cell = (() => {
                        switch (cell) {
                            case '1':
                            case true:
                                return this.intl.t('general.yes');
                            default:
                                return this.intl.t('general.no');
                        }
                    })();
                }

                return (row[column] = cell);
            });

            return formatted_data.pushObjects([row]);
        });

        return formatted_data;
    }),

    rowActionsObs: on(
        'init',
        observer('showRemoved', function () {
            const session = this.session;
            if (session) {
                if (this.Collector.getUserlevel(session) === 5) {
                    let arr = [
                        { action: 'viewRow', icon: 'move move-View' },
                        { action: 'removeRow', icon: 'move move-Delete' },
                        { action: 'showHistory', icon: 'move move-Search' },
                    ];

                    if (this.showRemoved) {
                        arr = [
                            { action: 'viewRow', icon: 'move move-View' },
                            { action: 'showHistory', icon: 'move move-Search' },
                        ];
                    }

                    return set(this, 'rowActions', arr);
                }
            }
        }),
    ),

    // get translation for every column
    translated_columns: computed('columns', function () {
        const translated_columns = [];
        this.columns.forEach((item) => {
            return translated_columns.push(this.intl.t('report.messageboard.' + item));
        });

        return translated_columns;
    }),

    actions: {
        catchActions(action, params) {
            return this.send(action, params);
        },

        async removeRow(params) {
            const con = await this.dialogs.confirm(this.intl.t('report.confirm_delete'));
            if (con) {
                await this.removeMessage(params.id);
                const obj = this.messageboards.findBy('id', params.id);
                return this.messageboards.removeObject(obj);
            }
        },

        viewRow(params) {
            //mark as read
            const obj = this.messageboards.findBy('id', params.id);
            obj.read = true;

            return this.popupArr.pushObject(obj);
        },

        columnClicked() {
            set(this, 'order', null);
        },

        showHistory(params) {
            return this.transitionToRoute('messageboard.history', params.id);
        },

        showRemovedRows() {
            return this.toggleProperty('showRemoved');
        },
    },

    async removeMessage(id) {
        try {
            const params = {};
            params.rowid = id;
            return await this.ttapi.request('/messageboard', {
                type: 'DELETE',
                data: params,
            });
        } catch (reason) {
            return this.error.notify(reason);
        }
    },
});

export default MessageboardController;
