/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/month-dropdown-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsMonthDropdownFieldComponent;

import Ember from 'ember';

import baseField from '../base-field/component';

CollectorFieldsMonthDropdownFieldComponent = baseField.extend({
    selectedMonth: 0,
    //get 36 previous months
    months: Ember.computed(function () {
        var count, montharr, obj;
        count = 1;
        montharr = [];
        while (count < 36) {
            obj = {
                id: this.get('useAsFilter')
                    ? moment()
                          .subtract(count - 1, 'months')
                          .startOf('month')
                          .format('YYYY-MM')
                    : moment()
                          .subtract(count - 1, 'months')
                          .startOf('month')
                          .format('YYYY-MM-DD'),
                name: moment()
                    .subtract(count - 1, 'months')
                    .format('MMMM YYYY'),
            };
            count++;
            montharr.push(obj);
        }
        return montharr;
    }),
    actions: {
        valueChanged: function (value) {
            return this.bubbleChange(value, this.get('field.name'));
        },
    },
});

export default CollectorFieldsMonthDropdownFieldComponent;
