import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

export default Component.extend({
    intl: service(),

    tagName: 'thead',
    classNames: ['settings-table-header'],

    translatedColumns: computed('columns', function () {
        let columns = get(this, 'columns');
        let form = get(this, 'form');
        assert(
            'You must give columns as an array to the table component like {{#table columns=["name", "address", "zip"]}}',
            Array.isArray(columns),
        );
        assert(
            'You must give a form to the table component like {{#table form="form"}}',
            typeof form === 'string' && form != '',
        );
        let translated = columns.map((column) => {
            return get(this, 'intl').t('report.' + get(this, 'form') + '.' + column);
        });
        return translated;
    }),
});
