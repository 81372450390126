/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/measurement_custom/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsMeasurementCustomRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SettingsMeasurementCustomRoute = Ember.Route.extend(AuthenticatedRouteMixin, {});

export default SettingsMeasurementCustomRoute;
