/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsController;

import Ember from 'ember';

import config from '../../config';

SettingsController = Ember.Controller.extend({
    _refreshNeeded: false,
    refreshNeeded: function () {
        console.info('refresh needed');
        return this.set('_refreshNeeded', true);
    },
});

export default SettingsController;
