/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/form-setting.coffee.old
// Generated by CoffeeScript 2.6.1
var FormSetting;

import DS from 'ember-data';

import formSettings from '../mixins/form-settings';

FormSetting = DS.Model.extend(formSettings, {
    form: DS.belongsTo('form'),
    name: DS.attr('string'),
    value: DS.attr('string'),
    userlevel: DS.attr('string'),
    editable: DS.attr(),
    condition: DS.attr('string'),
});

export default FormSetting;
