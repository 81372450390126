/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/worktime-list/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerWorktimeListRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

TimetrackerWorktimeListRoute = GeneralReportRoute.extend({
    collector: Ember.inject.service('collector-service'),
    capi: Ember.inject.service(),
    flags: Ember.inject.service(),
    form: 'worktime',
    localStorageKey: 'worktime-list',
    queryParams: {
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        typeofwork: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('form-report-worktimes')) {
            if (Ember.isPresent(params.groupBy)) {
                params.sideload = true;
                // This can be moved to mixin when wanted in other places also
                params.order = params.groupBy;
                return this.get('capi').findAll('worktime', params);
            } else {
                params.sideload = true;
                return this.store.query('worktime', params);
            }
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('timetracker.worktime-list').set('qParams', params);
        }
    },
});

export default TimetrackerWorktimeListRoute;
