/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/activitylog/route.coffee.old
// Generated by CoffeeScript 2.6.1
var DevelopmentActivitylogRoute;

import Ember from 'ember';

DevelopmentActivitylogRoute = Ember.Route.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        owner: {
            refreshModel: true,
        },
        _creator: {
            refreshModel: true,
        },
        action: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var query;
        query = {
            limit: 25,
            offset: params.page != null ? (params.page - 1) * 25 : 0,
            order: 'timestamp-',
        };
        if (params.owner) {
            query.owners = params.owner;
        }
        if (params._creator) {
            query._creator = params._creator;
        }
        if (params.action) {
            query.action = params.action;
        }
        return this.store.query('user_activity_log', query);
    },
});

export default DevelopmentActivitylogRoute;
