import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { set } from '@ember/object';

const MachinesTransactionProjectRoute = Route.extend({
    store: service(),
    Collector: service('collector-service'),
    init() {
        this._super(...arguments);
        const qp = {
            statusSearch: {
                refreshModel: true,
            },
        };
        const c = this.Collector;
        c.fieldArray('transaction').forEach((item) => {
            if (
                ['database', 'dropdown_values'].includes(item.type) &&
                !['project', 'status', 'tooltype'].includes(item.name)
            ) {
                return (qp[item.name] = { refreshModel: true });
            }
        });

        return set(this, 'queryParams', qp);
    },

    model(params) {
        let groups, transactions;
        if (params.statusSearch) {
            params.status = params.statusSearch;
        }
        delete params.statusSearch;
        //        params.groupBy =
        params.endtime = '(empty)';
        params.sideload = true;

        if (this.Collector.testNeeds(['toolgroup'])) {
            transactions = null;
            groups = this.store.findAll('toolgroup');
        } else {
            transactions = this.store.query('transaction', params);
            groups = null;
        }

        return hash({
            project: this.store.findRecord('project', params.project),
            amounts: [],
            transactions,
            params,
            groups,
        });
    },
});

export default MachinesTransactionProjectRoute;
