/* eslint-disable ember/named-functions-in-promises */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { get, set } from '@ember/object';

export default Component.extend({
    collector: service('collector-service'),
    dialogs: service(),
    intl: service(),

    banks: alias('model'),

    translated_columns: computed(function () {
        return get(this, 'columnList').map((column) => {
            return get(this, 'intl').t('defaults.field.' + column);
        });
    }),

    absencetypefield: computed('record.[]', function () {
        let c = get(this, 'Collector');
        let field = c.field('bank', 'absencetype');
        field.value = get(this, 'record.absencetype.id');
        return field;
    }),

    rows: computed('model.[]', function () {
        return get(this, 'banks').map((row) => {
            const column =
                get(row, 'column') &&
                get(this, 'collector').fieldExists('worktime', get(row, 'column'))
                    ? get(this, 'collector').getTranslationForColumn('worktime', get(row, 'column'))
                    : '-';
            return {
                id: get(row, 'id'),
                name: get(row, 'name'),
                column: column,
                absencetype: get(this, 'collector').testNeedsOne('abcense')
                    ? get(this, 'collector')._format_(
                          get(row, 'absencetype'),
                          'bank',
                          'absencetype',
                      )
                    : null,
                skipsaldo: get(this, 'collector').testNeedsOne('products.saldo')
                    ? get(this, 'collector')._format_(get(row, 'skipsaldo'), 'bank', 'skipsaldo')
                    : null,
            };
        });
    }),

    columns: computed(function () {
        const collector = get(this, 'collector');
        let worktimes = collector.fieldArray('worktime').filter((item) => {
            return item.type === 'hours';
        });

        worktimes = worktimes.map((item) => {
            const column = collector.fieldExists('worktime', item.name)
                ? collector.getTranslationForColumn('worktime', item.name)
                : '-';
            return { id: item.name, name: column };
        });

        worktimes.unshift({ id: '', name: get(this, 'intl').t('general.select_choose') });

        return worktimes;
    }),

    didReceiveAttrs() {
        this._super(...arguments);
        let c = get(this, 'collector');
        let columns = ['name', 'column'];
        if (get(this, 'collector').testNeedsOne('bank.skipsaldo')) {
            columns.push('skipsaldo');
        }
        if (get(this, 'collector').testNeedsOne('abcense')) {
            columns.push('absencetype');
        }

        set(this, 'rowActions', []);
        set(this, 'columnList', columns);

        if (c.testNeedsOne('bank@remove')) {
            this.rowActions.pushObject({ action: 'removeRow', icon: 'move move-Delete' });
        }
        if (c.testNeedsOne('bank@edit')) {
            this.rowActions.pushObject({ action: 'editRow', icon: 'move move-Pencil' });
        }
    },

    actions: {
        showAdder(on) {
            set(this, 'record', null);
            set(this, 'values', { name: null, column: null });
            set(this, 'showAdder', on);
        },

        changeName(value) {
            set(this, 'values.name', value);
        },

        changeColumn(value) {
            if (this.columnAlreadyInUse(value)) return;
            set(this, 'values.column', value);
            if (get(this, 'record')) {
                set(this, 'record.column', value);
            }
        },
        change(value, field) {
            set(this, `values.${field}`, value);
            if (get(this, 'record')) {
                set(this, `record.${field}`, value);
            }
        },
        save(record) {
            if (get(this, 'columnError')) return;

            if (record) {
                this.saveBank(record).then(() => {
                    set(this, 'showAdder', false);
                });
            } else {
                let obj = {
                    name: get(this, 'values.name') || '---',
                    column: get(this, 'values.column') || null,
                    absencetype: get(this, 'values.absencetype'),
                    skipsaldo: get(this, 'values.skipsaldo'),
                };
                this.createNewBank(obj);
                set(this, 'showAdder', false);
            }
        },

        action(type, row) {
            if (type === 'removeRow') {
                get(this, 'dialogs')
                    .confirm(get(this, 'intl').t('report.confirm_delete'))
                    .then((con) => {
                        if (con) {
                            this.deleteBank(row);
                        }
                    });
            } else if (type === 'editRow') {
                set(this, 'values', { name: null, column: null });
                this.editBank(row).then((editableBank) => {
                    set(this, 'record', editableBank);
                    set(this, 'showAdder', true);
                });
            }
        },
    },
    columnAlreadyInUse(value) {
        let columnFound = get(this, 'banks').filter((bank) => bank.column === value);
        set(this, 'columnError', columnFound.length > 0);
        return this.columnError;
    },
});
