/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1

/*

    Render any field found from forms

    Usage:

        1) Give form and field directly as string

            ie. {{collector/field-comp "worktime.user" change="valueChanged"}}

        2) Give field as object field = {form: <form>, name: <name of the field>, type: <type of field>}

            ie. {{collector/field-comp field change="valueChanged"}}

    Changes are sent back with "change" action. You need to define what you want to
    do when action is triggered.

        ie.  {{collector/field-comp ... change="doSomething"}}

*/
var CollectorFieldCompComponent;

import Ember from 'ember';

CollectorFieldCompComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    flags: Ember.inject.service(),
    currentValue: null,
    validated: Em.computed.lt('errors.length', 1),
    //    console.log "validated", @get('errors'), @get('errors.length')
    //    @get('errors.length') < 1
    _class: null,
    __class: Ember.computed('_class', 'fieldComponent', function () {
        return this.get('_class').replace('form-control', 'power-select-dropdown');
    }),
    _field: Ember.computed('field', function () {
        var c, field, initValue, options, ret, subFields, subForm;
        field = this.get('field');
        initValue = this.get('value');
        if (typeof field === 'string') {
            c = this.get('Collector');
            ret = c.field(field.split('.')[0], field.split('.')[1]);
        } else {
            ret = field;
        }
        if (this.get('useAsFilter') && ret.type === 'databasearray') {
            options = this.get('Collector').field(ret.form, ret.field).options;
            subForm = Ember.get(options, 'form');
            subFields = Ember.get(options, 'fields');
            if (subForm != null && subFields != null) {
                this.set('originalFieldName', Ember.get(ret, 'field'));
                if (ret.field === 'involved') {
                    ret = this.get('Collector').field(ret.form, 'user');
                } else {
                    ret = this.get('Collector').field(subForm, subFields[0]);
                }
            }
        }
        if (initValue) {
            ret.value = initValue;
        }
        if (this.get('prompt')) {
            ret.prompt = this.get('prompt');
        }
        return ret;
    }),
    _useForcedProjectField: function () {
        var ref;
        if (!this.get('Collector').testNeeds(['userlevel=1'])) {
            return false;
        }
        if (this.get('_field.skipForcedGPS')) {
            return false;
        }
        return (
            this.get('Collector').fieldExists('project', 'force_location') &&
            ((ref = this.get('_field.form')) === 'worktime' || ref === 'presence') &&
            !this.get('useAsFilter')
        );
    },
    // Hey you! When you add support to new fields, check also nfc-component, there is a list of not supported field types
    fieldComponent: Ember.computed('_field.external_type', 'forcetype', function () {
        if (this.get('forcetype')) {
            return 'collector/fields/' + this.get('forcetype');
        }
        if (!this.get('_field')) {
            Ember.assert('Collector field component had empty field', {
                id: 'collector.empty-field',
            });
        }
        switch (this.get('_field.type')) {
            case 'textbox':
                return 'collector/fields/text-field';
            case 'number':
            case 'price':
                if (
                    this.get('_field.name') === 'km' &&
                    this.get('Collector').testNeedsOne('products.timetracker_km_automatic')
                ) {
                    return 'collector/fields/gps-auto-km';
                } else {
                    return 'collector/fields/number-field';
                }
                break;
            case 'dropdown_values':
            case 'dropdown_options':
                return 'collector/fields/dropdown-field';
            case 'dropdown_userlevel':
                return 'collector/fields/dropdown-userlevel-field';
            case 'database':
                if (
                    this.get('_field.options.form') === 'project' &&
                    this._useForcedProjectField()
                ) {
                    return 'collector/fields/database-gps-force-field';
                }
                if (
                    (this.get('flags').test('power-database') || this.get('usePowerDropdown')) &&
                    !this.get('useAsFilter')
                ) {
                    return 'collector/fields/power-database';
                } else {
                    return 'collector/fields/database-field';
                }
                break;
            case 'multiselect':
                if (this.get('useAsFilter')) {
                    return 'collector/fields/database-field';
                } else {
                    return 'collector/fields/multiselect-field';
                }
                break;
            case 'status':
                return 'collector/fields/status-field';
            case 'textarea':
            case 'textarea_varchar':
                return 'collector/fields/textarea-field';
            case 'time_new':
                return 'collector/fields/time-field';
            case 'date':
                return 'collector/fields/date-field';
            case 'username':
                return 'collector/fields/username-field';
            case 'password':
                return 'collector/fields/password-field';
            case 'hdfile':
                return 'collector/fields/fileupload-field-ver3';
            case 'checkbox':
                return 'collector/fields/checkbox-field';
            case 'month_dropdown':
                return 'collector/fields/month_dropdown-field';
            case 'search':
                return 'collector/fields/search-field';
            case 'signature':
                return 'collector/fields/signature-field';
            case 'minutes':
                return 'collector/fields/number-field';
            case 'gpslocation':
                return 'collector/fields/gps-field';
            case 'price':
                return 'collector/fields/number-field';
            case 'big_price':
                return 'collector/fields/number-field';
            case 'decimal':
                return 'collector/fields/number-field';
            case 'radio':
                return 'collector/fields/radio-field';
            case 'label':
                return 'collector/fields/label-field';
            case 'databasearray':
                if (this.get('_field.options.form') === 'user') {
                    return 'collector/fields/databasearray-field';
                } else {
                    return 'collector/fields/databasearray-adder-field';
                }
                break;
            case 'dropdown_country':
                return 'collector/fields/dropdown-country-field';
            case 'checkboxgroup':
                return 'collector/fields/checkboxgroup-field';
            case 'hours-and-minutes':
            case 'hours':
                return 'collector/fields/hours-and-minutes-field';
            case 'timeclean_imei':
                return 'collector/fields/timeclean-field';
            case 'weather':
                return 'collector/fields/weather-field';
            case 'timerlink':
                return 'collector/fields/timerlink-field';
            case 'datetime':
                return 'collector/fields/datetime-field';
            default:
                return null;
        }
    }),
    observeValidationState: Em.observer('errors.length', function () {
        return this.sendAction(
            'change',
            this.get('currentValue'),
            this.get('_field.name'),
            this.get('validated'),
        );
    }),
    validateField: function (fieldName) {
        return this.get('model').validate([fieldName]);
    },
    setModelAndValidate: function (value, wait) {
        var fieldName, model;
        model = this.get('model');
        if (!model) {
            return;
        }
        if (model.get('isDeleted')) {
            return;
        }
        fieldName = this.get('_field.name');
        model.set(fieldName, value);
        // if we want to wait x milliseconds before validating
        if (wait) {
            return Ember.run.debounce(this, this.validateField, fieldName, 700);
        } else {
            return this.validateField(fieldName);
        }
    },
    didReceiveAttrs: function () {
        var def;
        if (!this.get('_class')) {
            def =
                this.get('fieldComponent') === 'collector/fields/power-database'
                    ? 'power-select-dropdown'
                    : 'form-control';
            return this.set('_class', this.get('class') || def);
        }
    },
    actions: {
        bubbleChange: function (
            value,
            fieldName,
            setModel = true,
            setComponent = true,
            wait = false,
        ) {
            if (setModel) {
                this.setModelAndValidate(value, wait);
            }
            if (this.get('originalFieldName')) {
                fieldName = this.get('originalFieldName');
            }
            // a little hack for extra-filters search-field
            // (do not set currentValue because field doesn't exist anymore)
            if (setComponent) {
                this.set('currentValue', value);
            }
            return this.sendAction('change', value, fieldName, setModel);
        },
        createNew: function () {
            return this.createNew();
        },
    },
});

CollectorFieldCompComponent.reopenClass({
    positionalParams: ['field'],
});

export default CollectorFieldCompComponent;
