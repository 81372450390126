/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/sign-in/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserSigninController;

import Ember from 'ember';

RegisterUserSigninController = Ember.Controller.extend({
    base: Ember.inject.controller('register.user'),
    nextPage: Ember.computed('model', function () {
        var type;
        type = this.get('base.type');
        if ((type && type === 'valtti') || type === 'normal') {
            return 'register.user.info';
        }
        return 'register.user.company';
    }),
});

export default RegisterUserSigninController;
