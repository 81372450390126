import EmberObject, { computed, set } from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import GeneralReportController from '../../../controllers/general-report';
import { typeOf } from '@ember/utils';

export default GeneralReportController.extend({
    session: service(),

    form: 'abcense',

    status: 'all',
    dateSelector: true,
    groupBy: null,

    init() {
        this._super();
        this.showColumns = [
            'user',
            'abcensetype',
            'startdate',
            'starttime',
            'enddate',
            'endtime',
            'dayamount',
            'absence_hours',
            'description',
        ];
        this.queryParams = ['startdate', 'status', 'user', 'abcensetype', 'group', 'groupBy'];
        this.user = [];
    },

    abcenses: alias('model'),

    filters: computed(function () {
        const c = this.Collector;
        const filter_fields = A([
            EmberObject.create({ field: 'startdate' }, { wagePeriods: true }),
            EmberObject.create({ field: 'abcensetype' }),
            EmberObject.create({ field: 'groupBy', type: 'groupByFilter' }),
        ]);
        // hardcoded for delete for now..
        // should make nicer
        if (c.testNeedsOne('user.costcenter')) {
            filter_fields.pushObject(EmberObject.create({ field: 'user' })); // collector-value-format-helper doesn't work if user-field is in extra-fields with sideloaded-search field
            filter_fields.pushObject(
                EmberObject.create({
                    extra: true,
                    type: 'search',
                    field: 'costcenter',
                    bindto: 'costcenter',
                    sideload: 'user',
                    form: 'user',
                }),
            );
        }
        return filter_fields;
    }),

    getAdditionalFilters: computed(function () {
        if (this.session.currentUser.userlevel !== '1') {
            return 'user.worktimegroup,user.employer';
        }
        return null;
    }),

    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            editRoute: 'abcenses.edit',
            historyRoute: 'abcenses.history',
        }),
        massedit: true,
        sortByColumn: 'startdate-,enddate-',
    }),

    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) == 'instance') {
                set(this, field, value.id);
            } else {
                set(this, field, value);
            }
        },

        closeEditModal() {
            this.editRecord.rollbackAttributes();
            set(this, 'editRecord', null);
        },
    },

    startdate:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('month').format('YYYY-MM-DD'),
});
