/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/forms/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsFormsRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SettingsFormsRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Em.inject.service('collector-service'),
    session: Em.inject.service(),
    beforeModel: function (transition) {
        if (this.get('Collector').testNeedsOne('products.mini_entry')) {
            transition.abort();
            return this.transitionTo('dashboard');
        }
    },
    model: function () {
        return this.store.findAll('form');
    },
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            return controller.set('form_id', void 0);
        }
    },
});

export default SettingsFormsRoute;
