import { set } from '@ember/object';
import GeneralReportRoute from '../../../routes/general-report';

const MateriallogReportRoute = GeneralReportRoute.extend({
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        taskname: {
            refreshModel: true,
        },
    },
    form: 'multitask',

    model(params) {
        if (params.page) {
            delete params.page;
        }
        return set(this.controllerFor('multitask.report'), 'qParams', params);
    },
});

export default MateriallogReportRoute;
