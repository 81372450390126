import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CollectorFormAddTooltypeComponent extends Component {
    @service store;
    @service flags;

    @tracked record;

    constructor() {
        super(...arguments);
        this.record = this.store.createRecord('tooltype');
    }

    @action
    onFieldChange(field, value) {
        this.record[field] = value;
    }
}
