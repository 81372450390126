/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/other-entries-report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OtherEntriesReportRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

OtherEntriesReportRoute = GeneralReportRoute.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        params.sideload = true;
        return this.store.query('diaryreport_other_note', params);
    },
});

export default OtherEntriesReportRoute;
