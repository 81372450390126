import Component from '@ember/component';
import { get } from '@ember/object';
import { computed } from '@ember/object';

export default Component.extend({
    fullValue: 198,
    isNegative: computed('value', function () {
        return get(this, 'value') <= 0;
    }),

    over100: computed('value', function () {
        return Math.abs(this.value) > 100;
    }),

    percent: computed('value', function () {
        if (get(this, 'value') > 0) {
            let tick = get(this, 'fullValue') / get(this, 'max');
            // if value is over max, use max
            if (get(this, 'value') > get(this, 'max')) {
                return get(this, 'fullValue') - tick * get(this, 'max');
            }
            return get(this, 'fullValue') - tick * get(this, 'value');
        } else if (get(this, 'value') < 0) {
            // if value is smaller than min, just use min as value
            if (Math.abs(get(this, 'value')) > get(this, 'min')) {
                return 0;
            }
            let tick = get(this, 'fullValue') / get(this, 'min');
            return (get(this, 'fullValue') + tick * get(this, 'value')) * -1;
        } else if (get(this, 'value') == 0) {
            return get(this, 'fullValue') * -1;
        }
        return null;
    }),
});
