/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersIndexRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

EmployersIndexRoute = GeneralReportRoute.extend({
    flags: Ember.inject.service(),
    form: 'employer',
    queryParams: {
        page: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
        emp_users_given: {
            refreshModel: true,
        },
    },
    model: function (params) {
        if (
            !this.get('flags').test('form-report-employers') &&
            !this.get('flags').test('ember-movenium-form-report-employers')
        ) {
            params = this._super(params);
            params.sideload = true;
            return this.store.query('employer', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('employers.index').set('qParams', params);
        }
    },
});

export default EmployersIndexRoute;
