/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/working-now/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorkingNowController;

import Ember from 'ember';

import { inject as service } from '@ember/service';

WorkingNowController = Ember.Controller.extend({
    ttapi: service(),
    intl: service(),
    actions: {
        showModal: function () {
            this.set('showModal', true);
            this.set('messageSent', false);
            this.set('message_error', false);
            return false;
        },
        closeModal: function () {
            return this.set('showModal', false);
        },
        sendMessage: function () {
            var message, params, receivers, self, title;
            self = this;
            params = {};
            receivers = [];
            title = this.get('title');
            message = this.get('message_content');
            if (title == null || title === '' || message == null || message === '') {
                this.set('message_error', true);
                return;
            }
            this.get('model').map((user) => {
                return receivers.push(user.id);
            });
            params.receivers = receivers;
            params.header = title;
            params.message = message;
            return this.get('ttapi')
                .request('/messages/new_message', {
                    type: 'POST',
                    data: params,
                })
                .then((data) => {
                    this.set('messageSent', true);
                    this.set('title', '');
                    return this.set('message_content', '');
                });
        },
    },
});

export default WorkingNowController;
