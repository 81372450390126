/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/id06competence/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditId06competenceController;

import Ember from 'ember';

UsersEditId06competenceController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    errorText: Em.computed('model', function () {
        var intl;
        intl = this.get('intl');
        if (!this.get('model.error')) {
            return false;
        }
        return intl.t('id06competence.error.' + this.get('model.error'));
    }),
});

export default UsersEditId06competenceController;
