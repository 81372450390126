/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/notices/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditNoticesRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

ProjectsEditNoticesRoute = GeneralReportRoute.extend({
    form: 'notice',
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var project;
        params = this._super(params);
        params.sideload = true;
        project = this.modelFor('projects.edit');
        params.project = project.get('id');
        return this.store.query('notice', params);
    },
});

export default ProjectsEditNoticesRoute;
