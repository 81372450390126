import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
    hasSplitLabel: computed('settingsPageSettings.@each.label', function () {
        return this.settingsPageSettings.some((setting) => setting.label === 'split');
    }),

    hasNonSplitLabel: computed('settingsPageSettings.@each.label', function () {
        return this.settingsPageSettings.some((setting) => setting.label !== 'split');
    }),

    settingsTitle: computed('settingsPage', function () {
        let arr = this.settingsPage.split('.');
        // Hide "settings" title if using top level setting.
        if (arr[0] === 'general') {
            return 'settings.general-settings';
        }
        if (arr[1] === 'settings') {
            return 'settings.' + arr[0];
        } else {
            return 'settings.' + arr[1];
        }
    }),

    actions: {
        refreshNeeded() {
            this.refreshNeeded();
        },
        toggleHelp() {
            this.toggleHelpContainerVisibility('showHelpContainer');
        },
        toggleActive(setting) {
            this.toggleActive(setting);
        },
        saveCalled(setting, donotreload) {
            this.saveCalled(setting, donotreload);
        },
        setCustomerSetting(setting, value, worktimegroup) {
            this.setCustomerSetting(setting, value, worktimegroup);
        },
        async setSetting(setting, option) {
            this.setSetting(setting, option);
        },
    },
});
