import Controller from '@ember/controller';
import { get, set, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import EmberError from '@ember/error';
import config from 'tt4/config/environment';

export default Controller.extend({
    Collector: service('collector-service'),
    ajax: service(),
    session: service(),
    intl: service(),
    cc: service(),
    notifications: service(),

    localStorageKey: 'passes',
    pass_ends_range: null,

    passes: alias('model'),

    rollockIntegration: computed(function () {
        if (config.target === 'release-next') {
            return 'rollock_qa';
        } else {
            return 'rollock';
        }
    }),

    progress: computed('numericalProgress', function () {
        return get(this, 'numericalProgress') + ' %';
    }),

    progressStyle: computed('progress', function () {
        return htmlSafe('width: ' + get(this, 'numericalProgress') + '%');
    }),

    timecleanProjects: computed('model.timcleanProjects', function () {
        if (!get(this, 'model.timecleanProjects')) {
            return null;
        } else {
            return get(this, 'model.timecleanProjects').map((project) => {
                return { id: project.id, name: project.name };
            });
        }
    }),

    actions: {
        showTimecleanModal() {
            set(this, 'showTimecleanModal', true);
        },

        syncRollock(rows) {
            //only try to sync if user has a pass with an end time
            rows = rows.filter((row) => get(row, 'pass_end'));
            set(this, 'rowAmount', rows.length);

            if (rows.length === 0) {
                get(this, 'notifications').error(get(this, 'intl').t('rollock.sync.no_users'), {
                    autoClear: true,
                });
                return;
            }

            set(this, 'showSyncProgress', true);
            set(this, 'allDone', false);

            set(this, 'numericalProgress', 0);
            set(this, 'count', 0);

            let rollockIntegration = get(this, 'rollockIntegration');
            let url = this.cc.host + '/' + rollockIntegration;

            let accessToken = get(this, 'session.data.authenticated.access_token');

            rows.forEach((row) => {
                get(this, 'ajax').request(url, {
                    type: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                    },
                    contentType: 'application/json',
                    data: JSON.stringify(row),
                    success: () => {
                        this.incrementProgress();
                    },
                    error: (e) => {
                        this.incrementProgress();
                        throw new EmberError(JSON.stringify(e));
                    },
                });
            });
        },

        close() {
            set(this, 'allDone', false);
            set(this, 'showSyncProgress', false);
        },
    },

    incrementProgress() {
        let count = get(this, 'count');
        let rowAmount = get(this, 'rowAmount');

        count++;
        if (count === rowAmount) set(this, 'allDone', true);

        set(this, 'count', count);
        set(this, 'numericalProgress', Math.floor((count / rowAmount) * 100));
    },
});
