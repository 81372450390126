/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

TimetrackerRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    beforeModel: function (transition) {
        if (
            transition.targetName === 'timetracker.worktime-list' &&
            this.get('Collector').testNeeds(['mobileView', 'userlevel=1'])
        ) {
            this.transitionTo('timetracker.week-report');
        }
        return this._super(transition);
    },
});

export default TimetrackerRoute;
