import { alias } from '@ember/object/computed';
import GeneralReportController from '../../../controllers/general-report';
import { inject as service } from '@ember/service';
import EmberObject, { set } from '@ember/object';
import { A } from '@ember/array';
import { typeOf } from '@ember/utils';

const MateriallogReportController = GeneralReportController.extend({
    flags: service(),

    form: 'materiallog',
    type: null,
    project: null,
    user: null,
    employer: null,
    task: null,

    limit: 25,
    init() {
        this._super(...arguments);
        this.showColumns = ['date', 'project', 'user', 'type', 'amount', 'employer', 'task'];
        this.queryParams = ['project', 'date', 'q', 'user', 'type', 'task', 'employer'];
    },

    content: alias('model'),
    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            editRoute: {
                modal: true,
            },
        }),
        sortByColumn: 'date-',
    }),

    filters: A([
        EmberObject.create({ field: 'date' }),
        EmberObject.create({ field: 'type' }),
        EmberObject.create({ field: 'project' }),
        EmberObject.create({ field: 'employer' }),
        EmberObject.create({ field: 'task' }),
        EmberObject.create({ field: 'user' }),
        EmberObject.create({ type: 'searchbox', bindto: 'q' }),
    ]),

    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) === 'instance') {
                return set(this, field, value.id);
            } else {
                return set(this, field, value);
            }
        },
    },
    init_functions() {
        if (
            this.flags.test('form-report-materiallogs') ||
            this.flags.test('ember-movenium-form-report-materiallogs')
        ) {
            return null;
        } else {
            return this._super();
        }
    },

    formReportDate:
        moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD') +
        '_' +
        moment().format('YYYY-MM-DD'),
    date:
        moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD') +
        '_' +
        moment().format('YYYY-MM-DD'),
});

export default MateriallogReportController;
