/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saldos/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSaldosController;

import Ember from 'ember';

import pdfExport from '../../../mixins/pdf-export';

import csvExport from '../../../mixins/csv-export';

import config from '../../../config/environment';

import { alias } from '@ember/object/computed';

import { computed } from '@ember/object';

import { Promise } from 'rsvp';

const { get, set } = Ember;

UsersSaldosController = Ember.Controller.extend(pdfExport, csvExport, {
    content: alias('model'),
    queryParams: [
        'page',
        'all_users',
        'employer',
        'user',
        'group',
        'worktimegroup',
        'superior',
        'userlevel',
        'date',
    ],
    all_users: true,
    values: {},
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    fileExports: Ember.inject.service(),
    filters: Em.computed(function () {
        var ref;
        return Em.A([
            Em.Object.create({
                field: 'date',
                type: 'date',
                form: 'worktime',
                name: 'date',
                defaultValue: null,
                value: this.date,
            }),
            Em.Object.create({
                field: 'employer',
                type: 'database',
                form: 'user',
                name: 'employer',
                value: this.employer,
            }),
            Em.Object.create({
                field: 'worktimegroup',
                type: 'database',
                form: 'user',
                name: 'worktimegroup',
                value: this.worktimegroup,
            }),
            Em.Object.create({
                field: 'user',
                type: 'database',
                form: 'worktime',
                name: 'user',
                value: this.user,
            }),
            Em.Object.create({
                field: 'group',
                type: 'database',
                form: 'user',
                name: 'group',
                value: this.group,
            }),
            Em.Object.create({
                field: 'superior',
                type: 'database',
                form: 'user',
                name: 'superior',
                value: this.superior,
            }),
            Em.Object.create({
                field: 'userlevel',
                form: 'user',
                type: 'dropdown_userlevel',
                options:
                    (ref = this.Collector.field('user', 'userlevel')) != null
                        ? ref.options
                        : void 0,
                prompt: 'user.all_userlevels',
                value: this.userlevel,
            }),
        ]);
    }),
    //form: "user"
    translations: {
        twentyfive: 25,
        fifty: 50,
        hundred: 100,
    },
    yesterday: computed(function () {
        return moment().subtract(1, 'day').format('YYYY-MM-DD');
    }),
    dateField: computed(function () {
        var field;
        field = this.get('Collector').field('saldodeducter', 'date');
        field.value = this.get('yesterday');
        return field;
    }),
    columnList: ['lastname', 'firstname', 'saldostart', 'saldo'],
    translated_columns: Em.computed(function () {
        return [
            this.get('intl').t('report.user.lastname'),
            this.get('intl').t('report.user.firstname'),
            this.get('intl').t('saldo.calculation_start'),
            this.get('intl').t('report.saldo.columns.saldo'),
        ];
    }),
    allUserRows: Ember.computed('model.[]', 'saldos.[]', function () {
        return this.get('model.users').map((user) => {
            var saldostart;
            if (
                get(user, 'saldos.user_startdate') &&
                get(user, 'saldos.user_startdate') > get(user, 'saldos.startdate')
            ) {
                saldostart = moment(get(user, 'saldos.user_startdate')).format('L');
            } else {
                saldostart = moment(get(user, 'saldos.startdate')).format('L');
            }
            if (!get(user, 'saldos')) {
                return {
                    userid: get(user, 'id'),
                    firstname: get(user, 'firstname'),
                    lastname: get(user, 'lastname'),
                    saldo: this.get('intl').t('saldos.no_calculations_yet'),
                    saldostart: this.get('intl').t('saldos.no_calculations_yet'),
                };
            }
            return {
                userid: get(user, 'id'),
                firstname: get(user, 'firstname'),
                lastname: get(user, 'lastname'),
                saldostart: saldostart,
                saldo: this.formatHours(get(user, 'saldos.saldo') / 60),
                unformattedSaldo: get(user, 'saldos.saldo'),
            };
        });
    }),
    outOffLimitsUserRows: Ember.computed('model.[]', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                var userids;
                userids = this.get('model.users').map(function (row) {
                    if (row.saldos) {
                        return get(row.saldos, 'userid');
                    }
                });
                userids = userids.filter(function (row) {
                    if (row) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (userids.length < 1) {
                    resolve({
                        value: [],
                    });
                    return;
                }
                return this.get('Collector')
                    .getRecordArray('user', userids)
                    .then((users) => {
                        var filtered_users, rows;
                        filtered_users = users.filterBy('row_info.status', 'normal');
                        rows = filtered_users.map((user) => {
                            var model_user, saldostart;
                            model_user = this.get('model.users').findBy(
                                'saldos.userid',
                                get(user, 'id'),
                            );
                            model_user = model_user.saldos;
                            if (model_user['user_startdate']) {
                                saldostart = moment(model_user['user_startdate']).format('L');
                            } else {
                                saldostart = moment(model_user['startdate']).format('L');
                            }
                            return {
                                firstname: get(user, 'firstname'),
                                lastname: get(user, 'lastname'),
                                saldostart: saldostart,
                                saldo: this.formatHours(model_user['saldo'] / 60),
                                unformattedSaldo: model_user['saldo'] / 60,
                            };
                        });
                        rows.sortBy('lastname');
                        return resolve({
                            value: rows,
                        });
                    });
            }),
        });
    }),
    getSaldoFor: function (userid) {
        var saldos, saldostart;
        saldos = this.get('saldos');
        if (saldos.get('value')) {
            saldos = saldos.get('value').findBy('userid', userid);
            if (saldos) {
                if (saldos.user_startdate && saldos.user_startdate > saldos.startdate) {
                    saldostart = moment(saldos.user_startdate).format('L');
                } else {
                    saldostart = moment(saldos.startdate).format('L');
                }
                return {
                    saldo: this.formatHours(saldos.saldo / 60),
                    saldostart: saldostart,
                    unformattedSaldo: saldos.saldo / 60,
                };
            } else {
                return {
                    saldo: this.get('intl').t('saldos.no_calculations_yet'),
                    saldostart: this.get('intl').t('saldos.no_calculations_yet'),
                };
            }
        } else {
            return '';
        }
    },
    formatHours: function (hours) {
        var minutes, negative;
        if (hours < 0) {
            hours = hours * -1;
            negative = true;
        } else {
            negative = false;
        }
        minutes = Math.round((hours - Math.floor(hours)) * 60);
        return (
            (negative ? '-' : '') +
            Math.floor(hours) +
            ':' +
            (minutes < 10 ? '0' : '') +
            minutes +
            'h'
        );
    },
    saldos: Ember.computed('model.[]', 'date', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                var enddate, startdate, url, userids;
                userids = this.get('model').map(function (user) {
                    return get(user, 'id');
                });
                userids = userids.filter(function (row) {
                    if (row) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (userids.length < 1) {
                    resolve({
                        value: [],
                    });
                    return;
                }
                url = 'saldo/get_multiple_saldos?userids=' + userids.join(',');
                if (this.get('date')) {
                    startdate = this.get('date').split('_')[0];
                    enddate = this.get('date').split('_')[1];
                    url += '&startdate=' + startdate + '&enddate=' + enddate;
                }
                return this.get('Collector')
                    .ttapi({
                        url: url,
                    })
                    .then((back) => {
                        return resolve({
                            value: back,
                        });
                    });
            }),
        });
    }),
    pageCount: Ember.computed('content.count', function () {
        var limit;
        limit = this.get('limit') ? this.get('limit') : 25;
        return Math.ceil(this.get('content.count') / limit);
    }),
    observeFilters: Ember.observer('filters.@each.value', function () {
        return this.get('filters').forEach((item) => {
            return this.set(item.name, item.value);
        });
    }),
    make_pdf_export: function (data) {
        var element, intl;
        intl = this.get('intl');
        element = this.createPdfTableElement(
            this.get('columnList'),
            data,
            this.get('translated_columns'),
        );
        return this.createPdfFromElements([element], 'download', '', 16).then((file) => {
            if (typeof cordova !== 'undefined' && cordova !== null) {
                return this.get('cordova').openFile(file, 'application/pdf');
            }
        });
    },
    make_csv_export: function (data) {
        var element;
        element = this.createCsvElement(
            this.get('columnList'),
            data,
            this.get('translated_columns'),
        );
        return this.createCsvFromElements(element);
    },
    make_excel_export: function (data) {
        var excelHeaders;
        excelHeaders = [];
        this.get('translated_columns').forEach((col, index) => {
            return excelHeaders.pushObject({
                name: col,
                key: this.get('columnList')[index],
            });
        });
        return this.get('fileExports').createExcelFromJSON({
            rows: data,
            columns: excelHeaders,
        });
    },
    checkedRows: computed('allUserRows.@each.checkedClass', function () {
        return this.get('allUserRows').filterBy('checkedClass', 'checked');
    }),
    get_rows_to_export: function () {
        return new Ember.RSVP.Promise((resolve) => {
            var checked_data, data, use_checked_rows;
            if (this.get('all_users')) {
                data = this.get('allUserRows');
            } else {
                data = this.get('outOffLimitsUserRows.value');
            }
            use_checked_rows = false;
            checked_data = Em.A([]);
            data.forEach((row) => {
                if (row.checkedClass === 'checked') {
                    use_checked_rows = true;
                    return checked_data.push(row);
                }
            });
            if (use_checked_rows) {
                return resolve(checked_data);
            } else {
                if (this.get('all_users')) {
                    return this.get_all_users_with_saldos().then((data) => {
                        return resolve(data);
                    });
                } else {
                    return resolve(data);
                }
            }
        });
    },
    // fetches data 100 entries at a time
    get_all_users_with_saldos: function () {
        return new Ember.RSVP.Promise((resolve) => {
            var dataArray, fetchPage, page, params;
            dataArray = [];
            page = 0;
            params = this.getQueryParamsForExport();
            fetchPage = () => {
                params.offset = page * 100;
                return this.ttapi
                    .request('saldo/usersWithSaldos', {
                        data: params,
                    })
                    .then((data) => {
                        dataArray = dataArray.concat(data.users);
                        if (dataArray.length < data.count) {
                            page++;
                            return fetchPage();
                        } else {
                            dataArray = dataArray.map((el) => {
                                var ret, saldostart, unformattedSaldo;
                                if (el.saldos) {
                                    saldostart = el.saldos.startdate;
                                    unformattedSaldo = this.formatHours(el.saldos.saldo / 60);
                                } else {
                                    saldostart = this.get('intl').t('saldos.no_calculations_yet');
                                    unformattedSaldo = this.get('intl').t(
                                        'saldos.no_calculations_yet',
                                    );
                                }
                                ret = {
                                    firstname: el.firstname,
                                    lastname: el.lastname,
                                    saldostart: saldostart,
                                    saldo: unformattedSaldo,
                                };
                                return ret;
                            });
                            return resolve(dataArray);
                        }
                    });
            };
            return fetchPage();
        });
    },
    getQueryParamsForExport: function () {
        var params;
        params = {
            limit: 100,
            order: 'lastname',
        };
        if (this.user) {
            params.id = this.user;
        }
        if (this.employer) {
            params.employer = this.employer;
        }
        if (this.group) {
            params.group = this.group;
        }
        if (this.worktimegroup) {
            params.worktimegroup = this.worktimegroup;
        }
        if (this.superior) {
            params.superior = this.superior;
        }
        if (this.userlevel) {
            params.userlevel = this.userlevel;
        }
        if (this.date) {
            params.startdate = this.date.split('_')[0];
            params.enddate = this.date.split('_')[1];
        }
        return params;
    },
    actions: {
        moreInfo: function () {
            return window.open(config.APP.CDN_URL + '/documents/Saldot+ohje.pdf', '_blank');
        },
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        all_users: function (is_on) {
            this.set('all_users', is_on);
            return false;
        },
        showSaldodeducter: function (type) {
            this.set('modalHeader', type ? 'saldo.add_saldo_adder' : 'saldo.add_saldo_deduction');
            this.set('showSaldodeducterType', type);
            this.set('showSaldodeducter', true);
            return false;
        },
        hideSaldodeducter: function () {
            this.set('showSaldodeducter', false);
            return false;
        },
        hideandreload: function () {
            this.set('showSaldodeducter', false);
            this.send('refreshModel');
            return false;
        },
        changeValue: function (value, field) {
            return this.set('values.' + field, value);
        },
        superMultiSaver: async function () {
            this.set('saving', true);
            const allUserRows = this.get('allUserRows');
            for (const row of allUserRows) {
                if (row.checkedClass === 'checked') {
                    let user = this.get('store').peekRecord('user', row.userid);
                    // first check if user has been loaded to store
                    if (!user) {
                        // if not, load user from server
                        user = await this.get('store').findRecord('user', row.userid);
                    }

                    await this.get('store')
                        .createRecord('saldodeducter', {
                            hours: this.get('values.hours'),
                            user: user,
                            description: this.get('values.description'),
                            date: this.get('values.date')
                                ? this.get('values.date')
                                : this.get('yesterday'),
                            addhours: this.get('showSaldodeducterType'),
                        })
                        .save();
                }
            }
            this.set('saving', false);
            return this.send('hideandreload');
        },
        filterChanged: function (value, fieldName) {
            if (fieldName === 'employer') {
                if (value == null) {
                    return this.set('employer', '');
                } else {
                    return this.set('employer', value.id);
                }
            } else if (fieldName === 'user') {
                if (value == null) {
                    return this.set('user', '');
                } else {
                    return this.set('user', value.id);
                }
            }
        },
        export_pdf: function () {
            return this.get_rows_to_export().then((data) => {
                return this.make_pdf_export(data);
            });
        },
        export_csv: function (data) {
            return this.get_rows_to_export().then((data) => {
                return this.make_csv_export(data);
            });
        },
        export_excel: function (data) {
            return this.get_rows_to_export().then((data) => {
                return this.make_excel_export(data);
            });
        },
        setRowsPerPage: function (limit) {
            this.set('page', 1);
            this.set('limit', limit);
            return this.get('localStorageWrapper').setUserSetting('saldo_defaultLimit', limit);
        },
    },
});

export default UsersSaldosController;
