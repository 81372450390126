/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/at-site/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddAtSiteRoute;

import Ember from 'ember';

import baseRoute from '../base-route/route';

WorksitediaryReportAddAtSiteRoute = baseRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    model: function (params) {
        var c, model;
        model = this._super(params);
        c = this.get('Collector');
        if (
            c.testNeedsOne('diaryreport.work_force') &&
            model.get('isNew') &&
            c.testNeedsOne('employer')
        ) {
            return this.get('store')
                .query('employer', {
                    main: 'on',
                })
                .then((emps) => {
                    var emp, promises;
                    if (emps.get('length') === 0) {
                        return model;
                    }
                    emp = emps.get('firstObject');
                    promises = [];
                    if (c.testNeedsOne('presence')) {
                        promises.push(
                            this.get('store').query('presence', {
                                project: model.get('project.id'),
                                date: model.get('diary_date'),
                                employer: emp.get('id'),
                                groupBy: 'user',
                                limit: 1,
                            }),
                        );
                        promises.push(
                            this.get('store').query('presence', {
                                project: model.get('project.id'),
                                date: model.get('diary_date'),
                                employer: '!' + emp.get('id'),
                                groupBy: 'user',
                                limit: 1,
                            }),
                        );
                    }
                    if (c.testNeedsOne('worktime')) {
                        promises.push(
                            this.get('store').query('worktime', {
                                project: model.get('project.id'),
                                date: model.get('diary_date'),
                                employer: emp.get('id'),
                                groupBy: 'user',
                                limit: 1,
                            }),
                        );
                        promises.push(
                            this.get('store').query('worktime', {
                                project: model.get('project.id'),
                                date: model.get('diary_date'),
                                employer: '!' + emp.get('id'),
                                groupBy: 'user',
                                limit: 1,
                            }),
                        );
                    }
                    return Ember.RSVP.Promise.all(promises).then((data) => {
                        var own, ue;
                        own = 0;
                        ue = 0;
                        if (data[0]) {
                            if (data[0].get('length') > 0) {
                                own += data[0].get('meta.count');
                            }
                        }
                        if (data[2]) {
                            if (data[2].get('length') > 0) {
                                own += data[2].get('meta.count');
                            }
                        }
                        if (data[1]) {
                            if (data[1].get('length') > 0) {
                                ue += data[1].get('meta.count');
                            }
                        }
                        if (data[3]) {
                            if (data[3].get('length') > 0) {
                                ue += data[3].get('meta.count');
                            }
                        }
                        model.set('work_force', 'Total: ' + own + '/' + ue);
                        return model;
                    });
                });
        } else {
            return model;
        }
    },
    actions: {
        willTransition: function (transition) {
            var c, string, workforce;
            c = this.get('Collector');
            if (c.fieldExists('diaryreport', 'work_force')) {
                workforce = this.get('controller.work_force');
                string = '';
                workforce.forEach(function (item) {
                    if (item.get('amount_own') !== 0 || item.get('amount_ue') !== 0) {
                        return (string +=
                            item.get('name') +
                            ': ' +
                            item.get('amount_own') +
                            '/' +
                            item.get('amount_ue') +
                            ', ');
                    }
                });
                string = string.slice(0, -2);
                this.set('controller.model.work_force', string);
            }
            return this._super(transition);
        },
    },
});

export default WorksitediaryReportAddAtSiteRoute;
