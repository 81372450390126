import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

export default Route.extend({
    router: service(),
    store: service(),

    async model(params) {
        let data = await get(this, 'store').query(params.form, {
            id: params.id,
            sideload: true,
            'row_info.status': 'all',
        });
        return get(data, 'firstObject');
    },

    setupController(controller, model) {
        set(controller, 'model', model);
    },

    actions: {
        cancel() {
            window.history.back();
        },
    },
});
