/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/add/message/route.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesAddMessageRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

MessagesAddMessageRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    setupController: function (controller) {
        return controller.fillData();
    },
    resetController: function (controller, isExiting, transition) {
        if (isExiting && transition.targetName === 'messages.list') {
            return controller.resetData();
        }
    },
});

export default MessagesAddMessageRoute;
