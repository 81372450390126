/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/devices/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerDetailsEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../../routes/general-add';

AdmintoolCustomerDetailsEditRoute = Ember.Route.extend();

export default AdmintoolCustomerDetailsEditRoute;
