/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PresencesEditController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

PresencesEditController = GeneralAddController.extend({
    session: Em.inject.service(),
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
    disableFields: Em.observer('model', function () {
        var cust;
        if (
            this.get('session.currentUser.userlevel') !== '5' &&
            this.get('session.currentUser.userlevel') !== '3' &&
            !moment(this.get('model.date')).isSame(moment().format('YYYY-MM-DD'))
        ) {
            cust = {
                in: {
                    features: {
                        disabled: true,
                    },
                },
                out: {
                    features: {
                        disabled: true,
                    },
                },
                user: {
                    features: {
                        disabled: true,
                    },
                },
                project: {
                    features: {
                        disabled: true,
                    },
                },
                date: {
                    features: {
                        disabled: true,
                    },
                },
                location: {
                    features: {
                        disabled: true,
                    },
                },
            };
            return this.set('customization', cust);
        }
    }),
});

export default PresencesEditController;
