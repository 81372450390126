// Stonly widget installation script converted with ChatGPT
// https://app.stonly.com/app/general/39226/widget/WidgetSettings/Installation
export default function initStonly(id) {
    if (id) {
        window.STONLY_WID = id || '1e6aaf2b-fa22-11ed-871a-0a52ff1ec764'; // Visma Devenor Stonly ID
        var s = window,
            t = document,
            o = 'script',
            n = 'https://stonly.com/js/widget/v2/',
            l,
            y,
            w,
            g,
            d,
            e;
        s.StonlyWidget ||
            (((d = s.StonlyWidget =
                function () {
                    d._api ? d._api.apply(d, arguments) : d.queue.push(arguments);
                }).scriptPath = n),
            (d.apiPath = l),
            (d.sPath = y),
            (d.queue = []),
            ((g = t.createElement(o)).async = !0),
            (e = new XMLHttpRequest()).open('GET', n + 'version?v=' + Date.now(), !0),
            (e.onreadystatechange = function () {
                4 === e.readyState &&
                    ((g.src =
                        n +
                        'stonly-widget.js?v=' +
                        (200 === e.status ? e.responseText : Date.now())),
                    (w = t.getElementsByTagName(o)[0]).parentNode.insertBefore(g, w));
            }),
            e.send());
    } else {
        // Log an error if id is not provided
        // eslint-disable-next-line no-console
        console.error('No widget id parameter provided. Skipping Stonly widget initialization.');
    }
}
