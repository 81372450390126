/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

OrientationsEditRoute = GeneralAddRoute.extend({
    modelName: 'orientation',
    edit: true,
    afterSave: 'orientations.reports',
    setupController: function (controller, model) {
        controller.set('model', model);
        controller.set(
            'fieldSettings',
            Em.A({
                edit: true,
            }),
        );
        return controller.set('edit', true);
    },
});

export default OrientationsEditRoute;
