/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/password-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsUsernameFieldComponent;

import Ember from 'ember';

import text from '../text-field/component';

import { computed } from '@ember/object';

CollectorFieldsUsernameFieldComponent = text.extend({
    show_generated: true,
    showPassword: computed('value', 'show_generated', function () {
        return this.get('value') && this.get('value') !== 'secured' && this.get('show_generated');
    }),
    actions: {
        changeFieldType: function () {
            return this.set('show_generated', false);
        },
    },
});

export default CollectorFieldsUsernameFieldComponent;
