/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/forms/form/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsFormsFormController,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from 'tt4/classes/collector';

SettingsFormsFormController = Ember.Controller.extend({
    session: Em.inject.service(),
    dialogs: Ember.inject.service(),
    error: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Em.inject.service(),
    settingsController: Em.inject.controller('settings'),
    formFieldController: Ember.inject.controller('settings.forms.form.field'),
    formsController: Ember.inject.controller('settings.forms'),
    modelCreator: Ember.inject.service('model-creator'),
    adminMode: Ember.computed.alias('formsController.adminMode'),
    selectedField: Ember.computed('formFieldController.field', function () {
        return this.get('formFieldController.field');
    }),
    fieldController: Em.inject.controller('settings.forms.form.field'),
    hasCCFunction: Ember.computed('filteredFormSettings.[]', function () {
        return this.get('filteredFormSettings').filterBy('value', 'ccfunctions').get('length') > 0;
    }),
    ccfunctionload: Ember.computed('model.form', function () {
        return this.get('Collector')
            .ttapi({
                url: 'ccfunctions/functions',
                dataType: 'text',
                data: {
                    form: this.get('model.form.external_type'),
                },
            })
            .then((data) => {
                return this.set('ccfunctionvalue', data);
            });
    }),
    filteredFields: Em.computed(
        'model.fields',
        'model.fields.@each.nro',
        'model.fields.[]',
        'model.form',
        'adminMode',
        'model.fields.@each.removed',
        function () {
            var fields, hideWithExternal, hideWithSetting, hideWithType;
            hideWithSetting = ['hide', 'hidden', 'hide_tt4'];
            hideWithType = ['device', 'rowlink', 'row_linker'];
            hideWithExternal = [''];
            if (!this.get('model.fields')) {
                return [];
            }
            fields = this.get('model.fields')
                .filterBy('form.id', this.get('model.form.id'))
                .sortBy('nro');
            if (fields) {
                return fields.filter((field) => {
                    var ref, ref1, ret;
                    if (field.get('removed')) {
                        return;
                    }
                    ret = true;
                    if (
                        ((ref = field.get('external_type')),
                        indexOf.call(hideWithExternal, ref) >= 0)
                    ) {
                        ret = false;
                    }
                    if (((ref1 = field.get('type')), indexOf.call(hideWithType, ref1) >= 0)) {
                        ret = false;
                    }
                    field.get('settings').forEach(function (setting) {
                        var ref2;
                        if (
                            ((ref2 = setting.get('name')), indexOf.call(hideWithSetting, ref2) >= 0)
                        ) {
                            // show this field if hide-setting is object (it is conditionally hidden, so it seems a good idea to show this field)
                            if (
                                setting.get('value').indexOf('[') === -1 &&
                                !setting.condition.includes('worktimegroup') &&
                                setting.get('name') === 'hide'
                            ) {
                                return (ret = false);
                            }
                        }
                    });
                    if (this.get('adminMode')) {
                        ret = true;
                    }
                    return ret;
                });
            }
        },
    ),
    filteredFormSettings: Ember.computed('model.settings', 'model.settings.[]', function () {
        return this.get('model.settings').filterBy('form.id', this.get('model.form.id'));
    }),
    filteredFieldSettings: Ember.computed(
        'filteredFields',
        'filteredFields.[]',
        'model.fieldSettings.[]',
        function () {
            var fieldIds;
            fieldIds = this.get('filteredFields').mapBy('id');
            return this.get('model.fieldSettings').filter((setting) => {
                var ref;
                return (ref = setting.get('field.id')), indexOf.call(fieldIds, ref) >= 0;
            });
        },
    ),
    unsavedChanges: Em.computed(
        'filteredFormSettings.@each.hasDirtyAttributes',
        'filteredFieldSettings.@each.hasDirtyAttributes',
        'filteredFields.@each.hasDirtyAttributes',
        'model.form.hasDirtyAttributes',
        function () {
            if (this.get('model.form.hasDirtyAttributes')) {
                return true;
            }
            if (!this.get('model.fields')) {
                return;
            }
            if (this.get('filteredFields').filterBy('hasDirtyAttributes').length !== 0) {
                return true;
            }
            if (this.get('filteredFormSettings').filterBy('hasDirtyAttributes').length !== 0) {
                return true;
            }
            if (this.get('filteredFieldSettings').filterBy('hasDirtyAttributes').length !== 0) {
                return true;
            }
        },
    ),
    dirtyFields: Em.computed('model.@each.hasDirtyAttributes', function () {
        return this.get('model.fields').filterBy('hasDirtyAttributes');
    }),
    generateExternalType: function (name) {
        if (!name) {
            name = Math.random().toString(36).substring(10);
        }
        name = name.toString();
        name = name.underscore();
        name = name.replace(/[^\w\s]/gi, '');
        name = name.substr(0, 20);
        name = this.checkDuplicates(name);
        name = 'addon_' + name;
        return name;
    },
    checkDuplicates: function (name) {
        var ret_name;
        ret_name = null;
        this.get('model.fields').forEach(function (field) {
            if (field.get('external_type') === 'addon_' + name) {
                return (ret_name = name + Math.floor(Math.random() * 10 + 1).toString());
            }
        });
        if (ret_name) {
            return this.checkDuplicates(ret_name);
        }
        return name;
    },
    saveField: function (field) {
        var isNew;
        isNew = false;
        if (field.get('isNew')) {
            field.set('external_type', this.generateExternalType(field.get('name')));
            isNew = true;
        }
        return field.save().then(() => {
            if (isNew) {
                return this.transitionToRoute('settings.forms.form.field', field.get('id'));
            }
        });
    },
    saveDirtyForm: function () {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var form;
            form = this.get('model.form');
            if (this.get('model.form.hasDirtyAttributes')) {
                return this.get('model.form').save().then(resolve, reject);
            } else {
                return resolve();
            }
        });
    },
    validateRecord: function (type, record) {
        var name;
        if (type === 'filteredFields') {
            name = record.get('name');
            if (name === '' || name === void 0) {
                throw this.get('intl').t('form.editor.no_empty_names');
            }
        }
    },
    saveDirtyRecords: function (type) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var promises;
            promises = [];
            this.get(type).forEach((record) => {
                this.validateRecord(type, record);
                if (record.get('hasDirtyAttributes')) {
                    return promises.push(record.save());
                }
            });
            return Ember.RSVP.Promise.all(promises).then(resolve, reject);
        });
    },
    formSettingOptions: [
        {
            name: 'access_denied',
            value: 'true',
        },
        {
            name: 'hide_addrow',
            value: 'true',
        },
        {
            name: 'hide_edit',
            value: 'true',
        },
        {
            name: 'hide_remove',
            value: 'true',
        },
    ],
    tt3ServiceAndDevMode: Ember.computed('Ember.devModeOn', function () {
        if (Ember.get(Ember, 'devModeOn') && collector.get('tt3flag')) {
            return true;
        }
    }),
    actions: {
        saveFields: function () {
            this.set('state', 'saving');
            return this.saveDirtyForm().then(() => {
                return this.saveDirtyRecords('filteredFields')
                    .then(() => {
                        return this.saveDirtyRecords('filteredFormSettings').then(() => {
                            return this.saveDirtyRecords('filteredFieldSettings').then(() => {
                                this.get('settingsController').refreshNeeded();
                                return this.get('modelCreator')
                                    .createEmberModels()
                                    .then(() => {
                                        return this.set('state', 'clean');
                                    });
                            });
                        });
                    })
                    .catch((reason) => {
                        this.set('state', null);
                        return this.get('error').notify(reason);
                    });
            });
        },
        addField: function () {
            var external_type, field, nro;
            nro = this.get('filteredFields.lastObject.nro');
            if (!nro) {
                nro = 1;
            }
            external_type = 'addon_' + Math.floor(Math.random() * 10000 + 1);
            field = this.store.createRecord('form-field', {
                form: this.get('model.form'),
                nro: ++nro,
                type: 'textbox',
                external_type: external_type,
            });
            return this.transitionToRoute('settings.forms.form.field', external_type);
        },
        toggleShowFormInfo: function () {
            this.set('showFormInfo', !this.get('showFormInfo'));
            return false;
        },
        removeField: function (field) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('form.editor.remove_field'))
                .then((con) => {
                    if (con) {
                        return field.destroyRecord().then(() => {
                            this.get('store').unloadRecord(field);
                            return this.transitionToRoute(
                                'settings.forms.form',
                                this.get('model.form.id'),
                            );
                        });
                    }
                });
        },
        addSetting: function (option) {
            var values;
            this.set('clearAddSetting', true);
            if (option === 'custom') {
                values = {};
            } else {
                values = this.get('formSettingOptions').findBy('name', option);
            }
            values.form = this.get('model.form');
            return this.store.createRecord('form-setting', values);
        },
        reorderFields: function (itemModels, draggedModel) {
            var i;
            i = 1;
            return itemModels.forEach(function (item) {
                if (item.get('nro') > i) {
                    // don't edit nro's when its not needed
                    i = item.get('nro');
                }
                item.set('nro', i);
                return i++;
            });
        },
        //@set("model.fields", itemModels.sortBy('nro'))
        saveCCFunction: function () {
            this.set('showCCSave', false);
            return this.get('Collector').ttapi({
                url: 'ccfunctions/functions',
                method: 'post',
                data: {
                    form: this.get('model.form.external_type'),
                    function: this.get('ccfunctionvalue'),
                },
            });
        },
        toggleSave: function () {
            return this.set('showCCSave', true);
        },
        addCCFunctions: function () {
            return this.get('store')
                .createRecord('form-setting', {
                    name: 'assigned_function',
                    value: 'ccfunctions',
                    form: this.get('model.form'),
                })
                .save();
        },
    },
});

export default SettingsFormsFormController;
