import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import DS from 'ember-data';
import config from 'tt4/config/environment';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { resolve } from 'rsvp';
import { inject as service } from '@ember/service';

export default DS.RESTAdapter.extend(DataAdapterMixin, {
    flags: service(),
    sessionhelper: service(),

    headers: computed('session.data.authenticated.access_token', function () {
        let headers = {};
        if (this.session.isAuthenticated) {
            // OAuth 2
            headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
        }
        return headers;
    }),
    host: computed('session.currentUser.partnerid', 'flags._client', function () {
        assert(
            'TTAPI_HOST must be set in environment.js to use ttapi adapter',
            config.APP.TTAPI_HOST,
        );
        return config.APP.TTAPI_HOST;
    }),
    namespace: computed(function () {
        assert(
            'TTAPI_FOLDER must be set in environment.js to use ttapi adapter',
            config.APP.TTAPI_FOLDER,
        );
        return this.createNamespace();
    }),

    ensureResponseAuthorized(status, headers, payload, requestData) {
        if (status === 401 && payload.message === 'The access token provided has expired') {
            this.sessionhelper.expiredRecovery(`${requestData.method} ${requestData.url}`);
        } else {
            return this._super(...arguments);
        }
    },

    createNamespace() {
        let path = config.APP.TTAPI_FOLDER;
        if (path.slice(0, 1) === '/') {
            path = path.slice(1);
        }
        return path;
    },

    query() {
        //skip ttapi requests if at2
        if (config.target === 'at2') {
            return resolve([]);
        }
        return this._super(...arguments);
    },
});
