import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
    tagName: 'tr',
    classNames: ['table-header-row'],
    anomaly: true,

    weekNumber: computed(function () {
        return moment(get(this, 'week')).format('W');
    }),

    weekdays: computed(function () {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const date = moment(get(this, 'week')).add(i, 'days').format('YYYY-MM-DD');
            days.push({
                date: date,
                hours: this.sumHours(date),
            });
        }

        return days;
    }),

    sumHoursAlldays: computed('weekdays', function () {
        return get(this, 'weekdays').reduce((total, day) => total + day.hours, 0);
    }),

    actions: {
        toggle() {
            this.toggleOpen(get(this, 'isOpen') ? false : true);
        },
        check() {
            this.toggleChecked(get(this, 'isChecked') ? false : true);
        },
    },

    sumHours(date) {
        if (!get(this, 'data')) return 0;
        return get(this, 'data')
            .filterBy('date', date)
            .reduce((total, day) => total + (day.hours ? day.hours : 0), 0);
    },
});
