import { helper } from "@ember/component/helper";

export function hours([hours, defaultValue, omitUnit, leadingZeros]) {
    if (!hours || isNaN(hours)) hours = 0;
    let negative;

    if (hours < 0) {
        hours *= -1;
        negative = true;
    } else {
        negative = false;
    }

    let fullHours = Math.floor(hours);

    let minutes = Math.round((hours - fullHours) * 60);
    if (minutes === 60) {
        fullHours += 1;
        minutes = 0;
    }
    if (defaultValue && !hours && !minutes) {
        return defaultValue;
    }
    return (
        (negative ? "-" : "") +
        (leadingZeros && hours < 10 && hours >= 0
            ? "0" + fullHours
            : fullHours) +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes +
        (omitUnit ? "" : "h")
    );
}

export default helper(hours);
