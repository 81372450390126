import { helper } from '@ember/component/helper';
import moment from 'moment';

// This function receives the params `params, hash`
export function date_diff(params) {
    const start = moment().format('YYYY-MM-DD');
    const end = params[0];

    return moment.utc(end).diff(moment.utc(start), 'days');
}

export default helper(date_diff);
