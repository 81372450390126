/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/user-activity-log.coffee.old
// Generated by CoffeeScript 2.6.1
var UserActivityLog;

import DS from 'ember-data';

UserActivityLog = DS.Model.extend({
    action: DS.attr(),
    timestamp: DS.attr(),
    form: DS.attr(),
    rowids: DS.attr(),
    owners: DS.attr(),
    user: DS.belongsTo('user', {
        async: true,
    }),
});

export default UserActivityLog;
