import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
    tagName: 'tr',

    _dates: computed('dates', function () {
        return get(this, 'dates').map((date, index) => {
            if (index == 0 && moment(date).format('D') <= 26) return date; // show month in first column if next month is at least 4 days from this date
            return moment(date).format('D') === '1' ? date : ''; // show date string on every month change column
        });
    }),
});
