/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyTrAddRoute;

import Ember from 'ember';

SiteSafetyTrAddRoute = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function (params) {
        if (params.id !== 'add') {
            this.transitionTo('site_safety.measurement.add.measurement');
        }
        return params.measurement;
    },
});

export default SiteSafetyTrAddRoute;
