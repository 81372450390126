import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import fetch from 'fetch';
import config from 'tt4/config/environment';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { validateBusinessId, hetuvalidation } from 'tt4/utils/businessid_validation';

export default class CollectorFormAddFormsEmployerComponent extends Component {
    @service flags;
    @service store;
    @service intl;
    @service cc;
    @service notifications;
    @service session;
    @service ttapi;
    @service('collector-service') Collector;

    @tracked record;
    @tracked loading;
    @tracked showInviteButton;
    @tracked message;
    @tracked messageClass;
    @tracked TvReport;
    @tracked showTvModal;
    @tracked businessIDError;

    constructor() {
        super(...arguments);
        if (!this.args.record) {
            this.createEmployerRecord();
        } else {
            this.record = this.args.record;
        }
    }
    get styleNamespace() {
        return podNames['collector/form-add/forms/employer'];
    }
    @action
    onFieldChange(field, value) {
        this.record[field] = value;
        this.businessIDError = '';
        if (
            field === 'emp_id' &&
            (!this.record['emp_id_type'] || parseInt(this.record['emp_id_type']) === 0)
        ) {
            if (!validateBusinessId(value) && !hetuvalidation(value)) {
                this.businessIDError = 'employer.emp_id.wrong_format';
            }
        }
    }

    @action
    addNew() {
        this.createEmployerRecord();
    }

    @action
    addSame() {
        this.record = this.Collector.copyRecord(this.record);
    }

    createEmployerRecord() {
        this.record = this.store.createRecord('employer');
    }

    @action
    async getInfoFromTV() {
        this.loading = true;
        let message = '';
        const empId = this.record.emp_id;
        if (isEmpty(empId)) {
            this.loading = false;
            return;
        }

        try {
            let fetchData = await fetch(this.cc.host + config.APP.CC_TVEMP + '?tunnus=' + empId, {
                method: 'GET',
            });
            let data = await fetchData.json();
            if (!data.error) data = data.firstObject;
            message =
                data && data.status && !data.error
                    ? this.getFoundStatusMessage(data, empId)
                    : this.getNotFoundStatusMessage();
        } catch (e) {
            message = this.getFailedMessage();
        } finally {
            this.loading = false;
            this.message = message;
        }
    }

    @action
    async sendInviteTilaajavastuuEmail() {
        this.notifications.success(this.intl.t('general.email_sent'), {
            autoClear: true,
        });
        this.tvText = this.intl.t('employer.invite_tilaajavastuu_email', {
            company: this.session.currentUser.company,
        });
        const addition = this.intl.t('employer.tilaajavastuu_invite_footer_text');
        await this.ttapi.request('/sendEmail', {
            method: 'post',
            data: {
                email: this.record['emp_email'],
                content: this.tvText + '<br><br>' + addition,
                title: this.intl.t('employer.invite_tilaajavastuu_title'),
            },
        });
        this.showInviteModal = false;
    }

    getFoundStatusMessage(data, empId) {
        this.showTvModal = true;
        setProperties(this.record, {
            name: data.company,
            emp_country: data.country.toLowerCase(),
            tv_status: data.status.toLowerCase(),
        });
        switch (data.status) {
            case 'Ok':
                this.messageClass = 'alert-success';
                break;
            case 'Seis':
                this.messageClass = 'alert-danger';
                break;
            default:
                this.messageClass = 'alert-warning';
        }
        this.showTvReport(empId);
        return htmlSafe(
            '<div> \
                                <h3>' +
                this.intl.t('employer.add.status') +
                ': <b>' +
                data.status +
                '</b></h3> \
                                <h4>&nbsp;&nbsp;&nbsp;' +
                data.description +
                '</h4> \
                            </div>',
        );
    }

    getNotFoundStatusMessage() {
        this.record['tv_status'] = 'ei_loydy';
        this.record['archive_code'] = '';
        this.messageClass = 'alert-danger';
        this.showInviteButton = true;
        return htmlSafe(
            '<div> \
                                <h3><b>' +
                this.intl.t('employer.add.status.notfound') +
                '</b></h3>' +
                this.intl.t('employer.add.status.marketing') +
                '\
                            </div>',
        );
    }

    getFailedMessage() {
        this.record['tv_status'] = 'ei_loydy';
        this.record['archive_code'] = '';
        this.messageClass = 'alert-danger';
        return htmlSafe(
            '<div> \
                                <h3><b>' +
                this.intl.t('employer.add.status.failed') +
                '</b></h3> \
                            </div>',
        );
    }

    async showTvReport(empId) {
        try {
            let fetchData = await fetch(this.cc.host + config.APP.CC_TVREP + '?tunnus=' + empId, {
                method: 'GET',
            });
            let data = await fetchData.text();
            this.TvReport = htmlSafe(data);
        } catch (e) {
            this.notifications.warning(e, { autoClear: true });
        }
    }
}
