/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/weather.coffee.old
// Generated by CoffeeScript 2.6.1
var WeatherService;

import Ember from 'ember';

import config from '../config/environment';

import $ from 'jquery';

WeatherService = Ember.Service.extend({
    store: Em.inject.service(),
    gps: Ember.inject.service(),
    fetchWeatherPosition: function (proj = false, date = false) {
        if (date) {
            this.set('date', date);
        }
        return new Ember.RSVP.Promise((resolve, reject) => {
            var id;
            if (proj) {
                id = proj.get('id');
                if (proj.get('location_map')) {
                    return this.fetchWeatherByProject(proj).then((weather) => {
                        return resolve(weather);
                    });
                } else {
                    return this.get('store')
                        .findRecord('project', id, {
                            reload: true,
                        })
                        .then((project) => {
                            var projectLocation;
                            projectLocation = project.get('location_map');
                            if (projectLocation) {
                                return this.fetchWeatherByProject(project).then((weather) => {
                                    return resolve(weather);
                                });
                            } else {
                                return this.fetchByGPS().then((weather) => {
                                    return resolve(weather);
                                });
                            }
                        });
                }
            } else {
                return this.fetchByGPS().then((weather) => {
                    return resolve(weather);
                });
            }
        });
    },
    fetchWeatherByProject: function (project, date) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var projectLocation;
            projectLocation = JSON.parse(project.get('location_map'));
            return this.fetchWeather(projectLocation.latitude, projectLocation.longitude, date)
                .then(function (json) {
                    return resolve(json);
                })
                .catch(function (e) {
                    return reject(e);
                });
        });
    },
    fetchWeather: function (lat, lon, date) {
        var url;
        date = moment(date).format('YYYYMMDD');
        url = config.APP.WEATHER_HISTORY_API;
        //test-data
        /*
    weather = {
        temperature: "8"
        humidity: "95"
        windspeed: "3"
        weather: "Clouds"
        dew_point: 7
    }
    #@set 'value', weather
    new Ember.RSVP.Promise (resolve,reject) =>
        console.log weather
        resolve weather

     */
        if (date === moment().format('YYYYMMDD')) {
            url = config.APP.WEATHER_API;
        }
        return $.getJSON(
            url + '?lat=' + lat + '&lon=' + lon + '&date=' + date + '&hour=12',
            (json) => {
                var dew_point;
                dew_point = json.temperature - (100 - json.humidity) / 5;
                json.dew_point = Math.round(dew_point * 100) / 100;
                return json;
            },
        );
    },
    fetchByGPS: function (position, date) {
        return this.fetchWeather(position.latitude, position.longitude, date)
            .then(function (json) {
                return json;
            })
            .catch(function (e) {
                return e;
            });
    },
    calcDewPoint: function (temp, humidity) {
        var dew_point;
        dew_point = temp - (100 - humidity) / 5;
        return Math.round(dew_point * 100) / 100;
    },
});

export default WeatherService;
