import Ember from 'ember';
import { get, set } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import EmberError from '@ember/error';
import { htmlSafe } from '@ember/template';
import { later } from '@ember/runloop';

//get promotion messages from AT2

const PromoMessagesComponent = Component.extend({
    session: service(),
    Collector: service('collector-service'),
    localStorageWrapper: service(),
    dialogs: service(),
    router: service(),
    promoMessages: service(),

    forcedArr: A([]),

    didInsertElement() {
        if (!Ember.testing) {
            return later(
                this,
                () => {
                    return this.checkmessages();
                },
                6000,
            );
        }
    },

    actions: {
        closeModal() {
            return set(this, 'show', false);
        },
    },

    checkmessages() {
        // do not show promo-messages on the day that service was created
        if (
            moment(get(this, 'session.currentUser.company_info.row_info.created')).format(
                'YYYY-MM-DD',
            ) === moment().format('YYYY-MM-DD')
        ) {
            return;
        }
        // do not show promo-messages if service has company name as empty, trying to not show welcome wizard and promomessages on each other
        if (get(this, 'session.currentUser.company_info.company_name') === 'empty') {
            return;
        }
        // do not show promo-messages if new user who is changing password
        if (get(this, 'router.currentRouteName') === 'changepassword') {
            return;
        }

        //do not fetch messages if impersonated user
        if (get(this, 'session.session.content.authenticated.scope')) {
            return;
        }

        //const products = get("Collector").get("products");
        const products = get(this, 'Collector.products');
        const country = get(this, 'session.currentUser.company_info.country');
        const userlevel = get(this, 'session.currentUser.userlevel');

        //return if any of the parameter is null
        if (!products || !country || !userlevel) {
            return;
        }

        const promise = this.promoMessages.getMessages({
            country,
            userlevel,
            prods: products,
            new: true,
        });
        const read_msgs = A([]);
        const subtle_msgs = A([]);
        const ls_key = get(this, 'session.currentUser.id') + '_promomessages';
        const ls_sub_key = get(this, 'session.currentUser.id') + '_promomessages_subtle';
        const already_read = A(get(this, 'localStorageWrapper').getItem(ls_key));

        return promise.then(
            (data) => {
                //convert to json object and set as ember object
                if (data != null) {
                    const msg = data.data;
                    if (msg != null) {
                        msg.forEach((tmp) => {
                            if (already_read.indexOf(tmp.id) === -1) {
                                tmp.message = htmlSafe(tmp.message);
                                tmp.short_description = htmlSafe(tmp.short_description);
                                if (tmp.forced === true) {
                                    get(this, 'forcedArr').pushObject(tmp);
                                    return read_msgs.push(tmp.id);
                                } else {
                                    return subtle_msgs.push(tmp);
                                }
                            }
                        });

                        const all_read = already_read.concat(read_msgs);

                        get(this, 'localStorageWrapper').setItems(ls_key, all_read);
                        return get(this, 'localStorageWrapper').setItems(ls_sub_key, subtle_msgs);
                    }
                }
            },

            function (reason) {
                throw new EmberError(
                    'promomessages fetch fail: ' +
                        (() => {
                            try {
                                return JSON.stringify(reason);
                            } catch (error) {
                                return (reason = error);
                            }
                        })(),
                );
            },
        );
    },
});

export default PromoMessagesComponent;
