import Component from '@ember/component';
import { Promise as EmberPromise } from 'rsvp';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),

    transactions: computed('tooltypes.value', 'otherParams', function () {
        return ObjectProxy.extend(PromiseProxyMixin).create({
            promise: new EmberPromise((resolve) => {
                if (!get(this, 'tooltypes.value') || get(this, 'tooltypes.value.length') == 0) {
                    resolve({ value: [] });
                    return;
                }

                const params = get(this, 'otherParams');
                params.tooltype = get(this, 'tooltypes.value').mapBy('id').join(',');

                get(this, 'store')
                    .query('transaction', params)
                    .then((records) => this._resolveTransactions(records, resolve));
            }),
        });
    }),

    tooltypes: computed('group', function () {
        return ObjectProxy.extend(PromiseProxyMixin).create({
            promise: new EmberPromise((resolve) => {
                get(this, 'store')
                    .query('tooltype', { toolgroup: get(this, 'group.id') })
                    .then((records) => this._resolveTooltypes(records, resolve));
            }),
        });
    }),

    actions: {
        valueChanged(value, tooltype, status, transactionid) {
            this.change(value, tooltype, status, transactionid);
        },
    },

    _resolveTransactions(records, resolve) {
        resolve({ value: records.sortBy('tooltype.type') });
    },

    _resolveTooltypes(records, resolve) {
        resolve({ value: records });
    },
});
