/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/multi-buttons.coffee.old
// Generated by CoffeeScript 2.6.1
var MultiButtonsComponent;

import Ember from 'ember';

import $ from 'jquery';

MultiButtonsComponent = Ember.Component.extend({
    // button array Em.A Ember.Object.create {id: id,name: text}
    content: null,
    orderable: false,
    initValues: Ember.on('init', function () {
        var content, counter, values;
        values = this.get('values');
        content = this.get('content');
        if (values) {
            counter = 1;
            return $.each(values, (key, item) => {
                var object;
                object = content.find(function (test) {
                    return test.id === item;
                });
                if (!object) {
                    return;
                }
                object.set('active', true);
                if (this.get('orderable')) {
                    return object.set('order', counter++);
                }
            });
        } else {
            return Ember.run.next(this, function () {
                return this.set('values', Em.A([]));
            });
        }
    }),
    updateValues: function () {
        var content, values;
        values = Em.A([]);
        content = this.get('content');
        $.each(content.sortBy('order'), (key, item) => {
            if (item.get('active')) {
                return values.pushObject(item.get('id'));
            }
        });
        return Ember.run.next(this, function () {
            return this.set('values', values);
        });
    },
    reorderButtons: function () {
        var content, counter;
        content = this.get('content');
        counter = 1;
        return $.each(content.sortBy('order'), (key, item) => {
            if (item.get('active')) {
                return item.set('order', counter++);
            }
        });
    },
    actions: {
        click: function (button) {
            var values;
            if (this.get('singleSelectMode')) {
                this.get('content').forEach((btn) => {
                    return btn.set('active', false);
                });
                button.set('active', true);
                this.updateValues();
                return;
            }
            values = this.get('values');
            if (button.get('active')) {
                button.set('order', null);
                button.set('active', false);
                if (this.get('orderable')) {
                    this.reorderButtons();
                }
            } else {
                if (this.get('orderable')) {
                    button.set('order', values.length + 1);
                }
                button.set('active', true);
            }
            return this.updateValues();
        },
    },
});

export default MultiButtonsComponent;
