define("tt4/services/google-charts", ["exports", "ember-google-charts/services/google-charts"], function (_exports, _googleCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleCharts.default;
    }
  });
});
