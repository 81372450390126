import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
    tagName: 'span',
    classNames: ['setting-value-input'],
    type: 'text',

    actions: {
        changeDate(event) {
            //format date to locale DD MM YYYY
            let date = event.target.value;
            set(this, 'value', date);
            this.changeValue(date);
        },
    },
});
