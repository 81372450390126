/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/trnotices/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditTrnoticesController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

ProjectsEditTrnoticesController = GeneralReportController.extend({
    form: 'trnotice',
    showColumns: ['date', 'project', 'title', 'desc', 'status', 'pic', 'user'],
    queryParams: ['date', 'status', 'user'],
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
        }),
        sortByColumn: 'date',
    }),
    filters: Em.A([]),
    status: 'all',
});

export default ProjectsEditTrnoticesController;
