/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/workday-calendar/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorkdayCalendarRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

WorkdayCalendarRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
        worktimegroup: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params.country = this.get('session.currentUser.company_info.country');
        return Ember.RSVP.hash({
            days: this.get('store').query('workday-day', params),
            weeks: this.get('store').query('workday-week', params),
            overtimeRules: this.get('store')
                .query('workday-overtimeRule', params)
                .then((rules) => {
                    return rules.forEach((rule) => {
                        var rulejson;
                        if (typeof rule.get('rules') !== 'string' || !rule.get('rules')) {
                            return;
                        }
                        rulejson = JSON.parse(rule.get('rules')).map((rule) => {
                            if (rule.field && !this.Collector.fieldExists('worktime', rule.field)) {
                                rule.field = null;
                            }
                            return rule;
                        });
                        return rule.set('rules', rulejson);
                    });
                }),
        });
    },
    actions: {
        save: function (type) {
            var record;
            record = this.get('controller.' + type);
            if (record.get('isSaving')) {
                return;
            }
            return record.save().then(() => {
                this.refresh();
                return this.set('controller.' + type, false);
            });
        },
        removeRow: function (row) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return row.destroyRecord().then(() => {
                            return this.refresh();
                        });
                    }
                });
        },
        removeWeekRow: function (row) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return row.destroyRecord().then(() => {
                            return this.refresh();
                        });
                    }
                });
        },
        removeDayRow: function (row) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return row.destroyRecord().then(() => {
                            this.set('controller.dayRule', false);
                            return this.refresh();
                        });
                    }
                });
        },
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default WorkdayCalendarRoute;
