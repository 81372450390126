/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/record-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorRecordCompComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import saveTheChildren from '../../../mixins/save-the-children';

import validator from 'tt4/classes/validations';

import { isArray } from '@ember/array';

import { copy } from 'ember-copy';

import $ from 'jquery';

CollectorRecordCompComponent = Ember.Component.extend(saveTheChildren, {
    // Properties

    saveTheChildrenRawErrors: true,
    // Store so we can fetch record
    store: Em.inject.service(),
    modalScroller: Ember.inject.service(),
    evented: Ember.inject.service(),
    // Collector service
    Collector: Em.inject.service('collector-service'),
    // Form of the record
    form: null,
    // ID of the record
    record_id: null,
    // Record we are working with
    record: null,
    // Fields on this form
    fields: Em.computed('record', {
        set: function (key, value) {
            return (this._value = value);
        },
        get: function () {
            var c, fields, form, mandatory, record, skip;
            if (this._value) {
                return this._value;
            }
            // Collector service
            c = this.get('Collector');
            // Collector form
            form = this.get('form');
            // Record
            record = this.get('record');
            // if needed, you can skip fields or add mandatory-settings to them (used in separating one-day and multi-day absences)
            skip = this.get('settings.skip');
            mandatory = this.get('settings.mandatory');
            if (!record) {
                return;
            }
            Em.assert('You need to provide the FORM for record comp', form);
            // Fetch form fields with collector service
            fields = this._mapFields(c.fieldArray(form));
            return fields.filter((item) => {
                var ref, ref1, ref2, ref3;
                if (item.features.stashed) {
                    return false;
                }
                if (
                    (ref = item.type) === 'fileupload' ||
                    ref === 'row_linker' ||
                    ref === 'rowlink' ||
                    ref === 'device' ||
                    ref === 'dropdown_language'
                ) {
                    return false;
                }
                if (
                    form === 'user' &&
                    ((ref1 = item.name) === 'fingerprint' || ref1 === 'setting_wap_font_size')
                ) {
                    return false;
                }
                if (skip && ((ref2 = item.name), indexOf.call(skip, ref2) >= 0)) {
                    return false;
                }
                if (mandatory && ((ref3 = item.name), indexOf.call(mandatory, ref3) >= 0)) {
                    item.features = copy(item.features);
                    Em.set(item, 'features.mandatory', true);
                }
                return true;
            });
        },
    }),
    _mapFields: function (fields) {
        var record;
        // Fetch form fields with collector service
        record = this.get('record');
        return fields.map((field) => {
            var value;
            if (field.name === 'location' || field.name === 'end_location') {
                field.type = 'gpslocation';
            }
            if (field.type === 'database' && record.get(field.name)) {
                field.value = record.get(field.name + '.id');
            } else if (field.type !== 'databasearray') {
                field.value = record.get(field.name);
            }
            if (
                this.get('record.isNew') &&
                field.features.default_value &&
                Em.isEmpty(record.get(field.name))
            ) {
                value = this.getDefaultValue(field);
                field.value = value;
                this.send('setValue', value, field.name);
            }
            return this.returnField(field);
        });
    },
    // Public way to do whatever you like with the field by extending
    returnField: function (field) {
        return field;
    },
    getDefaultValue: function (field) {
        if (field.type === 'date') {
            if (field.features.default_value === 'today') {
                return moment().format('YYYY-MM-DD');
            } else if (field.features.default_value === 'yesterday') {
                return moment().subtract(1, 'day').format('YYYY-MM-DD');
            }
        }
        return field.features.default_value;
    },
    addNeededObservers: Em.on(
        'init',
        Em.observer('fields', function () {
            if (!this.get('fields')) {
                return;
            }
            return this.get('fields').forEach((field) => {
                var observe_fields;
                // hide array means there are fields that are visible is some conditions
                if (Em.isArray(field.features.hide)) {
                    observe_fields = this.getFieldsToObserve(field.features.hide);
                    return observe_fields.forEach((observe_field) => {
                        this.addObserver(
                            'record.' + observe_field,
                            {
                                conditions: field.features.hide,
                                field: field.name,
                            },
                            this.setFieldVisibility,
                        );
                        return this.get('record').notifyPropertyChange(observe_field);
                    });
                }
            });
        }),
    ),
    getFieldsToObserve: function (condition_array) {
        var condition, field, i, len, temp, value;
        temp = [];
        for (i = 0, len = condition_array.length; i < len; i++) {
            condition = condition_array[i];
            for (field in condition) {
                value = condition[field];
                temp.push(field);
            }
        }
        return temp;
    },
    setFieldVisibility: function (sender, key) {
        var features, field;
        // set field visibility according to are conditions met or not
        field = sender.get('fields').findBy('name', this.field);
        features = copy(field.features);
        Ember.set(features, 'hide', !sender.areTheseMet(this.conditions));
        Ember.set(field, 'features', features);
        return sender.notifyPropertyChange('showFields');
    },
    areTheseMet: function (condition_array) {
        if (condition_array && !isArray(condition_array)) {
            return true;
        } else if (isArray(condition_array) && this.get('record')) {
            return validator._is_visible(this.get('record'), condition_array);
        }
        return false;
    },
    showFields: Em.computed('fields', 'record', function () {
        if (!this.get('fields')) {
            return;
        }
        return this.get('fields').reject((field) => {
            var model;
            if (typeof field.features.hide === 'object') {
                model = this.get('record');
                if (
                    field.features.hide.condition === "'!$editing'" ||
                    field.features.hide.condition === '!$editing'
                ) {
                    // hide if adding
                    if (model.get('isNew')) {
                        // adding
                        return true; // hide
                    } else {
                        return false; // do not hide
                    }
                }
                if (
                    field.features.hide.condition === "'$editing'" ||
                    field.features.hide.condition === '$editing'
                ) {
                    // hide if editing
                    if (!model.get('isNew')) {
                        // editing
                        return true; // hide
                    } else {
                        return false; // do not hide
                    }
                }
                return true;
            } else {
                return field.features.hide;
            }
        });
    }),

    //   Methods

    recordSetter: Em.on(
        'init',
        Em.observer('form', 'record_id', function () {
            var form, found, id, self, store, values;
            self = this;
            // Collector form external type
            form = this.get('form');
            // Record ID
            id = this.get('record_id');
            // Store
            store = this.get('store');
            Em.assert('You need to provide the FORM for record comp', form);
            // If we don't have a record ID we create a new record
            if (!id) {
                values = {};
                if (this.get('settings.defaultValues')) {
                    values = this.get('settings.defaultValues');
                }
                Ember.run.next(this, function () {
                    return this.set('record', store.createRecord(form.dasherize(), values));
                });
                return;
            }
            // Get record from store
            if (id === 'new') {
                found = store.peekAll(form.dasherize()).find((record) => {
                    return record.get('hasDirtyAttributes');
                });
                Ember.assert(
                    found,
                    "When using record-comp with id 'new' you must add dirty record to store before calling this",
                );
                return Ember.run.next(this, function () {
                    return this.set('record', found);
                });
            } else {
                return store
                    .findRecord(form.dasherize(), id, {
                        include: true,
                    })
                    .then(function (rec) {
                        return Ember.run.next(this, function () {
                            return self.set('record', rec);
                        });
                    });
            }
        }),
    ),
    refreshSettings: Ember.on(
        'init',
        Ember.observer('settings.form', 'settings.id', function () {
            var getSettings, self, settings;
            getSettings = ['form', 'id'];
            settings = this.get('settings');
            if (!settings) {
                return;
            }
            self = this;
            return $.each(getSettings, function (key, item) {
                var new_value, old_value;
                if ((!item) in settings) {
                    return;
                }
                old_value = self.get(item);
                new_value = settings[item];
                if (old_value !== new_value) {
                    return self.set(item, new_value);
                }
            });
        }),
    ),
    willClearRender: function () {
        if (!this.get('fields')) {
            return;
        }
        return this.get('fields').forEach((field) => {
            var observe_fields;
            if (Em.isArray(field.features.hide)) {
                observe_fields = this.getFieldsToObserve(field.features.hide);
                return observe_fields.forEach((observe_field) => {
                    return this.removeObserver(
                        'record.' + observe_field,
                        {
                            conditions: field.features.hide,
                            field: field.name,
                        },
                        this.setFieldVisibility,
                    );
                });
            }
        });
    },
    stashed_fields: Em.computed('record', function () {
        var c, fields, form, record;
        // Collector service
        c = this.get('Collector');
        // Collector form
        form = this.get('form');
        // Record
        record = this.get('record');
        if (!record) {
            return;
        }
        Em.assert('You need to provide the FORM for record comp', form);
        // Fetch form fields with collector service
        fields = this._mapFields(c.fieldArray(form));
        return fields.filterBy('features.stashed');
    }),
    scrollIfErrors: function () {
        return Em.run.next(this, function () {
            if ($('.has-error').length > 0) {
                return this.get('modalScroller').scrollVertical($('.has-error')[0]);
            }
        });
    },
    actions: {
        setValue: function (value, field, set = true) {
            var record;
            record = this.get('record');
            if (set) {
                if (Ember.typeOf(field) === 'object' && field.type === 'databasearray') {
                    return record.set(field.form, value);
                } else {
                    return record.set(field, value);
                }
            }
        },
        save: function () {
            var record;
            this.scrollIfErrors();
            record = this.get('record');
            this.set('saving', true);
            return record.validate().then(() => {
                if (record.get('isValid')) {
                    return this.save_record(record).then(
                        () => {
                            this.set('saving', false);
                            this.send('afterSave', this.get('settings'), this.get('record'));
                            this.get('evented').storeEvent(
                                'insert',
                                this.get('form'),
                                this.get('record'),
                            );
                            return this.sendAction(
                                'recordSaved',
                                this.get('settings'),
                                this.get('record'),
                            );
                        },
                        (e) => {
                            this.set('saving', false);
                            return this.set('validationMessage', e);
                        },
                    );
                } else {
                    return this.set('saving', false);
                }
            });
        },
        afterSave: function (settings, record) {},
        cancel: function () {
            if (this.get('record')) {
                if (this.get('record.isNew')) {
                    this.get('record').deleteRecord();
                } else {
                    this.get('record').rollbackAttributes();
                }
            }
            return this.sendAction('cancelClicked', this.get('settings'), true);
        },
        show_stashed_fields: function () {
            this.toggleProperty('stashed_visible');
            return false;
        },
    },
});

export default CollectorRecordCompComponent;
