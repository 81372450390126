import Component from '@ember/component';

export default Component.extend({
    tagName: '',
    actions: {
        refreshReport() {
            this.refreshReport();
        },
    },
});
