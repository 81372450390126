/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/removed/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersRemovedController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

EmployersRemovedController = GeneralReportController.extend({
    flags: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    form: 'employer',
    showColumns: [
        'name',
        'emp_id',
        'tv_status',
        'tv_pdf',
        'date_checked',
        'parent',
        'emp_users_given',
        'emp_email',
        'emp_phone',
    ],
    queryParams: ['page', 'q', 'emp_users_given', 'parent'],
    init_functions: function () {
        if (
            this.get('flags').test('form-report-employers') ||
            this.get('flags').test('ember-movenium-form-report-employers')
        ) {
            return null;
        } else {
            return this._super();
        }
    },
    filters: Ember.computed(function () {
        var prompt_text;
        prompt_text = this.get('intl').t('general.select.emp_users_given');
        return Em.A([
            Em.Object.create({
                type: 'dropdown_values',
                field: 'emp_users_given',
                prompt: prompt_text,
                options: [
                    {
                        id: '1',
                        value: this.get('intl').t('employer.emp_users_given.1'),
                    },
                    {
                        id: '2',
                        value: this.get('intl').t('employer.emp_users_given.2'),
                    },
                    {
                        id: '3',
                        value: this.get('intl').t('employer.emp_users_given.3'),
                    },
                ],
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
        ]);
    }),
    row_info_status: 'removed',
    settings: Em.Object.create({
        showStatus: 'removed',
        rowActions: [],
    }),
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
    },
});

export default EmployersRemovedController;
