import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul class=\"list-group\">\n    <li class=\"list-group-item\">\n        <ul class=\"list-unstyled\">\n            {{#each this.checkboxes as |box|}}\n                <label class=\"ember-movenium-checkbox\">\n                <p class=\"ember-movenium-checboxgroup-text\">{{box.text}}</p>\n                    <Input\n                        @type=\"checkbox\"\n                        @checked={{box.checked}}\n                        {{on 'change' (fn this.handleChange box)}}\n                    />\n                    <span class=\"ember-movenium-checkmark\"></span>\n                </label>\n            {{/each}}\n        </ul>\n    </li>\n</ul>", {"contents":"<ul class=\"list-group\">\n    <li class=\"list-group-item\">\n        <ul class=\"list-unstyled\">\n            {{#each this.checkboxes as |box|}}\n                <label class=\"ember-movenium-checkbox\">\n                <p class=\"ember-movenium-checboxgroup-text\">{{box.text}}</p>\n                    <Input\n                        @type=\"checkbox\"\n                        @checked={{box.checked}}\n                        {{on 'change' (fn this.handleChange box)}}\n                    />\n                    <span class=\"ember-movenium-checkmark\"></span>\n                </label>\n            {{/each}}\n        </ul>\n    </li>\n</ul>","moduleName":"tt4/components/addon/fields/checkboxgroup.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/checkboxgroup.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface ICheckboxGroupField {
    form: string;
    name: string;
    options: { [key: string]: string };
    value: string[];
    onChange(arg0: string[]): void;
}

export default class FieldsCheckboxgroupComponent extends Component<ICheckboxGroupField> {
    @service private intl: any;

    get checkboxes() {
        if (!this.args.options) return [];

        const value = this.args.value || [];

        return Object.keys(this.args.options).map((key: string) => {
            let text = this.args.options[key];
            if (text.substring(0, 1) === '$')
                text = this.intl.t(this.args.form + 's.' + this.args.name + '.' + key);
            return {
                key,
                text,
                checked: value.includes(key.toString()),
            };
        });
    }

    @action
    protected handleChange(box: any) {
        const index = box.key.toString();
        const ret = new Set(this.args.value);
        if (ret.has(index)) ret.delete(index);
        else ret.add(index);
        this.args.onChange(Array.from(ret));
    }
}
