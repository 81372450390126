/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/company/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserId06CompanyController;

import Ember from 'ember';

import validator from '../../../../mixins/base-validation';

RegisterUserId06CompanyController = Ember.Controller.extend(validator, {
    registerUserController: Ember.inject.controller('register/user'),
    actions: {
        gotoNextPage: function (isCompanyChanged) {
            return this.transitionToRoute('register.user.id06').then(function (newRoute) {
                newRoute.controller.set('customEmployer', isCompanyChanged);
                if (isCompanyChanged) {
                    newRoute.controller.set('model.employer_name', null);
                    newRoute.controller.set('model.employer_id', null);
                    return newRoute.controller.set('model.employer_country', null);
                }
            });
        },
    },
});

export default RegisterUserId06CompanyController;
