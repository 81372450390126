/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/timecard/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimecardAddRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

TimecardAddRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    store: Ember.inject.service(),
    gui: Ember.inject.service(),
    date: null,
    localStorageWrapper: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    openWorktime: false,
    newWorktime: false,
    beforeModel: function () {
        this._super(...arguments);
        return this.set('gui.hideMobileHeader', true);
    },
    model: function (params) {
        var create_new, self;
        self = this;
        create_new = true;
        return this.store
            .query('worktime', {
                date:
                    moment().subtract(1, 'day').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
                user: 'self',
                sideload: true,
                order: 'date-,endtime-',
            })
            .then(function (data) {
                var project, task, worktime;
                if (data.get('firstObject')) {
                    data.forEach(function (worktime) {
                        if (!worktime.get('endtime')) {
                            create_new = false;
                            self.get('store').findRecord('project', worktime.get('project.id'), {
                                reload: true,
                            });
                            return self.set('openWorktime', worktime);
                        }
                    });
                }
                if (create_new) {
                    worktime = self.store.createRecord('worktime', {
                        on_worksite: true,
                    });
                    project = self.get('localStorageWrapper').getUserSetting('project');
                    if (self.get('session.currentUser.currentMySite')) {
                        project = self.get('session.currentUser.currentMySite.id');
                    }
                    if (project && Ember.typeOf(project) !== 'array') {
                        self.store.findRecord('project', project).then(function (record) {
                            return worktime.set('project', record);
                        });
                    }
                    if (self.get('Collector').testNeeds(['worktime.task'])) {
                        task = self.get('localStorageWrapper').getUserSetting('task');
                        if (task && Ember.typeOf(task) !== 'array') {
                            self.store.findRecord('task', task).then(function (record) {
                                return worktime.set('task', record);
                            });
                        }
                    }
                    self.set('newWorktime', worktime);
                }
                return data;
            });
    },
    setupController: function (controller, model) {
        // if contoller open worktime is removed reset it
        if (
            controller.get('openWorktime') &&
            controller.get('openWorktime').get('currentState.stateName') === 'root.deleted.saved'
        ) {
            controller.set('openWorktime', null);
        }
        // do not overwrite controller openWorktime if we are refreshing model (screws up gps-calculating etc)
        if (this.get('openWorktime') && !controller.get('openWorktime')) {
            controller.set('openWorktime', this.get('openWorktime'));
        }
        if (this.get('newWorktime') && !controller.get('openWorktime')) {
            controller.set('openWorktime', this.get('newWorktime'));
        }
        controller.set('model', model);
        return controller.setMySitesProject();
    },
    resetController: function (controller, isExiting, transition) {
        this._super.apply(this, arguments);
        if (isExiting) {
            controller.set('started_worktime', false);
            return this.set('gui.hideMobileHeader', false);
        }
    },
    actions: {
        endWork: function () {},
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default TimecardAddRoute;
