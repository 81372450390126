/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/thereport/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ThereportController;

import Ember from 'ember';

ThereportController = Ember.Controller.extend({
    intl: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    subMenus: Em.computed('model', function () {
        var menus;
        menus = Em.A([]);
        this.get('model').forEach((item, index) => {
            return menus.pushObject({
                text: item.name,
                link: ['thereport.page', index],
            });
        });
        menus.pushObject({
            text: this.get('intl').t('thereport.settings'),
            link: ['thereport.settings'],
        });
        return menus;
    }),
    saveReport: function (id, name, settings) {
        return this.get('Collector').ttapi({
            method: 'post',
            url: 'comboreport',
            data: {
                id: id,
                name: name,
                settings: JSON.stringify(settings),
            },
        });
    },
    removeReport: function (id) {
        return this.get('Collector').ttapi({
            method: 'delete',
            url: 'comboreport/' + id,
        });
    },
});

export default ThereportController;
