/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/register/service/amounts.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterAmountsController;

import Ember from 'ember';

RegisterAmountsController = Ember.Controller.extend({
    registerController: Ember.inject.controller('register'),
    selectedProducts: Ember.computed.alias('registerController.selectedProducts'),
    products: Ember.computed.alias('registerController.products'),
    observeAmounts: Ember.on(
        'init',
        Ember.observer('product_list.@each.amount', function () {
            var currency, i, len, price, product, product_list, sum;
            product_list = this.get('product_list');
            sum = 0;
            for (i = 0, len = product_list.length; i < len; i++) {
                product = product_list[i];
                currency = product.get('currency');
                price = product.get('amount') * product.get('price');
                product.set('rowprice', price || '---');
                if (price) {
                    sum += price;
                }
            }
            this.set('sum', sum || '---');
            return this.set('sumcurrency', currency);
        }),
    ),
    product_list: Ember.computed('selectedProducts', 'products', function () {
        var i, len, product, ret, selectedProducts;
        selectedProducts = this.get('selectedProducts');
        //        products = @get 'products'
        ret = Em.A([]);
        for (i = 0, len = selectedProducts.length; i < len; i++) {
            product = selectedProducts[i];
            ret.pushObject(
                Em.Object.create({
                    name: product.header,
                    price: product.price,
                    currency: product.currency,
                    placeholder: this.getPlaceholder(product.pricetype),
                }),
            );
        }
        return ret;
    }),
    getPlaceholder: function (pricetype) {
        switch (pricetype) {
            case 'peruser':
                return 'users';
            case 'perproject':
                return 'projects';
            default:
                return 'amount';
        }
    },
});

export default RegisterAmountsController;
