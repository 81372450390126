/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/at-site/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryAtSiteController;

import Ember from 'ember';

WorksiteDiaryAtSiteController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    addController: Ember.inject.controller('worksitediary.report.add'),
    fields: [
        'diaryreport.foremans_at_site',
        'diaryreport.workers_at_site',
        'diaryreport.subcontractors_at_site',
        'diaryreport.side_contractors_at_site',
        'diaryreport.other_contractors_at_site',
        'diaryreport.stock_changes_at_site',
    ],
    total_workforce: Ember.computed('work_force', function () {
        return this.get('work_force.firstObject');
    }),
    work_force: Em.computed('model.work_force', function () {
        var splitted, workforce, workforce_arr;
        if (this.get('Collector').fieldExists('diaryreport', 'work_force')) {
            workforce_arr = Em.A([]);
            workforce = this.get('model.work_force');
            if (workforce) {
                splitted = workforce.split(',');
                splitted.forEach(function (item) {
                    var splitted_item, this_field;
                    if (item === '') {
                        return;
                    }
                    splitted_item = item.split(':');
                    this_field = Ember.Object.create({
                        name: '',
                        amount_own: 0,
                        amount_ue: 0,
                        input: false,
                    });
                    splitted_item.forEach((field) => {
                        var field_values;
                        if (field.indexOf('/') !== -1) {
                            field_values = field.split('/');
                            this_field.set('amount_own', parseInt(field_values[0], 10));
                            return this_field.set('amount_ue', parseInt(field_values[1], 10));
                        } else {
                            return this_field.set('name', field.trim(' '));
                        }
                    });
                    return workforce_arr.pushObject(this_field);
                });
            } else {
                workforce_arr.pushObject(
                    Em.Object.create({
                        name: 'Total',
                        amount_own: 0,
                        amount_ue: 0,
                        input: true,
                    }),
                );
            }
            return workforce_arr;
        }
    }),
    work_force_rest: Ember.computed('work_force.[]', function () {
        return this.get('work_force').slice(1);
    }),
    actions: {
        updateField: function (value, field) {
            return this.set('model.' + field, value);
        },
        setValue: function (obj) {
            return obj.set('input', false);
        },
        openEdit: function (obj) {
            obj.set('input', true);
        },
        addWorkforceRow: function () {
            return this.get('work_force').pushObject(
                Ember.Object.create({
                    name: '',
                    amount_own: 0,
                    amount_ue: 0,
                    input: true,
                }),
            );
        },
    },
});

//        calculateWorkforce: (field) ->
//            total = 0
//            @get('work_force').forEach (item) ->
//                total += parseInt(item[field], 10)

//            @set('total_workforce.'+field, total)
//            workforce = @get('total_workforce')
//            if (workforce.get(field) - total) < 0
//                workforce.set(field, 0)
//            else
//                workforce.set(field, workforce.get(field) - total)
//            @set('total_workforce', workforce)
export default WorksiteDiaryAtSiteController;
