import BaseAdapter from 'ember-metrics/metrics-adapters/base';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
/*global FirebasePlugin*/

//To use this set analyticsSettings.name = FirebaseAnalytics in config/environment.js
//Corber beforeBuild hook will set this for apps tt4/fastlane/corber/hooks/beforeBuild.js
export default BaseAdapter.extend({
    toStringExtension() {
        return 'firebase-metrics';
    },

    init() {
        //This function is required by the adapter to function
    },

    trackEvent({ category, action }) {
        assert(
            "You must give the category to firebase-metrics trackEvent method {category: 'My-Category'}",
            isPresent(category),
        );
        assert(
            "You must give the action to firebase-metrics trackEvent method {category: 'My-Category', action: 'someAction'}",
            isPresent(action),
        );
        FirebasePlugin.logEvent(category, { action: action });
    },

    trackPage({ page }) {
        assert('You must give the page to firebase-metrics trackPage method', isPresent(page));
        FirebasePlugin.setScreenName(page);
    },
});
