import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    session: service(),
    intl: service(),
    flags: service(),

    sentPage: false,
    userEmail: null,
    userPhone: null,
    feedback_toggled: true,

    disabled: computed(
        'shouldAskForContactDetails',
        'feedback_text',
        'userEmail',
        'userPhone',
        function () {
            if (
                this.shouldAskForContactDetails &&
                !this.validateEmail(this.userEmail) &&
                !this.validatePhone(this.userPhone)
            ) {
                return true;
            }
            return isEmpty(get(this, 'feedback_text'));
        },
    ),

    shouldAskForContactDetails: computed('feedback_toggled', function () {
        if (!get(this, 'feedback_toggled')) {
            return false;
        }

        return (
            !this.validateEmail(this.session.currentUser.email) &&
            !this.validatePhone(this.session.currentUser.phone)
        );
    }),

    actions: {
        sendFeedback() {
            get(this, 'Collector').ttapi({
                url: 'sendEmail',
                data: {
                    email: this.flags.test('send-feedback-email'),
                    title:
                        this.intl.t('widget.feedback.email-title') +
                        ' - ' +
                        get(this, 'session.currentUser.company'),
                    content: this.getEmailContent(),
                },
                method: 'post',
            });

            set(this, 'sentPage', true);
            later(
                this,
                function () {
                    this.resetView();
                },
                10000,
            );
        },
    },

    validatePhone(number) {
        return String(number).length > 4;
    },

    validateEmail(value) {
        return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
            value,
        );
    },

    resetView() {
        set(this, 'feedback_text', null);
        set(this, 'feedback_toggled', true);
        set(this, 'sentPage', false);
    },

    getEmailContent() {
        let feedback = '';
        let emailContent = A([]);

        let userEmail = this.session.currentUser.email || this.userEmail;
        let userPhone = this.session.currentUser.phone || this.userPhone;

        let toggleText = get(this, 'feedback_toggled')
            ? this.intl.t('general.yes')
            : this.intl.t('general.no');
        let user = this.session.currentUser.firstname + ' ' + this.session.currentUser.lastname;

        emailContent.push([
            this.intl.t('widget.feedback.partnerid'),
            this.session.currentUser.partnerid,
        ]);
        emailContent.push([
            this.intl.t('user.userlevel'),
            this.intl.t('user.userlevel.' + this.session.currentUser.userlevel),
        ]);
        emailContent.push([this.intl.t('widget.feedback.user'), user]);
        emailContent.push([this.intl.t('widget.feedback.email'), userEmail]);
        emailContent.push([this.intl.t('widget.feedback.phone'), userPhone]);
        emailContent.push([this.intl.t('widget.feedback.toggle'), toggleText]);
        emailContent.push([this.intl.t('widget.feedback.text'), get(this, 'feedback_text')]);

        emailContent.forEach((row) => {
            feedback += row[0] + ': ' + row[1] + '\r\n\n';
        });

        return feedback;
    },
});
