/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersAddController,
    indexOf = [].indexOf;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

import { t } from 'ember-intl';

import $ from 'jquery';

EmployersAddController = GeneralAddController.extend({
    session: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    notifications: Ember.inject.service(),
    intl: Em.inject.service(),
    cc: Em.inject.service(),
    //    needs: 'employers/saved'
    modalTitle: 'Tilaajavastuu.fi-raportti',
    showInviteDialog: false,
    messageButtonText: Em.computed(function () {
        return this.get('intl').t('employer.tilaajavastuu_invite_button');
    }),
    tilaajavastuuText: Em.computed('session.currentUser.company', function () {
        return this.get('intl').t('employer.invite_tilaajavastuu_email', {
            company: this.get('session.currentUser.company'),
        });
    }),
    tilaajavastuuHiddenAddition: Em.computed(function () {
        return this.get('intl').t('employer.tilaajavastuu_invite_footer_text');
    }),
    //    special_disable: ['tv_status','date_checked','archive_code']
    // Little häx for setting tv_status to ei_loydy before fetching
    reportRead: t('employer.add.tv_report.read'),
    setNotChecked: function () {
        if (collector.getFormObject('employer.tv_status')) {
            this.set('model.tv_status', 'ei_loydy');
            return this.set('pretext', this.get('intl').t('employer.add.pretext.check_status'));
        }
    }.observes('model'),
    setParentEmployer: Em.observer('model', 'session.currentUser', function () {
        var field, user;
        field = collector.getFormObject('employer.parent');
        if (field.features != null && field.features.do_not_preselect) {
            return;
        }
        if (!this.get('model.isNew')) {
            return;
        }
        if (collector.getFormObject('employer.parent') && this.get('session.currentUser')) {
            user = this.store.query('user', {
                id: this.get('session.currentUser.id'),
                sideload: true,
            });
            return user.then(() => {
                return this.set('model.parent', user.get('firstObject.employer'));
            });
        }
    }),
    setCustomization: Em.on('init', function () {
        if (collector.getFormObject('employer.tv_status')) {
            return this.set('customization', {
                emp_id: {
                    template: 'employers/add/emp_id',
                },
                tv_status: {
                    features: {
                        disabled: true,
                    },
                },
                date_checked: {
                    features: {
                        disabled: true,
                    },
                },
                archive_code: {
                    features: {
                        disabled: true,
                    },
                },
                emp_phone: {
                    template: 'employers/add/emp_phone',
                },
            });
        }
    }),
    countryLists: Ember.inject.service(),
    setCountryCode: function () {
        var code, codes, codesByNumber, companyCountry, i, len, model, number, ref, userCountry;
        model = this.get('model');
        codes = this.get('countryLists.phoneList');
        codesByNumber = codes.mapBy('number');
        userCountry = this.get('session.currentUser.homecountry');
        companyCountry = this.get('session.currentUser.company_info.country');
        number = null;
        if (
            !model.get('emp_phone') ||
            (((ref = model.get('emp_phone')), indexOf.call(codesByNumber, ref) >= 0) &&
                !this.get('countryCodeSetManually'))
        ) {
            for (i = 0, len = codes.length; i < len; i++) {
                code = codes[i];
                if (code.id === userCountry) {
                    number = code.number;
                    break;
                } else if (code.id === companyCountry) {
                    number = code.number;
                }
            }
            if (number) {
                return model.set('emp_phone', number);
            }
        }
    },
    changeCountry: function (countrycode) {
        this.set('countryCodeSetManually', true);
        return this.set('model.emp_phone', countrycode);
    },
    actions: {
        generalAddAction: function (type, param) {
            if (type === 'getEmp') {
                return this.getEmp();
            }
            if (type === 'changeCountry') {
                return this.changeCountry(param);
            }
            if (type === 'setCountryCode') {
                return this.setCountryCode();
            }
        },
        iHaveReadIt: function () {
            var model;
            model = this.get('model');
            model.set('date_checked', null);
            model.set('date_checked', moment().format('YYYY-MM-DD'));
            model.set('archive_code', this.get('archive_code'));
            return this.set('tilaajavastuu_report', null);
        },
        messageButtonAction: function () {
            this.set('showInviteDialog', true);
            return false;
        },
        removeModal: function () {
            return this.set('showInviteDialog', false);
        },
        sendInviteTilaajavastuuEmail: function () {
            var promise, title;
            this.get('notifications').success(this.get('intl').t('general.email_sent'), {
                autoClear: true,
            });
            title = this.get('intl').t('employer.invite_tilaajavastuu_title');
            promise = this.Collector.ttapi({
                url: 'sendEmail',
                data: {
                    email: this.get('model.emp_email'),
                    content:
                        this.get('tilaajavastuuText') +
                        '<br><br>' +
                        this.get('tilaajavastuuHiddenAddition'),
                    title: title,
                },
                method: 'post',
            });
            return promise.then((ret) => {
                $('.modal').modal('hide');
                return this.set('showInviteDialog', false);
            });
        },
    },
    getEmp: function (field) {
        var intl, model, self, value;
        this.set('showMessageButton', false);
        self = this;
        this.set('loading', true);
        model = this.get('model');
        value = this.get('model.emp_id');
        if (Ember.isEmpty(value)) {
            this.set('loading', false);
            return;
        }
        //        token = @get 'session.secure.access_token'
        // Fetching company status from Tilaajavastuu
        intl = this.get('intl');
        return $.ajax({
            url: this.get('cc').host + config.APP.CC_TVEMP,
            data: {
                tunnus: value,
            },
            type: 'GET',
            //            beforeSend: (xhr) ->
            //                xhr.setRequestHeader('Authorization', 'Bearer '+token)
            success: function (data) {
                var message, modelErrors;
                self.set('loading', false);
                self.set('tilaajavastuu_report', true);
                self.set('loadingTvReport', true);
                if (!data.error) {
                    data = data[0];
                }
                if (data != null && data.status && !data.error) {
                    self.set('showModal', true);
                    model.set('name', data.company);
                    model.set('emp_country', data.country.toLowerCase());
                    model.set('tv_status', data.status.toLowerCase());
                    modelErrors = self.get('errors');
                    if (modelErrors && indexOf.call(modelErrors, 'name') >= 0) {
                        modelErrors.removeObject('name');
                    }
                    if (modelErrors && indexOf.call(modelErrors, 'emp_country') >= 0) {
                        modelErrors.removeObject('emp_country');
                    }
                    if (data.status === 'Ok') {
                        self.set('messageStatus', 'alert-success');
                    } else if (data.status === 'Seis') {
                        self.set('messageStatus', 'alert-danger');
                    } else {
                        self.set('messageStatus', 'alert-warning');
                    }
                    message = Ember.String.htmlSafe(
                        '<div>' +
                            '<h3>' +
                            intl.t('employer.add.status') +
                            ': <b>' +
                            data.status +
                            '</b></h3>' +
                            '<h4>&nbsp;&nbsp;&nbsp;' +
                            data.description +
                            '</h4></div>',
                    );
                    self.set('archive_code', data.archive_code);
                    self.showTVReport(value);
                } else {
                    model.set('tv_status', 'ei_loydy');
                    model.set('archive_code', '');
                    self.set('messageStatus', 'alert-danger');
                    self.set('showMessageButton', true);
                    message = Ember.String.htmlSafe(
                        '<div>' +
                            '<h3><b>' +
                            intl.t('employer.add.status.notfound') +
                            '</b></h3>' +
                            intl.t('employer.add.status.marketing') +
                            '</div>',
                    );
                }
                return self.set('message', message);
            },
            fail: function (data) {
                var message;
                self.set('loading', false);
                model.set('tv_status', 'ei_loydy');
                model.set('archive_code', '');
                self.set('messageStatus', 'alert-danger');
                message = Ember.String.htmlSafe(
                    '<div>' + '<h3><b>' + intl.t('employer.add.status.failed') + '</b></h3></div>',
                );
                return self.set('message', message);
            },
        });
    },
    showTVReport: function (tunnus) {
        var self;
        //        token = @get 'session.secure.access_token'
        self = this;
        return $.ajax({
            url: this.get('cc').host + config.APP.CC_TVREP,
            data: {
                tunnus: tunnus,
            },
            type: 'GET',
            //            beforeSend: (xhr) ->
            //                xhr.setRequestHeader('Authorization', 'Bearer '+token)
            success: function (data) {
                self.set('tilaajavastuu_report', Ember.String.htmlSafe(data));
                return self.set('loadingTvReport', false);
            },
            fail: function (data) {},
        });
    },
});

export default EmployersAddController;
