import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

const DevelopmentFunctionsController = Controller.extend({
    Collector: service('collector-service'),
    ttapi: service(),
    store: service(),
    dialogs: service(),

    saving: false,

    content: alias('model'),

    actions: {
        save(func) {
            return this.ttapi.request('ccfunctions/functions', {
                method: 'post',
                data: {
                    function: func,
                },
            });
        },

        turnon() {
            const promise = this.Collector.addProduct('ttfunctions');

            return promise.then(() => window.location.reload());
        },
    },
});

export default DevelopmentFunctionsController;
