import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
    ttapi: service(),
    Collector: service('collector-service'),

    init() {
        this._super(...arguments);
        this.endpoint = this.ttapi.host + '/inactive_projects';
        this.types = this.getTypes();
    },

    content: alias('model'),

    getTypes() {
        let typesArr = [];
        if (this.Collector.formExists('worktime')) typesArr.push('worktime');
        if (this.Collector.formExists('presence')) typesArr.push('presence');

        return typesArr.join(',');
    },
});
