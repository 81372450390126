import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"map_div_size\">\n    <button type=\"button\" class=\"btn btn-m-link btn-block\" {{on \"click\" (fn (mut this.mapVisible))}}>{{t \"project.show_map\"}}</button>\n</div>\n\n<div class=\"{{if this.mapVisible 'show_gps_map' 'hide_gps_map'}}\">\n    <GpsLocationMap\n        @locationChanged={{this.onChange}}\n        @projectmode={{true}}\n    />\n    {{!-- {{gps-location-map value=value model=model projectmode=true}} --}}\n</div>\n", {"contents":"<div class=\"map_div_size\">\n    <button type=\"button\" class=\"btn btn-m-link btn-block\" {{on \"click\" (fn (mut this.mapVisible))}}>{{t \"project.show_map\"}}</button>\n</div>\n\n<div class=\"{{if this.mapVisible 'show_gps_map' 'hide_gps_map'}}\">\n    <GpsLocationMap\n        @locationChanged={{this.onChange}}\n        @projectmode={{true}}\n    />\n    {{!-- {{gps-location-map value=value model=model projectmode=true}} --}}\n</div>\n","moduleName":"tt4/components/addon/fields/gpslocationmap.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/gpslocationmap.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface IGpsLocationMapField {
    onChange(arg0: string): void;
}

export default class FieldsGpslocationmapComponent extends Component<IGpsLocationMapField> {
    @action protected onChange(location: JSON): void {
        this.args.onChange(JSON.stringify(location));
    }
}
