/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

import collector from '../../../classes/collector';

UsersAddRoute = GeneralAddRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    modelName: 'user',
    controllerName: 'users.add',
    beforeModel: function (transition) {
        var c, records;
        c = this.get('Collector');
        records = this.store.peekAll(this.get('modelName')).filterBy('isNew', true);
        if (collector.getFormObject('user.taxnumber') && records.get('length') < 1) {
            return this.transitionTo('users.add.builder');
        } else if (c.testNeeds(['user.id06kort']) && records.get('length') < 1) {
            return this.transitionTo('users.add.id06');
        }
    },
});

export default UsersAddRoute;
