import { alias } from '@ember/object/computed';
import GeneralAddController from 'tt4/controllers/general-add';
import { typeOf } from '@ember/utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { set } from '@ember/object';
import config from 'tt4/config/environment';

export default class worktimegroupChangeController extends GeneralAddController {
    @service store;
    @service dialogs;
    @service evented;
    @service cdn;
    @service intl;
    @service('collector-service') Collector;

    form = 'worktimegroupchange';
    @tracked status = 'all';
    @tracked dateSelector = true;
    @tracked groupBy = null;
    @tracked record;
    @tracked worktypeMappings = {};
    @tracked saving = false;
    @tracked alertShown = false;
    @tracked notification_user = null;

    @alias('args.model') model;

    @action
    filterChangeAction(value, field) {
        if (typeOf(value) === 'object' || typeOf(value) == 'instance') {
            this[field] = value.id;
        } else {
            this[field] = value;
        }
    }

    @action
    closeModal() {
        this.record.isNew ? this.record.deleteRecord() : this.record.rollbackAttributes();
        this.record = null;
    }

    @action
    addRecord() {
        Notification.requestPermission();
        let defaults = {};
        this.record = this.store.createRecord('worktimegroupchange', defaults);
    }

    @action
    removeRow(row) {
        // if row.startdate is in the past, then we cannot delete it
        if (row.startdate < moment().format('YYYY-MM-DD')) {
            this.dialogs.alert(this.intl.t('report.worktimegroupchanges.cannot_delete'));
        } else {
            this.dialogs
                .confirm(this.intl.t('report.confirm_delete'))
                .then(this.removeOrNot.bind(this, row));
        }
    }

    async removeOrNot(row, answer) {
        if (!answer) return;
        await row.destroyRecord();
        this.evented.storeEvent('delete', 'worktimegroupchange', null);
    }

    @action
    setDestinationWorktype(source, destination) {
        set(this.worktypeMappings, source.id, destination);
    }

    showSlowAlert() {
        this.clearStoredValues();
        this.alertShown = true;
        this.dialogs.alert(this.intl.t('report.worktimegroupchanges.saving-is-slow'));
    }

    showNotification() {
        new Notification(this.intl.t('report.worktimegroupchanges.notification-header'), {
            body: this.intl.t('report.worktimegroupchanges.notification-body', {
                user: this.notification_user.firstname + ' ' + this.notification_user.lastname,
            }),
            icon: this.Collector.testNeedsOne('products.timetracker')
                ? config.APP.CDN_URL + '/images/icons/movenium_filled.png'
                : config.APP.CDN_URL + '/images/icons/entry_filled.png',
        });
    }

    clearStoredValues() {
        this.record = null;
        this.alertShown = false;
        this.saving = false;
        this.evented.storeEvent('update', 'worktimegroupchange', null);
    }

    @action
    async saveMappingsAndForm() {
        let alertTimeout;
        this.saving = true;
        const transformedArray = Object.entries(this.worktypeMappings).map(([source, value]) => ({
            source,
            destination: value.id,
        }));
        this.record.mappings = JSON.stringify(transformedArray);
        try {
            // if request has taken too long, show alert that the changes are being saved and close the modal
            alertTimeout = setTimeout(() => this.showSlowAlert(), 9000);
            // store user for notification (cannot use this.record.user because we are clearing the record before the notification is shown)
            this.notification_user = this.record.user;
            await this.record.save();
            clearTimeout(alertTimeout);
            // if the alert has been shown, show notification
            if (this.alertShown) {
                this.showNotification();
            }
            this.clearStoredValues();
        } catch (e) {
            clearTimeout(alertTimeout);
            if (e.errors && e.errors[0].status === 504) {
                this.dialogs.alert(this.intl.t('report.worktimegroupchanges.timeout'));
            }
            this.clearStoredValues();
        }
    }
}
