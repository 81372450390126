import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';

export default Component.extend({
    ttapi2: service(),

    logrows: computed('rowid', function () {
        return get(this, 'ttapi2').request('webhooklog?rowid=' + get(this, 'rowid'));
    }),
});
