import Helper from '@ember/component/helper';

export default Helper.extend({
    compute(params, hash) {
        if (!params[0]) return null;
        if (hash.sortBy)
            params[0].sort((a, b) => ('' + a[hash.sortBy]).localeCompare(b[hash.sortBy]));
        return params[0].sort();
    },
});
