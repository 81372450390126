/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/company-info.coffee.old
// Generated by CoffeeScript 2.6.1
var CompanyInfo;

import DS from 'ember-data';

CompanyInfo = DS.Model.extend({
    company_name: DS.attr('string'),
    company_vatin: DS.attr('string'),
    company_contact_person: DS.attr('string'),
    company_phone: DS.attr('string'),
    company_email: DS.attr('string'),
    country: DS.attr('string'),
    row_info: DS.attr(),
});

export default CompanyInfo;
