import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

export default Route.extend({
    ttapi: service(),

    queryParams: {
        status: {
            refreshModel: true,
        },
    },

    async model(queryparams) {
        let params = {};

        params.project = get(this.modelFor('projects.edit'), 'id');
        params.limit = 'all';
        params.cols = 'employer';

        params.sideload = [
            'employer.name',
            'employer.tv_status',
            'employer.date_checked',
            'employer.tv_pdf',
        ];

        return {
            users: await get(this, 'ttapi').request('api/project/users', { data: params }),
            status: queryparams.status,
        };
    },

    resetController(controller, isExiting) {
        if (isExiting) {
            // Reset the status query parameter to its default value
            set(controller, 'status', null);
        }
    },
});
