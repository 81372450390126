/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport-contract/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportIndexController;

import Ember from 'ember';

TaxmanTaxreportIndexController = Ember.Controller.extend({
    indexController: Ember.inject.controller('taxman.taxreport-contract.index'),
    key: Ember.computed.alias('indexController.key'),
});

export default TaxmanTaxreportIndexController;
