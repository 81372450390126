import { get } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
    /*testQueue: {
        files: [
            {name: "testlongsafaslfsdsdf sdfsdf.txt", progress: 20},
            {name: "test2.txt", progress: 70}
        ]
    },*/

    actions: {
        abort(queue) {
            get(queue, 'files').forEach((file) => {
                file.task.cancel();
            });

            // if there are failed files in queue .. remove those now
            get(queue, 'queues').forEach((queueObj) => {
                get(queueObj, 'files').forEach((file) => {
                    if (get(file, 'state') === 'failed') queueObj.remove(file);
                });
            });
        },
    },
});
