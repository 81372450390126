import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

export default Component.extend({
    loadTranslations: service(),
    intl: service(),

    loading: true,

    terms: computed(function () {
        const terms = get(this, 'intl').t('terms.project');
        const changesArr = terms.split(/\n/g);
        const termList = changesArr[0].split(/,/g);

        return termList.map((item, key) => {
            return { id: '' + key, text: item };
        });
    }),

    didReceiveAttrs() {
        set(this, 'selectedTerm', get(this, 'loadTranslations').getTermsIndex());
        set(this, 'loading', false);
    },

    actions: {
        termsChanged(index) {
            get(this, 'loadTranslations').saveTermsIndex(index);
            get(this, 'loadTranslations').fetch();

            set(this, 'selectedTerm', index);

            get(this, 'loadTranslations').saveTermsIndex(index);
            this.saveCalled();
        },
    },
});
