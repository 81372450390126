/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/draggable_dropzone.coffee.old
// Generated by CoffeeScript 2.6.1
var DraggableDropzoneComponent;

import Ember from 'ember';

DraggableDropzoneComponent = Ember.Component.extend({
    classNames: ['draggable-dropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',
    attributeBindings: ['data-field'],
    dragLeave: function (event) {
        event.preventDefault();
        return this.set('dragClass', 'deactivated');
    },
    dragOver: function (event) {
        event.stopPropagation();
        this.set('dragClass', 'activated');
        return false;
    },
    dragEnter: function (event) {
        event.stopPropagation();
        return false;
    },
    drop: function (event) {
        var data, msie, setDataString, trident, userAgent;
        this.set('dragClass', 'deactivated');
        userAgent = window.navigator.userAgent;
        msie = userAgent.indexOf('MSIE ');
        trident = userAgent.indexOf('Trident/');
        if (msie > 0 || trident > 0) {
            setDataString = 'text';
        } else {
            setDataString = 'text/data';
        }
        //console.log setDataString
        data = event.dataTransfer.getData(setDataString);
        //console.log "dropped", data
        if (this.get('dropped')) {
            return this.dropped(data, event);
        }
    },
});

export default DraggableDropzoneComponent;
