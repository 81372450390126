import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';

export default Component.extend({
    ajax: service(),
    loading: false,

    async didReceiveAttrs() {
        if (this.settingsPage) {
            set(this, 'loading', true);
            let data = await this.getFileData();
            set(this, 'documentData', data);
            set(this, 'loading', false);
        }
    },
    actions: {
        toggleHelp() {
            this.toggleHelpContainerVisibility('hideHelpContainer');
        },
    },
    async getFileData() {
        let settings = this.neededHelpTexts;
        let key = '';
        let fileUrl = '';
        let helpTexts = [];
        for (var setting of settings) {
            //key = setting.replace(".", "-") + "-" + moment.locale()
            var splittedTextArr = setting.split('.');
            const lastKeyOfArray = splittedTextArr[splittedTextArr.length - 1];
            key = 'split-settings-' + lastKeyOfArray.replace(/\./g, '-') + '-' + moment.locale();
            // Show custom help text for new overtime settings
            key =
                lastKeyOfArray === 'overtime' &&
                this.Collector.testNeeds(['products.salarytype_v2||flag.new-overtime-settings'])
                    ? 'split-settings-overtime-new-' + moment.locale()
                    : key;
            fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;
            try {
                helpTexts.pushObject({
                    help: htmlSafe(await this.ajax.request(fileUrl, { dataType: 'text' })),
                    name: setting?.replace(/\./g, '-'),
                    key: key,
                });
            } catch (e) {
                if (this.Collector.testNeeds(['development'])) {
                    helpTexts.pushObject({
                        help: htmlSafe('<br><br>'),
                        name: setting?.replace(/\./g, '-'),
                        key: `cannot find help for ${key}`,
                    });
                }
            }
        }
        return helpTexts;
    },
});
