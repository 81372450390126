/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/radio-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsRadioFieldComponent;

import Ember from 'ember';

import base from '../base-field/component';

CollectorFieldsRadioFieldComponent = base.extend({
    // Methods

    setValue: Em.on('init', function () {
        return this.set('value', this.get('field.value'));
    }),
    didRender: function () {
        if (this.element.offsetWidth < 250) {
            return this.element.children[0].classList.add('is-narrow');
        }
    },
    actions: {
        change: function (value) {
            this.set('value', value);
            return this.sendAction('bubbleChange', value, this.get('field.name'));
        },
    },
});

export default CollectorFieldsRadioFieldComponent;
