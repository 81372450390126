import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

interface UserlevelHelpArgs {}

export default class UserlevelHelp extends Component<UserlevelHelpArgs> {
    get styleNamespace() {
        return podNames['field-info-bubble'];
    }
}
