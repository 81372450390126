/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/dialogs.coffee.old
// Generated by CoffeeScript 2.6.1
var DialogsService;

import Ember from 'ember';

import { alertify } from '@labeg/alertify.js';

DialogsService = Ember.Service.extend({
    intl: Ember.inject.service(),
    confirm: function (message) {
        if ('function' !== typeof Promise) {
            return new Ember.RSVP.Promise((resolve, reject) => {
                var val;
                val = confirm(message);
                return resolve(val);
            });
        } else {
            return alertify
                .okBtn(this.get('intl').t('general.yes'))
                .cancelBtn(this.get('intl').t('general.no'))
                .confirm(message)
                .then(function (resolvedValue) {
                    resolvedValue.event.preventDefault();
                    if (resolvedValue.buttonClicked === 'ok') {
                        return true;
                    } else {
                        return false;
                    }
                });
        }
    },
    alert: function (message) {
        return alertify.okBtn('OK').alert(message);
    },
    prompt: function (message, def) {
        return new Em.RSVP.Promise((resolve, reject) => {
            return alertify
                .defaultValue(def)
                .okBtn(this.get('intl').t('general.save'))
                .cancelBtn(this.get('intl').t('general.cancel'))
                .prompt(
                    message,
                    (str, e) => {
                        e.preventDefault();
                        return resolve(str);
                    },
                    (e) => {
                        e.preventDefault();
                        return resolve(false);
                    },
                );
        });
    },
});

export default DialogsService;
