/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/route.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

DiaryRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    beforeModel: function (transition) {
        if (transition && transition.targetName === 'diary.index') {
            this.transitionToRightPage();
        }
        return this._super(transition);
    },
    transitionToRightPage: function () {
        if (!this.get('Collector').testNeeds(['diary@access'])) {
            return this.transitionTo('diary.events');
        }
    },
});

export default DiaryRoute;
