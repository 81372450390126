/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryAddController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

WorksitediaryAddController = GeneralAddController.extend({
    weather: Ember.inject.service(),
    GpsLocator: Ember.inject.service(),
    /*
  GetGPSCoords: ->
      self = @
      tmp = Ember.A []
      @get('GpsLocator').getLocation().then (position) ->
          $.getJSON config.APP.WEATHER_API+"?lat="+position.coords.latitude+"&lon="+position.coords.longitude, (json) ->
              dew_point = json.temperature-((100-json.humidity)/5)
              json.dew_point = Math.round(dew_point * 100) / 100
              self.set 'model.weather', json
      , (error) ->
          self.set 'currentPos', error

  */
    editNewLink: 'worksitediary.edit',
    customization: Em.computed(function () {
        return {
            rating: {
                template: 'worksitediary/add/rating',
            },
            weather: {
                template: 'worksitediary/add/weather',
            },
        };
    }),
    actions: {
        generalAddAction: function (type, field = null) {
            switch (type) {
                case 'giveThumbUp':
                    return this.giveThumb('up');
                case 'giveThumbDown':
                    return this.giveThumb('down');
            }
        },
    },
    //if field == 'project'
    //    @get('weather').fetchWeatherPosition(type).then (weather) =>
    //        @set 'model.weather', weather
    //    , (reason) ->
    //        @set 'model.weather', reason
    giveThumb: function (value) {
        var model;
        model = this.get('model');
        return model.set('rating', value);
    },
});

export default WorksitediaryAddController;
