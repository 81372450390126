import moment from 'moment';
import { get } from '@ember/object';

export function perDate(rows) {
    let perDate = {};
    rows.forEach((item) => {
        const date = get(item, 'date');
        if (!perDate[date]) {
            perDate[date] = [];
        }
        if (get(item, 'enddate')) {
            let i = 1;
            const moment_date = moment(date);
            const diff = moment(get(item, 'enddate')).diff(moment_date, 'day');
            perDate[date].push(item);
            while (i <= diff) {
                const new_date = moment_date.add(1, 'day').format('YYYY-MM-DD');
                if (!perDate[new_date]) {
                    perDate[new_date] = [];
                }
                perDate[new_date].push(item);
                i++;
            }
        } else {
            perDate[date].push(item);
        }
    });
    return perDate;
}
