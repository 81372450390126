import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

export default Component.extend({
    store: service(),
    selected_users: A(),

    linkIds: computed.mapBy('selectedUsers', 'id'),

    lockPermissionField: computed('selectedProject', function () {
        return {
            form: 'lockpermission',
            name: 'lockgroupid',
            type: 'database',
            filterOptions: {
                project: this.selectedProject.id,
            },
        };
    }),

    unselectedUsers: computed('selectedEmployer', 'search', 'preSelectedUsers', async function () {
        let params = {};
        params['rfid_tag_id'] = '!(empty)';
        if (this.search) {
            params['q'] = this.search;
        }
        if (this.selectedEmployer) {
            params['employer'] = this.selectedEmployer.id;
        }
        if (this.preSelectedUsers) {
            params['id'] = this.preSelectedUsers.join(',');
        }
        let model = await get(this, 'store').query('user', params);
        return model;
    }),

    init() {
        this._super();
    },

    async didInsertElement() {
        set(this, 'showOnlyValid', false);
        set(this, 'selectedUsers', A());
    },
    actions: {
        searchChanged(value) {
            if (typeof value == 'string') {
                set(this, 'search', value);
            } else {
                set(this, 'search', null);
            }
        },
        async selectUser(user) {
            this.selectedUsers.pushObject(user);
        },
        deselectUser(user) {
            this.selectedUsers.removeObject(user);
        },
        changeProject(value) {
            set(this, 'selectedProject', value);
        },
        PermissionsAdderAction(lockgroup, project) {
            if (lockgroup) {
                set(this, 'lockgroupid', lockgroup);
            }
            if (project) {
                set(this, 'selectedProject', project.id);
            }
            set(this, 'showAddPermissionDialog', true);
        },
        changeLockgroup(value) {
            set(this, 'lockgroupid', value.id);
        },
        updateDateFields(value, field) {
            set(this, field, value);
            if (this.pass_start && this.pass_end) {
                set(this, 'valid', true);
            } else {
                set(this, 'valid', false);
            }
        },
    },
});
