import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
const DevelopmentOwnreportsController = Controller.extend({
    Collector: service('collector-service'),
    content: alias('model'),

    actions: {
        saveReport(report) {
            return this.Collector.ttapi({
                url: report.id ? 'defaultReports/' + report.id : 'defaultReports',
                method: report.id ? 'PUT' : 'POST',
                data: report,
                success(data) {
                    return (report.id = data.id);
                },
            });
        },
        removeReport(report) {
            return this.Collector.ttapi({
                url: 'defaultReports/' + report.id,
                method: 'DELETE',
            });
        },

        addNewReport() {
            return this.model.pushObject({ name: '', settings: { key: '', type: 'cc' } });
        },
    },
});

export default DevelopmentOwnreportsController;
