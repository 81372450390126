import Controller, { inject as controller } from '@ember/controller';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    saved: controller(),

    init() {
        this._super();
        this.settings = {
            form: 'abcense',
            skip: ['starttime', 'endtime', 'status', 'addon_frnvaro_timmar'],
            mandatory: ['startdate', 'enddate', 'dayamount'],
        };
    },

    modelAlias: alias('model'),

    actions: {
        recordSaved(settings, record) {
            set(this, 'saved.model', record);
            set(this, 'saved.hideAddLinks', true);
            return this.transitionToRoute('saved');
        },
        cancel() {
            return this.transitionToRoute('abcenses.index');
        },
    },
});
