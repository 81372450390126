import Controller from '@ember/controller';

// eslint-disable-next-line ember/alias-model-in-controller
export default Controller.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    columns: [
        { name: 'Name', valuePath: 'name', width: 200 },
        { name: 'Description', valuePath: 'description', width: 500 },
        { name: 'Key', valuePath: 'key', width: 300 },
    ],
});
