import GeneralReportRoute from '../../../routes/general-report';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

const MateriallogReportRoute = GeneralReportRoute.extend({
    flags: service(),
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        task: {
            refreshModel: true,
        },
        type: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },

    form: 'materiallog',
    model(params) {
        if (
            !this.flags.test('form-report-materiallogs') &&
            !this.flags.test('ember-movenium-form-report-materiallogs')
        ) {
            params = this._super(params);
            params.sideload = true;
            return this.store.query('materiallog', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return set(this.controllerFor('materiallog.report'), 'qParams', params);
        }
    },
});

export default MateriallogReportRoute;
