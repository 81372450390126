/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/automation/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryAutomationRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

WorksitediaryAutomationRoute = GeneralReportRoute.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    queryParams: {
        page: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        params.sideload = true;
        params.autodiary = 'on';
        return this.store.query('project', params);
    },
    actions: {
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default WorksitediaryAutomationRoute;
