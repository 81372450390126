import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import initRoute from 'tt4/mixins/init-route';
import { isEmpty, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';

const WorktaskCalendarRoute = Route.extend(AuthenticatedRouteMixin, initRoute, {
    tools: service(),

    Collector: service('collector-service'),
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        customer: {
            refreshModel: true,
        },
        more: {
            refreshModel: true,
        },
    },

    form: 'worktask',
    more: null,

    model(params) {
        //params = @._super(params)
        params.sideload = [
            'project.name',
            'project.id',
            'user.firstname',
            'user.lastname',
            'user.image',
        ];
        const split_date = params.date.split('_');
        params.date =
            moment(split_date[0]).subtract(2, 'weeks').format('YYYY-MM-DD') + '_' + split_date[1];
        delete params.page;
        // check if more-param is present, parse it correctly and finally remove it from params
        if (params.more != null) {
            params.more.split('|').forEach(function (param) {
                const splitted = param.split(':');
                if (splitted[0].includes('-')) {
                    if (isPresent(splitted[1])) {
                        const split = splitted[0].split('-');
                        if (isEmpty(params[split[0]])) {
                            return (params[split[0]] = { [split[1]]: splitted[1] });
                        }
                    }
                } else {
                    return (params[splitted[0]] = splitted[1]);
                }
            });
            set(this, 'more', params.more);
        } else {
            set(this, 'more', null);
        }

        if (params != null && params['more']) {
            delete params['more'];
        }

        params.limit = "all";

        this.store.query('worktask', params);
        if (this.Collector.testNeedsOne('abcense')) {
            const absenceParams = Object.assign({}, params);
            if (absenceParams.order != null && absenceParams.order.indexOf('date') > -1) {
                absenceParams.order = absenceParams.order.replace('date', 'startdate');
            }
            delete absenceParams.project;
            delete absenceParams.customer;
            for (let param in absenceParams) {
                if (!this.Collector.fieldExists('abcense', param)) {
                    delete absenceParams[param];
                }
            }
            absenceParams.sideload = [
                'user.firstname',
                'user.lastname',
                'user.image',
                'abcensetype.name',
            ];
            delete absenceParams.date;
            absenceParams.startdate =
                moment(split_date[0]).subtract(36, 'days').format('YYYY-MM-DD') +
                '_' +
                split_date[1];
            this.store.query('abcense', absenceParams);
        }

        return this.store.peekAll('worktask');
    },

    setupController(controller, model) {
        this._super(controller, model);

        if (get(this, 'more')) {
            return get(this, 'more')
                .split('|')
                .forEach(function (param) {
                    const splitted = param.split(':');
                    return set(controller, splitted[0], splitted[1]);
                });
        }
    },
});

export default WorktaskCalendarRoute;
