/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/locationtree/route.coffee.old
// Generated by CoffeeScript 2.6.1
var Route;

import Ember from 'ember';

import config from '../../../config/environment';

Route = Ember.Route.extend({
    backendurl: Ember.computed(function () {
        return Ember.get(config, 'APP.BEACONBACKENDURL');
    }),
    model: function (params) {
        return $.ajax({
            url: this.get('backendurl') + '/rigado/tree',
        });
    },
});

export default Route;
