/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import GeneralReportController from '../../../controllers/general-report';
import moment from 'moment';
import EmberObject, { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';

const MachinesPricesController = GeneralReportController.extend({
    intl: service(),
    collector: service('collector-service'),
    excel: service(),
    queryParams: ['project', 'tooltype', 'starttime', 'status', 'more'],
    form: 'transaction',
    selectedProject: null,
    tooltype: null,
    project: null,
    content: alias('model'),
    filters: computed(function () {
        return A([
            EmberObject.create(
                { field: 'starttime', type: 'date' },
                {
                    start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD'),
                },
            ),
            EmberObject.create({ field: 'project' }),
            EmberObject.create({ field: 'tooltype' }),
            EmberObject.create({ field: 'status' }),
        ]);
    }),
    formattedData: computed('model', function () {
        const c = get(this, 'collector');
        const model = this.model;
        for (let project in model) {
            const data = model[project];
            if (isPresent(data.rows)) {
                data.rows.forEach((item) => {
                    return (item.status = c.getDropdownValue(
                        null,
                        item.status,
                        c.field('transaction', 'status'),
                    ));
                });
            }
        }
        return model;
    }),

    rowColumns: computed(function () {
        const columns = ['tooltype', 'timespan', 'status', 'price', 'days', 'amount', 'sum'];
        get(this, 'ownFields').forEach((field) => {
            return columns.push(field.name);
        });
        return columns;
    }),

    ownFields: computed(function () {
        const formFields = [
            'tooltype',
            'timespan',
            'status',
            'price',
            'days',
            'amount',
            'sum',
            'starttime',
            'endtime',
            'project',
        ];
        const ownFields = [];
        const c = get(this, 'Collector');
        c.fieldArray(get(this, 'form')).filter((field) => {
            if (!formFields.includes(field.name)) {
                return ownFields.push(field);
            }
        });
        return ownFields;
    }),

    translatedColumns: computed(function () {
        const intl = get(this, 'intl');
        const rowColumns = get(this, 'rowColumns');
        const ownFields = get(this, 'ownFields');
        const translatedColumns = [];
        rowColumns.forEach((item) => {
            let translation = intl.t('report.transactionprices.' + item);
            if (ownFields) {
                ownFields.forEach((field) => {
                    if (field.name === item) {
                        return (translation = field.translated_name);
                    }
                });
            }
            return translatedColumns.push(translation);
        });
        return translatedColumns;
    }),

    actions: {
        filterChanged(num, form) {
            if (form === 'project') {
                set(this, 'selectedProject', num);
            }
            return this._super(num, form);
        },
        export_print() {
            return print();
        },
        export_excel() {
            const cols = this.translatedColumns.map((i) => i);
            const rowColumns = this.rowColumns;
            const excel = this.excel;
            const intl = this.intl;
            const exportData = [];
            const object = this.model;
            for (let project in object) {
                const data = object[project];
                exportData.push([project]);
                exportData.push([]);
                exportData.push(cols);
                data.rows.forEach((item) => {
                    const ret = [];
                    rowColumns.forEach((col) => {
                        return ret.push(item[col]);
                    });
                    return exportData.push(ret);
                });
                exportData.push([]);
            }

            return excel.export(exportData, {
                sheetName: intl.t('machines.prices'),
                fileName: intl.t('machines.prices') + '.xlsx',
            });
        },
    },
    starttime:
        moment().subtract(1, 'month').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
});

export default MachinesPricesController;
