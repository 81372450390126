/* eslint-disable ember/no-observers */
import { alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, observer, get, set } from '@ember/object';

const PassAddController = Controller.extend({
    saved: controller(),

    Collector: service('collector-service'),
    intl: service(),

    chooseUser: true,
    no_orientation: false,
    showNext: false,
    no_orientation_error: false,

    content: alias('model'),

    oriUserField: computed(function () {
        return this.Collector.formField('orientation', 'ori_user');
    }),
    oriProjectField: computed(function () {
        return this.Collector.formField('orientation', 'ori_site');
    }),

    overlap: observer('user', 'ori_site', function () {
        if (this.user && this.ori_site) {
            return this.store
                .query('orientation', { ori_site: this.ori_site.id, ori_user: this.user.id })
                .then(this.handleData.bind(this));
        }
    }),

    actions: {
        continue() {
            return set(this, 'chooseUser', false);
        },

        async save() {
            const row = await this.content.save();

            set(this, 'no_orientation', false);
            set(this, 'content', null);
            set(this, 'showNext', false);
            set(this, 'chooseUser', true);
            set(this, 'user', null);
            set(this, 'saved.model', row);
            set(this, 'saved.afterSave', 'orientations.passes');

            return this.transitionToRoute('saved');
        },
    },

    async handleData(orientation) {
        if (orientation.length === 0) {
            set(this, 'no_orientation_error', this.intl.t('orientations.no_orientation_error'));
            return set(this, 'showNext', false);
        } else {
            set(this, 'content', orientation.firstObject);
            set(this, 'no_orientation_error', false);
            set(this, 'showNext', true);
            const oriProjId = orientation.firstObject.ori_site.id;
            if (this.Collector.testNeeds(['employer', 'urakkaraportointi'])) {
                const contracts = await this.store.query('urakkaraportointi', {
                    contract_employer: get(this, 'user.employer.id'),
                });
                const contractEndDateFound = contracts
                    .sortBy('contract_month')
                    .reverseObjects()
                    .any((contract) => {
                        const contrProjId = get(contract, 'contract_project.id');
                        if (contrProjId === oriProjId) {
                            set(this, 'content.pass_start', moment().format('YYYY-MM-DD'));
                            set(this, 'content.pass_end', get(contract, 'contract_end_date'));
                        }
                        return contrProjId === oriProjId;
                    });
                if (!contractEndDateFound) {
                    set(this, 'content.pass_start', moment().format('YYYY-MM-DD'));
                    return set(
                        this,
                        'content.pass_end',
                        moment().add(1, 'months').format('YYYY-MM-DD'),
                    );
                }
            } else {
                set(this, 'content.pass_start', moment().format('YYYY-MM-DD'));
                return set(
                    this,
                    'content.pass_end',
                    moment().add(1, 'months').format('YYYY-MM-DD'),
                );
            }
        }
    },
});
export default PassAddController;
