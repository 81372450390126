/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/inline-edit-row/cell/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import $ from 'jquery';

Component = Ember.Component.extend({
    collector: Ember.inject.service('collector-service'),
    tagName: 'td',
    classNameBindings: ['editable:editable-field:uneditable-field'],
    active: false,
    editing: Ember.computed.and('editable', 'active'),
    _class: Ember.computed('field.type', function () {
        if (Ember.get(this, 'field.type') === 'database' && Ember.get(this, 'usePowerDropdown')) {
            return '';
        }
        if (Ember.get(this, 'field.type') === 'database') {
            return 'form-control';
        } else {
            return 'form-control add-fields';
        }
    }),
    findInput: function () {
        var element, obj;
        element = Ember.get(this, 'element');
        obj = $(element).find('input, select').first();
        return obj;
    },
    click: function (e) {
        Ember.set(this, 'active', true);
        return Ember.run.next(this, function () {
            var input;
            if (this.get('field.value')) {
                const modelValue = this.get('model.' + this.get('field.external_type'));
                if (
                    (modelValue?.id && modelValue.id !== this.get('field.value')) ||
                    modelValue !== this.get('field.value')
                ) {
                    // we set value also to model to make sure that this row will be saved to backend
                    // because there is a feature (or a bug) that copies values from other rows and terrawise is using it and are very angry if we take this feature (bug) away
                    this.set('model.' + this.get('field.external_type'), this.get('field.value'));
                }
            }
            input = $(e);
            if (input) {
                return input.focus();
            }
        });
    },
    actions: {
        setValue: function () {
            return this.get('model').send('becomeDirty');
        },
    },
});

//            Ember.set(@, 'active', false)
Component.reopenClass({
    positionalParams: ['model', 'field', 'editable', 'error'],
});

export default Component;
