/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/contract-list/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanContractListAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

TaxmanContractListAddRoute = GeneralAddRoute.extend({
    modelName: 'urakkaraportointi',
    controllerName: 'taxman/contract-list/add',
    //    model: ->
    //        self = @
    //        params = {}
    //        params.sideload = true
    //        newest = @.store.query('urakkaraportointi', params)
    //        newest.then ->
    //            return newest.get("firstObject")
    actions: {
        willTransition: function (transition) {
            this.controller.set('selectedProject', null);
            this.controller.set('contracts', Em.A([]));
            return this.controller.toggleProperty('launchProjectUpdate');
        },
    },
});

export default TaxmanContractListAddRoute;
