/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/addsaved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerAddSavedRoute;

import Ember from 'ember';

import SavedRoute from '../../../routes/saved';

TimetrackerAddSavedRoute = SavedRoute.extend({
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.notifyPropertyChange('currentSaldo');
    },
});

export default TimetrackerAddSavedRoute;
