import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    session: service(),
    collector: service('collector-service'),
    store: service(),
    evented: service(),

    init() {
        this._super();
        this.siteCards = [null, null, null];
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumeEvent');
    },

    async didReceiveAttrs() {
        this.mysites = await this.getMySites();
        if (this.mysites) {
            this.addMysitesToCards();
        }
    },

    didInsertElement() {
        set(this, 'canUse', this.canWeUseThisWidget());
        set(this, 'promise', this.getOpenWorktime());
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumeEvent');
    },

    actions: {
        notifySiteCards() {
            this.addMysitesToCards();
        },
        setRemovableIndexNull(project) {
            this.siteCards.splice(this.siteCards.indexOf(project), 1, null);
        },
    },

    onStoreEvent(params) {
        if (params.type === 'worktime') {
            set(this, 'promise', this.getOpenWorktime());
        }
    },

    onResumeEvent(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'promise', this.getOpenWorktime());
    },

    async getMySites() {
        return await this.store.findAll('my-site');
    },

    addMysitesToCards() {
        let mySitesArr = this.mysites.toArray();
        for (let i = 0; i < this.mysites.length; i++) {
            set(this.siteCards, i, mySitesArr[i]);
        }
        this.notifyPropertyChange('siteCards');
    },

    async getOpenWorktime() {
        let worktimes = await this.store.query('worktime', {
            date:
                moment().subtract(1, 'day').format('YYYY-MM-DD') +
                '_' +
                moment().format('YYYY-MM-DD'),
            user: this.session.currentUser.id,
            sideload: true,
        });
        let openWorktimes = await worktimes.filter((worktime) => isEmpty(worktime.endtime));
        set(this, 'openWorktime', !isEmpty(openWorktimes) ? openWorktimes.firstObject : null);
    },

    canWeUseThisWidget() {
        return !this.collector.fieldArray('worktime').some((item) => {
            return item.features.mandatory && !['project', 'user'].includes(item.name);
        });
    },
});
