import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    collector: service('collector-service'),
    store: service(),

    hooks: computed(function () {
        return get(this, 'store')
            .peekAll('form-setting')
            .filter((item) => {
                const correct_form = get(item, 'form.external_type') === get(this, 'form');
                const is_webhook = get(item, 'name') === 'webhook';
                return correct_form && is_webhook;
            });
    }),

    formRecord: computed('form', function () {
        return get(this, 'store')
            .peekAll('form')
            .find((item) => get(item, 'external_type') === get(this, 'form'));
    }),

    init() {
        this._super();
        set(this, 'texts', {
            webhooks: 'Webhooks',
            add: 'Add',
        });
    },

    actions: {
        async add(hook) {
            await get(this, 'store')
                .createRecord('form-setting', {
                    form: get(this, 'formRecord'),
                    name: 'webhook',
                    value: hook,
                })
                .save();
            this.notifyPropertyChange('hooks');
            set(this, 'showAdd', false);
        },

        edit(hook) {
            set(this, 'edit', hook);
        },

        async save(hook, json) {
            set(hook, 'value', json);
            await hook.save();
            this.notifyPropertyChange('hooks');
            set(this, 'edit', false);
        },

        async delete(hook) {
            await hook.destroyRecord();
            this.notifyPropertyChange('hooks');
            set(this, 'edit', false);
        },
    },
});
