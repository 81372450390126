/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from '@ember/object';

export default Route.extend({
    store: service(),
    flags: service(),

    queryParams: {
        page: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        selectedYears: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        worktimegroup: {
            refreshModel: true,
        },
        superior: {
            refreshModel: true,
        },
    },

    async model(params) {
        const rowsPerPage = 25;
        const offset = params.page != null ? (params.page - 1) * rowsPerPage : 0;
        delete params.page;
        params.limit = 25;
        params.offset = offset;

        params.country = get(this.store.peekAll('companyInfo'), 'firstObject.country');

        const days = params.country === 'se' ? '-04-01' : '-05-02';

        if (typeof params.selectedYears == 'string')
            params.selectedYears = JSON.parse(params.selectedYears);
        params.years = params.selectedYears.reduce((string, year, index) => {
            return (string += index == 0 ? year + days : ',' + year + days);
        }, '');

        delete params.selectedYears;

        if (!params.employer) {
            delete params.employer;
        }

        if (params.user) {
            params.id = params.user;
        }
        delete params.user;
        const url = 'api/getMultipleSeasons';
        return {
            params: params,
            data: await this.Collector.ttapi({
                url: url,
                data: params,
            }),
        };
    },

    actions: {
        refreshModel() {
            return this.refresh();
        },
    },
});
