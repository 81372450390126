/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/register/service/adddetails.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterAdddetailsRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../general-add';

RegisterAdddetailsRoute = GeneralAddRoute.extend({
    controllerName: 'register/service/adddetails',
    session: Em.inject.service(),
    beforeModel: function () {
        // overriding beforeModel so authenticatedMixin doesn't move as to /login
        if (this.get('session.isAuthenticated')) {
            this.get('session').invalidate();
        }
    },
    objects: {
        firstname: {
            translation: 'register.firstname',
            type: 'textbox',
            features: {},
        },
        lastname: {
            translation: 'register.lastname',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        email: {
            translation: 'register.email',
            type: 'username',
            features: {
                mandatory: true,
                validate_check: ['email', 'remote:username'],
            },
            validateOnInit: true,
        },
        domain: {
            translation: 'register.domain',
            type: 'textbox',
            features: {},
            validateOnInit: true,
        },
        text1: {
            translation: 'register.company_name',
            type: 'textbox',
            features: {},
        },
        company: {
            translation: 'register.company_name',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        vatin: {
            translation: 'register.company_id',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        phone: {
            translation: 'register.telephone',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        country: {
            name: '',
            translation: 'register.country',
            type: 'dropdown_values',
            features: {
                mandatory: true,
            },
            options: [
                {
                    id: 'fi',
                    value: 'Finland',
                },
                {
                    id: 'se',
                    value: 'Sweden',
                },
                {
                    id: 'no',
                    value: 'Norway',
                },
                {
                    id: 'dk',
                    value: 'Denmark',
                },
                {
                    id: 'uk',
                    value: 'UK',
                },
                {
                    id: 'us',
                    value: 'US',
                },
            ],
        },
        text2: {
            translation: 'register.promo_title',
            type: 'textbox',
            features: {},
        },
        promocode: {
            translation: 'register.promocode',
            type: 'textbox',
            features: {},
        },
    },
    saveAction: function (self, values) {
        var controller;
        controller = self.get('controller');
        controller.convertZohoLead(values);
        return controller.next(values);
    },
    resetController: function (controller, transition) {
        return controller.set('validationstate', Em.Object.create());
    },
});

export default RegisterAdddetailsRoute;
