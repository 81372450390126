/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/edit/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersEditIndexController;

import Ember from 'ember';

import AddController from '../add/controller';

import collector from '../../../classes/collector';

EmployersEditIndexController = AddController.extend({
    setNotChecked: null,
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
    setCustomization: Em.on('init', function () {
        if (collector.getFormObject('employer.tv_status')) {
            return this.set('customization', {
                emp_id: {
                    template: 'employers/add/emp_id',
                },
                tv_status: {
                    features: {
                        disabled: true,
                    },
                },
                date_checked: {
                    features: {
                        disabled: true,
                    },
                },
                archive_code: {
                    features: {
                        disabled: true,
                    },
                },
            });
        }
    }),
});

export default EmployersEditIndexController;
