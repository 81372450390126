import Helper from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export default Helper.extend({
    compute(params /*, hash*/) {
        let term = params[1];
        let text = params[0];
        if (term) {
            let re = new RegExp(term, 'gi');
            return htmlSafe(text.replace(re, "<span class='highlighted-text'>" + term + '</span>'));
        } else {
            return text;
        }
    },
});
