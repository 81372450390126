import Controller from '@ember/controller';
import { computed, get } from '@ember/object';

const WorktaskListController = Controller.extend({
    queryParams: ['date'],
    today_show: true,

    overdueTasks: computed('model', function () {
        return this.model.filter((task) => {
            return (
                get(task, 'date') < moment().format('YYYY-MM-DD') && get(task, 'status') === 'open'
            );
        });
    }),

    todayTasks: computed('model', function () {
        return this.model.filter((task) => {
            return get(task, 'date') === moment().format('YYYY-MM-DD');
        });
    }),

    futureTasks: computed('model', function () {
        return this.model.filter((task) => {
            return get(task, 'date') > moment().format('YYYY-MM-DD');
        });
    }),

    actions: {
        toggle(panel) {
            if (panel === 'future') {
                this.toggleProperty('future_show');
            } else {
                this.toggleProperty('today_show');
            }
            return false;
        },
    },
    date:
        moment().subtract(1, 'month').format('YYYY-MM-DD') +
        '_' +
        moment().add(1, 'month').format('YYYY-MM-DD'),
});

export default WorktaskListController;
