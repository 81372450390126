/* eslint-disable ember/no-observers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { computed, observer, set, get } from '@ember/object';

const DevelopmentOwnrolesController = Controller.extend({
    dialogs: service(),
    _refreshNeeded: false,
    content: alias('model'),
    userlevel_record: computed('model', function () {
        const form = this.model.findBy('external_type', 'user');
        return this.store
            .peekAll('form-field')
            .find((item) => item.external_type === 'userlevel' && get(item, 'form.id') === form.id);
    }),

    dropdown_options: computed('field', function () {
        const own_roles = this.userlevel_record.options.split('\n').filter(function (item) {
            const splitted = item.split(';');
            return parseInt(splitted[0], 10) >= 10;
        });
        return own_roles.map(function (item) {
            const splitted = item.split(';');
            return { id: splitted[0], name: splitted[1] };
        });
    }),

    selected_role: computed('role', function () {
        const role = this.role;

        if (!role) {
            return;
        }

        const role_obj = this.dropdown_options.findBy('id', role);

        return role + ' - ' + role_obj.name;
    }),

    form: observer('role', function () {
        if (this.role === undefined) {
            return;
        }
        if (this.role === 'null') {
            return this.transitionToRoute('development.ownroles');
        }
        return this.transitionToRoute('development.ownroles.role', this.role);
    }),

    actions: {
        async addOwnRole() {
            const name = await this.dialogs.prompt('Name for new role');
            if (!name) {
                return;
            }
            const record = this.userlevel_record;
            set(record, 'options', record.options + '\n' + this.nextUserLevel() + ';' + name);
            this.notifyPropertyChange('dropdown_options');
            record.save();
            return set(this, '_refreshNeeded', true);
        },

        async renameRole() {
            const role = this.role;
            const name = await this.dialogs.prompt('New name for role');
            if (!name) {
                return;
            }
            const record = this.userlevel_record;
            let options = record.options.split('\n');
            options = options.map(function (item) {
                const splitted = item.split(';');
                if (splitted[0] !== role) {
                    return item;
                }
                return splitted[0] + ';' + name;
            });

            set(record, 'options', options.join('\n'));

            this.notifyPropertyChange('dropdown_options');
            this.notifyPropertyChange('selected_role');
            record.save();
            return set(this, '_refreshNeeded', true);
        },

        async removeRole() {
            const role = this.role;
            const val = await this.dialogs.confirm("Do you really wan't to remove this user role?");
            if (!val) {
                return;
            }
            const record = this.userlevel_record;
            let options = record.options.split('\n');
            options = options.filter(function (item) {
                const splitted = item.split(';');
                if (splitted[0] !== role) {
                    return true;
                }
            });

            set(record, 'options', options.join('\n'));

            set(this, 'role', null);
            this.notifyPropertyChange('dropdown_options');
            this.notifyPropertyChange('selected_role');
            this.transitionToRoute('development.ownroles');
            record.save();
            return set(this, '_refreshNeeded', true);
        },
    },
    nextUserLevel() {
        let max = 9;
        this.userlevel_record.options.split('\n').forEach(function (item) {
            const num = parseInt(item.split(';')[0], 10);
            if (num > max) {
                return (max = num);
            }
        });
        return max + 1;
    },
});

export default DevelopmentOwnrolesController;
