/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

UsersEditRoute = GeneralAddRoute.extend({
    modelName: 'user',
    controllerName: 'users/edit',
    edit: true,
    afterSave: 'users',
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.notifyPropertyChange('currentSaldo');
    },
});

export default UsersEditRoute;
