/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/checkbox-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsCheckboxFieldComponent;

import Ember from 'ember';

import textInput from '../text-field/component';

CollectorFieldsCheckboxFieldComponent = textInput.extend({
    intl: Ember.inject.service(),
    options: Em.computed(function () {
        return [
            {
                id: '',
                name: this.field.prompt ? this.field.prompt : this.get('intl').t('general.choose'),
            },
            {
                id: 'off',
                name: this.get('intl').t('general.no'),
            },
            {
                id: 'on',
                name: this.get('intl').t('general.yes'),
            },
        ];
    }),
});

export default CollectorFieldsCheckboxFieldComponent;
