import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<XToggle\n    @theme={{this.theme}}\n    @value={{@value}}\n    @onToggle={{@onChange}}\n    @showLabels={{this.showLabels}}\n    @size={{this.size}}\n    @onLabel={{this.onLabel}}\n    @offLabel={{this.offLabel}}\n    @disabled={{this.disabled}}\n    @name={{@name}}\n/>", {"contents":"<XToggle\n    @theme={{this.theme}}\n    @value={{@value}}\n    @onToggle={{@onChange}}\n    @showLabels={{this.showLabels}}\n    @size={{this.size}}\n    @onLabel={{this.onLabel}}\n    @offLabel={{this.offLabel}}\n    @disabled={{this.disabled}}\n    @name={{@name}}\n/>","moduleName":"tt4/components/addon/fields/checkbox.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/checkbox.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
/**
    ```hbs
    <Fields::Checkbox @value={{this.checkboxValue}} @onChange={{fn (mut this.checkboxValue)}}/>
    ```
    @class FieldsCheckboxComponent
*/

interface ICheckbox {
    form: string;
    name: string;
    showLabels: boolean;
    disabled: boolean;
    theme: string;
    size: string;
    onLabel: string;
    offLabel: string;
}

export default class FieldsCheckboxComponent extends Component<ICheckbox> implements ICheckbox {
    @tracked public value: boolean = false;
    @service private intl: any;

    constructor(owner: unknown, args: ICheckbox) {
        super(owner, args);
    }

    get form() {
        return this.args.form;
    }

    get name() {
        return this.args.name;
    }

    get showLabels() {
        return this.args.showLabels === false ? false : true;
    }

    get theme() {
        return this.args.theme || 'material';
    }

    get size() {
        return this.args.size || 'large';
    }

    get disabled() {
        return this.args.disabled || false;
    }

    get onLabel() {
        const key = this.form + '.' + this.name + '.on';
        const fallback = this.intl.primaryLocale === 'xy' ? key : 'general.yes';
        return this.intl.t(key, { default: fallback });
    }

    get offLabel() {
        const key = this.form + '.' + this.name + '.off';
        const fallback = this.intl.primaryLocale === 'xy' ? key : 'general.no';
        return this.intl.t(key, { default: fallback });
    }
}
