import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class resourcePlanningRoute extends Route {
    @service('collector-service') Collector;
    @service router;

    beforeModel(transition) {
        this.transitionToRightPage(transition);
    }

    transitionToRightPage(transition) {
        if (
            transition.targetName === 'shift-planning.gantt' &&
            this.Collector.testNeeds(['!userfieldcheck.resourcePlanner'])
        ) {
            this.router.transitionTo('shift-planning.week');
        }
    }

    @action
    willTransition(transition) {
        this.transitionToRightPage(transition);
    }
}
