export function getCommunityLink(brand, companyInfo) {
    // eslint-disable-next-line no-irregular-whitespace
    // TODO: Remove in EP-11985
    if (brand == 'visma') return 'https://ohjeet.visma.fi/articles/#!visma-entry/sovellustuki';
    if (brand == 'otta') return 'https://ohjeet.visma.fi/articles/#!otta/sovellustuki';

    if (!companyInfo) return false;
    let country = companyInfo.country;

    switch (country) {
        case 'se':
            return 'https://community.visma.com/t5/Support-Visma-Construction/bd-p/SE_SW_Visma_Construction_support';
        case 'fi':
            return 'https://ohjeet.visma.fi/articles/#!visma-movenium/sovellustuki';
        default:
            return 'http://movenium.com/support/';
    }
}
export default { getCommunityLink };
