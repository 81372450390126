/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/notes/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryNotesRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

WorksitediaryNotesRoute = GeneralReportRoute.extend({
    form: 'diaryreport_note',
    queryParams: {
        page: {
            refreshModel: true,
        },
        type: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        params.sideload = true;
        return this.store.query('diaryreport-note', params);
    },
});

export default WorksitediaryNotesRoute;
