/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/edit/employer-files/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersViewEmployerFileController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

EmployersViewEmployerFileController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    employerFilesObserver: function () {
        return this.set('page', 1);
    }.observes('employerfile'),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
            hideHistory: true,
        }),
    }),
    form: 'employerfile',
    showColumns: ['description', 'file'],
    cancelRoute: 'employers.employerfiles',
    queryParams: ['row_info_status'],
    row_info_status: null,
    link: Ember.computed(function () {
        return {
            link: ['employers.edit.add-file'],
            translated: this.get('intl').t('menu.add_employer_file'),
        };
    }),
});

export default EmployersViewEmployerFileController;
