import config from 'tt4/config/environment';

export default async function initSnowPlow(me2Promise) {
    //console.log(ApplicationInstance.lookup('service:customer-settings'))
    // let owner = getOwner(app);
    //const customerSettings = app.lookup('service:customer-settings');

    const userdata = await me2Promise;

    (function (p, l, o, w, i, n, g) {
        if (!p[i]) {
            p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
            p.GlobalSnowplowNamespace.push(i);
            p[i] = function () {
                (p[i].q = p[i].q || []).push(arguments);
            };
            p[i].q = p[i].q || [];
            n = l.createElement(o);
            g = l.getElementsByTagName(o)[0];
            n.async = 1;
            n.src = w;
            g.parentNode.insertBefore(n, g);
        }
    })(
        window,
        document,
        'script',
        '//storage.googleapis.com/snowplow-cto-office-tracker-bucket/3.1.1/sp.js',
        'snowplow',
    );

    const PLATFORM = window.cordova ? 'app' : 'web';

    // customerSettings.getSettings()
    //     .then(function(data) {
    //         console.log(data)
    //     })

    window.snowplow('newTracker', 'cf', 'https://snowplow.visma.com', {
        // Initialise a tracker
        appId: config.snowPlow.app_id,
        platform: PLATFORM, // possible values (default is web): web, mob, app, pc, srv
        // anonymousTracking: true, // Use this option in case you want to anonymise your tracking. This will set user_ids and domain ids to 'null'
        contexts: {
            webPage: true, // Common id for webpages
            performanceTiming: true, // Creates performance contexts to create performance metrics of pages
            //geolocation: true    // Prompts user for geolocation data
        },
    });

    // DEFINING CONTEXTS --------------- Check the event dictionary (https://docs.google.com/spreadsheets/d/1G_Xqdj3qo8MqD5T_YAoMjYGPD380FrqkNbvYBlIkF-A/edit?usp=sharing) to understand how each event or context should be used.

    // Each optional field can be removed if not needed
    var context_customer = {
        customer_name: userdata.company_info.company_name, // Main company name
        company_name: userdata.company_info.company_name, // Secondary company name. Set equal to customer_name or leave blank if inapplicable
        organization_no: userdata.company_info.id.toString(),
        countryiso: userdata.company_info.country.toUpperCase(), // Must be exactly two string characters. Follows the IBAN ALPHA-2 code https://www.iban.com/country-codes
        business_unit: 'SW FI',
    };
    var context_product = {
        r12: config.snowPlow.r12, // Get from column R12 Product Line in https://docs.google.com/spreadsheets/d/1grDHVaMsXXPty9Za9UzI935laWGIqyJntEknstsjMWs/edit?usp=sharing
        product_name: config.snowPlow.product_name,
    };

    var context_user = {
        user_type: userdata.userrow.user[0].userlevel,
        // "registration_date" : "1900-01-01", // Must follow exact date format
        // "customfield_user1" : "ADD",
        // "customfield_user2" : "ADD",
        // "customfield_user3" : "ADD"
    };
    // var context_custom = {
    //     "custom_name" : "ADD",
    //     "customfield_custom1" : "ADD",
    //     "customfield_custom2" : "ADD",
    //     "customfield_custom3" : "ADD"
    // };
    // var context_deletion = {
    //     "deletion_id" : "ADD",
    //     "deletion_id2" : "ADD"
    // };

    // Place all contexts in a list. Remove contexts from lists if not used.
    var contexts = [
        {
            schema: 'iglu:com.visma-tech-hubs/context_customer/jsonschema/1-0-0',
            data: context_customer,
        },
        {
            schema: 'iglu:com.visma-tech-hubs/context_product/jsonschema/1-0-0',
            data: context_product,
        },
        { schema: 'iglu:com.visma-tech-hubs/context_user/jsonschema/1-0-0', data: context_user },
        // {'schema': 'iglu:com.visma-tech-hubs/context_custom/jsonschema/1-0-0', 'data': context_custom},
        // {'schema': 'iglu:com.visma-tech-hubs/context_deletion/jsonschema/1-0-0', 'data': context_deletion}
    ];
    // DEFINING CONTEXTS END ---------------

    window.snowplow('setUserId', userdata.userrow.user[0].id); // Create a user id. User Id will be anonymized.

    // Add global contexts to all events so those will be sent with custom structured events also
    const contextGenerator = (args) => {
        if (args.eventType == 'se') {
            return contexts;
        }
    };
    window.snowplow('addGlobalContexts', [contextGenerator]);

    // EVENT METHODS ---------------

    // Initialize link click events with contexts. Only works on HTML objects with "a" tags
    window.snowplow('enableLinkClickTracking', {
        context: contexts,
        pseudoClicks: true,
        trackContent: true,
    });
    // To track linkclicks on objects (e.g. buttons) other than "a" tagged, use following snippet and paste after the content in body/page has been loaded
    /*
        buttons = document.querySelectorAll('button')

        buttons.forEach(function(elem) {
          elem.addEventListener("click", function() {
            snowplow('trackLinkClick', {
              targetUrl: 'http://www.example.com',
              elementId: 'first-link',
              elementClasses: ['class-1', 'class-2'],
              elementTarget: '',
              elementContent: 'this page',
              context: contexts
            });
          });
        });
    */

    /*
    NOTE!! If using Form Tracking, make sure to only track fields with non-sensible information. To do this, add a config variable to the second argument (row 100 for example).
    Fields labelled password are already excluded by default
    var opts = {
          forms: {
            denylist: []
          },
          fields: {
            denylist: ['password']
          }
        };
    window.snowplow('enableFormTracking', { options: opts, context: contexts });
    */
    // window.snowplow('enableFormTracking', { options: {}, context: contexts });

    // Enable page ping events. Contexts are added based on 'trackPageView'. The enableActivityTracking method must be called before the trackPageView method.
    window.snowplow('enableActivityTracking', {
        minimumVisitLength: 10,
        heartbeatDelay: 10,
    });

    // Initialize page view events. Timer is reset for each time the page is loaded
    window.snowplow('trackPageView', { title: null, context: contexts });
    /*
    // Custom structured event, send in event with up to any 5 values
    // (OBS! Only if applicable and triggerpoint must be added)
    // se_category      se_action       se_label        se_property     se_value
    window.snowplow('trackStructEvent', {
              category: 'se_category',
              action: 'se_action',
              label: 'se_label',
              property: 'se_property',
              value: 1.0,
              context: contexts
            });


    // Self-describing events, send in event for a self-defined event with a published json schema
    // (OBS! Only applicable if an event json schema has been published and custom event is required. Remove or comment out if not used)
    var booking_data = {
        "booking_no" : "NO1323452",
        "date_travel" : "2020-04-08",
        "travel_type" : "Leisure",
        "destination" : "Barcelona, Spain"
    };

    window.snowplow('trackSelfDescribingEvent', {
        event: {
            schema: 'iglu:com.visma-tech-hubs/create_booking/jsonschema/1-0-0',
            data: booking_data
        },
        context: contexts
    });
    */

    // EVENT METHODS END ---------------
}
