/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/calendar/saldo-info/component.coffee.old
// Generated by CoffeeScript 2.6.1
var timetrackerCalendarSaldoInfoComponent;

import Ember from 'ember';

timetrackerCalendarSaldoInfoComponent = Ember.Component.extend({
    plus: Ember.computed('day_saldo', function () {
        if (this.get('day_saldo') > 0) {
            return '+';
        }
    }),
    positiveornegative: Ember.computed('day_saldo', function () {
        if (this.get('day_saldo') > 0) {
            return 'positive';
        }
        if (this.get('day_saldo') < 0) {
            return 'negative';
        }
        return '';
    }),
    day_saldo: Ember.computed('saldo', function () {
        var saldo, saldoHours;
        saldo = this.get('saldo');
        saldoHours = saldo.saldo / 60;
        if (saldo.cut) {
            saldoHours -= saldo.cut / 60;
        }
        return saldoHours;
    }),
});

export default timetrackerCalendarSaldoInfoComponent;
