/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/send-skatteverket.coffee.old
// Generated by CoffeeScript 2.6.1
var SendSkatteverketRoute;

import Ember from 'ember';

import pdfExport from '../mixins/pdf-export';

import formReport from '../pods/collector/report-comp/component';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SendSkatteverketRoute = Ember.Route.extend(pdfExport, AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    afterModel: function () {
        return this.sendReport();
    },
    sendReport: function () {
        var c;
        c = this.get('Collector');
        return this.getReport().then((report) => {
            var header, promise;
            header = this.get('intl').t('pl.skatteverket_emailheader');
            promise = c.ttapi({
                url: 'sendReportAsEmail',
                data: {
                    to_address: this.get('session.currentUser.email'),
                    email: report,
                    header: header.toString(),
                },
                method: 'post',
            });
            return promise.then(function (ret) {
                return console.log('done', ret);
            });
        });
    },
    getReport: function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var report;
            report = formReport.create({
                store: this.get('store'),
                Collector: this.get('Collector'),
                intl: this.get('intl'),
                rowsPerPage: 'all',
                form: 'presence',
                query: [
                    {
                        name: 'date',
                        value:
                            moment().subtract(3, 'months').format('YYYY-MM-DD') +
                            '_' +
                            moment().format('YYYY-MM-DD'),
                    },
                ],
                order: 'date-,out',
                show_columns:
                    'date,employer.name,project.name,user.lastname,user.firstname,user.personid,in,out',
            });
            return report.get('asPdfElement').then((pdf) => {
                return this.createPdfFromElements([pdf], 'base64').then((report) => {
                    return resolve(report);
                });
            });
        });
    },
});

export default SendSkatteverketRoute;
