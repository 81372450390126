import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    collector: service('collector-service'),
    init() {
        this._super(...arguments);
        this.rcbillColumns = [
            'customerproduct',
            'startdate',
            'enddate',
            'price',
            'amount',
            'description',
        ];
        this.fixedlimitColumns = ['customerproduct', 'limit', 'price'];
        this.customercampaignColumns = ['campaign'];
    },

    history: alias('model'),
});
