/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/general-v2/components/pause-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorSettingCompPauseSettingComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from '../../../../../classes/collector';

CollectorSettingCompPauseSettingComponent = Ember.Component.extend({
    store: Em.inject.service(),
    session: Em.inject.service(),
    notifications: Ember.inject.service(),
    intl: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    init: function () {
        if (this.get('Collector').testNeedsOne('products.rfid_pause')) {
            this.set('nfcPauseOn', true);
        }
        this._super();
        return this.getSetting();
    },
    pauseOptions: ['15', '30', '45', '60'],
    isValueCustom: Em.computed('internalsetting.value', function () {
        var ref;
        if (
            ((ref = this.get('internalsetting.value')),
            indexOf.call(this.get('pauseOptions'), ref) >= 0)
        ) {
            return false;
        }
        return true;
    }),
    showSaveButton: Ember.computed(
        'internalsetting.value',
        'internalsetting.hasDirtyAttributes',
        function () {
            if (
                this.get('internalsetting.value') >= 0 &&
                this.get('internalsetting.hasDirtyAttributes') &&
                this.get('internalsetting.value') !== null
            ) {
                return true;
            }
            return false;
        },
    ),
    getSetting: function () {
        var configs, setting_params;
        this.set('loading', true);
        setting_params = {
            name: 'default_value',
            value: 30,
        };
        if (this.get('worktimegroup')) {
            setting_params.condition = '$userProfile->worktimegroup==' + this.get('worktimegroup');
        }
        configs = {
            form: 'worktime',
            field: 'pause',
            setting: setting_params,
        };
        return this.get('store')
            .findAll('form', {
                reload: true,
            })
            .then((forms) => {
                var field, form, setting;
                form = forms.filterBy('external_type', configs.form);
                field = form.get('firstObject.fields').filterBy('external_type', configs.field);
                if (field.get('length') > 0) {
                    setting = field
                        .get('firstObject.settings')
                        .filterBy('name', configs.setting.name);
                    setting = setting.filter((item) => {
                        var arr;
                        if (this.get('worktimegroup')) {
                            if (!item.get('condition')) {
                                return false;
                            }
                            arr = item.get('condition').split('==');
                            return arr[1] === this.get('worktimegroup');
                        } else {
                            return item.get('condition') === '';
                        }
                    });
                    if (setting.get('firstObject')) {
                        this.set('internalsetting', setting.get('firstObject'));
                    } else {
                        configs.setting.field = field.get('firstObject');
                        this.set(
                            'internalsetting',
                            this.get('store').createRecord('form-field-setting', configs.setting),
                        );
                    }
                } else {
                    this.set('internalsetting', false);
                }
                return this.set('loading', false);
            });
    },
    actions: {
        saveSetting: function () {
            this.set('loading', true);
            return this.get('internalsetting')
                .save()
                .then(() => {
                    var ret;
                    if (this.get('nfcPauseOn')) {
                        ret = this.get('Collector').setProducts(['products.rfid_pause']);
                    } else {
                        ret = this.get('Collector').setProducts(['!products.rfid_pause']);
                    }
                    return Ember.RSVP.Promise.all(ret).then(() => {
                        this.set('loading', false);
                        if (this.get('settingSaved')) {
                            return this.settingSaved('products.pause');
                        }
                    });
                });
        },
        changeValue: function (value, isCustom) {
            this.set('isValueCustom', isCustom);
            if (!this.get('internalsetting')) {
                return this.getSetting().then(() => {
                    return this.set('internalsetting.value', value);
                });
            } else {
                return this.set('internalsetting.value', value);
            }
        },
    },
});

export default CollectorSettingCompPauseSettingComponent;
