/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/tree/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersTreeRoute;

import Ember from 'ember';

EmployersTreeRoute = Ember.Route.extend({
    model: function (params) {
        var self;
        self = this;
        return this.Collector.ajax({
            url: 'employers',
            data: {
                parent: '!(empty)',
                limit: 'all',
            },
        }).then(function (data) {
            var gdata, promarr;
            promarr = [];
            gdata = [['Name', 'Parent']];
            data.employers.forEach(function (row) {
                return promarr.push(
                    new Em.RSVP.Promise(function (resolve, reject) {
                        if (row.parent) {
                            return self.store
                                .findRecord('employer', row.parent)
                                .then(function (parent) {
                                    gdata.push([
                                        row.name.toString(),
                                        parent.get('name').toString(),
                                    ]);
                                    return resolve();
                                });
                        } else {
                            gdata.pushObject([row.name.toString(), '']);
                            return resolve();
                        }
                    }),
                );
            });
            return Em.RSVP.Promise.all(promarr).then(function () {
                return gdata;
            });
        });
    },
});

export default EmployersTreeRoute;
