import OrientationsAviController from '../../timetracker/avi/controller';
import EmberObject from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';

export default OrientationsAviController.extend({
    notinuse: alias('model'),
    filters: A([
        EmberObject.create({ field: 'project', form: 'presence', singleselect: 'true' }),
        EmberObject.create({ field: 'homecountry', form: 'user', type: 'dropdown_country' }),
    ]),
});
