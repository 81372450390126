define("tt4/components/addon/fields/textarea-varchar", ["exports", "tt4/components/addon/fields/textarea"], function (_exports, _textarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _textarea.default;
    }
  });
});
