/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/salarytypes/route.coffee.old
// Generated by CoffeeScript 2.6.1
var Route;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

Route = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    model: function () {
        this.store.query('salarytype', {
            sideload: true,
        });
        return Ember.RSVP.hash({
            salarytypes: this.store.peekAll('salarytype'),
            shortcuts: null,
        });
    },
    //setupController: (controller, post) ->
    //    this._super(controller, post)
    //    controller.updateModel()
    afterModel: function (model) {
        return this.get('Collector')
            .ccapi({
                url:
                    'default_salarytypes?products=' + this.get('Collector').getProducts().join(','),
            })
            .then((defaults) => {
                var dropdowns, fields;
                dropdowns = [];
                fields = this.get('Collector').fields('worktime');
                Object.keys(fields).forEach((field) => {
                    var defaultsFields, ref, ref1;
                    if (field === 'work_hours') {
                        return;
                    }
                    if ((ref = fields[field].type) === 'hours' || ref === 'number') {
                        defaultsFields = defaults.filter(function (item) {
                            var ref1;
                            return (
                                (ref1 = Ember.get(item, 'type')) === 'field' ||
                                ref1 === 'amount' ||
                                ref1 === 'check'
                            );
                        });
                        if (!defaultsFields.findBy('option', field)) {
                            return defaults.push({
                                name: fields[field].name
                                    ? fields[field].name
                                    : this.get('intl').t('worktime.' + field),
                                control: field,
                                type: 'disabled',
                                option: field,
                            });
                        }
                    } else if (
                        (ref1 = fields[field].type) === 'dropdown_values' ||
                        ref1 === 'dropdown_options'
                    ) {
                        return dropdowns.push({
                            text: fields[field].name
                                ? fields[field].name
                                : this.get('intl').t('worktime.' + field),
                            options: fields[field].options.map((option) => {
                                return {
                                    value: 'custom_' + field + '=' + option.id,
                                    text: this.get('intl').t('worktime.' + field + '.' + option.id),
                                };
                            }),
                        });
                    } else if (fields[field].type === 'checkbox') {
                        return dropdowns.push({
                            text: fields[field].name
                                ? fields[field].name
                                : this.get('intl').t('worktime.' + field),
                            options: [
                                {
                                    value: 'custom_' + field + '=true',
                                    text: this.get('intl').t('general.yes'),
                                },
                                {
                                    value: 'custom_' + field + '=false',
                                    text: this.get('intl').t('general.no'),
                                },
                            ],
                        });
                    }
                });
                Ember.set(model, 'shortcuts', defaults);
                Ember.set(model, 'dropdowns', dropdowns);
                Ember.get(model, 'shortcuts').forEach((shortcut) => {
                    var filtered, found, inuse;
                    if (shortcut.shortcut) {
                        found = Ember.get(model, 'salarytypes').findBy(
                            'shortcut',
                            shortcut.shortcut,
                        );
                    } else {
                        filtered = Ember.get(model, 'salarytypes').filter(function (item) {
                            var ref;
                            return (
                                (ref = item.get('type')) === 'field' ||
                                ref === 'amount' ||
                                ref === 'check' ||
                                ref === 'disabled'
                            );
                        });
                        found = filtered.findBy('option', shortcut.option);
                    }
                    if (!found) {
                        inuse = this.get('Collector').testNeeds(['worktime.' + shortcut.option]);
                        return this.store.createRecord('salarytype', {
                            name: shortcut.name,
                            control: shortcut.control,
                            key: shortcut.key,
                            type: inuse ? 'disabled' : shortcut.type,
                            option: shortcut.option,
                            shortcut: shortcut.shortcut,
                            inuse: inuse,
                        });
                    }
                });
                return Ember.set(model, 'salarytypes', this.store.peekAll('salarytype'));
            });
    },
    actions: {
        willTransition: function (transition) {
            return transition.then(() => {
                if (this.controller.get('_refreshNeeded')) {
                    return this.get('dialogs')
                        .alert(this.get('intl').t('general.settings_refresh_needed'))
                        .then(function () {
                            return location.reload();
                        });
                }
            });
        },
    },
});

export default Route;
