/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/form-column-selector/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FormColumnSelectorComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

FormColumnSelectorComponent = Ember.Component.extend({
    localStorageWrapper: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    gui: Ember.inject.service(),
    hiddenColumns: Em.A([]),
    shownColumns: Em.A([]),
    init: function () {
        var column,
            columns,
            defaultColumns,
            extraColumns,
            field,
            form,
            form_objects,
            globalHide,
            i,
            intl,
            j,
            k,
            key,
            len,
            name,
            obj,
            objects,
            ref,
            showColumnsFromLS,
            shownColumns,
            static_fields,
            summableFieldTypes,
            summableFields,
            supportForOldFormat,
            translatedName;
        this._super();
        form = this.get('form');
        globalHide = ['send_sms', 'linker', 'user_linker', 'device'];
        objects = this.Collector.fields(form);
        if (!objects) {
            return;
        }
        if (this.get('localStorageKey')) {
            key = this.get('localStorageKey');
        } else {
            key = form + '_defaultColumns';
        }
        supportForOldFormat = [];
        if (localStorage.getItem(key)) {
            supportForOldFormat = JSON.parse(localStorage.getItem(key));
        }
        showColumnsFromLS = this.get('localStorageWrapper').getUserSetting(
            key,
            supportForOldFormat,
        );
        shownColumns = [];
        if (showColumnsFromLS && key) {
            shownColumns = showColumnsFromLS;
        } else if (this.get('shownColumns')) {
            shownColumns = this.get('shownColumns');
        }
        if (shownColumns.length <= 0) {
            shownColumns = [];
            i = 0;
            for (k in objects) {
                obj = objects[k];
                if (!(i <= 5)) {
                    continue;
                }
                i++;
                shownColumns.push(k);
            }
            this.set('shownColumns', shownColumns);
        }
        columns = Em.A([]);
        defaultColumns = Em.A([]);
        extraColumns = Em.A([]);
        summableFieldTypes = this.get('summableFieldTypes');
        summableFields = Em.A([]);
        for (name in objects) {
            obj = objects[name];
            if (obj.features.hide_column) {
                continue;
            }
            if (indexOf.call(globalHide, name) >= 0) {
                continue;
            }
            if (this.get('settings.hideColumns')) {
                if (indexOf.call(this.get('settings.hideColumns'), name) >= 0) {
                    continue;
                }
            }
            translatedName = obj.name || this.get('intl').t('report.' + form + '.' + name);
            if (indexOf.call(shownColumns, name) >= 0) {
            } else {
                if (!(name.indexOf('_minus', name.length - 6) !== -1 || obj.features.hide_column)) {
                    if (obj.type !== 'multiselect_tmp') {
                        extraColumns.pushObject({
                            name: translatedName,
                            ext: name,
                            obj: obj,
                        });
                    }
                }
            }
            columns.pushObject({
                name: translatedName,
                ext: name,
            });
            if (
                summableFieldTypes &&
                ((ref = obj.type), indexOf.call(summableFieldTypes, ref) >= 0)
            ) {
                summableFields.pushObject({
                    ext: name,
                    type: obj.type,
                });
            }
        }
        // Add static columns here, like row_info stuff that is on every record
        intl = this.get('intl');
        static_fields = {
            'row_info.created': {
                name: intl.t('general.row_info.created'),
                ext: 'row_info.created',
                obj: {
                    type: 'row_info',
                    features: {},
                },
            },
        };
        for (key in static_fields) {
            field = static_fields[key];
            if (indexOf.call(shownColumns, key) >= 0) {
                continue;
            }
            extraColumns.pushObject(field);
            columns.pushObject(field);
        }
        // new way of showing columns
        for (j = 0, len = shownColumns.length; j < len; j++) {
            column = shownColumns[j];
            if (static_fields[column] != null) {
                defaultColumns.pushObject(static_fields[column]);
                columns.pushObject(field);
                continue;
            }
            if (indexOf.call(globalHide, column) >= 0) {
                continue;
            }
            if (this.get('settings.hideColumns')) {
                if (indexOf.call(this.get('settings.hideColumns'), column) >= 0) {
                    continue;
                }
            }
            // if form_objects are given as parameter, use them
            if (this.get('form_objects')) {
                form_objects = this.get('form_objects');
                obj = form_objects[column];
                translatedName = this.get('intl').t('report.' + form + '.' + column);
                if (obj.translation) {
                    translatedName = this.get('intl').t(obj.translation);
                }
            } else if (column.indexOf('.') !== -1 && this.Collector.fieldExists(form, column)) {
                obj = this.Collector.field(form, column);
                translatedName = this.get('intl').t('report.' + column);
            } else if (this.Collector.fieldExists(form, column)) {
                obj = this.Collector.field(form, column);
                translatedName = obj.translated_name || this.getTranslation(form, column);
            }
            if (!obj) {
                continue;
            }
            if (obj.features.hide_column) {
                continue;
            }
            if (obj) {
                defaultColumns.pushObject({
                    name: translatedName,
                    ext: column,
                    obj: obj,
                });
                columns.pushObject({
                    name: translatedName,
                    ext: column,
                });
            }
        }
        this.set('shownColumns', defaultColumns);
        this.set('hiddenColumns', extraColumns);
        return this.sendAction('bubbleActions', 'fieldsUpdate', this.get('shownColumns'));
    },
    getTranslation: function (form, column) {
        if (form.substring(0, 18) === 'measurement-custom') {
            // little hack to support measurement-custom form headers
            form = 'tr';
        }
        console.log(form, column);
        return this.get('intl').t('report.' + form + '.' + column);
    },
    moveElementInArray: function (obj, col, offset) {
        var index, newIndex;
        index = obj.indexOf(col);
        obj.removeObject(col);
        newIndex = index + offset;
        if (newIndex > obj.length) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = obj.length;
        }
        return obj.insertAt(newIndex, col);
    },
    setLocalStorageColumns: Em.observer('shownColumns.[]', function () {
        var columns, key;
        columns = [];
        if (this.get('localStorageKey')) {
            key = this.get('localStorageKey');
        } else {
            key = form + '_defaultColumns';
        }
        this.get('shownColumns').forEach(function (obj) {
            return columns.push(obj.ext);
        });
        if (key) {
            return this.get('localStorageWrapper').setUserSetting(key, columns);
        }
    }),
    actions: {
        showColumn: function (col) {
            var current, extra;
            current = this.get('shownColumns');
            extra = this.get('hiddenColumns');
            current.pushObject(col);
            extra.removeObject(col);
            this.set('shownColumns', current);
            this.set('hiddenColumns', extra);
            return this.sendAction('bubbleActions', 'fieldsUpdate', current);
        },
        hideColumn: function (col) {
            var current, extra;
            current = this.get('shownColumns');
            extra = this.get('hiddenColumns');
            current.removeObject(col);
            extra.pushObject(col);
            this.set('shownColumns', current);
            this.set('hiddenColumns', extra);
            return this.sendAction('bubbleActions', 'fieldsUpdate', current);
        },
        moveCol: function (col, dir) {
            this.moveElementInArray(this.get('shownColumns'), col, dir);
            return this.sendAction('bubbleActions', 'fieldsUpdate', this.get('shownColumns'));
        },
        columnDropped: function (target, dropped) {
            var columns;
            dropped = parseInt(dropped);
            if (target === dropped) {
                return;
            }
            columns = this.get('shownColumns');
            this.moveElementInArray(this.get('shownColumns'), columns[dropped], target - dropped);
            return this.sendAction('bubbleActions', 'fieldsUpdate', this.get('shownColumns'));
        },
        fullScreen: function () {
            if (this.get('fullscreenmode') === 'custom') {
                return this.get('gui').toggleCustomFullScreen();
            } else {
                return this.get('gui').togglefullScreen();
            }
        },
    },
});

export default FormColumnSelectorComponent;
