/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsReportRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

ProjectsReportRoute = GeneralReportRoute.extend({
    queryParams: {
        date: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var absenceParams;
        params = this._super(params);
        params.sideload = true;
        params.groupBy = 'user,project';
        absenceParams = {
            startdate: params.date,
            sideload: true,
            groupBy: 'user',
        };
        return Ember.RSVP.hash({
            worktimes: this.store.query('worktime', params),
            abcenses: this.store.query('abcense', absenceParams),
        });
    },
});

export default ProjectsReportRoute;
