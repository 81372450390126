import Controller from '@ember/controller';
import localization from 'tt4/classes/localization';
import { inject as service } from '@ember/service';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';

const WorktasksViewController = Controller.extend({
    Collector: service('collector-service'),
    gui: service(),
    manualFormat: null,
    editable: null,
    icons: null,

    init() {
        this._super(...arguments);
        this.manualFormat = ['date', 'est_hours'];
        this.editable = ['signature'];
        this.icons = {
            date: 'Date',
            project: 'Project',
            est_hours: 'Timespan',
            notes: 'Notes',
            files: 'Pictures',
            signature: 'Signature',
            starttime: 'Time',
        };
    },

    modelAlias: alias('model'),

    date_formatted: computed('model.date', function () {
        return moment(this.modelAlias.date).format('DD MMMM, YYYY');
    }),

    est_hours_formatted: computed('model.est_hours', function () {
        return localization.formatHours(this.modelAlias.est_hours);
    }),

    fields: computed(function () {
        const c = this.Collector;
        const fields = c.fieldArray('worktask');

        return fields.reject(
            (field) =>
                field.name === 'name' ||
                field.name === 'user' ||
                field.name === 'hours' ||
                (field.features.hide &&
                    typeof field.features.hide !== 'object' &&
                    field.features.hide.condition !== 'editing'),
        );
    }),

    actions: {
        setFixed() {
            set(this.modelAlias, 'status', 'fixed');
            /* eslint-disable ember/named-functions-in-promises */
            return this.modelAlias.save().then(() => {
                return this.transitionToRoute('worktasks.list');
            });
        },

        saveField(value, field) {
            if (field.name !== 'involved') {
                set(this, 'modelAlias.' + field.name, value);
            }

            return this.modelAlias.save();
        },
    },
});

export default WorktasksViewController;
