import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';

export default Component.extend({
    session: service(),
    store: service(),
    dialogs: service(),
    intl: service(),
    evented: service(),
    notifications: service(),
    tagName: 'tr',
    disableEditing: false,

    async didReceiveAttrs() {
        this._super();
        let model = get(this, 'model');
        if (
            model.constructor.modelName === 'worktime' &&
            (!this.Collector.canAddWorktime(model.date) ||
                this.Collector.testNeeds(['products.timetracker_clockcard', 'userlevel=1?']))
        ) {
            set(this, 'disableEditing', true);
        }

        // if model has no modelName we assume that it is absence fetched from /absence/split route
        if (!get(this, 'model.constructor.modelName')) {
            set(
                this,
                'record',
                get(
                    await this.store.query('abcense', {
                        sideload: true,
                        id: get(this, 'model.id'),
                    }),
                    'firstObject',
                ),
            );
        } else {
            set(this, 'record', get(this, 'model'));
        }

        set(this, 'data', {
            project: model.project ? model.project : null,
            starttime: model.starttime ? model.starttime : null,
            endtime: model.endtime ? model.endtime : null,
            hours: model.work_hours ? model.work_hours : model.hours,
            pause: model.pause ? model.pause : null,
            absencetype: model.abcensetype ? model.abcensetype : null,
            form: model.abcensetype ? 'abcense' : 'worktime',
            km: model.km ? model.km : null,
            comp: model.comp ? model.comp : null,
            worktype: model.worktype ? model.worktype : null,
        });
    },

    actions: {
        saveRow() {
            this.updateData();
            this.toggleProperty('showEditModal');
        },
        notifySaved() {
            this.updateData();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        },
        async openEditModal() {
            if (this.disableEditing) return;
            set(this, 'showEditModal', true);
        },
    },
});
