/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/invalid.coffee.old
// Generated by CoffeeScript 2.6.1
var InvalidRoute;

import Ember from 'ember';

InvalidRoute = Ember.Route.extend({
    redirect: function () {
        console.warn('No route for given URL found, redirecting to index route.');
        return this.transitionTo('index');
    },
});

export default InvalidRoute;
