/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/can-add-worktime.coffee.old
// Generated by CoffeeScript 2.6.1
var CanAddWorktimeHelper;

import Ember from 'ember';

CanAddWorktimeHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    compute: function (params, hash) {
        var c;
        c = this.get('Collector');
        return c.canAddWorktime(params[0]);
    },
});

export default CanAddWorktimeHelper;
