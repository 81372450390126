/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditIndexRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

ProjectsEditIndexRoute = GeneralAddRoute.extend({
    controllerName: 'projects/edit/index',
    model: function () {
        return this.modelFor('projects.edit');
    },
});

export default ProjectsEditIndexRoute;
