import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Component.extend({
    router: service(),

    actions: {
        signin() {
            get(this, 'router').transitionTo({ queryParams: { wizard: 'vismanet-wizard' } });
        },
    },
});
