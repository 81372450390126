/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saldohistory/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSaldohistoryController;

import Ember from 'ember';

UsersSaldohistoryController = Ember.Controller.extend({
    queryParams: ['date', 'user', 'employer'],
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'employer',
                type: 'database',
                form: 'user',
                name: 'employer',
                singleselect: true,
            }),
            Em.Object.create({
                field: 'user',
                type: 'database',
                form: 'worktime',
                name: 'user',
                singleselect: true,
            }),
            Em.Object.create({
                field: 'date',
                type: 'date',
                form: 'worktime',
                name: 'date',
            }),
        ]);
    }),
    mappedFilters: Em.computed('filters.@each.value', function () {
        var arr;
        arr = [];
        this.get('filters').forEach((filter) => {
            if (filter.get('field') === 'employer') {
                if (!this.get('filters').findBy('name', 'user').get('value')) {
                    return arr.push({
                        name: 'user.employer',
                        value: filter.get('value'),
                    });
                }
            } else {
                return arr.push({
                    name: filter.get('name'),
                    value: filter.get('value'),
                });
            }
        });
        return arr;
    }),
    rowActions: [
        {
            action: 'rowHistory',
            icon: 'move move-Search',
        },
        {
            action: 'removeRow',
            icon: 'move move-Delete',
        },
    ],
});

export default UsersSaldohistoryController;
