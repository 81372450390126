import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { getSums } from 'tt4/utils/sumrow-util';

export default Component.extend({
    gui: service(),

    selected: null,

    allSelected: computed('data.length', 'selected.length', function () {
        return get(this, 'data.length') === get(this, 'selected.length');
    }),

    _columns: computed('columns', 'columns.{@each.hide,[]}', function () {
        const actions = {
            name: '',
            valuePath: '@actions',
            isFixed: 'right',
            maxwidth: 45,
            isSortable: false,
        };
        return this.columns.concat([actions]);
    }),

    tableWidthConstraint: computed('_columns', function () {
        if (this.gui.isMobile()) return 'gte-container';
        return get(this, 'columns.length') > 13 ? 'gte-container' : 'eq-container';
    }),

    idsStr: computed('selected.[]', function () {
        return this.selected.mapBy('id').join(',');
    }),

    sumRow: computed('data', 'columns', 'selected.[]', function () {
        let data = !isEmpty(this.selected) ? this.selected : this.data;

        if (!this.columns || !data) return;

        return getSums(data, this.columns, this.form, this.Collector);
    }),

    didReceiveAttrs() {
        set(this, 'selected', []);
    },

    actions: {
        updateSorts(sorts) {
            set(this, 'sorts', sorts);
            if (this.updateSorts) this.updateSorts(sorts);
        },

        toggleSelectAll() {
            if (get(this, 'allSelected')) {
                set(this, 'selected', []);
            } else {
                set(this, 'selected', get(this, 'data').toArray());
            }
        },

        toggleInlineEdit() {
            //if inlineEdit is true, the action will be to disable inline editing and vice versa
            this.toggleInlineEdit();
        },

        setRowsPerPage(RowsPerPage) {
            this.setRowsPerPage(RowsPerPage);
        },

        deselectAll() {
            set(this, 'selected', []);
        },

        exportCsv(selected) {
            this.exportCsv(selected);
        },

        saveRows() {
            this.saveRows();
        },

        refreshReport() {
            this.refreshReport();
        },

        changeStatus(selected, status) {
            this.changeStatus(selected, status);
        },

        handleLock(selected, action) {
            this.handleLock(selected, action);
        },

        handleRemove(selected, action) {
            this.handleRemove(selected, action);
        },
    },
});
