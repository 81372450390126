import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    tagName: 'table',

    mappedLogs: computed('logs', function () {
        let logs = get(this, 'logs.content');

        let mapLogs = logs.map((log) => {
            if (log.body) {
                Object.values(log.body).map((values) => {
                    return this.modifiedData(log, values);
                });
            }
            return log;
        });
        return mapLogs;
    }),

    init() {
        this._super();
        this.showData = {};
    },

    actions: {
        toggle(log) {
            if (!get(this, 'showData.' + log._id)) {
                set(this, 'showData.' + log._id, true);
            } else {
                set(this, 'showData.' + log._id, false);
            }
        },
    },

    modifiedData(log, values) {
        if (Object.keys(log.body)[0] === 'formFieldSetting' && values.field) {
            values.field_info = this.store.peekRecord('form-field', values.field);
        } else {
            values.placeholder = values.external_type ? values.external_type : values.name;
        }
        return values;
    },
});
