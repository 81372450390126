/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/overview/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditOverviewController;

import Ember from 'ember';

import DS from 'ember-data';

ProjectsEditOverviewController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    hiddenDashboardObjects: [],
    dashboardObjects: Em.computed('model.project', function () {
        var c, objs;
        c = this.get('Collector');
        objs = [];
        if (c.testNeedsOne('presence')) {
            objs.push({
                name: 'presence',
                title: 'project.overview.title.presences',
                styles: 'order: 0',
            });
        }
        if (c.testNeedsOne('worktime')) {
            objs.push({
                name: 'worktime',
                title: 'project.overview.title.worktimes',
                styles: 'order: 1',
            });
        }
        if (c.testNeedsOne('worktask')) {
            objs.push({
                name: 'worktask',
                title: 'report.worktasks.report',
                styles: 'order: 2',
            });
        }
        if (c.testNeedsOne('tr')) {
            objs.push({
                name: 'tr',
                title: 'project.overview.tr_index.mean',
                styles: 'order: 3',
            });
        }
        if (c.testNeedsOne('mvr')) {
            objs.push({
                name: 'mvr',
                title: 'project.overview.mvr_index.mean',
                styles: 'order: 4',
            });
        }
        if (c.testNeedsOne('export_taxman')) {
            objs.push({
                name: 'taxmanerror',
                title: 'submenu.project.taxmanerrors',
                styles: 'order: 5',
            });
        }
        if (c.testNeedsOne('export_taxman') && this.get('model.project.proj_type') === '3') {
            objs.push({
                name: 'contractTaxmanerror',
                title: 'project.overview.contract_taxmanerrors',
                styles: 'order: 6',
            });
        }
        if (c.testNeedsOne('employer') && !c.testNeedsOne('products.generic_timetracker')) {
            objs.push({
                name: 'tv_status',
                title: 'submenu.project.tv_status',
                styles: 'order: 7',
            });
        }
        return objs;
    }),
    dashboardView: Em.computed('dashboardObjects', function () {
        var i, len, lsDbHide, object, ref, tmp, tmp_hidden;
        tmp = [];
        tmp_hidden = [];
        lsDbHide = this.get('localStorageWrapper').getUserSetting('hideDashboard');
        ref = this.get('dashboardObjects');
        for (i = 0, len = ref.length; i < len; i++) {
            object = ref[i];
            if (lsDbHide.indexOf(object.name) > -1) {
                tmp_hidden.push(object);
            } else {
                tmp.push(object);
            }
        }
        this.set('hiddenDashboardObjects', tmp_hidden);
        return tmp;
    }),
    pie_options: {
        pieHole: 0.9,
        chartArea: {
            width: '90%',
            height: '95%',
        },
        height: 200,
        width: 200,
        pieSliceTextStyle: {
            color: 'black',
            fontSize: 20,
        },
        pieSliceText: 'value',
    },
    //        slices: {
    //            1: {offset: 0.02}
    //            2: {offset: 0.02}
    //            3: {offset: 0.02}
    //        }
    form: 'user',
    fields: Ember.computed(function () {
        var obj;
        obj = {
            first_row: this.hasFields(['firstname', 'lastname']),
            rows: [this.hasFields(['personid', 'userlevel'])],
        };
        return obj;
    }),
    hasFields: function (fields, form = null) {
        var c, field, i, len, ret;
        c = this.get('Collector');
        if (!form) {
            form = 'user';
        }
        ret = [];
        for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];
            if (c.fieldExists(form, field)) {
                ret.push(field);
            }
        }
        return ret;
    },
    tr_mean: Ember.computed('model.tr', function () {
        var color, mean, trs;
        trs = this.get('model.tr');
        mean = this.calculateMean(trs, 'tr_rating');
        color = this.getRatingColor(mean);
        return {
            mean: mean.toFixed(0) + '%',
            color: color,
        };
    }),
    mvr_mean: Ember.computed('model.mvr', function () {
        var color, mean, mvrs;
        mvrs = this.get('model.mvr');
        mean = this.calculateMean(mvrs, 'mvr_rating');
        color = this.getRatingColor(mean);
        return {
            mean: mean.toFixed(0) + '%',
            color: color,
        };
    }),
    getRatingColor: function (value) {
        if (value >= 95) {
            return 'good-rating';
        } else if (value >= 90) {
            return 'decent-rating';
        } else if (value < 90) {
            return 'bad-rating';
        } else {
            return 'default';
        }
    },
    calculateMean: function (data, field) {
        var total;
        if (data.get('meta.count') === 0) {
            return '-';
        }
        total = 0;
        data.forEach(function (item) {
            return (total += item.get(field));
        });
        return total / data.get('meta.count');
    },
    sensors: Ember.computed('model.project.sensors', function () {
        var url;
        url = this.get('model.project.sensors');
        if (url) {
            return DS.PromiseObject.create({
                promise: this.fetchSensorData(url),
            });
        }
    }),
    sensorList: Ember.computed('sensors.isFulfilled', function () {
        var sensors;
        sensors = this.get('sensors');
        console.log('sensors', sensors);
        if (sensors && sensors.isFulfilled) {
            return sensors.content.map(function (core, key) {
                return {
                    id: key,
                    name: core.name,
                };
            });
        }
    }),
    selectedCores: Ember.computed('selectedSensor', 'sensors.isFulfilled', function () {
        var selectedSensor;
        if (!this.get('sensors.isFulfilled')) {
            return [];
        }
        selectedSensor = this.get('selectedSensor') || 0;
        return this.get('sensors.content')[selectedSensor].sensors;
    }),
    fetchSensorData: function (url) {
        var c, intl;
        c = this.get('Collector');
        intl = this.get('intl');
        return new Ember.RSVP.Promise((resolve, reject) => {
            return c
                .ccapi({
                    url: 'sensors_mama',
                    data: {
                        key: url,
                    },
                })
                .then((data) => {
                    var all_sensors, chart, core, i, j, len, len1, ref, ref1, row, sensors;
                    all_sensors = [];
                    ref = data.cores;
                    for (i = 0, len = ref.length; i < len; i++) {
                        core = ref[i];
                        if (core.values == null) {
                            continue;
                        }
                        sensors = [];
                        ref1 = core.values;
                        for (j = 0, len1 = ref1.length; j < len1; j++) {
                            row = ref1[j];
                            chart = this.getChartOptionsByType(row.type, row);
                            if (!chart) {
                                continue;
                            }
                            chart.chart = [
                                ['Label', 'Value'],
                                [intl.t('sensor.type.' + row.type), chart.value],
                            ];
                            sensors.push(chart);
                        }
                        if (sensors.length > 0) {
                            all_sensors.push({
                                name: core.tags.length > 0 ? core.tags[0] : core.name,
                                sensors: sensors,
                            });
                        }
                    }
                    return resolve(all_sensors);
                });
        });
    },
    getChartOptionsByType: function (type, data) {
        var temp;
        if (data.name === 'Dew Point') {
            // don't know better way to skip this one.. so hack for now :)
            return;
        }
        temp = {};
        temp.value = data.value;
        temp.unit = data.unit;
        if (type === 'temperature') {
            temp.chart_options = {
                yellowFrom: 25,
                yellowTo: 35,
                redFrom: 35,
                redTo: 50,
                max: 50,
                min: -50,
                width: 150,
                height: 150,
            };
        }
        if (type === 'pressure') {
            temp.unit = 'k' + data.unit;
            temp.value = Math.floor(data.value / 100) / 10;
            temp.chart_options = {
                greenFrom: 95,
                greenTo: 110,
                max: 120,
                min: 80,
                width: 150,
                height: 150,
            };
        }
        if (type === 'humidity') {
            temp.chart_options = {
                yellowFrom: 75,
                yellowTo: 85,
                redFrom: 85,
                redTo: 100,
                max: 100,
                min: 50,
                width: 150,
                height: 150,
            };
        }
        if (type === 'temperature' || type === 'pressure' || type === 'humidity') {
            return temp;
        } else {
            return null;
        }
    },
    actions: {
        transitionWithParams: function (key) {
            return this.transitionToRoute('projects.edit.taxmanerrors').then((targetRoute) => {
                return targetRoute.controller.set('taxmanKey', key);
            });
        },
        removeDashboardObject: function (object) {
            var lsDbHide;
            // update localstorage
            lsDbHide = this.get('localStorageWrapper').getUserSetting('hideDashboard');
            if (lsDbHide.length > 0) {
                lsDbHide.push(object.name);
            } else {
                lsDbHide = [object.name];
            }
            this.get('localStorageWrapper').setUserSetting('hideDashboard', lsDbHide);
            // update computed-property so we can update view in realtime
            this.get('dashboardView').removeObject(object);
            this.get('hiddenDashboardObjects').pushObject(object);
            return false;
        },
        restoreDashboardObject: function (object) {
            var index, lsDbHide;
            // update localstorage
            lsDbHide = this.get('localStorageWrapper').getUserSetting('hideDashboard');
            if (lsDbHide.length > 0) {
                index = lsDbHide.indexOf(object.name);
                if (index !== -1) {
                    lsDbHide.splice(index, 1);
                }
            }
            this.get('localStorageWrapper').setUserSetting('hideDashboard', lsDbHide);
            // update computed-property so we can update view in realtime
            this.get('dashboardView').pushObject(object);
            this.get('hiddenDashboardObjects').removeObject(object);
            return false;
        },
        editRow: function (row) {
            var form, id;
            form = row.get('constructor.modelName');
            id = row.get('id');
            return this.transitionToRoute({
                queryParams: {
                    modal: [form, id],
                },
            });
        },
    },
    worktasks: Ember.computed('model.worktask', function () {
        var i,
            item,
            j,
            len,
            len1,
            pie_done,
            pie_overdue,
            pie_upcoming,
            ref,
            worktask,
            worktask_pie,
            worktasks;
        worktask = this.get('model.worktask');
        worktask_pie = Em.A([
            [
                this.get('intl').t('report.worktasks.report'),
                this.get('intl').t('report.worktasks.report'),
            ],
        ]);
        pie_overdue = 0;
        pie_upcoming = 0;
        pie_done = 0;
        worktasks = [];
        ref = worktask.content;
        for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            worktasks.push(item._data);
        }
        for (j = 0, len1 = worktasks.length; j < len1; j++) {
            item = worktasks[j];
            if (moment().isBefore(item.date) && item.status === 'open') {
                pie_upcoming++;
            } else if (item.status === 'fixed') {
                pie_done++;
            } else if (moment().isAfter(item.date) && item.status === 'open') {
                pie_overdue++;
            }
        }
        worktask_pie.push([this.get('intl').t('worktask_pie.upcoming'), pie_upcoming]);
        worktask_pie.push([this.get('intl').t('worktask_pie.done'), pie_done]);
        worktask_pie.push([this.get('intl').t('worktask_pie.overdue'), pie_overdue]);
        return worktask_pie;
    }),
    openWorktasks: Ember.computed('model.worktask', function () {
        return this.get('model.worktask').filterBy('status', 'open');
    }),
    fixedWorktasks: Ember.computed('model.worktask', function () {
        return this.get('model.worktask').filterBy('status', 'fixed');
    }),
    worktaskFields: Ember.computed(function () {
        var obj;
        obj = {
            first_row: this.hasFields(['date', 'name'], 'worktask'),
            rows: [this.hasFields(['est_hours', 'hours', 'photos'], 'worktask')],
        };
        return obj;
    }),
    worktimes: Ember.computed('model.worktime', function () {
        var i, item, j, len, len1, pie_approved, pie_open, ref, worktime, worktime_pie, worktimes;
        worktime = this.get('model.worktime');
        worktime_pie = Em.A([
            [
                this.get('intl').t('project.overview.title.worktimes'),
                this.get('intl').t('project.overview.title.worktimes'),
            ],
        ]);
        pie_open = 0;
        pie_approved = 0;
        worktimes = [];
        ref = worktime.content;
        for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            worktimes.push(item._data);
        }
        for (j = 0, len1 = worktimes.length; j < len1; j++) {
            item = worktimes[j];
            if (item.status === 'open') {
                pie_open++;
            } else if (item.status === 'approved') {
                pie_approved++;
            }
        }
        worktime_pie.push([this.get('intl').t('worktime_pie.open'), pie_open]);
        worktime_pie.push([this.get('intl').t('worktime_pie.approved'), pie_approved]);
        return worktime_pie;
    }),
    openWorktimes: Ember.computed('model.worktime', function () {
        return this.get('model.worktime').filterBy('status', 'open');
    }),
    approvedWorktimes: Ember.computed('model.worktime', function () {
        return this.get('model.worktime').filterBy('status', 'approved');
    }),
    presences: Ember.computed('model.presence', function () {
        var i, item, j, len, len1, pie_total, presence, presence_pie, presences, ref;
        presence = this.get('model.presence');
        presence_pie = Em.A([
            [
                this.get('intl').t('project.overview.title.presences'),
                this.get('intl').t('project.overview.title.presences'),
            ],
        ]);
        pie_total = 0;
        presences = [];
        ref = presence.content;
        for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            presences.push(item._data);
        }
        for (j = 0, len1 = presences.length; j < len1; j++) {
            item = presences[j];
            pie_total++;
        }
        presence_pie.push([this.get('intl').t('presence_pie.total'), pie_total]);
        return presence_pie;
    }),
    taxmanerrors: Ember.computed('model', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('store')
                    .findAll('exportContactPerson')
                    .then((contacts) => {
                        contacts = contacts.filter((contact) => {
                            return contact.get('isNew') === false;
                        });
                        if (contacts.length > 0) {
                            return this.get('Collector')
                                .ccapi({
                                    url:
                                        'vstyonte?date=' +
                                        moment().startOf('month').format('YYYY-MM-DD') +
                                        '_' +
                                        moment().endOf('month').format('YYYY-MM-DD') +
                                        '&project=' +
                                        this.get('model.project.id'),
                                })
                                .then((back) => {
                                    var data, pie_total, taxman_pie;
                                    taxman_pie = Em.A([
                                        [
                                            this.get('intl').t('submenu.project.taxmanerrors'),
                                            this.get('intl').t('submenu.project.taxmanerrors'),
                                        ],
                                    ]);
                                    data = JSON.parse(back).validations;
                                    pie_total = Object.keys(data).length;
                                    taxman_pie.push([
                                        this.get('intl').t('taxmam_pie.total'),
                                        pie_total,
                                    ]);
                                    return resolve({
                                        value: taxman_pie,
                                    });
                                });
                        }
                    });
            }),
        });
    }),
    contractTaxmanerrors: Ember.computed('model', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('store')
                    .findAll('exportContactPerson')
                    .then((contacts) => {
                        contacts = contacts.filter((contact) => {
                            return contact.get('isNew') === false;
                        });
                        if (contacts.length > 0) {
                            return this.get('Collector')
                                .ccapi({
                                    url:
                                        'vsurakka?contract_month=' +
                                        moment().format('YYYY-MM') +
                                        '&project=' +
                                        this.get('model.project.id'),
                                })
                                .then((back) => {
                                    var data, pie_total, taxman_pie;
                                    taxman_pie = Em.A([
                                        [
                                            this.get('intl').t(
                                                'project.overview.contract_taxmanerrors',
                                            ),
                                            this.get('intl').t(
                                                'project.overview.contract_taxmanerrors',
                                            ),
                                        ],
                                    ]);
                                    data = JSON.parse(back).validations;
                                    pie_total = Object.keys(data).length;
                                    taxman_pie.push([
                                        this.get('intl').t('contract_taxman_pie.total'),
                                        pie_total,
                                    ]);
                                    return resolve({
                                        value: taxman_pie,
                                    });
                                });
                        }
                    });
            }),
        });
    }),
    tv_status: Ember.computed('model.projectUsers', function () {
        var employers,
            i,
            item,
            len,
            pie_alert,
            pie_check,
            pie_not_found,
            pie_ok,
            pie_stop,
            pie_waiting,
            tv_status_pie;
        employers = this.get('model.projectUsers.employers');
        tv_status_pie = Em.A([
            [
                this.get('intl').t('submenu.project.tv_status'),
                this.get('intl').t('submenu.project.tv_status'),
            ],
        ]);
        pie_ok = 0;
        pie_not_found = 0;
        pie_stop = 0;
        pie_check = 0;
        pie_waiting = 0;
        pie_alert = 0;
        for (i = 0, len = employers.length; i < len; i++) {
            item = employers[i];
            if (item.tv_status) {
                if (item.tv_status === 'ok') {
                    pie_ok++;
                } else if (item.tv_status === 'ei_loydy') {
                    pie_not_found++;
                } else if (item.tv_status === 'seis') {
                    pie_stop++;
                } else if (item.tv_status === 'selvita') {
                    pie_check++;
                } else if (item.tv_status === 'odotetaan') {
                    pie_waiting++;
                } else if (item.tv_status === 'huomioi') {
                    pie_alert++;
                }
            }
        }
        tv_status_pie.push([this.get('intl').t('employer.tv_status.ok'), pie_ok]);
        tv_status_pie.push([this.get('intl').t('employer.tv_status.ei_loydy'), pie_not_found]);
        tv_status_pie.push([this.get('intl').t('employer.tv_status.seis'), pie_stop]);
        tv_status_pie.push([this.get('intl').t('employer.tv_status.selvita'), pie_check]);
        tv_status_pie.push([this.get('intl').t('employer.tv_status.odotetaan'), pie_waiting]);
        tv_status_pie.push([this.get('intl').t('employer.tv_status.huomioi'), pie_alert]);
        return tv_status_pie;
    }),
});

export default ProjectsEditOverviewController;
