import { IRecord } from 'tt4/ember-movenium/interfaces/record';
import { IOptions } from 'tt4/ember-movenium/interfaces/forms';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export function validateEmail(email: string): boolean {
    const regExp =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i;
    return regExp.test(email);
}

export function isVisible(record: IRecord, options: IOptions | [{}]) {
    if (typeof options !== 'object') return true;
    if (!record) return true;

    let visible = false;
    options.forEach((option: any) => {
        Object.keys(option).forEach((key) => {
            const listString = option[key].toString();
            const value = get(record, key);
            visible = valueIsInList(value, listString);
        });
    });

    return visible;
}

function valueIsInList(value: any, listString: string) {
    if (listString === 'true') {
        if (value === 'true' || value === true) return true;
        else return false;
    }
    if (listString === 'false') {
        if (value === 'true' || value === true) return false;
        return true;
    }

    let negation = false;
    if (listString.substring(0, 1) === '!') {
        listString = listString.substring(1);
        negation = true;
    }

    const list = listString.split(',');
    if (value) value = value.toString();
    let found = list.includes(value);

    if (list.includes('(empty)')) {
        found = isEmpty(value);
    }

    return negation ? !found : found;
}
export default { validateEmail, isVisible };
