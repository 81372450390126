import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    dialogs: service(),
    intl: service(),

    init() {
        this._super();
        this.columns = ['period_name', 'period_start_date', 'period_end_date'];
        this.rowActions = [{ action: 'removeRow', icon: 'move move-Delete' }];
        this.wagePeriods = get(this, 'store').findAll('wage_period');
        this.perdiemToggled = get(this, 'settings.perdiem');
        this.kmToggled = get(this, 'settings.km_type');
    },
    actions: {
        perdiemToggled(value) {
            set(this, 'settings.perdiem', value === true ? 'def' : null);
            this.settingChanged('perdiem', get(this, 'settings.perdiem'));
        },

        km_typeToggled(value) {
            set(this, 'settings.km_type', value === true ? 'km' : null);
            this.settingChanged('km_type', get(this, 'settings.km_type'));
        },

        rowActions(action, row) {
            if (action == 'removeRow') {
                get(this, 'dialogs')
                    .confirm(get(this, 'intl').t('general.remove_row'))
                    .then(this._removeRowIfcon.bind(this, row));
            }
        },
    },
    _removeRowIfcon(row, con) {
        if (con) {
            row.destroyRecord();
        }
    },
});
