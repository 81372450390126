import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import collectorService from 'tt4/injections/services/collector-service';
import ttapiService from 'tt4/injections/services/ttapi';
import initappService from 'tt4/injections/services/initapp';
import sessionhelperService from 'tt4/injections/services/sessionhelper';
import { A } from '@ember/array';
import MutableArray from '@ember/array/mutable';
import { action, notifyPropertyChange } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import Ember from 'ember';
import { later } from '@ember/runloop';

export default class MessAgesComponent extends Component<any> {
    @service public session: any;
    @service protected router: any;
    @service('collector-service' as any) protected Collector: collectorService;
    @service protected ttapi: ttapiService;
    @service protected initapp: initappService;
    @service protected sessionhelper: sessionhelperService;

    @tracked protected messageStack: MutableArray<string> = A([]);

    public get currentMessage(): any {
        return this.messageStack.firstObject;
    }

    public get formattedCreated() {
        return moment.utc(this.currentMessage.created).local().format('LLLL');
    }

    constructor(owner: unknown, args: any) {
        super(owner, args);
        if (!Ember.testing) {
            if (this.initapp.appReady) {
                this.fetchMessagesLoop();
            } else {
                this.initapp.on('appReady', () => {
                    this.fetchMessagesLoop();
                });
            }
        }
    }

    public fetchMessagesLoop(): any {
        this.fetchMessages();
        return later(
            this,
            () => {
                return this.fetchMessagesLoop();
            },
            15 * 60 * 1000,
        );
    }

    public async fetchMessages() {
        if (!this.session.isAuthenticated) return;
        if (this.sessionhelper.tokenHasExpired()) return;
        const back = await this.ttapi._get('messages');

        return (this.messageStack = A(back));
    }

    public get superiorReplacementMessageTypes() {
        return [
            'superior-end-of-replacement',
            'superior-start-of-replacement',
            'replacement-start-of-replacement',
            'replacement-end-of-replacement',
        ];
    }

    @action
    public closeModal() {
        this.markAsRead();
        notifyPropertyChange(this, 'messageStack');
    }

    @action
    public async addUserConsent(type: string) {
        await this.ttapi._post('userconsent', { type });
        return this.markAsRead();
    }

    @action
    public async markAsRead() {
        if (!this.currentMessage) return;

        //do not mark as read when impersonating
        if (this.session.session.content.authenticated.scope) {
            return this.messageStack.removeObject(this.currentMessage);
        }

        //mark as read
        await this.ttapi._post('messages/markasread_by_id/' + this.currentMessage.id);

        return this.messageStack.removeObject(this.currentMessage);
    }

    @action
    public markAsReadAndTransition() {
        this.markAsRead();
        if (this.Collector.testNeeds(['modules.timetracker', 'modules.absences'])) {
            return this.router.transitionTo({ queryParams: { wizard: 'onboarding-wizard' } });
        } else if (this.Collector.testNeeds(['products.mini_entry'])) {
            return this.router.transitionTo({ queryParams: { wizard: 'billing' } });
        }
    }
    @action
    public async execute(value: boolean | string) {
        if (value === false) {
            this.messageStack.removeObject(this.currentMessage);
            return this.closeModal();
        }

        await this.ttapi._post('messages/' + this.currentMessage.id, { value });

        return this.messageStack.removeObject(this.currentMessage);
    }

    @action
    public async closeModalAndRefresh() {
        await this.markAsRead();
        window.location.reload();
    }
}
