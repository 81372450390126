/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/location/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller;

import Ember from 'ember';

import config from '../../../config/environment';

Controller = Ember.Controller.extend({
    session: Ember.inject.service(),
    columnList: ['name', 'start', 'end', 'info'],
    translated_columns: Em.computed(function () {
        return ['name', 'start', 'end', 'info'];
    }),
    queryParams: ['date', 'page', 'order'],
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    limit: 10,
    page: 1,
    pageCount: Ember.computed('model.users.meta.count', function () {
        var limit;
        limit = this.get('limit') ? this.get('limit') : 25;
        return Math.ceil(this.get('model.users.meta.count') / limit);
    }),
    data: Em.computed('model.beaconData', function () {
        var arr, format, i, len, name, ref, retArr, row;
        retArr = [];
        format = moment.localeData().longDateFormat('L') + ' HH:mm:ss';
        ref = this.get('model.beaconData');
        for (name in ref) {
            arr = ref[name];
            if (arr.length > 0) {
                for (i = 0, len = arr.length; i < len; i++) {
                    row = arr[i];
                    retArr.pushObject(
                        Ember.Object.create({
                            name: name,
                            start: moment(row['start']).format(format),
                            end: moment(row['end']).format(format),
                            info: row['info'],
                        }),
                    );
                }
            } else {
                retArr.pushObject(
                    Ember.Object.create({
                        name: name,
                        start: null,
                        end: null,
                        info: null,
                    }),
                );
            }
        }
        return retArr;
    }),
    dateObs: Ember.observer('filterValues', function () {
        var date, values;
        values = this.get('filterValues');
        if (!values) {
            if (!values) {
                date =
                    moment().startOf('week').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('week').format('YYYY-MM-DD');
            }
        } else {
            date = Ember.get(values.findBy('name', 'date'), 'value');
        }
        return this.set('date', date);
    }),
    backendurl: Ember.computed(function () {
        return Ember.get(config, 'APP.BEACONBACKENDURL');
    }),
    partnerid: Ember.computed(function () {
        return this.get('session.currentUser.services.partnerid');
    }),
    actions: {
        catchActions: function (action, params) {
            return;
            // this doesn't work yet
            if (
                action === 'columnClicked' ||
                action === 'removeRow' ||
                action === 'changeStatus' ||
                action === 'checkRow' ||
                action === 'rowHistory' ||
                action === 'lockRows' ||
                action === 'deleteChecked' ||
                action === 'restoreChecked'
            ) {
                return this.send(action, params);
            } else {
                return this.sendAction(action, params, this.get('settings'));
            }
        },
        click: function (item) {
            if (item.info.split(' ')[0] === 'notregistered') {
                this.set('showBeaconInfo', true);
                return this.set('selectedBeacon', item);
            }
        },
        close: function () {
            return this.set('showBeaconInfo', false);
        },
        columnClicked: function (order) {
            var currentOrder;
            currentOrder = this.get('order');
            if (currentOrder === order || currentOrder === null) {
                currentOrder = order + '-';
            } else {
                currentOrder = order;
            }
            return this.set('order', currentOrder);
        },
    },
});

export default Controller;
