/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/load-translations.coffee.old
// Generated by CoffeeScript 2.6.1
var LoadTranslationsService;

import Ember from 'ember';

import config from '../config/environment';

LoadTranslationsService = Ember.Service.extend({
    intl: Ember.inject.service(),
    cdn: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    ajax: Ember.inject.service(),
    fetch: function (use_cache = false) {
        var lang, lang_cache, path, promise;
        lang = moment.locale();
        if (config.environment === 'test') {
            return;
        }
        path = this.get('cdn').url('crowdin/tt4/' + lang + '.json');
        promise = this.get('ajax').request(path, {
            dataType: 'json',
        });
        promise.then((trans) => {
            this.makeWordChanges(trans);
            this.makeBrandingWordChanges(trans, config.brand);
            this.formatTranslations(trans);
            if (trans) {
                localStorage.setItem('lang_cache', JSON.stringify(trans));
            }
            return this._addTranslations(trans);
        });
        lang_cache = localStorage.getItem('lang_cache');
        if (use_cache && lang_cache) {
            return this._addTranslations(JSON.parse(lang_cache));
        } else {
            return promise;
        }
    },
    termsSettingStr: Ember.computed('intl.locale', function () {
        return 'terms_' + moment.locale();
    }),
    getTermsIndex: function () {
        var saved;
        saved = this.get('customerSettings').checkSetting(this.get('termsSettingStr'));
        if (!saved) {
            return localStorage.getItem(this.get('termsSettingStr'));
        } else {
            localStorage.setItem(this.get('termsSettingStr'), saved);
            return saved;
        }
    },
    saveTermsIndex: function (index) {
        this.get('customerSettings').setSetting(this.get('termsSettingStr'), index);
        return localStorage.setItem(this.get('termsSettingStr'), index);
    },

    /**
     * This method is used to make branding changes to translations
     * @param {*} translations
     * @param {string} brand
     */
    makeBrandingWordChanges(translations, brand) {
        if (brand === 'default' && translations['user.userlevel.6.movenium']) {
            translations['user.userlevel.6'] = translations['user.userlevel.6.movenium'];
        }
    },

    makeWordChanges: function (arr) {
        var change, changeCounter, changes, changesArr, keys, skipThese, term;
        change = {};
        term = this.getTermsIndex();

        // we default to 0 if we are in finnish and brand is default ... this makes old fi-CON lang obsolete
        if (config.brand === 'default' && moment.locale() === 'fi' && term === null) {
            term = 0;
        }

        skipThese = ['terms.project'];
        if (term === null) {
            return;
        }
        changes = arr['terms.project'];
        if (!changes) {
            return;
        }
        changesArr = changes.split(/\n/g);
        changesArr.forEach((item) => {
            var termList;
            termList = item.split(/,/g);

            // terms 2...6 will translate both terms työmaa and projekti because there are both in translations.. but this is only for finnish
            if (term !== 0) change[termList[0]] = termList[term];
            if (term !== 1 && moment.locale() === 'fi') change[termList[1]] = termList[term];
        });
        changeCounter = 0;
        keys = Object.keys(arr);
        return keys.forEach((key) => {
            var str;
            str = arr[key];
            if (skipThese.indexOf(key) !== -1) {
                return;
            }
            if (Ember.typeOf(str) !== 'string') {
                return;
            }
            return (arr[key] = str.replace(
                new RegExp('\\b(' + Object.keys(change).join('|') + ')\\b', 'gi'),
                (item) => {
                    var replaceWith, startsWithUppercase;
                    changeCounter++;
                    startsWithUppercase = item[0] === item[0].toUpperCase();
                    replaceWith = change[item.toLowerCase()];
                    if (startsWithUppercase) {
                        replaceWith = replaceWith.charAt(0).toUpperCase() + replaceWith.slice(1);
                    }
                    return replaceWith;
                },
            ));
        });
    },
    // console.log "Changed " + changeCounter + " strings"
    formatTranslations: function (json) {
        var keys;
        keys = Object.keys(json);
        keys.forEach((key) => {
            var newStr, str;
            str = json[key];
            if (!str) return; // continue if translated text is null
            newStr = str.replace(new RegExp(/\{{(.*?)\}}/g), (item) => {
                return item.slice(1, -1);
            });
            return (json[key] = newStr);
        });
        return json;
    },
    _addTranslations: function (json) {
        var intl;
        intl = this.get('intl');
        return intl.addTranslations(moment.locale(), json);
    },
});

export default LoadTranslationsService;
