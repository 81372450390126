import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    gui: service(),
    store: service(),
    intl: service(),
    cdn: service(),

    currentPage: 1,

    currentPageImage: computed('currentPage', function () {
        return this.cdn.url(
            'images/dashboard/help/' + this.intl.primaryLocale + '/' + this.currentPage + '.png',
        );
    }),
});
