import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import initRoute from '../../../mixins/init-route';

export default Route.extend(initRoute, {
    ttapi: service(),
    flags: service(),
    collector: service('collector-service'),
    queryParams: {
        date: { refreshModel: true },
        page: { refreshModel: true },
        user: { refreshModel: true },
        employer: { refreshModel: true },
    },

    async model(params) {
        if (get(this, 'flags').test('bank-v2')) return await this.newModel(params);
        else return await this.oldModel(params);
    },

    actions: {
        refreshModel() {
            this.refresh();
        },
    },

    async newModel(params) {
        let banks = this.store.findAll('bank', { include: true });

        const query = ['limit=25', 'offset=' + (params.page ? (params.page - 1) * 25 : 0)];
        if (params.date) query.push('date=' + params.date);
        if (params.user) query.push('id=' + params.user);
        if (params.employer) query.push('employer=' + params.employer);

        let url = 'api/usersWithBanks' + (query.length ? '?' + query.join('&') : '');
        const data = await get(this, 'ttapi').request(url);
        return hash({
            users: data,
            accounts: await banks,
            pageCount: Math.ceil(data.count / 25),
        });
    },

    async oldModel(params) {
        const cols = ['user', 'employer'];
        const absencetypes = [];
        let model = this.store.findAll('bank', { include: true });
        let self = this;
        const data = await model;
        data.forEach((bank) => {
            cols.push(get(bank, 'column'));
            if (get(bank, 'absencetype')) absencetypes.push(get(bank, 'absencetype.id'));
        });
        const rowsPerPage = 25;
        params.limit = 25;
        if (params.page) {
            params.offset = (params.page - 1) * rowsPerPage;
        } else {
            params.offset = 0;
        }
        params.date = params.date
            ? params.date
            : moment().subtract(4, 'year').format('YYYY-MM-DD') +
              '_' +
              moment().format('YYYY-MM-DD');
        let worktimeQueryParams = {
            date: params.date,
            groupBy: 'user',
            cols: cols.join(','),
            sideload: true,
            limit: params.limit,
            offset: params.offset,
        };
        let absenceQueryParams = {
            startdate: params.date,
            groupBy: 'user,abcensetype',
            sideload: true,
            cols: 'user,abcensetype,absence_hours',
            abcensetype: absencetypes.join('||'),
            limit: 'all',
        };
        // add user-queryparameter only if user has filtered report based on user
        // empty user-parameter on capi-request returns empty results for userlevel != 5
        if (params.user) {
            worktimeQueryParams.user = params.user;
            absenceQueryParams.user = params.user;
        }
        if (get(self, 'collector').testNeedsOne('employer') && params.employer) {
            worktimeQueryParams.employer = params.employer;
            absenceQueryParams.employer = params.employer;
        }

        return hash({
            rows: self.store.query('worktime', worktimeQueryParams),
            absences: get(self, 'collector').testNeedsOne('abcense')
                ? self.store.query('abcense', absenceQueryParams)
                : [],
            accounts: model,
            year: moment(params.date?.split('_')[1]).format('YYYY'),
            lastyear: moment(params.date?.split('_')[1]).subtract(1, 'year').format('YYYY'),
        });
    },
});
