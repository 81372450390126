/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/employer-sums/route.coffee.old
// Generated by CoffeeScript 2.6.1
var PresencesEmployerSumsRoute;

import Ember from 'ember';

PresencesEmployerSumsRoute = Ember.Route.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
            replace: true,
        },
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        delete params.page;
        if (params.project === null) {
            delete params.project;
        }
        //        params.date =
        params.groupBy = 'employer';
        return this.Collector.cget('presence', params, true);
    },
});

export default PresencesEmployerSumsRoute;
