import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function formatMinutes(params, hash) {
    let hours = Math.abs(params[0] / 60);

    let minutes = Math.round((hours - Math.floor(hours)) * 60);

    if (hash && hash.showLeadingZeros) {
        hours = Math.floor(hours);
        if (hours < 0 && hours > -10) hours = '-0' + Math.abs(hours);
        else if (hours < 10 && hours >= 0) hours = '0' + hours;
    } else {
        hours = Math.floor(hours);
    }

    let value = hours + ':' + (minutes < 10 ? '0' : '') + minutes + 'h';

    if (params[0] < 0) value = '-' + value;
    if (hash && hash.hideIcon) return value;

    let icon = params[1] ? params[1] : 'Time';
    return new htmlSafe(`<span class="move move-${icon}"></span>` + value);
}

export default helper(formatMinutes);
