import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'tt4/config/environment';
import moment from 'moment';

export default Component.extend({
    ttapi: service(),
    session: service(),
    store: service(),
    error: service(),
    notifications: service(),
    intl: service(),
    cc: service(),
    Collector: service('collector-service'),

    dates: computed('date', function () {
        return { from: get(this, 'date').split('_')[0], to: get(this, 'date').split('_')[1] };
    }),

    actions: {
        export() {
            this.notifyStarted();
            if (get(this, 'close')) get(this, 'close')();
            const date = get(this, 'dates').from + '_' + get(this, 'dates').to;
            this.createRecord(date).then(this.callCC.bind(this, date));
        },

        valueChanged(value, field) {
            set(this, field, value);
        },
    },

    createRecord(date) {
        const name = 'vismanet-' + this.niceDate(date);

        const record = get(this, 'store').createRecord('export_salary', {
            date: date,
            export_description: get(this, 'export_description'),
            export_name: name,
        });
        return record.save();
    },

    niceDate(dates) {
        return (
            moment(dates.split('_')[0]).format('L') + '-' + moment(dates.split('_')[1]).format('L')
        );
    },

    callCC(date, record) {
        const data = {};
        if (config.environment === 'development') {
            data.capi_url = 'http://capi/';
            data.ttapi_url = 'http://ttapi/';
            data.cc_url = 'http://ccbackend/trigger/';
        } else {
            data.capi_url = config.APP.COLLECTOR_API_URL + '/';
            data.ttapi_url = config.APP.TTAPI + '/';
            data.cc_url = this.cc.host + '/';
        }

        data.token = this.session.data.authenticated.access_token;
        data.exportName = 'export-salary';
        data.exportid = get(record, 'id');
        data.date = date;
        data.throwErrors = true;

        if (get(this, 'Collector').testNeedsOne('products.severa')) {
            this.triggerIntegration(data, 'general-severa-hours');
        } else if (get(this, 'Collector').testNeedsOne('products.vismanet')) {
            this.triggerIntegration(data, 'vismanet');
        } else {
            throw new Error('Product vismanet or severa not found from service');
        }
    },

    notifyStarted() {
        get(this, 'notifications').info(get(this, 'intl').t('fastexport.exporting'));
    },

    notifyError(error) {
        get(this, 'notifications').clearAll();
        get(this, 'error').notify(error);
    },

    notifyDone() {
        get(this, 'notifications').clearAll();
        get(this, 'notifications').success(get(this, 'intl').t('fastexport.success'), {
            autoClear: true,
        });
    },
    triggerIntegration(data, key) {
        get(this, 'ttapi')
            .request('ccrequest/' + key, {
                // headers: {Authorization: "Bearer " + data.token}, TTAPI service already does this, why would we want to set it manually?
                method: 'POST',
                data: JSON.stringify(data),
            })
            .then(this.notifyDone.bind(this))
            .catch(this.notifyError.bind(this));
    },
});
