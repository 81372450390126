/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/array-input2.coffee.old
// Generated by CoffeeScript 2.6.1
var ObjectInputComponent;

import Ember from 'ember';

import { t } from 'ember-intl';

ObjectInputComponent = Ember.Component.extend({
    // Where the objects live
    objects: Em.A([]),
    // We have to convert to objects instead of plain array so we can bind in template
    placeholderObjects: Em.computed('objects.[]', function () {
        var i, len, object, objects, obs;
        objects = this.get('objects');
        obs = Em.A([]);
        for (i = 0, len = objects.length; i < len; i++) {
            object = objects[i];
            obs.push({
                value: object,
            });
        }
        return obs;
    }),
    obsPlacehoder: Em.observer('placeholderObjects.@each.value', function () {
        var i, k, len, obj, obs, results;
        obs = this.get('placeholderObjects');
        results = [];
        for (k = i = 0, len = obs.length; i < len; k = ++i) {
            obj = obs[k];
            results.push(this.set('objects.' + k, obj.value));
        }
        return results;
    }),
    // Save button text
    saveText: t('general.save'),
    // Add button text
    addText: t('general.add'),
    // You can disable all inputs and buttons
    disabled: false,
    actions: {
        // Add objects to our array
        add: function () {
            return this.get('objects').pushObject('');
        },
        // Remove objects from our array
        remove: function (obj, index) {
            return this.get('objects').removeObject(obj.value);
        },
        // Send save action with our array
        save: function () {
            if (this.get('save')) {
                return this.save(this.get('objects'));
            }
        },
    },
});

export default ObjectInputComponent;
