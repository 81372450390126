/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/gui.coffee.old
// Generated by CoffeeScript 2.6.1
var GuiService;

import Ember from 'ember';

import format from 'ember-moment/computeds/format';

import $ from 'jquery';

import config from 'tt4/config/environment';

GuiService = Ember.Service.extend({
    moment: Ember.inject.service(),
    fullScreen: false,
    partialFullScreen: false,
    overlay_loader: false,
    breakpoints: {
        md: 992,
    },
    stickyValue: 0,
    buttonText: 'default',
    showStickyNote: false,
    setBreakpointObserver: function (bp) {
        var breakPointGetter;
        Ember.assert('Supported breakpoints are md', bp === 'md');
        breakPointGetter = 'isBelow' + bp.charAt(0).toUpperCase() + bp.slice(1);
        return $(window).on('resize', () => {
            return this.set(
                breakPointGetter,
                document.documentElement.clientWidth < this.breakpoints[bp],
            );
        });
    },
    removeBreakpointObserver: function () {
        return $(window).off('resize');
    },
    isBelowMd: Em.computed(function () {
        return document.documentElement.clientWidth < this.breakpoints.md;
    }),
    togglefullScreen: function () {
        this.toggleProperty('fullScreen');
        return true;
    },
    toggleCustomFullScreen: function () {
        this.toggleProperty('customFullScreen');
        return true;
    },
    abort: function () {
        this.get('requestObject').abort();
        return Em.run.later(
            this,
            () => {
                return this.set('showStickyNote', false);
            },
            4000,
        );
    },
    getBrand: function () {
        return config.brand;
    },
    isSafari: !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/),
    isIE: Ember.computed(function () {
        var msie, ua;
        ua = window.navigator.userAgent;
        msie = ua.indexOf('MSIE ');
        if (msie.length > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        }
        return false;
    }),
    isAndroid: Ember.computed(function () {
        return typeof cordova !== 'undefined' && cordova !== null && device.platform === 'Android';
    }),
    isIOS: Ember.computed(function () {
        return typeof cordova !== 'undefined' && cordova !== null && device.platform === 'iOS';
    }),
    platform: Ember.computed(function () {
        return device.platform;
    }),
    isCordova: Ember.computed(function () {
        return typeof cordova !== 'undefined' && cordova !== null;
    }),
    isAndroidBrowser:
        navigator.userAgent.match(/Android/i) &&
        (typeof cordova === 'undefined' || cordova === null),
    // Check is mobile depending on screen width
    // http://caniuse.com/#feat=matchmedia
    isMobile: function (width = 760) {
        return window.matchMedia('only screen and (max-width: ' + width + 'px)').matches;
    },
    isMobileComputed: Ember.computed(function () {
        return this.isMobile();
    }),
    tzOffset: format(new Date(), 'Z'),
    sidebar: Ember.computed(function () {
        var state;
        state = localStorage.getItem('sidebar');
        if (state === null || state === 'open') {
            return false;
        } else if (state === 'collapsed' || state === void 0) {
            return true;
        }
    }),
    resetSidebar: function () {
        var state;
        state = localStorage.getItem('sidebar');
        if (state === null || state === 'open') {
            return this.set('sidebar', false);
        } else if (state === 'collapsed' || state === void 0) {
            return this.set('sidebar', true);
        }
    },
    version: function () {
        var buildtime, commit;
        buildtime = moment(config.APP.BUILD_TIMESTAMP).utc().format('YYYY.MM.DD');
        commit = config.APP.version.split('+')[1];
        return buildtime + '+' + commit;
    },
    version_timestamp: function () {
        return config.APP.BUILD_TIMESTAMP;
    },
});

export default GuiService;
