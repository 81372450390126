import Component from '@ember/component';
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    analytics: service(),

    settingsComponent: computed('currentSetting', function () {
        return 'dash-board/widgets/anomalities/settings/' + this.currentSetting.replace('_', '-');
    }),

    actions: {
        check(setting) {
            this.toggleCheckRow(setting);
        },
        saveSettings() {
            set(this, 'currentSetting', false);
        },
        closeModal() {
            set(this, 'currentSetting', false);
        },
        setCurrentSetting(setting) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Open ${setting} settings`,
                label: 'Anomalities widget',
            });
            set(this, 'currentSetting', setting);
        },
    },
});
