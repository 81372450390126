import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    intl: service(),
    dialogs: service(),
    analytics: service(),

    didReceiveAttrs() {
        set(this, 'help', this.store.findAll('help'));
    },

    actions: {
        save(row) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Save instruction',
                label: 'custom-help widget',
            });

            if (row.type === 'link' && !row.url) return;

            row.save();
            set(this, 'editRow', null);
        },

        create() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Create new clicked',
                label: 'custom-help widget',
            });
            set(this, 'editRow', this.store.createRecord('help', {}));
        },

        async remove(row) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Remove instruction',
                label: 'custom-help widget',
            });
            if (await this.dialogs.confirm(this.intl.t('general.remove_row'))) row.destroyRecord();
        },

        edit(row) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Edit instruction',
                label: 'custom-help widget',
            });
            set(this, 'show', null);
            set(this, 'editRow', row);
        },

        open(row) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Open instruction',
                label: 'custom-help widget',
            });
            if (row.type === 'link') window.open(this.addProtocol(row.url), '_blank');
            else set(this, 'show', row);
        },

        close() {
            if (get(this, 'editRow.isNew')) this.editRow.deleteRecord();
            set(this, 'editRow', null);
        },
    },

    addProtocol(url) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) return 'https://' + url;
        return url;
    },
});
