/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/details/route.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerDetailsRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

AdmintoolCustomerDetailsRoute = GeneralAddRoute.extend({
    controllerName: 'admintool.customer.details',
    afterSave: Em.computed('controller.model', function () {
        var model;
        model = this.get('controller.model');
        return {
            route: 'admintool.customer',
            params: model,
        };
    }),
    edit: true,
    model: function () {
        return this.modelFor('admintool.customer');
    },
});

export default AdmintoolCustomerDetailsRoute;
