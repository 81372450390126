import Component from '@ember/component';
import moment from 'moment';
import { inject } from '@ember/service';
import { get, set, computed } from '@ember/object';

export default Component.extend({
    blocks: inject(),
    data: null,

    _data: computed('data', function () {
        let first = true;
        let format = false;

        get(this, 'data').forEach((item) => {
            if (first) {
                if (item[0] === 'date') format = true;
                first = false;
            } else if (format) {
                item[0] = moment(item[0]).toDate();
            }
        });

        return get(this, 'data');
    }),

    options: computed('title', function () {
        return {
            title: get(this, 'title'),
        };
    }),

    didReceiveAttrs() {
        if (get(this, 'url')) {
            get(this, 'blocks')
                .request(get(this, 'url'), {
                    data: get(this, 'params'),
                })
                .then((data) => this._setData(data));
        }
    },

    _setData(data) {
        set(this, 'data', data);
    },
});
