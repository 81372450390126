export default {
    pluralForm: function englishWithZero(n) {
        if (n === 0) {
            return 'zero';
        }
        if (n === 1) {
            return 'one';
        }
        return 'other';
    },
    rtl: false,
};
