/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/wt-service.coffee.old
// Generated by CoffeeScript 2.6.1
var Service;

import Ember from 'ember';

Service = Ember.Service.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    params: {
        date:
            moment().subtract(1, 'day').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
        endtime: '(empty)',
        user: 'self',
        sideload: true,
    },
    unlessOpenWt: function (site) {
        if (this.get('Collector').testNeedsOne('products.timetracker_clockcard')) {
            return this.get('store')
                .query('worktime', this.params)
                .then((data) => {
                    var openWt;
                    openWt = data.get('firstObject');
                    this.set('openWt', openWt);
                    if (openWt) {
                        if (site) {
                            site = Em.Object.create(site);
                            if (openWt.get('project.id') !== site.get('id')) {
                                return Em.RSVP.Promise.reject();
                            }
                        } else {
                            return Em.RSVP.Promise.reject();
                        }
                    }
                });
        } else {
            return Em.RSVP.Promise.resolve();
        }
    },
    showOpenWtError: function (msg) {
        var message;
        message =
            msg ||
            this.get('intl').t('mysites.open_worktime_error') +
                ' ' +
                this.get('openWt.project.name');
        return this.get('dialogs').alert(message);
    },
});

export default Service;
