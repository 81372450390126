/* eslint-disable ember/no-observers */
import Controller, { inject as controller } from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed, observer, set } from '@ember/object';
import { A } from '@ember/array';
import { isEmpty, typeOf } from '@ember/utils';

const ImportMapController = Controller.extend({
    mainController: controller('development.import'),
    intl: service(),
    Collector: service('collector-service'),
    headerRow: alias('mainController.hasHeaderRow'),
    usedFields: A([]),
    content: alias('model'),
    mappers: alias('mainController.mappers'),
    form: alias('mainController.form'),
    formFields: computed('mainController.form', function () {
        const c = this.Collector;
        const fields = c.fieldArray(this.mainController.form);
        const fieldOptions = A([]);
        fields.forEach((field) => {
            return fieldOptions.pushObject({
                id: field.name,
                name: field.translated_name,
                mandatory: field.features.mandatory,
            });
        });
        //        return [
        //            id: "firstname"
        //            name: "First name"
        //        ,
        //            id: "lastname"
        //            name: "Last name"
        //        ,
        //            id: "desc"
        //            name: "Description"
        //        ]
        return fieldOptions;
    }),
    mandatoryFields: computed('formFields', function () {
        return this.formFields.filterBy('mandatory');
    }),
    schemaFields: computed('mainController.file', function () {
        const file = this.mainController.file;
        if (isEmpty(file) || isEmpty(file[0])) {
            this.transitionToRoute('development.import.index');
        }
        //        return ["First name","Last name","Employer","Number"]
        return file[0];
    }),

    cfields: observer('mappers.@each.to', function () {
        const c = this.Collector;
        const form = this.form;
        const file = this.mainController.file;
        const intl = this.intl;
        return this.mappers.forEach((mapper) => {
            let from;
            if (!mapper.to || !mapper.from) {
                return;
            }
            if (mapper.maps.length > 0) {
                return;
            }
            const cf = c.field(form, mapper.to);
            if (
                !['dropdown_values', 'dropdown_userlevel', 'status', 'dropdown_options'].includes(
                    cf.type,
                )
            ) {
                return;
            }
            const froms = file.mapBy(mapper.from).uniq();
            if (this.headerRow) {
                froms.shiftObject();
            }
            for (let index = 0; index < froms.length; index++) {
                from = froms[index];
                if (!from || from === '') {
                    continue;
                }
                mapper.options.from.pushObject(from);
                mapper.maps.pushObject({ from: null, to: null, pk: false });
            }
            return (() => {
                const result = [];
                for (let option of Array.from(cf.options)) {
                    if (typeOf(option) === 'object') {
                        option.name = intl.t(form + '.' + cf.name + '.' + option.value);
                    } else {
                        const new_option = {
                            id: option,
                            name: intl.t(form + '.' + cf.name + '.' + option),
                        };
                        option = new_option;
                    }
                    result.push(mapper.options.to.pushObject(option));
                }
                return result;
            })();
        });
    }),

    actions: {
        addMapper() {
            return this.mappers.pushObject({
                from: null,
                to: null,
                pk: false,
                maps: A([]),
                options: { from: A([]), to: A([]) },
            });
        },

        valueChanged(field, old, type, number) {
            if (type === 'to') {
                let needle, needle1;
                if (((needle = old), Array.from(this.usedFields).includes(needle))) {
                    this.usedFields.removeObject(old);
                }
                if (field && ((needle1 = field), !Array.from(this.usedFields).includes(needle1))) {
                    this.usedFields.pushObject(field);
                }
            }
            set(this, 'mappers.' + number + '.' + type, field);
            return false;
        },

        valueChangedMaps(field, old, type, map) {
            map[type] = field;
            return false;
        },

        removeMapper(mapper) {
            return this.mappers.removeObject(mapper);
        },
    },
});

export default ImportMapController;
