/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/databasearray-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDatabasearrayFieldComponent;

import Ember from 'ember';

import {
    //import baseField from '../base-field/component'
    copy,
} from 'ember-copy';

CollectorFieldsDatabasearrayFieldComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    //#######
    // THIS CURRENTLY ONLY WORKS WITH USER FIELD
    // IF YOU WANT TO USE ELSEWHERE THEN MAKE IT WORK!!!!
    //######
    loadValues: Ember.computed('model', function () {
        var c, hasMany;
        c = this.get('Collector');
        if (c.fieldExists(this.get('field.form'), this.get('field.name'))) {
            hasMany = this.get('model').hasMany(this.get('field.name')).ids();
            if (hasMany.length > 0) {
                return this.get('store').query('user', {
                    field: this.get('field.name'),
                    form: this.get('field.form'),
                    id: hasMany.join(','),
                });
            }
        }
    }),
    databaseField: Ember.computed('field', function () {
        var field;
        field = copy(this.get('field'));
        field.value = void 0;
        return field;
    }),
    actions: {
        setValue: function (value) {
            this.get('model.' + this.get('field.name')).pushObject(value);
            this.set('databaseField.value', void 0);
            this.set('showDropdown', false);
            return this.sendAction(
                'bubbleChange',
                this.get('model.' + this.get('field.name')),
                this.get('field.name'),
                false,
            );
        },
        removeItem: function (value) {
            this.get('model.' + this.get('field.name')).removeObject(value);
            this.set('showDropdown', false);
            return this.sendAction(
                'bubbleChange',
                this.get('model.' + this.get('field.name')),
                this.get('field.name'),
                false,
            );
        },
        addValue: function () {
            this.toggleProperty('showDropdown');
            return false;
        },
    },
});

export default CollectorFieldsDatabasearrayFieldComponent;
