/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/event/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyEventRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

import { inject as service } from '@ember/service';

SiteSafetyEventRoute = GeneralReportRoute.extend({
    form: 'site_safety_event',
    capi: service(),
    flags: service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        creator: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            params.sideload = true;
            if (params.status === 'all') {
                delete params.status;
            }
            if (Ember.isPresent(params.groupBy)) {
                // This can be moved to mixin when wanted in other places also
                params.order = params.groupBy;
                return this.get('capi').findAll('site_safety_event', params);
            } else {
                return this.store.query('site_safety_event', params);
            }
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('site-safety.report.event').set('qParams', params);
        }
    },
});

export default SiteSafetyEventRoute;
