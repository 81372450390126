/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/chart-comp.coffee.old
// Generated by CoffeeScript 2.6.1
var ChartComponent;

import Ember from 'ember';

import $ from 'jquery';

import { getAssetFolder, getScriptsInOrder } from 'tt4/utils/getScript';

ChartComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    layoutName: 'components/chart',
    name: 'chart',
    id: null,
    data: null,
    // with this chart can fetch its data directly over api
    datapoint: null,
    // when using datapoint api request params can be setted with filter (ie. {test: 'value'})
    filters: null,
    two_series: false,
    export_pdf: false,
    showExport: Ember.computed(function () {
        var exports;
        exports = [];
        if (typeof cordova === 'undefined' || cordova === null) {
            exports.push('pdf');
        }
        return exports;
    }),
    didInsertElement: function () {
        if (this.get('datapoint')) {
            return this.fetch_data();
        } else {
            return this.drawChart();
        }
    },
    fetch_data: function () {
        var datapoint, filter_arr, filters, self, url;
        datapoint = this.get('datapoint');
        if (!datapoint) {
            return;
        }
        filters = this.get('filters');
        url = this.get('api_url') + datapoint;
        if (filters) {
            filter_arr = [];
            filters.forEach(function (item) {
                return filter_arr.push(item.key + '=' + item.value);
            });
            url += '?' + filter_arr.join('&');
        }
        self = this;
        return $.ajax({
            url: url,
            type: 'GET',
            //headers: {'Authorization': 'Bearer '+localStorage.access_token}
        }).done(function (data) {
            return self.set('data', data);
        });
    }.observes('datapoint', 'filters'),
    // draw chart or multiple chart when ever property data changes
    drawChart: function () {
        var container, data, parsed, raw_data, type;
        raw_data = this.get('data');
        if (typeof raw_data === 'string') {
            raw_data = JSON.parse(raw_data);
        }
        if (!raw_data) {
            return;
        }
        // Instantiate and draw our chart, passing in some options
        container = this.get('usecontainer') || $('div.chart', this.element);
        if ('chart_type' in raw_data) {
            parsed = this.parse_data(raw_data.data, 'nottable');
            this.draw_single_chart(container.get(0), raw_data.chart_type, parsed.google_data);
            return;
        }
        type = this.get('type');
        data = this.parse_data(raw_data, type);
        if (data.constructor !== Array) {
            return this.draw_single_chart(container.get(0), type, data.google_data);
        } else {
            return this.drawMultipleCharts(container, data);
        }
    }.observes('data'),
    // render multiple charts if data contains array
    drawMultipleCharts: function (container, data) {
        var self;
        self = this;
        container.empty();
        return $.each(data, function (key, item) {
            var subcontainer;
            if (item.header) {
                $('<h2>' + item.header + '</h2>').appendTo(container);
            } else {
                $('<br><br>').appendTo(container);
            }
            subcontainer = $('<div class="' + key + '"></div>');
            subcontainer.appendTo(container);
            return self.draw_single_chart(subcontainer.get(0), item.type, item.google_data);
        });
    },
    // call the actual google visulization draw method for every chart
    draw_single_chart: function (container, type, data) {
        var chart, colors_arr, columns, i, linecolors, multiple_series, options, self, series;
        switch (type) {
            case 'table':
                options = {
                    cssClassNames: {
                        tableCell: 'reportCell',
                    },
                };
                chart = new google.visualization.Table(container);
                break;
            case 'pie':
                if (this.get('options')) {
                    options = this.get('options');
                } else {
                    options = {
                        //is3D: true
                        chartArea: {
                            width: '100%',
                            height: '100%',
                        },
                        height: 350,
                    };
                }
                chart = new google.visualization.PieChart(container);
                if (this.get('onSelect'))
                    google.visualization.events.addListener(chart, 'select', () => {
                        var selectedItem = chart.getSelection()[0];
                        this.get('onSelect')(
                            selectedItem.row,
                            this.get('data')[selectedItem.row + 1][0],
                        );
                    });
                break;
            case 'bars':
                options = {
                    //is3D: true
                    //chartArea:{left:0,top:0,width:"100%",height:"100%"}
                    height: 350,
                };
                chart = new google.visualization.BarChart(container);
                break;
            case 'column':
                options = {
                    //is3D: true
                    //chartArea:{left:0,top:0,width:"100%",height:"100%"}
                    height: 350,
                };
                chart = new google.visualization.ColumnChart(container);
                break;
            case 'tree':
                chart = new google.visualization.OrgChart(container);
                options = {
                    size: 'large',
                };
                break;
            //                    nodeClass: 'org-chart-node'
            case 'gauge':
                chart = new google.visualization.Gauge(container);
                if (this.get('options')) {
                    options = this.get('options');
                } else {
                    options = {
                        width: 350,
                        height: 150,
                    };
                }
                break;
            default:
                columns = [];
                series = {};
                i = 0;
                while (i < data.getNumberOfColumns()) {
                    columns.push(i);
                    if (i > 0) {
                        series[i - 1] = {};
                    }
                    i++;
                }
                linecolors = [
                    '#3366cc',
                    '#dc3912',
                    '#ff9900',
                    '#109618',
                    '#990099',
                    '#0099c6',
                    '#dd4477',
                    '#66aa00',
                    '#b82e2e',
                    '#316395',
                    '#994499',
                    '#22aa99',
                    '#aaaa11',
                    '#6633cc',
                    '#e67300',
                    '#8b0707',
                    '#651067',
                    '#329262',
                    '#5574a6',
                    '#3b3eac',
                    '#b77322',
                    '#16d620',
                    '#b91383',
                    '#f4359e',
                    '#9c5935',
                    '#a9c413',
                    '#2a778d',
                    '#668d1c',
                    '#bea413',
                    '#0c5922',
                    '#743411',
                ];
                if (this.get('options')) {
                    options = this.get('options');
                } else {
                    options = {
                        height: 350,
                        width: '100%',
                        colors: [
                            '#3366cc',
                            '#dc3912',
                            '#ff9900',
                            '#109618',
                            '#990099',
                            '#0099c6',
                            '#dd4477',
                            '#66aa00',
                            '#b82e2e',
                            '#316395',
                            '#994499',
                            '#22aa99',
                            '#aaaa11',
                            '#6633cc',
                            '#e67300',
                            '#8b0707',
                            '#651067',
                            '#329262',
                            '#5574a6',
                            '#3b3eac',
                            '#b77322',
                            '#16d620',
                            '#b91383',
                            '#f4359e',
                            '#9c5935',
                            '#a9c413',
                            '#2a778d',
                            '#668d1c',
                            '#bea413',
                            '#0c5922',
                            '#743411',
                        ],
                    };
                }
                if (this.get('multiple_series')) {
                    multiple_series = this.get('multiple_series');
                    options.vAxes = multiple_series.vAxes;
                    options.series = multiple_series.series;
                    options.legend = {
                        position: 'top',
                        textStyle: {
                            fontSize: 14,
                        },
                    };
                }
                options.chartArea = {
                    left: 25,
                    top: 10,
                    width: '75%',
                    height: '80%',
                };
                chart = new google.visualization.LineChart(container);
                self = this;
                google.visualization.events.addListener(chart, 'select', function () {
                    var col, sel, show_columns, view;
                    if (self.get('selectionHandler')) {
                        return self.selectionHandler(chart.getSelection());
                    } else {
                        sel = chart.getSelection();
                        show_columns = [];
                        if (sel.length > 0) {
                            // if row is null, we clicked on the legend
                            if (sel[0].row === null) {
                                col = sel[0].column;
                                if (options.colors[col - 1] !== '#CCC') {
                                    options.colors[col - 1] = '#CCC';
                                } else {
                                    options.colors[col - 1] = linecolors[col - 1];
                                }
                                view = new google.visualization.DataView(data);
                                view.setColumns(columns);
                                chart.draw(view, options);
                            }
                        }
                    }
                });
        }
        if (this.get('selectionHandler')) {
            options.animation = {};
        } else if (type === 'column') {
            options.animation = {
                startup: true,
                duration: 200,
            };
        } else {
            options.animation = {
                startup: true,
                duration: 1000,
            };
        }
        if (options.hAxis == null) {
            options.hAxis = {};
        }
        if (this.get('Xmax')) {
            options.hAxis.maxValue = this.get('Xmax');
        }
        if (this.get('Xmin')) {
            options.hAxis.minValue = this.get('Xmin');
        }
        if (options.vAxis == null) {
            options.vAxis = {};
        }
        if (this.get('Ymax')) {
            options.vAxis.maxValue = this.get('Ymax');
        }
        if (this.get('Ymin')) {
            options.vAxis.minValue = this.get('Ymin');
        }
        //options.backgroundColor = '#E4E4E4'
        if (this.get('colors')) {
            colors_arr = this.get('colors').split(',');
            options.colors = colors_arr;
        }
        this.set('options', options);
        this.set('drawnData', data);
        chart.draw(data, options);
        return this.set('chart', chart);
    },
    // parse raw data returned from api to format which can be used to call google visulization
    // format for single chart is {columns: {...}, rows: [...]}
    // format when returning multiple charts is [{header: ..., columns: {...}, rows: [...]},...]
    parse_data: function (raw_data, default_type) {
        var self, temp;
        if (raw_data.constructor !== Array || default_type !== 'table') {
            return this.get_google_chart_data(default_type, raw_data);
        }
        // else contains many tables
        self = this;
        temp = [];
        $.each(raw_data, function (index, item) {
            return temp.push(self.get_google_chart_data(default_type, item));
        });
        return temp;
    },
    // parse one chart data for google visualization
    get_google_chart_data: function (default_type, raw_data) {
        var google_data, type;
        type = 'type' in raw_data ? raw_data.type : default_type;
        if (type === 'table') {
            google_data = this.parse_single_table(raw_data);
        } else {
            google_data = google.visualization.arrayToDataTable(raw_data);
        }
        return {
            header: raw_data.header,
            type: type,
            google_data: google_data,
        };
    },
    // parses table data for google visualization
    parse_single_table: function (raw_data) {
        var data, dk, drow, format, j, k, l, len, len1, len2, m, r, real_rows, ref, ref1, row, self;
        self = this;
        data = new google.visualization.DataTable();
        Ember.assert('table data contains no columns', raw_data.columns);
        $.each(raw_data.columns, function (index, item) {
            var t;
            if (item.type === 'int' || item.type === 'percent') {
                t = 'number';
            } else {
                t = item.type;
            }
            return data.addColumn(t, self.get('intl').t('chart.' + item.text));
        });
        Ember.assert('table data contains no rows', raw_data.rows);
        real_rows = [];
        ref = raw_data.rows;
        for (k = j = 0, len = ref.length; j < len; k = ++j) {
            row = ref[k];
            r = [];
            ref1 = raw_data.columns;
            for (dk = l = 0, len1 = ref1.length; l < len1; dk = ++l) {
                drow = ref1[dk];
                if (drow.type === 'number') {
                    r[dk] = {
                        v: parseFloat(row[dk]),
                        f: row[dk],
                    };
                } else if (drow.type === 'int') {
                    r[dk] = {
                        v: parseFloat(row[dk]),
                        f: row[dk],
                    };
                } else if (drow.type === 'percent') {
                    format = '';
                    if (row[dk]) {
                        format = row[dk] + ' %';
                    }
                    r[dk] = {
                        v: parseFloat(row[dk]),
                        f: format,
                    };
                } else {
                    r[dk] = row[dk];
                }
            }
            real_rows.push(r);
        }
        data.addRows(real_rows);
        for (k = m = 0, len2 = real_rows.length; m < len2; k = ++m) {
            row = real_rows[k];
            data.setProperty(k, 0, 'className', 'tableCellFixedWidth');
        }
        return data;
    },
    willDestroyElement: function () {
        if (this.get('chart') && Ember.typeOf(this.get('chart').clearChart) === 'function') {
            return this.get('chart').clearChart();
        }
    },
    actions: {
        redraw: function () {
            return this.drawChart();
        },
        save_pdf: function (param) {
            var assetsfolder, chart, docDefinition;
            this.set('showLoader', true);
            chart = this.get('chart');
            docDefinition = {
                content: {
                    image: chart.getImageURI(),
                    width: 800,
                },
                pageSize: 'A4',
                pageOrientation: 'landscape',
                pageMargins: [0, 20, 0, 0],
            };
            assetsfolder = getAssetFolder('assets');
            return getScriptsInOrder([
                assetsfolder + 'pdfmake_v0.1.68.min.js',
                assetsfolder + 'vfs_fonts_v0.1.68.min.js',
            ]).then(() => {
                createPdf(docDefinition).download('export.pdf');
                return this.set('showLoader', false);
            });
        },
    },
});

export default ChartComponent;
