import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import config from 'tt4/config/environment';

/*

    How to construct an onboarding wizard page:
    ember g component wizard-builder/onboarding-wizard/pages/<pageName> --pod

    template.hbs:
    {{#wizard-builder/onboarding-wizard/components/onboarding-wizard-page as |page|}}

        {{#page.header as |header|}}
            {{#header.icon}}
                {{party-parrot}}
            {{/header.icon}}
            {{#header.title}} {{!-- big blue text --}}
                {{t "wizard.onboarding.<pageName>.header_title"}}
            {{/header.title}}
            {{#header.text}} {{!-- smaller gray text --}}
                {{t "wizard.onboarding.<pageName>.header_text"}}
            {{/header.text}}
        {{/page.header}}

        {{#page.content-container as |container| }}
            {{!-- Content can basically be anything you want, just write it here --}}

            {{!-- Add as many setting cards as you need --}}
            {{#container.setting-card as |card|}}

                {{#card.title as |cardTitle|}}
                    {{cardTitle.text text=(t "wizard.onboarding.somethingTitle")}}
                    {{cardTitle.toggle toggled=somethingOnThisPageToggled}}
                    {{cardTitle.info-button}}
                {{/card.title}}

                {{!-- disabled will gray out the content, this should be used only if you add toggle to title --}}
                {{#card.content disabled=(not somethingOnThisPageToggled) as |content|}}

                    {{!-- basic list of things --}}
                    {{#content.setting-list}}

                        <li> items here </li>
                        <li>
                        {{!-- custom input field}}
                        {{content.input
                            disabled=(not somethingOnThisPageToggled)
                            value=valueForThisField
                            type="text"
                        }}
                        </li>
                    {{/content.setting-list}}

                    {{!-- nicely :) styled radio buttons --}}
                    {{#content.radio-group}}
                        {{#radio-button value="someValue" groupValue=radioGroupvalue changed=(action "groupValueChanged")}}
                            <div class="label pull-left"></div><div class="inline pull-right">{{t "wizard.onboarding.somePage.setting.someSetting"}}</div>
                        {{/radio-button}}
                        {{!-- add as many radio buttons as you need}}
                    {{/content.radio-group}}

                    {{!-- table for showing form rows}}
                    {{#content.table form=form columns=columns rows=rows as |table|}}
                        {{table.header}}
                        {{table.rows}}
                    {{/content.table}}

                    {{!-- modal for adding new records --}}
                    <button class="btn btn-primary add-saldo-absence-type-button" {{action 'openModal'}}>{{t "wizard.onboarding.somePage.addSomething"}}</button>
                    {{#page.mini-modal header=(t "wizard.onboarding.somePage.add_new") showModal=openModal save=(action (mut openModal))}}
                        {{#collector/form-add only="name,otherfield" form="form" showSaved=false record=thisRecord close=(action (mut openModal)) as |form|}}
                            {{#form.fields as |field|}}
                                {{field.container}}
                            {{/form.fields}}
                            {{form.buttons}}
                        {{/collector/form-add}}
                    {{/page.mini-modal}}

                {{/card.content}}

            {{/container.setting-card}}

        {{/page.content-container}}

    {{/wizard-builder/onboarding-wizard/components/onboarding-wizard-page }}




*/

export default Component.extend({
    Collector: service('collector-service'),
    store: service(),
    capi: service(),
    ttapi: service(),
    dialogs: service(),
    intl: service(),
    initapp: service(),
    analytics: service(),
    welcomeTour: service(),
    localStorageWrapper: service(),
    currentPage: 1,

    somethingHasBeenChanged: false,
    loading: false,

    currentPageComponent: computed('currentPage', function () {
        let currentPage = get(this, 'currentPage');
        return 'wizard-builder/onboarding-wizard/pages/' + get(this, 'pages')[currentPage - 1];
    }),

    TranslatedCurrentPageHeader: computed('currentPage', function () {
        if (get(this, 'pageNames')) {
            let pages = get(this, 'pageNames').map((name) => {
                return 'wizard.page.' + name;
            });
            return pages[get(this, 'currentPage') - 1];
        }
        return null;
    }),

    hideNext: computed('currentPage', function () {
        return get(this, 'currentPage') <= 1;
    }),
    showStartButton: computed('currentPage', function () {
        return get(this, 'currentPage') <= 1;
    }),

    pageNames: computed('pages', function () {
        let pages = get(this, 'pages');
        return pages.map((page) => 'onboarding.' + page);
    }),

    init() {
        this._super();
        let pages = [
            'welcome',
            'timetracker-settings',
            'salary-settings',
            'overtime-settings',
            'saldo-settings',
            'project-settings',
            'summary',
        ];
        if (this.Collector.testNeeds(['serviceCountry@se'])) {
            // Remove overtime settings page from Swedish services
            pages = pages.filter((item) => item !== 'overtime-settings');
        }

        this.pages = pages;
        this.settings = {
            calculateIfOvertimeEvening: false,
            calculateIfOvertimeNight: false,
            daily50: '8',
            daily100: '10',
            dailyOvertime: false,
            editTime: 0,
            eveningEnd: '21:00',
            eveningStart: '16:00',
            kilokm_typemeterProduct: false,
            nightEnd: '06:00',
            nightStart: '21:00',
            overtimeCalculateAlways: false,
            overtimeTimebased: false,
            overtimehours: false,
            pause: null,
            perdiem: null,
            saldoAutocutter: false,
            saldoMaximum: '40',
            saldoMinimum: '20',
            saldoDailymaximum: '4',
            saldoProduct: false,
            splitOvertimehours: false,
            timeAddingType: 'hybrid',
            weeklyMaximum: '8',
            weeklyOvertime: false,
            saldoStart: moment().format('YYYY-MM-DD'),
            product: config.brand == 'visma' || config.brand == 'otta' ? 'Otta' : 'Visma Movenium', // TODO: Remove in EP-11985
        };
    },

    actions: {
        startWizard() {
            this.incrementProperty('currentPage');
        },
        collectSettingsToObject(setting, value) {
            set(this, 'somethingHasBeenChanged', true);
            set(this, 'settings.' + setting, value);
        },
        async executeSettings() {
            get(this, 'analytics').trackEvent({
                category: 'Onboarding-wizard',
                action: 'executeSettings',
            });
            set(this, 'loading', true);
            await get(this, 'store').findAll('form', { reload: true });
            await this.executeTimetrackingPage();
            await this.exucuteSalarySettingsPage();
            await this.exucuteOvertimeSettingsPage();
            await this.executeSaldoSettingsPage();

            await get(this, 'capi').request('forms'); // make /forms request to make all the settings on

            get(this, 'initapp').clearInit();
            // We wan't to launch the wootric survay after the reload so need to put this into localstorage
            localStorage.setItem('survey', 'wizard');
            this.startTourIfNeeded(true);
            await this.close();
            location.reload();
        },
        async closingWizard() {
            let currentPageName = get(this, 'currentPageComponent').split('/')[3];
            get(this, 'analytics').trackEvent({
                category: 'Wizard-closing-page',
                action: currentPageName,
            });

            if (get(this, 'somethingHasBeenChanged')) {
                const executeChanges = await get(this, 'dialogs').confirm(
                    get(this, 'intl').t('wizard.onboarding.close'),
                );
                if (executeChanges) this.send('executeSettings');
            } else {
                this.close();
                this.startTourIfNeeded();
            }
        },
    },

    async executeTimetrackingPage() {
        //time adding types
        const addingTypes = {
            startandend: [
                '!products.timetracker_hybrid',
                '!products.timetracker_hours',
                '!products.timetracker_clockcard',
            ],
            hours: [
                '!products.timetracker_hybrid',
                'products.timetracker_hours',
                '!products.timetracker_clockcard',
            ],
            hybrid: [
                'products.timetracker_hybrid',
                '!products.timetracker_hours',
                '!products.timetracker_clockcard',
            ],
            clockcard: [
                '!products.timetracker_hybrid',
                '!products.timetracker_hours',
                'products.timetracker_clockcard',
            ],
        };
        await get(this, 'Collector').setProducts(addingTypes[get(this, 'settings.timeAddingType')]);

        //date interval setting
        await this.setFormFieldSetting(
            'worktime',
            'date',
            'date_interval',
            get(this, 'settings.editTime'),
        );

        //pause setting
        if (get(this, 'settings.pause')) {
            await Promise.all(get(this, 'Collector').setProducts(['products.pause']));
            await get(this, 'capi').request('forms'); // make /forms request to make module_handler to update forms
            await get(this, 'store').findAll('form', { reload: true });
            await this.setFormFieldSetting(
                'worktime',
                'pause',
                'default_value',
                get(this, 'settings.pause'),
            );
        } else {
            await get(this, 'Collector').setProducts(['!products.pause']);
        }
    },

    async exucuteSalarySettingsPage() {
        // salary-perdiem
        const perdiemProducts = {
            none: ['!products.timetracker_perdiem', '!products.timetracker_perdiem_meal'],
            def: ['products.timetracker_perdiem', '!products.timetracker_perdiem_meal'],
            meal: ['!products.timetracker_perdiem', 'products.timetracker_perdiem_meal'],
        };
        const perdiem = get(this, 'settings.perdiem') ? get(this, 'settings.perdiem') : 'none';
        await get(this, 'Collector').setProducts(perdiemProducts[perdiem]);

        // km_type
        const km_typeProducts = {
            none: ['!products.timetracker_km', '!products.timetracker_kmsade'],
            km: ['products.timetracker_km', '!products.timetracker_kmsade'],
            kmsade: ['!products.timetracker_km', 'products.timetracker_kmsade'],
            kmandsade: ['products.timetracker_km', 'products.timetracker_kmsade'],
        };
        const km_type = get(this, 'settings.km_type') ? get(this, 'settings.km_type') : 'none';
        await get(this, 'Collector').setProducts(km_typeProducts[km_type]);
    },

    async exucuteOvertimeSettingsPage() {
        const rules = [];
        const products = [];

        // dailyOvertime
        if (get(this, 'settings.dailyOvertime')) {
            products.push('products.overtime_daily');

            const daily50 = '' + get(this, 'settings.daily50');
            const daily100 = '' + get(this, 'settings.daily100');
            rules.push({ name: '_50', values: { from: daily50, to: daily100, salarytypes: '' } });
            rules.push({ name: '_100', values: { from: daily100, salarytypes: '' } });
        }

        // weeklyOvertime
        if (get(this, 'settings.weeklyOvertime')) {
            products.push('products.overtime_weekly');
            const weeklyOvertime = get(this, 'settings.weeklyMaximum');

            rules.push({
                name: '_week50',
                values: { from: 0, to: weeklyOvertime, salarytypes: '' },
            });
            rules.push({ name: '_week100', values: { from: weeklyOvertime, salarytypes: '' } });
        }

        // overtime_time_based
        if (get(this, 'settings.overtimeTimebased')) {
            products.push('products.overtime_weekly');

            const evening = {
                name: '_evening',
                values: {
                    from: get(this, 'settings.eveningStart'),
                    to: get(this, 'settings.eveningEnd'),
                    salarytypes: '',
                },
            };
            if (!get(this, 'settings.calculateIfOvertimeEvening'))
                evening.values.do_not_calculate_if_overtime = true;
            const night = {
                name: '_night',
                values: {
                    from: get(this, 'settings.nightStart'),
                    to: get(this, 'settings.nightEnd'),
                    salarytypes: '',
                },
            };
            if (!get(this, 'settings.calculateIfOvertimeNight'))
                night.values.do_not_calculate_if_overtime = true;
            if (get(this, 'settings.overtimeCalculateAlways')) {
                evening.values.calculate_compensations_always = true;
                night.values.calculate_compensations_always = true;
            }

            rules.push(evening, night);
        }

        //overtime_splitted
        if (get(this, 'settings.splitOvertimehours')) {
            products.push('products.overtime_splitted');
            rules.push({ name: '_overtimehours', values: { salarytypes: '' } });
            rules.push({ name: '_basichours', values: { salarytypes: '' } });
        }

        // save overtime rules
        if (rules.length > 0) {
            await get(this, 'ttapi').request('overtime/settings', {
                method: 'post',
                data: { rules: JSON.stringify(rules) },
            });
        }

        // set products on
        if (products.length > 0) {
            products.push('products.overtime');
            await get(this, 'Collector').setProducts(products);
        }
    },

    async executeSaldoSettingsPage() {
        if (get(this, 'settings.saldoProduct')) {
            let saldoAbsence = await this.createSaldoAbsence();

            const saldoSetOn = get(this, 'Collector').setProducts(['products.saldo']);

            const saldoSettingOK = get(this, 'ttapi').request('saldo/saldoSettings', {
                method: 'post',
                data: {
                    startdate: get(this, 'settings.saldoStart'),
                    minimum: parseInt(get(this, 'settings.saldoMinimum'), 10) * 60,
                    maximum: parseInt(get(this, 'settings.saldoMaximum'), 10) * 60,
                    dailymaximum: parseFloat(get(this, 'settings.saldoDailymaximum'), 10) * 60,
                    saldo_absence_id: get(saldoAbsence, 'id'),
                    auto_cut: get(this, 'settings.saldoAutocutter'),
                },
            });

            // wait for both to resolve
            await saldoSetOn;
            await saldoSettingOK;
        }
    },
    async createSaldoAbsence() {
        let saldoAbsences = await get(this, 'store')
            .peekAll('abcensetype')
            .filterBy('name', get(this, 'intl').t('saldo.saldo_absence'));

        if (isEmpty(saldoAbsences)) {
            return await get(this, 'store')
                .createRecord('abcensetype', { name: get(this, 'intl').t('saldo.saldo_absence') })
                .save();
        } else {
            return get(saldoAbsences, 'firstObject');
        }
    },

    async setCapiProduct(products) {
        await get(this, 'Collector').setProducts(products);
    },

    async setFormFieldSetting(formName, fieldName, settingName, value) {
        let form = get(this, 'store').peekAll('form').filterBy('external_type', formName);
        let fields = get(form, 'firstObject.fields').filterBy('external_type', fieldName);
        let settings = get(fields, 'firstObject.settings').filterBy('name', settingName);

        if (isEmpty(settings)) {
            await get(this, 'store')
                .createRecord('form-field-setting', {
                    field: get(fields, 'firstObject'),
                    name: settingName,
                    value: value,
                })
                .save();
        } else {
            let setting = get(settings, 'firstObject');
            set(setting, 'value', value);
            await setting.save();
        }
    },

    startTourIfNeeded(executeSettings) {
        if (!isEmpty(this.localStorageWrapper.getUserSetting('tourShown'))) return;
        executeSettings
            ? localStorage.setItem('welcome-tour', 'startTour')
            : this.welcomeTour.start();
    },
});
