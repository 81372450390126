import Controller, { inject as controller } from '@ember/controller';

import { alias } from '@ember/object/computed';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default Controller.extend({
    toggleController: controller('drivinglog/toggle/index'),
    Collector: service('collector-service'),
    gui: service(),
    km: 'km',
    init() {
        this._super(...arguments);
        if (!get(this, 'toggleController.openDrive')) {
            return this.transitionToRoute('drivinglog.toggle');
        }
    },
    drivinglog: alias('model'),
    openDrive: computed('toggleController.openDrive', function () {
        if (!get(this, 'toggleController.openDrive')) {
            this.transitionToRoute('drivinglog.toggle');
        }

        return get(this, 'toggleController.openDrive');
    }),

    distField: computed(function () {
        return get(this, 'Collector').field('drive', 'distance');
    }),

    routeField: computed(function () {
        return get(this, 'Collector').field('drive', 'route');
    }),

    gaugeField: computed(function () {
        return get(this, 'Collector').field('drive', 'gauge_end');
    }),

    projField: computed(function () {
        return get(this, 'Collector').field('drive', 'project');
    }),

    privateField: computed(function () {
        return get(this, 'Collector').field('drive', 'private_drive');
    }),

    descField: computed(function () {
        return get(this, 'Collector').field('drive', 'description');
    }),

    actions: {
        confirm() {
            return get(this, 'toggleController').send('endDrive');
        },

        setRoute(value) {
            return set(this, 'toggleController.openDrive.route', value);
        },

        privateDrive() {
            this.toggleProperty('toggleController.openDrive.private_drive');
            return false;
        },

        toggleRoute() {
            this.toggleProperty('route');
            later(
                this,
                () => document.getElementsByClassName('confirm-extra-route-textarea')[0].focus(),
                500,
            );
            return false;
        },

        toggleComment() {
            this.toggleProperty('commentToggled');
            return false;
        },
    },
});
