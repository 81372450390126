/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/notices-graph.coffee.old
// Generated by CoffeeScript 2.6.1
var NoticesGraphComponent;

import Ember from 'ember';

NoticesGraphComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['notices-graph-container'],
    // DATA
    data: Ember.computed('all', 'overdue', function () {
        var all, intl, overdue, solved;
        intl = this.get('intl');
        all = this.get('all');
        overdue = this.get('overdue');
        solved = all - this.get('not_solved');
        return [
            ['Title', 'Value'],
            [intl.t('site-safety.overview.notices_total'), all],
            [intl.t('site-safety.overview.notices_solved'), solved],
            [intl.t('site-safety.overview.notices_overdue'), overdue],
        ];
    }),
    // COLORS
    colors: '#FFC56C,#5CB85C,#D9534F',
    // OPTIONS
    options: {
        pieHole: 0.9,
        chartArea: {
            width: '90%',
            height: '95%',
        },
        height: 200,
        width: 350,
        pieSliceTextStyle: {
            color: 'black',
            fontSize: 20,
        },
        pieSliceText: 'value',
    },
});

//        slices: {
//            1: {offset: 0.05}
//            2: {offset: 0.05}
//            3: {offset: 0.05}
//        }
export default NoticesGraphComponent;
