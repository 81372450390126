import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import RSVP from 'rsvp';
import { isNone } from '@ember/utils';

export default Route.extend({
    store: service(),
    collector: service('collector-service'),
    capi: service(),
    ttapi: service(),
    queryParams: {
        startdate: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        superior: {
            refreshModel: true,
        },
        worktimegroup: {
            refreshModel: true,
        },
    },
    model(params) {
        const c = get(this, 'collector');
        const userParams = { offset: params.page === 1 ? 0 : 25 * (params.page - 1) };
        Object.keys(params).forEach((item) => {
            if (item === 'user' && params.user) {
                userParams.id = params.user;
            } else if (c.testNeedsOne('user.' + item)) {
                userParams[item] = params[item];
            }
        });

        const allParams = Object.assign({ limit: 25, order: 'lastname' }, userParams);
        let queryString = '';
        Object.entries(allParams).forEach((param) => {
            if (isNone(param[1])) return;

            if (!queryString) queryString += `?${param[0]}=${param[1]}`;
            else queryString += `&${param[0]}=${param[1]}`;
        });

        const canSeeOnlyOwnAbsences = this.collector.testNeeds(['abcense.user@hide']);

        const showTeamCalendar =
            canSeeOnlyOwnAbsences && this.collector.testNeeds(['products.team_calendar']);

        if (showTeamCalendar) {
            return RSVP.hash({
                showTeamCalendar: true,
                data: this.fetchTeamAbsences(params),
            });
        }

        let userPromise = this.capi.request('abcenses/user' + queryString);

        return RSVP.hash({
            showTeamCalendar: false,
            users: userPromise,
            absences: this.fetchAbsences(userPromise, params),
        });
    },
    fetchTeamAbsences(params) {
        return this.ttapi.request(`absence/team?startdate_enddate=${params.startdate}`);
    },
    fetchAbsences(usersPromise, params) {
        return usersPromise.then((users) => {
            return get(this, 'store').query('abcense', {
                user: users.user.mapBy('id').join(','),
                startdate_enddate: params.startdate,
                order: 'startdate',
                sideload: true,
            });
        });
    },
});
