/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-page-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddNotesPageComponent;

import Ember from 'ember';

WorksitediaryReportAddNotesPageComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    setField: function (field) {
        if (field.name === 'type') {
            field.features.hide = true;
        }
        return field;
    },
    show_modal: false,
    actions: {
        updateField: function (value, field) {
            return this.set('model.' + field, value);
        },
        edit_record: function (record) {
            this.set('record', record);
            this.set('show_modal', true);
            return false;
        },
        removeRecord: function (id) {
            var record;
            record = this.get('store').peekRecord(this.get('form'), id);
            this.sendAction('recordDeleted', record);
            return record.destroyRecord();
        },
        add_record: function () {
            var record;
            record = this.get('store').createRecord(this.get('form').dasherize(), {
                type: this.get('type'),
            });
            this.set('record', record);
            return this.set('show_modal', true);
        },
        closeModal: function () {
            this.set('show_modal', false);
            return this.set('record', null);
        },
        recordAdded: function (settings, record) {
            this.sendAction('recordAdded', this.get('form'), record);
            this.set('show_modal', false);
            return this.set('record', null);
        },
        setSearchOption: function (option) {
            return this.set('record.title', option.string);
        },
    },
});

export default WorksitediaryReportAddNotesPageComponent;
