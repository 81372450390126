/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/week-report2/component.coffee.old
// Generated by CoffeeScript 2.6.1
var WeekReport2Component;

import Ember from 'ember';

WeekReport2Component = Ember.Component.extend({
    intl: Em.inject.service(),
    gui: Em.inject.service(),
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    sum: Em.computed('model', function () {
        var arr, totalAbsenceDays, totalAbsenceHours, totalWorkHours;
        totalWorkHours = 0;
        totalAbsenceDays = 0;
        totalAbsenceHours = 0;
        this.get('model').forEach((row) => {
            if (Ember.typeOf(row) === 'object') {
                if (row['dayamount']) {
                    return totalAbsenceDays++;
                } else {
                    return (totalAbsenceHours += row['hours']);
                }
            } else {
                return (totalWorkHours += row.get('work_hours'));
            }
        });
        arr = [];
        arr['totalWorkHours'] = totalWorkHours;
        arr['totalAbsenceDays'] = totalAbsenceDays;
        arr['totalAbsenceHours'] = totalAbsenceHours;
        return arr;
    }),
    saldosInUse: Ember.computed(function () {
        return this.get('Collector').testNeeds(['products.saldo']);
    }),
    currentSaldo: Ember.computed('model', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('Collector')
                    .ttapi({
                        url: 'saldo/get_saldo?userid=' + this.session.currentUser.id,
                    })
                    .then((back) => {
                        return resolve({
                            value: back['saldo'] / 60,
                        });
                    });
            }),
        });
    }),
    actions: {
        click: function (row) {
            // worktime is instance
            if (Ember.typeOf(row) === 'instance') {
                if (
                    this.get('Collector').canAddWorktime(row.get('date')) &&
                    this.get('rowClicked')
                ) {
                    return this.rowClicked(row);
                } else {
                }
            } else {
                if (this.get('rowClicked')) {
                    return this.rowClicked(row);
                }
            }
        },
    },
});

export default WeekReport2Component;
