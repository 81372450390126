/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/calendar/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerCalendarRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import initRoute from '../../../mixins/init-route';

import GeneralReportRoute from '../../../routes/general-report';

import { perDate } from 'tt4/utils/date-util';

TimetrackerCalendarRoute = Ember.Route.extend(AuthenticatedRouteMixin, initRoute, {
    Collector: Ember.inject.service('collector-service'),
    ttapi: Ember.inject.service(),
    queryParams: {
        date: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        gantt: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        more: {
            refreshModel: true,
        },
    },
    more: null,
    model: function (params) {
        var abparams, c, param, split_date;
        for (param in params) {
            if (params[param] === null) {
                delete params[param];
            }
        }
        if (params.gantt) {
            delete params.gantt;
            if (!params.project) {
                return [];
            }
            c = this.get('Collector');
            params.sideload = c.getSideloads('worktime');
            params.sideload.push('user.image');
            params.limit = 15;
            if (params.page) {
                params.offset = params.limit * (params.page - 1);
                delete params.page;
            }
            return this.get('ttapi')
                .request('api/project/users', {
                    data: params,
                })
                .then((data) => {
                    var promises;
                    promises = [];
                    this.get('store').pushPayload();
                    delete params.limit;
                    data.users.forEach((user) => {
                        return promises.push(
                            this.get('store')
                                .query(
                                    'worktime',
                                    Object.assign(
                                        {
                                            user: Ember.get(user, 'id'),
                                        },
                                        params,
                                    ),
                                )
                                .then((worktimes) => {
                                    return Ember.Object.create({
                                        main: user,
                                        rows: perDate(worktimes),
                                    });
                                }),
                        );
                    });
                    return Ember.RSVP.hash({
                        response: data,
                        data: Ember.RSVP.Promise.all(promises),
                    });
                });
        } else {
            delete params.gantt;
            delete params.page;
            c = this.get('Collector');
            params.sideload = c.getSideloads('worktime');
            params.sideload.push('user.image');
            if (params.startdate) {
                params.order = 'startdate';
            } else {
                params.order = 'date';
            }
            //params.date = moment(split_date[0]).subtract(2, 'weeks').format('YYYY-MM-DD') + '_' + split_date[1]

            //if service doesn't have employers, do not use it as query param
            if (!this.get('Collector').testNeedsOne('worktime.employer')) {
                delete params.employer;
            }
            // check if more-param is present, parse it correctly and finally remove it from params
            if (params.more != null) {
                params.more.split('|').forEach(function (param) {
                    var split, splitted;
                    splitted = param.split(':');
                    if (splitted[0].includes('-')) {
                        if (Ember.isPresent(splitted[1])) {
                            split = splitted[0].split('-');
                            if (Ember.isEmpty(params[split[0]])) {
                                return (params[split[0]] = {
                                    [`${split[1]}`]: splitted[1],
                                });
                            }
                        }
                    } else {
                        return (params[splitted[0]] = splitted[1]);
                    }
                });
                this.set('more', params.more);
            } else {
                this.set('more', null);
            }
            if (params != null && params['more']) {
                delete params['more'];
            }
            this.store.query('worktime', params);
            if (
                this.get('Collector').testNeedsOne('abcense') &&
                (Ember.isEmpty(params.project) ||
                    this.get('Collector').testNeedsOne('abcense.project'))
            ) {
                abparams = Object.assign({}, params);
                split_date = abparams.date.split('_');
                abparams.startdate =
                    moment(split_date[0]).subtract(36, 'days').format('YYYY-MM-DD') +
                    '_' +
                    split_date[1];
                for (param in abparams) {
                    if (!c.fieldExists('abcense', param)) {
                        delete abparams[param];
                    }
                }
                abparams.sideload = c.getSideloads('abcense');
                abparams.sideload.push('user.image');
                this.get('store').query('abcense', abparams);
            }
            return this.store.peekAll('worktime');
        }
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        if (this.get('more')) {
            return this.get('more')
                .split('|')
                .forEach(function (param) {
                    var splitted;
                    splitted = param.split(':');
                    return controller.set(splitted[0], splitted[1]);
                });
        }
    },
});

export default TimetrackerCalendarRoute;
