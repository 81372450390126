import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PayrollSettingsComponent extends Component {
    @service store;
    @service('collector-service') Collector;

    @tracked dim1;
    @tracked dim2;
    @tracked dim3;
    @tracked dim4;
    @tracked dim5;
    @tracked isLoading;

    labeltext1 = 'DIM1';
    labeltext2 = 'DIM2';
    labeltext3 = 'DIM3';
    labeltext4 = 'DIM4';
    labeltext5 = 'DIM5';

    constructor() {
        super(...arguments);
        this.getSettings();
    }

    @action
    save() {
        this.store.peekAll('integrationsetting').save();
        this.args.close();
    }

    async getSettings() {
        this.isLoading = true;
        let settings = await this.store.query('integrationsetting', {
            integration: 'payroll_worktime_rest',
        });

        this.dim1 = this.setRecord(settings, 'dim1', null);
        this.dim2 = this.setRecord(settings, 'dim2', null);
        this.dim3 = this.setRecord(settings, 'dim3', null);
        this.dim4 = this.setRecord(settings, 'dim4', null);
        this.dim5 = this.setRecord(settings, 'dim5', null);

        this.isLoading = false;
    }

    setRecord(settings, key, value) {
        let record = settings.findBy('key', key);
        return record
            ? record
            : this.store.createRecord('integrationsetting', {
                  integration: 'payroll_worktime_rest',
                  key: key,
                  value: value,
              });
    }
}
