/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/card/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersCardController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

UsersCardController = GeneralReportController.extend({
    form: 'user',
    showColumns: ['lastname', 'firstname', 'employer', 'id06kort', 'id06valid', 'status'],
    queryParams: ['page', 'employer'],
    filters: Em.A([
        Em.Object.create({
            field: 'employer',
        }),
    ]),
    employer: null,
    id06valid:
        moment().subtract(1, 'year').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    settings: Em.Object.create({
        localStorageKey: 'user_id06card',
        sortByColumn: 'lastname',
    }),
});

export default UsersCardController;
