/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/saved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsSavedRoute;

import Ember from 'ember';

import SavedRoute from '../../../routes/saved';

ProjectsSavedRoute = SavedRoute.extend({
    templateName: 'projects.saved',
});

export default ProjectsSavedRoute;
