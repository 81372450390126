import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    collector: service('collector-service'),
    notificationpanel: alias('model'),
    // [{title: "test", notifications: [{title:"Card expired", active: true}, {title: "Worktime not added", active: true}]}]
    notificationGroups: computed('model', function () {
        const filtered = get(this, 'model').filter((item) => {
            if (!item.needs) return true;
            return get(this, 'collector').testNeeds(item.needs);
        });

        const groups = filtered.mapBy('group').filter(function (x, i, a) {
            return a.indexOf(x) == i;
        });

        const ret = [];
        groups.forEach((group) => {
            ret.push({
                title: group,
                notifications: filtered.filterBy('group', group).map((notification) => {
                    return {
                        title: notification.type,
                        type: notification.type,
                        active: notification.active,
                        customTime: notification.customTime,
                    };
                }),
            });
        });
        return ret;
    }),
});
