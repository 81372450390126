import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set, computed } from '@ember/object';
//import moment from 'moment-timezone';

export default Component.extend({
    ttapi: service(),
    notifications: service(),
    intl: service(),

    show: false,

    customTime: computed('notification.customTime', function () {
        if (!get(this, 'notification.customTime')) return null;
        const utctime = get(this, 'notification.customTime');
        const date = moment().format('YYYY-MM-DD');
        return moment(date + ' ' + utctime + ':00Z').format('HH:mm');
    }),

    actions: {
        toggleActive(value, onoff) {
            set(this, 'notification.active', onoff);
            let date = moment().format('YYYY-MM-DD');
            let time = get(this, 'notification.customTime')
                ? get(this, 'notification.customTime')
                : null;

            let customTime = time
                ? moment(date + ' ' + time + ':00')
                      .utc()
                      .format('HH:mm')
                : null;

            get(this, 'ttapi')._post('notification/update', {
                notification: get(this, 'notification.type'),
                active: onoff,
                customTime: customTime,
            });
        },

        async saveCustomTime(value) {
            let date = moment().format('YYYY-MM-DD');
            let customTime = moment(date + ' ' + value + ':00')
                .utc()
                .format('HH:mm');
            await get(this, 'ttapi')._post('notification/update', {
                notification: get(this, 'notification.type'),
                active: get(this, 'notification.active'),
                customTime: customTime,
            });
            this.notifications.success(this.intl.t('general.saved'), {
                autoClear: true,
            });
        },
    },
});
