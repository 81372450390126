/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import config from '../../../config/environment';

ProjectsEditController = GeneralAddController.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Em.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
    subMenus: Ember.computed('dashboardView.[]', 'model.proj_type', function () {
        var c, subMenus;
        c = this.get('Collector');
        subMenus = [
            {
                link: 'projects.edit.overview',
                text: 'submenu.project.overview',
            },
            {
                link: 'projects.edit.index',
                text: 'menu.edit_project',
                needs: ['!userlevel=3'],
            },
            {
                link: 'projects.edit.project-files',
                text: 'menu.project-files',
                needs: ['projectfile'],
            },
            {
                link: 'projects.edit.working-now',
                text: 'menu.people_working_now',
                needs: ['worktime'],
            },
            {
                link: 'projects.edit.subprojects',
                text: 'submenu.project.subprojects',
                needs: ['subproject', 'project@edit'],
            },
            {
                link: 'projects.edit.tasks',
                text: 'submenu.project.tasks',
                needs: ['task||multitask', 'project@edit'],
            },
            {
                link: 'projects.edit.notices',
                text: 'submenu.project.notices',
                needs: ['notice'],
            },
            {
                link: 'projects.edit.trnotices',
                text: 'submenu.project.trnotices',
                needs: ['trnotice'],
            },
            {
                link: 'projects.edit.project-users',
                text: 'submenu.project.project-users',
                needs: ['products.rakentajanpaketti'],
            },
            {
                link: 'projects.edit.linked-users',
                text: 'submenu.project.linked_users',
                needs: ['!products.generic_timetracker', '!products.base_basic'],
            },
            {
                link: 'projects.edit.open_worktimes',
                text: 'submenu.project.open_worktimes',
                needs: ['userlevel=3'],
            },
            {
                link: 'projects.edit.passes',
                text: 'submenu.project.passes',
                needs: ['orientation', 'userlevel=3'],
            },
            {
                link: 'projects.edit.rollocks',
                text: 'submenu.project.rollocks',
                needs: ['rollock', 'userlevel=5'],
            },
            {
                link: 'projects.edit.tv-status',
                text: 'submenu.project.tv_status',
                needs: ['project@edit', 'employer', '!products.generic_timetracker'],
            },
            {
                link: 'projects.edit.card-list',
                text: 'submenu.project.card-list',
                needs: ['!products.personalliggareandid06', 'products.cardregister', 'employer'],
            },
            {
                link: 'projects.edit.taxmanerrors',
                text: 'submenu.project.taxmanerrors',
                needs: ['export_taxman'],
            },
        ];
        if (this.get('model.proj_type') === '3' || this.get('model.proj_type') === '4') {
            subMenus.pushObject({
                link: 'projects.edit.contract_report',
                text: 'submenu.project.contract_report',
                needs: ['project@edit', 'export_taxman'],
            });
        }
        return subMenus.filter(function (item) {
            if (item.needs) {
                return c.testNeeds(item.needs);
            }
            return true;
        });
    }),
    contact_person_name: Em.observer('model.contact_user', function () {
        var c;
        //do not change values when entering edit mode, only when changing contact_user
        if (!this.get('model.hasDirtyAttributes')) {
            return;
        }
        c = this.get('Collector');
        if (this.get('model.contact_user') == null) {
            this.set('model.contact_person', null);
            this.set('model.email', null);
            this.set('model.phone', null);
            return;
        }
        //set contact person field
        this.set(
            'model.contact_person',
            this.get('model.contact_user').get('lastname') +
                ' ' +
                this.get('model.contact_user').get('firstname'),
        );
        if (this.get('model.contact_user').get('email')) {
            this.set('model.email', this.get('model.contact_user').get('email'));
        } else {
            this.set('model.email', null);
        }
        if (this.get('model.contact_user').get('phone')) {
            this.set('model.phone', this.get('model.contact_user').get('phone'));
        } else {
            this.set('model.phone', null);
        }
        //if finnishbuilder-module, set also other contact information
        if (c.testNeeds(['modules.finnishbuilder'])) {
            this.set('model.proj_firstname', this.get('model.contact_user').get('firstname'));
            this.set('model.proj_lastname', this.get('model.contact_user').get('lastname'));
            if (this.get('model.contact_user').get('email')) {
                return this.set('model.proj_email', this.get('model.contact_user').get('email'));
            } else {
                return this.set('model.proj_email', null);
            }
        }
    }),
    projectImage: Ember.computed('model.image', function () {
        var image;
        image = this.get('model.image');
        if (image != null && image.length > 0) {
            if (image[0].url) {
                return Ember.String.htmlSafe(config.APP.COLLECTOR_API_URL + '/' + image[0].url);
            } else if (image[0].file) {
                return Ember.String.htmlSafe(image[0].file);
            } else {
                return Ember.String.htmlSafe('img/placeholder.png');
            }
        } else {
            return Ember.String.htmlSafe('img/placeholder.png');
        }
    }),
});

export default ProjectsEditController;
