import Component from '@ember/component';
import { computed } from '@ember/object';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Component.extend({
    intl: service(),
    analytics: service(),
    gui: service(),
    classNames: ['widget'],
    attributeBindings: ['draggable', 'data-name'],
    classNameBindings: ['dragClass'],
    dragClass: 'inactive',
    draggable: alias('editing'),
    'data-name': alias('name'),

    widgetPath: computed(function () {
        return 'dash-board/widgets/' + get(this, 'name');
    }),

    helpBubbleText: computed('betaValue', function () {
        if (!this.name) return '';
        const translationName = this.betaValue ? this.name + '-beta' : this.name;
        return 'widget.' + translationName + '.help';
    }),

    actions: {
        removeMe() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'remove-' + get(this, 'name'),
            });
            this.onremove(this.name);
        },

        helpClicked() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'help-' + get(this, 'name'),
            });
        },
    },

    dragStart(event) {
        event.dataTransfer.setData('text/data', get(this, 'name'));
        window.Tt4.draggedWidgetType = 'widget';
    },

    dragLeave(event) {
        event.preventDefault();
        set(this, 'dragClass', 'inactive');
    },

    dragOver(event) {
        const itemType = window.Tt4.draggedWidgetType;
        if (itemType === 'widget' || itemType === 'widgetIcon') {
            event.preventDefault();
            const { y, height } = event.target.getBoundingClientRect();
            if (event.originalEvent.pageY - window.scrollY < y + height / 2) {
                set(this, 'dragClass', 'active-up');
            } else {
                set(this, 'dragClass', 'active-down');
            }
        }
    },

    dragEnter(event) {
        event.preventDefault();
    },

    drop(event) {
        set(this, 'dragClass', 'inactive');
        const data = event.dataTransfer.getData('text/data');
        if (!data || data == this.name) return;
        this.dropped(this.name, data, event);
    },
});
