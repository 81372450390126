/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import config from '../../../config/environment';

EmployersIndexController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    flags: Ember.inject.service(),
    cc: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    form: 'employer',
    showColumns: [
        'name',
        'emp_id',
        'tv_status',
        'tv_pdf',
        'date_checked',
        'parent',
        'emp_users_given',
        'emp_email',
        'emp_phone',
    ],
    queryParams: ['page', 'q', 'emp_users_given', 'parent'],
    emp_users_given: null,
    parent: null,
    init_functions: function () {
        if (
            this.get('flags').test('form-report-employers') ||
            this.get('flags').test('ember-movenium-form-report-employers')
        ) {
            return null;
        } else {
            return this._super();
        }
    },
    emp_users_given_options: Ember.computed(function () {
        return [
            {
                id: '1',
                name: this.get('intl').t('employer.emp_users_given.1'),
            },
            {
                id: '2',
                name: this.get('intl').t('employer.emp_users_given.2'),
            },
            {
                id: '3',
                name: this.get('intl').t('employer.emp_users_given.3'),
            },
        ];
    }),
    autoChecked: Ember.computed(function () {
        if (!this.get('Collector').testNeeds(['employer.tv_status'])) {
            return null;
        }
        return moment().startOf('month').format('L');
    }),
    filters: Ember.computed(function () {
        var prompt_text;
        prompt_text = this.get('intl').t('general.select.emp_users_given');
        return Em.A([
            Em.Object.create({
                type: 'dropdown_values',
                field: 'emp_users_given',
                prompt: prompt_text,
                options: [
                    {
                        id: '1',
                        value: this.get('intl').t('employer.emp_users_given.1'),
                    },
                    {
                        id: '2',
                        value: this.get('intl').t('employer.emp_users_given.2'),
                    },
                    {
                        id: '3',
                        value: this.get('intl').t('employer.emp_users_given.3'),
                    },
                ],
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
        ]);
    }),
    settings: Em.computed(function () {
        var _self, c, ret;
        _self = this;
        c = this.get('Collector');
        ret = Em.Object.create({});
        ret.set('massedit', true);
        if (c.fieldExists('employer', 'tv_status')) {
            ret.set('rowActions', [
                {
                    text: 'employer.actions.tv_report_pdf',
                    action: function (self, items) {
                        return items.forEach(function (row) {
                            if (!row.get('emp_id')) {
                                return;
                            }
                            return _self.saveTVPdf(row);
                        });
                    },
                },
            ]);
        }
        return ret;
    }),
    cdn_url: Em.computed(function () {
        return config.APP.CDN_URL;
    }),
    saveTVPdf: function (row) {
        return $.ajax({
            url: this.get('cc').host + config.APP.CC_TVEMP,
            data: {
                tunnus: row.get('emp_id'),
            },
            type: 'GET',
            success: function (data) {
                if (!data.error) {
                    data = data[0];
                }
                row.set('date_checked', moment().format('YYYY-MM-DD'));
                if (data != null && data.status && !data.error) {
                    row.set('tv_status', data.status.toLowerCase());
                    row.set('archive_code', data.archive_code);
                } else {
                    row.set('tv_status', 'ei_loydy');
                    row.set('archive_code', '');
                }
                return row.save();
            },
            fail: function (data) {
                row.set('tv_status', 'ei_loydy');
                row.set('archive_code', '');
                row.set('date_checked', moment().format('YYYY-MM-DD'));
                return row.save();
            },
        });
    },
    formatter: function (column, value, scope, formatted) {
        if (column === 'tv_status') {
            if (value && value !== 'ei_loydy') {
                return new Ember.String.htmlSafe(
                    '<img src="' +
                        config.APP.CDN_URL +
                        '/images/tv' +
                        value +
                        '.png" class="report-img" title="' +
                        formatted +
                        '">',
                );
            } else {
                return formatted;
            }
        }
        return false;
    },
    actions: {
        transitionToEditRoute: function (row) {
            return this.transitionToRoute('employers.edit', row);
        },
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        exportTVPdf: function (items) {
            return items.forEach((row) => {
                if (!row.get('emp_id')) {
                    return;
                }
                return this.saveTVPdf(row);
            });
        },
    },
});

export default EmployersIndexController;
