import { typeOf } from '@ember/utils';

/**
 * Sorts options based on given value (options can be array of objects or record)
 * @function sortBy
 * @param {String} sort
 * @param {any} options
 * @param {Array} subformFields
 */

export function sortBy(sort: string, options: any, subformFields: any = []) {
    options = typeOf(options) !== 'array' ? options.toArray() : options;

    if (sort !== 'all') {
        options.sort((a: { [x: string]: any }, b: { [x: string]: any }) =>
            ('' + a[sort]).localeCompare(b[sort]),
        );
    } else {
        options.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
            if (a[subformFields[0]] > b[subformFields[0]]) return 1;
            if (a[subformFields[0]] < b[subformFields[0]]) return -1;

            if (subformFields[1]) {
                if (a[subformFields[1]] > b[subformFields[1]]) return 1;
                if (a[subformFields[1]] < b[subformFields[1]]) return -1;
            }
            if (subformFields[2]) {
                if (a[subformFields[2]] > b[subformFields[2]]) return 1;
                if (a[subformFields[2]] < b[subformFields[2]]) return -1;
            }
            return 0;
        });
    }

    return options;
}
export default { sortBy };
