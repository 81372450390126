import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default class CollectorFormAddFormsWorktimegroupChangePage3Component extends Component {
    @service store;

    @tracked record;
    @tracked loading = false;
    @tracked shouldShowMappings = false;
    @tracked value = null;
    @tracked destinationWorktype;
    @tracked selectedValue;

    constructor() {
        super(...arguments);
        // if user is defined, we get worktypes from the user's worktimegroup, otherwise we get worktypes from the worktimegroup
        this.worktypes = this.getWorktimegroupWorktypes();
        this.destinationWorktypes = this.getDestinationWorktypes();
        this.shouldShowMappings = this.getShouldShowMappings();
    }

    async getDestinationWorktypes() {
        let worktypes = await this.store.query('worktypev2', {
            worktimegroup: this.args.record.destination.id,
        });
        // map the worktypes to an array of objects with id and name
        let mappedWorktypes = worktypes.map((worktype) => {
            return { id: worktype.id, name: worktype.name };
        });
        return mappedWorktypes;
    }

    async getWorktimegroupWorktypes() {
        this.loading = true;
        let ret = null;
        if (this.args.record.user) {
            // first we need to get the user's worktimegroup
            const user = await this.store.query('user', {
                id: this.args.record.user.id,
                sideload: true,
            });
            ret = await this.store.query('worktypev2', {
                worktimegroup: get(user.firstObject, 'worktimegroup.id'),
            });
        } else {
            ret = await this.store.query('worktypev2', {
                worktimegroup: this.args.record.worktimegroup.id,
            });
        }
        this.loading = false;
        return ret;
    }

    // returns true if args.record.startdate is in the past
    // returns false if args.record.user is null
    // default returns true
    getShouldShowMappings() {
        if (
            (this.args.record.startdate <= moment().format('YYYY-MM-DD') &&
                !this.args.record.user) ||
            (this.args.record.startdate <= moment().format('YYYY-MM-DD') && this.args.record.user)
        ) {
            return true;
        }
        return false;
    }
}
