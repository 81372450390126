import Controller from '@ember/controller';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
    store: service(),

    init() {
        this._super(...arguments);
        set(this, 'qParams', []);
        set(this, 'reportIsEmpty', true);
        set(this, 'loading', false);
        set(this, 'show_removed', true);
    },

    dummy: alias('model'),

    actions: {
        async setEmployer(employer) {
            set(this, 'employer', employer);
            this.filterReport();
        },
        async setUser(user) {
            set(this, 'user', user);
            this.filterReport();
        },

        showCard(row) {
            set(this, 'cardRow', row);
            set(this, 'showModal', true);
        },
    },
    async filterReport() {
        let qParams = { limit: 'all' };
        if (this.employer) {
            qParams['employer'] = this.employer.id;
        }
        if (this.user) {
            qParams['id'] = this.user.id;
        }
        if (qParams['id'] || qParams['employer']) {
            set(this, 'loading', true);
            let users = await this.store.query('user', qParams);
            let taxnumbers = users.mapBy('taxnumber').join(',');
            set(this, 'taxnumbers', taxnumbers || 'not_found');
            set(this, 'showReport', true);
            set(this, 'loading', false);
            return users;
        }
        set(this, 'showReport', false);
        return [];
    },
});
