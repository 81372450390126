import { typeOf } from '@ember/utils';

export function getSums(data, columns, form, collector, pdfSumrow = false) {
    const summableFieldTypes = [
        'date',
        'hours',
        'number',
        'checkbox',
        'decimal',
        'price',
        'big_price',
        'minutes',
        'dropdown_values',
    ];
    let summableFields = collector
        .fieldArray(form)
        .filter((field) => summableFieldTypes.includes(field.type));
    if (!summableFields) return;

    let sums = {};

    data.forEach((item) => {
        summableFields.forEach((field) => {
            if (!item) return;
            let cell = item[field.name];
            switch (field.type) {
                case 'checkbox':
                    if (!sums[field.name]) sums[field.name] = [0, 0];
                    cell ? sums[field.name][0]++ : sums[field.name][1]++;
                    break;
                case 'dropdown_values':
                    if (!sums[field.name]) sums[field.name] = [];
                    if (cell) sums[field.name].push(cell);
                    break;
                case 'date':
                    if (typeOf(cell) === 'string' && cell.substring(0, 6) === 'count:') {
                        sums[field.name] += parseInt(cell.substring(6));
                    } else {
                        sums[field.name] = '';
                        return;
                    }
                    break;
                default:
                    if (!sums[field.name]) sums[field.name] = 0;
                    sums[field.name] += cell;
            }
        });
    });

    let sumRow = {};

    columns.forEach((column) => {
        if (column.external_type === 'row_info.created') return;
        let field = column.external_type;

        let dropdownSums = [];
        if (sums[field]) {
            if (column.type === 'checkbox') {
                sumRow[field] =
                    collector.format(true, column, null, data) +
                    ': ' +
                    sums[field][0] +
                    ' / ' +
                    collector.format(false, column, null, data) +
                    ': ' +
                    sums[field][1];
            } else if (
                column.type === 'number' &&
                !isNaN(sums[field]) &&
                !Number.isInteger(sums[field])
            ) {
                let sumToFixed = sums[field].toFixed(2);
                sumRow[field] = collector.format(sumToFixed, column, null, data);
            } else if (column.type === 'dropdown_values') {
                sums[field].forEach((opt) => {
                    dropdownSums[opt] = (dropdownSums[opt] || 0) + 1;
                });
                sumRow[field] = collector.format(dropdownSums, column, null, data, pdfSumrow, form);
            } else {
                sumRow[field] = collector.format(sums[field], column, null, data, pdfSumrow);
            }
        } else {
            sumRow[field] = '';
        }
    });

    return [sumRow];
}
