/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

ProjectsEditRoute = GeneralAddRoute.extend({
    modelName: 'project',
    controllerName: 'projects/edit',
    edit: true,
    afterSave: 'projects',
});

export default ProjectsEditRoute;
