/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Takes two parameters: container and app
export function initialize(application) {
    application.inject('controller', 'Collector', 'service:collectorService');
    application.inject('route', 'Collector', 'service:collectorService');
    application.inject('component', 'Collector', 'service:collectorService');
    application.inject('adapter', 'Collector', 'service:collectorService');
}

export default {
    name: 'Collector',
    initialize,
};
