import Route from '@ember/routing/route';

const DevelopmentDatacreatorRoute = Route.extend({
    init() {
        this._super(...arguments);
        this.dateMemory = {};
    },

    actions: {
        createProjects() {
            return (() => {
                const result = [];
                for (let i = 1; i < 120; i++) {
                    const record = this.store.createRecord('project', {
                        number: i,
                        name: 'generated_' + i,
                    });

                    result.push(record.save());
                }
                return result;
            })();
        },

        createPLProjects() {
            return (() => {
                const result = [];
                for (let i = 1; i < 120; i++) {
                    const record = this.store.createRecord('project', {
                        number: i,
                        name: 'gen_pl_' + i,
                        presence_project: true,
                        skv_number: 'PL12345678' + i,
                    });

                    result.push(record.save());
                }
                return result;
            })();
        },

        createTasks() {
            return (() => {
                const result = [];
                for (let i = 1; i < 120; i++) {
                    const record = this.store.createRecord('task', { name: 'generated_' + i });

                    result.push(record.save());
                }
                return result;
            })();
        },

        createWorktimes() {
            const users = this.store.peekAll('user');
            const projects = this.store.peekAll('project');

            const starttimes = ['7:00', '7:30', '8:00'];
            const endtimes = ['12:00', '15:30', '16:00'];

            if (users.length < 1) {
                alert('go visit users route to get users to store');
                return;
            }

            if (projects.length < 1) {
                alert('go visit projects route to get projects to store');
                return;
            }

            return (() => {
                const result = [];
                for (let i = 1; i < 120; i++) {
                    var date;
                    const user = this.getRandom(users.content);
                    const project = this.getRandom(projects.content);

                    const userid = user.id;

                    const lastdate = this.dateMemory[userid];

                    if (!lastdate) {
                        date = this.dateMemory[userid] = moment().format('YYYY-MM-DD');
                    } else {
                        date = this.dateMemory[userid] = moment(lastdate)
                            .subtract(1, 'day')
                            .format('YYYY-MM-DD');
                    }

                    const record = this.store.createRecord('worktime', {
                        date,
                        project: this.store.peekRecord('project', project.id),
                        user: this.store.peekRecord('user', user.id),
                        starttime: this.getRandom(starttimes),
                        endtime: this.getRandom(endtimes),
                    });

                    result.push(record.save());
                }
                return result;
            })();
        },
        createUsers() {
            return (() => {
                const result = [];
                for (let i = 1; i < 120; i++) {
                    const record = this.store.createRecord('user', {
                        firstname: 'generated',
                        lastname: (Math.random() + 1).toString(36).substring(1, 11),
                        password: (Math.random() + 1).toString(36).substring(1, 7),
                    });

                    result.push(record.save());
                }
                return result;
            })();
        },
    },

    getRandom(arr) {
        return arr[this.getRandomArbitrary(0, arr.length - 1)];
    },

    getRandomArbitrary(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
});

export default DevelopmentDatacreatorRoute;
