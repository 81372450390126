/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/confirm/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var confirmController;

import Ember from 'ember';

import collector from '../../../../classes/collector';

confirmController = Ember.Controller.extend({
    //    needs: ["orientations/add"]
    addController: Ember.inject.controller('orientations/add'),
    indexController: Ember.inject.controller('orientations/add/index'),
    record: Ember.computed.alias('addController.record'),
    Collector: Ember.inject.service('collector-service'),
    intl: Em.inject.service(),
    fileField: Ember.computed('record.ori_site.file', function () {
        var field;
        field = this.get('Collector').field('project', 'file');
        field.value = this.get('record.ori_site.file');
        this.set('record.file', this.get('record.ori_site.file'));
        return field;
    }),
    extras: Ember.computed(function () {
        var extras, extras_tmp;
        extras_tmp = collector.forms.orientation.objects;
        extras = [];
        $.each(extras_tmp, function (index, obj) {
            if (obj.type !== 'checkboxgroup') {
                if (index.indexOf('addon_') >= 0) {
                    obj.ext = index;
                    return extras.push(obj);
                }
            }
        });
        return extras;
    }),
    passStart: moment().format('YYYY-MM-DD'),
    unvalid: Em.A([]),
    setPassDates: function () {
        if (this.Collector.testNeeds(['project.proj_end'])) {
            if (this.get('record.ori_site.proj_end')) {
                return this.setPassDatesTo(null, this.get('record.ori_site.proj_end'));
            } else {
                return this.setPassDatesTo();
            }
        } else {
            return this.setPassDatesTo();
        }
    },
    setPassDatesTo: function (start, end) {
        if (start) {
            this.set('record.pass_start', moment(start).format('YYYY-MM-DD'));
        } else {
            this.set('record.pass_start', moment().format('YYYY-MM-DD'));
        }
        if (end) {
            return this.set('record.pass_end', moment(end).format('YYYY-MM-DD'));
        } else {
            return this.set('record.pass_end', moment().add(1, 'months').format('YYYY-MM-DD'));
        }
    },
    clearPassDates: function () {
        if (this.get('showPassDates')) {
            this.set('record.pass_start', null);
            this.set('record.pass_end', null);
            return this.set('showPassDates', false);
        }
    },
    checkTVEmployerStatus: function (employer) {
        var employerId;
        employerId = Ember.get(employer, 'emp_id');
        if (!employerId) {
            return;
        }
        return this.get('Collector').ccapi({
            url: 'tvemployer',
            data: {
                tunnus: employerId,
            },
            type: 'GET',
            success: function (data) {
                if (!data.error) {
                    data = data[0];
                }
                employer.set('date_checked', moment().format('YYYY-MM-DD'));
                if (data != null && data.status && !data.error) {
                    employer.set('tv_status', data.status.toLowerCase());
                    employer.set('archive_code', data.archive_code);
                } else {
                    employer.set('tv_status', 'ei_loydy');
                    employer.set('archive_code', '');
                }
                return employer.save();
            },
        });
    },
    actions: {
        save: function (row) {
            var dummy, k, mandatory, records, self, store, users, v, valid;
            self = this;
            mandatory = this.get('addController.mandatoryArr');
            valid = true;
            this.set('unvalid', Em.A([]));
            for (k in mandatory) {
                v = mandatory[k];
                if (
                    !row.get(k) &&
                    k !== 'ori_user' &&
                    k !== 'ori_site' &&
                    k !== 'ori_date' &&
                    k !== 'ori_place' &&
                    k !== 'sign_emp_img' &&
                    k !== 'sign_per_img'
                ) {
                    self.get('unvalid').pushObject(
                        self.get('intl').t('orientations.validation.' + k),
                    );
                    valid = false;
                }
            }
            if (moment(row.get('pass_start')).isAfter(row.get('pass_end'))) {
                self.get('unvalid').pushObject(
                    self.get('intl').t('orientations.validation.pass_start_before_end'),
                );
                valid = false;
            }
            if (valid) {
                this.set('addController.disableLinks', true);
                users = this.get('addController.users');
                store = this.get('store');
                dummy = row.serialize();
                dummy.ori_site = store.peekRecord('project', dummy.ori_site);
                //dummy.file = @get 'record.ori_site.file'
                records = Em.A([]);
                users.forEach(function (id) {
                    return self
                        .get('Collector')
                        .findFormRelations('orientation', 'ori_user', {
                            id: id,
                        })
                        .then(function (oriuser) {
                            var record, ref, user;
                            user = oriuser[0];
                            dummy.ori_user = user;
                            if (self.get('Collector').fieldExists('orientation', 'parent')) {
                                dummy.parent = self.get('addController.selected_parents')[id];
                            }
                            record = store.createRecord('orientation', dummy);
                            records.pushObject(record);
                            self.set('addController.orientations', records);
                            self.clearPassDates();
                            if (
                                (ref = self.get('addController.projectHasLocks')) != null
                                    ? ref._result
                                    : void 0
                            ) {
                                return self.transitionToRoute('orientations.add.add-passes');
                            } else {
                                self.transitionToRoute('orientations.add.signatures');
                                return row.rollbackAttributes();
                            }
                        });
                });
                if (this.get('Collector').testNeedsOne('employer')) {
                    return store
                        .query('user', {
                            id: users.join(','),
                        })
                        .then((users) => {
                            var usersWithUniqueEmployers;
                            usersWithUniqueEmployers = users.uniqBy('employer.emp_id');
                            return usersWithUniqueEmployers.forEach((user) => {
                                var employer;
                                if (!user.get('employer.id')) {
                                    return;
                                }
                                return (employer = store
                                    .findRecord('employer', user.get('employer.id'))
                                    .then((employer) => {
                                        return this.checkTVEmployerStatus(employer);
                                    }));
                            });
                        });
                }
            }
        },
        emptyPassDates: function () {
            var oriProjId;
            if (this.get('showPassDates')) {
                this.set('record.pass_start', null);
                this.set('record.pass_end', null);
                this.set('showPassDates', false);
            } else {
                if (Modernizr.inputtypes.date) {
                    oriProjId = this.get('indexController.record.ori_site.id');
                    if (this.Collector.testNeeds(['employer', 'urakkaraportointi'])) {
                        this.store
                            .findRecord('user', this.get('addController.users.firstObject'), {
                                reload: true,
                            })
                            .then((user) => {
                                return this.store.query('urakkaraportointi', {
                                    contract_employer: user.get('employer.id'),
                                });
                            })
                            .then((contracts) => {
                                var contractEndDateFound;
                                contractEndDateFound = contracts
                                    .sortBy('contract_month')
                                    .reverseObjects()
                                    .any((contract) => {
                                        var contrProjId;
                                        contrProjId = contract.get('contract_project.id');
                                        if (contrProjId === oriProjId) {
                                            this.setPassDatesTo(
                                                null,
                                                contract.get('contract_end_date'),
                                            );
                                        }
                                        return contrProjId === oriProjId;
                                    });
                                if (!contractEndDateFound) {
                                    return this.setPassDates();
                                }
                            });
                    } else {
                        this.setPassDates();
                    }
                }
                this.set('showPassDates', true);
            }
            return false;
        },
        setFile: function (file, field) {
            return this.set('record.file', file);
        },
    },
});

export default confirmController;
