import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { max } from '@ember/object/computed';

export default class CollectorFormAddFormsProjectComponent extends Component {
    @service flags;
    @service store;
    @service('collector-service') Collector;

    @tracked record;
    @tracked projectNumbers;

    @max('projectNumbers') maxNumber;

    constructor() {
        super(...arguments);
        this.allProjects = this.getAllProjects();
        if (!this.args.record && this.flags.test('ember-movenium-form-add-projects')) {
            this.createProjectRecord();
        }
    }

    get defaults() {
        return this.getDefaults();
    }

    @action
    onFieldChange(field, value) {
        this.record[field] = value;
    }

    @action
    addNew() {
        this.createProjectRecord();
    }

    @action
    addSame() {
        this.record = this.Collector.copyRecord(this.record);
    }

    async getDefaults() {
        let _defaults = {};
        const projects = await this.allProjects;
        this.projectNumbers = projects
            .mapBy('number')
            .map((num) => parseInt(num, 10))
            .filter((filtered) => !isNaN(filtered));

        const biggestNumber = this.projectNumbers.length !== 0 ? this.maxNumber : 0;
        _defaults.number = biggestNumber + 1;
        return _defaults;
    }

    async getAllProjects() {
        return await this.store.findAll('project');
    }

    async createProjectRecord() {
        const _defaults = await this.defaults;
        this.record = this.store.createRecord('project', _defaults);
    }
}
