/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/automation/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryAutomationController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

WorksitediaryAutomationController = GeneralReportController.extend({
    Collector: Ember.inject.service('collector-service'),
    projects: false,
    addedProjects: Ember.A([]),
    showTheseColumns: ['number', 'name', 'autodiary'],
    project: Ember.computed(function () {
        var project;
        project = this.get('Collector').fieldArray('diaryreport', ['project'])[0];
        project.value = void 0;
        project.filterOptions = {
            autodiary: 'off',
        };
        return project;
    }),
    filters: Em.A([
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
            hideRemove: true,
            historyRoute: {
                modal: true,
            },
        }),
        localStorageKey: 'diaryautomation',
    }),
    saveItem: function (items) {
        var item;
        item = items.popObject();
        if (item) {
            item.set('autodiary', true);
            return item.save().then(() => {
                return this.saveItem(items);
            });
        } else {
            this.set('loading', false);
            this.send('close');
            return this.send('refreshModel');
        }
    },
    actions: {
        addProjects: function () {
            this.set('projects', true);
            return false;
        },
        addProject: function (value) {
            var projects;
            projects = this.get('addedProjects');
            if (projects.filterBy('id', value.get('id')).get('length') > 0) {
                return;
            }
            return this.get('addedProjects').pushObject(value);
        },
        save: function () {
            var add;
            this.set('loading', true);
            add = this.get('addedProjects');
            return this.saveItem(add);
        },
        close: function () {
            this.set('addedProjects', Ember.A([]));
            return this.set('projects', false);
        },
    },
});

export default WorksitediaryAutomationController;
