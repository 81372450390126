import Component from '@ember/component';
import { computed, get, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { later, debounce, next } from '@ember/runloop';
import Ember from 'ember';

export default Component.extend({
    intl: service(),
    collector: service('collector-service'),
    flags: service(),
    gui: service(),
    store: service(),
    localstorage: service(),
    analytics: service(),
    evented: service(),
    userSettings: service(),

    widgets: computed(function () {
        const widgets = [
            {
                needs: [
                    'worktime',
                    'worktime@add',
                    'modulecontrol.worktimes',
                    'flag.clockcard-25||!products.gps_force',
                    '!products.timetracker_hours',
                ],
                name: 'timecard',
                icon: 'Time',
                header: this.intl.t('header.path.add.timecard'),
            },
            {
                needs: [
                    'worktime',
                    'worktime@add',
                    '!products.timetracker_clockcard||!userlevel=1?',
                    'modulecontrol.worktimes',
                ],
                name: 'worktime-add',
                icon: 'Time',
                header: this.intl.t('widget.worktime-add.header'),
            },
            { needs: ['userHasSaldos'], name: 'saldo', icon: 'Timespan', header: 'Saldo' },
            {
                needs: ['worktime', 'modulecontrol.worktimes'],
                name: 'worktime-calendar',
                icon: 'Calendar',
                header: this.intl.t('widget.worktime-calendar.header'),
                wide: true,
            },
            {
                needs: [
                    '!mobileView',
                    'worktime',
                    'worktime@add',
                    'modulecontrol.worktimes',
                    '!products.gps_force',
                ],
                name: 'my-sites',
                icon: 'MySites',
                header: this.intl.t('widget.my-sites.header'),
                wide: true,
            },
            {
                needs: [
                    'worktime',
                    'products.absences',
                    '!customersetting.hide_holidays',
                    'holiday@access',
                ],
                name: 'holiday',
                icon: 'Sun',
                header: this.intl.t('widget.holiday.header'),
            },
            {
                needs: ['worktime', 'products.absences'],
                name: 'absence',
                icon: 'Cup',
                header: this.intl.t('widget.absence.header'),
            },
            {
                needs: [],
                name: 'custom-help',
                icon: 'FAQ',
                header: this.intl.t('widget.custom-help.header'),
            },
            {
                needs: [],
                name: 'feedback',
                icon: 'Notes',
                header: this.intl.t('widget.feedback.header'),
            },
            {
                needs: [
                    'worktime',
                    'salarytype||salarytypev2',
                    'salarytype@access||salarytypev2@access',
                ],
                name: 'salary-sum',
                icon: 'Timespan',
                header: this.intl.t('widget.salary-sum.header'),
            },
            {
                needs: ['products.worktimebank'],
                name: 'bank',
                icon: 'Timespan',
                header: this.intl.t('widget.bank.header'),
            },
            {
                needs: [],
                name: 'promo-messages',
                icon: 'Message',
                header: this.intl.t('widget.promoMessages.header'),
            },
            {
                needs: ['worktime||presence', '!userlevel=1?'],
                name: 'working-now',
                icon: 'Calendar',
                header: this.intl.t('widget.working-now.header'),
            },
            {
                needs: [
                    'employer',
                    'employer@access',
                    'employer.tv_status',
                    'worktime.project||presence.project',
                ],
                name: 'tv-status',
                icon: 'PieChart',
                header: this.intl.t('widget.tv-status.header'),
            },
            {
                needs: ['worktime', 'products.absences', '!userlevel=1,9?'],
                name: 'absentees',
                icon: 'Calendar',
                header: this.intl.t('widget.absentees.header'),
            },
        ];

        if (this.flags.test('anomality-widget')) {
            widgets.unshift({
                needs: [
                    'products.saldo||worktime||products.orientations||export_taxman',
                    '!userlevel=1',
                ],
                name: 'anomalities',
                icon: 'Expired',
                header: this.intl.t('widget.anomalities.header'),
            });
        }

        return widgets.filter((item) => this.collector.testNeeds(item.needs));
    }),

    hidden_widgets: computed('widgets', 'widget_columns', function () {
        const allWidgets = this.widgets;
        const shownWidgets = this.widget_columns;
        let hidden_widgets = [];
        if (this.isMobileOrEditingMobile) {
            allWidgets
                .filter((widget) => !widget.needs.includes('!mobileView'))
                .forEach((widget) => {
                    if (shownWidgets.mobile.findBy('name', widget.name)) return;
                    hidden_widgets.push(widget);
                });
        } else {
            allWidgets.forEach((widget) => {
                if (shownWidgets.left.findBy('name', widget.name)) return;
                if (shownWidgets.center.findBy('name', widget.name)) return;
                if (shownWidgets.right.findBy('name', widget.name)) return;
                hidden_widgets.push(widget);
            });
        }
        return hidden_widgets;
    }),

    widget_columns: computed('isMobileOrEditingMobile', function () {
        const dashboard = this.localstorage.getItem('dashboard');
        const dashboardMobile = this.localstorage.getItem('dashboard-mobile');

        if (this.isMobileOrEditingMobile && dashboardMobile) {
            return {
                mobile: this.mapWidgetsAndFilter(dashboardMobile.mobile),
            };
        } else if (!this.isMobileOrEditingMobile && dashboard) {
            return {
                left: this.mapWidgetsAndFilter(dashboard.left),
                center: this.mapWidgetsAndFilter(dashboard.center),
                right: this.mapWidgetsAndFilter(dashboard.right),
            };
        }

        return this.default_widgets;
    }),

    default_widgets: computed(function () {
        const narrow_widgets = this.widgets.filter((item) => !item.wide);
        const slice_from = Math.floor(narrow_widgets.length / 2);

        return {
            left: narrow_widgets.slice(0, slice_from),
            center: this.widgets.filter((item) => item.wide),
            right: narrow_widgets.slice(slice_from, narrow_widgets.length),
            mobile: this.widgets.filter((widget) => !widget.needs.includes('!mobileView')),
        };
    }),

    isMobileOrEditingMobile: computed('dashboardView', function () {
        return this.gui.isMobile() || this.dashboardView === 'mobile';
    }),

    projectField: computed('project', function () {
        const form = this.Collector.fieldExists('worktime', 'project') ? 'worktime' : 'presence';
        let field = this.collector.field(form, 'project');
        if (field) {
            field.prompt = 'general.all_projects';
        }
        return field;
    }),

    init() {
        this._super(...arguments);
        this.dashboardView = 'browser';
        this.evented.on('onResumed', this, 'onResumed');
        set(this, 'refreshTime', new Date()); // this is shown in template
        if (!Ember.testing) later(this, this.autorefresh, 30 * 60 * 1000); // 30 minutes
        this.doTheseBeforeRender();
    },

    didInsertElement() {
        this.loadDashboard();
    },

    didDestroyElement() {
        this.evented.off('onResumed', this, 'onResumed');
    },

    actions: {
        projectFilterChange(project) {
            set(this, 'project', project?.id);
            this.evented.dashboardProjectChanged(project);
        },

        toggleEdit() {
            this.toggleProperty('editingDashboard');
            this.analytics.trackEvent({ category: 'Dashboard', action: 'edit-mode' });
            window.Tt4.draggedWidgetType = null;
            if (this.editingDashboard) {
                set(this, 'gui.sidebar', false);
            } else {
                this.gui.resetSidebar();
                this.send('changeView', 'browser'); //to load the correct view when exiting edit mode
            }
        },

        async onremove(name) {
            this.analytics.trackEvent({ category: 'Dashboard', action: `Widget removed: ${name}` });
            let widgetStrings = this.moveWidgets(name);
            await this.saveDashboard(widgetStrings);
            this.evented.storeEvent('insert', 'dashboard', null);
        },

        widgetDropped(dropTargetName, dropWidgetName, event) {
            this.analytics.trackEvent({ category: 'Dashboard', action: `Widget moved` });

            const { y, height } = event.target.getBoundingClientRect();
            // set(this, 'dragClass', 'active-down')
            const dropPlacement =
                event.originalEvent.pageY - window.scrollY < y + height / 2 ? 'above' : 'below';

            let widgetStrings = this.moveWidgets(dropWidgetName, dropTargetName, dropPlacement);
            this.saveDashboard(widgetStrings);
        },

        async changeView(view) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Edit view changed to ${view}`,
            });
            set(this, 'dashboardView', view);
            set(this, 'loading', true);
            await this.loadDashboard();
            set(this, 'loading', false);
        },

        refresh() {
            this.analytics.trackEvent({ category: 'Dashboard', action: 'Refresh' });
            // prevent fast clicking
            debounce(this, this.refreshDebounce, 1000, true);
        },
        async moveWidget(widget, direction) {
            this.disableScrolling(); // we have to disable scrolling because if we unshift objects to the top of the array the page jumps to the top without smooth scrolling

            let widgets = this.widget_columns.mobile;
            let currentWidget = widgets.findBy('name', widget.name);
            let currentIndex = widgets.indexOf(currentWidget);

            if (direction == 'up' && currentIndex - 1 >= 0) {
                widgets.removeObject(currentWidget);
                widgets.insertAt(currentIndex - 1, currentWidget);
            } else if (direction == 'down' && currentIndex + 1 < widgets.length) {
                widgets.removeObject(currentWidget);
                widgets.insertAt(currentIndex + 1, currentWidget);
            } else if (direction == 'bottom') {
                widgets.removeObject(currentWidget);
                widgets.pushObject(currentWidget);
            } else if (direction == 'top') {
                widgets.removeObject(currentWidget);
                widgets.unshiftObject(currentWidget);
            }

            let widgetNames = widgets.reduce((acc, widget) => {
                acc.push(widget.name);
                return acc;
            }, []);

            this.saveDashboard({ mobile: widgetNames.join() });

            next(this, () => {
                this.enableScrolling(); // we can enable scrolling once again so we can smoothly scroll to correct position
                this.evented.storeEvent('insert', 'dashboard', null);
                let element = document.querySelector("[data-name='" + widget.name + "']");
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            });
        },

        async addWidget(widget) {
            let widgets = this.widget_columns.mobile;
            let widgetNames = widgets.reduce((acc, widget) => {
                acc.push(widget.name);
                return acc;
            }, []);
            widgetNames.unshiftObject(widget.name);
            await this.saveDashboard({ mobile: widgetNames.join() });
            this.evented.storeEvent('insert', 'dashboard', null);
        },
    },

    async doTheseBeforeRender() {
        set(this, 'loading', true);

        //check if 'user has saldo on' info is set to localstorage and if not set it
        //why? because first time user logs in dashboard is rendered before user-settings service has checked if user has saldos
        if (this.localstorage.getItem('saldosOn') === null) {
            await this.userSettings.setUserSettings();
        }

        set(this, 'loading', false);
    },

    refreshDebounce() {
        get(this, 'evented').resumed({ force: true });
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;

        set(this, 'refreshTime', new Date()); // this is shown in template
    },

    autorefresh() {
        if (this.isDestroyed) return;

        // stop autorefreshing after 3 days .. just for case
        if (get(this, 'evented').getOntime() > 3 * 24 * 3600) return;

        get(this, 'evented').resumed({ force: true });
        later(this, this.autorefresh, 30 * 60 * 1000); // 30 minutes
    },

    async loadDashboard() {
        let dashboards;
        try {
            dashboards = this.isMobileOrEditingMobile
                ? await this.store.query('dashboard-mobile', {})
                : await this.store.query('dashboard', {});
        } catch (error) {
            // no need for actions .. we just use what is in ls
            return;
        }

        if (this.isMobileOrEditingMobile) {
            // dashboard is different in database and localstorage .. update localstorage and inform widget_columns to reload
            if (
                JSON.stringify(this.localstorage.getItem('dashboard-mobile')) !==
                JSON.stringify(get(dashboards, 'firstObject') || null)
            ) {
                if (get(dashboards, 'firstObject'))
                    this.localstorage.setItem('dashboard-mobile', get(dashboards, 'firstObject'));
                else this.localstorage.removeItem('dashboard-mobile');
            }
        } else {
            // dashboard is different in database and localstorage .. update localstorage and inform widget_columns to reload
            if (
                JSON.stringify(this.localstorage.getItem('dashboard')) !==
                JSON.stringify(get(dashboards, 'firstObject') || null)
            ) {
                if (get(dashboards, 'firstObject'))
                    this.localstorage.setItem('dashboard', get(dashboards, 'firstObject'));
                else this.localstorage.removeItem('dashboard');
            }
        }
        this.notifyPropertyChange('widget_columns');
    },

    async saveDashboard(dashboard) {
        this.isMobileOrEditingMobile
            ? this.localstorage.setItem('dashboard-mobile', dashboard)
            : this.localstorage.setItem('dashboard', dashboard);
        this.notifyPropertyChange('widget_columns');
        const storeDashboards = this.isMobileOrEditingMobile
            ? this.store.peekAll('dashboard-mobile')
            : this.store.peekAll('dashboard');
        let storeBoard = '';
        if (get(storeDashboards, 'length') > 0) {
            storeBoard = get(storeDashboards, 'firstObject');
        } else {
            storeBoard = this.isMobileOrEditingMobile
                ? this.store.createRecord('dashboard-mobile', dashboard)
                : this.store.createRecord('dashboard', dashboard);
        }
        setProperties(storeBoard, dashboard);
        return storeBoard.save();
    },

    moveWidgets(move, moveAt = false, dropPlacement) {
        let lsWidgets = this.localstorage.getItem('dashboard');
        let mobileLsWidgets = this.localstorage.getItem('dashboard-mobile');
        let widgets = {};
        if (this.isMobileOrEditingMobile && mobileLsWidgets) {
            widgets = {
                mobile: this.mapWidgetsAndFilter(mobileLsWidgets.mobile),
            };
        } else if (!this.isMobileOrEditingMobile && lsWidgets) {
            widgets = {
                left: this.mapWidgetsAndFilter(lsWidgets.left),
                center: this.mapWidgetsAndFilter(lsWidgets.center),
                right: this.mapWidgetsAndFilter(lsWidgets.right),
            };
        } else {
            widgets = this.default_widgets;
        }

        if (this.isMobileOrEditingMobile)
            return {
                mobile: this.mapWidgetsToString(
                    widgets.mobile,
                    move,
                    moveAt,
                    'mobile',
                    dropPlacement,
                ),
            };
        return {
            left: this.mapWidgetsToString(widgets.left, move, moveAt, 'left', dropPlacement),
            center: this.mapWidgetsToString(widgets.center, move, moveAt, 'center', dropPlacement),
            right: this.mapWidgetsToString(widgets.right, move, moveAt, 'right', dropPlacement),
        };
    },

    mapWidgetsToString(widgets, move, moveAt, column, dropPlacement) {
        let widgetNames = widgets.map((item) => item.name);
        if (moveAt === column) {
            if (widgetNames.includes(move)) {
                widgetNames = widgetNames.filter((item) => item !== move);
            }
            widgetNames.push(move);
            return widgetNames.join(',');
        }

        let indexMove = widgetNames.indexOf(move);
        if (indexMove > -1) {
            widgetNames.splice(indexMove, 1);
        }

        let index1 = widgetNames.indexOf(moveAt);
        if (index1 > -1) {
            if (dropPlacement === 'above') {
                widgetNames.splice(index1, 0, move);
            } else {
                widgetNames.splice(index1 + 1, 0, move);
            }
        }

        return widgetNames.join(',');
    },

    mapWidgetsAndFilter(str) {
        if (!str) return [];
        return str
            .split(',')
            .map((name) => this.widgets.find((item) => item.name === name))
            .filter((item) => item);
    },

    disableScrolling() {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    },
    enableScrolling() {
        window.onscroll = function () {};
    },
    position(index, count) {
        if (index === 0) return 'top';
        if (index === count - 1) return 'bottom';
        return 'center';
    },
});
