import config from 'tt4/config/environment';
import getScript from 'tt4/utils/getScript';

export default {
    name: 'google-maps',

    initialize() {
        const url = `https://maps.googleapis.com/maps/api/js?key=${config.APP.GM_KEY}&libraries=geometry`;
        getScript(url);
    },
};
