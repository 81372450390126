/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/database-gps-force-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDatabaseForceGpsFieldComponent;

import Ember from 'ember';

import collectorField from '../../../../mixins/collector-field';

CollectorFieldsDatabaseForceGpsFieldComponent = Ember.Component.extend(collectorField, {
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    gps: Ember.inject.service(),
    classes: 'form-control add-fields',
    init: function () {
        this._super();
        this.set('forced_projects', Em.A([]));
        Ember.defineProperty(
            this,
            'modelValue',
            Ember.computed.oneWay('model.' + this.get('field.name')),
        );
        this.fetchProjects();
        return this.get('gps').on('lockedPositionChanged', () => {
            return this.fetchProjects();
        });
    },
    fetchProjects: function () {
        var forced, forced_params;
        forced = Ember.A([]);
        forced_params = {
            force_location: 'on',
            location_map: '!(empty)',
            limit: 'all',
        };
        this.addFilterOptionsToParams(forced_params);
        if (!this.get('gps').get('lockedPosition')) {
            return;
        }
        return this.get('store')
            .query('project', forced_params)
            .then((data) => {
                var gps;
                if (this.get('isDestroyed')) {
                    return;
                }
                this.set('forced_projects', forced);
                gps = this.get('gps');
                data.forEach((project) => {
                    var projLoc;
                    projLoc = JSON.parse(project.get('location_map'));
                    if (
                        project.get('location_map') &&
                        gps.withinAcceptedDistance(
                            {
                                lat: projLoc.latitude,
                                lon: projLoc.longitude,
                            },
                            {
                                lat: gps.get('lockedLatitude'),
                                lon: gps.get('lockedLongitude'),
                            },
                        )
                    ) {
                        return forced.pushObject(project);
                    }
                });
                if (
                    this.get('modelValue.id') &&
                    this.get('modelValue.force_location') === 'on' &&
                    !forced.findBy('id', this.get('modelValue.id'))
                ) {
                    return this.send('changeValue', null);
                }
            });
    },
    normal_projects: Ember.computed(function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                var params;
                params = {
                    force_location: 'off',
                };
                this.addFilterOptionsToParams(params);
                return this.get('store')
                    .query('project', params)
                    .then((data) => {
                        if (this.get('isDestroyed')) {
                            resolve({
                                value: null,
                            });
                            return;
                        }
                        return resolve({
                            value: data,
                        });
                    });
            }),
        });
    }),
    addFilterOptionsToParams: function (params) {
        var filterOptions, key, results;
        filterOptions = this.get('field.filterOptions');
        results = [];
        for (key in filterOptions) {
            results.push((params[key] = filterOptions[key]));
        }
        return results;
    },
    willDestroy: function () {
        return this.get('gps').off('lockedPositionChanged', this, 'fetchProjects');
    },
    actions: {
        changeValue: function (value) {
            if (value) {
                value = this.get('store').peekRecord(this.get('field.options.form'), value);
            }
            return this.sendAction('bubbleChange', value, this.get('field'));
        },
    },
});

export default CollectorFieldsDatabaseForceGpsFieldComponent;
