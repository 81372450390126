/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/changeusername/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditChangeusernameController;

import Ember from 'ember';

UsersEditChangeusernameController = Ember.Controller.extend({
    router: Em.inject.service(),
    session: Em.inject.service(),
    validated: Em.computed.and('model.isValid', 'new_username'),
    username_changed: false,
    old_username: null,
    //    setOldUsername: Em.on 'init', Em.observer 'model.username', ->
    //        username = @get 'model.username'
    //        if username and not @get 'old_username'
    //            @set 'old_username', username
    editingCurrentUser: Em.computed('model.username', function () {
        return this.get('model.username') === this.get('session.currentUser.username');
    }),
    actions: {
        username_changed: function (value, fieldName) {
            var model;
            this.set('new_username', value);
            model = this.get('model');
            return model.set('username', value);
        },
        // validation is handled by field-comp component
        //model.validate(['username'])
        change_username: function (new_username) {
            var model;
            model = this.get('model');
            if (model.get('isSaving')) {
                return;
            }
            return model.validate(['username']).then(() => {
                if (model.get('isSaving')) {
                    return;
                }
                if (model.get('isValid')) {
                    return model.save().then(() => {
                        this.set('username_changed', true);
                        if (this.get('editingCurrentUser')) {
                            return this.get('router').transitionTo('logout');
                        } else {
                            return Em.run.later(
                                this,
                                function () {
                                    this.replaceRoute('users.edit', model.get('id'));
                                    return this.set('username_changed', false);
                                },
                                2000,
                            );
                        }
                    });
                }
            });
        },
    },
});

export default UsersEditChangeusernameController;
