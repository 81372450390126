import Component from '@ember/component';
import { get, set } from '@ember/object';

/*
Show skeleton component on widget first load, and normal loader on subsequent loads
Just dump this component in the first line of the widget's template

{{dash-board/widget-spinner promise=promise skeletonRows=<number>}}

If you have more than one promise use the promise-all helper
{{dash-board/widget-spinner promise=(promise-all promise1 promise2 promise3)}}

*/

export default Component.extend({
    skeletonRows: 4,
    firstLoad: true,

    async didUpdateAttrs() {
        if (!get(this, 'firstLoad')) return;
        try {
            await get(this, 'promise');
            set(this, 'firstLoad', false);
        } catch (e) {
            set(this, 'firstLoad', false);
        }
    },
});
