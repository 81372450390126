import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';

const DevelopmentIntegrationsController = Controller.extend({
    Collector: service('collector-service'),
    store: service(),
    dialogs: service(),
    intl: service(),

    saving: false,
    content: alias('model'),
    export_salary_on: computed('session.currentUser', function () {
        return this.Collector.testNeeds(['products.export_salary']);
    }),

    export_absence_on: computed('session.currentUser', function () {
        return this.Collector.testNeeds(['products.export_absence']);
    }),

    export_salary_integrations: computed('model.[]', function () {
        return this.model.filter((item) => item.type === 'export_salary');
    }),

    export_absence_integrations: computed('model.[]', function () {
        return this.model.filter((item) => item.type === 'export_absence');
    }),

    actions: {
        turn_export_salary(onoff) {
            return this.turn('export_salary', onoff);
        },

        turn_export_absence(onoff) {
            return this.turn('export_absence', onoff);
        },

        add_new_integration(type) {
            return this.store.createRecord('integration', { type });
        },

        async save_integrations() {
            set(this, 'saving', true);
            const promises = [];
            this.model
                .filter((record) => record.hasDirtyAttributes)
                .forEach((record) => promises.push(record.save()));

            await EmberPromise.all(promises);

            return set(this, 'saving', false);
        },

        async delete(row) {
            const con = await this.dialogs.confirm(this.intl.t('report.confirm_delete'));
            if (con) {
                return row.destroyRecord();
            }
        },
    },

    turn(product, onoff) {
        let promise;
        console.log('turning ' + product, onoff); // eslint-disable-line

        if (onoff === 'on') {
            promise = this.Collector.addProduct(product);
        } else {
            promise = this.Collector.removeProduct(product);
        }

        return promise.then(() => window.location.reload());
    },
});

export default DevelopmentIntegrationsController;
