/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/salaryperiods/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller;

import Ember from 'ember';

Controller = Ember.Controller.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    report: Ember.computed('filters.@each.value', function () {
        return Ember.Object.create({
            form: 'wage_period',
            refreshRows: true,
            order: 'period_start_date-,period_end_date-',
            query: this.get('filters').map(function (filter) {
                if (filter.get('field') === 'period_start_date') {
                    return {
                        name: 'period_start_date:period_end_date',
                        value: filter.get('value'),
                    };
                } else {
                    return {
                        name: filter.get('field'),
                        value: filter.get('value'),
                    };
                }
            }),
        });
    }),
    checked_rows: [],
    filters: Em.A([
        Em.Object.create({
            field: 'period_start_date',
            type: 'date',
            form: 'wage_period',
        }),
    ]),
    addSingleButton: Ember.computed(function () {
        return (
            this.get('intl').t('general.add') +
            ' ' +
            this.get('intl').t('salary_period.adder.single').toLowerCase()
        );
    }),
    actions: {
        showAdd: function (onoff) {
            this.set('record', null);
            this.set('showAddPopup', onoff);
            return false;
        },
        editRow: function (row) {
            this.set('record', this.get('store').peekRecord(this.get('report.form'), row.id));
            this.set('showAddPopup', true);
            return false;
        },
        periodAdded: function () {
            this.send('showAdd', false);
            return this.get('report').notifyPropertyChange('refreshRows');
        },
        filterChanged: function (value, field) {
            if (value == null) {
                return this.set(field, null);
            } else {
                return this.set(field, value);
            }
        },
        removeCheckedRows: function (rows) {
            if (this.get('checked_rows.length') > 0) {
                return this.get('dialogs')
                    .confirm(this.get('intl').t('report.confirm_delete'))
                    .then((con) => {
                        if (con) {
                            return rows.forEach((row) => {
                                console.log(this.get('form'));
                                row = this.get('store').peekRecord(this.get('report.form'), row.id);
                                if (row.get('row_info.privilege_remove') === false) {
                                    this.get('dialogs').alert(
                                        this.get('intl').t(
                                            'general_report.mass_delete.not_allowed',
                                        ),
                                    );
                                    return;
                                }
                                return row.destroyRecord();
                            });
                        }
                    });
            }
        },
        checkedRows: function (rows) {
            this.set('checked_rows', rows);
        },
    },
});

export default Controller;
