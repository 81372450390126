import { inject as service } from '@ember/service';

import Component from '@ember/component';
import pdf_measurement_mixin from '../mixins/sitesafety-export';
import formatted_pdf_measurement_mixin from '../mixins/sitesafety-formatted-export';
import { get, set, computed } from '@ember/object';
import { A } from '@ember/array';

const RecordListComponent = Component.extend(
    pdf_measurement_mixin,
    formatted_pdf_measurement_mixin,
    {
        store: service(),
        analytics: service(),
        Collector: service('collector-service'),
        error: service(),
        fileExports: service(),
        intl: service(),
        tagName: 'ul',
        class: 'list-group',
        fieldSettings: A({
            report: true,
            preview: true,
            title: true,
            show: true,
        }),

        currentForm: computed('model', function () {
            return get(this, 'model.constructor.modelName');
        }),

        exports: computed('model', function () {
            if (
                get(this, 'model.constructor.modelName') === 'tr' ||
                get(this, 'model.constructor.modelName') === 'mvr'
            ) {
                return [
                    {
                        export: 'measurement_pdf',
                        translation: get(this, 'intl').t('general.export'),
                    },
                    {
                        export: 'formatted_measurement_pdf',
                        translation: get(this, 'intl').t('site_safety.export_formatted'),
                    },
                ];
            } else {
                return [{ export: 'pdf', translation: get(this, 'intl').t('general.export') }];
            }
        }),
        actions: {
            async export(type) {
                get(this, 'analytics').trackEvent({ category: 'Show page export', action: type });
                set(this, 'showLoader', true);
                if (type === 'pdf') {
                    try {
                        const form = get(this, 'model.constructor.modelName').replace(/-/g, '_');
                        await this.fileExports.createPdfFull(
                            form,
                            [{ id: get(this, 'model.id') }],
                            {},
                        );
                    } catch (error) {
                        this.error.notify(error);
                    } finally {
                        set(this, 'showLoader', false);
                    }
                } else if (type === 'measurement_pdf') {
                    this.exportMeasurementPdf(get(this, 'fields'), get(this, 'model'));
                    return set(this, 'showLoader', false);
                } else if (type === 'formatted_measurement_pdf') {
                    let this_fields;
                    const modelsArr = [get(this, 'model')];
                    if (get(this, 'model.constructor.modelName') === 'tr') {
                        this_fields = this.Collector.fields('tr');
                    } else {
                        this_fields = this.Collector.fields('mvr');
                    }
                    return this.createMeasurementPdf(this_fields, modelsArr).then(() =>
                        this._setLoaderFalse(),
                    );
                }
            },

            filterFields(field) {
                return !field.features?.hide_column;
            },
        },
        _setLoaderFalse() {
            return set(this, 'showLoader', false);
        },
    },
);

export default RecordListComponent;
