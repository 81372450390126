import Component from '@ember/component';
import { get } from '@ember/object';
import { typeOf } from '@ember/utils';

export default Component.extend({
    //radio-groups will set these automatically when you change them so you can just get these directly
    init() {
        this._super();

        let settings = get(this, 'settings');

        if (typeOf(settings.pause) == 'string') {
            this.pause = 'custom';
            this.customPause = parseInt(settings.pause);
        } else {
            this.pause = settings.pause;
        }

        if (typeOf(settings.editTime) == 'string') {
            this.editTime = 'custom';
            this.customEditTime = parseInt(settings.editTime);
        } else {
            this.editTime = settings.editTime;
        }
    },
});
