/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/timecard/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimecardAddController;

import Ember from 'ember';

import collector from '../../../../classes/collector';

import FormatUtil from '../../../../utils/format';

import config from '../../../../config/environment';

import { copy } from 'ember-copy';

import $ from 'jquery';

import { captureException } from '@sentry/browser';

TimecardAddController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    sessionhelper: Ember.inject.service(),
    gui: Ember.inject.service(),
    Google: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    gps: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    clock: Ember.inject.service('timecard-clock'),
    currentDate: null,
    currentTime: null,
    isTogglerVisible: false,
    validForm: true,
    refreshing: false,
    dayStart: '---',
    custom_projects: Em.A([]),
    force_location: Ember.computed('openWorktime.project.force_location', function () {
        if (!this.get('Collector').fieldExists('project', 'force_location')) {
            return false;
        }
        if (this.get('openWorktime.project.force_location') === void 0) {
            // The project hasn't loaded this field at all so we keep it as 'forced' until everything is loaded
            return true;
        }
        return (
            this.get('openWorktime.project.force_location') &&
            Ember.isPresent(this.get('openWorktime.project.location_map'))
        );
    }),
    totalWorkHours: '00:00',
    totalWorkRaw: 0,
    init: function () {
        this._super(...arguments);
        this.checkMandatoryFields();
        this.resume();
        //@updateTimeProperty()
        if (collector.forms.worktime.objects.project.features.mandatory) {
            this.set('projectIsMandatory', true);
        }
        return this.clock; //ember lazily loads injections, so this needs to be called somehow somewhere to get the clock ticking
    },
    resume: function () {
        return this.get('sessionhelper').on('resumed', () => {
            return this.onresume();
        });
    },
    onresume: function () {
        var create_new, self;
        self = this;
        create_new = true;
        this.set('refreshing', true);
        return this.get('store')
            .query('worktime', {
                date:
                    moment().subtract(1, 'day').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
                user: 'self',
                sideload: true,
                order: 'date-,endtime-',
            })
            .then(function (data) {
                var project, task, unsavedRecords, worktime;
                self.set('refreshing', false);
                if (data.get('firstObject')) {
                    data.forEach(function (worktime) {
                        if (!worktime.get('endtime')) {
                            create_new = false;
                            self.get('store').findRecord('project', worktime.get('project.id'), {
                                reload: true,
                            });
                            self.set('openWorktime', worktime);
                            return self.notifyPropertyChange('isForcedAndNotCloseEnough');
                        }
                    });
                }
                if (create_new) {
                    unsavedRecords = self.get('store').peekAll('worktime').filterBy('isNew');
                    if (unsavedRecords.length > 0) {
                        worktime = unsavedRecords[0];
                    } else {
                        worktime = self.store.createRecord('worktime', {
                            on_worksite: true,
                        });
                    }
                    project = self.get('localStorageWrapper').getUserSetting('project');
                    if (self.get('session.currentUser.currentMySite')) {
                        project = self.get('session.currentUser.currentMySite.id');
                    }
                    if (project && Ember.typeOf(project) !== 'array') {
                        self.store.findRecord('project', project).then(function (record) {
                            return worktime.set('project', record);
                        });
                    }
                    if (self.get('Collector').testNeeds(['worktime.task'])) {
                        task = self.get('localStorageWrapper').getUserSetting('task');
                        if (task && Ember.typeOf(task) !== 'array') {
                            self.store.findRecord('task', task).then(function (record) {
                                return worktime.set('task', record);
                            });
                        }
                    }
                    self.set('openWorktime', worktime);
                    self.set('newWorktime', worktime);
                    return self.set('started_worktime', false);
                }
            });
    },
    //data.get('content').push(worktime)
    worktimeDuration: Ember.computed('clock.time', function () {
        var clockedTime, diff, h, m, s, start;
        if (
            this.get('started_worktime') ||
            (this.get('openWorktime') != null && this.get('openWorktime').get('starttime'))
        ) {
            if (this.get('started_worktime')) {
                start = moment(this.get('started_worktime'));
            } else {
                start = moment(
                    this.get('openWorktime').get('date') +
                        ' ' +
                        this.get('openWorktime').get('starttime'),
                );
            }
            diff = moment().diff(start, 'seconds');
            //if diff < 0 then return "00:00:00"
            h = parseInt(diff / 3600) % 24;
            m = parseInt(diff / 60) % 60;
            s = diff % 60;
            if (diff < 0) {
                m = Math.abs(m);
                s = Math.abs(s);
                h = Math.abs(h);
            }
            if (h < 10) {
                h = '0' + h;
            }
            if (m < 10) {
                m = '0' + m;
            }
            if (s < 10) {
                s = '0' + s;
            }
            clockedTime = diff < 0 ? '-' + h + ':' + m + ':' + s : h + ':' + m + ':' + s;
            this.calculateTotalWorkingTime(diff);
            return clockedTime;
        } else {
            return '00:00:00';
        }
    }),
    calculateTotalWorkingTime: function (seconds) {
        var tmp_totalwork, tmp_totalwork_hours, tmp_totalwork_mins, totalWorkHours;
        //calculate total workingtime
        tmp_totalwork = seconds / 3600 + this.get('totalWorkRaw'); // as hours
        tmp_totalwork_hours = Math.floor(tmp_totalwork);
        tmp_totalwork_mins = Math.floor((tmp_totalwork - tmp_totalwork_hours) * 60);
        if (tmp_totalwork < 0) {
            tmp_totalwork_hours = Math.abs(tmp_totalwork_hours);
            tmp_totalwork_mins = Math.abs(tmp_totalwork_mins);
        }
        if (tmp_totalwork_hours < 10) {
            tmp_totalwork_hours = '0' + tmp_totalwork_hours;
        }
        if (tmp_totalwork_mins < 10) {
            tmp_totalwork_mins = '0' + tmp_totalwork_mins;
        }
        totalWorkHours =
            tmp_totalwork < 0
                ? '-' + tmp_totalwork_hours + ':' + tmp_totalwork_mins
                : tmp_totalwork_hours + ':' + tmp_totalwork_mins;
        return this.set('totalWorkHours', totalWorkHours);
    },
    setMySitesProject: function () {
        if (this.get('session.currentUser.currentMySite') && !this.get('openWorktime.id')) {
            return this.set('openWorktime.project', this.get('session.currentUser.currentMySite'));
        } else if (
            this.get('session.currentUser.currentMySite') &&
            this.get('session.currentUser.currentMySite') !== this.get('openWorktime.project')
        ) {
            return this.get('notifications').warning(
                this.get('intl').t('timetracker.timecard.already_started_other_project'),
                {
                    autoClear: true,
                },
            );
        }
    },
    projectField: Ember.computed('openWorktime.project', function () {
        var field;
        field = this.get('Collector').fieldArray('worktime', ['project'])[0];
        field.value = this.get('openWorktime.project.id');
        return field;
    }),
    currentMySiteTooFar: Ember.computed(
        'gps.lockedLatitude',
        'gps.lockedLongitude',
        'session.currentUser.currentMySite.location_map',
        'session.currentUser.currentMySite.force_location',
        function () {
            var loc, projLoc;
            if (
                !this.get('session.currentUser.currentMySite') ||
                !this.get('session.currentUser.currentMySite.location_map') ||
                !this.get('session.currentUser.currentMySite.force_location')
            ) {
                return;
            }
            loc = this.get('session.currentUser.currentMySite.location_map');
            projLoc = JSON.parse(loc);
            if (
                !this.get('gps').withinAcceptedDistance(
                    {
                        lat: projLoc.latitude,
                        lon: projLoc.longitude,
                    },
                    {
                        lat: this.get('gps.lockedLatitude'),
                        lon: this.get('gps.lockedLongitude'),
                    },
                )
            ) {
                Ember.run.next(this, () => {
                    return this.set('openWorktime.project', null);
                });
                return true;
            }
        },
    ),
    sortProperties: ['date:desc', 'starttime:asc'],
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    WorkHoursObs: function () {
        var hours, mins, sorted_model, total;
        if (this.get('model').get('firstObject')) {
            sorted_model = this.get('sortedModel');
            if (sorted_model.get('firstObject').get('starttime')) {
                this.set('dayStart', sorted_model.get('firstObject').get('starttime'));
            }
            total = 0;
            this.get('model').forEach(function (worktime) {
                if (
                    worktime.get('work_hours') &&
                    worktime.get('date') === moment().format('YYYY-MM-DD')
                ) {
                    return (total = total + worktime.get('work_hours'));
                }
            });
            if (total > 0) {
                hours = parseInt(total);
                mins = Math.round((total - hours) * 60);
                if (mins < 10) {
                    mins = '0' + mins;
                }
                if (hours < 10) {
                    hours = '0' + hours;
                }
                this.set('totalWorkRaw', total);
                return this.set('totalWorkHours', hours + ':' + mins);
            }
        }
    }.observes('model'),
    isForcedAndNotCloseEnough: Ember.computed(
        'openWorktime.project',
        'force_location',
        'gps.lockedLongitude',
        'gps.lockedLatitude',
        function () {
            var gps, projLoc, project;
            project = this.get('openWorktime.project');
            if (!project) {
                return;
            }
            if (this.get('force_location') && project.get('location_map')) {
                gps = this.get('gps');
                projLoc = JSON.parse(project.get('location_map'));
                if (
                    gps.withinAcceptedDistance(
                        {
                            lat: projLoc.latitude,
                            lon: projLoc.longitude,
                        },
                        {
                            lat: gps.get('lockedLatitude'),
                            lon: gps.get('lockedLongitude'),
                        },
                    )
                ) {
                    return false;
                }
                return true;
            } else if (this.get('force_location') && !project.get('location_map')) {
                return true;
            }
            return false;
        },
    ),
    latlng: Ember.computed(
        'gps.lockedLatitude',
        'gps.lockedLongitude',
        'gps.currentAccuracy',
        function () {
            return {
                lat: this.get('gps.lockedLatitude'),
                lng: this.get('gps.lockedLongitude'),
                accuracy: this.get('gps.currentAccuracy'),
            };
        },
    ),
    projectMarker: Ember.computed('openWorktime.project.location_map', function () {
        var projLoc;
        if (this.get('openWorktime.project.location_map')) {
            projLoc = JSON.parse(this.get('openWorktime.project.location_map'));
            return [
                {
                    position: {
                        lat: projLoc.latitude,
                        lng: projLoc.longitude,
                        accuracy: this.get('customerSettings').checkSetting('gps_distance') || 700,
                    },
                    title: this.get('openWorktime.project.name'),
                },
            ];
        } else {
            return [];
        }
    }),
    projectMissing: Ember.computed('openWorktime.project', function () {
        var project;
        project = this.get('openWorktime.project');
        if (
            collector.forms.worktime.objects.project.features.mandatory &&
            !this.get('openWorktime.project.id')
        ) {
            return true;
        } else {
            return false;
        }
    }),
    loading: true,
    validation: null,
    checkMandatoryFields: function () {
        var self;
        self = this;
        return $.each(collector.forms.worktime.objects, function (field, settings) {
            if (settings.features.mandatory && field !== 'project' && field !== 'user') {
                return self.set('validForm', false);
            }
        });
    },
    location: Ember.computed(function () {
        return this.get('Collector').fieldExists('worktime', 'location');
    }),
    hasError: Ember.computed(function () {
        var error_types, errors;
        errors = collector.getErrors('worktime');
        error_types = this.Collector.parseFormErrors(errors);
        this.set('errorMessages', error_types);
        if (errors) {
            return true;
        }
        return false;
    }),
    calcAutoKm: Em.computed(function () {
        if (this.get('Collector').testNeedsOne('products.timetracker_km_automatic')) {
            return true;
        }
    }),
    showEndLocation: Em.computed(function () {
        return this.get('Collector').fieldExists('worktime', 'end_location');
    }),
    showTasks: Em.computed(function () {
        return (
            this.get('Collector').fieldExists('worktime', 'task') &&
            !this.get('taskField.features.hide')
        );
    }),
    taskField: Ember.computed('openWorktime.project', function () {
        var field;
        //return if !@get('showTasks')
        field = this.get('Collector').fieldArray('worktime', ['task'])[0];
        field.value = this.get('openWorktime.task.id');
        return field;
    }),
    commentField: Ember.computed('openWorktime.description', function () {
        var field;
        field = this.get('Collector').fieldArray('worktime', ['description'])[0];
        field.value = this.get('openWorktime.description');
        return field;
    }),
    showExtrainfoModal: false,
    modalFields: Em.computed('openWorktime', function () {
        var c, fields, form, record, retFields;
        c = this.get('Collector');
        form = 'worktime';
        record = this.get('openWorktime');
        if (!record) {
            return;
        }
        fields = c.fieldArray(form);
        retFields = [];
        // Fetch form fields with collector service
        fields.forEach((field) => {
            var db_record, ref, ref1, user_address, value_from_ls;
            if (field.features.stashed) {
                return false;
            }
            if (
                (ref = field.name) === 'group' ||
                ref === 'endtime' ||
                ref === 'description' ||
                ref === 'employer' ||
                ref === 'location' ||
                ref === 'work_hours' ||
                ref === 'customer'
            ) {
                return;
            }
            if (
                !Em.isEmpty(record.get(field.name)) &&
                record.get(field.name) !== 0 &&
                field.type !== 'checkbox'
            ) {
                return;
            }
            if ((ref1 = field.type) === 'dropdown_values' || ref1 === 'dropdown_options') {
                value_from_ls = this.get('localStorageWrapper').getUserSetting(
                    'select_' + field.name,
                );
                if (value_from_ls && Ember.isPresent(value_from_ls)) {
                    Ember.set(field, 'value', value_from_ls);
                    record.set(field.name, value_from_ls);
                }
            }
            if (field.type === 'database') {
                value_from_ls = this.get('localStorageWrapper').getUserSetting(field.name);
                //fetch that record
                if (value_from_ls && Ember.isPresent(value_from_ls)) {
                    db_record = this.get('store').peekRecord(
                        field.options.form,
                        parseInt(value_from_ls, 10),
                    );
                    record.set(field.name, db_record);
                    field.value = parseInt(value_from_ls, 10);
                }
            }
            if (
                field.name === 'pause' &&
                Ember.get(field, 'features.default_value') &&
                !this.get('openWorktime.pause')
            ) {
                if (typeof Ember.get(field, 'features.default_value') === 'object') {
                    this.set(
                        'openWorktime.pause',
                        Ember.get(field, 'features.default_value.value'),
                    );
                } else {
                    this.set('openWorktime.pause', Ember.get(field, 'features.default_value'));
                }
            }
            if (
                field.name === 'affects_overtime' &&
                Ember.get(field, 'features.default_value') &&
                !this.get('openWorktime.affects_overtime')
            ) {
                this.set(
                    'openWorktime.affects_overtime',
                    Ember.get(field, 'features.default_value'),
                );
            }
            if (field.name === 'km' && this.get('calcAutoKm')) {
                if (
                    !this.get('session.currentUser.address') ||
                    !this.get('session.currentUser.city')
                )
                    return;

                user_address =
                    this.get('session.currentUser.address') +
                    ', ' +
                    this.get('session.currentUser.city');
                if (this.get('session.currentUser.fin_address')) {
                    user_address =
                        this.get('session.currentUser.fin_address') +
                        ', ' +
                        this.get('session.currentUser.fin_city');
                }
                this.get('store')
                    .findRecord('project', record.get('project.id'), {
                        reload: true,
                    })
                    .then((project) => {
                        var project_address;
                        if (!project.get('address') || !project.get('city')) {
                            return this.set('openWorktime.km', null);
                        }
                        project_address = project.get('address') + ', ' + project.get('city');
                        return this.get('Google')
                            .routeDistance(user_address, project_address)
                            .then((dist) => {
                                if (dist) {
                                    return this.set('openWorktime.km', dist);
                                } else {
                                    return this.set('openWorktime.km', null);
                                }
                            });
                    });
            }
            return retFields.push(copy(field));
        });
        return retFields;
    }),
    currentSaldo: function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('Collector')
                    .ttapi({
                        url: 'saldo/get_saldo?userid=' + this.session.currentUser.id,
                    })
                    .then((back) => {
                        return resolve({
                            value: back['saldo'] / 60,
                            from: back['from'],
                            to: back['to'],
                        });
                    });
            }),
        });
    },
    actions: {
        startWork: function () {
            var date, row, self, starttime, store;
            if (this.get('hasError')) {
                return;
            }
            self = this;
            row = this.get('openWorktime');
            store = this.get('store');
            date = moment().format('YYYY-MM-DD');
            starttime = moment().format('HH:mm');
            if (this.get('model.length') === 0) {
                this.set('dayStart', starttime);
            }
            row.set('starttime', starttime);
            row.set('date', date);
            row.set('validation', 'off');
            this.set('apiError', null);
            return row.save().then(
                () => {
                    self.set('started_worktime', moment());
                    this.set('startWasClicked', true);
                    Em.run.later(() => {
                        return this.set('startWasClicked', false);
                    }, 3500);
                    this.get('notifications').success(
                        this.get('intl').t('timetracker.timecard.started'),
                        {
                            autoClear: true,
                        },
                    );
                    return this.WorkHoursObs(); // calculate workhours again
                },
                (error) => {
                    var errorObj;
                    if (error.errors[0].error) {
                        errorObj = error.errors[0];
                    } else {
                        errorObj = error.errors;
                    }
                    // overlapping worktimes, give user option to start this worktime at the last worktime endtime
                    if (errorObj.error === 'overlap') {
                        this.set('started_worktime', false);
                        this.set('openWorktime.starttime', false);
                        if (errorObj.errorMeta.hits === 'absence') {
                            return this.get('dialogs').alert(
                                this.get('intl').t('timetracker.timecard.overlap_absence'),
                            );
                        } else {
                            //@set 'startWasClicked', false
                            return this.get('dialogs')
                                .confirm(this.get('intl').t('timetracker.timecard.confirm_overlap'))
                                .then((con) => {
                                    if (con) {
                                        // model has worktimes sorted by date and endtime, so first element has always the latest endtime
                                        row.set('starttime', this.get('model.firstObject.endtime'));
                                        return row.save().then(() => {
                                            this.set('startWasClicked', true);
                                            Em.run.later(() => {
                                                return this.set('startWasClicked', false);
                                            }, 3500);
                                            return this.get('notifications').success(
                                                this.get('intl').t('timetracker.timecard.started'),
                                                {
                                                    autoClear: true,
                                                },
                                            );
                                        });
                                    }
                                });
                        }
                    } else {
                        //@WorkHoursObs() # calculate workhours again
                        captureException(error);
                        this.set(
                            'apiError',
                            JSON.stringify(error.errors != null ? error.errors : error),
                        );
                        if (error.errors != null) {
                            this.set('started_worktime', false);
                            row.set('starttime', void 0);
                            row.set('date', void 0);
                            return row.set('isError', false);
                        }
                    }
                },
            );
        },
        //                    self.set('openWorktime', self.store.createRecord('worktime', {}))
        endWork: function () {
            if (this.get('modalFields.length') === 0) {
                return this.send('stopWorkAndSave');
            } else {
                return this.set('showExtrainfoModal', true);
            }
        },
        stopWorkAndSave: function (type) {
            var field, fieldValue, i, len, ref, self, worktime;
            this.set('apiError', null);
            self = this;
            worktime = this.get('openWorktime');
            worktime.set('endtime', moment().format('HH:mm'));
            ref = this.get('Collector').fieldArray('worktime');
            //get all database fields and save them to LS
            for (i = 0, len = ref.length; i < len; i++) {
                field = ref[i];
                if (field.type === 'database') {
                    fieldValue = worktime.get(field.name);
                    this.get('localStorageWrapper').setUserSetting(
                        field.name,
                        fieldValue ? fieldValue.id : '',
                    );
                }
            }
            // a bit of a gimmick
            // if startdate is same as current date..
            // and starttime is in future..
            // ..then set endtime same as starttime
            if (
                worktime.get('date') === moment().format('YYYY-MM-DD') &&
                worktime.get('starttime') > moment().format('HH:mm')
            ) {
                worktime.set('endtime', worktime.get('starttime'));
            }
            return worktime.save().then(
                (saved) => {
                    var new_rec, new_worktime;
                    this.get('notifications').success(this.get('intl').t('general.saved'), {
                        autoClear: true,
                    });
                    if (this.get('Collector').testNeeds(['products.saldo'])) {
                        this.currentSaldo().then((saldo) => {
                            var format;
                            format = FormatUtil.create();
                            return this.get('notifications').success(
                                this.get('intl').t('saldo.your_saldo_now') +
                                    ' ' +
                                    format.hours(saldo.value),
                                {
                                    autoClear: true,
                                },
                            );
                        });
                    }
                    new_rec = {
                        on_worksite: true,
                    };
                    if (type === 'task') {
                        new_rec.project = worktime.get('project');
                    }
                    if (type === 'project' && this.get('showTasks')) {
                        new_rec.task = worktime.get('task');
                    }
                    if (!type) {
                        new_rec.project = worktime.get('project');
                        if (this.get('Collector').testNeeds(['worktime.task'])) {
                            new_rec.task = worktime.get('task');
                        }
                    }
                    new_worktime = this.store.createRecord('worktime', new_rec);
                    this.set('newWorktime', new_worktime);
                    this.set('openWorktime', new_worktime);
                    //@set("model", new_worktime)
                    this.set('started_worktime', null);
                    //@WorkHoursObs()
                    return this.send('refreshModel');
                },
                (error) => {
                    captureException(error);
                    this.set(
                        'apiError',
                        JSON.stringify(error.errors != null ? error.errors : error),
                    );
                    if (error.errors != null) {
                        if (error.errors[0].errorMeta.hits === 'absence') {
                            this.get('dialogs').alert(
                                this.get('intl').t('timetracker.timecard.overlap_absence'),
                            );
                        }
                        return worktime.set('endtime', null);
                    }
                },
            );
        },
        change_task: function (value) {
            return this.set('openWorktime.task', value);
        },
        cancelExtrainfoModal: function () {
            return this.set('showExtrainfoModal', false);
        },
        saveExtrainfoModal: function () {
            this.set('showExtrainfoModal', false);
            return this.send('stopWorkAndSave');
        },
        emptyMySites: function (value) {
            if (value && value.get('id') !== this.get('session.currentUser.currentMySite')) {
                return this.set('session.currentUser.currentMySite', null);
            } else if (!value) {
                return this.set('session.currentUser.currentMySite', null);
            }
        },
    },
});

//)
export default TimecardAddController;
