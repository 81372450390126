import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),
    ttapi: service(),
    error: service(),
    intl: service(),
    localstorage: service(),

    init() {
        this._super();
        let forms = [];
        if (this.Collector.testNeedsOne('worktime')) forms.push('worktime');
        if (this.Collector.testNeedsOne('abcense')) forms.push('abcense');
        this.forms = forms;

        this.loadProjects();
    },

    actions: {
        check(row) {
            this.toggleCheckRow(row);
        },
        checkAll() {
            if (this.isChecked) {
                this.toggleCheckAll('missing_worktimes', false);
            } else {
                this.toggleCheckAll('missing_worktimes', true);
            }
            this.toggleProperty('isChecked');
        },

        sendNotificationToCheckedUsers() {
            this.sendNotificationToCheckedUsers();
        },
        showAddModal(user, date) {
            set(this, 'selectedUser', user);
            set(this, 'selectedDate', date);
            set(this, 'showAddModal', true);
        },
    },

    async loadProjects() {
        const projectSums = {};
        this.data.forEach((item) => {
            const message = JSON.parse(item.message);
            if (!message.project) return;
            if (projectSums[message.project]) projectSums[message.project]++;
            else projectSums[message.project] = 1;
        });

        const projects = await this.store.query('project', {
            id: Object.keys(projectSums).join(','),
        });

        const errorsByProject = Object.keys(projectSums).map((projectid) => {
            return {
                project: { name: projects.findBy('id', projectid).name, id: projectid },
                errors: projectSums[projectid],
            };
        });

        set(this, 'projects', errorsByProject);
    },
});
