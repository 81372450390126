/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/modal-scroller.coffee.old
// Generated by CoffeeScript 2.6.1
var ModalScrollerService;

import Scroller from 'tt4/services/scroller';

import $ from 'jquery';

ModalScrollerService = Scroller.extend({
    scrollVertical: function () {
        this.notifyPropertyChange('scrollVerticalCalled');
        return this._super(...Array.from(arguments));
    },
    scrollable: Em.computed('scrollVerticalCalled', function () {
        return $('.record-comp-content');
    }),
});

export default ModalScrollerService;
