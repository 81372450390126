/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/form-settings.coffee.old
// Generated by CoffeeScript 2.6.1
var FormSettingsMixin;

import Ember from 'ember';

FormSettingsMixin = Ember.Mixin.create({
    intl: Ember.inject.service(),
    translatedName: Ember.computed('name', function () {
        var form, name;
        name = this.get('name');
        if (/^\$lang\(.+\)$/i.test(name)) {
            form = this.get('form.external_type');
            if (form) {
                name = this.get('intl').t(
                    this.get('form.external_type') + '.' + this.get('external_type'),
                );
            } else {
                name = this.get('intl').t('form.' + this.get('external_type'));
            }
        }
        return name;
    }),
    type: Ember.computed('value', function () {
        if (
            this.get('value') === 'true' ||
            this.get('value') === 'false' ||
            typeof this.get('value') === 'boolean'
        ) {
            return 'boolean';
        }
        return 'text';
    }),
});

export default FormSettingsMixin;
