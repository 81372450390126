/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SkyddsrondAddRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import toTop from '../../../mixins/reset-scroll';

SkyddsrondAddRoute = Ember.Route.extend(AuthenticatedRouteMixin, toTop, {
    Collector: Ember.inject.service('collector-service'),
    model: function () {
        var default_values, fields;
        fields = this.get('Collector').fieldArray('skyddsrond');
        default_values = {};
        fields.forEach(function (item) {
            if (item.type === 'radio') {
                return (default_values[item.name] = '$ej_akt');
            }
        });
        return this.get('store').createRecord('skyddsrond', default_values);
    },
    actions: {
        transitionToSaved: function (record) {
            return this.transitionTo('skyddsronds.saved', record);
        },
    },
});

export default SkyddsrondAddRoute;
