import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Component.extend({
    router: service(),
    tagName: '',
    actions: {
        transitionToMassedit() {
            if (isEmpty(this.selected)) return;
            let mappedIds = this.selected.map((row) => row.id);
            this.router.transitionTo('massedit', this.form, mappedIds.join(','));
        },
    },
});
