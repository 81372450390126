/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldo/calendar-card/component.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoCalendarCardComponent;

import Ember from 'ember';

import { htmlSafe } from '@ember/string';

UsersEditSaldoCalendarCardComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    plus: Ember.computed('day_saldo', function () {
        if (this.get('day_saldo') > 0) {
            return '+';
        }
    }),
    positiveornegative: Ember.computed('day_saldo', function () {
        if (this.get('day_saldo') > 0) {
            return 'positive';
        }
        if (this.get('day_saldo') < 0) {
            return 'negative';
        }
        return '';
    }),
    emptyday: Ember.computed('saldo', function () {
        if (
            parseInt(this.get('saldo.workday_length'), 10) === 0 &&
            parseInt(this.get('saldo.workday_hours'), 10) === 0
        ) {
            return true;
        }
    }),
    notStarted: Ember.computed('saldo', function () {
        if (this.get('saldo.saldo') === 'not_started') {
            return true;
        }
    }),
    notStartedText: Ember.computed(function () {
        return htmlSafe(this.get('intl').t('saldo.calculation_not_started'));
    }),
    notCalculatedYet: Ember.computed('saldo', function () {
        var diff, today;
        today = moment().format('YYYY-MM-DD');
        diff = moment.utc(this.get('saldo.date')).diff(moment.utc(today), 'days');
        if (diff > 0) {
            return true;
        }
    }),
    day_saldo: Ember.computed('saldo', function () {
        var saldo, saldoHours;
        saldo = this.get('saldo');
        saldoHours = saldo.saldo / 60;
        if (saldo.cut) {
            saldoHours -= saldo.cut / 60;
        }
        return saldoHours;
    }),
    day_saldo_over_max: Ember.computed('saldo_settings', 'saldo', function () {
        if (this.saldo.dailySaldoCut) {
            return this.saldo.dailySaldoCut;
        } else {
            return this.saldo.saldo > this.saldo_settings.dailymaximum;
        }
    }),
    workday_length: Ember.computed('saldo', function () {
        var saldo;
        saldo = this.get('saldo');
        return saldo.workday_length / 60;
    }),
    workday_hours: Ember.computed('saldo', function () {
        var saldo;
        saldo = this.get('saldo');
        return saldo.workday_hours / 60;
    }),
    cumulative: Ember.computed('saldo', function () {
        var saldo;
        saldo = this.get('saldo');
        return saldo.cumulative / 60;
    }),
    cumulative_under_min: Ember.computed('saldo_settings', 'saldo', function () {
        return this.get('saldo.cumulative') < this.get('saldo_settings.minimum') * -1;
    }),
    cumulative_over_max: Ember.computed('saldo_settings', 'saldo', function () {
        return this.get('saldo.cumulative') > this.get('saldo_settings.maximum');
    }),
    cut: Ember.computed('saldo', function () {
        var saldo;
        saldo = this.get('saldo');
        if (saldo.cut) {
            return saldo.cut / 60;
        } else {
            return null;
        }
    }),
    actions: {
        click: function () {
            if (this.get('click')) {
                return this.click();
            }
        },
    },
});

export default UsersEditSaldoCalendarCardComponent;
