/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/contract-list/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanContractListAddController;

import Ember from 'ember';

TaxmanContractListAddController = Ember.Controller.extend({
    launchProjectUpdate: false,
    Collector: Em.inject.service('collector-service'),
    intl: Ember.inject.service(),
    employers: Ember.computed(function () {
        return this.store.findAll('employer');
    }),
    selectedMonth: 0,
    contracts: Em.A([]),
    mandatoryFields: Em.computed(function () {
        var field, field_obj, fields, tmp_fields;
        tmp_fields = [];
        fields = this.get('Collector').fields('urakkaraportointi');
        for (field in fields) {
            field_obj = fields[field];
            if (
                field !== 'contract_month' &&
                field_obj.features != null &&
                field_obj.features.mandatory
            ) {
                tmp_fields.push(field);
            }
        }
        return tmp_fields;
    }),
    //get 36 previous months
    months: Ember.computed(function () {
        var count, montharr, obj;
        count = 0;
        montharr = [];
        while (count < 36) {
            obj = {
                id: count,
                name: moment().subtract(count++, 'months').format('MMMM YYYY'),
            };
            montharr.push(obj);
        }
        return montharr;
    }),
    flipped_options: Ember.computed(function () {
        var values;
        values = Em.A([]);
        values.push({
            id: '1',
            name: this.get('intl').t('general.yes'),
        });
        values.push({
            id: '2',
            name: this.get('intl').t('general.no'),
        });
        return values;
    }),
    contract_type_options: Ember.computed(function () {
        var values;
        values = Em.A([]);
        values.push({
            id: '1',
            name: this.get('intl').t('taxman.contract.add.contract_type.1'),
        });
        values.push({
            id: '2',
            name: this.get('intl').t('taxman.contract.add.contract_type.2'),
        });
        //values.push( {id:'4', name:@get('intl').t("taxman.contract.add.contract_type.4")} ) Not supported properly currently
        return values;
    }),
    okToAdd: Ember.computed('selectedMonth', 'selectedProject', 'contract_number', function () {
        var contractNumberSet, monthSet, projectSet;
        monthSet = this.get('selectedMonth') != null && this.get('selectedMonth') !== '';
        projectSet = this.get('selectedProject') != null && this.get('selectedProject') !== '';
        contractNumberSet =
            this.get('contract_number') != null && this.get('contract_number') !== '';
        if (!monthSet) {
            return false;
        }
        if (!projectSet) {
            return false;
        }
        if (contractNumberSet && !projectSet) {
            return false;
        }
        return true;
    }),
    updateContract: function (contract) {
        var contract_number, params, project;
        project = this.get('selectedProject');
        contract_number = this.get('contract_number');
        if ((!project && !contract_number) || !contract.contract_employer) {
            return;
        }
        params = {
            contract_employer: contract.contract_employer.get('id'),
        };
        if (project) {
            params.contract_project = project.get('id');
        } else if (contract_number) {
            params.contract_number = contract_number;
        }
        params.order = 'contract_month-';
        params.limit = 12;
        return this.store.query('urakkaraportointi', params).then((all_records) => {
            var getLatestRecordEndDate, records;
            // EP-8664 change logic to use previous data instead of previous month
            // filter the latest contract end date
            getLatestRecordEndDate = all_records.mapBy('contract_end_date').sort().reverse();
            records = all_records.filterBy('contract_end_date', getLatestRecordEndDate[0]);
            Em.set(contract, 'old_contracts', all_records); // show old contracts
            // if yhere are no records, display empty values
            if (records.get('length') === 0) {
                Em.set(contract, 'contract_billed', null);
                Em.set(contract, 'contract_total_price', null);
                Em.set(contract, 'contract_start_date', null);
                return Em.set(contract, 'contract_end_date', null);
            } else {
                // if there are records, use the latest by contract_end_date
                Em.set(contract, 'contract_billed', records.get('firstObject.contract_billed'));
                Em.set(
                    contract,
                    'contract_total_price',
                    records.get('firstObject.contract_total_price'),
                );
                Em.set(
                    contract,
                    'contract_start_date',
                    records.get('firstObject.contract_start_date'),
                );
                Em.set(contract, 'contract_end_date', records.get('firstObject.contract_end_date'));
                Em.set(contract, 'contract_flipped', records.get('firstObject.contract_flipped'));
                Em.set(contract, 'contract_type', records.get('firstObject.contract_type'));
                return this.set('contract_number', records.get('firstObject.contract_number'));
            }
        });
    },
    actions: {
        addNewContract: function () {
            var obj;
            obj = {
                employer: this.get('model.contract_employer'),
                flipped_tax: this.get('model.contract_flipped'),
                contract_type: this.get('model.contract_type'),
                first_worker: this.get('model.contract_first_worker'),
                total_price: this.get('model.contract_total_price'),
                contract_start: this.get('model.contract_start_date'),
                contract_end: this.get('model.contract_end_date'),
                billed: this.get('model.contract_billed'),
                description: this.get('model.contract_description'),
            };
            return this.get('contracts').pushObject(obj);
        },
        save: function () {
            var i, missingFields, records, self, validated;
            self = this;
            this.set('spinIsLoading', true);
            this.set('disabled', true);
            this.set('errorMessage', null);
            records = [];
            missingFields = [];
            i = 0;
            this.get('contracts').forEach((contract) => {
                var contract_record, currentContractMissingFields, field, j, len, ref;
                currentContractMissingFields = [];
                ref = this.get('mandatoryFields');
                // try to validate
                for (j = 0, len = ref.length; j < len; j++) {
                    field = ref[j];
                    if (
                        field === 'contract_first_worker' &&
                        contract['contract_type'] === '2' &&
                        contract[field] === void 0
                    ) {
                        currentContractMissingFields.push(field);
                    } else if (field !== 'contract_first_worker' && contract[field] === void 0) {
                        currentContractMissingFields.push(field);
                    }
                }
                missingFields[i] = currentContractMissingFields;
                contract_record = self.store.createRecord('urakkaraportointi', {
                    contract_employer: contract.contract_employer,
                    contract_end_date: contract.contract_end_date,
                    contract_first_worker: contract.contract_first_worker,
                    contract_flipped: contract.contract_flipped,
                    contract_month: moment()
                        .subtract(self.get('selectedMonth'), 'months')
                        .format('YYYY-MM'),
                    contract_project: self.get('selectedProject'),
                    contract_number: this.get('contract_number'),
                    contract_total_price: contract.contract_total_price,
                    contract_billed: contract.contract_billed,
                    contract_start_date: contract.contract_start_date,
                    contract_type: contract.contract_type,
                    contract_description: contract.contract_description,
                });
                records.push(contract_record);
                return i++;
            });
            this.set('missingFields', missingFields);
            validated = records.invoke('validate');
            return Em.RSVP.Promise.all(validated).then(() => {
                var saved_records;
                if (records.filterBy('isValid', false).length > 0) {
                    this.set('errorMessage', this.get('intl').t('general_add.error'));
                    this.set('spinIsLoading', false);
                    this.set('disabled', false);
                    return;
                }
                saved_records = records.invoke('save');
                return Em.RSVP.Promise.all(saved_records)
                    .then(() => {
                        this.set('spinIsLoading', false);
                        this.set('disabled', false);
                        this.set('contracts', Em.A([]));
                        return this.transitionToRoute('taxman.contract-list.index');
                    })
                    .catch((reason) => {
                        this.set('errorMessage', reason.errors[0].error_msg);
                        self.set('spinIsLoading', false);
                        return self.set('disabled', false);
                    });
            });
        },
        removeContract: function (contract) {
            return this.get('contracts').removeObject(contract);
        },
        setEmployer: function (contract, value) {
            if (Ember.typeOf(value) === 'instance') {
                Em.set(contract, 'contract_employer', value);
                return this.updateContract(contract);
            }
        },
        setProject: function (value) {
            return this.set('selectedProject', value);
        },
    },
});

export default TaxmanContractListAddController;
