/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/gps-locator.coffee.old
// Generated by CoffeeScript 2.6.1
var GpsLocatorService;

import Ember from 'ember';

GpsLocatorService = Ember.Service.extend({
    getLocation: function (options = {}) {
        return new Em.RSVP.Promise(function (resolve, reject) {
            if (navigator.geolocation) {
                return navigator.geolocation.getCurrentPosition(
                    function (position) {
                        return resolve(position);
                    },
                    function (error) {
                        return reject(error);
                    },
                    options,
                );
            } else {
                return reject('Geolocation is not supported by this browser.');
            }
        });
    },
    GetLocationByAddress: function (address) {
        return new Em.RSVP.Promise(function (resolve, reject) {
            var geocoder;
            geocoder = new google.maps.Geocoder();
            //address = document.getElementById('txtAddress').value
            return geocoder.geocode(
                {
                    address: address,
                },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        return resolve({
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                        });
                    } else {
                        return resolve({
                            error: 'could not get address-info',
                        });
                    }
                },
            );
        });
    },
});

export default GpsLocatorService;
