import config from 'tt4/config/environment';
import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';
import moment from 'moment';
import collector from 'tt4/classes/collector';
import getScript from 'tt4/utils/getScript';

export default class SurveysService extends Service {
    @service session;
    @service intl;
    @service initapp;
    @service flags;
    @service router;

    initializeAndRun(surveyType) {
        // we skip showing if no flag
        if (!this.flags.test('show-survey')) {
            return;
        }
        const initapp = get(this, 'initapp');
        if (get(initapp, 'appReady')) {
            this.initWootric(surveyType);
        } else {
            initapp.on('appReady', () => {
                this.initWootric(surveyType);
            });
        }
    }

    async initWootric(surveyType) {
        if (!get(this, 'session.session.content.authenticated.scope')) {
            if (config.environment === 'development' || get(this, 'testMode') === true) {
                window.wootric_survey_immediately = true;
                window.wootric_no_surveyed_cookie = true;
            }
            const user = get(this, 'session.currentUser');
            const intl = get(this, 'intl');

            // browser info
            const { UAParser } = window;
            const parser = new UAParser();
            const bo = parser.setUA(navigator.userAgent).getResult();

            // eg. 'Firefox 68.0 / Gecko 68.0 / Windows 10'
            const browserString = `${bo.browser.name} ${bo.browser.version} / ${bo.engine.name} ${bo.engine.version} / ${bo.os.name} ${bo.os.version}`;

            window.wootricSettings = {
                email: get(user, 'email'),
                created_at: moment(get(user, 'row_info.created')).format('X'),
                language: get(intl, 'primaryLocale'),
                dashboard_language: get(intl, 'primaryLocale'),
                modal_footprint: 'compact',
                disclaimer: {
                    text: intl.t('wootric.disclamer.text').toString(),
                    link: 'https://www.visma.com/privacy/customer-feedback/',
                    link_word: intl.t('wootric.disclamer.link_word').toString(),
                },
                properties: {
                    role: get(user, 'userlevel'),
                    product: config.brand == 'visma' ? 'Visma Entry' : 'Movenium',
                    country: get(user, 'company_info.country')
                        ? get(user, 'company_info.country').toUpperCase()
                        : 'N/A',
                    customer: get(user, 'company'),
                    vatin: get(user, 'company_info.company_vatin'),
                    partnerid: get(user, 'partnerid'),
                    modules_array: collector.products.toString(),
                    platform: typeof cordova !== 'undefined' ? device.platform : browserString, // eslint-disable-line
                    page: this.router.currentRouteName,
                },
            };

            if (this.WootricSurvey) {
                this.run(surveyType);
            } else {
                await getScript('https://cdn.wootric.com/wootric-sdk.js');
                this.WootricSurvey = WootricSurvey;
                this.run(surveyType);
            }
        }
    }

    run(type) {
        if (!type) return;
        WootricSurvey.stop();
        window.wootricSettings.account_token = 'NPS-EU-b52c0bfb';
        window.wootricSettings.event_name = type;
        window.wootricSettings.product_name = null;
        WootricSurvey.run(window.wootricSettings);
    }
}
