/*eslint-disable ember/named-functions-in-promises */
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import recordComp from '../../record-comp/component';

const CollectorAddModalWorktaskComponent = recordComp.extend({
    store: service(),
    intl: service(),

    layoutName: 'collector/record-comp',

    init() {
        this._super();
        this.currentValues = {
            date: null,
            userid: null,
        };
    },

    actions: {
        setValue(value, field, set) {
            if (set == null) {
                set = true;
            }
            this._super(value, field, set);
            return this.updateMessage();
        },
    },

    /*
    check if worktasks are found for selected user for selected date
    */
    updateMessage() {
        if (
            get(this, 'currentValues.date') === get(this, 'record.date') &&
            get(this, 'currentValues.userid') === get(this, 'record.user.id')
        ) {
            return;
        }

        set(this, 'currentValues', {
            date: get(this, 'record.date'),
            userid: get(this, 'record.user.id'),
        });

        if (!get(this, 'record.user.id') || !get(this, 'record.date')) {
            set(this, 'messageBox', null);
            return;
        }

        const params = { user: get(this, 'record.user.id'), date: get(this, 'record.date') };
        if (get(this, 'record.id')) {
            params.id = '!' + get(this, 'record.id');
        }
        return this.store.query('worktask', params).then((tasks) => {
            const filtered_tasks = tasks.filter((task) => {
                return get(task, 'id') !== this.record_id;
            });

            let hours = 0;
            filtered_tasks.forEach((task) => (hours += get(task, 'est_hours')));

            const minutes = Math.round((hours - Math.floor(hours)) * 60);
            const formatted_hours =
                Math.floor(hours) + ':' + (minutes < 10 ? '0' : '') + minutes + 'h';

            if (get(filtered_tasks, 'length') > 0) {
                const message = this.intl.t('worktask.worktasks_found', {
                    count: get(filtered_tasks, 'length'),
                    hours: formatted_hours,
                });

                return set(this, 'messageBox', message);
            } else {
                return set(this, 'messageBox', null);
            }
        });
    },
});

export default CollectorAddModalWorktaskComponent;
