/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/database-array/component.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryDatabasearrayComponent,
    get,
    oneWay,
    service,
    indexOf = [].indexOf;

import Ember from 'ember';

({
    //import baseField from '../base-field/component'
    inject: { service },
    computed: { oneWay },
    get,
} = Ember);

DiaryDatabasearrayComponent = Ember.Component.extend({
    store: service(),
    Collector: service('collector-service'),
    didReceiveAttrs: function () {
        return this._super();
    },
    //        @set 'value', oneWay(@get('model.'+@get('field.name')))
    specialFormat: ['user', 'images'],
    value: Ember.computed.oneWay('field.value'),
    values: Ember.computed('value', function () {
        var field, model;
        field = this.get('field');
        model = this.get('model');
        return model.get(get(field, 'name'));
    }),
    dateField: Ember.computed(function () {
        return this.get('Collector')
            .fieldArray(this.get('field.options.form'))
            .filterBy('type', 'date')
            .get('firstObject');
    }),
    projectField: Ember.computed(function () {
        var fields;
        fields = this.get('Collector')
            .fieldArray(this.get('field.options.form'))
            .filter(function (item) {
                return get(item, 'options.form') === 'project';
            });
        return fields.get('firstObject');
    }),
    userField: Ember.computed(function () {
        var fields;
        fields = this.get('Collector')
            .fieldArray(this.get('field.options.form'))
            .filter(function (item) {
                return get(item, 'options.form') === 'user';
            });
        return fields.get('firstObject');
    }),
    items: Ember.computed('model.date', 'model.project', function () {
        var params;
        params = {};
        params[this.get('dateField.name')] = this.get('model.date');
        params[this.get('projectField.name')] = this.get('model.project.id');
        return this.get('store').query(this.get('field.options.form'), params);
    }),
    filteredItems: Ember.computed('items.isFulfilled', 'values.[]', function () {
        var items, selectedItems;
        selectedItems = this.get('values').mapBy('id');
        items = Ember.A(this.get('items')).filter(function (item) {
            var ref;
            return (ref = item.get('id')), indexOf.call(selectedItems, ref) < 0;
        });
        return items;
    }),
    actions: {
        toggleModal: function () {
            this.toggleProperty('showModal');
            return false;
        },
        addItem: function (item) {
            return this.get('values').pushObject(item);
        },
        removeItem: function (item) {
            return this.get('values').removeObject(item);
        },
    },
});

export default DiaryDatabasearrayComponent;
