/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/pdf-export.coffee.old
// Generated by CoffeeScript 2.6.1
var PdfExportMixin;

import Ember from 'ember';

import config from '../config/environment';

import baseExport from './base-export';

import $ from 'jquery';

import { getAssetFolder, getScriptsInOrder } from 'tt4/utils/getScript';

PdfExportMixin = Ember.Mixin.create(baseExport, {
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    gui: Ember.inject.service(),
    totalPdfImages: 0,
    cordova: Ember.inject.service('own-cordova-service'),
    createPdfFromElements: function (
        elements,
        mode = 'download',
        headers = '',
        headerSize = 8,
        exportParams = {},
    ) {
        if (this.get('totalPdfImages') > 10) {
            this.get('gui').set('showStickyNote', true);
            this.get('gui').set('rawStickyValue', 100);
            this.get('gui').set('stickyValue', this.get('intl').t('export.pdf.started_generating'));
        }
        //console.log "start: "+moment().format("mm:ss")
        this.set('full_pdf_export', false);
        this.set('totalPdfImages', 0);
        return new Em.RSVP.Promise((resolve, reject) => {
            var orientation, pdf_content;
            pdf_content = [];
            orientation = Ember.get(exportParams, 'orientation')
                ? Ember.get(exportParams, 'orientation')
                : 'portrait';
            return this.getHeaders(headers, headerSize).then((headers) => {
                var assetsfolder, docDefinition, element, i, len, pageSize;
                pdf_content.push(headers);
                if (!this.get('doNotPutElementsInColumns')) {
                    elements = this.putElementsInColumns(elements);
                }
                for (i = 0, len = elements.length; i < len; i++) {
                    element = elements[i];
                    if (!element) {
                        return;
                    }
                    if (element.table && !Ember.get(exportParams, 'orientation')) {
                        if (element.table.body[0].length > 7) {
                            orientation = 'landscape';
                        }
                    }
                    pdf_content.push(element);
                }
                pageSize = 'A4';
                if (Ember.get(exportParams, 'signature')) {
                    pdf_content.push({
                        style: 'marginTop',
                        table: {
                            widths: ['auto', 140, 30, 'auto', 140],
                            body: [
                                [
                                    {
                                        border: [false, true, false, false],
                                        text: this.get('intl').t('report.worktask.signature') + ':',
                                    },
                                    {
                                        border: [false, true, false, false],
                                        text: '',
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: '',
                                    },
                                    {
                                        border: [false, true, false, false],
                                        text: this.get('intl').t('thereport.print_name') + ':',
                                    },
                                    {
                                        border: [false, true, false, false],
                                        text: '',
                                    },
                                ],
                            ],
                        },
                    });
                    if (Ember.get(exportParams, 'dateAndPlace')) {
                        pdf_content.push({
                            style: 'marginTop',
                            table: {
                                widths: ['auto', 140, 55, 'auto', 140],
                                body: [
                                    [
                                        {
                                            border: [false, true, false, false],
                                            text: this.get('intl').t('thereport.date') + ':',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: '',
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: '',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: this.get('intl').t('thereport.place') + ':',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: '',
                                        },
                                    ],
                                ],
                            },
                        });
                    }
                }
                if (
                    elements[0].table != null &&
                    exportParams.imgLocation != null &&
                    exportParams.imgLocation === 'afterReport'
                ) {
                    pdf_content.push(this.tryToExtractImages(elements[0].table.body, orientation));
                }
                docDefinition = {
                    content: pdf_content,
                    pageSize: 'A4',
                    pageOrientation: orientation,
                    footer: (currentPage, pageCount) => {
                        return this.getFooter(currentPage, pageCount);
                    },
                    pageMargins: [15, 15, 15, 15],
                    styles: {
                        tableHeader: {
                            bold: true,
                        },
                        bigText: {
                            bold: true,
                            fontSize: 20,
                        },
                        table: {
                            fontSize: 10,
                        },
                        footer: {
                            fontSize: 8,
                            color: 'grey',
                        },
                        headertext: {
                            fontSize: 8,
                        },
                        marginTop: {
                            margin: [0, 40, 0, 0],
                        },
                    },
                };
                //console.log(JSON.stringify(docDefinition, null, 4))
                //return
                assetsfolder = getAssetFolder('assets');
                return getScriptsInOrder([
                    assetsfolder + 'pdfmake_v0.1.68.min.js',
                    assetsfolder + 'vfs_fonts_v0.1.68.min.js',
                ]).then(() => {
                    return createPdf(docDefinition)._createDoc({}, () => {
                        var item, j, len1;
                        for (j = 0, len1 = pdf_content.length; j < len1; j++) {
                            item = pdf_content[j];
                            if (
                                item.style === 'table' &&
                                item._maxWidth > 842 &&
                                orientation !== 'portrait'
                            ) {
                                pageSize = {
                                    height: item._maxWidth,
                                    width: item._maxWidth / Math.sqrt(2),
                                };
                                continue;
                            }
                        }
                        docDefinition.pageSize = pageSize;
                        //console.log(JSON.stringify(docDefinition, null, 4))
                        //return
                        if (mode === 'download') {
                            if (typeof cordova !== 'undefined' && cordova !== null) {
                                createPdf(docDefinition).getBuffer((buffer) => {
                                    var binaryArray, promise, utf8;
                                    utf8 = new Uint8Array(buffer);
                                    binaryArray = utf8.buffer;
                                    promise = this.get('cordova').saveFile(
                                        binaryArray,
                                        'export',
                                        'pdf',
                                    );
                                    return promise.then(function (file) {
                                        return resolve(file);
                                    });
                                });
                            } else {
                                resolve(createPdf(docDefinition).download('export.pdf'));
                            }
                            return this.get('gui').set(
                                'stickyValue',
                                this.get('intl').t('export.pdf.done_generating'),
                            );
                        } else if (mode === 'base64') {
                            return createPdf(docDefinition).getBase64(function (file) {
                                return resolve(file);
                            });
                        }
                    });
                });
            });
        });
    },
    tryToExtractImages: function (data, orientation) {
        var allImages, el, element, i, j, len, len1, maxHeight, maxWidth;
        if (orientation === 'landscape') {
            maxHeight = 570;
            maxWidth = 830;
        } else {
            maxHeight = 830;
            maxWidth = 570;
        }
        // A4 - 595 × 842
        allImages = [];
        for (i = 0, len = data.length; i < len; i++) {
            element = data[i];
            //console.log data
            for (j = 0, len1 = element.length; j < len1; j++) {
                el = element[j];
                if (el.image) {
                    allImages.push({
                        image: el.image,
                        maxHeight: maxHeight,
                        maxWidth: maxWidth,
                    });
                }
            }
        }
        return allImages;
    },
    putElementsInColumns: function (elements) {
        var cell, element, fillingColumn, i, len, ret, row;
        ret = [];
        row = [];
        cell = [];
        for (i = 0, len = elements.length; i < len; i++) {
            element = elements[i];
            if (!element.column) {
                if (fillingColumn) {
                    row.push(cell);
                    cell = [];
                    ret.push({
                        columns: row,
                    });
                }
                row = [];
                ret.push(element);
                fillingColumn = null;
            } else {
                if (fillingColumn && fillingColumn < element.column) {
                    row.push(cell);
                    cell = [];
                }
                if (fillingColumn && fillingColumn > element.column) {
                    row.push(cell);
                    cell = [];
                    ret.push({
                        columns: row,
                    });
                    row = [];
                }
                if (!element.width) {
                    element.width = '*';
                }
                cell.push(element);
                fillingColumn = element.column;
            }
        }
        if (fillingColumn) {
            row.push(cell);
            ret.push({
                columns: row,
            });
        }
        return ret;
    },
    /*
  Creates pdf table from given data

  columns: ['col1','col2']
  rows: [
     {col1: "cell_1", col2: "cell_2"}
  ]
  */
    createPdfTableElement: function (
        columns,
        rows,
        translated_columns = null,
        sumRow = null,
        extraSettings = false,
    ) {
        var i, indexOf, item, j, k, len, len1, len2, row, spliced;
        if (!translated_columns) {
            translated_columns = columns;
        }
        for (indexOf = i = 0, len = rows.length; i < len; indexOf = ++i) {
            row = rows[indexOf];
            if (Array.isArray(row)) {
                spliced = rows.splice(indexOf);
                for (j = 0, len1 = spliced.length; j < len1; j++) {
                    item = spliced[j];
                    for (k = 0, len2 = item.length; k < len2; k++) {
                        row = item[k];
                        rows.push(row);
                    }
                }
            }
        }
        return {
            table: {
                headerRows: 1,
                widths: this.getWidths(columns),
                body: this.getTable(columns, rows, translated_columns, sumRow),
            },
            layout: 'lightHorizontalLines',
            margin: [2, 2, 10, 10],
            style: 'table',
        };
    },
    createPdfTableElementRows: function (
        columns,
        rows,
        translated_columns = null,
        sumRow = null,
        insertPageBreak = false,
    ) {
        var c,
            i,
            img_rows,
            j,
            len,
            len1,
            row,
            row_ind,
            sub_ind,
            subrow,
            tables,
            text_rows,
            tmp_table;
        this.set('doNotPutElementsInColumns', true);
        c = this.get('Collector');
        if (!translated_columns) {
            translated_columns = columns;
        }
        rows = this.getTableFullFormat(columns, rows, translated_columns, sumRow);
        tables = [];
        for (row_ind = i = 0, len = rows.length; i < len; row_ind = ++i) {
            row = rows[row_ind];
            text_rows = [];
            img_rows = [];
            // separate images into own array
            for (sub_ind = j = 0, len1 = row.length; j < len1; sub_ind = ++j) {
                subrow = row[sub_ind];
                if (Ember.typeOf(subrow[1]) === 'object') {
                    img_rows.push(subrow);
                } else {
                    text_rows.push(subrow);
                }
            }
            tmp_table = [
                {
                    table: {
                        headerRows: 1,
                        body: text_rows,
                        dontBreakRows: true,
                    },
                    layout: 'noBorders',
                    margin: [2, 2, 10, 10],
                    style: 'table',
                },
            ];
            // if there are images, push them into array
            if (img_rows.length > 0) {
                tmp_table.push(img_rows);
            }
            tables.push(tmp_table);
            // after every table, add one linebreak (or pagebreak) to separate them better
            if (row_ind + 1 !== rows.length) {
                if (insertPageBreak) {
                    tables.push({
                        text: '',
                        pageBreak: 'after',
                    });
                } else {
                    tables.push('\n');
                    tables.push('\n');
                }
            }
        }
        //removing this line for now because for some strange reason it makes full pdf look very crappy SUP-2070
        //tables.push {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1 }]}
        return tables;
    },
    createPdfElement: function (text, pageBreak = 'after') {
        return {
            text: text,
            pageBreak: pageBreak,
            margin: [0, 10, 0, 0],
        };
    },
    createPdfHeaderElement: function (text) {
        return {
            text: text,
            style: 'tableHeader',
            margin: [0, 10, 0, 0],
        };
    },
    getWidths: function (columns) {
        var colObj, column, i, len, ref, widths;
        widths = [];
        for (i = 0, len = columns.length; i < len; i++) {
            column = columns[i];
            if (
                !this.get('form') ||
                column === 'id' ||
                column === 'row_info.created' ||
                column === 'row_info.modified' ||
                column === 'status_last_changed'
            ) {
                widths.push('auto');
            } else {
                colObj = this.get('Collector').field(this.get('form'), column);
                if (
                    (ref = colObj['type']) === 'textbox' ||
                    ref === 'textarea' ||
                    ref === 'textarea_varchar'
                ) {
                    widths.push(200);
                } else {
                    widths.push('auto');
                }
            }
        }
        return widths;
    },
    getFooter: function (currentPage, pageCount) {
        return {
            columns: [
                {
                    text: 'movenium.com',
                    style: 'footer',
                    margin: [20, 2, 0, 0],
                },
                {
                    text: moment().format('L') + '  ' + currentPage.toString() + '/' + pageCount,
                    style: 'footer',
                    margin: [0, 2, 20, 0],
                    alignment: 'right',
                },
            ],
        };
    },
    getHeaders: function (additionalheaders, headerSize) {
        var c;
        c = this.get('Collector');
        return new Em.RSVP.Promise((resolve, reject) => {
            return c.getLogo().then((logo) => {
                var company, session;
                session = this.get('session');
                company = session.get('currentUser.company');
                return resolve({
                    columns: [
                        [
                            {
                                fit: [120, 40],
                                image: logo,
                                margin: [0, 0, 0, 20],
                            },
                            {
                                text: additionalheaders,
                                style: 'headertext',
                                fontSize: headerSize,
                            },
                        ],
                        {
                            width: '*',
                            text: company || '',
                            alignment: 'right',
                            fontSize: 22,
                            margin: [0, 0, 0, 20],
                        },
                    ],
                });
            });
        });
    },
    getImageUriRotated: function (url, rotateImages, type = null) {
        var d, img, self;
        d = new $.Deferred();
        self = this;
        img = new Image();
        img.onload = function () {
            var canvas, ctx, dataUrl, degrees;
            canvas = document.createElement('canvas');
            ctx = canvas.getContext('2d');
            //canvas.style.width="20%"
            if (img.width < img.height && rotateImages) {
                degrees = 90;
            } else {
                degrees = 0;
            }
            if (degrees === 90 || degrees === 270) {
                canvas.width = this.height;
                canvas.height = this.width;
            } else {
                canvas.width = this.width;
                canvas.height = this.height;
            }
            self.set('pdfImageSize', this.width);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            //console.log img.height
            //console.log img.width
            if (degrees === 90 || degrees === 270) {
                ctx.translate(img.height / 2, img.width / 2);
            } else {
                ctx.translate(img.width / 2, img.height / 2);
            }
            ctx.rotate((degrees * Math.PI) / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            if (type && type === 'image/png') {
                dataUrl = canvas.toDataURL('image/png', 0.8);
            } else {
                dataUrl = canvas.toDataURL('image/jpeg', 0.8);
            }
            return d.resolve({
                dataUrl: dataUrl,
                width: this.width,
                height: this.height,
            });
        };
        img.setAttribute('crossOrigin', 'anonymous');
        if (url.includes('https://')) {
            img.src = url;
        } else {
            img.src = config.APP.COLLECTOR_API_URL + '/' + url;
        }
        img.onerror = function () {
            return d.resolve(false);
        };
        return d;
    },
    getImageUri: function (url) {
        var d, img, self;
        d = new $.Deferred();
        self = this;
        img = new Image();
        img.onload = function () {
            var canvas, ctx, dataUrl;
            canvas = document.createElement('canvas');
            // calculate new dimensions so image isn't bigger than width of pdf-page
            /*
      if @width > pdf_max_width and self.get('full_pdf_export')
          resize_factor = pdf_max_width / @width
      else if self.get('full_pdf_export')
          resize_factor = 0.75 # for some reason pdfmake scales all images up
      else
          resize_factor = 1*/
            self.set('pdfImageSize', this.width);
            canvas.width = this.width;
            canvas.height = this.height;
            ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            dataUrl = canvas.toDataURL();
            return d.resolve(dataUrl);
        };
        img.setAttribute('crossOrigin', 'anonymous');
        img.src = config.APP.COLLECTOR_API_URL + '/' + url;
        img.onerror = function () {
            return d.resolve(false);
        };
        return d;
    },
    formatImagesToPdf: function (fields, model) {
        var field, i, imgs, len, promise, ref;
        imgs = [];
        promise = $.Deferred();
        for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];
            if ((ref = field['type']) === 'hdfile' || ref === 'signature') {
                imgs.push(
                    new Em.RSVP.Promise((resolve, reject) => {
                        var img, imgArr, j, len1, ref1;
                        imgArr = [];
                        ref1 = model.get(field['ext']);
                        for (j = 0, len1 = ref1.length; j < len1; j++) {
                            img = ref1[j];
                            //console.log img["name"]
                            imgArr.push(
                                this.formatImageToPdf(img, 'list', 400, false, 'true', {
                                    string: img['name'],
                                }),
                            );
                        }
                        //resolve(imgArr)
                        return Em.RSVP.Promise.all(imgArr).then(function (data) {
                            //console.log data
                            return promise.resolve(data);
                        });
                    }),
                );
            }
        }
        return promise;
    },
    //    pdf_output_images.push
    //console.log col
    //    console.log row.get(col['ext_type'])

    // returns pdf-formatted image-promise
    formatImageToPdf: function (
        cell,
        type,
        size = false,
        rotateImages = false,
        imageSize = false,
        name = false,
    ) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var img;
            if (imageSize === 'hideImages') {
                resolve('');
                return;
            }
            if (this.get('full_pdf_export')) {
                img = cell.url;
            } else if (
                cell.s3thumb &&
                (imageSize === 'thumbnails' || imageSize === 'afterReport')
            ) {
                size = 50;
                img = cell.s3thumb;
            } else {
                img = cell.thumb;
            }
            return this.getImageUriRotated(img, rotateImages, cell.type).then((imageObj) => {
                var image, imageWidth, pdf_max_width, resize_factor;
                image = imageObj['dataUrl'];
                imageWidth = imageObj['width'];
                resize_factor = 0.75;
                if (
                    this.get('full_pdf_export') ||
                    imageSize === 'thumbnails' ||
                    imageSize === 'afterReport'
                ) {
                    pdf_max_width = size ? size : 300;
                    if (imageWidth > pdf_max_width) {
                        resize_factor = pdf_max_width / imageWidth;
                    }
                }
                if (imageSize === 'true') {
                    resize_factor = 1;
                    if (imageWidth > 500) {
                        imageWidth = 500;
                    }
                }
                //    imageWidth = imageObj["width"]
                //    console.log @get('pdfImageSize')
                if (cell.name === 'signature.png') {
                    resize_factor = 1;
                }
                if (type === 'list') {
                    if (image) {
                        return resolve({
                            name: name,
                            value: {
                                image: image,
                                margin: [5, 5, 5, 5],
                                alignment: 'center',
                                width: parseInt(imageWidth * resize_factor),
                            },
                        });
                    } else {
                        return resolve({
                            name: name,
                            value: '',
                        });
                    }
                } else if (type === 'row') {
                    if (image) {
                        return resolve({
                            image: image,
                            margin: [0, 5, 0, 5],
                            alignment: 'center',
                            width: parseInt(imageWidth * resize_factor),
                        });
                    } else {
                        return resolve('');
                    }
                }
            });
        });
    },
    // Formats collector fields names and values to a PDF friendly array
    // TODO: Only supports type "list" at the moment, should probably also support table rows
    formatCollectorFieldsToPdfArray: function (
        fields,
        model,
        type = 'list',
        return_fields = false,
        add_titles = false,
        rotateImages = false,
        whatAboutImages = 'showImages',
    ) {
        var c, promise, rows, self, totalImages;
        self = this;
        promise = $.Deferred();
        c = this.get('Collector');
        rows = [];
        totalImages = this.get('totalPdfImages');
        $.each(fields, (key, column) => {
            var cell, formatted, name, ref;
            column = Em.Object.create(column);
            if (!column.get('ext')) {
                column.set('ext', key);
            }
            if (column.get('ext').indexOf('_minus') > -1) {
                return;
            }
            if (return_fields && return_fields.indexOf(column.get('ext')) < 0) {
                return;
            }
            cell = model.get(column.get('ext'));
            if (column.get('ext') === 'status_last_changed') {
                rows.push(model.get('status_last_changed'));
                return;
            }
            // name is only used when creating pdf-report from binoculars-page (show-component)
            if (column.translated_name) {
                name = column.translated_name;
            } else if (!model.get('constructor.modelName')) {
                name = '';
            } else {
                name = c.getFieldName(model.get('constructor.modelName'), column);
            }
            //if column.get('type') not in ['hdfile','signature'] and cell? and whatAboutImages == "onlyImages"
            //    rows.push ""
            if (((ref = column.get('type')) === 'hdfile' || ref === 'signature') && cell != null) {
                return rows.push(
                    new Em.RSVP.Promise((resolve, reject) => {
                        var i, img, imgs, j, len, len1, size, tmpArr;
                        if (
                            (typeof cell === 'object' && cell.length === 0) ||
                            whatAboutImages === 'hideImages'
                        ) {
                            resolve('');
                        }
                        if (whatAboutImages === 'showOnlyNames') {
                            tmpArr = [];
                            for (i = 0, len = cell.length; i < len; i++) {
                                img = cell[i];
                                //console.log img
                                tmpArr.push(img['name']);
                            }
                            resolve(tmpArr);
                        }
                        if (
                            typeof cell !== 'string' &&
                            cell.length > 0 &&
                            (whatAboutImages === 'showImages' || whatAboutImages === 'thumbnails')
                        ) {
                            imgs = [];
                            size = false;
                            if (cell.length > 1) {
                                size = 270;
                            } else {
                                rotateImages = false;
                            }
                            // lets iterate all
                            for (j = 0, len1 = cell.length; j < len1; j++) {
                                img = cell[j];
                                if (
                                    img.type != null &&
                                    !['image/png', 'image/jpg', 'image/jpeg'].includes(img.type)
                                ) {
                                    continue;
                                }
                                imgs.push(
                                    this.formatImageToPdf(
                                        img,
                                        type,
                                        size,
                                        rotateImages,
                                        whatAboutImages,
                                        name,
                                    ),
                                );
                                this.incrementProperty('totalPdfImages');
                            }
                            //imgs.push "1"
                            //console.log totalImages
                            return Em.RSVP.Promise.all(imgs).then(function (data) {
                                var imgData, imgRow, imgTable, k, len2;
                                imgData = [];
                                imgRow = [];
                                if (data.length > 1) {
                                    for (k = 0, len2 = data.length; k < len2; k++) {
                                        img = data[k];
                                        imgRow.push(img);
                                        if (imgRow.length === 2) {
                                            imgData.push(imgRow);
                                            imgRow = [];
                                        }
                                    }
                                    // add last image (also add one empty cell so table is valid)
                                    if (imgRow.length > 0) {
                                        imgRow.push('');
                                        imgData.push(imgRow);
                                    }
                                    imgTable = {
                                        table: {
                                            body: imgData,
                                        },
                                        layout: 'noBorders',
                                    };
                                } else {
                                    imgTable = data;
                                }
                                //console.log(JSON.stringify(imgTable, null, 4))
                                //console.log imgData
                                return resolve(imgTable);
                            });
                        } else if (cell.url && whatAboutImages === 'showImages') {
                            return resolve(this.formatImageToPdf(cell, type, false, false, name));
                        } else if (typeof cell === 'string' && cell !== '') {
                            // mongo returns already base64-string so we just add it to pdf
                            return resolve({
                                image: cell,
                                margin: [0, 5, 0, 5],
                                alignment: 'center',
                                width: 80,
                            });
                        } else {
                            return resolve('');
                        }
                    }),
                );
            } else {
                if (model.get('constructor.modelName')) {
                    if (cell === 0) {
                        formatted = '' + 0;
                    } else {
                        formatted = c._format_(
                            cell,
                            model.get('constructor.modelName'),
                            column.get('ext'),
                            'pdf',
                            model,
                        );
                    }
                } else {
                    formatted = c.format(cell, column, null, model, true);
                }
                if (!formatted) {
                    formatted = '';
                }
                if (typeof formatted === 'object' && formatted) {
                    formatted = formatted;
                }
                if (type === 'list') {
                    if (name) {
                        return rows.push({
                            name: name,
                            value: formatted,
                        });
                    } else {
                        return rows.push({
                            name: name,
                            value: formatted,
                        });
                    }
                } else if (type === 'row') {
                    if (add_titles) {
                        formatted = name(+': ' + formatted);
                    }
                    return rows.push(formatted);
                }
            }
        });
        Em.RSVP.Promise.all(rows).then(function (data) {
            return promise.resolve(data);
        });
        return promise;
    },
    _formatDatabaseArray: function (cell, column) {
        return new Ember.RSVP.Promise((resolve) => {
            var promises;
            promises = [];
            cell.forEach((tmp) => {
                return promises.push(
                    new Ember.RSVP.Promise((resolve) => {
                        return this.get('Collector')
                            .getRecordArray(column.options.form, [tmp.get('id')])
                            .then((records) => {
                                var field, i, len, record, ref, values;
                                record = records[0];
                                values = [];
                                ref = column.options.fields;
                                for (i = 0, len = ref.length; i < len; i++) {
                                    field = ref[i];
                                    cell = record.get(field);
                                    if (cell && cell.then != null) {
                                        values.push(
                                            this._formatDatabaseCell(
                                                cell,
                                                column.options.form,
                                                field,
                                            ),
                                        );
                                    } else if (cell && Ember.typeOf(cell) === 'instance') {
                                        values.push(cell.get('name'));
                                    } else {
                                        values.push(cell);
                                    }
                                }
                                return Ember.RSVP.Promise.all(values).then(
                                    function (resolved_values) {
                                        return resolve(resolved_values.join(' '));
                                    },
                                );
                            });
                    }),
                );
            });
            return Ember.RSVP.Promise.all(promises).then(function (results) {
                return resolve(results.join('\n'));
            });
        });
    },
    _formatDatabaseCell: function (record, form, field) {
        return new Ember.RSVP.Promise(function (resolve) {
            return record.then(function (res) {
                // TODO: fields should be fetched from /forms .. not just guess "name"
                return resolve(res ? res.get('name') : res);
            });
        });
    },
    formatWeatherForPDF: function (weather) {
        var json, text;
        if (!weather) {
            return '-';
        }
        json = JSON.parse(weather);
        if (!json.temperature) {
            json.temperature = '-';
        }
        if (!json.windspeed) {
            json.windspeed = '-';
        }
        if (!json.humidity) {
            json.humidity = '-';
        }
        if (!json.dew_point) {
            json.dew_point = '-';
        }
        text = json.temperature + ' °C, ' + json.windspeed + ' m/s ';
        text += json.humidity + ' % ' + this.get('intl').t('worksitediary.abbr_rel_hum') + ', ';
        text += this.get('intl').t('worksitediary.abbr_dew_point') + ': ' + json.dew_point + ' °C';
        return text;
    },
    exportCollectorPdf: function (fields, model, columns = ['name', 'value']) {
        var promise, self;
        self = this;
        promise = this.formatCollectorFieldsToPdfArray(fields, model, 'list', false, true);
        return promise.then(function (data) {
            var element;
            element = self.createPdfTableElement(columns, data, ['', '']);
            return self.createPdfFromElements([element]).then(function (file) {
                if (typeof cordova !== 'undefined' && cordova !== null) {
                    return self.get('cordova').openFile(file.localURL, 'application/pdf');
                }
            });
        });
    },
    createPdfListElements: function (form) {
        var c, fields, i, item, len, names;
        c = this.get('Collector');
        fields = c.fieldArray(form);
        names = [];
        for (i = 0, len = fields.length; i < len; i++) {
            item = fields[i];
            names.push(item.translated_name);
        }
        return names;
    },
    getPdfFilters: function (rows) {
        var c, filter, filterName, filterValue, i, len, pdf_filters_body, ref, value;
        c = this.get('Collector');
        pdf_filters_body = [];
        ref = this.get('filters');
        for (i = 0, len = ref.length; i < len; i++) {
            filter = ref[i];
            if (filter.get('value') && filter.get('name') !== 'groupBy') {
                if (Ember.isArray(filter.get('value')) && filter.get('value.length') === 0) {
                    continue;
                }
                if (filter.get('type') === 'database') {
                    value = parseInt(filter.get('value'));
                } else {
                    value = filter.get('value');
                }
                if (filter.get('bindto') && !filter.get('field')) {
                    filterValue = value;
                    filterName = this.get('intl').t('filters.free_search');
                } else {
                    filterValue = c._format_(
                        value,
                        filter.get('form'),
                        filter.get('field'),
                        'pdf',
                        rows,
                    );
                    filterName = this.get('intl').t(filter.get('form') + '.' + filter.get('name'));
                }
                if (this.get('full_pdf_export')) {
                    pdf_filters_body.push([
                        {
                            text: filterName + ': ' + filterValue,
                            style: 'headertext',
                        },
                    ]);
                } else {
                    pdf_filters_body.push([filterName + ':', filterValue]);
                }
            }
        }
        return pdf_filters_body;
    },
});

export default PdfExportMixin;
