/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/subcontractor/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSubcontractorRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

import config from '../../../config/environment';

import collector from '../../../classes/collector';

UsersSubcontractorRoute = GeneralReportRoute.extend({
    ttapi: Ember.inject.service(),
    queryParams: {
        project: {
            refreshModel: true,
            replace: true,
        },
        employer: {
            refreshModel: true,
            replace: true,
        },
        page: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
            replace: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        return this.get('ttapi').request('/subcontractor', {
            type: 'GET',
            data: params,
        });
    },
});

export default UsersSubcontractorRoute;
