/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetySavedController;

import Ember from 'ember';

import pdf_mixin from '../../../mixins/pdf-export';

SiteSafetySavedController = Ember.Controller.extend(pdf_mixin, {
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    queryParams: ['email'],
    email: false,
    fields: Ember.computed('model', function () {
        var fields;
        fields = this.get('Collector').fieldArray(this.get('model.constructor.modelName'));
        return fields.reject(function (item) {
            item.ext = item.name;
            return false;
        });
    }),
    afterSave: Ember.computed('model', function () {
        return 'site_safety.' + this.get('model.constructor.modelName') + '.graphs';
    }),
    actions: {
        //        setEmail: (value) ->
        //            @get('store').findRecord('user', value.get('id'), {reload: true}).then (user) =>
        //                user.validate(['email']).then =>
        //                    error = user.get('errors').errorsFor('email')
        //                    email = user.get('email')
        //                    if error.length > 0
        //                        @set 'email_error', error
        //                    else if email
        //                        @set 'email', email
        send_email: function () {
            var email, fields;
            email = this.get('address');
            if (!email) {
                return this.set('email_error', this.get('intl').t('validation.errors.mandatory'));
            } else if (
                email &&
                !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
                    email,
                )
            ) {
                return this.set('email_error', this.get('intl').t('validation.errors.email'));
            } else {
                this.set('loading', true);
                fields = this.get('fields').reject(function (item) {
                    if (item.type === 'databasearray') {
                        return true;
                    }
                });
                return this.formatCollectorFieldsToPdfArray(fields, this.get('model')).then(
                    (data) => {
                        var element;
                        element = this.createPdfTableElement(['name', 'value'], data, ['', '']);
                        return this.createPdfFromElements([element], 'base64').then((pdf) => {
                            var c, intl;
                            c = this.get('Collector');
                            intl = this.get('intl');
                            return c
                                .ttapi({
                                    method: 'post',
                                    url: 'sendReportAsEmail',
                                    data: {
                                        to_address: this.get('address'),
                                        email: pdf,
                                        header: intl.t('skyddsrond.email.title', {
                                            date: moment(this.get('model.date')).format('L'),
                                        }),
                                        content: this.get('email_content'),
                                    },
                                })
                                .then((ret) => {
                                    this.set('email', false);
                                    return this.set('loading', false);
                                });
                        });
                    },
                );
            }
        },
        removeModal: function () {
            return this.set('email', false);
        },
    },
});

export default SiteSafetySavedController;
