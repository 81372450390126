import Component from '@ember/component';
import config from 'tt4/config/environment';
import { inject as service } from '@ember/service';
import { get, computed } from '@ember/object';
import moment from 'moment';
import collector from 'tt4/classes/collector';
import getScript from 'tt4/utils/getScript';

export default Component.extend({
    session: service(),
    intl: service(),
    initapp: service(),
    flags: service(),

    testMode: false,

    enabled: computed(function () {
        return (
            this.flags.test('show-survey') &&
            get(this, 'initapp.appReady') &&
            !get(this, 'session.session.content.authenticated.scope')
        );
    }),
    init() {
        this._super(...arguments);
        if (this.flags.test('show-survey')) {
            const initapp = get(this, 'initapp');
            if (get(initapp, 'appReady')) {
                this.initWootric();
            } else {
                initapp.on('appReady', () => {
                    this.initWootric();
                });
            }
        }
    },

    async initWootric() {
        if (!get(this, 'session.session.content.authenticated.scope')) {
            if (config.environment === 'development' || get(this, 'testMode') === true) {
                window.wootric_survey_immediately = true;
                window.wootric_no_surveyed_cookie = true;
            }
            const user = get(this, 'session.currentUser');
            const intl = get(this, 'intl');

            // browser info
            const { UAParser } = window;
            const parser = new UAParser();
            const bo = parser.setUA(navigator.userAgent).getResult();

            // eg. 'Firefox 68.0 / Gecko 68.0 / Windows 10'
            const browserString = `${bo.browser.name} ${bo.browser.version} / ${bo.engine.name} ${bo.engine.version} / ${bo.os.name} ${bo.os.version}`;

            window.wootricSettings = {
                email: get(user, 'email'),
                created_at: moment(get(user, 'row_info.created')).format('X'),
                language: get(intl, 'primaryLocale'),
                dashboard_language: get(intl, 'primaryLocale'),
                modal_footprint: 'compact',
                disclaimer: {
                    text: intl.t('wootric.disclamer.text').toString(),
                    link: 'https://www.visma.com/privacy/customer-feedback/',
                    link_word: intl.t('wootric.disclamer.link_word').toString(),
                },
                properties: {
                    role: get(user, 'userlevel'),
                    product: config.brand == 'visma' ? 'Visma Entry' : 'Movenium',
                    country: get(user, 'company_info.country')
                        ? get(user, 'company_info.country').toUpperCase()
                        : 'N/A',
                    customer: get(user, 'company'),
                    vatin: get(user, 'company_info.company_vatin'),
                    partnerid: get(user, 'partnerid'),
                    modules: JSON.stringify(collector.products),
                    platform: typeof cordova !== 'undefined' ? device.platform : browserString, // eslint-disable-line
                },
            };

            if (localStorage.getItem('survey') !== null) {
                this.surveyType = localStorage.getItem('survey');
                localStorage.removeItem('survey');
            }

            if (this.WootricSurvey) {
                this.run(this.surveyType);
            } else {
                await getScript('https://cdn.wootric.com/wootric-sdk.js');
                this.WootricSurvey = WootricSurvey;
                this.run(this.surveyType);
            }
        }
    },

    run(type = 'NPS') {
        const intl = this.intl;
        const brand = config.brand;
        WootricSurvey.stop();
        if (type === 'NPS') {
            window.wootricSettings.account_token = 'NPS-EU-bca074ee';
            window.wootricSettings.wootric_recommend_target = intl
                .t('wootric.question.target')
                .toString();
            (window.wootricSettings.product_name =
                brand == 'visma'
                    ? intl.t('wootric.visma.product_name').toString()
                    : intl.t('wootric.default.product_name').toString()),
                (window.customMessages = {
                    followup_questions_list: {
                        detractor_question: intl
                            .t(`wootric.${brand}.followup.detractor`)
                            .toString(),
                        passive_question: intl.t(`wootric.${brand}.followup.passive`).toString(),
                        promoter_question: intl.t(`wootric.${brand}.followup.promoter`).toString(),
                    },
                    placeholder_texts_list: {
                        detractor_text: intl
                            .t(`wootric.${brand}.question.placeholder.detractor`)
                            .toString(),
                        passive_text: intl
                            .t(`wootric.${brand}.question.placeholder.passive`)
                            .toString(),
                        promoter_text: intl
                            .t(`wootric.${brand}.question.placeholder.promoter`)
                            .toString(),
                    },
                });
        } else if (type === 'wizard') {
            window.wootricSettings.account_token = 'NPS-EU-b52c0bfb';
            window.wootricSettings.event_name = 'wizard';
            window.wootricSettings.product_name = null;
        }
        WootricSurvey.run(window.wootricSettings);
    },
});
