import Em from 'ember';
import { get } from '@ember/object';
const DURATION = 750;

export default Em.Service.extend({
    animateElement(element, from, to) {
        return new Promise((resolve) => {
            const easeOutQuad = (x, t, b, c, d) => {
                return -c * (t /= d) * (t - 2) + b;
            };

            const start = new Date().getTime();

            // animation
            (function animate() {
                var now = new Date().getTime() - start;
                var ease = easeOutQuad(0, now, 0, 1, DURATION);
                element.scrollTop = from + (to - from) * ease;
                if (now < DURATION) {
                    setTimeout(animate, 1000 / 60);
                } else {
                    resolve();
                }
            })();
        });
    },

    getScrollableTop() {
        // because the target elements top is calculated relative to the document,
        // and if the scrollable container is not the document,
        // we need to normalize the target elements top based on the top and current scrolled position of the scrollable

        if (document.querySelector('html').offsetTop) {
            return document.querySelector('html').offsetTop - get('scrollable', this).offsetTop;
        } else {
            return 0;
        }
    },

    getVerticalCoord(target, offset = 0) {
        return this.getScrollableTop() + target.offsetTop + offset;
    },

    scrollVertical(target, opts = {}) {
        return new Em.RSVP.Promise((resolve, reject) => {
            this.animateElement(
                document.querySelector('html'),
                document.querySelector('html').scrollTop,
                this.getVerticalCoord(target, opts.offset),
            ).then(resolve, reject);
        });
    },
});
