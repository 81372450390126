/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/orientations/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersViewOrientationsController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

import { alias } from '@ember/object/computed';

UsersViewOrientationsController = GeneralReportController.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    addController: Ember.inject.controller('orientations/add'),
    content: alias('model'),
    filters: Em.A([
        Em.Object.create({
            field: 'ori_site',
        }),
    ]),
    ori_site: null,
    projectObserver: function () {
        return this.set('page', 1);
    }.observes('project'),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
            hideRemove: true,
        }),
    }),
    queryParams: [
        'page',
        'user',
        {
            ori_site: {
                as: 'project',
            },
        },
    ],
    filter: false,
    search: false,
    form: 'orientation',
    localStorageKey: 'Tt4.Orientation',
    showColumns: [
        'ori_date',
        'special_warning',
        'ori_place',
        'ori_user',
        'ori_site',
        'ori_employer',
        'sign_emp_img',
        'sign_per_img',
    ],
    cancelRoute: 'orientations.reports',
    orientations_on: Ember.computed(function () {
        return this.get('Collector').testNeeds(['orientation']);
    }),
    showOriButton: Em.computed('ori_site', 'content.meta.count', function () {
        if (
            this.get('ori_site') &&
            !this.get('ori_site').includes(',') &&
            this.get('content.meta.count') === 0
        ) {
            return true;
        }
    }),
    actions: {
        orientate: function () {
            var addController, userid;
            userid = [parseInt(this.get('model.query.ori_user'))];
            addController = this.get('addController');
            this.get('store')
                .findRecord('project', this.get('ori_site'))
                .then((project) => {
                    addController.set('users', userid);
                    this.set('session.currentUser.currentMySite', project);
                    return this.transitionToRoute('orientations.add');
                });
        },
    },
});

export default UsersViewOrientationsController;
