import Component from '@ember/component';
import { get, set } from '@ember/object';
import { max } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    collector: service('collector-service'),

    columns: null,
    // projects: [{
    //     "number": "1234",
    //     "name": "Eka Projekti",
    //     "address": "Puupolku 4F",
    //     "zip": "67575",
    //     "city": "Puukylä"
    // }],
    projects: null,
    projectRecord: null,
    contactPerson: null,

    init() {
        this._super();
        //if we're re-running the wizard we want to get all projects that may have been created
        let projects = get(this, 'store').findAll('project').then(this._getProjects.bind(this));
        this.projects = projects;
        this.only = 'number,name,address,zip,city,proj_type';
        this.columns = ['number', 'name', 'address', 'zip', 'city'];
        if (this.collector.testNeedsOne('products.rakentajanpaketti')) {
            this.contactPerson = get(this.store.peekAll('export_contact_person'), 'firstObject');
        }
    },

    actions: {
        openAddProjectModal() {
            //get biggest project number+1 to autofill number field
            let newProjectNumber = get(this, 'maxProjectNumber') + 1;
            set(
                this,
                'projectRecord',
                get(this, 'store').createRecord('project', { number: newProjectNumber }),
            );
            set(this, 'showProjectAddModal', true);
        },

        saveProject() {
            set(this, 'showProjectAddModal', false);
        },
    },
    async _getProjects(data) {
        this.projectNumbers = await data.mapBy('number');
        let projectsLength = await data.length;
        this.maxProjectNumber = projectsLength !== 0 ? max('projectNumbers') : 0;
        return data;
    },

    willDestroy() {
        this._super(...arguments);
        if (this.contactPerson) {
            set(this.contactPerson, 'validation', 'off');
            this.contactPerson.save();
        }
    },
});
