/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/gps-auto-km/component.coffee.old
// Generated by CoffeeScript 2.6.1
var GpsAutoKmComponent;

import Ember from 'ember';

GpsAutoKmComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    Google: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    classes: 'form-control add-fields',
    calcDist: Em.computed('model.project', 'model.user', function () {
        var userid;
        //first get user address
        userid = this.get('session.currentUser.id');
        if (this.get('model.user.id') && this.get('model.user.id') !== userid) {
            userid = this.get('model.user.id');
        }
        this.get('store')
            .findRecord('user', userid, {
                reload: true,
                include: true,
            })
            .then((user) => {
                var user_address;
                if (!user.get('address') || !user.get('city')) {
                    return;
                }
                user_address = user.get('address') + ', ' + user.get('city');
                if (user.get('fin_address')) {
                    user_address = user.get('fin_address') + ', ' + user.get('fin_city');
                }
                return this.calculateProjectDist(user_address);
            });
    }),
    calculateProjectDist: function (user_address) {
        var projectid;
        projectid = this.get('model.project.id');
        if (projectid) {
            return this.get('store')
                .findRecord('project', projectid, {
                    reload: true,
                })
                .then((project) => {
                    var coords, office_dist, project_address, project_dist;
                    if (!project.get('address') || !project.get('city')) {
                        this.set('model.km', null);
                        return;
                    }
                    project_address = project.get('address') + ', ' + project.get('city');
                    coords = this.hasAddressSetting();
                    office_dist = null;
                    if (coords) {
                        office_dist = this.calculateAddressSettingDist(
                            JSON.parse(coords),
                            project_address,
                        );
                    }
                    project_dist = this.get('Google').routeDistanceAndTime(
                        user_address,
                        project_address,
                    );
                    Ember.RSVP.Promise.all([project_dist, office_dist]).then((data) => {
                        var coordsJson, dist, finaldist, finaltime;
                        if (Ember.isPresent(data[0]) || Ember.isPresent(data[1])) {
                            dist = data[0]
                                ? data[0]
                                : {
                                      dist: 2e308,
                                  };
                            if (
                                data[1] &&
                                data[1].dist &&
                                parseInt(data[1].dist) < parseInt(dist.dist)
                            ) {
                                dist = data[1];
                            }
                            finaldist = dist.dist;
                            finaltime = dist.time;
                            if (coords) {
                                coordsJson = JSON.parse(coords);
                                if (!coordsJson.no_back_forth) {
                                    finaldist = finaldist * 2;
                                    finaltime = finaltime * 2;
                                }
                            }
                            this.set('model.km', finaldist);
                            return this.set('model.traveltime', finaltime);
                        } else {
                            this.set('model.km', null);
                            return this.set('model.traveltime', null);
                        }
                    });
                });
        }
    },
    hasAddressSetting: function () {
        var coordsString, worktimegroup;
        worktimegroup =
            this.get('model.user.worktimegroup.id') ||
            this.get('session.currentUser.worktimegroup.id');
        coordsString = this.get('customerSettings').checkSetting('autokm_address');
        if (worktimegroup) {
            coordsString = this.get('customerSettings').checkSetting(
                'autokm_address_' + worktimegroup,
            );
        }
        return coordsString;
    },
    calculateAddressSettingDist: function (coords, project_address) {
        return this.get('Google').routeDistanceAndTime(
            {
                lat: coords.latitude,
                lng: coords.longitude,
            },
            project_address,
        );
    },
});

export default GpsAutoKmComponent;
