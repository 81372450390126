/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/employer-sums/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerEmployerSumsController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

TimetrackerEmployerSumsController = GeneralReportController.extend({
    showColumns: ['employer', 'work_hours'],
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'date',
                form: 'worktime',
                type: 'date',
                value: this.get('date'),
                wagePeriods: true,
            }),
            Em.Object.create({
                field: 'project',
                form: 'worktime',
                value: this.get('project'),
            }),
        ]);
    }),
    form: 'worktime',
    date:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    queryParams: ['project', 'page', 'date'],
    project: null,
    page: null,
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
            hideCheckbox: true,
        }),
    }),
    actions: {
        filterChanged: function (value, field) {
            return this.set(field, value);
        },
    },
});

export default TimetrackerEmployerSumsController;
