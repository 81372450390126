import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import EmberObject, { get, set } from '@ember/object';

export default Route.extend({
    ttapi: service(),
    localStorageWrapper: service(),

    queryParams: {
        date: { refreshModel: true },
        page: { refreshModel: true },
        user: { refreshModel: true },
    },

    model: function (params) {
        let anomalies = [];
        let controller = this.controllerFor('timetracker/anomalies');
        return new Promise((resolve) => {
            new Promise((resolve) => {
                let tests = get(this, 'ttapi')._get('/api/anomalyReport/anomalies');
                resolve(tests);
            }).then((tests) => {
                tests.forEach((anomaly) => {
                    let testParams = {
                        test: anomaly,
                        active: true,
                        translatedName: 'anomaly.' + anomaly,
                        tolerance: get(controller, 'tolerance'),
                    };
                    anomalies.push(EmberObject.create(testParams));
                });

                set(controller, 'anomalyTests', anomalies);
                params['anomalies'] = JSON.parse(JSON.stringify(anomalies));

                resolve(get(this, 'ttapi')._post('/api/anomalyReport', params));
            });
        });
    },
});
