/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSavedController;

import Ember from 'ember';

import savedController from '../../../controllers/saved';

UsersSavedController = savedController.extend({
    //    needs: 'employers/add'
    actions: {
        addUser: function () {
            var user;
            //            user = @get 'model.id'
            //            controller = @get 'controllers.orientations/add'
            //            controller.set 'users', Em.A([parseInt(user,10)])
            user = this.store.peekRecord('user', null);
            if (!user) {
                user = this.store.createRecord('user');
            }
            user.set('employer', this.get('model'));
            return this.transitionToRoute('users.add');
        },
    },
});

export default UsersSavedController;
