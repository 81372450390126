import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

export default Component.extend({
    intl: service(),
    store: service(),
    analytics: service(),
    linkIds: computed.mapBy('hideUsers', 'id'),

    dateRanges: computed(function () {
        return [
            { id: 'current_week', name: this.intl.t('widget.anomalities.current_week') },
            { id: 'last_week', name: this.intl.t('widget.anomalities.last_week') },
            { id: 'last_2_weeks', name: this.intl.t('widget.anomalities.last_2_weeks') },
            { id: 'current_month', name: this.intl.t('widget.anomalities.current_month') },
            { id: 'last_month', name: this.intl.t('widget.anomalities.last_month') },
        ];
    }),
    sendMethods: computed(function () {
        return [
            { id: 'sms', name: this.intl.t('widget.anomalities.sms') },
            { id: 'push', name: this.intl.t('widget.anomalities.push') },
            { id: 'email', name: this.intl.t('widget.anomalities.email') },
        ];
    }),

    showUsers: computed('users', 'hideUserIds', 'hideUserIds.length', function () {
        return this.users.filter((user) => !this.hideUserIds.includes(user.id));
    }),

    hiddenUsers: computed('users', 'hideUserIds', 'hideUserIds.length', function () {
        return this.users.filter((user) => this.hideUserIds.includes(user.id));
    }),

    init() {
        this._super();
        let record = this.store
            .peekAll('widget-anomalies-setting')
            .findBy('name', 'missing_worktimes');
        if (!record)
            record = this.store.createRecord('widget-anomalies-setting', {
                name: 'missing_worktimes',
                value: { selectedDateRange: 'current_week', hideUserIds: [], sendBy: 'push' },
            });
        set(this, 'record', record);
        set(this, 'selectedDateRange', this.record.value.selectedDateRange);
        set(this, 'hideUserIds', A(this.record.value.hideUserIds || []));
        set(this, 'sendBy', this.record.value.sendBy);

        set(this, 'users', []);
        this.loadUsers();
    },

    actions: {
        save() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Missing worktimes settings saved',
                label: 'Anomalities widget',
            });

            set(this, 'record.value.selectedDateRange', this.selectedDateRange);
            set(this, 'record.value.hideUserIds', this.hideUserIds);
            set(this, 'record.value.sendBy', this.sendBy);

            this.record.save();
            this.cancel();
        },
        cancel() {
            this.cancel();
        },
        searchChanged(value) {
            this.loadUsers(typeof value == 'string' ? value : null);
        },
        selectUser(user) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'User selected to be shown on missing worktimes',
                label: 'Anomalities widget',
            });
            this.hideUserIds.removeObject(user.id);
        },
        deselectUser(user) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'User selected to be hidden on missing worktimes',
                label: 'Anomalities widget',
            });
            this.hideUserIds.pushObject(user.id);
        },
    },

    async loadUsers(term) {
        let params = {};
        if (term) params.q = term;
        set(this, 'users', await get(this, 'store').query('user', params));
    },
});
