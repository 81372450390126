import Controller from '@ember/controller';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    store: service(),
    collector: service('collector-service'),
    addMeasurement: alias('model'),
    project_obj: computed('model', function () {
        if (get(this, 'collector').fieldExists(get(this, 'model'), 'project')) {
            return get(this, 'model') + '.project';
        }
        return 'measurement_' + get(this, 'model') + '.project';
    }),
    oldMeasurement: computed('project', function () {
        let measurementName = get(this, 'model');
        if (!get(this, 'collector').fieldExists(get(this, 'model'), 'project')) {
            measurementName = 'measurement_' + get(this, 'model');
        }
        return ObjectProxy.extend(PromiseProxyMixin).create({
            //eslint-disable-line
            promise: new EmberPromise((resolve) => {
                get(this, 'store')
                    .query(measurementName, {
                        date: moment().format('YYYY-MM-DD'),
                        project: get(this, 'project.id'),
                    })
                    .then((measurements) => this._resolveMeasurement(measurements, resolve));
            }),
        });
    }),
    actions: {
        projectChanged(project) {
            set(this, 'project', project);
        },
    },

    _resolveMeasurement(measurements, resolve) {
        if (get(measurements, 'firstObject')) {
            resolve({ id: get(measurements, 'firstObject').id });
        } else {
            resolve();
        }
    },
});
