import Controller from '@ember/controller';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    collector: service('collector-service'),
    intl: service(),
    project: null,
    init() {
        this._super(...arguments);
        const intl = get(this, 'intl');
        set(this, 'options', {
            tr: {
                working: {
                    options: [
                        intl.t('tr.notice.desc.helmet'),
                        intl.t('tr.notice.desc.glasses'),
                        intl.t('tr.notice.desc.vest'),
                        intl.t('tr.notice.desc.shoes'),
                        intl.t('tr.notice.desc.harness'),
                        intl.t('tr.notice.desc.idcard'),
                        intl.t('tr.notice.desc.extinguish'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                scaffolding: {
                    options: [
                        intl.t('tr.notice.desc.ladder'),
                        intl.t('tr.notice.desc.stool'),
                        intl.t('tr.notice.desc.ramp'),
                        intl.t('tr.notice.desc.rail'),
                        intl.t('tr.notice.desc.guide'),
                        intl.t('tr.notice.desc.footboard'),
                        intl.t('tr.notice.desc.scaffoldingcard'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                tools: {
                    options: [
                        intl.t('tr.notice.desc.circular_saw_cover'),
                        intl.t('tr.notice.desc.circular_saw_stick'),
                        intl.t('tr.notice.desc.moonwalker'),
                        intl.t('tr.notice.desc.personlifter'),
                        intl.t('tr.notice.desc.scissor_lifter'),
                        intl.t('tr.notice.desc.gasbottles'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                fall: {
                    options: [
                        intl.t('tr.notice.desc.gapcover_missing'),
                        intl.t('tr.notice.desc.gapcover_unmarked'),
                        intl.t('tr.notice.desc.rail_missing'),
                        intl.t('tr.notice.desc.handguide_missing'),
                        intl.t('tr.notice.desc.guide_missing'),
                        intl.t('tr.notice.desc.footboard'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                electricity: {
                    options: [
                        intl.t('tr.notice.desc.electricitybox'),
                        intl.t('tr.notice.desc.general_lighting'),
                        intl.t('tr.notice.desc.cabels'),
                        intl.t('tr.notice.desc.cabels_broken'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                garbage: {
                    options: [
                        intl.t('tr.notice.desc.area_orderliness'),
                        intl.t('tr.notice.desc.garbage_bin_full'),
                        intl.t('tr.notice.desc.garbage_contains_wood'),
                        intl.t('tr.notice.desc.wood_garbage'),
                        intl.t('tr.notice.desc.material_moving'),
                        intl.t('tr.notice.desc.chisel_garbage'),
                        intl.t('tr.notice.desc.masonry_garbage'),
                        intl.t('tr.notice.desc.drywall_garbage'),
                        intl.t('tr.notice.desc.plumber_garbage'),
                        intl.t('tr.notice.desc.electrician_garbage'),
                        intl.t('tr.notice.desc.IV_garbage'),
                        intl.t('tr.notice.desc.vacuuming'),
                        intl.t('tr.notice.desc.chisel_job'),
                        intl.t('tr.notice.desc.clearing_job'),
                        intl.t('tr.notice.desc.moving_job'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                dust: {
                    options: [
                        intl.t('tr.notice.desc.sanddust'),
                        intl.t('tr.notice.desc.sawdust'),
                        intl.t('tr.notice.desc.drywalldust'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
            },
            mvr: {
                working: {
                    options: [
                        intl.t('mvr.notice.desc.helmet'),
                        intl.t('mvr.notice.desc.glasses'),
                        intl.t('mvr.notice.desc.vest'),
                        intl.t('mvr.notice.desc.shoes'),
                        intl.t('mvr.notice.desc.harness'),
                        intl.t('mvr.notice.desc.idcard'),
                        intl.t('mvr.notice.desc.extinguish'),
                        intl.t('mvr.notice.desc.risk'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },

                equipment: {
                    options: [
                        intl.t('mvr.notice.desc.machines'),
                        intl.t('mvr.notice.desc.tools'),
                        intl.t('mvr.notice.desc.electricity'),
                        intl.t('mvr.notice.desc.lighting'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                protection: {
                    options: [
                        intl.t('mvr.notice.desc.fall'),
                        intl.t('mvr.notice.desc.collapse'),
                        intl.t('mvr.notice.desc.safety_zone'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                road: {
                    options: [
                        intl.t('mvr.notice.desc.trafic'),
                        intl.t('mvr.notice.desc.walking'),
                        intl.t('mvr.notice.desc.worksite_road'),
                        intl.t('mvr.notice.desc.passage'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
                order: {
                    options: [
                        intl.t('mvr.notice.desc.general_order'),
                        intl.t('mvr.notice.desc.garbage_bins'),
                        intl.t('mvr.notice.desc.dangerous_materials'),
                        intl.t('site_safety.notice.desc.other'),
                    ],
                },
            },
        });
    },
    form: alias('model'),
    parseFields(form) {
        const fields = get(this, 'collector').fieldArray(form);
        let temp = A([]);
        let counter = 1;
        const options = get(this, 'options');
        // make helper array for fields with notices
        let helper_notices = {};
        fields.forEach((item) => {
            const key_arr = get(item, 'name').split('_');
            if (key_arr[1] === 'notices') {
                helper_notices[key_arr[0]] = true;
            }
        });

        fields.forEach((item) => {
            const key_arr = get(item, 'name').split('_');
            if (key_arr[1] === 'plus') {
                const ext = key_arr[0];
                counter++;
                const title = get(this, 'intl').exists(form + '.title.' + ext)
                    ? get(this, 'intl').t(form + '.title.' + ext)
                    : counter + '. ' + item.translated_name;
                temp.push({
                    ext: ext,
                    title: title,
                    notices: helper_notices[ext] == true,
                    options: options[form] ? options[form][ext].options : false,
                });
            }
        });
        return temp;
    },
    date: moment().subtract(3, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
});
