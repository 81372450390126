import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class AppVersionDialogComponent extends Component {
    @service('collector-service') Collector;
    @service() gui;

    @tracked completed;
    get styleNamespace() {
        return podNames['app-version-dialog'];
    }
    // TODO: Remove in EP-11985
    get isEntry() {
        return this.Collector.testNeedsOne('products.generic_timetracker');
    }
    get isOtta() {
        return this.Collector.testNeedsOne('products.generic_timetracker');
    }
    get currentLang() {
        return moment.locale();
    }
}
