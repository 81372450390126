/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/form-view/route.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormViewRoute;

import Ember from 'ember';

FormsFormViewRoute = Ember.Route.extend({
    model: function (params) {
        this.set('modelName', params.form.dasherize());
        return this.store.findRecord(this.get('modelName'), params.id, {
            include: true,
        });
    },
});

export default FormsFormViewRoute;
