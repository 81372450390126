/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/database-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDropdownFieldComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import collectorField from '../../../../mixins/collector-field';

import $ from 'jquery';

CollectorFieldsDropdownFieldComponent = Ember.Component.extend(collectorField, {
    localStorageWrapper: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionGlobals: Ember.inject.service(),
    dialogs: Em.inject.service(),
    router: Em.inject.service(),
    showOptions: false,
    value: '',
    text: '',
    search: '',
    prompt: Ember.computed('field.prompt', function () {
        if (this.get('field.show_prompt')) {
            return this.get('field.prompt');
        }
        if (this.get('field.features.remove_empty_selection')) {
            return null;
        }
        if (this.get('field.prompt.string')) {
            return this.get('field.prompt');
        }
        if (this.get('field.prompt')) {
            return this.get('intl').t(this.get('field.prompt'));
        } else {
            if (this.get('useAsFilter')) {
                return (
                    this.get('intl').t('general.select_choose') +
                    ' ' +
                    this.Collector.getTranslationForColumn(
                        this.get('field.form'),
                        this.get('field.name'),
                    ) +
                    '...'
                );
            } else {
                return this.get('intl').t('general.select_choose');
            }
        }
    }),
    query: '',
    options: Em.A([]),
    // possible values
    //   normal = browser's internal component
    //   search = custom dropdown with search
    //   lastused = list of last used selctions from localstorage <- not used anymore
    dropdownType: 'normal',
    use_normal_dropdown_limit: 30,
    ajax_search_limit: Em.computed.alias('use_normal_dropdown_limit'),
    localStorage_size_limit: 20,
    useBrowsersInternalDropdown: true,
    localStorageKey: Em.computed('field.name', function () {
        return 'select_' + this.get('field.name');
    }),
    defaultValueForNormalDropdown: null,
    initAliases: Em.on('init', function () {
        return Ember.defineProperty(
            this,
            'modelValue',
            Ember.computed.alias('model.' + this.get('field.name')),
        );
    }),

    // decide which version to use

    initilizeDropdown: Em.on(
        'init',
        Em.observer('session.currentUser', 'field.filterOptions', function () {
            var settings;
            if (!this.get('session.currentUser')) {
                return;
            }
            settings = this.getSettingsFromLs();
            //if settings.use and settings.objects? and settings.objects.length > 3
            //    @set 'dropdownType', 'lastused'
            //    @set 'options', @getOptionsFromLocalStorage()
            //    @set 'moreCount', 'more'
            //    return
            return Ember.run.next(() => {
                // fetching must be done in next loop so everything is settled down and possible reloader field is ready
                if (this.get('isDestroyed')) {
                    return;
                }
                return this.fetchFromDatabase().then((data) => {
                    if (this.get('isDestroyed')) {
                        return;
                    }
                    if (
                        !this.get('field.show_prompt') &&
                        !this.get('field.value') &&
                        this.get('field.features.remove_empty_selection')
                    ) {
                        this.fetchRecordById(Ember.get(data, 'firstObject.id')).then((record) => {
                            // super ugly hax to prevent overwriting delayed changes from parent component
                            // already made an issue to rewrite this whole component EP-4228
                            return Ember.run.later(
                                this,
                                () => {
                                    if (!this.get('value')) {
                                        return this.sendChange(record);
                                    }
                                },
                                2500,
                            );
                        });
                    }
                    if (this.get('queryRowCount') > this.get('ajax_search_limit')) {
                        if (!settings.use) {
                            this.setUseLocalStorage();
                        }
                        //@set 'moreCount', data.count - @get 'ajax_search_limit'
                        return this.set('dropdownType', 'search');
                    } else {
                        this.set('dropdownType', 'normal');
                        if (this.get('field.value')) {
                            return this.set(
                                'defaultValueForNormalDropdown',
                                this.get('field.value'),
                            );
                        } else {
                            return this.set(
                                'defaultValueForNormalDropdown',
                                this.get('model.' + this.get('field.name') + '.id'),
                            );
                        }
                    }
                });
            });
        }),
    ),
    offset: 0,

    // Fetch options from backend .. Use search query if it is set

    fetchFromDatabase: function (loadMore) {
        var c, field, promise;
        c = this.get('Collector');
        field = c.field(this.get('form'), this.get('object'));
        if (loadMore) {
            this.set('offset', this.get('offset') + this.get('ajax_search_limit'));
        } else {
            this.set('offset', 0);
        }
        promise = this.queryBackend(field.options.form);
        //lataa aluksi, paina lataa lisää, offsettiä kasvatetaan ajax_search_limitin verran, tulos appendataan optionseihin
        promise.then((data) => {
            var moreCount, options;
            options = this.convertOptions(data);
            if (this.get('isDestroyed')) {
                return;
            }
            this.set('loading', false);
            if (loadMore) {
                this.get('options').pushObjects(options);
            } else {
                this.set('options', options);
            }
            moreCount = this.get('queryRowCount') - this.get('options.length');
            return this.set('moreCount', moreCount > 0 ? moreCount : null);
        });
        //@set 'useSaveToLocalStorage', true if data.count >= @get('search_limit') and not query
        return promise;
    },
    queryBackend: function (form) {
        var c, params, promise, query_field, row_status, show_removed_arr;
        c = this.get('Collector');
        if (this.get('useAsFilter') && this.get('sessionGlobals').get('show_removed')) {
            show_removed_arr = this.get('sessionGlobals').get('show_removed');
            if (show_removed_arr && show_removed_arr[form]) {
                row_status = 'removed';
            }
        }
        params = {
            form: this.get('form'),
            field: this.get('object'),
            limit: this.get('ajax_search_limit'),
            offset: this.get('offset'),
            row_info_status: row_status,
            q: this.get('query'),
        };
        //if values loaded by some other field, use that as parameter
        if (this.get('loadedValuesBy')) {
            query_field = c.field(this.get('form'), this.get('loadedValuesByKey'));
            params[query_field.options.form] = this.get('loadedValuesBy');
        }
        this.addFilterOptionsToParams(params);
        promise = this.get('store').query(form, params);
        promise.then((data) => {
            if (this.get('isDestroyed')) {
                return;
            }
            if (this != null) {
                return this.set('queryRowCount', data.meta.count);
            }
        });
        return promise;
    },
    addFilterOptionsToParams: function (params) {
        var filterOptions, key, results;
        filterOptions = this.get('field.filterOptions');
        results = [];
        for (key in filterOptions) {
            results.push((params[key] = filterOptions[key]));
        }
        return results;
    },
    convertOptions: function (data) {
        var options;
        options = [];
        data.forEach((row) => {
            return options.push({
                id: row.get('id'),
                text: this.getText(row),
            });
        });
        return options;
    },

    //   fetch preselected object from localstorage and show it in dropdown

    setDefaultValue: Ember.on(
        'init',
        Em.observer('dropdownType', function () {
            var id, option;
            if (Ember.isPresent(this.get('field.value')) && this.get('dropdownType') !== 'normal') {
                id = this.get('field.value');
                option = this.getOptionsFromLocalStorage().findBy('id', parseInt(id, 10));
                if (option) {
                    return this.setState(id, option.text);
                } else {
                    return this.fetchRecordById(id).then((record) => {
                        return this.setState(id, this.getText(record));
                    });
                }
            }
        }),
    ),

    //   Create list of options to use with browser's internal component

    normalDropdownOptions: Em.computed('options', 'defaultValueForNormalDropdown', function () {
        var field, subform;
        if (
            !this.get('useAsFilter') &&
            !this.get('model.isNew') &&
            Ember.isPresent(this.get('defaultValueForNormalDropdown')) &&
            !this.get('options').findBy('id', this.get('defaultValueForNormalDropdown'))
        ) {
            field = this.get('Collector').field(this.get('form'), this.get('object'));
            subform = field.options.form;
            this.get('options').pushObject({
                id: this.get('defaultValueForNormalDropdown'),
                text: this.getText(
                    this.get('store').peekRecord(
                        subform,
                        this.get('defaultValueForNormalDropdown'),
                    ),
                ),
            });
        }
        return this.get('options').map((option) => {
            Ember.set(option, 'name', option.text);
            return option;
        });
    }),

    //   Same as above but include "last used" objects

    normalDropdownOptionsWithLastused: Em.computed('normalDropdownOptions', function () {
        var intl, lastused;
        lastused = this.getOptionsFromLocalStorage()
            .slice(0, 5)
            .map(function (option) {
                option.name = option.text;
                return option;
            });
        intl = this.get('intl');
        if (this.get('loadedValuesBy')) {
            lastused = false;
        }
        if (lastused.length > 0 && this.get('normalDropdownOptions.length') > 10) {
            return [
                {
                    label: intl.t('select.last_selections'),
                    options: lastused,
                },
                {
                    label: intl.t('select.all'),
                    options: this.get('normalDropdownOptions'),
                },
            ];
        } else {
            return null;
        }
    }),

    //   options for "custom dropdown with search"

    optionsForSearcheableDropdown: Ember.computed('options.[]', function () {
        var temp;
        temp = Em.A([]);
        //if not @get 'search'
        //    temp.pushObject {id: null, text: @get('intl').t("select.clear"), class: "clear"}

        //if @get('dropdownType') == 'lastused' and not @get 'search'
        //    temp.pushObject {id: null, text: @get('intl').t("select.last_selections"), class: "lastused"}
        return temp.pushObjects(this.get('options'));
    }),

    //   Same as above but include "last used" objects

    optionsForSearcheableDropdownWithLastused: Em.computed(
        'optionsForSearcheableDropdown',
        function () {
            var intl, lastused, temp;
            lastused = this.getOptionsFromLocalStorage().slice(0, 15);
            if (this.get('loadedValuesBy')) {
                lastused = false;
            }
            intl = this.get('intl');
            temp = Em.A([]);
            if (!this.get('search')) {
                temp.pushObject({
                    id: null,
                    text: this.get('intl').t('select.clear'),
                    class: 'clear',
                });
            }
            if (lastused.length > 0 && !this.get('search')) {
                temp.pushObject({
                    id: null,
                    text: this.get('intl').t('select.last_selections'),
                    class: 'lastused',
                });
                temp.pushObjects(lastused);
                temp.pushObject({
                    id: null,
                    text: this.get('intl').t('select.all'),
                    class: 'lastused',
                });
            }
            temp.pushObjects(this.get('optionsForSearcheableDropdown'));
            return temp;
        },
    ),

    //  Observe when user types something to searchable dropdown and update options accordingly

    ObserveSearch: Ember.observer('search', function () {
        var search;
        search = this.get('search');
        if (!search && this.get('dropdownType') === 'lastused') {
            this.set('moreCount', 'more');
            this.set('options', this.getOptionsFromLocalStorage());
            return;
        }
        if (typeof search !== 'string') {
            return;
        }
        if (!this.get('showOptions')) {
            return;
        }
        this.set('query', search);
        this.set('loading', true);
        return Ember.run.debounce(this, this.fetchFromDatabase, 700);
    }),
    // i hope no one finds this..
    obsValue: Em.observer('field.value', function () {
        if (Em.isEmpty(this.get('field.value'))) {
            return this.set('search', null);
        }
    }),
    loadedValuesBy: null,

    // this is for reloading options in another dropdown according to value in another (ie tasks by projects)

    modifyDatabaseItems: function (sender, key, user_selected = true) {
        var c,
            data,
            field,
            form,
            forms,
            old_value,
            promise,
            query,
            query_field,
            query_key,
            subform,
            value;
        old_value = this.get('field.value');
        c = this.get('Collector');
        query = this.get('query');
        query_key = key.split('.')[1];
        value = this.get('model.' + query_key + '.id');
        // reload supporting multiselects .. those values are array of indexes .. not a record
        if (!value && Ember.isArray(this.get('model.' + query_key))) {
            value = this.get('model.' + query_key);
        }
        // dismiss unneeded observer calls
        if (value === this.get('loadedValuesBy')) {
            return;
        }
        if (!value && !this.get('loadedValuesBy')) {
            return;
        }
        // test that field has linkings for query_key .. if it doesn't and request is made api returns error
        // only test for project tasks .. ugly hack.. :(
        if (this.get('object') === 'task' && query_key === 'project') {
            forms = c.forms();
            form = forms[this.get('object')];
            if (form != null && form.features != null) {
                if (Ember.isArray(form.features.link)) {
                    if (form.features.link.indexOf(query_key) === -1) {
                        return;
                    }
                } else if (form.features.link !== query_key) {
                    return;
                }
            } else {
                return;
            }
        }
        this.set('loadedValuesBy', value);
        this.set('loadedValuesByKey', query_key);
        data = {};
        query_field = c.field(this.get('form'), query_key);
        if (value) {
            data[query_field.options.form] = value;
        }
        data['form'] = this.get('form');
        data['field'] = this.get('object');
        field = c.field(this.get('form'), this.get('object'));
        promise = this.get('store').query(field.options.form, data);
        subform = field.options.form;
        // Not using search limit since this doesn't work with search dropdown and we want all options.
        return promise.then((data) => {
            var add_option, option, options;
            options = this.convertOptions(data);
            if (this.get('isDestroyed')) {
                return;
            }
            if (!old_value) {
                this.send('select');
            }
            option = [];
            if (old_value) {
                option = options.filterBy('id', old_value.toString());
                // this is the last resort .. if there is more than 100 filtered records and old_value is not one of them .. we
                // try to fetch record with peekRecord and add it on the fly to options.. it should be found (almost always) because
                // we came to edit route from report witch has already sideloaded the correct record
                if (
                    Ember.isEmpty(option) &&
                    !this.get('model.isNew') &&
                    ((user_selected && data.get('meta.count') > 100) || !user_selected) &&
                    this.router._router.currentPath !== 'timetracker.timecard.add'
                ) {
                    add_option = {
                        id: old_value,
                        text: this.getText(this.get('store').peekRecord(subform, old_value)),
                    };
                    option = [add_option];
                    options.push(add_option);
                }
            }
            value = void 0;
            if (option.get('length') > 0) {
                value = option[0];
            } else if (options.length === 1 && !field.features.do_not_preselect) {
                value = options[0];
            }
            if (this.get('dropdownType') === 'normal') {
                this.set('defaultValueForNormalDropdown', value ? value.id : value);
                this.send('normalSelect', value ? value.id : value);
            } else {
                this.send('select', value);
            }
            return this.set('options', options);
        });
    },

    // add observers for modifyDatabaseItems method

    didReceiveAttrs: function () {
        var c, field, forms, self;
        field = this.get('field');
        c = this.get('Collector');
        forms = c.fieldArray(this.get('form'));
        self = this;
        return forms.forEach(function (form) {
            var ref, reload;
            reload = form.features.reload;
            if (!Ember.isArray(reload)) {
                reload = [reload];
            }
            if (((ref = field.name), indexOf.call(reload, ref) >= 0)) {
                self.addObserver('model.' + form.name, self, self.modifyDatabaseItems);
                return Ember.run.later(() => {
                    return self.modifyDatabaseItems(self, 'model.' + form.name, false);
                }, 500);
            }
        });
    },
    getText: function (record, field) {
        var i, len, ref, ref1, text, val;
        text = '';
        if (!record) {
            return '';
        }
        if ((!field && !this.get('form')) || !this.get('object')) {
            return '';
        }
        if (!field) {
            field = this.get('Collector').field(this.get('form'), this.get('object'));
        }
        ref = field.options.fields;
        for (i = 0, len = ref.length; i < len; i++) {
            field = ref[i];
            if (!field) {
                continue;
            }
            val = record.get(field);
            if (val && (ref1 = Ember.typeOf(val)) !== 'object' && ref1 !== 'array') {
                text += val + ' ';
            }
        }
        return text.slice(0, -1);
    },
    setState: function (id, text = '') {
        this.set('value', id);
        this.set('text', text);
        return this.set('search', text);
    },
    //ObservePromptTranslation: Ember.on 'init', Ember.observer 'promptTranslation', ->
    //    @set 'prompt', @get('intl').t @get 'promptTranslation'
    addModelObs: Ember.on('init', function () {
        var modelfield;
        //@updateValueFromModel true
        modelfield = 'model.' + this.get('object');
        return this.addObserver(modelfield, this, this.modelChanged);
    }),
    modelChanged: function () {
        if (!this.get('userHasSelected')) {
            return Ember.run.once(this, this.updateValueFromModel);
        }
    },
    updateValueFromModel: function (init) {
        var modelfieldid, modelfieldtext, option_id, option_text, orig_value;
        modelfieldid = 'model.' + this.get('object') + '.id';
        modelfieldtext = 'model.' + this.get('object') + '.name';
        option_id = +this.get(modelfieldid);
        orig_value = this.get('value');
        option_text = this.get(modelfieldtext);
        if (!option_text) {
            option_text = this.getRealOptionText();
        }
        if (option_id != null && option_id && option_id !== orig_value) {
            if (init) {
                this.set('field.value', option_id);
                return this.setDefaultValue();
            } else {
                return this.setState(option_id, option_text);
            }
        }
    },
    getRealOptionText: function () {
        var fields, textArr;
        fields = Ember.get(
            this.get('Collector').field(this.get('form'), this.get('object')),
            'options.fields',
        );
        textArr = [];
        fields.forEach((field) => {
            return textArr.push(this.get('model.' + this.get('object') + '.' + field));
        });
        return textArr.join(' ');
    },
    fetchRecordById: function (id) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var f, field, parsedid, record;
            if (!id) {
                resolve(null);
            }
            field = this.get('field');
            f = this.get('Collector').field(field.form, field.name);
            parsedid = id.length > 15 ? id : parseInt(id, 10);
            record = this.get('store').peekRecord(f.options.form, parsedid);
            if (record) {
                return resolve(record);
            } else {
                return this.get('store')
                    .findRecord(f.options.form, parsedid)
                    .then(
                        (record) => {
                            return resolve(record);
                        },
                        function (reason) {
                            return resolve(null);
                        },
                    );
            }
        });
    },
    pushToStore: function (type, data) {
        data.type = type;
        data.attributes = data.values;
        return this.get('store').push({
            data: data,
        });
    },
    //#####################################################################################################

    // LocalStorage

    sendChange: function (record) {
        if (record === null) {
            this.set('field.value', null);
        } else if (typeof record === 'object') {
            this.set('field.value', record.get('id'));
        } else {
            this.set('field.value', record);
        }
        return this.sendAction('bubbleChange', record, this.get('field.name'));
    },
    getSettingsFromLs: function () {
        return this.get('localStorageWrapper').getUserSetting(this.get('localStorageKey'), {});
    },
    saveSettingsToLs: function (settings) {
        if (!this.get('session.currentUser')) {
            return;
        }
        return this.get('localStorageWrapper').setUserSetting(
            this.get('localStorageKey'),
            settings,
        );
    },
    setUseLocalStorage: function () {
        var settings;
        settings = this.getSettingsFromLs();
        settings.use = true;
        return this.saveSettingsToLs(settings);
    },
    addToLastused: function (id, text) {
        var item, lastused, settings;
        settings = this.getSettingsFromLs();
        lastused = Em.A(settings.objects);
        if ((item = lastused.findBy('id', id))) {
            item.used = moment().format('YYYY-MM-DD HH:mm:ss');
            item.text = text;
        } else {
            lastused.pushObject({
                id: id,
                text: text,
                used: moment().format('YYYY-MM-DD hh:mm:ss'),
            });
        }
        settings.objects = lastused
            .sortBy('used')
            .reverse()
            .slice(0, this.get('localStorage_size_limit'));
        return this.saveSettingsToLs(settings);
    },
    removeFromLastUsed: function (id) {
        var item, lastused, settings;
        settings = this.getSettingsFromLs();
        lastused = Em.A(settings.objects);
        if ((item = lastused.findBy('id', id))) {
            lastused.removeObject(item);
            settings.objects = lastused
                .sortBy('used')
                .reverse()
                .slice(0, this.get('localStorage_size_limit'));
            return this.saveSettingsToLs(settings);
        }
    },
    getOptionsFromLocalStorage: function () {
        var settings;
        settings = this.getSettingsFromLs();
        return Em.A(settings.objects);
    },
    //####################################################################################################
    setSessionRemoved: function (state) {
        var show_removed;
        if (state === 'show_removed') {
            this.set('show_removed', true);
            show_removed = this.get('sessionGlobals').get('show_removed');
            if (!show_removed || show_removed === null) {
                show_removed = {};
            }
            show_removed[this.get('field.name')] = true;
            this.get('sessionGlobals').set('show_removed', show_removed);
            this.initilizeDropdown();
        }
        if (state === 'hide_removed') {
            this.set('show_removed', false);
            show_removed = this.get('sessionGlobals').get('show_removed');
            //if !show_removed || show_removed is null
            //    show_removed = {}
            if (show_removed[this.get('field.name')]) {
                delete show_removed[this.get('field.name')];
                this.get('sessionGlobals').set('show_removed', show_removed);
            }
            return this.initilizeDropdown();
        }
    },
    clearOptions: function () {
        return this.set('options', []);
    },
    actions: {
        focusIn: function () {
            this.set('search', '');
            return Em.run.later(
                this,
                function () {
                    return this.set('showOptions', true);
                },
                200,
            );
        },
        touchMove: function () {
            //If the user touches this field and starts scrolling, we actually want to scroll and not snap to this field and open options
            // #UX´
            return Em.run.debounce(
                this,
                function () {
                    return this.set('touchMoving', true);
                },
                50,
            );
        },
        touchStart: function () {
            //Only open the options if we have not initiated scrolling
            return Em.run.later(
                this,
                function () {
                    if (!this.get('touchMoving')) {
                        this.set('search', '');
                        return this.set('showOptions', true);
                    }
                },
                200,
            );
        },
        touchEnd: function () {
            //We have ended fiddling with this field,
            // if we were moving then blur to not snap scroll back to this field because of mobile shenanigans
            if (this.get('touchMoving')) {
                this.set('touchMoving', false);
                return this.element.querySelector('input').blur();
            }
        },
        closeOptions: function () {
            this.set('showOptions', false);
            this.set('search', this.get('text') || this.get('prompt'));
            return this.set('query', '');
        },
        focusOut: function () {
            this.set('showOptions', false);
            this.set('search', this.get('text') || this.get('prompt'));
            this.set('query', '');
            return this.fetchFromDatabase();
        },
        loadMore: function () {
            return this.fetchFromDatabase(true);
        },
        normalSelect: function (id) {
            var option;
            if (id === null || id === '' || id === void 0 || id === 'null') {
                this.sendChange(null);
            }
            if (id === 'show_removed' || id === 'hide_removed') {
                if (id === 'show_removed') {
                    this.get('dialogs').alert(this.get('intl').t('general.showing_removed'));
                }
                this.setSessionRemoved(id);
                return;
            }
            if (id === '(empty)') {
                this.sendChange(id);
                return;
            }
            option = this.get('normalDropdownOptions').findBy('id', parseInt(id, 10));
            if (option) {
                this.addToLastused(parseInt(id, 10), option.name);
            }
            return this.fetchRecordById(id).then((record) => {
                return this.sendChange(record);
            });
        },
        select: function (option) {
            this.set('userHasSelected', true);
            $('input', this.element).blur();
            if (!option) {
                option = {};
            }
            if (option === 'show_removed' || option === 'hide_removed') {
                this.setSessionRemoved(option);
                return;
            }
            this.set('search', option.id ? option.text : this.get('prompt'));
            this.set('value', option.id);
            this.set('text', option.id ? option.text : null);
            this.set('showOptions', false);
            this.set('query', null);
            if (this.get('value')) {
                return this.fetchRecordById(this.get('value')).then((record) => {
                    if (
                        record.get != null &&
                        record.get('row_info.status') === 'removed' &&
                        !this.get('useAsFilter')
                    ) {
                        this.get('dialogs').alert(
                            this.get('intl').t('collector.fields.database.last_used_is_removed'),
                        );
                        this.removeFromLastUsed(option.id);
                        this.set('value', null);
                        this.set('text', null);
                        this.set('search', this.get('prompt'));
                        return this.sendChange(null);
                    } else {
                        this.addToLastused(option.id, option.text);
                        return this.sendChange(record);
                    }
                });
            } else {
                return this.sendChange(null);
            }
        },
        toggleRemoved: function () {
            this.set('showOptions', false);
            if (this.get('show_removed')) {
                return this.setSessionRemoved('hide_removed');
            } else {
                this.get('dialogs').alert(this.get('intl').t('general.showing_removed'));
                return this.setSessionRemoved('show_removed');
            }
        },
    },
});

export default CollectorFieldsDropdownFieldComponent;
