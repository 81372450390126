/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/sitesafety-export.coffee.old
// Generated by CoffeeScript 2.6.1
var SitesafetyExportMixin;

import Ember from 'ember';

import pdfExport from './pdf-export';

import collector from '../classes/collector';

SitesafetyExportMixin = Ember.Mixin.create(pdfExport, {
    arrayToTableFormat: function (columns, data) {
        var i, index, j, len, len1, ret, row, row_data, row_index, tmp;
        ret = [];
        for (row_index = i = 0, len = data.length; i < len; row_index = ++i) {
            row = data[row_index];
            tmp = {};
            for (index = j = 0, len1 = row.length; j < len1; index = ++j) {
                row_data = row[index];
                tmp[columns[index]] = row_data;
            }
            ret.push(tmp);
        }
        return ret;
    },
    exportMeasurementPdf: function (fields, model) {
        var c,
            column,
            columns,
            elements,
            extra_cols,
            extra_fields,
            extra_return_fields,
            fieldName,
            formObjects,
            i,
            id,
            j,
            len,
            len1,
            name,
            notice_ids,
            notices,
            promise,
            ref,
            return_fields,
            translated_extra_cols;
        c = this.get('Collector');
        notices = [];
        extra_fields = [];
        elements = [];
        columns = ['name', 'value'];
        return_fields = ['date', 'place', 'user', 'desc', 'noticedesc'];
        formObjects = collector.getFormObjects(this.get('model.constructor.modelName'));
        extra_return_fields = {};
        extra_cols = [];
        translated_extra_cols = [];
        extra_fields = JSON.parse(JSON.stringify(collector.forms['trnotice'].objects));
        for (i = 0, len = return_fields.length; i < len; i++) {
            name = return_fields[i];
            extra_return_fields[name] = extra_fields[name];
            extra_cols.push(name);
            column = Em.Object.create([]);
            column.set('ext', name);
            translated_extra_cols.push(c.getFieldName('trnotice', column));
        }
        for (fieldName in formObjects) {
            if (fieldName.indexOf('_notices') > -1 && model.get(fieldName)) {
                ref = model.get(fieldName);
                for (j = 0, len1 = ref.length; j < len1; j++) {
                    id = ref[j];
                    notices.push(id);
                }
            }
        }
        notice_ids = notices.join();
        promise = new Em.RSVP.Promise((resolve, reject) => {
            if (notice_ids) {
                return resolve(
                    this.get('store').query('trnotice', {
                        id: notice_ids,
                        sideload: true,
                    }),
                );
            } else {
                return resolve([]);
            }
        });
        return promise.then((notice_data) => {
            // first add orginal measurement row, as list-type
            return this.formatCollectorFieldsToPdfArray(fields, model).then((data) => {
                var row_array;
                row_array = [];
                elements.push(this.createPdfTableElement(columns, data, ['', '']));
                // next get notices and add them also..
                notice_data.forEach((notice) => {
                    // ..but leave only some fields
                    return row_array.push(
                        this.formatCollectorFieldsToPdfArray(extra_return_fields, notice, 'row'),
                    );
                });
                return Em.RSVP.Promise.all(row_array).then((data) => {
                    data = this.arrayToTableFormat(extra_cols, data);
                    elements.push(
                        this.createPdfTableElement(extra_cols, data, translated_extra_cols),
                    );
                    return this.createPdfFromElements([elements]).then(function (file) {
                        if (typeof cordova !== 'undefined' && cordova !== null) {
                            return self.get('cordova').openFile(file, 'application/pdf');
                        }
                    });
                });
            });
        });
    },
});

export default SitesafetyExportMixin;
