import Component from '@ember/component';
import { get, set, setProperties, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise } from 'rsvp';
import { A } from '@ember/array';
import config from '../../../../config/environment';

export default Component.extend({
    ajax: service(),
    admintool: service(),
    error: service(),
    session: service(),
    store: service(),
    cdn: service(),
    router: service(),
    collector: service('collector-service'),
    ttapi: service(),

    loading: false,
    show: false,

    errors: A(),

    mandatoryFields: A(['password', 'company_name', 'phone']),

    termsAndConditionsLink: computed(function () {
        if (moment.locale() == 'fi') return this.cdn.url(config.termsAndConditions.fi);
        if (moment.locale() == 'se' || moment.locale() == 'sv')
            return this.cdn.url(config.termsAndConditions.se);
        return this.cdn.url(config.termsAndConditions.en);
    }),

    init() {
        this._super();
        this.show = false;
        this.validation_error = false;
        //prefill inputs with whatever data we already have
        const userPromise = this.store.query('user', {
            id: get(this, 'session.currentUser.id'),
            sideload: true,
        });
        const companyPromise = this.store.findAll('company_info');

        Promise.all([companyPromise, userPromise]).then(this.prefillData.bind(this));
    },

    actions: {
        handlePhoneValidation(isValid) {
            if (isValid) {
                set(this, 'phone_error', false);
            } else {
                set(this, 'phone_error', true);
            }
        },
        saveData() {
            if (get(this, 'phone_error') == true) {
                return;
            }
            set(this, 'saving', true);

            get(this, 'store')
                .findAll('company_info')
                .then(this.saveCompanyInfo.bind(this))
                .catch(this._handleError.bind(this));

            get(this, 'store')
                .query('user', { id: get(this, 'session.currentUser.id'), sideload: true })
                .then(this.saveUserInfo.bind(this))
                .catch(this._handleError.bind(this));

            set(this, 'saving', false);

            // if mini-entry we need to ask for billing-information also
            if (this.collector.testNeeds(['products.mini_entry'])) {
                get(this, 'router').transitionTo({ queryParams: { wizard: 'billing' } });
                this.close();
            }
            // Send some data to pub/sub (service-created-2)
            this.sendToSns();

            //Had to make it like this, so it works in mess-ages too
            this.transitionToOnboarding();
        },
        valueUpdated(field, value) {
            set(this, field, value);
            this.validate(field);
            set(this, 'phone_error', false); // reset phone error
        },
        checkPasswords() {
            if (
                (!get(this, 'password') || get(this, 'password').length < 8) &&
                get(this, 'errors').indexOf('password_length') == -1
            ) {
                get(this, 'errors').pushObject('password_length');
            } else if (
                get(this, 'password') &&
                get(this, 'password').length >= 8 &&
                get(this, 'errors').indexOf('password_length') != -1
            ) {
                get(this, 'errors').removeObject('password_length');
            }
            this.notifyPropertyChange('errors');
        },
        validatePhone() {
            if (get(this, 'phone_error') || get(this, 'phone_error') == undefined) {
                set(this, 'phone_error', true);
                get(this, 'errors').pushObject('phone');
            } else {
                get(this, 'errors').removeObject('phone');
            }

        },
        validateAllAndSave() {
            for (var field of get(this, 'mandatoryFields')) {
                this.validate(field);
            }
            this.send('checkPasswords');
            if (!get(this, 'phone') || get(this, 'phone') == '' || get(this, 'phone') == undefined) {
                return
            }
            this.send('validatePhone');
            if (get(this, 'errors').length == 0) {
                this.send('saveData');
            }
        },
    },

    validate(field) {
        if (get(this, 'mandatoryFields').indexOf(field) != -1) {
            this.toggleError(field);
        }
        this.notifyPropertyChange('errors');
    },
    toggleError(field) {
        if (
            (!get(this, field) || get(this, field) == '') &&
            get(this, 'errors').indexOf(field) == -1
        ) {
            get(this, 'errors').pushObject(field);
        } else if (get(this, field) && get(this, 'errors').indexOf(field) != -1) {
            get(this, 'errors').removeObject(field);
        }
    },

    prefillData(data) {
        const company = data[0];
        const user = data[1];

        if (get(company, 'firstObject.company_name') === 'empty')
            set(company, 'firstObject.company_name', null);
        if (get(user, 'firstObject.phone') === 'empty') set(user, 'firstObject.phone', null);

        set(this, 'companyName', get(company, 'firstObject.company_name'));
        set(this, 'company_name', get(company, 'firstObject.company_name'));

        set(this, 'firstname', get(user, 'firstObject.firstname'));
        set(this, 'lastname', get(user, 'firstObject.lastname'));
        set(this, 'phone', get(user, 'firstObject.phone'));

        set(this, 'show', true);
    },

    saveCompanyInfo(cinfoObj) {
        let cinfo = get(cinfoObj, 'firstObject');
        set(cinfo, 'company_name', get(this, 'company_name'));
        set(cinfo, 'validation', 'off');

        if (get(this, 'Collector').testNeedsOne('employer')) {
            get(this, 'store')
                .findAll('employer')
                .then(this._makeFirstEmployer.bind(this))
                .catch(this._handleError.bind(this));
        }
        cinfo.save().then(this.saveToAt2());
    },
    saveUserInfo(userInfoObj) {
        if (get(this, 'password') != get(this, 'password_again')) {
            set(this, 'password_error', true);
        }
        let userInfo = get(userInfoObj, 'firstObject');
        set(userInfo, 'firstname', get(this, 'firstname'));
        set(userInfo, 'lastname', get(this, 'lastname'));
        set(userInfo, 'phone', get(this, 'phone'));
        set(userInfo, 'password', get(this, 'password'));
        set(userInfo, 'validation', 'off');
        userInfo.save();
    },
    _returnFirstEmployer(data) {
        if (data.firstObject && data.firstObject.company) {
            set(this, 'companyName', data.firstObject.company);
        } else {
            set(this, 'companyName', null);
        }
        set(this, 'loading', false);
    },
    _rejectEmployerData(reason) {
        get(this, 'error').notify(reason);
        set(this, 'loading', false);
    },
    _makeFirstEmployer(emp) {
        let first_emp = get(emp, 'firstObject');
        setProperties(first_emp, {
            name: get(this, 'companyName'),
            validation: 'off',
        });
        if (this.ytj_info) {
            setProperties(first_emp, {
                emp_address: this.ytj_info.company_address,
                emp_zip: this.ytj_info.company_zip,
                emp_city: this.ytj_info.company_city,
            });
        }
        first_emp.save();
    },
    _handleError(error) {
        get(this, 'error').notify(error);
    },
    saveToAt2() {
        new Promise((resolve, reject) => {
            get(this, 'admintool')
                .ajax({
                    url: 'set_own_info',
                    headers: {
                        Authorization:
                            'Bearer ' + get(this, 'session.session.authenticated.access_token'),
                    },
                    data: {
                        name: get(this, 'companyName'),
                    },
                    type: 'POST',
                })
                .then(this._resolveData.bind(this, resolve))
                .catch(this._rejectData.bind(this, reject));
        });
    },
    _resolveData(resolve, data) {
        resolve(data);
    },
    _rejectData(reject, reason) {
        reject(reason);
    },
    sendToSns() {
        const data = {
            company_name: this.company_name,
            firstname: this.firstname,
            lastname: this.lastname,
            phone: this.phone,
            email: this.session.currentUser.email,
            partnerid: this.session.currentUser.partnerid,
        };
        this.ttapi.request('/sendSnsServiceCreated2Data', {
            method: 'post',
            data,
        });
    },
});
