import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{this.class}}>\n    <TagInput\n        @tags={{this.parsedTags}}\n        @addTag={{this.addTag}}\n        @removeTagAtIndex={{this.removeTagAtIndex}}\n        @maxlength={{15}}\n        @onKeyUp={{fn this.addTagIfImeiIsCorrect}}\n        as |tag|>\n        <span class=\"tag-input-imei {{if (not (is-equal (string-length tag) 15)) \"error\"}}\">\n            {{tag}}\n        </span>\n    </TagInput>\n</div>\n", {"contents":"<div class={{this.class}}>\n    <TagInput\n        @tags={{this.parsedTags}}\n        @addTag={{this.addTag}}\n        @removeTagAtIndex={{this.removeTagAtIndex}}\n        @maxlength={{15}}\n        @onKeyUp={{fn this.addTagIfImeiIsCorrect}}\n        as |tag|>\n        <span class=\"tag-input-imei {{if (not (is-equal (string-length tag) 15)) \"error\"}}\">\n            {{tag}}\n        </span>\n    </TagInput>\n</div>\n","moduleName":"tt4/components/addon/fields/timeclean-imei.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/timeclean-imei.hbs"}});
import FieldsTagInputComponent from './tag-input';
import { action } from '@ember/object';
import { A } from '@ember/array';
import MutableArray from '@ember/array/mutable';

export default class FieldsTimecleanImeiComponent extends FieldsTagInputComponent {
    protected class: string = 'ember-movenium-timeclean-imei-field';

    @action public onChange(tags: MutableArray<string>) {
        return this.args.onChange(JSON.stringify(tags));
    }

    get parsedTags() {
        let tags: MutableArray<string> = A([]);
        if (typeof this.args.value === 'string') tags = A(JSON.parse(this.args.value));
        else if (this.args.value) tags = A(this.args.value);
        this.tags = tags;
        return tags;
    }

    @action protected addTagIfImeiIsCorrect(tag: string) {
        const tagAsNumber = parseInt(tag, 10);
        if (!isNaN(tagAsNumber) && isFinite(tagAsNumber) && tag.length === 15) {
            this.addTagToList(tag);
            (document.querySelector('.' + this.class + ' input') as HTMLInputElement).value = '';
        }
    }

    protected addTagToList(tag: string) {
        this.tags.pushObject(tag);
        this.onChange(this.tags as MutableArray<string>);
    }
}
