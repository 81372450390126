// this tests for finnish hetu format 120988-2345
// return boolean valid
export function hetuvalidation(hetu) {
    const id = hetu.trim();

    if (id.length !== 11) return false;

    const birthdate = id.substr(0, 6);
    const month = parseInt(birthdate.substr(2, 2));
    const day = parseInt(birthdate.substr(0, 2));
    const uniquenum = parseInt(id.substr(7, 3));
    const checknum = parseInt(id.substr(10, 1));

    if (month > 12 || day > 31) {
        return false;
    }

    const concatted = String(birthdate) + String(uniquenum);

    const checknumClaculated = parseInt(concatted) % 31;

    if (checknum !== checknumClaculated) {
        return false;
    }

    return true;
}

// this tests for finnish y-tunnus format  1234567-8
// return boolean valid
export function validateBusinessId(businessid) {
    const id = businessid.trim();
    const check = [7, 9, 10, 5, 8, 4, 2];

    if (id.length > 10) {
        return false;
    }
    // regex for NNNNNNN-N
    if (!/(\d+)-(\d)/.test(id)) {
        return false;
    }

    // the last digit is a check
    var nums = RegExp.$1.split('');
    let checksum = 0;
    nums.forEach((num, index) => (checksum += num * check[index]));
    const checknum = 11 - (checksum % 11);
    if (checknum !== parseInt(RegExp.$2)) {
        return false;
    }

    // all checks paased, must be valid
    return true;
}
