/* eslint-disable */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Service.extend({
  store: service(),
  session: service(),
  Collector: service('collector-service'),
  ttapi: service(),
  capi: service(),

  async checkAndSetAutopause() {
    if (!this.get('Collector').testNeedsOne('products.worktimegroup')) {
        if (this.get('Collector').testNeedsOne('products.autopause')) {
            let settings = await this.getSetting(null);
            if (settings) {
                settings.set('value', 0);
                await settings.save();
            }
        } else {
            return;
        }
        return;
    }

    try {
      // Fetch all worktime groups
      let response = await get(this, 'capi').request('user/worktimegroups');

      // Extract the worktimegroups array from the response
      let worktimeGroups = response.worktimegroups;

      // Check if worktimeGroups is an array
      if (!Array.isArray(worktimeGroups)) {
        throw new Error('Invalid worktimeGroups format');
      }

      // Loop through each worktime group
      for (let worktimeGroup of worktimeGroups) {
        let worktimeGroupId = worktimeGroup.id;

        // Check if autopause is on for the worktime group
        const isAutopauseOn = await this.checkIfAutopauseIsOnForWorktimeGroup(worktimeGroupId);

        if (isAutopauseOn) {
          // Fetch the settings for the current worktime group
          let settings = await this.getSetting(worktimeGroupId);

          if (settings) {
            // Set the pause value to 0 if autopause is on
            settings.set('value', 0);
            await settings.save();
          }
        }
      }

      // Handle the default case where worktimegroup is not set
      const isAutopauseOn = await this.checkIfAutopauseIsOnForWorktimeGroup(null);
      if (isAutopauseOn) {
        let settings = await this.getSetting(null);
        if (settings) {
          settings.set('value', 0);
          await settings.save();
        }
      }

    } catch (error) {
      console.error('Error checking autopause status:', error);
    }
  },

  async checkIfAutopauseIsOnForWorktimeGroup(worktimegroupId) {
    const url = worktimegroupId ? `forms?user.worktimegroup=${worktimegroupId}` : 'forms?user';
    const response = await get(this, 'Collector').ajax({ url });
    return response.updater && Array.isArray(response.updater.products) && response.updater.products.includes('autopause');
  },

  async getSetting(worktimegroupId) {
    let condition = worktimegroupId ? `$userProfile->worktimegroup==${worktimegroupId}` : '';
    let settingParams = {
      name: 'default_value',
      value: 0, // Ensure the value is set to 0 by default
      condition: condition
    };


    if (!worktimegroupId) {
      settingParams.condition = '';
    }

    const configs = {
      form: 'worktime',
      field: 'pause',
      setting: settingParams
    };

    const forms = await this.store.findAll('form', { reload: true });
    const form = forms.filterBy('external_type', configs.form).get('firstObject');
    if (form) {
      const field = form.fields.filterBy('external_type', configs.field).get('firstObject');
      if (field) {
        const setting = field.settings.filterBy('name', configs.setting.name).find(item => {
          if (worktimegroupId) {
            const arr = item.condition ? item.condition.split('==') : [];
            return arr.length > 1 && arr[1] == worktimegroupId;
          } else {
            return item.condition === '';
          }
        });

        return setting || this.store.createRecord('form-field-setting', configs.setting);
      }
    }
    return null;
  }
});


