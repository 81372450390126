/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/route.coffee.old
// Generated by CoffeeScript 2.6.1
var Route;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

Route = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    beforeModel: function (transition) {
        if (transition.targetName === 'salary.index') {
            this.transitionToRightPage();
        }
        return this._super(transition);
    },
    transitionToRightPage: function () {
        if (
            this.get('Collector').testNeeds([
                'userlevel=2,3,5',
                'export_salary',
                'export_salary@access',
            ])
        ) {
            return this.transitionTo('salary.export-salary');
        } else {
            return this.transitionTo('salary.salaryperiods');
        }
    },
    actions: {
        willTransition: function (transition) {
            if (transition.targetName === 'salary.index') {
                return this.transitionToRightPage();
            }
        },
    },
});

export default Route;
