import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';

export default class WorkingNowComponent extends Component {
    @service ttapi;
    @service('collector-service') Collector;
    @service evented;
    @service intl;
    @service analytics;

    @tracked data;
    @tracked page;
    @tracked promise;
    @tracked project;

    get styleNamespace() {
        return podNames['dash-board/widgets/working-now'];
    }

    get projectField() {
        let field = this.Collector.field(this.form, 'project');
        if (field) {
            field.value = this.project;
            field.prompt = 'general.all_projects';
        }
        return field;
    }

    get usersField() {
        let field = this.Collector.field(this.form, 'user');
        if (field) {
            field.value = this.user;
            field.prompt = 'general.all_users';
        }
        return field;
    }

    get employersField() {
        let field = this.Collector.field(this.form, 'employer');
        if (field) {
            field.value = this.employer;
            field.prompt = 'general.all_employers';
        }
        return field;
    }

    get pageCount() {
        if (!this.data) return null;
        return Math.ceil(this.data.count / this.limit);
    }

    get translated_headers() {
        return this.headers.map((header) =>
            this.intl.t('widget.working-now.table-header.' + header),
        );
    }

    get form() {
        return this.Collector.testNeedsOne('worktime') ? 'worktime' : 'presence';
    }

    async setData() {
        this.data = await this.ttapi.request('api/workingNow', {
            data: {
                project: this.project,
                employer: this.employer,
                user: this.user,
                offset: this.page,
            },
        });
    }

    constructor() {
        super(...arguments);
        this.promise = this.setData();
        this.setHeaders();
        this.limit = 25;
        this.page = 1;
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
        this.evented.on('dashboardProjectChanged', this, 'dashboardProjectChanged');
    }

    @action
    filterchanged(value, field) {
        if (field) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Change ${field} filter`,
                label: 'Working-now widget',
            });
            if (!value) set(this, field, null);
            else set(this, field, value.id);
        }
        this.page = 1;
        this.promise = this.setData();
    }

    @action
    pageChange(page) {
        this.page = page;
        this.promise = this.setData();
    }

    willDestroy() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
        this.evented.off('dashboardProjectChanged', this, 'dashboardProjectChanged');
    }

    onStoreEvent(params) {
        if (params.type === 'worktime' || params.type === 'presence') this.promise = this.setData();
    }

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        this.promise = this.setData();
    }

    dashboardProjectChanged() {
        this.project = this.args.project ? this.args.project : null;
        this.promise = this.setData();
    }

    setHeaders() {
        this.headers = ['user', 'project', 'starttime'];
        if (this.Collector.testNeedsOne('employer')) this.headers.insertAt(1, 'employer');
    }
    // if headers contain "starttime" and customer doesn't have worktime module, change "starttime" to "in" and return modified headers but don't change original headers
    get pdf_headers() {
        if (this.headers.includes('starttime') && !this.Collector.testNeedsOne('worktime')) {
            let headers = this.headers.slice();
            headers[headers.indexOf('starttime')] = 'in';
            return headers;
        }
        return this.headers;
    }
    // if data contains "starttime" and customer doesn't have worktime module, change "starttime" to "in" and return modified data but don't change original data
    get pdf_data() {
        // check that:
        // 1. data has rows
        // 2. data has rows[0]
        // 3. rows[0] has starttime
        // 4. customer doesn't have worktime module
        if (
            this.data?.rows &&
            this.data.rows[0] &&
            this.data.rows[0].starttime &&
            !this.Collector.testNeedsOne('worktime')
        ) {
            let data = this.data.rows.slice();
            data.forEach((row) => {
                row.in = row.starttime;
                delete row.starttime;
            });
            return data;
        }
        return this.data ? this.data.rows : null;
    }
}
