import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import AjaxService from 'ember-ajax/services/ajax';
import config from 'tt4/config/environment';

// const {inject: {service}, get, Object, ObjectProxy, PromiseProxyMixin, isPresent, A} = Ember;

export default AjaxService.extend({
    session: service(),
    flags: service(),

    host: computed(function () {
        if (config.environment !== 'development' && get(this, 'flags').test('cc-url'))
            return get(this, 'flags').test('cc-url') + '/trigger';
        return config.APP.CC_URL;
    }),

    headers: computed('session.session.authenticated.access_token', {
        get() {
            let headers = {};
            let token = get(this, 'session.session.authenticated.access_token');
            headers['Authorization'] = 'Bearer ' + token;
            return headers;
        },
    }),
});
