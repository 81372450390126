import EmberObject, { computed, set } from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';
import GeneralReportController from '../../../controllers/general-report';
import { typeOf } from '@ember/utils';

const MachinesMachineController = GeneralReportController.extend({
    form: 'tooltype',
    limit: 25,

    init() {
        this._super(...arguments);
        this.showColumns = ['type', 'name'];
        this.queryParams = ['q'];
    },

    content: alias('model'),
    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            editRoute: {
                modal: true,
            },
        }),
        massedit: true,
        sortByColumn: 'type',
    }),

    filters: computed(function () {
        return A([EmberObject.create({ type: 'searchbox', bindto: 'q' })]);
    }),

    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) === 'instance') {
                return set(this, field, value.id);
            } else {
                return set(this, field, value);
            }
        },
    },
});

export default MachinesMachineController;
