/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport/add/add-taxreport-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportAddAddTaxreportCompComponent;

import Ember from 'ember';

import recordComp from '../../../../collector/record-comp/component';

TaxmanTaxreportAddAddTaxreportCompComponent = recordComp.extend({
    layoutName: 'collector/record-comp',
    Collector: Em.inject.service('collector-service'),
    cc: Em.inject.service(),
    dialogs: Em.inject.service(),
    intl: Em.inject.service(),
    didReceiveAttrs: function () {
        var values;
        values = [];
        this.set('record', this.get('store').createRecord(this.get('form').dasherize(), values));
        this.set('record.export_month', this.get('month'));
        this.set('record.etype', 'P');
        return this.set('record_id', 'new');
    },
    save_record: function () {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var c, date, filename, month, promise, record, url;
            record = this.get('record');
            month = record.get('export_month');
            date =
                moment(month).startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment(month).endOf('month').format('YYYY-MM-DD');
            c = this.get('Collector');
            filename = record.get('export_name').replace(/[^A-Za-z0-9]/g, '') || 'verottaja';
            url =
                this.get('key') +
                '?date=' +
                date +
                '&format=verottaja&type=' +
                record.get('etype') +
                '&code=' +
                record.get('etype_code');
            if (record.get('maincontractor')) {
                url += '&maincontractor=' + record.get('maincontractor.id');
            }
            promise = c.ccapi({
                url: url,
            });
            return promise.then((back) => {
                if (back === '') {
                    alert(this.get('intl').t('taxman.no_data'));
                    reject();
                    return;
                }
                /*
        a = document.createElement 'a'
        a.href = 'data:attachment/csv;,' + back
        a.target = '_blank'
        a.download = filename + '.txt'
        document.body.appendChild(a)
        a.click()
        */
                record.set('export_period_file', [
                    {
                        name: filename + '.txt',
                        file: 'data:text/plain;base64,' + btoa(back),
                    },
                ]);
                return record.save().then((back) => {
                    var a, file_url, files;
                    if (c.testNeeds(['products.autotaxman'])) {
                        return this.sendTaxmanFile(record, resolve, reject);
                    }
                    files = back.get('export_period_file');
                    file_url = files[0].url;
                    a = document.createElement('a');
                    a.href = this.get('Collector').getApiUrl() + file_url;
                    a.target = '_blank';
                    a.download = filename + '.txt';
                    document.body.appendChild(a);
                    a.click();
                    return resolve();
                });
            });
        });
    },
    sendTaxmanFile: function (record, resolve, reject) {
        var promise;
        promise = this.get('cc').request('taxmansend?id=' + record.get('id'));
        return promise.then(
            (result) => {
                if (result.response.errors.length > 0) {
                    record.set('status', 'error');
                    record.set('statusfile', [
                        {
                            name: 'response.html',
                            file: 'data:text/plain;base64,' + btoa(result.html),
                        },
                    ]);
                    return record.save().then(() => {
                        this.downloadFile(
                            'response.html',
                            record.get('statusfile.firstObject.s3url'),
                        );
                        this.get('dialogs').alert(this.get('intl').t('taxman.send_failed'));
                        return reject();
                    });
                } else {
                    record.set('status', 'approved');
                    if (result.response.deliveryId) {
                        record.set('etype_code', result.response.deliveryId);
                    }
                    record.save();
                    this.get('dialogs').alert(this.get('intl').t('taxman.send_success'));
                    return resolve();
                }
            },
            (e) => {
                return reject(e);
            },
        );
    },
    downloadFile: function (filename, url) {
        var a;
        a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = filename + '.txt';
        document.body.appendChild(a);
        return a.click();
    },
});

export default TaxmanTaxreportAddAddTaxreportCompComponent;
