import { get } from '@ember/object';
import GeneralReportRoute from '../../../routes/general-report';

export default GeneralReportRoute.extend({
    queryParams: {
        q: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
    },

    model(params) {
        params = this._super(params);
        return get(this, 'store').query('task', params);
    },
});
