import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    analytics: service(),
    currentPage: 1,

    actions: {
        pageChange(page) {
            get(this, 'analytics').trackEvent({ category: 'Wizard', action: 'pageChange' });

            set(this, 'currentPage', page);
            if (get(this, 'pageChange')) get(this, 'pageChange')(page);
        },
    },
});
