/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/mvr/data/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMvrDataRoute;

import Ember from 'ember';

import GeneralReportRoute from 'tt4/routes/general-report';

import { inject as service } from '@ember/service';

SiteSafetyMvrDataRoute = GeneralReportRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    capi: service(),
    flags: service(),
    beforeModel: function (transition) {
        var c;
        this._super(transition);
        c = this.get('Collector');
        if (transition.targetName !== 'site_safety.mvr.notices' && !c.testNeedsOne('mvr@add')) {
            transition.abort();
            return this.transitionTo('site_safety.mvr.notices');
        }
    },
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            params.sideload = true;
            //        params.order = "date-"
            if (Ember.isPresent(params.groupBy)) {
                // This can be moved to mixin when wanted in other places also
                params.order = params.groupBy;
                return this.get('capi').findAll('mvr', params);
            } else {
                return this.store.query('mvr', params);
            }
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('site-safety.report.mvr.data').set('qParams', params);
        }
    },
});

export default SiteSafetyMvrDataRoute;
