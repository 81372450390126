/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/sign-in/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UserLinkSignInRoute;

import Ember from 'ember';

import collector from '../../../../classes/collector';

UserLinkSignInRoute = Ember.Route.extend({
    session: Em.inject.service(),
    intl: Em.inject.service(),
    beforeModel: function (transition) {
        if (
            this.get('session.isAuthenticated') &&
            localStorage.getItem('ember_simple_auth-session')
        ) {
            return this.replaceWith('register.user.confirm');
        }
    },
    setupController: function (controller, transition) {
        this._super(controller, transition);
        controller.set('hideRegister', true);
        controller.set('mobile', false);
        return controller.set('mobile_devices', false);
    },
    actions: {
        authenticate: function () {
            var controller, data, ls, promise, self;
            self = this;
            ls = this.get('localStorageWrapper');
            controller = this.get('controller');
            data = controller.getProperties('identification', 'password');
            if (!data.identification || !data.password) {
                this.set(
                    'controller.errorMessage',
                    this.get('intl').t('login.give_username_and_password'),
                );
                return;
            }
            this.set('controller.loadingMessage', 'Logging in...');
            this.set('controller.errorMessage', null);
            promise = this.get('session').authenticate(
                'authenticator:collector-auth',
                data.identification,
                data.password,
            );
            promise.then(() => {
                self.set('controller.loadingMessage', null);
                return location.reload();
            });
            return promise.catch(function (resp) {
                var error;
                self.set('controller.loadingMessage', null);
                error = resp ? resp.error : 'failed';
                return self.set('controller.errorMessage', self.get('intl').t('login.' + error));
            });
        },
    },
});

export default UserLinkSignInRoute;
