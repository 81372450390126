/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/datetime-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDatetimeFieldComponent;

import Ember from 'ember';

import baseField from '../text-field/component';

import moment from 'moment';

CollectorFieldsDatetimeFieldComponent = baseField.extend({
    datevalue: Em.computed('value', function () {
        if (!this.get('value')) {
            return '';
        }
        return this.get('value').substr(0, 10);
    }),
    timevalue: Em.computed('value', function () {
        if (!this.get('value')) {
            return '';
        }
        return this.get('value').substr(11, 5);
    }),
    actions: {
        changeTime: function (event) {
            var time, tmpValue;
            time = event.target.value;
            tmpValue = this.get('value') ? this.get('value') : moment().format('YYYY-MM-DD');
            return this.set('value', tmpValue.substr(0, 10) + ' ' + time);
        },
        changeDate: function (event) {
            var date, tmpValue;
            date = event.target.value;
            tmpValue = this.get('value') ? this.get('value') : moment().format('YYYY-MM-DD HH:mm');
            return this.set('value', date + ' ' + tmpValue.substr(11, 5));
        },
    },
});

export default CollectorFieldsDatetimeFieldComponent;
