/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/summary-page/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteSummaryController;

import Ember from 'ember';

WorksiteSummaryController = Ember.Controller.extend({
    session: Ember.inject.service(),
    addController: Ember.inject.controller('worksitediary.report.add'),
    work_force: Ember.computed('model.work_force', function () {
        if (this.get('model.work_force')) {
            return this.get('model.work_force').split(',');
        }
    }),
    user: Ember.computed(function () {
        var tmp_user, user;
        tmp_user = this.get('addController.model.user');
        if (tmp_user) {
            user = this.store.peekRecord('user', tmp_user.id);
            return user.get('firstname') + ' ' + user.get('lastname');
        } else {
            return '';
        }
    }),
});

export default WorksiteSummaryController;
