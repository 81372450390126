/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/saved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var savedRoute;

import Ember from 'ember';

import OrientationsSubRoute from '../sub/route';

savedRoute = OrientationsSubRoute.extend();

export default savedRoute;
