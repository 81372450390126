import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from '@ember/object';

export default Route.extend({
    ttapi2: service(),
    session: service(),

    queryParams: {
        date: {
            refreshModel: true,
        },
    },

    model(params) {
        return get(this, 'ttapi2')._get(
            '/getUserActivity?userid=' +
                get(this.modelFor('users.edit'), 'id') +
                '&date=' +
                moment(params.date).format('YYYY-MM-DD'),
        );
    },
});
