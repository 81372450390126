/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/databasearray-adder-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDatabasearrayAdderFieldComponent;

import Ember from 'ember';

//import recordComp from '../../record-comp/component'
CollectorFieldsDatabasearrayAdderFieldComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    /*
  THESE THREE VALUES MUST BE CODED BETTER IF THIS FIELD IS USED SOMEWHERE ELSE THAN WORKTIME.MATERIAL
  */
    form: Ember.computed.alias('field.options.form'),
    parentform: Ember.computed.alias('field.form'),
    fieldname: Ember.computed.alias('field.name'),
    //records: Ember.computed.alias 'model.material'
    //helperArray: Ember.A []
    initAliases: Ember.on('init', function () {
        return Ember.defineProperty(
            this,
            'records',
            Ember.computed.alias('model.' + this.get('fieldname')),
        );
    }),
    didInsertElement: function () {
        var ids;
        this.set('helperArray', Ember.A([]));
        ids = [];
        return this.get('records').then((records) => {
            var promise;
            records.forEach((record) => {
                if (record.get('id')) {
                    return ids.push(record.get('id'));
                }
            });
            promise = new Em.RSVP.Promise((resolve, reject) => {
                if (ids.length > 0) {
                    return resolve(
                        this.get('store').query(this.get('form'), {
                            id: ids.join(','),
                            include: true,
                        }),
                    );
                } else {
                    return resolve([]);
                }
            });
            return promise.then(() => {
                return records.forEach((record) => {
                    var show_fields;
                    // set correct values for every field
                    show_fields = this.showFields().map(function (field) {
                        var key;
                        key = field.name + (field.type === 'database' ? '.id' : '');
                        field.value = record.get(key);
                        return field;
                    });
                    return this.get('helperArray').pushObject({
                        record: record,
                        showFields: show_fields,
                    });
                });
            });
        });
    },
    //willDestroyElement: ->
    //    console.log "destroy"
    //    @get('records.lastObject').deleteRecord()
    showFields: function () {
        var c, fields, form, parentfields;
        c = this.get('Collector');
        // Collector form
        form = this.get('form');
        parentfields = c.fieldArray(this.get('parentform'));
        // Fetch form fields with collector service
        fields = c.fieldArray(form);
        return fields.filter(function (field) {
            if (field.features != null && field.features.hide) {
                return false;
            }
            return !parentfields.findBy('name', field.name);
        });
    },
    createAddRow: function () {
        var parentfields, record;
        record = this.get('store').createRecord(this.get('form'));
        //record.send('becomeClean')
        parentfields = this.get('Collector').fieldArray(this.get('parentform'));
        parentfields.forEach((field) => {
            return record.set(field.name, this.get('model.' + field.name));
        });
        this.get('records').pushObject(record);
        return this.get('helperArray').pushObject({
            record: record,
            showFields: this.showFields(),
        });
    },
    autoSetHours: function () {
        var allAreTheSame, endtime, lastOneMinutes, minutes, split, sumBefore;
        if (this.get('field.features.validate_check') !== 'multipleHours') {
            return;
        }
        minutes = 0;
        endtime = this.get('model.endtime') ? this.get('model.endtime') : moment().format('HH:mm');
        if (
            !this.get('model.starttime') &&
            !this.get('model.endtime') &&
            this.get('model.work_hours')
        ) {
            split = this.get('model.work_hours').split(':');
            minutes = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
        } else {
            minutes = moment(this.get('model.date') + ' ' + endtime).diff(
                moment(this.get('model.date') + ' ' + this.get('model.starttime')),
                'minutes',
            );
        }
        if (this.get('model.pause')) {
            minutes -= this.get('model.pause');
        }
        if (!minutes) {
            return;
        }
        if (minutes < 0) {
            minutes += 1440;
        }
        sumBefore = 0;
        lastOneMinutes = null;
        allAreTheSame = true;
        this.get('records').forEach((row) => {
            var addMinutes;
            if (typeof row.get('hours') === 'string') {
                split = row.get('hours').split(':');
                addMinutes = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
            } else if (typeof row.get('hours') === 'number') {
                addMinutes = row.get('hours') * 60;
            } else {
                return;
            }
            if (lastOneMinutes && lastOneMinutes !== addMinutes) {
                allAreTheSame = false;
            }
            lastOneMinutes = addMinutes;
            return (sumBefore += addMinutes);
        });
        // if all hours is divided to rows and all rows are the same we change hours of every row
        if (sumBefore === minutes && allAreTheSame) {
            return this.get('records').forEach((record) => {
                return record.set('hours', minutes / this.get('records.length') / 60);
            });
            // if rows have been edited and there is still minutes left add all of those minutes to new row
        } else if (minutes > sumBefore) {
            return this.get('records.lastObject').set('hours', (minutes - sumBefore) / 60);
        }
    },
    actions: {
        change: function (record) {
            return this.sendAction(
                'bubbleChange',
                this.get('model.' + this.get('field.name')),
                this.get('field'),
                false,
            );
        },
        //    if @get('records').indexOf(record) == @get('records.length') - 1
        //        @createAddRow()
        addRow: function () {
            this.createAddRow();
            this.autoSetHours();
            return false;
        },
        remove: function (helperRow) {
            var record;
            //console.log "before", @get('records.length')
            record = Ember.get(helperRow, 'record');
            this.get('records').removeObject(record);
            this.get('helperArray').removeObject(helperRow);
            if (record.get('isNew')) {
                return record.deleteRecord();
            } else {
                record.destroyRecord();
                return this.sendAction(
                    'bubbleChange',
                    this.get('model.' + this.get('field.name')),
                    this.get('field'),
                    false,
                );
            }
        },
    },
});

//console.log "after", @get('records.length')
//if @get('records.length') < 1
//    @createAddRow()
export default CollectorFieldsDatabasearrayAdderFieldComponent;
