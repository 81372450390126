import Route from '@ember/routing/route';

const MachinesMapRoute = Route.extend({
    queryParams: {
        tooltype: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
    },

    model(params) {
        params.endtime = '(empty)';
        params.groupBy = 'project';
        params.sideload = ['project.name', 'project.location_map'];

        return this.store.query('transaction', params);
    },
});

export default MachinesMapRoute;
