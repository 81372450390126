/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/worktime-list/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerWorktimeListController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import collector from '../../../classes/collector';

TimetrackerWorktimeListController = GeneralReportController.extend({
    session: Ember.inject.service(),
    form: 'worktime',
    localStorageKey: 'worktime-list',
    showColumns: [
        'date',
        'status',
        'user',
        'employer',
        'project',
        'starttime',
        'endtime',
        'work_hours',
        'pause',
        'description',
    ],
    limit: 25,
    Collector: Em.inject.service('collector-service'),
    filters: Ember.computed(function () {
        var c, filter_fields;
        c = this.get('Collector');
        filter_fields = Em.A([
            Em.Object.create(
                {
                    field: 'date',
                    type: 'date',
                },
                {
                    wagePeriods: true,
                },
            ),
            Em.Object.create({
                field: 'project',
                value: this.get('project'),
            }),
            Em.Object.create({
                field: 'employer',
            }),
            Em.Object.create({
                field: 'user',
            }),
            Em.Object.create({
                field: 'groupBy',
                type: 'groupByFilter',
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
        ]);
        // hardcoded for delete for now..
        // should make nicer
        if (c.testNeedsOne('user.costcenter')) {
            filter_fields.pushObject(
                Em.Object.create({
                    extra: true,
                    type: 'search',
                    field: 'costcenter',
                    bindto: 'costcenter',
                    sideload: 'user',
                    form: 'user',
                }),
            );
        }
        return filter_fields;
    }),
    filterReport: Ember.computed('filters.@each.value', function () {
        var filters;
        filters = {};
        this.get('filters').forEach((item) => {
            return (filters[item.name] = item.value);
        });
        return filters;
    }),
    getAdditionalFilters: Ember.computed(function () {
        if (this.session.currentUser.userlevel !== '1') {
            return 'user.worktimegroup';
        }
        return null;
    }),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: 'timetracker.edit',
            historyRoute: 'timetracker.history',
        }),
        massedit: true,
        inlineEdit: true,
        sortByColumn: 'date-,starttime-',
    }),
    init_functions: function () {
        this._super();
        return this.hideColumns();
    },
    hideColumns: function () {
        var fields;
        fields = collector.getFormObjects('worktime');
        if (
            fields.starttime.features.hide &&
            fields.endtime.features.hide &&
            fields.work_hours.features.mandatory
        ) {
            return this.set('settings.hideColumns', ['starttime', 'endtime']);
        }
    },
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        closeEditModal: function () {
            this.editRecord.rollbackAttributes();
            return this.set('editRecord', null);
        },
    },
    queryParams: [
        'project',
        'date',
        'user',
        'status',
        'employer',
        'customer',
        'task',
        'group',
        'typeofwork',
        'project_owner',
        'groupBy',
        'q',
    ],
    //    order: 'date-,starttime-'
    customer: null,
    project: null,
    group: null,
    typeofwork: null,
    project_owner: null,
    user: [],
    employer: null,
    status: 'all',
    dateSelector: true,
    userSelector: true,
    statusSelector: true,
    task: null,
    groupBy: null,
    date:
        moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD') +
        '_' +
        moment().format('YYYY-MM-DD'),
});

export default TimetrackerWorktimeListController;
