/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-direktiv/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryNotesDirektivController;

import Ember from 'ember';

WorksiteDiaryNotesDirektivController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    type: 'direktiv',
    records: Ember.computed('model.notes.[]', function () {
        return this.get('model.notes').filterBy('type', this.get('type'));
    }),
    fields: Ember.computed(function () {
        var c;
        c = this.get('Collector');
        return [
            c.field('diaryreport_note', 'title'),
            c.field('diaryreport_note', 'desc'),
            c.field('diaryreport_note', 'responsible'),
        ];
    }),
    options: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return [
            intl.t('diaryreport.notes.options.bestallare'),
            intl.t('diaryreport.notes.options.kontrollant'),
            intl.t('diaryreport.notes.options.byggnadsnamnd'),
            intl.t('diaryreport.notes.options.miljo'),
        ];
    }),
    actions: {
        pushToModel: function (form, record) {
            return this.get('model.notes').pushObject(record);
        },
        removeFromModel: function (record) {
            return this.get('model.notes').removeObject(record);
        },
    },
});

export default WorksiteDiaryNotesDirektivController;
