import Route from '@ember/routing/route';
import { hash } from 'rsvp';

const DevelopmentOwnrolesRoleRoute = Route.extend({
    model(params) {
        return hash({
            formSettings: this.store.peekAll('form-setting'),
            formFieldSettings: this.store.peekAll('form-field-setting'),
            role: params.id,
        });
    },
});

export default DevelopmentOwnrolesRoleRoute;
