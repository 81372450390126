import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import initRoute from 'tt4/mixins/init-route';
import { isEmpty, isPresent } from '@ember/utils';
import { set } from '@ember/object';

const MachinesPricesRoute = Route.extend(initRoute, {
    collector: service('collector-service'),
    queryParams: {
        project: {
            refreshModel: true,
        },
        tooltype: {
            refreshModel: true,
        },
        starttime: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        more: {
            refreshModel: true,
        },
    },

    more: null,
    model(params) {
        // only fetch if project is chosen
        let split;
        if (params.project == null || params.project === '') {
            return;
        }
        for (let param in params) {
            const v = params[param];
            if (param.includes('-')) {
                if (isPresent(v)) {
                    split = param.split('-');
                    if (isEmpty(params[split[0]])) {
                        params[split[0]] = { [split[1]]: v };
                    }
                }
                delete params[param];
            }

            if (v === 'null' || v === null || v === 'undefined') {
                delete params[param];
            }
        }

        if (params.more != null) {
            params.more.split(',').forEach(function (param) {
                const splitted = param.split(':');
                if (splitted[0].includes('-')) {
                    if (isPresent(splitted[1])) {
                        split = splitted[0].split('-');
                        if (isEmpty(params[split[0]])) {
                            return (params[split[0]] = { [split[1]]: splitted[1] });
                        }
                    }
                } else {
                    return (params[splitted[0]] = splitted[1]);
                }
            });
            set(this, 'more', params.more);
        } else {
            set(this, 'more', null);
        }

        delete params.more; // just to make sure this doesn't bleed into capi

        delete params.page;
        params.limit = 'all';
        return this.collector.ttapi({ url: 'machines/prices', data: params });
    },
});

export default MachinesPricesRoute;
