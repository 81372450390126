import Controller from '@ember/controller';
import { get, set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    queryParams: ['user', 'date'],

    user: null,
    date: null,

    init() {
        this._super(...arguments);
        if (!get(this, 'date')) {
            set(
                this,
                'date',
                moment().startOf('isoWeek').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('isoWeek').format('YYYY-MM-DD'),
            );
        }
    },

    dummy: alias('model'),
});
