/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/route.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserRoute;

import Ember from 'ember';

import initmixin from 'tt4/mixins/init-route';

RegisterUserRoute = Ember.Route.extend(initmixin, {
    model: function (params) {
        if (params.project === void 0) {
            return this.replaceWith('register.user.code');
        } else {
            this.replaceWith('register.user.sign-in');
            return Ember.Object.create(params);
        }
    },
});

//    resetController: (controller, isExiting, transition) ->
//        if isExiting
//            controller.set('applicationController.hide_topbar', false)
//            controller.set('applicationController.hide_sidebar', false)
export default RegisterUserRoute;
