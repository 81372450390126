import Service from '@ember/service';
import Evented from '@ember/object/evented';
import { IRecord } from 'tt4/ember-movenium/interfaces/record';

/**
 *
 * Trigger an event in some component
 *
 * ```js
 *@service evented;
 *
 *this.evented.storeEvent('insert', 'worktime', this.worktimeRecord)
 *```
 * You can listen to these events in another component. Always remember to unsubscribe from the events with .off()
 * ```js
 *
 *@service event;
 *
 *constructor() {
 *   this.evented.on('storeEvent', this, 'onStoreEvent')
 *}
 *
 *function onStoreEvent(params) {
 *   if (params.type === 'worktime') // do something else
 *},
 *
 *willDestroy(){
 *   this.evented.off('storeEvent', this, 'onStoreEvent')
 *}
 * ```
 *
 * @class EventService
 * @constructor
 * @public
 */
export default Service.extend(Evented, {
    /**
     * A DS.Store event
     * @method storeEvent
     * @param {String} action the type of action, ie 'remove', 'insert', 'update'
     * @param {String} type type of event that happened ie 'worktime', 'absence', 'myEvent'
     * @param {IRecord} record the record that triggered the event
     * @return {void}
     */
    storeEvent(action: string, type: string, record: IRecord): void {
        this.trigger('storeEvent', { action, type, record });
    },

    /**
     * Can be called for example when the application's onResume() is triggered to trigger resumes elsewhere in the application
     * @method resumed
     * @return {void}
     */
    resumed(): void {
        this.trigger('onResumed');
    },
});
