import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    collector: service('collector-service'),

    classNameBindings: ['fieldNameClass'],

    fieldNameClass: computed('field', function () {
        return 'field-' + get(this, 'field.name');
    }),

    fieldForFieldContainer: computed('field', function () {
        const field = get(this, 'field');
        let value = get(this, 'record.' + get(field, 'name'));
        if (value && typeof value === 'object' && get(value, 'id')) {
            this.setFieldValue(get(value, 'id'));
        } else {
            this.setFieldValue(value);
        }
        return field;
    }),

    visible: computed('only', 'field._hide', function () {
        if (get(this, 'field._hide')) return false;
        if (!get(this, 'only')) return true;
        return get(this, 'only').split(',').includes(get(this, 'field.name'));
    }),

    setFieldValue(value) {
        set(this, 'field.value', value);
    },
});
