/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsIndexRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

ProjectsIndexRoute = GeneralReportRoute.extend({
    flags: Ember.inject.service(),
    form: 'project',
    queryParams: {
        page: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
    },
    model: function (params) {
        if (
            !this.get('flags').test('form-report-projects') &&
            !this.get('flags').test('ember-movenium-form-report-projects')
        ) {
            params = this._super(params);
            params.sideload = true;
            return this.store.query('project', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('projects.index').set('qParams', params);
        }
    },
});

export default ProjectsIndexRoute;
