/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldo/saldo-deducter/component.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoSaldoDeducterComponent;

import { set, get, computed } from '@ember/object';

import recordComp from '../../../../collector/record-comp/component';

UsersEditSaldoSaldoDeducterComponent = recordComp.extend({
    layoutName: 'collector/record-comp',
    form: 'saldodeducter',
    showFields: computed('fields', 'record', function () {
        if (this._super()) {
            return this._super().filter(function (item) {
                var ref;
                return !((ref = get(item, 'name')) === 'addhours');
            });
        }
    }),
    actions: {
        save: function () {
            var record;
            record = get(this, 'record');
            set(record, 'addhours', this.get('adding'));
            return this._super();
        },
    },
});

export default UsersEditSaldoSaldoDeducterComponent;
