/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/id06/route.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserRoute;

import Ember from 'ember';

RegisterUserRoute = Ember.Route.extend({
    model: function () {
        return this.modelFor('register.user');
    },
});

export default RegisterUserRoute;
