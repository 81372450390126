/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/nfc-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var NfcCompComponent;

import Ember from 'ember';

import collector from '../../../classes/collector';

NfcCompComponent = Ember.Component.extend({
    Collector: Em.inject.service('collector-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    schema_map_to_type: [],
    schema_map_to_exttype: [],
    nfcIsOn: Ember.computed(function () {
        var product;
        product = Em.A(['products.rfid']);
        return this.get('Collector').testNeeds(product);
    }),
    projects: Ember.computed(function () {
        return this.get('store').query('project', {
            rfid_device_id: '!(empty)',
        });
    }),
    didReceiveAttrs: function () {
        var self, types;
        if (!this.get('nfcIsOn')) {
            return;
        }
        self = this;
        this._super();
        types = [];
        return this.get('store')
            .findAll('form')
            .then((raw_forms) => {
                var hide_these_types,
                    raw_fields,
                    raw_form,
                    schema_map_to_exttype,
                    schema_map_to_type,
                    skip_externals,
                    tmp_types;
                raw_form = raw_forms.filterBy('external_type', 'worktime');
                raw_fields = raw_form.get('firstObject.fields');
                this.set('raw_form', raw_form.get('firstObject'));
                tmp_types = Em.A([]);
                // these are not supported by nfc at all
                skip_externals = [
                    'date',
                    'status',
                    'group',
                    'user',
                    'starttime',
                    'endtime',
                    'work_hours',
                    'customer',
                    'employer',
                ];
                // these are not yet supported by collector/collector-field -component
                hide_these_types = ['checkbox'];
                schema_map_to_type = this.get('schema_map_to_type');
                schema_map_to_exttype = this.get('schema_map_to_exttype');
                raw_fields.forEach(function (field) {
                    var name;
                    if (
                        skip_externals.indexOf(field.get('external_type')) < 0 &&
                        hide_these_types.indexOf(field.get('type')) < 0
                    ) {
                        schema_map_to_type[field.get('schemaid')] = field.get('type');
                        schema_map_to_exttype[field.get('schemaid')] = field.get('external_type');
                        if (field.get('name').indexOf('lang') > 0) {
                            name = self
                                .get('intl')
                                .t('worktime' + '.' + field.get('external_type'));
                        } else {
                            name = field.get('name');
                        }
                        return tmp_types.pushObject(
                            Em.Object.create({
                                id: field.get('schemaid'),
                                name: name,
                                type: field.get('type'),
                            }),
                        );
                    }
                });
                return this.set('types', tmp_types);
            });
    },
    buttonTypeChanged: function (value, button) {
        var buttonRow, field, i, len, object, raw_form_fields;
        // first get row
        i = 0;
        buttonRow = null;
        len = this.get('buttonRows').length;
        while (i < len) {
            if (this.get('buttonRows')[i]['name'] === button) {
                buttonRow = this.get('buttonRows')[i];
                break;
            }
            i++;
        }
        Ember.set(buttonRow, 'value', null);
        if (value === 'null') {
            Ember.set(buttonRow, 'field', null);
            return;
        }
        object = null;
        raw_form_fields = this.get('raw_form.fields');
        raw_form_fields.forEach(function (raw_field) {
            if (value === raw_field.get('schemaid')) {
                return (object = raw_field);
            }
        });
        Ember.set(buttonRow, 'schemaid', object.get('schemaid'));
        field = {
            form: 'worktime',
            name: this.get('schema_map_to_exttype')[object.get('schemaid')],
            type: this.get('schema_map_to_type')[object.get('schemaid')],
            options:
                collector.forms.worktime.objects[
                    this.get('schema_map_to_exttype')[object.get('schemaid')]
                ].options,
        };
        Ember.set(buttonRow, 'field', field);
        Ember.set(buttonRow, 'type', object.get('type'));
        Ember.set(buttonRow, 'ext_type', object.get('external_type'));
        return this.updateModel();
    },
    buttonValueChanged: function (value, button) {
        var buttonRow, i, len;
        if (Ember.typeOf(value) === 'instance') {
            value = value.get('id');
        }
        buttonRow = null;
        len = this.get('buttonRows').length;
        i = 0;
        while (i < len) {
            if (this.get('buttonRows')[i]['name'] === button) {
                buttonRow = this.get('buttonRows')[i];
                break;
            }
            i++;
        }
        Ember.set(buttonRow, 'value', value);
        return this.updateModel();
    },
    updateModel: function () {
        var row_setting, tmp;
        tmp = {};
        tmp['tce_formid'] = this.get('raw_form.id');
        this.get('buttonRows').forEach(function (row) {
            if (row['value']) {
                tmp[row['name'] + '_select'] = row['schemaid'];
                return (tmp[row['name'] + '_' + row['schemaid']] = row['value']);
            }
        });
        if (this.get('settings')) {
            row_setting = this.get('settings');
            row_setting.send('becomeDirty');
            return row_setting.set('value', JSON.stringify(tmp));
        }
    },
    actions: {
        save: function () {
            var row_setting, tmp;
            tmp = {};
            tmp['tce_formid'] = this.get('raw_form.id');
            this.get('buttonRows').forEach(function (row) {
                if (row['value']) {
                    tmp[row['name'] + '_select'] = row['schemaid'];
                    return (tmp[row['name'] + '_' + row['schemaid']] = row['value']);
                }
            });
            if (this.get('settings')) {
                row_setting = this.get('settings');
                row_setting.set('value', JSON.stringify(tmp));
                return row_setting.save();
            }
        },
        actionBubblerA: function (value) {
            return this.buttonTypeChanged(value, 'A');
        },
        actionBubblerB: function (value) {
            return this.buttonTypeChanged(value, 'B');
        },
        actionBubblerC: function (value) {
            return this.buttonTypeChanged(value, 'C');
        },
        actionBubblerD: function (value) {
            return this.buttonTypeChanged(value, 'D');
        },
        actionBubblerE: function (value) {
            return this.buttonTypeChanged(value, 'E');
        },
        actionBubblerF: function (value) {
            return this.buttonTypeChanged(value, 'F');
        },
        changeButtonValueA: function (value) {
            return this.buttonValueChanged(value, 'A');
        },
        changeButtonValueB: function (value) {
            return this.buttonValueChanged(value, 'B');
        },
        changeButtonValueC: function (value) {
            return this.buttonValueChanged(value, 'C');
        },
        changeButtonValueD: function (value) {
            return this.buttonValueChanged(value, 'D');
        },
        changeButtonValueE: function (value) {
            return this.buttonValueChanged(value, 'E');
        },
        changeButtonValueF: function (value) {
            return this.buttonValueChanged(value, 'F');
        },
        projectSelected: function (projectid) {
            var buttons;
            // every time project is changed, init buttons so that old values are removed
            buttons = [
                {
                    name: 'A',
                    type: null,
                    value: null,
                    schemaid: null,
                },
                {
                    name: 'B',
                    type: null,
                    value: null,
                    schemaid: null,
                },
                {
                    name: 'C',
                    type: null,
                    value: null,
                    schemaid: null,
                },
                {
                    name: 'D',
                    type: null,
                    value: null,
                    schemaid: null,
                },
                {
                    name: 'E',
                    type: null,
                    value: null,
                    schemaid: null,
                },
                {
                    name: 'F',
                    type: null,
                    value: null,
                    schemaid: null,
                },
            ];
            return this.get('store')
                .query('row-setting', {
                    toid: projectid,
                })
                .then((data) => {
                    var buttonRow,
                        field,
                        key,
                        letter,
                        schema_map_to_exttype,
                        schema_map_to_type,
                        value,
                        values;
                    this.set('buttonRows', buttons);
                    if (data.get('firstObject')) {
                        schema_map_to_type = this.get('schema_map_to_type');
                        schema_map_to_exttype = this.get('schema_map_to_exttype');
                        values = JSON.parse(data.get('firstObject.value'));
                        for (key in values) {
                            value = values[key];
                            // get schemaid
                            if (key.indexOf('_select') > 0) {
                                letter = key.substr(0, 1);
                                buttonRow = buttons.findBy('name', letter);
                                Ember.set(buttonRow, 'schemaid', value);
                                Ember.set(buttonRow, 'type', schema_map_to_type[value]);
                                field = {
                                    form: 'worktime',
                                    name: schema_map_to_exttype[value],
                                    type: schema_map_to_type[value],
                                    options:
                                        collector.forms.worktime.objects[
                                            schema_map_to_exttype[value]
                                        ].options,
                                };
                                Ember.set(buttonRow, 'field', field);
                            }
                            // get value
                            if (parseInt(key.substr(2))) {
                                letter = key.substr(0, 1);
                                buttonRow = buttons.findBy('name', letter);
                                Ember.set(buttonRow, 'value', value);
                                field = buttonRow['field'];
                                field['value'] = value;
                                Ember.set(buttonRow, 'field', field);
                            }
                        }
                        return this.set('settings', data.get('firstObject'));
                    } else {
                        return this.set(
                            'settings',
                            this.get('store').createRecord('row-setting', {
                                name: 'timeclean_button',
                                toid: this.get('project_id'),
                                value: null,
                            }),
                        );
                    }
                });
        },
    },
});

export default NfcCompComponent;
