import Component from '@ember/component';
import moment from 'moment';
import { computed, get } from '@ember/object';

export default Component.extend({
    dates: computed('range', function () {
        function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
            if (start.isAfter(end)) throw new Error('start must precede end');
            if (!key) throw new Error('key must be given');
            const next = moment(start).add(1, key).startOf(key);

            if (next.isAfter(end, key)) return arr;

            return getRangeOfDates(next, end, key, arr.concat(next));
        }
        if (!get(this, 'range')) return [];
        const [start, end] = get(this, 'range').split('_');
        return getRangeOfDates(moment(start), moment(end), 'day');
    }),

    fixedCellWidths: computed('range', function () {
        return get(this, 'dates.length') * 35 > this.element.offsetWidth;
    }),
});
