/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/basic-info/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddBasicInfoRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

WorksitediaryReportAddBasicInfoRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    model: function () {
        return this.modelFor('worksitediary.report.add');
    },
    afterModel: function (model, transition) {
        var firstController;
        firstController = this.controllerFor('worksitediary.report.first');
        if (firstController.project) {
            model.set('project', firstController.project);
        }
        if (firstController.diary_date) {
            return model.set('diary_date', firstController.diary_date);
        }
    },
    actions: {
        didTransition: function () {
            var basicController;
            basicController = this.controllerFor('worksitediary.report.add.basic-info');
            return basicController.changeThings();
        },
    },
});

export default WorksitediaryReportAddBasicInfoRoute;
