/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/project-files/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsViewProjectFileController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

ProjectsViewProjectFileController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    filters: Em.A([
        Em.Object.create({
            field: 'ori_site',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    ori_site: null,
    projectFilesObserver: function () {
        return this.set('page', 1);
    }.observes('projectfile'),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
            hideHistory: true,
        }),
    }),
    queryParams: [
        'page',
        'user',
        {
            ori_site: {
                as: 'project',
            },
        },
        'row_info_status',
        'q',
    ],
    row_info_status: null,
    filter: false,
    search: false,
    form: 'projectfile',
    showColumns: ['description', 'file'],
    cancelRoute: 'projects.projectfiles',
    show_link: false,
    link: Ember.computed(function () {
        return {
            link: ['projects.edit.add-file'],
            translated: this.get('intl').t('menu.add_project_file'),
        };
    }),
    actions: {
        tmp: function () {
            return console.log('joo');
        },
    },
});

export default ProjectsViewProjectFileController;
