/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/collector-value-format.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorValueFormatHelper;

import Ember from 'ember';

CollectorValueFormatHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    compute: function (params, hash) {
        var c,
            field,
            form,
            isImageModal,
            options,
            realField,
            realFieldForm,
            record,
            ref,
            ret,
            value;
        c = this.get('Collector');
        field = hash.field;
        form = hash.form;
        value = hash.value;
        record = hash.record ? hash.record : null;
        isImageModal = hash.isImageModal;
        if (field === 'row_info.created') {
            return this.get('Collector')._format_(value, form, field);
        }
        if (field === 'row_info.modified') {
            return this.get('Collector')._format_(value, form, field);
        }

        realField = c.field(form, field);

        if (field === 'location' || field === 'end_location') {
            return c.format(value, realField, null, record, false, form);
        }

        if (
            (ref = realField.type) === 'database' ||
            ref === 'timerlink' ||
            ref === 'multiselect' ||
            ref === 'databasearray'
        ) {
            if (realField.type === 'databasearray') {
                options = Ember.get(realField, 'options');
                form = Ember.get(options, 'form');
                field = Ember.get(options, 'fields.firstObject');
                realFieldForm = Ember.get(c.field(form, field), 'options.form');
            } else {
                realFieldForm = realField.options.form;
            }
            if (!isNaN(value) && value) {
                value = parseInt(value, 10);
            }
            if (!value) {
                return '';
            }
            ret = this.formatValue(value, form, field);
            if (ret !== void 0 && ret !== null && Ember.typeOf(ret) !== 'instance') {
                if (typeof ret === 'number') {
                    return ret;
                }
                return ret.trim();
            } else {
                if (Ember.typeOf(value) === 'instance') {
                    value = value.get('id');
                }
                if (value) {
                    this.get('store')
                        .query(realFieldForm, {
                            id: value,
                            include: true,
                        })
                        .then((record) => {
                            if (!Em.isEmpty(record)) {
                                return this.recompute();
                            }
                        });
                    return '';
                }
                return value;
            }
        } else {
            return c._format_(value, form, field, 'html', record, isImageModal);
        }
    },
    formatValue: function (value, form, field) {
        var empty_found, value_arr;
        if (Ember.typeOf(value) === 'string' && value.indexOf(',') !== -1) {
            value_arr = [];
            empty_found = false;
            value.split(',').forEach((item) => {
                var subvalue;
                // for some weird reason _format_ needs id as int
                subvalue = this.get('Collector')._format_(parseInt(item, 10), form, field);
                if (!subvalue) {
                    empty_found = true;
                }
                return value_arr.push(subvalue);
            });
            if (empty_found) {
                return null;
            }
            return value_arr.join(',');
        } else {
            return this.get('Collector')._format_(value, form, field);
        }
    },
});

export default CollectorValueFormatHelper;
