import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),

    getRecord: computed(function () {
        if (!get(this, 'editRow')) {
            return get(this, 'store').createRecord('wage_period', {});
        } else {
            return get(this, 'editRow');
        }
    }),

    actions: {
        close() {
            this.close();
        },
        saved() {
            this.saved();
        },
    },
});
