import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    Collector: service('collector-service'),
    intl: service(),
    tagName: 'table',
    sums: computed('data', 'salarytypes.[]', async function () {
        let types = [{ name: 'hours', fieldType: 'work_hours' }].concat(
            await get(this, 'salarytypes'),
        );

        const sums = [];
        for (const type of types) {
            if (type.name === 'comp') continue;
            if (!type.fieldType) continue;
            let typeValue = get(this, 'data')
                ? get(this, 'data').reduce(
                      (total, row) => total + (row[type.name] ? Number(row[type.name]) : 0),
                      0,
                  )
                : 0;

            if (type.fieldType) {
                typeValue = get(this, 'Collector')._format_(
                    typeValue,
                    'worktime',
                    type.fieldType,
                    'salarycategory',
                );
            }
            let name = type.translatedName ? type.translatedName : type.name;
            sums.push({ name, value: typeValue });
        }

        return sums;
    }),
});
