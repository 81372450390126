/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/integrationcontroller/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var IntegrationcontrollerController;

import Ember from 'ember';

IntegrationcontrollerController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    worktime_fields: Ember.computed(function () {
        var fields;
        fields = this.get('Collector').fieldArray('worktime');
        return fields.filter(function (field) {
            var ref;
            return (ref = field.type) === 'hours' || ref === 'number' || ref === 'currency';
        });
    }),
    control_values: Ember.computed(function () {
        var dropdowns, fields, values;
        fields = this.get('Collector').fieldArray('worktime');
        dropdowns = fields.filter(function (field) {
            var ref;
            return (ref = field.type) === 'dropdown_values';
        });
        values = [
            {
                name: this.get('intl').t('general.choose'),
                value: '',
            },
        ];
        dropdowns.forEach((field) => {
            return field.options.forEach((option) => {
                return values.push({
                    name:
                        field.translated_name.string +
                        ' is ' +
                        this.get('intl').t('worktime.' + field.name + '.' + option.value),
                    value: field.name + '=' + option.value,
                });
            });
        });
        return values;
    }),
    actions: {
        save: function () {
            return this.get('model').forEach((row) => {
                if (row.get('hasDirtyAttributes')) {
                    return row.save();
                }
            });
        },
        remove: function (row) {
            return row.destroyRecord();
        },
        addrow: function () {
            return this.store.createRecord('integrationcontroller');
        },
        change: function (value) {
            return console.log('change', value);
        },
    },
});

export default IntegrationcontrollerController;
