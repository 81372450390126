import Component from '@ember/component';
import { get, set } from '@ember/object';

export default Component.extend({
    currentPage: 1,

    actions: {
        pageChange(page) {
            set(this, 'currentPage', page);
            if (get(this, 'pageChange')) get(this, 'pageChange')(page);
        },
    },
});
