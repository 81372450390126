/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/my-sites/route.coffee.old
// Generated by CoffeeScript 2.6.1
var MySitesRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

MySitesRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    session: Ember.inject.service(),
    mySites: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    localStorageWrapper: Ember.inject.service(),
    localStorageId: 'movenium_tt_users_my_sites',
    model: function (params) {
        var promise, self;
        self = this;
        //get sites from localstorage and update them

        //do not fetch mysites from ttapi/LS when in testing mode
        if (Ember.testing) {
            return Em.A([]);
        }
        promise = this.getMySites();
        return promise.then((all_sites) => {
            var i,
                len,
                not_own_sites,
                own_sites,
                par,
                partnerid,
                projects_from_api,
                site,
                updated_own_projects;
            partnerid = this.get('session.currentUser.partnerid');
            //get only sites from localstorage where partnerid is same as currentuser partnerid
            own_sites = Em.A([]);
            not_own_sites = Em.A([]);
            for (i = 0, len = all_sites.length; i < len; i++) {
                site = all_sites[i];
                if (site.partnerid === partnerid) {
                    own_sites.push(site);
                } else {
                    not_own_sites.push(site);
                }
            }
            // filter broken sites
            own_sites = own_sites.filter(function (item) {
                return Ember.get(item, 'id');
            });
            //update own sites and return them and other ones
            if (own_sites.length > 0) {
                //get own projects
                par = {};
                par.id = own_sites.mapBy('id').join(',');
                if (this.get('Collector').testNeeds(['project.maincontractor'])) {
                    par.sideload = 'maincontractor.name';
                }
                updated_own_projects = Em.A([]);
                projects_from_api = this.store.query('project', par);
                return projects_from_api.then(function (projs) {
                    var sites;
                    //get each project from api
                    projs.forEach(function (proj) {
                        var proj_obj;
                        proj_obj = self.get('mySites').getMySiteObject(proj);
                        // ugly hack to get correct used timestamp from original mysite row
                        if (own_sites.findBy('id', proj.get('id'))) {
                            Ember.set(
                                proj_obj,
                                'used',
                                moment(
                                    Ember.get(own_sites.findBy('id', proj.get('id')), 'used'),
                                ).format('YYYY-MM-DD HH:mm:ss'),
                            );
                        }
                        return updated_own_projects.pushObject(proj_obj);
                    });
                    //combine updated own projects and other mysites from localstorage
                    sites = Em.A([]);
                    sites.pushObjects(not_own_sites);
                    sites.pushObjects(updated_own_projects);
                    return sites;
                });
            } else {
                return all_sites;
            }
        });
    },
    getMySites: function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var ret, sites;
            ret = Em.A(this.get('localStorageWrapper').getItemWithBackend('mySites'));
            if (!sites) {
                //sites = ret.local # do not fetch localstorage saved projects
                sites = [];
            }
            return ret.backend.then(
                (more_sites) => {
                    var found, i, len, site;
                    for (i = 0, len = more_sites.length; i < len; i++) {
                        site = more_sites[i];
                        if (!site) {
                            continue;
                        }
                        found = sites.find(function (item) {
                            return item.id === site.id;
                        });
                        if (!found) {
                            site.partnerid = parseInt(site.partnerid, 10);
                            sites.push(site);
                        }
                    }
                    return resolve(sites);
                },
                (e) => {
                    return resolve([]);
                },
            );
        });
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.addOwnedProjectsToMySites();
    },
});

/*
self = @
params.id = @get('mySitesProjects').mapBy('id').join ','
params.sideload = true

if params.id == ""
    return Em.A []

unsorted_mySitesProjects = @.store.query('project', params)

sortedMySitesProjects = Em.A []

projectIds = @get('mySitesProjects')

unsorted_mySitesProjects.then (projects) =>
    projectIds.forEach (localStoredId) =>
        projects.forEach (project) ->
            projectId = project.get('id')
            if (localStoredId == projectId)
                sortedMySitesProjects.pushObject(project)

    @setNumberOfWorkersAtProjects(sortedMySitesProjects)

return sortedMySitesProjects
*/
export default MySitesRoute;
