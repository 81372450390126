/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/id06/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddId06Controller;

import Ember from 'ember';

import config from '../../../../config/environment';

UsersAddId06Controller = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    countryLists: Ember.inject.service(),
    intl: Ember.inject.service(),
    call_cc: function () {
        var model;
        model = this.get('model');
        return this.get('Collector').ccapi({
            url: config.APP.ID06_VERIFY_URL,
            timeout: 10000,
            data: {
                card: model.get('id06kort'),
                personid: model.get('personid'),
                nationality: model.get('homecountry').toUpperCase(),
                emp_id: model.get('employer.emp_id'),
                emp_nationality: model.get('employer.country').toUpperCase(),
            },
        });
    },
    search_user_with_id06card: function () {
        var model;
        model = this.get('model');
        return this.get('Collector').ttapi({
            url: 'searchid06',
            dataType: 'text',
            data: {
                card: model.get('id06kort'),
                personid: model.get('personid'),
                nationality: model.get('homecountry').toUpperCase(),
            },
        });
    },
    create_user_with_id06card: function () {
        var model;
        model = this.get('model');
        return this.get('Collector').ttapi({
            url: 'createid06linkeduser',
            method: 'post',
            data: {
                card: model.get('id06kort'),
                personid: model.get('personid'),
                nationality: model.get('homecountry').toUpperCase(),
                employerid: model.get('employer.id'),
            },
        });
    },
    //    setModel: (data, skip) ->
    //        model = @get 'model'
    //        for k,v of data
    //            unless k in skip
    //                model.set k, v

    //    addEmployerIfNotExist: (name, id) ->
    //        new Ember.RSVP.Promise (resolve, reject) =>
    //            store = @get 'store'
    //            store.query('employer', {emp_id: id}).then (employers) =>
    //                console.log "employers", employers
    //                if employers.get('length') > 0
    //                    resolve employers.get 'firstObject'
    //                else
    //                    record = store.createRecord 'employer',
    //                        name: name
    //                        emp_id: id
    //                    record.save().then =>
    //                        resolve record
    id06kortErrors: Em.computed('model.errors.[]', function () {
        return this.get('model.errors').errorsFor('id06kort');
    }),
    personidErrors: Em.computed('model.errors.[]', function () {
        return this.get('model.errors').errorsFor('personid');
    }),
    homecountryErrors: Em.computed('model.errors.[]', function () {
        return this.get('model.errors').errorsFor('homecountry');
    }),
    employerErrors: Em.computed('model.errors.[]', function () {
        return this.get('model.errors').errorsFor('employer');
    }),
    // TODO stuff like default values should be set in models
    // moved to routes afterModel
    //    setCountry: Em.observer('model', ->
    //        c = @get('Collector')
    //        if @get('model.homecountry') == undefined
    //            @set 'model.homecountry', c.field('user', 'homecountry').features.default_value
    //    )

    //    setEmployer: Em.observer('model','session.currentUser', ->
    //        if not @get('model') then return
    //        if not @get('session.currentUser.employer') then return
    //        @set 'model.employer', @get("session.currentUser.employer")
    //    )
    actions: {
        transitionToUserform: function (param) {
            this.set('linkUser', false);
            if (!param) {
                this.set('model.id06kort', void 0);
                this.set('model.personid', void 0);
                return this.transitionToRoute('users.add.index').then((newRoute) => {
                    return newRoute.controller.set('no_id06', true);
                });
            } else if (param === 'linkUser') {
                this.set('loading', true);
                return this.create_user_with_id06card().then((back) => {
                    this.set('loading', false);
                    return this.transitionToRoute('users.edit', back.user.user.id);
                });
            } else if (param === 'not_verified') {
                this.set('model.status', 'not_verified');
                return this.transitionToRoute('users.add.index');
            } else {
                return this.send('fetchID06Card');
            }
        },
        fetchID06Card: function () {
            this.set('loading', true);
            this.set('error', false);
            this.set('emp_link', false);
            this.set('error500', false);
            return this.get('model')
                .validate(['id06kort', 'personid', 'homecountry', 'employer'])
                .then(() => {
                    if (Ember.isEmpty(this.get('model.id06kort'))) {
                        this.get('model.errors').add(
                            'id06kort',
                            this.get('intl').t('validation.error.mandatory'),
                        );
                    }
                    if (this.get('model.isValid')) {
                        return this.search_user_with_id06card().then((response) => {
                            if (response !== 'notfound') {
                                this.set('linkUser', true);
                                this.set(
                                    'linkUserTitle',
                                    this.get('intl').t('user.add.id06_link_user', {
                                        lastname: response.lastname,
                                        firstname: response.firstname,
                                    }),
                                );
                                this.set('loading', false);
                                return;
                            }
                            return this.store
                                .findRecord('employer', this.get('model.employer.id'), {
                                    reload: true,
                                })
                                .then(() => {
                                    if (this.get('model.employer.country') === null) {
                                        // Häx because all employers might not have the field filled
                                        this.set(
                                            'error',
                                            this.get('intl').t('user.add.id06.emp_no_nationality'),
                                        );
                                        this.set('emp_link', true);
                                        return this.set('loading', false);
                                    } else if (this.get('model.employer.emp_id') === null) {
                                        // Häx because all employers might not have the field filled
                                        this.set(
                                            'error',
                                            this.get('intl').t('user.add.id06.emp_no_id'),
                                        );
                                        this.set('emp_link', true);
                                        return this.set('loading', false);
                                    } else {
                                        return this.call_cc().then(
                                            (response) => {
                                                if (typeof response === 'string') {
                                                    response = JSON.parse(response);
                                                }
                                                this.set('loading', false);
                                                if (response.error) {
                                                    return this.set(
                                                        'error',
                                                        this.get('intl').t('user.add.id06.cc_fail'),
                                                    );
                                                } else if (response.reason !== 'verified') {
                                                    this.set(
                                                        'error',
                                                        this.get('intl').t(
                                                            'user.add.id06.card_status.' +
                                                                response.reason,
                                                        ),
                                                    );
                                                    if ((response.code = 500)) {
                                                        return this.set('error500', true);
                                                    }
                                                } else {
                                                    return this.transitionToRoute(
                                                        'users.add.index',
                                                    );
                                                }
                                            },
                                            (error) => {
                                                this.set('loading', false);
                                                this.set(
                                                    'error',
                                                    this.get('intl').t('user.add.id06.cc_fail'),
                                                );
                                                if (error.statusText === 'timeout') {
                                                    return this.set('error500', true);
                                                }
                                            },
                                        );
                                    }
                                });
                        });
                    } else {
                        return this.set('loading', false);
                    }
                });
        },
        valueChanged: function (value, field) {
            if (field) {
                if (value && value !== 'null') {
                    this.set('model.' + field, value);
                    return this.get('model').validate([field]);
                } else {
                    if (value === 'null') {
                        this.set('model.' + field, null);
                    }
                    return this.get('model').validate([field]);
                }
            } else {
                this.set('model.homecountry', value.id);
                return this.get('model').validate(['homecountry']);
            }
        },
        validateField: function (field) {
            return this.get('model').validate([field]);
        },
    },
});

export default UsersAddId06Controller;
