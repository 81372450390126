import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Route.extend({
    gui: service(),

    queryParams: {
        code: {
            refreshModel: true,
        },
    },

    model(params) {
        set(this, 'gui.saveVismanetCode', params.code);
        this.transitionTo('salary.export-salary', { queryParams: { wizard: 'vismanet-wizard' } });
        return { code: params.code };
    },
});
