/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalController;

import Ember from 'ember';

TimetrackerApprovalController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    queryParams: ['date', 'employer'],
    userSelector2: true,
    employer: null,
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'employer',
                type: 'database',
                form: 'worktime',
                name: 'employer',
                singleselect: 'true',
            }),
            Em.Object.create({
                field: 'date',
                type: 'date',
                form: 'worktime',
                name: 'date',
                value: this.get('date'),
                wagePeriods: true,
            }),
        ]);
    }),
    dateRange: Ember.computed('intl.primaryLocale', function () {
        return Em.A([
            Em.Object.create({
                id:
                    moment().startOf('week').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('week').format('YYYY-MM-DD'),
                name:
                    this.get('intl').t('timetracker.approval.this_week') +
                    ': ' +
                    moment().startOf('week').format('L') +
                    '-' +
                    moment().endOf('week').format('L'),
            }),
            Em.Object.create({
                id:
                    moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD') +
                    '_' +
                    moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
                name:
                    this.get('intl').t('timetracker.approval.last_week') +
                    ': ' +
                    moment().subtract(1, 'week').startOf('week').format('L') +
                    '-' +
                    moment().subtract(1, 'week').endOf('week').format('L'),
            }),
            Em.Object.create({
                id:
                    moment().startOf('week').subtract(2, 'week').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('week').subtract(1, 'week').format('YYYY-MM-DD'),
                name:
                    this.get('intl').t('timetracker.approval.last_two_weeks') +
                    ': ' +
                    moment().startOf('week').subtract(2, 'week').format('L') +
                    '-' +
                    moment().endOf('week').subtract(1, 'week').format('L'),
            }),
            Em.Object.create({
                id:
                    moment().subtract(1, 'months').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
                name:
                    this.get('intl').t('timetracker.approval.last_month') +
                    ': ' +
                    moment().subtract(1, 'months').format('L') +
                    '-' +
                    moment().format('L'),
            }),
        ]);
    }),
    actions: {
        filterChanged: function (value, fieldName) {
            if (fieldName === 'employer') {
                if (value == null) {
                    return this.set('employer', '');
                } else {
                    return this.set('employer', value.id);
                }
            } else {
                return this.set(fieldName, value);
            }
        },
    },
});

export default TimetrackerApprovalController;
