/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/api-report.coffee.old
// Generated by CoffeeScript 2.6.1
var ApiReportComponent;

import Ember from 'ember';

import pdfExport from '../mixins/pdf-export';

import csvExport from '../mixins/csv-export';

import $ from 'jquery';

ApiReportComponent = Ember.Component.extend(pdfExport, csvExport, {
    Collector: Ember.inject.service('collector-service'),
    admintool: Ember.inject.service('admintool'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    translationPrefix: null,
    path: '',
    rowsPerPage: 30,
    initData: Ember.on(
        'init',
        Ember.observer('query', 'currentPage', 'url', function () {
            var c, promise;
            c = this.get('Collector');
            if (this.get('host') && this.get('host') === 'ADMINTOOLURL') {
                promise = this.get('admintool').get(this.get('url'), this.get('query'));
            } else {
                promise = c.ttapi({
                    url: this.get('url'),
                });
            }
            promise.then((data) => {
                var i, j, key, len, len1, obj, object_data, ref, ret, row;
                this.set('rows', data.count);
                if (!this.get('noPagination')) {
                    this.set('pageCount', Math.ceil(data.count / this.get('rowsPerPage')));
                }
                ret = data.data;
                if (data.count > 0 && data.data[0] instanceof Array) {
                    object_data = [];
                    ref = data.data;
                    for (i = 0, len = ref.length; i < len; i++) {
                        row = ref[i];
                        obj = {};
                        for (key = j = 0, len1 = row.length; j < len1; key = ++j) {
                            data = row[key];
                            obj[key] = data;
                        }
                        object_data.push(obj);
                    }
                    ret = object_data;
                }
                return this.set('data', ret);
            });
            return promise;
        }),
    ),
    query: Ember.computed.alias('filters'),
    url: Ember.computed('query', 'currentPage', 'rowsPerPage', 'path', function () {
        var query, query_str_arr, rowsPerPage, url;
        url = this.get('path');
        query = this.get('query');
        if (query) {
            query_str_arr = [];
            $.each(query, function (key, item) {
                if (!item.value) {
                    return;
                }
                return query_str_arr.push(item.name + '=' + item.value);
            });
            url += (url.includes('?') ? '&' : '?') + query_str_arr.join('&');
        }
        if (!this.get('noPagination')) {
            rowsPerPage = this.get('rowsPerPage');
            url += '&limit=' + rowsPerPage;
            if (rowsPerPage !== 'all') {
                url += '&offset=' + rowsPerPage * (this.get('currentPage') - 1);
            }
        }
        // if no query-params, replace first & with ? so url is correct
        if (url.indexOf('?') === -1) {
            url = url.replace('&', '?');
        }
        return url;
    }),
    data: Em.A([]),
    columns: Em.computed('data', function () {
        var columns, firstRow;
        if (!this.get('data')) {
            return Em.A([]);
        }
        firstRow = this.get('data.firstObject');
        if (!firstRow) {
            return Em.A([]);
        }
        columns = Em.A([]);
        $.each(firstRow, function (key, item) {
            return columns.pushObject(key);
        });
        return columns;
    }),
    translatedColumns: Em.computed('columns', function () {
        var columns, intl, translated_columns, translationPrefix;
        translationPrefix = this.get('translationPrefix');
        if (translationPrefix) {
            columns = this.get('columns');
            intl = this.get('intl');
            translated_columns = [];
            $.each(columns, (key, item) => {
                return translated_columns.push(intl.t(translationPrefix + '.' + item));
            });
            return translated_columns;
        }
        return this.get('columns');
    }),
    asPdfElement: Ember.computed('columns', 'data', 'translatedColumns', function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            this.set('rowsPerPage', 'all');
            return this.initData().then(() => {
                this.set('rowsPerPage', 30);
                return resolve(
                    this.createPdfTableElement(
                        this.get('columns'),
                        this.get('data'),
                        this.get('translatedColumns'),
                    ),
                );
            });
        });
    }),
    asCsvElement: Ember.computed('columns', 'data', 'translatedColumns', function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            this.set('rowsPerPage', 'all');
            return this.initData().then(() => {
                return resolve(
                    this.createCsvElement(
                        this.get('columns'),
                        this.get('data'),
                        this.get('translatedColumns'),
                    ),
                );
            });
        });
    }),
    /*

  PROPERTIES FOR PAGINATION

  */
    currentPage: 1,
    showPagination: Ember.computed.gt('pageCount', 1),
    pages: Ember.computed('pageCount', function () {
        var ref;
        return Em.A(
            function () {
                var results = [];
                for (
                    var i = 1, ref = this.get('pageCount');
                    1 <= ref ? i <= ref : i >= ref;
                    1 <= ref ? i++ : i--
                ) {
                    results.push(i);
                }
                return results;
            }.apply(this),
        );
    }),
    hasPreviousPage: Ember.computed.gt('currentPage', 1),
    hasNextPage: Ember.computed('currentPage', 'pageCount', function () {
        return this.get('currentPage') < this.get('pageCount');
    }),
    actions: {
        catchActions: function (action, params) {
            if (action === 'checkedRowsAction') {
                return params.action.func.bind(this)(params.rows);
            } else {
                return this.sendAction(action, params);
            }
        },
        nextPage: function () {
            return this.set('currentPage', this.get('currentPage') + 1);
        },
        previousPage: function () {
            return this.set('currentPage', this.get('currentPage') - 1);
        },
        changePage: function (page) {
            return this.set('currentPage', page);
        },
        export_pdf: function () {
            var c, self, store;
            self = this;
            c = this.get('Collector');
            store = this.get('store');
            return this.get('asPdfElement').then((element) => {
                if (self.get('pdfSubHeader')) {
                    return self.get('query').forEach(function (qPart) {
                        if (qPart['name'] === self.get('pdfSubHeader')) {
                            return store
                                .findRecord(qPart['name'], qPart['value'])
                                .then(function (data) {
                                    var addHeader;
                                    addHeader =
                                        c.getTranslationForColumn('presence', qPart['name']) +
                                        ': ' +
                                        data.get('name');
                                    return self
                                        .createPdfFromElements([element], 'download', addHeader, 22)
                                        .then(function (file) {
                                            if (
                                                typeof cordova !== 'undefined' &&
                                                cordova !== null
                                            ) {
                                                return self
                                                    .get('cordova')
                                                    .openFile(file, 'application/pdf');
                                            }
                                        });
                                });
                        }
                    });
                } else {
                    return this.createPdfFromElements([element]).then(function (file) {
                        if (typeof cordova !== 'undefined' && cordova !== null) {
                            return self.get('cordova').openFile(file, 'application/pdf');
                        }
                    });
                }
            });
        },
        export_csv: function () {
            return this.get('asCsvElement').then((element) => {
                return this.createCsvFromElements(element);
            });
        },
    },
});

export default ApiReportComponent;
