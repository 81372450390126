import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 *
 *
 * parameters:
 *      rowActions: what actions to show separated by comma. default: edit,remove
 *
 */
export default Component.extend({
    collector: service('collector-service'),

    showActions: 'edit,history,remove',

    showEdit: computed('showActions', function () {
        if (!get(this, 'collector').testNeeds([get(this, 'form') + '@edit'])) return false;
        if (!get(this, 'showActions')) return false;
        if (!this.row.isEditable) return false;
        return get(this, 'showActions').split(',').includes('edit');
    }),

    showHistory: computed('showActions', function () {
        if (!get(this, 'showActions')) return false;
        return get(this, 'showActions').split(',').includes('history');
    }),

    showRemove: computed('showActions', function () {
        if (!get(this, 'collector').testNeeds([get(this, 'form') + '@remove'])) return false;
        if (!get(this, 'showActions')) return false;
        if (!this.row.isEditable) return false;
        return get(this, 'showActions').split(',').includes('remove');
    }),

    showCopy: computed('showActions', function () {
        if (
            !this.collector.testNeeds([
                '!' + get(this, 'form') + '@errors',
                get(this, 'form') + '@add',
            ])
        )
            return false;
        return true;
    }),

    showLock: computed('row', function () {
        if (get(this.row, 'row_info.privilege_openlock') === false) return false;
        return get(this.row, 'row_info.status') === 'locked';
    }),

    rowIsRemoved: computed('row', function () {
        return get(this.row, 'row_info.status') === 'removed';
    }),

    showRestore: computed('form', function () {
        return get(this, 'collector').testNeeds([get(this, 'form') + '@remove']);
    }),

    actions: {
        clicked(action) {
            get(this, 'actionClicked')(action, get(this, 'row'));
        },
    },
});
