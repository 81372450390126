/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-ritningar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryReportAddNotesRitningarController;

import Ember from 'ember';

import direktivController from '../notes-direktiv/controller';

WorksiteDiaryReportAddNotesRitningarController = direktivController.extend({
    type: 'ritningar',
    intl: Ember.inject.service(),
    options: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return [
            intl.t('diaryreport.notes.options.arkitekt'),
            intl.t('diaryreport.notes.options.konstruktor'),
            intl.t('diaryreport.notes.options.ror'),
            intl.t('diaryreport.notes.options.ventilation'),
            intl.t('diaryreport.notes.options.el'),
            intl.t('diaryreport.notes.options.nyritning'),
        ];
    }),
});

export default WorksiteDiaryReportAddNotesRitningarController;
