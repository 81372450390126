import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CustomExportComponent extends Component {
    @service ttapi;
    @service('collector-service') Collector;

    /**
     * When salarytypes_v2 used always call ttapi/api/salary/automaticSalarytypes after changing km setting
     */
    @action
    async saved() {
        if (!this.Collector.testNeeds(['products.salarytype_v2'])) return;

        await this.ttapi.post('api/salary/automaticSalarytypes');
    }
}
