/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/register/service/products.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterProductsController;

import Ember from 'ember';

RegisterProductsController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    registerController: Ember.inject.controller('register.service'),
    selectedProducts: Em.A([]),
    obs_products: Ember.observer('products.@each.selected', function () {
        return this.set('selectedProducts', Em.A(this.get('products').filterBy('selected', true)));
    }),
    products: Ember.computed.alias('registerController.products'),
    _products: Ember.computed('products', function () {
        var intl;
        intl = this.get('intl');
        return this.get('products').map(function (item) {
            item.pricetype_text = (function () {
                switch (item.pricetype) {
                    case 'peruser':
                        return intl.t('register.price_type_peruser');
                    case 'perproject':
                        return intl.t('register.price_type_perproject');
                    case 'permonth':
                        return intl.t('register.price_type_permonth');
                    case 'monthlyperproject':
                        return intl.t('register.price_type_monthlyperproject');
                }
            })();
            if (item.image && item.image.substring(0, 5) === 'icon:') {
                item.icon = item.image.substring(5);
            }
            return item;
        });
    }),
    actions: {
        moreInfo: function (link) {
            return window.open(link, '_blank');
        },
        next: function () {
            var registerController;
            registerController = this.get('registerController');
            registerController.set('selectedProducts', this.get('selectedProducts'));
            return this.transitionToRoute('login.register.summary');
        },
        click: function (object) {
            object.toggleProperty('selected');
            return false;
        },
    },
});

export default RegisterProductsController;
