/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import pdfMixin from '../../../mixins/pdf-export';

import { generateQrCode } from 'tt4/utils/qrcode-generator';

ProjectsIndexController = GeneralReportController.extend(pdfMixin, {
    flags: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    exportFile: Ember.inject.service(),
    form: 'project',
    showColumns: [
        'number',
        'name',
        'address',
        'city',
        'zip',
        'proj_type',
        'proj_end',
        'description',
    ],
    queryParams: ['page', 'q', 'group', 'customer', 'owner', 'contact_user'],
    filters: Em.A([
        Em.Object.create({
            field: 'group',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    status: 'active',
    group: null,
    contact_user: null,
    owner: null,
    customer: null,
    settings: Em.Object.create({
        rowActions: [],
        massedit: true,
    }),
    init_functions: function () {
        if (!this.get('flags').test('form-report-projects')) {
            this._super();
            this.addSelfRegisteringHelp();
            this.addSmsHelp();
            return this.addSafetyNoticeUrlCreation();
        } else {
            return null;
        }
    },
    addSmsHelp: function () {
        var _self;
        _self = this;
        if (
            !this.get('Collector').testNeeds([
                'products.personalliggareandid06||products.personalliggare',
            ])
        ) {
            return;
        }
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('general.smshelp'),
            action: function (self, projects) {
                return _self.send('smsHelpFunction', projects);
            },
        });
    },
    addSelfRegisteringHelp: function () {
        var _self;
        _self = this;
        // hidden for now.. waiting new way to do the whole thing
        if (!this.get('Collector').testNeeds(['presence', '!products.kulunvalvonta'])) {
            return;
        }
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('general.usersigninghelp'),
            action: function (self, projects) {
                return _self.send('selfRegisteringHelp', projects);
            },
        });
    },
    addSafetyNoticeUrlCreation: function () {
        var _self;
        _self = this;
        if (
            !this.get('Collector').testNeeds(['products.measurement_tr||products.measurement_mvr'])
        ) {
            return;
        }
        return this.get('settings.rowActions').push({
            text: this.get('intl').t('project.anon_safety_observation'),
            action: (self, projects) => {
                return _self.send('safetyNoticeUrlCreationAction', projects);
            },
        });
    },
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        transitionToEditRoute: function (row) {
            return this.transitionToRoute('projects.edit', row);
        },
        showRemovedRows: function () {
            if (this.get('settings.showStatus') === 'removed') {
                return this.set('settings.showStatus', 'normal');
            } else {
                return this.set('settings.showStatus', 'removed');
            }
        },


        safetyNoticeUrlCreationAction: function (projects) {
            var projArr = [];
            projects.forEach((project) => {
                projArr.push(project.get('id'));
            });
            this.set('gettingUrl', true);
            return this.get('Collector')
                .ttapi({
                    url: 'safety_notice_shorturl',
                    method: 'post',
                    data: {
                        projectids: projArr,
                        lang: moment.locale(),
                    },
                })
                .then((data) => {
                    if (data.urls) {
                        const qrCodeDataUrls = {};
                        for (const [projectId, url] of Object.entries(data.urls)) {
                            qrCodeDataUrls[projectId] = generateQrCode(url); // Use plain URL
                        }
                        return this.get('Collector')
                            .ttapi({
                                url: 'safety_notice_pdf',
                                method: 'post',
                                data: {
                                    originalUrls: data.urls,
                                    qrCodeDataUrls: qrCodeDataUrls,
                                    projectids: projArr,
                                },
                            })
                            .then((response) => {
                                if (response.url) {
                                    window.open(response.url, '_blank');
                                }
                            });
                    }
                });
        },
        selfRegisteringHelp: function (projects) {
            /*
      CREATE USER SIGNING PDF HELP - this is so horrible!!!!!! .. but does the thing
      */
            var _self,
                arr,
                creator,
                currenturl,
                getLongUrl,
                getTinyUrl,
                i,
                intl,
                len,
                project,
                promise,
                promise_arr,
                writtenForm;
            intl = this.get('intl');
            _self = this;
            currenturl = window.location.href.split('#')[0];
            creator = this.get('session.currentUser.username');
            if (!creator) {
                alert('Error occured. Please, log in again.');
                return;
            }
            getLongUrl = function (projectid, creator) {
                return new Em.RSVP.Promise(function (resolve, reject) {
                    var store, url, userid;
                    url = '#/register/user?project=' + projectid + '&creator=' + creator;
                    userid = _self.get('session.currentUser.id');
                    store = _self.get('store');
                    return store
                        .query('user', {
                            id: userid,
                            sideload: ['employer.name', 'employer.emp_id', 'employer.country'],
                        })
                        .then(() => {
                            var employer;
                            employer = _self.get('session.currentUser.employer');
                            if (employer) {
                                if (!employer.get('country')) {
                                    reject({
                                        missing: 'employer_country',
                                    });
                                }
                                url += '&employer_name=' + encodeURIComponent(employer.get('name'));
                                url += '&employer_id=' + encodeURIComponent(employer.get('emp_id'));
                                url +=
                                    '&employer_country=' +
                                    encodeURIComponent(employer.get('country'));
                                return resolve({
                                    longurl: url,
                                    employer: employer.get('name'),
                                });
                            } else {
                                return store
                                    .query('employer', {
                                        main: 'on',
                                    })
                                    .then((employers) => {
                                        employer = employers.get('firstObject');
                                        if (employer == null) {
                                            return alert(
                                                'Main employer is not set. Cannot create help.',
                                            );
                                        } else {
                                            url +=
                                                '&employer_name=' +
                                                encodeURIComponent(employer.get('name'));
                                            url +=
                                                '&employer_id=' +
                                                encodeURIComponent(employer.get('emp_id'));
                                            url +=
                                                '&employer_country=' +
                                                encodeURIComponent(employer.get('country'));
                                            return resolve({
                                                longurl: url,
                                                employer: employer.get('name'),
                                            });
                                        }
                                    });
                            }
                        });
                });
            };
            getTinyUrl = function (projectid, projectname) {
                return new Em.RSVP.Promise(function (resolve, reject) {
                    //resolve "http://goo.gl/Aer43 " + projectid
                    return getLongUrl(projectid, creator).then(
                        (ret) => {
                            var call;
                            call = _self.Collector.ttapi({
                                url: 'code/generate',
                                data: {
                                    longUrl: currenturl + ret.longurl,
                                },
                                method: 'post',
                            });
                            return call.then(function (back) {
                                return resolve({
                                    project: projectname,
                                    url: back.id,
                                    employer: ret.employer,
                                });
                            });
                        },
                        function (error) {
                            return reject(error);
                        },
                    );
                });
            };
            writtenForm = function (url) {
                var arr, ascii, character, characters, i, len, numbers, ret_arr, str;
                arr = url.split('/');
                str = arr[arr.length - 1];
                characters = str.split('');
                numbers = intl.t('general.numbers').split(',');
                ret_arr = [];
                for (i = 0, len = characters.length; i < len; i++) {
                    character = characters[i];
                    ascii = character.charCodeAt(0);
                    ret_arr.push(
                        ascii >= 48 && ascii <= 57
                            ? numbers[ascii - 48]
                            : ascii >= 65 && ascii <= 90
                              ? intl.t('general.big_char') + ' ' + character
                              : ascii >= 97 && ascii <= 122
                                ? intl.t('general.small_char') +
                                  ' ' +
                                  String.fromCharCode(ascii - 32)
                                : void 0,
                    );
                }
                return ret_arr.join(', ');
            };
            promise_arr = [];
            arr = [];
            for (i = 0, len = projects.length; i < len; i++) {
                project = projects[i];
                promise = getTinyUrl(project.get('id'), project.get('name'));
                promise_arr.push(promise);
                promise.then(function (back) {
                    return arr.push(back);
                });
            }
            return Em.RSVP.Promise.all(promise_arr).then(
                function () {
                    var codeBlock, elem, first, j, len1, pdfElements, start;
                    pdfElements = [];
                    first = true;
                    for (j = 0, len1 = arr.length; j < len1; j++) {
                        elem = arr[j];
                        if (elem.url.indexOf('=') < 0) {
                            start = elem.url.lastIndexOf('/') + 1; // when using http://m0v.eu/<code> url
                        } else {
                            start = elem.url.indexOf('=') + 1; // when using kehityspurkki or p.move (http://p.movenium.com/next/ttapi/public/code?code=<code>)
                        }
                        codeBlock = elem.url.substring(start);
                        if (first) {
                            pdfElements.push(elem.project + ' - ' + elem.employer);
                        } else {
                            pdfElements.push({
                                text: elem.project + ' - ' + elem.employer,
                                pageBreak: 'before',
                            });
                        }
                        pdfElements.push(' ');
                        pdfElements.push(intl.t('general.usersigninghelp_before').toString());
                        pdfElements.push({
                            text: elem.url,
                            style: 'bigText',
                            margin: [10, 10, 10, 10],
                        });
                        pdfElements.push('(' + writtenForm(elem.url) + ')');
                        pdfElements.push(' ');
                        pdfElements.push(intl.t('general.usersigninghelp_after').toString());
                        pdfElements.push({
                            text: codeBlock,
                            style: 'bigText',
                            margin: [10, 10, 10, 10],
                        });
                        first = false;
                    }
                    return _self.createPdfFromElements(pdfElements);
                },
                (error) => {
                    if (error.missing) {
                        return self
                            .get('dialogs')
                            .alert(
                                self
                                    .get('intl')
                                    .t('user_invite.pdf_create.missing.' + error.missing),
                            );
                    } else {
                        return self.get('dialogs').alert(self.get('intl').t('general.error'));
                    }
                },
            );
        },
        //########################################################
        smsHelpFunction: function (projects) {
            var _self, first, i, intl, len, pdfElements, project, text;
            intl = this.get('intl');
            pdfElements = [];
            first = true;
            _self = this;
            for (i = 0, len = projects.length; i < len; i++) {
                project = projects[i];
                if (!project.get('number')) {
                    alert(
                        intl.t('general.smshelperror', {
                            projectname: project.get('name'),
                            projectnumber: project.get('number'),
                        }),
                    );
                    continue;
                }
                text = intl.t('general.smshelptext', {
                    projectname: project.get('name'),
                    projectnumber: project.get('number'),
                });
                if (first) {
                    pdfElements.push(text);
                } else {
                    pdfElements.push({
                        text: text,
                        pageBreak: 'before',
                    });
                }
                first = false;
            }
            if (pdfElements.length > 0) {
                return _self.createPdfFromElements(pdfElements);
            }
        },
    },
});

export default ProjectsIndexController;
