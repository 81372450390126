/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/notices/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SkyddsrondReportController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

SkyddsrondReportController = GeneralReportController.extend({
    form: 'notice',
    showColumns: ['fixbydate', 'project', 'title', 'desc', 'status', 'pic', 'user'],
    queryParams: ['fixbydate', 'status', 'user', 'project'],
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
        }),
        sortByColumn: 'fixbydate',
    }),
    filters: Em.A([]),
    status: 'all',
});

export default SkyddsrondReportController;
