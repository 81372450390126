/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/notices/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMeasurementNoticesController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

import { computed } from '@ember/object';

SiteSafetyMeasurementNoticesController = GeneralReportController.extend({
    //    needs: 'site-safety/measurement'
    measurementController: Ember.inject.controller('site-safety/measurement'),
    project: Ember.computed.alias('measurementController.project'),
    date: Ember.computed.alias('measurementController.date'),
    queryParams: ['status', 'project', 'date', 'page', 'employer', 'groupBy'],
    filters: computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'date',
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
            Em.Object.create({
                field: 'groupBy',
                type: 'groupByFilter',
            }),
        ]);
    }),
    arrangedContent: Ember.computed('model.[]', function () {
        return this.get('content').sortBy('date');
    }),
    statusSelector: Ember.computed.alias('measurementController.statusSelector'),
    search: false,
    status: Ember.computed.alias('measurementController.status'),
    form: 'trnotice',
    showColumns: ['date', 'status', 'desc', 'user', 'project', 'pic'],
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
    },
});

export default SiteSafetyMeasurementNoticesController;
