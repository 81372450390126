import EmberObject from '@ember/object';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';
import GeneralReportController from '../../controllers/general-report';

const GroupsReportController = GeneralReportController.extend({
    limit: 25,
    form: 'group',

    init() {
        this._super(...arguments);
        this.showColumns = ['name', 'type'];
        this.queryParams = ['name', 'type', 'q'];
    },

    content: alias('model'),

    filters: A([
        EmberObject.create({ field: 'type' }),
        EmberObject.create({ type: 'searchbox', bindto: 'q' }),
    ]),

    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            editRoute: {
                modal: true,
            },
        }),
        sortByColumn: 'name',
    }),
});

export default GroupsReportController;
