/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceIndexRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

PresenceIndexRoute = GeneralReportRoute.extend({
    form: 'presence',
    session: Em.inject.service(),
    flags: Em.inject.service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-presence')) {
            params.sideload = true;
            params.out = '(empty)';
            if (this.get('session.currentUser.userlevel') === '1') {
                params.user = 'self';
            }
            params.date =
                moment().subtract(7, 'days').format('YYYY-MM-DD') +
                '_' +
                moment().format('YYYY-MM-DD');
            return this.store.query('presence', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('presences.index').set('qParams', params);
        }
    },
});

export default PresenceIndexRoute;
