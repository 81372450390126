import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

export default class DisabledComponent extends Component {
    @service('collector-service') collector;

    constructor() {
        super(...arguments);
        this.errors = [];
        for (let need of this.args.needs) {
            if (!this.collector.testNeeds([need.key])) {
                this.errors.push(need.text);
            }
        }
    }
    get styleNamespace() {
        return podNames['settings/products/disabled'];
    }
}
