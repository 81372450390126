/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/side-menu.coffee.old
// Generated by CoffeeScript 2.6.1
var SideMenuComponent;

import Ember from 'ember';

import config from '../config/environment';

import menu from '../mixins/menu';

SideMenuComponent = Ember.Component.extend(menu, {
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    gui: Ember.inject.service(),
    Collector: Em.inject.service('collector-service'),
    layoutName: 'side-menu',
    editmode: false,
    init: function () {
        this._super(...arguments);
        if (typeof cordova !== 'undefined' && cordova !== null) {
            return cordova.getAppVersion.getVersionNumber().then((version) => {
                return this.set(
                    'version',
                    this.get('intl').t('application.version') +
                        ': ' +
                        this.get('gui').version() +
                        ' APP: ' +
                        version,
                );
            });
        } else {
            return this.set(
                'version',
                this.get('intl').t('application.version') + ': ' + this.get('gui').version(),
            );
        }
    },
    skv_report: Ember.computed('session.currentUser', function () {
        if (!this.get('session.currentUser')) {
            return;
        }
        return (
            config.APP.TTAPI +
            '/personalliggare?partnerid=' +
            this.get('session.currentUser.partnerid')
        );
    }),
    impersonate: Em.computed(function () {
        if (this.get('session.session.content.authenticated.scope')) {
            return true;
        }
    }),
    product_updates: Em.computed('intl.primaryLocale', function () {
        // TODO: Remove in EP-11985
        if (config.brand === 'visma') {
            return this.get('intl').t('general.product_updates_url_entry').toString();
        } else if (config.brand === 'otta') {
            return this.get('intl').t('general.product_updates_url_otta').toString();
        } else {
            return this.get('intl').t('general.product_updates_url').toString();
        }
    }),
    devModeClicks: 0,
    devModeOn: Ember.computed(function () {
        return Ember.devModeOn === true;
    }),
    actions: {
        devmode: function () {
            this.set('devModeClicks', this.get('devModeClicks') + 1);
            if (this.get('devModeClicks') > 6) {
                Em.dev();
                this.set('devModeClicks', 0);
            }
            return this.set('devModeOn', Ember.devModeOn === true);
        },
        close: function (menu) {
            if (this.get('close')) {
                this.close();
            }
            if (menu && this.get('transition')) {
                return this.transition(menu.link);
            }
        },
        editmode: function () {
            return this.toggleProperty('editmode');
        },
        toggleMenuVisibility: function (item) {
            return item.toggleProperty('hide_leftmenu');
        },
        saveMenuState: function () {
            this.saveLeftmenuHidings(this.get('menus'));
            return this.send('close');
        },
        invalidateSession: function () {
            delete localStorage['first-time-loader-greeting'];
            if (this.get('logout')) {
                return this.logout();
            }
        },
        changeLanguage: function (lang) {
            if (this.get('changeLanguage')) {
                return this.changeLanguage(lang);
            }
        },
        openLink: function (url) {
            return window.open(url, '_blank');
        },
        openFeedbackModal: function () {
            this.send('close');
            return this.set('showFeedbackModal', true);
        },
        closeModal: function () {
            return this.set('showFeedbackModal', false);
        },
        sendTestNotification: function () {
            var c;
            c = this.get('Collector');
            return c.ttapi({
                url: 'send_test_notification',
                data: {
                    lang: this.get('intl.primaryLocale'),
                    userid: this.get('session.currentUser.id'),
                },
                method: 'post',
            });
        },
    },
});

export default SideMenuComponent;
