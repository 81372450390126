import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    dummy: alias('model'),

    actions: {
        coderead(code, action) {
            this.transitionToRoute(action.route);
        },
    },
});
