/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/product-post-add.coffee.old
// Generated by CoffeeScript 2.6.1
var ProductPostAddMixin;

import Ember from 'ember';

ProductPostAddMixin = Ember.Mixin.create({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    postAddingMethods: ['adding_personalliggareandid06'],
    // this method adds main-employer if no other main-employers are found
    adding_personalliggareandid06: function () {
        var company;
        company = this.get('session.currentUser.company_info');
        return this.get('store')
            .query('employer', {
                main: 'on',
            })
            .then((emps) => {
                var record;
                if (emps.get('meta.count') === 0) {
                    record = this.get('store').createRecord('employer', {
                        name: company.get('company_name'),
                        country: company.get('country'),
                        emp_id: company.get('company_vatin'),
                        main: true,
                    });
                    return record.save();
                }
            });
    },
    adding_fivaldi_salary_csv: function () {
        return this.get('Collector').ttapi({
            url: 'productAdded/fivaldi_salary_csv',
        });
    },
    adding_nova_salary_csv: function () {
        return this.get('Collector').ttapi({
            url: 'productAdded/nova_salary_csv',
        });
    },
    adding_fivaldi_salary_soap: function () {
        return this.get('Collector').ttapi({
            url: 'productAdded/fivaldi_salary_soap',
        });
    },
    adding_setting_absences: function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            return this.get('store')
                .findAll('abcensetype')
                .then((abcensetypes) => {
                    var promises, records;
                    // no absence-types so lets add some
                    if (abcensetypes.get('length') === 0) {
                        records = [];
                        records.push(
                            this.store.createRecord('abcensetype', {
                                name: this.get('intl').t('abcense.types.sick_leave'),
                            }),
                        );
                        records.push(
                            this.store.createRecord('abcensetype', {
                                name: this.get('intl').t('abcense.types.vacation'),
                            }),
                        );
                        records.push(
                            this.store.createRecord('abcensetype', {
                                name: this.get('intl').t('abcense.types.other'),
                            }),
                        );
                        promises = [];
                        records.forEach(function (record) {
                            return promises.push(record.save());
                        });
                        return Ember.RSVP.Promise.all(promises).then((records) => {
                            return resolve(records);
                        });
                    } else {
                        return resolve(true);
                    }
                });
        });
    },
});

export default ProductPostAddMixin;
