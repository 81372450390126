import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import AjaxService from 'ember-ajax/services/ajax';
import config from 'tt4/config/environment';
import { resolve } from 'rsvp';

// const {inject: {service}, get, Object, ObjectProxy, PromiseProxyMixin, isPresent, A} = Ember;
/* eslint-disable */
export default AjaxService.extend({
    session: service(),
    flags: service(),

    host: computed('session.currentUser.partnerid', 'flags._client', function () {
        return config.APP.TTAPI;
    }),

    headers: computed('session.session.authenticated.access_token', {
        get() {
            let headers = {};

            let token = get(this, 'session.session.authenticated.access_token');
            headers['Authorization'] = 'Bearer ' + token;

            headers.clientv = config.APP.version;
            headers['X-brand'] = config.brand;
            // if (get(Ember, 'devModeOn') === true) headers['devMode'] = true;
            return headers;
        },
    }),

    _get(route, params) {
        return this._request('get', route, params);
    },

    _post(route, params) {
        return this._request('post', route, params);
    },

    _delete(route, params) {
        return this._request('delete', route, params);
    },

    _request(method, route, params = {}) {
        const promiseObject = ObjectProxy.extend(PromiseProxyMixin);

        return promiseObject.create({
            promise: this.request(route, { method: method, data: params }).then((data) =>
                this._returnData(data),
            ),
        });
    },

    request() {
        //skip ttapi requests if at2
        if (config.target === 'at2') {
            return resolve([]);
        }
        return this._super(...arguments);
    },

    _returnData(data) {
        return data;
    },
});
