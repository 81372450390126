/*eslint-disable ember/named-functions-in-promises */
import { inject as controller } from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { A } from '@ember/array';
import GeneralAddController from '../../../controllers/general-add';

const AbcensesEditController = GeneralAddController.extend({
    saved: controller(),

    edit: true,
    abcensesAlias: alias('model'),
    fieldSettings: A({
        edit: true,
    }),

    settings: computed('model', function () {
        // if hour-type adding
        let ret;
        if (
            get(this, 'model.starttime') ||
            (get(this, 'model.endtime') && get(this, 'model.dayamount') === 0) ||
            !get(this, 'model.dayamount')
        ) {
            if (this.Collector.testNeedsOne('products.absences_hours')) {
                ret = {
                    form: 'abcense',
                    skip: ['dayamount', 'status', 'enddate'],
                    mandatory: ['absence_hours', 'startdate'],
                };
            } else {
                ret = {
                    form: 'abcense',
                    skip: ['dayamount', 'status', 'enddate'],
                    mandatory: ['starttime', 'endtime', 'startdate'],
                };
            }
        } else {
            ret = {
                form: 'abcense',
                skip: ['starttime', 'endtime', 'status', 'addon_frnvaro_timmar', 'absence_hours'],
                mandatory: ['startdate', 'enddate', 'dayamount'],
            };
        }
        return ret;
    }),

    multipleDays: computed('model', function () {
        if (
            get(this, 'model.starttime') ||
            (get(this, 'model.endtime') && get(this, 'model.dayamount') === 0) ||
            !get(this, 'model.dayamount')
        ) {
            return false;
        } else {
            return true;
        }
    }),

    actions: {
        recordSaved(settings, record) {
            set(this, 'saved.model', record);
            set(this, 'saved.hideAddLinks', true);
            if (get(this, 'afterSave')) {
                return this.transitionToRoute('saved').then((newRoute) => {
                    return set(newRoute.controller, 'afterSave', get(this, 'afterSave'));
                });
            } else {
                return this.transitionToRoute('saved');
            }
        },
        cancel() {
            if (get(this, 'afterSave')) {
                const afterSave = get(this, 'afterSave');
                if (typeof afterSave === 'object') {
                    return this.transitionToRoute(afterSave.route, afterSave.params);
                }
                return this.transitionToRoute(get(this, 'afterSave'));
            } else {
                return this.transitionToRoute('abcenses.index');
            }
        },
    },
});
export default AbcensesEditController;
