/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/tr/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyReportController;

import Ember from 'ember';

SiteSafetyReportController = Ember.Controller.extend({
    date: moment().subtract(3, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    project: null,
    group: null,
});

export default SiteSafetyReportController;
