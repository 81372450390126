/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/observation/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyObservationAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

SiteSafetyObservationAddRoute = GeneralAddRoute.extend({
    modelName: 'site_safety_observation',
    controllerName: 'site_safety.observation.add',
    afterSave: 'site_safety.report.observation',
});

export default SiteSafetyObservationAddRoute;
