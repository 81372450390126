/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/thereport/settings/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ThereportSettingsController;

import Ember from 'ember';

ThereportSettingsController = Ember.Controller.extend({
    parent: Em.inject.controller('thereport'),
    dialogs: Em.inject.service(),
    intl: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    session: Ember.inject.service(),
    reports: Em.computed.alias('parent.model'),
    _reports: Em.computed('reports', function () {}),
    allInUse: Em.computed('reports', function () {
        var allInUse;
        allInUse = true;
        if (this.get('session.currentUser.userlevel') === '1') {
            //hide "Add report" from settings page for worker if worker has been given edit permissions for some report...
            allInUse = false;
        }
        this.get('model').forEach(function (item) {
            if (!item.id) {
                return (allInUse = false);
            }
        });
        return allInUse;
    }),
    _userlevels: Em.computed('currentReport', function () {
        var c, lvls;
        c = this.get('Collector');
        lvls = c.getUserlevels([]);
        return lvls;
    }),
    onlyIcanSee: Em.computed('currentReport', function () {
        if (this.get('currentReport.id')) {
            if (this.get('currentReport.settings.only_creator_can_see')) {
                return true;
            }
        }
        return false;
    }),
    createdByCurrentuser: Em.computed('currentReport', function () {
        if (this.get('currentReport.created_by_currentuser') === true) {
            return true;
        }
        return false;
    }),
    userlevels: Em.computed('_userlevels', function () {
        var i, len, lvl, lvls, savedUserlevels, tmp_lvls;
        tmp_lvls = this.get('_userlevels');
        savedUserlevels = this.get('currentReport.settings.userlevels');
        if (this.get('session.currentUser.userlevel') !== '5') {
            lvls = tmp_lvls.filter((lvl) => {
                if (lvl['id'] === '5') {
                    return false;
                } else {
                    return true;
                }
            });
        } else {
            lvls = tmp_lvls;
        }
        for (i = 0, len = lvls.length; i < len; i++) {
            lvl = lvls[i];
            if (
                (savedUserlevels && savedUserlevels['view'].indexOf(parseInt(lvl['id'])) > -1) ||
                lvl['id'] === this.get('session.currentUser.userlevel')
            ) {
                //console.log "userlevel "+lvl['id']+" has view permission"
                lvl['view'] = true;
            }
            if (
                (savedUserlevels && savedUserlevels['edit'].indexOf(parseInt(lvl['id'])) > -1) ||
                lvl['id'] === this.get('session.currentUser.userlevel')
            ) {
                //console.log "userlevel "+lvl['id']+" has edit permission"
                lvl['edit'] = true;
            }
        }
        return lvls;
    }),
    actions: {
        saveComboSettings: function () {
            var editPermissions, i, len, lvl, ref, report, viewPermissions;
            report = this.get('currentReport');
            viewPermissions = [];
            editPermissions = [];
            ref = this.get('userlevels');
            for (i = 0, len = ref.length; i < len; i++) {
                lvl = ref[i];
                if (lvl['edit']) {
                    editPermissions.push(parseInt(lvl['id']));
                }
                if (lvl['view']) {
                    viewPermissions.push(parseInt(lvl['id']));
                }
            }
            if (this.get('onlyIcanSee')) {
                Em.set(report, 'settings.only_creator_can_see', true);
            } else {
                delete report['settings']['only_creator_can_see'];
                //Em.set report,'settings.userid', null
                if (viewPermissions.length > 0 && editPermissions.length > 0) {
                    // if this is not personal report-page, set permissions
                    // also set permissions only if user has checked some boxes
                    // ALSO admin has always permissions to edit & view
                    if (viewPermissions.indexOf(5) === -1) {
                        viewPermissions.push(5);
                    }
                    if (editPermissions.indexOf(5) === -1) {
                        editPermissions.push(5);
                    }
                    Em.set(report, 'settings.userlevels', {
                        view: viewPermissions,
                        edit: editPermissions,
                    });
                } else {
                    delete report['settings']['userlevels'];
                }
            }
            this.get('parent').saveReport(report['id'], report['name'], report['settings']);
            this.set('showComboDialog', false);
            return this.get('parent').send('reload');
        },
        edit: function (report) {
            this.set('currentReport', report);
            if (
                this.get('currentReport.settings.userid') &&
                parseInt(this.get('currentReport.settings.userid')) ===
                    parseInt(this.get('session.currentUser.id'))
            ) {
                this.set('ownReportPage', true);
            }
            this.set('showComboDialog', true);
            return false;
        },
        rename: function (report) {
            var promise;
            promise = this.get('dialogs').prompt(
                this.get('intl').t('thereport.report_name'),
                report.name,
            );
            return promise.then((val) => {
                if (val !== false) {
                    return this.get('parent')
                        .saveReport(report.id, val)
                        .then(() => {
                            return this.get('parent').send('reload');
                        });
                }
            });
        },
        addNew: function () {
            this.set('ownReportPage', true);
            this.set(
                'currentReport',
                Em.Object.create({
                    settings: {
                        userid: this.get('session.currentUser.id'),
                    },
                    created_by_currentuser: true,
                }),
            );
            this.set('showComboDialog', true);
            //@set 'currentReport', report
            return false;
        },
        /*
    promise = @get('dialogs').prompt @get('intl').t "thereport.report_new_name"

    promise.then (val) =>
        if val isnt false
            @get('parent').saveReport(null, val).then =>
                @get('parent').send 'reload'

    */
        remove: function (report) {
            var promise;
            promise = this.get('dialogs').confirm(this.get('intl').t('thereport.remove'));
            return promise.then((val) => {
                if (val === true) {
                    return this.get('parent')
                        .removeReport(report.id)
                        .then(() => {
                            return this.get('parent').send('reload');
                        });
                }
            });
        },
        removeModal: function () {
            return this.set('showComboDialog', false);
        },
    },
});

export default ThereportSettingsController;
