import { computed, get } from '@ember/object';
import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    queryParams: ['date'],
    activitycontroller: alias('model'),

    date: computed(function () {
        return moment().format('YYYY-MM-DD');
    }),

    ago: computed('date', function () {
        let date = moment(get(this, 'date'));

        if (moment().diff(date, 'days') > 1) {
            return date.add(1, 'day').fromNow(); // '2 days ago' etc.
        }
        return date.calendar().split(' ')[0]; // 'Today', 'yesterday', 'tomorrow'
    }),
});
