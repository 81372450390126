/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-besiktningar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryNotesBesiktningarController;

import Ember from 'ember';

import direktivController from '../notes-direktiv/controller';

WorksiteDiaryNotesBesiktningarController = direktivController.extend({
    type: 'besiktningar',
    intl: Ember.inject.service(),
    options: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return [
            intl.t('diaryreport.notes.options.byggnadsnamnd'),
            intl.t('diaryreport.notes.options.arbetsmiljo'),
            intl.t('diaryreport.notes.options.elinspektion'),
            intl.t('diaryreport.notes.options.anlaggnprovning'),
            intl.t('diaryreport.notes.options.miljo'),
        ];
    }),
});

export default WorksiteDiaryNotesBesiktningarController;
