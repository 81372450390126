/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import { observer, set } from '@ember/object';
import { A } from '@ember/array';

const ImportFileReaderComponent = Component.extend({
    data: A([]),

    obsSplitter: observer('splitter', function () {
        if (this.rawFile) {
            return this.readFile(this.rawFile);
        }
    }),
    actions: {
        addFile() {
            return this.element.querySelector('.file_input').click();
        },
    },
    change(e) {
        const self = this;
        return Array.from(e.target.files).map((file) => self.readFile(file));
    },
    readFile(file) {
        set(this, 'rawFile', file);
        set(this, 'error', null);
        const reader = new FileReader();
        set(this, 'filename', file.name);
        const splitname = file.name.split('.');
        const filetype = splitname[splitname.length - 1];
        if (filetype) {
            set(this, 'filetype', filetype);
        }
        reader.onload = (event) => {
            return this.processFile(event.target.result);
        };

        return reader.readAsText(file);
    },

    processFile(file) {
        if (this.filetype === 'csv') {
            const allTextLines = file.split(/\r\n|\n/);
            const lines = [];
            for (let line of Array.from(allTextLines)) {
                if (!line) {
                    continue;
                }
                const data = line.split(this.splitter);
                const tarr = [];
                for (let d of Array.from(data)) {
                    if (d) {
                        tarr.push(d.replace(/(^\s*,)|(,\s*$)/g, ''));
                    } else {
                        tarr.push(d);
                    }
                }
                lines.push(tarr);
            }

            return set(this, 'file', lines);
        }
    },
});

export default ImportFileReaderComponent;
