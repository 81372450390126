/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-setting-comp/pause-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorSettingCompPauseSettingComponent;

import Ember from 'ember';

import collector from '../../../../classes/collector';

CollectorSettingCompPauseSettingComponent = Ember.Component.extend({
    store: Em.inject.service(),
    session: Em.inject.service(),
    intl: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    init: function () {
        if (this.get('Collector').testNeedsOne('products.rfid_pause')) {
            this.set('nfcPauseOn', true);
        }
        this._super();
        return this.getSetting();
    },
    getSetting: function () {
        var configs, setting_params;
        setting_params = {
            name: 'default_value',
            value: 30,
        };
        if (this.get('worktimegroup')) {
            setting_params.condition = '$userProfile->worktimegroup==' + this.get('worktimegroup');
        }
        configs = {
            form: 'worktime',
            field: 'pause',
            setting: setting_params,
        };
        return this.get('store')
            .findAll('form', {
                reload: true,
            })
            .then((forms) => {
                var field, form, setting;
                form = forms.filterBy('external_type', configs.form);
                field = form.get('firstObject.fields').filterBy('external_type', configs.field);
                if (field.get('length') > 0) {
                    setting = field
                        .get('firstObject.settings')
                        .filterBy('name', configs.setting.name);
                    setting = setting.filter((item) => {
                        var arr;
                        if (this.get('worktimegroup')) {
                            if (!item.get('condition')) {
                                return false;
                            }
                            arr = item.get('condition').split('==');
                            return arr[1] === this.get('worktimegroup');
                        } else {
                            return item.get('condition') === '';
                        }
                    });
                    if (setting.get('firstObject')) {
                        return this.set('setting', setting.get('firstObject'));
                    } else {
                        configs.setting.field = field.get('firstObject');
                        return this.set(
                            'setting',
                            this.get('store').createRecord('form-field-setting', configs.setting),
                        );
                    }
                } else {
                    return this.set('setting', false);
                }
            });
    },
    actions: {
        saveSetting: function () {
            return this.get('setting')
                .save()
                .then(() => {
                    var ret;
                    if (this.get('nfcPauseOn')) {
                        ret = this.get('Collector').setProducts(['products.rfid_pause']);
                    } else {
                        ret = this.get('Collector').setProducts(['!products.rfid_pause']);
                    }
                    return Ember.RSVP.Promise.all(ret).then(() => {
                        // unfortunately we need to send products.pause as param so that famocos can be updated
                        return this.sendAction('settingSaved', 'products.pause');
                    });
                });
        },
    },
});

export default CollectorSettingCompPauseSettingComponent;
