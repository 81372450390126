/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/collector.coffee.old
// Generated by CoffeeScript 2.6.1
var Collector;

import DS from 'ember-data';

Collector = DS.Model.extend({
    row_info: DS.belongsTo('row-info'),
    clone: function () {
        var attrs, class_type, model, root;
        model = this;
        attrs = this.serialize();
        class_type = model.constructor;
        root = Ember.String.decamelize(class_type.toString().split(':')[1]);
        this.eachRelationship(function (key, relationship) {
            if (relationship.kind === 'belongsTo') {
                attrs[key] = model.get(key);
            }
        });

        return this.store.createRecord(root, attrs);
    },
    //                if relationship.kind == 'belongsTo'
    //                    attrs[key] = model.get(key)

    //    info: Ember.computed.alias 'data.row_info'
    isRemovable: Ember.computed.alias('row_info.privilege_remove'),
    isEditable: Ember.computed.alias('row_info.privilege_edit'),
    //    isRemovable: Ember.computed('data.row_info', ->
    //        return true if not @get 'data.row_info'
    //        @get 'data.row_info.privilege_remove'
    //    )

    //    isEditable: Ember.computed('data.row_info', ->
    //        return true if not @get 'data.row_info'
    //        @get 'data.row_info.privilege_edit'
    //    )
    validation: 'on',
});

export default Collector;
