/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/product-card/component.coffee.old
// Generated by CoffeeScript 2.6.1
var ProductCardComponent;

import Ember from 'ember';

import config from '../../../config/environment';

ProductCardComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    cdn: Ember.inject.service(),
    image: Em.computed(function () {
        if (this.get('product.image')) {
            return this.get('cdn').url('images/products/' + this.get('product.image'));
        }
    }),
    actions: {
        click: function () {
            if (
                this.get('product.selected') ||
                this.get('products').filterBy('selected').length === 0
            ) {
                if (this.get('click')) {
                    return this.click(this.get('product'));
                }
            } else if (this.get('products').filterBy('selected').length > 0) {
                this.get('products').map((product) => {
                    return product.set('selected', false);
                });
                if (this.get('click')) {
                    return this.click(this.get('product'));
                }
            }
        },
        infoButton: function () {
            return this.infoButton();
        },
    },
});

export default ProductCardComponent;
