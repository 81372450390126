/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/dashboard/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SkyddsrondDashboardRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

SkyddsrondDashboardRoute = GeneralReportRoute.extend({
    store: Ember.inject.service(),
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var store;
        params = this._super(params);
        params.sideload = true;
        store = this.get('store');
        return Ember.RSVP.hash({
            skyddsrond: store.query('skyddsrond', params),
            notices: store.query('notice', {
                limit: 1,
                project: params.project,
            }),
            not_solved: store.query('notice', {
                limit: 1,
                status: 'open',
                project: params.project,
            }),
            overdue: store.query('notice', {
                fixbydate: params.date,
                status: 'open',
                limit: 1,
                project: params.project,
            }),
        });
    },
});

export default SkyddsrondDashboardRoute;
