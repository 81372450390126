/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/show.coffee.old
// Generated by CoffeeScript 2.6.1
var ShowController;

import Ember from 'ember';

import collector from '../classes/collector';

import config from '../config/environment';

import GeneralAddController from '../controllers/general-add';

ShowController = GeneralAddController.extend({
    intl: Ember.inject.service(),
    header: Em.computed('model', 'intl.primaryLocale', function () {
        var form;
        if (this.get('model.constructor.modelName').includes('measurement-custom')) {
            form = this.Collector.forms()[this.get('form').underscore()];
            return form.name;
        } else {
            return this.get('intl').t('header.path.add.' + this.get('model.constructor.modelName'));
        }
    }),
});

export default ShowController;
