/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/first/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportFirstController;

import Ember from 'ember';

WorksitediaryReportFirstController = Ember.Controller.extend({
    dateField: Ember.computed(function () {
        var field;
        field = this.get('Collector').fieldArray('diaryreport', ['diary_date'])[0];
        field.value = moment().format('YYYY-MM-DD');
        this.send('updateField', field.value, 'diary_date');
        return field;
    }),
    projectField: Ember.computed(function () {
        var field;
        field = this.get('Collector').fieldArray('diaryreport', ['project'])[0];
        this.send('updateField', field.value, 'project');
        return field;
    }),
    actions: {
        updateField: function (value, fieldname) {
            var params;
            if (fieldname === 'diary_date') {
                this.set('diary_date', value);
            }
            if (fieldname === 'project') {
                this.set('project', value);
            }
            if (Em.isEmpty(this.get('project'))) {
                return;
            }
            if (Em.isEmpty(this.get('diary_date'))) {
                return;
            }
            //check if there is already journal for this day
            params = {};
            params.diary_date = this.get('diary_date');
            params.project = this.get('project.id');
            this.get('store')
                .query('diaryreport', params)
                .then((data) => {
                    if (data.content.length > 0) {
                        this.set('diaryFound', true);
                        this.set('diaryRow', data.content[0]);
                    } else {
                        this.set('diaryFound', false);
                    }
                    return this.set('showNextButton', true);
                });
        },
        goToNext: function () {
            var id;
            if (this.get('diaryFound')) {
                id = this.get('diaryRow.id');
            } else {
                id = 'add';
            }
            this.set('dateField.value', this.get('diary_date'));
            this.set('projectField.value', this.get('project'));
            this.set('showNextButton', false);
            return this.transitionToRoute('worksitediary.report.add.basic-info', id);
        },
    },
});

export default WorksitediaryReportFirstController;
