/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/graphs/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMeasurementGraphsController;

import Ember from 'ember';

import formattedMixin from '../../../../mixins/sitesafety-formatted-export';

SiteSafetyMeasurementGraphsController = Ember.Controller.extend(formattedMixin, {
    //    needs: 'site-safety/measurement'
    measurementController: Ember.inject.controller('site-safety/measurement'),
    queryParams: ['project', 'date'],
    project_filter: Em.computed('project', 'form', function () {
        return {
            form: this.get('form'),
            name: 'project',
            type: 'database',
            value: this.get('project'),
        };
    }),
    measurementController: Ember.inject.controller('site-safety/measurement'),
    project: Ember.computed.alias('measurementController.project'),
    date: Ember.computed.alias('measurementController.date'),
    form: Ember.computed.alias('measurementController.form'),
    dialogs: Ember.inject.service(),
    intl: Em.inject.service(),
    arrangedContent: Ember.computed('content', function () {
        return this.get('content').sortBy('date');
    }),
    dataSetter: function () {
        var data, data_rating, fields, form, headers, mc, measurements, self;
        self = this;
        mc = this.get('measurementController');
        form = mc.get('model');
        fields = mc.parseFields(form);
        headers = [this.get('intl').t('date')];
        fields.forEach(function (item) {
            return headers.push(item.title);
        });
        data = Em.A([headers]);
        data_rating = Em.A([[this.get('intl').t('date'), this.get('intl').t('tr.rating')]]);
        measurements = this.get('arrangedContent');
        measurements.forEach(function (measurement) {
            var date, field, field_object, i, len, minus, plus, row;
            if (moment(measurement.get('date')).isAfter(moment().subtract(1, 'years'))) {
                row = [moment(measurement.get('date')).format('L')];
                for (i = 0, len = fields.length; i < len; i++) {
                    field_object = fields[i];
                    field = field_object.ext;
                    plus = measurement.get(field + '_plus');
                    minus = measurement.get(field + '_minus');
                    if (plus && minus) {
                        row.push(parseInt(plus, 10) - parseInt(minus, 10));
                    } else if (plus) {
                        row.push(parseInt(plus, 10));
                    } else if (minus) {
                        row.push(parseInt(minus, 10) * -1);
                    } else {
                        row.push(0);
                    }
                }
                data.push(row);
                date = moment(measurement.get('date')).format('L');
                if (!isNaN(measurement.get('tr_rating')) && measurement.get('tr_rating') !== null) {
                    return data_rating.push([date, parseFloat(measurement.get('tr_rating'))]);
                } else {
                    return data_rating.push([date, 0]);
                }
            }
        });
        self.set('data', data);
        return self.set('data_rating', data_rating);
    }.observes('arrangedContent.[]', 'content.[]'),
    actions: {
        exportNewestPdf: function (project) {
            var customMeasurement, fields, newest;
            customMeasurement = this.get('form');
            newest = this.store
                .peekAll(customMeasurement)
                .filter((record) => {
                    return record.get('project.id') === project;
                })
                .sortBy('date')
                .reverse()
                .get('firstObject');
            if (!newest) {
                this.get('dialogs').alert(
                    this.get('intl').t('tr.chart.export_newest_pdf_not_found'),
                );
            }
            // export as measurement pdf
            fields = this.get('Collector').fields(customMeasurement);
            return this.createMeasurementPdf(fields, [newest]);
        },
        filterChanged: function (value, fieldName) {
            if (fieldName === 'project') {
                if (value == null) {
                    return this.set('project', '');
                } else {
                    return this.set('project', value.id);
                }
            }
        },
    },
});

export default SiteSafetyMeasurementGraphsController;
