/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/form-wizard/custom-measurement/component.coffee.old
// Generated by CoffeeScript 2.6.1

/*

    this is done totally wrong!!!!!! tries to be component for wizards but in reality only support own measurement!!

*/
var CustomMeasurementComponent;

import Ember from 'ember';

CustomMeasurementComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Em.inject.service(),
    notifications: Ember.inject.service(),
    modelCreator: Ember.inject.service('model-creator'),
    // form we are creating or editing
    form: null,
    model: null,
    measurement_fields: Ember.computed('model.fields.[]', function () {
        if (!this.get('model.fields')) {
            return [];
        }
        return this.get('model.fields').filter(function (item) {
            return item.get('external_type').indexOf('_plus') !== -1;
        });
    }),
    getSchemaidFor: function (form, field) {
        var field_record, form_record;
        form_record = this.get('store').peekAll('form').findBy('external_type', form);
        if (!form_record) {
            Ember.assert('getSchemaidFor failed because form (' + form + ') not found');
        }
        field_record = form_record.get('fields').findBy('external_type', field);
        if (!form_record) {
            Ember.assert(
                'getSchemaidFor failed because field (' + field + ') not found from form ' + form,
            );
        }
        return field_record.get('id');
    },
    makeid: function () {
        var i, j, possible, text;
        text = '';
        possible = 'abcdefghijklmnopqrstuvwxyz';
        for (i = j = 0; j < 10; i = ++j) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    },
    // set all missing field names
    beforeSave: function () {
        var intl, model;
        model = this.get('model');
        intl = this.get('intl');
        return model.get('fields').forEach(function (record) {
            var endsWith, external_type, newName, splitted, startsWith;
            external_type = record.get('external_type');
            splitted = external_type.split('_');
            endsWith = splitted[splitted.length - 1];
            if (endsWith === 'minus' || endsWith === 'notices') {
                startsWith = external_type.substring(0, external_type.length - endsWith.length - 1);
                newName = model
                    .get('fields')
                    .findBy('external_type', startsWith + '_plus')
                    .get('name');
                if (endsWith === 'notices') {
                    newName += ' ' + intl.t('measurement_custom.notice_field');
                }
                return record.set('name', newName);
            }
        });
    },
    actions: {
        addField: function () {
            var fields, rand;
            fields = this.get('model.fields');
            if (fields.length > 48) {
                this.get('dialogs').alert(
                    this.get('intl').t('settings.measurement_custom.max_fields'),
                );
                return;
            }
            rand = this.makeid();
            fields.pushObject(
                this.get('store').createRecord('form-field', {
                    type: 'number',
                    external_type: 'meas' + rand + '_plus',
                }),
            );
            fields.pushObject(
                this.get('store').createRecord('form-field', {
                    type: 'number',
                    external_type: 'meas' + rand + '_minus',
                }),
            );
            return fields.pushObject(
                this.get('store').createRecord('form-field', {
                    type: 'multiselect',
                    external_type: 'meas' + rand + '_notices',
                    options: this.getSchemaidFor('trnotice', 'desc'),
                }),
            );
        },
        removeField: function (field) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('general.areyousure'))
                .then((con) => {
                    var field_prefix, remove_fields;
                    if (!con) {
                        return;
                    }
                    field_prefix = field
                        .get('external_type')
                        .substring(0, field.get('external_type').length - 4);
                    remove_fields = this.get('model.fields').filter(function (item) {
                        return item.get('external_type').indexOf(field_prefix) !== -1;
                    });
                    return remove_fields.forEach(function (record) {
                        return record.destroyRecord();
                    });
                });
        },
        saveForm: function () {
            var model;
            this.set('saving', true);
            this.beforeSave();
            model = this.get('model');
            return model
                .save()
                .then(() => {
                    var promises;
                    promises = [];
                    model.get('fields').forEach(function (record) {
                        if (record.get('hasDirtyAttributes')) {
                            return promises.push(record.save());
                        }
                    });
                    return Ember.RSVP.Promise.all(promises).then(() => {
                        return this.get('modelCreator')
                            .createEmberModels()
                            .then(() => {
                                if (this.get('saved')) {
                                    this.saved();
                                }
                                return this.set('saving', false);
                            });
                    });
                })
                .catch((e) => {
                    this.get('notifications').error(
                        this.get('intl').t('settings.measurement_custom.create_failed'),
                        {
                            autoClear: false,
                        },
                    );
                    if (this.get('extClash')) {
                        this.extClash(model);
                    }
                    return this.set('saving', false);
                });
        },
        removeForm: function (form) {
            this.set('removing', true);
            return this.get('dialogs')
                .confirm(this.get('intl').t('settings.measurement_custom.confirm_remove'))
                .then((con) => {
                    if (con) {
                        return form
                            .destroyRecord()
                            .then(() => {
                                return this.get('modelCreator')
                                    .createEmberModels()
                                    .then(() => {
                                        if (this.get('removedMeasurement')) {
                                            this.removedMeasurement();
                                        }
                                        return this.get('notifications').success(
                                            this.get('intl').t(
                                                'settings.measurement_custom.remove_success',
                                            ),
                                            {
                                                autoClear: true,
                                            },
                                        );
                                    });
                            })
                            .catch((e) => {
                                return this.get('notifications').error(
                                    this.get('intl').t('settings.measurement_custom.remove_fail'),
                                    {
                                        autoClear: false,
                                    },
                                );
                            })
                            .finally(() => {
                                if (!this.get('isDestroyed')) {
                                    return this.set('removing', false);
                                }
                            });
                    }
                });
        },
    },
});

export default CustomMeasurementComponent;
