import { init, BrowserTracing } from '@sentry/browser';
import { Ember as EmberIntegration } from '@sentry/integrations/esm/ember';
import config from 'tt4/config/environment';
import { isArray } from '@ember/array';

export function startSentry() {
    if (config.environment !== 'development') {
        init({
            ...config.sentry,

            release: config.APP.version,

            integrations: [
                new EmberIntegration({
                    onerror: true,
                    onunhandledrejection: true,
                }),
                new BrowserTracing(),
            ],

            tracesSampleRate: 0.2,

            beforeSend(event, hint) {
                /*
                 * if flag sentry-enabled is false, don't send events to sentry
                 * this is done through event.extra because we cannot access the services/flags from the sentry.js
                 */
                if (event && event.extra && !event.extra.sentryIsEnabled) {
                    return null;
                }

                const error = hint.originalException;
                if (error && error.errors && error.errors.error === 'overlap') {
                    return null;
                }
                // https://github.com/emberjs/ember.js/issues/5566
                if (error && error.message === 'TransitionAborted') {
                    return null;
                }
                if (error && error.error === 'invalid_grant') {
                    return null;
                }
                if (
                    error &&
                    error.message ===
                        'a glimmer transaction was begun, but one already exists. You may have a nested transaction'
                ) {
                    return null;
                }
                if (
                    error &&
                    hint.originalException.payload &&
                    hint.originalException.payload.error === 'username_in_use'
                ) {
                    return null;
                }
                if (
                    error &&
                    hint.originalException.payload &&
                    hint.originalException.payload.error === 'username_contains_illegal_characters'
                ) {
                    return null;
                }

                const normalize = (filename) =>
                    config.sentry.publicUrl + '/assets/' + filename.split('/www/', 2)[1];

                if (
                    event.exception &&
                    event.exception.values &&
                    event.exception.values[0] &&
                    event.exception.values[0].stacktrace &&
                    isArray(event.exception.values[0].stacktrace.frames)
                ) {
                    event.exception.values[0].stacktrace.frames.forEach((frame) => {
                        if (frame.filename.includes('/www/')) {
                            frame.filename = normalize(frame.filename);
                        }
                    });
                    event.culprit = event.exception.values[0].stacktrace.frames[0].filename;
                }

                return event;
            },
        });
    }
}
