/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryAddController;

import Ember from 'ember';

WorksiteDiaryAddController = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ['date'],
    page: 1,
    display_previous: false,
    router: Ember.inject.service(),
    enable_next: Ember.computed.alias('model.project.id'),
    applicationController: Ember.inject.controller('application'),
    pages: [
        {
            link: 'worksitediary.report.add.basic-info',
            needs: [],
        },
        {
            link: 'worksitediary.report.add.at-site',
            needs: [],
        },
        {
            link: 'worksitediary.report.add.diary-events',
            needs: [],
        },
        {
            link: 'worksitediary.report.add.extra',
            needs: ['diaryreport.egen_kontrol'],
        },
        {
            link: 'worksitediary.report.add.notes-direktiv',
            needs: ['diaryreport_note'],
        },
        {
            link: 'worksitediary.report.add.notes-anmalan',
            needs: ['diaryreport_note'],
        },
        {
            link: 'worksitediary.report.add.notes-besiktningar',
            needs: ['diaryreport_note'],
        },
        {
            link: 'worksitediary.report.add.notes-ritningar',
            needs: ['diaryreport_note'],
        },
        {
            link: 'worksitediary.report.add.material',
            needs: ['material'],
        },
        {
            link: 'worksitediary.report.add.review-page',
            needs: ['review_and_inspection'],
        },
        {
            link: 'worksitediary.report.add.ordered-changes',
            needs: ['diaryreport_misc'],
        },
        {
            link: 'worksitediary.report.add.offering',
            needs: ['diaryreport_given_offer'],
        },
        {
            link: 'worksitediary.report.add.required-extra',
            needs: ['diaryreport_required_extra'],
        },
        {
            link: 'worksitediary.report.add.supervisor-note',
            needs: ['diaryreport_supervisor_note'],
        },
        {
            link: 'worksitediary.report.add.other-entries',
            needs: ['diaryreport_other_note'],
        },
        {
            link: 'worksitediary.report.add.signature',
            string: 'orientations.breadcrumb.signatures',
            icon: 'move move-Signature',
            needs: [],
        },
        {
            link: 'worksitediary.report.add.completion-page',
            needs: ['completion_rate'],
        },
        {
            link: 'worksitediary.report.add.summary-page',
            needs: [],
        },
    ],
    filteredPages: Ember.computed(function () {
        var c;
        c = this.get('Collector');
        return this.get('pages').filter(function (item) {
            if (item.needs && c.testNeeds(item.needs)) {
                return item;
            }
        });
    }),
    hide_previous: Em.computed.equal(
        'router.currentRouteName',
        'worksitediary.report.add.basic-info',
    ),
    hide_next: Em.computed.equal(
        'router.currentRouteName',
        'worksitediary.report.add.summary-page',
    ),
    actions: {
        next: function () {
            var page, pages;
            this.set('model.status', 'saved');
            if (this.get('model.isNew')) {
                this.set('model.user', this.get('session.currentUser'));
            }
            pages = this.get('filteredPages').mapBy('link');
            page = pages.indexOf(this.get('router.currentRouteName'));
            return this.transitionToRoute(pages[page + 1]);
        },
        previous: function () {
            var page, pages;
            this.set('model.status', 'saved');
            if (this.get('model.isNew')) {
                this.set('model.user', this.get('session.currentUser'));
            }
            pages = this.get('filteredPages').mapBy('link');
            page = pages.indexOf(this.get('router.currentRouteName'));
            return this.transitionToRoute(pages[page - 1]);
        },
        updateField: function (value, field) {
            return this.set('model.' + field, value);
        },
    },
});

export default WorksiteDiaryAddController;
