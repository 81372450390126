/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/google.coffee.old
// Generated by CoffeeScript 2.6.1
var GoogleService;

import Ember from 'ember';

import config from '../config/environment';

import { computed } from '@ember/object';

import $ from 'jquery';

GoogleService = Ember.Service.extend({
    directionsService: computed(function () {
        if (Em.testing) {
            return;
        }
        return new window.google.maps.DirectionsService();
    }),
    key: config.APP.GM_KEY,
    // this tries to fetch metadata from streetview
    // and use that data to determine if streetview-image is available or not
    getMetadata: function (lat, lon) {
        return new Em.RSVP.Promise((resolve) => {
            return $.ajax({
                beforeSend: function (request) {},
                //request.setRequestHeader 'Authorization', ' '
                url:
                    'https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=' +
                    lat +
                    ',' +
                    lon +
                    '&heading=0&pitch=-0.76&key=' +
                    this.get('key'),
                type: 'GET',
                success: (metadata) => {
                    return resolve(metadata);
                },
            });
        });
    },
    streetViewUrl: function (coords) {
        var lat, lon, url;
        lat = coords.latitude;
        lon = coords.longitude;
        url =
            'https://maps.googleapis.com/maps/api/streetview?size=600x300&location=' +
            lat +
            ',' +
            lon +
            '&heading=0&pitch=-0.76';
        if (this.get('key')) {
            url += '&key=' + this.get('key');
        }
        return url;
    },
    routeDistance: function (from, to) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var request;
            request = {
                origin: from,
                destination: to,
                travelMode: 'DRIVING',
                provideRouteAlternatives: true,
            };
            return this.get('directionsService').route(request, (response, status) => {
                var minDist;
                if (status === 'OK') {
                    minDist = 1000000000;
                    response.routes.forEach((route) => {
                        var dist;
                        dist = route.legs[0].distance.value / 1000;
                        if (dist < minDist) {
                            return (minDist = dist);
                        }
                    });
                    return resolve(minDist.toFixed(0));
                } else {
                    return reject();
                }
            });
        });
    },
    routeDistanceAndTime: function (from, to) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var date, request;
            // google directions do not like dates in past
            // so we must make sure to use date in future
            if (moment().isAfter(moment().format('YYYY-MM-DD 03:00'))) {
                date = moment().add(1, 'day').hour(3);
            } else {
                date = moment().hour(3);
            }
            request = {
                origin: from,
                destination: to,
                travelMode: 'DRIVING',
                provideRouteAlternatives: true,
                drivingOptions: {
                    departureTime: date.toDate(),
                },
            };
            return this.get('directionsService').route(request, (response, status) => {
                var minDist, time;
                if (status === 'OK') {
                    minDist = 1000000000;
                    time = 0;
                    response.routes.forEach((route) => {
                        var dist;
                        dist = route.legs[0].distance.value / 1000;
                        if (dist < minDist) {
                            minDist = dist;
                            return (time = route.legs[0].duration.value / 60);
                        }
                    });
                    return resolve({
                        dist: minDist.toFixed(0),
                        time: time.toFixed(0),
                    });
                } else {
                    return reject();
                }
            });
        });
    },
});

export default GoogleService;
