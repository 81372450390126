/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/person-in-charge/component.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMeasurementPersonInChargeComponent;

import Ember from 'ember';

import DatabaseField from '../../../collector/fields/database-field/component';

SiteSafetyMeasurementPersonInChargeComponent = DatabaseField.extend({
    layout: 'collector/fields/database-field',
    _class: 'form-control',
    ObservePrompt: '',
    field: Em.computed(function () {
        var arr;
        return (arr = {
            form: 'tr',
            name: 'user',
            placeholder: 'site_safety.notice.person.placeholder',
        });
    }),
    actions: {
        focusIn: function () {
            return this.set('showOptions', true);
        },
        focusOut: function () {
            return this.set('showOptions', false);
        },
        select: function (option) {
            if (!option) {
                option = {};
            }
            return this._super(option);
        },
    },
});

export default SiteSafetyMeasurementPersonInChargeComponent;
