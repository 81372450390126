/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/dashboard/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SkyddsrondDashboardController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

SkyddsrondDashboardController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    queryParams: ['project', 'date'],
    form: 'skyddsrond',
    settings: Em.Object.create({
        rowSettings: Em.Object.create({}),
    }),
    filters: Em.A([]),
    date: moment().subtract(1, 'month').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    pie_options: {
        pieHole: 0.9,
        chartArea: {
            width: '90%',
            height: '95%',
        },
        height: 200,
        width: 350,
        pieSliceTextStyle: {
            color: 'black',
            fontSize: 20,
        },
    },
    //        slices: {
    //            1: {offset: 0.02}
    //            2: {offset: 0.02}
    //            3: {offset: 0.02}
    //        }
    data: Ember.computed('model.skyddsrond', function () {
        var arr, by_date, date_obj, intl, key, obj, value;
        obj = {
            $utan_anm: 0,
            $med_anm: 0,
            $ej_akt: 0,
        };
        date_obj = {};
        //        projects = Em.A([])
        this.get('model.skyddsrond').forEach(function (item) {
            var date;
            //            projects.pushObject item.get('project.id')
            date = item.get('date');
            if (date_obj[date] == null) {
                date_obj[date] = {
                    $utan_anm: 0,
                    $med_anm: 0,
                    $ej_akt: 0,
                };
            }
            return item.eachAttribute(function (name, meta) {
                var value;
                value = item.get(name);
                if (value === '$utan_anm' || value === '$med_anm' || value === '$ej_akt') {
                    ++date_obj[date][value];
                    return ++obj[value];
                }
            });
        });
        intl = this.get('intl');
        arr = Em.A([
            ['Rating', 'Value'],
            [intl.t('skyddsrond.option.utan_anm'), obj['$utan_anm']],
            [intl.t('skyddsrond.option.med_anm'), obj['$med_anm']],
            [intl.t('skyddsrond.option.ej_akt'), obj['$ej_akt']],
        ]);
        by_date = [
            [
                'Date',
                intl.t('skyddsrond.option.utan_anm'),
                intl.t('skyddsrond.option.med_anm'),
                intl.t('skyddsrond.option.ej_akt'),
            ],
        ];
        //        by_date_normalized = [["Date", "Utan","Med","Ej akt"]]
        //        projects_amount = projects.uniq().get('length')
        for (key in date_obj) {
            value = date_obj[key];
            by_date.push([
                moment(key).format('L'),
                value['$utan_anm'],
                value['$med_anm'],
                value['$ej_akt'],
            ]);
        }
        return {
            //            by_date_normalized.push [moment(key).format('L'), value["$utan_anm"]/projects_amount, value["$med_anm"]/projects_amount, value["$ej_akt"]/projects_amount]
            total: arr,
            by_date: by_date, //, by_date_normalized: by_date_normalized}
        };
    }),
    pie_colors: '#086E3D,#C51017,#B3B3B3',
    chart_options: {
        height: 350,
        width: '100%',
        colors: ['#086E3D', '#C51017', '#B3B3B3'],
    },
});

export default SkyddsrondDashboardController;
