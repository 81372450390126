/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/magickey/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsMagickeyController;

import Ember from 'ember';

ProjectsMagickeyController = Ember.Controller.extend({
    queryParams: ['magic_key'],
    Collector: Em.inject.service('collector-service'),
    intl: Em.inject.service(),
    mySites: Em.inject.service(),
    session: Em.inject.service(),
    tools: Em.inject.service(),
    store: Em.inject.service(),
    selfregister_url: null,
    createNewUserAndLogin: function (creator, projectid) {
        var p1, userinfo;
        userinfo = {
            project: projectid,
            creator: creator,
            person_country: this.get('session.currentUser.homecountry')
                ? this.get('session.currentUser.homecountry')
                : null,
            lastname: this.get('session.currentUser.lastname'),
            firstname: this.get('session.currentUser.firstname'),
            id06card: this.get('session.currentUser.id06kort'),
            person_code: this.get('session.currentUser.personid'),
            relative_name: this.get('session.currentUser.relative_name'),
            relative_phone: this.get('session.currentUser.relative_phone'),
            email: this.get('session.currentUser.email'),
            phone: this.get('session.currentUser.phone'),
            id06valid: this.get('session.currentUser.id06valid'),
            taxnumber: this.get('session.currentUser.taxnumber'),
            birthdate: this.get('session.currentUser.birthdate'),
            contract_type: this.get('session.currentUser.emp_relation'),
            workpass: this.get('session.currentUser.workpass'),
        };
        //number: @get 'session.currentUser.number'
        p1 = new Ember.RSVP.Promise((resolve, reject) => {
            var employer;

            // first fetch employer from api to get the needed info (emp_id and country)
            if (this.get('Collector').testNeedsOne('employer')) {
                return this.get('store')
                    .findRecord('employer', this.get('session.currentUser.employer.id'), {
                        reload: true,
                    })
                    .then((employer) => {
                        userinfo['employer_name'] = employer.get('name');
                        userinfo['employer_id'] = employer.get('emp_id');
                        userinfo['employer_country'] = employer.get('country');
                        return resolve(userinfo);
                    });
            } else {
                //if service doesnt have employers on, employer is the current company
                employer = this.get('session.currentUser.company_info');
                userinfo['employer_name'] = employer.get('company_name');
                userinfo['employer_id'] = employer.get('id');
                userinfo['employer_country'] = employer.get('country');
                return resolve(userinfo);
            }
        });
        return p1.then((userinfo) => {
            var promise;
            promise = this.get('Collector').ttapi({
                url: 'registerUser',
                method: 'post',
                data: {
                    userinfo: userinfo,
                },
            });
            return promise.then((back) => {
                this.get('mySites').addSiteWithJustId(projectid);
                return this.get('tools').change_service(back.username, '#/my-sites');
            });
        });
    },
    actions: {
        changeuser: function () {
            var c, params, username;
            username = this.get('change_user_username');
            c = this.get('Collector');
            params = {
                url: 'login',
                type: 'POST',
                data: {
                    change_user: username,
                },
            };
            return c.ajax(params).then((data) => {
                return this.transitionToRoute('token', data.refresh_token, {
                    queryParams: {
                        url: window.location.href,
                    },
                });
            });
        },
        add_project_with_magickey: function (magickey) {
            var c, promise;
            c = this.get('Collector');
            if (!magickey) {
                this.set('error', this.get('intl').t('magic_key.error_key_not_given'));
                return;
            }
            this.set('error', null);
            promise = c.ttapi({
                url: 'send_magickey',
                method: 'post',
                data: {
                    magickey: magickey,
                },
            });
            return promise.then(
                (back) => {
                    switch (back.type) {
                        case 'linked':
                            if (back.projectid) {
                                this.get('mySites').addSite(back.projectid, true);
                            }
                            return this.set('show_linking_done', true);
                        case 'selfregister':
                            this.set('selfregister_url', back.url);
                            if (back.creator) {
                                return this.createNewUserAndLogin(back.creator, back.project);
                            } else {
                                // fall back version if ttapi isnt returning creator
                                return this.set('show_needs_selfregister', true);
                            }
                            break;
                        case 'changeuser':
                            this.set('change_user_username', back.username);
                            return this.set('show_needs_changeuser', true);
                        default:
                            this.set('error', 'unknown error!!');
                            return console.error(
                                'Unknown response for add_project_with_magickey ' +
                                    JSON.stringify(back),
                            );
                    }
                },
                (reason) => {
                    return this.set(
                        'error',
                        this.get('intl').t('magic_key.error_' + reason.payload.error),
                    );
                },
            );
        },
    },
});

export default ProjectsMagickeyController;
