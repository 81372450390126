/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/online-checker.coffee.old
// Generated by CoffeeScript 2.6.1
var OnlineChecker;

import Ember from 'ember';

import collector from '../classes/collector';

OnlineChecker = Ember.Service.extend({
    is_online: true,
    check: function () {
        var self, url;
        //checking connection..
        url = collector.api_url() + '/status';
        self = this;
        return $.get(url, function (data) {
            return self.set('is_online', true);
        }).fail(function (err) {
            return self.set('is_online', false);
        });
    },
});

export default OnlineChecker;
