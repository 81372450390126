/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/drop-down-search.coffee.old
// Generated by CoffeeScript 2.6.1
var DropDownSearchComponent;

import Ember from 'ember';

import $ from 'jquery';

DropDownSearchComponent = Ember.Component.extend({
    // If we show options or not
    showOptions: false,
    // Field value
    value: null,
    // default class
    classes: 'form-control',
    // All options
    options: [],
    observeValue: Ember.observer('value', function () {
        if (this.get('value.string')) {
            return this.sendAction('change', this.get('value.string'));
        } else {
            return this.sendAction('change', this.get('value'));
        }
    }),
    // Filtered options by search value
    _options: Ember.computed('options', 'value', function () {
        var regexp;
        if (this.get('value')) {
            regexp = new RegExp(this.get('value'), 'i');
            return this.get('options').filter(function (item) {
                if (typeof item === 'object') {
                    return regexp.test(item.name);
                }
                return regexp.test(item);
            });
        } else {
            return this.get('options');
        }
    }),
    setValue: Em.on(
        'init',
        Em.observer('input', function () {
            if (!this.get('input')) {
                return;
            }
            if (Array.isArray(this.get('options')[0])) {
                return this.set('value', this.get('options').find(this.get('input')));
            } else {
                return this.set('value', this.get('options').findBy('id', this.get('input')).name);
            }
        }),
    ),
    actions: {
        focusIn: function () {
            return this.set('showOptions', true);
        },
        touched: function () {},
        // this is dummy method.. for some reason browser click fails without this
        focusOut: function () {
            this.set('showOptions', false);
            return this.send('hasOption');
        },
        select: function (option) {
            var value;
            $('input', this.element).blur();
            if (option) {
                if (option.get != null) {
                    value = option.get('name');
                } else if (option.id) {
                    value = option.name;
                } else {
                    value = option;
                }
                this.set('value', value);
            } else {
                this.set('value', null);
            }
            return this.sendAction('bubbleChange', option);
        },
        hasOption: function () {
            var empty, i, len, option, options, val;
            empty = false;
            options = this.get('_options');
            if (options.length === 0) {
                empty = true;
            }
            for (i = 0, len = options.length; i < len; i++) {
                option = options[i];
                val = option.id ? option.name : option;
                if (this.get('value') === !val) {
                    empty = true;
                } else {
                    this.sendAction('bubbleChange', option);
                }
            }
            if (empty) {
                return this.set('value', null);
            }
        },
    },
});

export default DropDownSearchComponent;
