import { inject as service } from '@ember/service';
import EmberObject, { get } from '@ember/object';

export default EmberObject.extend({
    capi: service(),

    find() {
        return get(this, 'capi')
            .request('/settings/gui/menu', {
                dataType: 'json',
            })
            .then(() => this._data());
    },
    _data(data) {
        return data;
    },
});
