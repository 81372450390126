import Controller from '@ember/controller';
import EmberObject, { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

// eslint-disable-next-line ember/alias-model-in-controller
export default Controller.extend({
    session: service(),
    flags: service(),
    notifications: service(),
    overrideFlags: null,
    clearConsole: true,
    flagFilter: '',

    init() {
        this._super(...arguments);
        if (localStorage.APP) {
            set(this, 'app_override', localStorage.APP);
        }

        if (sessionStorage.overrideFlags) {
            set(
                this,
                'overrideFlags',
                EmberObject.create(JSON.parse(sessionStorage.overrideFlags)),
            );
        } else {
            set(this, 'overrideFlags', EmberObject.create());
        }
    },

    href: computed(function () {
        console.log(this.get('session.session')); //eslint-disable-line
        return (
            'localhost:4200/#/token/' +
            get(this, 'session.session.content.authenticated.refresh_token')
        );
    }),

    currentFlags: computed('flagFilter', 'overrideFlags.version', function () {
        const overrides = get(this, 'overrideFlags') || {};
        let isDirty = false;
        let allFlags = Object.entries(get(this, 'flags').allFlags())
            .map((...args) => {
                let [key, value] = Array.from(args[0]);
                let isOverriden = false;
                if (overrides[key] !== undefined) {
                    let oldval = value;
                    value = overrides[key];
                    if (oldval !== value) {
                        isOverriden = true;
                        isDirty = true;
                    }
                }

                return {
                    key,
                    value,
                    isOverriden,
                };
            })
            .filter((item) => typeof item.value === 'boolean')
            .filter((item) => {
                if (this.flagFilter === '') {
                    return true;
                }
                return new RegExp(this.flagFilter).test(item.key);
            });

        return {
            allFlags,
            isDirty,
        };
    }),

    actions: {
        copyAccessToken() {
            navigator.clipboard.writeText(
                get(this, 'session.session.content.authenticated.access_token'),
            );
            this.notifications.success('access token copied to clipboard', { autoClear: true });
        },

        copyRefreshToken() {
            navigator.clipboard.writeText(
                get(this, 'session.session.content.authenticated.refresh_token'),
            );
            this.notifications.success('refresh token copied to clipboard', { autoClear: true });
        },

        overrideFlag(flag) {
            let overrideFlags = get(this, 'overrideFlags');

            // overrides[flag.key] = !flag.value;
            set(overrideFlags, flag.key, !flag.value);
            set(overrideFlags, 'version', Math.random());
            window.overrideFlags = overrideFlags;
            sessionStorage.setItem('overrideFlags', JSON.stringify(overrideFlags));
        },

        setAppOverride() {
            sessionStorage.setItem('APP', get(this, 'app_override'));
            return location.reload();
        },

        clearAppOverride() {
            sessionStorage.removeItem('APP');
            return location.reload();
        },

        sendToken() {
            return this.transitionToRoute('token', get(this, 'refreshToken'), {
                queryParams: { url: window.location.href },
            });
        },
        resetFlags() {
            set(this, 'overrideFlags', {});
            sessionStorage.removeItem('overrideFlags');
            delete window.overrideFlags;
        },
        showToasts() {
            this.notifications.success('This is a success toast', { autoClear: false });
            this.notifications.info('This is an info toast', { autoClear: false });
            this.notifications.warning('This is a warning toast', { autoClear: false });
            this.notifications.error('This is an error toast', { autoClear: false });
        },
    },
});
