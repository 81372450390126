/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/report/date/project/route.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryReportDateProjectRoute, get, service, set;

import Ember from 'ember';

import DS from 'ember-data';

({
    inject: { service },
    set,
    get,
} = Ember);

DiaryReportDateProjectRoute = Ember.Route.extend({
    Collector: service('collector-service'),
    store: service(),
    session: service(),
    model: function (params) {
        var parentParams;
        parentParams = this.paramsFor('diary.report.date');
        return this.get('store')
            .findRecord('project', params.project)
            .then((project) => {
                var date;
                date =
                    moment(parentParams.date).subtract(7, 'days').format('YYYY-MM-DD') +
                    '_' +
                    parentParams.date;
                return this.get('store')
                    .query('diary', {
                        date: date,
                        project: params.project,
                        sideload: true,
                        order: 'date-',
                    })
                    .then((rows) => {
                        var rowsForDate;
                        rowsForDate = rows.filterBy('date', parentParams.date);
                        if (rowsForDate.get('length') > 0) {
                            return rowsForDate.get('firstObject');
                        } else {
                            return this.getWorkdayStrength(parentParams.date, params.project).then(
                                (strength) => {
                                    var data;
                                    data = strength || {};
                                    if (rows.get('length') > 0) {
                                        // copy only these to fields .. nothing else is needed
                                        data.deviations = rows.get('firstObject').deviations;
                                        data.works = rows.get('firstObject').works;
                                    }
                                    Object.assign(data, {
                                        date: parentParams.date,
                                        project: project,
                                    });
                                    delete data.event;
                                    return this.get('store').createRecord('diary', data);
                                },
                            );
                        }
                    });
            });
    },
    afterModel: function (model) {
        var controller, e;
        controller = this.controllerFor('diary/report/date/project');
        controller.set('overrideWeather', false);
        if (model.get('isNew')) {
            controller.set(
                'weather',
                DS.PromiseObject.create({
                    promise: controller.fetchWeatherPosition(model),
                }),
            );
            return controller.set('editMode', false);
        } else {
            try {
                controller.set('weather', JSON.parse(model.get('weather')));
            } catch (error) {
                e = error;
                controller.set('weather', null);
            }
            return controller.set('editMode', true);
        }
    },
    resetController: function (controller, isExiting) {
        if (isExiting) {
            return controller.set('ownWeather', {});
        }
    },
    getWorkdayStrength: function (date, project) {
        return new Ember.RSVP.Promise((resolve) => {
            var c, presencePromise, store, worktimePromise;
            c = this.get('Collector');
            store = this.get('store');
            worktimePromise = Ember.A([]);
            presencePromise = Ember.A([]);
            if (c.formExists('worktime')) {
                worktimePromise = store.query('worktime', {
                    date: date,
                    project: project,
                    limit: 'all',
                    sideload: ['user.employer', 'user.userlevel', 'employer.name'],
                });
            }
            if (c.formExists('presence')) {
                presencePromise = store.query('presence', {
                    date: date,
                    project: project,
                    limit: 'all',
                    sideload: ['user.employer', 'user.userlevel', 'employer.name'],
                });
            }
            return Ember.RSVP.Promise.all([worktimePromise, presencePromise]).then((data) => {
                var employer,
                    foremen,
                    foremenStrength,
                    maincontractorId,
                    own,
                    rows,
                    subStrength,
                    workerStrength;
                rows = data[0].toArray().concat(data[1].toArray()).uniqBy('user');
                employer = this.get('session.currentUser.employer.id');
                if (Ember.isPresent(employer)) {
                    if (c.testNeeds(['project.maincontractor'])) {
                        maincontractorId = store
                            .peekRecord('project', project)
                            .get('maincontractor.id');
                        own = rows.filterBy('employer.id', maincontractorId);
                    } else {
                        own = rows.filterBy('employer.id', employer);
                    }
                } else {
                    own = rows;
                }
                foremen = own.filter((item) => {
                    return [3, 5, 6].includes(parseInt(item.get('user.userlevel')));
                });
                subStrength = rows.get('length') - own.get('length');
                foremenStrength = foremen.get('length');
                workerStrength = own.get('length') - foremenStrength;
                return resolve({
                    workforce_workers: workerStrength,
                    workforce_foremen: foremenStrength,
                    workforce_subcontractor: subStrength,
                });
            });
        });
    },
});

export default DiaryReportDateProjectRoute;
