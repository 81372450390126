import { inject as service } from '@ember/service';
import recordComp from '../../record-comp/component';
import { copy } from 'ember-copy';

const CollectorAddModalAdmintoolComponent = recordComp.extend({
    store: service(),
    intl: service(),

    layoutName: 'collector/record-comp',

    returnField(field) {
        if (
            field.type === 'database' &&
            this.form === 'timecleandevice' &&
            field.name === 'customer'
        ) {
            const features = copy(field.features);
            features.hide = true;
            field.features = features;
        }
        return field;
    },
});

export default CollectorAddModalAdmintoolComponent;
