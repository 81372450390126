define("tt4/injections/services/sessionhelper", ["exports", "app/services/sessionhelper"], function (_exports, _sessionhelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sessionhelper.default;
    }
  });
});
