/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/confirm/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UserLinkConfirmController;

import Ember from 'ember';

UserLinkConfirmController = Ember.Controller.extend({
    session: Ember.inject.service(),
    registerUser: Em.inject.controller('register.user'),
});

export default UserLinkConfirmController;
