import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';

export default Controller.extend({
    init() {
        this._super();
        this.showColumns =
            'contract_month,contract_employer,contract_type,contract_number,contract_start_date,contract_end_date,contract_billed,contract_total_price,contract_flipped';
    },
    project: alias('model'),

    defaultValues: computed('project', function () {
        return { contract_project: get(this, 'project') };
    }),
});
