/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/multiselect-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsMultiselectComponent;

import Ember from 'ember';

import CollectorFieldsDropdownFieldComponent from '../database-field/component';

import $ from 'jquery';

CollectorFieldsMultiselectComponent = CollectorFieldsDropdownFieldComponent.extend({
    intl: Ember.inject.service(),
    selected_options: Em.A([]),
    selected_option_ids: Em.A([]),
    //showOptions: true

    // overwrite database-field fetchFromDatabase
    fetchFromDatabase: function () {
        return this.initilizeDropdown();
    },
    initilizeDropdown: Em.on(
        'init',
        Em.observer('session.currentUser', function () {
            var c, form, limit, object, promise, query, self;
            if (!this.get('session.currentUser')) {
                return;
            }
            self = this;
            c = this.get('Collector');
            form = this.get('field.form');
            object = this.get('field.name');
            query = this.get('query');
            limit = 25;
            promise = c.ajax({
                url: form + '/' + object,
                data: {
                    limit: limit,
                    q: query,
                },
            });
            return promise.then((data) => {
                var sel_ids;
                sel_ids = this.create_id_array(this.get('selected_options'));
                // remove all already selected options
                if (this.get('selected_options').length > 0) {
                    this.set('options', Em.A([]));
                    data[object].forEach(function (obj) {
                        if (sel_ids.indexOf(obj['id']) < 0) {
                            return self.get('options').pushObject(obj);
                        }
                    });
                } else {
                    this.set('options', data[object]);
                }
                return this.set('moreCount', data.count > limit ? data.count - limit : null);
            });
        }),
    ),
    unselected_options: Ember.computed('selected_options.[]', 'options.[]', function () {
        return this.get('options').reject((option) => {
            return this.get('selected_options').findBy('id', option.id);
        });
    }),
    // get all records and filter them
    setDefaultValue: Ember.on('init', function () {
        var c, field, form, ids, object, promise, self, value;
        self = this;
        field = this.get('field');
        if (field.value == null) {
            //empty selected options if value not set
            this.set('selected_options', []);
            return;
        }
        value = field.value;
        c = this.get('Collector');
        form = this.get('field.form');
        object = this.get('field.name');
        if (typeof value === 'string') {
            ids = value;
        } else {
            ids = value.join();
        }
        if (ids === '') {
            this.setValues([]);
            return;
        }
        promise = c.ajax({
            url: form + '/' + object,
            data: {
                id: ids,
            },
        });
        return promise.then((data) => {
            return this.setValues(data[object]);
        });
    }),
    setValues: function (values) {
        var text;
        this.set('selected_options', values);
        this.set('value', values);
        text = this.getText(values);
        this.set('text', text);
        return this.set('search', text);
    },
    getText: function (values) {
        if (values.length > 3) {
            return values.length + ' ' + this.get('intl').t('select.chosen');
        } else if (values.length > 0) {
            return values.mapBy('text').join(',');
        } else {
            return this.get('prompt');
        }
    },
    create_id_array: function (objects) {
        var tmp;
        tmp = Em.A([]);
        objects.forEach(function (obj) {
            return tmp.pushObject(obj['id']);
        });
        return tmp;
    },
    actions: {
        deselect: function (option) {
            this.get('selected_options').removeObject(option);
            this.get('options').pushObject(option);
            this.set(
                'value',
                this.get('selected_options').length + ' ' + this.get('intl').t('select.chosen'),
            );
            this.set('text', this.getText(this.get('selected_options')));
            //$('input', this.element).blur()
            return this.sendAction(
                'bubbleChange',
                this.create_id_array(this.get('selected_options')) || null,
                this.get('field.name'),
            );
        },
        select: function (option) {
            if (option === null) {
                this.set('selected_options', Em.A([]));
                option = {};
                this.set('showOptions', false);
                $('input', this.element).blur();
                this.set('text', this.get('prompt'));
                this.set('search', this.get('prompt'));
            } else {
                this.get('selected_options').pushObject(option);
                this.get('options').removeObject(option);
            }
            //$('input', this.element).blur()
            //if (@get('selected_options').length > 0)
            //    @set 'search', @get('selected_options').length+" "+@get('intl').t 'select.chosen'
            //else
            //    @set 'search', @get 'prompt'
            this.set('value', option.id);
            this.set('text', this.getText(this.get('selected_options')));
            //@set 'showOptions', false
            this.set('query', null);
            return this.sendAction(
                'bubbleChange',
                this.create_id_array(this.get('selected_options')) || null,
                this.get('field.name'),
            );
        },
        select_all: function () {
            var all_options;
            all_options = Em.A([]);
            all_options.pushObjects(this.get('selected_options'));
            all_options.pushObjects(this.get('options'));
            this.set('selected_options', all_options);
            this.set('options', Em.A([]));
            this.set(
                'text',
                this.get('selected_options').length + ' ' + this.get('intl').t('select.chosen'),
            );
            return this.sendAction(
                'bubbleChange',
                this.create_id_array(this.get('selected_options')) || null,
                this.get('field.name'),
            );
        },
    },
});

export default CollectorFieldsMultiselectComponent;
