/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsAddController,
    indexOf = [].indexOf;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

ProjectsAddController = GeneralAddController.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    userId: Ember.computed('session.currentUser.id', function () {
        return this.get('session.currentUser.id');
    }),
    customization: Ember.computed('model', function () {
        var self;
        self = this;
        return {
            number: {
                default: function () {
                    if (!self.get('model.isNew')) {
                        return;
                    }
                    return self.store
                        .query('project', {
                            sideload: true,
                            limit: 1,
                            order: 'number-',
                        })
                        .then((projects) => {
                            var e, number;
                            try {
                                number = parseInt(projects.get('firstObject.number'), 10);
                                if (!number) {
                                    number = 0;
                                }
                                return self.set('model.number', ++number);
                            } catch (error) {
                                e = error;
                            }
                        });
                },
            },
            // nothing to do..
            skv_number: {
                template: 'projects/add/skv_number',
            },
            phone: {
                template: 'projects/add/phone',
            },
            owner: {
                filterOptions: {
                    userlevel: '!1',
                },
            },
        };
    }),
    contact_person_name: Em.observer('model.contact_user', function () {
        if (this.get('model.contact_user') == null) {
            this.set('model.contact_person', null);
            this.set('model.email', null);
            this.set('model.phone', null);
            return;
        }
        //set contact person field
        if (!this.get('model.contact_user.email') && !this.get('model.contact_user.phone')) {
            return this.store
                .findRecord('user', this.get('model.contact_user.id'), {
                    reload: true,
                })
                .then(() => {
                    return this.setContactPersonInfo();
                });
        } else {
            return this.setContactPersonInfo();
        }
    }),
    setContactPersonInfo: function () {
        this.set(
            'model.contact_person',
            this.get('model.contact_user').get('lastname') +
                ' ' +
                this.get('model.contact_user').get('firstname'),
        );
        if (this.get('model.contact_user').get('email')) {
            this.set('model.email', this.get('model.contact_user').get('email'));
        } else {
            this.set('model.email', null);
        }
        if (this.get('model.contact_user').get('phone')) {
            this.set('model.phone', this.get('model.contact_user').get('phone'));
        } else {
            this.set('model.phone', null);
        }
        //if finnishbuilder-module, set also other contact information
        if (this.get('Collector').testNeeds(['modules.finnishbuilder'])) {
            this.set('model.proj_firstname', this.get('model.contact_user').get('firstname'));
            this.set('model.proj_lastname', this.get('model.contact_user').get('lastname'));
            if (this.get('model.contact_user').get('email')) {
                return this.set('model.proj_email', this.get('model.contact_user').get('email'));
            } else {
                return this.set('model.proj_email', null);
            }
        }
    },
    countryLists: Ember.inject.service(),
    setCountryCode: function () {
        var code, codes, codesByNumber, companyCountry, i, len, model, number, ref, userCountry;
        model = this.get('model');
        codes = this.get('countryLists.phoneList');
        codesByNumber = codes.mapBy('number');
        userCountry = this.get('session.currentUser.homecountry');
        companyCountry = this.get('session.currentUser.company_info.country');
        number = null;
        if (
            !model.get('phone') ||
            (((ref = model.get('phone')), indexOf.call(codesByNumber, ref) >= 0) &&
                !this.get('countryCodeSetManually'))
        ) {
            for (i = 0, len = codes.length; i < len; i++) {
                code = codes[i];
                if (code.id === userCountry) {
                    number = code.number;
                    break;
                } else if (code.id === companyCountry) {
                    number = code.number;
                }
            }
            if (number) {
                return model.set('phone', number);
            }
        }
    },
    actions: {
        generalAddAction: function (type, param) {
            if (type === 'changeCountry') {
                return this.changeCountry(param);
            }
            if (type === 'setCountryCode') {
                return this.setCountryCode();
            }
        },
    },
    changeCountry: function (countrycode) {
        this.set('countryCodeSetManually', true);
        return this.set('model.phone', countrycode);
    },
});

export default ProjectsAddController;
