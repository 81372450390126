/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/login/register.coffee.old
// Generated by CoffeeScript 2.6.1
var LoginRegisterRoute;

import Ember from 'ember';

import GeneralAddRoute from '../general-add';

LoginRegisterRoute = GeneralAddRoute.extend({
    controllerName: 'login/register',
    beforeModel: function () {},
    // overriding beforeModel so authenticatedMixin doesn't move as to /login
    objects: {
        firstname: {
            translation: 'register.firstname',
            type: 'textbox',
            features: {},
        },
        lastname: {
            translation: 'register.lastname',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        email: {
            translation: 'register.email',
            type: 'textbox',
            features: {
                mandatory: true,
                validate_check: ['email', 'remote:username'],
            },
        },
        password: {
            translation: 'register.password',
            type: 'password',
            features: {
                mandatory: true,
                validate_check: ['minLength:8'],
            },
        },
        password_confirm: {
            translation: 'register.password_confirmation',
            type: 'password',
            features: {
                mandatory: true,
                validate_check: ['minLength:8', 'match:password'],
            },
        },
        text1: {
            translation: 'register.company_name',
            type: 'textbox',
            features: {},
        },
        company: {
            translation: 'register.company_name',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        vatin: {
            translation: 'register.company_id',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
        phone: {
            translation: 'register.telephone',
            type: 'textbox',
            features: {
                mandatory: true,
            },
        },
    },
    saveAction: function (self, values) {
        var controller;
        controller = self.get('controller');
        return controller.createService(values);
    },
    actions: {
        cancel: function () {
            return this.transitionTo('index');
        },
        willTransition: function () {
            return true;
        },
    },
});

export default LoginRegisterRoute;
