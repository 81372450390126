/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorktimeEditController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import collector from '../../../classes/collector';

WorktimeEditController = GeneralAddController.extend({
    Collector: Ember.inject.service('collector-service'),
    flags: Ember.inject.service(),
    useFormAdd: Ember.computed(function () {
        return this.get('flags').test('form-add');
    }),
    useNewVersion: Ember.computed(function () {
        return this.get('Collector').testNeeds(['products.multitasking']) || this.get('useFormAdd');
    }),
    editNewLink: 'timetracker.edit',
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
    init: function () {
        var cust;
        this._super();
        cust = {};
        if (this.get('Collector').testNeedsOne('products.timetracker_km_automatic')) {
            cust.km = {
                template: 'timetracker/add/km_auto',
            };
            return this.set('customization', cust);
        }
    },
    actions: {
        goBack: () => {
            return window.history.back(-1);
        },
    },
});

export default WorktimeEditController;
