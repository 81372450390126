/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/my-sites/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var MySitesController,
    indexOf = [].indexOf;

import Ember from 'ember';

MySitesController = Ember.Controller.extend({
    queryParams: ['site', 'project'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    google: Ember.inject.service(),
    mySites: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    Collector: Em.inject.service('collector-service'),
    tools: Em.inject.service(),
    dialogs: Em.inject.service(),
    gps: Em.inject.service('gps'),
    wtService: Em.inject.service(),
    cdn: Em.inject.service(),
    error: Em.inject.service(),
    project: '',
    projects: Ember.computed(function () {
        var c;
        c = this.get('Collector');
        if (c.testNeedsOne('presence') && !c.testNeedsOne('worktime')) {
            return this.store.query('project', {
                sideload: true,
                presence_project: 'on',
            });
        } else {
            return this.store.query('project', {
                sideload: true,
            });
        }
    }),
    showAddSiteButton: Em.computed.gt('session.currentUser.services.linkings.length', 0),
    serviceList: Em.computed('session.currentUser.services.linkings', function () {
        return this.get('session.currentUser.services.linkings').map(function (item) {
            return {
                id: item.username,
                name: item.company + ' - ' + item.username,
                company: item.company,
                partnerid: item.partnerid,
            };
        });
    }),
    currentService: Em.computed.alias('session.currentUser.username'),
    selectProjectField: Em.computed(function () {
        var c, field;
        c = this.get('Collector');
        if (c.fieldExists('worktime', 'project')) {
            field = c.field('worktime', 'project');
        } else if (c.fieldExists('presence', 'project')) {
            field = c.field('presence', 'project');
        } else if (c.fieldExists('orientation', 'ori_site')) {
            field = c.field('orientation', 'ori_site');
        } else if (c.fieldExists('tr', 'project')) {
            field = c.field('tr', 'project');
        } else if (c.fieldExists('mvr', 'project')) {
            field = c.field('mvr', 'project');
        } else {
            return null;
        }
        return field;
    }),
    selectProject: Em.computed('selectProjectField', function () {
        var field;
        field = this.get('selectProjectField');
        if (field) {
            field.prompt = 'my-sites.add_site';
            field.skipForcedGPS = true;
        }
        return field;
    }),
    isMySitesStored: false,
    /*isMySitesStored_observer: Em.observer 'model.[]', ->
          self = @
          storageData = JSON.parse(localStorage.getItem(@get('localStorageId') ))
          if storageData
              @set 'isMySitesStored', true
          else
              @set 'isMySitesStored', false

          self.set "showGpsButton", false
          #check if projects has gps locations
          $.each @get("model"), (key, item) =>
              if item.get("location_map")
                  self.set "showGpsButton", true
   */
    showGpsButton: false,
    setNumberOfWorkersAtProjects: function (projects) {
        var params, promise;
        params = Em.Object.create({
            sideload: true,
            endtime: '(empty)',
        });
        promise = this.store.query('worktime', params);
        return promise.then(function (worktimes) {
            var tmp_dict;
            tmp_dict = [];
            projects.forEach(function (project) {
                return project.set('active_workers', 0);
            });
            worktimes.forEach(function (item) {
                var count;
                if (tmp_dict.hasOwnProperty(item.get('project.id'))) {
                    count = tmp_dict.get(item.get('project.id')) + 1;
                    return (tmp_dict[item.get('project.id')] = count);
                } else {
                    return (tmp_dict[item.get('project.id')] = 1);
                }
            });
            return tmp_dict.forEach(function (value, projectId) {
                return projects.forEach(function (project) {
                    if (project.get('id').toString() === projectId.toString()) {
                        return project.set('active_workers', value);
                    }
                });
            });
        });
    },
    project_ids_observer: Em.observer('model.[]', 'session.currentUser', function () {
        if (!this.get('session.currentUser')) {
            return;
        }
        if (this.get('model.length') === 0) {
            return;
        }
        return this.updateSites().then(() => {
            return this.saveMySitesToLocalStorage();
        });
    }),
    // If model contains sites that have not all the information fetch it now
    updateSites: function () {
        var store;
        store = this.get('store');
        return new Em.RSVP.Promise((resolve, reject) => {
            var promises, sites;
            sites = this.get('model');
            promises = [];
            sites.forEach((site) => {
                var promise;
                if (!site.partnerid || site.partnerid === 'NaN') {
                    site.partnerid = this.get('session.currentUser.partnerid');
                }
                if ('name' in site) {
                    return;
                }
                promise = store.findRecord('project', site.id);
                promises.push(promise);
                return promise.then(
                    (project) => {
                        return this.get('mySites').getMySiteObject(project, site);
                    },
                    (reason) => {},
                );
            });
            // this should never happen
            if (promises.length === 0) {
                return resolve();
            } else {
                return Em.RSVP.Promise.all(promises).then(() => {
                    return resolve();
                });
            }
        });
    },
    /*
  loadMySitesFromLocalStorage: Ember.on 'init', Ember.observer 'session.currentUser','refresh', ->
      return if not @get 'session.currentUser'
      mySites = @get('mySites').loadFromLocalStorage()

      if @get('site')
          @setCurrentMySite {id: @get('site')}

      if mySites.length < 1
          @loadEverythingToMySites()
      else
          @addOwnedProjectsToMySites()

  */
    addOwnedProjectsToMySites: function () {
        var c, mySites, promise, userId;
        if (!this.get('session.currentUser')) {
            return;
        }
        c = this.get('Collector');
        if (!c.fieldExists('project', 'owner')) {
            return;
        }
        mySites = this.get('model');
        if (!mySites) {
            return;
        }
        userId = this.get('session.currentUser.id');
        promise = this.store.query('project', {
            owner: userId,
            sideload: true,
        });
        return promise.then((projects) => {
            var add_these;
            add_these = projects.reject((project) => {
                return mySites.findBy('id', project.id);
            });
            if (add_these.length > 0) {
                add_these.forEach((project) => {
                    return mySites.pushObject(this.get('mySites').getMySiteObject(project));
                });
                return this.saveMySitesToLocalStorage();
            }
        });
    },
    loadEverythingToMySites: function () {
        var promise, userId;
        promise = this.get('projects');
        userId = this.get('session.currentUser.id');
        return promise.then((projects) => {
            var mySites, tmp_projects;
            tmp_projects = projects.filter((record) => {
                if (record.get('owner')) {
                    return record.get('owner').get('id') === userId;
                } else {
                    return false;
                }
            });
            if (tmp_projects.length > 0) {
                projects = tmp_projects;
            }
            mySites = projects.slice(0, 100).map((record) => {
                return this.get('mySites').getMySiteObject(record);
            });
            return this.set('model', Em.A(mySites));
        });
    },
    saveMySitesToLocalStorage: function () {
        return this.get('mySites').saveToLocalStorage(
            this.get('model').sortBy('used').reverse().slice(0, 100),
        );
    },
    changeService: function (site) {
        var partnerid, ret, service;
        Em.assert(site.partnerid, 'mySite partnerid not set');
        partnerid = site.partnerid;
        service = Em.A(this.get('session.currentUser.services.linkings')).findBy(
            'partnerid',
            '' + partnerid,
        );
        ret = confirm(this.get('intl').t('mysites.change_service'));
        if (ret) {
            return this.get('tools').change_service(service.username, '#/my-sites?site=' + site.id);
        }
    },
    setCurrentMySite: function (site) {
        return this.get('wtService')
            .unlessOpenWt(site)
            .then(
                () => {
                    return this.get('store')
                        .findRecord('project', site.id)
                        .then(
                            (siteRecord) => {
                                var modelsite;
                                if (siteRecord.get('row_info.status') === 'removed') {
                                    this.get('model').removeObject(
                                        this.get('model').findBy('id', site.id),
                                    );
                                    this.saveMySitesToLocalStorage();
                                    return;
                                }
                                modelsite = this.get('model').findBy('id', site.id);
                                this.get('mySites').getMySiteObject(siteRecord, modelsite);
                                this.saveMySitesToLocalStorage();
                                this.get('localStorageWrapper').setUserSetting(
                                    'currentMySite',
                                    parseInt(site.id, 10),
                                );
                                this.set('session.currentUser.currentMySite', siteRecord);
                                return this.doTransition(siteRecord);
                            },
                            (reason) => {
                                this.get('model').removeObject(
                                    this.get('model').findBy('id', site.id),
                                );
                                return this.saveMySitesToLocalStorage();
                            },
                        );
                },
                () => {
                    this.get('wtService').showOpenWtError();
                    return this.set('saving', false);
                },
            );
    },
    doTransition: function (record) {
        if (
            record.get('presence_project') &&
            this.get('Collector').testNeeds(['presence@add', 'userfieldcheck.id06kort'])
        ) {
            return this.transitionToRoute('presences.add');
        } else if (
            this.get('Collector').testNeeds([
                'worktime',
                'products.timetracker_clockcard||worktime.starttime@disabled||products.timetracker_hybrid',
                'modulecontrol.worktimes',
            ])
        ) {
            if (this.get('Collector').testNeedsOne('flag.clockcard-25')) {
                return this.transitionToRoute({ queryParams: { modal: 'timecard' } });
            }
            return this.transitionToRoute('timetracker.timecard.add');
        } else if (
            this.get('Collector').testNeeds([
                'worktime@add',
                '!worktime.starttime@disabled',
                '!products.timetracker_hybrid',
                'modulecontrol.worktimes',
            ])
        ) {
            return this.transitionToRoute('timetracker.add');
        } else if (this.get('Collector').testNeeds(['orientation', 'orientation@add'])) {
            return this.transitionToRoute('orientations.add');
        } else if (this.get('Collector').testNeeds(['tr', 'tr@add'])) {
            return this.transitionToRoute('site_safety.measurement.add', 'add', 'tr');
        } else if (this.get('Collector').testNeeds(['mvr', 'mvr@add'])) {
            return this.transitionToRoute('site_safety.measurement.add', 'add', 'mvr');
        } else {
            return this.get('dialogs').alert(
                this.get('intl').t('mysites.no_rights_for_this_project'),
            );
        }
    },
    filteredSites: Em.computed(
        'model.@each.partnerid',
        'model',
        'model.[]',
        'session.currentUser.services',
        function () {
            var partnerids, services, sites;
            if (!this.get('session.currentUser.services')) {
                return Em.A();
            }
            services = this.get('session.currentUser.services');
            partnerids = Em.A(services.linkings).map(function (item) {
                return parseInt(item.partnerid, 10);
            });
            partnerids.push(services.partnerid);
            sites = this.get('model').filter(function (item) {
                var ref;
                return (ref = item.partnerid), indexOf.call(partnerids, ref) >= 0;
            });
            return sites.sortBy('used').reverse();
        },
    ),
    currentlySelectedProjectForAddingMysite: null,
    actions: {
        openOverview: function (site) {
            var c;
            c = this.get('Collector');
            if (this.get('session.currentUser.userlevel') === '3' || c.testNeeds(['project@add'])) {
                return this.get('store')
                    .findRecord('project', site.id)
                    .then((siteRecord) => {
                        this.set('session.currentUser.currentMySite', siteRecord);
                        this.transitionToRoute('projects.edit.overview', site.id);
                    });
            } else {
                return this.set('projectinfo', site.id);
            }
        },
        openFilesview: function (site) {
            var c;
            c = this.get('Collector');
            if (c.testNeeds(['project@add'])) {
                return this.transitionToRoute('projects.edit.project-files', site.id);
            } else {
                return this.set('projectfiles', site.id);
            }
        },
        removeProjectModal: function () {
            return this.set('projectinfo', '');
        },
        removeFilesModal: function () {
            return this.set('projectfiles', '');
        },
        openAddSiteModalOn: function () {
            this.set('showAddSiteModal', true);
            return false;
        },
        removeModal: function () {
            return this.set('showAddSiteModal', false);
        },
        addNewSiteFromMySites: function (project) {
            var mySitesProjects;
            if (!project) {
                return;
            }
            mySitesProjects = this.get('model');
            if (!mySitesProjects.findBy('id', project.get('id'))) {
                return this.get('store')
                    .query('project', {
                        id: project.get('id'),
                        sideload: true,
                    })
                    .then((found_projects) => {
                        var found_project;
                        found_project = found_projects.get('firstObject');
                        return mySitesProjects.pushObject(
                            this.get('mySites').getMySiteObject(found_project),
                        );
                    });
            }
        },
        removeSiteFromMySites: function (projectId) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('mysites.confirm_remove'))
                .then((con) => {
                    if (con) {
                        this.get('model').removeObject(projectId);
                        if (this.get('model.length') === 0) {
                            return this.get('mySites').removeAllSites();
                        } else {
                            return this.saveMySitesToLocalStorage();
                        }
                    }
                });
        },
        projectClicked: function (site) {
            if (site.partnerid !== this.get('session.currentUser.services.partnerid')) {
                return this.changeService(site);
            } else {
                return this.setCurrentMySite(site);
            }
        },
        imageUpdated: function (site_id) {
            var promise, site, sites, store;
            store = this.get('store');
            sites = this.get('model');
            site = sites.findBy('id', site_id);
            promise = store.findRecord('project', site_id);
            return promise.then(
                (project) => {
                    return this.get('mySites').getMySiteObject(project, site);
                },
                (reason) => {},
            );
        },
        //clearMySites: () ->
        //    @set 'mySitesProjectIds', Em.A []
        projectSelected: function (project) {
            this.set('currentlySelectedProjectForAddingMysite', project);
            this.set('showAddSiteNowButton', project ? true : false);
            return false;
        },
        addSiteNow: function (service, project) {
            var promise;
            promise = this.get('Collector').ttapi({
                url: 'tunnel/findRow/project/' + project,
                data: {
                    username: service,
                },
            });
            promise.then((data) => {
                var found, mySitesProjects, mysites, newsite;
                mysites = this.get('mySites');
                newsite = mysites.getMySiteObject(Em.Object.create(data.project));
                service = this.get('serviceList').findBy('id', service);
                Em.set(newsite, 'partnerid', parseInt(service.partnerid, 10));
                Em.set(newsite, 'company', service.company);
                //mysites.addSiteObject newsite
                mySitesProjects = this.get('model');
                found = mySitesProjects.find(function (item) {
                    return parseInt(item.id, 10) === newsite.id;
                });
                if (!found) {
                    return mySitesProjects.pushObject(newsite);
                }
            });
            return this.set('showAddSiteModal', false);
        },
        addMySite: function (project) {
            if (project) {
                return this.send('addNewSiteFromMySites', project);
            }
        },
        openGpsMap: function () {
            var locs, rows, self;
            self = this;
            if (!this.get('showGpsMap')) {
                locs = [];
                rows = self.get('model');
                $.each(this.get('model'), (key, item) => {
                    var loc;
                    loc = {};
                    loc.project = JSON.parse(item.get('location_map'));
                    return locs.push(loc);
                });
                this.set('mapMarkers', locs);
                return this.set('showGpsMap', true);
            } else {
                return self.set('showGpsMap', false);
            }
        },
        updateMySitesPicture: function (record, mysite) {
            this.get('mySites').getMySiteObject(record, mysite);
            return this.set('edit_id', null);
        },
        toggleEditMode: function (site) {
            var edit_id;
            edit_id = this.get('edit_id');
            if (edit_id === site.id) {
                return this.set('edit_id', null);
            } else {
                return this.set('edit_id', site.id);
            }
        },
        fetchNearest: function () {
            var project_promise, self;
            self = this;
            this.set('project_fetching', true);
            project_promise = this.get('store').findAll('project', {
                reload: true,
            });
            return project_promise.then((projects) => {
                var options;
                options = {
                    enableHighAccuracy: true,
                    maximumAge: 5000,
                };
                if (navigator.geolocation) {
                    return navigator.geolocation.getCurrentPosition(
                        function (location) {
                            var add_project,
                                dialog_text,
                                nearProject,
                                shortestDist,
                                shortestProject;
                            shortestProject = null;
                            nearProject = null;
                            shortestDist = null;
                            projects.forEach(function (project) {
                                var distance, pres_latlng, proj_latlng, proj_loc;
                                if (project.get('location_map')) {
                                    proj_loc = JSON.parse(project.get('location_map'));
                                    if (proj_loc) {
                                        proj_latlng = new window.google.maps.LatLng(
                                            proj_loc.latitude,
                                            proj_loc.longitude,
                                        );
                                        pres_latlng = new window.google.maps.LatLng(
                                            location.coords.latitude,
                                            location.coords.longitude,
                                        );
                                        distance =
                                            window.google.maps.geometry.spherical.computeDistanceBetween(
                                                proj_latlng,
                                                pres_latlng,
                                            );
                                        if (shortestProject === null || shortestDist > distance) {
                                            shortestProject = project;
                                            return (shortestDist = distance);
                                        }
                                    }
                                }
                            });
                            self.set('project_fetching', false);
                            add_project = false;
                            if (shortestProject) {
                                //find if project already in mysites list
                                if (self.get('filteredSites').length > 0) {
                                    if (
                                        !self
                                            .get('filteredSites')
                                            .findBy('id', shortestProject.get('id'))
                                    ) {
                                        add_project = true;
                                    } else {
                                        add_project = false;
                                    }
                                } else {
                                    add_project = true;
                                }
                                if (add_project) {
                                    dialog_text = self
                                        .get('intl')
                                        .t('my-sites.add_project_and_continue', {
                                            project_name: shortestProject.get('name'),
                                        });
                                    return self
                                        .get('dialogs')
                                        .confirm(dialog_text)
                                        .then((con) => {
                                            if (con) {
                                                self.send('addNewSiteFromMySites', shortestProject);
                                                return self.setCurrentMySite(shortestProject);
                                            }
                                        });
                                } else {
                                    dialog_text = self
                                        .get('intl')
                                        .t('my-sites.continue_to_project', {
                                            project_name: shortestProject.get('name'),
                                        });
                                    return self
                                        .get('dialogs')
                                        .confirm(dialog_text)
                                        .then((con) => {
                                            if (con) {
                                                return self.setCurrentMySite(shortestProject);
                                            }
                                        });
                                }
                            } else {
                                return self
                                    .get('dialogs')
                                    .alert(
                                        self.get('intl').t('my-sites.no_shortest_project_found'),
                                    );
                            }
                        },
                        (reason) => {
                            return this.get('error').notify(reason);
                        },
                        options,
                    );
                }
            });
        },
        removeAll: function () {
            return this.get('dialogs')
                .confirm(this.get('intl').t('mysites.confirm_remove_all'))
                .then((con) => {
                    if (con) {
                        return this.get('mySites')
                            .removeAllSites()
                            .then(() => {
                                this.set('model', []);
                                return this.saveMySitesToLocalStorage();
                            });
                    }
                });
        },
    },
});

export default MySitesController;
