import EmberObject from '@ember/object';

export default EmberObject.extend({
    canChangeStatus(rows, status, field) {
        let rejected = [];
        let accepted = [];
        rows.forEach((row) => {
            if (this.notEditable(row)) {
                rejected.push(row);
            } else if (this.onlyOption(status, field)) {
                rejected.push(row);
            } else if (this.readOnly(field)) {
                rejected.push(row);
            } else {
                accepted.push(row);
            }
        });
        return { accepted: accepted, rejected: rejected };
    },

    notEditable(row) {
        if ('isEditable' in row && !row.isEditable) {
            return true;
        }
    },
    onlyOption(status, field) {
        if (field && field.features.only_options) {
            let only_options = field.features.only_options.split(',');
            if (!only_options.includes(status)) {
                return true;
            }
        }
    },
    readOnly(field) {
        if (field && field.features.readonly) {
            return true;
        }
    },
});
