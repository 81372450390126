import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action, set, notifyPropertyChange } from '@ember/object';
export default class ProjectCardListController extends Controller {
    @service ttapi;

    @alias('model') projectId;

    get projectUsers() {
        let params = {};

        params.project = this.projectId;
        params.limit = 'all';
        params.cols = 'taxnumber,employer';

        return this.ttapi.request('api/project/users', { data: params });
    }

    get taxnumbers() {
        return this.getTaxnumbers();
    }

    async getTaxnumbers() {
        const projectUsers = await this.projectUsers;
        if (projectUsers.count > 400 && !this.employer && !this.user)
            return set(this, 'showReport', false);
        else set(this, 'showReport', true);

        let users = projectUsers.users;
        if (this.employer)
            users = projectUsers.users.filterBy('employer', parseInt(this.employer.id));
        if (this.user) users = projectUsers.users.filterBy('taxnumber', this.user.taxnumber);

        const taxnumbers = users.mapBy('taxnumber');

        return taxnumbers.join(',') || 'not_found';
    }

    @action
    showCard(row) {
        set(this, 'cardRow', row);
        set(this, 'showModal', true);
    }

    @action
    setEmployer(employer) {
        set(this, 'employer', employer);
        notifyPropertyChange(this, 'taxnumbers');
    }

    @action
    setUser(user) {
        set(this, 'user', user);
        notifyPropertyChange(this, 'taxnumbers');
    }
}
