import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import Collector from 'tt4/injections/services/collector';

interface IHash {
    form: string;
    field: string;
}

export default class TranslateColumnHelper extends Helper {
    @service('addon/collector' as any) private collector: Collector;

    public compute([], hash: IHash) {
        return this.collector.getTranslationForColumn(hash.form, hash.field);
    }
}
