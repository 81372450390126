/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/rfiddevices/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsRfiddevicesController;

import Ember from 'ember';

ProjectsRfiddevicesController = Ember.Controller.extend({
    report: Ember.computed('filters.@each.value', function () {
        return Ember.Object.create({
            form: 'rfiddevice',
            query: this.get('filters').map(function (filter) {
                return {
                    name: filter.get('field'),
                    value: filter.get('value'),
                };
            }),
            refreshRows: true,
        });
    }),
    rowActions: [
        {
            action: 'rowHistory',
            icon: 'move move-Search',
        },
        {
            action: 'removeRow',
            icon: 'move move-Delete',
        },
        {
            action: 'remoteControl',
            icon: 'move move-Remote',
        },
        {
            action: 'editRow',
            icon: 'move move-Pencil',
        },
    ],
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                type: 'database',
                form: 'rfiddevice',
                name: 'project',
                singleselect: true,
            }),
            Em.Object.create({
                field: 'user',
                type: 'database',
                form: 'rfiddevice',
                name: 'project',
                singleselect: true,
            }),
        ]);
    }),
    actions: {
        showRemoteControl: function (onoff = true) {
            this.set('showEditor', onoff);
            return false;
        },
        editRow: function (row) {
            return this.store.findRecord('rfiddevice', row.id).then((record) => {
                record.on('didUpdate', () => {
                    this.get('report').toggleProperty('refreshRows');
                    return record.off('didUpdate');
                });
                return this.transitionToRoute({
                    queryParams: {
                        modal: ['rfiddevice', row.id],
                    },
                });
            });
        },
        addRow: function () {
            var record;
            record = this.store.createRecord('rfiddevice');
            record.set('date', moment().format('YYYY-MM-DD'));
            record.on('didCreate', () => {
                this.get('report').toggleProperty('refreshRows');
                return record.off('didCreate');
            });
            return this.transitionToRoute({
                queryParams: {
                    modal: ['rfiddevice', 'new'],
                },
            });
        },
        openEditor: function (row) {
            this.set('imei', row.get('imei'));
            return this.send('showRemoteControl', true);
        },
        filterChanged: function (value, field) {
            if (value == null) {
                return this.set(field, '');
            } else {
                return this.set(field, value.id);
            }
        },
    },
});

export default ProjectsRfiddevicesController;
