/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/list/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesListController;

import Ember from 'ember';

import config from '../../../config/environment';

import { htmlSafe } from '@ember/string';

import { sanitize } from 'dom-purify';

MessagesListController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    appCtrl: Ember.inject.controller('application'),
    addIndexController: Ember.inject.controller('messages/add/index'),
    columns: ['created', 'header', 'content', 'creator_name', 'read_status'],
    queryParams: ['page'],
    popupArr: Em.A([]),
    showRemoved: false,
    hideSort: true,
    offset: 0,
    limit: 25,
    page: 1,
    pages: Em.computed('model', function () {
        return Math.ceil(this.get('model.count') / this.get('limit'));
    }),
    data: Em.computed('model.messages.[]', 'popupArr.[]', function () {
        var columns, data, formatted_data;
        data = this.get('model.messages');
        if (!data) {
            return null;
        }
        columns = this.get('columns');
        formatted_data = Em.A([]);
        data.forEach((unformatted_row) => {
            var row;
            row = Ember.Object.create();
            if (unformatted_row.id) {
                row.id = unformatted_row.id;
            }
            //            if !unformatted_row.read
            //                row.class = "warning"

            //            if unformatted_row.row_info.status == "removed"
            //                row.class = "danger"
            if (unformatted_row.sticky === '1') {
                row.class = 'info';
            }
            $.each(columns, (key, column) => {
                var cell, userlevel;
                cell = sanitize(unformatted_row[column]);
                if (column === 'created') {
                    cell = moment(cell).format('L');
                }
                if (column === 'read_status') {
                    cell = unformatted_row.read_count + ' / ' + unformatted_row.receiver_count;
                    if (unformatted_row.read_count / unformatted_row.receiver_count === 1) {
                        cell = new htmlSafe(
                            '<span class="label-m label-m-approved" title="ok"><span class="glyphicon glyphicon-ok"></span></span>',
                        );
                    }
                    userlevel = this.get('session.currentUser.userlevel');
                    if (userlevel !== '5' && userlevel !== '6') {
                        if (
                            unformatted_row.read_count === '0' &&
                            unformatted_row.receiver_count === '1'
                        ) {
                            cell = new htmlSafe('');
                        }
                    }
                }
                if (cell && cell.length > 50) {
                    cell = cell.substring(0, 50) + '...';
                }
                return (row[column] = new htmlSafe(cell));
            });
            return formatted_data.pushObjects([row]);
        });
        return formatted_data;
    }),
    rowActions: Em.computed(function () {
        var arr, userlevel;
        userlevel = this.get('session.currentUser.userlevel');
        if (userlevel === '5') {
            return (arr = [
                {
                    action: 'copyRow',
                    icon: 'move move-CopyRow',
                    text: this.get('intl').t('timetracker.calendar.copy'),
                },
                {
                    action: 'removeRow',
                    icon: 'move move-Delete',
                    text: this.get('intl').t('report.delete_rows'),
                },
            ]);
        } else if (userlevel !== '1') {
            return (arr = [
                {
                    action: 'copyRow',
                    icon: 'move move-CopyRow',
                    text: this.get('intl').t('timetracker.calendar.copy'),
                },
            ]);
        }
    }),
    // get translation for every column
    translated_columns: Ember.computed('columns', function () {
        var c, columns, intl, translated_columns;
        c = this.get('Collector');
        intl = this.get('intl');
        columns = this.get('columns');
        translated_columns = [];
        $.each(columns, (key, item) => {
            return translated_columns.push(intl.t('report.messages.' + item));
        });
        return translated_columns;
    }),
    removeMessage: function (id) {
        return new Em.RSVP.Promise((resolve, reject) => {
            return this.get('ttapi')
                .request('/messages/' + id, {
                    type: 'DELETE',
                })
                .then((data) => {
                    return resolve(data);
                })
                .catch((reason) => {
                    var error_obj;
                    error_obj = {};
                    error_obj.responseText = reason.responseJSON.error;
                    error_obj.status = reason.status;
                    error_obj.statusText = reason.responseJSON.error_description;
                    Tt4.set('last_error', error_obj);
                    return reject(reason);
                });
        });
    },
    editMessage: function (id) {
        return new Em.RSVP.Promise((resolve, reject) => {
            return this.get('ttapi')
                .request('/messages/' + id, {
                    type: 'PUT',
                })
                .then((data) => {
                    return resolve(data);
                })
                .catch((reason) => {
                    var error_obj;
                    error_obj = {};
                    error_obj.status = reason.status;
                    Tt4.set('last_error', error_obj);
                    return reject(reason);
                });
        });
    },
    autoFillData: function (message) {
        var promise, ref, ref1, ref2;
        promise = this.get('Collector').ttapi({
            method: 'get',
            url: 'messages/receivers/' + message.id,
        });
        promise.then((data) => {
            var receivers;
            if (data.receivers) {
                receivers = data.receivers.mapBy('user');
                return this.store
                    .query('user', {
                        limit: 'all',
                        id: receivers.join(','),
                    })
                    .then((users) => {
                        return this.send('addUsersToList', users);
                    });
            }
        });
        this.set('addIndexController.header', message.header);
        this.set('addIndexController.message', message.content);
        this.set(
            'addIndexController.send_sms',
            (ref = message.sms_sent === '1') != null
                ? ref
                : {
                      true: false,
                  },
        );
        this.set(
            'addIndexController.send_email',
            (ref1 = message.email_sent === '1') != null
                ? ref1
                : {
                      true: false,
                  },
        );
        return this.set(
            'addIndexController.sticky_message',
            (ref2 = message.sticky === '1') != null
                ? ref2
                : {
                      true: false,
                  },
        );
    },
    actions: {
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        closeModal: function () {
            this.send('markAsRead');
            return this.set('currentMessage', null);
        },
        removeRow: function (params) {
            var self;
            self = this;
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return this.removeMessage(params.id).then(function () {
                            var obj;
                            obj = self.get('model.messages').findBy('id', params.id);
                            return self.get('model.messages').removeObject(obj);
                        });
                    }
                });
        },
        unstick: function (params) {
            var self;
            self = this;
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_unstick'))
                .then((con) => {
                    if (con) {
                        return this.editMessage(params.id).then(function () {
                            var obj;
                            obj = self.get('model.messages').findBy('id', params.id);
                            obj.sticky = '0';
                            self.set('currentMessage', null);
                            return self.send('refreshModel');
                        });
                    }
                });
        },
        addUsersToList: function (users) {
            return this.set('addIndexController.selected_users_arr', [].addObjects(users));
        },
        copyRow: function (params) {
            var messageData;
            this.transitionToRoute('/messages/add/message');
            messageData = this.get('model.messages').findBy('id', params.id);
            this.autoFillData(messageData);
        },
        showRow: function (msg) {
            var message, promise, userlevel;
            message = $.extend(true, {}, this.get('model.messages').findBy('id', msg.id)); //make deep copy so message can be re-opened without reloading site
            //find users who have read and who have not (admin only)
            userlevel = this.get('session.currentUser.userlevel');
            message.created = moment.utc(message.created).local().format('LLLL');
            if (
                userlevel === '5' ||
                (userlevel === '6' &&
                    message['creator_user'] === this.get('session.currentUser.id'))
            ) {
                promise = this.get('Collector').ttapi({
                    method: 'get',
                    url: 'messages/receivers/' + msg.id,
                });
                return promise.then((data) => {
                    var i, len, receivers, ref, row, userids, users_notread, users_read;
                    if (data.receivers) {
                        receivers = data.receivers;
                        userids = [];
                        ref = data.receivers;
                        for (i = 0, len = ref.length; i < len; i++) {
                            row = ref[i];
                            userids.push(row.user);
                        }
                        users_read = Em.A([]);
                        users_notread = Em.A([]);
                        return this.store
                            .query('user', {
                                id: userids.join(','),
                                order: 'lastname',
                                limit: 'all',
                            })
                            .then((users) => {
                                users.forEach((user) => {
                                    var receiver_row;
                                    receiver_row = receivers.findBy('user', user.id);
                                    user.set('readstatus', receiver_row.status);
                                    if (receiver_row.status === 'new') {
                                        return users_notread.pushObject(user);
                                    } else {
                                        return users_read.pushObject(user);
                                    }
                                });
                                message.users_read = users_read;
                                message.users_notread = users_notread;
                                return this.set('currentMessage', message);
                            });
                    }
                });
            } else {
                return this.set('currentMessage', message);
            }
        },
        //            @set "currentMessage", message
        markAsRead: function () {
            var promise;
            if (Em.isNone(this.get('currentMessage'))) {
                return;
            }
            //do not mark as read when impersonating
            if (this.get('appCtrl.impersonate')) {
                this.set('currentMessage', null);
                return;
            }
            //mark as read
            promise = this.get('Collector').ttapi({
                method: 'post',
                url: 'messages/markasread/' + this.get('currentMessage.id'),
            });
            return promise.then((back) => {
                //remove currentmessage
                this.set('currentMessage', null);
                return console.log('removed');
            });
        },
    },
});

export default MessagesListController;
