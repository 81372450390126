import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
    type: 'single',

    actions: {
        showAdd(value) {
            this.showAdd(value);
        },
        changeType(value) {
            set(this, 'type', value);
        },
        saved() {
            this.saved();
        },
    },
});
