/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/gps-forced-dropdown.coffee.old
// Generated by CoffeeScript 2.6.1
var GpsForcedDropdown;

import Ember from 'ember';

import collector from '../classes/collector';

GpsForcedDropdown = Ember.Component.extend({
    store: Ember.inject.service(),
    classes: 'form-control add-fields',
    actions: {
        changeValue: function (value) {
            return this.sendAction('change', value, 'project');
        },
    },
});

export default GpsForcedDropdown;
