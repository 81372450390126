/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/activitylog/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var DevelopmentActivitylogController;

import Ember from 'ember';

import { alias } from '@ember/object/computed';

DevelopmentActivitylogController = Ember.Controller.extend({
    content: alias('model'),
    queryParams: ['owner', '_creator', 'action'],
    userField: Ember.computed(function () {
        var c, field;
        c = this.get('Collector');
        if (c.fieldExists('worktime', 'user')) {
            field = c.field('worktime', 'user');
        } else if (c.fieldExists('presence', 'user')) {
            field = c.field('presence', 'user');
        } else if (c.fieldExists('orientation', 'ori_user')) {
            field = c.field('orientation', 'ori_user');
        } else if (c.fieldExists('tr', 'user')) {
            field = c.field('tr', 'user');
        } else if (c.fieldExists('mvr', 'user')) {
            field = c.field('mvr', 'user');
        } else {
            return null;
        }
        return field;
    }),
    translations: {
        findAll: 'findAll',
        insertRow: 'insertRow',
        updateRow: 'updateRow',
        rows: 'Rows',
        owners: 'Owners',
    },
    ownerField: Ember.computed(function () {
        return Object.assign({}, this.get('userField'));
    }),
    columns: Ember.computed(function () {
        return ['timestamp', '_creator', 'action', 'form', 'rowids', 'owners'];
    }),
    pageCount: Ember.computed('content.meta.count', function () {
        return Math.ceil(this.get('content.meta.count') / 25);
    }),
    historyModal: Ember.computed(function () {
        return ['worktime', this.get('model.id'), 'history'];
    }),
    actions: {
        changeOwner: function (owner) {
            this.set('owner', owner != null ? owner.get('id') : '');
            return false;
        },
        changeUser: function (_creator) {
            this.set('_creator', _creator != null ? _creator.get('id') : '');
            return false;
        },
        changeAction: function (action) {
            this.set('action', action);
            return false;
        },
    },
});

export default DevelopmentActivitylogController;
