/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/realtime/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerRealtimeRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

import collector from '../../../classes/collector';

TimetrackerRealtimeRoute = GeneralReportRoute.extend({
    form: 'worktime',
    queryParams: {
        page: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
            replace: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        params.sideload = true;
        params.endtime = '(empty)';
        params.date =
            moment().subtract(1, 'days').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD');
        if (!params.order) {
            params.order = 'date-,starttime-';
        }
        return this.store.query('worktime', params);
    },
});

export default TimetrackerRealtimeRoute;
