import Controller from '@ember/controller';
// import { Promise as EmberPromise } from 'rsvp';
// import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
// import ObjectProxy from '@ember/object/proxy';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';

export default Controller.extend({
    ttapi: service(),
    store: service(),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    eventCodesValue: [
        { key: 100, name: 'NFC key denied' },
        { key: 111, name: 'NFC key has expired' },
        { key: 205, name: 'Key not found' },
        { key: 204, name: 'Open authorized' },
    ],
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    eventCodeOptions: [
        { key: 100, name: 'NFC key denied' },
        { key: 101, name: 'NFC key accepted' },
        { key: 102, name: 'Programming started with programming NFC key' },
        { key: 103, name: 'Programming ended with programming NFC key' },
        {
            key: 104,
            name: 'Removal of all accepted keys from lock started with programming NFC key',
        },
        { key: 105, name: 'All accepted keys removed from lock with programming NFC key' },
        { key: 106, name: "NFC key added to lock's accepted keys" },
        { key: 107, name: "NFC key removed from lock's accepted keys" },
        { key: 108, name: 'Removal of all accepted keys pending' },
        { key: 109, name: 'NFC key error' },
        { key: 111, name: 'NFC key has expired' },
        { key: 200, name: 'Broken key' },
        { key: 201, name: 'Duplicate key' },
        { key: 202, name: 'Revoked key removed' },
        { key: 203, name: 'One-time key used' },
        { key: 204, name: 'Open authorized' },
        { key: 205, name: 'Key not found' },
        { key: 206, name: 'Master key authorized' },
        { key: 207, name: 'No capability' },
        { key: 208, name: 'Key mass erase started' },
        { key: 209, name: 'Key mass erase completed' },
        { key: 210, name: 'Key expired' },
        { key: 211, name: 'Key not yet valid' },
        { key: 212, name: 'Open denied' },
        { key: 213, name: 'Out of memory' },
        { key: 300, name: 'Wireless power enabled' },
        { key: 301, name: 'Wireless power disabled' },
        { key: 302, name: 'Door opened' },
        { key: 303, name: 'Door closed' },
        { key: 500, name: 'Network connected' },
        { key: 501, name: 'Telemetry enabled' },
        { key: 502, name: 'Topic subscribed' },
        { key: 503, name: 'Topic subscription failed' },
        { key: 504, name: 'Network error' },
        { key: 505, name: 'Telemetry disabled' },
        { key: 506, name: 'Unknown message received' },
        { key: 507, name: 'Message received' },
        { key: 508, name: 'Message sent' },
        { key: 509, name: 'Invalid message received' },
        { key: 510, name: 'Secure communication link established' },
        { key: 511, name: 'Secure communication link failed' },
        { key: 512, name: 'Time updated' },
        { key: 513, name: 'Telemetry message failed' },
        { key: 514, name: 'Firmware update available' },
        { key: 515, name: 'Firmware update in progress' },
        { key: 516, name: 'Firmware update completed' },
        { key: 517, name: 'Firmware update failed' },
        { key: 518, name: 'Remote open accepted' },
        { key: 519, name: 'Key handled' },
        { key: 520, name: 'Remote command received' },
        { key: 521, name: 'Remote command replied' },
        { key: 522, name: 'Remote open confirmed' },
        { key: 523, name: 'Remote open failed' },
        { key: 600, name: 'Message sent to cloud' },
        { key: 601, name: 'Message received from cloud' },
        { key: 602, name: 'Message sent to cloud' },
        { key: 603, name: 'Message received from cloud' },
        { key: 604, name: 'Message sent to cloud' },
        { key: 605, name: 'Message received from cloud' },
        { key: 606, name: 'Message sent to cloud' },
        { key: 607, name: 'Message received from cloud' },
        { key: 608, name: 'Message sent to cloud' },
        { key: 609, name: 'Message received from cloud' },
        { key: 610, name: 'Message sent to cloud' },
        { key: 611, name: 'Message received from cloud' },
        { key: 612, name: 'Message sent to cloud' },
        { key: 613, name: 'Message received from cloud' },
        { key: 614, name: 'Message sent to cloud' },
        { key: 615, name: 'Message received from cloud' },
        { key: 616, name: 'Message sent to cloud' },
        { key: 617, name: 'Message received from cloud' },
        { key: 618, name: 'Message sent to cloud' },
        { key: 619, name: 'Message received from cloud' },
        { key: 620, name: 'Message received from cloud' },
        { key: 621, name: 'Message received from cloud' },
        { key: 800, name: 'Firmware update requested' },
        { key: 900, name: 'Locked idle mode enabled' },
        { key: 901, name: 'Master mode enabled' },
        { key: 902, name: 'Idle day mode enabled' },
        { key: 903, name: 'Unlocked mode enabled' },
        { key: 904, name: 'Master mode disabled' },
        { key: 1100, name: 'Firmware update forced manually' },
        { key: 1200, name: 'Device reboot' },
        { key: 1201, name: 'Device ready' },
        { key: 1202, name: 'Forced device reboot' },
        { key: 1300, name: 'Door firmware update started' },
        { key: 1301, name: 'Door firmware update completed' },
        { key: 1302, name: 'Door open sequence sent' },
        { key: 1303, name: 'Door communication fault' },
        { key: 1304, name: 'Door communication fault' },
        { key: 1305, name: 'Door communication fault' },
        { key: 1306, name: 'Door communication fault' },
        { key: 1307, name: 'Door communication fault' },
        { key: 1308, name: 'Door communication fault' },
        { key: 1309, name: 'Door communication fault' },
        { key: 1310, name: 'Bolt idle' },
        { key: 1311, name: 'Bolt micro switch fault' },
        { key: 1312, name: 'Door open sequence completed' },
        { key: 1313, name: 'Waiting door open retry' },
        { key: 1314, name: 'Door open failed' },
        { key: 1400, name: 'Bluetooth boot completed' },
        { key: 1401, name: 'Bluetooth connected' },
        { key: 1402, name: 'Bluetooth disconnected' },
        { key: 1403, name: 'eKey accepted' },
        { key: 1404, name: 'eKey denied' },
        { key: 1405, name: 'eKey not yet valid' },
        { key: 1406, name: 'eKey has expired' },
        { key: 1407, name: 'One-time eKey used' },
        { key: 1408, name: 'Bluetooth data received' },
        { key: 1409, name: 'Bluetooth data requested' },
        { key: 1410, name: 'Bluetooth response sent' },
        { key: 1411, name: 'Bluetooth boot failed' },
        { key: 1500, name: 'Door locked' },
        { key: 1501, name: 'Door closed in day mode' },
        { key: 1502, name: 'Handle turned in day mode' },
        { key: 1503, name: 'Handle released in day mode' },
        { key: 1504, name: 'Door opened in day mode' },
        { key: 1505, name: 'Inside handle turned while door locked' },
        { key: 1506, name: 'Inside handle released while door locked' },
        { key: 1507, name: 'Locked door opened from inside' },
        { key: 1508, name: 'Unlocking door' },
        { key: 1509, name: 'Door unlocked' },
        { key: 1510, name: 'Door unlocking failed' },
        { key: 1511, name: 'Unlocked door opened' },
        { key: 1512, name: 'Unlocked door not opened' },
        { key: 1513, name: 'Locked door opened' },
        { key: 1514, name: 'Door left open' },
        { key: 1515, name: 'Door closed' },
        { key: 1516, name: 'Operational state response sent' },
        { key: 1517, name: 'Door locking failed' },
        { key: 50002, name: 'Remote open request sent' },
        { key: 50003, name: 'Remote open request attempt without permission' },
        { key: 50004, name: 'Remote command sent' },
        { key: 50005, name: 'Remote boot request sent' },
        { key: 50006, name: 'Remote boot request attempt without permission' },
        { key: 50007, name: 'Operational state request sent' },
        { key: 50008, name: 'Operational state request attempt without permission' },
    ],
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    columns: [
        { name: 'Timestamp', valuePath: 'eventTimestamp', width: 200 },
        { name: 'Lock serial', valuePath: 'lockSerial', width: 200 },
        { name: 'Tag UUID', valuePath: 'reference', width: 200 },
        { name: 'Event Code', valuePath: 'eventCode', width: 200 },
        { name: 'message', valuePath: 'message', width: 200 },
        { name: 'Log timestamp', valuePath: 'logTimestamp', width: 200 },
    ],
    notinuse: alias('model'),

    filters: computed('startFilter', 'endFilter', 'serials', 'eventCodesValue.[]', function () {
        let filters = new Object();
        filters.send = false;
        if (get(this, 'eventCodesValue'))
            filters.eventCodes = get(this, 'eventCodesValue').mapBy('key');
        if (get(this, 'serials')) {
            filters.serials = get(this, 'serials');
        }
        if (get(this, 'startFilter')) {
            filters.from = get(this, 'startFilter.target.value') + ':00';
        }
        if (get(this, 'endFilter')) {
            filters.to = get(this, 'endFilter.target.value') + ':00';
        }

        return filters;
    }),

    logs: computed('filters', async function () {
        let url = 'api/rollockEvents';
        let filters = get(this, 'filters');
        if (!filters.from || !filters.to || !filters.serials) return;
        if (filters) {
            for (const key in filters) {
                if (key == Object.keys(filters)[0]) {
                    url += '?' + key + '=' + filters[key];
                } else {
                    url += '&' + key + '=' + filters[key];
                }
            }
        }

        return await get(this, 'ttapi')._get(url);
    }),
    filteredLogs: computed('tagFilter', 'logs', async function () {
        if (isPresent(get(this, 'tagFilter.target.value'))) {
            return (await get(this, 'logs'))
                .filterBy('reference', get(this, 'tagFilter.target.value'))
                .reverse();
        }
        return (await get(this, 'logs')).reverse();
    }),
    actions: {
        async selectProject(value) {
            const rollocks = await get(this, 'store').query('rollock', {
                project: get(value, 'id'),
            });
            set(this, 'serials', rollocks.mapBy('serialnumber').join(','));
        },
    },
});
