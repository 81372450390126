/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/range-picker.coffee.old
// Generated by CoffeeScript 2.6.1
var RangePickerComponent;

import Ember from 'ember';

import { t } from 'ember-intl';

import $ from 'jquery';

RangePickerComponent = Ember.Component.extend({
    store: Em.inject.service(),
    classNames: [],
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: void 0,
    end: void 0,
    timePicker: false,
    format: moment.localeData().longDateFormat('L'),
    serverFormat: 'YYYY-MM-DD',
    rangeText: Ember.computed('start', 'end', 'value', function () {
        var end, format, serverFormat, split, start, value;
        format = this.get('format');
        serverFormat = this.get('serverFormat');
        start = this.get('start');
        end = this.get('end');
        if ((value = this.get('value'))) {
            split = value.split('_');
            return (
                moment(split[0], serverFormat).format(format) +
                this.get('separator') +
                moment(split[1], serverFormat).format(format)
            );
        } else if (!Ember.isEmpty(start) && !Ember.isEmpty(end)) {
            return (
                moment(start, serverFormat).format(format) +
                this.get('separator') +
                moment(end, serverFormat).format(format)
            );
        }
        return '';
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    buttonClasses: ['btn'],
    applyClass: null,
    cancelClass: null,
    limit: {
        days: 365,
    },
    ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
        ],
    },
    removeDropdownOnDestroy: false,
    cancelLabel: t('general.cancel'),
    applyAction: null,
    cancelAction: null,
    customRangeLabel: t('rangepicker.custom_range'),
    //Init the dropdown when the component is added to the DOM
    didInsertElement: function () {
        var d, endDate, momentEndDate, momentStartDate, self, splitted, startDate, value;
        self = this;
        value = this.get('value');
        if (value) {
            splitted = value.split('_');
            momentStartDate = moment(splitted[0], this.get('serverFormat'));
            momentEndDate = moment(splitted[1], this.get('serverFormat'));
        } else {
            momentStartDate = moment(this.get('start'), this.get('serverFormat'));
            momentEndDate = moment(this.get('end'), this.get('serverFormat'));
        }
        startDate = momentStartDate.isValid() ? momentStartDate : void 0;
        endDate = momentEndDate.isValid() ? momentEndDate : void 0;
        d = new $.Deferred();
        if (this.get('ranges')) {
            this.get('ranges').then(function (ranges) {
                return d.resolve(ranges);
            });
        } else {
            d.resolve();
        }
        return d.then((ranges) => {
            var options;
            options = {
                locale: {
                    cancelLabel: this.get('cancelLabel'),
                    format: this.get('format'),
                    customRangeLabel: this.get('customRangeLabel'),
                },
                autoUpdateInput: false,
                startDate: startDate,
                endDate: endDate,
                opens: this.get('opens'),
                autoApply: this.get('autoApply'),
                timePicker: this.get('timePicker'),
                buttonClasses: this.get('buttonClasses'),
                applyClass: this.get('applyClass'),
                cancelClass: this.get('cancelClass'),
                separator: this.get('separator'),
                singleDatePicker: this.get('singleDatePicker'),
                drops: this.get('drops'),
                linkedCalendars: false,
                dateLimit: this.get('limit'),
                alwaysShowCalendars: true,
            };
            if (ranges && !$.isEmptyObject(ranges)) {
                options.ranges = ranges;
            }
            $('.daterangepicker-input', this.element).daterangepicker(options);
            $('.daterangepicker-input', this.element).on(
                'show.daterangepicker',
                function (ev, picker) {
                    var serverFormat;
                    serverFormat = self.get('serverFormat');
                    startDate = moment(self.get('start'), serverFormat);
                    endDate = moment(self.get('end'), serverFormat);
                    value = self.get('value');
                    if (value) {
                        splitted = value.split('_');
                        picker.setStartDate(moment(splitted[0], serverFormat));
                        return picker.setEndDate(moment(splitted[1], serverFormat));
                    } else if (startDate.isValid() && endDate.isValid()) {
                        picker.setStartDate(startDate);
                        return picker.setEndDate(endDate);
                    }
                },
            );
            $('.daterangepicker-input', this.element).on(
                'apply.daterangepicker',
                function (ev, picker) {
                    var applyAction, end, start;
                    start = picker.startDate.format(self.get('serverFormat'));
                    end = picker.endDate.format(self.get('serverFormat'));
                    applyAction = self.get('applyAction');
                    if (applyAction) {
                        Ember.assert(
                            'applyAction for date-range-picker must be a function',
                            typeof applyAction === 'function',
                        );
                        return applyAction(start, end);
                    } else {
                        self.setProperties({ start, end });
                        return self.set('value', start + '_' + end);
                    }
                },
            );
            return $('.daterangepicker-input', this.element).on(
                'cancel.daterangepicker',
                function () {
                    var cancelAction;
                    cancelAction = self.get('cancelAction');
                    if (cancelAction) {
                        Ember.assert(
                            'cancelAction for date-range-picker must be a function',
                            typeof cancelAction === 'function',
                        );
                        return cancelAction();
                    } else {
                        self.set('start', self.get('start'));
                        return self.set('end', self.get('end'));
                    }
                },
            );
        });
    },
    //Remove the hidden dropdown when this component is destroyed
    willDestroy: function () {
        if (this.get('removeDropdownOnDestroy')) {
            return $('.daterangepicker').remove();
        }
    },
});

export default RangePickerComponent;
