import Component from '@ember/component';
import { computed, get, set, defineProperty } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import RSVP from 'rsvp';
import isDisabled from 'tt4/utils/isDisabled';

export default Component.extend({
    store: service(),
    gui: service(),
    intl: service(),
    localstorage: service(),

    isopen: false,

    form: alias('field.form'),
    subform: alias('field.options.form'),
    subformfields: alias('field.options.fields'),
    fieldName: alias('field.name'),

    placeholder: computed('field.prompt', 'prompt', function () {
        if (this.prompt) return this.prompt;
        if (!get(this, 'field.prompt')) return null;
        return get(this, 'intl').t(get(this, 'field.prompt'));
    }),

    options: computed('filter', function () {
        return new RSVP.Promise((resolve, reject) => {
            this._performSearch(
                null,
                (options) => {
                    // do not use lastused if selection are filtered by something or not that many selections in first place
                    if (get(this, 'filter') || get(options, 'length') < 20) return resolve(options);

                    const lastused = this.getLastUsed(options);
                    if (lastused) lastused.then(resolve, reject);
                    else resolve(options);
                },
                reject,
            );
        });
    }),

    value: computed('modelValue', 'fieldValue', '_value', 'field.value', function () {
        if (typeof this._value !== 'undefined') {
            return !this._value || typeof this._value === 'object'
                ? this._value
                : this.store.peekRecord(this.subform, this._value);
        }
        if (get(this, 'modelValue')) return get(this, 'modelValue');
        return get(this, 'fieldValue');
    }),

    init() {
        this._super(...arguments);
        // modelValue alias must be defined this way because field name is dynamic
        if (get(this, 'model'))
            defineProperty(this, 'modelValue', alias('model.' + get(this, 'fieldName')));
    },

    didReceiveAttrs() {
        if (isDisabled(get(this, 'field'), get(this, 'model'))) {
            set(this, '_disabled', true);
        }
    },

    actions: {
        search(term) {
            return new RSVP.Promise((resolve, reject) => {
                debounce(this, this._performSearch, term, resolve, reject, 600);
            });
        },

        changeId(id) {
            this.send('change', get(this, 'store').peekRecord(get(this, 'subform'), id));
        },

        change(value) {
            this.saveLastUsed(value);

            if (get(this, 'model')) set(this, 'model.' + get(this, 'fieldName'), value);
            else set(this, 'fieldValue', value);

            this.onchange(value, get(this, 'fieldName'));
        },

        createNew() {
            this.createNew();
        },

        showOrHideCreate() {
            return this.showCreate && this.Collector.testNeeds([this.subform + '@add']);
        },
    },

    saveLastUsed(record) {
        if (!record) return;
        let lastused = get(this, 'localstorage').getItem('lastused.' + get(this, 'subform'));
        if (!lastused) lastused = [];

        lastused.unshift(get(record, 'id'));
        get(this, 'localstorage').setItem(
            'lastused.' + get(this, 'subform'),
            Array.from(new Set(lastused)).slice(0, 5),
        );
    },

    getLastUsed(all) {
        const lastused = get(this, 'localstorage').getItem('lastused.' + get(this, 'subform'));

        if (!lastused) return false;

        return new RSVP.Promise((resolve, reject) => {
            get(this, 'store')
                .query(get(this, 'subform'), {
                    id: lastused.join(','),
                    form: get(this, 'form'),
                    field: get(this, 'fieldName'),
                })
                .then(this.createGroups.bind(this, resolve, all), reject);
        });
    },

    createGroups(resolve, all, records) {
        resolve([
            {
                groupName: get(this, 'intl').t('select.last_selections'),
                options: records.toArray(),
            },
            { groupName: get(this, 'intl').t('select.all'), options: all.toArray() },
        ]);
    },

    optionsUpdated(term, options) {
        if (this.isDestroyed) {
            return;
        }
        if (!term)
            set(this, 'useNormalDropdown', get(this, 'gui').isMobile() && options.length < 20);

        // reset value if not in options
        if (
            this.recordIsDirty() &&
            typeof get(this, 'value') !== 'undefined' &&
            get(this, 'filter') &&
            !options.includes(get(this, 'value'))
        ) {
            // we still need to check from backend if value really is ok and just not in 20 first options
            get(this, 'store')
                .query(get(this, 'subform'), this._createQueryParams(null, get(this, 'value.id')))
                .then(this.clearFieldIfResolvesEmpty.bind(this));
        } else {
            set(this, 'disabled', false);
        }
    },

    clearFieldIfResolvesEmpty(result) {
        if (result.length < 1) this.send('change', null);
        set(this, 'disabled', false);
    },

    /**
     * Create queryParams for options fetching from backend
     * @param {String} term Search term given by user
     * @param {String} id Parameter if you want to get only known record by id
     */
    _createQueryParams(term = null, id = null) {
        const params = {
            form: get(this, 'form'),
            field: get(this, 'fieldName'),
            limit: 100,
            offset: 0,
        };

        if (id) params.id = id;
        if (term) params.q = term;

        if (get(this, 'filter')) {
            for (const key of Object.keys(get(this, 'filter'))) {
                const filterValue = this.filter[key];
                if (filterValue) {
                    params[key] =
                        typeof filterValue === 'string' ? filterValue : get(this.filter[key], 'id');
                }
            }
        }

        return params;
    },

    _performSearch(term, resolve, reject) {
        if (get(this, 'filter') && !term) set(this, 'disabled', true);
        const promise = get(this, 'store').query(
            get(this, 'subform'),
            this._createQueryParams(term),
        );
        promise.then(this.optionsUpdated.bind(this, term), () => {});
        promise.then(resolve, reject);
    },

    recordIsDirty() {
        return !!this.model?.isNew || !!this.model?.hasDirtyAttributes;
    },
});
