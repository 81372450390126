/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/accident/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceAccidentController;

import Ember from 'ember';

PresenceAccidentController = Ember.Controller.extend({
    Collector: Em.inject.service('collector-service'),
    showTable: Ember.computed('filterValues', function () {
        var filterValues, project;
        filterValues = this.get('filterValues');
        if (!filterValues) {
            return false;
        }
        project = filterValues.findBy('name', 'project');
        return !Ember.isEmpty(project.value);
    }),
    path: 'presence',
    rows: 0,
    getRows: Em.observer('filterValues', function () {
        var c, project, promise, query, query_str_arr, url;
        url = this.get('path');
        query = this.get('filterValues');
        project = query.findBy('name', 'project');
        if (Ember.isEmpty(project.value)) {
            return;
        }
        if (query) {
            query_str_arr = [];
            $.each(query, (key, item) => {
                if (!item.value) {
                    return;
                }
                return query_str_arr.push(item.name + '=' + item.value);
            });
            if (query_str_arr.length === 0) {
                this.set('rows', 0);
            }
            url += '?' + query_str_arr.join('&');
        }
        url += '&limit=1&out=(empty)';
        c = this.get('Collector');
        promise = c.ttapi({
            url: url,
        });
        return promise.then((data) => {
            return this.set('rows', data.count);
        });
    }),
});

export default PresenceAccidentController;
