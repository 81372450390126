import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import resetScroll from 'tt4/mixins/reset-scroll';

export default Route.extend(resetScroll, {
    store: service(),
    model() {
        const form = get(this.modelFor('settings.forms.form'), 'form.external_type');
        return get(this, 'store').query('form-field', { row_info_removed: true, form: form });
    },
});
