/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/project-help.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectHelpComponent;

import Ember from 'ember';

ProjectHelpComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    helptext: null,
    projectidObs: Ember.observer('projectid', function () {
        return Ember.run.once(this, this.processProjectChange);
    }),
    processProjectChange: function () {
        var self, store;
        self = this;
        this.set('helptext', null);
        //get project info
        store = this.get('store');
        if (this.get('projectid')) {
            return store
                .findRecord('project', this.get('projectid'), {
                    reload: true,
                })
                .then(function (data) {
                    return self.set('helptext', data.get('help'));
                });
        } else {
            return self.set('helptext', null);
        }
    },
});

export default ProjectHelpComponent;
