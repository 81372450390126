/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/dropdown-country-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDropdownCountryFieldComponent;

import Ember from 'ember';

import base from '../base-field/component';

CollectorFieldsDropdownCountryFieldComponent = base.extend({
    countryLists: Ember.inject.service(),
    intl: Ember.inject.service(),
    prompt: Em.computed(function () {
        return this.get('intl').t('general.choose') + '...';
    }),
    options: Ember.computed.alias('countryLists.fullList'),
    _value: Ember.computed('field.value', function () {
        if (this.get('field.value')) {
            return this.get('field.value').toLowerCase();
        }
    }),
    actions: {
        bubbleChange: function (value) {
            return this.sendAction('bubbleChange', value, this.get('field.name'));
        },
    },
});

export default CollectorFieldsDropdownCountryFieldComponent;
