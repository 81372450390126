import GeneralReportController from '../../../controllers/general-report';
import { alias } from '@ember/object/computed';
import EmberObject, { computed, set } from '@ember/object';
import { typeOf } from '@ember/utils';
import { A } from '@ember/array';

const MachinesTransactionsHistoryController = GeneralReportController.extend({
    form: 'transaction',
    limit: 25,

    init() {
        this._super(...arguments);
        this.showColumns = ['project', 'tooltype', 'amount', 'price', 'starttime'];
        this.queryParams = ['project', 'tooltype', 'q'];
    },

    content: alias('model'),
    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            hideEdit: true,
        }),
        massedit: true,
        sortByColumn: 'project,starttime-',
    }),

    filters: computed(function () {
        return A([
            EmberObject.create({ field: 'project' }),
            EmberObject.create({ field: 'tooltype' }),
            EmberObject.create({ type: 'searchbox', bindto: 'q' }),
            EmberObject.create({ field: 'endtime', value: '!(empty)' }),
        ]);
    }),

    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) === 'instance') {
                return set(this, field, value.id);
            } else {
                return set(this, field, value);
            }
        },
    },
});

export default MachinesTransactionsHistoryController;
