import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),

    init() {
        this._super(...arguments);
        this.headers = ['date', 'absencetype', 'status'];
        this.form = 'absence';
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    },

    didInsertElement() {
        set(this, 'data', this.getAbsences());
    },

    didDestroyElement() {
        this._super(...arguments);
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    },

    onStoreEvent(params) {
        if (params.type === 'abcense') set(this, 'data', this.getAbsences());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'data', this.getAbsences());
    },

    async getAbsences() {
        if (!this.Collector.testNeedsOne('abcense')) return [];
        let absences = await this.store.query('abcense', {
            startdate_enddate:
                moment().format('YYYY-MM-DD') + '_' + moment().add(2, 'years').format('YYYY-MM-DD'),
            order: 'startdate,enddate',
            user: this.session.currentUser.id,
            sideload: ['abcensetype.name'],
        });
        let arr = [];

        absences.forEach((data) => {
            let enddate =
                data.startdate !== data.enddate ? ' - ' + moment(data.enddate).format('L') : '';
            arr.pushObject({
                date: moment(data.startdate).format('L') + enddate,
                absencetype: data.abcensetype.name,
                status: data.status,
            });
        });
        return arr;
    },
});
