import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("\n<li class=\"{{@disabledClass}}\" data-anchor=\"{{@dataAnchor}}\" >\n    <div role=\"button\" {{on 'click' this.actionTriggered}} disabled={{@disabled}}>{{yield}}</div>\n</li>", {"contents":"\n<li class=\"{{@disabledClass}}\" data-anchor=\"{{@dataAnchor}}\" >\n    <div role=\"button\" {{on 'click' this.actionTriggered}} disabled={{@disabled}}>{{yield}}</div>\n</li>","moduleName":"tt4/components/addon/form-report/more-row.hbs","parseOptions":{"srcName":"tt4/components/addon/form-report/more-row.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
/* istanbul ignore next: this should be tested in form-report acceptance test */
export default class FormReportMoreRowComponent extends Component {
    @action
    actionTriggered() {
        if (this.args.action) this.args.action();
    }
}
