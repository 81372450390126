/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/utils/format.coffee.old
// Generated by CoffeeScript 2.6.1
var FormatUtil;

import Ember from 'ember';

FormatUtil = Ember.Object.extend({
    hours: function (hours) {
        var minutes, negative;
        if (hours < 0) {
            hours = hours * -1;
            negative = true;
        } else {
            negative = false;
        }
        minutes = Math.round((hours - Math.floor(hours)) * 60);
        return (
            (negative ? '-' : '') +
            Math.floor(hours) +
            ':' +
            (minutes < 10 ? '0' : '') +
            minutes +
            'h'
        );
    },
    // check name if it has mileage or kilometrit or other
    // string that possibly should not be converted to hours eg. 12:32h
    shouldFormatHours: function (name) {
        if (!name) {
            return true;
        }
        return !/kilometrit|mileage|^km|kilometer/i.test(name);
    },
});

export default FormatUtil;
