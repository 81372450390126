/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/gps.coffee.old
// Generated by CoffeeScript 2.6.1
var Service;

import Ember from 'ember';

Service = Ember.Service.extend(Ember.Evented, {
    customerSettings: Ember.inject.service(),
    lockAccuracy: 200,
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    inUse: false,
    startIfGpsIsOn: function () {
        if (this.get('Collector').testNeeds(['modules.gps||modules.plgps||project.location_map'])) {
            return this.start();
        }
    },
    start: function () {
        if (this.get('inUse')) {
            return;
        }
        this.set('inUse', true);
        this.set('status', 'searching');
        this.set('lastError', null);
        this.set(
            'allowed_distance',
            this.get('customerSettings').checkSetting('gps_distance') || 700,
        );
        Ember.run.later(
            this,
            function () {
                if (!this.get('lockedPosition')) {
                    return this.setLastError('CUSTOMTIMEOUT');
                }
            },
            8000,
        );
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.showPosition.bind(this),
                this.positionError.bind(this),
            );
            return navigator.geolocation.watchPosition(
                this.showPosition.bind(this),
                this.positionError.bind(this),
                {
                    enableHighAccuracy: true,
                },
            );
        } else {
            return alert('Geolocation is not supported by this browser.');
        }
    },
    getCurrentPosition: function () {},
    getLockedPosition: Ember.computed('lockedPosition', function () {
        if (this.get('lockedLatitude') && this.get('lockedLongitude')) {
            return {
                latitude: this.get('lockedLatitude'),
                longitude: this.get('lockedLongitude'),
            };
        }
    }),
    searchingLocation: Ember.computed('status', function () {
        if (this.get('status') === 'searching') {
            return true;
        }
    }),
    debug: 'start searching gps',
    counter: 0,
    status: 'searching',
    lastError: null,
    currentLatitude: null,
    currentLongitude: null,
    currentAccuracy: null,
    lockedLatitude: null,
    lockedLongitude: null,
    lockedAccuracy: null,
    lockedPosition: Ember.computed('lockedLatitude', 'lockedLongitude', function () {
        return this.get('lockedLatitude') && this.get('lockedLongitude');
    }),
    resetPosition: function () {
        this.setProperties({
            currentLatitude: null,
            currentLongitude: null,
            lockedLatitude: null,
            lockedLongitude: null,
        });
        return this.trigger('lockedPositionChanged');
    },
    positionError: function (error) {
        this.resetPosition();
        if (error.code === error.PERMISSION_DENIED) {
            return this.setLastError('PERMISSION_DENIED');
        } else if (error.code === error.POSITION_UNAVAILABLE) {
            return this.setLastError('POSITION_UNAVAILABLE');
        } else if (error.code === error.TIMEOUT) {
            return this.setLastError('TIMEOUT');
        } else {
            return this.setLastError('UNKNOWN_ERROR');
        }
    },
    setLastError: function (error) {
        this.set('lastError', error);
        return this.set('status', 'error');
    },
    showErrorTooltip: function (message) {
        return this.set('errorTooltipText', message);
    },
    lastErrorHeader: Ember.computed('lastError', function () {
        return (
            'gps.errors.' +
            function () {
                switch (this.get('lastError')) {
                    case 'PERMISSION_DENIED':
                        return 'permissiondenied';
                    case 'TIMEOUT':
                    case 'CUSTOMTIMEOUT':
                        return 'timeout';
                    default:
                        return 'unknownerror';
                }
            }.call(this)
        );
    }),
    lastErrorMessage: Ember.computed('lastError', function () {
        return (
            'gps.errorhelpers.' +
            function () {
                switch (this.get('lastError')) {
                    case 'PERMISSION_DENIED':
                        return 'permissiondenied';
                    case 'TIMEOUT':
                    case 'CUSTOMTIMEOUT':
                        return 'timeout';
                    default:
                        return 'unknownerror';
                }
            }.call(this)
        );
    }),
    showPosition: function (position) {
        var counter;
        counter = this.get('counter');
        this.set('lastError', null);
        if (this.get('status') === 'error') {
            this.set('status', 'searching');
        }
        this.set('counter', counter + 1);
        this.set(
            'debug',
            '[' +
                position.coords.latitude +
                ',' +
                position.coords.longitude +
                '] - ' +
                position.coords.accuracy +
                ' #' +
                counter,
        );
        this.set('currentAccuracy', position.coords.accuracy);
        this.set('currentLongitude', position.coords.longitude);
        this.set('currentLatitude', position.coords.latitude);
        return this.tryToLockLocation(
            position.coords.accuracy,
            position.coords.latitude,
            position.coords.longitude,
        );
    },
    tryToLockLocation: function (accuracy, lat, lon) {
        if (!this.isMapsLoaded()) {
            Ember.run.later(
                this,
                () => {
                    return this.tryToLockLocation(accuracy, lat, lon);
                },
                3000,
            );
            return;
        }
        if (accuracy < this.get('lockAccuracy')) {
            if (this.checkLocationChange(lat, lon, 50)) {
                this.set('lockedLatitude', lat);
                this.set('lockedLongitude', lon);
                this.set('lockedAccuracy', this.get('currentAccuracy'));
                this.trigger('lockedPositionChanged');
            }
            return this.set('status', 'located');
        }
    },
    checkLocationChange: function (lat, lon, change) {
        var dist;
        if (!this.get('lockedLatitude') || !this.get('lockedLongitude')) {
            return true;
        }
        dist = this.getDistance(
            {
                lat: this.get('lockedLatitude'),
                lon: this.get('lockedLongitude'),
            },
            {
                lat: lat,
                lon: lon,
            },
        );
        return dist > change;
    },
    isMapsLoaded: function () {
        if (window.google && window.google.maps) {
            return true;
        } else {
            return false;
        }
    },
    getDistance: function (loc1, loc2) {
        var distance, gloc1, gloc2;
        gloc1 = new window.google.maps.LatLng(loc1.lat, loc1.lon);
        gloc2 = new window.google.maps.LatLng(loc2.lat, loc2.lon);
        distance = window.google.maps.geometry.spherical.computeDistanceBetween(gloc1, gloc2);
        return distance;
    },
    withinAcceptedDistance: function (loc1, loc2) {
        return this.getDistance(loc1, loc2) < this.get('allowed_distance');
    },
    haversine: function (...args) {
        var R, a, dLat, dLon, lat1, lat2, lon1, lon2, radians;
        R = 6372.8;
        radians = args.map(function (deg) {
            return (deg / 180.0) * Math.PI;
        });
        lat1 = radians[0];
        lon1 = radians[1];
        lat2 = radians[2];
        lon2 = radians[3];
        dLat = lat2 - lat1;
        dLon = lon2 - lon1;
        a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        return R * 2 * Math.asin(Math.sqrt(a));
    },
    iconClass: Ember.computed('searchingLocation', function () {
        if (this.get('searchingLocation')) {
            return 'searching';
        }
    }),
    GetLocationByAddress: function (address) {
        return new Em.RSVP.Promise(function (resolve, reject) {
            var geocoder;
            geocoder = new google.maps.Geocoder();
            //address = document.getElementById('txtAddress').value
            return geocoder.geocode(
                {
                    address: address,
                },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        return resolve({
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                        });
                    } else {
                        return resolve({
                            error: 'could not get address-info',
                        });
                    }
                },
            );
        });
    },
    GetLocationByLatLng: function (latlng) {
        return new Em.RSVP.Promise(function (resolve, reject) {
            var geocoder;
            geocoder = new google.maps.Geocoder();
            //address = document.getElementById('txtAddress').value
            return geocoder.geocode(
                {
                    location: latlng,
                },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        return resolve(results[0].formatted_address);
                    } else {
                        return resolve({
                            error: 'could not get address-info',
                        });
                    }
                },
            );
        });
    },
    GetFullObjectByAddress: function (address) {
        return new Em.RSVP.Promise(function (resolve, reject) {
            var geocoder;
            geocoder = new google.maps.Geocoder();
            //address = document.getElementById('txtAddress').value
            return geocoder.geocode(
                {
                    address: address,
                },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        return resolve(results[0]);
                    } else {
                        return reject();
                    }
                },
            );
        });
    },
});

export default Service;
