import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    analytics: service(),

    arrowRight: '⮞',
    arrowLeft: '⮜',

    hidePrevious: computed('currentPage', function () {
        return get(this, 'currentPage') <= 1;
    }),

    lastPage: computed('currentPage', 'pages', function () {
        return get(this, 'currentPage') === get(this, 'pages');
    }),

    actions: {
        next() {
            get(this, 'analytics').trackEvent({ category: 'Wizard', action: 'next' });
            get(this, 'pageChange')(get(this, 'currentPage') + 1);
        },

        previous() {
            get(this, 'analytics').trackEvent({ category: 'Wizard', action: 'previous' });
            get(this, 'pageChange')(get(this, 'currentPage') - 1);
        },

        done() {
            if (get(this, 'done')) get(this, 'done')();
            get(this, 'close')();
        },
        save() {
            this.save();
        },
        close() {
            get(this, 'close')();
        },
    },
});
