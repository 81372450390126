/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/review-page/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryReviewController;

import Ember from 'ember';

WorksiteDiaryReviewController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    addController: Ember.inject.controller('worksitediary.report.add'),
    review_options: Ember.computed(function () {
        var field;
        field = this.get('Collector').field('review_and_inspection', 'type');
        field = Ember.Object.create(field);
        field.get('options').forEach((item) => {
            return (item.name = this.get('intl').t('review_and_inspection.type.' + item.value));
        });
        return field.get('options');
    }),
    currentdate: Em.computed(function () {
        return moment().format('YYYY-MM-DD');
    }),
    datefield: Em.computed(function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('review_and_inspection', 'date');
        field.value = this.get('date');
        return field;
    }),
    initData: Ember.on('init', function () {
        var currentdate;
        currentdate = moment().format('YYYY-MM-DD');
        return this.set('date', currentdate);
    }),
    date: '',
    title: '',
    added_reviews_and_inspections: Ember.computed.alias(
        'addController.model.review_and_inspection',
    ),
    actions: {
        update_reviews: function (title) {
            return this.set('title', title);
        },
        update_date: function (date) {
            return this.set('date', date);
        },
        add_item: function () {
            var date, record;
            if (this.get('title') === '' && this.get('value') === void 0) {
                return;
            }
            record = this.get('store').createRecord('review_and_inspection');
            if (this.get('title') !== '') {
                record.set('title', this.get('title'));
            } else {
                record.set('title', this.get('value.string'));
            }
            date = this.get('date') || this.get('currentdate');
            record.set('date', date);
            this.set('record', record);
            this.get('added_reviews_and_inspections').pushObject(record);
            record.save();
            return this.set('value', '');
        },
        remove_item: function (record) {
            this.set('record', null);
            this.get('added_reviews_and_inspections').removeObject(record);
            return record.destroyRecord();
        },
    },
});

export default WorksiteDiaryReviewController;
