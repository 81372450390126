import Component from '@ember/component';

export default Component.extend({
    actions: {
        changeStatus(status) {
            this.changeStatus(status);
        },

        handleLock(action) {
            this.handleLock(action);
        },

        handleRemove(action) {
            this.handleRemove(action);
        },
    },
});
