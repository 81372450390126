/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/user-selector/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import { inject as service } from '@ember/service';

Component = Ember.Component.extend({
    analytics: Ember.inject.service(),
    Collector: service('collector-service'),
    store: service(),
    offset: 0,
    count: null,
    usersFromBackend: null,
    loading: true, // should be in load-mode until we have fetched all users
    offsetPlusOne: Ember.computed('offset', function () {
        return this.get('offset') + 1;
    }),
    fetchUsers: function () {
        const params = {
            form: 'worktime',
            field: 'user',
            order: 'lastname,firstname',
            employer: this.get('employerid'),
            limit: 200,
        };
        if (!this.get('Collector').testNeedsOne('employer')) {
            delete params.employer;
        }
        this.set('loading', true);
        this.get('store')
            .query('user', params)
            .then((ret) => {
                this.set('usersFromBackend', ret.toArray());
                if (this.get('userid')) {
                    const offset = this.tryToGetOffsetFor(this.get('userid'));
                    this.set('offset', offset);
                    this.updateUser();
                } else {
                    this.set('user', ret.objectAt(this.offset));
                }
                this.set('count', Ember.get(ret, 'meta.count'));
                this.set('loading', false);
            });
    },
    didReceiveAttrs: function () {
        // employer has been changed from outside of this component so we need to fetch new users and reset offset
        if (
            this.get('employerid') !== null &&
            this.get('employerid') !== this.get('employer') &&
            this.get('Collector').testNeedsOne('employer')
        ) {
            this.set('employer', this.get('employerid'));
            // run fetchUsers on the next runloop because we don't want race conditions
            Ember.run.next(this, function () {
                this.fetchUsers();
            });
        } else if (this.get('userid') !== null && this.get('userid') !== this.get('user.id')) {
            // if user has been changed from outside of this component we need to update the offset and user
            if (this.get('usersFromBackend') === null) {
                Ember.run.next(this, function () {
                    this.fetchUsers();
                });
            } else {
                const offset = this.tryToGetOffsetFor(this.get('userid'));
                this.set('offset', offset);
                this.updateUser();
            }
        }
    },
    // get the correct offset for the user
    tryToGetOffsetFor: function (userid) {
        var users = this.get('usersFromBackend');
        if (!users || !userid) {
            return 0;
        }
        return users.indexOf(users.findBy('id', userid) || users.get('firstObject'));
    },
    updateUser: function (userid = null) {
        const user = this.get('usersFromBackend').objectAt(this.get('offset'));
        this.set('user', user);
        this.userChanged(user.get('id'));
    },
    showSelector: Em.computed('count', 'limit', function () {
        if (!this.get('limit') || this.get('count') <= this.get('limit')) {
            return true;
        }
        return false;
    }),
    thereIsNextOne: Ember.computed('offset', 'count', function () {
        return this.get('offset') + 1 < this.get('count');
    }),
    thereIsPreviousOne: Ember.computed('offset', 'count', function () {
        return this.get('offset') > 0;
    }),
    actions: {
        previous: function () {
            Ember.get(this, 'analytics').trackEvent({
                category: 'User-selector',
                action: 'Previous user',
            });
            this.set('offset', this.get('offset') - 1);
            return this.updateUser();
        },
        next: function () {
            Ember.get(this, 'analytics').trackEvent({
                category: 'User-selector',
                action: 'Next user',
            });
            this.set('offset', this.get('offset') + 1);
            return this.updateUser();
        },
    },
});

export default Component;
