import Component from '@ember/component';
import { get, set } from '@ember/object';
import Ember from 'ember';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';

export default Component.extend({
    localStorageWrapper: service(),
    session: service(),
    store: service(),
    preventDropdownClose: false,
    showNotificationBadge: true,
    displayNotification: null,

    init() {
        this._super(...arguments);
        //check promo messages from ls immediately and again after 8s
        this.checkPromoMessages();
        if (!Ember.testing) {
            run.later(
                this,
                function () {
                    this.checkPromoMessages();
                },
                10000,
            );
        }
        window.onclick = (event) => {
            if (this.preventDropdownClose || !this.dropdownOpen) return;
            const parentClassList = event.target.parentElement.classList;
            if (
                parentClassList.contains('dropdown') ||
                parentClassList.contains('dropdown-menu') ||
                parentClassList.contains('nav-button')
            )
                return;
            if (event.target.classList.contains('subtle-message-title')) return;
            this.toggleProperty('dropdownOpen');
        };
    },

    actions: {
        toggleDropdown() {
            this.toggleProperty('dropdownOpen');
            // Do not show notification badge when messages list is viewed.
            set(this, 'displayNotification', null);
        },

        openMessage(item) {
            this.preventDropdownClosing();
            if (get(item, 'message.string')) {
                set(item, 'message', get(item, 'message.string'));
            }
            set(this, 'content', item);
            set(this, 'showMessageModal', true);
        },

        closeMessage() {
            set(this, 'content', null);
            set(this, 'showMessageModal', false);
        },

        closeNotificationCard(item) {
            this.preventDropdownClosing();
            this.markAsRead(item);
            //this.allowDropdownClosing();
        },

        clearAllMessages() {
            this.preventDropdownClosing();
            let notificationsCopy = get(this, 'notifications').slice();

            notificationsCopy.forEach((item) => {
                this.markAsRead(item);
            });

            set(this, 'notifications', []);
            set(this, 'messagesAmount', 0);

            let ls_subtle_key = get(this, 'session.currentUser.id') + '_promomessages_subtle';
            get(this, 'localStorageWrapper').setItem(ls_subtle_key, []);
            this.allowDropdownClosing();
        },
    },

    markAsRead(item) {
        let ls_key = get(this, 'session.currentUser.id') + '_promomessages';
        let ls_subtle_key = get(this, 'session.currentUser.id') + '_promomessages_subtle';

        let readMessages = get(this, 'localStorageWrapper').getItem(ls_key);

        get(this, 'notifications').removeObject(item);
        get(this, 'localStorageWrapper').setItems(ls_subtle_key, get(this, 'notifications'));

        readMessages.push(get(item, 'id'));
        get(this, 'localStorageWrapper').setItems(ls_key, readMessages);

        set(this, 'messagesAmount', get(this, 'messagesAmount') - 1);
    },

    checkPromoMessages() {
        let ls = get(this, 'localStorageWrapper');
        let key = get(this, 'session.currentUser.id') + '_promomessages_subtle';

        let msgs = ls.getItem(key);
        let amount = msgs.length;

        if (amount <= 0) {
            amount = null;
        }

        set(this, 'notifications', msgs);
        set(this, 'messagesAmount', amount);
        set(this, 'displayNotification', amount);
    },

    preventDropdownClosing() {
        set(this, 'preventDropdownClose', true);
    },

    allowDropdownClosing() {
        set(this, 'preventDropdownClose', false);
    },
});
