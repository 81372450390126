/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/subcontractor/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSubcontractorController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import pdfExport from '../../../mixins/pdf-export';

UsersSubcontractorController = GeneralReportController.extend(pdfExport, {
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['project', 'page', 'employer'],
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                type: 'database',
                form: this.get('Collector').testNeedsOne('worktime.project')
                    ? 'worktime'
                    : 'presence',
                name: 'project',
            }),
            Em.Object.create({
                field: 'employer',
                type: 'database',
                form: 'user',
                name: 'employer',
            }),
            Em.Object.create({
                field: 'date',
                type: 'date',
                form: this.get('Collector').testNeedsOne('worktime.project')
                    ? 'worktime'
                    : 'presence',
                name: 'date',
            }),
        ]);
    }),
    limit: 25,
    page: 1,
    editingRow: null,
    project: [],
    employer: [],
    date:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('month').format('YYYY-MM-DD'),
    showColumns: [
        'firstname',
        'lastname',
        'birthdate',
        'taxnumber',
        'employer',
        'startdate',
        'enddate',
        'sent_contact_person',
        'sent_contact_address',
        'workpass',
    ],
    rowActions: Ember.computed('intl', function () {
        var arr;
        arr = [
            {
                action: 'removeRow',
                icon: 'move move-Delete',
                text: this.get('intl').t('report.delete_rows'),
            },
            {
                action: 'editRow',
                icon: 'move move-Pencil',
                text: this.get('intl').t('report.edit_rows'),
            },
        ];
        return arr;
    }),
    init_functions: function () {
        return this._super();
    },
    asPdfElement: Ember.computed('model', function () {
        var params;
        params = {};
        return new Em.RSVP.Promise((resolve, reject) => {
            var data, filter, i, len, ref;
            ref = this.get('filters');
            for (i = 0, len = ref.length; i < len; i++) {
                filter = ref[i];
                if (filter.value) {
                    params[filter.field] = filter.value;
                }
            }
            data = this.get('Collector').ttapi({
                url: 'subcontractor',
                data: params,
            });
            return data.then((return_data) => {
                var currentData;
                if (return_data) {
                    currentData = this.formatData(return_data);
                    return resolve(
                        this.createPdfTableElement(
                            this.get('showColumns'),
                            currentData,
                            this.get('translated_columns'),
                        ),
                    );
                } else {
                    return resolve([]);
                }
            });
        });
    }),
    // get translation for every column
    translated_columns: Em.computed('showColumns', function () {
        var c, columns, intl, translated_columns;
        c = this.get('Collector');
        intl = this.get('intl');
        columns = this.get('showColumns');
        translated_columns = [];
        $.each(columns, (key, item) => {
            return translated_columns.push(intl.t('report.user.' + item));
        });
        return translated_columns;
    }),
    formatData: function (model) {
        var formatted_rows;
        if (!model) {
            return;
        }
        formatted_rows = [];
        model['worktimes'].forEach((row) => {
            var tmp, tmpEmp, tmpuser, userData;
            tmp = row;
            tmpuser = model['users'].filterBy('id', tmp.user);
            for (userData in tmpuser[0]) {
                if (typeof tmpuser[0][userData] === 'boolean') {
                    if (tmpuser[0][userData]) {
                        tmpuser[0][userData] = this.get('intl').t('general.yes');
                    } else {
                        tmpuser[0][userData] = this.get('intl').t('general.no');
                    }
                }
                if (userData !== 'id') {
                    if (userData === 'birthdate') {
                        Em.set(row, userData, moment(tmpuser[0][userData]).format('L'));
                    } else {
                        Em.set(row, userData, tmpuser[0][userData]);
                    }
                }
            }
            if (row.startdate) {
                Em.set(row, 'startdate', moment(tmp.startdate).format('L'));
            }
            if (row.enddate) {
                Em.set(row, 'enddate', moment(tmp.enddate).format('L'));
            }
            if (row.employer) {
                tmpEmp = model['employers'].filterBy('id', tmp.employer);
                if (tmpEmp[0]) {
                    Em.set(row, 'employer', tmpEmp[0]['emp_id'] + ' ' + tmpEmp[0]['name']);
                }
            }
            return formatted_rows.push(row);
        });
        return formatted_rows;
    },
    data: Em.computed('model', function () {
        if (!this.get('model')) {
            return;
        }
        return this.formatData(this.get('model'));
    }),
    pageCount: Ember.computed('model.count', function () {
        return Math.ceil(this.get('model.count') / this.get('limit'));
    }),
    changeFormat: function (date) {
        if (date.indexOf('/') > -1) {
            return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
        }
        if (date.indexOf('.') > -1) {
            return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        return date;
    },
    actions: {
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        removeRow: function (obj) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    var promise;
                    if (con) {
                        promise = this.get('Collector').ttapi({
                            method: 'post',
                            data: {
                                userid: obj.id,
                                startdate: obj.startdate,
                                enddate: obj.enddate,
                                projectid: obj.projectid,
                            },
                            url: 'removeFirstWorkLog',
                        });
                        return promise.then((back) => {
                            if (back.msg === 'ok') {
                                return this.get('data').removeObject(obj);
                            }
                        });
                    }
                });
        },
        closeModal: function () {
            return this.set('editingRow', null);
        },
        editRow: function (obj) {
            var _enddate, _startdate, newDates;
            _startdate = this.changeFormat(obj.startdate);
            _enddate = this.changeFormat(obj.enddate);
            newDates = {
                startdate: _startdate,
                enddate: _enddate,
            };
            this.set('editingRow', obj);
            return this.set('editingRow.newDates', newDates);
        },
        newDates: function () {
            var enddate, promise, startdate;
            startdate = this.get('editingRow.newDates.startdate');
            enddate = this.get('editingRow.newDates.enddate');
            promise = this.get('Collector').ttapi({
                method: 'post',
                data: {
                    userid: this.get('editingRow').user,
                    newstartdate: startdate,
                    newenddate: enddate,
                    oldstartdate: this.changeFormat(this.get('editingRow').startdate),
                    oldenddate: this.changeFormat(this.get('editingRow').enddate),
                    projectid: this.get('editingRow').project,
                },
                url: 'editFirstWorkLog',
            });
            return promise.then((back) => {
                this.set('editingRow.startdate', startdate);
                this.set('editingRow.enddate', enddate);
                return this.set('editingRow', null);
            });
        },
        export_pdf: function () {
            return this.get('asPdfElement').then((element) => {
                var elements, pdf_filters_body;
                elements = [element];
                if (this.get('filters')) {
                    pdf_filters_body = this.getPdfFilters(this.get('data'));
                    if (pdf_filters_body.length > 0) {
                        elements.unshift({
                            table: {
                                body: pdf_filters_body,
                            },
                            layout: 'noBorders',
                        });
                    }
                }
                return this.createPdfFromElements(elements).then((file) => {
                    if (typeof cordova !== 'undefined' && cordova !== null) {
                        return this.get('cordova').openFile(file, 'application/pdf');
                    }
                });
            });
        },
    },
});

export default UsersSubcontractorController;
