/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/number-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsNumberFieldComponent;

import Ember from 'ember';

import textInput from '../text-field/component';

CollectorFieldsNumberFieldComponent = textInput.extend({
    session: Ember.inject.service(),
    unit: Ember.computed('field', function () {
        var currency;
        currency = function () {
            switch (this.get('session.currentUser.company_info.country')) {
                case 'se':
                    return 'SEK';
                case 'fi':
                    return '€';
            }
        }.call(this);
        switch (this.get('field.type')) {
            case 'minutes':
                return 'min';
            case 'price':
                return currency;
            case 'big_price':
                return currency;
            default:
                return this.get('field.options');
        }
    }),
});

export default CollectorFieldsNumberFieldComponent;
