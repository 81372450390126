import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),
    ttapi: service(),
    error: service(),
    intl: service(),
    localstorage: service(),

    init() {
        this._super();
        let forms = [];
        if (this.Collector.testNeedsOne('worktime')) forms.push('worktime');
        if (this.Collector.testNeedsOne('abcense')) forms.push('abcense');
        this.forms = forms;
        let own_settings = {}; //this.settings.findBy('type','missing_worktimes').settings;
        if (!own_settings || !own_settings.unSelectedUsers) {
            own_settings = { unSelectedUsers: [] };
        }

        set(this, 'own_settings', own_settings);
        //set(this, 'settings', await this.data.settings);
        //console.log(this.settings);
    },

    actions: {
        check(row) {
            this.toggleCheckRow(row);
        },
        checkAll() {
            this.isChecked ? this.toggleCheckAll(false) : this.toggleCheckAll(true);
            this.toggleProperty('isChecked');
        },

        sendNotificationToCheckedUsers() {
            this.sendNotificationToCheckedUsers();
        },
        showAddModal(user, date) {
            set(this, 'selectedUser', user);
            set(this, 'selectedDate', date);
            set(this, 'showAddModal', true);
        },
    },
});
