import Component from '@ember/component';
import { get, set } from '@ember/object';
import { debounce } from '@ember/runloop';

export default Component.extend({
    actions: {
        toggleActive(test) {
            test.toggleProperty('active');
            this.update(); //calls the updateTests action passed from anomalies controller (check template)
        },

        setTolerance(test, tolerance) {
            set(test, 'tolerance', tolerance);
            debounce(this, this.update, 500); //calls the updateTests action passed from anomalies controller (check template)
        },
    },

    update() {
        this.updateTests(get(this, 'anomalyTests'));
    },
});
