/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/needs.coffee.old
// Generated by CoffeeScript 2.6.1
var NeedsHelper;

import Ember from 'ember';

NeedsHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    compute: function (params, hash) {
        if (hash.needs) {
            return this.get('Collector').testNeeds(hash.needs);
        }
        return this.get('Collector').testNeeds(params);
    },
});

export default NeedsHelper;
