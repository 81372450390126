import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const DevelopmentOwnreportsRoute = Route.extend({
    Collector: service('collector-service'),
    model() {
        const c = this.Collector;
        return c.ttapi({
            url: 'defaultReports',
        });
    },
});

export default DevelopmentOwnreportsRoute;
