/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/invite-users/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var InviteUsersController;

import Ember from 'ember';

import pdfMixin from '../../mixins/pdf-export';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

InviteUsersController = Ember.Controller.extend(AuthenticatedRouteMixin, pdfMixin, {
    Collector: Em.inject.service('collector-service'),
    store: Em.inject.service(),
    session: Em.inject.service(),
    dialogs: Em.inject.service(),
    notifications: Ember.inject.service(),
    intl: Em.inject.service(),
    queryParams: ['projectid'],
    emailSending: false,
    smsSending: false,
    projectid: null,
    codeObs: Ember.observer('magicKey', function () {
        return this.set('message_sent', false);
    }),
    field: Em.computed(function () {
        var c, f, form, forms, i, len;
        c = this.get('Collector');
        forms = [
            'presence',
            'worktime',
            'orientations',
            'diaryreport',
            'tr',
            'mvr',
            'worktask',
            'skyddsrond',
            'byggdagbok',
        ];
        form = false;
        for (i = 0, len = forms.length; i < len; i++) {
            f = forms[i];
            if (c.formExists(f)) {
                form = f;
                break;
            }
        }
        return c.field(form, 'project');
    }),
    projectObs: Em.observer('projectid', 'session.currentUser.username', function () {
        if (this.get('session.currentUser.username')) {
            if (!this.get('projectid')) {
                return;
            }
            this.set('field.value', this.get('projectid'));
            return this.store
                .query('project', {
                    id: this.get('projectid'),
                })
                .then((data) => {
                    this.set('project', data.get('firstObject'));
                    return this.createInvitePDF();
                });
        }
    }),
    sms_placeholder: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return intl.t('invite_users.sms_placeholder');
    }),
    sms_number: Ember.computed(function () {
        var self;
        self = this;
        return this.store
            .findRecord(
                'user',
                this.get('session.currentUser.id', {
                    reload: true,
                }),
            )
            .then(function (data) {
                return self.set('sms_number', data.get('phone'));
            });
    }),
    email_address: Ember.computed(function () {
        return this.get('session.currentUser.email');
    }),
    smsnumObs: Ember.observer('sms_number', function () {
        if (
            !/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[^0]\d{1,14}$/i.test(
                this.get('sms_number'),
            )
        ) {
            this.set('numbererror', this.get('intl').t('field.error.phone_number'));
            return this.set('sms_button_disabled', true);
        } else {
            this.set('numbererror', null);
            return this.set('sms_button_disabled', false);
        }
    }),
    emailObs: Ember.observer('email_address', function () {
        if (!this.get('email_address')) {
            return this.set('email_button_disabled', true);
        } else {
            return this.set('email_button_disabled', false);
        }
    }),
    createInvitePDF: function () {
        /*
    CREATE USER SIGNING PDF HELP - this is so horrible!!!!!! .. but does the thing
    */
        var c,
            creator,
            currenturl,
            getLongUrl,
            getTinyUrl,
            intl,
            project,
            promise,
            promise_arr,
            store,
            writtenForm;
        project = this.get('project');
        store = this.get('store');
        c = this.get('Collector');
        if (!project) {
            return;
        }
        intl = this.get('intl');
        currenturl = window.location.origin + '/';
        creator = this.get('session.currentUser.username');
        if (!creator) {
            alert('Error occured. Please, log in again.');
            return;
        }
        getLongUrl = (projectid, creator) => {
            // if you edit this, edit also projects/saved/controller
            return new Em.RSVP.Promise((resolve, reject) => {
                var type, url, userid;
                url = '#/register/user?project=' + projectid + '&creator=' + creator;
                type = '';
                if (c.productExists('products.rakentajanpaketti')) {
                    type = 'valtti';
                } else if (!c.productExists('products.personalliggareandid06')) {
                    type = 'normal';
                }
                url += '&type=' + type;
                userid = this.get('session.currentUser.id');
                if (c.formExists('employer')) {
                    return store
                        .query('user', {
                            id: userid,
                            sideload: ['employer.name', 'employer.emp_id', 'employer.country'],
                        })
                        .then(() => {
                            var employer;
                            employer = this.get('session.currentUser.employer');
                            if (employer) {
                                if (
                                    !employer.get('country') &&
                                    c.productExists('products.personliggareandid06')
                                ) {
                                    reject({
                                        missing: 'employer_country',
                                    });
                                }
                                url += '&employer_name=' + encodeURIComponent(employer.get('name'));
                                url += '&employer_id=' + encodeURIComponent(employer.get('emp_id'));
                                if (employer.get('country')) {
                                    url +=
                                        '&employer_country=' +
                                        encodeURIComponent(employer.get('country'));
                                }
                                return resolve({
                                    longurl: url,
                                    employer: employer.get('name'),
                                });
                            } else {
                                return store
                                    .query('employer', {
                                        main: 'on',
                                    })
                                    .then((employers) => {
                                        employer = employers.get('firstObject');
                                        if (employer == null) {
                                            return alert(
                                                'Main employer is not set. Cannot create help.',
                                            );
                                        } else {
                                            url +=
                                                '&employer_name=' +
                                                encodeURIComponent(employer.get('name'));
                                            url +=
                                                '&employer_id=' +
                                                encodeURIComponent(employer.get('emp_id'));
                                            if (employer.get('country')) {
                                                url +=
                                                    '&employer_country=' +
                                                    encodeURIComponent(employer.get('country'));
                                            }
                                            return resolve({
                                                longurl: url,
                                                employer: employer.get('name'),
                                            });
                                        }
                                    });
                            }
                        });
                } else {
                    return resolve({
                        longurl: url,
                    });
                }
            });
        };
        getTinyUrl = (projectid, projectname) => {
            return new Em.RSVP.Promise((resolve, reject) => {
                //resolve "http://goo.gl/Aer43 " + projectid
                return getLongUrl(projectid, creator).then(
                    (ret) => {
                        var call;
                        console.log(ret);
                        call = this.get('Collector').ttapi({
                            url: 'code/generate',
                            data: {
                                longUrl: currenturl + ret.longurl,
                            },
                            method: 'post',
                        });
                        return call.then(function (back) {
                            var retarr;
                            retarr = {
                                project: projectname,
                                url: back.id,
                            };
                            if (c.formExists('employer')) {
                                retarr['employer'] = ret.employer;
                            }
                            console.log(retarr);
                            return resolve(retarr);
                        });
                    },
                    (error) => {
                        return reject(error);
                    },
                );
            });
        };
        writtenForm = function (url) {
            var arr, ascii, character, characters, i, len, numbers, ret_arr, str;
            arr = url.split('/');
            str = arr[arr.length - 1];
            characters = str.split('');
            numbers = intl.t('general.numbers').split(',');
            ret_arr = [];
            for (i = 0, len = characters.length; i < len; i++) {
                character = characters[i];
                ascii = character.charCodeAt(0);
                ret_arr.push(
                    ascii >= 48 && ascii <= 57
                        ? numbers[ascii - 48]
                        : ascii >= 65 && ascii <= 90
                          ? intl.t('general.big_char') + ' ' + character
                          : ascii >= 97 && ascii <= 122
                            ? intl.t('general.small_char') + ' ' + String.fromCharCode(ascii - 32)
                            : void 0,
                );
            }
            return ret_arr.join(', ');
        };
        promise_arr = [];
        promise = getTinyUrl(project.get('id'), project.get('name'));
        promise_arr.push(promise);
        return promise.then(
            (elem) => {
                var codeBlock, pdfElements, start;
                if (elem.url.indexOf('=') < 0) {
                    start = elem.url.lastIndexOf('/') + 1; // when using http://m0v.eu/<code> url
                } else {
                    start = elem.url.indexOf('=') + 1; // when using kehityspurkki or p.move (http://p.movenium.com/next/ttapi/public/code?code=<code>)
                }
                codeBlock = elem.url.substring(start);
                pdfElements = [];
                pdfElements.push({
                    text: elem.project + ' - ' + elem.employer,
                    style: 'bigText',
                    alignment: 'center',
                });
                pdfElements.push(' ');
                pdfElements.push({
                    text: intl.t('general.usersigninghelp_before').toString(),
                    alignment: 'center',
                });
                pdfElements.push({
                    text: elem.url,
                    style: 'bigText',
                    margin: [10, 10, 10, 10],
                    alignment: 'center',
                });
                pdfElements.push({
                    text: '(' + writtenForm(elem.url) + ')',
                    alignment: 'center',
                });
                pdfElements.push(' ');
                pdfElements.push({
                    text: intl.t('general.usersigninghelp_after').toString(),
                    alignment: 'center',
                });
                pdfElements.push({
                    text: codeBlock,
                    style: 'bigText',
                    margin: [10, 10, 10, 10],
                    alignment: 'center',
                });
                pdfElements.push(' ');
                pdfElements.push({
                    text: intl.t('general.usersigninghelp_qr').toString(),
                    alignment: 'center',
                });
                pdfElements.push(' ');
                pdfElements.push({
                    qr: elem.url,
                    alignment: 'center',
                });
                this.set('pdfObject', pdfElements);
                return this.set('magicKey', elem.url.slice(-6));
            },
            (error) => {
                console.error(error);
                if (error.missing) {
                    return this.get('dialogs').alert(
                        this.get('intl').t('user_invite.pdf_create.missing.' + error.missing),
                    );
                } else {
                    return this.get('dialogs').alert(this.get('intl').t('general.error'));
                }
            },
        );
    },
    //########################################################
    actions: {
        projectChanged: function (value) {
            this.set('project', value);
            return this.createInvitePDF();
        },
        getPDF: function () {
            if (this.get('pdfObject')) {
                return this.createPdfFromElements(this.get('pdfObject'));
            }
        },
        sendSms: function () {
            var self, send;
            self = this;
            send = this.Collector.ttapi({
                url: 'sendSms',
                data: {
                    phone: self.get('sms_number'),
                    messageid: 'magic_code_sms',
                    values: {
                        code: self.get('magicKey'),
                    },
                    language: moment.locale(),
                },
                method: 'post',
            });
            return send.then(() => {
                this.get('notifications').success(this.get('intl').t('invite_users.sms_sent'), {
                    autoClear: true,
                });
                self.set('sms_message_sent', true);
                return this.set('smsSending', false);
            });
        },
        sendEmail: function () {
            var self, send;
            this.set('emailSending', true);
            self = this;
            send = this.Collector.ttapi({
                url: 'sendEmail',
                data: {
                    email: self.get('email_address'),
                    messageid: 'magic_code_email',
                    titleid: 'magic_code_email_title',
                    values: {
                        sender:
                            this.get('session.currentUser.firstname') +
                            ' ' +
                            this.get('session.currentUser.lastname'),
                        service: this.get('session.currentUser.employer.name'),
                        project: this.get('project.name'),
                        code: self.get('magicKey'),
                    },
                    language: moment.locale(),
                },
                method: 'post',
            });
            return send.then(() => {
                this.get('notifications').success(this.get('intl').t('general.email_sent'), {
                    autoClear: true,
                });
                self.set('email_message_sent', true);
                return this.set('emailSending', false);
            });
        },
        setInviteType: function (type) {
            if (type === 'sms') {
                return this.set('inviteType', 'sms');
            } else if (type === 'email') {
                return this.set('inviteType', 'email');
            }
        },
    },
});

export default InviteUsersController;
