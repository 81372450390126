/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/form-view/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormViewController;

import Ember from 'ember';

FormsFormViewController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    fields: Ember.computed('model', function () {
        var c, fields, form_name, model;
        model = this.get('model');
        if (!model) {
            return;
        }
        form_name = model.get('constructor.modelName');
        if (form_name) {
            form_name = form_name.underscore();
        }
        c = this.get('Collector');
        fields = c.fieldArray(form_name);
        Em.assert('Seems that your form name ' + form_name + ' has no fields', fields);
        fields = fields.filterBy('features.hide', void 0);
        return fields;
    }),
});

export default FormsFormViewController;
