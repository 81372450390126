/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorktimeEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

WorktimeEditRoute = GeneralAddRoute.extend({
    modelName: 'worktime',
    controllerName: 'timetracker/edit',
    edit: true,
    //    model: (params) ->
    //        @store.findRecord(@get('modelName'), params.id)
    savedRoute: 'timetracker.addsaved',
    beforeModel: function (transition) {
        this._super();
        if (transition.from && transition.from.name === 'timetracker.comp-report') {
            return this.set('afterSave', 'timetracker.comp-report');
        } else if (transition.from && transition.from.name === 'timetracker.aggregated.rows') {
            return this.set('afterSave', {
                route: 'timetracker.aggregated.rows',
                params: transition.from.params.date,
            });
        } else {
            if (this.get('Collector').testNeeds(['mobileView', 'userlevel=1'])) {
                return this.set('afterSave', 'timetracker.week-report');
            } else {
                return this.set('afterSave', 'timetracker.worktime-list');
            }
        }
    },
    actions: {
        afterSave: function () {
            return this.transitionTo(this.get('afterSave'));
        },
    },
});

export default WorktimeEditRoute;
