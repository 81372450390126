import { Promise as EmberPromise } from 'rsvp';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
    ttapi: service(),
    Collector: service('collector-service'),
    intl: service(),
    notifications: service(),
    sendto: '',
    days: '',
    nameField: '',
    projectField: '',
    error_negative: false,
    showCustomField: false,

    showNameField: computed(function () {
        return get(this, 'form') == 'user' || get(this, 'form') == 'card' ? false : true;
    }),

    formFields: computed(function () {
        return get(this, 'Collector').fieldArray(get(this, 'form'));
    }),

    sendto_options: computed(function () {
        const intl = get(this, 'intl');
        let roles = [
            { id: '3', translated_name: intl.t('user.userlevel.3'), name: 'userlevel=3' },
            { id: '6', translated_name: intl.t('user.userlevel.6'), name: 'userlevel=6' },
            { id: '5', translated_name: intl.t('user.userlevel.5'), name: 'userlevel=5' },
        ];

        if (get(this, 'Collector').testNeeds(['products.project_owner'])) {
            roles = this._getProjectOwnerRole(roles);
        }
        return roles;
    }),
    alarmValue: computed(function () {
        const key = 'expires@' + get(this, 'form') + '.' + get(this, 'field');

        return ObjectProxy.extend(PromiseProxyMixin).create({
            promise: new EmberPromise((resolve) => {
                get(this, 'ttapi')
                    ._get('alarm', { key: key })
                    .then((back) => this._setAlarms(back, resolve));
            }),
        });
    }),

    showSaveButton: computed('nameField', 'days', 'sendto', function () {
        let name = get(get(this, 'alarmValue'), 'name');
        let days = get(get(this, 'alarmValue'), 'before');
        let sendto = get(get(this, 'alarmValue'), 'sendto');

        if (this.error_negative || !get(this, 'days')) return false;

        if (
            name != get(this, 'nameField') ||
            days != get(this, 'days') ||
            sendto != get(this, 'sendto')
        ) {
            return true;
        } else {
            return false;
        }
    }),

    init() {
        this._super(...arguments);
        this.alarmOptions = ['0', '5', '7', '10', '14', '21', '28'];
    },

    async didReceiveAttrs() {
        let alarm = await get(this, 'alarmValue');
        if (alarm) set(this, 'showCustomField', !this.alarmOptions.includes(alarm.before));
    },

    actions: {
        saveAlarm: function () {
            const key = 'expires@' + get(this, 'form') + '.' + get(this, 'field');
            const fieldTranslation = get(this, 'Collector').getTranslationForColumn(
                get(this, 'form'),
                get(this, 'field'),
            );
            const days = get(this, 'days');

            get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                autoClear: true,
            });

            if (!isNaN(days)) {
                get(this, 'ttapi')._post('alarms', {
                    type: 'expires',
                    extra: {
                        form: get(this, 'form'),
                        field: get(this, 'field'),
                        before: days,
                        fieldTranslation: fieldTranslation.string
                            ? fieldTranslation.string
                            : fieldTranslation,
                        locale: moment.locale(),
                        send_to: get(this, 'sendto'),
                        nameField: get(this, 'nameField'),
                        projectField: get(this, 'projectField'),
                    },
                    key: key,
                });
            } else {
                get(this, 'ttapi')._delete('alarms/' + key);
            }
        },

        setDays(value) {
            set(this, 'error_negative', false);

            if (value === 'custom') {
                set(this, 'showCustomField', true);
            } else {
                set(this, 'days', value);
                set(this, 'showCustomField', false);
            }
        },

        validateAndSetCustomDays(days) {
            if (days < 0) {
                set(this, 'error_negative', true);
                return;
            }
            set(this, 'error_negative', false);
            set(this, 'days', days);
        },
    },

    _setAlarms(back, resolve) {
        if (back.length > 0) {
            let sendTo = JSON.parse(get(back, 'firstObject.extra')).send_to;
            if (sendTo) {
                set(this, 'sendto', sendTo);
            }
            let nameField = JSON.parse(get(back, 'firstObject.extra')).nameField;
            if (nameField) {
                set(this, 'nameField', nameField);
            }
            let before = JSON.parse(get(back, 'firstObject.extra')).before;
            if (before) {
                set(this, 'days', before);
            }
            resolve({ before: before, name: nameField, sendto: sendTo });
        } else {
            resolve();
        }
    },
    _getProjectOwnerRole(roles) {
        const intl = get(this, 'intl');
        let projectFieldName = '';
        get(this, 'formFields').forEach(function (field) {
            if (field.type == 'database' && field.options.form == 'project') {
                projectFieldName = field.name;
                roles.push({
                    translated_name: intl.t('alarm.project_owner'),
                    name: 'project_owner',
                });
            }
        });
        set(this, 'projectField', projectFieldName);
        return roles;
    },
});
