import Component from '@ember/component';
import { inject } from '@ember/service';
import { computed, get, set } from '@ember/object';
import { isPresent } from '@ember/utils';
// import collectorField from '../../../../mixins/collector-field';
import moment from 'moment';
import { later } from '@ember/runloop';

export default Component.extend({
    store: inject(),

    _record: computed('field.value', function () {
        if (isPresent(get(this, 'field.value'))) {
            return get(this, 'store').peekRecord(
                get(this, 'field.options.form'),
                get(this, 'field.value'),
            );
        } else {
            const record = get(this, 'store').createRecord(get(this, 'field.options.form'));
            this.sendAction('bubbleChange', record, get(this, 'field.name')); //eslint-disable-line
            return record;
        }
    }),

    _isRunning: computed('_record.{starttime,endtime}', function () {
        return (
            isPresent(get(this, '_record.starttime')) && !isPresent(get(this, '_record.endtime'))
        );
    }),
    _isStopped: computed('_record.endtime', function () {
        return isPresent(get(this, '_record.endtime'));
    }),

    time: computed(function () {
        if (isPresent(get(this, '_record.endtime'))) {
            return this.giveFormattedDifference(
                get(this, '_record.starttime'),
                get(this, '_record.endtime'),
            );
        } else if (isPresent(get(this, '_record.starttime'))) {
            return this.giveFormattedDifference(get(this, '_record.starttime'), moment());
        } else {
            return '00:00:00';
        }
    }),

    init() {
        this._super(...arguments);
        this._ticker();
    },

    actions: {
        toggleButton() {
            if (get(this, '_isStopped')) return;
            const record = get(this, '_record');
            if (isPresent(get(record, 'starttime'))) {
                set(record, 'endtime', moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                record.save();
            } else {
                this._ticker();
                set(record, 'starttime', moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                record.save();
            }
        },
    },

    _ticker() {
        if (get(this, '_isStopped')) return;
        this.notifyPropertyChange('time');
        later(this, this._ticker, 1000);
    },

    giveFormattedDifference(start, end) {
        const ms = moment(end).diff(moment(start));
        const d = moment.duration(ms);
        const hours = Math.floor(d.asHours());
        return (hours < 10 ? '0' + hours : hours) + moment.utc(ms).format(':mm:ss');
    },
});
