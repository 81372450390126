import Component from '@ember/component';
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    router: service(),
    store: service(),
    notifications: service(),
    intl: service(),
    tagName: '',
    disableAdding: false,

    defaults: computed('form', 'day', function () {
        let params = {};
        if (this.form == 'abcense') {
            params.startdate = this.day;
            params.enddate = this.day;
            params.dayamount = 1;
        } else {
            params.date = this.day;
        }
        if (this.user) {
            params.user = this.store.peekRecord('user', this.user);
        }

        return params;
    }),

    init() {
        this._super(...arguments);
        if (
            this.form === 'worktime' &&
            (!this.Collector.canAddWorktime(this.day) ||
                this.Collector.testNeeds(['products.timetracker_clockcard', 'userlevel=1?']))
        ) {
            set(this, 'disableAdding', true);
        }
    },

    actions: {
        notifySaved() {
            this.updateData();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            if (this.rowAdded) this.rowAdded();
        },
    },
});
