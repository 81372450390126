import { helper } from '@ember/component/helper';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

export function formatOption(params) {
    let item = params[0];
    let ret = '';

    if (typeof params[1] === 'string') {
        params[1] = params[1].split(',');
    }

    if (isPresent(params[1])) {
        params[1].forEach((field) => {
            if (get(item, field)) {
                ret += get(item, field) + ' ';
            }
        });
    }
    return ret.trim();
}

export default helper(formatOption);
