/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/confirm/route.coffee.old
// Generated by CoffeeScript 2.6.1
var confirmRoute;

import Ember from 'ember';

import OrientationsSubRoute from '../sub/route';

confirmRoute = OrientationsSubRoute.extend({
    model: function () {
        var id;
        if (
            this.Collector.testNeeds(['project.proj_end']) ||
            this.Collector.testNeeds(['orientation.file'])
        ) {
            id = this.controllerFor('orientations.add').get('projectRecord').get('id');
            return this.store.findRecord('project', id, {
                reload: true,
            });
        }
    },
});

export default confirmRoute;
