define("tt4/injections/services/capi", ["exports", "app/services/capi"], function (_exports, _capi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _capi.default;
    }
  });
});
