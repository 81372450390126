import { computed, get } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
    Collector: service('collector-service'),
    tagName: 'span',

    typeIsArray: equal('fieldObj.type', 'databasearray'),

    preformatted: computed('field', function () {
        if (get(this, 'field') === 'row_info.created') {
            return moment(get(this, 'value')).format('LLL');
        } else {
            return null;
        }
    }),

    fieldObj: computed('form', 'field', function () {
        return get(this, 'Collector').field(get(this, 'form'), get(this, 'field'));
    }),
});
