import Controller from '@ember/controller';
import { Promise as EmberPromise } from 'rsvp';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    ttapi2: service(),
    notinuse: alias('model'),

    filters: computed('imeiFilter', 'tagFilter', 'date', function () {
        let filters = new Object();
        if (get(this, 'imeiFilter.target.value')) {
            filters.imei = get(this, 'imeiFilter.target.value');
        }
        if (get(this, 'tagFilter.target.value')) {
            filters.tagid = get(this, 'tagFilter.target.value');
        }
        if (get(this, 'date')) {
            filters.datetime = get(this, 'date');
        }

        return filters;
    }),

    logs: computed('filters', function () {
        let url = 'at2/timecleanlog';
        let filters = get(this, 'filters');
        if (filters) {
            for (const key in filters) {
                if (key == Object.keys(filters)[0]) {
                    url += '?' + key + '=' + filters[key];
                } else {
                    url += '&' + key + '=' + filters[key];
                }
            }
        }
        return ObjectProxy.extend(PromiseProxyMixin).create({
            //eslint-disable-line
            promise: new EmberPromise((resolve) => {
                get(this, 'ttapi2')
                    ._get(url)
                    .then((back) => this._resolvePromise(back, resolve));
            }),
        });
    }),

    _resolvePromise(back, resolve) {
        resolve(back.message);
    },
});
