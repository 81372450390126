import Component from '@ember/component';
import { set } from '@ember/object';

/**
 * 
 * {{#auto-modal as |modal|}}
        {{#modal.open}}<button {{action modal.show}}>open</button>{{/modal.open}}

        {{#modal.content}}
            modal content goes here

            <button {{action (model.close)}}>close</button>
        {{/modal.content}}
    {{/auto-modal}}
 * 
 */
export default Component.extend({
    showModal: false,

    actions: {
        openModal() {
            set(this, 'showModal', true);
        },

        closeModal() {
            set(this, 'showModal', false);
        },
    },
});
