/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/add/message/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesAddMessageController;

import Ember from 'ember';

import config from '../../../../config/environment';

import { gt } from '@ember/object/computed';

import { sanitize } from 'dom-purify';

MessagesAddMessageController = Ember.Controller.extend({
    addIndexController: Ember.inject.controller('messages/add/index'),
    ttapi: Ember.inject.service(),
    session: Ember.inject.service(),
    users_without_phone: Em.A([]),
    users_without_email: Em.A([]),
    //get receivers user ids to own array
    receivers: Em.computed('addIndexController.selected_users_arr.[]', function () {
        var recobj;
        recobj = this.get('addIndexController.selected_users_arr');
        //get all users if none selected
        if (Em.isEmpty(recobj) || recobj.length === 0) {
            return this.get('store')
                .query('user', {
                    limit: 'all',
                })
                .then((users) => {
                    this.set('addIndexController.selected_users_arr', users);
                    return this.parseReceivers(users);
                });
        } else {
            return this.parseReceivers(recobj);
        }
    }),
    parseReceivers: function (recobj) {
        var recArr;
        recArr = [];
        this.set('users_without_phone', Em.A([]));
        this.set('users_without_email', Em.A([]));
        recobj.forEach((receiver) => {
            //if send sms set and someones doesnt have phone
            if (!receiver.get('phone')) {
                this.get('users_without_phone').push(receiver);
            }
            //same thing for email
            if (!receiver.get('email')) {
                this.get('users_without_email').push(receiver);
            }
            return recArr.push(receiver.id);
        });
        return recArr;
    },
    message_length: Em.computed('header', 'message', 'send_sms', function () {
        var chars, length;
        if (!this.get('send_sms')) {
            return;
        }
        chars = 3; //sms message format is like "title - rest_of_the_message"
        if (this.get('header')) {
            chars += parseInt(this.get('header.length'));
        }
        if (this.get('message')) {
            chars += parseInt(this.get('message.length'));
        }
        length = Math.ceil(chars / 160);
        return length;
    }),
    multiple_sms: gt('message_length', 1),
    fillData: function () {
        var addController;
        addController = this.get('addIndexController');
        this.set('selected_users_arr', addController.selected_users_arr);
        this.set('header', addController.header);
        this.set('message', addController.message);
        this.set('send_sms', addController.send_sms);
        this.set('send_email', addController.send_email);
        return this.set('sticky_message', addController.sticky_message);
    },
    //called from routes resetController hook
    resetData: function () {
        this.set('addIndexController.selected_users_arr', Em.A([]));
        this.set('addIndexController.tab', '');
        this.set('addIndexController.selected_employer', '');
        this.set('addIndexController.selected_project', '');
        this.set('addIndexController.selected_group', '');
        this.set('addIndexController.selected_role', '');
        this.set('addIndexController.q', '');
        this.set('addIndexController.header', '');
        this.set('addIndexController.message', '');
        this.set('addIndexController.send_sms', '');
        this.set('addIndexController.send_email', '');
        return this.set('addIndexController.sticky_message', '');
    },
    actions: {
        save: function () {
            var params, self;
            self = this;
            params = {};
            params.receivers = this.get('receivers');
            params.header = sanitize(this.get('header'));
            if (this.get('message')) {
                //if this is null, message opening screen gets stuck
                params.message = sanitize(this.get('message'));
            } else {
                params.message = '';
            }
            params.send_sms = this.get('send_sms');
            params.send_email = this.get('send_email');
            params.sticky_message = this.get('sticky_message');
            params.creator_name =
                this.get('session.currentUser.firstname') +
                ' ' +
                this.get('session.currentUser.lastname');
            return this.get('ttapi')
                .request('/messages/new_message', {
                    type: 'POST',
                    data: params,
                })
                .then((data) => {
                    self.set('spinIsLoading', false);
                    self.set('disabled', false);
                    return self.transitionToRoute('messages.list');
                });
        },
        cancel: function () {
            this.set('spinIsLoading', false);
            this.set('disabled', false);
            return this.transitionToRoute('messages.list');
        },
    },
});

export default MessagesAddMessageController;
