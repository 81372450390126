/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/modal-array.coffee.old
// Generated by CoffeeScript 2.6.1
var ModalArrayComponent;

import Ember from 'ember';

import config from '../config/environment';

import $ from 'jquery';

ModalArrayComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    tagName: '',
    to_users: Em.A([]),
    didInsertElement: function () {
        if (this.get('array.firstObject')) {
            this.set('currentMessage', this.get('array.firstObject'));
            this.getNames();
            return $('#array-modal').modal('show');
        }
    },
    getNames: function () {
        var readed_users, self, session;
        session = this.get('session');
        if (session) {
            if (this.Collector.getUserlevel(session) !== 5) {
                return;
            }
        }
        self = this;
        readed_users = this.get('currentMessage.readed');
        return this.get('store')
            .findByIds('user', this.get('currentMessage.to'))
            .then(function (arr) {
                var i, len, to_users, user, user_obj;
                to_users = [];
                for (i = 0, len = arr.length; i < len; i++) {
                    user = arr[i];
                    user_obj = {};
                    user_obj.name = user.get('lastname') + ' ' + user.get('firstname');
                    //check if user is in "readed"-array
                    if (readed_users != null) {
                        if (readed_users.indexOf(user.get('id')) !== -1) {
                            user_obj.readed = true;
                        }
                    }
                    to_users.push(user_obj);
                }
                return self.set('to_users', to_users);
            });
    },
    actions: {
        closeAndMarkAsRead: function (row) {
            var arr, params;
            // here we have to somehow mark message as read
            params = {};
            params.rowid = row.id;
            this.get('ttapi').request('/msgboard', {
                type: 'POST',
                data: params,
            });
            arr = this.get('array');
            arr.removeAt(0);
            this.set('currentMessage', this.get('array.firstObject'));
            if (arr.length === 0) {
                return $('#array-modal').modal('hide');
            }
        },
    },
});

export default ModalArrayComponent;
