import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
    popperOptions: null,
    tagName: '',
    init() {
        this._super(...arguments);
        set(this, 'popperOptions', {
            modifiers: {
                preventOverflow: {
                    escapeWithReference: false,
                },
            },
        });
    },
});
