/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/builder/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddBuilderRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

UsersAddBuilderRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    actions: {
        willTransition: function (transition) {
            this.controller.set('create', false);
            this.controller.set('users', null);
            this.controller.set('message', null);
            this.controller.set('taxnumber', null);
            this.controller.set('cardnumber', null);
            this.controller.set('noEmployer', false);
            this.controller.set('activeUser', null);
            return true;
        },
    },
});

export default UsersAddBuilderRoute;
