/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/pagi-nate.coffee.old
// Generated by CoffeeScript 2.6.1
var PagiNateComponent;

import Ember from 'ember';

PagiNateComponent = Ember.Component.extend({
    // Evil Markus commented this because should be handled in controller/parent company and was messing up in reports with loading state.
    // Let's see what breaks
    //    didInsertElement: ->
    //        @set 'currentPage', 1
    currentPage: null,
    showPagination: Ember.computed.gt('pageCount', 1),
    pages: Ember.computed('pageCount', 'currentPage', function () {
        var currentPage,
            end,
            limiter,
            middle,
            midpoint,
            pageCount,
            pages,
            ref,
            ref1,
            ref2,
            ref3,
            start;
        pageCount = this.get('pageCount');
        currentPage = this.get('currentPage');
        limiter = function (item) {
            return item >= 1 && item <= pageCount;
        };
        start = [1, 2, 3, 4].filter(limiter);
        end = function () {
            var results = [];
            for (
                var j = (ref = pageCount - 3), ref1 = pageCount + 1;
                ref <= ref1 ? j < ref1 : j > ref1;
                ref <= ref1 ? j++ : j--
            ) {
                results.push(j);
            }
            return results;
        }
            .apply(this)
            .filter(limiter);
        pages = this.union(start, end);
        midpoint = currentPage;
        if (currentPage < 8) {
            midpoint = 8;
        }
        if (currentPage + 8 > pageCount) {
            midpoint = pageCount - 8;
        }
        middle = function () {
            var results = [];
            for (
                var j = (ref2 = midpoint - 3), ref3 = midpoint + 4;
                ref2 <= ref3 ? j < ref3 : j > ref3;
                ref2 <= ref3 ? j++ : j--
            ) {
                results.push(j);
            }
            return results;
        }
            .apply(this)
            .filter(limiter);
        pages = this.union(pages, middle);
        return this.fillCaps(pages);
    }),
    showCurrentpageOverLimit: Ember.computed('currentPage', function () {
        return (
            this.get('queryLimitFull') &&
            this.get('currentPage') !== this.get('pageCount') &&
            !this.get('hasNextPage')
        );
    }),
    // if there are caps between numbers fill those with ... (or with number if cap length is only 1)
    fillCaps: function (array) {
        var break_indexes, i, j, last, len, ref;
        last = 0;
        break_indexes = [];
        array.forEach(function (val, key) {
            if (last + 1 !== val) {
                break_indexes.push([key, last + 2 === val ? last + 1 : '...']);
            }
            return (last = val);
        });
        ref = break_indexes.reverse();
        for (j = 0, len = ref.length; j < len; j++) {
            i = ref[j];
            array.splice(i[0], 0, i[1]);
        }
        return array;
    },
    // union two arrays of numbers to one array with unique values
    union: function (arr1, arr2) {
        var onlyUnique, sortNumber;
        onlyUnique = function (value, index, self) {
            return self.indexOf(value) === index;
        };
        sortNumber = function (a, b) {
            return a - b;
        };
        return arr1.concat(arr2).filter(onlyUnique).sort(sortNumber);
    },
    hasPreviousPage: Ember.computed.gt('currentPage', 1),
    hasNextPage: Ember.computed('currentPage', 'pageCount', function () {
        return this.get('currentPage') < this.get('pageCount');
    }),
    actions: {
        nextPage: function () {
            this.set('currentPage', parseInt(this.get('currentPage'), 10) + 1);
            if (this.get('pageChange')) {
                return this.get('pageChange')(this.get('currentPage'));
            }
        },
        previousPage: function () {
            this.set('currentPage', parseInt(this.get('currentPage'), 10) - 1);
            if (this.get('pageChange')) {
                return this.get('pageChange')(this.get('currentPage'));
            }
        },
        changePage: function (page) {
            if (page === '...') {
                return;
            }
            this.set('currentPage', page);
            if (this.get('pageChange')) {
                return this.get('pageChange')(this.get('currentPage'));
            }
        },
    },
});

export default PagiNateComponent;
