import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';
import config from 'tt4/config/environment';

// This function receives the params `params, hash`
export function apiImage(params) {
    if (params[0] != null) {
        if (params[0].substring(0, 4) === 'http') {
            return params[0];
        }

        return htmlSafe(config.APP.COLLECTOR_API_URL + '/' + params[0]);
    }
}

export default helper(apiImage);
