import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ITestTypescript {
    text: string;
    number: number;
}

export default class TestTypescriptComponent extends Component<ITestTypescript> {
    @tracked protected message: string;

    constructor(owner: unknown, args: ITestTypescript) {
        super(owner, args);
        this.message = 'Your text is: ' + this.args.text;
    }

    get anotherMessage() {
        return 'A number: ' + this.args.number;
    }
}
