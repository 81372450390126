/* eslint-disable ember/named-functions-in-promises */
import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    session: service(),
    evented: service(),

    init() {
        this._super();
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    },

    didInsertElement() {
        set(this, 'saldoHours', this.getSaldoData());
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    },

    onStoreEvent(params) {
        if (params.type === 'worktime' || params.type === 'abcense')
            set(this, 'saldoHours', this.getSaldoData());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'saldoHours', this.getSaldoData());
    },

    async getSaldoData() {
        const saldoSettings = await this.Collector.ttapi({
            url: 'saldo/saldoSettings?userid=' + this.session.currentUser.id,
        });
        const userSaldo = await this.Collector.ttapi({
            url: 'saldo/get_current_saldo?userid=' + this.session.currentUser.id,
        });

        set(this, 'max', saldoSettings['maximum'] / 60);
        set(this, 'min', saldoSettings['minimum'] / 60);

        return userSaldo['saldo'] / 60;
    },
});
