/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/username-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsUsernameFieldComponent;

import Ember from 'ember';

import text from '../text-field/component';

CollectorFieldsUsernameFieldComponent = text.extend({
    // Send the change action
    valueObserver: Em.observer('value', function () {
        return this.sendAction(
            'bubbleChange',
            this.get('value'),
            this.get('field.name'),
            true,
            true,
            700,
        );
    }),
});

export default CollectorFieldsUsernameFieldComponent;
