import { get } from '@ember/object';

export default function isDisabled(field, model) {
    if (model && !get(model, 'isNew') && get(field, 'features.lock_value')) {
        for (let set of Array.from(get(field, 'features.lock_value'))) {
            for (let k in set) {
                var needle;
                const v = set[k];
                if (((needle = get(model, k)), Array.from(v.split(',')).includes(needle))) {
                    return true;
                }
            }
        }
    }
    if (typeof get(field, 'features.disabled') === 'object') {
        if (!model) {
            return false;
        }
        const condition = get(field, 'features.disabled.condition');
        if (
            condition &&
            condition.indexOf('$editing') >= 0 &&
            get(field, 'features.disabled.value') === true &&
            !get(model, 'isNew') === true &&
            condition.indexOf('$wap') === -1
        ) {
            return true;
        } else if (
            get(field, 'features.disabled.value') === true &&
            !get(field, 'features.disabled.condition')
        ) {
            return true;
        }
    } else if (get(field, 'form') === 'worktime' && get(field, 'options.form') === 'project') {
        return false;
    } else if (get(field, 'features.disabled')) {
        return true;
    }
    return false;
}
