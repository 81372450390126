/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/history/route.coffee.old
// Generated by CoffeeScript 2.6.1
var Route;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

Route = Ember.Route.extend(AuthenticatedRouteMixin, {
    queryParams: {
        page: {
            refreshModel: true,
        },
    },
    Collector: Ember.inject.service('collector-service'),
    model: function (params) {
        return this.get('Collector').ajax({
            url:
                'service/productHistory' + (params.page ? '?offset=' + (params.page - 1) * 25 : ''),
        });
    },
});

export default Route;
