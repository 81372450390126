/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/saved.coffee.old
// Generated by CoffeeScript 2.6.1
var SavedRoute;

import Ember from 'ember';

import toTop from '../mixins/reset-scroll';

SavedRoute = Ember.Route.extend(toTop, {
    //setupController: (controller, model) ->
    //    unless controller.get('model')
    //        @transitionTo('index')
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            return controller.set('hideAddLinks', false);
        }
    },
});

//controller.set 'afterSave', false
export default SavedRoute;
