import Component from '@ember/component';
import { computed } from '@ember/object';
import { get } from '@ember/object';

export default Component.extend({
    tagName: '',

    color: '#0974B3',
    color2: '#A0A0A0',

    style: '',

    strokeWidth: 30,

    size: computed('custom_size', function () {
        if (get(this, 'custom_size')) {
            return get(this, 'custom_size');
        } else {
            return 220;
        }
    }),
    styleStr: computed('color', 'style', function () {
        if (this.style === 'outline') {
            return `fill-opacity:0;fill-rule:nonzero;stroke:${this.color};stroke-width:${this.strokeWidth}`;
        } else {
            return `fill:${this.color};fill-opacity:1;fill-rule:nonzero;stroke:none`;
        }
    }),

    styleFill: computed('color', function () {
        return `fill:${this.color};fill-opacity:1;fill-rule:nonzero;stroke:none`;
    }),

    styleFillColor2: computed('color2', function () {
        return `fill:${this.color2};fill-opacity:1;fill-rule:nonzero;stroke:none`;
    }),

    viewBox: computed('number', function () {
        return [
            '0 -20 128 128',
            '96 0 155 128',
            '243 -20 128 128',
            '0 0 0 0',
            '0 0 0 0',
            '0 100 85 128',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '265 209 155 128',
            '0 0 0 0',
            '0 0 75.52 74.075',
            '0 0 0 0',
            '0 0 48 74',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '151 421 128 128',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '0 0 0 0',
            '375 537 90 110',
            '469 519 94 140',
            '0 0 0 0',
        ][this.number - 1];
    }),
});
