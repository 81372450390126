/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/contract-list/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanContractListIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

TaxmanContractListIndexController = GeneralReportController.extend({
    form: 'urakkaraportointi',
    showColumns: ['contract_project', 'contract_month', 'contract_employer'],
    queryParams: ['page', 'contract_project', 'contract_month', 'contract_employer'],
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'contract_employer',
            }),
            Em.Object.create({
                field: 'contract_project',
            }),
            Em.Object.create({
                field: 'contract_month',
            }),
        ]);
    }),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: 'taxman.contract-list.edit',
            historyRoute: 'taxman.contract-list.history',
        }),
    }),
});

export default TaxmanContractListIndexController;
