/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport-contract/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportIndexController;

import Ember from 'ember';

TaxmanTaxreportIndexController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    selectedMonth: 0,
    maincontractor: null,
    projectFilter: null,
    key: 'vsurakka',
    contacts: Ember.computed('maincontractor', function () {
        if (this.get('maincontractor')) {
            return this.store.query('exportContactPerson', {
                employer: this.get('maincontractor.id'),
            });
        } else {
            return this.store.findAll('exportContactPerson');
        }
    }),
    contactNotSet: Em.computed('contacts.[]', function () {
        var contacts;
        contacts = this.get('contacts').filter((contact) => {
            return contact.get('isNew') === false;
        });
        return contacts.length <= 0;
    }),
    settings: Em.Object.create({
        form: 'export_contact_person',
    }),
    //get 36 previous months
    months: Ember.computed(function () {
        var count, montharr, obj;
        count = 0;
        montharr = [];
        while (count < 36) {
            obj = {
                id: count,
                name: moment().subtract(count++, 'months').format('MMMM YYYY'),
            };
            montharr.push(obj);
        }
        return montharr;
    }),
    values: Ember.computed('selectedMonth', 'maincontractor', 'projectFilter', function () {
        var arr, moment_month, month;
        month = this.get('selectedMonth');
        moment_month = moment().subtract(month, 'months');
        arr = [
            {
                name: 'contract_month',
                value: moment_month.format('YYYY-MM'),
            },
        ];
        if (this.get('projectFilter')) {
            arr.push({
                name: 'contract_project',
                value: this.get('projectFilter.id'),
            });
        }
        if (this.get('maincontractor') && !this.get('projectFilter')) {
            arr.push({
                name: 'maincontractor',
                value: this.get('maincontractor.id'),
            });
        }
        return arr;
    }),
    employerField: Ember.computed(function () {
        if (this.get('Collector').testNeedsOne('worktime')) {
            return 'worktime.employer';
        } else {
            return 'presence.employer';
        }
    }),
    projectField: Ember.computed(function () {
        if (this.get('Collector').testNeedsOne('worktime')) {
            return 'worktime.project';
        } else {
            return 'presence.project';
        }
    }),
    actions: {
        maincontractorChanged: function (value) {
            return this.set('maincontractor', value);
        },
        refreshPreview: function () {
            return this.notifyPropertyChange('selectedMonth');
        },
        projectChanged: function (value) {
            return this.set('projectFilter', value);
        },
        toggleModal: function (settings, record) {
            if (record) {
                this.notifyPropertyChange('contacts');
            }
            this.toggleProperty('showModal');
            return false;
        },
    },
});

export default TaxmanTaxreportIndexController;
