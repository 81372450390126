/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/timecard/add/timecard-record-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var TimecardRecordCompComponent;

import Ember from 'ember';

import recordComp from '../../../../collector/record-custom-comp/component';

TimecardRecordCompComponent = recordComp.extend({
    layoutName: 'collector/record-comp',
});

export default TimecardRecordCompComponent;
