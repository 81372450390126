import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
    currentPage: 1,

    currentPageComponent: computed('currentPage', function () {
        return 'wizard-builder/test-wizard/page' + get(this, 'currentPage');
    }),
});
