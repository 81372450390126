/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/changepassword/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditChangepasswordRoute;

import Ember from 'ember';

import ChangePasswordRoute from '../../../../routes/changepassword';

UsersEditChangepasswordRoute = ChangePasswordRoute.extend({
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            controller.set('password_changed', false);
            controller.set('currentPassword', null);
            controller.set('password', null);
            controller.set('passwordAgain', null);
        }
        return this._super;
    },
});

export default UsersEditChangepasswordRoute;
