import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get, action } from '@ember/object';
import Ember from 'ember';

export default class InfoCompComponent extends Component {
    @service store;
    @service() notifications;
    @service intl;
    @service session;

    @tracked company;
    @tracked loading;
    @tracked validate;
    @tracked isSaving;

    countries = [
        { id: 'se', name: 'se' },
        { id: 'fi', name: 'fi' },
    ];

    get adminMode() {
        if (Ember.devModeOn || this.session.session.content.authenticated.scope) return true;
        return false;
    }

    constructor() {
        super(...arguments);
        this.loading = true;
        this.setCompanyInfo();
    }

    @action
    async saveCompanyInfo() {
        this.isSaving = true;
        this.validate = false;

        this.validationErrorsFound() ? (this.validate = true) : await this.company.save();
        this.isSaving = false;
        if (!this.validate)
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
    }

    async setCompanyInfo() {
        this.company = get(await this.store.findAll('company-info'), 'firstObject');
        this.loading = false;
    }

    validationErrorsFound() {
        if (!this.company.company_name || this.company.company_name == '') return true;
        if (!this.company.company_vatin || this.company.company_vatin == '') return true;
        return false;
    }
}
