/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditIndexController;

import Ember from 'ember';

import GeneralAddController from '../../../../controllers/general-add';

import $ from 'jquery';

UsersEditIndexController = GeneralAddController.extend({
    session: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    showResetDialog: false,
    UsersAddIndexController: Ember.inject.controller('users/add/index'),
    customization: Em.computed('model', function () {
        var cust, userlevels;
        cust = {
            phone: {
                template: 'users/add/phone',
            },
            username: {
                translation: 'user.edit.username',
            },
            password: {
                translation: 'user.edit.password',
            },
        };
        if (this.get('session.currentUser.userlevel') === '1') {
            cust.password.hide = true;
        }
        if (this.get('model.id') === this.get('session.currentUser.id')) {
            cust.userlevel = {
                features: {
                    disabled: true,
                },
            };
        } else if (
            parseInt(this.get('session.currentUser.userlevel')) < 5 ||
            parseInt(this.get('session.currentUser.userlevel')) === 6
        ) {
            // allow adding roles 1-3 and own roles
            userlevels = Ember.get(this.get('Collector').formField('user', 'userlevel'), 'options');
            cust.userlevel = {
                options: userlevels
                    .filter(function (userlevel) {
                        return !(userlevel === '4' || userlevel === '5' || userlevel === '6');
                    })
                    .join(','),
            };
        } else if (parseInt(this.get('session.currentUser.userlevel')) > 9) {
            cust.userlevel = {
                features: {
                    disabled: false,
                },
            };
        }
        if (this.get('Collector').fieldExists('user', 'taxnumber')) {
            cust.image = {
                template: 'users/add/image',
                features: {
                    hide_preview: true,
                },
            };
        }
        return cust;
    }),
    hasEmail: Em.computed('model.email', function () {
        if (this.get('model.email')) {
            return true;
        }
    }),
    hasPhone: Em.computed('model.phone', function () {
        if (this.get('model.phone')) {
            return true;
        }
    }),
    generatePassword: function () {
        var chars, generated_password, i, rnum, string_length;
        chars = '23456789abcdefghkmnpqrstuvwxyz';
        string_length = 6;
        generated_password = '';
        i = 0;
        while (i < string_length) {
            rnum = Math.floor(Math.random() * chars.length);
            generated_password += chars.substring(rnum, rnum + 1);
            i++;
        }
        return generated_password;
    },
    actions: {
        clear: function () {
            $('.modal').modal('hide');
            return this.set('showResetDialog', false);
        },
        generalAddAction: async function (type, param) {
            if (type === 'getImage') {
                this.set('loading', true);
                await this.UsersAddIndexController.getImage(this.model);
                this.set('loading', false);
            }
            if (type === 'changeCountry') {
                return this.changeCountry(param);
            }
            if (type === 'resetPsw') {
                return this.resetPsw();
            }
        },
        reset_and_send: function () {
            var company, model, psw, username;
            this.set('showResetDialog', false);
            model = this.get('model');
            psw = this.generatePassword();
            model.set('password', psw);
            username = model.get('username');
            company = this.get('session.currentUser.company');
            return model.save().then(() => {
                var email_content, email_title;
                if (this.get('by_email') && model.get('email')) {
                    email_title = this.get('intl').t('changepassword.reset_email_title');
                    email_content = this.get('intl').t('changepassword.reset_email_content', {
                        psw: psw,
                        username: username,
                        company: company,
                    });
                    this.get('notifications').success(this.get('intl').t('general.email_sent'), {
                        autoClear: true,
                    });
                    this.Collector.ttapi({
                        url: 'sendPasswordReset',
                        data: {
                            userid: model.get('id'),
                            title: email_title,
                            content: email_content,
                        },
                        method: 'post',
                    });
                }
                if (this.get('by_sms') && model.get('phone')) {
                    this.get('notifications').success(this.get('intl').t('general.sms_sent'), {
                        autoClear: true,
                    });
                    return this.Collector.ttapi({
                        url: 'sendSms',
                        data: {
                            phone: model.get('phone'),
                            messageid: 'password_reset',
                            values: {
                                userid: model.get('id'),
                            },
                            language: moment.locale(),
                        },
                        method: 'post',
                    });
                }
            });
        },
        removeModal: function () {
            $('.modal').modal('hide');
            return this.set('showResetDialog', false);
        },
    },
    resetPsw: function () {
        this.set('showResetDialog', true);
        return false;
    },
    changeCountry: function (countrycode) {
        var phone, val;
        val = this.get('model.phone');
        if (!val) {
            phone = countrycode;
        } else if (val.slice(0, 1) !== '+') {
            phone = countrycode + this.get('model.phone');
        } else {
            phone = val;
        }
        return this.set('model.phone', phone);
    },
    fieldSettings: Em.computed('model', function () {
        var i_am_this_person;
        if (this.get('session.currentUser.id') === this.get('model.id')) {
            i_am_this_person = true;
        } else {
            i_am_this_person = false;
        }
        return Em.A({
            edit: true,
            own_profile: i_am_this_person,
        });
    }),
});

export default UsersEditIndexController;
