import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { isNone } from '@ember/utils';

export default Component.extend({
    addOptions: computed(function () {
        let options = [];
        const c = get(this, 'Collector');
        if (
            c.testNeeds([
                'worktime',
                '!products.gps_force',
                '!worktime.starttime@disabled',
                '!worktime@errors',
            ])
        ) {
            options.push('worktime');
        }
        if (c.testNeeds(['abcense', '!abcense@errors'])) {
            options.push('abcense');
        }

        return options;
    }),

    summableAbsenceFields: computed(function () {
        const abFields = get(this, 'Collector').fieldArray('abcense');
        const summableTypes = get(this, 'summableFieldTypes');
        return abFields.filter((field) => {
            return summableTypes.includes(get(field, 'type'));
        });
    }),

    absencesByType: computed('absences.[]', function () {
        if (isNone(get(this, 'absences'))) {
            return 0;
        }
        let ret = [];
        let types = get(this, 'absenceTypes');
        types.forEach((type) => {
            let absences = get(this, 'absences').filterBy('abcensetype', type.id);
            ret.push({
                typeId: type.id,
                daySum: get(absences.mapBy('date').uniq(), 'length'),
                rows: absences,
            });
        });
        return ret;
    }),

    init() {
        this._super();
        // Do not show fields with "hide_column" field setting in worktime sums section
        const hideColumnFields = this.Collector.fieldArray('worktime')
            .filter((field) => field.features?.hide_column)
            .mapBy('name');
        this.skipSumFields = hideColumnFields.concat(['id', 'user']);
        this.summableFieldTypes = [
            'number',
            'hours',
            'dropdown_options',
            'dropdown_values',
            'checkbox',
        ];
    },

    actions: {
        sendFilterChange(value, field) {
            this.filterChanged(value, field);
        },
    },
});
