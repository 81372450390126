import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
    collector: service('collector-service'),

    queryParams: ['ori_site'],

    ori_site: null,
    orientations: alias('model'),

    data: computed('orientations', function () {
        // Get the data from the model and convert it to a format that the tree component can use
        // The structure of the data is as follows:
        // [employer.name, parent.name]
        const data = get(this, 'orientations').map((item) => {
            return [get(item, 'ori_employer.name'), get(item, 'parent.name')];
        });

        // Add the parent names of those parents that are not presented as ori_employer in the data
        // After this we have all the parents in the data
        get(this, 'orientations').forEach((item) => {
            const parentName = get(item, 'parent.name') || null;
            const isFoundAlready = data.find((item) => item[0] === parentName);

            if (parentName && !isFoundAlready) {
                data.push([parentName, null]);
            }
        });

        //
        const dataWithoutNulls = data.filter((item) => item[0]);
        const dataWithoutLoops = dataWithoutNulls.filter((item) => item[0] !== item[1]);

        const nestedData = this.convertToNestedArray(dataWithoutLoops);

        const flattenedData = this.flatten(nestedData);

        const result = flattenedData.map((item) => {
            return {
                ...item,
                lastindex: item.level - 1,
                levelAsRange: Array.from({ length: item.level }, (_, index) => index + 1),
            };
        });

        return result;
    }),

    projectField: computed(function () {
        const fieldArray = get(this, 'collector').fieldArray('orientation');
        return fieldArray.findBy('name', 'ori_site');
    }),

    actions: {
        projectChanged(value) {
            set(this, 'ori_site', value ? get(value, 'id') : null);
        },
    },

    convertToNestedArray(rows) {
        // Format the rows to objects
        rows = rows.map((row) => {
            return { name: row[0], parent: row[1], children: [] };
        });

        // Create trees from all the rows
        const trees = [];
        // Rows that have been iterated
        const iteratedRows = [];

        // Cut the loops
        // If there are two rows that have each other as parent, rename parent from one of them
        const loopParents = [];
        rows.forEach((row) => {
            if (row.parent) {
                const parent = rows.find((item) => item.name === row.parent);
                if (parent && parent.parent === row.name) {
                    if (!loopParents.includes(parent.parent)) {
                        parent.parent = parent.parent + ' (esiintyy useassa eri roolissa)';
                        loopParents.push(parent.parent);
                    } else {
                        parent.parent =
                            +' (esiintyy useassa eri roolissa ' +
                            '(' +
                            loopParents.filter((item) => item === parent.parent).length +
                            ')' +
                            ')';
                        loopParents.push(parent.parent);
                    }
                }
            }
        });

        // If row has a parent who has no row in the data, add it to the data
        rows.forEach((row) => {
            if (row.parent && !rows.find((item) => item.name === row.parent)) {
                rows.push({ name: row.parent, parent: null, children: [] });
            }
        });

        const nestRows = (tempRows, levels = 1) => {
            // Copy the rows to avoid modifying the original array
            let rowsCopied = tempRows;

            const iterate = (rows) => {
                for (const row of rows) {
                    if (!row.parent) {
                        trees.push(row);
                        iteratedRows.push(row);
                    } else {
                        const parent = rows.find((item) => item.name === row.parent);
                        if (parent) {
                            parent.children.push(row);
                            iteratedRows.push(row);
                        }
                    }
                }
            };

            let levelsIterated = 0;
            while (levelsIterated < levels) {
                const rows = rowsCopied.filter(
                    (row) => !iteratedRows.find((tree) => tree.name === row.name),
                );
                iterate(rows);
                if (rows.length === 0) break;
                levelsIterated++;
            }
        };

        nestRows(rows, 10);
        return trees;
    },

    flatten(data) {
        const flattenStructure = (node, level, result, breadCrumps, breadCrumpsArray) => {
            result.push({
                name: node.name,
                level,
                hasChildren: node.children.length > 0,
                childrenCount: node.children.length,
                breadCrumps: `${breadCrumps} > ${node.name}`.substring(6),
                breadCrumpsArray: breadCrumpsArray,
            });

            if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                    if (breadCrumpsArray.includes(child.name)) return;

                    flattenStructure(child, level + 1, result, `${breadCrumps} > ${node.name}`, [
                        ...breadCrumpsArray,
                        node.name,
                    ]);
                }
            }
        };

        const result = [];
        flattenStructure({ name: '', children: data }, -1, result, '', []);

        return result.slice(1);
    },
});
