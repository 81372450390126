import Component from '@ember/component';

export default Component.extend({
    tagName: '',
    actions: {
        changeStatus(status) {
            this.changeStatus(status);
        },
    },
});
