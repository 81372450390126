/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/status-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsStatusFieldComponent;

import Ember from 'ember';

import textInput from '../text-field/component';

CollectorFieldsStatusFieldComponent = textInput.extend({
    intl: Ember.inject.service(),
    prompt: Ember.computed('field.prompt', function () {
        if (this.get('field.prompt')) {
            return this.get('field.prompt');
        }
        if (this.get('field.translated_name')) {
            return (
                this.get('intl').t('general.select_with_fieldname') +
                ' ' +
                this.get('field.translated_name').toLowerCase() +
                '...'
            );
        }
        return this.get('intl').t('general.select_choose');
    }),
    options: Ember.computed('field.options', {
        set: function (key, value) {
            return (this._value = value);
        },
        get: function () {
            var intl;
            if (this._value) {
                return this._value;
            }
            intl = this.get('intl');
            return Em.A(
                this.get('field.options').map(function (item) {
                    var name;
                    if (intl.exists('status.' + item)) {
                        name = intl.t('status.' + item);
                    } else {
                        name = item;
                    }
                    return Ember.Object.create({
                        id: item,
                        name: name,
                    });
                }),
            );
        },
    }),
    actions: {
        change: function (id) {
            return this.sendAction('bubbleChange', id, this.get('field.name'));
        },
    },
});

export default CollectorFieldsStatusFieldComponent;
