import Component from '@ember/component';
import { get, set } from '@ember/object';
import config from 'tt4/config/environment';
import { inject as service } from '@ember/service';

export default Component.extend({
    gui: service(),
    error: service(),
    session: service(),
    ajax: service(),

    placeholder: 'this is ge number 2',

    init() {
        this._super();
        this.partnerId = this.session.currentUser.partnerid
        if (get(this, 'gui.saveVismanetCode')) {
            this.signin(get(this, 'gui.saveVismanetCode'));
            set(this, 'gui.saveVismanetCode', null);
        } else this.gotoSignIn();
    },

    gotoSignIn() {
        set(this, 'transtionToVismanet', true);

        let params = ['scope=vismanet', 'redirectRoute=vismanet', 'partnerid=' + this.partnerId];

        window.open(config.APP.VISMASSOURL + '?' + params.join('&'), '_system');
    },

    signin(credentials) {
        const token = this.session.data.authenticated.access_token;

        const data = {
            credentials: credentials,
            key: 'vismanet',
        };

        this.ajax
            .request(config.APP.ONLY_CC_URL + '/saveCredentials', {
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(data),
            })
            .then(this.signedIn.bind(this), this.signinFailed.bind(this));
    },

    signedIn(resp) {
        if (resp.error) {
            set(this, 'errorMessage', get(this, 'error').toString(resp));
            return;
        }

        set(this, 'done', true);
    },

    signinFailed(reason) {
        set(this, 'errorMessage', get(this, 'error').toString(reason));
    },
});
