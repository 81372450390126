/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/mobile-welcome-tour/component.coffee.old
// Generated by CoffeeScript 2.6.1
var MobileWelcomeTourComponent;

import Ember from 'ember';

import config from '../../../config/environment';

MobileWelcomeTourComponent = Ember.Component.extend({
    cdn: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentStep: 0,
    currentStepText: Em.computed('currentStep', function () {
        return this.get('currentStep') + 1;
    }),
    totalSteps: Em.computed(function () {
        return this.get('stepTexts').length;
    }),
    first_step: true,
    last_step: false,
    current_step_text: Em.computed(function () {
        return this.get('stepTexts')[this.get('currentStep')];
    }),
    current_step_image: Em.computed(function () {
        return this.get('cdn').url(
            'images/intro/' +
                this.get('intl.primaryLocale') +
                '/' +
                this.get('stepImages')[this.get('currentStep')],
        );
    }),
    stepTexts: Em.computed(function () {
        return [this.get('intl').t('tour.steps.plus'), this.get('intl').t('tour.steps.hamburger')];
    }),
    stepImages: Em.computed(function () {
        return ['mobile_tour_plus_icon.png', 'mobile_tour_hamburger.png'];
    }),
    actions: {
        close: function () {
            if (this.get('close')) {
                return this.close();
            }
        },
        go: function (dir) {
            var current_step;
            current_step = this.get('currentStep');
            if (dir === 'forward') {
                if (this.get('stepTexts')[current_step + 1] != null) {
                    this.incrementProperty('currentStep');
                }
            } else {
                if (this.get('stepTexts')[current_step - 1] != null) {
                    this.decrementProperty('currentStep');
                }
            }
            if (this.get('stepTexts').length === this.get('currentStep') + 1) {
                this.set('last_step', true);
                this.set('first_step', false);
            }
            if (this.get('currentStep') === 0) {
                this.set('first_step', true);
                this.set('last_step', false);
            }
            this.set('current_step_text', this.get('stepTexts')[this.get('currentStep')]);
            return this.set(
                'current_step_image',
                this.get('cdn').url(
                    'images/intro/' +
                        this.get('intl.primaryLocale') +
                        '/' +
                        this.get('stepImages')[this.get('currentStep')],
                ),
            );
        },
    },
});

export default MobileWelcomeTourComponent;
