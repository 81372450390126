/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/completion-page/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryCompletionController;

import Ember from 'ember';

WorksiteDiaryCompletionController = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    addController: Ember.inject.controller('worksitediary.report.add'),
    added_completions: Ember.computed.alias('addController.model.completion_rate'),
    review_options: Ember.computed(function () {
        var field;
        field = this.get('Collector').field('completion_rate', 'type');
        field = Ember.Object.create(field);
        field.get('options').forEach((item) => {
            return (item.name = this.get('intl').t('completion_rate.type.' + item.value));
        });
        return field.get('options');
    }),
    record: Em.computed(function () {
        return this.get('store').createRecord('completion_rate');
    }),
    ratefield: Em.computed('record', function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('completion_rate', 'rate');
        return field;
    }),
    phaseField: Ember.computed('record', function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('completion_rate', 'phasetitle');
        return field;
    }),
    title: '',
    actions: {
        update_reviews: function (title) {
            return this.set('record.title', title);
        },
        update_rate: function (rate) {
            return this.set('record.rate', rate);
        },
        setField: function (value, field) {
            return this.set('record.' + field, value);
        },
        add_item: function () {
            var record;
            record = this.get('record');
            this.set('record', record);
            this.get('added_completions').pushObject(record);
            record.save();
            this.set('record', this.get('store').createRecord('completion_rate'));
            return this.set('title', '');
        },
        remove_item: function (record) {
            this.set('record', null);
            this.get('added_completions').removeObject(record);
            record.destroyRecord();
            return this.set('record', this.get('store').createRecord('completion_rate'));
        },
    },
});

export default WorksiteDiaryCompletionController;
