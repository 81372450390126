/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/serializers/application.coffee.old
// Generated by CoffeeScript 2.6.1
var ApplicationSerializer;

import RESTSerializer from 'ember-data/serializers/rest';

import DS from 'ember-data';

import { pluralize } from 'ember-inflector';

ApplicationSerializer = RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
    attrs: {
        row_info: {
            embedded: 'always',
            serialize: false,
            deserialize: 'records',
        },
    },
    isNewSerializerAPI: true,
    extractMeta: function (store, type, payload) {
        payload.meta = {
            count: payload.count,
            additional_meta: payload.meta,
        };
        delete payload.count;
        return this._super(store, type, payload);
    },
    serializeIntoHash: function (hash, type, snapshot, options) {
        var record;
        this._super(hash, type, snapshot, options);
        record = snapshot.record;
        if (record.get('validation') === 'off') {
            Ember.assign(hash, {
                validation: record.get('validation'),
            });
        }
        if (record.get('metadata')) {
            Ember.assign(hash, {
                meta: record.get('metadata'),
            });
            record.set('metadata', null);
        }
    },
    serialize: function (snapshot, options) {
        var json;
        json = this._super(snapshot, options);
        return json;
    },
    normalizeQueryResponse: function (store, primaryModelClass, payload, id, requestType) {
        var camelized, data, i, inPayload, item, len, main, r, record, value;
        camelized = Ember.String.camelize(primaryModelClass.modelName);
        main = pluralize(camelized);
        inPayload = payload[Object.keys(payload)[0]];
        if (
            inPayload &&
            inPayload.length > 0 &&
            inPayload[0].values &&
            Object.keys(payload).length === 2
        ) {
            data = {};
            for (item in payload) {
                value = payload[item];
                if (item === 'count') {
                    data[item] = value;
                    continue;
                }
                data[main] = [];
                for (i = 0, len = value.length; i < len; i++) {
                    record = value[i];
                    r = record.values;
                    r.id = record.id;
                    data[main].push(r);
                }
            }
            return this._super(store, primaryModelClass, data, id, requestType);
        } else if (!payload[main]) {
            data = {};
            data[main] = [];
            return this._super(store, primaryModelClass, data, id, requestType);
        }
        return this._super(...arguments);
    },
});

export default ApplicationSerializer;
