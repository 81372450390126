/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/sitesafety-formatted-export.coffee.old
// Generated by CoffeeScript 2.6.1
var SitesafetyFormattedExportMixin;

import Ember from 'ember';

import pdfExport from './pdf-export';

import collector from '../classes/collector';

import { getAssetFolder, getScriptsInOrder } from 'tt4/utils/getScript';

SitesafetyFormattedExportMixin = Ember.Mixin.create(pdfExport, {
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    createMeasurementPdf: function (fields, models, mode = 'download', images = []) {
        var pdf_content;
        pdf_content = [];
        return this.getHeaderLogo().then((logo) => {
            var currentModelNum, modelCount;
            this.set('logo', logo);
            modelCount = models.length;
            currentModelNum = 0;
            models.forEach((model) => {
                var lastModel;
                currentModelNum++;
                if (currentModelNum === modelCount) {
                    lastModel = true;
                } else {
                    lastModel = false;
                }
                return pdf_content.push(this.createPdfPage(fields, model, lastModel, images));
            });
            return Ember.RSVP.Promise.all(pdf_content).then((pdf_content) => {
                return new Em.RSVP.Promise((resolve, reject) => {
                    var assetsfolder, docDefinition;
                    docDefinition = {
                        content: pdf_content,
                        pageSize: 'A4',
                        pageOrientation: 'portrait',
                        footer: (currentPage, pageCount) => {
                            return this.getFooter(currentPage, pageCount);
                        },
                        styles: {
                            tableMainHeader: {
                                fillColor: '#DB4436',
                                color: '#fff',
                                bold: true,
                            },
                            tableSideHeader: {
                                fillColor: '#f0f0f0',
                                bold: true,
                            },
                            tableHeader: {
                                bold: true,
                            },
                            bigTextOK: {
                                fillColor: '#7BD17D',
                                bold: true,
                                fontSize: 20,
                                alignment: 'center',
                            },
                            bigTextWarning: {
                                fillColor: '#FFC56C',
                                bold: true,
                                fontSize: 20,
                                alignment: 'center',
                            },
                            bigTextNotOk: {
                                fillColor: '#FAAFAF',
                                bold: true,
                                fontSize: 20,
                                alignment: 'center',
                            },
                            smallTextOK: {
                                fillColor: '#7BD17D',
                                alignment: 'center',
                            },
                            smallTextWarning: {
                                fillColor: '#FFC56C',
                                alignment: 'center',
                            },
                            smallTextNotOk: {
                                fillColor: '#FAAFAF',
                                alignment: 'center',
                            },
                            table: {
                                fontSize: 8,
                            },
                            footer: {
                                fontSize: 8,
                                color: 'grey',
                            },
                            headertext: {
                                fontSize: 8,
                            },
                        },
                    };
                    //console.log docDefinition
                    //console.log(JSON.stringify(docDefinition, null, 4))
                    //return
                    assetsfolder = getAssetFolder('assets');
                    return getScriptsInOrder([
                        assetsfolder + 'pdfmake_v0.1.68.min.js',
                        assetsfolder + 'vfs_fonts_v0.1.68.min.js',
                    ]).then(() => {
                        if (mode === 'download') {
                            if (typeof cordova !== 'undefined' && cordova !== null) {
                                return createPdf(docDefinition).getBuffer((buffer) => {
                                    var binaryArray, promise, utf8;
                                    utf8 = new Uint8Array(buffer);
                                    binaryArray = utf8.buffer;
                                    promise = this.get('cordova').saveFile(
                                        binaryArray,
                                        'export',
                                        'pdf',
                                    );
                                    return promise.then(function (file) {
                                        resolve(file);
                                        return this.set('logo', null);
                                    });
                                });
                            } else {
                                resolve(createPdf(docDefinition).download('export.pdf'));
                                return this.set('logo', null);
                            }
                        } else if (mode === 'base64') {
                            return createPdf(docDefinition).getBase64((file) => {
                                resolve(file);
                                return this.set('logo', null);
                            });
                        }
                    });
                });
            });
        });
    },
    createPdfPage: function (fields, model, lastModel, images) {
        var field, fieldname, i, id, len, minusArr, notices, plusArr, uniqueFieldname;
        minusArr = [];
        plusArr = [];
        //fields = @get 'fields'
        notices = [];
        //get all plus and minus fields
        for (fieldname in fields) {
            field = fields[fieldname];
            Ember.set(field, 'ext', fieldname);
            if (fieldname.indexOf('_notices') > -1 && model.get(fieldname)) {
                uniqueFieldname = model.get(fieldname).uniq();
                for (i = 0, len = uniqueFieldname.length; i < len; i++) {
                    id = uniqueFieldname[i];
                    notices.push(id);
                }
            }
            //tmp = []
            if (fieldname.indexOf('_minus') > -1) {
                //tmp['minus'] = field
                minusArr.push(field);
            } else if (fieldname.indexOf('_plus') > -1) {
                plusArr.push(field);
            }
        }
        this.set('minusArr', minusArr);
        this.set('plusArr', plusArr);
        this.set('noticesArr', notices);
        return this.formatPdfPage(fields, model, lastModel, images);
    },
    createNoticesTable: function () {
        var notice_ids;
        notice_ids = this.get('noticesArr').join();
        return new Em.RSVP.Promise((resolve) => {
            if (this.get('noticesArr')[0]) {
                return this.get('store')
                    .query('trnotice', {
                        id: notice_ids,
                        sideload: true,
                    })
                    .then((notice_data) => {
                        var notices;
                        notices = [];
                        notices.push([
                            {
                                text: this.get('intl').t('site_safety.notices'),
                                style: 'tableMainHeader',
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                        ]);
                        //notices.push [style: "tableMainHeader", text: @get('intl').t("tr.notices"), colspan: 3]
                        notices.push([
                            {
                                style: 'tableSideHeader',
                                text: '',
                            },
                            {
                                style: 'tableSideHeader',
                                text: this.get('intl').t('report.trnotice.desc'),
                            },
                            {
                                style: 'tableSideHeader',
                                text: this.get('intl').t('report.trnotice.user'),
                            },
                            {
                                style: 'tableSideHeader',
                                text: this.get('intl').t('report.trnotice.date'),
                            },
                        ]);
                        notice_data.forEach((notice, index) => {
                            return notices.push([
                                index + 1,
                                notice.get('desc'),
                                notice.get('user.firstname') + ' ' + notice.get('user.lastname'),
                                moment(notice.get('date')).format('L'),
                            ]);
                        });
                        return resolve({
                            table: {
                                headerRows: 1,
                                widths: [20, '*', '*', '*'],
                                body: notices,
                            },
                        });
                    });
            } else {
                return resolve({});
            }
        });
    },
    createNoticeImages: function () {
        var images, notices, store;
        store = this.get('store');
        images = [];
        this.set('full_pdf_export', true);
        notices = this.get('noticesArr').sort(function (a, b) {
            return b - a;
        });
        notices.forEach((id, index) => {
            var notice;
            notice = store.peekRecord('trnotice', id);
            if (!notice) {
                return;
            }
            if (notice.get('pic')) {
                return notice.get('pic').forEach((image) => {
                    return images.push(this.getImageBase64(image, index));
                });
            }
        });
        return Ember.RSVP.Promise.all(images).then((data) => {
            if (data.length > 0) {
                return {
                    table: {
                        body: data,
                        widths: [20, 500],
                        dontBreakRows: true,
                    },
                    pageBreak: 'before',
                };
            }
        });
    },
    getImageBase64: function (image, index) {
        return new Ember.RSVP.Promise((resolve) => {
            return this.formatImageToPdf(image, 'row', 300).then((data) => {
                return resolve([index + 1, data]);
            });
        });
    },
    createSignaturesTable: function (fields, model) {
        var field, fieldname, signs;
        signs = [];
        for (fieldname in fields) {
            field = fields[fieldname];
            if (field['type'] === 'hdfile') {
                signs.push(field);
            }
        }
        //new Em.RSVP.Promise (resolve, reject) =>
        return this.formatCollectorFieldsToPdfArray(signs, model).then((data) => {
            var i, ind, len, sign, sign_name, signatures, table;
            signatures = [];
            for (ind = i = 0, len = data.length; i < len; ind = ++i) {
                sign = data[ind];
                if (sign.length === 1) {
                    sign = sign[0];
                }
                if (sign && sign.value && sign.value.image) {
                    signatures.push({
                        stack: [
                            {
                                width: 150,
                                image: sign['value']['image'],
                            },
                        ],
                    });
                } else {
                    sign_name = this.get('intl').t('tr.' + signs[ind]['ext']);
                    signatures.push({
                        stack: [' ', ' ', ' ', sign_name],
                    });
                }
            }
            table = {
                table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [signatures],
                },
            };
            if (signatures.length === 2) {
                table.table.body.push([
                    model.get('user.firstname') + ' ' + model.get('user.lastname'),
                    this.get('intl').t('site_safety.print_name') + ':',
                ]);
            } else {
                fields = [];
                signatures.forEach(() => {
                    return fields.push(this.get('intl').t('site_safety.print_name') + ':');
                });
                table.table.body.push(fields);
            }
            return table;
        });
    },
    createMainTable: function (model) {
        var dataArr,
            ext_base_arr,
            field,
            i,
            len,
            minus,
            partialIndex,
            plus,
            ref,
            table,
            totalMin,
            totalPlus,
            translation;
        table = [];
        dataArr = [];
        totalMin = 0;
        totalPlus = 0;
        // get headers (plus-fields are used to get headers)
        // also add data
        dataArr.push([
            {
                style: 'tableMainHeader',
                text: this.get('intl').t('site_safety.measured_item'),
            },
            {
                style: 'tableMainHeader',
                text: this.get('intl').t('tr.plus'),
            },
            {
                style: 'tableMainHeader',
                text: this.get('intl').t('tr.minus'),
            },
            {
                style: 'tableMainHeader',
                text: this.get('intl').t('site_safety.partial_index'),
            },
        ]);
        ref = this.get('plusArr');
        for (i = 0, len = ref.length; i < len; i++) {
            field = ref[i];
            ext_base_arr = field['ext'].split('_');
            plus = model.get(field['ext']);
            minus = model.get(ext_base_arr[0] + '_minus'); // get minus
            translation = this.get('intl').t(
                model.get('constructor.modelName') + '.' + field['ext'],
            );
            if (field.name) {
                translation = field.name;
            }
            partialIndex = (plus / (plus + minus)) * 100;
            dataArr.push([
                {
                    style: 'tableSideHeader',
                    text: translation,
                },
                plus ? plus.toString() : '',
                minus ? minus.toString() : '',
                plus || minus
                    ? {
                          text: partialIndex.toFixed(0) + '%',
                          style: this.partialIndexColor(partialIndex),
                      }
                    : '',
            ]);
            totalMin += Number(minus);
            totalPlus += Number(plus);
        }
        dataArr.push([
            {
                style: 'tableSideHeader',
                text: this.get('intl').t('report.tr.total'),
            },
            totalPlus.toString(),
            totalMin.toString(),
            '',
        ]);
        this.set('totalPlus', totalPlus);
        this.set('totalMin', totalMin);
        table = {
            table: {
                headerRows: 1,
                widths: ['*', 50, 50, 60],
                body: dataArr,
            },
        };
        return table;
    },
    ratingColor: function (rating) {
        if (rating > 95) {
            return 'bigTextOK';
        } else if (rating > 90) {
            return 'bigTextWarning';
        } else {
            return 'bigTextNotOk';
        }
    },
    partialIndexColor: function (rating) {
        if (rating > 95) {
            return 'smallTextOK';
        } else if (rating > 90) {
            return 'smallTextWarning';
        } else {
            return 'smallTextNotOk';
        }
    },
    createMeasurementSumTable: function (model) {
        var dataArr, rating, style, table, text;
        dataArr = [];
        if (model.get('constructor.modelName') === 'mvr') {
            rating = model.get('mvr_rating');
        } else {
            rating = model.get('tr_rating');
        }
        text = this.get('intl').t('measurement.rating');
        style = this.ratingColor(rating);
        dataArr.push({
            style: 'tableSideHeader',
            text: text,
        });
        dataArr.push({
            style: style,
            text: rating + '%',
        });
        table = {
            table: {
                widths: ['*', 80],
                body: [dataArr],
            },
        };
        return table;
    },
    addEmptySpace: function (margin = false) {
        var object;
        margin = margin ? margin : 20;
        object = {
            text: '',
            margin: [0, margin, 0, 0],
        };
        return object;
    },
    createAveragePercentNumber: function (model) {
        var dates;
        //model.get('constructor.modelName')
        //console.log model.get 'project.id'
        dates =
            moment().subtract(12, 'month').format('YYYY-MM-DD') +
            '_' +
            moment().format('YYYY-MM-DD');
        return new Em.RSVP.Promise((resolve, reject) => {
            //found = @get('store').peekRecord(model.get('constructor.modelName'),model.get('id'))
            //console.log found
            return this.get('store')
                .query(model.get('constructor.modelName'), {
                    project: model.get('project.id'),
                    date: dates,
                })
                .then((data) => {
                    var average, rating, ratings, rowCount, table;
                    ratings = 0;
                    rowCount = data.get('meta.count');
                    rating =
                        model.get('constructor.modelName') === 'mvr' ? 'mvr_rating' : 'tr_rating';
                    data.forEach((row) => {
                        return (ratings += row.get(rating));
                    });
                    average = ratings / rowCount;
                    table = {
                        table: {
                            body: [
                                [
                                    {
                                        text:
                                            this.get('intl').t('measurement.rating') +
                                            ', ' +
                                            this.get('intl').t('tr.chart.average') +
                                            ', ' +
                                            this.get('intl').t('tr.last_12_months'),
                                        style: 'tableMainHeader',
                                    },
                                ],
                                [
                                    {
                                        text: average.toFixed(2) + '%',
                                        style: this.ratingColor(average),
                                    },
                                ],
                            ],
                        },
                    };
                    return resolve(table);
                });
        });
    },
    formatPdfPage: function (fields, model, lastModel, images) {
        var pdf_content;
        //return new Em.RSVP.Promise (resolve, reject) =>

        //@getHeader().then (header) =>
        pdf_content = [];
        // this is some ugly-ass code right here, should maybe be made to use array of promises or something
        return this.createNoticesTable().then((notices) => {
            return this.createNoticeImages().then((notice_images) => {
                return this.createSignaturesTable(fields, model).then((signatures) => {
                    return this.createAveragePercentNumber(model).then((average) => {
                        // first add movenium-logo and additional info about worksite, company etc
                        pdf_content.push(this.createHeaders(model));
                        // next add main-table
                        pdf_content.push(this.createMainTable(model));
                        // then add tr-level table
                        pdf_content.push(this.createMeasurementSumTable(model));
                        // add empty space
                        pdf_content.push(this.addEmptySpace());
                        // after that add notices
                        if (notices) {
                            pdf_content.push(notices);
                        }
                        // add empty space
                        pdf_content.push(this.addEmptySpace());
                        // last one, signatures
                        pdf_content.push(signatures);
                        // last one +1 (average percent)
                        pdf_content.push(this.addEmptySpace(40));
                        pdf_content.push(average);
                        images.forEach((image, index) => {
                            if (image.title) {
                                pdf_content.push({
                                    text: image.title,
                                    headlineLevel: 4,
                                    pageBreak: index === 0 ? 'before' : '',
                                });
                            }
                            return pdf_content.push({
                                image: image.url,
                                width: image.width ? image.width : 500,
                                pageBreak: index === 0 && !image.title ? 'before' : '',
                            });
                        });
                        if (notice_images) {
                            pdf_content.push(notice_images);
                        }
                        // then add page-break
                        if (!lastModel) {
                            pdf_content.push({
                                text: '',
                                pageBreak: 'after',
                            });
                        }
                        return pdf_content;
                    });
                });
            });
        });
    },
    createHeaders: function (model) {
        var logo, right_header;
        // get data for right-hand header
        right_header = [];
        right_header.push(
            this.get('intl').t('report.tr.project') +
                ': ' +
                model.get('project.number') +
                ' ' +
                model.get('project.name'),
        );
        right_header.push(
            this.get('intl').t('report.tr.date') + ': ' + moment(model.get('date')).format('L'),
        );
        right_header.push(
            this.get('intl').t('report.tr.inspector') +
                ': ' +
                model.get('user.firstname') +
                ' ' +
                model.get('user.lastname'),
        );
        right_header.push(
            this.get('intl').t('report.tr.company_name') +
                ': ' +
                this.get('session.currentUser.company_info.company_name'),
        );
        logo = this.get('logo');
        return {
            columns: [
                [
                    {
                        fit: [120, 40],
                        image: logo,
                        margin: [0, 0, 0, 20],
                    },
                ],
                {
                    width: '*',
                    stack: right_header,
                    alignment: 'right',
                    fontSize: 10,
                    margin: [0, 0, 0, 20],
                },
            ],
        };
    },
    getHeaderLogo: function () {
        var c;
        c = this.get('Collector');
        return new Em.RSVP.Promise((resolve, reject) => {
            return c.getLogo().then((logo) => {
                var company, session;
                if (logo) {
                    this.set('logo', logo);
                }
                session = this.get('session');
                company = session.get('currentUser.company');
                if (!logo) {
                    logo = this.get('moveniumLogo');
                }
                return resolve(logo);
            });
        });
    },
});

export default SitesafetyFormattedExportMixin;
