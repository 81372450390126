/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceReportRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

import { inject as service } from '@ember/service';

PresenceReportRoute = GeneralReportRoute.extend({
    form: 'presence',
    flags: service(),
    queryParams: {
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-presence')) {
            params.sideload = true;
            return this.store.query('presence', params);
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('presences.report').set('qParams', params);
        }
    },
});

export default PresenceReportRoute;
