import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class saldoInsightsRowComponent extends Component {
    @service store;
    @service('collector-service') Collector;

    @tracked row;
    @tracked showDetails;

    constructor() {
        super(...arguments);
        this.showDetails = false;
        this.saldoTotal = this.calsSaldoSum();
        this.hourFields = ['minutes', 'saldo'];
        this.rows = this.args.rows.details;
    }

    async calsSaldoSum() {
        let total = 0;
        await this.args.rows.details.forEach((row) => {
            if (row.saldo) total += row.saldo;
        });
        return total;
    }

    @action
    showRowDetails() {
        if (this.showDetails) this.showDetails = false;
        else this.showDetails = true;
    }
}
