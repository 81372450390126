/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/location/route.coffee.old
// Generated by CoffeeScript 2.6.1
var Route;

import Ember from 'ember';

import config from '../../../config/environment';

import GeneralReportRoute from '../../../routes/general-report';

Route = Ember.Route.extend({
    session: Ember.inject.service(),
    collector: Ember.inject.service('collector-service'),
    queryParams: {
        date: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        order: {
            refreshModel: true,
        },
    },
    backendurl: Ember.computed(function () {
        return Ember.get(config, 'APP.BEACONBACKENDURL');
    }),
    model: function (params) {
        var beaconDataArr, limit, order, promise, promises, user_params, usrs, wirepas;
        beaconDataArr = {};
        promises = [];
        limit = 10;
        order = null;
        if ((params.order = 'name')) {
            order = 'lastname';
        } else if ((params.order = 'name-')) {
            order = 'lastname-';
        }
        params.offset = params.page != null ? (params.page - 1) * limit : 0;
        wirepas = this.get('collector').testNeeds(['user.wirepas']) ? true : false;
        user_params = {
            limit: limit,
            offset: params.offset,
            order: order,
        };
        if (wirepas) {
            user_params.wirepas = '!(empty)';
        } else {
            user_params.phone_imei = '!(empty)';
        }
        usrs = this.get('store').query('user', user_params);
        promise = new Em.RSVP.Promise((resolve) => {
            return usrs.then((users) => {
                users.forEach((user) => {
                    var name;
                    name = user.get('firstname') + ' ' + user.get('lastname');
                    return promises.push(
                        $.ajax({
                            url:
                                this.get('backendurl') +
                                (wirepas ? '/rigado/getBulks' : '/beacon/getBulks'),
                            data: {
                                partnerid: this.get('session.currentUser.services.partnerid'),
                                maxpause: 20,
                                scanlevel: 0,
                                resolution: 15,
                                imei: user.get(wirepas ? 'wirepas' : 'phone_imei'),
                                date: params.date,
                            },
                            success: (data) => {
                                return (beaconDataArr[name] = data);
                            },
                        }),
                    );
                });
                return Em.RSVP.Promise.all(promises).then(() => {
                    return resolve();
                });
            });
        });
        return promise.then(() => {
            return Ember.RSVP.hash({
                beaconData: beaconDataArr,
                users: usrs,
            });
        });
    },
});

export default Route;
