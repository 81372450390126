/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/csv-export.coffee.old
// Generated by CoffeeScript 2.6.1
var CsvExportMixin;

import Ember from 'ember';

import baseExport from './base-export';

CsvExportMixin = Ember.Mixin.create(baseExport, {
    createCsvElement: function (columns, rows, translated_columns = null, sumRow = null) {
        if (!translated_columns) {
            translated_columns = columns;
        }
        return this.getTable(columns, rows, translated_columns, sumRow);
    },
    createCsvFromElements: function (element, skip_headers = true) {
        var a, blobObj, company, csvString, csv_str, j, key, len, mapped_row, name, row;
        csv_str = [];
        for (key = j = 0, len = element.length; j < len; key = ++j) {
            row = element[key];
            if (key === 0 && skip_headers) {
                continue;
            }
            mapped_row = row.map(function (item) {
                //remove linebreaks from csv string to avoid invalid format
                if (Ember.typeOf(item) === 'string') {
                    item.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
                }
                return item;
            });
            csv_str.push(mapped_row.join(';'));
        }
        name = 'movenium_export';
        company = this.get('session.currentUser.company');
        if (company) {
            name = company;
        }
        //create download for android app
        if (typeof LocalFileSystem !== 'undefined' && LocalFileSystem !== null) {
            return window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fileSystem) {
                return fileSystem.root.getFile(
                    name + '.csv',
                    {
                        create: true,
                    },
                    function (entry) {
                        var fileEntry;
                        fileEntry = entry;
                        return entry.createWriter(function (writer) {
                            var csvString;
                            //write file and open
                            writer.onwrite = function (evt) {
                                return cordova.plugins.fileOpener2.open(
                                    fileEntry.toURL(),
                                    'text/csv',
                                );
                            };
                            csvString = csv_str.join('\n');
                            return writer.write(encodeURIComponent(csvString));
                        });
                    },
                );
            });
        } else {
            //download csv for desktop
            csvString = csv_str.join('\n');
            if (window.Blob && window.navigator.msSaveOrOpenBlob) {
                blobObj = new Blob(['\uFEFF' + csvString], {
                    type: 'text/csv',
                });
                return window.navigator.msSaveBlob(blobObj, name + '.csv');
            } else {
                a = document.createElement('a');
                a.href = 'data:attachment/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvString);
                a.download = name + '.csv';
                a.style.display = 'none';
                document.body.appendChild(a);
                return a.click();
            }
        }
    },
    createMultiCsv: function (csvString) {
        var a, blobObj, company, name;
        name = 'movenium_export';
        company = this.get('session.currentUser.company');
        if (company) {
            name = company;
        }
        //create download for android app
        if (typeof LocalFileSystem !== 'undefined' && LocalFileSystem !== null) {
            return window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fileSystem) {
                return fileSystem.root.getFile(
                    name + '.csv',
                    {
                        create: true,
                    },
                    function (entry) {
                        var fileEntry;
                        fileEntry = entry;
                        return entry.createWriter(function (writer) {
                            //write file and open
                            writer.onwrite = function (evt) {
                                return cordova.plugins.fileOpener2.open(
                                    fileEntry.toURL(),
                                    'text/csv',
                                );
                            };
                            return writer.write(encodeURIComponent(csvString));
                        });
                    },
                );
            });
        } else {
            //download csv for desktop
            if (window.Blob && window.navigator.msSaveOrOpenBlob) {
                blobObj = new Blob(['\uFEFF' + csvString], {
                    type: 'text/csv',
                });
                return window.navigator.msSaveBlob(blobObj, name + '.csv');
            } else {
                a = document.createElement('a');
                a.href = 'data:attachment/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvString);
                a.download = name + '.csv';
                a.style.display = 'none';
                document.body.appendChild(a);
                return a.click();
            }
        }
    },
    createMultiTableCsv: function (elements, headers = false, mainHeader) {
        var csv_multitable, csv_str, element, i, j, k, key, len, len1, mapped_row, row;
        csv_multitable = [];
        csv_multitable.push(mainHeader + '\n');
        i = 0;
        for (j = 0, len = elements.length; j < len; j++) {
            element = elements[j];
            csv_str = [];
            csv_str.push(headers[i]);
            for (key = k = 0, len1 = element.length; k < len1; key = ++k) {
                row = element[key];
                mapped_row = row.map(function (item) {
                    //remove linebreaks from csv string to avoid invalid format
                    if (Ember.typeOf(item) === 'string') {
                        return item.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
                    }
                });
                csv_str.push(mapped_row.join(';'));
            }
            csv_multitable.push(csv_str.join('\n'));
            i++;
        }
        //csv_multitable.join("\n\n")
        return this.createMultiCsv(csv_multitable.join('\n\n'));
    },
});

export default CsvExportMixin;
