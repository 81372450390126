/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/week-report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerWeekReportRoute;

import Ember from 'ember';

import collector from '../../../classes/collector';

TimetrackerWeekReportRoute = Ember.Route.extend({
    Collector: Em.inject.service('collector-service'),
    queryParams: {
        date: {
            refreshModel: true,
        },
    },
    currentWeek:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    model: function (params) {
        var c, date, sideloaded, split_date, worktime_params, worktimesArr;
        c = this.get('Collector');
        //params = @._super(params)
        date = params.date || this.currentWeek;
        worktime_params = {};
        worktime_params.sideload = true;
        worktime_params.user = 'self'; // get only own worktimes
        worktime_params.date = date;
        worktime_params.order = 'date-,starttime-';
        split_date = date.split('_');
        sideloaded = [];
        sideloaded.push('abcensetype.name');
        worktimesArr = [];
        return this.store.query('worktime', worktime_params).then((worktimes) => {
            var absPromise;
            worktimes.forEach((row) => {
                return worktimesArr.push(row);
            });
            absPromise = new Em.RSVP.Promise((resolve, reject) => {
                var absences;
                if (c.testNeedsOne('abcense')) {
                    absences = this.get('Collector').ttapi({
                        url: 'absence/split',
                        data: {
                            startdate: split_date[0],
                            enddate: split_date[1],
                            sideload: sideloaded,
                            order: 'startdate-',
                            tt3flag: collector.get('tt3flag'),
                            user: 'self',
                        },
                    });
                    return absences.then((abs) => {
                        return resolve(abs);
                    });
                } else {
                    return resolve([]);
                }
            });
            return absPromise.then((abs) => {
                var model;
                //model = worktimesArr.concat(abs).sortBy("date")
                model = abs.concat(worktimesArr).sortBy('date');
                return Em.RSVP.hash({
                    combined: model,
                });
            });
        });
    },
});

//worktimes: @store.query('worktime', worktime_params)
//absences: abs
export default TimetrackerWeekReportRoute;
