import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelectWithCreate\n    @class={{this._class}}\n    @name={{this.name}}\n    @dropdownClass=\"power-select-add-field\"\n    @tagName=\"div\"\n    @allowClear={{true}}\n    @selected={{@value}}\n    @preventScroll={{true}}\n    @placeholder={{this.placeholder}}\n    @options={{await this.options}}\n    @loadingMessage={{t \"general.loading\"}}\n    @disabled={{this.disabled}}\n    @searchEnabled={{true}}\n    @search={{fn this.search}}\n    @onOpen={{fn (mut this.isopen) true}}\n    @onClose={{fn (mut this.isopen) false}}\n    @onCreate={{this.createNew}}\n    @showCreateWhen={{this.showOrHideCreate}}\n    @showCreatePosition=\"bottom\"\n    @buildSuggestion={{this.customSuggestion}}\n    @onChange={{this.onChange}} as |record|>\n    {{#each this.subformFields as |field|}}\n        {{get record field}}\n    {{/each}}\n</PowerSelectWithCreate>", {"contents":"<PowerSelectWithCreate\n    @class={{this._class}}\n    @name={{this.name}}\n    @dropdownClass=\"power-select-add-field\"\n    @tagName=\"div\"\n    @allowClear={{true}}\n    @selected={{@value}}\n    @preventScroll={{true}}\n    @placeholder={{this.placeholder}}\n    @options={{await this.options}}\n    @loadingMessage={{t \"general.loading\"}}\n    @disabled={{this.disabled}}\n    @searchEnabled={{true}}\n    @search={{fn this.search}}\n    @onOpen={{fn (mut this.isopen) true}}\n    @onClose={{fn (mut this.isopen) false}}\n    @onCreate={{this.createNew}}\n    @showCreateWhen={{this.showOrHideCreate}}\n    @showCreatePosition=\"bottom\"\n    @buildSuggestion={{this.customSuggestion}}\n    @onChange={{this.onChange}} as |record|>\n    {{#each this.subformFields as |field|}}\n        {{get record field}}\n    {{/each}}\n</PowerSelectWithCreate>","moduleName":"tt4/components/addon/fields/database-gps-force-field.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/database-gps-force-field.hbs"}});
import DatabaseField from './database';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import GpsService from 'tt4/services/gps2';
import { IRecord } from 'tt4/ember-movenium/interfaces/record';
import EventedService from 'tt4/injections/services/need';
import RSVP from 'rsvp';

interface IPosition {
    longitude: string;
    latitude: string;
}
interface GpsPositionEvent {
    lockedPosition: {
        latitude: number;
        longitude: number;
    };
}
export default class FieldsDatabaseGpsForceFieldComponent extends DatabaseField {
    @tracked protected gpsOptions: object[] | null = null;
    @service('gps2' as any) declare gps: GpsService;
    @service private evented: EventedService;

    @tracked reTriggerOptions: boolean = false;
    // Added properties to store the last known coordinates
    @tracked private lastKnownLatitude: number | null = null;
    @tracked private lastKnownLongitude: number | null = null;

    constructor(owner: any, args: any) {
        super(owner, args);
        this.gps.start();
        // when gps position changes, we have to re-fetch options so that those contain correct projects based on gps location..
        this.evented.on('gpsLockedPositionChanged', this, (newPosition: GpsPositionEvent) => {
            // ..but when the position has changed gps2 service will emit the event first with the old position so we need to check if the position has actually changed and only then retrigger the options
            // this will prevent the options from being re-fetched twice when the position has changed
            if (this.hasPositionChanged(newPosition.lockedPosition)) {
                this.reTriggerOptions = !this.reTriggerOptions;
                // Update the last known position
                this.lastKnownLatitude = newPosition.lockedPosition.latitude;
                this.lastKnownLongitude = newPosition.lockedPosition.longitude;
            }
        });
    }

    // Check if the new position is different from the last known position
    private hasPositionChanged(newPosition: { latitude: number; longitude: number }): boolean {
        return (
            this.lastKnownLatitude !== newPosition.latitude ||
            this.lastKnownLongitude !== newPosition.longitude
        );
    }

    @computed('subform', 'subformFields', 'args.filter', 'reTriggerOptions')
    public get options() {
        return new RSVP.Promise((resolve) => {
            this.performSearch(null, async (options: any) => {
                return resolve(options);
            });
        });
    }

    protected async performSearch(term: any, resolve: (arg0: object[]) => Promise<void>) {
        super.performSearch(term, async (normalProjects: IRecord) => {
            let forcedGpsProjects = await this.getForcedGpsProjects(term);

            if (forcedGpsProjects) {
                forcedGpsProjects = forcedGpsProjects.filter((project: IRecord) => {
                    return (
                        this.gps.getDistance(
                            this.gps.lockedPosition as IPosition,
                            JSON.parse(project.location_map),
                        ) < this.gps.allowedDistance
                    );
                });
            }

            resolve([
                {
                    groupName: this.intl.t('gps.forced_projects'),
                    options: forcedGpsProjects ? forcedGpsProjects.toArray() : [],
                },
                {
                    groupName: this.intl.t('gps.normal_projects'),
                    options: normalProjects ? normalProjects.toArray() : [],
                },
            ]);
        });
    }

    /**
     * Create queryParams for options fetching from backend. This overrides DatabaseField's method and adds additional queryparams
     * @method createQueryParams
     * @param {String} term Search term given by user
     * @param {String} id Parameter if you want to get only known record by id
     * @return {object}
     */
    protected createQueryParams(term = null, id = null): object {
        const params = {
            form: this.form,
            field: this.name,
            force_location: 'off',
            limit: '20',
        } as any;

        if (id) params.id = id;
        if (term) params.q = term;

        if (this.filter) {
            for (const key of Object.keys(this.filter)) {
                if (this.filter[key]) {
                    params[key] = this.filter[key].id;
                }
            }
        }

        return params;
    }

    private async getForcedGpsProjects(term: string | null = null): Promise<any> {
        if (!this.gps.lockedPosition) return null;
        const params = {
            force_location: 'on',
            location_map: '!(empty)',
            limit: 'all',
        } as any;

        if (term) params.q = term;

        return this.store.query('project', params);
    }
}
