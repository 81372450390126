/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/fileupload-field-ver3/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsFileUploadFieldComponent;

import Ember from 'ember';

import config from '../../../../config/environment';
import { blackListFiles } from 'tt4/ember-movenium/utils/file';
import { get, set, computed } from '@ember/object';

import File from 'ember-file-upload/file';

import collector from 'tt4/classes/collector';

import { resizeImage } from 'tt4/utils/file-util';

CollectorFieldsFileUploadFieldComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    gui: Ember.inject.service(),
    capi: Ember.inject.service(),
    FileQueue: Ember.inject.service(),
    notifications: Ember.inject.service(),
    is_image_file: Ember.computed.alias('field.features.is_image'),
    // cache for saving base64 images to show in preview before those are even sent
    base64imageCache: {},
    base64Cache: {},
    testQueue: {
        files: [
            {
                name: 'testlongsafaslfsdsdf sdfsdf.txt',
                progress: 20,
            },
            {
                name: 'test2.txt',
                progress: 70,
            },
        ],
    },
    is_image_file: computed.alias('field.features.is_image'),
    single_file: computed.alias('field.features.single_file'),
    is_public: computed.alias('field.features.public'),
    init: function () {
        this._super();
        return (this.value = this.get('field.value'));
    },
    showAddButton: computed('single_file', 'value.[]', function () {
        if (this.get('single_file') && this.get('value.length') > 0) {
            return false;
        } else if (this.get('gui.isIE')) {
            return false;
        } else {
            return true;
        }
    }),
    addButtonText: computed(function () {
        if (this.get('field.form') === 'logo') {
            return this.get('intl').t('add.logo');
        } else if (this.get('is_image_file') && this.get('gui.isAndroid')) {
            return this.get('intl').t('field.fileupload.add_from_camera');
        } else if (this.get('is_image_file')) {
            return this.get('intl').t('add.image');
        } else {
            return this.get('intl').t('add.file');
        }
    }),
    queueName: computed('field.form', 'field.name', function () {
        return (
            'files_' +
            this.get('field.form') +
            '.' +
            this.get('field.name') +
            '.' +
            Math.random().toString(36).substring(7)
        );
    }),
    imageTypes: [
        'image/gif',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/svg',
        'image/bmp',
    ],
    exeTypes: [
        'application/octet-stream',
        'application/x-msdownload',
        'application/exe',
        'application/x-exe',
        'application/dos-exe',
        'vms/exe',
        'application/x-winexe',
        'application/msdos-windows',
        'application/x-msdos-program',
    ],
    isImageType: function (type) {
        return this.imageTypes.indexOf(type) !== -1;
    },
    addFile: function (file) {
        if (!Ember.isArray(this.get('value'))) {
            this.set('value', []);
        }
        this.get('value').pushObject(file);
        return this.send('change', this.get('value'));
    },
    fileList: computed('value.[]', 'awsurl', function () {
        if (!this.get('value')) {
            return;
        }
        return this.get('value').map((file) => {
            var fileObj;
            fileObj = {
                name: get(file, 'name'),
                type: get(file, 'type'),
                originalFile: file,
            };
            if (this.isImageType(get(file, 'type'))) {
                fileObj.s3thumb = get(file, 's3thumb')
                    ? get(file, 's3thumb')
                    : this.get('base64imageCache')[get(file, 'name_on_hd')];
                fileObj.previewFile = fileObj.s3thumb;
            } else {
                if (get(file, 'url')) {
                    fileObj.previewFile = this.get('Collector').makeFileUrl(
                        collector.api_url() + '/',
                        get(file, 'url'),
                    );
                } else {
                    fileObj.previewFile = this.get('base64Cache')[get(file, 'name_on_hd')];
                }
            }
            return fileObj;
        });
    }),
    uploadFile: function (file, isImageType = false) {
        const fileExt = file.name?.split('.')[1].toLowerCase();
        if (blackListFiles().includes('.' + fileExt)) {
            get(this, 'FileQueue.files').removeObject(file);
            return this.notifications.error(this.intl.t('field.fileupload.blocked_filetype'), {
                autoClear: true,
            });
        }
        var policyurl;
        policyurl = 'awsurl?filename=' + get(file, 'name') + '&format=policy';
        if (this.get('is_public')) {
            policyurl += '&public=true';
        }
        if (isImageType) {
            policyurl += '&thumb=true';
        }
        return this.get('capi')
            .request(policyurl)
            .then(
                (response) => {
                    var fileObj, task;
                    fileObj = {
                        name: get(file, 'name'),
                        type: get(file, 'type'),
                        name_on_hd: response.s3file,
                    };
                    task = file.upload(response.url, {
                        data: response.inputs,
                    });
                    if (isImageType) {
                        this.createThumb(get(file, 'name'), get(file, 'type'), file.blob, response);
                        file.readAsDataURL().then((base64Url) => {
                            this.get('base64imageCache')[response.s3file] = base64Url;
                            return this.notifyPropertyChange('fileList');
                        });
                    } else {
                        file.readAsDataURL().then((url) => {
                            this.get('base64Cache')[response.s3file] = url;
                            return this.notifyPropertyChange('fileList');
                        });
                    }
                    task.then((response) => {
                        return this.notifyPropertyChange('fileList');
                    });
                    file.task = task;
                    file.name_on_hd = response.s3file;
                    return this.addFile(fileObj);
                },
                (reason) => {
                    return alert('upload failed');
                },
            );
    },
    createThumb: function (filename, type, blob, policyResponse) {
        return this.resizeImage(blob).then((resized) => {
            var resizedImageFile;
            resizedImageFile = File.fromDataURL(resized);
            set(resizedImageFile, 'name', filename);
            set(resizedImageFile, 'type', type);
            return resizedImageFile.upload(policyResponse.thumb.url, {
                data: policyResponse.thumb.inputs,
            });
        });
    },
    resizeImage: function (file, pixels = 1600) {
        return new Ember.RSVP.Promise((resolve) => {
            return resizeImage(file).then((resized) => {
                return resolve(resized);
            });
        });
    },
    actions: {
        uploadImage: function (file) {
            if (get(file, 'size') > 20971520) {
                return this.get('notifications').error(
                    this.get('intl').t('field.fileupload.file_too_large'),
                    {
                        autoClear: true,
                    },
                );
            }
            if (this.isImageType(get(file, 'type'))) {
                return this.resizeImage(file.blob).then((resized) => {
                    var queue, resizedImageFile;
                    queue = get(this, 'FileQueue').find(this.get('queueName'));
                    // original file must be removed from queue by hand
                    get(this, 'FileQueue.files').removeObject(file);
                    resizedImageFile = File.fromDataURL(resized);
                    set(resizedImageFile, 'name', get(file, 'name'));
                    set(resizedImageFile, 'type', get(file, 'type'));
                    get(this, 'FileQueue.files').pushObject(resizedImageFile);
                    get(queue, 'files').pushObject(resizedImageFile);
                    return this.uploadFile(resizedImageFile, true);
                });
            } else {
                return this.uploadFile(file);
            }
        },
        abort: function (file) {
            this.get('value').removeObject(this.get('value').findBy('name_on_hd', file.name_on_hd));
            this.send('change', this.get('value'));
            return file.task.cancel();
        },
        addFile: function (source = null) {
            var options;
            options = {
                allowEdit: false,
                targetWidth: 900,
                targetHeight: 900,
                quality: 50,
                destinationType: Camera.DestinationType.FILE_URI,
                sourceType:
                    source === 'gallery'
                        ? Camera.PictureSourceType.PHOTOLIBRARY
                        : Camera.PictureSourceType.CAMERA,
                mediaType: Camera.MediaType.PICTURE,
                encodingType: Camera.EncodingType.JPEG,
                correctOrientation: true,
                encodingType: 0,
            };
            return navigator.camera.getPicture(
                (imageData) => {
                    return window.resolveLocalFileSystemURL(
                        imageData,
                        (fileEntry) => {
                            return fileEntry.file((file) => {
                                file.type = 'image/jpeg';
                                file.name = file.name.replace(
                                    'image.',
                                    'image' + moment().format('YYYY-MM-DD-hh-mm-ss') + '.',
                                );
                                return this.send('uploadImage', {
                                    blob: file,
                                    name: file.name,
                                    type: file.type,
                                });
                            });
                        },
                        function (e) {
                            throw new Ember.Error(e);
                        },
                    );
                },
                function (message) {
                    throw new Ember.Error(message);
                },
                options,
            );
        },
        removeFile: function (file) {
            if (this.showModal) {
                this.set('showModal', false);
            }
            this.get('value').removeObject(file);
            return this.send('change', this.get('value'));
        },
        change: function (value) {
            this.set('value', value);
            return this.sendAction('bubbleChange', value, this.get('field.name'));
        },
    },
});

export default CollectorFieldsFileUploadFieldComponent;
