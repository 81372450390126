import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class saldoInsightsDetailsComponent extends Component {
    @service store;
    @service('collector-service') Collector;

    @tracked row;
    @tracked showDetails;

    constructor() {
        super(...arguments);
        this.showDetails = false;
        this.data = this.getData();
    }

    async getData() {
        return await this.args.row.datarow;
    }

    @action
    toggleThisRowDetails(row) {
        this.args.toggleRowDetails(row);
    }
}
