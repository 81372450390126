/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryReportController, computed, controller, get, service, set;

import Ember from 'ember';

({
    computed,
    inject: { service, controller },
    set,
    get,
} = Ember);

DiaryReportController = Ember.Controller.extend({
    Collector: service('collector-service'),
    dateController: controller('diary.report.date'),
    projectController: controller('diary.report.date.project'),
    date: computed.alias('dateController.model.date'),
    project: computed.alias('projectController.model.project'),
    field: computed('date', function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('diary', 'date');
        set(field, 'value', this.get('date'));
        return field;
    }),
    actions: {
        setValue: function (value, field) {
            if (moment(value).isValid()) {
                if (this.get('project')) {
                    return this.transitionToRoute(
                        'diary.report.date.project',
                        value,
                        this.get('project.id'),
                    );
                } else {
                    return this.transitionToRoute('diary.report.date', value);
                }
            }
        },
    },
});

export default DiaryReportController;
