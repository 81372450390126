import Component from '@ember/component';

export default Component.extend({
    tagName: 'tr',
    classNames: ['settings-table-row'],

    actions: {
        rowAction(action, row) {
            this.action(action, row);
        },
    },
});
