import Component from '@ember/component';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    analytics: service(),

    tooltipDirection: 'right',
    classNames: ['wizard-info-button'],

    init() {
        this._super(...arguments);
        this.popperOptions = {
            modifiers: {
                preventOverflow: {
                    escapeWithReference: false,
                },
            },
        };
    },

    actions: {
        trackAnalyticsEvent() {
            get(this, 'analytics').trackEvent({
                category: 'Onboarding-wizard',
                action: 'info-button',
            });
        },
    },
});
