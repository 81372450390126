/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/record-view-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var ColletorRecordViewCompComponent;

import Ember from 'ember';

ColletorRecordViewCompComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    _fields: Ember.computed('fields', function () {
        var c, fields, model;
        fields = this.get('fields');
        model = this.get('model');
        if (!fields) {
            return;
        }
        if (!model) {
            return;
        }
        c = this.get('Collector');
        fields.forEach(function (field) {
            return (field.value = c.format(
                model.get(field.name),
                field,
                null,
                model,
                false,
                model.get('constructor.modelName'),
            ));
        });
        return fields;
    }),
});

export default ColletorRecordViewCompComponent;
