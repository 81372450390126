/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SkyddsrondReportController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

SkyddsrondReportController = GeneralReportController.extend({
    form: 'skyddsrond',
    showColumns: ['date', 'project', 'user', 'foreman_sign', 'safety_officer_sign'],
    queryParams: ['date', 'status', 'user', 'project'],
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
            hideHistory: true,
        }),
        sortByColumn: 'project',
    }),
    filters: Em.A([]),
});

export default SkyddsrondReportController;
