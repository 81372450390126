import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface IMessAgesTypesMessageComponent {
    params: { text: string };
}

export default class MessAgesTypesMessageComponent extends Component<IMessAgesTypesMessageComponent> {
    get content() {
        // convert line breaks to html
        return htmlSafe(this.args.params.text.replace(/\n/g, '<br>'));
    }
}
