/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/drop-down-search-input.coffee.old
// Generated by CoffeeScript 2.6.1
var DropDownSearchInputComponent;

import Ember from 'ember';

import DropDownSearch from './drop-down-search';

DropDownSearchInputComponent = DropDownSearch.extend({
    layoutName: 'components/drop-down-search',
    placeholder: 'general.write_or_search',
    actions: {
        focusOut: function () {
            return this.set('showOptions', false);
        },
    },
});

export default DropDownSearchInputComponent;
