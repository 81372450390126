/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/events/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import localization from '../../../classes/localization';

WorksitediaryIndexController = GeneralReportController.extend({
    form: 'worksitediary',
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: 'worksitediary.edit',
            historyRoute: 'worksitediary.history',
            hideHistory: true,
        }),
        sortByColumn: 'date-',
    }),
    showColumns: ['date', 'project', 'title', 'description', 'images', 'rating', 'weather'],
    queryParams: ['project', 'date', 'page', 'status'],
    status: 'all',
    date: moment().subtract(1, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    filters: Em.A([
        Em.Object.create({
            field: 'date',
        }),
        Em.Object.create({
            field: 'project',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    formatter: function (column, value, self) {
        if (column === 'rating' && value) {
            if (value === 'up') {
                value = new Ember.String.htmlSafe(
                    "<span class='move move-GoodQuality big-thumb report-thumb-up'></span>",
                );
            } else {
                value = new Ember.String.htmlSafe(
                    "<span class='move move-PoorQuality big-thumb report-thumb-down'></span>",
                );
            }
            return value;
        }
    },
    actions: {
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        transitionToWizard: function () {
            return this.transitionToRoute('worksitediary.report.add.basic-info', 'add');
        },
        transitionToEvents: function () {
            return this.transitionToRoute('worksitediary.events');
        },
    },
});

export default WorksitediaryIndexController;
