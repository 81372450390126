import GeneralAddController from '../../../controllers/general-add';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';

export default GeneralAddController.extend({
    edit: true,
    content: alias('model'),
    fieldSettings: A({
        edit: true,
    }),
});
