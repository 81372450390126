/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

PresenceIndexController = GeneralReportController.extend({
    form: 'presence',
    showColumns: ['in', 'user', 'project', 'date'],
    queryParams: ['page', 'project', 'employer', 'user'],
    filters: Em.A([
        Em.Object.create({
            field: 'project',
        }),
    ]),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
        }),
    }),
    actions: {
        closeEditModal: function () {
            this.editRecord.rollbackAttributes();
            return this.set('editRecord', null);
        },
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        exportShare: function () {
            var params, promise;
            params = {
                limit: 'all',
            };
            if (this.qParams) {
                Object.entries(this.qParams).forEach(function (filter) {
                    if (filter[1] && filter[0] !== 'status') {
                        return (params[filter[0]] = filter[1]);
                    }
                });
            }
            if (this.qParams['order']) {
                params.order = this.qParams['order'];
            }
            this.set('showShareModal', true);
            promise = this.get('Collector').ttapi({
                url: 'share/link',
                method: 'post',
                data: {
                    params: params,
                    values: {
                        form: this.get('form'),
                        lang: this.get('intl.primaryLocale'),
                    },
                },
            });
            return promise.then((back) => {
                return this.set(
                    'shareUrl',
                    this.get('Collector').ttapi_url() + '/share?k=' + back.link,
                );
            });
        },
    },
    date: moment().subtract(7, 'days').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    project: null,
});

export default PresenceIndexController;
