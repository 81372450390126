import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
    location: config.locationType,
    rootURL: config.rootURL,
});

Router.map(function () {
    this.route('show', { path: 'show/:form/:id' });
    this.route('protected');
    this.route('login');
    this.route('logout');

    this.route('register', function () {
        this.route('service', function () {
            this.route('adddetails');
            this.route('products');
            this.route('amounts');
            this.route('done');
        });

        this.route('user', function () {
            this.route('code');
            this.route('company');
            this.route('sign-in');
            this.route('confirm');
            this.route('id06');
            this.route('done');
            this.route('info');
        });
    });

    this.route('timetracker', function () {
        this.route('add');
        this.route('addsaved');
        this.route('comp-report');
        this.route('employer-sums');
        this.route('timecard', function () {
            this.route('add');
            this.route('additional');
        });
        this.route('saved');
        this.route('approval', { path: '/approve' }, function () {
            this.route('site', { path: '/:project' }, function () {
                this.route('user', { path: '/:user' });
            });
        });
        this.route('realtime');
        this.route('worktime-list', { path: '/list' });
        this.route('anomalies');
        this.route('edit', { path: 'edit/:id' });
        this.route('history', { path: 'history/:id' });
        this.route('week-report');
        this.route('avi');
        this.route('calendar');
        this.route('bank');
        this.route('bank_ad');
        this.route('aggregated', function () {
            this.route('rows', { path: '/:date' });
        });
        this.route('salary-category');
    });

    this.route('taxman', function () {
        this.route('contract-list', function () {
            this.route('add');
            this.route('edit', { path: 'edit/:id' });
            this.route('history', { path: 'history/:id' });
        });
        this.route('taxreport', function () {
            this.route('add', { path: 'add/:month' });
        });
        this.route('archive');

        this.route('taxreport-contract', function () {
            this.route('add', { path: 'add/:month' });
        });
    });

    this.route('presences', function () {
        this.route('add');
        this.route('accident');
        this.route('report');
        this.route('edit', { path: 'edit/:id' });
        this.route('history', { path: 'history/:id' });
        this.route('employer-sums');
        this.route('location');
        this.route('locationtree');
        this.route('avi');
    });

    this.route('users', function () {
        this.route('add', function () {
            this.route('builder');
            this.route('id06');
        });
        this.route('edit', { path: 'edit/:id' }, function () {
            this.route('competence');
            this.route('id06competence');
            this.route('orientations');
            this.route('changeusername');
            this.route('changepassword');
            this.route('permissions');
            this.route('saldo');
            this.route('saldos_ad');
            this.route('saldohistory');
            this.route('userdata');
            this.route('activity');
            this.route('superior_replacements');
        });
        this.route('saved');
        this.route('history', { path: 'history/:id' });
        this.route('card');
        this.route('subcontractor');
        this.route('linked');
        this.route('inactive');
        this.route('removed');
        this.route('saldos');
        this.route('saldos_ad');
        this.route('saldohistory');
        this.route('saldocalendar');
        this.route('cardregister');
        this.route('superior_replacements');
        this.route('worktimegroup_changes');
    });

    this.route('projects', function () {
        this.route('add');
        this.route('saved');
        this.route('edit', { path: 'edit/:id' }, function () {
            this.route('project-files');
            this.route('project-users');
            this.route('add-file');
            this.route('working-now');
            this.route('tasks');
            this.route('linked-users');
            this.route('overview');
            this.route('notices');
            this.route('trnotices');
            this.route('open_worktimes');
            this.route('passes');
            this.route('taxmanerrors');
            this.route('subprojects');
            this.route('rollocks');
            this.route('tv-status');
            this.route('contract_report');
            this.route('card-list');
        });
        this.route('history', { path: 'history/:id' });
        this.route('magickey');
        this.route('removed');
        this.route('inactive');
        this.route('rfiddevices');
        this.route('report');
        this.route('tasks');
    });

    this.route('employers', function () {
        this.route('add');
        this.route('edit', { path: 'edit/:id' }, function () {
            this.route('employer-files');
            this.route('add-file');
            this.route('zeckit');
        });

        this.route('history', { path: 'history/:id' });
        this.route('saved');
        this.route('tree');
        this.route('check');
        this.route('removed');
        this.route('inactive');
    });

    this.route('orientations', function () {
        this.route('add', function () {
            this.route('checkboxes', { path: '/checkboxes/:group' });
            this.route('parent');
            this.route('confirm');
            this.route('signatures');
            this.route('saved');
            this.route('add-passes');
        });
        this.route('reports');
        this.route('passes');
        this.route('edit', { path: 'edit/:id' });
        this.route('history', { path: 'history/:id' });
        this.route('withoutori');
        this.route('own-orientation', { path: '/own-orientation/:id' });
        this.route('employeetree');
    });

    this.route('site_safety', function () {
        this.route('report', function () {
            this.route('tr', function () {
                this.route('graphs');
                this.route('data');
                this.route('notices');
            });
            this.route('mvr', function () {
                this.route('graphs');
                this.route('data');
                this.route('notices');
            });
            this.route('observation');
            this.route('event');
        });

        this.route('measurement', { path: 'measurement/:measurement' }, function () {
            this.route('graphs');
            this.route('data');
            this.route('notices');
            this.route('edit', { path: 'edit/:id' });
            this.route('history', { path: 'history/:id' });
        });

        this.route('measurement.add', { path: '/measurement/:id/:measurement' }, function () {
            this.route('measurement', { path: 'measure/:project' });
        });

        this.route('addnotice', function () {
            this.route('tr');
            this.route('mvr');
        });

        this.route('saved', { path: '/saved/:type/:id' });
        this.route('observation', function () {
            this.route('add');
            this.route('edit', { path: 'edit/:id' });
        });
    });

    this.route('skyddsronds', function () {
        this.route('add');
        this.route('report');
        this.route('edit', { path: 'edit/:id' });
        this.route('history', { path: 'history/:id' });
        this.route('notices');
        this.route('dashboard');
        this.route('saved', { path: 'saved/:id' });
    });

    this.route('trnotices', { path: '/notices' }, function () {
        this.route('edit', { path: '/edit/:id' });
        this.route('history', { path: '/history/:id' });
    });

    this.route('abcenses', function () {
        this.route('holidays');
        this.route('holidaypays');
        this.route('gantt');
        this.route('add');
        this.route('add-multiple', { path: '/addmultiple' });
        this.route('edit', { path: 'edit/:id' });
        this.route('history', { path: 'history/:id' });
    });

    this.route('changepassword');

    this.route('saved');

    this.route('forms', function () {
        this.route('customer', function () {
            this.route('edit', { path: 'edit/:id' });
        });
        this.route('form-add', { path: ':form/add' });
        this.route('form-edit', { path: ':form/edit/:id' });
        this.route('form-report', { path: ':form/report' });
        this.route('form-history', { path: ':form/history/:id' });
        this.route('form-view', { path: ':form/view/:id' });
        this.route('custom-report', { path: ':form/custom-report' });
        this.route('information');
        this.route('report', { path: ':form' });
    });

    this.route('worksitediary', function () {
        this.route('add');
        this.route('events');
        this.route('edit', { path: 'edit/:id' }, function () {
            this.route('basic-info');
        });
        this.route('supervisor-notes-report');
        this.route('other-entries-report');
        this.route('offering-report');
        this.route('calendar');
        this.route('notes');

        this.route('history', { path: 'history/:id' });
        this.route('report', function () {
            this.route('first');
            this.route('add', { path: '/:id' }, function () {
                this.route('basic-info');
                this.route('at-site');
                this.route('diary-events');
                this.route('material');
                this.route('review-page');
                this.route('ordered-changes');
                this.route('offering');
                this.route('required-extra');
                this.route('supervisor-note');
                this.route('other-entries');
                this.route('signature');
                this.route('completion-page');
                this.route('summary-page');
                this.route('notes-direktiv', { path: 'direktiv' });
                this.route('notes-anmalan', { path: 'anmalan' });
                this.route('notes-besiktningar', { path: 'besiktningar' });
                this.route('notes-ritningar', { path: 'ritningar' });
                this.route('extra');
            });
        });
        this.route('automation');
    });

    this.route('diary', function () {
        this.route('report', function () {
            this.route('date', { path: ':date' }, function () {
                this.route('project', { path: ':project' });
            });
        });
        this.route('events');
        this.route('calendar');
        this.route('combo');
    });

    this.route('messageboard', function () {
        this.route('add');
        this.route('history', { path: 'history/:id' });
    });

    this.route('pass', function () {
        this.route('add');
    });

    this.route('token', { path: 'token/:token' });

    this.route('settings', function () {
        this.route('general');
        this.route('general-v2');
        this.route('measurement_custom');
        this.route('forms', function () {
            this.route('form', { path: ':form' }, function () {
                this.route('field', { path: ':field' });
                this.route('removed-fields');
            });
        });
        this.route('products');
        this.route('notificationpanel');
        this.route('billing');
        this.route('company-profile');
        this.route('integrations');
        this.route('orientations');
        this.route('history');
        this.route('activitylog');
    });

    this.route('thereport', { path: 'comboreport' }, function () {
        this.route('page', { path: 'page/:page' });
        this.route('settings');
    });

    this.route('invite-users');

    // this.route('invalid', { path: "/*path" });

    this.route('my-sites', function () {
        this.route('add-image-component');
    });
    this.route('dashboard');
    this.route('massedit', { path: '/massedit/:form/:ids' });

    this.route('send_skatteverket');

    this.route('development', function () {
        this.route('cron');
        this.route('integrations');
        this.route('datacreator');
        this.route('react');
        this.route('ownreports');
        this.route('notifications');
        this.route('smslogs');
        this.route('functions');
        this.route('products');
        this.route('rollock');
        this.route('activitylog');
        this.route('ownroles', function () {
            this.route('role', { path: '/:id' });
        });
        this.route('import', function () {
            this.route('index');
            this.route('map');
            this.route('create');
            this.route('save');
        });
    });

    this.route('password', function () {
        this.route('recovery');
    });

    this.route('services');

    this.route('admintool', function () {
        this.route('reports', function () {
            this.route('bi', function () {
                this.route('timecleanlog');
                this.route('findtimeclean');
            });
            this.route('featureflags');
        });
        this.route('customer', { path: '/customer/:id' }, function () {
            this.route('overview');
            this.route('details');
            this.route('reports', function () {
                this.route('bills');
                this.route('users');
            });
            this.route('devices', function () {
                this.route('edit');
            });
            this.route('tt3tott4');
            this.route('charts');
            this.route('history');
        });
        this.route('createservice');
        this.route('billing');
    });

    this.route('groups');

    this.route('worktasks', function () {
        this.route('list');
        this.route('edit', { path: 'edit/:id' });
        this.route('history', { path: 'history/:id' });
        this.route('view', { path: 'view/:id' });
        this.route('confirm', { path: 'confirm/:id' });
        this.route('calendar');
        this.route('report');
        this.route('gantt');
    });

    this.route('messages', function () {
        this.route('add', function () {
            this.route('message');
        });
        this.route('list');
        this.route('email-approvals');
    });

    this.route('mapon');

    this.route('drivinglog', function () {
        this.route('toggle', function () {
            this.route('confirm');
        });
        this.route('edit', { path: 'edit/:id' });
    });

    this.route('materiallog', function () {
        this.route('report');
        this.route('list');
        this.route('groups');
    });

    this.route('multitask', function () {
        this.route('report');
    });

    this.route('gpslocation');

    this.route('machines', function () {
        this.route('transactions');
        this.route('transactions-history');
        this.route('transaction', function () {
            this.route('add');
            this.route('tooltype', function () {
                this.route('selected', { path: ':tooltype' });
            }),
                this.route('project', function () {
                    this.route('selected', { path: ':project' });
                }),
                this.route('move', { path: 'move/:tooltype/:project' });
        });
        this.route('machine');
        this.route('map');
        this.route('prices');
    });

    this.route('salary', function () {
        this.route('workday-calendar', { path: 'workday' });
        this.route('export-salary', function () {
            this.route('add');
        });

        this.route('custom-export', function () {
            this.route('edit', { path: ':id' });
        });

        this.route('custom-export-ad');
        this.route('salarytypes');
        this.route('salaryperiods');
        this.route('export-abcense', function () {
            this.route('add');
        });
    });

    this.route('firecards', function () {
        this.route('add');
        this.route('edit', { path: 'edit/:id' });
    });
    this.route('test');
    this.route('vismanetsignin');

    this.route('worksite-areas', function () {
        this.route('report');
        this.route('log-report');
    });

    this.route('nova_costcenters');

    this.route('integrations', function () {
        this.route('list');
        this.route('report');
        this.route('fondionerrors');
    });
    this.route('resource-planning', function () {
        this.route('gantt');
        this.route('week');
    });
    this.route('shift-planning', function () {
        this.route('gantt');
        this.route('week');
    });
});

export default Router;
