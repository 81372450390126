/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/admintool.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolService;

import Ember from 'ember';

import config from '../config/environment';

import $ from 'jquery';

AdmintoolService = Ember.Service.extend({
    session: Ember.inject.service(),
    ajax: function (params) {
        var self;
        params.url = config.APP.ADMINTOOLURL + params.url;
        self = this;
        params.beforeSend = function (request) {
            if (Ember.get(self, 'session.session.authenticated.access_token')) {
                return request.setRequestHeader(
                    'Authorization',
                    'Bearer ' + Ember.get(self, 'session.session.authenticated.access_token'),
                );
            }
        };
        return $.ajax(params);
    },
    get: function (path, params) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise;
            if (!params) {
                params = {};
            }
            params.url = path;
            promise = this.ajax(params);
            return promise.then(
                function (data) {
                    return resolve(data[path] != null ? Em.A(data[path]) : Em.A(data));
                },
                function (reason) {
                    return reject(reason);
                },
            );
        });
    },
});

export default AdmintoolService;
