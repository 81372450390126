/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/add/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesAddIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

MessagesAddIndexController = GeneralReportController.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                type: 'searchbox',
            }),
        ]);
    }),
    queryParams: ['q'],
    tab: '',
    employers: Em.computed(function () {
        return this.store.findAll('employer');
    }),
    projects: Em.computed(function () {
        return this.store.findAll('project');
    }),
    groups: Em.computed(function () {
        return this.store.findAll('group');
    }),
    roles: Em.computed(function () {
        var ownroles, roles;
        roles = [
            {
                id: '1',
                name: this.get('intl').t('user.userlevel.1'),
            },
            {
                id: '2',
                name: this.get('intl').t('user.userlevel.2'),
            },
            {
                id: '3',
                name: this.get('intl').t('user.userlevel.3'),
            },
            {
                id: '6',
                name: this.get('intl').t('user.userlevel.6'),
            },
            {
                id: '5',
                name: this.get('intl').t('user.userlevel.5'),
            },
            {
                id: '4',
                name: this.get('intl').t('user.userlevel.4'),
            },
        ];
        ownroles = Ember.get(this.get('Collector').field('user', 'userlevel'), 'options').filter(
            function (item) {
                return item.length > 1;
            },
        );
        ownroles.forEach((role) => {
            return roles.push({
                id: role.substring(0, 2),
                name: role.substring(2),
            });
        });
        return roles;
    }),
    users: Em.computed('q', function () {
        if (this.get('q')) {
            return this.store.query('user', {
                q: this.get('q'),
                order: 'lastname',
            });
        }
    }),
    selected_users_arr: Em.computed(function () {
        return Em.A([]);
    }),
    selected_employer_count: Em.computed('selected_users_arr.@each', function () {
        if (!this.get('showEmployer')) {
            return false;
        }
        return this.get('selected_users_arr').uniqBy('employer').length;
    }),
    //update selected users array from selected value
    selected_users: Em.observer(
        'tab',
        'selected_employer',
        'selected_project',
        'selected_group',
        'selected_role',
        function () {
            switch (this.get('tab')) {
                case 'employer':
                    if (!this.get('selected_employer')) {
                        return;
                    }
                    //get users with selected employer
                    return this.store
                        .query('user', {
                            limit: 'all',
                            employer: this.get('selected_employer.id'),
                            order: 'lastname',
                        })
                        .then((users) => {
                            return this.send('addUsersToList', users);
                        });
                case 'group':
                    if (!this.get('selected_group')) {
                        return;
                    }
                    //get users with selected employer
                    return this.store
                        .query('user', {
                            limit: 'all',
                            group: this.get('selected_group.id'),
                            order: 'lastname',
                        })
                        .then((users) => {
                            return this.send('addUsersToList', users);
                        });
                case 'role':
                    if (!this.get('selected_role')) {
                        return;
                    }
                    //get users with selected employer
                    return this.store
                        .query('user', {
                            limit: 'all',
                            userlevel: this.get('selected_role'),
                            order: 'lastname',
                        })
                        .then((users) => {
                            return this.send('addUsersToList', users);
                        });
            }
        },
    ),
    selected_users_arr_formated: Em.computed('selected_users_arr.[]', function () {
        return this.get('selected_users_arr').map((row) => {
            if (!isNaN(row.get('userlevel'))) {
                row.set(
                    'userlevel',
                    this.get('Collector')._format_(row.get('userlevel'), 'user', 'userlevel'),
                );
            }
            return row;
        });
    }),
    showColumns: Em.computed('showEmployer', function () {
        if (this.get('showEmployer')) {
            return ['lastname', 'firstname', 'userlevel', 'email', 'phone', 'employer.name'];
        } else {
            return ['lastname', 'firstname', 'userlevel', 'email', 'phone'];
        }
    }),
    // get translation for every column
    translated_columns: Em.computed('showColumns', function () {
        var c, columns, intl, translated_columns;
        c = this.get('Collector');
        intl = this.get('intl');
        columns = this.get('showColumns');
        translated_columns = [];
        $.each(columns, (key, item) => {
            return translated_columns.push(intl.t('report.user.' + item));
        });
        return translated_columns;
    }),
    rowActions: Em.computed('intl', function () {
        var arr;
        arr = [
            {
                action: 'removeRow',
                icon: 'move move-Delete',
                text: this.get('intl').t('report.delete_rows'),
            },
        ];
        return arr;
    }),
    showColumnsUsersearch: ['lastname', 'firstname', 'email', 'phone'],
    // get translation for every column
    translated_columns_userSearch: Em.computed('showColumnsUsersearch', function () {
        var c, columns, intl, translated_columns;
        c = this.get('Collector');
        intl = this.get('intl');
        columns = this.get('showColumnsUsersearch');
        translated_columns = [];
        $.each(columns, (key, item) => {
            return translated_columns.push(intl.t('report.user.' + item));
        });
        return translated_columns;
    }),
    rowActionsUsersearch: [
        {
            action: 'addUserToList',
            icon: 'glyphicon glyphicon-plus',
        },
    ],
    showGroup: Em.computed(function () {
        return this.get('Collector').formExists('group');
    }),
    showEmployer: Em.computed(function () {
        return this.get('Collector').formExists('employer');
    }),
    actions: {
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        changeTab: function (tab) {
            return this.set('tab', tab);
        },
        removeRow: function (user) {
            var userObject;
            if (this.get('selected_users_arr.arrangedContent')) {
                userObject = this.get('selected_users_arr.arrangedContent').findBy('id', user.id);
                return this.get('selected_users_arr.arrangedContent').removeObject(userObject);
            } else {
                return this.get('selected_users_arr').removeObject(user);
            }
        },
        addUserToList: function (user) {
            return this.get('selected_users_arr').addObject(user);
        },
        addUsersToList: function (users) {
            if (this.get('selected_users_arr.arrangedContent')) {
                return users.arrangedContent.forEach((user) => {
                    return this.get('selected_users_arr.arrangedContent').addObject(user);
                });
            } else {
                return this.get('selected_users_arr').addObjects(users);
            }
        },
        sendToAll: function () {
            this.set('selected_users_arr', Em.A([]));
            return this.transitionToRoute('messages.add.message');
        },
        clearReceivers: function () {
            return this.set('selected_users_arr', Em.A([]));
        },
        searchChanged: function (value) {
            if (typeof value === 'object') {
            } else {
                return this.set('q', value);
            }
        },
    },
});

export default MessagesAddIndexController;
