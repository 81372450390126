import { alias } from '@ember/object/computed';
import EmberPromise, { hash } from 'rsvp';
import Controller from '@ember/controller';
import EmberObject, { get, set, computed } from '@ember/object';
import { A } from '@ember/array';
import { typeOf } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Controller.extend({
    Collector: service('collector-service'),
    dialogs: service(),
    intl: service(),
    analytics: service(),
    store: service(),
    capi: service(),
    ttapi: service(),
    error: service(),
    form: 'worktime',
    user: null,
    employer: null,

    init() {
        this._super(...arguments);
        this.queryParams = ['user', 'date', 'status', 'project', 'employer'];
        this.shownColumns = ['project', 'starttime', 'endtime', 'work_hours', 'task'];
        this.absenceColorFrom = ['abcensetype.id', 'user.firstname', 'user.lastname'];
        this.worktimeFields = ['work_hours'];
        this.absenceFields = ['hours'];
    },

    dailyWorktimeSums: alias('model.dailyWorktimeSums.content'),
    worktimeSums: alias('model.worktimeSums.content'),
    absences: alias('model.absences.abcenses'),
    absenceTypes: alias('model.absences.abcensetypes'),
    settings: EmberObject.create({
        massedit: true,
        hideExports: true,
        rowSettings: EmberObject.create({
            editRoute: 'timetracker.edit',
            historyRoute: 'timetracker.history',
        }),
    }),
    absence_settings: EmberObject.create({
        massedit: true,
        hideExports: true,
        rowSettings: EmberObject.create({
            editRoute: 'abcenses.edit',
            historyRoute: 'abcenses.history',
        }),
    }),
    filters: computed('status', 'user', 'date', function () {
        const c = get(this, 'Collector');

        let fields = c.fieldArray('worktime', ['date', 'user', 'status', 'project', 'employer']);

        // https://jira.visma.com/browse/SUP-3159 can't filter by worktimes as box users don't show then
        // if (c.testNeeds(['user.module_controls'])) {
        //     fields.findBy('name', 'user').filterOptions = {module_controls: "worktimes"}
        // }
        const filters = A([]);
        fields.forEach((field) => {
            let filter = EmberObject.create({...field, value: get(this, get(field, 'name')), field: field.name});
            filters.pushObject(filter);
        });

        return filters;
    }),

    showWarning: computed('dailyWorktimeSums', 'absences', function () {
        if (get(this, 'dailyWorktimeSums.length') >= 100 || get(this, 'absences.length') >= 100) {
            return true;
        } else {
            return false;
        }
    }),

    statusTypes: computed('form', function () {
        if (!get(this, 'form')) {
            return [];
        }
        const c = get(this, 'Collector');
        const intl = get(this, 'intl');
        const field = c.field(get(this, 'form'), 'status');
        let options = [];
        field.options.forEach((option) => {
            options.push(
                intl.exists('status.' + option)
                    ? {
                          id: option,
                          name: intl.t('calendar.' + option + '_all_rows'),
                      }
                    : { id: option, name: intl.t('calendar.change_status') + option },
            );
        });
        return options;
    }),

    actions: {
        addNew(day, type) {
            window.scrollTo(0, 0);
            let params = {};
            if (type == 'abcense') {
                params.startdate = day;
                params.enddate = day;
            } else {
                params.date = day;
            }

            if (get(this, 'user')) {
                params.user = get(this, 'store').peekRecord('user', get(this, 'user'));
            }
            if (get(this, 'project')) {
                params.project = get(this, 'store').peekRecord('project', get(this, 'project'));
            }
            const record = get(this, 'store').createRecord(type, params);

            ['didDelete', 'didCreate', 'didUpdate'].forEach((eventName) => {
                record.on(eventName, () => {
                    this.send('refreshModel', record);
                });
            });

            this.transitionToRoute({ queryParams: { modal: [type, 'new'] } });
        },
        openEditor(row) {
            if (get(row, 'row_info.privilege_remove') != undefined && !get(row, 'isEditable')) {
                return;
            }
            window.scrollTo(0, 0);
            row.on('didUpdate', () => {
                this.send('refreshModel', row);
            });
            this.transitionToRoute({
                queryParams: { modal: [get(row, 'constructor.modelName'), get(row, 'id')] },
            });
        },
        remove(record) {
            get(this, 'dialogs')
                .confirm(get(this, 'intl').t('general.remove_row'))
                .then((con) => this._removeRecord(con, record));
        },

        filterChanged(value, field) {
            if (['object', 'instance'].includes(typeOf(value))) {
                value = get(value, 'id');
            }
            if (!field) {
                set(this, 'date', value);
            } else {
                set(this, field, value);
            }
        },

        showRows(date) {
            this.transitionToRoute('timetracker.aggregated.rows', date);
        },

        showAllRows() {
            this.toggleProperty('showAllRows');
            if (get(this, 'showAllRows')) {
                this.transitionToRoute('timetracker.aggregated.rows', 'all');
            } else {
                this.transitionToRoute('timetracker.aggregated');
            }
        },

        print() {
            get(this, 'analytics').trackEvent({
                category: 'Aggregated report',
                action: 'Print report',
            });
            print();
        },

        async printAll() {
            get(this, 'analytics').trackEvent({
                category: 'Aggregated report',
                action: 'Print all data',
            });
            if (get(this, 'showAllRows')) {
                this.transitionToRoute('timetracker.aggregated');
                set(this, 'showAllRowsByUser', true);
            } else {
                set(this, 'showAllRowsByUser', false);
            }
            set(this, 'printLoading', true);

            this.Collector.ajax({
                url:
                    'system/clear_memcache?partnerid=' +
                    get(this, 'session.currentUser.partnerid') +
                    '&username=' +
                    get(this, 'session.currentUser.username'),
            });
            let users = await this.store.query('user', { limit: 'all' });
            let mappedData = [];

            mappedData = users.map(async (user) => {
                try {
                    return hash({
                        user: user,
                        dailyWorktimeSums: await this.capi.findAll('worktime', {
                            user: user.id,
                            date: get(this, 'date'),
                            groupBy: 'date',
                            status: this.status,
                        }),
                        absences: await this.ttapi._get('/api/absences/splitted', {
                            user: user.id,
                            status: this.status,
                            startdate: get(this, 'date').split('_')[0],
                            enddate: get(this, 'date').split('_')[1],
                            sideload: this.Collector.getSideloads('abcense'),
                            raw: true,
                        }),
                        worktimeSums: await this.capi.findAll('worktime', {
                            user: user.id,
                            date: get(this, 'date'),
                            groupBy: 'user',
                            status: this.status,
                        }),
                        rows: hash({
                            worktimeRows: this.showAllRowsByUser
                                ? get(this, 'store').query('worktime', {
                                      user: user.id,
                                      date: get(this, 'date'),
                                      status: this.status,
                                  })
                                : [],
                            absenceRows: this.showAllRowsByUser
                                ? get(this, 'store').query('abcense', {
                                      user: user.id,
                                      startdate: get(this, 'date'),
                                      status: this.status,
                                  })
                                : [],
                        }),
                    });
                } catch (e) {
                    this.error.notify(e);
                    set(this, 'printLoading', false);
                    return [];
                }
            });

            let data = await EmberPromise.all(mappedData);
            await set(this, 'printableData', data);
            set(this, 'printLoading', false);
            await this.printableData;
            print();
            set(this, 'printableData', []);
        },

        setRowsPerPage(form, limit) {
            set(this, form + '_page', 1);
            set(this, form + '_limit', limit);
        },
    },
    _removerecord(con, record) {
        if (con) {
            record.destroyRecord();
        }
    },

    canAdd(type, date) {
        if (type == 'worktime') {
            const c = get(this, 'Collector');
            return c.canAddWorktime(date);
        }
        return true;
    },

    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
});
