import Ember from 'ember';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import './models/custom-inflector-rules';
import { set, get } from '@ember/object';
import { startSentry } from './sentry';

startSentry();

export default class App extends Application {
    modulePrefix = config.modulePrefix;
    podModulePrefix = config.podModulePrefix;
    Resolver = Resolver;
}

Ember.TextSupport.reopen({
    // These are not needed if we don't want to give them in templates..
    attributeBindings: ['autocapitalize'],
    //    autocapitalize: "off",
    autocorrect: 'off',
    autocomplete: 'off',
});

if (window.sessionStorage && sessionStorage.getItem('APP')) {
    let app_override = JSON.parse(sessionStorage.getItem('APP'));
    Object.keys(app_override).forEach(function (key) {
        config.APP[key] = app_override[key];
    });
}

if (Modernizr.localstorage && localStorage.getItem('devModeOn')) {
    set(Ember, 'devModeOn', true);
}

if (config.APP.devMode) {
    set(Ember, 'devModeOn', true);
}

Ember.dev = function () {
    if (get(Ember, 'devModeOn') === true) {
        if (Modernizr.localstorage) {
            localStorage.removeItem('devModeOn');
        }
        console.info('Development mode is now turned off!'); //eslint-disable-line
        set(Ember, 'devModeOn', false);
    } else {
        if (Modernizr.localstorage) {
            localStorage.setItem('devModeOn', 'on');
        }
        console.info('Development mode is now turned on!'); //eslint-disable-line
        set(Ember, 'devModeOn', true);
    }
};

Error.stackTraceLimit = 100;

loadInitializers(App, config.modulePrefix);
