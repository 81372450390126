import GeneralReportRoute from '../../../../routes/general-report';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default GeneralReportRoute.extend({
    ttapi: service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },

    model(params) {
        params = this._super(params);

        // Delete empty params and add all params to queryParams so filtering works
        Object.keys(params).forEach((param) => {
            if (isEmpty(params[param])) delete params[param];
            this.queryParams[param] = { refreshModel: true };
        });
        let project = this.modelFor('projects.edit');
        params.project = get(project, 'id');
        let data = get(this, 'ttapi').request('/avi/project_users', {
            type: 'GET',
            data: params,
        });
        return hash({
            data: data,
            project: project,
        });
    },
});
