import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';

const ImportController = Controller.extend({
    //{from: 0, to: "firstname", pk: true},{from: 1, to: "lastname", pk: false},{from: 2, to: "employer", pk: false}
    file: null,
    //    file: [["Ali","Lastnametus","Test Company Ltd","Number"]]
    form: null,
    content: alias('model'),
    mappers: A([]),
});

export default ImportController;
