/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/check/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersCheckController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import config from '../../../config/environment';

EmployersCheckController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    indexController: Ember.inject.controller('employers.index'),
    settings: Ember.computed.alias('indexController.settings'),
    form: 'employer',
    showColumns: ['name', 'emp_id', 'tv_status', 'tv_pdf', 'date_checked'],
    queryParams: ['page', 'q'],
    filteredEmployers: Ember.computed(function () {
        return {
            tv_status: '!ok',
        };
    }),
    formatter: function (column, value, scope, formatted) {
        if (column === 'tv_status') {
            if (value && value !== 'ei_loydy') {
                return new Ember.String.htmlSafe(
                    '<img src="' +
                        config.APP.CDN_URL +
                        '/images/tv' +
                        value +
                        '.png" class="report-img" title="' +
                        formatted +
                        '">',
                );
            } else {
                return formatted;
            }
        }
        return false;
    },
    actions: {
        exportTVPdf: function (items) {
            return items.forEach((row) => {
                if (!row.get('emp_id')) {
                    return;
                }
                return this.get('indexController').saveTVPdf(row);
            });
        },
        transitionToEditRoute: function (row) {
            return this.transitionToRoute('employers.edit', row);
        },
    },
});

export default EmployersCheckController;
