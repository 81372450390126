import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CollectorFormAddFormsWorktaskComponent extends Component {
    @service store;
    @service flags;
    @service intl;

    @tracked record;
    @tracked messageBox;

    constructor() {
        super(...arguments);
        // Addon form-add gets defaults from form
        const defaults = !this.flags.test('ember-movenium-form-add-worktasks')
            ? { date: moment().format('YYYY-MM-DD') }
            : {};
        this.record = this.args.record
            ? this.args.record
            : this.store.createRecord('worktask', defaults);
        this.currentValues = { date: null, userid: null };
    }

    @action
    async onFieldChange(field, value) {
        this.record[field] = value;
        this.messageBox = await this.updateMessage();
    }

    /*
    check if worktasks are found for selected user for selected date
    */
    async updateMessage() {
        if (!this.record.user || !this.record.date) return null;
        if (
            this.currentValues.date === this.record.date &&
            this.currentValues.userid === this.record.user.id
        )
            return this.messageBox;

        this.currentValues = {
            date: this.record.date,
            userid: this.record.user.id,
        };

        const params = { user: this.record.user.id, date: this.record.date };
        if (this.record.id) params.id = '!' + this.record.id;
        let tasks = await this.store.query('worktask', params);

        const filtered_tasks = tasks.filter((task) => task.id !== this.record_id);

        let hours = 0;
        filtered_tasks.forEach((task) => (hours += task.est_hours));
        const minutes = Math.round((hours - Math.floor(hours)) * 60);
        const formatted_hours = Math.floor(hours) + ':' + (minutes < 10 ? '0' : '') + minutes + 'h';

        let message = null;
        if (filtered_tasks.length > 0) {
            message = this.intl.t('worktask.worktasks_found', {
                count: filtered_tasks.length,
                hours: formatted_hours,
            });
        }

        return message;
    }
}
