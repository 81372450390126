/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/overview/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolOverviewController;

import Ember from 'ember';

import config from '../../../../config/environment';

import { isPresent } from '@ember/utils';

AdmintoolOverviewController = Ember.Controller.extend({
    session: Em.inject.service(),
    admintool: Ember.inject.service(),
    Collector: Em.inject.service('collector-service'),
    tools: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    notifications: Ember.inject.service(),
    dirtyRecords: false,
    dirtyCustomer: false,
    customerSaving: false,
    price_disabled: true,
    calculated_from_promo: false,
    loginUserInfoId: null,
    loadingFixedPrices: false,
    errors: [],
    init_stuff: function () {
        var currency;
        if (!this.get('model.country')) {
            return;
        }
        this.set('calculated_from_promo', false);
        this.set('fixed_price_model', null);
        this.set('fixed_price', null);
        // get fixed_price if any
        this.set('loadingFixedPrices', true);
        this.store
            .query('fixedprice', {
                customer: this.get('model.id'),
            })
            .then((fp) => {
                this.set('loadingFixedPrices', false);
                if (fp.get('firstObject')) {
                    //console.log fp.get 'firstObject'
                    this.set('fixed_price_model', fp.get('firstObject'));
                    this.set('has_fixed_price', true);
                    this.set('fixed_price', fp.get('firstObject.price'));
                    return this.set('price_disabled', false);
                } else {
                    this.set('fixed_price', null);
                    this.set('price_disabled', true);
                    return this.set('has_fixed_price', false);
                }
            });
        this.getAdminUsers().then((admins) => {
            var intl;
            this.set('loginUser', {
                username: admins['users'][0]['username'],
                userid: admins['users'][0]['id'],
                partnerid: this.get('model.partnerid'),
            });
            intl = this.get('intl');
            if (!this.get('model.login_user_info_name')) {
                this.set('dirtyRecords', true);
                this.set(
                    'model.login_user_info_name',
                    admins['users'][0]['firstname'] + ' ' + admins['users'][0]['lastname'],
                );
                this.set('model.login_user_info_phone', admins['users'][0]['phone']);
                return this.set('model.login_user_info_email', admins['users'][0]['email']);
            }
        });
        this.getBillingStarted().then((billingStarted) => {
            return this.set('billingStarted', moment(billingStarted).format('YYYY-MM-' + '01'));
        });
        currency = function () {
            switch (this.get('model.country')) {
                case 'se':
                    return 'sek';
                case 'fi':
                    return 'eur';
            }
        }.call(this);
        this.set('showCurrency', currency);
        // get salesmen
        this.get('store')
            .findAll('user', {
                reload: true,
            })
            .then((users) => {
                var salesmen;
                this.set('salesmen', users);
                salesmen = [];
                users.forEach((user) => {
                    return salesmen.push({
                        id: user.get('id'),
                        name: user.get('firstname') + ' ' + user.get('lastname'),
                    });
                });
                return this.set('salesmen', salesmen);
            });
        this.getCustomerProducts().then((customerProductsAll) => {
            var customerProducts, hiddenByName;
            customerProducts = customerProductsAll['public'].concat(customerProductsAll['hidden']);
            hiddenByName = customerProductsAll['hidden'].mapBy('name');
            if (
                hiddenByName.includes('generic_timetracker') ||
                this.get('model.campaign') === 'visma' ||
                this.get('model.campaign') === 'entry_mini'
            ) {
                this.set('clientVersion', 'visma');
            } else {
                this.set('clientVersion', 'movenium');
            }
            //@set 'customerProducts', customerProducts
            //console.log customerProducts
            // get all campaigns
            return this.getCampaigns().then((campaigns) => {
                // set campaigns to dropdown
                this.set('campaigns', campaigns['camps']);
                return this.getProductCampaigns().then((pCampaigns) => {
                    var currentCamp;
                    this.set('productcampaigns', pCampaigns);
                    //console.log pCampaigns

                    // find customers current campaigns
                    currentCamp = campaigns['campaigns'].filterBy(
                        'name',
                        this.get('model.campaign'),
                    );
                    this.set('currentCamp', currentCamp);
                    //console.log currentCamps
                    return this.getCustomersProductCampaigns().then((cpCampaigns) => {
                        var prodCamps;
                        this.set('customersProductCampaigns', cpCampaigns);
                        prodCamps = [];
                        // add all product-campaigns into array
                        cpCampaigns.forEach((cp) => {
                            return prodCamps.push(pCampaigns.findBy('id', cp.get('campaign.id')));
                        });
                        //console.log prodCamps
                        customerProducts.forEach((cProd) => {
                            var def_camp, found;
                            //cProd['default_price'] = cProd['price']

                            // get default-price from default-campaign
                            def_camp = campaigns['campaigns'].filter((cmp) => {
                                if (
                                    cmp.get('name') === 'default' &&
                                    parseInt(cmp.get('product.id')) === parseInt(cProd['id'])
                                ) {
                                    return true;
                                }
                            });
                            cProd['default_price'] = def_camp.get('firstObject.price');
                            cProd['productcampaigns'] = pCampaigns.filterBy(
                                'product.id',
                                cProd['id'].toString(),
                            );
                            found = false;
                            // search for product-campaign for this customer
                            found = prodCamps.findBy('product.id', cProd['id'].toString());
                            if (!found) {
                                //search from current campaign
                                found = currentCamp.findBy('product.id', cProd['id'].toString());
                            }
                            if (!found) {
                                // get price from default-campaign
                                found = def_camp.get('firstObject');
                            }
                            if (found) {
                                cProd['price'] = found.get('price');
                                cProd['campaign'] = found.get('id');
                                cProd['org_campaign'] = found.get('id');
                                return (cProd['custom_campaign'] = cpCampaigns.findBy(
                                    'campaign.id',
                                    found.get('id'),
                                ));
                            }
                        });
                        //console.log customerProducts
                        return this.set('customerProducts', customerProducts);
                    });
                });
            });
        });
        return this.get('store').query('fixedlimit', {
            customer: this.get('model.id'),
            sideload: true,
        });
    },
    campaignTypes: Em.computed(function () {
        return [
            {
                id: 'default',
                name: this.get('intl').t('admintool.campaigns.default'),
            },
            {
                id: 'customer',
                name: this.get('intl').t('admintool.campaigns.customer'),
            },
        ];
    }),
    /*
  price_disabled: Em.computed 'has_fixed_price', ->
      if @get 'has_fixed_price'
          return false
      else
          return true
  */
    allFixedLimits: Ember.computed('model.id', function () {
        return this.get('store').peekAll('fixedlimit');
    }),
    fixedLimits: Ember.computed('allFixedLimits.[]', function () {
        return this.get('allFixedLimits').filterBy('customer.id', this.get('model.id'));
    }),
    getBillingStarted: function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            if (this.get('model.billing_start')) {
                return resolve(this.get('model.billing_start'));
            } else {
                this.set('calculated_from_promo', true);
                return $.ajax({
                    url:
                        config.APP.ADMINTOOLURL +
                        'getBillingStart?promocode=' +
                        this.get('model.promocode') +
                        '&created=' +
                        this.get('model.createddate'),
                    type: 'GET',
                    headers: {
                        Authorization:
                            'Bearer ' + this.get('session.session.authenticated.access_token'),
                    },
                    success: (data) => {
                        return resolve(data);
                    },
                });
            }
        });
    },
    getCustomerProducts: function () {
        return $.ajax({
            url:
                config.APP.ADMINTOOLURL +
                'getCustomerProducts?partnerid=' +
                this.get('model.partnerid') +
                '&country=' +
                this.get('model.country'),
            type: 'GET',
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
            success: (data) => {
                return data;
            },
        });
    },
    getAllProducts: function () {
        var params;
        params = {
            data: {
                country: this.get('model.country'),
                lang: moment.locale(),
            },
        };
        return this.get('admintool').get('products', params);
    },
    getCampaigns: function () {
        // we need to fetch ALL campaigns without limits
        // should be safe because this route is not called that often
        return this.get('store')
            .query('campaign', {
                sideload: true,
                limit: 'all',
            })
            .then((campaigns) => {
                var camp, camps, i, len, ref;
                camps = [];
                ref = campaigns.mapBy('name').uniq();
                for (i = 0, len = ref.length; i < len; i++) {
                    camp = ref[i];
                    if (camp === 'default') {
                        continue;
                    }
                    camps.push({
                        id: camp,
                        name: camp,
                    });
                }
                return { camps, campaigns };
            });
    },
    getProductCampaigns: function () {
        return this.get('store').query('productcampaign', {
            sideload: true,
        });
    },
    getCustomersProductCampaigns: function () {
        return this.get('store').query('customercampaign', {
            sideload: true,
            customer: this.get('model.id'),
        });
    },
    statuslist: Em.computed(function () {
        return [
            {
                id: 'normal',
                name: this.get('intl').t('customer.status.normal'),
            },
            {
                id: 'demo',
                name: this.get('intl').t('customer.status.demo'),
            },
        ];
    }),
    //            {id: "disabled", name: @get('intl').t('customer.status.disabled')}
    getAdminUsers: function () {
        return $.ajax({
            url:
                config.APP.ADMINTOOLURL +
                'getCustomerAdminUsers?partnerid=' +
                this.get('model.partnerid'),
            type: 'GET',
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
            success: (data) => {
                return data;
            },
        });
    },
    billinginfo: Ember.computed('model', function () {
        return this.get('store').query('billinginfo', {
            partnerid: this.get('model.partnerid'),
        });
    }),
    recurringBillRows: Ember.computed('model', function () {
        return this.get('store').query('rcbill', {
            customer: this.get('model.id'),
            limit: 'all',
            //            startdate: moment().subtract(10,'years').format('YYYY-MM-DD')+"_"+moment().endOf('month').format('YYYY-MM-DD'),
            //            enddate: moment().startOf('month').format('YYYY-MM-DD')+"_"+moment().add(10,'years').format('YYYY-MM-DD')+"||(empty)",
            sideload: true,
        });
    }),
    billRows: Ember.computed('model', function () {
        return this.get('store').query('bill', {
            customer: this.get('model.id'),
            date:
                moment().startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('month').format('YYYY-MM-DD'),
            limit: 'all',
            sideload: true,
        });
    }),
    tt3billing: Ember.computed('model', function () {
        return this.get('store').query('tt3billing', {
            customer: this.get('model.partnerid'),
        });
    }),
    tt3billRows: Ember.computed('model', function () {
        return this.get('store').query('tt3bill', {
            customer: this.get('model.id'),
            date:
                moment().startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('month').format('YYYY-MM-DD'),
            sideload: true,
        });
    }),
    hasIntegrations: Ember.computed('model.integration', 'model.customization', function () {
        return (
            isPresent(this.get('model.integration')) || isPresent(this.get('model.customization'))
        );
    }),
    actions: {
        toggleFixedPrice: function () {
            if (this.get('has_fixed_price')) {
                this.set('price_disabled', true);
            } else {
                this.set('price_disabled', false);
            }
            return this.set('dirtyFixedPrice', true);
        },
        changedFixedPrice: function () {
            return this.set('dirtyFixedPrice', true);
        },
        //console.log "sdhkfsdh"
        saveFixedPrice: function () {
            var c, model, price;
            price = this.get('fixed_price');
            this.set('fixed_price_saving', true);
            c = this.get('Collector');
            // if record not found, create one
            if (!this.get('fixed_price_model')) {
                model = this.store.createRecord('fixedprice', {
                    price: price,
                    customer: this.get('model'),
                });
            } else {
                model = this.get('fixed_price_model');
            }
            // delete record
            if (!this.get('has_fixed_price')) {
                model.deleteRecord();
                if (this.get('fixedLimits').length > 0) {
                    this.dialogs
                        .confirm(
                            this.get('intl').t('admintool.fixedprice.confirm_remove_fixedlimits'),
                        )
                        .then((ret) => {
                            if (ret) {
                                return this.get('fixedLimits').invoke('destroyRecord');
                            }
                        });
                }
                this.set('fixed_price', null);
                this.set('fixed_price_model', null);
            } else {
                model.set('price', price);
                this.set('fixed_price_model', model);
            }
            return model.save().then(() => {
                this.set('fixed_price_saving', false);
                return this.set('dirtyFixedPrice', false);
            });
        },
        delete: function (row) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('general.remove_row'))
                .then(function (con) {
                    if (con) {
                        return row.destroyRecord();
                    }
                });
        },
        changeMainCampaign: function (campaign) {
            var camps;
            camps = this.store.peekAll('campaign').filterBy('name', campaign);
            // this is kinda stupid but didn't come up with anything else
            // iterate all products and set default-prices for all products
            this.get('customerProducts').forEach((product) => {
                if (!product['custom_campaign']) {
                    return Ember.set(product, 'price', product['default_price']);
                }
            });
            // ..after that set campaign-prices for those products that have them
            return camps.forEach((camp) => {
                var product;
                product = this.get('customerProducts').findBy(
                    'id',
                    parseInt(camp.get('product.id')),
                );
                if (product && !product['custom_campaign']) {
                    return Ember.set(product, 'price', camp.get('price'));
                }
            });
        },
        changeCampaign: function (product) {
            var found_camp, new_camp;
            this.set('dirtyRecords', true);
            // if product-campaign
            if (product['campaign'] !== '') {
                // first get record for this products product-campaign
                found_camp = this.store.peekRecord('productcampaign', product['campaign']);
                if (!product['custom_campaign']) {
                    // if this product didn't have customer-campaign, create new
                    new_camp = this.store.createRecord('customercampaign', {
                        campaign: found_camp,
                        customer: this.get('model'),
                    });
                    product['custom_campaign'] = new_camp; // this product now has new customer-campaign (not saved yet)
                } else {
                    // update customer-campaign to new product-campaign
                    product['custom_campaign'].set('campaign', found_camp);
                    product['custom_campaign'].send('becomeDirty'); // manually set record dirty because of belongsTo
                }
                return Ember.set(product, 'price', found_camp.get('price'));
            } else {
                // mark customer-campaign as deleted
                if (product['custom_campaign'].get('isNew')) {
                    this.store.unloadRecord(product['custom_campaign']);
                } else {
                    product['custom_campaign'].deleteRecord(); // this is in db so it needs to be deleted
                }
                product['custom_campaign'] = null;
                // use product default-price
                found_camp = this.get('customerProducts').findBy('id', product['id']);
                return Ember.set(product, 'price', found_camp['default_price']);
            }
        },
        clearCache: function () {
            return this.get('ajax')
                .request(
                    config.APP.TT4CAPIURL +
                        '/system/clear_memcache?partnerid=' +
                        this.get('model.partnerid'),
                )
                .then(() => {
                    return this.get('notifications').success(
                        this.get('intl').t('general.cache_cleared'),
                        {
                            autoClear: true,
                        },
                    );
                });
        },
        saveCampaigns: function () {
            this.store
                .peekAll('customercampaign')
                .filterBy('hasDirtyAttributes')
                .forEach((cp) => {
                    if (cp.get('hasDirtyAttributes')) {
                        return cp.save();
                    }
                });
            this.set('saving', false);
            return this.set('dirtyRecords', false);
        },
        saveCustomer: function () {
            this.set('errors', []);
            // show error if billing_start or vatin is empty, otherwise save
            if (
                (!this.get('model.billing_start') || !this.get('model.vatin')) &&
                this.get('model.status') === 'normal'
            ) {
                if (!this.get('model.billing_start')) {
                    this.get('errors').pushObject(
                        this.get('intl').t('admintool.overview.errors.billing_start'),
                    );
                }
                if (!this.get('model.vatin')) {
                    this.get('errors').pushObject(
                        this.get('intl').t('admintool.overview.errors.vatin'),
                    );
                }
                this.set('customerSaving', false);
                return;
            }
            return this.get('model')
                .save()
                .then(() => {
                    return this.set('customerSaving', false);
                });
        },
        changedSalesman: function (salesman) {
            this.set('model.salesman', this.get('store').peekRecord('user', salesman));
            return this.get('model').send('becomeDirty');
        },
        login: function () {
            if (this.get('clientVersion') === 'visma') {
                return this.get('tools').login(this.get('loginUser'), config.APP.ENTRY_URL);
            } else {
                return this.get('tools').login(this.get('loginUser'));
            }
        },
        forceBillingPopup: function () {
            return this.get('dialogs')
                .confirm(this.get('intl').t('admintool.overview.billing.force_popup_alert'))
                .then((con) => {
                    if (con) {
                        return this.get('billinginfo.content').destroyRecord();
                    }
                });
        },
        addRCBill: function () {
            this.get('store').createRecord('rcbill', {
                customer: this.get('model'),
            });
            return this.transitionToRoute({
                queryParams: {
                    modal: 'rcbill,new',
                },
            });
        },
        addFixedLimit: function () {
            this.get('store').createRecord('fixedlimit', {
                customer: this.get('model'),
            });
            return this.transitionToRoute({
                queryParams: {
                    modal: ['fixedlimit', 'new'],
                },
            });
        },
        /* not implemented
           changeBillingStart: (start) ->
               @set 'model.billing_start', start
               if start != ""
                   @set 'calculated_from_promo', false
               else
                   @set 'calculated_from_promo', true
           */
        editBillinginfo: function () {
            return this.transitionToRoute({
                queryParams: {
                    modal: 'billinginfo,' + this.get('billinginfo.id'),
                },
            });
        },
    },
});

export default AdmintoolOverviewController;
