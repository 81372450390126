/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/forms/form/field/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsFormsFormFieldController,
    indexOf = [].indexOf;

import Ember from 'ember';

import { t } from 'ember-intl';

import { isPresent } from '@ember/utils';

SettingsFormsFormFieldController = Ember.Controller.extend({
    formController: Em.inject.controller('settings.forms.form'),
    formsController: Ember.inject.controller('settings.forms'),
    tools: Ember.inject.service(),
    intl: Em.inject.service(),
    adminMode: Ember.computed.alias('formsController.adminMode'),
    fieldTypes: Em.computed('intl', 'adminMode', function () {
        var types;
        types = [
            {
                id: 'textbox',
                name: this.get('intl').t('field.type.textbox'),
            },
            {
                id: 'textarea_varchar',
                name: this.get('intl').t('field.type.textarea_varchar'),
            },
            {
                id: 'number',
                name: this.get('intl').t('field.type.number'),
            },
            {
                id: 'date',
                name: this.get('intl').t('field.type.date'),
            },
            {
                //{id: "dropdown_options", name: @get('intl').t("field.type.dropdown_options")}
                id: 'dropdown_values',
                name: this.get('intl').t('field.type.dropdown_values'),
            },
            {
                id: 'hours',
                name: this.get('intl').t('field.type.hours'),
            },
            {
                //{id: "price", name: @get('intl').t("field.type.price")}
                //{id: "big_price", name: @get('intl').t("field.type.big_price")}
                id: 'decimal',
                name: this.get('intl').t('field.type.decimal'),
            },
            {
                id: 'checkbox',
                name: this.get('intl').t('field.type.checkbox'),
            },
            {
                id: 'hdfile',
                name: this.get('intl').t('field.type.hdfile'),
            },
            {
                id: 'signature',
                name: this.get('intl').t('field.type.signature'),
            },
            {
                id: 'checkboxgroup',
                name: this.get('intl').t('field.type.checkboxgroup'),
            },
            {
                id: 'weather',
                name: this.get('intl').t('field.type.weather'),
            },
        ];
        if (this.get('adminMode')) {
            types.push({
                id: 'database',
                name: this.get('intl').t('field.type.database'),
            });
            types.push({
                id: 'timerlink',
                name: this.get('intl').t('field.type.timerlink'),
            });
            types.push({
                id: 'datetime',
                name: this.get('intl').t('field.type.datetime'),
            });
            types.push({
                id: 'status',
                name: this.get('intl').t('field.type.status'),
            });
            types.push({
                id: 'databasearray',
                name: this.get('intl').t('field.type.databasearray'),
            });
            types.push({
                id: 'multiselect',
                name: this.get('intl').t('field.type.multiselect'),
            });
            types.push({
                id: 'time_new',
                name: this.get('intl').t('field.type.time_new'),
            });
        }
        return types;
    }),
    fieldSelectorFields: ['database', 'databasearray', 'timerlink', 'multiselect'],
    field: Ember.computed.alias('model.field'),
    filteredSettings: Em.computed('model.settings.[]', function () {
        return this.get('model.settings').filterBy('field.id', this.get('model.field.id'));
    }),
    nameDisabled: Ember.computed('model.field', function () {
        if (this.get('adminMode')) {
            return false;
        }
        return (
            this.get('model.field.name') &&
            this.get('model.field.name').substring(0, 5) === '$lang' &&
            this.get('model.field.name') !== '$lang(10)'
        );
    }),
    showRawOptions: Ember.computed('model.field', function () {
        var dropdowns, field, ref;
        field = this.get('field');
        dropdowns = [
            'dropdown_values',
            'dropdown_userlevel',
            'dropdown_options',
            'dropdown_language',
            'checkboxgroup',
        ];
        if (!this.get('adminMode')) {
            return false;
        }
        if (((ref = field.get('type')), indexOf.call(dropdowns, ref) >= 0)) {
            return true;
        }
    }),
    /*
  typeObserver: Em.observer('field.type', ->
      type = @get('field.type')
      if (type == 'dropdown_options' || type == 'checkboxgroup') and !@get('field.options')
          @set('field.options', "")
      else if type == 'hdfile'
              @set('field.hdfile', true)
          else
              @set('field.hdfile', false)
  )

  */
    options: Em.computed('field.options', {
        set: function (key, value) {
            return (this._value = value);
        },
        get: function () {
            var EmberOptions,
                dropdowns,
                field,
                fieldString,
                formatOption,
                i,
                len,
                name,
                option,
                options,
                ref,
                splitOption;
            if (this._value) {
                return this._value;
            }
            field = this.get('field');
            dropdowns = [
                'dropdown_values',
                'dropdown_userlevel',
                'dropdown_options',
                'dropdown_language',
                'checkboxgroup',
            ];
            if (((ref = field.get('type')), indexOf.call(dropdowns, ref) < 0)) {
                return;
            }
            fieldString = field.get('form.external_type') + '.' + field.get('external_type');
            if (field.get('options') === void 0) {
                field.set('options', '');
            }
            options = field.get('options').split('\n');
            EmberOptions = Em.A([]);
            for (i = 0, len = options.length; i < len; i++) {
                option = options[i];
                if (field.get('type') === 'dropdown_options') {
                    EmberOptions.pushObject(option);
                } else {
                    splitOption = option.split(';');
                    formatOption = {
                        id: splitOption[0],
                    };
                    if (field.get('own') || isPresent(splitOption[1])) {
                        formatOption.name = splitOption[1];
                    } else {
                        name = this.get('intl').t(fieldString + '.' + splitOption[0]);
                        formatOption.name = name;
                    }
                    EmberOptions.pushObject(formatOption);
                }
            }
            return EmberOptions;
        },
    }),
    settableSettings: Ember.computed(
        'field.type',
        'filteredSettings.[]',
        'filteredSettings.@each.isDeleted',
        function () {
            var settableSettings;
            settableSettings = ['mandatory', 'stashed'];
            if (['checkbox', 'checkboxgroup'].includes(this.get('field.type'))) {
                settableSettings.splice(settableSettings.indexOf('mandatory'), 1);
            }
            if (this.get('field.type') === 'hdfile') {
                settableSettings.push('is_image');
            }
            return (settableSettings = settableSettings.map((settingName) => {
                var settingRecord;
                settingRecord = this.get('filteredSettings').find((record) => {
                    return (
                        record.get('name') === settingName &&
                        record.get('value') === 'true' &&
                        Ember.isEmpty(record.get('userlevel')) &&
                        Ember.isEmpty(record.get('conditions'))
                    );
                });
                return {
                    name: settingName,
                    on: settingRecord != null && !settingRecord.get('isDeleted'),
                    record: settingRecord,
                };
            }));
        },
    ),
    mandatory: Em.computed.alias('field.mandatory'),
    stashed: Em.computed.alias('field.stashed'),
    hdfile: Em.computed.alias('field.hdfile'),
    disabled: Em.computed.alias('field.isEditable'),
    dirtySettings: Em.A([]),
    hasDirtySettings: Em.computed.notEmpty('dirtySettings'),
    forms: Ember.computed(function () {
        return this.store.peekAll('form');
    }),
    databaseFieldSelections: Em.computed('field.id', 'selectedForm', function () {
        var allObjects, firstObject, firstOption, formExternal_type, options, typesWhitelist;
        if (this.get('selectedForm')) {
            formExternal_type = this.get('selectedForm');
        } else {
            if (Ember.isEmpty(this.get('field.options'))) {
                return [];
            }
            options = this.get('field.options').split('\n');
            firstOption = options[0];
            if (!firstOption) {
                return Em.A([]);
            }
            firstObject = this.store.peekRecord('form-field', firstOption);
            if (!firstObject) {
                return [];
            }
            formExternal_type = firstObject.get('form.external_type');
        }
        typesWhitelist = [
            'textbox',
            'number',
            'dropdown_options',
            'dropdown_values',
            'hdfile',
            'datetime',
            'hours',
        ];
        if (this.get('field.type') === 'databasearray') {
            typesWhitelist.push('database');
        }
        allObjects = this.store.peekAll('form-field').filter(function (item) {
            var ref;
            return (
                item.get('form.external_type') === formExternal_type &&
                !item.get('features.hide') &&
                ((ref = item.get('type')), indexOf.call(typesWhitelist, ref) >= 0)
            );
        });
        return allObjects.map((item) => {
            var name;
            if (item.get('name') === '$lang(10)') {
                name = this.get('intl').t(formExternal_type + '.' + item.get('external_type'));
                if (name.string != null) {
                    name = name.string;
                }
            } else {
                name = item.get('name');
            }
            return Ember.Object.create({
                id: item.get('id'),
                name: name.substring(0, 30),
            });
        });
    }),
    databaseFieldSelectionValues: null,
    observeDatabaseFieldSelectionValues: Em.observer('databaseFieldSelectionValues', function () {
        var options, ref;
        if (
            (ref = this.get('field.type')) !== 'database' &&
            ref !== 'timerlink' &&
            ref !== 'databasearray' &&
            ref !== 'multiselect'
        ) {
            return;
        }
        options = this.get('databaseFieldSelectionValues');
        if (options.length > 0) {
            return this.set('field.options', options.join('\n'));
        }
    }),
    initDatabaseFieldSelectionValues: Em.observer('field.id', function () {
        if (!this.get('field.options')) {
            return;
        }
        return this.set(
            'databaseFieldSelectionValues',
            this.get('field.options')
                .split('\n')
                .map(function (item) {
                    return item.trim();
                }),
        );
    }),
    formFieldSettingOptions: [
        {
            name: 'mandatory',
            value: 'true',
        },
        {
            name: 'hide_column',
            value: 'true',
        },
        {
            name: 'hide',
            value: 'true',
        },
        {
            name: 'validate_check',
        },
        {
            name: 'hide_filter',
            value: 'true',
        },
        {
            name: 'filter',
            value: '$userProfile->primary_key',
        },
        {
            name: 'set',
            value: '$userProfile->primary_key',
        },
        {
            name: 'disabled',
            value: 'true',
        },
        {
            name: 'default_value',
        },
    ],
    selectedWorktimegroups: Em.computed(
        'field.type',
        'filteredSettings.@each.condition',
        'filteredSettings.@each.isDeleted',
        function () {
            var groups;
            groups = [];
            this.filteredSettings.forEach((setting) => {
                if (
                    setting.name === 'hide' &&
                    setting.condition &&
                    setting.condition.includes('worktimegroup') &&
                    !setting.isDeleted
                ) {
                    return groups.push(
                        this.worktimegroups.findBy('id', setting.condition.split('==')[1]),
                    );
                }
            });
            return groups;
        },
    ),
    actions: {
        saveSettings: function () {
            return this.get('dirtySettings').forEach(function (setting) {
                if (setting.get('value') === 'false') {
                    return setting.destroyRecord();
                } else {
                    return setting.save();
                }
            });
        },
        turnOnSetting: function (setting) {
            var record;
            record = Ember.get(setting, 'record');
            if (Ember.isEmpty(record)) {
                return this.store.createRecord('form-field-setting', {
                    field: this.get('model.field'),
                    name: Ember.get(setting, 'name'),
                    value: 'true',
                });
            } else if (record.get('isDeleted')) {
                return record.rollbackAttributes();
            }
        },
        turnOffSetting: function (setting) {
            var record;
            record = Ember.get(setting, 'record');
            if (!record || record.get('isDeleted')) {
                return;
            }
            return Ember.get(setting, 'record').deleteRecord();
        },
        addSetting: function (option) {
            var values;
            this.set('clearAddSetting', true);
            if (option === 'custom') {
                values = {};
            } else {
                values = this.get('formFieldSettingOptions').findBy('name', option);
            }
            values.field = this.get('model.field');
            return this.store.createRecord('form-field-setting', values);
        },
        saveMandatorySetting: function (value) {
            var mandatorySetting, model;
            mandatorySetting = null;
            model = this.store
                .peekAll('form-field-setting')
                .filterBy('field.id', this.get('field.id'));
            model.forEach(function (setting) {
                if (setting.get('name') === 'mandatory') {
                    return (mandatorySetting = setting);
                }
            });
            if (value === false && mandatorySetting) {
                mandatorySetting.destroyRecord();
            }
            if (!mandatorySetting) {
                mandatorySetting = this.store.createRecord('form-field-setting', {
                    field: this.get('field'),
                    name: 'mandatory',
                });
            }
            if (value === true) {
                mandatorySetting.set('value', true);
                mandatorySetting.save();
            }
            this.set('mandatory', value);
            return this.send('refreshModel');
        },
        refreshModel: function () {
            return this.set(
                'model.settings',
                this.store.peekAll('form-field-setting').filterBy('field.id', this.get('field.id')),
            );
        },
        saveFieldName: function () {
            var field;
            if (this.get('field.name') === (field = this.get('field'))) {
                return;
            }
            if (
                field.get('name') === this.get('fieldName') ||
                field.get('translatedName.string') === this.get('fieldName')
            ) {
                return;
            }
            if (field.get('isNew')) {
                field.set(
                    'external_type',
                    (this.get('adminMode') ? '' : 'addon_') +
                        this.get('tools').computerizeHumanInput(this.get('fieldName')),
                );
            }
            return field.set('name', this.get('fieldName'));
        },
        saveOptions: function (options) {
            var field, i, len, option, optionsString;
            optionsString = '';
            field = this.get('field');
            for (i = 0, len = options.length; i < len; i++) {
                option = options[i];
                if (field.get('type') === 'dropdown_options') {
                    optionsString += option + '\n';
                } else {
                    if (!option.name || !option.id) {
                        return;
                    }
                    optionsString += option.id + ';' + option.name + '\n';
                }
            }
            if (optionsString !== '') {
                optionsString = optionsString.slice(0, -1);
            }
            return field.set('options', optionsString);
        },
        //get('formController').saveField(field)
        hideForWorktimegroup: function (groups) {
            groups.forEach((group) => {
                var condition;
                if (!this.selectedWorktimegroups.isAny('id', group.id)) {
                    condition = '$userProfile->worktimegroup==' + group.id;
                    this.createSetting('hide', condition, '!5');
                    this.createSetting('disabled', condition, '!5');
                    return this.createSetting('hide_column', condition, '!5');
                }
            });
            return this.selectedWorktimegroups.forEach((group) => {
                var condition, records;
                if (!groups.isAny('id', group.id)) {
                    condition = '$userProfile->worktimegroup==' + group.id;
                    records = this.filteredSettings.map((record) => {
                        if (record.condition === condition) {
                            return record;
                        }
                    });
                    return records.forEach((record) => {
                        if (record && !record.isDeleted) {
                            return record.deleteRecord();
                        }
                    });
                }
            });
        },
    },
    createSetting: function (name, condition, userlevel) {
        return this.store.createRecord('form-field-setting', {
            field: this.get('model.field'),
            name: name,
            condition: condition,
            userlevel: userlevel,
            value: 'true',
        });
    },
});

export default SettingsFormsFormFieldController;
