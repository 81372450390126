import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Service.extend({
    collector: service('collector-service'),
    ttapi: service(),
    session: service(),
    localstorage: service(),

    hasSaldos: false,

    async setUserSettings() {
        this.hasSaldos = this.localstorage.getItem('saldosOn');
        let hasSaldos = await this.checkIfUserHasSaldos();
        this.localstorage.setItem('saldosOn', hasSaldos);
        this.hasSaldos = hasSaldos;
    },

    checkIfUserHasSaldos() {
        if (!this.collector.testNeedsOne('products.saldo')) return false;
        if (!this.collector.testNeedsOne('worktimegroup')) return true;

        const userWorktimegroup = get(this.session, 'currentUser.worktimegroup.id');
        if (!userWorktimegroup) return true; //user is not in a worktimegroup so calculation is on

        return this.getSaldoSettings(userWorktimegroup);
    },

    async getSaldoSettings(userWorktimegroup) {
        const saldoSettings = await this.ttapi._get('saldo/saldoSettings');
        if (saldoSettings.skip_worktimegroups && !isEmpty(saldoSettings.skip_worktimegroups)) {
            //safety net
            //if user's worktimegroup is not included in the list of worktime groups to be skipped
            return !saldoSettings.skip_worktimegroups.split(',').includes(userWorktimegroup);
        } else {
            return true;
        }
    },
});
