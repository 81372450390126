/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/offering/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddOffersRoute;

import Ember from 'ember';

import baseRoute from '../base-route/route';

WorksitediaryReportAddOffersRoute = baseRoute.extend();

export default WorksitediaryReportAddOffersRoute;
