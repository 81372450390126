/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/form-field.coffee.old
// Generated by CoffeeScript 2.6.1
var FormField;

import DS from 'ember-data';

import formSettings from '../mixins/form-settings';

FormField = DS.Model.extend(formSettings, {
    intl: Ember.inject.service(),
    name: DS.attr('string'),
    external_type: DS.attr('string'),
    type: DS.attr('string'),
    options: DS.attr('string'),
    form: DS.belongsTo('form'),
    nro: DS.attr('number'),
    schemaid: DS.attr('string'),
    settings: DS.hasMany('form-field-setting'),
    removed: DS.attr('boolean'),
    editable: DS.attr('boolean'),
    translatedType: Ember.computed('type', function () {
        return this.get('intl').t('field.type.' + this.get('type'));
    }),
    own: Ember.computed('external_type', function () {
        var ext;
        ext = this.get('external_type');
        if (ext) {
            if (ext.substring(0, 6) === 'addon_') {
                return true;
            }
        }
        return false;
    }),
    mandatory: Ember.computed('settings.[]', function () {
        var mandatory;
        mandatory = false;
        this.get('settings').forEach(function (setting) {
            if (setting.get('name') === 'mandatory' && setting.get('value') === 'true') {
                return (mandatory = true);
            }
        });
        return mandatory;
    }),
    stashed: Ember.computed('settings.[]', function () {
        var stashed;
        stashed = false;
        this.get('settings').forEach(function (setting) {
            if (setting.get('name') === 'stashed' && setting.get('value') === 'true') {
                return (stashed = true);
            }
        });
        return stashed;
    }),
    isEditable: Em.computed('settings.[]', function () {
        var disable;
        disable = false;
        this.get('settings').forEach((setting) => {
            if (setting.get('name') === 'requirements') {
                return (disable = true);
            }
        });
        return disable;
    }),
    isLocked: Em.computed('settings.[]', function () {
        var disable;
        disable = false;
        this.get('settings').forEach((setting) => {
            if (setting.get('name') === 'lock_edit') {
                return (disable = true);
            }
        });
        return disable;
    }),
    dirtySettings: Em.computed('settings.[]', function () {
        return this.get('settings').filterBy('hasDirtyAttributes');
    }),
});

export default FormField;
