/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/register/service/done.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterDoneRoute;

import Ember from 'ember';

RegisterDoneRoute = Ember.Route.extend();

export default RegisterDoneRoute;
