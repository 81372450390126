import Component from '@ember/component';
import { get } from '@ember/object';

export default Component.extend({
    showAll: false,

    actions: {
        expandAll() {
            this.expandAll(true);
        },
        minimizeAll() {
            this.expandAll(false);
        },
        check() {
            this.toggleProperty('checked');
            this.checkAll(get(this, 'checked'));
        },
        changeStatus() {
            this.changeStatus('approved');
        },
    },
});
