import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ember-movenium-datetime-field\">\n    <Input @type=\"date\" @max=\"9999-12-31\" @value={{readonly this.date}} @focus-out={{fn this.changeDate}} />\n    <Input @type=\"time\" @value={{readonly this.time}} @focus-out={{fn this.changeTime}}/>\n</div>", {"contents":"<div class=\"ember-movenium-datetime-field\">\n    <Input @type=\"date\" @max=\"9999-12-31\" @value={{readonly this.date}} @focus-out={{fn this.changeDate}} />\n    <Input @type=\"time\" @value={{readonly this.time}} @focus-out={{fn this.changeTime}}/>\n</div>","moduleName":"tt4/components/addon/fields/datetime.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/datetime.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment';
// import { tracked } from '@glimmer/tracking';

interface IDateTimeField {
    value: string;
    onChange(arg0: string): void;
}
// "2020-09-24 15:53"
export default class FieldsDatetimeComponent extends Component<IDateTimeField> {
    get time(): string | null {
        return this.args.value?.split(' ')[1];
    }

    get date(): string | null {
        return this.args.value?.split(' ')[0];
    }

    @action
    protected changeDate(date: string): void {
        const time = this.time || moment().format('HH:mm');
        this.args.onChange(date + ' ' + time);
    }

    @action
    protected changeTime(time: string): void {
        const date = this.date || moment().format('YYYY-MM-DD');
        this.args.onChange(date + ' ' + time);
    }
}
