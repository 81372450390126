/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/reports/bills/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolReportsController;

import Ember from 'ember';

import config from '../../../../../config/environment';

AdmintoolReportsController = Ember.Controller.extend({
    store: Ember.inject.service(),
    billingHash: Ember.computed('model', function () {
        return (
            config.APP.MAIN_TTAPI +
            '/service/' +
            btoa(this.get('model.id') + '_' + this.get('model.country'))
        );
    }),
    tt3bills: Ember.computed('model', function () {
        return this.get('store').query('tt3bill', {
            customer: this.get('model.id'),
            sideload: true,
            order: 'date-',
        });
    }),
    customerid: Em.computed('model.id', function () {
        return this.get('model.id');
    }),
    month: Ember.computed(function () {
        return moment().format('YYYY-MM-DD');
    }),
    oldMonths: Em.computed('month', function () {
        var i, j, month, months;
        month = this.get('month');
        months = [];
        for (i = j = 1; j < 4; i = ++j) {
            months.push(moment(month).subtract(i, 'month').format('YYYY-MM-DD'));
        }
        return months;
    }),
    actions: {
        showOldBills: function () {
            this.set('showOldBills', true);
            return false;
        },
    },
});

export default AdmintoolReportsController;
