/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/draggable_item.coffee.old
// Generated by CoffeeScript 2.6.1
var DraggableItemComponent;

import Ember from 'ember';

DraggableItemComponent = Ember.Component.extend({
    classNames: ['draggable-item'],
    attributeBindings: ['draggable'],
    draggable: 'true',
    dragStart: function (event) {
        var msie, setDataString, trident, userAgent;
        //console.log "drag start: ",  @get('content')
        userAgent = window.navigator.userAgent;
        msie = userAgent.indexOf('MSIE ');
        trident = userAgent.indexOf('Trident/');
        if (msie > 0 || trident > 0) {
            setDataString = 'text';
        } else {
            setDataString = 'text/data';
        }
        return event.dataTransfer.setData(setDataString, this.get('content'));
    },
});

export default DraggableItemComponent;
