import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),
    notifications: service(),
    intl: service(),
    analytics: service(),

    loading: false,

    workweek: computed('data', async function () {
        const worktimes = await this.data;
        return [0, 1, 2, 3, 4, 5, 6].map((index) => {
            const date = moment().startOf('isoWeek').add(index, 'days');
            return {
                name: this.capitalize(date.format('dd')),
                active: moment().isSame(date, 'day'),
                hours: worktimes
                    .filter((worktime) => moment(worktime.date).isSame(date, 'day'))
                    .reduce((hours, worktime) => (hours += worktime.work_hours), 0),
            };
        });
    }),

    hoursToday: computed('data', async function () {
        return (await this.data)
            .filter((worktime) => moment(worktime.date).isSame(moment(), 'day'))
            .reduce((hours, worktime) => (hours += worktime.work_hours), 0);
    }),

    hourSum: computed('workweek', async function () {
        return (await this.workweek).reduce((hours, day) => (hours += day.hours), 0);
    }),

    init() {
        this._super();

        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    },

    didInsertElement() {
        set(this, 'data', this.loadWorktimeData());
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    },

    actions: {
        notifySaved() {
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        },
        showWorktimeModal() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Add worktime clicked',
                label: 'worktime add widget',
            });
            set(this, 'addWorktimeModal', true);
        },
    },

    onStoreEvent(params) {
        if (params.type === 'worktime') set(this, 'data', this.loadWorktimeData());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'data', this.loadWorktimeData());
    },

    async loadWorktimeData() {
        return await this.store.query('worktime', {
            user: get(this, 'session.currentUser.id'),
            date:
                moment().startOf('isoWeek').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('week').format('YYYY-MM-DD'),
        });
    },

    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
});
