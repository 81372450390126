/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/dropdown-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDropdownFieldComponent;

import Ember from 'ember';

import collectorField from '../../../../mixins/collector-field';

import $ from 'jquery';

CollectorFieldsDropdownFieldComponent = Ember.Component.extend(collectorField, {
    intl: Em.inject.service(),
    localStorageWrapper: Em.inject.service(),
    setDefaultValue: Ember.on('init', function () {
        if (this.get('field.value')) {
            return this.set('value', this.get('field.value'));
        }
    }),
    fieldoptions: Em.computed('field.options', 'field.options.[]', function () {
        var field, options;
        options = this.get('field.options');
        if (!options) {
            return;
        }
        field = this.get('field');
        return options.map((item) => {
            if (this.get('intl').exists(field.form + '.' + field.name + '.' + item.value)) {
                Ember.set(
                    item,
                    'value',
                    this.get('intl').t(field.form + '.' + field.name + '.' + item.value),
                );
            }
            return item;
        });
    }),
    localStorageKey: Em.computed('field.name', function () {
        return 'select_' + this.get('field.name');
    }),
    actions: {
        change: function () {
            //update localstorage
            this.get('localStorageWrapper').setUserSetting(
                this.get('localStorageKey'),
                $('select', this.element).val(),
            );
            //TODO: Should maybe use normal dropdown component and not own thing ?
            return this.sendAction(
                'bubbleChange',
                $('select', this.element).val(),
                this.get('field.name'),
            );
        },
    },
});

export default CollectorFieldsDropdownFieldComponent;
