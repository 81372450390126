import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
    tagName: 'div',
    classNames: ['day-cell-bar'],
    //classNameBindings: ['hasRows:day-empty'],
    //hasRows: notEmpty('rows')

    blockWidth: 35,

    dayCount: 3 /*computed("rows", function() {
       return this.get("rows.length")
    }),*/,

    widthInPixels: computed('dayCount', function () {
        return get(this, 'dayCount') * get(this, 'blockWidth') - 4 + 'px';
    }),

    isFirst: computed('rows', 'date', function () {
        return moment(get(this, 'rows.firstObject.date')).isSame(get(this, 'date'), 'day');
    }),
});
