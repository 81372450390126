/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/register/service/done.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterDoneController;

import Ember from 'ember';

RegisterDoneController = Ember.Controller.extend({
    registerController: Ember.inject.controller('register.service'),
    application: Ember.inject.controller(),
    userinfo: Ember.computed.alias('registerController.userinfo'),
    selectedProducts: Ember.computed.alias('registerController.selectedProducts'),
    Collector: Ember.inject.service('collector-service'),
    localStorageWrapper: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    initapp: Ember.inject.service(),
    intl: Ember.inject.service(),
    done: false,
    password: null,
    session: Ember.inject.service(),
    loginToService: function () {
        var passwd, promise, self, userinfo, username;
        username = this.get('username');
        userinfo = this.get('userinfo');
        passwd = this.get('password');
        self = this;
        promise = this.get('session').authenticate(
            'authenticator:collector-auth',
            username,
            passwd,
        );
        return promise.then(() => {
            return this.addWelcomeMessageForAdmin().then(() => {
                //set new messageboard as default for new customers
                this.get('customerSettings').setSetting('new_messageboard', 'on');
                //and hide the setting, so customers cannot not change to old messageboard
                this.get('customerSettings').setSetting('hide_setting_new_messageboard', 'on');
                //set hiding of measurement-index to new customers
                //if @get('Collector').testNeedsOne('products.measurement_tr') or @get('Collector').testNeedsOne('products.measurement_mvr')
                //@get("customerSettings").setSetting("hide_measurement_index", "on")
                return this.get('initapp').start();
            });
        });
    },
    callServiceCreation: function () {
        var c, promise, self, userinfo;

        //        chars = '23456789abcdefghkmnpqrstuvwxyz'
        //        string_length = 8
        //        generated_password = ''
        //        i = 0
        //        while i < string_length
        //            rnum = Math.floor(Math.random() * chars.length)
        //            generated_password += chars.substring(rnum, rnum + 1)
        //            i++
        //        if userinfo.get 'password'
        //            password = userinfo.get 'password'
        //        else
        //            password = generated_password

        //        @set 'password', password
        userinfo = this.get('userinfo');
        c = this.get('Collector');
        self = this;
        promise = c.ajax({
            url: 'createService',
            method: 'post',
            data: {
                firstname: userinfo.get('firstname'),
                lastname: userinfo.get('lastname'),
                country: userinfo.get('country'),
                company: userinfo.get('company'),
                vatin: userinfo.get('vatin'),
                phone: userinfo.get('phone'),
                email: userinfo.get('email'),
                domain: userinfo.get('domain'),
                password: userinfo.get('password'),
                products: this.get('selectedProducts').mapBy('name').join(','),
            },
        });
        promise.then((back) => {
            var ls;
            self.set('username', back.username);
            self.set('userid', back.userid);
            ls = this.get('localStorageWrapper');
            ls.setSavedUsername(back.username);
            self.set('password', userinfo.get('password'));
            self.set('done', true);
            //            self.get('registerController').sendCrmInfo(true)
            return this.get('selectedProducts').forEach(function (product) {
                var company_name, contact_person, email_content, email_country, email_header;
                if (product.get('name') === 'rfid') {
                    company_name = userinfo.get('company');
                    email_country = userinfo.get('country');
                    contact_person = userinfo.get('lastname') + ' ' + userinfo.get('firstname');
                    email_content =
                        self.get('intl').t('general.' + email_country + '_nfc_content') +
                        '<br><br>' +
                        self.get('intl').t('general.' + email_country + '_nfc_customer_info') +
                        ':<br>' +
                        contact_person +
                        '<br>' +
                        userinfo.get('email') +
                        '<br>' +
                        userinfo.get('phone');
                    email_header = self.get('intl').t('general.' + email_country + '_nfc_header');
                    return (promise = self.get('Collector').ttapi({
                        url: 'sendNfcEmail',
                        data: {
                            content: email_content.replace('{customer}', company_name),
                            header: email_header.replace('{customer}', company_name),
                            country: email_country,
                        },
                        method: 'post',
                    }));
                }
            });
        });
        promise.catch(function (back) {
            var ret;
            ret = JSON.parse(back.responseText);
            alert('Service creation failed! Please try again.\n' + ret.error_description);
            return self.transitionToRoute('login');
        });
        return promise;
    },
    addWelcomeMessageForAdmin: function () {
        var c;
        c = this.get('Collector');
        return c.ttapi({
            url: 'messages/new_message',
            data: {
                receivers: [this.get('userid')],
                type: 'welcome-admin',
            },
            method: 'post',
        });
    },
    actions: {
        login: function () {
            return this.loginToService();
        },
    },
});

export default RegisterDoneController;
