/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/event/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyEventController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

SiteSafetyEventController = GeneralReportController.extend({
    form: 'site_safety_event',
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    queryParams: ['status', 'page', 'employer', 'creator', 'groupBy', 'project'],
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'employer',
            }),
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'creator',
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
            Em.Object.create({
                field: 'groupBy',
                type: 'groupByFilter',
            }),
        ]);
    }),
    //        Em.Object.create({field: "user-group"})
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            //            hideEdit: true
            //            hideHistory: true
            editRoute: {
                modal: true,
            },
        }),
        massedit: true,
        rowActions: [],
        sortByColumn: 'created-',
    }),
    search: false,
    status: 'all',
    limit: 25,
    groupBy: null,
    project: null,
    user: null,
    q: null,
    showColumns: ['created', 'creator', 'status', 'desc', 'project', 'pic'],
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
    },
});

export default SiteSafetyEventController;
