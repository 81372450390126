/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-report/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarReportComponent;

import Ember from 'ember';

//import collector from '../../classes/collector'
CalendarReportComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    gui: Ember.inject.service(),
    store: Ember.inject.service(),
    date:
        moment.utc().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment.utc().endOf('month').format('YYYY-MM-DD'),
    hideWeekend: true,
    showWeekend: Ember.computed.not('hideWeekend'),
    workdays: Ember.computed('user', 'date', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                var c, params;
                c = this.get('Collector');
                params = {
                    country: this.get('session.currentUser.company_info.country'),
                    date: this.get('date'),
                };
                if (c.formExists('worktimegroup') && this.get('user')) {
                    const record = this.store.peekRecord('user', this.get('user'));
                    params['worktimegroup'] = record.get('worktimegroup.id')
                        ? record.get('worktimegroup.id')
                        : null;
                }
                return this.get('store')
                    .query('workday-day', params)
                    .then((rows) => {
                        var days;
                        days = {};
                        rows.forEach((row) => {
                            return (days[row.get('date')] = row.get('desc'));
                        });
                        return resolve({
                            value: days,
                        });
                    });
            }),
        });
    }),
    calendar: Ember.computed('date', function () {
        const extractDates = (dateString) => {
            const [start, end] = dateString.split('_');
            return { startDay: moment.utc(start).toDate(), endDay: moment.utc(end).toDate() };
        };
        const weekdayNames = [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
        ];
        const { startDay, endDay } = extractDates(this.get('date'));
        // Get days between start and end
        const days = [];
        let day = startDay;
        while (day <= endDay) {
            days.push(day);
            day = moment.utc(day).add(1, 'day').toDate();
        }
        // Get week number(s) between start and end
        const weekNumbers = [];
        let week = moment(startDay).startOf('isoWeek').toDate();
        while (week <= endDay) {
            weekNumbers.push(moment(week).isoWeek());
            week = moment(week).add(1, 'week').toDate();
        }
        // Create array for each week
        const weekArrays = [];
        weekNumbers.forEach(() => {
            weekArrays.push(['', '', '', '', '', '', '']);
        });
        // Fill week arrays with their days
        weekArrays.forEach((weekArray) => {
            for (let i = 0; i < 7; i++) {
                const _day = days.shift();
                if (!_day) break;
                const index = weekdayNames.indexOf(
                    _day.toLocaleDateString('en', { weekday: 'long' }).toLowerCase(),
                );
                weekArray[index] = _day;
                if (index === 6 || days.length === 0) break;
            }
        });
        // Format week array dates
        const formattedWeekArrays = weekArrays.map((weekArray) => {
            return weekArray.map((weekday) => {
                if (weekday === '') return weekday;
                else return moment(weekday).format('YYYY-MM-DD');
            });
        });
        // Create object with week numbers as keys and week arrays as values (i.e 202309: [....])
        let weekObject = {};
        let currentYear = moment().year();
        let lastWeekNumber = 0; // Variable to keep track of the last week number

        weekNumbers.forEach((weekNumber, index) => {
            // Check if the weekNumber has rolled over to the next year
            if (weekNumber < lastWeekNumber) {
                currentYear++;
            }
            // Update the lastWeekNumber for the next iteration
            lastWeekNumber = weekNumber;
            // Generate the key for weekObject
            let key =
                weekNumber < 10 ? `${currentYear}0${weekNumber}` : `${currentYear}${weekNumber}`;
            weekObject[key] = formattedWeekArrays[index];
        });
        return weekObject;
    }),
    actions: {
        fullScreen: function () {
            if (this.get('fullscreenmode') === 'custom') {
                return this.get('gui').toggleCustomFullScreen();
            } else {
                return this.get('gui').togglefullScreen();
            }
        },
        toggleHideWeekend: function () {
            return this.toggleProperty('hideWeekend');
        },
    },
});

export default CalendarReportComponent;
