/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import localization from '../../../classes/localization';

import pdfmixin from '../../../mixins/worksitediary_pdf';

WorksitediaryIndexController = GeneralReportController.extend(pdfmixin, {
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    cordova: Ember.inject.service('own-cordova-service'),
    store: Ember.inject.service(),
    form: 'diaryreport',
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: 'worksitediary.report.add.basic-info',
        }),
        hideColumns: [
            'diaryreport_given_offer',
            'diaryreport_misc',
            'diaryreport_other_entrie',
            'diaryreport_required_extra',
            'diaryreport_supervisor_note',
            'events_altered',
            'events_finished',
            'events_interrupted',
            'events_started',
            'events_work_in_progress',
            'orders_ordered',
            'orders_received',
            'notes',
        ],
        rowActions: [],
    }),
    pdfReport: null,
    addRowActions: function () {
        var columns, fields, intl, pdf_output, self2, title_row, widths;
        self2 = this;
        intl = this.get('intl');
        if (this.Collector == null) {
            return;
        }
        pdf_output = [];
        title_row = [];
        columns = [];
        widths = [];
        widths.push('auto');
        pdf_output.push(title_row);
        fields = this.Collector.fields('diaryreport');
        if ('status' in fields) {
            columns = fields;
            return this.set('settings.rowActions', [
                {
                    text: 'status.journal_pdf',
                    action: (self, items) => {
                        return this.createWorksitediaryPdf(items);
                    },
                },
            ]);
        }
    }.on('init'),
    showColumns: [
        'project',
        'status',
        'diary_date',
        'work_week',
        'worksite_number',
        'supervisor',
        'overseer',
    ],
    status: 'all',
    //    hideColumns: Em.on('init', ->
    //        @set('settings.hideColumns', [

    //        ])

    //    )
    queryParams: ['project', 'diary_date', 'page', 'user'],
    diary_date:
        moment().subtract(1, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    filters: Em.A([
        Em.Object.create({
            field: 'diary_date',
        }),
        Em.Object.create({
            field: 'project',
        }),
        Em.Object.create({
            field: 'user',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    actions: {
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        transitionToWizard: function () {
            return this.transitionToRoute('worksitediary.report.first');
        },
        transitionToEvents: function () {
            return this.transitionToRoute('worksitediary.events');
        },
    },
});

export default WorksitediaryIndexController;
