import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { inject as service } from '@ember/service';

export default Route.extend(AuthenticatedRouteMixin, {
    Collector: service('collector-service'),

    beforeModel(transition) {
        if (!this.Collector.testNeeds(['products.admintool'])) {
            transition.abort();
            this.transitionTo('my-sites.index');
        }
    },
});
