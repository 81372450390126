import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ember-movenium-gpslocation-field\">\n    {{#if this.gps.lockedPosition}}\n        {{t 'gps.location.found'}} {{#if this.distanceToProject}} ({{t 'gps.location.distance'}} {{this.formattedDistanceToProject}}) {{/if}} <span class=\"text-success\">{{standard-icon 'ok'}}</span>\n    {{else if this.gps.notSupported}}\n        {{t \"gps.not_supported\"}}\n    {{else if this.gps.errorMsg}}\n        {{this.gps.errorMsg}}\n    {{else}}\n        <img src=\"img/ajax-loader.gif\" alt=\"\" role=\"none\"/>\n    {{/if}}\n</div>", {"contents":"<div class=\"ember-movenium-gpslocation-field\">\n    {{#if this.gps.lockedPosition}}\n        {{t 'gps.location.found'}} {{#if this.distanceToProject}} ({{t 'gps.location.distance'}} {{this.formattedDistanceToProject}}) {{/if}} <span class=\"text-success\">{{standard-icon 'ok'}}</span>\n    {{else if this.gps.notSupported}}\n        {{t \"gps.not_supported\"}}\n    {{else if this.gps.errorMsg}}\n        {{this.gps.errorMsg}}\n    {{else}}\n        <img src=\"img/ajax-loader.gif\" alt=\"\" role=\"none\"/>\n    {{/if}}\n</div>","moduleName":"tt4/components/addon/fields/gpslocation.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/gpslocation.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import GpsService from 'tt4/services/gps2';
import { IRecord } from 'tt4/ember-movenium/interfaces/record';
import DS from 'ember-data';
import EventedService from 'tt4/injections/services/need';
import { tracked } from '@glimmer/tracking';

interface IGpslocation {
    gps: GpsService;
    onChange(args0: string | null): void;
    record: IRecord;
}

export default class FieldsGpslocationComponent extends Component<IGpslocation> {
    @service('gps2' as any) declare gps: GpsService;
    @service private store!: DS.Store;
    @service private evented: EventedService;

    @tracked public distanceToProject: number | null = null;

    constructor(owner: any, args: IGpslocation) {
        super(owner, args);
        this.gps.start();
        this.evented.on('formAddFieldChanged', this, 'onFormFieldChange');
        this.evented.on('gpsLockedPositionChanged', this, 'onPositionChance');
        //this is needed when user edits record and the gps is already initialized
        //why? because in that case onFormFieldChange or onPositionChance is not called, so the location/distance is not updated
        this.sendLocationToForm(this.gps.lockedPosition);
    }

    willDestroy() {
        this.evented.off('formAddFieldChanged', this, 'onFormFieldChange');
        this.evented.off('gpsLockedPositionChanged', this, 'onPositionChance');
    }

    get formattedDistanceToProject(): string {
        const distance = this.distanceToProject as number;
        if (distance > 1000) return Math.round(distance / 1000) + 'km';
        else return Math.round(distance) + 'm';
    }

    onPositionChance() {
        this.sendLocationToForm(this.gps.lockedPosition);
    }

    onFormFieldChange(params: any) {
        if (params.fieldName === 'project') this.sendLocationToForm(this.gps.lockedPosition);
    }

    private async sendLocationToForm(userLocation: any): Promise<void> {
        // do not send if the user's location is unknown
        if (!this.gps.lockedPosition) return;

        // set "distance to project" info to userLocation object
        await this.setGpsDistanceToProject(userLocation);

        // location is saved as string in form-add
        if (typeof userLocation != 'string') userLocation = JSON.stringify(userLocation);

        // send user location to form-add
        return this.args.onChange(userLocation);
    }

    // try to inject "distance to project" to given userLocation object
    private async setGpsDistanceToProject(userLocation: any): Promise<void> {
        if (!this.args.record?.project) return;

        if (typeof userLocation === 'string') userLocation = JSON.parse(userLocation);

        const project = await this.store.findRecord('project', this.args.record.project.id, {
            reload: true,
        });
        if (!project || !project.location_map) {
            // if project has no location, remove possible distance from user location and from gps service
            // why? because if user has selected a project with location and then selects a project without location, the distance to project will remain
            this.distanceToProject = null;
            delete userLocation.distance;
        } else {
            let projectLocation =
                typeof project.location_map === 'string'
                    ? JSON.parse(project.location_map)
                    : project.location_map;

            userLocation.distance = this.gps.getDistance(userLocation, projectLocation);
            this.distanceToProject = userLocation.distance;
        }
    }
}
