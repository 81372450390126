/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/check/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersCheckRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

EmployersCheckRoute = GeneralReportRoute.extend({
    flags: Ember.inject.service(),
    form: 'employer',
    queryParams: {
        page: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        if (
            !this.get('flags').test('form-report-employers') &&
            !this.get('flags').test('ember-movenium-form-report-employers')
        ) {
            params = this._super(params);
            params.sideload = true;
            params.tv_status = '!ok';
            return this.store.query('employer', params);
        }
    },
});

export default EmployersCheckRoute;
