/*eslint-disable ember/named-functions-in-promises */
import collector from '../../../../classes/collector';
import recordComp from '../../record-comp/component';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

const CollectorAddModalWorktimeComponent = recordComp.extend({
    Collector: service('collector-service'),
    dialogs: service(),
    router: service(),
    intl: service(),
    store: service(),
    ttapi: service(),
    session: service(),

    init() {
        this._super();
        const fields = collector.getFormObjects('worktime');
        if (
            !fields.starttime.features.hide &&
            !fields.endtime.features.hide &&
            !fields.work_hours.features.mandatory
        ) {
            fields.starttime.features.default_value = '07:00';
            fields.endtime.features.default_value = moment().format('HH:mm');
            fields.date.features.default_value = moment().format('YYYY-MM-DD');
        }

        if (fields.starttime.features.hide && fields.endtime.features.hide) {
            return (fields.date.features.default_value = moment().format('YYYY-MM-DD'));
        }
    },

    actions: {
        afterSave(settings, row) {
            // save worktime adding to ttapi
            this.ttapi.request('/worktimeAdded', {
                method: 'post',
                data: {
                    username: get(row, 'user.username'),
                    userid: get(row, 'user.id'),
                    starttime: get(row, 'starttime'),
                    date: get(row, 'date'),
                },
            });

            // Alert for missing orientation or outdated pass
            if (
                this.Collector.testNeedsOne('modules.orientations') &&
                get(this, 'session.currentUser.userlevel') === '1' &&
                !get(this, 'session.currentUser.no_orientation')
            ) {
                const project = get(row, 'project.id');
                if (this.Collector.testNeedsOne('products.rakentajanpaketti')) {
                    if (
                        get(row, 'project.proj_type') === '2' ||
                        get(row, 'project.proj_type') === null
                    ) {
                        return;
                    }
                }
                const user = get(row, 'user.id');
                return this.store
                    .query('orientation', { ori_site: project, ori_user: user, limit: 1 })
                    .then((data) => {
                        if (
                            this.Collector.testNeedsOne('products.orientations_self') &&
                            get(data, 'meta.count') === 0
                        ) {
                            return this.dialogs
                                .alert(
                                    this.intl.t('worktime.after_save.orientation.self_orientation'),
                                )
                                .then(() => {
                                    return this.router.transitionTo(
                                        'orientations.own-orientation',
                                        get(row, 'project.id'),
                                    );
                                });
                        } else if (get(data, 'meta.count') === 0) {
                            return this.dialogs.alert(
                                this.intl.t('worktime.after_save.orientation.not_found'),
                            );
                        } else if (
                            get(data, 'firstObject.pass_end') &&
                            moment().isAfter(moment(get(data, 'firstObject.pass_end')))
                        ) {
                            return this.dialogs.alert(
                                this.intl.t('worktime.after_save.orientation.outdated'),
                            );
                        }
                    });
            }
        },
    },
});

export default CollectorAddModalWorktimeComponent;
