/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/general-v2/components/postpopulated-worktimes/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompSaldoSettingComponent;

import Ember from 'ember';

import collector from '../../../../../classes/collector';

CollectorFieldSettingCompSaldoSettingComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    ttapi: Em.inject.service(),
    customerSettings: Em.inject.service(),
    store: Em.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    loading: true,
    settingKey: Ember.computed('worktimegroup', function () {
        return (
            'postpopulated_worktimes_project' +
            (this.get('worktimegroup') ? '_' + this.get('worktimegroup') : '')
        );
    }),
    didReceiveAttrs: function () {
        this.set('loading', true);
        return this.get('ttapi')
            ._get('/settings?worktimegroup=' + this.get('worktimegroup'))
            .then((ret) => {
                var projectSetting;
                projectSetting = ret.findBy('key', this.get('settingKey'));
                if (projectSetting && projectSetting.value) {
                    this.get('store')
                        .findRecord('project', projectSetting.value)
                        .then((project) => {
                            return this.set('selectedProject', project);
                        });
                }
                return this.set('loading', false);
            });
    },
    projectField: Ember.computed('selectedProject', function () {
        var field;
        field = this.get('Collector').field('worktime', 'project');
        field.value = this.get('selectedProject');
        return field;
    }),
    disabled: Em.computed('selectedProject', function () {
        if (!this.get('selectedProject') || this.get('selectedProject') === null) {
            return true;
        }
        return false;
    }),
    actions: {
        saveSetting: function () {
            this.set('loading', true);
            return this.get('customerSettings')
                .setSetting(
                    this.get('settingKey'),
                    this.get('selectedProject.id'),
                    this.get('worktimegroup'),
                )
                .then(() => {
                    this.set('loading', false);
                    if (this.get('settingSaved')) {
                        return this.settingSaved();
                    }
                });
        },
        changeProject: function (project) {
            return this.set('selectedProject', project);
        },
    },
});

export default CollectorFieldSettingCompSaldoSettingComponent;
