import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import podNames from 'ember-component-css/pod-names';

export default class CollectorFormAddFormsWorktimegroupChangeComponent extends Component {
    @service flags;
    @service store;
    @service('collector-service') Collector;
    @tracked currentPage = 1;
    @tracked canEdit = true;

    constructor() {
        super(...arguments);
        if (!this.args.record) {
            this.args.record = this.store.createRecord('worktimegroupchange');
        }
        this.submenus = [
            'worktimegroup-change-help',
            'worktimegroup-change-add',
            'worktimegroup-change-mapping',
        ];
        this.canEdit = this.checkIfCanEdit();
    }

    get styleNamespace() {
        return podNames['collector/form-add/forms/worktimegroupchange'];
    }

    @action
    onFieldChange(field, value) {
        this.args.record[field] = value;
    }

    get currentPageComponent() {
        return 'collector/form-add/forms/worktimegroupchange/pages/page' + this.currentPage;
    }

    // record can be edited only if the following conditions are met:
    // - its enddate is today or in the future or it doesn't have an enddate
    // - its startdate is today or in the future
    checkIfCanEdit() {
        if (this.args.record.enddate) {
            if (moment(this.args.record.enddate).isSameOrBefore(moment().format('YYYY-MM-DD'))) {
                return false;
            }
        }
        if (moment(this.args.record.startdate).isSameOrBefore(moment().format('YYYY-MM-DD'))) {
            return false;
        }
        return true;
    }

    // next button should be disabled if current page is 2 and the following conditions are met:
    // - it has startdate
    // - user AND destination are set
    get nextButtonDisabled() {
        if (this.currentPage == 2) {
            if (this.args.record.startdate) {
                if (this.args.record.user && this.args.record.destination) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
}
