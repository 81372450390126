/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/general-report.coffee.old
// Generated by CoffeeScript 2.6.1
var GeneralReportMixin;

import Ember from 'ember';

GeneralReportMixin = Ember.Mixin.create({
    queryParams: ['page', 'order', 'row_info_status', 'limit', 'more'],
});

export default GeneralReportMixin;
