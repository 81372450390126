/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/product.coffee.old
// Generated by CoffeeScript 2.6.1
var UserHelper;

import Ember from 'ember';

import config from '../config/environment';

UserHelper = Ember.Helper.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    // This function receives the params `params, hash`
    compute: function (params, hash) {
        var found, product;
        product = params[0];
        found = this.get('Collector').getProductByName(product);
        if (!found) {
            return params[0];
        } else if (!found.then) {
            return Ember.get(found, 'header');
        } else {
            found.then(() => {
                return this.recompute();
            });
            return '';
        }
    },
});

export default UserHelper;
