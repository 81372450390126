/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/local-storage-wrapper.coffee.old
// Generated by CoffeeScript 2.6.1
var localStorageWrapper;

import Ember from 'ember';

import collector from '../classes/collector';

import $ from 'jquery';

localStorageWrapper = Ember.Service.extend({
    session: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    ttapi: Ember.inject.service(),
    // this is true when syncing is under way
    syncing: false,
    namespace: 'movenium',
    getUserSetting: function (key, defaultValue) {
        var hashed, setting;
        hashed = this.getHashedKey(key);
        setting = this.getItem(hashed, defaultValue);
        return setting;
    },
    setUserSetting: function (key, value) {
        var hashed;
        hashed = this.getHashedKey(key);
        return this.setItems(hashed, value);
    },
    removeUserSetting: function (key) {
        var hashed;
        hashed = this.getHashedKey(key);
        return localStorage.removeItem(this._namespacedKey(hashed));
    },
    getHashedKey: function (key) {
        var combined, id;
        id = this.get('session.currentUser.id');
        combined = id + '_' + key;
        //TODO: Could actually create a hash out of the key
        return combined;
    },
    createId: function (length = 32) {
        var id;
        id = '';
        while (id.length < length) {
            id += Math.random().toString(36).substr(2);
        }
        return id.substr(0, length);
    },
    setItem: function (key, object) {
        var objects;
        object.id = this.createId();
        objects = this.getItem(key);
        if (objects == null) {
            objects = [];
        }
        objects.push(object);
        this.setItems(key, objects);
        return object.id;
    },
    getItem: function (key, defaultValue) {
        var item;
        item = localStorage.getItem(this._namespacedKey(key));
        if (!item && defaultValue) {
            return Em.A(defaultValue);
        } else if (item === 'undefined') {
            return Em.A([]);
        } else {
            return Em.A(JSON.parse(item));
        }
    },
    setItemsWithBackend: function (key, object) {
        var data;
        this.setItems(key, object);
        data = {};
        data[key] = object;
        return this.get('ttapi')._post('lssave', data);
    },
    getItemWithBackend: function (key, object) {
        return {
            local: this.getItem(key, object),
            backend: this.get('ttapi')._get('lssave?key=' + key, []),
        };
    },
    setSavedUsername: function (username) {
        return localStorage.setItem('username_save', username);
    },
    getSavedUsername: function () {
        return localStorage.getItem('username_save');
    },
    // Does not work with async
    //    deleteItem: (key, index) ->
    //        objects = @getItem(key)
    //        if !objects?
    //            return false
    //        objects.splice(index,1)
    //        console.log objects
    //        @setItems(key, objects)

    //find objects with object name and value
    find: function (key, obj_key, value) {
        var i, item, items, len, re, return_items;
        //console.log "finding " + key + " with " + obj_key + " = " + value
        items = this.getItem(key);
        return_items = Em.A([]);
        if (items != null) {
            for (i = 0, len = items.length; i < len; i++) {
                item = items[i];
                re = new RegExp(value);
                if (item[obj_key] != null) {
                    if (item[obj_key].search(re) !== -1) {
                        return_items.push(item);
                    }
                }
            }
        }
        //console.log "found", return_items
        return return_items;
    },
    findBelongsTo: function (belongsToId) {
        var arr;
        if (belongsToId.substring == null) {
            return null;
        }
        if (belongsToId.substring(0, 10) !== 'belongsTo:') {
            return null;
        }
        arr = belongsToId.substring(10).split('#');
        if (arr.length < 2) {
            return null;
        }
        return this.find(arr[0], 'id', arr[1])[0];
    },
    update: function (key, updated) {
        var i, item, items, len, new_items;
        items = this.getItem(key);
        new_items = [];
        for (i = 0, len = items.length; i < len; i++) {
            item = items[i];
            if (item.id === updated.id) {
                if (!updated.remove_flag) {
                    new_items.push(updated);
                }
            } else {
                new_items.push(item);
            }
        }
        return this.setItems(key, new_items);
    },
    setItems: function (key, objects) {
        var stringified;
        stringified = JSON.stringify(objects);
        return localStorage.setItem(this._namespacedKey(key), stringified);
    },
    _namespacedKey: function (key) {
        return this.get('namespace') + '_' + key;
    },
    getBelongsToId: function (model, id) {
        return 'belongsTo:' + model + '#' + id;
    },
    synchronize: function (keys) {
        var promiseArray, self;
        if (this.get('syncing')) {
            return;
        }
        this.set('syncing', true);
        self = this;
        promiseArray = [];
        $.each(keys, function () {
            var items, param;
            items = self.getItem(this.key);
            //console.log items
            param = this;
            return items.forEach(function (item) {
                return promiseArray.push(self.synchronizeItem(item, param));
            });
        });
        return $.when(promiseArray).done(function () {
            return self.set('syncing', false);
        });
    },
    synchronizeItem: function (item, param) {
        var data_to_server, self, to;
        self = this;
        //delete item if synced and 3 days old
        if (item.sync === 'ok') {
            //check if 3 days old or older
            if (moment().diff(item.date, 'days') >= 3) {
                //set remove flag
                item.remove_flag = true;
                //and update items
                self.update(param.key, item);
            }
            return;
        }
        self = this;
        to = param.to != null ? param.to : param.key;
        if (!self.isFilter(item, param.filter)) {
            return;
        }
        //console.log param.key + ' #'+ item.id + " sync state " + item.sync
        // this would be needed if we wanted to check if item already exists in backend
        //                if @pairing?
        //                    self.getId to, @pairing, item[@pairing], (id) ->
        //                        item.backend = id
        //                        item.sync = 'ok'
        //                        self.update key, item
        //                        return
        //                    , ->
        //                        self.send to, item
        //                else

        //console.log "item before ",item
        data_to_server = this.resolveBelongsTo(item);
        //console.log "item after ",item
        return self.send(to, data_to_server, item, param.key, item.sync === 'dirty');
    },
    resolveBelongsTo: function (item) {
        var ret, self;
        self = this;
        ret = {};
        $.each(item, function (key, value) {
            var belongsToItem;
            if (typeof value === 'object') {
                return (ret[key] = parseInt(value.id));
            } else {
                belongsToItem = self.findBelongsTo(value);
                return (ret[key] = belongsToItem ? belongsToItem.backend : value);
            }
        });
        return ret;
    },
    isFilter: function (item, filter_array) {
        var ret, self;
        if (!filter_array) {
            return true;
        }
        ret = true;
        self = this;
        $.each(filter_array, function (key, value) {
            if (!self.isOneFilter(item, key, value)) {
                return (ret = false);
            }
        });
        return ret;
    },
    isOneFilter: function (item, key, value) {
        var a, id, key_arr, test;
        key_arr = key.split('.');
        if (key_arr.length > 1) {
            id = item[key_arr[0]];
            if (!id) {
                return false;
            }
            a = this.findBelongsTo(id);
            test = a ? a[key_arr[1]] : null;
        } else {
            //console.log "testing: " + key_arr[0] + " id: " + id + " test = " + test + " == " + value,a
            test = item[key];
        }
        if (value === true) {
            return test;
        } else {
            return test === value;
        }
    },
    getId: function (form, field, value, found, notfound) {
        var json_data, realdata, type, url;
        //console.log "finding "+form + " where " + field + " = " + value
        url = collector.api_url() + '/' + form + 's?validation=off';
        type = 'POST';
        realdata = {};
        realdata[form] = data;
        json_data = JSON.stringify(realdata);
        return $.ajax(url, {
            contentType: 'application/json',
            data: json_data,
            type: type,
            error: function (err) {
                console.log('could not send stamp, trying later again');
                return console.log(err);
            },
            success: function (ret) {
                return success(ret[form].id);
            },
        });
    },
    send: function (form, data, item, key, update) {
        var json_data, realdata, self, type, url;
        self = this;
        //console.log "sending", data
        if (update && item.backend) {
            url = collector.api_url() + '/' + form + 's/' + item.backend + '?validation=off';
            type = 'PUT';
        } else {
            url = collector.api_url() + '/' + form + 's?validation=off';
            type = 'POST';
        }
        realdata = {};
        realdata[form] = data;
        json_data = JSON.stringify(realdata);
        return $.ajax(url, {
            contentType: 'application/json',
            data: json_data,
            type: type,
            error: function (err) {
                return self.set('syncing', false);
            },
            success: function (ret) {
                //console.log 'stamp sent succesfully, setting stamp id', ret
                item.backend = ret[form].id;
                item.sync = 'ok';
                return self.update(key, item);
            },
        });
    },
});

export default localStorageWrapper;
