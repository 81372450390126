/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/tt3tott4/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerDetailsController;

import Ember from 'ember';

AdmintoolCustomerDetailsController = Ember.Controller.extend({
    admintool: Ember.inject.service(),
    tt3service: Ember.computed('model.forms', function () {
        return this.get('model.forms.tt3flag');
    }),
    products: Ember.computed.alias('model.tt3tott4.added_products'),
    keys: Ember.computed('model.tt3tott4.keys', function () {
        return this.get('model.tt3tott4.keys').join('\n');
    }),
    actions: {
        tt3tott4: function () {
            var promise, w;
            w = confirm(
                'Are you sure!?\n\nUse this action only if you are 100% sure what this button does. This action is irreversible and prohibits TT3 usage completely',
            );
            console.log('w', w);
            if (!w) {
                return;
            }
            promise = this.get('admintool').ajax({
                url: 'tt3tott4',
                method: 'get',
                data: {
                    partnerid: this.get('model.partnerid'),
                    execute: true,
                },
            });
            return promise.then((back) => {
                alert(
                    'Concrats!\n\nService is now pure TT4.\nReport saved to ' +
                        back.report_saved_to,
                );
                return this.send('refreshModel');
            });
        },
    },
});

export default AdmintoolCustomerDetailsController;
