/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/salarytypes/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller, get, set;

import Ember from 'ember';

({ get: get, set: set } = Ember);

import { computed } from '@ember/object';

Controller = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    servicehandler: Ember.inject.service(),
    tools: Ember.inject.service(),
    settings: Em.inject.controller(),
    notifications: Ember.inject.service(),
    preventUsage: computed('model.salarytypes.length', function () {
        return this.get('model.salarytypes').filterBy('isNew', false).length === 0;
    }),
    rowActions: [
        {
            action: 'editRow',
            icon: 'move move-Pencil',
        },
        {
            action: 'rowHistory',
            icon: 'move move-Search',
        },
        {
            action: 'removeRow',
            icon: 'move move-Delete',
        },
    ],
    devText:
        'If you want to start using new salarytypes v2, go to salarytype list and remove all old salarytypes ',
    salarytypesText: 'salarytypes',
    absenceSettings: Ember.Object.create({
        form: 'abcensetype',
    }),
    sortedModel: Ember.computed.sort('model.salarytypes', function (a, b) {
        var id_a, id_b, index_a, index_b, sortOrder;
        sortOrder = [
            '_basichours',
            '_evening',
            'hours_evening',
            '_night',
            '_extrahours',
            '_overtimehours',
            '_50',
            'hours_50',
            '_100',
            'hours_100',
            '_week50',
            '_week100',
            'hours_150',
            'hours_200',
            'hours_hourly',
            'hours_piece',
            'hours_extra',
        ];
        id_a = a.get('shortcut');
        id_b = b.get('shortcut');
        if (!id_a) {
            id_a = a.get('control');
        }
        if (!id_b) {
            id_b = b.get('control');
        }
        if (!id_a) {
            id_a = '';
        }
        if (!id_b) {
            id_b = '';
        }
        id_a = id_a.split(';')[0];
        id_b = id_b.split(';')[0];
        index_a = sortOrder.indexOf(id_a);
        index_b = sortOrder.indexOf(id_b);
        if (index_a === -1) {
            index_a = sortOrder.length + 1;
        }
        if (index_b === -1) {
            index_b = sortOrder.length + 1;
        }
        if (index_a > index_b) {
            return 1;
        } else {
            return -1;
        }
    }),
    dropdowns: Ember.computed.alias('model.dropdowns'),
    shortcuts: Ember.computed.alias('model.shortcuts'),
    manipulateService: function () {
        var fields, selectable_count;
        fields = {};
        selectable_count = 0;
        this.get('model.salarytypes').forEach((row) => {
            var field, negation, pieces_or_hours, ref, type;
            negation = get(row, 'inuse') ? '' : '!';
            if ((ref = get(row, 'type')) === 'field' || ref === 'amount' || ref === 'check') {
                if (!get(row, 'option')) {
                    field = this.getFieldName(row);
                    set(row, 'option', field);
                    set(row, 'control', '$option');
                }
                type = {
                    field: 'hours',
                    amount: 'number',
                    check: 'checkbox',
                }[get(row, 'type')];
                fields[negation + 'worktime.' + get(row, 'option')] = {
                    type: type,
                    name: row.get('name'),
                };
            } else if (
                Ember.typeOf(row.get('type')) === 'string' &&
                row.get('type').substring(0, 7) === 'custom_'
            ) {
                pieces_or_hours =
                    row.get('type').substring(7, 17) === 'typeofwork' ? 'hours' : 'pieces';
                field = pieces_or_hours === 'hours' ? 'work_hours' : '1';
                row.set('control', field + ';' + row.get('type').substring(7));
            } else if (this.get('Collector').fieldExists('worktime', row.get('type'))) {
                row.set('control', row.get('type'));
                row.set('option', row.get('type'));
            } else if (!row.get('control')) {
                row.set('control', 'work_hours;salarytype=$this');
            }
            if (!row.get('type') && row.get('inuse')) {
                return selectable_count++;
            }
        });
        // if there is more than 1 selectable salarytype and selection to worktime field
        if (
            selectable_count > 1 &&
            !this.get('Collector').testNeeds(['products.selectablesalarytype'])
        ) {
            this.get('Collector').addProduct('selectablesalarytype');
        } else if (
            selectable_count <= 1 &&
            this.get('Collector').testNeeds(['products.selectablesalarytype'])
        ) {
            this.get('Collector').removeProduct('selectablesalarytype');
        }
        return this.get('servicehandler').manipulateService(this.removeDuplicateFields(fields));
    },
    getFieldName: function (row) {
        var name, option;
        name = row.get('name');
        option = null;
        this.get('model.salarytypes').forEach((salarytype) => {
            if (salarytype.get('name') === name && salarytype.get('option')) {
                return (option = salarytype.get('option'));
            }
        });
        if (option) {
            return option;
        } else {
            return 'addon_' + this.get('tools').computerizeHumanInput(name);
        }
    },
    removeDuplicateFields: function (fields) {
        var keysByName, ret;
        keysByName = {};
        ret = {};
        Object.keys(fields).forEach((fieldName) => {
            var field;
            field = fields[fieldName];
            if (keysByName[field.name] == null) {
                keysByName[field.name] = [];
            }
            return keysByName[field.name].push({
                name: fieldName,
                field: field,
            });
        });
        Object.keys(keysByName).forEach((name) => {
            var useField, useName;
            fields = keysByName[name];
            useField = null;
            useName = null;
            fields.forEach((field) => {
                if (!useField) {
                    useField = field;
                }
                if (useField.name.startsWith('!') && !field.name.startsWith('!')) {
                    useField.name = useField.name.substring(1);
                }
                if (!this.plainFieldName(field.name).indexOf('addon') === -1) {
                    return (useName = this.plainFieldName(field.name));
                }
            });
            if (useName) {
                useField.name = (useField.name.startsWith('!') ? '!' : '') + useName;
            }
            return (ret[useField.name] = useField.field);
        });
        return ret;
    },
    plainFieldName: function (name) {
        if (name.startsWith('!')) {
            return name.substring(1);
        } else {
            return name;
        }
    },
    showSave: Ember.computed(
        'model.salarytypes.@each.changedWorktimegroup',
        'model.salarytypes.@each.hasDirtyAttributes',
        'model.salarytypes.@each.inuse',
        function () {
            var show;
            show = false;
            this.get('model.salarytypes').forEach((row) => {
                if (!row.get('inuse') && row.get('isNew')) {
                    return;
                }
                if (row.get('hasDirtyAttributes') || row.get('changedWorktimegroup')) {
                    return (show = true);
                }
            });
            return show;
        },
    ),
    actions: {
        changedWorktimegroup: function (row, record, fieldname) {
            if (!fieldname) {
                return;
            }
            row.set('changedWorktimegroup', true);
            return row.set(fieldname, record);
        },
        save: function () {
            this.get('notifications').success(this.get('intl').t('general.saved'), {
                autoClear: true,
            });
            this.set('_refreshNeeded', true);
            this.manipulateService();
            this.set('changedWorktimegroup', false);
            return this.get('model.salarytypes').forEach((row) => {
                if (row.get('hasDirtyAttributes') || row.get('changedWorktimegroup')) {
                    return row.save();
                }
            });
        },
        remove: function (row) {
            return row.destroyRecord();
        },
        addRow: function () {
            return this.store.createRecord('salarytype', {
                inuse: true,
            });
        },
        editRow: function (row) {
            return this.store.findRecord('abcensetype', row.id).then((record) => {
                record.on('didUpdate', () => {
                    return this.get('absenceSettings').toggleProperty('refreshRows');
                });
                return this.transitionToRoute({
                    queryParams: {
                        modal: ['abcensetype', row.id],
                    },
                });
            });
        },
        removeRow: function (row, report) {
            Em.assert('removeRow action needs row id', row.id);
            return this.store.findRecord(report.get('form'), row.id).then(function (record) {
                return record.destroyRecord();
            });
        },
    },
});

export default Controller;
