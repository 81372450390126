/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalRoute;

import Ember from 'ember';

import collector from '../../../classes/collector';

TimetrackerApprovalRoute = Ember.Route.extend({
    queryParams: {
        date: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params.status = 'open';
        params.sideload = true;
        params.limit = 'all';
        //        params.date = moment().startOf('week').format('YYYY-MM-DD')+'_'+moment().endOf('week').format('YYYY-MM-DD')
        params.employer = this.controllerFor('timetracker.approval').get('employer');
        if (!this.get('Collector').testNeedsOne('worktime.employer')) {
            delete params.employer;
        }
        return this.store.query('worktime', params);
    },
    beforeModel: function () {
        if ('readonly' in collector.forms.worktime.objects.status.features) {
            return this.transitionTo('timetracker.timecard');
        }
    },
    setupController: function (controller, model) {
        return this._super(controller, model);
    },
});

export default TimetrackerApprovalRoute;
