/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddRoute;

import Ember from 'ember';

WorksitediaryReportAddRoute = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function (params) {
        var date;
        date = moment().format('YYYY-MM-DD');
        if (params.date && moment(params.date).isValid()) {
            date = params.date;
        }
        if (params.id === 'add') {
            return this.get('store').createRecord('diaryreport', {
                diary_date: date,
            });
        } else {
            return this.get('store').findRecord('diaryreport', params.id, {
                include: true,
            });
        }
    },
    actions: {
        willTransition: function (transition) {
            if (transition.targetName.substring(0, 25) === 'worksitediary.report.add.') {
                return this.get('controller.model').save();
            }
        },
    },
});

export default WorksitediaryReportAddRoute;
