import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { later } from '@ember/runloop';
import { typeOf } from '@ember/utils';

export default Component.extend({
    notifications: service(),
    store: service(),
    intl: service(),
    gui: service(),
    error: service(),

    didInsertElement() {
        if (!this.users) set(this, 'users', A([]));
        else this.updateUsers(this.users);
    },

    actions: {
        async addUser(user) {
            if (this.users.includes(user))
                return this.notifications.warning(this.intl.t('orientations.user_already_added'), {
                    autoClear: true,
                });

            set(this, 'loading', true);
            set(this, 'errorMessage', false);
            // check if user is already orientated for this project
            if (await this.alreadyOrientated(user, this.project)) {
                set(this, 'loading', false);
                set(this, 'userSelector', null);
                return;
            }

            try {
                await this.store.findRecord('user', get(user, 'id'), { reload: true });
                set(this, 'selected', user);
                user.validate();
            } catch (err) {
                const readableError = this.error.toError(err);
                if (readableError.error == 'not_found') {
                    set(this, 'errorMessage', get(this, 'intl').t('validation.not_found.user'));
                } else {
                    set(this, 'errorMessage', this.error.toString(err));
                }
                later(this, () => set(this, 'errorMessage', false), 10000);
                set(this, 'selected', null);
            }
            this.users.pushObject(user);
            if (this.usersChanged) this.usersChanged(this.users);
            // clear user finder dropdown
            set(this, 'userSelector', null);
            set(this, 'loading', false);
            set(this, 'userModel', null);
        },

        setupUserModel() {
            set(this, 'selected', this.store.createRecord('user', {}));
        },

        async createUser() {
            if (!this.selected.isNew) return this.send('addUser', this.selected);
            set(this, 'loading', true);
            this.users.pushObject(this.selected);
            if (this.usersChanged) this.usersChanged(this.users);
            set(this, 'loading', false);
        },

        async changeUser(user) {
            set(this, 'loading', true);
            set(this, 'selected', await user);
            set(this, 'loading', false);
        },

        userEdited() {
            if (this.usersChanged) this.usersChanged(this.users);
            this.notifications.success('Tiedot tallennettu', { autoClear: true });
        },

        async userAdded(user) {
            set(this, 'loading', true);
            const newUser = await this.store.findRecord('user', user.id, { reload: true });
            set(this, 'selected', newUser);
            if (this.usersChanged) this.usersChanged(this.users);
            this.notifications.success('Tiedot tallennettu', { autoClear: true });
            set(this, 'loading', false);
        },

        removeUserFromList(user) {
            if (this.selected && this.selected.id == user.id) {
                set(this, 'selected', false);
            }
            this.users.removeObject(user);
            this.usersChanged(this.users);
        },
        changeProject(project) {
            if (typeOf(project) == 'object') return;
            set(this, 'project', project);
            this.projectChanged(project);
            if (this.users) this.updateUserList(project);
            if (!project) {
                set(this, 'users', A());
            }
        },

        activateCardregister() {
            this.activateCardRegister();
        },
    },

    async alreadyOrientated(user, project) {
        const orientations = await this.store.query('orientation', {
            ori_site: get(project, 'id'),
            ori_user: get(user, 'id'),
        });
        if (orientations.length > 0) {
            this.notifications.warning(this.intl.t('orientations.already_orientated'), {
                autoClear: true,
            });
            return true;
        }
        return false;
    },

    updateUserList(project) {
        this.users.forEach(async (user) => {
            if (await this.alreadyOrientated(user, project)) {
                this.users.removeObject(user);
            }
        });
    },
});
