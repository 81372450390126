/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/fileupload-preview.coffee.old
// Generated by CoffeeScript 2.6.1
var FileuploadPreviewComponent;

import Ember from 'ember';

FileuploadPreviewComponent = Ember.Component.extend({
    tagName: 'canvas',
    attributeBindings: ['width', 'height'],
    didInsertElement: function () {
        var canvas;
        canvas = this.get('element').getContext('2d');
        this.set('canvas', canvas);
        this._empty(canvas);
        return this.draw();
    },
    draw: function () {
        var canvas, image, self;
        self = this;
        canvas = this.get('canvas');
        this._empty(canvas);
        image = new Image();
        image.onload = function () {
            canvas = self.get('canvas');
            return canvas.drawImage(image, 0, 0, image.width, image.height);
        };
        return (image.src = this.get('data'));
    }.observes('data'),
    _empty: function (canvas) {
        if (canvas) {
            canvas.fillStyle = '#fff';
            return canvas.fillRect(0, 0, this.get('width'), this.get('height'));
        }
    },
});

export default FileuploadPreviewComponent;
