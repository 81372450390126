import Service from '@ember/service';
import Evented from '@ember/object/evented';
import { inject as service } from '@ember/service';

export default Service.extend(Evented, {
    lastTimeResumed: null,
    started: null,
    sessionhelper: service(),

    init() {
        this._super();
        this.lastTimeResumed = new Date();
        this.started = new Date();
    },

    storeEvent(action, type, record) {
        this.trigger('storeEvent', { action: action, type: type, record: record });
    },

    resumed(params = {}) {
        // if token will expire in 5 minutes, do not trigger onResumed
        // application-controller should handle token-refresh if token is about to expire
        // also if app is in offline-mode do not trigger onResumed
        if (this.sessionhelper.tokenWillExpireIn(5) || this.sessionhelper.isOffline()) {
            // eslint-disable-next-line no-console
            console.log('evented.resumed.tokenHasExpired'); // debugging why users are being logged off from dashboard, we should see this debugging-line in sentry if dashboard doesn't trigger onResumed when token was expired
            return;
        } else {
            // eslint-disable-next-line no-console
            console.log('evented.resumed triggering onResumed'); // if token wasn't expired, we should see this debugging-line in sentry
        }

        // duration is time from last event in seconds .. first resume is calculated from initilization
        params.duration = (new Date() - this.lastTimeResumed) / 1000;
        this.lastTimeResumed = new Date();
        params.ontime = (new Date() - this.started) / 1000;
        this.trigger('onResumed', params);
    },

    getOntime() {
        return (new Date() - this.started) / 1000;
    },

    dashboardProjectChanged(project) {
        this.trigger('dashboardProjectChanged', { project });
    },

    // this is triggered in components/collector/form-add/component.js
    // when onChange action is called in form-add
    // why? because we need to know when user has changed fields in form-add
    // ie. when user has changed project, we need to know that so we can update distance to project
    formAddFieldChanged(fieldName, value) {
        this.trigger('formAddFieldChanged', { fieldName, value });
    },

    // this is triggered in services/gps2.js
    // when gps position is changed or reset
    // why? because we need to know when users gps position has changed
    gpsLockedPositionChanged(lockedPosition) {
        this.trigger('gpsLockedPositionChanged', { lockedPosition });
    },
});
