/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/billing-info.coffee.old
// Generated by CoffeeScript 2.6.1
var BillingInfoComponent;

import Ember from 'ember';

import config from '../config/environment';

import $ from 'jquery';

BillingInfoComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    admintool: Ember.inject.service(),
    countryLists: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    didInsertElement: function () {
        return $('#' + this.get('billingtype')).addClass('in');
    },
    /*
  billingTypes: Em.A [
      Em.Object.create({name:'email',show:true})
      Em.Object.create({name:'e_invoice',show:false})
      Em.Object.create({name:'paper',show:false})
  ]
  */
    errors: Ember.Object.create({
        street: false,
        zip: false,
        town: false,
        country: false,
    }),
    showEinvoiceOption: Em.computed(function () {
        //console.log @get('session.currentUser.company_info.country')
        if (this.get('session.currentUser.company_info.country') === 'fi') {
            return true;
        }
        return false;
    }),
    getEinvoicingOperators: Em.on('init', function () {
        /*
    promise = new Ember.RSVP.Promise (resolve, reject) =>
        promise = @get('admintool').ajax
            url: "operators"
            method: "get"

        promise.then (data) ->
            resolve data
    promise.then (data) =>
    */
        var data;
        data = [
            {
                id: 'apix',
                name: 'Apix Messaging Oy (003723327487)',
            },
            {
                id: 'basware',
                name: 'BasWare Einvoices Oy (BAWCFI22)',
            },
            {
                id: 'basware_oyj',
                name: 'BasWare Oyj (003705925424)',
            },
            {
                id: 'cgi',
                name: 'CGI - Logica (003703575029)',
            },
            {
                id: 'danske_bank',
                name: 'Danske Bank Oyj (DABAFIHH)',
            },
            {
                id: 'enfo',
                name: 'Enfo Oy (003718062728)',
            },
            {
                id: 'enfo_oyj',
                name: 'Enfo Oyj (003714377140)',
            },
            {
                id: 'inex',
                name: 'InExchange Factorum AB (INEXCHANGE)',
            },
            {
                id: 'liaison',
                name: 'LIAISON Technologies Oy (003708599126)',
            },
            {
                id: 'maventa',
                name: 'Maventa Services (003721291126)',
            },
            {
                id: 'nordea',
                name: 'Nordea (NDEAFIHH)',
            },
            {
                id: 'notebeat',
                name: 'Notebeat Oy (003717203971)',
            },
            {
                id: 'op',
                name: 'OP-Pohjola-ryhmä (OKOYFIHH)',
            },
            {
                id: 'opus',
                name: 'OpusCapita Group Oy - Itella (003710948874)',
            },
            {
                id: 'pagero',
                name: 'Pagero (003723609900)',
            },
            {
                id: 'pop',
                name: 'POP Pankit (POPFFI22)',
            },
            {
                id: 's_pankki',
                name: 'S-Pankki Oy (SBANFIHH)',
            },
            {
                id: 'sampo',
                name: 'Sampo Pankki Oyj (PSPBFIHH)',
            },
            {
                id: 'handelsbank',
                name: 'Svenska HAndelsbanken AB (HANDFIHH)',
            },
            {
                id: 'saasto',
                name: 'Säästöpankit (ITELFIHH)',
            },
            {
                id: 'tapiola',
                name: 'Tapiola Pankki (TAPIFI22)',
            },
            {
                id: 'teliasonera',
                name: 'TeliaSonera Finland Oyj (003714756079)',
            },
            {
                id: 'tieto',
                name: 'Tieto Oyj (003701011385)',
            },
            {
                id: 'aland',
                name: 'Ålandsbanken Abp (AABAFI22)',
            },
            {
                id: 'opus_solutions',
                name: 'OpusCapita Solutions Oy (E204503)',
            },
        ];
        return this.set('eInvoicingOperators', data);
    }),
    billingtype: 'email',
    invoicingAddress: Em.computed(function () {
        return {
            email: this.get('session.currentUser.email'),
        };
    }),
    save_company_info: function () {
        var promise;
        promise = this.get('admintool').ajax({
            url: 'customer_info',
            method: 'PUT',
            data: {
                street: this.get('customerData.street'),
                zip: this.get('customerData.zip'),
                town: this.get('customerData.town'),
                country: this.get('customerData.country'),
                specifier: this.get('customerData.specifier'),
            },
        });
        return promise.then(() => {
            // close only if shown in modal
            if (this.get('close')) {
                $('.modal').modal('hide');
                return this.close();
            } else {
                return this.get('notifications').success(this.get('intl').t('general.saved'), {
                    autoClear: true,
                });
            }
        });
    },
    actions: {
        copy_fields: function (checked) {
            if (checked) {
                this.set('invoicingAddress.zip', this.get('customerData.zip'));
                this.set('invoicingAddress.town', this.get('customerData.town'));
                this.set('invoicingAddress.country', this.get('customerData.country'));
                return this.set('invoicingAddress.street', this.get('customerData.street'));
            }
        },
        setBillingType: function (btype) {
            return this.set('billingtype', btype);
        },
        edit: function () {
            this.set('editmode', true);
            return this.send('save');
        },
        close: function () {
            $('.modal').modal('hide');
            if (this.get('close')) {
                return this.close();
            }
        },
        save: function () {
            var data_arr, field, hasError, i, len, method, promise, ref;
            data_arr = {};
            if (this.get('editmode')) {
                method = 'PUT';
            } else {
                method = 'POST';
            }
            if (this.get('billingtype') === 'eInvoice') {
                this.set('invoicingAddress.email', null);
                this.set('invoicingAddress.zip', null);
                this.set('invoicingAddress.town', null);
                this.set('invoicingAddress.country', null);
                this.set('invoicingAddress.street', null);
            } else if (this.get('billingtype') === 'email') {
                this.set('invoicingAddress.zip', null);
                this.set('invoicingAddress.town', null);
                this.set('invoicingAddress.country', null);
                this.set('invoicingAddress.e_invoice_operator', null);
                this.set('invoicingAddress.e_invoice_address', null);
            } else if (this.get('billingtype') === 'paper') {
                this.set('invoicingAddress.e_invoice_operator', null);
                this.set('invoicingAddress.e_invoice_address', null);
                this.set('invoicingAddress.email', null);
            }
            if (
                this.get('invoicingAddress.use_company_address') &&
                this.get('billingtype') === 'paper'
            ) {
                data_arr['street'] = this.get('customerData.street');
                data_arr['zip'] = this.get('customerData.zip');
                data_arr['town'] = this.get('customerData.town');
                data_arr['country'] = this.get('customerData.country');
            } else {
                data_arr['street'] = this.get('invoicingAddress.street');
                data_arr['zip'] = this.get('invoicingAddress.zip');
                data_arr['town'] = this.get('invoicingAddress.town');
                data_arr['country'] = this.get('invoicingAddress.country');
            }
            data_arr['billingtype'] = this.get('billingtype');
            data_arr['email'] = this.get('invoicingAddress.email');
            data_arr['e_invoice_operator'] = this.get('invoicingAddress.e_invoice_operator');
            data_arr['e_invoice_address'] = this.get('invoicingAddress.e_invoice_address');
            data_arr['invoice_ref'] = this.get('invoicingAddress.invoice_ref');
            data_arr['use_company_address'] = this.get('invoicingAddress.use_company_address');
            hasError = false;
            if (this.get('billingtype') === 'eInvoice') {
                ref = ['street', 'zip', 'town', 'country'];
                for (i = 0, len = ref.length; i < len; i++) {
                    field = ref[i];
                    if (
                        !this.get(
                            'customerData.' + field || this.get('customerData.' + field === ''),
                        )
                    ) {
                        this.set('errors.' + field, true);
                        hasError = true;
                    } else {
                        this.set('errors.' + field, false);
                    }
                }
            }
            if (!hasError) {
                promise = this.get('admintool').ajax({
                    url: 'billinginfo',
                    method: method,
                    data: data_arr,
                });
                return promise.then(() => {
                    // save also address-info if "use company address" is checked
                    if (this.get('popup')) {
                        return this.save_company_info();
                    } else {
                        if (this.get('close')) {
                            $('.modal').modal('hide');
                            return this.close();
                        } else {
                            return this.get('notifications').success(
                                this.get('intl').t('general.saved'),
                                {
                                    autoClear: true,
                                },
                            );
                        }
                    }
                });
            }
        },
    },
});

export default BillingInfoComponent;
