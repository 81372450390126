/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var savedController;

import Ember from 'ember';

import collector from '../../../classes/collector';

savedController = Ember.Controller.extend({
    //    needs: ['timetracker/timecard']
    timecardController: Ember.inject.controller('timetracker/timecard'),
    commentSaved: false,
    actions: {
        saveComment: function () {
            var self, worktime;
            self = this;
            worktime = this.get('model');
            return worktime.save().then(function () {
                return self.set('commentSaved', true);
            });
        },
    },
});

export default savedController;
