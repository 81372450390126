import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import config from '../../../config/environment';
import menu from '../../../mixins/menu';

export default Component.extend(menu, {
    ajax: service(),
    ttapi: service(),
    router: service(),
    tagName: 'li',
    classNameBindings: ['isDisabled:disabled:'],

    isDisabled: computed('disabledClass', function () {
        return this.disabledClass === 'disabled';
    }),

    actions: {
        generate() {
            let urls = [];
            let own_url = '';
            let editurls = [];
            var fullUrl = '';
            var urlParts = '';

            // lets just get edit-urls from dom-elements, easiest this way
            let domrows = document.querySelectorAll('.general-report span.move-CheckedCheckbox');
            for (var domrow of domrows) {
                if (
                    domrow.closest('thead') === null &&
                    domrow.closest('tr').querySelector('span.move-Pencil').closest('a') !== null
                ) {
                    fullUrl = domrow
                        .closest('tr')
                        .querySelector('span.move-Pencil')
                        .closest('a').href;
                    urlParts = fullUrl.split('#');
                    urls.push(urlParts[1]);
                }
            }
            // if report doesnt have links, try to use urlFor for generating edit-urls
            if (urls.length === 0) {
                for (var row of get(this, 'rows')) {
                    if (get(this, 'editRoute'))
                        urls.push(this.router.urlFor(get(this, 'editRoute'), get(row, 'id')));
                    else urls.push('/forms/' + get(this, 'form') + '/edit/' + get(row, 'id'));
                }
            }

            // use app.movenium url always if not development
            if (config.environment !== 'development') {
                own_url = 'https://app.movenium.com/';
            } else {
                if (config.APP.OWN_URL.slice(-1) == '/') {
                    own_url = config.APP.OWN_URL;
                } else {
                    own_url = config.APP.OWN_URL + '/';
                }
            }

            let editUrl = '';

            for (var url of urls) {
                editUrl = own_url + '#' + url;
                let qrUrl =
                    'https://chart.googleapis.com/chart?cht=qr&chl=' +
                    encodeURIComponent(editUrl) +
                    '&choe=UTF-8&chs=250x250';
                editurls.pushObject({ url: qrUrl });
            }
            let return_url_promise = get(this, 'ttapi').request('qr_codes', {
                method: 'POST',
                dataType: 'text',
                data: {
                    urls: editurls,
                },
            });
            return_url_promise.then(function (return_url) {
                var w = window.open('about:blank');
                w.document.open();
                w.document.write(return_url);
                w.document.close();
            });
        },
    },
});
