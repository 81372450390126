import { set } from '@ember/object';
import mapper from '../field-mapper/component';

const ImportValueMapperComponent = mapper.extend({
    actions: {
        valueChangedTo(field) {
            const old = this.to;
            if (this.valueChanged) {
                this.valueChanged(field, old, 'to', this.maps);
            }
            return set(this, 'to', field);
        },
        valueChangedFrom(field) {
            const old = this.from;
            if (this.valueChanged) {
                this.valueChanged(field, old, 'from', this.maps);
            }
            return set(this, 'to', field);
        },
    },
});

export default ImportValueMapperComponent;
