/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/filters-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FormFiltersComponent;

import Ember from 'ember';

FormFiltersComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionGlobals: Em.inject.service(),
    // Name of the form to be used to get different filter options
    form: null,
    // all the fields wanted to be shown. format string ie. "date,user"
    filters: null,
    // parameter for values to be used outside of the component
    // all values of the filter will be in ember object ie. {date: date, user: user}
    values: null,
    refreshValues: Ember.observer('_filters.@each.value', function () {
        var filters, sessionFilters, values;
        filters = this.get('_filters');
        values = [];
        sessionFilters = {};
        filters.forEach((item) => {
            // support for filters from different forms
            if (item.get('form') && this.get('form') !== item.get('form')) {
                values.push({
                    name: item.get('form') + '.' + item.get('name'),
                    value: item.get('value'),
                });
                return (sessionFilters[item.get('name')] = item.get('value'));
            } else {
                values.push({
                    name: item.get('name'),
                    value: item.get('value'),
                });
                return (sessionFilters[item.get('name')] = item.get('value'));
            }
        });
        if (this.get('form')) {
            this.get('sessionGlobals').set('filters_' + this.get('form'), sessionFilters);
        }
        // always save also as global filters so can be used on own reports
        this.get('sessionGlobals').set('filters', sessionFilters);
        return this.set('values', Em.A(values));
    }),
    _filters: Ember.computed('filters', function () {
        var filters, types;
        types = this.get('filters').split(',');
        filters = Em.A([]);
        $.each(types, (key, item) => {
            var filter_field, itemArr;
            if (item.indexOf('.') > -1) {
                itemArr = item.split('.');
                filter_field = this.getFilterObject(itemArr[1], itemArr[0]);
            } else {
                filter_field = this.getFilterObject(item);
            }
            if (filter_field) {
                return filters.pushObject(filter_field);
            }
        });
        return filters;
    }),
    getFilterObject: function (item, form = null) {
        var c, defaultFilters, field, fieldType, intl, object, rangesPromise, store, translatedName;
        form = form || this.get('form');
        c = this.get('Collector');
        store = this.get('store');
        intl = this.get('intl');
        field = c.fields(form)[item];
        if (item === 'q') {
            field = {
                ext: 'q',
                type: 'search',
            };
        }
        if (item === 'groupBy') {
            field = {
                ext: 'groupBy',
                type: 'groupByFilter',
            };
        }
        if (field == null) {
            return;
        }
        //skip filter if hide_filter feature found
        if (field.features && field.features.hide_filter) {
            return;
        }
        fieldType = field.type;
        object = Ember.Object.create({
            type: fieldType,
            name: item,
            value: null,
        });
        if (field.options) {
            object.set('options', field.options);
        }
        switch (fieldType) {
            case 'date':
                object.title = field.name;
                if (field.features.use_index) {
                    object.start = moment().startOf('week').format('YYYY-MM-DD');
                    object.end = moment().endOf('week').format('YYYY-MM-DD');
                    object.value = object.start + '_' + object.end;
                }
                if (this.get('form') === 'worktime') {
                    rangesPromise = $.Deferred();
                    if (c.formExists('wage_period')) {
                        this.get('store')
                            .query('wage-period', {
                                limit: 20,
                                order: 'period_end_date-,period_start_date-',
                            })
                            .then(function (periods) {
                                var ranges;
                                ranges = {};
                                periods.forEach(function (period) {
                                    var name;
                                    if (period.get('period_name')) {
                                        name = period.get('period_name');
                                    } else {
                                        name =
                                            moment(period.get('period_start_date')).format('L') +
                                            ' - ' +
                                            moment(period.get('period_end_date')).format('L');
                                    }
                                    return (ranges[name] = [
                                        moment(period.get('period_start_date')),
                                        moment(period.get('period_end_date')),
                                    ]);
                                });
                                return rangesPromise.resolve(ranges);
                            });
                    } else {
                        rangesPromise.resolve({});
                    }
                    object.ranges = rangesPromise;
                }
                translatedName = c.getTranslationForColumn(form, item);
                object.prompt = intl.t('filter.select_date', {
                    field:
                        translatedName.string != null
                            ? translatedName.string.toLowerCase()
                            : translatedName.toLowerCase(),
                });
                break;
            case 'database':
            case 'multiselect':
                object.name = item;
                object.form = form;
                break;
            //                object.prompt = 'workday.select_' + item
            case 'status':
                object.field = item;
                break;
            case 'dropdown_values':
                object.name = item;
                object.form = form;
        }
        //                object.prompt = @get('intl').t('workday.select_' + item)+"..."
        object.features = field.features;
        if (this.get('form')) {
            defaultFilters = this.get('sessionGlobals').get('filters_' + this.get('form'));
        } else {
            defaultFilters = this.get('sessionGlobals').get('filters');
        }
        if (defaultFilters && defaultFilters[item] != null) {
            object.value = defaultFilters[item];
        }
        if (this.get('prompt')) {
            object.prompt = this.get('prompt');
        }
        return object;
    },
    setDropdownFilterings: function (changeInField, value) {
        var filtering, i, len, options, ref, results, setFilter;
        ref = this.get('Collector').getDropdownDepency(changeInField);
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
            filtering = ref[i];
            setFilter = this.get('_filters').findBy('name', filtering.field);
            if (!setFilter) {
                continue;
            }
            options = {};
            options[filtering.filter] = value ? value.get('id') : null;
            results.push(setFilter.set('filterOptions', options));
        }
        return results;
    },
    actions: {
        clearFilters: function () {
            var filters, i, item, len, results;
            filters = this.get('_filters');
            results = [];
            for (i = 0, len = filters.length; i < len; i++) {
                item = filters[i];
                if (item.type !== 'date') {
                    results.push(Ember.set(item, 'value', ''));
                } else {
                    results.push(void 0);
                }
            }
            return results;
        },
        valueChanged: function (value, fieldName) {
            var filters, id;
            this.setDropdownFilterings(fieldName, value);
            filters = this.get('_filters');
            if (value) {
                if (typeof value.get === 'function') {
                    if (Array.isArray(value)) {
                        id = value.join();
                    } else {
                        id = value.get('id');
                    }
                } else {
                    id = value;
                }
            } else {
                id = null;
            }
            filters.findBy('name', fieldName).set('value', id);
            return this.sendAction('change', value, fieldName);
        },
    },
});

//@set 'filter.value "change",a,b
export default FormFiltersComponent;
