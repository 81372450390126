import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import AjaxService from 'ember-ajax/services/ajax';
import config from 'tt4/config/environment';

// const {inject: {service}, get, Object, ObjectProxy, PromiseProxyMixin, isPresent, A} = Ember;

export default AjaxService.extend({
    host: config.APP.TTAPI2,

    session: service(),
    headers: computed('session.session.authenticated.access_token', {
        get() {
            let headers = {};
            let token = get(this, 'session.session.authenticated.access_token');
            headers['Authorization'] = 'Bearer ' + token;
            return headers;
        },
    }),

    _get(route, params) {
        return this._request('get', route, params);
    },

    _post(route, params) {
        return this._request('post', route, params);
    },

    _delete(route, params) {
        return this._request('delete', route, params);
    },

    _request(method, route, params = {}) {
        const promiseObject = ObjectProxy.extend(PromiseProxyMixin);

        return promiseObject.create({
            promise: this.request(route, { method: method, data: params }).then((data) =>
                this._returnData(data),
            ),
        });
    },

    _returnData(data) {
        return data;
    },
});
