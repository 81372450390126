/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/calendar/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

WorksitediaryIndexRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    queryParams: {
        project: {
            refreshModel: true,
        },
        diary_date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params.sideload = ['user.image', 'user.firstname', 'user.lastname', 'project.name'];
        return this.store.query('diaryreport', params);
    },
});

export default WorksitediaryIndexRoute;
