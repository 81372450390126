/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/changepassword.coffee.old
// Generated by CoffeeScript 2.6.1
var ChangepasswordController;

import Ember from 'ember';

ChangepasswordController = Ember.Controller.extend({
    session: Ember.inject.service(),
    flags: Ember.inject.service(),
    securedPassword: Em.computed('session.currentUser', function () {
        if (this.get('session.currentUser.password') === 'secured') {
            return true;
        }
        return false;
    }),
    emailErrors: [],
    phoneErrors: [],
    showEmail: Em.computed('user', function () {
        if (this.get('user') && this.get('user.email') === null) {
            return true;
        }
        return false;
    }),
    showPhone: Em.computed('user', function () {
        if (this.get('user') && this.get('user.phone') === null) {
            return true;
        }
        return false;
    }),
    showTitle: Em.computed('showPhone', 'showEmail', 'session.currentUser', function () {
        if (this.get('showPhone') || this.get('showEmail')) {
            return true;
        }
        return false;
    }),
    user: Em.computed('session.currentUser', function () {
        return this.store.peekRecord('user', this.get('session.currentUser.id'));
    }),
    showModal: true,
    hideCross: Em.computed('session.currentUser', function () {
        if (
            this.flags.test('old-password-change') &&
            this.get('session.currentUser.password') === 'secured'
        ) {
            return false;
        }
        return true;
    }),
    actions: {
        closeModal: function () {
            if (
                this.flags.test('old-password-change') &&
                this.get('session.currentUser.password') === 'secured'
            ) {
                history.back();
            }
        },
    },
});

export default ChangepasswordController;
