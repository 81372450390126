/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Promise as EmberPromise } from 'rsvp';
import OAuth2PasswordGrantAuthenticator from 'ember-simple-auth/authenticators/oauth2-password-grant';
import config from 'tt4/config/environment';
import { warn } from '@ember/debug';
import { later } from '@ember/runloop';
import { typeOf } from '@ember/utils';
import { get } from '@ember/object';

export default OAuth2PasswordGrantAuthenticator.extend({
    serverTokenEndpoint: config.APP.COLLECTOR_API_URL + '/login',
    clientId: config.clientId,
    makeRequest(url, data) {
        data.client_id = get(this, 'clientId');
        return this._super(url, data);
    },

    restore(data) {
        return new EmberPromise((resolve, reject) => {
            return this._super(data).then(resolve, (response) => {
                warn('Restore failed. Goto to /offline', { id: 'collector-auth-warn' });

                if (typeOf(response) === 'error') {
                    // couldn't get any response
                    data.offline = true;
                    this.trigger('sessionDataUpdated', data);

                    resolve(data);
                } else {
                    reject();
                    later(this, () => (location.href = config.rootURL), 1000);
                }
            });
        });
    },
});
