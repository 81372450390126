import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
const WorktasksViewRoute = Route.extend(AuthenticatedRouteMixin, {
    model(params) {
        if (params.id) {
            params.sideload = true;
            return this.store.findRecord('worktask', params.id, { include: true });
        } else {
            return this.transitionTo('worktask.list');
        }
    },
});

export default WorktasksViewRoute;
