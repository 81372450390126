/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/export-abcense/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerExportSalaryAddRoute;

import Ember from 'ember';

TimetrackerExportSalaryAddRoute = Ember.Route.extend({
    model: function () {
        return this.store.query('integration', {
            type: 'export_absence',
        });
    },
    actions: {
        fileCreated: function () {
            return this.transitionTo('salary.export-abcense');
        },
        cancel: function () {
            return this.transitionTo('salary.export-abcense');
        },
    },
});

export default TimetrackerExportSalaryAddRoute;
