/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/mass-edit.coffee.old
// Generated by CoffeeScript 2.6.1
var MassEditComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

MassEditComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    dialogs: Em.inject.service(),
    evented: Em.inject.service(),
    didReceiveAttrs: function () {
        var c, fields, i, item, len, results;
        this._super(...arguments);
        this.set('values', {});
        c = this.get('Collector');
        fields = c.fieldArray(this.get('form'));
        results = [];
        for (i = 0, len = fields.length; i < len; i++) {
            item = fields[i];
            Ember.set(item.features, 'selected', false);
            if (item.features.mandatory === true) {
                results.push(Ember.set(item.features, 'dontshowCheckbox', true));
            } else {
                results.push(void 0);
            }
        }
        return results;
    },
    executing: false,
    recalculations: Ember.computed(function () {
        var list;
        list = [];
        if (
            this.get('form') === 'worktime' &&
            this.get('Collector').testNeeds(['products.overtime'])
        ) {
            list.push({
                value: 'overtime',
                text: 'settings.timetracker.overtime',
            });
        }
        if (
            this.get('form') === 'worktime' &&
            this.get('Collector').testNeeds(['products.saldo'])
        ) {
            list.push({
                value: 'saldo',
                text: 'settings.timetracker.saldo',
            });
        }
        return list;
    }),
    clearValueArray: ['date', 'dropdown_userlevel'],
    // when cancel is clicked this is set and executing is halted
    die_signal: false,
    alldone: false,
    values: {},
    progress: Ember.computed('numericalProgress', function () {
        return this.get('numericalProgress') + ' %';
    }),
    progressStyle: Ember.computed('progress', function () {
        return Ember.String.htmlSafe('width: ' + this.get('numericalProgress') + '%');
    }),
    ids: Ember.computed('idsStr', function () {
        return this.get('idsStr').split(',');
    }),
    rowCount: Ember.computed(function () {
        var filtered_rows, ids, rows;
        ids = this.get('ids');
        rows = this.get('store').peekAll(this.get('form'));
        filtered_rows = rows.filter((row) => {
            if (ids.indexOf(row.get('id')) > -1 && row.get('row_info.status') !== 'locked') {
                return true;
            }
        });
        return 0 + filtered_rows.get('length');
    }),
    locked_rows: Em.computed(function () {
        var ids, locked_rows, rows;
        ids = this.get('ids');
        rows = this.get('store').peekAll(this.get('form'));
        locked_rows = rows.filter((row) => {
            if (ids.indexOf(row.get('id')) > -1 && row.get('row_info.status') === 'locked') {
                return true;
            }
        });
        return locked_rows.length;
    }),
    fields: Ember.computed('form', function () {
        var acceptedFieldNames, acceptedFields, c, fields, hideFields, intl, usableFields;
        c = this.get('Collector');
        intl = this.get('intl');
        acceptedFields = [
            'dropdown_values',
            'database',
            'date',
            'number',
            'dropdown_userlevel',
            'multiselect',
            'checkbox',
            'checkboxgroup',
            'dropdown_options',
            'hours',
            'decimal',
            'time_new',
            'price',
            'minutes',
            'datetime',
        ];
        acceptedFieldNames = ['export_salary', 'id06control'];
        hideFields = ['worktime.worktype'];
        fields = c.fieldArray(this.get('form'));
        usableFields = fields.filter((field) => {
            var ref, ref1, ref2;
            if (((ref = field.name), indexOf.call(acceptedFieldNames, ref) >= 0)) {
                return true;
            }
            if (field.features.disabled) {
                return false;
            }
            // hide field form massedit if it is hidden from normal adding
            if (field.features.hide && field.features.hide.condition !== '!$editing') {
                return false;
            }
            if (
                ((ref1 = this.get('form') + '.' + field.name), indexOf.call(hideFields, ref1) >= 0)
            ) {
                return false;
            }
            if (((ref2 = field.type), indexOf.call(acceptedFields, ref2) >= 0)) {
                return true;
            }
        });
        return usableFields.map(function (item) {
            var options;
            item.prompt = intl.t('massedit.nochange');
            if (item.features.remove_empty_selection) {
                item.show_prompt = true;
            }
            if (item.type === 'checkbox') {
                options = [
                    {
                        id: '1',
                        value: intl.t('general.yes'),
                    },
                    {
                        id: '2',
                        value: intl.t('general.no'),
                    },
                ];
                item.options = options;
                item.type = 'dropdown_values';
                Ember.set(item.features, 'dontshowCheckbox', true);
            }
            return item;
        });
    }),
    getChanges: function () {
        var key, ref, ret, value;
        ret = [];
        ref = this.get('values');
        for (key in ref) {
            value = ref[key];
            if (value === '(empty)') {
                ret.push({
                    key: key,
                    value: null,
                });
            } else {
                if (Em.isPresent(value)) {
                    ret.push({
                        key: key,
                        value: value,
                    });
                }
            }
        }
        return ret;
    },
    execute_one: function (heap, count) {
        var change, changes, form, i, id, len, originals, promise, record, store, userId;
        id = heap.popObject();
        userId = this.get('session.currentUser.id');
        if (!id) {
            return;
        }
        store = this.get('store');
        form = this.get('form');
        record = store.peekRecord(form, id);
        // locked rows cannot be edited
        if (record.get('row_info.status') === 'locked') {
            return this.goNext(heap, count);
        } else {
            changes = this.getChanges();
            originals = {};
            originals[record.get('id')] = record.serialize();
            for (i = 0, len = changes.length; i < len; i++) {
                change = changes[i];
                if (change.key === 'userlevel' && id === userId && form === 'user') {
                    this.get('dialogs').alert(this.get('intl').t('massedit.self_selected'));
                    continue;
                } else {
                    record.set(change.key, change.value);
                }
            }
            if (this.get('selectedRecalculation')) {
                record.set('metadata', {
                    recalculate: this.get('selectedRecalculation'),
                });
            }
            promise = record.save();
            return promise.then(
                () => {
                    return this.goNext(heap, count);
                },
                (e) => {
                    this.pushError(record, e, originals);
                    return this.goNext(heap, count);
                },
            );
        }
    },
    pushError: function (record, e, originals) {
        var attr, c, field, key, ref;
        c = this.get('Collector');
        ref = originals[record.get('id')];
        // Sadly we have to do it like this because ember still doesn't support rolling back relationships
        for (key in ref) {
            attr = ref[key];
            if (key === 'row_info') {
                continue;
            }
            field = c.field(record.get('constructor.modelName'), key);
            if (field.type === 'database' && attr) {
                record.set(key, this.get('store').peekRecord(field.options.form, attr));
            } else {
                record.set(key, attr);
            }
        }
        record.rollbackAttributes();
        return this.get('errors').pushObject(
            Ember.Object.create({
                row: record,
                error: e,
            }),
        );
    },
    modelFields: Ember.computed('form', function () {
        return this.get('Collector').fieldArray(this.get('form'));
    }),
    goNext: function (heap, count) {
        this.set('numericalProgress', Math.floor(((count - heap.length) / count) * 1000) / 10);
        if (heap.length === 0) {
            this.evented.storeEvent('update', this.form, null);
            this.set('alldone', true);
            this.set('values', {});
            if (this.close && this.errors.length < 1) {
                this.close();
            }
        }
        if (this.get('die_signal')) {
            alert('Mass edit cancelled!');
            this.set('alldone', true);
            this.set('values', {});
            return;
        }
        return this.execute_one(heap, count);
    },
    actions: {
        clearValue: function (object) {
            if (Ember.get(object.features, 'selected')) {
                Ember.set(object.features, 'selected', false);
                return Ember.set(this, 'values.' + object.name, null);
            } else {
                Ember.set(object.features, 'selected', true);
                return Ember.set(this, 'values.' + object.name, '(empty)');
            }
        },
        start: function () {
            var heap;
            this.set('executing', true);
            this.set('errors', Em.A());
            this.set('numericalProgress', 0);
            heap = Em.A(this.get('ids'));
            return this.execute_one(heap, heap.length);
        },
        cancel: function () {
            return this.set('die_signal', true);
        },
        back: function () {
            if (this.inModal) {
                if (this.close) {
                    return this.close();
                }
            } else {
                if (this.getback) {
                    return this.getback();
                }
            }
        },
        valueChanged: function (value, fieldName) {
            return Ember.set(this, 'values.' + fieldName, value);
        },
    },
});

export default MassEditComponent;
