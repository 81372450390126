/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/site/user/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalSiteUserRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../../routes/general-report';

TimetrackerApprovalSiteUserRoute = GeneralReportRoute.extend({
    queryParams: {
        status: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.setData(model.query);
    },
    model: function (params) {
        var parentModel;
        params = this._super(params);
        if (params.status === 'all') {
            delete params.status;
        }
        parentModel = this.modelFor('timetracker.approval.site');
        params.project = parentModel.project.get('id');
        params.date = this.controllerFor('timetracker.approval').get('date');
        params.employer = this.controllerFor('timetracker.approval').get('employer');
        if (!this.get('Collector').testNeedsOne('worktime.employer')) {
            delete params.employer;
        }
        params.sideload = true;
        //        params.status = 'open'
        return this.store.query('worktime', params);
    },
});

export default TimetrackerApprovalSiteUserRoute;
