/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldohistory/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldohistoryRoute;

import Ember from 'ember';

UsersEditSaldohistoryRoute = Ember.Route.extend();

export default UsersEditSaldohistoryRoute;
