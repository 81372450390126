/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-card-multi-day/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarCardMultiDayComponent;

import Ember from 'ember';

CalendarCardMultiDayComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    end_field: 'enddate',
    _data: Ember.computed('day', 'data.@each.date', function () {
        return this.getData();
    }),
    _fields: Ember.computed('fields', function () {
        var fields;
        fields = this.get('fields');
        if (Ember.typeOf(fields) === 'string') {
            Em.assert(
                'You need to provide the form if giving fields to calendar-card as string',
                this.get('form'),
            );
            fields = this.get('Collector').fieldArray(this.get('form'), fields.split(','));
        }
        return fields;
    }),
    getData: function () {
        var data, day, day_index, end, first, start;
        data = this.get('data');
        if (data) {
            day = this.get('day');
            start = this.get('date_field');
            end = this.get('end_field');
            first = this.get('date').split('_')[0];
            day_index = this.get('index');
            return data.map(function (item) {
                if (item.get(start) <= day && item.get(end) >= day) {
                    return Ember.Object.create({
                        row: item,
                        first: day === first || day_index === 0 ? true : false,
                    });
                } else if (
                    day <= item.get(start) ||
                    moment(day).week() > moment(item.get(end)).week()
                ) {
                    return false;
                } else {
                    return 'empty';
                }
            });
        }
    },
    actions: {
        click: function (row) {
            if (this.get('onclick')) {
                return this.onclick(row);
            }
        },
        removeRecord: function (row) {
            if (this.get('remove')) {
                return this.remove(row);
            }
        },
        showRemoveIcon: function (index) {
            var elem;
            elem = '.calendar-remove-item.num-' + index;
            return $(elem).css('visibility', 'visible');
        },
        hideRemoveIcon: function (index) {
            var elem;
            elem = '.calendar-remove-item.num-' + index;
            return $(elem).css('visibility', 'hidden');
        },
    },
});

export default CalendarCardMultiDayComponent;
