define("tt4/components/notification-container", ["exports", "ember-cli-notifications/components/notification-container"], function (_exports, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _notificationContainer.default;
    }
  });
});
