/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/id06competence/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditId06competenceRoute;

import Ember from 'ember';

UsersEditId06competenceRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    model: function (params) {
        var c, id06kort, user;
        c = this.get('Collector');
        user = this.modelFor('users.edit');
        if (!user.get('id06kort')) {
            return {
                error: 'id06kort_not_found',
            };
        }
        if (!user.get('employer.id')) {
            return {
                error: 'employer_not_found',
            };
        }
        if (!user.get('personid')) {
            return {
                error: 'personid_not_found',
            };
        }
        id06kort = user.get('id06kort');
        //get user employer
        return this.store
            .findRecord('employer', user.get('employer.id'), {
                reload: true,
            })
            .then((employer) => {
                if (!employer.get('emp_id')) {
                    return {
                        error: 'employer_id_not_found',
                    };
                }
                //first get cardinfo with taxnumber
                return c
                    .ccapi({
                        url: 'id06-competence',
                        data: {
                            id: id06kort,
                            companyid: employer.get('emp_id'),
                            personalcode: user.get('personid'),
                        },
                    })
                    .then((data) => {
                        if (data.length === 0) {
                            return {
                                error: 'no_competences',
                            };
                        }
                        return data;
                    });
            });
    },
});

export default UsersEditId06competenceRoute;
