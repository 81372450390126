/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/pl/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsPlRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SettingsPlRoute = Ember.Route.extend(AuthenticatedRouteMixin);

//    resetController: (controller, isExiting, transition) ->
//        @_super.apply(this, arguments);

//        if isExiting
//            @transitionTo transition.targetName
//            location.reload()
export default SettingsPlRoute;
