/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/report/date/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryReportDateController, computed, controller, get, service, set;

import Ember from 'ember';

({
    computed,
    inject: { service, controller },
    set,
    get,
} = Ember);

DiaryReportDateController = Ember.Controller.extend({
    Collector: service('collector-service'),
    projectController: controller('diary.report.date.project'),
    project: computed.alias('projectController.model.project'),
    field: computed('project', function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('diary', 'project');
        set(field, 'value', this.get('project.id'));
        return field;
    }),
    actions: {
        setValue: function (value, field) {
            if (value) {
                return this.transitionToRoute('diary.report.date.project', value.get('id'));
            }
        },
    },
});

export default DiaryReportDateController;
