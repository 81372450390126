define("tt4/services/clock", ["exports", "ember-cli-clock/services/clock"], function (_exports, _clock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _clock.default;
    }
  });
});
