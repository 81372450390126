import GeneralAddController from '../../../controllers/general-add';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';

export default GeneralAddController.extend({
    session: service(),
    edit: true,
    drivinglog: alias('model'),
    fieldSettings: A({
        edit: true,
    }),
});
