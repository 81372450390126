/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/thereport/page/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ThereportController,
    indexOf = [].indexOf;

import Ember from 'ember';

import pdfExport from '../../../mixins/pdf-export';

import csvExport from '../../../mixins/csv-export';

import formReport from '../../collector/report-comp/component';

import ccReport from '../../../components/cc-report';

ThereportController = Ember.Controller.extend(pdfExport, csvExport, {
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionGlobals: Ember.inject.service(),
    gui: Ember.inject.service(),
    session: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    checked_rows: [],
    // contains current values of report filters
    filterValues: null,
    rowActions: Ember.computed('intl', function () {
        var arr;
        arr = [
            {
                action: 'editRow',
                icon: 'move move-Pencil',
                text: this.get('intl').t('general.edit'),
            },
            {
                action: 'rowHistory',
                icon: 'move move-Search',
                text: this.get('intl').t('report.customer.history'),
            },
            {
                action: 'removeRow',
                icon: 'move move-Delete',
                text: this.get('intl').t('report.delete_rows'),
            },
        ];
        return arr;
    }),
    isMobile: Em.computed(function () {
        return typeof cordova !== 'undefined' && cordova !== null;
    }),
    // these field types can be used as filters
    filterFieldTypes: ['database', 'status', 'dropdown_options', 'dropdown_values', 'multiselect'],
    allowedMoreFilters: ['user.worktimegroup'],
    // this needs to be set for filters to work .. we must use filters for SOME form
    baseForm: Em.computed(function () {
        if (this.get('Collector').testNeeds(['bill'])) {
            return 'bill';
        } else {
            return 'worktime';
        }
    }),
    settings: Em.computed.alias('model.settings'),
    pageid: Em.computed.alias('model.pageid'),
    selectableFilters: Ember.computed('pageid', function () {
        var c,
            field,
            fields,
            filterFieldTypes,
            form,
            i,
            len,
            moreFilter,
            moreFilterArr,
            name,
            ref,
            ref1,
            ret;
        c = this.get('Collector');
        filterFieldTypes = this.get('filterFieldTypes');
        form = this.get('baseForm');
        fields = c.fields(form);
        ret = Em.A([]);
        for (name in fields) {
            field = fields[name];
            if (((ref = field.type), indexOf.call(filterFieldTypes, ref) >= 0)) {
                ret.pushObject(
                    Ember.Object.create({
                        id: name,
                        name: c.getTranslationForColumn(form, name),
                    }),
                );
            }
        }
        if (this.get('allowedMoreFilters')) {
            ref1 = this.get('allowedMoreFilters');
            for (i = 0, len = ref1.length; i < len; i++) {
                moreFilter = ref1[i];
                moreFilterArr = moreFilter.split('.');
                if (c.fieldExists(moreFilterArr[0], moreFilterArr[1])) {
                    ret.pushObject(
                        Ember.Object.create({
                            id: moreFilterArr[0] + '.' + moreFilterArr[1],
                            name: c.getTranslationForColumn(moreFilterArr[0], moreFilterArr[1]),
                        }),
                    );
                }
            }
        }
        return ret;
    }),
    refreshQueries: Ember.on(
        'init',
        Ember.observer('filterValues', function () {
            var reports;
            reports = this.get('reports');
            return reports.forEach((item) => {
                return this.refreshReport(item);
            });
        }),
    ),
    refreshReport: function (report) {
        var c, fields, filterValues, form, query, showFiltersArr;
        filterValues = this.get('filterValues');
        if (!filterValues) {
            return;
        }
        c = this.get('Collector');
        form = report.get('form');
        showFiltersArr = this.get('showFilters').split(',');
        fields = c.fields(form);
        query = [];
        $.each(showFiltersArr, (key, item) => {
            var field_key, i, len, ref, useField, value;
            if (fields) {
                ref = Object.keys(fields);
                for (i = 0, len = ref.length; i < len; i++) {
                    field_key = ref[i];
                    if (fields[field_key].options && fields[field_key].options.form === item) {
                        useField = this.queryDefinitions(form, fields[field_key].ext);
                        break;
                    }
                }
            }
            if (!useField) {
                useField = this.queryDefinitions(form, item);
            }
            if (!fields && report.get('type') !== 'cc') {
                // this means that report is some broken or empty
                return;
            }
            if (report.get('type') !== 'cc' && !(useField in fields) && item.indexOf('.') === -1) {
                return;
            }
            value = filterValues.findBy('name', item).value;
            if (!value) {
                return;
            }
            return query.pushObject({
                name: useField,
                value: value,
            });
        });
        return report.set('query', query);
    },
    // list for special definitions for query parameters .. ie. abcenses has startdate not date field
    queryDefinitions: function (form, field) {
        if (form === 'abcense' && field === 'date') {
            return 'startdate';
        }
        return field;
    },
    saveSettings: function () {
        var addReport,
            c,
            date_field_found,
            field,
            field_name,
            field_obj,
            form_fields,
            i,
            j,
            k,
            len,
            len1,
            len2,
            report,
            report_field,
            report_fields,
            reports,
            save,
            saveFields,
            tmp_field;
        c = this.get('Collector');
        reports = this.get('reports');
        saveFields = [
            'header',
            'group',
            'show_columns',
            'form',
            'half',
            'custom',
            'type',
            'key',
            'calendar',
            'rowsPerPage',
        ];
        save = [];
        for (i = 0, len = reports.length; i < len; i++) {
            report = reports[i];
            // this is calendar-report so make sure that date-column is selected
            if (report['calendar']) {
                date_field_found = false;
                if (report['show_columns']) {
                    report_fields = report['show_columns'].split(',');
                } else if (report['group']) {
                    report_fields = report['group'].split(',');
                }
                for (j = 0, len1 = report_fields.length; j < len1; j++) {
                    report_field = report_fields[j];
                    tmp_field = c.formField(report['form'], report_field);
                    if (tmp_field && tmp_field['type'] === 'date') {
                        date_field_found = true;
                        break;
                    }
                }
                if (!date_field_found) {
                    // no datefield was found, iterate through form and get first date-field
                    form_fields = c.fields(report['form']);
                    for (field_name in form_fields) {
                        field_obj = form_fields[field_name];
                        if (field_obj['type'] === 'date') {
                            if (report['show_columns']) {
                                Ember.set(
                                    report,
                                    'show_columns',
                                    report['show_columns'] + ',' + field_name,
                                );
                            } else if (report['group']) {
                                Ember.set(report, 'group', report['group'] + ',' + field_name);
                            }
                        }
                    }
                }
            }
            if (this.Collector.fieldExists(report['form'], 'status')) {
                Ember.set(report, 'canChangeStatus', true);
            } else {
                Ember.set(report, 'canChangeStatus', false);
            }
            addReport = {};
            for (k = 0, len2 = saveFields.length; k < len2; k++) {
                field = saveFields[k];
                addReport[field] = report.get(field);
            }
            save.push(addReport);
        }
        return c.ttapi({
            method: 'post',
            url: 'comboreport',
            data: {
                id: this.get('pageid'),
                settings: JSON.stringify({
                    filters: this.get('showFilters'),
                    reports: save,
                }),
            },
        });
    },
    showAddReportButton: Ember.computed('reports.length', function () {
        if (this.get('reports.length') <= 6 && this.get('session.currentUser.userlevel') !== '1') {
            return true;
        } else {
            return false;
        }
    }),
    reports: Ember.computed('settings.reports', function () {
        var reports, ret;
        ret = Em.A([]);
        reports = this.get('settings.reports');
        if (!reports) {
            return ret;
        }
        $.each(reports, (key, item) => {
            var field, i, len, ref, showcolumns;
            item['index'] = key;
            if (Ember.isPresent(item['form']) && !this.get('Collector').formExists(item['form'])) {
                item.error_in_report = 'Form ' + item.form + ' does not exist in service';
            }
            //check group items
            if (item['group']) {
                ref = item['group'].split(',');
                for (i = 0, len = ref.length; i < len; i++) {
                    field = ref[i];
                    if (field === 'all') {
                        continue;
                    }
                    if (this.Collector.fieldExists(item.form, field) === false) {
                        item.error_in_report = 'missing ' + field + ' in ' + item.form + ' form';
                    }
                }
            }
            if (item.type === 'cc') {
                item.hideFullExportLink = true;
            }
            showcolumns = item['show_columns'] ? item['show_columns'].split(',') : [];
            if (!item['group'] && this.Collector.fieldExists(item.form, 'status')) {
                item.useRowInfo = true;
            }
            // we need checkbox on grouped report also
            if (this.Collector.fieldExists(item.form, 'status')) {
                item.canChangeStatus = true;
            }
            return ret.pushObject(Ember.Object.create(item));
        });
        return ret;
    }),
    showFilters: 'date,project,user',
    loadFilters: Ember.observer('settings.filters', function () {
        var _showFilters, filters, i, len, ref, val;
        filters = this.get('settings.filters');
        if (!filters) {
            return 'date,project,user';
        }
        _showFilters = Em.A([]);
        ref = filters.split(',');
        for (i = 0, len = ref.length; i < len; i++) {
            val = ref[i];
            _showFilters.pushObject(val);
        }
        this.set('_showFilters', _showFilters);
        return this.set('showFilters', filters);
    }),
    updateShowFilters: Ember.observer('_showFilters.[]', function () {
        return this.set(
            'showFilters',
            Em.A(['date']).addObjects(this.get('_showFilters')).join(','),
        );
    }),
    // default date-range for calendar
    dateRange: Em.computed(function () {
        return (
            moment().startOf('month').format('YYYY-MM-DD') +
            '_' +
            moment().endOf('month').format('YYYY-MM-DD')
        );
    }),
    actions: {
        fullScreen: function () {
            this.get('gui').togglefullScreen();
            return false;
        },
        editorOn: function (report) {
            report.set('editorMode', true);
            return false;
        },
        editorOff: function (report) {
            report.set('editorMode', false);
            this.saveSettings();
            this.refreshReport(report);
            return false;
        },
        editRow: function (row, report) {
            var record;
            record = this.get('store').peekRecord(report.get('form'), row.get('id'));
            record.one('didUpdate', () => {
                return report.notifyPropertyChange('refreshRows');
            });
            return this.transitionToRoute({
                queryParams: {
                    modal: [report.get('form'), row.get('id')],
                },
            });
        },
        addNewReport: function () {
            return this.get('reports').pushObject(
                Ember.Object.create({
                    editorMode: true,
                }),
            );
        },
        removeReport: function (report) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('thereport.remove'))
                .then((con) => {
                    if (con) {
                        this.get('reports').removeObject(report);
                        this.refreshReport(report);
                        this.saveSettings();
                    }
                    return false;
                });
        },
        editFilters: function (state) {
            this.set('editFiltersMode', state);
            if (state === false) {
                this.saveSettings();
            }
            return false;
        },
        cancelEdit: function (report) {
            report.set('editorMode', false);
            return false;
        },
        export_csv: function (report) {
            var component, csv_str, promise, self;
            if (this.get('showLoader')) {
                return;
            }
            this.set('showLoader', true);
            // pretty much copy & paste code from export_pdf
            csv_str = [];
            component = this.createComponent(report);
            component.set('settings', report);
            promise = component.get('asCsvArray');
            self = this;
            return promise.then((array) => {
                this.set('showLoader', false);
                if (!array) {
                    return;
                }
                return this.createCsvFromElements(array, false);
            });
        },
        export_pdf_full: function (report = false) {
            this.set('full_pdf_export', true);
            return this.send('export_pdf', report);
        },
        export_excel: function (report) {
            var component, promise;
            if (this.get('showLoader')) {
                return;
            }
            this.set('showLoader', true);
            component = this.createComponent(report);
            component.set('settings', report);
            promise = component.get('asCsvArray');
            return promise
                .then((array) => {
                    var excelArray;
                    if (!array) {
                        return;
                    }
                    excelArray = array.map((arr) => {
                        return arr.map((item) => {
                            if (typeof item === 'object' && item !== null) {
                                return item.string;
                            }
                            return item;
                        });
                    });
                    return this.get('excel').export(excelArray, 'sheet1', 'export.xlsx');
                })
                .finally(() => {
                    return this.set('showLoader', false);
                });
        },
        export_excel_page: function () {
            var getReportData, promises, reports;
            if (this.get('showLoader')) {
                return;
            }
            this.set('showLoader', true);
            reports = this.get('reports');
            promises = [];
            getReportData = (report, index) => {
                var component, promise;
                component = this.createComponent(report);
                component.set('settings', report);
                promise = component.get('asCsvArray');
                return promise.then((array) => {
                    if (!array) {
                        return;
                    }
                    return array.map((arr) => {
                        return arr.map((item) => {
                            if (typeof item === 'object' && item !== null) {
                                return item.string;
                            }
                            return item;
                        });
                    });
                });
            };
            reports.forEach((report, index) => {
                return promises.push(getReportData(report, index));
            });
            return Ember.RSVP.Promise.all(promises).then((data) => {
                var excelArray;
                excelArray = [];
                data.forEach(function (rep) {
                    rep.forEach(function (row) {
                        return excelArray.push(row);
                    });
                    excelArray.push([]);
                    return excelArray.push([]);
                });
                this.get('excel').export(excelArray, 'sheet1', 'export.xlsx');
                return this.set('showLoader', false);
            });
        },
        export_pdf: function (report = false) {
            var column,
                component,
                counter,
                getReportData,
                headers,
                i,
                len,
                pdfElements,
                promises,
                reports,
                self;
            if (this.get('showLoader')) {
                return;
            }
            this.set('showLoader', true);
            self = this;
            reports = this.get('reports');
            // If report is given we just want to export that
            if (report) {
                reports = reports.filter(function (rep) {
                    if (rep.get('header') === report.get('header')) {
                        //report_index =
                        return true;
                    }
                });
            }
            promises = [];
            pdfElements = Em.A([]);
            headers = this.createAdditionalPdfHeaders();
            getReportData = (report, counter, column) => {
                var promise;
                if (this.get('full_pdf_export')) {
                    promise = report.get('asPdfElementFull');
                    return promise.then(function (element) {
                        return self.createPdfFromElements([element]);
                    });
                } else {
                    promise = report.get('asPdfElement');
                    promises.push(promise);
                    return promise.then((element) => {
                        var headerElement;
                        if (!element) {
                            return;
                        }
                        if (report.settings.header) {
                            headerElement = this.createPdfHeaderElement(report.settings.header);
                            headerElement.index = counter;
                            headerElement.column = column;
                            pdfElements.pushObject(headerElement);
                        }
                        element.index = counter + 1;
                        element.column = column;
                        return pdfElements.pushObject(element);
                    });
                }
            };
            column = null;
            for (counter = i = 0, len = reports.length; i < len; counter = ++i) {
                report = reports[counter];
                component = this.createComponent(report);
                component.set('settings', report);
                if (report.half) {
                    column = column === 1 ? 2 : 1;
                } else {
                    column = null;
                }
                getReportData(component, counter * 2, column);
            }
            if (this.get('full_pdf_export')) {
                // remove property so next export doesn't use this
                this.set('full_pdf_export', false);
                this.set('showLoader', false);
                return;
            }
            return Em.RSVP.Promise.all(promises).then(() => {
                if (this.get('signature')) {
                    pdfElements.pushObject({
                        index: Number.MAX_SAFE_INTEGER - 1,
                        style: 'marginTop',
                        table: {
                            widths: ['auto', 140, 30, 'auto', 140],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: this.get('intl').t('report.worktask.signature') + ':',
                                    },
                                    {
                                        border: [false, false, false, true],
                                        text: '',
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: '',
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: this.get('intl').t('thereport.print_name') + ':',
                                    },
                                    {
                                        border: [false, false, false, true],
                                        text: '',
                                    },
                                ],
                            ],
                        },
                    });
                }
                if (this.get('datePlace')) {
                    pdfElements.pushObject({
                        index: Number.MAX_SAFE_INTEGER,
                        style: 'marginTop',
                        table: {
                            widths: ['auto', 140, 30, 'auto', 140],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: this.get('intl').t('thereport.date') + ':',
                                    },
                                    {
                                        border: [false, false, false, true],
                                        text: '',
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: '',
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: this.get('intl').t('thereport.place') + ':',
                                    },
                                    {
                                        border: [false, false, false, true],
                                        text: '',
                                    },
                                ],
                            ],
                        },
                    });
                }
                return this.createPdfFromElements(
                    pdfElements.sortBy('index'),
                    'download',
                    headers,
                ).finally(() => {
                    return this.set('showLoader', false);
                });
            });
        },
        addDefaultReport: function (defaultReport) {
            var header, key, newReport, ref, value;
            header =
                'header' in defaultReport.settings
                    ? defaultReport.settings.header
                    : defaultReport.name;
            newReport = Ember.Object.create({
                custom: true,
                header: header,
            });
            ref = defaultReport.settings;
            for (key in ref) {
                value = ref[key];
                newReport[key] = value;
            }
            this.get('reports').pushObject(newReport);
            return this.saveSettings();
        },
        checkedRowsChanged: function (report_index, rows) {
            return this.set('checked_rows_' + report_index, rows);
        },
        removeFieldFromOrder: function (field) {
            var cols;
            cols = this.get('settings.order').split(',');
            if (cols.length === 1) {
                this.set('settings.order', this.get('settings.defaultOrder'));
                this.set('manuallyOrdered', false);
                return;
            }
            if (cols.includes(field + '-')) {
                field = field + '-';
            }
            cols.splice(cols.indexOf(field), 1);
            if (cols.length > 0) {
                this.set('manuallyOrdered', true);
                this.set('order', cols.join(','));
                return this.set('settings.order', cols.join(','));
            }
        },
    },
    createComponent: function (report) {
        if (report.type === 'cc') {
            return ccReport.create({
                renderer: this,
                store: this.get('store'),
                Collector: this.get('Collector'),
                intl: this.get('intl'),
                rowsPerPage: 'all',
                sessionGlobals: this.get('sessionGlobals'),
                settings: report,
                key: report.key,
            });
        } else {
            return formReport.create({
                renderer: this,
                store: this.get('store'),
                Collector: this.get('Collector'),
                intl: this.get('intl'),
                rowsPerPage: 'all',
                sessionGlobals: this.get('sessionGlobals'),
                checked_rows: this.get('checked_rows_' + report.index),
                settings: report,
            });
        }
    },
    createAdditionalPdfHeaders: function () {
        var baseForm, c, field, filter, filterValues, filtername, formName, i, len, rows, tmpFilter;
        c = this.get('Collector');
        filterValues = this.get('filterValues');
        if (!filterValues) {
            // ugly hack to get empty filters to work in pdf headers
            filterValues = [
                {
                    name: 'date',
                    value:
                        moment().startOf('week').format('YYYY-MM-DD') +
                        '_' +
                        moment().endOf('week').format('YYYY-MM-DD'),
                },
            ];
        }
        rows = [];
        baseForm = this.get('baseForm');
        for (i = 0, len = filterValues.length; i < len; i++) {
            filter = filterValues[i];
            if (!filter.value) {
                continue;
            }
            if (filter.name.indexOf('.') > -1) {
                tmpFilter = filter.name.split('.');
                formName = tmpFilter[0];
                filtername = tmpFilter[1];
            } else {
                formName = baseForm;
                filtername = filter.name;
            }
            field = c.field(formName, filtername);
            if (
                field.type === 'database' &&
                Ember.typeOf(filter.value) === 'string' &&
                !isNaN(filter.value)
            ) {
                filter.value = parseInt(filter.value);
            }
            rows.push(
                c.getTranslationForColumn(formName, filtername) +
                    ': ' +
                    c._format_(filter.value, formName, filtername, 'pdf'),
            );
        }
        return rows.join('\n');
    },
    defaultReports: Ember.computed(function () {
        var c, intl, promise;
        intl = this.get('intl');
        c = this.get('Collector');
        promise = c.ttapi({
            url: 'defaultReports',
        });
        promise.then((data) => {
            var i, key, len, report;
            for (key = i = 0, len = data.length; i < len; key = ++i) {
                report = data[key];
                if ('translatedName' in report) {
                    data[key].name = intl.t(report.translatedName);
                }
            }
            data = c.needsOfArray(data);
            return this.set('defaultReports', data);
        });
        return null;
    }),
});

export default ThereportController;
