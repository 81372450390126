/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/notes/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryNotesController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

WorksitediaryNotesController = GeneralReportController.extend({
    form: 'diaryreport_note',
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
        }),
    }),
    //    showColumns: ["date", "project", "title", "description", "images", "rating", "weather"]
    queryParams: ['type', 'responsible'],
    filters: Em.A([
        //        Em.Object.create({field: "date"})
        Em.Object.create({
            field: 'type',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
});

export default WorksitediaryNotesController;
