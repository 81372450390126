/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/cdn.coffee.old
// Generated by CoffeeScript 2.6.1
var CdnService;

import Ember from 'ember';

import config from '../config/environment';

CdnService = Ember.Service.extend({
    url: function (url) {
        var base;
        base = config.APP.CDN_URL;
        if (
            base.substring(base.length - 1, base.length) !== '/' &&
            url.substring(url.length - 1, url.length) !== '/'
        ) {
            base += '/';
        }
        return base + url;
    },
});

export default CdnService;
