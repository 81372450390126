/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-setting-comp/postpopulated-worktimes/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompSaldoSettingComponent;

import Ember from 'ember';

import collector from '../../../../classes/collector';

CollectorFieldSettingCompSaldoSettingComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    ttapi: Em.inject.service(),
    customerSettings: Em.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    loading: true,
    settingKey: Ember.computed('worktimegroup', function () {
        return (
            'postpopulated_worktimes_project' +
            (this.get('worktimegroup') ? '_' + this.get('worktimegroup') : '')
        );
    }),
    didReceiveAttrs: function () {
        return this.get('ttapi')
            ._get('/settings?worktimegroup=' + this.get('worktimegroup'))
            .then((ret) => {
                var projectSetting;
                projectSetting = ret.findBy('key', this.get('settingKey'));
                if (projectSetting) {
                    this.set('selectedProject', projectSetting.value);
                }
                return this.set('loading', false);
            });
    },
    projectField: Ember.computed('selectedProject', function () {
        var field;
        field = this.get('Collector').field('worktime', 'project');
        field.value = this.get('selectedProject');
        return field;
    }),
    actions: {
        saveSetting: function () {
            return this.get('customerSettings')
                .setSetting(
                    this.get('settingKey'),
                    this.get('selectedProject.id'),
                    this.get('worktimegroup'),
                )
                .then(() => {
                    return this.get('notifications').success(this.get('intl').t('general.saved'), {
                        autoClear: true,
                    });
                });
        },
        changeProject: function (project) {
            return this.set('selectedProject', project);
        },
    },
});

export default CollectorFieldSettingCompSaldoSettingComponent;
