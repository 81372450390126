/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/user-icon2/component.coffee.old
// Generated by CoffeeScript 2.6.1
var UserIcon2Component;

import Ember from 'ember';

import config from '../../../config/environment';

UserIcon2Component = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    classNames: ['inline-block'],
    image_url: Em.computed('user', function () {
        return config.APP.COLLECTOR_API_URL + '/' + this.get('user.image.firstObject.thumb');
    }),
    text: Em.computed('user', function () {
        return this.get('user.firstname') + ' ' + this.get('user.lastname');
    }),
    shortText: Em.computed('text', function () {
        return this.get('Collector').shortFormat(this.get('text'), 2);
    }),
    color: Em.computed('text', function () {
        return this.get('Collector').randomizeColor(this.get('text'));
    }),
});

export default UserIcon2Component;
