import GeneralReportRoute from '../../../routes/general-report';

export default GeneralReportRoute.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    form: 'drive',
    model(params) {
        params = this._super(params);
        params.sideload = true;
        return this.store.query('drive', params);
    },
});
