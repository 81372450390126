/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/report-filters/component.coffee.old
// Generated by CoffeeScript 2.6.1
var ReportFiltersComponent;

import Ember from 'ember';

ReportFiltersComponent = Ember.Component.extend({
    layoutName: 'components/report-filters',
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    analytics: Ember.inject.service(),
    gui: Ember.inject.service(),
    filterMode: false,
    statusName: Ember.computed('intl.primaryLocale', 'status', function () {
        if (this.get('intl').exists('status.' + this.get('status'))) {
            return this.get('intl').t('status.' + this.get('status'));
        } else {
            return this.get('status');
        }
    }),
    statusTypes: Em.computed('form', function () {
        var c, field, intl, option, options;
        if (!this.get('form')) {
            return [];
        }
        c = this.get('Collector');
        intl = this.get('intl');
        field = c.field(this.get('form'), 'status');
        options = (function () {
            var i, len, ref, results;
            ref = field.options;
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
                option = ref[i];
                if (intl.exists('status.' + option)) {
                    results.push({
                        id: option,
                        name: intl.t('status.' + option),
                    });
                } else {
                    results.push({
                        id: option,
                        name: option,
                    });
                }
            }
            return results;
        })();
        return options;
    }),
    dateObs: function () {
        var dates;
        if (this.get('startDate') && this.get('endDate')) {
            return this.set('date', this.get('startDate') + '_' + this.get('endDate'));
        } else if (this.get('date')) {
            dates = this.get('date').split('_');
            this.set('startDate', dates[0]);
            return this.set('endDate', dates[1]);
        } else {
            return this.set('date', null);
        }
    }.observes('startDate', 'endDate', 'date'),
    _filters: Ember.computed('filters', function () {
        var c, filters, hiddenFilters;
        c = this.get('Collector');
        filters = this.get('filters');
        if (!filters) {
            return;
        }
        filters.forEach(function (item, key) {
            if (item.type === 'date' && !item.hasOwnProperty('defaultValue')) {
                item.defaultValue = item.value;
            }
            Em.set(item, 'name', item.field);
            if (item.type != null) {
                return;
            }
            return (item.type = 'database');
        });
        hiddenFilters = [];
        filters = filters.filter(function (item) {
            var ref;
            if (item.hide_filter) {
                return false;
            }
            if (item.extra && c.fieldExists(item.form, item.field)) {
                hiddenFilters.push(item);
                return false;
            }
            return (
                (ref = item.type) === 'searchbox' ||
                ref === 'groupByFilter' ||
                c.fieldExists(item.form, item.field)
            );
        });
        this.set('hiddenFilters', hiddenFilters);
        return filters;
    }),
    setDropdownFilterings: function (changeInField, value) {
        var dependencies, filtering, i, len, options, results, setFilter;
        dependencies = this.get('Collector').getDropdownDepency(changeInField);
        this.get('filters').forEach((field) => {
            if (
                Ember.isPresent(Ember.get(field, 'reload')) &&
                Ember.get(field, 'reload') === changeInField
            ) {
                return dependencies.push({
                    field: Ember.get(field, 'field'),
                    filter: Ember.get(field, 'reload'),
                });
            }
        });
        results = [];
        for (i = 0, len = dependencies.length; i < len; i++) {
            filtering = dependencies[i];
            setFilter = this.get('filters').findBy('name', filtering.field);
            if (!setFilter) {
                continue;
            }
            if (Ember.typeOf(filtering.filter) !== 'string') {
                // This does not support array reloads currently
                continue;
            }
            options = {};
            if (typeof value === 'object') {
                options[filtering.filter] = value ? value.get('id') : null;
            } else {
                options[filtering.filter] = value ? value : null;
            }
            results.push(setFilter.set('filterOptions', options));
        }
        return results;
    },
    isIE: Ember.computed(function () {
        return this.get('gui.isIE');
    }),
    actions: {
        toggleFilter: function () {
            return this.toggleProperty('filterMode');
        },
        status: function (status, filter) {
            //            filter.set 'value', status
            return this.set('status', status);
        },
        filterChanged: function (value, fieldName) {
            var filter, id;
            this.setDropdownFilterings(fieldName, value);
            filter = this.get('filters').findBy('name', fieldName);
            if (fieldName && Ember.isPresent(filter) && Ember.isPresent(filter.subform)) {
                fieldName = filter.form + '-' + fieldName;
            }
            this.sendAction('filterValueChanged', value, fieldName);
            if (value) {
                this.analytics.trackEvent({
                    category: this.form + ' report filters',
                    action: fieldName,
                    label: 'General-report',
                });
            }
            if (value) {
                if (typeof value.get === 'function') {
                    if (Array.isArray(value)) {
                        id = value.join();
                    } else {
                        id = value.get('id');
                    }
                } else {
                    id = value;
                }
            } else {
                id = null;
            }
            return filter.set('value', id);
        },
        searchChanged: function (value) {
            value = value.target ? value.target.value : value;
            this.sendAction('filterValueChanged', value, 'q');
            if (value) {
                return this.analytics.trackEvent({
                    category: this.form + ' report filters',
                    action: 'search',
                    label: 'General-report',
                });
            }
        },
        dateChanged: function (field, value) {
            if (typeof value !== 'string') {
                return;
            }
            this.sendAction('filterValueChanged', value, field);
            if (value) {
                return this.analytics.trackEvent({
                    category: this.form + ' report filters',
                    action: field,
                    label: 'General-report',
                });
            }
        },
        resetFilter: function (filter) {
            return this.send('filterChanged', null, filter.field);
        },
        clearFilters: function () {
            var filters, i, item, len, results;
            filters = this.get('filters');
            Ember.get(this, 'analytics').trackEvent({
                category: this.form + ' report filters',
                action: 'clear filters',
                label: 'General-report',
            });
            results = [];
            for (i = 0, len = filters.length; i < len; i++) {
                item = filters[i];
                if (!Em.isBlank(item.value)) {
                    if (item.extra || item.type === 'groupByFilter') {
                        this.send('filterChanged', null, item.field);
                        //                        @sendAction('filterValueChanged', null , item.field)
                        results.push(Ember.set(item, 'value', null));
                    } else {
                        if (item.type === 'date') {
                            this.sendAction('filterChanged', item.defaultValue, item.field);
                            Ember.set(item, 'value', item.defaultValue);
                            if (item.defaultValue === null) {
                                Ember.set(item, 'end', null);
                                Ember.set(item, 'start', null);
                            }
                        } else {
                            Ember.set(item, 'value', null);
                        }
                        if (item.type === 'searchbox') {
                            results.push(Ember.set(item, 'tmpvalue', ''));
                        } else {
                            results.push(void 0);
                        }
                    }
                } else {
                    results.push(void 0);
                }
            }
            return results;
        },
    },
});

export default ReportFiltersComponent;
