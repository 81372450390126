/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/mvr/notices/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMvrNoticesRoute;

import Ember from 'ember';

import GeneralReportRoute from 'tt4/routes/general-report';

import { inject as service } from '@ember/service';

SiteSafetyMvrNoticesRoute = GeneralReportRoute.extend({
    capi: service(),
    flags: service(),
    form: 'trnotice',
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            params.type = 'mvr';
            if (params.date) {
                params.created = params.date;
                delete params.date;
            }
            params.sideload = true;
            if (params.status === 'all') {
                delete params.status;
            }
            if (Ember.isPresent(params.groupBy)) {
                params.sideload = true;
                // This can be moved to mixin when wanted in other places also
                params.order = params.groupBy;
                return this.get('capi').findAll(this.get('form'), params);
            } else {
                return this.store.query('trnotice', params);
            }
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('site-safety.report.mvr.notices').set('qParams', params);
        }
    },
    actions: {
        didTransition: function () {
            return this.set('controller.statusSelector', true);
        },
    },
});

export default SiteSafetyMvrNoticesRoute;
