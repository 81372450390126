/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/session-globals.coffee.old
// Generated by CoffeeScript 2.6.1
var SessionGlobalsService;

import Ember from 'ember';

SessionGlobalsService = Ember.Service.extend({
    set: function (name, value) {
        if (window.globals == null) {
            window.globals = {};
        }
        return (window.globals[name] = value);
    },
    get: function (name) {
        if (window.globals == null) {
            return null;
        }
        if (!window.globals[name]) {
            return null;
        }
        return JSON.parse(JSON.stringify(window.globals[name]));
    },
});

export default SessionGlobalsService;
