/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/employer-sums/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PresencesEmployerSumsController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

PresencesEmployerSumsController = GeneralReportController.extend({
    showColumns: ['employer', 'hours'],
    filters: Em.A([
        Em.Object.create({
            field: 'project',
        }),
        Em.Object.create({
            field: 'date',
        }),
    ]),
    objects: Ember.computed(function () {
        return {
            employer: {
                type: 'database',
                features: {},
                options: {
                    form: 'employer',
                    fields: ['emp_id', 'name'],
                },
            },
            hours: {
                type: 'hours',
                features: {},
            },
        };
    }),
    form: 'presence',
    date:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    queryParams: ['project', 'page', 'date'],
    project: null,
    page: null,
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
            hideCheckbox: true,
        }),
    }),
});

export default PresencesEmployerSumsController;
