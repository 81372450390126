import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Helper.extend({
    collector: service('collector-service'),

    compute(params) {
        const [form, field] = params[0].split('.');
        return get(this, 'collector').field(form, field);
    },
});
