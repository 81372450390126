/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/products/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsProductsRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SettingsProductsRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    beforeModel: function (transition) {
        if (this.get('Collector').testNeedsOne('products.mini_entry')) {
            transition.abort();
            return this.transitionTo('dashboard');
        }
    },
});

export default SettingsProductsRoute;
