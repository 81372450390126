import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { set, get } from '@ember/object';
import { A } from '@ember/array';
import { typeOf } from '@ember/utils';

export default Component.extend({
    ttapi: service(),
    flags: service(),
    dialogs: service(),
    store: service(),
    intl: service(),

    classNames: ['holiday-details-box'],

    numberOfSeasons: 5,

    userDetails: computed('user', async function () {
        return await this.getUserDetails();
    }),

    translated_columns: computed('showHistoryColumns', function () {
        return this.showHistoryColumns.map((column) => {
            return this.intl.t('holiday_list.history.' + column);
        });
    }),

    async didInsertElement() {
        set(this, 'yearsArr', A());

        const data = await get(this, 'userDetails');

        let ret = [];

        for (var row of data) {
            ret.push(row.users[0]);
        }
        set(this, 'data', ret);
        set(this, 'historyType', this.historyType ? this.historyType : 'table');

        set(this, 'showHistoryColumns', ['created', 'season', 'description', 'days', 'deducts']);
        set(this, 'historyFilters', { type: 'both' });
        this.historyRowActions = [
            { action: 'remove', icon: 'move move-Delete' },
            { action: 'edit', icon: 'move move-Pencil' },
        ];
        if (this.historyType === 'list') await this.showHolidayPayList();
    },

    actions: {
        toggleAllSeasons() {
            if (this.toggledSeasons) {
                set(this, 'yearsArr', []);
            } else {
                let startYear = moment()
                    .subtract(this.numberOfSeasons - 1, 'year')
                    .format('YYYY');
                let arr = [];
                for (var i = 0; i < this.numberOfSeasons; i++) {
                    arr.pushObject(moment(startYear).add(i, 'year').format('YYYY'));
                }
                set(this, 'yearsArr', arr);
            }
            this.notifyPropertyChange('yearsArr');
            this.toggleProperty('toggledSeasons');
        },
        showDetailsForSeason(year) {
            let arr = get(this, 'yearsArr');
            if (arr.indexOf(year) == -1) {
                arr.pushObject(year);
            } else {
                arr.removeObject(year);
            }
            this.notifyPropertyChange('yearsArr');
        },
        filterHistory(name, e) {
            this.filterHistory(name, e.target.value);
        },

        async changeType(type) {
            if (type === 'list' && this.historyType !== 'list') await this.showHolidayPayList();
            return set(this, 'historyType', type);
        },

        async historyRowAction(action, row) {
            if (action === 'remove') {
                const con = await this.dialogs.confirm(
                    get(this, 'intl').t('report.confirm_delete'),
                );
                if (!con) return;
                set(
                    this,
                    'filteredHistoryData',
                    this.filteredHistoryData.filter((el) => el.id !== row.id),
                );
                this.removeRecord?.(row);
            }
            if (action === 'edit') {
                this.editRecord?.(row);
            }
        },
    },
    filterHistory(name, value) {
        this.historyFilters[name] = value;

        let historydata = get(this, 'historyData');

        historydata = historydata.filter((el) => {
            let ret = true;
            if (this.historyFilters.type === 'automatic') {
                ret = el.type === 'absence';
            }

            if (this.historyFilters.type === 'manual') {
                ret = el.type === 'manual';
            }
            return ret;
        });

        set(this, 'filteredHistoryData', historydata);
    },

    async showHolidayPayList() {
        let historyData = [];

        set(this, 'historyDataLoading', true);
        set(this, 'historyData', null);
        set(this, 'historyFilters.type', 'both');

        try {
            const absences = await this.getAbsenceRows();
            const holidayPays = await this.getHolidayPays();
            historyData = absences?.concat(holidayPays);
        } catch (e) {
            let message = e.message || 'Server error';
            this.dialogs.alert(message);
        }

        set(this, 'historyDataLoading', false);
        // Sort by date
        set(
            this,
            'historyData',
            historyData?.sort((el1, el2) => (el1.sortdate < el2.sortdate ? 1 : -1)),
        );
        set(this, 'filteredHistoryData', this.historyData);
        this.filterHistory('type', 'both');
    },

    async getAbsenceRows() {
        const params = {
            user: this.user.id,
            startdate:
                moment()
                    .subtract(this.numberOfSeasons - 1, 'year')
                    .format('YYYY-MM-DD') +
                '_' +
                moment().format('YYYY-MM-DD'),
            limit: 100,
            'abcensetype[usesholiday]': 'holidaypay',
            sideload: ['abcensetype.usesholiday'],
        };
        const absences = await this.store.query('abcense', params);
        if (!absences) return [];
        let absenceArr = A([]);

        for (const row of absences.toArray()) {
            const absenceSeasonArray = await this.ttapi.request(
                'api/getAbsenceSeason?id=' + this.user.id + '&absenceid=' + get(row, 'id'),
            );
            for (const resp of absenceSeasonArray) {
                absenceArr.pushObject({
                    id: get(row, 'id'),
                    created: moment(get(row, 'row_info.created')).format('DD.MM.YYYY'),
                    sortdate: get(row, 'row_info.created'),
                    season: resp['season'],
                    description: get(row, 'description'),
                    days: resp['used'],
                    deducts: true,
                    type: 'absence',
                });
            }
        }
        return absenceArr;
    },

    async getUserDetails() {

        // Get past 5 years seasons
        let result = [];
        const url = 'api/getMultipleSeasonsUsersWithHolidays';
        let res = await this.Collector.ttapi({
            url: url,
            data: {
                id: this.user.id,
                start: moment().add({year: 1}).format('YYYY-05-02'),
                country: 'fi',
                years: this.numberOfSeasons,
            },
        });
        result = res;

        // Get all seasons if the flag is set
        if (this.flags.test('holiday-details-modal-v-2')) {
            const url = 'api/getAllUserHolidaysWithDetails';
            let resFlag = await this.Collector.ttapi({
                url: url,
                data: {
                    id: this.user.id,
                    country: 'fi',
                },
            });
            
            try {
                // Check if there are new seasons
                let newSeasons = resFlag.filter(row => result.filter(rowR => rowR.users[0]?.holidayYear === row?.users[0]?.holidayYear).length === 0);
                if(newSeasons.length > 0) {
                    result = result.concat(newSeasons);
                }
                // Arrange the seasons in order
                result = result.sort((a, b) => {
                    return a.users[0].holidayYear - b.users[0].holidayYear;
                });
                return result;
            } catch(err) {
                return result;
            }
        }

        return result;
    },


    async getHolidayPays() {
        const holidayPays = await this.store.query('holidaypay', { user: this.user.id });
        if (!holidayPays) return [];

        return holidayPays.map((historyrow) => {
            return {
                id: get(historyrow, 'id'),
                created: moment(get(historyrow, 'row_info.created')).format('DD.MM.YYYY'),
                sortdate: get(historyrow, 'row_info.created'),
                season:
                    typeOf(get(historyrow, 'date')) === 'string'
                        ? get(historyrow, 'date').substr(0, 4)
                        : get(historyrow, 'date')?.value,
                description: get(historyrow, 'description'),
                days: get(historyrow, 'days'),
                deducts: get(historyrow, 'deducts'),
                type: 'manual',
            };
        });
    },
});
