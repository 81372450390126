import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

const ServicesRoute = Route.extend(AuthenticatedRouteMixin, {
    Collector: service('collector-service'),
    tools: service(),

    model() {
        return this.Collector.ajax({ url: 'me?linkings' });
    },

    actions: {
        changeService(user) {
            return this.tools.change_service(user.username);
        },
    },
});

export default ServicesRoute;
