/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/company-profile/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var CompanyProfileController;

import Ember from 'ember';

import $ from 'jquery';

CompanyProfileController = Ember.Controller.extend({
    cdn: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    admintool: Ember.inject.service(),
    store: Ember.inject.service(),
    application: Em.inject.controller(),
    settingsController: Em.inject.controller('settings'),
    notifications: Ember.inject.service(),
    intl: Em.inject.service(),
    getData: Em.on(
        'init',
        Em.observer('session.currentUser.partnerid', function () {
            var promise;
            if (
                this.get('session.currentUser.partnerid') &&
                parseInt(this.get('session.currentUser.userlevel')) === 5
            ) {
                // get billing-data
                promise = this.get('admintool').ajax({
                    url: 'billinginfo',
                    method: 'get',
                });
                promise.then(
                    (data) => {
                        this.set('billingData', data);
                        this.set('billingtype', data['billingtype']);
                        this.set('editmode', true);
                        return $('#' + data['billingtype']).addClass('in');
                    },
                    (reason) => {
                        this.set('billingData', {});
                        this.set('billingtype', 'email');
                        return $('#email').addClass('in');
                    },
                );
                // get address-data
                promise = this.get('admintool').ajax({
                    url: 'customer_info',
                    method: 'get',
                });
                return promise.then((data) => {
                    return this.set('customerData', data);
                });
            }
        }),
    ),
    showAdminMode: Em.computed('application.impersonate', 'Ember.devModeOn', function () {
        if (Ember.get(Ember, 'devModeOn')) {
            return true;
        }
        if (this.get('application.impersonate')) {
            return true;
        }
        return false;
    }),
    actions: {
        change: function (field, value) {
            return this.set(field, value);
        },
        refreshNeeded: function () {
            this.get('settingsController').refreshNeeded();
            return false;
        },
        updateLogo: function (img) {
            //console.log img
            if (this.get('logo.isDeleted')) {
                this.set('logo', this.get('store').createRecord('logo'));
            }
            this.set('logo.logo', img);
            this.set('logoImage', false);
            this.set('saveButtonVisible', true);
            return false;
        },
        //@set 'logoImage', img.get 'thumb'
        saveLogo: function () {
            if (this.get('logo.logo') === 'remove') {
                return this.get('logo')
                    .destroyRecord()
                    .then(() => {
                        //@get('dialogs').success 'logo removed successfully'
                        return this.set('saveButtonVisible', false);
                    });
            } else {
                return this.get('logo')
                    .save()
                    .then(() => {
                        //@get('dialogs').success 'logo uploaded successfully'
                        this.set('saveButtonVisible', false);
                        return this.get('notifications').success(
                            this.get('intl').t('billing_info.logo_saved'),
                            {
                                autoClear: true,
                            },
                        );
                    });
            }
        },
        //@set 'logoImage', data.get 'logo.thumb'
        removeModal: function () {
            return this.set('showBillingTypeSelection', false);
        },
    },
});

export default CompanyProfileController;
