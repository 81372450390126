// noinspection TypeScriptValidateTypes
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { isArray } from '@ember/array';

import FormsService from './forms';
import { decamelize } from '@ember/string';

interface ICurrentUser {
    [key: string]: string;
}

export default class NeedsService extends Service {
    @service private session: any;
    @service private forms!: FormsService;

    public test(needs: string | string[]): boolean {
        if (!needs) return true;
        if (!isArray(needs)) needs = [needs];

        for (let need of needs) {
            let inverse = false;
            if (need.substring(0, 1) === '!') {
                inverse = true;
                need = need.substring(1);
            }
            const ok = inverse ? !this.testOne(need) : this.testOne(need);
            if (!ok) return false;
        }

        return true;
    }

    public testOne(need: string): boolean {
        if (need.substring(0, 9) === 'userlevel') return this.testUserlevel(need.split('=')[1]);
        if (need.includes('@')) return this.testFormPrivilege(need);
        if (need.includes('.')) return this.testFieldExistance(need);

        return false;
    }

    private testUserlevel(userlevels: string): boolean {
        const splitUserlevels = userlevels.split(',');

        return splitUserlevels.includes(
            (this.session.currentUser as ICurrentUser).userlevel.toString(),
        );
    }

    private testFormPrivilege(need: string): boolean {
        const split = need.split('@');
        const form = decamelize(split[0]);
        const privilege = split[1];

        if (!this.forms.formExists(form)) return false;

        if (privilege === 'errors') return this.forms.forms[form].errors ? true : false;
        if (!this.forms.forms[form].privileges) return false;

        return !!this.forms.forms[form].privileges![privilege];
    }

    private testFieldExistance(need: string): boolean {
        const split = need.split('.');
        return this.forms.fieldExists(split[0], split[1]);
    }
}
