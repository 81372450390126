/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/info/route.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserInfoRoute;

import Ember from 'ember';

RegisterUserInfoRoute = Ember.Route.extend({
    session: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
});

export default RegisterUserInfoRoute;
