/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSavedRoute;

import Ember from 'ember';

import SavedRoute from '../../../routes/saved';

UsersSavedRoute = SavedRoute.extend({
    templateName: 'users.saved',
    actions: {
        didTransition: function () {
            var controller;
            controller = this.get('controller');
            if (!controller) {
                return;
            }
            return controller.reset();
        },
    },
});

export default UsersSavedRoute;
