/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/project-icon/component.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectIconComponent;

import Ember from 'ember';

import config from '../../../config/environment';

ProjectIconComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    classNames: ['inline-block'],
    setProject: Em.on('didReceiveAttrs', function () {
        if (this.get('project')) {
            this.set('project', this.get('project'));
        }
    }),
    // old code that fetched project from backend everytime
    //return if @get('store').peekRecord('project', @get('project.id')).get('image.firstObject.thumb')
    //@get('store').findRecord('project', @get('project.id')).then (proj) =>
    //    @set 'project', proj
    image_url: Em.computed('project.image.[]', function () {
        if (this.get('project.image.firstObject.thumb')) {
            return Ember.String.htmlSafe(
                config.APP.COLLECTOR_API_URL + '/' + this.get('project.image.firstObject.thumb'),
            );
        }
        return false;
    }),
    text: Em.computed('project', function () {
        return this.get('project.name');
    }),
    shortText: Em.computed('text', function () {
        return this.get('text').substr(0, 1);
    }),
    color: Em.computed('text', function () {
        return this.get('Collector').randomizeColor(this.get('text'));
    }),
});

export default ProjectIconComponent;
