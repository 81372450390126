import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { getOwner } from '@ember/application';

export default Component.extend({
    ttapi: service(),
    intl: service(),
    notifications: service(),
    tagName: '',

    actions: {
        openSaldostartModal() {
            set(this, 'showSaldoStart', true);
        },
        closeSaldostartModal() {
            this.refreshSaldo();
            set(this, 'showSaldoStart', false);
        },
        saveSaldostart() {
            get(this, 'ttapi')
                ._post('/saldo/set_saldo_start', {
                    id: get(this, 'userid'),
                    startdate: get(this, 'startdate'),
                })
                .then((result) => this.showError(result));
            let enddate = moment().endOf('week').format('YYYY-MM-DD');
            enddate = moment().isBefore(enddate) ? moment().format('YYYY-MM-DD') : enddate;
            let between = get(this, 'startdate') + '_' + enddate;
            get(this, 'Collector')
                .ttapi({
                    url: 'saldo/calculateSaldos?date=' + between + '&userid=' + get(this, 'userid'),
                })
                .then(this._refreshRoute.bind(this));
        },
    },
    _refreshRoute() {
        let controller = getOwner(this).lookup('controller:application');
        let route = getOwner(this).lookup('route:' + get(controller, 'currentRouteName'));
        route.refresh();
        this.send('closeSaldostartModal');
    },
    showError(result) {
        if (result.error)
            this.notifications.error(this.intl.t('saldo_start.date_too_old'), { autoClear: true });
    },
});
