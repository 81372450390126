/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/array-input.coffee.old
// Generated by CoffeeScript 2.6.1
var ArrayInputComponent;

import Ember from 'ember';

ArrayInputComponent = Ember.Component.extend({
    makeArray: Em.on('init', function () {
        var arr, i, item, len, value;
        value = this.get('value');
        if (value) {
            if (typeof value === 'string') {
                value = JSON.parse(this.get('value'));
            }
            if (Array.isArray(value)) {
                arr = Em.A([]);
                for (i = 0, len = value.length; i < len; i++) {
                    item = value[i];
                    arr.pushObject({
                        value: parseInt(item, 10),
                    });
                }
                return this.set('array', arr);
            }
        }
    }),
    setValue: Em.observer('array.[]', 'array.@each.value', function () {
        var i, item, len, ref, value;
        value = [];
        ref = this.get('array');
        for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            if (item.value !== '') {
                value.push(item.value);
            }
        }
        return Ember.run.once(this, function () {
            return this.set('value', JSON.stringify(value));
        });
    }),
    setAddText: Em.observer('addText', function () {
        if (!this.get('addText')) {
            return this.set('addText', 'general.add');
        }
    }),
    actions: {
        add: function () {
            if (!this.get('array')) {
                this.set('array', Em.A([]));
            }
            return this.get('array').pushObject({
                value: '',
            });
        },
        remove: function (obj) {
            return this.get('array').removeObject(obj);
        },
    },
});

export default ArrayInputComponent;
