import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
const { alias } = computed;

export default Controller.extend({
    addController: controller('orientations/add'),
    init() {
        this._super(...arguments);
    },
    worksite: alias('addController.record.ori_site'),
    users: alias('addController.users'),
    content: alias('model'),

    actions: {
        async save() {
            if (!this.lockgroupid) {
                this.transitionToRoute('orientations.add.signatures');
                return;
            }
            let lock = await get(this, 'store').findRecord('lockgroup', this.lockgroupid);

            //set(this,'saving', true);
            let passesToBeSaved = [];

            for (var item of this.selectedUsers) {
                var record = get(this, 'store').createRecord('lockpermission', {
                    user: item,
                    lockgroupid: lock,
                    pass_start: this.pass_start,
                    pass_end: this.pass_end,
                });
                passesToBeSaved.pushObject(record);
                //await record.save();
            }
            set(this, 'addController.passesToBeSaved', passesToBeSaved);
            if (get(this, 'addController.record')) {
                get(this, 'addController.record').rollbackAttributes(); // do not leave any orphan records
            }
            this.transitionToRoute('orientations.add.signatures');
        },
    },
});
