/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldo/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoRoute;

import Ember from 'ember';

UsersEditSaldoRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    queryParams: {
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var enddate, startdate, user;
        user = this.modelFor('users.edit');
        startdate = Ember.get(params, 'date').split('_')[0];
        enddate = Ember.get(params, 'date').split('_')[1];
        return Ember.RSVP.hash({
            list: this.get('Collector').ttapi({
                url:
                    'saldo/get_saldo_list?startdate=' +
                    startdate +
                    '&enddate=' +
                    enddate +
                    '&userid=' +
                    user.get('id'),
            }),
            user: user,
            saldo_settings: this.get('Collector').ttapi({
                url: 'saldo/saldoSettings?userid=' + user.get('id'),
            }),
        });
    },
    actions: {
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default UsersEditSaldoRoute;
