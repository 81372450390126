import Model, { attr } from '@ember-data/model';

export default class FormReportTemplateModel extends Model {
    @attr('string') name;
    @attr('string') form;
    @attr('string') sums;
    @attr('string') sumType;
    @attr('number') limit;
    @attr('string') columns;
    @attr('string') filters;
    @attr() filterOrder;
    @attr() userlevels;
    @attr('boolean') isEditable;
}
