import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ModalDialog\n    @onClose={{fn @close}}\n    @onClickOverlay={{fn this.handleOverlayClick @outsideClose}}\n    @wrapperClassNames=\"ember-movenium-modal {{@classes}}\"\n    @translucentOverlay={{true}}\n    @overlayPosition=\"sibling\"\n>\n\n    {{yield (hash\n        Header=(component \"addon/modal/header\" hideClose=@hideClose close=@close headerClass=@headerClass)\n        Body=(component \"addon/modal/body\" bodyClass=@bodyClass)\n        Footer=(component \"addon/modal/footer\" footerClass=@footerClass)\n    )}}\n\n</ModalDialog>\n\n\n", {"contents":"<ModalDialog\n    @onClose={{fn @close}}\n    @onClickOverlay={{fn this.handleOverlayClick @outsideClose}}\n    @wrapperClassNames=\"ember-movenium-modal {{@classes}}\"\n    @translucentOverlay={{true}}\n    @overlayPosition=\"sibling\"\n>\n\n    {{yield (hash\n        Header=(component \"addon/modal/header\" hideClose=@hideClose close=@close headerClass=@headerClass)\n        Body=(component \"addon/modal/body\" bodyClass=@bodyClass)\n        Footer=(component \"addon/modal/footer\" footerClass=@footerClass)\n    )}}\n\n</ModalDialog>\n\n\n","moduleName":"tt4/components/addon/modal.hbs","parseOptions":{"srcName":"tt4/components/addon/modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface IModal {
    close(): void;
}
/* istanbul ignore next: cannot click outside modal in test */
export default class ModalComponent extends Component<IModal> {
    @action
    protected handleOverlayClick(outsideClick: boolean) {
        if (!outsideClick) return;
        return this.args.close();
    }
}
