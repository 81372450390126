/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/record-custom-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorRecordCustomCompComponent;

import Ember from 'ember';

import recordComp from '../record-comp/component';

CollectorRecordCustomCompComponent = recordComp.extend({
    recordSetter: Em.on(
        'init',
        Em.observer('form', 'record_id', function () {
            if (this.get('record')) {
                return;
            }
            return this._super();
        }),
    ),
    actions: {
        setValue: function (value, field) {
            if (!field) {
                return;
            }
            this._super(value, field);
            return this.sendAction('bubbleChange', value, field);
        },
    },
});

export default CollectorRecordCustomCompComponent;
