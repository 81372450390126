/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerController;

import Ember from 'ember';

AdmintoolCustomerController = Ember.Controller.extend({
    subMenus: [
        {
            link: 'admintool.customer.overview',
            text: 'menu.admintool.overview',
            needs: [],
        },
        {
            link: 'admintool.customer.details',
            text: 'menu.admintool.details',
            needs: [],
        },
        {
            link: 'admintool.customer.devices',
            text: 'menu.admintool.devices',
            needs: ['!userlevel=7,8'],
        },
        {
            link: 'admintool.customer.reports.bills',
            text: 'menu.admintool.bills',
            needs: ['!userlevel=7,8'],
        },
        {
            link: 'admintool.customer.reports.users',
            text: 'menu.admintool.users',
            needs: [],
        },
        {
            link: 'admintool.customer.tt3tott4',
            text: 'menu.admintool.tt3tott4',
            needs: ['!userlevel=7,8'],
        },
        {
            // this page makes mongo alerts and does not work at all because queries are so slow
            //{link: 'admintool.customer.charts', text: "menu.admintool.charts", needs: []}
            link: 'admintool.customer.history',
            text: 'menu.admintool.history',
            needs: ['!userlevel=7,8'],
        },
    ],
});

export default AdmintoolCustomerController;
