/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/calendar/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import initRoute from '../../../mixins/init-route';

WorksitediaryIndexRoute = Ember.Route.extend(AuthenticatedRouteMixin, initRoute, {
    tools: Ember.inject.service(),
    queryParams: {
        project: {
            refreshModel: true,
        },
        diary_date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        event: {
            refreshModel: true,
        },
        more: {
            refreshModel: true,
        },
    },
    more: null,
    model: function (params) {
        params.sideload = ['user.image', 'user.firstname', 'user.lastname', 'project.name'];
        delete params.page;
        // check if more-param is present, parse it correctly and finally remove it from params
        if (params.more != null) {
            params.more.split('|').forEach(function (param) {
                var split, splitted;
                splitted = param.split(':');
                if (splitted[0].includes('-')) {
                    if (Ember.isPresent(splitted[1])) {
                        split = splitted[0].split('-');
                        if (Ember.isEmpty(params[split[0]])) {
                            return (params[split[0]] = {
                                [`${split[1]}`]: splitted[1],
                            });
                        }
                    }
                } else {
                    return (params[splitted[0]] = splitted[1]);
                }
            });
            this.set('more', params.more);
        } else {
            this.set('more', null);
        }
        if (params != null && params['more']) {
            delete params['more'];
        }
        if (params.event) {
            delete params.event;
            this.store.query('worksitediary', params);
            return this.store.peekAll('worksitediary');
        } else {
            delete params.event;
            this.store.query('diary', params);
            return this.store.peekAll('diary');
        }
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        if (this.get('more')) {
            return this.get('more')
                .split('|')
                .forEach(function (param) {
                    var splitted;
                    splitted = param.split(':');
                    return controller.set(splitted[0], splitted[1]);
                });
        }
    },
});

export default WorksitediaryIndexRoute;
