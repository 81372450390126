/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/collector-format.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFormatHelper;

import Ember from 'ember';

CollectorFormatHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    compute: function (params, hash) {
        var c, field, form, fuckTheseImageModals, model, ref, value;
        c = this.get('Collector');
        field = hash.field;
        form = hash.form;
        model = hash.model;
        fuckTheseImageModals = hash.fuckTheseImageModals || false;
        value = false;
        if (hash.formatter) {
            value = hash.formatter(
                field.ext || field.name,
                model.get(field.ext || field.name),
                null,
                c.format(model.get(field.ext || field.name), field, null, model, false, form),
            );
        }
        if (value) {
            return value;
        } else if ((ref = Ember.typeOf(field)) === 'object' || ref === 'instance') {
            return c.format(
                model.get(field.ext || field.name),
                field,
                null,
                model,
                false,
                form,
                false,
                fuckTheseImageModals,
            );
        } else {
            return c._format_(model, form, field);
        }
    },
});

export default CollectorFormatHelper;
