import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

const CollectorFormatHelper = Helper.extend({
    Collector: service('collector-service'),
    compute(params) {
        const c = get(this, 'Collector');
        return c.shortFormat(params[0], params[1] || 2);
    },
});

export default CollectorFormatHelper;
