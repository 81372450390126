/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/help-dialog.coffee.old
// Generated by CoffeeScript 2.6.1
var HelpDialogComponent;

import Ember from 'ember';

HelpDialogComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    loadText: function () {
        var promise;
        promise = this.get('Collector').ttapi({
            method: 'get',
            url: 'get_translation',
            data: {
                translation_key: this.get('translation_key'),
                lang: this.get('intl.primaryLocale'),
            },
        });
        return promise.then((back) => {
            return this.set('content', new Ember.String.htmlSafe(back.string));
        });
    },
    actions: {
        show_help: function () {
            this.loadText();
            return this.set('show_dialog', true);
        },
        removeModal: function () {
            $('.modal').modal('hide');
            return this.set('show_dialog', false);
        },
    },
});

export default HelpDialogComponent;
