import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}\n\n{{this.message}}\n\n{{#if (await this.hittingWorktimes)}}\n    {{#each this.hittingWorktimes as |worktime|}}\n        <br /> - {{worktime.project.name}} {{worktime.starttime}} - {{worktime.endtime}}\n    {{/each}}\n{{/if}}\n\n{{#if (await this.hittingAbsences)}}\n    {{#each this.hittingAbsences as |absence|}}\n        <br /> - {{absence.abcensetype.name}} {{moment-format absence.startdate \"L\"}} - {{moment-format absence.enddate \"L\"}}\n    {{/each}}\n{{/if}}\n\n{{#if (await this.hittingComps)}}\n    {{#each this.hittingComps as |worktime|}}\n        <br /> - {{worktime.project.name}} {{moment-format worktime.date \"L\"}}\n    {{/each}}\n{{/if}}", {"contents":"{{yield}}\n\n{{this.message}}\n\n{{#if (await this.hittingWorktimes)}}\n    {{#each this.hittingWorktimes as |worktime|}}\n        <br /> - {{worktime.project.name}} {{worktime.starttime}} - {{worktime.endtime}}\n    {{/each}}\n{{/if}}\n\n{{#if (await this.hittingAbsences)}}\n    {{#each this.hittingAbsences as |absence|}}\n        <br /> - {{absence.abcensetype.name}} {{moment-format absence.startdate \"L\"}} - {{moment-format absence.enddate \"L\"}}\n    {{/each}}\n{{/if}}\n\n{{#if (await this.hittingComps)}}\n    {{#each this.hittingComps as |worktime|}}\n        <br /> - {{worktime.project.name}} {{moment-format worktime.date \"L\"}}\n    {{/each}}\n{{/if}}","moduleName":"tt4/components/addon/collector-error-message.hbs","parseOptions":{"srcName":"tt4/components/addon/collector-error-message.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import ErrorHandler from 'tt4/injections/services/error-handler';
import DS from 'ember-data';
import { IError } from 'tt4/ember-movenium/interfaces/error';
import { IRecord } from 'tt4/ember-movenium/interfaces/record';

interface IErrorMessageComponent {
    error: IError;
}

export default class CollectorErrorMessageComponent extends Component<IErrorMessageComponent> {
    @service private errorHandler!: ErrorHandler;
    @service private store!: DS.Store;

    get parsedError(): IError {
        return this.errorHandler.toError(this.args.error) as IError;
    }

    get message(): string {
        return this.errorHandler.errorToString(this.args.error);
    }

    // We use computeds here becuase we have to cache the ArrayPromiseProxy that the store query returns.
    // If we don't, these getters will go into an infinite loop. This should be converted over to @cached if/when it is supported by Ember
    @computed('parsedError')
    get hittingWorktimes(): DS.PromiseArray<IRecord> | null {
        if (this.parsedError?.meta?.hits === 'worktime') {
            return this.store.query('worktime', {
                id: this.parsedError!.meta.ids.join(','),
                sideload: true,
            });
        } else {
            return null;
        }
    }

    @computed('parsedError')
    get hittingAbsences(): DS.PromiseArray<IRecord> | null {
        if (this.parsedError?.meta?.hits === 'absence') {
            return this.store.query('abcense', {
                id: this.parsedError!.meta.ids.join(','),
                sideload: true,
            });
        } else {
            return null;
        }
    }

    @computed('parsedError')
    get hittingComps(): DS.PromiseArray<IRecord> | null {
        if (this.parsedError?.meta?.hits === 'comp') {
            return this.store.query('worktime', {
                id: this.parsedError!.meta.ids.join(','),
                sideload: true,
            });
        } else {
            return null;
        }
    }
}
