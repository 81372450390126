/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/thereport/form-reporteditor/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FormReporteditorComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

FormReporteditorComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    showSaveButton: Ember.computed.or('settings.form', 'settings.custom'),
    rowsPerPageOptions: Em.computed('settings.form', function () {
        return Em.A([
            Em.Object.create({
                id: 25,
                name: '25',
            }),
            Em.Object.create({
                id: 50,
                name: '50',
            }),
            Em.Object.create({
                id: 100,
                name: '100',
            }),
        ]);
    }),
    forms: Ember.computed(function () {
        var c, forms, intl, ret, supportedForms;
        supportedForms = ['worktime', 'abcense', 'bill', 'presence', 'materiallog'];
        c = this.get('Collector');
        intl = this.get('intl');
        forms = c.forms();
        ret = Em.A([]);
        $.each(forms, (key, item) => {
            if (!(indexOf.call(supportedForms, key) >= 0 || item.name)) {
                return;
            }
            if (item.name) {
                return ret.pushObject(
                    Ember.Object.create({
                        id: key,
                        name: intl.t(item.name),
                    }),
                );
            } else {
                return ret.pushObject(
                    Ember.Object.create({
                        id: key,
                        name: intl.t('forms.' + key),
                    }),
                );
            }
        });
        return ret;
    }),
    columns: Ember.computed('settings.form', 'groupingIsOn', function () {
        var c, columns, form, groupableColumnsList, groupingIsOn, intl, ret;
        groupableColumnsList = [
            'date',
            'number',
            'hours',
            'price',
            'big_price',
            'dropdown_values',
            'dropdown_options',
        ];
        c = this.get('Collector');
        form = this.get('settings.form');
        intl = this.get('intl');
        groupingIsOn = this.get('groupingIsOn');
        if (!form || !c.formExists(form)) {
            return Em.A([]);
        }
        columns = c.fields(form);
        ret = Em.A([]);
        ret.push(
            Ember.Object.create({
                id: 'id',
                name: 'id',
            }),
        );
        $.each(columns, (key, item) => {
            var ref;
            if (
                groupingIsOn &&
                !((ref = item.type), indexOf.call(groupableColumnsList, ref) >= 0)
            ) {
                return;
            }
            return ret.pushObject(
                Ember.Object.create({
                    id: key,
                    name: c.getTranslationForColumn(form, key),
                }),
            );
        });
        return ret;
    }),
    groupingIsOn: Ember.computed.gt('group_columns.length', 0),
    groupByColumns: Ember.computed('settings.form', function () {
        var c, columns, form, groupByColumnsList, ret_arr;
        groupByColumnsList = ['database', 'dropdown_values', 'dropdown_options', 'date'];
        c = this.get('Collector');
        form = this.get('settings.form');
        if (!form || !c.formExists(form)) {
            return [];
        }
        columns = c.fields(form);
        ret_arr = Em.A([]);
        $.each(columns, function (key, item) {
            var ref;
            if (((ref = item.type), indexOf.call(groupByColumnsList, ref) >= 0)) {
                return ret_arr.pushObject(
                    Ember.Object.create({
                        id: key,
                        name: c.getTranslationForColumn(form, key),
                    }),
                );
            }
        });
        ret_arr.pushObject(
            Ember.Object.create({
                id: 'all',
                name: this.get('intl').t('general.sum_all'),
            }),
        );
        return ret_arr;
    }),
    saveValues: Em.on('init', function () {
        var settings;
        settings = Em.Object.create({
            calendar: this.get('settings.calendar'),
            editorMode: false,
            form: this.get('settings.form'),
            group: this.get('settings.group'),
            half: this.get('settings.half'),
            header: this.get('settings.header'),
            show_columns: this.get('settings.show_columns'),
            rowsPerPage: this.get('settings.rowsPerPage'),
        });
        return this.set('originalSettings', settings);
    }),
    columnsObserver: Ember.observer('show_columns', function () {
        var columns;
        columns = this.get('show_columns');
        return this.set('settings.show_columns', columns.join(','));
    }),
    groupObserver: Ember.observer('group_columns', function () {
        var columns;
        columns = this.get('group_columns');
        return this.set('settings.group', columns.join(','));
    }),
    rowsPerPageObserver: Ember.observer('rowsPerPage', function () {
        var rowsPerPage;
        rowsPerPage = this.get('rowsPerPage.firstObject');
        return this.set('settings.rowsPerPage', rowsPerPage);
    }),
    show_columns: Ember.computed('settings.show_columns', function () {
        var show_columns;
        show_columns = this.get('settings.show_columns');
        if (show_columns) {
            return show_columns.split(',');
        } else {
            return [];
        }
    }),
    group_columns: Ember.computed('settings.group', function () {
        var group;
        group = this.get('settings.group');
        if (group) {
            return group.split(',');
        } else {
            return [];
        }
    }),
    rowsPerPage: Ember.computed('settings.rowsPerPage', function () {
        var rowsPerPage;
        rowsPerPage = this.get('settings.rowsPerPage');
        if (rowsPerPage) {
            return [rowsPerPage];
        } else {
            return [25];
        }
    }),
    actions: {
        save: function () {
            var settings;
            settings = this.get('settings');
            if (!settings.show_columns) {
                settings.show_columns = 'empty';
            }
            if (this.get('save')) {
                return this.save(settings);
            }
        },
        remove: function () {
            if (this.get('remove')) {
                return this.remove(this.get('settings'));
            }
        },
        cancel: function () {
            var settings;
            if (this.get('cancel')) {
                this.cancel(this.get('settings'));
            }
            settings = this.get('originalSettings');
            if (settings) {
                this.set('settings.calendar', settings.calendar);
                this.set('settings.form', settings.form);
                this.set('settings.group', settings.group);
                this.set('settings.half', settings.half);
                this.set('settings.header', settings.header);
                this.set('settings.show_columns', settings.show_columns);
                return this.set('settings.rowsPerPage', settings.rowsPerPage);
            }
        },
    },
});

export default FormReporteditorComponent;
