/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/my-sites/add-image-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var MySitesAddImageComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import { resizeImage } from 'tt4/utils/file-util';

import $ from 'jquery';

MySitesAddImageComponent = Ember.Component.extend({
    tagName: 'span',
    store: Ember.inject.service(),
    project_image_observer: Em.observer('file', function () {
        var project, projectImage;
        project = this.get('project');
        projectImage = this.get('file');
        return this.get('store')
            .findRecord('project', project.id)
            .then((record) => {
                var images;
                images = record.get('image');
                //do not delete old images if there are already multiple images
                if (images.length > 1) {
                    //put new image as default (first in array)
                    images.unshiftObject(projectImage[0]);
                    projectImage = images;
                }
                if (projectImage === null) {
                    record.set('image', '');
                } else {
                    record.set('image', projectImage);
                }
                return record.save().then(() => {
                    if (this.get('updateImage')) {
                        return this.updateImage();
                    }
                });
            });
    }),
    data: Em.A([]),
    change: function (e) {
        var file, i, len, ref, results, self;
        self = this;
        this.set('data', Em.A([]));
        ref = e.target.files;
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
            file = ref[i];
            results.push(self.readFile(file));
        }
        return results;
    },
    readFile: function (file) {
        var acceptedTypes, file_data, reader, ref, self;
        self = this;
        acceptedTypes = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'];
        this.set('error', null);
        if (((ref = file.type), indexOf.call(acceptedTypes, ref) >= 0)) {
            file_data = {};
            file_data.name = file.name;
            return resizeImage(file, 960, 960, 80).then((resized) => {
                file_data.file = resized;
                return resizeImage(resized, 200, 200, 80).then((ersized) => {
                    var data;
                    file_data.preview = resized;
                    data = self.get(data);
                    data.pushObject(file_data);
                    return self.set('file', data);
                });
            });
        } else {
            reader = new FileReader();
            reader.onloadend = function (e) {
                var api_format, dataURL, parse_file;
                dataURL = e.target.result;
                parse_file = dataURL.split(',');
                api_format = {
                    data: parse_file[1],
                    name: file.name,
                };
                return self.set('file', api_format);
            };
            return reader.readAsDataURL(file);
        }
    },
    actions: {
        addFile: function () {
            return $(this.get('element')).find('.file_input').click();
        },
    },
});

export default MySitesAddImageComponent;
