import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class NotificationCenterComponent extends Component {
    @service('collector-service') collector;
    @service intl;
    @service ttapi;
    @service customerSettings;
    @service session;

    @tracked updateThese;
    @tracked notificationSettings;

    get styleNamespace() {
        return podNames['settings/general-v2/components/notification-center'];
    }

    getNotificationChannels(channel) {
        let channels = A();
        switch (channel) {
            case 'notification':
                channels.pushObject(this.intl.t('widget.anomalities.push'));
                break;
            case 'email':
                channels.pushObject(this.intl.t('widget.anomalities.email'));
                break;
            case 'sms':
                channels.pushObject(this.intl.t('widget.anomalities.sms'));
                break;
            case 'tt4':
                channels.pushObject(this.intl.t('settings.notification-center.in-service-message'));
                break;
        }
        return channels;
    }

    async getNotificationSettings() {
        let retArr = A();
        let splittedChannels = [];

        let settings = await this.ttapi._get('notification/categories');

        let push_time = await this.customerSettings.checkSetting(
            'push_notification_time_hours_' + this.session.currentUser.company_info.country,
        );

        let extraSettings = [
            {
                type:
                    'push_notification_time_hours_' + this.session.currentUser.company_info.country,
                active: push_time ? true : false,
                translation: 'settings.notifications.use_missing_hours',
                help:
                    'notification.' +
                    'push_notification_time_hours_' +
                    this.session.currentUser.company_info.country.toLowerCase() +
                    '.sending-time',
                channel: 'notification',
                customTime: push_time ? push_time : '16:00',
                custom: true,
            },
        ];

        settings.pushObjects(extraSettings);

        settings.forEach((setting) => {
            if (!setting.needs || (setting.needs && this.collector.testNeeds(setting.needs))) {
                set(
                    setting,
                    'help',
                    'notification.' + setting.type.toLowerCase() + '.sending-time',
                );
                splittedChannels = setting.channel.split(',');
                let channels = [];
                for (var channel of splittedChannels) {
                    channels.pushObject(this.getNotificationChannels(channel));
                }
                set(setting, 'channel', channels.join(', '));
                if (setting.customTime) {
                    set(setting, 'customTime', this.getCustomTime(setting.customTime));
                    set(setting, 'hasCustomTime', true);
                }
                // unapproved_worktimes customer-setting used to be its own customer-setting but now we combine it with this notification-setting
                if (setting.type == 'unapprovedworktimes') {
                    set(setting, 'hasCustomTime', true);
                    let unaprrovedSetting =
                        this.customerSettings.checkSetting('unapproved_worktimes');
                    if (!unaprrovedSetting) {
                        set(setting, 'active', false);
                        set(setting, 'customTime', '16:00');
                    } else {
                        set(setting, 'customTime', this.getCustomTime(unaprrovedSetting));
                    }
                }
                retArr.pushObject(setting);
            }
        });
        return retArr;
    }

    get isDisabled() {
        return this.updateThese.length == 0;
    }

    async setNotificationSettings() {
        this.notificationSettings = await this.getNotificationSettings();
    }

    constructor() {
        super(...arguments);
        //this.setNotificationSettings();
        this.notificationSettings = this.getNotificationSettings();
        this.updateThese = A();
    }

    @action
    async save() {
        for (var setting of this.updateThese) {
            // we need to update customer-setting also here
            if (setting.type == 'unapprovedworktimes')
                await this.customerSettings.setSetting(
                    'unapproved_worktimes',
                    setting.active ? this.setCustomTime(setting.customTime) : 'off',
                );
            if (setting.custom)
                await this.customerSettings.setSetting(
                    setting.type,
                    setting.active ? this.setCustomTime(setting.customTime) : 'off',
                );
            else await this.saveNotification(setting);
        }
        this.updateThese = A();
        this.args.settingSaved();
    }

    @action
    setSetting(setting, state) {
        set(setting, 'active', state);
        if (!this.updateThese.includes(setting)) {
            this.updateThese.pushObject(setting);
        }
    }

    saveNotification(notification) {
        let date = moment().format('YYYY-MM-DD');
        let time = notification.customTime ? notification.customTime : null;

        let customTime = time
            ? moment(date + ' ' + time + ':00')
                  .utc()
                  .format('HH:mm')
            : null;

        return this.ttapi._post('notification/update', {
            notification: notification.type,
            active: notification.active,
            customTime: customTime,
        });
    }

    setCustomTime(customTime) {
        if (!customTime) return null;
        const utctime = customTime;
        const date = moment().format('YYYY-MM-DD');
        return moment(date + ' ' + utctime + ':00')
            .utc()
            .format('HH:mm');
    }

    getCustomTime(customTime) {
        if (!customTime) return null;
        const utctime = customTime;
        const date = moment().format('YYYY-MM-DD');
        return moment(date + ' ' + utctime + ':00Z').format('HH:mm');
    }
}
