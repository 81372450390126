import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';
import { assert } from '@ember/debug';
import { typeOf } from '@ember/utils';

export default Component.extend({
    intl: service(),
    store: service(),
    gui: service(),

    year: computed('date', function () {
        const date = this.date.split('_')[0];
        return moment(date).year();
    }),

    months: computed('workdayDays', function () {
        if (!this.workdayDays) return;
        let months = [];
        const midweekHolidayPayOn = this.Collector.testNeedsOne('products.midweek_holiday_pay');
        for (let i = 1; i < 13; i++) {
            const number = i;

            const month = moment(i, 'MM').year(this.year).startOf('month');
            const minDate = month.toDate();
            // "center of the month" date is formed by adding at least 1 day to start of month
            // to make sure we're inside the correct month regardless of timezones
            const center = month.add(1, 'day').toDate();
            const maxDate = month.endOf('month').toDate();

            const filteredDays = this.workdayDays.filter(
                (day) => moment(day.date).month() + 1 === i,
            );
            let days = {};
            let specialDays = [];

            filteredDays.forEach((day) => {
                let dayObj = {
                    minutes: get(day, 'minutes'),
                    own: get(day, 'own'),
                    absence_minutes:
                        day.absence_minutes == null ? day.minutes : day.absence_minutes,
                };
                if (midweekHolidayPayOn)
                    dayObj['midweek_holiday_pay'] = get(day, 'midweek_holiday_pay');
                days[get(day, 'date')] = dayObj;
                if (day.minutes == 0) specialDays.push(day.date);
            });
            months.push({ number, center, days, minDate, maxDate, specialDays });
        }

        return months;
    }),

    init() {
        this._super();
        assert(
            'Workday-days component needs workdays (array or instance)! Give me @workdayDays={{this.workdayDays}}',
            typeOf(this.workdayDays) === 'array' || typeOf(this.workdayDays) === 'instance',
        );
    },

    actions: {
        selectDay(date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');

            const dayRule = this.workdayDays.find((rule) => {
                return rule.date === formattedDate;
            });

            this.showDayRule(formattedDate, dayRule);
        },

        changeYear(amount) {
            this.changeYear(this.year + amount);
        },
    },
});
