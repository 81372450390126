/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/data/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMeasurementDataController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

import formattedMixin from 'tt4/mixins/sitesafety-formatted-export';

import { computed } from '@ember/object';

import { inject as service } from '@ember/service';

SiteSafetyMeasurementDataController = GeneralReportController.extend(formattedMixin, {
    //    needs: 'site-safety/measurement'
    flags: service(),
    measurementController: Ember.inject.controller('site-safety/measurement'),
    queryParams: ['project', 'date', 'page', 'groupBy'],
    project: null,
    date: moment().subtract(3, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    form: Ember.computed.alias('measurementController.form'),
    init: function () {
        this._super();
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            return this.addRowActions();
        }
    },
    filters: computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'date',
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
        ]);
    }),
    // Em.Object.create({field: "groupBy", type: "groupByFilter"})
    formatter: function (column, value) {
        if (column === 'tr_rating') {
            if (value > 95) {
                return new Ember.String.htmlSafe(
                    '<span class="label-m label-m-approved">' + value + ' %</span>',
                );
            } else if (value > 90) {
                return new Ember.String.htmlSafe(
                    '<span class="label-m label-m-warning">' + value + ' %</span>',
                );
            } else {
                return new Ember.String.htmlSafe(
                    '<span class="label-m label-m-open">' + value + ' %</span>',
                );
            }
        }
        return false;
    },
    showColumns: Ember.computed(function () {
        var columns, fields, key;
        fields = this.Collector.fields(this.get('form'));
        columns = [];
        for (key in fields) {
            if (key.substring(key.length - 6) !== '_minus') {
                columns.push(key);
            }
        }
        return columns;
    }),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideEdit: true,
            hideHistory: true,
        }),
        sortByColumn: 'date-',
    }),
    addRowActions: function () {
        if (this.Collector == null) {
            return;
        }
        return this.set('settings.rowActions', [
            {
                text: 'site_safety.formatted_pdf_export',
                action: (self, items) => {
                    var fields;
                    fields = this.Collector.fields(this.get('form'));
                    return this.createMeasurementPdf(fields, items);
                },
            },
        ]);
    },
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        createSpecialPdf: function (selected) {
            var fields;
            fields = this.Collector.fields(this.get('form'));
            return this.createMeasurementPdf(fields, selected);
        },
    },
});

export default SiteSafetyMeasurementDataController;
