/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/signatures/route.coffee.old
// Generated by CoffeeScript 2.6.1
var signaturesRoute;

import Ember from 'ember';

import OrientationsSubRoute from '../sub/route';

signaturesRoute = OrientationsSubRoute.extend();

export default signaturesRoute;
