/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/trnotices/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TrnoticesEditController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

TrnoticesEditController = GeneralAddController.extend({
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
});

export default TrnoticesEditController;
