import Component from '@ember/component';

export default Component.extend({
    tagName: '',
    actions: {
        handleRemove(action) {
            this.handleRemove(action);
        },
    },
});
