import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { get } from '@ember/object';

const MachinesTransactionTooltypeController = Controller.extend({
    content: alias('model'),
    actions: {
        moveMachines(transaction) {
            const project = transaction.project;
            if (project) {
                return this.transitionToRoute(
                    'machines.transaction.move',
                    get(transaction, 'tooltype.id'),
                    project.id,
                );
            } else {
                return this.transitionToRoute(
                    'machines.transaction.move',
                    get(transaction, 'tooltype.id'),
                    '(empty)',
                );
            }
        },
    },
});

export default MachinesTransactionTooltypeController;
