/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/machine-map/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import $ from 'jquery';

Component = Ember.Component.extend({
    store: Ember.inject.service(),
    mapMarkers: Ember.A([]),
    didInsertElement: function () {
        var container, lat, latlng, lng, map, options;
        container = $('.canvas', this.element)[0];
        if (moment.locale() === 'fi') {
            lat = 64.8929581;
            lng = 26.0218001;
        } else {
            lat = 63.6532593;
            lng = 18.7268782;
        }
        latlng = new window.google.maps.LatLng(lat, lng);
        options = {
            center: latlng,
            zoom: 5,
        };
        map = new window.google.maps.Map(container, options);
        this.set('map', map);
        return this.addMachines();
    },
    //map.addListener 'zoom_changed', =>
    //    @addMachines()
    getLatLng: function (machine) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var json, parsed;
            json = machine.get('project.location_map');
            if (typeof json === 'undefined') {
                return this.get('store')
                    .findRecord('project', machine.get('project.id'), {
                        reload: true,
                    })
                    .then(function (project) {
                        var parsed;
                        json = project.get('location_map');
                        if (!json) {
                            reject();
                            return;
                        }
                        parsed = JSON.parse(json);
                        return resolve({
                            lat: parsed.latitude,
                            lng: parsed.longitude,
                        });
                    });
            } else if (json) {
                parsed = JSON.parse(json);
                return resolve({
                    lat: parsed.latitude,
                    lng: parsed.longitude,
                });
            } else {
                return reject();
            }
        });
    },
    observeModel: Ember.observer('machines.[]', function () {
        this.get('markers').forEach((marker) => {
            return marker.setMap(null);
        });
        this.set('markers', []);
        return this.addMachines();
    }),
    markers: [],
    addMachines: function () {
        var biggest_amount, smallest_amount;
        biggest_amount = 0;
        smallest_amount = 100000;
        this.get('machines').forEach((machine) => {
            if (biggest_amount < machine.get('amount')) {
                biggest_amount = machine.get('amount');
            }
            if (smallest_amount > machine.get('amount')) {
                return (smallest_amount = machine.get('amount'));
            }
        });
        return this.get('machines').forEach((machine) => {
            var scaleMultiplier;
            if (biggest_amount === smallest_amount) {
                scaleMultiplier = 2;
            } else {
                scaleMultiplier =
                    (3 / (biggest_amount - smallest_amount)) *
                        (machine.get('amount') - smallest_amount) +
                    1;
            }
            return this.getLatLng(machine).then((position) => {
                return this.get('markers').push(
                    new google.maps.Marker({
                        position: position,
                        map: this.get('map'),
                        title: machine.get('project.name') + ' - ' + machine.get('amount'),
                        //animation: google.maps.Animation.DROP
                        label: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            text: '' + machine.get('amount'),
                        },
                        icon: {
                            strokeColor: '#0c9d58',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                            fillColor: '#D8FFFF',
                            fillOpacity: 0.5,
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 10 * scaleMultiplier,
                            anchor: new google.maps.Point(0, 0),
                        },
                    }),
                );
            });
        });
    },
    actions: {
        test: function () {
            return this.get('map').setCenter({
                lat: 60,
                lng: 26,
            });
        },
    },
});

export default Component;
