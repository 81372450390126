/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/realtime/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerRealtimeController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import collector from '../../../classes/collector';

TimetrackerRealtimeController = GeneralReportController.extend({
    showColumns: ['user', 'user.taxnumber', 'user.personid', 'project', 'starttime', 'date'],
    filters: Em.A([
        Em.Object.create({
            field: 'project',
        }),
    ]),
    form: 'worktime',
    queryParams: ['page', 'project', 'user', 'employer'],
    project: null,
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: 'timetracker.edit',
            historyRoute: 'timetracker.history',
            hideCheckbox: true,
        }),
    }),
    init_functions: function () {
        this._super();
        return this.hideColumns();
    },
    hideColumns: function () {
        var fields;
        fields = collector.getFormObjects('worktime');
        if (
            fields.starttime.features.hide &&
            fields.endtime.features.hide &&
            fields.work_hours.features.mandatory
        ) {
            return this.set('settings.hideColumns', ['starttime', 'endtime']);
        }
    },
});

export default TimetrackerRealtimeController;
