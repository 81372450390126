/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/integration-settings/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

Component = Ember.Component.extend({
    Collector: Em.inject.service('collector-service'),
    store: Ember.inject.service(),
    didReceiveAttrs: function () {
        if (this.get('Collector').productExists('products.netvisor_salary_rest')) {
            return this.get('store')
                .query('integrationsetting', {
                    integration: 'general-netvisor-salary-rest',
                })
                .then((settings) => {
                    if (settings.get('content').length > 1) {
                        this.set('cid', settings.findBy('key', 'cid').get('value'));
                        return this.set(
                            'customerID',
                            settings.findBy('key', 'customerID').get('value'),
                        );
                    }
                });
        } else if (this.get('Collector').productExists('products.fivaldi_salary_soap')) {
            return this.get('store')
                .query('integrationsetting', {
                    integration: 'general-fivaldi-salary-soap',
                })
                .then((settings) => {
                    if (settings.get('content').length > 1) {
                        this.set('businessid', settings.findBy('key', 'businessid').get('value'));
                        this.set('schema', settings.findBy('key', 'schema').get('value'));
                        return this.set(
                            'followupID',
                            settings.findBy('key', 'followupID').get('value'),
                        );
                    }
                });
        }
    },
    followupIDs: ['1', '2', '3', '4'],
    showSaveButton: Ember.computed('showModal', function () {
        return ['netvisor', 'fivaldi'].includes(this.get('showModal'));
    }),
    fivaldiSaveSettings: function () {
        var businessid,
            followupID,
            row,
            row1,
            row2,
            schema,
            setting_businessid,
            setting_followupID,
            setting_schema,
            settings;
        ({ businessid, schema, followupID } = this.getProperties(
            'businessid',
            'schema',
            'followupID',
        ));
        if (Em.isEmpty(businessid) || Em.isEmpty(schema) || Em.isEmpty(followupID)) {
            this.set('settingsError', 'fivaldi_soap');
            Ember.run.later(
                this,
                function () {
                    return this.set('settingsError', null);
                },
                4000,
            );
            return;
        }
        settings = this.get('store').peekAll('integrationsetting');
        setting_businessid = settings.findBy('key', 'businessid');
        if (setting_businessid) {
            row = setting_businessid;
            row.set('value', businessid);
        } else {
            row = this.get('store').createRecord('integrationsetting', {
                integration: 'general-fivaldi-salary-soap',
                key: 'businessid',
                value: businessid,
            });
        }
        setting_schema = settings.findBy('key', 'schema');
        if (setting_schema) {
            row1 = setting_schema;
            row1.set('value', schema);
        } else {
            row1 = this.get('store').createRecord('integrationsetting', {
                integration: 'general-fivaldi-salary-soap',
                key: 'schema',
                value: schema,
            });
        }
        setting_followupID = settings.findBy('key', 'followupID');
        if (setting_followupID) {
            row2 = setting_followupID;
            row2.set('value', followupID);
        } else {
            row2 = this.get('store').createRecord('integrationsetting', {
                integration: 'general-fivaldi-salary-soap',
                key: 'followupID',
                value: followupID,
            });
        }
        return Em.A([row, row1, row2]).invoke('save');
    },
    netvisorSaveSettings: function () {
        var cid, customerID, row, row1, setting_cid, setting_customerID, settings;
        ({ cid, customerID } = this.getProperties('cid', 'customerID'));
        if (Em.isEmpty(cid) || Em.isEmpty(customerID)) {
            this.set('settingsError', 'netvisor_rest');
            Ember.run.later(
                this,
                function () {
                    return this.set('settingsError', null);
                },
                4000,
            );
            return;
        }
        settings = this.get('store').peekAll('integrationsetting');
        setting_cid = settings.findBy('key', 'cid');
        if (setting_cid) {
            row = setting_cid;
            row.set('value', cid);
        } else {
            row = this.get('store').createRecord('integrationsetting', {
                integration: 'general-netvisor-salary-rest',
                key: 'cid',
                value: cid,
            });
        }
        setting_customerID = settings.findBy('key', 'customerID');
        if (setting_customerID) {
            row1 = setting_customerID;
            row1.set('value', customerID);
        } else {
            row1 = this.get('store').createRecord('integrationsetting', {
                integration: 'general-netvisor-salary-rest',
                key: 'customerID',
                value: customerID,
            });
        }
        return Em.A([row, row1]).invoke('save');
    },
    actions: {
        saveSettings: function (integration) {
            return Ember.RSVP.Promise.all(this[integration + 'SaveSettings']()).then(() => {
                if (this.get('save')) {
                    return this.save();
                }
            });
        },
        close: function () {
            if (this.get('close')) {
                return this.close();
            }
        },
    },
});

export default Component;
