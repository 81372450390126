import Component from '@ember/component';
import { get } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Component.extend({
    classNames: ['widget-thumbnail'],
    attributeBindings: ['draggable', 'data-name'],
    draggable: true,
    'data-name': alias('widget.name'),
    dragStart(event) {
        event.dataTransfer.setData('text/data', get(this, 'widget.name'));
        window.Tt4.draggedWidgetType = 'widgetIcon';
    },
});
