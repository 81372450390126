/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/addnotice/mvr/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyAddnoticeMvrController;

import Ember from 'ember';

import GeneralAddController from '../../../../controllers/general-add';

SiteSafetyAddnoticeMvrController = GeneralAddController.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    intl: Em.inject.service(),
    customization: {
        type: {
            hide: true,
            features: {
                default_value: 'tr',
            },
        },
        date: {
            translation: 'site_safety.notice.fixdate',
            features: {
                default_value: moment().add(3, 'days').format('YYYY-MM-DD'),
            },
        },
        user: {
            translation: 'site_safety.notice.person',
        },
        email: {
            translation: 'site_safety.notice.send_email',
        },
    },
    actions: {
        save: function () {
            var record;
            record = this.get('model');
            return record.validate().then(() => {
                var email, projectString, userString;
                if (!record.get('isValid')) {
                    this.set('submitted', true);
                    this.set('spinIsLoading', false);
                    return;
                }
                if (record.get('email')) {
                    userString = '';
                    if (this.get('session.currentUser.firstname')) {
                        userString += this.get('session.currentUser.firstname') + ' ';
                    }
                    if (this.get('session.currentUser.lastname')) {
                        userString += this.get('session.currentUser.lastname') + '\n';
                    }
                    if (this.get('session.currentUser.email')) {
                        userString += this.get('session.currentUser.email') + '\n';
                    }
                    if (this.get('session.currentUser.phone')) {
                        userString += this.get('session.currentUser.phone') + '\n';
                    }
                    projectString = '';
                    if (record.get('project.number')) {
                        projectString += record.get('project.number') + ' ';
                    }
                    projectString += record.get('project.name');
                    email = {
                        email: record.get('email'),
                        title:
                            this.get('session.currentUser.company') +
                            ' - ' +
                            this.get('intl').t('site_safety.notice.email_content.title'),
                        lang: this.get('intl.primaryLocale'),
                        content:
                            this.get('intl').t('site_safety.notice.email_content.content') +
                            '\n' +
                            this.get('intl').t('site_safety.notice.project') +
                            ': ' +
                            projectString +
                            '\n' +
                            this.get('intl').t('site_safety.notice.desc') +
                            ': ' +
                            record.get('desc') +
                            '\n' +
                            this.get('intl').t('site_safety.notice.noticedesc') +
                            ': ' +
                            record.get('noticedesc') +
                            '\n' +
                            this.get('intl').t('site_safety.notice.person') +
                            ': ' +
                            record.get('user.firstname') +
                            ' ' +
                            record.get('user.lastname') +
                            '\n' +
                            this.get('intl').t('site_safety.notice.fixdate') +
                            ': ' +
                            moment(record.get('date')).format('L') +
                            '\n\n' +
                            userString,
                    };
                    if (record.get('pic')) {
                        email.pic = {
                            name: record.get('pic').name,
                            data: record.get('pic').data,
                        };
                    }
                    record.set('email_content', email);
                }
                record.set('creator', this.get('session.currentUser'));
                record.set('type', 'mvr');
                return record
                    .save()
                    .then(() => {
                        return this.transitionToRoute('site_safety.report.mvr.notices');
                    })
                    .catch((back) => {
                        this.set('submitted', true);
                        this.set('spinIsLoading', false);
                        return this.set('validationMessageData', back.errors);
                    });
            });
        },
    },
});

export default SiteSafetyAddnoticeMvrController;
