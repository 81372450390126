/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/diary-events/event-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddEventComponentComponent;

import Ember from 'ember';

import collectorField from '../../../../../collector/record-comp/component';

import config from '../../../../../../config/environment';

import $ from 'jquery';

WorksitediaryReportAddEventComponentComponent = collectorField.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    GpsLocator: Ember.inject.service(),
    setup: Em.on('init', function () {
        var model;
        model = this.get('record');
        if (model !== null) {
            if (!model.get('id')) {
                this.GetGPSCoords();
            }
            model.set('date', moment().format('YYYY-MM-DD'));
            return model.set('project', this.get('project'));
        }
    }),
    weather: null,
    skip: ['rating', 'project', 'weather'],
    updateRatingVisualization: Em.on('didRender', function () {
        var model, rating, tmp_weather;
        model = this.get('record');
        if (model) {
            rating = model.get('rating');
            if (rating) {
                this.giveThumb(rating);
            }
            if (this.get('weather') === null) {
                tmp_weather = model.get('weather');
                if (tmp_weather) {
                    return this.set('weather', JSON.parse(tmp_weather));
                }
            }
        }
    }),
    actions: {
        giveThumbUp: function () {
            return this.giveThumb('up');
        },
        giveThumbDown: function () {
            return this.giveThumb('down');
        },
        save: function () {
            if (!this.get('record.project')) {
                this.set('record.project', this.get('project'));
            }
            return this._super();
        },
    },
    GetGPSCoords: function () {
        var self, tmp;
        self = this;
        tmp = Ember.A([]);
        return this.get('GpsLocator')
            .getLocation()
            .then(
                function (position) {
                    return $.getJSON(
                        config.APP.WEATHER_API +
                            '?lat=' +
                            position.coords.latitude +
                            '&lon=' +
                            position.coords.longitude,
                        function (json) {
                            var dew_point;
                            dew_point = json.temperature - (100 - json.humidity) / 5;
                            json.dew_point = Math.round(dew_point * 100) / 100;
                            if (self.get('weather')) {
                                self.set('weather', json);
                            }
                            json = JSON.stringify(json);
                            if (self.get('record')) {
                                return self.set('record.weather', json);
                            }
                        },
                    );
                },
                function (error) {
                    return self.set('currentPos', error);
                },
            );
    },
    giveThumb: function (value) {
        var model;
        model = this.get('record');
        model.set('rating', value);
        if (value === 'up') {
            $('.thumbs span:eq(0)').addClass('active');
            return $('.thumbs span:eq(1)').removeClass('active');
        } else {
            $('.thumbs span:eq(1)').addClass('active');
            return $('.thumbs span:eq(0)').removeClass('active');
        }
    },
});

export default WorksitediaryReportAddEventComponentComponent;
