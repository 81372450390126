/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/integrations/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsIntegrationsController;

import Ember from 'ember';

import $ from 'jquery';

SettingsIntegrationsController = Ember.Controller.extend({
    collectorService: Ember.inject.service(),
    sending: false,
    callIntegration: function (data) {
        return this.get('collectorService').ccapi({
            url: 'at2unpaidservices',
            method: 'post',
            data: {
                post: data,
            },
        });
    },
    actions: {
        closeModal: function () {
            return this.set('backFromCC', null);
        },
        sendFile: function () {
            return $('input.sendAdmintoolIntegrationFile').click();
        },
        upload: function (evt) {
            var input, reader;
            input = evt.target;
            if (input.files && input.files[0]) {
                this.set('sending', true);
                reader = new FileReader();
                reader.onload = (e) => {
                    var data;
                    data = e.target.result;
                    this.callIntegration(atob(data.split('base64,')[1])).then((back) => {
                        this.set('backFromCC', 'Response: ' + JSON.stringify(back));
                        return this.set('sending', false);
                    });
                    //console.log atob data.substring(13)
                    return this.set('selectedFile', null);
                };
                return reader.readAsDataURL(input.files[0]);
            }
        },
    },
});

export default SettingsIntegrationsController;
