import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, get, set } from '@ember/object';
import { filterBy, gt } from '@ember/object/computed';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    Collector: service('collector-service'),
    session: service(),
    store: service(),
    dialogs: service(),
    intl: service(),
    queryParams: ['searchTerm'],

    searchTerm: '',
    dirtyRecords: filterBy('model.allSubProjects', 'hasDirtyAttributes', true),
    hasDirtyRecords: gt('dirtyRecords.length', 0),
    subproject: alias('model'),
    _subprojects: computed('model.{subprojects,subprojects.[]}', function () {
        if (get(this, 'Collector').testNeeds(['products.subprojectsmulti'])) {
            return get(this, 'model.subprojects').map((item) => {
                set(item, 'selected', get(item, 'project').includes(get(this, 'model.recordid'))); // eslint-disable-line
                return item;
            });
        } else {
            return get(this, 'model.subprojects').map((item) => {
                item.selected = true;
                return item;
            });
        }
    }),

    actions: {
        searchChanged(term) {
            set(this, 'searchTerm', term);
        },
        save() {
            set(this, 'saving', true);

            const promises = [];
            get(this, 'dirtyRecords').forEach((record) => {
                promises.push(record.save());
            });

            Promise.all(promises).then(() => this._setSavingFalse());
        },

        toggle(record) {
            // skip toggling if multi subprojects is not on
            if (!get(this, 'Collector').testNeeds(['products.subprojectsmulti'])) {
                return;
            }

            if (get(record, 'project').includes(get(this, 'model.recordid'))) {
                get(record, 'project').removeObject(get(this, 'model.recordid'));
            } else {
                get(record, 'project').pushObject(get(this, 'model.recordid'));
            }

            record.send('becomeDirty');

            this.notifyPropertyChange('_subprojects');
        },
        changeNewSubprojectName(newName) {
            set(this, 'newSubprojectName', newName);
        },

        addSubproject(name) {
            set(this, 'saving', true);

            let record;
            if (get(this, 'edit')) {
                set(this, 'edit.name', name);
                record = get(this, 'edit');
            } else {
                const project = get(this, 'Collector').testNeeds(['products.subprojectsmulti'])
                    ? [get(this, 'model.recordid')]
                    : get(this, 'model.record');
                record = get(this, 'store').createRecord('subproject', {
                    name: name,
                    project: project,
                });
            }
            record.save().then(() => this._refresh());
        },

        open(record) {
            const field = get(this, 'Collector').field('subproject', 'name');
            field.value = record ? get(record, 'name') : '';
            set(this, 'edit', record);
            set(this, 'field', field);
            set(this, 'showAddPopup', true);
        },

        remove(record) {
            let confirm_text = get(this, 'intl').t('general.remove_row');
            if (get(this, 'Collector').testNeedsOne('products.subprojectsmulti')) {
                confirm_text = get(this, 'intl').t('subproject.remove_subproject', {
                    count: get(record, 'project.length'),
                });
            }
            get(this, 'dialogs')
                .confirm(confirm_text)
                .then((con) => this._removeRecord(con, record));
        },
    },

    _setSavingFalse() {
        set(this, 'saving', false);
    },
    _refresh() {
        set(this, 'saving', false);
        set(this, 'showAddPopup', false);
        this.send('refreshModel');
    },
    _removeRecord(con, record) {
        if (con) {
            record.destroyRecord();
        }
    },
});
