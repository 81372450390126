import GeneralAddController from 'tt4/controllers/general-add';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FormsFormAddController extends GeneralAddController {
    @service router;
    @service store;
    @service('collector-service') collector;

    @action goBack() {
        this.router.transitionTo('forms.form-report', this.form.underscore());
    }

    @action onFieldChange(field, value) {
        this.model[field] = value;
    }

    @action addNew() {
        this.model = this.store.createRecord(this.form);
    }

    @action addSame() {
        this.model = this.collector.copyRecord(this.model);
    }
}
