/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/custom-filters-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorCustomFiltersCompComponent;

import Ember from 'ember';

import filterComp from '../filters-comp/component';

CollectorCustomFiltersCompComponent = filterComp.extend({
    _filters: Em.computed('filters', function () {
        var filters, types;
        types = this.get('filters').split(',');
        filters = Em.A([]);
        $.each(types, (key, item) => {
            var filter;
            filter = item.split('.');
            if (!this.get('form')) {
                this.set('form', filter[0]);
            }
            return filters.pushObject(this.getFilterObject(filter[1], filter[0]));
        });
        return filters;
    }),
});

export default CollectorCustomFiltersCompComponent;
