/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryEditController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

WorksitediaryEditController = GeneralAddController.extend({
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
    customization: Em.computed(function () {
        return {
            rating: {
                template: 'worksitediary/add/rating',
            },
            weather: {
                template: 'worksitediary/add/weather',
            },
        };
    }),
    weather: null,
    editNewLink: 'worksitediary.edit',
    updateRatingVisualization: Em.on('didRender', function () {
        var model, rating, tmp_weather;
        model = this.get('model');
        if (model) {
            rating = model.get('rating');
            if (rating) {
                this.giveThumb(rating);
            }
            if (this.get('weather')) {
                tmp_weather = model.get('weather');
                if (tmp_weather) {
                    return this.set('weather', JSON.parse(tmp_weather));
                }
            }
        }
    }),
    actions: {
        generalAddAction: function (type) {
            switch (type) {
                case 'giveThumbUp':
                    return this.giveThumb('up');
                case 'giveThumbDown':
                    return this.giveThumb('down');
            }
        },
    },
    giveThumb: function (value) {
        var model;
        model = this.get('model');
        return model.set('rating', value);
    },
});

export default WorksitediaryEditController;
