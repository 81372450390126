let allowrun = true;
let ttapiPromise;

export default (ttapi) => {
    return new Promise((resolve) => {
        const onRequestEnd = () => {
            resolve();
            allowrun = true;
        };

        if (allowrun) {
            ttapiPromise = ttapi.post('/api/salary/automaticSalarytypes');
            allowrun = false;
        }
        ttapiPromise.then(onRequestEnd);
    });
};
