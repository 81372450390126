import EmberObject from '@ember/object';
import GeneralReportController from 'tt4/controllers/general-report';
import filterModel from 'tt4/mixins/filter-model';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';

const WorktaskCalendarController = GeneralReportController.extend(filterModel, {
    Collector: service('collector-service'),
    store: service(),
    localStorageWrapper: service(),
    intl: service(),
    dialogs: service(),
    queryParams: null,
    skip_params: null,
    date: null,
    localStorageKey: 'worktaskcalendar_defaultColumns',
    form: 'worktask',

    user: null,
    project: null,
    // do not use limiting with calender
    limit: null,

    colorFrom: null,
    absenceColorFrom: null,
    init() {
        this._super();
        this.skip_params = ['page', 'order', 'date'];
        this.queryParams = ['project', 'user', 'date', 'q', 'customer'];
        this.date =
            moment().startOf('week').format('YYYY-MM-DD') +
            '_' +
            moment().endOf('week').format('YYYY-MM-DD');
        this.colorFrom = ['project.id', 'user.firstname', 'user.lastname'];
        this.absenceColorFrom = ['abcensetype.id', 'user.firstname', 'user.lastname'];
    },

    modelAlias: alias('model'),

    settings: EmberObject.create({
        sortByColumn: 'user,date',
    }),

    calFields: computed(function () {
        const c = this.Collector;
        const col_arr = ['name', 'project', 'user', 'est_hours', 'hours'];
        const fields = c.fieldArray(this.form, col_arr);
        return fields;
    }),

    multiDayFields: computed(function () {
        return this.Collector.fieldArray(this.form, ['project', 'name', 'user']);
    }),

    multiDayAbsenceFields: computed(function () {
        return this.Collector.fieldArray('abcense', ['user', 'abcensetype', 'description']);
    }),

    oneDayAbsenceFields: computed(function () {
        const c = this.Collector;
        const col_arr = ['abcensetype', 'starttime', 'endtime', 'absence_hours', 'description'];
        const fields = c.fieldArray('abcense', col_arr);
        return fields;
    }),

    filters: computed(function () {
        return A([
            EmberObject.create({ field: 'date' }),
            EmberObject.create({ field: 'project' }),
            EmberObject.create({ field: 'user', singleselect: true }),
            EmberObject.create({ field: 'customer' }),
        ]);
    }),

    filteredModelOneDay: computed(
        'filteredModel.{@each.date,filteredModel.@each.enddate}',
        function () {
            const c = this.Collector;
            const model = this.filteredModel;
            if (!c.fieldExists('worktask', 'enddate')) {
                return model;
            }

            return model.filter(function (item) {
                if (
                    get(item, 'enddate') === null ||
                    get(item, 'enddate') === get(item, 'startdate')
                ) {
                    return true;
                }
                return false;
            });
        },
    ),

    filteredModelManyDays: computed('filteredModel.{@each.date,@each.enddate}', function () {
        const c = this.Collector;
        const model = this.filteredModel;
        if (!c.fieldExists('worktask', 'enddate')) {
            return;
        }

        return model.filterBy('enddate').sortBy('date');
    }),

    allAbsences: computed(function () {
        return this.store.peekAll('abcense');
    }),

    filteredAbsences: computed('allAbsences.[]', 'filters.@each.value', function () {
        return this._filterThese(this.allAbsences, ['page', 'order', 'date', 'project']);
    }),

    filteredAbsencesOneDay: computed(
        'filteredAbsences.{[],@each.startdate,@each.enddate}',
        function () {
            const model = this.filteredAbsences;

            return model.filter(function (item) {
                if (
                    get(item, 'enddate') === null ||
                    get(item, 'enddate') === get(item, 'startdate')
                ) {
                    return true;
                }
                return false;
            });
        },
    ),

    filteredAbsencesManyDays: computed(
        'filteredAbsences.{[],@each.startdate,@each.enddate}',
        function () {
            const model = this.filteredAbsences;
            return model.filter(function (item) {
                if (
                    get(item, 'enddate') !== null &&
                    get(item, 'enddate') !== get(item, 'startdate')
                ) {
                    return true;
                }
                return false;
            });
        },
    ),

    actions: {
        openWorktaskModal(day) {
            const params = { date: day };

            // next two lines is added to make sure new record will be shown on calendar right away
            // even when filters are used
            if (this.user) {
                params.user = this.store.peekRecord('user', this.user);
            }
            if (this.project) {
                params.project = this.store.peekRecord('project', this.project);
            }

            this.store.createRecord('worktask', params);
            return this.transitionToRoute({ queryParams: { modal: ['worktask', 'new'] } });
        },

        openEditor(row) {
            return this.transitionToRoute({ queryParams: { modal: ['worktask', get(row, 'id')] } });
        },

        openEditorAbsence(row) {
            return this.transitionToRoute({ queryParams: { modal: ['abcense', get(row, 'id')] } });
        },

        catchActions(action, params) {
            if (action === 'fieldsUpdate') {
                const c = this.Collector;
                const cols = [];
                params.forEach((col) => {
                    return cols.push(col.ext);
                });
                const fields = c.fieldArray(this.form, cols);
                return set(this, 'calFields', fields);
            }
        },

        remove(record) {
            /* eslint-disable ember/named-functions-in-promises */
            return this.dialogs.confirm(this.intl.t('general.remove_row')).then(function (con) {
                if (con) {
                    return record.destroyRecord();
                }
            });
        },

        eventDropped(day, event) {
            const c = this.Collector;
            const [id, model] = Array.from(event.split(':'));
            const dropped_record = this.store.peekRecord(model, id);
            if (get(dropped_record, 'date') === day || get(dropped_record, 'startdate') === day) {
                return;
            }

            const record = c.copyRecord(dropped_record);
            if (model === 'abcense') {
                set(record, 'startdate', day);
                set(record, 'enddate', day);
            } else {
                set(record, 'date', day);
            }
            /* eslint-disable ember/named-functions-in-promises */
            return record
                .save()
                .then((row) => {
                    return row.reload();
                })
                .catch((row) => {
                    let msg = '<h4>' + this.intl.t('worktime.calendar.error') + '</h4><br>';
                    if (this.intl.exists('worktime.calendar.error_type.' + row.errors.error)) {
                        msg += this.intl.t('worktime.calendar.error_type.' + row.errors.error);
                    } else if (this.intl.exists('errors.' + row.errors.error)) {
                        msg += this.intl.t('errors.' + row.errors.error);
                    } else {
                        msg += row.errors.error_msg;
                    }
                    record.destroyRecord();
                    return this.dialogs.alert(msg);
                });
        },
    },
});

export default WorktaskCalendarController;
