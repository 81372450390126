import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import config from 'tt4/config/environment';
import { inject as service } from '@ember/service';
import Ember from 'ember';

export default Component.extend({
    cc: service(),
    session: service(),
    error: service(),

    syncstate: null,

    capi_url: computed(function () {
        return config.environment === 'development'
            ? 'http://capi/'
            : config.APP.COLLECTOR_API_URL + '/';
    }),

    init() {
        this._super();
        if (!Ember.testing)
            this.syncFromVismanet().then(this.syncok.bind(this), this.syncfailed.bind(this));
    },

    syncok() {
        set(this, 'syncstate', 'success');
        set(this, 'done', true);
    },

    syncfailed(reason) {
        set(this, 'syncstate', 'danger');
        set(this, 'errorMessage', get(this, 'error').toString(reason));
    },

    syncFromVismanet() {
        return this.cc.request('general_vismanet', {
            headers: {
                Authorization: 'Bearer ' + get(this, 'session.session.authenticated.access_token'),
            },
            data: {
                capi_url: get(this, 'capi_url'),
            },
        });
    },
});
