/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/text-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsTextFieldComponent;

import Ember from 'ember';

import base from '../base-field/component';

CollectorFieldsTextFieldComponent = base.extend({
    // Methods

    setValue: Em.on('init', function () {
        return Ember.run.next(this, () => {
            if (this.get('field.value') && !this.get('model.' + this.get('field.name'))) {
                return this.set('value', this.get('field.value'));
            }
        });
    }),
    // Send the change action
    valueObserver: Em.observer('value', function () {
        if (this.get('value') === this.get('field.value')) {
            return;
        }
        return this.sendAction('bubbleChange', this.get('value'), this.get('field.name'));
    }),
    actions: {
        touchMove: function () {
            //If the user touches this field and starts scrolling, we actually want to scroll and not snap to this field and open options
            // #UX
            return Em.run.debounce(
                this,
                function () {
                    console.log('setting touchmoving');
                    return this.set('touchMoving', true);
                },
                50,
            );
        },
        touchEnd: function () {
            //We have ended fiddling with this field,
            // if we were moving then blur to not snap scroll back to this field because of mobile shenanigans
            console.log(this.get('touchMoving'));
            if (this.get('touchMoving')) {
                this.set('touchMoving', false);
                return this.element.querySelector('input').blur();
            }
        },
    },
});

export default CollectorFieldsTextFieldComponent;
