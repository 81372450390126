import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

const DevelopmentFunctionsRoute = Route.extend({
    collectorService: service(),

    model() {
        return hash({
            turnedon: this.collectorService.testNeeds(['modules.ttfunctions']),
            functions: this.collectorService.ttapi({
                url: 'ccfunctions/functions',
            }),
        });
    },
});

export default DevelopmentFunctionsRoute;
