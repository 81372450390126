/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/gps-icon/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

Component = Ember.Component.extend({
    gps: Ember.inject.service(),
    classNameBindings: ['getClassNames'],
    getClassNames: Ember.computed('position', function () {
        if (this.get('position') === 'left') {
            return 'left-side';
        }
    }),
    toolTipPosition: Ember.computed('position', function () {
        if (this.get('position') === 'left') {
            return 'right';
        } else {
            return 'left';
        }
    }),
});

export default Component;
