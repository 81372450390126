/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/combo/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryComboController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import pdfmixin from '../../../mixins/worksitediary_pdf';

DiaryComboController = GeneralReportController.extend(pdfmixin, {
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    cordova: Ember.inject.service('own-cordova-service'),
    store: Ember.inject.service(),
    form: 'diary',
    settings: Em.Object.create({
        hideExports: true,
        rowSettings: Em.Object.create({
            hideActions: true,
        }),
    }),
    //            editRoute: {
    //                dynamic: true
    //                route: 'diary.report.date.project'
    //                fields: ['date','project']
    //            }
    limit: 100,
    showColumns: ['project', 'date', 'works', 'deviations', 'weather', 'status'],
    showColumnsWorktime: ['project', 'date', 'user', 'work_hours'],
    showColumnsPresence: ['project', 'date', 'user', 'in', 'out'],
    linkedReports: Em.computed('model', function () {
        var c;
        c = this.get('Collector');
        return c.fieldArray('diary').filterBy('type', 'databasearray');
    }),
    status: 'all',
    queryParams: ['project', 'date', 'wtPage', 'prPage'],
    date: moment().format('YYYY-MM-DD'),
    filters: Em.A([
        Em.Object.create({
            field: 'date',
        }),
        Em.Object.create({
            field: 'project',
        }),
    ]),
    //        Em.Object.create({field: "user"})
    //        Em.Object.create({type: "searchbox", bindto: "q"})
    mappedFilters: Em.computed('filters.@each.value', function () {
        return this.get('filters').map(function (filter) {
            return {
                name: filter.get('field'),
                value: filter.get('value'),
            };
        });
    }),
    projectField: Em.computed('project', function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('diary', 'project');
        field.value = this.get('project');
        return field;
    }),
    dateField: Em.computed('date', function () {
        var c, field;
        c = this.get('Collector');
        field = c.field('diary', 'date');
        field.value = this.get('date');
        return field;
    }),
    project: null,
    diaryFields: Em.computed(function () {
        return Object.entries(this.Collector.fields('diary')).map((field) => {
            Em.set(field[1], 'ext', field[0]);
            if (!field[1]['name']) {
                Em.set(field[1], 'name', this.get('intl').t('report.diary.' + field[0]));
            }
            return field[1];
        });
    }),
    actions: {
        print: function () {
            return print();
        },
        filterChanged: function (value, field) {
            return this.get('filters').forEach(function (filter) {
                if (filter.get('field') === field) {
                    if (field === 'project' && value) {
                        return filter.set('value', value.get('id'));
                    } else {
                        return filter.set('value', value);
                    }
                }
            });
        },
    },
});

export default DiaryComboController;
