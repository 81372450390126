import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class LogoCompComponent extends Component {
    @service store;
    @service() notifications;
    @service intl;

    @tracked saveButtonVisible;
    @tracked logo;
    @tracked logoImage;
    @tracked logoField;
    @tracked isLoading;

    constructor() {
        super(...arguments);
        this.activate();
    }

    @action
    async updateLogo(img) {
        if (this.logo.isDeleted) this.logo = await this.store.createRecord('logo');
        this.logo.logo = img;
        this.logoImage = false;
        this.saveButtonVisible = true;
    }
    @action
    async saveLogo() {
        this.isLoading = true;
        if (this.logo.logo == 'removed') {
            await this.logo.destroyRecord();
        } else {
            await this.logo.save();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        }
        this.isLoading = false;
        this.saveButtonVisible = false;
    }

    async activate() {
        let logo = await this.store.findAll('logo');

        this.logoField = {
            form: 'logo',
            name: 'logo',
            type: 'hdfile',
            features: { is_image: true },
        };

        if (logo.firstObject) {
            let currentLogo = logo.firstObject;
            this.logoImage = currentLogo.logo.thumb;
            this.logo = currentLogo;
            this.logoField.value = currentLogo.logo;
        } else {
            this.logo = this.store.createRecord('logo');
        }
    }
}
