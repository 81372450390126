/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-setting-comp/overtime-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompOvertimeSettingComponent;

import Ember from 'ember';

import collector from '../../../../classes/collector';

import config from '../../../../config/environment';

CollectorFieldSettingCompOvertimeSettingComponent = Ember.Component.extend({
    Collector: Em.inject.service('collector-service'),
    session: Em.inject.service(),
    store: Em.inject.service(),
    intl: Em.inject.service(),
    initapp: Em.inject.service(),
    servicehandler: Em.inject.service(),
    tools: Em.inject.service(),
    notifications: Em.inject.service(),
    //startdate: Em.computed ->
    //    moment().format('YYYY-MM-DD')
    fieldsToBeRemoved: [],
    salarytypes: Em.computed(function () {
        var fields;
        fields = [];
        if (
            this.get('Collector').testNeeds(['products.salarytype||products.selectablesalarytype'])
        ) {
            return this.get('store')
                .query('salarytype', {
                    type: '(empty)',
                })
                .then((salarytypes) => {
                    //console.log salarytypes
                    salarytypes.forEach((stype) => {
                        if (stype.get('id')) {
                            return fields.push({
                                id: stype.get('id'),
                                text: stype.get('name'),
                            });
                        }
                    });
                    return fields;
                });
        } else {
            return fields;
        }
    }),
    init: function () {
        var _overtime_types;
        this._super(...arguments);
        _overtime_types = Ember.Object.create({
            daily_overtime: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.daily_overtime',
                in_use: false,
                settings: {
                    _50start: 8,
                    _100start: 10,
                },
                product: 'overtime_daily',
                rule_creator: function (settings) {
                    return [
                        {
                            name: '_50',
                            values: {
                                from: settings._50start,
                                to: settings._100start,
                            },
                        },
                        {
                            name: '_100',
                            values: {
                                from: settings._100start,
                            },
                        },
                    ];
                },
                rule_parser: function (rules) {
                    return {
                        _50start: '_50.values.from',
                        _100start: '_100.values.from',
                    };
                },
            },
            weekly_overtime: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.weekly_overtime',
                in_use: false,
                settings: {
                    _week50start: 0,
                    _week100start: 8,
                },
                product: 'overtime_weekly',
                rule_creator: function (settings) {
                    return [
                        {
                            name: '_week50',
                            values: {
                                from: settings._week50start,
                                to: settings._week100start,
                            },
                        },
                        {
                            name: '_week100',
                            values: {
                                from: settings._week100start,
                            },
                        },
                    ];
                },
                rule_parser: function (rules) {
                    return {
                        _week50start: '_week50.values.from',
                        _week100start: '_week100.values.from',
                    };
                },
            },
            extrahours_overtime: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.extrahours',
                in_use: false,
                settings: {
                    _extrahours_start: 7.5,
                    _extrahours_end: 8,
                },
                product: 'overtime_extrahours',
                rule_creator: function (settings) {
                    return [
                        {
                            name: '_extrahours',
                            values: {
                                from: settings._extrahours_start,
                                to: settings._extrahours_end,
                            },
                        },
                    ];
                },
                rule_parser: function (rules) {
                    return {
                        _extrahours_start: '_extrahours.values.from',
                        _extrahours_end: '_extrahours.values.to',
                    };
                },
            },
            time_based: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.time_based',
                in_use: false,
                settings: {
                    _evening_start: '16:00',
                    _evening_end: '21:00',
                    _night_start: '21:00',
                    _night_end: '06:00',
                },
                product: 'overtime_time_based',
                rule_creator: function (settings) {
                    return [
                        {
                            name: '_evening',
                            values: {
                                from: settings._evening_start,
                                to: settings._evening_end,
                                do_not_calculate_if_overtime: settings._evening_checked,
                                calculate_compensations_always:
                                    settings._calculate_compensations_always,
                            },
                        },
                        {
                            name: '_night',
                            values: {
                                from: settings._night_start,
                                to: settings._night_end,
                                do_not_calculate_if_overtime: settings._night_checked,
                                calculate_compensations_always:
                                    settings._calculate_compensations_always,
                            },
                        },
                    ];
                },
                rule_parser: function () {
                    return {
                        _evening_start: '_evening.values.from',
                        _evening_end: '_evening.values.to',
                        _evening_checked: '_evening.values.do_not_calculate_if_overtime',
                        _night_start: '_night.values.from',
                        _night_end: '_night.values.to',
                        _night_checked: '_night.values.do_not_calculate_if_overtime',
                        _calculate_compensations_always:
                            '_night.values.calculate_compensations_always',
                    };
                },
            },
            time_based_se: {
                needs: ['serviceCountry@se'],
                name: 'overtime.time_based',
                in_use: false,
                settings: {},
                product: 'overtime_time_based_se',
                alwaysOn: true,
                rule_creator: function (settings) {
                    return [];
                },
                rule_parser: function () {
                    return {};
                },
            },
            overtime_splitted: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.split_overtimehours',
                in_use: false,
                settings: {},
                product: 'overtime_splitted',
                rule_creator: function (settings) {
                    return [
                        {
                            name: '_overtimehours',
                            values: {},
                        },
                        {
                            name: '_basichours',
                            values: {},
                        },
                    ];
                },
                rule_parser: function () {
                    return {};
                },
            },
        });
        this.set('_overtime_types', _overtime_types);
        return this.set('days', [
            {
                id: 'mo',
                name: this.get('intl').t('workday.calendar.week.monday'),
            },
            {
                id: 'tu',
                name: this.get('intl').t('workday.calendar.week.tuesday'),
            },
            {
                id: 'we',
                name: this.get('intl').t('workday.calendar.week.wednesday'),
            },
            {
                id: 'th',
                name: this.get('intl').t('workday.calendar.week.thursday'),
            },
            {
                id: 'fr',
                name: this.get('intl').t('workday.calendar.week.friday'),
            },
            {
                id: 'sa',
                name: this.get('intl').t('workday.calendar.week.saturday'),
            },
            {
                id: 'su',
                name: this.get('intl').t('workday.calendar.week.sunday'),
            },
        ]);
    },
    // filter all the overtime_types with needs
    overtime_types: Ember.computed('worktimegroup', function () {
        var keys, ret, types;
        types = this.get('_overtime_types');
        keys = Object.keys(types);
        ret = {};
        keys.forEach((key) => {
            // skip types that has needs but needs return false
            if (types[key].needs && !this.get('Collector').testNeeds(types[key].needs)) {
                return;
            }
            // all the other types goes to ret
            return (ret[key] = types[key]);
        });
        return Ember.Object.create(ret);
    }),
    fields: Ember.computed(function () {
        return this.get('Collector')
            .fieldArray('worktime')
            .filterBy('type', 'hours')
            .rejectBy('name', 'work_hours');
    }),
    didReceiveAttrs: function () {
        if (this.get('customrules')) {
            return this.setRules(this.parseIfString(this.get('customrules')));
        } else {
            return this.getGeneralRules();
        }
    },
    parseIfString: function (mixed) {
        if (Ember.typeOf(mixed) === 'string') {
            return JSON.parse(mixed);
        }
        return mixed;
    },
    getGeneralRules: function () {
        var data;
        data = {};
        if (this.get('worktimegroup')) {
            data.worktimegroup = this.get('worktimegroup');
        }
        return this.get('Collector')
            .ttapi({
                url: 'overtime/settings',
                method: 'get',
                data: data,
            })
            .then((rules) => {
                var i, len, rule, search_rule, stypes, types_arr;
                // get salarytypes
                if (
                    this.get('Collector').testNeeds([
                        'products.salarytype||products.selectablesalarytype',
                    ])
                ) {
                    search_rule = false;
                    // check every compensation and overtime type and take first element that has salarytypes
                    for (i = 0, len = rules.length; i < len; i++) {
                        rule = rules[i];
                        if (rule.values != null && rule.values.salarytypes) {
                            search_rule = rule;
                        }
                    }
                    if (search_rule) {
                        types_arr = search_rule.values.salarytypes.split(',');
                        stypes = [];
                        this.get('store')
                            .query('salarytype', {
                                id: search_rule.values.salarytypes,
                            })
                            .then((salarytypes) => {
                                return salarytypes.forEach((stype) => {
                                    if (types_arr.indexOf(stype.get('id')) > -1) {
                                        stypes.push({
                                            id: stype.get('id'),
                                            text: stype.get('name'),
                                        });
                                    }
                                    return this.set('salarytype', stypes);
                                });
                            });
                    } else {
                        this.set('salarytype', null);
                    }
                }
                return this.setRules(rules);
            });
    },
    setRules: function (rules) {
        var i, len, overtime_types, ref, type;
        this.set('customTimeBased', Em.A([]));
        this.set('customDayBased', Em.A([]));
        overtime_types = this.get('overtime_types');
        ref = Object.keys(overtime_types);
        for (i = 0, len = ref.length; i < len; i++) {
            type = ref[i];
            if (overtime_types[type].alwaysOn === true) {
                this.turnOn(type);
            } else {
                this.setValuesForOvertimeType(type, rules, overtime_types[type].rule_parser(rules));
            }
        }
        this.getCustomFields('hoursbetween', rules);
        return this.getCustomDayFields('custom_dayrule', rules);
    },
    getCustomFields: function (type, rules) {
        return rules.filterBy('name', type).forEach((item) => {
            return this.get('customTimeBased').pushObject({
                name: Ember.get(item, 'field'),
                text: Ember.get(item, 'text'),
                start: Ember.get(item, 'values.from'),
                end: Ember.get(item, 'values.to'),
                checked:
                    Ember.get(item, 'values.do_not_calculate_if_overtime') === void 0 ||
                    Ember.get(item, 'values.do_not_calculate_if_overtime') === true
                        ? true
                        : false,
            });
        });
    },
    getCustomDayFields: function (type, rules) {
        return rules.filterBy('name', type).forEach((item) => {
            var daysArr;
            daysArr = Ember.get(item, 'values.days').split('-');
            return this.get('customDayBased').pushObject({
                name: Ember.get(item, 'field'),
                text: Ember.get(item, 'text'),
                start: Ember.get(item, 'values.from'),
                end: Ember.get(item, 'values.to'),
                start_day: daysArr[0],
                end_day: daysArr[1],
            });
        });
    },
    //checked: if Ember.get(item, 'values.do_not_calculate_if_overtime') == undefined || Ember.get(item, 'values.do_not_calculate_if_overtime') == true then true else false
    setValuesForOvertimeType: function (type, rules, paths) {
        var i, len, path, paths_splitted, results, rule, value, value_name, values;
        values = Object.keys(paths);
        rules.forEach((rule) => {
            if (rule.name === '_basichours') {
                return this.set('overtime_types.overtime_splitted.in_use', true);
            } else {
                return this.set('overtime_types.overtime_splitted.in_use', false);
            }
        });
        results = [];
        for (i = 0, len = values.length; i < len; i++) {
            value_name = values[i];
            paths_splitted = paths[value_name].split('.');
            rule = rules.findBy('name', paths_splitted[0]);
            if (!rule) {
                this.turnOff(type);
                continue;
            }
            path = paths_splitted.slice(1).join('.');
            value = Ember.get(rule, path);
            if (value || (!value && type === 'time_based')) {
                this.turnOn(type);
                results.push(this.set('overtime_types.' + type + '.settings.' + value_name, value));
            } else {
                results.push(void 0);
            }
        }
        return results;
    },
    // special check for checkboxes (because old customers do not have these checkboxes saved)
    //if value_name.indexOf("_checked") >= 0 && value == undefined
    //    @set 'overtime_types.' + type + '.settings.' + value_name, true
    //else if value_name.indexOf("_checked") >= 0 && value == false
    //    @set 'overtime_types.' + type + '.settings.' + value_name, false

    //_100start: Ember.get rules.findBy('name', '_100'), 'values.from'
    turnOn: function (type) {
        return this.set('overtime_types.' + type + '.in_use', true);
    },
    turnOff: function (type) {
        return this.set('overtime_types.' + type + '.in_use', false);
    },
    updateNeededProducts: function (needed_products, unneeded_products) {
        var c;
        c = this.get('Collector');
        needed_products.forEach((needed_product) => {
            if (!c.testNeeds(['products.' + needed_product])) {
                c.addProduct(needed_product);
                return this.sendAction('refreshNeeded');
            }
        });
        return unneeded_products.forEach((unneeded_product) => {
            var worktimegroup;
            if (c.testNeeds(['products.' + unneeded_product])) {
                worktimegroup = this.get('worktimegroup') ? this.get('worktimegroup') : null;
                c.removeProduct(unneeded_product, worktimegroup);
                return this.sendAction('refreshNeeded');
            }
        });
    },
    searchFieldWithName: function (fields, name) {
        var fieldKeys, found;
        fieldKeys = Object.keys(fields);
        found = null;
        fieldKeys.forEach((key) => {
            if (fields[key].name === name) {
                return (found = key);
            }
        });
        if (found) {
            return found.split('.')[1];
        } else {
            return null;
        }
    },
    onlyOneRuleForField: function (field, type) {
        return this.get(type).findBy('name', field).length < 2;
    },
    actions: {
        checkValues: function (value1, value2) {
            if (value1.toString() === value2.toString()) {
                return this.set('disabled', true);
            } else {
                return this.set('disabled', false);
            }
        },
        moreInfo: function () {
            return window.open(
                config.APP.CDN_URL + '/documents/Ylity%C3%B6t+ja+lis%C3%A4t+asetukset.pdf',
                '_blank',
            );
        },
        addTimeBased: function () {
            return this.get('customTimeBased').pushObject({});
        },
        addDayBased: function () {
            return this.get('customDayBased').pushObject({});
        },
        removeCustomTimeBased: function (row) {
            if (
                Ember.get(row, 'name') &&
                this.onlyOneRuleForField(Ember.get(row, 'name'), 'customTimeBased')
            ) {
                this.get('fieldsToBeRemoved').push(Ember.get(row, 'name'));
            }
            return this.get('customTimeBased').removeObject(row);
        },
        removeCustomDayBased: function (row) {
            if (
                Ember.get(row, 'name') &&
                this.onlyOneRuleForField(Ember.get(row, 'name'), 'customDayBased')
            ) {
                this.get('fieldsToBeRemoved').push(Ember.get(row, 'name'));
            }
            return this.get('customDayBased').removeObject(row);
        },
        createAndSaveRules: function () {
            var fields,
                i,
                j,
                k,
                len,
                len1,
                len2,
                needed_products,
                overtime_type,
                overtime_types,
                ref,
                ref1,
                rule,
                rules,
                type,
                unneeded_products,
                wt,
                wtypes,
                wtypesString;
            //console.info "types",Object.keys(@get('overtime_types')), @get 'overtime_types'
            rules = [];
            needed_products = [];
            unneeded_products = [];
            overtime_types = this.get('overtime_types');
            wtypes = [];
            wtypesString = '';
            if (this.get('salarytype')) {
                ref = this.get('salarytype');
                for (i = 0, len = ref.length; i < len; i++) {
                    wt = ref[i];
                    wtypes.push(wt.id);
                }
                wtypesString = wtypes.join();
            }
            ref1 = Object.keys(overtime_types);
            for (j = 0, len1 = ref1.length; j < len1; j++) {
                type = ref1[j];
                overtime_type = overtime_types[type];
                if (overtime_type.in_use) {
                    rules.pushObjects(overtime_type.rule_creator(overtime_type.settings));
                    needed_products.pushObject(overtime_type.product);
                } else {
                    unneeded_products.pushObject(overtime_type.product);
                }
            }
            for (k = 0, len2 = rules.length; k < len2; k++) {
                rule = rules[k];
                rule['values']['salarytypes'] = wtypesString;
            }
            this.updateNeededProducts(needed_products, unneeded_products);
            fields = {};
            this.get('customTimeBased').forEach((row) => {
                var field, sameField;
                // if no name given add atleast something
                if (!Ember.get(row, 'name') && !Ember.get(row, 'text')) {
                    Ember.set(row, 'text', 'empty');
                }
                if (!Ember.get(row, 'name') && Ember.get(row, 'text')) {
                    sameField = this.searchFieldWithName(fields, Ember.get(row, 'text'));
                    if (sameField) {
                        Ember.set(row, 'name', sameField);
                    } else {
                        Ember.set(
                            row,
                            'name',
                            '_' + this.get('tools').computerizeHumanInput(Ember.get(row, 'text')),
                        );
                    }
                }
                field = Ember.get(row, 'name');
                fields['worktime.' + field] = {
                    type: 'hours',
                    name: Ember.get(row, 'text'),
                };
                fields['worktime.' + field + '.@hide'] = 'true';
                return rules.pushObject({
                    name: 'hoursbetween',
                    field: field,
                    text: Ember.get(row, 'text'),
                    values: {
                        from: Ember.get(row, 'start'),
                        to: Ember.get(row, 'end'),
                        do_not_calculate_if_overtime:
                            Ember.get(row, 'checked') === void 0
                                ? 'false'
                                : Ember.get(row, 'checked'),
                    },
                });
            });
            this.get('customDayBased').forEach((row) => {
                var field, sameField;
                // if no name given add atleast something
                if (!Ember.get(row, 'name') && !Ember.get(row, 'text')) {
                    Ember.set(row, 'text', 'empty');
                }
                if (!Ember.get(row, 'name') && Ember.get(row, 'text')) {
                    sameField = this.searchFieldWithName(fields, Ember.get(row, 'text'));
                    if (sameField) {
                        Ember.set(row, 'name', sameField);
                    } else {
                        Ember.set(
                            row,
                            'name',
                            '_' + this.get('tools').computerizeHumanInput(Ember.get(row, 'text')),
                        );
                    }
                }
                field = Ember.get(row, 'name');
                fields['worktime.' + field] = {
                    type: 'hours',
                    name: Ember.get(row, 'text'),
                };
                fields['worktime.' + field + '.@hide'] = 'true';
                return rules.pushObject({
                    name: 'custom_dayrule',
                    field: field,
                    text: Ember.get(row, 'text'),
                    values: {
                        from: Ember.get(row, 'start'),
                        to: Ember.get(row, 'end'),
                        days: Ember.get(row, 'start_day') + '-' + Ember.get(row, 'end_day'),
                        do_not_calculate_if_overtime:
                            Ember.get(row, 'checked') === void 0
                                ? 'false'
                                : Ember.get(row, 'checked'),
                        calculate_compensations_always:
                            this.get(
                                'overtime_types.time_based.settings._calculate_compensations_always',
                            ) === void 0
                                ? 'false'
                                : this.get(
                                      'overtime_types.time_based.settings._calculate_compensations_always',
                                  ),
                    },
                });
            });
            this.get('fieldsToBeRemoved').forEach((item) => {
                return (fields['!worktime.' + item] = true);
            });
            this.set('fieldsToBeRemoved', []);
            if (this.get('customrules')) {
                return this.sendAction('save', rules);
            } else {
                return this.saveGeneralSettings(fields, rules);
            }
        },
    },
    saveGeneralSettings: function (fields, rules) {
        var data;
        if (Object.keys(fields).length > 0) {
            this.sendAction('refreshNeeded');
        }
        this.get('servicehandler').manipulateService(fields);
        data = {
            rules: JSON.stringify(rules),
        };
        if (this.get('worktimegroup')) {
            data.worktimegroup = this.get('worktimegroup');
        }
        return this.get('Collector')
            .ttapi({
                url: 'overtime/settings',
                method: 'post',
                data: data,
            })
            .then(() => {
                this.get('initapp').clearInit();
                return this.get('notifications').success(this.get('intl').t('general.saved'), {
                    autoClear: true,
                });
            });
    },
});

export default CollectorFieldSettingCompOvertimeSettingComponent;
