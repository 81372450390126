import Controller from '@ember/controller';
import EmberObject, { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';
import avi from '../../../../mixins/avi';
import initController from '../../../../mixins/init-controller';

export default Controller.extend(avi, initController, {
    intl: service(),
    ttapi: service(),
    store: service(),
    Collector: service('collector-service'),
    limit: 25,
    page: 1,
    queryObject: null,
    employer: null,
    init() {
        this._super(...arguments);
        this.columns = this.Collector.fieldArray('user');
        this.showColumns = this.columns.mapBy('name');
        this.queryParams = this.showColumns.concat(['page', 'q']);
        this.rowActions = [
            { action: 'edit', icon: 'move move-Pencil', text: this.intl.t('general.edit') },
        ];
        this.queryObject = {};
    },

    project: alias('model.project'),
    data: alias('model.data'),

    filters: computed(function () {
        let filterArr = A([
            EmberObject.create({ field: 'employer', form: 'user', singleselect: 'true' }),
            EmberObject.create({ type: 'searchbox' }),
        ]);

        // Add all the possible filters from user report as "more filters"
        const filterTypes = [
            'date',
            'database',
            'databasearray',
            'multiselect',
            'dropdown_options',
            'dropdown_values',
            'dropdown_userlevel',
            'dropdown_country',
            'checkbox',
        ];
        for (const col of this.columns) {
            if (!filterTypes.includes(col.type)) continue;
            if (col.name === 'employer') continue;
            filterArr.pushObject(
                EmberObject.create({
                    field: col.name,
                    form: col.form,
                    type: col.type,
                    options: col.options,
                    extra: true,
                }),
            );
        }
        return filterArr;
    }),

    pages: computed('data.count', function () {
        return Math.ceil(get(this, 'data.count') / get(this, 'limit'));
    }),
    translated_columns: computed(function () {
        return get(this, 'columns').mapBy('translated_name');
    }),

    actions: {
        filterChanged(value, field) {
            if (!value) value = '';
            value = value.id || value;
            set(this, field, value);
        },

        action(type, row) {
            if (type === 'edit') {
                this.transitionToRoute('users.edit', row.id).then((newRoute) =>
                    this._afterSave(newRoute),
                );
            }
        },
    },

    _afterSave(newRoute) {
        newRoute.controller.afterSave = {
            route: 'projects.edit.project-users',
            params: this.project.id,
        };
    },

    init_functions() {
        this._super();
    },
});
