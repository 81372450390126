import { hash } from 'rsvp';
import GeneralReportRoute from '../../../../routes/general-report';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

export default GeneralReportRoute.extend({
    store: service(),
    collector: service('collector-service'),
    queryParams: {
        worktime_limit: {
            refreshModel: true,
        },
        absence_limit: {
            refreshModel: true,
        },
        worktime_page: {
            refreshModel: true,
        },
        absence_page: {
            refreshModel: true,
        },
        order: {
            refreshModel: true,
        },
        absence_order: {
            refreshModel: true,
        },
        form_status: {
            refreshModel: true,
        },
    },
    beforeModel(transition) {
        this._super(...arguments);
        if (
            transition.targetName === 'timetracker.aggregated.rows' &&
            transition.to.params.date === 'all'
        ) {
            set(this.controllerFor('timetracker.aggregated'), 'showAllRows', true);
        }
    },
    model(params) {
        let parentParams = this.paramsFor('timetracker.aggregated');
        parentParams.sideload = true;
        if (!this.collector.testNeedsOne('employer')) delete parentParams.employer;
        if (!parentParams.user) delete parentParams.user;
        let worktimeParams = parentParams;

        let absenceParams = {
            startdate: parentParams.date,
            user: parentParams.user,
            status: parentParams.status,
            limit: parentParams.limit,
            sideload: true,
        };
        if (params.date != 'all') {
            parentParams.date = params.date;
        }
        if (params.form_status === 'all') {
            delete params.form_status;
        }

        worktimeParams.limit = params.worktime_limit;
        worktimeParams.order = params.order;
        worktimeParams.status = parentParams.status;
        worktimeParams.offset = (params.worktime_page - 1) * params.worktime_limit;

        absenceParams.limit = params.absence_limit;
        absenceParams.order = params.absence_order;
        absenceParams.status = parentParams.status;
        absenceParams.offset = (params.absence_page - 1) * params.absence_limit;

        const c = get(this, 'collector');

        return hash({
            worktimeRows: c.testNeedsOne('worktime')
                ? get(this, 'store').query('worktime', worktimeParams)
                : [],
            absenceRows: c.testNeedsOne('abcense')
                ? get(this, 'store').query('abcense', absenceParams)
                : [],
            params: parentParams,
        });
    },
    setupController(controller) {
        this._super(...arguments);
        // set filters for rows controllers settings object from parent controller, so they can be used in the general-report component
        const parentFilters = this.controllerFor('timetracker.aggregated').filters;
        set(controller, 'settings.filters', parentFilters);
    },
    actions: {
        willTransition(transition) {
            set(this.controllerFor('timetracker.aggregated'), 'showAllRows', false);

            if (
                transition.targetName === 'abcenses.edit' ||
                transition.targetName === 'timetracker.edit'
            ) {
                transition.queryParams.afterSave = 'timetracker.aggregated';
                this.transitionTo(transition.targetName).then((newRoute) =>
                    this.setAfterSave(newRoute),
                );
            }
        },
    },
    setAfterSave(newRoute) {
        newRoute.controller.afterSave = 'timetracker.aggregated';
    },
});
