/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/search-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsTextFieldComponent;

import Ember from 'ember';

import collectorField from '../../../../mixins/collector-field';

CollectorFieldsTextFieldComponent = Ember.Component.extend(collectorField, {
    intl: Ember.inject.service(),

    // Methods

    setValue: Em.on('init', function () {
        return this.set('value', this.get('field.value'));
    }),
    placeholder: Ember.computed(function () {
        return this.get('intl').t('general.search');
    }),
    // Send the change action
    valueObserver: Em.observer('value', function () {
        if (this.get('value') === this.get('field.value')) {
            return;
        }
        return Ember.run.debounce(
            this,
            function () {
                return this.sendAction(
                    'bubbleChange',
                    this.get('value'),
                    this.get('field.name'),
                    true,
                    false,
                );
            },
            2000,
        );
    }),
});

export default CollectorFieldsTextFieldComponent;
