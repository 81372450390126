/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/group-by-filter/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

Component = Ember.Component.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    fields: Em.computed(function () {
        return this.get('Collector').fieldArray(this.get('form'));
    }),
    chosenFields: Em.A([]),
    didReceiveAttrs: function () {
        var chosenFields, unchosenFields, valueArr;
        valueArr = this.get('value') ? this.get('value').split(',') : [];
        chosenFields = valueArr.map((val) => {
            return this.get('fields').findBy('name', val);
        });
        this.set('chosenFields', chosenFields);
        unchosenFields = this.get('fields').filter((field) => {
            if (field.type === 'databasearray') {
                return;
            }
            return !this.chosenFields.find((f) => {
                return f.name === field.name;
            });
        });
        return this.set('unchosenFields', unchosenFields);
    },
    actions: {
        toggleModal: function () {
            return this.toggleProperty('showModal');
        },
        addField: function (field) {
            this.get('unchosenFields').removeObject(field);
            return this.get('chosenFields').pushObject(field);
        },
        removeField: function (field, applyFilters) {
            this.get('chosenFields').removeObject(field);
            this.get('unchosenFields').pushObject(field);
            if (applyFilters) {
                return this.send('applyFilters');
            }
        },
        applyFilters: function () {
            if (this.get('chosenFields').length) {
                this.sendAction(
                    'change',
                    this.get('chosenFields').map((field) => {
                        return field.name;
                    }),
                    'groupBy',
                );
            } else {
                this.sendAction('change', null, 'groupBy');
            }
            return this.send('toggleModal');
        },
    },
});

export default Component;
