import { computed, get } from '@ember/object';
import AjaxService from 'ember-ajax/services/ajax';
import { inject } from '@ember/service';
import config from 'tt4/config/environment';

export default AjaxService.extend({
    session: inject(),
    headers: computed('session.session.authenticated.access_token', {
        get() {
            let headers = {};
            const authToken = get(this, 'session.session.authenticated.access_token');
            if (authToken) {
                headers['Authorization'] = 'Bearer ' + authToken;
            }
            return headers;
        },
    }),
    host: config.APP.BLOCKS_URL,
});
