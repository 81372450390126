import { htmlSafe } from '@ember/string';
import Ember from 'ember';
import { helper } from '@ember/component/helper';

export function icon(value) {
    let escaped = Ember.Handlebars.Utils.escapeExpression(value);
    let className = '';
    switch (escaped) {
        case 'save':
            className = 'glyphicon glyphicon-floppy-disk';
            break;
        case 'edit':
            className = 'glyphicon glyphicon-pencil';
            break;
        case 'warning':
            className = 'glyphicon glyphicon-warning-sign';
            break;
        case 'filter':
            className = 'glyphicon glyphicon-filter';
            break;
        case 'export':
            className = 'glyphicon glyphicon-save-file';
            break;
        case 'previous':
            className = 'glyphicon glyphicon-arrow-left';
            break;
        case 'next':
            className = 'glyphicon glyphicon-arrow-right';
            break;
        case 'unchecked':
            className = 'move move-UncheckedCheckbox';
            break;
        case 'checked':
            className = 'move move-CheckedCheckbox';
            break;
        case 'ok':
            className = 'glyphicon glyphicon-ok';
            break;
        case 'cancel':
            className = 'glyphicon glyphicon-ban-circle';
            break;
        case 'remove':
            className = 'glyphicon glyphicon-remove';
            break;
        case 'info':
            className = 'move move-Info';
            break;
    }

    if (escaped) {
        return new htmlSafe('<span class="' + className + '"></span>');
    }
}
export default helper(icon);
