/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/history/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller;

import Ember from 'ember';

import productSettings from '../../../config/settings';

Controller = Ember.Controller.extend({
    queryParams: 'page',
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    gui: Ember.inject.service(),
    formattedModel: Ember.computed('model', function () {
        return this.get('model.products').map((row) => {
            row.product = this.getProductName(row.product);
            row.type =
                row.type === 'add'
                    ? this.get('intl').t('general.on')
                    : this.get('intl').t('general.off');
            row.date = moment(row.date + ' UTC').format('LLL');
            return row;
        });
    }),
    rowCount: Ember.computed.alias('model.count'),
    pageCount: Ember.computed('rowCount', function () {
        return Math.ceil(this.get('rowCount') / 25);
    }),
    getProductName: function (product) {
        var formList, list;
        list = this.getListOfProducts();
        formList = list.findBy('product', product);
        if (formList) {
            return {
                header: this.get('intl').t(formList.header),
                subheader: this.get('intl').t(formList.subheader),
            };
        } else {
            return {
                name: product,
            };
        }
    },
    getListOfProducts: function () {
        var k, list, productSetting;
        if (this.get('listOfProducts')) {
            return this.get('listOfProducts');
        }
        list = [];
        for (k in productSettings) {
            productSetting = productSettings[k];
            productSetting.forEach((item) => {
                var form, productName, setting_name;
                setting_name = item.title;
                form = item.form;
                if (!item.options) {
                    productName = Ember.isArray(item.productsOn)
                        ? item.productsOn[0].substring(0, 9) === 'products.'
                            ? item.productsOn[0].slice(9)
                            : item.productsOn[0]
                        : form;
                    list.push({
                        header: setting_name,
                        subheader: null,
                        product: productName,
                    });
                    return;
                }
                return item.options.forEach((option) => {
                    var option_name;
                    option_name = option.title;
                    return option.products.forEach((product) => {
                        if (product.substring(0, 9) === 'products.') {
                            return list.push({
                                header: form ? setting_name : option_name,
                                subheader: form ? null : setting_name,
                                product: product.substring(9),
                            });
                        }
                    });
                });
            });
        }
        this.set('listOfProducts', list);
        return list;
    },
});

export default Controller;
