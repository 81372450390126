import { helper } from '@ember/component/helper';

/**
 * Checks if the date's day is sunday regardless of timezone
 * @param {Date} date
 * @returns boolean - true or false
 */
export function isSunday(date) {
    return date[0]?.toLocaleDateString('en', { weekday: 'long' }).toLowerCase() === 'sunday';
}

export default helper(isSunday);
