/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/report/date/route.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryReportDateRoute;

import Ember from 'ember';

DiaryReportDateRoute = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function (transition) {
        if (transition.targetName === 'diary.report.date.index') {
            if (this.get('session.currentUser.currentMySite')) {
                this.transitionTo(
                    'diary.report.date.project',
                    this.get('session.currentUser.currentMySite.id'),
                );
            }
            if (
                this.modelFor('diary.report.date.project') &&
                this.modelFor('diary.report.date.project').get('project.id')
            ) {
                return this.transitionTo(
                    'diary.report.date.project',
                    this.modelFor('diary.report.date.project').get('project.id'),
                );
            }
        }
    },
    actions: {
        willTransition: function (transition) {
            if (transition.targetName === 'diary.report.index') {
                return transition.abort();
            }
        },
    },
});

export default DiaryReportDateRoute;
