import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

export default Component.extend({
    router: service(),
    store: service(),
    flags: service(),

    form: computed('modal', function () {
        return get(this, 'modal').split(',')[0];
    }),

    record: computed('modal', function () {
        const id = get(this, 'modal').split(',')[1];
        // support for weird modal queryParam .. when queryParam caontains id it means someone has already made a new record for form-add
        if (!id || id === 'new')
            return get(this, 'store')
                .peekAll(get(this, 'form'))
                .find((record) => get(record, 'hasDirtyAttributes'));
        return this.store.peekRecord(this.form, id);
    }),
    actions: {
        closeAndRefresh() {
            const controller = getOwner(this).lookup('controller:application');
            const routeName = get(this.router, 'currentRouteName');
            if (
                routeName.includes(this.form) ||
                (this.form == 'worktime' && routeName.includes('worktime-list'))
            ) {
                const route = getOwner(this).lookup('route:' + get(controller, 'currentRouteName'));
                route.refresh();
            }
            set(this, 'modal', null);
        },
    },
});
