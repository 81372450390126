/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/passes/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsPassesController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

ProjectsPassesController = GeneralReportController.extend({
    filters: Em.A([
        Em.Object.create({
            field: 'ori_user',
        }),
    ]),
    ori_site: null,
    projectObserver: function () {
        return this.set('page', 1);
    }.observes('project'),
    settings: Em.Object.create({
        localStorageKey: 'project-passes',
    }),
    queryParams: [
        {
            ori_site: {
                as: 'project',
            },
        },
        'page',
        'ori_employer',
        'ori_user',
    ],
    pass_end: '!(empty)',
    pass_start: '!(empty)',
    form: 'orientation',
    //localStorageKey: "project-passes"
    showColumns: ['ori_user', 'pass_start', 'pass_end'],
});

export default ProjectsPassesController;
