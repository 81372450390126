import { helper } from '@ember/component/helper';

// This function receives the params `params, hash`
export function stringToArray(params) {
    if (typeof params[0] == 'string') {
        return JSON.parse(params[0]);
    }
    return params[0];
}
export default helper(stringToArray);
