/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

WorksitediaryAddRoute = GeneralAddRoute.extend({
    modelName: 'worksitediary',
    afterSave: 'worksitediary.events',
    controllerName: 'worksitediary.add',
    savedRoute: 'saved',
    Collector: Ember.inject.service('collector-service'),
    weather: Ember.inject.service(),
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.set('model', model);
    },
    //@get('weather').fetchWeatherPosition().then (e) ->
    //    controller.set('model.weather', e)
    afterModel: function (model) {
        var controller;
        controller = this.controllerFor('worksitediary/add');
        return controller.set('overrideWeather', false);
    },
    /*
  if model.get 'isNew'
      @get('weather').fetchWeatherPosition(model).then ((weather) ->
          controller.set('model.weather', weather)
      ), (reason) ->
          controller.set('model.weather', reason)
  else
      try
          controller.set 'weather', JSON.parse(model.get('weather'))
      catch e
          controller.set 'weather', null
      controller.set 'editMode', true

  controller = @controllerFor('worksitediary/add')
  console.log model.get 'project.id'
  @get('weather').fetchWeatherPosition(model).then ((weather) ->
      controller.set('model.weather', weather)
  ), (reason) ->
      controller.set('model.weather', reason)

  */
    actions: {
        save: function () {
            var json_weather, row, self;
            self = this;
            row = this.get('controller.model');
            if (row.get('weather')) {
                json_weather = row.get('weather');
                row.set('weather', JSON.stringify(json_weather));
            }
            if (this.get('Collector').formExists('diary')) {
                this.set('afterSave', 'diary.events');
            }
            //hideFields = self.get 'controller.hideFromSaved'
            this._super();
        },
    },
});

//            row.save().then (saved_row) ->
//                self.transitionTo('saved').then (newRoute) ->
//                    newRoute.controller.set 'model', saved_row
//                    newRoute.controller.set 'afterSave',
//                    newRoute.controller.set 'hideFields', self.get 'controller.hideFromSaved'
export default WorksitediaryAddRoute;
