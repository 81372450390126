import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FondionSettingsComponent extends Component {
    @service store;
    @service('collector-service') Collector;
    @service ttapi;
    @service intl;

    @tracked fondion_client_id;
    @tracked fondion_client_secret;
    @tracked fondion_tenant_id;
    @tracked isLoading;

    constructor() {
        super(...arguments);
        this.getSettings();
    }

    @action
    save() {
        this.store.peekAll('integrationsetting').save();

        const addThese = [
            {
                key: '8d9e9811-6ab3-4303-a0c8-57a3c5ba4465',
                name: this.intl.t('ccbutton.fondion.projectsAndTasks'),
                tag: 'fondionProjectsAndTasks',
            },
            {
                key: 'bb664c4f-f04d-4f16-a82d-096f64b2fcae',
                name: this.intl.t('ccbutton.fondion.users'),
                tag: 'fondionUsers',
            },
        ];

        addThese.forEach((item) => {
            this.ttapi.request('/api/addCustomIntegrationsToCcbutton', {
                method: 'POST',
                data: item,
            });
        });

        this.args.close();
    }

    async getSettings() {
        this.isLoading = true;
        let settings = await this.store.query('integrationsetting', { integration: 'fondion' });

        this.fondion_client_id = this.setRecord(settings, 'fondion_client_id', null);
        this.fondion_client_secret = this.setRecord(settings, 'fondion_client_secret', null);
        this.fondion_tenant_id = this.setRecord(settings, 'fondion_tenant_id', null);
        this.isLoading = false;
    }

    setRecord(settings, key, value) {
        let record = settings.findBy('key', key);
        return record
            ? record
            : this.store.createRecord('integrationsetting', {
                  integration: 'fondion',
                  key: key,
                  value: value,
              });
    }
}
