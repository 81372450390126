/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/contract-list/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanContractListIndexRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

TaxmanContractListIndexRoute = GeneralReportRoute.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        contract_employer: {
            refreshModel: true,
        },
        contract_project: {
            refreshModel: true,
        },
        contract_month: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        params.sideload = true;
        return this.store.query('urakkaraportointi', params);
    },
});

export default TaxmanContractListIndexRoute;
