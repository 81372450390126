/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/tr/graphs/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyTrGraphsRoute;

import Ember from 'ember';

SiteSafetyTrGraphsRoute = Ember.Route.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    beforeModel: function (transition) {
        var c;
        this._super(transition);
        c = this.get('Collector');
        if (transition.targetName !== 'site_safety.tr.notices' && !c.testNeedsOne('tr@add')) {
            transition.abort();
            return this.transitionTo('site_safety.report.tr.notices');
        }
    },
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var cols, date, monthAgo, store, trfieldkeys, trfields;
        params.sideload = true;
        if (params.project === 'undefined') {
            delete params.project;
        }
        if (!params.group) {
            delete params.group;
        }
        params.order = 'date';
        store = this.get('store');
        monthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD_');
        date = moment().format('YYYY-MM-DD');
        trfields = this.get('Collector').fields('tr');
        trfieldkeys = Object.keys(trfields);
        cols = [];
        trfieldkeys.forEach(function (field) {
            if (trfields[field].type !== 'multiselect') {
                return cols.push(field);
            }
        });
        params.cols = cols.join(',');
        return Ember.RSVP.hash({
            tr: store.query('tr', params),
            noticesSelected: store.query('trnotice', {
                type: 'tr',
                limit: 100,
                created: monthAgo.concat(date),
                sideload: params.sideload,
                project: params.project,
                group: params.group,
            }),
            notices: store.query('trnotice', {
                type: 'tr',
                limit: 1,
                created: params.date,
                project: params.project,
                group: params.group,
            }),
            not_solved: store.query('trnotice', {
                type: 'tr',
                limit: 1,
                created: params.date,
                status: 'open',
                project: params.project,
                group: params.group,
            }),
            overdue: store.query('trnotice', {
                type: 'tr',
                created: params.date,
                date: params.date,
                status: 'open',
                limit: 1,
                project: params.project,
                group: params.group,
            }),
        });
    },
});

export default SiteSafetyTrGraphsRoute;
