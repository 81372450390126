/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/signature/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiarySignatureController;

import Ember from 'ember';

WorksiteDiarySignatureController = Ember.Controller.extend({
    session: Ember.inject.service(),
    addController: Ember.inject.controller('worksitediary.report.add'),
    actions: {
        changeOverseerSignature: function (sig) {
            this.set('addController.model.overseer', sig);
            return this.set('addController.model.status', 'Approved');
        },
        changeSupervisorSignature: function (sig) {
            this.set('addController.model.supervisor', sig);
            return this.set('addController.model.status', 'Approved');
        },
    },
});

export default WorksiteDiarySignatureController;
