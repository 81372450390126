/*eslint-disable ember/named-functions-in-promises */
import { getOwner } from '@ember/application';

import { Promise } from 'rsvp';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { next, once, bind } from '@ember/runloop';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import pdf_mixin from '../../../mixins/pdf-export';

const CollectorAddModalComponent = Component.extend(pdf_mixin, {
    Collector: service('collector-service'),
    store: service(),
    gui: service(),
    flags: service(),
    localStorageWrapper: service(),
    intl: service(),
    router: service(),
    record: null,

    fields: computed('form', function () {
        return get(this, 'Collector')
            .fieldArray(get(this, 'form'))
            .reject((field) => field.features.hide);
    }),

    _modal: computed('modal', function () {
        return get(this, 'modal').split(',');
    }),

    form: computed('_modal', function () {
        return get(this, '_modal')[0];
    }),

    type: computed('_modal', function () {
        return get(this, '_modal')[2];
    }),

    storeRecord: computed('record', function () {
        const record = get(this, 'store').peekRecord(get(this, 'form'), get(this, 'record'));
        if (!record) {
            next(this, () => {
                return set(this, 'modal', []); //eslint-disable-line
            });
        }

        return record;
    }),

    recordCompComponent: computed('form', function () {
        const addModal = (() => {
            switch (get(this, 'form')) {
                case 'worktask':
                    return 'worktask';
                case 'timecleandevice':
                    return 'admintool';
                case 'abcense':
                    return 'absence';
                case 'worktime':
                    return 'worktime';
                case 'presence':
                    return 'presence';
                case 'card':
                    return 'card';
            }
        })();
        if (addModal) {
            return 'collector/add-modal/' + addModal;
        } else {
            return 'collector/record-comp';
        }
    }),

    currentHolidays: computed(function () {
        return ObjectProxy.extend(PromiseProxyMixin).create({
            promise: new Promise((resolve) => {
                const userId = get(
                    get(this, 'store').peekRecord(get(this, 'form'), get(this, 'record')),
                    'user.id',
                );
                const start = moment().format('YYYY') + '-05-02';
                const country = get(
                    get(this, 'store').peekAll('companyInfo'),
                    'firstObject.country',
                );

                const url = 'api/getUsersWithHolidays';
                return get(this, 'Collector')
                    .ttapi({
                        url: url + '?id=' + userId + '&start=' + start + '&country=' + country,
                    })
                    .then((back) => {
                        return resolve({ value: back['users'][0]['availabledays'] });
                    });
            }),
        });
    }),

    didReceiveAttrs() {
        if (get(this, 'record')) return;
        const modal = get(this, '_modal');
        if (modal[1]) {
            set(this, 'record', modal[1]);
        } else {
            set(this, 'record', false);
        }
    },

    willInsertElement() {
        return once(this, bind(this, this.hideMobileHeader));
    },

    didDestroyElement() {
        return set(this, 'gui.hideMobileHeader', false);
    },

    actions: {
        modalRecordSaved(settings, record) {
            //            @get('notifications').success(@get('intl').t("modal.record.saved",  {form: record.constructor.modelName}), {
            //                autoClear: true
            //                clearDuration: 2000
            //            })
            set(this, 'record', get(record, 'id'));
            return set(this, 'saved', true);
        },

        removeModal(settings, cleaningDone) {
            // this is ugly but only way I came up with...
            const promise = new Promise((resolve) => {
                if (!cleaningDone) {
                    if (get(this, 'record') === 'new') {
                        const found = get(this, 'store')
                            .peekAll(get(this, 'form'))
                            .find((record) => {
                                return get(record, 'hasDirtyAttributes');
                            });
                        if (found) {
                            found.deleteRecord();
                        }
                    } else {
                        if (get(this, 'record')) {
                            const record = get(this, 'store').peekRecord(
                                get(this, 'form'),
                                get(this, 'record'),
                            );
                            if (record) {
                                record.rollbackAttributes();
                            }
                        }
                    }
                }
                // Only refresh model for presence form for now, this should be removed or slowly added more options
                if (
                    !['show', 'history'].includes(get(this, 'type')) &&
                    ['presence', 'abcense', 'group', 'site_safety_event', 'worktask'].includes(
                        get(this, 'form'),
                    )
                ) {
                    const controller = getOwner(this).lookup('controller:application');
                    const routeName = get(this.router, 'currentRouteName');
                    if (routeName.includes(get(this, 'form'))) {
                        const route = getOwner(this).lookup(
                            'route:' + get(controller, 'currentRouteName'),
                        );
                        return route.refresh().then(() => {
                            return resolve();
                        });
                    } else {
                        return resolve();
                    }
                } else {
                    return resolve();
                }
            });
            // we have to wait for model-refreshing is done before removing modal, otherwise it creates some weird issues with array-type query-params
            return promise.then(() => {
                set(this, 'modal', null);
                return set(this, 'showEmailModal', false);
            });
        },

        showEmailModal() {
            return set(this, 'showEmailModal', true);
        },

        sendEmail(to, title, content) {
            set(this, 'loading', true);
            const intl = get(this, 'intl');
            const c = get(this, 'Collector');
            set(this, 'loading', true);
            const form = get(this, 'form');
            const fields = c.fields(form);

            const titles = [];
            const columns = [];
            const export_fields = [];
            for (let name in fields) {
                const field = fields[name];
                set(field, 'ext', name);
                if (field.features && field.features.hide_column) {
                    continue;
                }
                titles.push(c.getTranslationForColumn(form, field.ext));
                columns.push(field.ext);
                export_fields.push(field);
            }

            return this.formatCollectorFieldsToPdfArray(
                export_fields,
                get(this, 'storeRecord'),
                'row',
            ).then((data) => {
                const element = this.createPdfTableElementRows(columns, [data], titles);
                return this.createPdfFromElements(element, 'base64').then((pdf) => {
                    return c
                        .ttapi({
                            url: 'sendReportAsEmail',
                            data: {
                                to_address: to,
                                header: title,
                                content,
                                email: pdf,
                                lang: intl.primaryLocale,
                            },
                            method: 'post',
                        })
                        .then(() => {
                            set(this, 'loading', false);
                            set(this, 'emailSent', true);
                            return get(this, 'localStorageWrapper').setUserSetting(
                                'last_sent_email_address',
                                to,
                            );
                        });
                });
            });
        },
    },

    hideMobileHeader() {
        set(this, 'gui.hideMobileHeader', true);
    },
});

export default CollectorAddModalComponent;
