import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

const ImportIndexController = Controller.extend({
    mainController: controller('development.import'),
    intl: service(),
    Collector: service('collector-service'),
    splitter: ',',
    file: alias('mainController.file'),
    form: alias('mainController.form'),
    hasHeaderRow: alias('mainController.hasHeaderRow'),
    content: alias('model'),
    showFile: computed('form', 'delimiter', function () {
        if (this.form && this.splitter) {
            return true;
        }
        return false;
    }),
    forms: computed(function () {
        const forms = this.Collector.forms();
        const t = this.intl;
        const formOptions = [];
        for (let key in forms) {
            const form = forms[key];
            formOptions.push({
                id: key,
                name: form.name || t.t('general.form.' + key),
            });
        }
        return formOptions;
    }),
});

export default ImportIndexController;
