/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/save-the-children.coffee.old
// Generated by CoffeeScript 2.6.1
var SaveTheChildrenMixin;

import Ember from 'ember';

SaveTheChildrenMixin = Ember.Mixin.create({
    save_record: function (record) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var children, field, fields, name, obj, promises, removeThese;
            promises = [];
            fields = this.get('Collector').fields(this.get('form'));
            children = [];
            for (name in fields) {
                field = fields[name];
                if (field.type === 'databasearray' && name !== 'involved') {
                    removeThese = [];
                    obj = Ember.Object.extend({
                        fieldsInCommon: Ember.computed.intersect('parentFields', 'childFields'),
                    }).create({
                        parentFields: Object.keys(fields),
                        childFields: Object.keys(this.get('Collector').fields(field.options.form)),
                    });
                    if (record.get(name)) {
                        record.get(name).forEach((child) => {
                            if (child.get('id') !== '0') {
                                obj.get('fieldsInCommon').forEach((commonField) => {
                                    if (record.get(commonField)) {
                                        return child.set(commonField, record.get(commonField));
                                    }
                                });
                                return children.push(child);
                            } else {
                                return removeThese.pushObject(child);
                            }
                        });
                    }
                    if (removeThese.length > 0) {
                        record.get(name).removeObjects(removeThese); // just remove objects from parent record
                    }
                }
            }
            if (children.length > 0) {
                return this.get('Collector')
                    .checkRowValidation(this.get('form'), record, !record.get('isNew'))
                    .then(
                        (resp) => {
                            promises = children.map(function (child) {
                                return child.save();
                            });
                            return Ember.RSVP.Promise.all(promises).then(() => {
                                return this.saveParentRecord(record, resolve, reject);
                            }, reject);
                        },
                        (e) => {
                            if (this.get('saveTheChildrenRawErrors')) {
                                this.set('validationMessage', e);
                            } else {
                                this.set('validationMessage', [
                                    e.responseJSON ? e.responseJSON.error_msg : e.payload.error_msg,
                                ]);
                            }
                            return reject(e);
                        },
                    );
            } else {
                return this.saveParentRecord(record, resolve, reject);
            }
        });
    },
    saveParentRecord: function (record, resolve, reject) {
        return record.save().then(resolve, reject);
    },
});

export default SaveTheChildrenMixin;
