import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    ttapi: service(),
    analytics: service(),

    attributeBindings: ['name'],

    debug: false,
    refreshWarnings: false,
    /*
    showRowEditor: computed('setting.form', function() {
        if (this.worktimegroupAndNotEditableSetting || (get(this, 'setting.options') && get(this, 'setting.options.firstObject.active'))) return false;
        return get(this,'setting.form');
    }),*/

    name: computed('setting.title', function () {
        return this.setting.title;
    }),

    settingIsLoading: computed('setting.loading', function () {
        if (
            this.setting.loading &&
            (this.setting.productsOn || this.setting.name || this.setting.options)
        )
            return true;
        return false;
    }),

    filterByWorktimegroup: computed('worktimegroup', function () {
        if (this.worktimegroup) {
            return { worktimegroup: this.worktimegroup };
        }
        return {};
    }),

    showSettingOptions: computed(
        'setting.{component,form,options,active,hideSettingsFromWorktimegroups}',
        'loadingState',
        'worktimegroup',
        function () {
            if (this.setting.hasReport && !this.setting.active) return false;
            if (this.loadingState) return false;
            if (this.worktimegroup && this.setting.hideSettingsFromWorktimegroups) return false;
            else if (this.setting.component || this.setting.options) return true;
            else if (!this.setting.active) return false;
            else if (this.setting.form) return true;

            //if (!this.setting.form && !this.setting.options) return true; // show component-type always

            //if (this.setting.component || this.setting.form || this.setting.options) return true;
            return false;
        },
    ),
    showPlaceholder: computed(
        'setting.{component,form,options,active}',
        'loadingState',
        function () {
            return false;
            //if (!this.loadingState && !this.setting.active && this.setting.form) return true;
            //return false;
        },
    ),

    showOverlay: computed('setting.active', function () {
        if (!this.setting.active && this.showSettingOptions) return true;
        return false;
    }),

    showFormReport: computed(
        'setting.{form,options.@each.active,loading,groupEditableSettings}',
        'settingIsLoading',
        'worktimegroup',
        function () {
            if (
                get(this, 'worktimegroup') &&
                !this.Collector.testNeeds([this.setting.form + '.worktimegroup'])
            )
                return false;
            if (this.settingIsLoading) return false;
            if (this.setting.options && this.setting.options.firstObject.active) return false;
            else if (this.setting.form) return true;
            return false;
        },
    ),

    showDisabledNotification: computed('setting.groupEditableIf', 'worktimegroup', function () {
        if (this.worktimegroup && this.setting.groupEditableIf) {
            return !this.Collector.testNeeds(this.setting.groupEditableIf.needs);
        } else {
            return false;
        }
    }),

    shouldShowOnlyDisable: computed('disableOnlyText', function () {
        if (
            this.setting.disable.findBy('key', this.disableOnlyText.key) &&
            !this.Collector.testNeeds([this.disableOnlyText.key])
        )
            return true;
        return false;
    }),

    showInfo: computed(
        'setting.editingDisabled',
        'showPlaceholder',
        'showSettingOptions',
        'showDisabledNotification',
        function () {
            if (this.setting.editingDisabled) return true;
            if (this.showPlaceholder || this.showSettingOptions || !this.showDisabledNotification)
                return false;
            return true;
        },
    ),

    shouldCheckWarnings: computed('setting.checkWorktimegroupWarnings', 'worktimegroup', function () {
        return this.worktimegroup && this.setting.checkWorktimegroupWarnings;
    }),

    getWarnings: computed('shouldCheckWarnings', 'refreshWarnings', async function () {
        if (this.shouldCheckWarnings) return await this.fetchWarnings();
        return false;
    }),

    actions: {
        filterSettingNeeds(negativeNeeds, setting) {
            if (negativeNeeds)
                return setting.key.includes('!') && !this.Collector.testNeeds([setting.key]);
            else return !setting.key.includes('!') && !this.Collector.testNeeds([setting.key]);
        },
        refreshNeeded() {
            set(this, 'refreshWarnings', true);
            this.refreshNeeded();
        },
        async toggleActive(setting) {
            set(this, 'refreshWarnings', true);
            this.toggleActive(setting);
            this.trackAnalyticsEvent(setting);

        },
        saveCalled() {
            this.saveCalled();
        },
        setSetting(setting, options) {
            this.setSetting(setting, options);
        },
        async save(setting) {
            //const setting = get(this,'setting');
            if (get(setting, 'value')) {
                const old_value = get(setting, 'value');
                let tmp_value = get(setting, 'value');
                if (get(this, 'configs').setting.name === 'date_interval') {
                    if (get(setting, 'value') === '0' || get(setting, 'value') === '1') {
                        tmp_value = 'locked';
                    }
                    if (
                        get(this, 'date_interval_future') &&
                        get(this, 'date_interval_future_check')
                    ) {
                        const val = Math.abs(get(this, 'date_interval_future'));
                        tmp_value += ',' + val;
                    }
                }
                set(setting, 'value', tmp_value);
                await setting.save();
                set(setting, 'value', old_value);
                get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                    autoClear: true,
                });
            }
        },
    },

    async fetchWarnings() {
        set(this, 'refreshWarnings', false);
        return this.ttapi._get('/api/checkSettingWarnings', {
            worktimegroup: this.worktimegroup,
            products: this.setting.checkWorktimegroupWarnings.products.join(','),
        });
    },

    trackAnalyticsEvent(setting) {
        // Format example:
        // category: 'settings',
        // action: 'enabled_setting',
        // label: 'timetracker.auto_pause'
        let originalTitle = setting.title;
        let partString = originalTitle.split('.', 3);
        let title = partString.slice(1).join('.');
        let status = '';
        setting.active === true ? status = 'enabled' : status = 'disabled';

        this.analytics.trackEvent({
            category: 'Settings',
            action: status + '_setting',
            label: title,
        });
    },

});
