import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (is-equal this.dateOptions 'locked') }}<br>\n    <p class=\"date-field-locked-text\">\n        {{this.today}}\n    </p>\n{{else}}\n    <Input\n        @type=\"date\"\n        @min={{this.minDate}}\n        @max={{this.maxDate}}\n        @value={{@value}}\n        @disabled={{@disabled}}\n        @name={{this.name}}\n        @class=\"ember-movenium-date-field\"\n        {{on 'change' (fn @onChange @value)}}\n    />\n{{/if}}\n", {"contents":"{{#if (is-equal this.dateOptions 'locked') }}<br>\n    <p class=\"date-field-locked-text\">\n        {{this.today}}\n    </p>\n{{else}}\n    <Input\n        @type=\"date\"\n        @min={{this.minDate}}\n        @max={{this.maxDate}}\n        @value={{@value}}\n        @disabled={{@disabled}}\n        @name={{this.name}}\n        @class=\"ember-movenium-date-field\"\n        {{on 'change' (fn @onChange @value)}}\n    />\n{{/if}}\n","moduleName":"tt4/components/addon/fields/date.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/date.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

interface IDateField {
    dateInterval: string | null;
    features?: {
        [key: string]: string | number | object | boolean;
    };
}
export default class FieldsDateComponent extends Component<IDateField> {
    public options: any;
    @tracked public disabled: boolean = false;
    @tracked protected selected: string | null = null;

    get dateInterval() {
        return this.args.dateInterval || this.args.features?.date_interval;
    }

    get dateOptions(): string[] | null | string {
        const dateInterval = this.dateInterval;
        if (!dateInterval) return null;
        if (dateInterval === 'locked') return 'locked';

        const arr: string[] = [];

        const splitted: string[] = ('' + dateInterval).split(',');
        let start: number = 0;
        let end: number = 0;
        if (splitted.length > 1) {
            start = Number(splitted[1]) * -1;
            end = Number(splitted[0]);
        } else {
            start = 0;
            end = Number(dateInterval);
        }
        for (let i = start; i < end; i++) {
            const date = moment().subtract(i, 'days');
            arr.push(date.format('YYYY-MM-DD'));
        }

        return arr;
    }

    /**
     * Maximum date for the date picker based on date options
     */
    get maxDate(): string {
        if (!this.dateOptions || typeof this.dateOptions == 'string') return '9999-12-31';
        return this.dateOptions.sort()[this.dateOptions.length - 1];
    }

    /**
     * Minimum date for the date picker based on date options
     */
    get minDate(): string {
        if (!this.dateOptions || typeof this.dateOptions == 'string') return '1900-01-01';
        return this.dateOptions.sort()[0];
    }

    get today(): string {
        return moment().format('dddd L');
    }
}
