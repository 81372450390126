import Component from '@ember/component';

export default Component.extend({
    tagName: 'li',
    actions: {
        action() {
            this.action();
        },
    },
});
