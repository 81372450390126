import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { action, set } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { tracked } from '@glimmer/tracking';

export default class HelpBubbleIconComponent extends Component {
    @tracked showTooltip = false;
    notfoundText = 'No info found';

    get styleNamespace() {
        return podNames['help-bubble-icon'];
    }

    get class() {
        if (this.args.icon) {
            return (
                this.args.icon +
                ' move move-' +
                this.args.icon.charAt(0).toUpperCase() +
                this.args.icon.slice(1)
            );
        } else if (this.args.vismaIcon) {
            return this.args.vismaIcon;
        }
        return 'info move move-Info';
    }

    @action
    async togglePopOver() {
        if (!this.showTooltip) await this.getInfoText();
        this.showTooltip = !this.showTooltip;
    }

    async getInfoText() {
        const key = `${this.args.key.replace(/\./g, '-')}-${moment.locale()}`;
        const fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;
        const response = await fetch(fileUrl);
        if (response.status === 403) return null;

        const html = await response.text();
        set(this, 'infoText', htmlSafe(html));
    }
}
