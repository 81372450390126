import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UpgradeIconComponent extends Component {
    @service session;
    @service intl;
    @service ttapi;
    @service('collector-service') Collector;
    @tracked email_message_sent;
    @tracked sending;
    @tracked showModal;
    @tracked more_info;
    @tracked sender;
    @tracked debug_email;

    constructor() {
        super(...arguments);
        this.email_message_sent = false;
        this.sending = false;
        this.sender = this.session.currentUser.email;
    }

    get styleNamespace() {
        return podNames['upgrade-icon'];
    }

    get isDisabled() {
        if (this.Collector.testNeedsOne('devMode') && !this.debug_email) return true;
        else if (!this.sender) return true;
        return false;
    }

    @action
    async sendEmail() {
        this.sending = true;
        let mail = this.debug_email
            ? this.debug_email
            : this.intl.t('upgrade_service.send-to-email');

        await this.ttapi.request('/sendEmail', {
            method: 'post',
            data: {
                email: mail,
                title:
                    this.intl.t('upgrade_service.email-title') +
                    ' - ' +
                    get(this, 'session.currentUser.company') +
                    ' (' +
                    get(this, 'session.currentUser.partnerid') +
                    ')',
                content:
                    this.intl.t('upgrade_service.email_content') +
                    '\r\n' +
                    this.more_info +
                    '\r\n' +
                    this.sender,
            },
        });
        this.sending = false;
        this.email_message_sent = true;
    }

    @action
    closeModal() {
        this.showModal = false;
        this.sending = false;
        this.email_message_sent = false;
        this.more_info = '';
    }
}
