/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsSavedController;

import Ember from 'ember';

import savedController from '../../../controllers/saved';

ProjectsSavedController = savedController.extend({
    session: Em.inject.service(),
    store: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    //hideAddSame: true
    magicKeyInit: Em.observer('model.id', function () {
        var c;
        c = this.get('Collector');
        if (
            c.testNeedsOne('products.personalliggareandid06') &&
            this.get('session.currentUser.userlevel') !== 1
        ) {
            return this.getMagicKey();
        }
    }),
    getLongUrl: function () {
        var creator, model, projectid, promise, store;
        store = this.get('store');
        creator = this.get('session.currentUser.username');
        if (!creator) {
            return;
        }
        model = this.get('model');
        projectid = model.get('id');
        promise = new Em.RSVP.Promise((resolve, reject) => {
            var url, userid;
            url = '#/register/user?project=' + projectid + '&creator=' + creator;
            userid = this.get('session.currentUser.id');
            return store
                .query('user', {
                    id: userid,
                    sideload: ['employer.name', 'employer.emp_id', 'employer.country'],
                })
                .then(() => {
                    var employer;
                    employer = this.get('session.currentUser.employer');
                    if (employer) {
                        if (!employer.get('country')) {
                            reject({
                                missing: 'employer_country',
                            });
                        }
                        url += '&employer_name=' + encodeURIComponent(employer.get('name'));
                        url += '&employer_id=' + encodeURIComponent(employer.get('emp_id'));
                        url += '&employer_country=' + encodeURIComponent(employer.get('country'));
                        return resolve({
                            longurl: url,
                            employer: employer.get('name'),
                        });
                    } else {
                        return store
                            .query('employer', {
                                main: 'on',
                            })
                            .then((employers) => {
                                employer = employers.get('firstObject');
                                if (employer == null) {
                                    return alert('Main employer is not set. Cannot create help.');
                                } else {
                                    url +=
                                        '&employer_name=' +
                                        encodeURIComponent(employer.get('name'));
                                    url +=
                                        '&employer_id=' +
                                        encodeURIComponent(employer.get('emp_id'));
                                    url +=
                                        '&employer_country=' +
                                        encodeURIComponent(employer.get('country'));
                                    return resolve({
                                        longurl: url,
                                        employer: employer.get('name'),
                                    });
                                }
                            });
                    }
                });
        });
        return promise;
    },
    getMagicKey: function () {
        return this.getLongUrl().then((ret) => {
            var call, longUrl;
            longUrl = window.location.origin + '/' + ret.longurl;
            call = this.get('Collector').ttapi({
                url: 'code/generate',
                data: {
                    longUrl: longUrl,
                },
                method: 'post',
            });
            return call.then((back) => {
                return this.set('magicKey', back.id.slice(-6));
            });
        });
    },
    usersObs: Em.observer('model.id', function () {
        var c, call, groups, model, preselected_users;
        c = this.get('Collector');
        this.set('users', null);
        if (c.formExists('group')) {
            model = this.get('model');
            groups = model.get('group');
            //get users with selected groups
            preselected_users = Em.A([]);
            return this.get('store')
                .query('user', {
                    group: groups.join(),
                })
                .then((groupusers) => {
                    var call;
                    groupusers.forEach((groupuser) => {
                        return preselected_users.push(parseInt(groupuser.id));
                    });
                    call = c.ttapi({
                        url: 'linked_users?forms=project',
                        method: 'get',
                    });
                    return call.then((back) => {
                        var tmpobj;
                        if (back.data) {
                            tmpobj = Em.A([]);
                            back.data.forEach((user) => {
                                if (preselected_users && groups.length > 0) {
                                    if (preselected_users.indexOf(user.id) !== -1) {
                                        return tmpobj.pushObject(Ember.Object.create(user));
                                    }
                                } else {
                                    return tmpobj.pushObject(Ember.Object.create(user));
                                }
                            });
                            return this.set('users', tmpobj);
                        }
                    });
                });
        } else {
            call = c.ttapi({
                url: 'linked_users?forms=project',
                method: 'get',
            });
            return call.then((back) => {
                var tmpobj;
                if (back.data) {
                    tmpobj = Em.A([]);
                    back.data.forEach((user) => {
                        return tmpobj.pushObject(Ember.Object.create(user));
                    });
                    return this.set('users', tmpobj);
                }
            });
        }
    }),
    selectedUsers: Em.A([]),
    actions: {
        forward: function () {
            var model, projectid;
            model = this.get('model');
            projectid = model.get('id');
            return this.transitionToRoute('invite-users', {
                queryParams: {
                    projectid: projectid,
                },
            });
        },
        gotoProjectTasks: function () {
            return this.transitionToRoute('projects.edit.tasks', this.get('model.id'));
        },
        toggleUser: function (user) {
            var id;
            this.set('saving', false);
            this.set('showLinks', true);
            id = user.id;
            if (user.get('selected')) {
                this.get('selectedUsers').removeObject(id);
                user.set('selected', false);
            } else {
                this.get('selectedUsers').pushObject(id);
                user.set('selected', true);
            }
        },
        saveLinks: function () {
            var c, projectids;
            c = this.get('Collector');
            projectids = Em.A([]);
            projectids.push(this.get('model.id'));
            return this.get('selectedUsers').forEach((userid) => {
                this.set('saving', true);
                //get already saved links
                return c.getLinks(userid, 'user', 'projects').then((links) => {
                    //and push them to the projectids array if there is any
                    if (links.projects) {
                        links.projects.forEach((projectlink) => {
                            return projectids.push(projectlink.id.toString());
                        });
                    }
                    return c.saveLinks(userid, 'user', 'projects', projectids).then(
                        (links) => {
                            this.set('saving', false);
                            return this.set('showLinks', false);
                        },
                        (error) => {
                            this.set('saving', false);
                            return this.set('showLinks', false);
                        },
                    );
                });
            });
        },
        cancelLinks: function () {
            return this.set('users', Em.A([]));
        },
    },
});

export default ProjectsSavedController;
