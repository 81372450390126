/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/avi.coffee.old
// Generated by CoffeeScript 2.6.1
var AviMixin;

import Ember from 'ember';

import config from '../config/environment';

AviMixin = Ember.Mixin.create({
    ttapi: Ember.inject.service(),
    getAviData: function (params) {
        return new Em.RSVP.Promise((resolve, reject) => {
            if (params.project) {
                return this.get('ttapi')
                    .request('/avi', {
                        type: 'GET',
                        data: params,
                    })
                    .then((data) => {
                        return resolve(data);
                    });
            } else {
                return resolve({
                    users: [],
                    count: 0,
                });
            }
        });
    },
});

export default AviMixin;
