/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/changeusername/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditChangeusernameRoute;

import Ember from 'ember';

UsersEditChangeusernameRoute = Ember.Route.extend({
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.set('old_username', model.get('username'));
    },
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            controller.set('username_changed', false);
            controller.set('new_username', null);
            controller.set('old_username', null);
            if (controller.get('model.hasDirtyAttributes')) {
                controller.get('model').rollbackAttributes();
            }
        }
        return this._super;
    },
});

export default UsersEditChangeusernameRoute;
