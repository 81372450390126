import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import config from '../../../../../config/environment';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    ajax: service(),
    session: service(),
    ttapi2: service(),
    dialogs: service(),
    intl: service(),

    queryParams: ['imei'],

    imei: null,
    notInUse: alias('model'),

    texts: computed(function () {
        return {
            header: 'Timeclean Overview',
            registeredTo: 'Registered to services',
            commands: 'Commands',
        };
    }),

    imeiOk: computed('imei', function () {
        return get(this, 'imei.length') === 15;
    }),

    registeredTo: computed('imei', function () {
        return get(this, 'ajax').request(
            config.APP.ADMINTOOLURL + 'BI/fetchDeviceServices?imei=' + get(this, 'imei'),
            {
                type: 'GET',
                headers: {
                    Authorization:
                        'Bearer ' + get(this, 'session.session.authenticated.access_token'),
                },
            },
        );
    }),

    commands: computed('imei', function () {
        return get(this, 'ttapi2').request(
            '/at2/jsonapi/commands?filter[imei]=' + get(this, 'imei'),
        );
    }),

    actions: {
        register(partnerid) {
            get(this, 'ajax')
                .request(config.APP.REALTTAPI + '/api/registerTC', {
                    type: 'POST',
                    headers: {
                        Authorization:
                            'Bearer ' + get(this, 'session.session.authenticated.access_token'),
                    },
                    data: { imei: get(this, 'imei'), partnerid: partnerid },
                })
                .then(this.refresh.bind(this));

            set(this, 'showRegisterAdder', false);
        },

        remove(partnerid) {
            get(this, 'dialogs')
                .confirm(get(this, 'intl').t('report.confirm_delete'))
                .then(this.removeNow.bind(this, partnerid));
        },
    },

    refresh() {
        this.notifyPropertyChange('imei');
    },

    removeNow(partnerid, con) {
        if (!con) return;
        get(this, 'ajax')
            .request(
                config.APP.REALTTAPI +
                    '/api/registerTC?imei=' +
                    get(this, 'imei') +
                    '&partnerid=' +
                    partnerid,
                {
                    type: 'DELETE',
                    headers: {
                        Authorization:
                            'Bearer ' + get(this, 'session.session.authenticated.access_token'),
                    },
                },
            )
            .then(this.refresh.bind(this));
    },
});
