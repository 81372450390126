import DS from 'ember-data';

export default DS.Model.extend({
    name: DS.attr('string'),
    columns: DS.attr('string'),
    rows: DS.attr('number'),
    order: DS.attr('string'),
    form: DS.attr('string'),
    user: DS.belongsTo('user', { async: true }),
});
