import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const DevelopmentIntegrationsRoute = Route.extend({
    collectorService: service(),

    model() {
        if (this.collectorService.testNeeds(['integration'])) {
            return this.store.findAll('integration');
        }
    },
});

export default DevelopmentIntegrationsRoute;
