/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/diary-events/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddDiaryEventsRoute;

import Ember from 'ember';

import baseRoute from '../base-route/route';

WorksitediaryReportAddDiaryEventsRoute = baseRoute.extend({
    model: function () {
        var model;
        model = this._super();
        this.store.query('worksitediary', {
            sideload: true,
            project: model.get('project.id'),
        });
        return Ember.RSVP.hash({
            events: this.store.peekAll('worksitediary'),
            parent: model,
        });
    },
});

export default WorksitediaryReportAddDiaryEventsRoute;
