/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/project-info.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectInfoComponent;

import Ember from 'ember';

import config from '../config/environment';

ProjectInfoComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    record: Ember.computed('project', function () {
        return this.get('store').findRecord('project', this.get('project'));
    }),
    tr: Ember.computed('project', function () {
        var c, project;
        c = this.get('Collector');
        project = this.get('project');
        if (c.testNeedsOne('tr')) {
            return this.get('store').query('tr', {
                project: project,
                date:
                    moment().subtract(1, 'months').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
            });
        }
    }),
    tr_mean: Ember.computed('tr', function () {
        var color, mean, trs;
        trs = this.get('tr');
        mean = this.calculateMean(trs, 'tr_rating');
        color = this.getRatingColor(mean);
        return {
            mean: mean.toFixed(0) + '%',
            color: color,
        };
    }),
    mvr: Ember.computed('project', function () {
        var c, project;
        c = this.get('Collector');
        project = this.get('project');
        if (c.testNeedsOne('mvr')) {
            return this.get('store').query('mvr', {
                project: project,
                date:
                    moment().subtract(1, 'months').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
            });
        }
    }),
    mvr_mean: Ember.computed('mvr', function () {
        var color, mean, mvrs;
        mvrs = this.get('mvr');
        mean = this.calculateMean(mvrs, 'mvr_rating');
        color = this.getRatingColor(mean);
        return {
            mean: mean.toFixed(0) + '%',
            color: color,
        };
    }),
    getRatingColor: function (value) {
        if (value >= 95) {
            return 'good-rating';
        } else if (value >= 90) {
            return 'decent-rating';
        } else if (value < 90) {
            return 'bad-rating';
        } else {
            return 'default';
        }
    },
    calculateMean: function (data, field) {
        var total;
        if (data.get('content.meta.count') === 0) {
            return '-';
        }
        total = 0;
        data.forEach(function (item) {
            return (total += item.get(field));
        });
        return total / data.get('content.meta.count');
    },
    events: Em.computed('project', function () {
        var c, project;
        c = this.get('Collector');
        project = this.get('project');
        if (c.testNeedsOne('worksitediary')) {
            return this.get('store').query('worksitediary', {
                project: project,
                date:
                    moment().startOf('week').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('week').format('YYYY-MM-DD'),
                sideload: ['user.firstname', 'user.lastname', 'user.image'],
            });
        }
    }),
    event_fields: Ember.computed(function () {
        var obj;
        obj = {
            first_row: this.hasFields(['title'], 'worksitediary'),
            rows: [
                this.hasFields(['description'], 'worksitediary'),
                this.hasFields(['weather'], 'worksitediary'),
                this.hasFields(['images'], 'worksitediary'),
            ],
        };
        return obj;
    }),
    hasFields: function (fields, form = null) {
        var c, field, i, len, ret;
        c = this.get('Collector');
        if (!form) {
            form = 'user';
        }
        ret = [];
        for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];
            if (c.fieldExists(form, field)) {
                ret.push(field);
            }
        }
        return ret;
    },
    actions: {
        openSkvLink: function () {
            return window.open(
                config.APP.TTAPI +
                    '/personalliggare?partnerid=' +
                    this.get('session.currentUser.partnerid') +
                    '&pl_number=' +
                    this.get('record.skv_number'),
                '_blank',
            );
        },
        toggleMap: function () {
            var elmt;
            elmt = $(this.get('element')).find('.project-info-content')[0];
            this.toggleProperty('show_map');
            return Ember.run.later(
                this,
                function () {
                    return (elmt.scrollTop = elmt.scrollHeight);
                },
                50,
            );
        },
        removeProjectModal: function () {
            return this.set('project', '');
        },
    },
});

export default ProjectInfoComponent;
