import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';

interface IMessAgesTypesPromoComponent {
    content: any;
    close(): void;
}

export default class MessAgesTypesPromoComponent extends Component<IMessAgesTypesPromoComponent> {
    @tracked protected show: boolean = true;

    get iframe_size(): string {
        if (!this.args.content.external_height) {
            return '500';
        } else {
            return this.args.content.external_height;
        }
    }

    get message_content() {
        return htmlSafe(this.args.content.message);
    }

    @action
    closeModal() {
        if (this.args.close) {
            this.args.close();
        }
        return (this.show = false);
    }
}
