/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import format from 'ember-moment/computeds/format';

import momentComputed from 'ember-moment/computeds/moment';

import config from '../../../config/environment';

UsersEditController = GeneralAddController.extend({
    moment: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    userIsNotAdmin: function () {
        var ref;
        return (ref = Number(this.get('model.userlevel'))) !== 5 || Ember.devModeOn === true;
    },
    subMenus: Em.computed('model.userlevel', function () {
        var c, subMenus;
        c = this.get('Collector');
        subMenus = [
            {
                link: 'users.edit.index',
                text: 'menu.edit_profile',
            },
            {
                link: 'users.edit.competence',
                text: 'submenu.user.competences',
                needs: ['!products.personalliggareandid06', 'employer', 'products.cardregister'],
            },
            {
                link: 'users.edit.id06competence',
                text: 'submenu.user.id06competences',
                needs: ['products.personalliggareandid06'],
            },
            {
                link: 'users.edit.orientations',
                text: 'submenu.user.orientations',
                needs: ['orientation'],
            },
            {
                link: 'users.edit.permissions',
                text: 'submenu.user.permissions',
                needs: ['userlevel=4,5', this.userIsNotAdmin.bind(this)],
            },
            {
                link: 'firecards.index',
                text: 'report.users.firecard',
                needs: ['products.firecard', '!userlevel=5'],
            },
            {
                link: 'users.edit.saldo',
                text: 'submenu.user.saldo',
                needs: ['products.saldo'],
            },
            {
                link: 'users.edit.saldos_ad',
                text: 'submenu.user.saldo',
                needs: ['!products.saldo', 'products.mini_entry'],
            },
            {
                link: 'users.edit.saldohistory',
                text: 'report.users.saldohistory',
                needs: ['products.saldo', '!userlevel=5'],
            },
            {
                link: 'users.edit.userdata',
                text: 'report.users.userdata',
                needs: ['userlevel=5'],
            },
            {
                link: 'users.edit.superior_replacements',
                text: 'report.users.superior_replacements',
                needs: ['!userlevel=1', 'products.superior_replacement'],
            },
        ];
        return subMenus.filter(function (item) {
            if (item.needs) {
                return c.testNeeds(item.needs);
            }
            return true;
        });
    }),
    user_created: Ember.computed('model', function () {
        var row_info;
        row_info = this.get('model').get('row_info');
        return row_info.created;
    }),
    saldosInUse: Ember.computed(function () {
        return this.get('Collector').testNeeds(['products.saldo']);
    }),
    currentSaldo: Ember.computed('model', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('Collector')
                    .ttapi({
                        url: 'saldo/get_saldo?userid=' + this.get('model.id'),
                    })
                    .then((back) => {
                        return resolve({
                            value: back['saldo'] / 60,
                            from: back['from'],
                            to: back['to'],
                        });
                    });
            }),
        });
    }),
    usersImage: Ember.computed('model.image', function () {
        var image;
        image = this.get('model.image');
        if (image != null && image.length > 0) {
            if (image[0].url) {
                return config.APP.COLLECTOR_API_URL + '/' + image[0].url;
            } else if (image[0].file) {
                return image[0].file;
            } else {
                return 'img/dyn-icon-24-user-placeholder.svg';
            }
        } else {
            return 'img/dyn-icon-24-user-placeholder.svg';
        }
    }),
    actions: {
        refreshSaldo: function () {
            return this.notifyPropertyChange('currentSaldo');
        },
    },
});

export default UsersEditController;
