/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/my-sites.coffee.old
// Generated by CoffeeScript 2.6.1
var MySitesService;

import Ember from 'ember';

import { getImageFromUrl, resizeImage } from 'tt4/utils/file-util';

MySitesService = Ember.Service.extend({
    localStorageWrapper: Em.inject.service(),
    google: Em.inject.service(),
    session: Em.inject.service(),
    store: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    ttapi: Ember.inject.service(),
    saveToLocalStorage: function (mySitesArray) {
        return this.get('localStorageWrapper').setItemsWithBackend('mySites', mySitesArray);
    },
    loadFromLocalStorage: function () {
        return this.get('localStorageWrapper').getItem('mySites');
    },
    loadFromLocalStorageWithBackend: function () {
        return this.get('localStorageWrapper').getItemWithBackend('mySites');
    },
    hasMySites: function () {
        return this.loadFromLocalStorage().length > 0;
    },
    /*
  project: project record
  item: if given item is modified
  */
    getMySiteObject: function (project, item) {
        var company, image, partnerid, projectimage;
        projectimage = project.get('image');
        // show projects image or if not set get url for google streetview
        if (projectimage != null && projectimage.length > 0) {
            image = projectimage[0].s3url || projectimage[0].url;
        } else if (project.get('image.url')) {
            image = project.get('image.url');
        } else if (project.get('location_map')) {
            // save this image to db so we don't have to call streetview-api everytime
            image = this.get('google').streetViewUrl(JSON.parse(project.get('location_map')));
            new Ember.RSVP.Promise((resolve) => {
                return getImageFromUrl(image).then((file) => {
                    var file_data;
                    file_data = {};
                    file_data.name = 'file';
                    return resizeImage(file, 600, 300, 60).then((resized) => {
                        file_data.file = resized;
                        return resizeImage(resized, 200, 100, 60).then((resized) => {
                            file_data.preview = resized;
                            return resolve(file_data);
                        });
                    });
                });
            })
                .then((file) => {
                    return this.get('ttapi').request('/save_project_image', {
                        type: 'POST',
                        dataType: 'text',
                        data: {
                            projectid: project.get('id'),
                            image: file,
                        },
                    });
                })
                .catch((err) => {
                    return console.log(err);
                });
        } else {
            image = null;
        }
        if (!item) {
            item = {
                id: project.get('id'),
            };
        }
        partnerid = this.get('session.currentUser.services.partnerid');
        company = this.get('session.currentUser.company_info.company_name');
        Em.assert(partnerid, 'currentUser.services.partnerid was empty');
        Em.set(item, 'name', project.get('name'));
        Em.set(item, 'number', project.get('number'));
        Em.set(item, 'image', image);
        Em.set(item, 'partnerid', partnerid);
        Em.set(item, 'company', company);
        Em.set(item, 'used', moment().format('YYYY-MM-DD HH:mm:ss'));
        return item;
    },
    addSite: function (projectid, setToDefault) {
        return this.get('store')
            .findRecord('project', projectid)
            .then((record) => {
                this.addSiteObject(this.getMySiteObject(record));
                if (setToDefault) {
                    return this.setCurrentSite(record);
                }
            });
    },
    addSiteObject: function (object) {
        var mySites;
        mySites = this.loadFromLocalStorage();
        if (mySites.findBy('id', object.id)) {
            return;
        }
        mySites.push(object);
        return this.saveToLocalStorage(mySites);
    },
    // projects can be added with only projectid.. all the other data is loaded when mysites is loading
    addSiteWithJustId: function (projectid) {
        var mySites;
        mySites = this.loadFromLocalStorage();
        if (mySites.findBy('id', projectid)) {
            return;
        }
        mySites.push({
            id: '' + projectid,
        });
        return this.saveToLocalStorage(mySites);
    },
    setCurrentSite: function (siteRecord) {
        this.get('localStorageWrapper').setUserSetting(
            'currentMySite',
            parseInt(siteRecord.get('id'), 10),
        );
        return this.set('session.currentUser.currentMySite', siteRecord);
    },
    removeAllSites: function () {
        return this.get('Collector').ttapi({
            method: 'post',
            url: 'empty_mysites',
        });
    },
});

export default MySitesService;
