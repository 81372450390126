/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/reports/users/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerReportsUsersController;

import Ember from 'ember';

import config from 'tt4/config/environment';

AdmintoolCustomerReportsUsersController = Ember.Controller.extend({
    tools: Ember.inject.service(),
    admintool: Ember.inject.service(),
    ajax: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    init_stuff: function () {
        return this.getData().then((data) => {
            return this.set('users', data);
        });
    },
    searchObserver: Ember.observer('q', function () {
        //run immediatly if empty value
        if (this.get('q') === '') {
            return Ember.run(this, this.doSearch);
        } else {
            return Ember.run.debounce(this, this.doSearch, 1000);
        }
    }),
    userlevel: 5,
    getData: function () {
        var c, promise;
        c = this.get('Collector');
        promise = this.get('admintool').ajax({
            url: 'get_form_data',
            method: 'get',
            data: {
                form: 'user',
                partnerid: this.get('model.partnerid'),
                q: this.get('q'),
                userlevel: this.get('userlevel'),
                order: 'userlevel-',
                sideload: true,
            },
        });
        return promise.then((data) => {
            data.forEach((row) => {
                return (row['userlevel'] =
                    parseInt(row['userlevel']) < 10
                        ? c._format_(row['userlevel'], 'user', 'userlevel')
                        : row['userlevel']);
            });
            return data;
        });
    },
    userlevels: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return [
            {
                id: 1,
                name: intl.t('user.userlevel.1'),
            },
            {
                id: 2,
                name: intl.t('user.userlevel.2'),
            },
            {
                id: 3,
                name: intl.t('user.userlevel.3'),
            },
            {
                id: 4,
                name: intl.t('user.userlevel.4'),
            },
            {
                id: 5,
                name: intl.t('user.userlevel.5'),
            },
            {
                id: 6,
                name: intl.t('user.userlevel.6'),
            },
        ];
    }),
    doSearch: function () {
        return this.getData().then((data) => {
            return this.set('users', data);
        });
    },
    actions: {
        login: function (username) {
            if (
                this.get('model.campaign') === 'visma' ||
                this.get('model.campaign') === 'entry_mini'
            ) {
                return this.get('tools').login(
                    {
                        username: username,
                    },
                    config.APP.ENTRY_URL,
                );
            } else {
                return this.get('tools').login({
                    username: username,
                });
            }
        },
        clearCache: function (username) {
            return this.get('ajax')
                .request(
                    config.APP.TT4CAPIURL +
                        '/system/clear_memcache?partnerid=' +
                        this.get('model.partnerid') +
                        '&username=' +
                        username,
                )
                .then(() => {
                    return this.get('notifications').success(
                        this.get('intl').t('general.user_cache_cleared'),
                        {
                            autoClear: true,
                        },
                    );
                });
        },
        filterChanged: function () {
            return this.doSearch();
        },
    },
});

export default AdmintoolCustomerReportsUsersController;
