import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import EmberObject, { get, set } from '@ember/object';
import { Promise as EmberPromise } from 'rsvp';
import { later } from '@ember/runloop';
import Ember from 'ember';

const MachinesTransactionMoveController = Controller.extend({
    store: service(),
    content: alias('model'),
    pojo: EmberObject.create({
        amount: null,
        project: null,
    }),
    actions: {
        change(value, field) {
            return set(this.pojo, field, value);
        },

        async moveMachines() {
            const pojo = this.pojo;
            const model = this.model;
            const store = this.store;
            if (get(pojo, 'project.id') !== get(model, 'project.id')) {
                const amount = parseInt(model.amount, 10);
                const pojo_amount = parseInt(pojo.amount, 10);
                set(model, 'endtime', moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'));
                let old_project = null;
                const data = await store.query('transaction', {
                    project: get(pojo, 'project.id'),
                    tooltype: get(model, 'tooltype.id'),
                    endtime: '(empty)',
                });
                if (amount - pojo_amount > 0) {
                    old_project = store.createRecord('transaction', {
                        amount: amount - pojo_amount,
                        tooltype: model.tooltype,
                        starttime: moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
                        project: model.project,
                        price: get(model, 'tooltype.price'),
                    });
                }
                let new_amount = pojo_amount;
                if (data.length > 0) {
                    const found_project = data.firstObject;
                    const old_amount = parseInt(found_project.amount, 10);
                    set(found_project, 'endtime', moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'));
                    found_project.save();
                    new_amount += old_amount;
                }

                const new_project = store.createRecord('transaction', {
                    amount: new_amount,
                    tooltype: model.tooltype,
                    starttime: moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
                    project: pojo.project,
                    price: get(model, 'tooltype.price'),
                });

                const save_these = [model.save, new_project.save()];
                if (old_project) {
                    save_these.push(old_project.save());
                }

                await EmberPromise.all(save_these);
                set(this, 'saved', true);
                if (!Ember.testing) {
                    return later(
                        this,
                        () => {
                            set(this, 'saved', false);
                            set(pojo, 'amount', null);
                            set(pojo, 'project', null);
                            return this.transitionToRoute('machines.transactions');
                        },
                        5000,
                    );
                }
            }
        },
    },
});

export default MachinesTransactionMoveController;
