import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"btn-group report-actions actions-right\" data-anchor={{concat @name '-report-action'}}>\n    <button type=\"button\" {{on 'click' (fn this.showInfo)}} class=\"btn btn-secondary glyphicon glyphicon-info-sign info-action-button\" title={{t \"report.actions.show_info\"}}>\n        <EmberPopover \n            @popperContainer=\"body\" \n            @tooltipClass=\"help-tooltip form-report-tooltip\" \n            @event=\"none\" \n            @isShown={{this.isVisible}} \n            @hideOn=\"click\" \n            @side=\"left\"\n            @text={{this.infoText}}\n        />\n    </button>\n</div>\n\n", {"contents":"<div class=\"btn-group report-actions actions-right\" data-anchor={{concat @name '-report-action'}}>\n    <button type=\"button\" {{on 'click' (fn this.showInfo)}} class=\"btn btn-secondary glyphicon glyphicon-info-sign info-action-button\" title={{t \"report.actions.show_info\"}}>\n        <EmberPopover \n            @popperContainer=\"body\" \n            @tooltipClass=\"help-tooltip form-report-tooltip\" \n            @event=\"none\" \n            @isShown={{this.isVisible}} \n            @hideOn=\"click\" \n            @side=\"left\"\n            @text={{this.infoText}}\n        />\n    </button>\n</div>\n\n","moduleName":"tt4/components/addon/form-report/info.hbs","parseOptions":{"srcName":"tt4/components/addon/form-report/info.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import fetch from 'fetch';

export default class FormReportInfoComponent extends Component {
    @tracked isVisible;
    @tracked infoText;

    constructor() {
        super(...arguments);
        this.setInfoText();
    }

    @action showInfo() {
        this.isVisible = !this.isVisible;
    }

    async setInfoText() {
        const key = 'report-help-' + moment.locale();
        const fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;

        let response = await fetch(fileUrl, { dataType: 'text' });
        if (!response.ok) return (this.infoText = `cannot find help for ${key}`);

        this.infoText = htmlSafe(await response.text());
    }
}
