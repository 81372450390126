/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/workday-calendar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorkdayCalendarController;

import Ember from 'ember';

import { inject as service } from '@ember/service';

import EmberObject from '@ember/object';

WorkdayCalendarController = Ember.Controller.extend({
    intl: service(),
    store: service(),
    tools: service(),
    session: service(),
    customerSettings: service(),
    ttapi: service(),
    Collector: service('collector-service'),
    queryParams: ['worktimegroup', 'date'],
    worktimegroup: null,
    date:
        moment().startOf('year').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('year').format('YYYY-MM-DD'),
    daily_checked: true,
    time_based_checked: false,
    customTimeBased: Em.A([]),
    fieldsToBeRemoved: [],
    rowActions: [
        {
            action: 'editRow',
            icon: 'move move-Pencil',
        },
    ],
    showDailyOvertime: Ember.computed('Ember.devModeOn', function () {
        //return false if !Ember.get(Ember, 'devModeOn')
        if (this.get('Collector').testNeeds(['products.overtime', 'serviceCountry@se'])) {
            return true;
        } else {
            return false;
        }
    }),
    sortedWeekdays: Ember.computed('model.weeks.[]', 'worktimegroup', function () {
        var days;
        days = this.get('model.weeks');
        return Ember.A([
            days.filterBy('day', 'monday').get('lastObject'),
            days.filterBy('day', 'tuesday').get('lastObject'),
            days.filterBy('day', 'wednesday').get('lastObject'),
            days.filterBy('day', 'thursday').get('lastObject'),
            days.filterBy('day', 'friday').get('lastObject'),
            days.filterBy('day', 'saturday').get('lastObject'),
            days.filterBy('day', 'sunday').get('lastObject'),
        ]);
    }),
    columns: Ember.computed(function () {
        var cols;
        cols = ['date', 'minutes', 'desc'];
        if (this.get('Collector').testNeedsOne('worktimegroup')) {
            cols.push('worktimegroup');
        }
        if (this.get('Collector').testNeedsOne('products.midweek_holiday_pay')) {
            cols.push('midweek_holiday_pay');
            cols.push('midweek_holiday_length');
        }
        cols.push('type');
        return cols;
    }),
    overtimeColumns: ['name', 'rules'],
    translated_columns: Ember.computed(function () {
        var col, i, len, ref, translated;
        translated = [];
        ref = this.get('overtimeColumns');
        for (i = 0, len = ref.length; i < len; i++) {
            col = ref[i];
            translated.push('overtimerule.' + col);
        }
        return translated;
    }),
    ownOvertimeColumns: [],
    worktimegroupfield: Ember.computed('worktimegroup', function () {
        var field;
        field = this.get('Collector').field('user', 'worktimegroup');
        field.value = this.get('worktimegroup');
        return field;
    }),
    calculateSum: Ember.computed('sortedWeekdays', function () {
        var max_sum, sum, weekdays;
        weekdays = this.get('sortedWeekdays');
        sum = 0;
        max_sum = 0;
        weekdays.forEach((day) => {
            if (day) {
                sum += day.get('minutes');
                return (max_sum += day.get('max_minutes'));
            }
        });
        return EmberObject.create({
            sum: sum,
            max_sum: max_sum,
        });
    }),
    calculateDayLength: Ember.computed(
        'editingWeekRule.starttime',
        'editingWeekRule.endtime',
        function () {
            var end, length, minutes, start;
            if (this.get('editingWeekRule.endtime') && this.get('editingWeekRule.starttime')) {
                end = moment(this.get('editingWeekRule.endtime'), 'HH:mm');
                start = moment(this.get('editingWeekRule.starttime'), 'HH:mm');
                length = moment.duration(end.diff(start));
                minutes = length.asMinutes();
                if (
                    this.get('Collector').testNeedsOne('worktime') &&
                    this.get('Collector').testNeedsOne('worktime.pause')
                ) {
                    return this.getPause().then((pause) => {
                        if (pause) {
                            minutes -= pause;
                        }
                        if (minutes > 0) {
                            return this.set('editingWeekRule.minutes', minutes);
                        } else {
                            return this.set('editingWeekRule.minutes', 0);
                        }
                    });
                } else {
                    if (minutes > 0) {
                        return this.set('editingWeekRule.minutes', minutes);
                    } else {
                        return this.set('editingWeekRule.minutes', 0);
                    }
                }
            }
        },
    ),
    getPause: function () {
        var configs, setting_params, worktimegroup;
        worktimegroup = this.get('editingWeekRule.worktimegroup.id');
        setting_params = {
            name: 'default_value',
            value: 30,
        };
        if (this.get('editingWeekRule.worktimegroup.id')) {
            setting_params.condition = '$userProfile->worktimegroup==' + worktimegroup;
        }
        configs = {
            form: 'worktime',
            field: 'pause',
            setting: setting_params,
        };
        return this.get('store')
            .findAll('form', {
                reload: true,
            })
            .then((forms) => {
                var field, form, setting;
                form = forms.filterBy('external_type', configs.form);
                field = form.get('firstObject.fields').filterBy('external_type', configs.field);
                if (field.get('length') > 0) {
                    setting = field
                        .get('firstObject.settings')
                        .filterBy('name', configs.setting.name);
                    setting = setting.filter((item) => {
                        var arr;
                        if (worktimegroup) {
                            if (!item.get('condition')) {
                                return false;
                            }
                            arr = item.get('condition').split('==');
                            return arr[1] === worktimegroup;
                        } else {
                            return item.get('condition') === '';
                        }
                    });
                    if (setting.get('firstObject')) {
                        return setting.get('firstObject').value;
                    } else {
                        configs.setting.field = field.get('firstObject');
                        return this.get('store').createRecord(
                            'form-field-setting',
                            configs.setting,
                        );
                    }
                } else {
                    return false;
                }
            });
    },
    isDisabled: function (day) {
        if (day === 'saturday') {
            return this.fiveDayVacationWeekIsOn();
        }
        if (day === 'sunday') {
            return true;
        }
        return false;
    },
    fiveDayVacationWeekIsOn: function (day) {
        var mainSettingOn, setting, settingsPromise;
        mainSettingOn = this.customerSettings.checkSetting('vacation_week_length');
        if (!this.Collector.testNeedsOne('worktimegroup') || !this.worktimegroup || mainSettingOn) {
            return mainSettingOn;
        }
        setting = '';
        settingsPromise = this.get('ttapi')._get('/settings?worktimegroup=' + this.worktimegroup);
        return settingsPromise.then((result) => {
            var vacation_week_length;
            vacation_week_length = result.findBy('key', 'vacation_week_length');
            if (!vacation_week_length) {
                return false;
            }
            if (vacation_week_length.value === 'off') {
                return false;
            }
            return true;
        });
    },
    actions: {
        showDayRule: function (date, day) {
            const isoWeekday = moment(date).isoWeekday() - 1; // use isoWeekday to get weekdays not affected by locale
            var defaultDayLength, params;
            if (day && !Ember.get(day, 'own')) {
                params = {
                    date: Ember.get(day, 'date'),
                    minutes: Ember.get(day, 'minutes'),
                    desc: Ember.get(day, 'desc'),
                    own: true,
                    midweek_holiday_pay: false,
                    midweek_holiday_length: null,
                    absence_minutes: null,
                };
                if (this.get('worktimegroup')) {
                    params.worktimegroup = this.get('store').peekRecord(
                        'worktimegroup',
                        this.get('worktimegroup'),
                    );
                }
                day = this.get('store').createRecord('workday-day', params);
            } else if (day == null) {
                params = {};
                if (this.get('worktimegroup')) {
                    params.worktimegroup = this.get('store').peekRecord(
                        'worktimegroup',
                        this.get('worktimegroup'),
                    );
                }
                if (date) {
                    params.date = date;
                    defaultDayLength = Ember.get(this.get('sortedWeekdays')[isoWeekday], 'minutes');
                    params.minutes = defaultDayLength;
                    params.absence_minutes = Ember.get(
                        this.get('sortedWeekdays')[isoWeekday],
                        'absence_minutes',
                    );
                    if (this.get('Collector').testNeedsOne('products.midweek_holiday_pay')) {
                        params.midweek_holiday_length = defaultDayLength;
                        params.midwdeek_holiday_pay = true;
                    }
                }
                day = this.get('store').createRecord('workday-day', params);
            }
            if (day.absence_minutes === null) {
                day.absence_minutes = day.minutes;
            }
            return this.set('dayRule', day);
        },
        closeModal: function () {
            var day;
            day = this.get('dayRule');
            day.rollbackAttributes();
            return this.set('dayRule', false);
        },
        closeWeekModal: function () {
            var day;
            day = this.get('editingWeekRule');
            day.rollbackAttributes();
            return this.set('editingWeekRule', false);
        },
        editWeekRule: function (rule) {
            var c, record, weekend, worktimegroup;
            c = this.get('Collector');
            record = false;
            weekend = ['saturday', 'sunday'];
            if (
                !rule.get('own') ||
                (rule.get('own') && this.get('worktimegroup') && !rule.get('worktimegroup.content'))
            ) {
                worktimegroup =
                    c.testNeedsOne('worktimegroup') && this.get('worktimegroup')
                        ? this.get('store').peekRecord('worktimegroup', this.get('worktimegroup'))
                        : void 0;
                record = this.get('store').createRecord('workday-week', {
                    day: rule.get('day'),
                    starttime: rule.get('starttime'),
                    endtime: rule.get('endtime'),
                    minutes: rule.get('minutes'),
                    max_minutes: rule.get('max_minutes') || 0,
                    absence_minutes:
                        rule.absence_minutes === null && !weekend.includes(rule.day)
                            ? rule.minutes
                            : rule.absence_minutes,
                    worktimegroup: worktimegroup,
                    own: true,
                    overtimerule: null,
                });
            }
            if (rule.absence_minutes === null && !weekend.includes(rule.day)) {
                rule.absence_minutes = rule.minutes;
            }
            this.set('selectedOvertimeRule', rule.get('overtimerule.id'));
            this.set('editingWeekRule', record || rule);
            return this.set('vacationIsDisabled', this.isDisabled(rule.day));
        },
        filterChanged: function (value, field) {
            if (field === 'worktimegroup' && value) {
                value = value.get('id');
            }
            return this.set(field, value);
        },
        setValue: function (field, value) {
            this.set(field, value);
            if (field.includes('starttime') || field.includes('endtime')) {
                return this.calculateDayLength;
            }
        },
        showOvertimeModal: function () {
            var row;
            row = this.get('store').createRecord('workdayOvertimeRule', {
                text: '',
                rules: [],
            });
            return this.set('editOvertimeRow', row);
        },
        closeOvertimeModal: function () {
            this.set('editingOvertimeRule', false);
            return this.set('addingOvertimeRule', false);
        },
        editRow: function (row) {
            return this.set('editOvertimeRow', row);
        },
        saveOvertimeRule: function (rules) {
            var row;
            row = this.get('editOvertimeRow');
            row.set('rules', JSON.stringify(rules));
            return row.save().then(() => {
                this.set('editOvertimeRow', null);
                return this.send('refreshModel');
            });
        },
        valueChanged: function (val) {
            var rule, weekRule;
            weekRule = this.get('editingWeekRule');
            console.log(this.get('selectedOvertimeRule'));
            if (this.get('selectedOvertimeRule')) {
                rule = this.get('store').peekRecord(
                    'workday-overtimeRule',
                    this.get('selectedOvertimeRule'),
                );
                return weekRule.set('overtimerule', rule);
            } else {
                return weekRule.set('overtimerule', null);
            }
        },
        changeYear: function (year) {
            var newYearParams;
            newYearParams = year + '-01-01_' + year + '-12-31';
            return this.set('date', newYearParams);
        },
    },
});

export default WorkdayCalendarController;
