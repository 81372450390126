import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import collector from 'tt4/classes/collector';
import moment from 'moment';
import podNames from 'ember-component-css/pod-names';
import { later } from '@ember/runloop';

export default class PresenceClockcardComponent extends Component {
    @service store;
    @service session;
    @service intl;
    @service dialogs;
    @service('collector-service') collector;
    @service evented;

    @tracked model = null;
    @tracked selectedProject = null;
    @tracked inActive = false;
    @tracked outActive = true;
    @tracked inoutLoading = false;
    @tracked timeout = false;
    @tracked seconds = 0;
    @tracked apiError = null;
    @tracked loading = false;

    constructor() {
        super(...arguments);
        this.initPresence();
    }

    get styleNamespace() {
        return podNames['presence-clockcard'];
    }

    async initPresence() {
        this.loading = true;
        let dateRange = `${moment().subtract(1, 'weeks').format('YYYY-MM-DD')}_${moment().format(
            'YYYY-MM-DD',
        )}`;
        let data = await this.store.query('presence', {
            date: dateRange,
            user: 'self',
            sideload: true,
            out: '(empty)',
            status: 'open',
        });

        let firstObject = get(data, 'firstObject');
        if (firstObject) {
            this.model = firstObject;
            this.inActive = true;
            this.outActive = false;
        } else {
            this.model = this.store.createRecord('presence', {
                date: moment().format('YYYY-MM-DD'),
            });
        }
        this.selectedProject = this.model.project;
        this.checkPresenceOver16h();
        this.loading = false;
    }

    get location() {
        return collector.getFormObject('presence.location');
    }

    get filter() {
        return { presence_project: 'on' };
    }

    get projectField() {
        let field = this.collector.fieldArray('presence', ['project'])[0];
        field.value = this.model?.project;
        field.filterOptions = { presence_project: 'on' };
        return field;
    }

    get mySiteSelected() {
        return this.session.currentUser.currentMySite && this.outActive;
    }

    get projectDisabled() {
        return this.inActive ? 'disabled' : false;
    }

    get forceLocation() {
        return (
            collector.getFormObject('rojectroject.force_location') &&
            this.session.currentUser.userlevel !== '5'
        );
    }

    get showEndLocation() {
        return collector.getFormObject('presence.end_location');
    }

    async getIn(row) {
        if (!this.inActive) {
            let project = await this.store.findRecord('project', this.selectedProject.id, {
                reload: true,
            });
            if (!project.presence_project) {
                this.dialogs.alert(this.intl.t('presence.not_an_pl_project'));
                this.inActive = false;
                this.outActive = true;
                this.inoutLoading = false;
            } else {
                row.project = project;
                await this.saveRow(row, 'in');
                this.inActive = true;
                this.outActive = false;
            }
        }
    }
    async getOut(row) {
        if (!this.outActive) {
            await this.saveRow(row, 'out');
            this.inActive = false;
            this.outActive = true;
        }
    }
    async saveRow(row, status) {
        try {
            this.apiError = null;
            if (status === 'out') {
                set(row, 'in', undefined);
                set(row, 'out', moment().format('HH:mm'));
            } else if (status === 'in') {
                set(row, 'in', moment().format('HH:mm'));
                set(row, 'date', moment().format('YYYY-MM-DD'));
            } else if (status === 'error') {
                set(row, 'in', undefined);
                set(row, 'status', 'error_out');
            }

            await row.save();
            this.evented.storeEvent('insert', 'presence', null);

            if (status === 'out' || status === 'error') {
                let newrow = this.store.createRecord('presence', {
                    date: moment().format('YYYY-MM-DD'),
                    project: this.selectedProject,
                    in: moment().format('HH:mm'),
                });
                this.model = newrow;
            }
        } catch (error) {
            // Handle and log the error appropriately
            this.apiError = `An error occurred: ${error.message || JSON.stringify(error)}`;
        } finally {
            this.inoutLoading = false;
        }
    }
    checkPresenceOver16h() {
        if (!this.outActive) {
            // Only perform check if not already out
            let now = moment();
            let inDateTime = moment(`${this.model.date} ${this.model.in}`);
            let diff = now.diff(inDateTime, 'hours');

            if (diff >= 16) {
                this.dialogs
                    .alert(this.intl.t('presence.over16h_automatic_checkout'))
                    .then(this.handleAutomaticCheckout.bind(this));
            }
        }
    }

    handleAutomaticCheckout() {
        this.saveRow(this.model, 'error');
        this.inActive = false;
        this.outActive = true;
    }

    updateSecs() {
        if (this.seconds > 0) {
            later(() => {
                this.seconds--;
                this.updateSecs();
            }, 1000);
        }
    }
    @action
    async worksitePresence(value) {
        if (this.timeout) return;

        this.inoutLoading = true;
        this.timeout = true;
        this.seconds = 5;
        this.updateSecs();

        later(() => (this.timeout = false), 5000);

        if (value === 'in') {
            await this.getIn(this.model);
        } else if (value === 'out') {
            await this.getOut(this.model);
        }
        this.inoutLoading = false;
    }

    @action
    setProject(project) {
        this.selectedProject = project;
    }
}
