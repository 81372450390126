import Service from '@ember/service';
import { inject as service } from '@ember/service';
import collector from '../classes/collector';
import { Promise as EmberPromise, allSettled } from 'rsvp';
import Evented from '@ember/object/evented';
import { get, set } from '@ember/object';
import 'moment-timezone';
import config from 'tt4/config/environment';
import { configureScope } from '@sentry/browser';
import { bind } from '@ember/runloop';
import chatBot from 'tt4/utils/chatBot';
import snowplowinit from 'tt4/utils/snowplowinit';
import initStonly from 'tt4/utils/stonly';

/*global FirebasePlugin*/

export default Service.extend(Evented, {
    capi: service(),
    store: service(),
    session: service(),
    Collector: service('collector-service'),
    moment: service(),
    loadTranslations: service(),
    customerSettings: service('customer-settings'),
    userSettings: service(),
    router: service(),
    gps: service(),
    analytics: service(),
    metrics: service(),
    flags: service(),
    modelCreator: service('model-creator'),
    forms: service(),
    dap: service(),
    notifications: service(),

    appReady: false,

    start() {
        return new EmberPromise(async (resolve) => {
            const isLogged = get(this, 'session.session.authenticated.access_token') ? true : false;
            const promises = [];
            // set default lang by localStorage or browser default lang
            this.setDefaultLang(isLogged);

            // fetch translations
            promises.push(this.loadLangs());
            if (isLogged) {
                // fetch me2
                const me2Promise = this.getMe2().then(this.createFromsAndSetInformation.bind(this));
                promises.push(this.initFlags(me2Promise));
                promises.push(me2Promise);
                promises.push(this.getCustomerSettings());
                promises.push(this.configureSentry(me2Promise));
                allSettled(promises).then(this.configureChatBot.bind(this, me2Promise));
                allSettled(promises).then(this.configureSnowPlow.bind(this, me2Promise));
            }
            // after /me2 and langs are settled everything is ready for loading first route
            allSettled(promises).then(this.allDoneMarkAppReady.bind(this, isLogged, resolve));
            allSettled(promises).then(this.addStonly.bind(this));
        });
    },

    configureSnowPlow(me2Promise) {
        if (this.flags.test('use-snowplow')) {
            snowplowinit(me2Promise);
        }
    },

    configureChatBot(me2Promise) {
        me2Promise.then((me2) => {
            chatBot(me2, this.flags);
        });
    },

    addStonly() {
        if (this.flags.test('stonly-user-guidance')) {
            initStonly(config.APP.STONLY_WID);
        }
    },

    async configureSentry(me2Promise) {
        const data = await me2Promise;

        return configureScope((scope) => {
            scope.setUser({
                id: data.userid,
                service: data.company_info.company_name,
            });
            scope.setTag('environment', config.environment);
            scope.setExtra('products', JSON.stringify(collector.products));
            scope.setExtra('sentryIsEnabled', this.flags.test('sentry-enabled'));
        });
    },
    // initialize launch darkly feature flags
    initFlags(data) {
        return data.then((userdata) => {
            return get(this, 'flags').initialize(userdata);
        });
    },
    allDoneMarkAppReady(isLogged, resolve) {
        if (isLogged) {
            get(this, 'gps').startIfGpsIsOn();
            get(this, 'dap').start();
            set(this, 'appReady', true);
            this.trigger('appReady');
        }

        this.handleAppLinks();

        this.initMetrics();
        resolve();
    },

    /**
     * This is used to handle app links from outside of the app
     *
     * there are two possible scenarios:
     *
     * 1) app was not runinng before and index.html couldn't use window.globalInitAppServiceGotoRoute
     *      method .. so index.html just saves the app link url to global globalInitAppServiceGotoUrl
     *      and this initialization makes the transition to correct route
     * 2) App Link was used when app was already running .. so index.html could useglobalInitAppServiceGotoRoute
     *      and nothing more needs to be done
     */
    handleAppLinks() {
        window.globalInitAppServiceGotoRoute = this.gotoRoute.bind(this);

        if (window.globalInitAppServiceGotoUrl) this.gotoRoute(window.globalInitAppServiceGotoUrl);
    },

    /**
     * All different kind of app link paths must be registered here
     * @param {*} urlString
     * @returns
     */
    gotoRoute(urlString) {
        // cannot use URL class because it works differenty in safari and chrome
        const splittedUrl = urlString.split(':');

        // Extract components
        const protocol = splittedUrl[0] + ':';
        const path = splittedUrl[1].substring(2); // remove leading '//'

        if (protocol !== 'devenor:') {
            this.notifications.error(`unknown applink protocol: '${protocol}'`, {
                autoClear: true,
            });
            return `unknown applink protocol: '${protocol}'`;
        }

        const pathParts = path.split('/');

        // different routes needs to be registered here .. reason for this is that
        // I do not know how to do a general route transition with query params
        if (pathParts[0] === 'token') {
            this.router.transitionTo(pathParts[0], pathParts[1]);
        } else {
            this.notifications.error(`unknown path: '${urlString}'`, {
                autoClear: true,
            });
            return `unknown path: '${urlString}'`;
        }
    },

    createFromsAndSetInformation(data) {
        function preventMouseWheelScrollOnNumberFields() {
            document.addEventListener('wheel', function () {
                if (document.activeElement.type === 'number') {
                    document.activeElement.blur();
                }
            });
        }

        // Call the function to add the event listener
        preventMouseWheelScrollOnNumberFields();

        // load forms and create models
        // this must be always done first so store can be used by other init's
        this.createForms(data['forms']);

        // push currentUser to store
        this.pushCurrentUser(data['userrow']);

        // push companyInfo to store
        this.pushCompanyInfo(data['company_info']);

        // set some session variables to support all kind of legacy code in tt4
        this.setSessionVariables(data);

        this.setAnalyticsContext();

        // Put trigger on user models didUpdate to update user info to me2 cache
        if (data['userrow'].user) {
            this.updateCachedUserOnSave(data['userrow'].user[0].id);
        }

        // set correct timeZone by service country
        this.setTimezone(data);

        this.checkCompanyInfo();

        this.userSettings.setUserSettings();

        return data;
    },

    clearInit() {
        this.unsetStorage('me2');
        this.unsetStorage('customerSettings');
    },

    createForms(data) {
        get(this, 'modelCreator').createModels(collector.addCommonForms(data));
        //ember-movenium addon
        this.forms.forms = collector.addCommonForms(data).forms;
    },

    getMe2() {
        return new EmberPromise((resolve) => {
            let alreadyResolved = false;

            if (this.getStorage('me2')) {
                resolve(this.getStorage('me2'));
                alreadyResolved = true;
            }

            const prevent_caching = '&sid=' + Math.random().toString(36).substring(14);

            get(this, 'capi')
                .request('me2?get=all' + prevent_caching)
                .then((data) => this._resolveGetMe2(data, alreadyResolved, resolve));
        });
    },

    updateDataFromMe2(data) {
        // TODO: here is just one of these .. also company_info etc should be updated
        const currentUser = get(this, 'store').peekRecord('user', data.userid);
        if (currentUser) {
            set(currentUser, 'firstname', data['userrow']['user'][0]['firstname']);
            set(currentUser, 'lastname', data['userrow']['user'][0]['lastname']);
        }

        // recreate forms
        this.createForms(data['forms']);
    },

    updateCachedUserOnSave(id) {
        get(this, 'store')
            .peekRecord('user', id)
            .on('didUpdate', () => {
                let me2 = this.getStorage('me2');
                const user = get(this, 'store').peekRecord('user', id);
                me2.userrow.user = [user.serialize({ includeId: true })];
                this.setStorage('me2', me2);
            });
    },
    pushCurrentUser(rows) {
        get(this, 'store').pushPayload(rows);
    },

    pushCompanyInfo(row) {
        get(this, 'store').pushPayload({ companyInfo: row });
    },

    setSessionVariables(data) {
        set(this, 'session.saveUserId', data.userid);
        set(this, 'session.currentUser', get(this, 'store').peekRecord('user', data.userid));
        // signed_token is not used any more because all tokens are jwt
        //set(this, "session.signed_token", data.signed_token)
        // parse partnerid to int if it seems to be numeric
        const partnerid = isNaN(data.partnerid) ? data.partnerid : parseInt(data.partnerid, 10);
        set(this, 'session.currentUser.partnerid', partnerid);

        set(
            this,
            'session.currentUser.company',
            get(get(this, 'store').peekAll('companyInfo'), 'firstObject.company_name'),
        );
        set(
            this,
            'session.currentUser.company_info',
            get(get(this, 'store').peekAll('companyInfo'), 'firstObject'),
        );

        set(this, 'session.currentUser.services', {
            linkings: data.linkings,
            partnerid: partnerid,
        });

        set(this, 'session.modelscreated', true);
        set(this, 'session.session.alldone', true);
    },

    setTimezone(data) {
        if (data.country == 'se') get(this, 'moment').changeTimeZone('Europe/Stockholm');
        else get(this, 'moment').changeTimeZone('Europe/Helsinki');
    },

    /**
     * 1) call ttapi to get saved language
     * 2) if lang type is not "saved" in response -> user does not have saved lang yet .. so send users lang to ttapi .. then return
     * 3) if lang type is saved and saved lang is different that in localstorage -> change tt4 lang
     */
    async fetchSavedLanguageFromTtapi(lang) {
        // 1.
        const ttapiLang = await this.Collector.ttapi({
            url: 'api/getUserLang',
            method: 'get',
        });
        // 2.
        if (ttapiLang.type != 'saved') {
            return this.Collector.ttapi({
                url: 'api/setUserLang?lang=' + lang,
                method: 'post',
            });
        }
        // 3.
        // TODO
    },

    async setDefaultLang(isLogged) {
        let defaultLang = 'en';
        const acceptedLangs = ['en', 'fi', 'sv'];
        let setLang = null;
        if (Modernizr.localstorage && localStorage.getItem('lang')) {
            setLang = localStorage.getItem('lang');
        } else {
            const browser = window.navigator.userLanguage || window.navigator.language;
            setLang = browser.substring(0, 2).toLowerCase();
        }
        if (acceptedLangs.indexOf(setLang) !== -1) {
            moment.locale(setLang);
            get(this, 'moment').setLocale(setLang);
        } else {
            moment.locale(defaultLang);
            get(this, 'moment').setLocale(defaultLang);
        }

        if (isLogged) this.fetchSavedLanguageFromTtapi(moment.locale());
    },

    loadLangs() {
        return get(this, 'loadTranslations').fetch(true);
    },

    getCustomerSettings() {
        if (this.getStorage('customerSettings')) {
            get(this, 'customerSettings').settings = this.getStorage('customerSettings');
            let promise = get(this, 'customerSettings').getSettings();
            promise.then(() => {
                this.setStorage('customerSettings', get(this, 'customerSettings').settings);
            });
            return new EmberPromise((resolve) => {
                resolve();
            });
        } else {
            let promise = get(this, 'customerSettings').getSettings();
            promise.then(() => {
                this.setStorage('customerSettings', get(this, 'customerSettings').settings);
            });
            return promise;
        }
    },

    getStorage(key) {
        if (!Modernizr.localstorage) return null;

        if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key));
        }

        return null;
    },

    setStorage(key, object) {
        if (!Modernizr.localstorage) return;
        localStorage.setItem(key, JSON.stringify(object));
    },

    unsetStorage(key) {
        if (!Modernizr.localstorage) return;
        localStorage.removeItem(key);
    },

    setAnalyticsContext() {
        const currentUser = get(this, 'session.currentUser');
        set(this, 'metrics.context.dimension4', config.brand);
        set(this, 'metrics.context.dimension3', get(currentUser, 'partnerid').toString());
        set(this, 'metrics.context.dimension2', get(currentUser, 'company_info.company_name'));
        set(this, 'metrics.context.dimension1', get(currentUser, 'userlevel'));

        if (window.cordova && FirebasePlugin) {
            FirebasePlugin.setAnalyticsCollectionEnabled(true);
            FirebasePlugin.setUserProperty('brand', config.brand);
            FirebasePlugin.setUserProperty('partnerid', get(currentUser, 'partnerid').toString());
            FirebasePlugin.setUserProperty(
                'company_name',
                get(currentUser, 'company_info.company_name'),
            );
            FirebasePlugin.setUserProperty('userlevel', get(currentUser, 'userlevel').toString());
        }
    },

    checkCompanyInfo() {
        if (get(this, 'session.currentUser.userlevel') === '5') {
            //check that company country is not null
            const companyInfo = get(get(this, 'store').peekAll('companyInfo'), 'firstObject');
            if (companyInfo && !get(companyInfo, 'country')) {
                set(companyInfo, 'country', 'fi');
                companyInfo.save();
            }
        }
    },

    _resolveGetMe2(data, alreadyResolved, resolve) {
        this.setStorage('me2', data);

        // if there is differences in /me request response and ls .. we make updates
        if (alreadyResolved) this.updateDataFromMe2(data);

        if (!alreadyResolved) resolve(data);
    },

    initMetrics() {
        if (config.environment !== 'test') {
            this.router.on('routeDidChange', bind(this, 'trackPage'));
        }
    },

    trackPage() {
        const router = this.router;
        const page = router.currentURL;
        const title = router.currentRouteName || 'unknown';
        this.analytics.trackPage({ page, title });
    },
});
