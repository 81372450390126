/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/reports/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

OrientationRoute = GeneralReportRoute.extend({
    form: 'orientation',
    queryParams: {
        ori_date: {
            refreshModel: true,
            replace: true,
        },
        page: {
            refreshModel: true,
        },
        ori_site: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        //params.ori_date = params.date
        //delete params.date
        if (params.project) {
            if (params.project !== 'null') {
                params.ori_site = params.project;
            }
        }
        delete params.project;
        params.sideload = true;
        return this.store.query('orientation', params);
    },
});

export default OrientationRoute;
