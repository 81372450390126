/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/gps-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsGpsFieldComponent;

import Ember from 'ember';

import textInput from '../text-field/component';

CollectorFieldsGpsFieldComponent = textInput.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    showDistanceError: false,
    shortestFound: false,
    errorMsg: false,
    error_arr: Em.A([]),
    fetchLocation: Em.on('init', function () {
        var id, options, self;
        self = this;
        if (!this.get('model.isNew')) {
            return;
        }
        options = {
            enableHighAccuracy: true,
            timeout: 50000,
            maximumAge: 5000,
        };
        if (navigator.geolocation) {
            id = navigator.geolocation.watchPosition(
                function (location) {
                    var loc, project;
                    loc = {};
                    loc.latitude = location.coords.latitude;
                    loc.longitude = location.coords.longitude;
                    loc.accuracy = location.coords.accuracy;
                    self.set('gps_location', JSON.stringify(loc));
                    self.set('value', JSON.stringify(loc));
                    if (self.get('distance') === null) {
                        project = self.get('model.project');
                        if (project) {
                            return self
                                .get('store')
                                .findRecord('project', project.get('id'), {
                                    reload: true,
                                })
                                .then((record) => {
                                    var forced, proj_loc, value;
                                    value = self.get('value');
                                    proj_loc = record.get('location_map');
                                    forced = record.get('force_location');
                                    if (proj_loc && value) {
                                        return self.calculateDistance(proj_loc, value, forced);
                                    } else {
                                        return self.set('distance', null);
                                    }
                                });
                        } else {
                            return self.set('distance', null);
                        }
                    }
                },
                function (error) {
                    var errorKey;
                    console.log(error);
                    if (error.code === error.PERMISSION_DENIED) {
                        errorKey = 'permissindenied';
                    } else if (error.code === error.POSITION_UNAVAILABLE) {
                        errorKey = 'poisition_unavailable';
                    } else if (error.code === error.TIMEOUT) {
                        errorKey = 'timeout';
                    } else {
                        errorKey = 'unknown_error';
                    }
                    return self.set('errorMsg', self.intl.t('gps.errors.' + errorKey));
                },
                options,
            );
            return this.set('watchID', id);
        } else {
            return this.set('notSupported', true);
        }
    }),
    removeWatch: Em.on('willDestroyElement', function () {
        return navigator.geolocation.clearWatch(this.get('watchID'));
    }),
    locObs: Ember.observer('gps_location', function () {
        var distance;
        if (!(typeof this.get === 'function' ? this.get('first_location') : void 0)) {
            this.set('first_location', this.get('gps_location'));
        }
        //calc dist
        distance = this.calcDist(this.get('gps_location'), this.get('first_location'));
        //if distance is changed over 100m
        if (distance > 100) {
            //copy present location to temp pos
            this.set('first_location', this.get('gps_location'));
            //set distance null, so that everything will be calculated again
            return this.set('distance', null);
        }
    }),
    //listen changes in address fields
    projectObserver: Ember.observer('model.project', function () {
        return Ember.run.once(this, this.processProjectChange);
    }),
    processProjectChange: function () {
        var id, value;
        id = this.get('model.project.id');
        this.set('showDistanceError', false);
        this.set('distance', null);
        if (id) {
            value = this.get('value');
            return this.get('store')
                .findRecord('project', id, {
                    reload: true,
                })
                .then((record) => {
                    var forced, proj_loc;
                    proj_loc = record.get('location_map');
                    forced = record.get('force_location');
                    if (proj_loc && value) {
                        return this.calculateDistance(proj_loc, value, forced);
                    } else {
                        return this.set('distance', null);
                    }
                });
        } else {
            return this.set('distance', null);
        }
    },
    //update distance in value when distance is changed
    distanceObs: Em.observer('distance', 'value', function () {
        var loc;
        if (!this.get('distance')) {
            if (this.get('value')) {
                loc = JSON.parse(this.get('value'));
                if (loc && loc.distance) {
                    delete loc.distance;
                }
                return this.set('value', JSON.stringify(loc));
            }
        }
    }),
    calcDist: function (loc1, loc2) {
        var loc1_latlng, loc1_parsed, loc2_latlng, loc2_parsed;
        loc1_parsed = JSON.parse(loc1);
        loc2_parsed = JSON.parse(loc2);
        loc1_latlng = new window.google.maps.LatLng(loc1_parsed.latitude, loc1_parsed.longitude);
        loc2_latlng = new window.google.maps.LatLng(loc2_parsed.latitude, loc2_parsed.longitude);
        return window.google.maps.geometry.spherical.computeDistanceBetween(
            loc1_latlng,
            loc2_latlng,
        );
    },
    calculateDistance: function (project_loc, present_loc, forced) {
        var dist_txt, distance, loc, pres_latlng, pres_loc, proj_latlng, proj_loc;
        proj_loc = JSON.parse(project_loc);
        pres_loc = JSON.parse(present_loc);
        if (proj_loc && pres_loc) {
            proj_latlng = new window.google.maps.LatLng(proj_loc.latitude, proj_loc.longitude);
            pres_latlng = new window.google.maps.LatLng(pres_loc.latitude, pres_loc.longitude);
            distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                proj_latlng,
                pres_latlng,
            );
            loc = JSON.parse(this.get('value'));
            if (loc) {
                loc.distance = distance;
                this.set('value', JSON.stringify(loc));
                if (distance > 1000) {
                    dist_txt = Math.round(distance / 1000) + 'km';
                } else {
                    dist_txt = Math.round(distance) + 'm';
                }
                return this.set('distance', dist_txt);
            }
        }
    },
});

export default CollectorFieldsGpsFieldComponent;
