import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    collector: service('collector-service'),
    intl: service(),
    analytics: service(),

    useMultiFilter: true,
    filters: computed('form', function () {
        const allowFilters = [
            'date',
            'database',
            'dropdown_values',
            'status',
            'multiselect',
            'dropdown_userlevel',
        ];

        let fieldArray = get(this, 'collector')
            .fieldArray(get(this, 'form'))
            .filter((field) => {
                if (!allowFilters.includes(field.type)) return false;
                if (field.features.hide_filter) return false; // hide_filter feature hides filter

                return true;
            });

        fieldArray.forEach((item, index) => {
            if (get(this, 'values.' + item.name))
                fieldArray[index].value = get(this, 'values.' + item.name);
            fieldArray[index].label = this.collector.getTranslationForColumn(this.form, item.name);
            fieldArray[index].prompt = this.intl.t('general.choose');
            fieldArray[index].data_name = item.form + '-' + item.name;
        });

        const additionalFilters = this.additional ? this.getAdditionalFilters(this.additional) : [];

        let search = {
            type: 'searchbox',
            name: 'q',
            label: this.intl.t('general.search'),
            value: get(this, 'values.q') ? get(this, 'values.q') : null,
            data_name: 'search',
        };

        if (get(this, 'showColumns')) {
            fieldArray = fieldArray.filter((item) =>
                get(this, 'showColumns').split(',').includes(item.name),
            );

            // if showColumns is in use show fresearch only if given
            if (!get(this, 'showColumns').split(',').includes('q')) search = null;
        }

        return fieldArray.concat(additionalFilters, search ? [search] : []);
    }),

    actions: {
        /**
         * custom_field is used with additional filters
         * @param {*} custom_field
         * @param {*} value
         * @param {*} field
         */
        change(custom_field, value, field) {
            if (!field) return;
            if (custom_field) field = custom_field;
            this.analytics.trackEvent({
                category: this.form + ' report filters',
                action: field,
                label: 'Form-report',
            });
            get(this, 'change')(value, field);
            if (this.filterChangeAction) {
                this.filterChangeAction(value, field);
            }
        },
        async clearFilters() {
            this.analytics.trackEvent({
                category: this.form + ' report filters',
                action: 'clear filters',
                label: 'Form-report',
            });
            await this.clearFilters();
            this.notifyPropertyChange('filters');
            if (this.filterChangeAction) {
                this.filters.forEach((filter) => {
                    let value = filter.value ? filter.value : '';
                    this.filterChangeAction(value, filter.name);
                });
            }
        },
    },

    /**
     * additional filters are given ie. "project.owner,user.lastname"
     * @param string add
     */
    getAdditionalFilters(add) {
        const ret = [];
        for (const field of add.split(',')) {
            const fieldArr = field.split('.');

            let fields = this.collector.fieldArray(fieldArr[0]).filter((field) => {
                return field.name === fieldArr[1];
            });
            if (isEmpty(fields)) continue;
            fields[0].label = fields[0].translated_name;
            fields[0].customfield = fieldArr[0] + '[' + fieldArr[1] + ']';
            fields[0].prompt = this.intl.t('general.choose');
            fields[0].data_name = fields[0].form + '-' + fields[0].name;

            ret.push(fields[0]);
        }

        return ret;
    },
});
