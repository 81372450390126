import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isArray } from '@ember/array';
import validator from 'tt4/classes/validations';

import saveTheChildren from 'tt4/mixins/save-the-children';

/**
 *
 * report for collector form
 *
 * usage:
 *  BASIC:
 *      {{collector/form-add form=form}}
 *
 *  ADVANCED:
 *      {{#collector/form-add form=form record=record close=(action (mut show) false) as |form|}}
            {{#form.fields as |fields fieldcomp|}}
                {{#each fields as |field|}}
                    {{fieldcomp field=field}}
                {{/each}}
            {{/form.fields}}
            {{form.buttons}}

            {{form.saved}}
        {{/collector/form-add}}
 *
 * parameters:
 *      form: collector form name
 *      record: record for the add form
 *      defaults: if record is not given directly (or record is new) you can also give object which is passed to createRecord method
 *      showSaved: if saved page should be shown
 *      only: list columns you want to show (ie "date,user")
 *      filters: object containing filters for fields. ie filters=(hash user=(userlevel="5"))
 *
 * actions:
 *      rowAdded: called when new row is created and saved
 *      rowEdited: called when existing row is edited and saved
 *      close: called when cancel is clicked or saved page ok is clicked
 *      fieldChanged: when any field is edited on form action is triggered. Has parameters value and field
 */

export default Component.extend(saveTheChildren, {
    collector: service('collector-service'),
    store: service(),
    evented: service(),
    localstorage: service(),
    surveys: service(),

    showSaved: true,

    // this is the model of the record adding/editing component
    record: null,

    fields: computed('form', 'only', function () {
        const fields = get(this, 'collector').fieldArray(get(this, 'form'));

        let filteredFields = fields.filter((field) => {
            if (get(this, 'only') && !get(this, 'only').split(',').includes(field.name))
                return false;
            if (get(field, 'features.hide') && isArray(get(field, 'features.hide'))) {
                // Always "show" fields that are conditionally hidden, this conditionality is handled elsewhere
                return true;
            }
            if (get(field, 'features.hide')) return false;

            return true;
        });

        filteredFields = filteredFields.map((field) => {
            if (field.name === 'location' || field.name === 'end_location')
                field.type = 'gpslocation';
            if (field.name === 'location_map') field.type = 'gpslocationmap';
            return field;
        });

        if (this.sortFields) {
            return filteredFields.sort((a, b) => {
                if (get(this, 'only')) {
                    const onlyArr = get(this, 'only');
                    return onlyArr.indexOf(a.name) - onlyArr.indexOf(b.name);
                }
            });
        } else {
            return filteredFields;
        }
    }),

    conditionalHides: computed('form', function () {
        return get(this, 'fields').filter((field) => {
            return get(field, 'features.hide') && isArray(get(field, 'features.hide'));
        });
    }),

    init() {
        this._super();

        if (
            !get(this, 'preserveRecordOnDestroy') &&
            get(this, 'record.isNew') &&
            get(this, 'defaults')
        ) {
            this.setRecordDefaults();
        }

        set(this, 'filters', {});
    },

    async didReceiveAttrs() {
        if (!get(this, 'form')) throw new Error('form-add needs form parameter');
        if (!get(this, 'record')) {
            set(
                this,
                'record',
                get(this, 'store').createRecord(get(this, 'form'), await get(this, 'defaults')),
            );
        }

        await this.setLsDefaults();
        await this.setFilters();
        this.setConditionalHides();
    },

    willDestroyElement() {
        // if record is new (and preserveRecordOnDestroy is not set) we just destoy it
        // if we were editing record we rollback it to make sure record is not left in unsaved state when we destroy this component (preserveRecordOnDestroy skips this also)
        if (!get(this, 'preserveRecordOnDestroy') && get(this, 'record.isNew'))
            get(this, 'record').deleteRecord();
        else if (
            !get(this, 'preserveRecordOnDestroy') &&
            !get(this, 'record.isNew') &&
            get(this, 'record.hasDirtyAttributes')
        )
            get(this, 'record').rollbackAttributes();
        this._super(...arguments);
    },

    actions: {
        async addNew() {
            if (this.addNew) this.addNew();
            else
                set(
                    this,
                    'record',
                    get(this, 'store').createRecord(get(this, 'form'), await get(this, 'defaults')),
                );
            set(this, 'savedView', false);
        },

        addSame() {
            if (this.addSame) this.addSame();
            else set(this, 'record', this.collector.copyRecord(this.record));
            set(this, 'savedView', false);
        },

        fieldChanged(value, field) {
            this.setFilters(field);
            this.setConditionalHides(field);

            set(this, 'record.' + field, value);

            // this is a dirty, filthy hack (copied from addon form-add) for marking a record dirty when its relationships (belongsTo, hasMany) are changed
            // this is done here as a workaround so you don't need to do this in the parent component every time
            // https://github.com/emberjs/rfcs/pull/21
            this.record.send('becomeDirty');
            if (this.fieldChanged) this.fieldChanged(value, field);
        },

        async save() {
            set(this, 'saving', true);
            set(this, 'error', null);
            set(this, 'stateBeforeSave', get(this, 'record.currentState.stateName'));
            let isRecordNew = get(this, 'record.isNew');

            try {
                //saveTheChildren
                await get(this, 'record').validate();

                if (!get(this, 'record.isValid')) {
                    set(this, 'saving', false);
                    const firstErrorElement = this.element.querySelector('.has-error');
                    if (firstErrorElement) firstErrorElement.scrollIntoView({ behavior: 'smooth' });
                    return;
                }

                await this.save_record(get(this, 'record'));

                this.evented.storeEvent('insert', get(this, 'form'), get(this, 'record'));

                if (!this.isDestroyed) set(this, 'saving', false);

                if (!get(this, 'showSaved')) {
                    if (get(this, 'close')) get(this, 'close')();
                } else set(this, 'savedView', true);

                if (get(this, 'stateBeforeSave') === 'root.loaded.created.uncommitted') {
                    if (get(this, 'rowAdded')) get(this, 'rowAdded')(get(this, 'record'));
                } else {
                    if (get(this, 'rowEdited')) get(this, 'rowEdited')(get(this, 'record'));
                }
                if (get(this, 'form') == 'worktime' && isRecordNew) {
                    this.surveys.initializeAndRun('worktime_entry'); // show wootric CES-survey after saving worktime
                }
            } catch (reason) {
                set(this, 'error', reason);
                set(this, 'saving', false);
                throw reason;
            }
        },

        cancel() {
            get(this, 'close')();
        },

        close() {
            get(this, 'close')();
        },
    },

    async setRecordDefaults() {
        const defaults = await get(this, 'defaults');
        const record = get(this, 'record');
        // only apply defaults if record value is not set already
        for (const def in defaults) {
            if (!get(record, def)) set(record, def, defaults[def]);
        }
    },

    async setLsDefaults() {
        const fields = this.fields;
        for (var field of fields) {
            // make sure we don't overwrite any values
            if (field.type == 'database' && !get(this, 'record.' + field.name)) {
                let lastused = get(this, 'localstorage').getItem('lastused.' + field.options.form);
                if (lastused) {
                    let defValue = lastused[0];
                    if (
                        defValue &&
                        get(this, 'record.isNew') &&
                        !get(this, 'hide') &&
                        !field.features.do_not_preselect
                    ) {
                        var promisevalue = await this.store.query(
                            field.options.form,
                            { id: defValue, form: field.form, field: field.name },
                            { reload: true },
                        );
                        promisevalue = promisevalue?.firstObject;
                        if (promisevalue && get(promisevalue, 'row_info.status') != 'removed') {
                            set(field, 'value', promisevalue);
                            set(this, 'record.' + field.name, await promisevalue);
                        }
                    }
                }
            }
        }
    },
    setFilters(depends) {
        const filterBy = this.getFilters(this.form);
        const filters = {};
        filterBy.forEach((item) => {
            if (depends && item.with !== depends) return;
            if (!filters[item.field]) filters[item.field] = {};
            filters[item.field][item.filter] = get(this, 'record.' + item.with);
        });

        Object.keys(filters).forEach((key) => {
            set(this, 'filters.' + key, filters[key]);
        });
    },

    /**
     * Hide or show fields if field.features.hide setting is conditional
     * If called without field parameter then goes through all fields that have some kind of conditional setting
     * else only for the given field name
     * Sets true/false to fields "private" _hide variable that is used atleast in form-add/field component
     * Examples:
     * Name: hide, Value: [{"alasveto": "eka"}]
     * Name: hide, Value: [{"alasveto": "!(empty)"}]
     * Name: hide, Value: [{"alasveto": "(empty)"}]
     * @param {string} field the external type of the field also known as "name"
     */
    setConditionalHides(field = false) {
        get(this, 'conditionalHides').forEach((item) => {
            get(item, 'features.hide').forEach((rule) => {
                let ruleField = Object.keys(rule)[0].split('.')[0];
                if ((field && ruleField.includes(field)) || !field) {
                    set(
                        item,
                        '_hide',
                        !validator._is_visible(get(this, 'record'), get(item, 'features.hide')),
                    );
                }
            });
        });
    },

    /**
     * Example of one filter {field: "task", filter: "project", with: "project"}
     * @param {*} form
     */
    getFilters(form) {
        const filters = [];
        const fields = this.collector.fieldArray(form);
        for (const field of fields) {
            let reload = field.features.reload;
            if (reload) {
                if (!isArray(reload)) reload = [reload];

                for (const reloadfield of reload) {
                    filters.push({
                        field: reloadfield, // field name that needs to be reloaded
                        filter: field.options.form, // filter name that is used to fetch objects. Normally the form where reloader field points to. ie tasks?project=1234 => project
                        with: field.name, // name of the field to use as filter .. this needs to found from model
                    });
                }
            }
        }

        return filters;
    },
});
