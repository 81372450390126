import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';

export default class CcbuttonComponent extends Component {
    @tracked integrations;
    @tracked saving = false;
    @tracked selected = null;
    @tracked errorMessage = null;
    @tracked values = {};
    @service store;
    @service ttapi;
    @service notifications;
    @service intl;
    @service error;

    constructor() {
        super(...arguments);
        this.load();
    }

    async load() {
        const integrations = await this.store.findAll('ccbutton');

        const running = await this.store.query('ccbuttonexecution', {
            filter: { 'attributes.state': 'running' },
        });

        const oldRows = running.filter((item) =>
            moment(item.started).isBefore(moment().subtract(1, 'hour')),
        );

        oldRows.forEach((record) => {
            record.state = 'error';
            record.save();
        });

        this.integrations = integrations.map((item) => {
            return {
                id: item.id,
                name: item.name,
                disabled: running.find((a) => {
                    if (!a.ccbutton) return false;
                    if (a.state !== 'running') return false;
                    return get(a, 'ccbutton.id') === get(item, 'id');
                })
                    ? true
                    : false,
            };
        });
    }

    get selectedIntegrationName() {
        return this.store.peekRecord('ccbutton', this.selected).name;
    }

    get fields() {
        let fields = this.store.peekRecord('ccbutton', this.selected).fields;
        if (!fields) return [];
        fields = fields.split(',');
        return fields.map((field) => {
            if (field.includes('.')) {
                const [form, fieldName] = field.split('.');
                return { form, fieldName };
            }
            return { form: 'worktime', fieldName: field };
        });
    }

    @action async execute() {
        this.saving = true;
        this.errorMessage = null;

        const queryParams = Object.keys(this.values).map((key) => {
            const value = this.values[key];
            return key + '=' + (value.id ? value.id : value);
        });
        queryParams.push('blocking=' + this.store.peekRecord('ccbutton', this.selected).blocking);
        if (this.store.peekRecord('ccbutton', this.selected).token) queryParams.push('token=true');

        try {
            await this.ttapi.request(
                'api/ccbutton/execute/' + this.selected + '?' + queryParams.join('&'),
                { method: 'post' },
            );
            this.notifications.success(this.intl.t('integrations.started'), { autoClear: true });
            this.selected = null;
        } catch (e) {
            this.errorMessage = get(e, 'payload.message') || e;
        }

        this.saving = false;
    }
}
