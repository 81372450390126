import { set } from '@ember/object';
import { typeOf } from '@ember/utils';
import GeneralReportController from '../../../controllers/general-report';

// eslint-disable-next-line ember/alias-model-in-controller
export default GeneralReportController.extend({
    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) === 'instance') {
                return set(this, field, value.id);
            } else {
                return set(this, field, value);
            }
        },
    },

    date:
        moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD') +
        '_' +
        moment().format('YYYY-MM-DD'),
});
