import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import capiService from 'tt4/injections/services/capi';
import ttapiService from 'tt4/injections/services/ttapi';

interface IMessAgesTypesLinkRequestComponent {
    messageid: number;
    params?: any;
    execute(args0: boolean | string): void;
}

export default class MessAgesTypesLinkRequestComponent extends Component<IMessAgesTypesLinkRequestComponent> {
    @service protected session: any;
    @service protected capi: capiService;
    @service protected ttapi: ttapiService;

    @action
    async linknow() {
        await this.ttapi.request('messages/' + this.args.messageid, {
            method: 'post',
            data: {
                value: 'linknow',
            },
        });

        const data = await this.capi.request('me?linkings');
        set(this.session, 'currentUser.services', data);
        if (this.args.execute) {
            return this.args.execute(false);
        }
    }

    @action
    skiplinking() {
        if (this.args.execute) {
            return this.args.execute('skiplinking');
        }
    }
}
