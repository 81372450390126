import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import EmberObject, { set, computed } from '@ember/object';

const MessageboardAddController = Controller.extend({
    Collector: service('collector-service'),
    ttapi: service(),
    intl: service(),
    userform: 'worktime',

    spinIsLoading: false,
    disabled: false,

    init() {
        this._super(...arguments);

        if (this.Collector.fieldExists('worktime', 'user')) {
            return set(this, 'userform', 'worktime');
        } else if (this.Collector.fieldExists('presence', 'user')) {
            return set(this, 'userform', 'presence');
        }
    },

    content: alias('model'),

    field: computed(function () {
        return EmberObject.create({
            type: 'multiselect',
            features: {},
            form: this.userform,
            name: 'user',
            options: {
                fields: ['lastname', 'firstname'],
            },
            ext: 'user',
        });
    }),

    actions: {
        async save() {
            const params = {};

            params.receivers = this.receivers;
            params.header = this.header;
            params.message = this.message;
            params.forced = this.forced;

            set(this, 'receivers', null);
            set(this, 'header', null);
            set(this, 'message', null);
            set(this, 'forced', null);
            await this.ttapi.request('/messageboard/new', {
                type: 'POST',
                data: params,
            });
            set(this, 'spinIsLoading', false);
            set(this, 'disabled', false);
            return this.transitionToRoute('messageboard.index');
        },

        cancel() {
            set(this, 'receivers', null);
            set(this, 'header', null);
            set(this, 'message', null);
            set(this, 'forced', null);
            return this.transitionToRoute('messageboard.index');
        },

        bubbleChange(value) {
            return set(this, 'receivers', value);
        },
    },
});

export default MessageboardAddController;
