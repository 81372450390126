/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/logo.coffee.old
// Generated by CoffeeScript 2.6.1
var Logo;

import DS from 'ember-data';

Logo = DS.Model.extend({
    logo: DS.attr(),
});

export default Logo;
