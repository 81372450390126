import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import menu from '../../../mixins/menu';

export default Component.extend(menu, {
    router: service(),
    subMenus: computed('form', function () {
        let form = this.form;
        // lets use this only for worktime-modal (and also presences) for now
        if (form == 'presence' || form == 'worktime') {
            if (form == 'worktime') form = 'timetracker';
            else form = 'presences';
            return this.getSubMenus([form, 'add']);
        } else return [];
    }),
    actions: {
        transitionAndClose(route) {
            this.closeModal();
            this.router.transitionTo(route[0]);
        },
    },
});
