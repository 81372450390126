import Component from '@ember/component';
import { get, set, computed } from '@ember/object';

export default Component.extend({
    tagName: 'tr',

    weekdays: computed(function () {
        const days = [];
        for (let i = 0; i < 7; i++) {
            days.push(moment(get(this, 'week')).add(i, 'days').format('YYYY-MM-DD'));
        }
        return days;
    }),

    init() {
        this._super();
        this.forms = ['worktime', 'abcense'];
    },

    actions: {
        openEditModal(day) {
            let formattedDay = moment(day).format('dd D[.]MM');
            set(this, 'formattedDay', formattedDay);
            set(this, 'day', day);
            set(this, 'showEdit', true);
        },
    },
});
