/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/my-sites/select-project/component.coffee.old
// Generated by CoffeeScript 2.6.1
var MySitesSelectProjectComponent;

import Ember from 'ember';

import databaseField from '../../collector/fields/database-field/component';

MySitesSelectProjectComponent = databaseField.extend({
    layoutName: 'collector/fields/database-field',

    //   override database-field

    normalDropdownOptions: Em.computed('options', 'defaultValueForNormalDropdown', function () {
        return this.get('options').map((option) => {
            Ember.set(option, 'name', option.text);
            return option;
        });
    }),
    //use_normal_dropdown_limit: 4
    queryBackend: function (form) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise;
            promise = this.get('Collector').ttapi({
                url: 'tunnel/findAll/' + this.get('form'),
                data: {
                    field: this.get('object'),
                    limit: this.get('ajax_search_limit'),
                    q: this.get('query'),
                    username: this.get('username'),
                },
            });
            return promise.then((data) => {
                this.set('queryRowCount', data.count);
                return resolve(
                    Em.A(data[form + 's']).map(function (item) {
                        return Em.Object.create(item);
                    }),
                );
            }, reject);
        });
    },
    getText: function (record) {
        return record.text;
    },
    pushToStore: function () {},
    addToLastused: function () {},
    getOptionsFromLocalStorage: function () {
        return Em.A([]);
    },
    fetchRecordById: function (id) {
        return new Em.RSVP.Promise(function (resolve) {
            return resolve(id);
        });
    },
    reload: Em.observer('username', function () {
        return this.initilizeDropdown();
    }),
});

export default MySitesSelectProjectComponent;
