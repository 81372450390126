/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/id06/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddId06Route;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

UsersAddId06Route = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    afterModel: function (model, transition) {
        var c;
        c = this.get('Collector');
        if (model.get('homecountry') === void 0) {
            model.set('homecountry', c.field('user', 'homecountry').features.default_value);
        }
        if (this.get('session.currentUser.employer')) {
            model.set('employer', this.get('session.currentUser.employer'));
        }
        return this._super(model, transition);
    },
    resetController: function (controller, isExiting, transition) {
        this._super(controller, isExiting, transition);
        if (isExiting) {
            controller.set('error', false);
            return controller.set('error500', false);
        }
    },
});

export default UsersAddId06Route;
