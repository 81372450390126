import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    Collector: service('collector-service'),
    intl: service(),
    tagName: 'tr',

    shouldShow: computed('weekdays', function () {
        // Show show row if at least one column has a value
        return get(this, 'weekdays').some((el) => el.values !== undefined && el.values !== null);
    }),

    weekdays: computed(function () {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const date = moment(get(this, 'week')).add(i, 'days').format('YYYY-MM-DD');
            let sumValues = this.sumValues(date, get(this, 'salaryType.name'));
            let formattedValues = sumValues;

            if (get(this, 'salaryType.fieldType')) {
                formattedValues = get(this, 'Collector')._format_(
                    sumValues,
                    'worktime',
                    get(this, 'salaryType.fieldType'),
                    'salarycategory',
                );
            }

            days.push({
                date: date,
                values: sumValues,
                formatted: formattedValues,
            });
        }
        return days;
    }),

    sumValuesAlldays: computed('weekdays', function () {
        let sum = get(this, 'weekdays').reduce((total, day) => total + day.values, 0);
        if (get(this, 'salaryType.fieldType')) {
            sum = get(this, 'Collector')._format_(
                sum,
                'worktime',
                get(this, 'salaryType.fieldType'),
                'salarycategory',
            );
        }
        return sum;
    }),

    sumValues(date, field) {
        if (!get(this, 'data')) return 0;
        return get(this, 'data')
            .filterBy('date', date)
            .reduce((total, day) => total + (day[field] ? Number(day[field]) : 0), 0);
    },
});
