export default {
    generalSettings: [
        {
            title: 'settings.general',
        },
        {
            name: 'new_messageboard',
            random: true,
            title: 'settings.general.new_messageboard',
            needs: [],
        },
        {
            title: 'settings.general.terms',
            needs: ['modules.timetracker'],
            component: 'collector/field-setting-comp/terms-setting',
        },
        {
            title: 'settings.general.recipients',
            random: true,
            productsOn: ['products.recipients'],
            productsOff: ['!products.recipients'],
            needs: ['products.timetracker'],
        },
        {
            name: 'orientations_check_all',
            title: 'settings.general.orientations',
            needs: ['modules.orientations'],
        },
        {
            title: 'settings.general.self_orientation',
            productsOn: ['products.orientations_self'],
            productsOff: ['!products.orientations_self'],
            needs: ['modules.orientations'],
        },
        {
            title: 'settings.general.orientations_parent',
            random: true,
            productsOn: ['products.orientations_parent'],
            productsOff: ['!products.orientations_parent'],
            needs: ['modules.orientations'],
        },
        {
            title: 'settings.worksitediary.state',
            random: true,
            productsOn: ['products.worksitediary_state'],
            productsOff: ['!products.worksitediary_state'],
            needs: ['products.worksitediary'],
        },
        {
            title: 'settings.phasetitle',
            random: true,
            form: 'phasetitle',
            productsOn: ['products.worksitediary_phasetitle'],
            productsOff: ['!products.worksitediary_phasetitle'],
            needs: ['products.worksitediary'],
        },
        {
            title: 'settings.general.superior_for_user',
            productsOn: ['products.superior_for_user'],
            productsOff: ['!products.superior_for_user'],
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.general.employer',
            productsOn: ['products.employer_standalone'],
            productsOff: ['!products.employer_standalone'],
            needs: ['products.timetracker', '!products.rakentajanpaketti'],
        },
        {
            name: 'push_notification_time',
            setting: {
                name: 'push_notification_time',
            },
            title: 'settings.general.push_notifications_fixed_time',
            component: 'collector/field-setting-comp/push-setting',
            needs: ['modules.timetracker', 'serviceCountry@fi'],
            secondary_setting: 'push_notification_time_hours_fi',
        },
        {
            name: 'push_notification_time',
            setting: {
                name: 'push_notification_time',
            },
            title: 'settings.general.push_notifications_fixed_time',
            component: 'collector/field-setting-comp/push-setting',
            needs: ['modules.timetracker', 'serviceCountry@se'],
            secondary_setting: 'push_notification_time_hours_se',
        },
        {
            title: 'settings.general.gps_distance',
            component: 'collector/field-setting-comp/number-field',
            setting: {
                setting: 'gps_distance',
                minVal: 0,
                defaultVal: 700,
                unit: 'm',
            },
            needs: ['products.gps_force'],
        },
        {
            name: 'email_approval_for_all',
            title: 'settings.general.email_approval_for_all',
            needs: ['modules.timetracker'],
            random: true,
        },
    ],
    timetrackerSettings: [
        {
            title: 'settings.timetracker',
        },
        {
            name: 'postpopulated_worktimes',
            random: true,
            title: 'settings.general.postpopulated_worktimes',
            needs: ['modules.timetracker'],
            groupEditable: true,
            groupEditableSettings: true,
            groupEditableIf: {
                needs: ['!customersetting.postpopulated_worktimes'],
                title: 'settings.general.postpopulated_worktimes.worktimegroup_needs',
            },
            component: 'collector/field-setting-comp/postpopulated-worktimes',
        },
        {
            title: 'settings.tasks',
            options: [
                {
                    products: ['!products.tasks', '!products.multitasking'],
                    title: 'general.off',
                },
                {
                    products: ['products.tasks', '!products.multitasking'],
                    title: 'general.on',
                },
                {
                    products: ['!products.tasks', 'products.multitasking'],
                    title: 'settings.tasks.multitasking',
                },
                {
                    products: ['products.tasks', 'products.multitasking'],
                    title: 'settings.tasks.both',
                },
            ],
            form: 'task',
            needs: ['modules.timetracker'],
            groupEditable: true,
        },
        {
            title: 'settings.subtasks',
            productsOn: ['products.subtasks'],
            productsOff: ['!products.subtasks'],
            form: 'subtask',
            needs: ['modules.timetracker', 'products.tasks||products.multitasking'],
        },
        {
            title: 'settings.timetracker.timeclean',
            random: true,
            component: 'collector/nfc-comp',
            productsOn: ['products.rfid'],
            productsOff: ['!products.rfid'],
            needs: ['modules.timetracker||products.personalliggareandid06', 'debug'],
        },
        {
            title: 'settings.materials',
            productsOn: ['products.materiallog'],
            productsOff: ['!products.materiallog'],
            form: 'materialtype',
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.materialgroups',
            productsOn: ['products.materialgroups'],
            productsOff: ['!products.materialgroups'],
            needs: ['modules.timetracker', 'products.materiallog'],
        },
        {
            title: 'settings.timetracker.km_automatic',
            productsOn: ['products.timetracker_km_automatic', 'products.timetracker_km'],
            productsOff: ['!products.timetracker_km_automatic', '!products.timetracker_km'],
            needs: ['modules.timetracker'],
            component: 'collector/field-setting-comp/autokm-setting',
            groupEditable: true,
            groupEditableSettings: true,
        },
        {
            title: 'settings.timetracker.traveltime_automatic',
            productsOn: ['products.timetracker_traveltime'],
            productsOff: ['!products.timetracker_traveltime'],
            needs: [
                'modules.timetracker',
                'serviceCountry@fi',
                '!products.generic_timetracker',
                'products.timetracker_km_automatic',
            ],
        },
        {
            title: 'settings.absencetype',
            random: true,
            productsOn: ['products.absences_hours'],
            productsOff: ['!products.absences_startandend', '!products.absences_hours'],
            needs: ['modules.absences', '!products.generic_timetracker'],
        },
        {
            title: 'settings.holiday_pay',
            random: true,
            productsOn: ['products.holiday_pay'],
            productsOff: ['!products.holiday_pay'],
            needs: ['modules.absences', 'products.worktimebank'],
        },
        {
            name: 'vacation_week_length',
            random: true,
            title: 'settings.vacation_week_length5',
            needs: ['modules.absences', 'serviceCountry@fi'],
        },
        {
            title: 'settings.overlapping_worktimes',
            random: true,
            productsOn: ['products.timetracker_disable_overlapping'],
            productsOff: [
                '!products.timetracker_overlap',
                '!products.timetracker_disable_overlapping',
            ],
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.prevent_multiple_worktimes',
            random: true,
            productsOn: ['products.timetracker_prevent_multiple'],
            productsOff: ['!products.timetracker_prevent_multiple'],
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.groups',
            random: true,
            options: [
                {
                    products: ['!products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.nogroups',
                },
                {
                    products: ['products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.groups',
                },
                {
                    products: ['!products.groups', 'products.multigroups'],
                    title: 'settings.timetracker.multigroups',
                },
            ],
            form: 'group',
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.subprojects',
            random: true,
            options: [
                {
                    products: ['!products.subprojects', '!products.subprojectsmulti'],
                    title: 'general.off',
                },
                {
                    products: ['products.subprojects', '!products.subprojectsmulti'],
                    title: 'settings.timetracker.subprojects',
                },
                {
                    products: ['!products.subprojects', 'products.subprojectsmulti'],
                    title: 'settings.timetracker.subprojectsmulti',
                },
            ],
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.absences',
            productsOn: ['products.absences'],
            productsOff: ['!products.absences'],
            form: 'abcensetype',
            needs: ['modules.timetracker'],
            groupEditable: true,
        },
        {
            title: 'settings.absence_projects',
            random: true,
            productsOn: ['products.absence_projects'],
            productsOff: ['!products.absence_projects'],
            needs: ['abcense'],
        },
        {
            title: 'settings.customers',
            random: true,
            productsOn: ['products.customer'],
            productsOff: ['!products.customer'],
            form: 'customer',
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.timetracker.customer.projects',
            random: true,
            productsOn: ['products.projects_by_customer'],
            productsOff: ['!products.projects_by_customer'],
            needs: ['products.customer', 'modules.timetracker', '!products.base_basic'],
        },
        {
            title: 'settings.mainproject',
            productsOn: ['products.mainproject'],
            productsOff: ['!products.mainproject'],
            form: 'mainproject',
            needs: ['modules.timetracker', 'products.projects_by_customer', '!products.base_basic'],
        },
        {
            title: 'settings.timetracker.pause',
            productsOn: ['products.pause'],
            productsOff: ['!products.pause'],
            component: 'collector/field-setting-comp/pause-setting',
            setting: {
                form: 'worktime',
                field: 'pause',
                setting: { title: 'default_value', value: 30 },
            },
            needs: ['modules.timetracker'],
            groupEditable: true,
            groupEditableSettings: true,
        },
        {
            title: 'settings.timetracker.saldo',
            productsOn: ['products.saldo'],
            productsOff: ['!products.saldo'],
            component: 'collector/field-setting-comp/saldo-setting',
            needs: ['modules.timetracker'],
            groupEditable: true,
        },
        {
            title: 'settings.timetracker.overtime',
            productsOn: ['products.overtime'],
            productsOff: ['!products.overtime'],
            component: 'collector/field-setting-comp/overtime-setting',
            needs: ['modules.timetracker'],
            groupEditableSettings: true,
            groupEditable: true,
        },
        {
            title: 'settings.project_owner',
            random: true,
            productsOn: ['products.project_owner'],
            productsOff: ['!products.project_owner'],
            needs: ['modules.timetracker'],
        },

        {
            title: 'settings.timetracker.multiple_companies',
            random: true,
            productsOn: ['products.multiple_companies'],
            productsOff: ['!products.multiple_companies'],
            needs: ['project', 'products.rakentajanpaketti'],
        },
        {
            title: 'settings.absences',
            tt3valueOn: 'absences.none',
            tt3valueOff: 'absences.on',
            productsOn: ['abcensetype'],
            productsOff: ['!abcensetype'],
            form: 'abcensetype',
            needs: ['worktime', '!modules.timetracker'],
        },
        {
            title: 'settings.customers',
            random: true,
            tt3valueOn: 'customers.none',
            tt3valueOff: 'customers.on',
            productsOn: ['customer'],
            productsOff: ['!customer'],
            form: 'customer',
            needs: ['worktime', '!modules.timetracker'],
        },
        {
            title: 'settings.groups',
            tt3valueOn: 'groups.none',
            tt3valueOff: 'groups.group',
            productsOn: ['group'],
            productsOff: ['!group'],
            form: 'group',
            needs: ['worktime', '!modules.timetracker'],
        },
        {
            title: 'settings.worktimegroup',
            random: true,
            productsOn: ['products.worktimegroup'],
            productsOff: ['!products.worktimegroup'],
            form: 'worktimegroup',
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.timetracker_allow_nfc_edit',
            productsOn: ['products.timetracker_allow_nfc_edit'],
            productsOff: ['!products.timetracker_allow_nfc_edit'],
            needs: ['products.rfid', '!products.generic_timetracker'],
        },
        {
            title: 'settings.timetracker.time_adding_type',
            options: [
                {
                    products: [
                        '!products.timetracker_hybrid',
                        '!products.timetracker_hours',
                        '!products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.startandend',
                },
                {
                    products: [
                        '!products.timetracker_hybrid',
                        'products.timetracker_hours',
                        '!products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.hours',
                    notGroupEditable: true,
                },
                {
                    products: [
                        'products.timetracker_hybrid',
                        '!products.timetracker_hours',
                        '!products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.hybrid',
                },
                {
                    products: [
                        '!products.timetracker_hybrid',
                        '!products.timetracker_hours',
                        'products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.clockcard',
                },
            ],
            needs: ['modules.timetracker'],
            groupEditable: true,
            groupEditableSettings: true,
            groupEditableIf: {
                needs: [
                    '!products.timetracker_hybrid',
                    '!products.timetracker_hours',
                    '!products.timetracker_clockcard',
                ],
                title: 'settings.timetracker.startandend.worktimegroup_needs',
            },
            addition: true,
        },
        {
            title: 'settings.timetracker.km_type',
            options: [
                {
                    products: [
                        '!products.timetracker_km',
                        '!products.timetracker_kmsade',
                        '!products.timetracker_kmandsade',
                    ],
                    title: 'settings.timetracker.km_none',
                },
                {
                    products: [
                        'products.timetracker_km',
                        '!products.timetracker_kmsade',
                        '!products.timetracker_kmandsade',
                    ],
                    title: 'settings.timetracker.km',
                },
                {
                    products: [
                        'products.timetracker_kmsade',
                        '!products.timetracker_km',
                        '!products.timetracker_kmandsade',
                    ],
                    title: 'settings.timetracker.kmsade',
                },
                {
                    products: ['products.timetracker_kmsade', 'products.timetracker_km'],
                    title: 'settings.timetracker.kmandsade',
                },
            ],
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.timetracker.perdiem',
            options: [
                {
                    products: [
                        '!products.timetracker_perdiem',
                        '!products.timetracker_perdiem_meal',
                    ],
                    title: 'settings.timetracker.perdiem_none',
                },
                {
                    products: [
                        'products.timetracker_perdiem',
                        '!products.timetracker_perdiem_meal',
                    ],
                    title: 'settings.timetracker.perdiem_def',
                },
                {
                    products: [
                        '!products.timetracker_perdiem',
                        'products.timetracker_perdiem_meal',
                    ],
                    title: 'settings.timetracker.perdiem_meal',
                },
            ],
            needs: ['modules.timetracker', 'serviceCountry@fi'],
        },
        {
            title: 'settings.timetracker.worktype',
            random: true,
            options: [
                {
                    products: [
                        '!products.timetracker_worktype',
                        '!products.timetracker_worktype_extra',
                    ],
                    title: 'settings.timetracker.worktype_none',
                },
                {
                    products: [
                        'products.timetracker_worktype',
                        '!products.timetracker_worktype_extra',
                    ],
                    title: 'settings.timetracker.worktype_def',
                },
                {
                    products: [
                        '!products.timetracker_worktype',
                        'products.timetracker_worktype_extra',
                    ],
                    title: 'settings.timetracker.worktype_extra',
                },
            ],
            needs: [
                'modules.timetracker',
                '!products.generic_timetracker',
                'products.timetracker_worktype||products.timetracker_worktype_extra',
            ],
        },
        {
            title: 'settings.timetracker.salarytype',
            random: true,
            productsOn: ['products.salarytype'],
            productsOff: ['!products.salarytype'],
            needs: [
                'modules.timetracker',
                '!products.timetracker_worktype',
                '!products.timetracker_worktype_extra',
            ],
        },
        {
            title: 'settings.gps',
            options: [
                {
                    products: ['!products.gps', '!products.gps_force'],
                    title: 'settings.gps_none',
                },
                {
                    products: ['products.gps', '!products.gps_force'],
                    title: 'settings.gps_def',
                },
                {
                    products: ['!products.gps', 'products.gps_force'],
                    title: 'settings.gps_force',
                },
            ],
            needs: ['modules.timetracker'],
        },

        {
            title: 'settings.eventtitle',
            form: 'eventtitle',
            needs: ['eventtitle'],
        },
        {
            title: 'settings.round_times',
            random: true,
            form: 'rounding_rule',
            needs: ['modules.timetracker', 'worktime.formFeature.round_times;10'],
        },
        {
            title: 'settings.round_times',
            random: true,
            productsOn: ['products.rounding_rule_v2'],
            productsOff: ['!products.rounding_rule_v2'],
            form: 'rounding_rule_v2',
            needs: ['modules.timetracker', '!worktime.formFeature.round_times;10'],
        },
        {
            title: 'settings.wage_period',
            form: 'wage_period',
            component: 'salary-period-adder',
            needs: ['worktime', 'wage_period'],
        },
        {
            title: 'settings.export_contact_person',
            random: true,
            form: 'export_contact_person',
            needs: ['export_contact_person'],
        },
        {
            title: 'settings.timetracker.edit_time',
            component: 'collector/field-setting-comp',
            setting: {
                form: 'worktime',
                field: 'date',
                setting: { name: 'date_interval', userlevel: '1', value: 'locked' },
                type: 'number',
            },
            needs: ['modules.timetracker'],
        },
        {
            title: 'settings.timetracker.banks',
            random: true,
            productsOn: ['products.worktimebank'],
            productsOff: ['!products.worktimebank'],
            needs: ['modules.timetracker'],
        },

        // TT3 support
        {
            title: 'settings.timetracker.km_type',
            options: [
                {
                    tt3value: 'mileage.none',
                    products: ['!worktime.km', '!worktime.kmsade'],
                    title: 'settings.timetracker.km_none',
                },
                {
                    tt3value: 'mileage.km',
                    products: ['worktime.km', '!worktime.kmsade'],
                    title: 'settings.timetracker.km',
                },
                {
                    tt3value: 'mileage.kmsade',
                    products: ['!worktime.km', 'worktime.kmsade'],
                    title: 'settings.timetracker.kmsade',
                },
                {
                    tt3value: 'mileage.both',
                    products: ['worktime.km', 'worktime.kmsade'],
                    title: 'settings.timetracker.kmandsade',
                },
            ],
            needs: ['worktime', '!modules.timetracker'],
        },

        {
            title: 'settings.timetracker.hourprice',
            random: true,
            options: [
                {
                    //tt3value: "hourly_rates.none"
                    products: ['!products.hourprice_user', '!products.hourprice_project'],
                    title: 'settings.timetracker.hourprice_none',
                },
                {
                    //tt3value: "hourly_rates.user"
                    products: ['products.hourprice_user', '!products.hourprice_project'],
                    title: 'settings.timetracker.hourprice_user',
                },
                {
                    //tt3value: "hourly_rates.project"
                    products: ['!products.hourprice_user', 'products.hourprice_project'],
                    title: 'settings.timetracker.hourprice_project',
                },
            ],
            needs: ['modules.timetracker'],
        },
        {
            name: 'stampout_worktime_fi',
            setting: {
                name: 'stampout_worktime_fi',
                secondary_setting_name: 'stampout_worktime_time_fi',
            },
            title: 'settings.general.stampout_worktime',
            component: 'collector/field-setting-comp/time-setting',
            needs: ['modules.timetracker', 'serviceCountry@fi'],
            random: true,
        },
        {
            name: 'stampout_worktime_se',
            setting: {
                name: 'stampout_worktime_se',
                secondary_setting_name: 'stampout_worktime_time_se',
            },
            title: 'settings.general.stampout_worktime',
            component: 'collector/field-setting-comp/time-setting',
            needs: ['modules.timetracker', 'serviceCountry@se'],
            random: true,
        },
        {
            name: 'unapproved_worktimes',
            setting: {
                name: 'unapproved_worktimes',
            },
            title: 'settings.general.unapproved_worktimes',
            component: 'collector/field-setting-comp/time-setting',
            needs: ['worktime', 'serviceCountry@fi'],
            random: true,
        },
        {
            name: 'hide_holidays',
            setting: {
                name: 'hide_holidays',
            },
            title: 'settings.general.hide_holidays',
            needs: ['abcense'],
            random: true,
        },
        {
            title: 'settings.general.midweek_holiday_pay',
            productsOn: ['products.midweek_holiday_pay'],
            productsOff: ['!products.midweek_holiday_pay'],
            needs: ['worktime'],
            random: true,
        },
    ],
    taskSettings: [
        {
            title: 'settings.worktasks',
        },
        {
            title: 'settings.tasks',
            form: 'task',
            needs: ['task', '!modules.timetracker'],
        },
        {
            title: 'settings.taskmanagement.worktask_involved',
            productsOn: ['products.worktask_involved'],
            productsOff: ['!products.worktask_involved'],
            needs: ['products.taskmanagement'],
        },
        {
            title: 'settings.taskmanagement.worktask_signature',
            productsOn: ['products.worktask_signature'],
            productsOff: ['!products.worktask_signature'],
            needs: ['products.taskmanagement'],
        },
        {
            title: 'settings.taskmanagement.worktask_enddate',
            productsOn: ['products.worktask_enddate'],
            productsOff: ['!products.worktask_enddate'],
            needs: ['products.taskmanagement'],
        },
        {
            title: 'settings.taskmanagement.worktask_starttime',
            productsOn: ['products.worktask_starttime'],
            productsOff: ['!products.worktask_starttime'],
            needs: ['products.taskmanagement'],
        },
    ],
    drivingLogSettings: [
        {
            title: 'menu.drivinglog',
        },
        {
            title: 'settings.general.drivinglog.gauge',
            productsOn: ['products.drivinglog_gauge'],
            productsOff: ['!products.drivinglog_gauge'],
            needs: ['products.drivinglog'],
        },
        {
            title: 'settings.general.drivinglog.project',
            productsOn: ['products.drivinglog_project'],
            productsOff: ['!products.drivinglog_project'],
            needs: ['products.drivinglog'],
        },
        {
            title: 'settings.general.drivinglog.date',
            productsOn: ['products.drivinglog_date'],
            productsOff: ['!products.drivinglog_date'],
            needs: ['products.drivinglog'],
        },
        {
            title: 'settings.general.drivinglog.private',
            productsOn: ['products.drivinglog_private'],
            productsOff: ['!products.drivinglog_private'],
            needs: ['products.drivinglog'],
        },
        {
            title: 'settings.general.drivinglog.plate',
            productsOn: ['products.drivinglog_plate'],
            productsOff: ['!products.drivinglog_plate'],
            needs: ['products.drivinglog'],
        },
        {
            title: 'settings.general.drivinglog.desc',
            productsOn: ['products.drivinglog_desc'],
            productsOff: ['!products.drivinglog_desc'],
            needs: ['products.drivinglog'],
        },
    ],
    measurementSettings: [
        {
            title: 'settings.measurements',
        },
        {
            name: 'hide_measurement_index',
            title: 'settings.general.hide_measurement_index',
            needs: ['products.measurement_tr||products.measurement_mvr'],
        },
        {
            title: 'settings.general.measurement_mandatory_signatures',
            productsOn: ['products.measurement_mandatory_signatures'],
            productsOff: ['!products.measurement_mandatory_signatures'],
            needs: ['products.measurement_tr||products.measurement_mvr'],
        },
        {
            title: 'settings.general.measurement_status',
            productsOn: ['products.measurement_status'],
            productsOff: ['!products.measurement_status'],
            needs: ['products.measurement_tr||products.measurement_mvr'],
        },
    ],
    pl_settings: [
        {
            title: 'settings.pl',
        },
        {
            title: 'settings.plgps',
            options: [
                {
                    products: ['!products.plgps_off', '!products.plgps_end'],
                    title: 'settings.plgps_on',
                },
                {
                    products: ['!products.plgps_off', 'products.plgps_end'],
                    title: 'settings.plgps_also_end',
                },
                {
                    products: ['products.plgps_off', '!products.plgps_end'],
                    title: 'settings.plgps_off',
                },
            ],
            needs: ['products.personalliggareandid06||products.personalliggare'],
        },
        {
            name: 'stampout_presence_fi',
            setting: {
                name: 'stampout_presence_fi',
                secondary_setting_name: 'stampout_presence_time_fi',
            },
            title: 'settings.general.stampout_presence',
            component: 'collector/field-setting-comp/time-setting',
            needs: ['presence', 'serviceCountry@fi'],
            random: true,
        },
        {
            title: 'settings.export_contact_person',
            form: 'export_contact_person',
            needs: ['export_contact_person', '!worktime'],
        },
        {
            title: 'settings.customers',
            productsOn: ['products.customer'],
            productsOff: ['!products.customer'],
            form: 'customer',
            needs: ['presence', '!products.timetracker'],
        },
        {
            title: 'settings.groups',
            options: [
                {
                    products: ['!products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.nogroups',
                },
                {
                    products: ['products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.groups',
                },
                {
                    products: ['!products.groups', 'products.multigroups'],
                    title: 'settings.timetracker.multigroups',
                },
            ],
            form: 'group',
            needs: ['presence', '!products.timetracker'],
        },
    ],
    admintoolSettings: [
        {
            title: 'settings.groups',
            form: 'group',
            needs: ['products.admintool', 'group'],
        },
    ],
    saldoSettings: [
        {
            title: 'settings.saldo',
        },
        {
            name: 'saldo_skip_pieceworkdays',
            title: 'settings.saldo.skip_pieceworkdays',
            needs: ['products.saldo', 'worktime.typeofwork'],
        },
        {
            name: 'saldo_skip_weekends',
            title: 'settings.saldo.skip_weekends',
            needs: ['products.saldo'],
        },
    ],
    machineSettings: [
        {
            title: 'settings.machine',
        },
        {
            title: 'settings.toolgroups',
            productsOn: ['products.toolgroups'],
            productsOff: ['!products.toolgroups'],
            needs: ['products.machine'],
            form: 'toolgroup',
        },
    ],
};
