import Controller, { inject as controller } from '@ember/controller';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    saved: controller('saved'),
    modelAlias: alias('model'),

    settings: computed(function () {
        // if hour-type adding
        let ret;
        if (this.Collector.testNeedsOne('products.absences_hours')) {
            ret = {
                form: 'abcense',
                skip: ['dayamount', 'status', 'enddate'],
                mandatory: ['startdate', 'absence_hours'],
            };
        } else {
            ret = {
                form: 'abcense',
                skip: ['dayamount', 'status', 'enddate'],
                mandatory: ['starttime', 'endtime', 'startdate'],
            };
        }
        return ret;
    }),

    actions: {
        recordSaved(settings, record) {
            set(this, 'saved.model', record);
            set(this, 'saved.hideAddLinks', true);
            return this.transitionToRoute('saved');
        },
        cancel() {
            return this.transitionToRoute('abcenses.index');
        },
    },
});
