/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/report-filters-v2/component.coffee.old
// Generated by CoffeeScript 2.6.1
var ReportFiltersComponent;

import Ember from 'ember';

ReportFiltersComponent = Ember.Component.extend({
    ollector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    filterMode: false,
    statusName: Em.computed('intl.primaryLocale', 'status', function () {
        if (this.get('intl').exists('status.' + this.get('status'))) {
            return this.get('intl').t('status.' + this.get('status'));
        } else {
            return this.get('status');
        }
    }),
    statusTypes: Em.computed('form', function () {
        var c, field, intl, option, options;
        if (!this.get('form')) {
            return [];
        }
        c = this.get('Collector');
        intl = this.get('intl');
        field = c.field(this.get('form'), 'status');
        options = (function () {
            var i, len, ref, results;
            ref = field.options;
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
                option = ref[i];
                if (intl.exists('status.' + option)) {
                    results.push({
                        id: option,
                        name: intl.t('status.' + option),
                    });
                } else {
                    results.push({
                        id: option,
                        name: option,
                    });
                }
            }
            return results;
        })();
        return options;
    }),
    _mainDateFilter: Ember.computed('filters', function () {
        return this.get('filters').find((filter) => {
            return filter.type === 'date';
        });
    }),
    _filters: Ember.computed('filters', function () {
        var c, filters, hiddenFilters, showfilters;
        c = this.get('Collector');
        filters = this.get('filters');
        if (!filters) {
            return;
        }
        filters.forEach(function (item, key) {
            if (item.type === 'date' && !Ember.isPresent(item.defaultValue)) {
                item.defaultValue = item.value;
            }
            Em.set(item, 'name', item.field);
            if (item.type != null) {
                return;
            }
            return (item.type = 'database');
        });
        hiddenFilters = [];
        showfilters = filters.filter(function (item) {
            if (item.type === 'date') {
                //if item.extra && c.fieldExists(item.form, item.field)
                //    hiddenFilters.push item
                //    return false
                return false;
            }
            return item.type === 'searchbox' || c.fieldExists(item.form, item.field);
        });
        //@set 'hiddenFilters', hiddenFilters
        return showfilters;
    }),
    groupByBox: Ember.computed('filters', function () {
        return this.get('filters').find((filter) => {
            return filter.type === 'groupByFilter';
        });
    }),
    showClickHere: Ember.computed('_filters.@each.value', function () {
        var novalues;
        novalues = true;
        this.get('_filters').forEach((filter) => {
            if (!Ember.isEmpty(filter.value)) {
                return (novalues = false);
            }
        });
        if (this.get('groupByBox.value')) {
            novalues = false;
        }
        return novalues;
    }),
    setDropdownFilterings: function (changeInField, value) {
        var filtering, i, len, options, ref, results, setFilter;
        ref = this.get('Collector').getDropdownDepency(changeInField);
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
            filtering = ref[i];
            setFilter = this.get('filters').findBy('name', filtering.field);
            if (!setFilter) {
                continue;
            }
            options = {};
            if (typeof value === 'object') {
                options[filtering.filter] = value ? value.get('id') : null;
            } else {
                options[filtering.filter] = value ? value : null;
            }
            results.push(setFilter.set('filterOptions', options));
        }
        return results;
    },
    actions: {
        toggleFilter: function () {
            return this.toggleProperty('filterMode');
        },
        status: function (status, filter) {
            //            filter.set 'value', status
            return this.set('status', status);
        },
        mainDateChanged: function (value) {
            return this.send('filterChanged', value, this.get('_mainDateFilter.name'));
        },
        filterChanged: function (value, fieldName) {
            var filter, id;
            this.setDropdownFilterings(fieldName, value);
            filter = this.get('filters').findBy('name', fieldName);
            if (fieldName && Ember.isPresent(filter) && Ember.isPresent(filter.subform)) {
                fieldName = filter.form + '-' + fieldName;
            }
            this.sendAction('filterValueChanged', value, fieldName);
            if (value) {
                if (typeof value.get === 'function') {
                    if (Array.isArray(value)) {
                        id = value.join();
                    } else {
                        id = value.get('id');
                    }
                } else {
                    id = value;
                }
            } else {
                id = null;
            }
            return filter.set('value', id);
        },
        searchChanged: function (value) {
            return this.sendAction('filterValueChanged', value);
        },
        dateChanged: function (field, value) {
            if (typeof value !== 'string') {
                return;
            }
            return this.sendAction('filterValueChanged', value, field);
        },
        resetFilter: function (filter) {
            return this.send('filterChanged', null, filter.field);
        },
        clearFilter: function (filter) {
            if (!Em.isBlank(filter.value)) {
                if (filter.extra || filter.type === 'groupByFilter') {
                    this.send('filterChanged', null, filter.field);
                    //                        @sendAction('filterValueChanged', null , item.field)
                    return Ember.set(filter, 'value', null);
                } else {
                    if (filter.type === 'date') {
                        this.sendAction('filterChanged', filter.defaultValue, filter.field);
                        Ember.set(filter, 'value', filter.defaultValue);
                    } else {
                        Ember.set(filter, 'value', null);
                    }
                    if (filter.type === 'searchbox') {
                        return Ember.set(filter, 'tmpvalue', '');
                    }
                }
            }
        },
        clearFilters: function () {
            var filters, i, item, len, results;
            filters = this.get('filters');
            results = [];
            for (i = 0, len = filters.length; i < len; i++) {
                item = filters[i];
                results.push(this.send('clearFilter', item));
            }
            return results;
        },
    },
});

export default ReportFiltersComponent;
