import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import runAutomaticSalarytypes from '../runAutomaticSalarytypes';
import { isPresent } from '@ember/utils';
export default class SalarytypesWorktypesComponent extends Component {
    @service store;
    @service ttapi;
    @service evented;
    @service intl;
    @service notifications;
    @service scroller;
    @service dialogs;

    @tracked record;
    @tracked saving;
    @tracked error;
    @tracked errors;
    @tracked calculationTypes;
    @tracked calculations;
    @tracked showPassive;
    @tracked loadingCalculationTypes;
    @tracked showReport = false;
    @tracked showTooltip = {};
    @tracked loadingDefault;
    @tracked recordToBeSavedAlso;

    constructor() {
        super(...arguments);
        this.worktimegroup = this.args.worktimegroup ? this.args.worktimegroup : null;
        this.filters = this.worktimegroup ? { worktimegroup: this.worktimegroup } : {};
        this.updateSalaryCalculationTypes(this.worktimegroup);
        this.showPassive = false;
        this.worktimeGroupLocked = isPresent(this.args.worktimegroup);
        this.init();
    }

    async init() {
        this.showReport = false;
        await runAutomaticSalarytypes(this.ttapi);
        this.showReport = true;
    }

    @action
    getTranslatedValue(rawValues) {
        return rawValues.split(',').length + ' ' + this.intl.t('report.items_checked');
    }

    @action
    addWorktype() {
        this.error = null;
        this.errors = {};
        this.calculations = [];
        let defaults = {};
        if (this.worktimegroup) {
            defaults.worktimegroup = this.store.peekRecord('worktimegroup', this.worktimegroup);
        }
        this.updateSalaryCalculationTypes(this.worktimegroup);
        this.record = this.store.createRecord('worktypev2', defaults);
    }

    @action
    editWorktype(row) {
        this.error = null;
        if (!this.args.worktimegroup)
            this.updateSalaryCalculationTypes(row.worktimegroup ? row.worktimegroup.id : null);
        this.calculations = row['calculations'] ? row['calculations'].split(',') : [];
        this.record = row;
    }

    @action
    removeWorktype(row) {
        const filteredWorktypes = this.store
            .peekAll('worktypev2')
            .filterBy('worktimegroup', row.worktimegroup);
        if (filteredWorktypes.length <= 1)
            return this.notifications.warning(this.intl.t('worktypes.cannot_remove_last'), {
                autoClear: true,
            });
        this.evented.storeEvent('remove', 'worktypev2', row);
    }

    @action
    restoreWorktype(row) {
        this.evented.storeEvent('restore', 'worktypev2', row);
    }

    @action
    closeModal() {
        if (this.record && this.record.isNew) this.record.deleteRecord();
        else if (this.record) this.record.rollbackAttributes();
        this.evented.storeEvent('insert', 'worktypev2', null);
        this.record = null;
        // if we are closing modal but we have not persisted changes to other record, just discard those changes
        if (this.recordToBeSavedAlso) {
            this.recordToBeSavedAlso.rollbackAttributes();
            this.recordToBeSavedAlso = null;
        }
    }

    @action
    onFieldChange(field, value) {
        if (field === 'worktimegroup') this.updateSalaryCalculationTypes(value ? value.id : null);
        this.record[field] = value;
    }

    @action
    toggleCalculationValue(value) {
        if (!this.calculations.includes(value.name)) {
            if (this.validateSliders(value)) this.calculations.push(value.name);
        } else {
            if (!this.validateSliders(value)) return;
            const index = this.calculations.indexOf(value.name);
            if (index >= 0) this.calculations.splice(index, 1);
        }
        this.calculations = this.calculations;
    }

    // used for setting worktimetype as default
    // When setting default to this record, we unset default on previous record that had default-value
    // When trying to set default off, we can do it on new record but not on any other record because we cannot know which record we should set as default
    @action
    async setForSaveDefault() {
        this.loadingDefault = true;
        const found = await this.store.query('worktypev2', {
            default: 'on',
            worktimegroup: this.record.worktimegroup.id,
        });
        this.loadingDefault = false;
        let foundRecord = found.firstObject;

        // if this is a new record we can turn default on AND off..
        if (this.record.default) {
            if (this.record.isNew) {
                foundRecord.default = true;
                this.record.default = false;
            } else {
                // ..but if we trying to set default off when editing, we show dialog that you cannot do it
                this.dialogs.alert(this.intl.t('worktypes.cannot_unset_default'));
            }
        } else {
            // if another worktype has default, set it off (but do not save yet)
            if (found.length > 0) {
                const con2 = await this.dialogs.confirm(
                    this.intl.t('worktypes.confirm_substitue_default', {
                        from_type: '"' + foundRecord.name + '"',
                    }),
                );
                if (!con2) return;
                foundRecord.default = false;
                this.recordToBeSavedAlso = foundRecord;
            }
            this.record.default = true;
        }
    }

    @action
    async save() {
        this.saving = true;
        this.errors = {};
        try {
            if (this.record.isNew) {
                const found = await this.store.query('worktypev2', {
                    name: this.record.name,
                    worktimegroup: this.record.worktimegroup.id,
                });
                if (found.length) {
                    this.errors = {
                        name: this.intl.t('field.error.unique', { field: this.record.name }),
                    };
                    const modelContentElement = document.querySelector(
                        '.worktypev2-custom-modal .ember-movenium-modal-content',
                    );
                    const { scrollTop } = modelContentElement;
                    this.scroller.animateElement(modelContentElement, scrollTop, 0);
                    return false;
                }
            }
            await this.record.validate();
            this.calculationTypes = await this.calculationTypes;
            this.calculations = this.calculations.filter((cal) =>
                this.calculationTypes.mapBy('name').includes(cal),
            );
            this.record['calculations'] = this.calculations.join(',');
            await this.record.save();
            // save also the other record that we modified when we set default on this record..
            if (this.recordToBeSavedAlso) await this.recordToBeSavedAlso.save();
            await runAutomaticSalarytypes(this.ttapi);
            this.closeModal();
        } catch (e) {
            this.error = e;
        } finally {
            this.saving = false;
        }
    }

    @action
    toggleRemoved() {
        this.showPassive = !this.showPassive;
        this.evented.storeEvent('toggleRemoved', 'worktypev2', null);
    }

    @action
    togglePopover(id) {
        if (!isPresent(this.showTooltip[id])) this.showTooltip[id] = true;
        else this.showTooltip[id] = !this.showTooltip[id];
        this.showTooltip = this.showTooltip;
    }

    async updateSalaryCalculationTypes(worktimegroup) {
        this.loadingCalculationTypes = true;
        this.calculationTypes = await this.ttapi.request(
            'api/salary/calculations/' + worktimegroup,
        );
        this.loadingCalculationTypes = false;
    }

    validateSliders(value) {
        let errors = [];
        let message = this.intl.t('worktypes.calculation_errors.titel') + '<br>';
        let [fieldName, isSaldoField] = value.name.split(/(?=_)/g);

        if (!this.calculations.includes(value.name)) {
            //show alarm if trying to turn on overtime saldo calculation but saldo calculation is off
            if (isSaldoField && !this.calculations.includes('saldo'))
                errors.push(
                    '<br>' + this.intl.t('worktypes.calculation_errors.saldo_not_on') + '<br>',
                );
            //show alarm if trying to turn on some calculation but saldo or normal version of it is on
            let overlappingCalculation = isSaldoField ? fieldName : fieldName + '_saldo';
            if (this.calculations.includes(overlappingCalculation))
                errors.push(
                    '<br>' +
                        this.intl.t('worktypes.calculation_errors.calculation_overlap', {
                            calculation: this.translateOverlapCalc(overlappingCalculation, value),
                        }) +
                        '<br>',
                );
        } else {
            //show alarm if trying to remove saldo calculation but some overtime saldo calculation is on
            if (
                this.calculations.includes('saldo') &&
                fieldName == 'saldo' &&
                this.calculationsIncludesOvertimeSaldos()
            )
                errors.push(
                    '<br>' +
                        this.intl.t('worktypes.calculation_errors.OvertimeSaldo_calculation_on') +
                        '<br>',
                );
        }

        if (!errors.length) return true;

        errors.map((error) => (message += error));
        this.dialogs.alert(message);
        return false;
    }

    calculationsIncludesOvertimeSaldos() {
        return this.calculations.find((calc) => calc.includes('_saldo'));
    }

    translateOverlapCalc(overlappingCalc, calc) {
        if (calc.text) {
            let [fieldName, isSaldoField] = calc.text.split(/(?= )/g);
            return isSaldoField ? fieldName : fieldName + ' (saldo)';
        }
        return this.intl.t('worktime.' + overlappingCalc);
    }
}
