/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/site/user/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalSiteUserController;

import Ember from 'ember';

import GeneralReportController from '../../../../../controllers/general-report';

import collector from '../../../../../classes/collector';

TimetrackerApprovalSiteUserController = GeneralReportController.extend({
    //    status: 'all'
    queryParams: ['status', 'page'],
    form: 'worktime',
    localStorageKey: 'Tt4.WorktimeApproval',
    showColumns: ['task', 'status', 'starttime', 'endtime', 'work_hours', 'pause', 'description'],
    sortByDates: true,
    status: 'all',
    statusSelector: true,
    user: null,
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    project: null,
    date: Em.computed(function () {
        return this.get('model').get('query.date');
    }),
    setData: function (params) {
        this.set('user', params.user);
        this.set('project', params.project);
        return this.set('date', params.date);
    },
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                form: 'worktime',
                value: this.get('project'),
            }),
            Em.Object.create({
                field: 'user',
                form: 'worktime',
                value: this.get('user'),
            }),
            Em.Object.create({
                field: 'date',
                start: '',
                end: '',
                form: 'worktime',
                type: 'date',
                value: this.get('date'),
            }),
        ]);
    }),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: {
                modal: true,
            },
            historyRoute: 'timetracker.history',
        }),
    }),
    hideColumns: Em.on('init', function () {
        var fields;
        fields = collector.getFormObjects('worktime');
        if (
            fields &&
            fields.starttime.features.hide &&
            fields.endtime.features.hide &&
            fields.work_hours.features.mandatory
        ) {
            return this.set('settings.hideColumns', ['starttime', 'endtime']);
        }
    }),
});

export default TimetrackerApprovalSiteUserController;
