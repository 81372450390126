/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-report-day/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarReportDayComponent;

import Ember from 'ember';

CalendarReportDayComponent = Ember.Component.extend({
    isToday: Ember.computed('day', function () {
        return moment().isSame(this.get('day'), 'day');
    }),
    utcday: Ember.computed('day', function () {
        if (!this.get('day') || this.get('day') === '') {
            return;
        }
        return moment(this.get('day')).format('DD.MM');
    }),
});

export default CalendarReportDayComponent;
