/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/timeclean-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsIMEIFieldComponent;

import Ember from 'ember';

import base from '../base-field/component';

import { debounce } from '@ember/runloop';

CollectorFieldsIMEIFieldComponent = base.extend({
    onInit: Em.on('init', function () {
        var arr, i, item, len, value;
        value = this.get('model.rfid_device_id');
        if (value) {
            if (typeof value === 'string') {
                value = JSON.parse(value);
            }
            if (Array.isArray(value)) {
                arr = Em.A([]);
                for (i = 0, len = value.length; i < len; i++) {
                    item = value[i];
                    arr.pushObject({
                        value: parseInt(item, 10),
                    });
                }
                return this.set('array', arr);
            }
        }
    }),
    makeArray: Em.observer('model.rfid_device_id', function () {
        var arr, i, item, len, value;
        value = this.get('model.rfid_device_id');
        if (value) {
            if (typeof value === 'string') {
                value = JSON.parse(value);
            }
            if (Array.isArray(value)) {
                arr = Em.A([]);
                for (i = 0, len = value.length; i < len; i++) {
                    item = value[i];
                    arr.pushObject({
                        value: parseInt(item, 10),
                    });
                }
                return this.set('array', arr);
            }
        }
    }),
    setValue: Em.observer('array.[]', 'array.@each.value', function () {
        var i, item, len, ref, value;
        value = [];
        ref = this.get('array');
        for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            if (item.value !== '') {
                value.push(item.value);
            }
        }
        this.set('value', JSON.stringify(value));
        if (this.get('value') === this.get('field.value')) {
            return;
        }
        return this.sendAction('bubbleChange', this.get('value'), this.get('field.name'));
    }),
    actions: {
        change: function () {
            return debounce(
                this,
                function () {
                    return this.sendAction(
                        'bubbleChange',
                        this.get('value'),
                        this.get('field.name'),
                        true,
                        false,
                    );
                },
                2000,
            );
        },
        add: function () {
            if (!this.get('array')) {
                this.set('array', Em.A([]));
            }
            return this.get('array').pushObject({
                value: '',
            });
        },
        remove: function (obj) {
            return this.get('array').removeObject(obj);
        },
    },
});

export default CollectorFieldsIMEIFieldComponent;
