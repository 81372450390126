/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/site/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalSiteController;

import Ember from 'ember';

TimetrackerApprovalSiteController = Ember.Controller.extend({
    //    needs: ["timetracker/approval/site/user"]
    //    queryParams: ["date": {as: 'other-date'}]
    userController: Ember.inject.controller('timetracker/approval/site/user'),
    approvalIndexController: Ember.inject.controller('timetracker/approval/index'),
    sites: Ember.computed('model.worktimes.firstObject.project.id', function () {
        return this.get('approvalIndexController.sites');
    }),
    users: Ember.computed('model.worktimes.[]', function () {
        var k, ret, user, users, worktimes;
        worktimes = this.get('model.worktimes');
        users = Em.A({});
        worktimes.forEach(function (time) {
            var user_id;
            user_id = time.get('user.id');
            if (!users[user_id]) {
                return (users[user_id] = time.get('user'));
            }
        });
        ret = Em.A([]);
        for (k in users) {
            user = users[k];
            ret.pushObject(user);
        }
        return ret;
    }),
    currentUser: Ember.computed('users', 'userController.model.firstObject.user', function () {
        if (this.get('userController.model.firstObject.user')) {
            return this.get('userController.model.firstObject.user');
        } else {
            return this.get('users.firstObject');
        }
    }),
    nextUser: Ember.computed('users', 'userController.model.firstObject.user', function () {
        var found, i, len, ref, self, user;
        self = this;
        found = false;
        ref = this.get('users');
        for (i = 0, len = ref.length; i < len; i++) {
            user = ref[i];
            if (found) {
                return user;
            }
            if (user.get('id') === self.get('currentUser.id')) {
                found = true;
            }
        }
    }),
    previousUser: Ember.computed('users', 'userController.model.firstObject.user', function () {
        var i, last, len, ref, self, user;
        self = this;
        last = void 0;
        ref = this.get('users');
        for (i = 0, len = ref.length; i < len; i++) {
            user = ref[i];
            if (user.get('id') === self.get('currentUser.id')) {
                return last;
            }
            last = user;
        }
    }),
    currentSite: Ember.computed('sites', 'model.firstObject.project', function () {
        return this.get('model.worktimes.firstObject.project');
    }),
    previousSite: Ember.computed('sites', function () {
        var i, last, len, ref, self, site;
        self = this;
        last = void 0;
        ref = this.get('sites');
        for (i = 0, len = ref.length; i < len; i++) {
            site = ref[i];
            if (site['id'] === self.get('currentSite.id')) {
                return last;
            }
            last = site;
        }
    }),
    nextSite: Ember.computed('sites', function () {
        var found, i, len, ref, self, site;
        self = this;
        found = false;
        ref = this.get('sites');
        for (i = 0, len = ref.length; i < len; i++) {
            site = ref[i];
            if (found) {
                return site;
            }
            if (site['id'] === self.get('currentSite.id')) {
                found = true;
            }
        }
    }),
});

export default TimetrackerApprovalSiteController;
