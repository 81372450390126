import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import runAutomaticSalarytypes from '../runAutomaticSalarytypes';

export default class SalarytypesAbsencetypesComponent extends Component {
    @service evented;
    @service store;
    @service intl;
    @service('collector-service') Collector;
    @service ttapi;

    @tracked showPassive;
    @tracked record;
    @tracked saving;
    @tracked error;
    @tracked selectedWorktimegroups;
    @tracked showReport = true;
    @tracked showTooltip = {};

    constructor() {
        super(...arguments);
        this.filters = this.args.worktimegroup ? { worktimegroup: this.args.worktimegroup } : {};
        this.showPassive = false;
        this.init();
    }

    async init() {
        this.showReport = false;
        await runAutomaticSalarytypes(this.ttapi);
        this.showReport = true;
    }

    get disabled() {
        return isPresent(this.args.worktimegroup);
    }

    @action
    closeModal() {
        if (this.record && this.record.isNew) this.record.deleteRecord();
        else if (this.record) this.record.rollbackAttributes();
        this.evented.storeEvent('insert', 'abcensetype', null);
        this.record = null;
    }

    @action
    onFieldChange(field, value) {
        if (field === 'worktimegroup' && this.record.isNew) {
            this.selectedWorktimegroups = value;
            this.selectedWorktimegroups = this.selectedWorktimegroups;
        } else this.record[field] = value;
    }

    @action
    async save() {
        this.saving = true;

        try {
            await this.record.validate();
            if (!isEmpty(this.selectedWorktimegroups) && this.record.isNew) {
                const absenceTypes = await this.store.query('abcensetype', {
                    name: this.record.name,
                });
                for (let wtgroup of this.selectedWorktimegroups) {
                    const newRecord = this.Collector.copyRecord(this.record);
                    newRecord['worktimegroup'] = wtgroup;
                    const found = absenceTypes?.filterBy('worktimegroup', wtgroup);
                    if (found.length) {
                        return (this.error = this.intl.t('absencetype.error.unique', {
                            worktimegroup: wtgroup.name,
                        }));
                    }

                    await newRecord.save();
                }
            } else await this.record.save();
            this.closeModal();
        } catch (e) {
            this.error = e;
        } finally {
            this.saving = false;
        }
    }

    @action
    addAbsencetype() {
        this.error = null;
        this.selectedWorktimegroups = this.args.worktimegroup
            ? [this.store.peekRecord('worktimegroup', this.args.worktimegroup)]
            : [];
        this.record = this.store.createRecord('abcensetype');
    }

    @action
    editAbsencetype(row) {
        this.error = null;
        this.selectedWorktimegroups = row['worktimegroup'] ? [row['worktimegroup']] : [];
        this.record = row;
    }

    @action
    toggleRemoved() {
        this.showPassive = !this.showPassive;
        this.evented.storeEvent('toggleRemoved', 'abcensetype', null);
    }

    @action
    restoreAbsencetype(row) {
        this.evented.storeEvent('restore', 'abcensetype', row);
    }

    @action
    removeAbsencetype(row) {
        this.evented.storeEvent('remove', 'abcensetype', row);
    }

    @action
    togglePopover(id) {
        if (!isPresent(this.showTooltip[id])) this.showTooltip[id] = true;
        else this.showTooltip[id] = !this.showTooltip[id];
        this.showTooltip = this.showTooltip;
    }
}
