/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/change-status/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import { pluralize } from 'ember-inflector';

Component = Ember.Component.extend({
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    capi: Ember.inject.service(),
    rowCount: Ember.computed('params.rowCount', function () {
        return this.get('params.rowCount');
    }),
    rowHeap: [],
    fullHeapSize: 0,
    done: false,
    numericalProgress: 0,
    progress: Ember.computed('numericalProgress', function () {
        return this.get('numericalProgress') + ' %';
    }),
    progressStyle: Ember.computed('progress', function () {
        return Ember.String.htmlSafe('width: ' + this.get('numericalProgress') + '%');
    }),
    setStatusForAll: function (changeData) {
        var data, form, reqParams;
        reqParams = this.get('params.requestParams');
        form = this.get('params.form');
        data = {};
        data[form] = changeData;
        this.set('executing', true);
        return this.get('store')
            .query(form, reqParams)
            .then((rows) => {
                var array_row_ids, chunkSize, handled, i, j, promises, ref, row_ids, slices;
                row_ids = [];
                array_row_ids = [];
                rows.forEach((row) => {
                    return row_ids.push(row.get('id'));
                });
                // set size of id's to capi multiedit
                chunkSize = 10;
                slices = Math.ceil(row_ids.length / chunkSize);
                //covert long id array to chunks of max chunkSize
                for (
                    i = j = 0, ref = slices;
                    0 <= ref ? j < ref : j > ref;
                    i = 0 <= ref ? ++j : --j
                ) {
                    array_row_ids.push(row_ids.slice(i * chunkSize, (i + 1) * chunkSize));
                }
                promises = [];
                handled = 0;
                array_row_ids.forEach((item) => {
                    var form_plural, promise;
                    data[form].id = item;
                    data[form].meta = {
                        insert_history: 'true',
                    };
                    form_plural = pluralize(form).camelize();
                    promise = this.get('capi')
                        .request('/' + form_plural, {
                            dataType: 'json',
                            type: 'PUT',
                            data: JSON.stringify(data),
                        })
                        .then(() => {
                            handled++;
                            return this.set(
                                'numericalProgress',
                                Math.ceil((handled / array_row_ids.length) * 100),
                            );
                        })
                        .catch((e) => {
                            this.get('notifications').error(e, {
                                autoClear: true,
                            });
                            handled++;
                            return this.set(
                                'numericalProgress',
                                Math.ceil((handled / array_row_ids.length) * 100),
                            );
                        });
                    return promises.push(promise);
                });
                return Em.RSVP.Promise.all(promises).then(() => {
                    this.set('executing', false);
                    return this.set('done', true);
                });
            });
    },
    actions: {
        closeModal: function () {
            return this.sendAction('close');
        },
        changeStatusForAll: function () {
            var action, changeData, locked, status;
            action = this.get('params.action');
            status = this.get('params.status');
            if (action === 'lock' || action === 'unlock') {
                locked = this.get('params.action') === 'lock' ? true : false;
                changeData = {
                    locked: locked,
                };
            } else {
                changeData = {
                    status: status,
                };
            }
            return this.setStatusForAll(changeData);
        },
    },
});

export default Component;
