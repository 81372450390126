/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saldocalendar/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoRoute;

import Ember from 'ember';

UsersEditSaldoRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    queryParams: {
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var enddate, promise, startdate, user;
        if (!Ember.get(params, 'user') || !Ember.get(params, 'date')) {
            return null;
        }
        user = Ember.get(params, 'user');
        startdate = Ember.get(params, 'date').split('_')[0];
        enddate = Ember.get(params, 'date').split('_')[1];
        promise = new Ember.RSVP.Promise((resolve) => {
            return this.get('Collector')
                .ttapi({
                    url: 'saldo/get_saldo?userid=' + user,
                })
                .then((back) => {
                    return resolve({
                        value: back['saldo'] / 60,
                        from: back['from'],
                        to: back['to'],
                    });
                });
        });
        return promise.then((currentSaldo) => {
            return Ember.RSVP.hash({
                currentSaldo: currentSaldo,
                list: this.get('Collector').ttapi({
                    url:
                        'saldo/get_saldo_list?startdate=' +
                        startdate +
                        '&enddate=' +
                        enddate +
                        '&userid=' +
                        user,
                }),
                user: user,
                saldo_settings: this.get('Collector').ttapi({
                    url: 'saldo/saldoSettings?userid=' + user,
                }),
            });
        });
    },
    actions: {
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default UsersEditSaldoRoute;
