/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/edit/employer-files/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersEditEmployerFilesRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

EmployersEditEmployerFilesRoute = GeneralReportRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    queryParams: {
        page: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var employer;
        params = this._super(params);
        employer = this.modelFor('employers.edit');
        params['employer'] = employer.get('id');
        return this.store.query('employerfile', params);
    },
});

export default EmployersEditEmployerFilesRoute;
