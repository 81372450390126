/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/general-add.coffee.old
// Generated by CoffeeScript 2.6.1

/*

Customizations:
  any field on general add form can be replaced in controller by

    customization:
        <field name>:
            template: "something"

  Sending actions back to controller:
    template: {{action "send" "someaction"}}
    controller:
        actions:
            generalAddAction: (type) -> # type is going to be "someaction"

*/
var GeneralAddController,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from '../classes/collector';

import config from '../config/environment';

import $ from 'jquery';

GeneralAddController = Ember.Controller.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    saved: Ember.inject.controller(),
    fieldtypes_not_to_show: ['device'],
    external_errors: Em.A([]),
    fieldSettings: Ember.Object.create({
        title: true,
    }),
    hasError: Ember.computed(function () {
        var error_types, errors;
        errors = collector.getErrors(this.get('form'));
        error_types = this.Collector.parseFormErrors(errors);
        this.set('errorMessages', error_types);
        if (errors) {
            this.set('disabled', true);
            return true;
        }
        return false;
    }),
    observeExternalErrors: Em.observer('external_errors.[]', function () {
        var err, error_msg, i, intl, len, ref;
        intl = this.get('intl');
        //        Ember.set(@,"disabled", false)
        if (this.get('external_errors.length') > 0) {
            //            Ember.set(@,"disabled", true)
            error_msg = '';
            ref = this.get('external_errors');
            for (i = 0, len = ref.length; i < len; i++) {
                err = ref[i];
                error_msg += intl.t(err) + '<br>';
            }
            return this.set('validationMessage', Ember.String.htmlSafe(error_msg));
        } else {
            //            Ember.set(@,"disabled", false)
            return this.set('validationMessage', null);
        }
    }),
    form: Ember.computed.alias('model._internalModel.modelName'),
    formName: Ember.computed(function () {
        var form;
        form = this.get('Collector').forms()[this.get('form').underscore()];
        if (Ember.isPresent(form.name)) {
            return form.name;
        }
    }),
    fields: Ember.computed('model', function () {
        var customization, fields, form_name, objects, self;
        self = this;
        form_name = this.get('form');
        if (form_name) {
            form_name = form_name.underscore();
        }
        customization = this.get('customization');
        if (!form_name) {
            objects = this.get('model.objects');
        } else {
            objects = JSON.parse(JSON.stringify(collector.forms[form_name].objects));
        }
        fields = [];
        if (!objects) {
            return;
        }
        $.each(objects, function (index, obj) {
            var feature, item, k, ref, ref1, value;
            obj.ext = index;
            obj.form = form_name;
            if (customization) {
                ref = customization[index];
                for (k in ref) {
                    item = ref[k];
                    if (typeof item === 'object' && obj[k] != null) {
                        for (feature in item) {
                            value = item[feature];
                            if (typeof value === 'object') {
                                if (!obj[k][feature]) {
                                    obj[k][feature] = value;
                                }
                            } else {
                                obj[k][feature] = value;
                            }
                        }
                    } else {
                        obj[k] = item;
                    }
                }
                if (index in customization) {
                    obj.customization = customization[index];
                }
            }
            // if parent has set customization default we set it to model directly
            // (used at least with user status = ok)
            if (obj.customization && obj.customization.default) {
                if (Ember.typeOf(obj.customization.default) === 'string') {
                    self.set('model.' + obj.ext, obj.customization.default);
                } else {
                    self.set('model.' + obj.ext, obj.customization.default());
                }
            }
            if (((ref1 = obj.type), indexOf.call(self.get('fieldtypes_not_to_show'), ref1) < 0)) {
                return fields.push(obj);
            }
        });
        this.setInitialFilters(fields);
        return fields;
    }),
    // This just doesn't work with conditional mandatory and no idea how to fix it
    // disabled: Ember.computed('errors.[]', ->
    //     if @get('submitted') == false
    //         return false
    //     else if @get('errors.length') > 0
    //         return true
    //     false
    // )
    submitted: false,
    setErrorMessage: Ember.observer('validationMessageData', function () {
        var data;
        data = this.get('validationMessageData');
        if (Ember.isArray(data) && Ember.isPresent(data[0]) && Ember.isPresent(data[0].title)) {
            return this.setErrorMsg(data[0].detail);
        } else if (Ember.isArray(data)) {
            data = data[0];
        }
        switch (data.error) {
            case 'overlap':
                return this.setOverlapMessage(data.errorMeta);
            case 'not_only_row':
                return this.setErrorMsg(data.error);
            case 'validation_error':
                return this.setValidationMsg(data.error_msg);
            default:
                return this.set(
                    'validationMessage',
                    Ember.String.htmlSafe(
                        'unknown error: <br>' + data.error + ' - ' + data.error_msg,
                    ),
                );
        }
    }),
    setOverlapMessage: function (meta) {
        var intl, start, store;
        store = this.get('store');
        intl = this.get('intl');
        if (meta.hits === 'worktime') {
            start = intl.t('validation.worktime_overlap');
            this.set('validationMessage', start);
            store
                .query('worktime', {
                    id: meta.ids.join(','),
                    sideload: true,
                })
                .then((worktimes) => {
                    var add;
                    add = '';
                    worktimes.forEach((item) => {
                        return (add +=
                            '<br> - ' +
                            item.get('project.name') +
                            ' ' +
                            item.get('starttime') +
                            ' - ' +
                            item.get('endtime'));
                    });
                    return this.set(
                        'validationMessage',
                        Ember.String.htmlSafe(start + '<br>' + add),
                    );
                });
        }
        if (meta.hits === 'absence') {
            start = intl.t('validation.absence_overlap');
            this.set('validationMessage', start);
            store
                .query('abcense', {
                    id: meta.ids.join(','),
                    sideload: true,
                })
                .then((absences) => {
                    var add;
                    add = '';
                    absences.forEach((item) => {
                        return (add +=
                            '<br> - ' +
                            item.get('abcensetype.name') +
                            ' ' +
                            moment(item.get('startdate')).format('L') +
                            ' - ' +
                            moment(item.get('enddate')).format('L'));
                    });
                    return this.set(
                        'validationMessage',
                        Ember.String.htmlSafe(start + '<br>' + add),
                    );
                });
        }
        if (meta.hits === 'comp') {
            start = intl.t('validation.comp_overlap');
            this.set('validationMessage', start);
            return store
                .query('worktime', {
                    id: meta.ids.join(','),
                    sideload: true,
                })
                .then((worktimes) => {
                    var add;
                    add = '';
                    worktimes.forEach((item) => {
                        return (add +=
                            '<br> - ' +
                            item.get('project.name') +
                            ' ' +
                            moment(item.get('date')).format('L'));
                    });
                    return this.set(
                        'validationMessage',
                        Ember.String.htmlSafe(start + '<br>' + add),
                    );
                });
        }
    },
    validationMessage: null,
    setErrorMsg: function (msg) {
        switch (msg) {
            case 'The adapter operation was aborted':
                return this.set(
                    'validationMessage',
                    Ember.String.htmlSafe(this.get('intl').t('validation.network_error')),
                );
            case 'not_only_row':
                return this.set(
                    'validationMessage',
                    Ember.String.htmlSafe(this.get('intl').t('validation.worktime_not_only_row')),
                );
        }
    },
    setValidationMsg: function (msg) {
        //will display translated message for validation.msg if the key exists in intl: if msg is "network_error" will translate validation.network_error,
        //else if the translation key does not exist will default to "Validation error: validation message"
        return this.set(
            'validationMessage',
            Ember.String.htmlSafe(
                this.get('intl').t('validation.' + msg, {
                    default: 'Validation error <br>' + msg,
                }),
            ),
        );
    },
    custom_projects: Em.A([]),
    stashed_fields: Em.computed('fields', function () {
        var field, fields, i, len, stashed_arr;
        fields = this.get('fields');
        if (!fields) {
            return;
        }
        stashed_arr = [];
        for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];
            if (field.features.stashed) {
                stashed_arr.push(field);
            }
        }
        return stashed_arr;
    }),
    setInitialFilters: function (fields) {
        return fields.forEach((field) => {
            var fieldObj, reloadFields;
            fieldObj = this.get('Collector').fieldArray(this.get('form')).findBy('name', field.ext);
            if (!fieldObj) {
                return;
            }
            if (!this.get('model.' + field.ext)) {
                return;
            }
            if (fieldObj.features.reload) {
                reloadFields = fieldObj.features.reload;
                if (Ember.isArray(reloadFields)) {
                    return reloadFields.forEach((fieldObj) => {
                        return this.setFiltersForField(
                            fields,
                            this.get('model.' + field.ext),
                            field.ext,
                            fieldObj,
                        );
                    });
                } else {
                    return this.setFiltersForField(
                        fields,
                        this.get('model.' + field.ext),
                        field.ext,
                        reloadFields,
                    );
                }
            }
        });
    },
    setFilterOptionsByLinkings: function (value, field) {
        var fieldObj, reloadFields;
        fieldObj = this.get('Collector').fieldArray(this.get('form')).findBy('name', field);
        if (!fieldObj) {
            return;
        }
        if (fieldObj.features.reload) {
            reloadFields = fieldObj.features.reload;
            if (Ember.isArray(reloadFields)) {
                return reloadFields.forEach((fieldObj) => {
                    return this.setFiltersForField(this.get('fields'), value, field, fieldObj);
                });
            } else {
                return this.setFiltersForField(this.get('fields'), value, field, reloadFields);
            }
        }
    },
    setFiltersForField: function (fields, value, fieldName, reloadFieldName) {
        var reloadField;
        reloadField = fields.findBy('ext', reloadFieldName);
        if (!reloadField) {
            return;
        }
        return Ember.set(reloadField, 'filter', this.createFilterOptions(fieldName, value));
    },
    createFilterOptions: function (fieldName, value) {
        var filterOptions;
        filterOptions = {};
        filterOptions[fieldName] = value;
        return filterOptions;
    },
    actions: {
        employerSelect: function (id) {
            return console.log(id);
        },
        specialAction: function (field) {
            return false;
        },
        show_stashed_fields: function () {
            this.toggleProperty('stashed_visible');
            return false;
        },
        generalAddAction: function (value, field) {
            this.setFilterOptionsByLinkings(value, field);
            return false;
        },
    },
});

export default GeneralAddController;
