import { helper } from '@ember/component/helper';
import moment from 'moment';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';

export function isWeekend(params /*, hash*/) {
    // Week numbers are isoweek => from monday - sunday / 1-7
    assert('Must pass one parameter with either date or day of week number', isPresent(params[0]));
    return [6, 7].includes(isNaN(params[0]) ? moment(params[0]).isoWeekday() : params[0]);
}

export default helper(isWeekend);
