/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/database-multiple-filter/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

Component = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    mySites: Ember.inject.service(),
    //which filter options are affected by query params
    linked_fields: ['worktime.user'],
    placeholder: Em.computed(function () {
        if (!this.get('filter.prompt')) {
            return this.get('intl').t(this.get('filter.form') + '.' + this.get('filter.field'));
        }
        return this.get('intl').t(this.get('filter.prompt'));
    }),
    show_deleted: false,
    salaryTypeFields: ['abcensetype', 'worktype'],

    //must have observer to observe filter clear
    valueObs: Em.observer('filter.value', function () {
        if (Em.isEmpty(this.get('filter.value'))) {
            this.set('row', []);
            return this.sendAction('change', [], this.get('filter.field'));
        }
    }),
    init: function () {
        var filter_ids, promise_arr, selected_rows;
        this._super();
        if (this.get('filter.name') && !this.get('filter.field')) {
            this.set('filter.field', this.get('filter.name'));
        }
        if (!Em.isEmpty(this.get('filter.value'))) {
            filter_ids = [];
            if (typeof this.get('filter.value') === 'string') {
                filter_ids = this.get('filter.value').split(',');
            }
            selected_rows = [];
            promise_arr = [];
            return this.get('options').then((options) => {
                filter_ids.forEach((filter_id) => {
                    var sel_opt;
                    sel_opt = options.findBy('id', filter_id);
                    //if option not found in list already
                    if (Em.isEmpty(sel_opt)) {
                        promise_arr.push(
                            this.get('Collector').findFormRelations(
                                this.get('filter.form'),
                                this.get('filter.field'),
                                {
                                    id: filter_id,
                                },
                            ),
                        );
                        return promise_arr.push(
                            this.get('Collector').findFormRelations(
                                this.get('filter.form'),
                                this.get('filter.field'),
                                {
                                    id: filter_id,
                                    row_info_status: 'removed',
                                },
                            ),
                        );
                    } else {
                        return selected_rows.push(sel_opt);
                    }
                });
                if (!Em.isEmpty(promise_arr)) {
                    return Ember.RSVP.Promise.all(promise_arr).then((rows) => {
                        //if second row not empty = show deleted is selected
                        if (!Em.isEmpty(rows[1])) {
                            this.set('show_deleted', true);
                        }
                        rows.forEach((rowobj) => {
                            var obj, row, text;
                            row = rowobj.get('firstObject');
                            if (!Em.isEmpty(row)) {
                                text = this.getText(row);
                                obj = {
                                    id: row.get('id'),
                                    name: text,
                                };
                                return selected_rows.push(obj);
                            }
                        });
                        return this.set('row', selected_rows);
                    });
                } else {
                    return this.set('row', selected_rows);
                }
            });
        }
    },
    currentMySites: Ember.computed(function () {
        var currentMySites, slicedArr;
        currentMySites = this.get('mySites').loadFromLocalStorage();
        slicedArr = currentMySites.slice(0, 5);
        return slicedArr;
    }),
    options: Em.computed('show_deleted', function () {
        var arr,
            currentMySites,
            field,
            filterOptions,
            formfield,
            key,
            links,
            mySitesArr,
            params,
            projectFields;
        params = {};
        arr = [];
        mySitesArr = [];
        currentMySites = this.get('currentMySites');
        projectFields = ['project', 'ori_site', 'contract_project'];
        //filter options only when field is found in linked_fields
        formfield = this.get('filter.form') + '.' + this.get('filter.field');
        if (this.get('linked_fields').includes(formfield)) {
            field = this.get('Collector').field(this.get('filter.form'), this.get('filter.field'));
            //get this field forms database links
            links = this.get('Collector')
                .fieldArray(field.options.form)
                .filterBy('type', 'database')
                .mapBy('options.form');
            //check if some filter which has value is related to this filter
            this.get('allFilters').forEach((other_filter) => {
                var real_field;
                //skip filters without value
                if (Em.isEmpty(other_filter.value)) {
                    return;
                }
                //skip all but database fields
                if (other_filter.type !== 'database') {
                    return;
                }
                //skip self
                if (other_filter.field === this.get('filter.field')) {
                    return;
                }
                //check if filter is found in current form fields
                if (links.includes(other_filter.field)) {
                    //get real name for field
                    real_field = this.get('Collector')
                        .fieldArray(field.options.form)
                        .filterBy('options.form', other_filter.field)
                        .get('firstObject');
                    //and add it to params
                    return (params[real_field.name] = other_filter.value);
                }
            });
        }
        if (this.get('show_deleted')) {
            params.row_info_status = 'removed';
        }
        filterOptions = this.get('filter.filterOptions');
        if (filterOptions) {
            for (key in filterOptions) {
                if (filterOptions[key]) {
                    params[key] = filterOptions[key];
                }
            }
        }
        // set mysite options
        if (projectFields.includes(this.get('filter.field')) && currentMySites.length > 0) {
            this.set('showMySitesLabel', true);
            this.set('showOthersLabel', true);
            this.findMysites(currentMySites.getEach('id')).then((items) => {
                return items.forEach((item) => {
                    return mySitesArr.push({
                        id: item.id,
                        name: this.getText(item),
                    });
                });
            });
        }
        // return filter options with subheaders
        if (
            this.salaryTypeFields.includes(this.get('filter.field')) &&
            this.Collector.testNeedsOne('products.salarytype_v2')
        ) {
            return this.getOptionsWithSubheaders(this.filter, params);
        }

        // set other options
        return this.get('Collector')
            .findFormRelations(this.get('filter.form'), this.get('filter.field'), params)
            .then((rows) => {
                var randomId;
                rows.forEach((row) => {
                    var text;
                    text = this.getText(row);
                    return arr.push({
                        id: row.id,
                        name: text,
                    });
                });
                randomId =
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15);
                if (this.get('showMySitesLabel')) {
                    mySitesArr.unshift({
                        id: randomId,
                        label: this.get('intl').t('my-sites.title'),
                        disabled: true,
                    });
                }
                if (this.get('showOthersLabel')) {
                    arr.unshift({
                        id: randomId + 1,
                        label: this.get('intl').t('other-sites.label'),
                        disabled: true,
                    });
                }
                if (mySitesArr.length > 0) {
                    arr = mySitesArr.concat(arr).uniqBy('id');
                }
                if (this.get('show_deleted')) {
                    arr.push({
                        hide_deleted: true,
                    });
                } else {
                    arr.push({
                        show_deleted: true,
                    });
                }
                return arr;
            });
    }),
    // why: some salarytype fields needs subheaders,
    // ie service can have multiple absence types with same name so we need to have subheader that tells user which worktimegroup it belongs to
    async getOptionsWithSubheaders(filter, params) {
        params.sideload = true;
        let options = await this.store.query(filter.options.form, params);
        let orderedOptions = await options.sortBy('worktimegroup.name');
        let filterOptionsArr = [];

        orderedOptions.map((item) => {
            // if worktimegroup label is not found in optionsArr, add it
            if (
                item.worktimegroup &&
                !filterOptionsArr.find((option) => option?.id == item.worktimegroup.id)
            ) {
                filterOptionsArr.push({
                    id: item.worktimegroup.id,
                    label: item.worktimegroup.name,
                    disabled: true,
                });
            }
            // add item to optionsArr
            return filterOptionsArr.push({
                id: item.id,
                name: this.getText(item),
            });
        });
        // add show/hide deleted options
        filterOptionsArr.push(this.show_deleted ? { hide_deleted: true } : { show_deleted: true });

        return filterOptionsArr;
    },

    getText: function (record) {
        var field, i, len, ref, ref1, text, val;
        text = '';
        if (!record) {
            return '';
        }
        field = this.get('Collector').field(this.get('filter.form'), this.get('filter.field'));
        ref = field.options.fields;
        for (i = 0, len = ref.length; i < len; i++) {
            field = ref[i];
            if (!field) {
                continue;
            }
            val = record.get(field);
            if (val && (ref1 = Ember.typeOf(val)) !== 'object' && ref1 !== 'array') {
                text += val + ' ';
            }
        }
        return text.slice(0, -1);
    },
    findRows: function () {
        var arr, filterOptions, key, params, q, row_status;
        if (this.get('show_deleted')) {
            row_status = 'removed';
        }
        q = this.get('q');
        params = {
            q: q,
            row_info_status: row_status,
        };
        // add filter options also to search params
        filterOptions = this.get('filter.filterOptions');
        if (filterOptions) {
            for (key in filterOptions) {
                if (filterOptions[key]) {
                    params[key] = filterOptions[key];
                }
            }
        }
        // set filter options with subheaders
        if (
            this.salaryTypeFields.includes(this.get('filter.field')) &&
            this.Collector.testNeedsOne('products.salarytype_v2')
        ) {
            this.set('options', this.getOptionsWithSubheaders(this.filter, params));
            return this.set('searching', false);
        }

        arr = [];
        return this.get('Collector')
            .findFormRelations(this.get('filter.form'), this.get('filter.field'), params)
            .then((rows) => {
                if (this.get('isDestroyed')) {
                    return;
                }
                rows.forEach((row) => {
                    var text;
                    text = this.getText(row);
                    return arr.push({
                        id: row.id,
                        name: text,
                    });
                });
                if (this.get('show_deleted')) {
                    arr.push({
                        hide_deleted: true,
                    });
                } else {
                    arr.push({
                        show_deleted: true,
                    });
                }
                this.set('options', arr);
                return this.set('searching', false);
            });
    },
    findMysites: function (ids) {
        return this.get('Collector').findFormRelations(
            this.get('filter.form'),
            this.get('filter.field'),
            {
                id: ids.join(),
            },
        );
    },
    showIEPlaceholder: Ember.computed('row', function () {
        // Mega haxx for showing a "placeholder" on IE11 because power-select is buggy
        return this.get('isIE') && !this.get('row');
    }),
    actions: {
        selectRow: function (row) {
            var ids;
            if (!Em.isEmpty(row.findBy('show_deleted'))) {
                this.set('show_deleted', true);
                return;
            }
            if (!Em.isEmpty(row.findBy('hide_deleted'))) {
                this.set('show_deleted', false);
                return;
            }
            this.set('row', row);
            ids = row.mapBy('id').toString();
            ids = ids.replace(/(,$)|(^,)/g, ''); // make sure we don't have preceding or trailing comma.
            //do not send change action if values not actually changed
            if (ids === this.get('filter.value')) {
                return;
            }
            return this.sendAction('change', ids, this.get('filter.field'));
        },
        searchRows: function (q) {
            this.set('searching', true);
            this.set('options', [
                {
                    loader: true,
                },
            ]);
            this.set('q', q);
            return Ember.run.debounce(this, this.findRows, 500);
        },
        closeDropdown: function () {
            if (!Em.isNone(this.get('q'))) {
                this.set('q', void 0);
                return this.findRows();
            }
        },
        preventEnter: function (options, e) {
            if (e.key === 'Enter' && this.get('searching')) {
                e.preventDefault();
                return false;
            }
            return true;
        },
    },
});

export default Component;
