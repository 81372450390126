import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
    store: service(),
    intl: service(),

    selectedMonth: 0,

    init() {
        this._super();
        this.contacts = get(this, 'store').findAll('exportContactPerson');
        this.settings = { form: 'export-contact-person' };
    },

    taxmanerror: alias('model'),
    taxmanKey: computed('taxmanerror.proj_type', function () {
        if (get(this, 'taxmanerror.proj_type') == '4') {
            return 'vsurakka';
        } else {
            return 'vstyonte';
        }
    }),
    taxmanOptions: computed(function () {
        return [
            { id: 'vstyonte', name: get(this, 'intl').t('taxman.version.taxman_tyonte') },
            { id: 'vsurakka', name: get(this, 'intl').t('taxman.version.taxman_urakka') },
        ];
    }),

    contactSet: computed('contacts.[]', function () {
        let contacts = get(this, 'contacts').filter((contact) => {
            return get(contact, 'isNew') === false;
        });
        return contacts.length > 0;
    }),

    months: computed(function () {
        let count = 0;
        let montharr = [];

        while (count < 36) {
            const obj = {
                id: count,
                name: moment().subtract(count++, 'months').format('MMMM YYYY'),
            };
            montharr.push(obj);
        }
        return montharr;
    }),

    values: computed('model.id', function () {
        let month = get(this, 'selectedMonth');

        let moment_month = moment().subtract(month, 'months');

        const project = get(get(this, 'model'), 'id');

        let arr = [];

        if (get(this, 'taxmanKey') == 'vstyonte') {
            arr = [
                {
                    name: 'date',
                    value:
                        moment_month.startOf('month').format('YYYY-MM-DD') +
                        '_' +
                        moment_month.endOf('month').format('YYYY-MM-DD'),
                },
                {
                    name: 'project',
                    value: project,
                },
            ];
        } else {
            arr = [
                {
                    name: 'contract_month',
                    value: moment_month.format('YYYY-MM'),
                },
                {
                    name: 'contract_project',
                    value: project,
                },
            ];
        }

        return arr;
    }),

    actions: {
        toggleModal(settings, record) {
            if (record) this.notifyPropertyChange('contacts');
            this.toggleProperty('showModal');
        },

        fetchErrors() {
            this.notifyPropertyChange('values');
        },
    },
});
