/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/open-worktimes/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsOpenWorktimesController;

import Ember from 'ember';

import GeneralReportController from '../../../../controllers/general-report';

ProjectsOpenWorktimesController = GeneralReportController.extend({
    form: 'worktime',
    showColumns: ['status', 'user', 'date'],
    queryParams: ['date', 'status', 'user'],
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
        }),
        sortByColumn: 'date',
    }),
    filters: Em.A([
        Em.Object.create({
            field: 'date',
        }),
    ]),
    status: 'open',
    project: null,
    page: null,
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
});

export default ProjectsOpenWorktimesController;
