import { helper } from '@ember/component/helper';

// This function receives the params `params, hash`
export function showJson(params) {
    if (params[0]) {
        return JSON.stringify(params[0], null, params[1] ? params[1] : null);
    }
}

export default helper(showJson);
