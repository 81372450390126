/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/register/service.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterServiceRoute;

import Ember from 'ember';

import initmixin from 'tt4/mixins/init-route';

RegisterServiceRoute = Ember.Route.extend(initmixin, {
    beforeModel: function () {
        return this.transitionTo('register.service.adddetails');
    },
});

export default RegisterServiceRoute;
