/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/export-salary/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerExportSalaryAddController;

import Ember from 'ember';

import DS from 'ember-data';

TimetrackerExportSalaryAddController = Ember.Controller.extend({
    selectableIntegrations: Ember.computed.gt('model.length', 1),
    selectedIntegration: Ember.computed('_selectedIntegration', function () {
        return this.get('_selectedIntegration') || this.get('model.firstObject.key');
    }),
    checkSettings: function () {
        return new Ember.RSVP.Promise((resolve, reject) => {
            if (this.get('selectedIntegration') === 'general-fivaldi-salary-soap-v4') {
                return this.get('store')
                    .query('integrationsetting', {
                        integration: 'general-fivaldi-salary-soap',
                    })
                    .then((settings) => {
                        var mapped;
                        mapped = settings.mapBy('key');
                        if (!mapped.includes('businessid') || !mapped.includes('schema')) {
                            this.set('showModal', 'fivaldi');
                            return resolve();
                        } else {
                            return resolve();
                        }
                    });
            } else if (this.get('selectedIntegration') === 'general-netvisor-salary-rest-v4') {
                return this.get('store')
                    .query('integrationsetting', {
                        integration: 'general-netvisor-salary-rest',
                    })
                    .then((settings) => {
                        var mapped;
                        mapped = settings.mapBy('key');
                        if (!mapped.includes('cid') || !mapped.includes('customerID')) {
                            this.set('showModal', 'netvisor');
                            return resolve();
                        } else {
                            return resolve();
                        }
                    });
            } else {
                return resolve();
            }
        });
    },
    settings: Ember.computed('selectedIntegration', function () {
        return DS.PromiseObject.create({
            promise: this.checkSettings(),
        });
    }),
    actions: {
        closeModal: function () {
            return this.set('showModal', false);
        },
    },
});

export default TimetrackerExportSalaryAddController;
