import Service from '@ember/service';
import { get, set } from '@ember/object';
import { later } from '@ember/runloop';
import { captureMessage } from '@sentry/browser';
import { inject as service } from '@ember/service';
import Evented from '@ember/object/evented';

export default Service.extend(Evented, {
    session: service(),
    router: service(),

    refreshing: false,
    refreshPromise: null,
    expiredRecoveryStarted: false,

    tokenHasExpired() {
        return new Date().getTime() > get(this, 'session.data.authenticated.expires_at');
    },

    /**
     * HELPER TO TEST OLD TOKENS IN TT4
     *
     * NOTE: the hardcoded access token is only for testing purposes and can be replaved with your own expired token when testing
     *
     * Usage:
     *  1. go to /development and click "sessionhelper to console"
     *  2. execute in console: `setTimeout(() => {$E.makeTokenOld();console.log("token is now old")}, 3000)`
     *  3. click tt4 so onResume action is called before token is old
     *
     */
    makeTokenOld() {
        const expiresIn = -3600;
        set(
            this.session,
            'session.authenticated.access_token',
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm1vdmVuaXVtLmNvbSIsImF1ZCI6Imh0dHBzOlwvXC9hcGkubW92ZW5pdW0uY29tIiwiZXhwIjoxNjQxMzgyNDU0LCJpYXQiOjE2NDEzODYwNTQsInVzZXJuYW1lIjoiZ2NmcmNsQG1haWwuY29tIiwidXNlcmlkIjoiMjMyNjgxIiwicGFydG5lcmlkIjoiMzA1MDYiLCJjbGllbnRfaWQiOiJlbWJlcl9hcHAiLCJ1c2VybGV2ZWwiOiI1IiwiY291bnRyeSI6ImZpIn0.MyV_VmCa2zyMZ3lVyL3z9llJLM6ysjQIb4RC-XHbdA4ze9fvwZDgLyXQSN0avZ4S0HOkPkNwrXY4MkGpMG9TUWDf0uhM2lWRWeQq3-4Du8aP7opS-nJHiNoTvZSz5i12KPyrzwY1BADUKBprEjUci8N3nuTi7aIrTKZ_pzxl58U'
        );
        //set(this.session, "session.authenticated.refresh_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm1vdmVuaXVtLmNvbSIsImF1ZCI6Imh0dHBzOlwvXC9hcGkubW92ZW5pdW0uY29tIiwiZXhwIjoxNjQxNTM2NDE4LCJpYXQiOjE2NDE1NDAwMTgsInVzZXJuYW1lIjoiZ2NmcmNsQG1haWwuY29tIiwidXNlcmlkIjoiMjMyNjgxIiwicGFydG5lcmlkIjoiMzA1MDYiLCJjbGllbnRfaWQiOiJlbWJlcl9hcHAiLCJ0eXBlIjoicmVmcmVzaCJ9.UcRJBL7hhSu1nqGy6DJGr1aP8B-2fnZkwReHT7Z-m-OUBRaKT5B_HOi_gOvfC8Yu3ZJJ_KS85LbjqFdLZ9xyIwOfxbG-QTlKiAwf2Ezon_Y-nGjCwhpIl4u8kMExtdJqW-tAxvMsqiebXAgz4hV-sRKqrNZSfqNQJmBRL-eSMqI")
        set(this.session, 'session.authenticated.expires_in', expiresIn);
        set(
            this.session,
            'session.authenticated.expires_at',
            new Date(new Date().getTime() + expiresIn * 1000).getTime()
        );

        localStorage.setItem(
            'ember_simple_auth-session',
            JSON.stringify({
                alldone: true,
                authenticated: get(this.session, 'session.authenticated'),
            })
        );
    },

    refresh() {
        this.refreshing = true;
        this.refreshPromise = get(this, 'session').session.restore({
            refresh_token: get(this, 'session.session.authenticated.refresh_token'),
            expires_at: get(this, 'session.data.authenticated.expires_at'),
        });

        this.refreshPromise.then(() => {
            this.refreshing = false;
        });

        return this.refreshPromise;
    },

    resumed() {
        if (!this.tokenHasExpired()) this.trigger('resumed');
    },

    tokenWillExpireIn(minutes) {
        return (
            new Date().getTime() + minutes * 60000 >
            get(this, 'session.data.authenticated.expires_at')
        );
    },
    isOffline() {
        return get(this, 'session.session.authenticated.offline');
    },

    // This method is called when ember data gets 401 from backend.
    // Called by capi-3, application and ttapi-base adapters.
    //
    // why? onResume calls + simple auth timers should always make sure that token is never old when used ... BUT
    // in production it happens quite often that the token is old for some reason and user is kicked out.
    expiredRecovery(expiredRequest) {
        // if recovery is already started, do nothing
        if (this.expiredRecoveryStarted) return;
        this.expiredRecoveryStarted = true;

        // save the current route so we can go back to it after recovery
        const currentRoute = this.router.currentRouteName;

        // if refreshing is not started from somewhere else, start it now
        if (!this.refreshing) this.refresh();

        // goto to loading route so the whole route is rerendered after refresh is done
        this.router.transitionTo('loading');

        // wait for the refresh to complete and then reroute to the route where we were
        this.refreshPromise.then(() => {
            // eslint-disable-next-line no-console
            console.log(
                `expiredRecovery: failed request '${expiredRequest}' in route '${currentRoute}'`
            );
            captureMessage(
                `expiredRecovery: failed request '${expiredRequest}' in route '${currentRoute}'`
            );
            this.router.transitionTo(currentRoute);
        });

        // wait 15 minutes before resetting the recovery system
        // reason:
        //  1) some old requests might resolve after the recovery is already done and recovery is reinitiated unnecessarily
        //  2) if some problem causes infinite recovery loop .. it initiates max once every 15 minutes
        later(() => (this.expiredRecoveryStarted = false), 15 * 60 * 1000);
    },
});
