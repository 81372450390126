/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/fields/time-minutes/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FieldsTimeMinutesComponent;

import Ember from 'ember';

FieldsTimeMinutesComponent = Ember.Component.extend({
    hours: Em.computed('value', function () {
        var time;
        time = parseInt(this.value);
        if (isNaN(time)) {
            return null;
        }
        return Math.floor(time / 60);
    }),
    minutes: Em.computed('value', function () {
        var h, time;
        time = parseInt(this.value);
        if (isNaN(time)) {
            return null;
        }
        h = time / 60;
        return Math.round((h - Math.floor(h)) * 60);
    }),
    changeValue: function () {
        var hours, minutes, value;
        minutes = this.get('minutes');
        hours = this.get('hours');
        if (hours) {
            hours = parseInt(hours) * 60;
        }
        if (minutes) {
            minutes = parseInt(minutes);
        }
        value = hours + minutes;
        return this.sendAction('valueChanged', value);
    },
    actions: {
        setValue: function () {
            return this.changeValue();
        },
    },
});

export default FieldsTimeMinutesComponent;
