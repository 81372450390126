import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs(" <div role=\"button\" class=\"ember-movenium-accordion-item {{if this.active \"active\"}}\" {{on 'click' this.toggle value=\"event\"}}>\n\n    {{#if @title}}\n        {{@title}}\n    {{else}}\n        {{if this.active @hideTitle @showTitle}}\n    {{/if}}\n\n    <span class=\"accordion-chevron\" ></span>\n</div>\n<div class=\"ember-movenium-accordion-panel {{if this.active \"active\"}}\">\n    <div class=\"ember-movenium-accordion-panel-content\">\n        {{yield}}\n    </div>\n</div>\n", {"contents":" <div role=\"button\" class=\"ember-movenium-accordion-item {{if this.active \"active\"}}\" {{on 'click' this.toggle value=\"event\"}}>\n\n    {{#if @title}}\n        {{@title}}\n    {{else}}\n        {{if this.active @hideTitle @showTitle}}\n    {{/if}}\n\n    <span class=\"accordion-chevron\" ></span>\n</div>\n<div class=\"ember-movenium-accordion-panel {{if this.active \"active\"}}\">\n    <div class=\"ember-movenium-accordion-panel-content\">\n        {{yield}}\n    </div>\n</div>\n","moduleName":"tt4/components/addon/accordion/item.hbs","parseOptions":{"srcName":"tt4/components/addon/accordion/item.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AccordionItemComponent extends Component {
    @tracked public active: boolean = false;

    @action protected toggle(event: any): void {
        const target = event.target;
        const panel = target.nextElementSibling;
        if (!panel) return;
        this.active = !this.active;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    }
}
