/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/locationtree/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller;

import Ember from 'ember';

import config from '../../../config/environment';

Controller = Ember.Controller.extend();

export default Controller;
