/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/gps-location.coffee.old
// Generated by CoffeeScript 2.6.1
var GpsLocationComponent;

import Ember from 'ember';

import collector from '../classes/collector';

GpsLocationComponent = Ember.Component.extend({
    gps: Ember.inject.service(),
    store: Ember.inject.service(),
    notSupported: Ember.computed.alias('gps.lastError'),
    lockedPosition: Ember.computed(
        'gps.lockedPosition',
        'gps.lockedLatitude',
        'gps.lockedLongitude',
        'gps.lockedAccuracy',
        function () {
            var position;
            if (!this.get('gps.lockedPosition')) {
                Ember.run.next(this, () => {
                    return this.set('value', null);
                });
                return null;
            } else {
                position = {
                    latitude: this.get('gps.lockedLatitude'),
                    longitude: this.get('gps.lockedLongitude'),
                    accuracy: this.get('gps.lockedAccuracy'),
                };
                Ember.run.next(this, () => {
                    return this.set('value', JSON.stringify(position));
                });
                return position;
            }
        },
    ),
    distance: Ember.computed('model.project.id', 'lockedPosition', function () {
        return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
            promise: new Ember.RSVP.Promise((resolve) => {
                return this.get('store')
                    .query(
                        'project',
                        {
                            id: this.get('model.project.id'),
                        },
                        {
                            reload: true,
                        },
                    )
                    .then((project) => {
                        var distance, projectCoords;
                        if (!project.get('location_map')) {
                            resolve({
                                value: null,
                            });
                            return;
                        }
                        projectCoords = JSON.parse(project.get('location_map'));
                        distance = this.get('gps').getDistance(
                            {
                                lat: projectCoords.latitude,
                                lon: projectCoords.longitude,
                            },
                            {
                                lat: this.get('lockedPosition.latitude'),
                                lon: this.get('lockedPosition.longitude'),
                            },
                        );
                        return resolve({
                            value: Math.round(distance) + 'm',
                        });
                    });
            }),
        });
    }),
});

export default GpsLocationComponent;
