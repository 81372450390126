/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldo/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoController;

import Controller from '@ember/controller';

import { inject as service } from '@ember/service';

import { get, set, computed } from '@ember/object';

import { alias } from '@ember/object/computed';

UsersEditSaldoController = Controller.extend({
    Collector: service('collector-service'),
    session: service(),
    queryParams: ['date'],
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    saldos: alias('model.list'),
    showRecalculateButton: computed(function () {
        if (
            this.get('session.session.content.authenticated.scope') ||
            this.get('Collector').testNeeds(['development'])
        ) {
            return true;
        }
    }),
    modalTitle: computed('showDate', function () {
        return moment(this.get('showDate')).format('L');
    }),
    actions: {
        dayClicked: function (date) {
            return set(this, 'showDate', date);
        },
        //@get('Collector').ttapi
        //    url: "saldo/calculateSaldos?date=" + date
        recalculate: function () {
            var between, date, enddate, startdate;
            date = get(this, 'date');
            startdate = date.split('_')[0];
            enddate = date.split('_')[1];
            if (moment().isBefore(enddate)) {
                enddate = moment().format('YYYY-MM-DD');
            }
            between = startdate + '_' + enddate;
            return this.get('Collector')
                .ttapi({
                    url:
                        'saldo/calculateSaldos?date=' +
                        between +
                        '&userid=' +
                        get(this, 'model.user.id'),
                })
                .then(() => {
                    return this.send('refreshModel');
                });
        },
        closeModal: function () {
            return set(this, 'showDate', null);
        },
    },
});

export default UsersEditSaldoController;
