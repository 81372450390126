/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsRoute,
    indexOf = [].indexOf;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SettingsRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Em.inject.service('collector-service'),
    settings: Em.inject.service(),
    dialogs: Em.inject.service(),
    intl: Em.inject.service(),
    initapp: Em.inject.service(),
    flags: Ember.inject.service(),
    transitionToRightPage: function () {
        return this.transitionTo('settings.general-v2');
    },
    beforeModel: function (transition) {
        var target_arr;
        target_arr = transition.targetName.split('.');
        if (target_arr[0] === 'settings' && !this.get('Collector').testNeedsOne('userlevel=5')) {
            transition.abort();
            return this.transitionTo('my-sites.index');
        } else if (target_arr[0] === 'settings' && target_arr[1] && target_arr[1] !== 'index') {
        } else {
            return this.transitionToRightPage();
        }
    },
    actions: {
        willTransition: function (transition) {
            var force_refresh, ref;
            force_refresh = ['salary.salarytypes'];
            if (
                transition.targetName.substring(0, 8) !== 'settings' ||
                ((ref = transition.targetName), indexOf.call(force_refresh, ref) >= 0)
            ) {
                return transition.finally(() => {
                    if (
                        this.controller.get('_refreshNeeded') ||
                        this.get('settings').refreshNeeded()
                    ) {
                        this.get('initapp').clearInit();
                        return this.get('dialogs')
                            .alert(this.get('intl').t('general.settings_refresh_needed'))
                            .then(function () {
                                return location.reload();
                            });
                    }
                });
            } else if (transition.targetName === 'settings.index') {
                return this.transitionToRightPage();
            }
        },
    },
});

export default SettingsRoute;
