import { helper } from '@ember/component/helper';
import { isEqual as equal } from '@ember/utils';

// This function receives the params `params, hash`

export function isEqual(params) {
    if (params[2] && params[0] && params[1]) {
        return equal(params[0].toString(), params[1].toString());
    } else {
        return equal(params[0], params[1]);
    }
}

export default helper(isEqual);
