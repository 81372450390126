/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/week-report.coffee.old
// Generated by CoffeeScript 2.6.1
var WeekReportComponent;

import Ember from 'ember';

WeekReportComponent = Ember.Component.extend({
    intl: Em.inject.service(),
    gui: Em.inject.service(),
    didInsertElement: function () {
        this._super();
        return this.get('gui').setBreakpointObserver('md');
    },
    willDestroyElement: function () {
        this._super();
        return this.get('gui').removeBreakpointObserver();
    },
    actions: {
        click: function (row) {
            if (this.get('rowClicked')) {
                return this.rowClicked(row);
            }
        },
    },
});

export default WeekReportComponent;
