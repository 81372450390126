import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import RSVP from 'rsvp';

export default Route.extend({
    store: service(),
    collector: service('collector-service'),
    queryParams: {
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        worktimegroup: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
    },
    model(params) {
        const c = get(this, 'collector');
        const userParams = { offset: params.page === 1 ? 0 : 25 * (params.page - 1) };
        Object.keys(params).forEach((item) => {
            if (item === 'user' && params.user) {
                userParams.id = params.user;
            } else if (c.testNeedsOne('user.' + item)) {
                userParams[item] = params[item];
            }
        });
        let users = [];
        let userPromise = get(this, 'store').query(
            'user',
            Object.assign({ limit: 25 }, userParams),
        );

        userPromise.then((queryUsers) => {
            users = queryUsers;
        });
        //.then((tasks) => resolve(this.mapTasks(tasks, users)));

        return RSVP.hash({
            users: userPromise,
            worktasks: this.fetchWorktasks(users, params),
        });
    },
    fetchWorktasks(users, params) {
        return get(this, 'store').query('worktask', {
            user: users.mapBy('id').join(','),
            date: params.date,
            project: params.project,
            order: 'date',
        });
    },
});
