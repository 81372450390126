/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/user.coffee.old
// Generated by CoffeeScript 2.6.1
var UserHelper;

import Ember from 'ember';

import config from '../config/environment';

UserHelper = Ember.Helper.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    // This function receives the params `params, hash`
    compute: function (params, hash) {
        var found, userid;
        userid = params[0];
        if (!userid) {
            return;
        }
        found = this.get('store').peekRecord('user', userid);
        if (found) {
            return this.format(found, params[1]);
        } else {
            this.get('store')
                .findRecord('user', userid)
                .then(
                    () => {
                        return this.recompute();
                    },
                    () => {},
                );
            // user not found .. but it is ok
            return '';
        }
    },
    format: function (record, format) {
        var fullname;
        fullname = record.get('firstname') + ' ' + record.get('lastname');
        if (!format) {
            return fullname;
        }
        if (format === 'icon') {
            return this.getIcon([record.get('image.firstObject.thumb'), fullname]);
        }
    },
    getIcon: function (params) {
        var c, color, image, ret, size;
        c = this.get('Collector');
        // params[0] = image
        // params[1] = alternative text
        // params[2] = color
        size = '';
        if (params[0]) {
            if (params[0].substring(0, 4) === 'http') {
                image = params[0];
            }
            image = config.APP.COLLECTOR_API_URL + '/' + params[0];
            ret =
                "<div title='" +
                params[1] +
                "' class='user-icon img-circle " +
                size +
                "' style='background-image:url(\"" +
                image +
                '")\'>&nbsp;</div>';
        } else {
            color = c.randomizeColor(params[1]);
            ret =
                "<div title='" +
                params[1] +
                "' class='user-icon img-circle " +
                size +
                "' style='background-color:" +
                color +
                "'>" +
                c.shortFormat(params[1], 2) +
                '</div>';
        }
        return new Ember.String.htmlSafe(ret);
    },
});

export default UserHelper;
