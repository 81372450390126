/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/builder/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddBuilderController,
    indexOf = [].indexOf;

import Ember from 'ember';

import config from '../../../../config/environment';

// veronumero for testing 100011258537
UsersAddBuilderController = Ember.Controller.extend({
    //    needs: ["employers/add"]
    session: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    addController: Ember.inject.controller('employers/add'),
    capi: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    intl: Ember.inject.service(),
    cc: Ember.inject.service(),
    found_employers: [],
    not_found_employers: [],
    empArr: Em.A([]),
    no_employers: false,
    userAlreadyExists: false,
    veroApiInfoExists: false,
    birthday: null,
    birthdateField: null,
    obs: function () {
        this.set('messageStatus', null);
        this.set('message', null);
        this.set('create', false);
        this.set('users', null);
        this.set('noEmployer', false);
        this.set('userAlreadyExists', false);
        this.set('activeUser', null);
        this.set('found_employers', []);
        this.set('not_found_employers', []);
        this.set('no_employers', false);
        if (this.get('taxnumber.length') === 12) {
            return this.send('fetchTax');
        } else if (this.get('cardnumber.length') === 12) {
            return this.send('fetchCard');
        } else if (this.get('taxnumber.length') === 14) {
            return this.send('fetchCardWithUID');
        }
    }.observes('taxnumber', 'cardnumber'),
    setModel: function (data, skip) {
        var k, model, results, v;
        model = this.get('model');
        results = [];
        for (k in data) {
            v = data[k];
            if (indexOf.call(skip, k) < 0) {
                results.push(model.set(k, v));
            } else {
                results.push(void 0);
            }
        }
        return results;
    },
    //    setUserInfo: (data, skip) ->
    //        intl = @get 'intl'
    //        if !data.error
    //            @setModel(data,skip)
    //            if data.cardStatus == 'closed' && data.status == 'verified'
    //                @set 'messageStatus', 'alert-warning'
    //            else if data.status == 'verified'
    //                @set 'messageStatus', 'alert-success'
    //            else
    //                @set 'messageStatus', 'alert-danger'
    //            string = ''
    //            if data.status
    //                string += '<div><h3>'+intl.t('user.add.status.taxnumber')+': <b>'+intl.t('user.add.status.'+data.status)+'</b></h3></div>'

    //            if data.cardStatus == 'closed'
    //               string += '<div><h3><b>'+intl.t('user.add.card.closed')+'</b></h3><b>'+intl.t('user.add.card.reason')+': '+data.cardReason+'</b></div>'
    //            message = Ember.String.htmlSafe(string)
    //        else
    //            @set 'messageStatus', 'alert-danger'
    //            message = Ember.String.htmlSafe('<div>'+
    //                '<h3><b>'+intl.t('user.add.status.notfound')+'</b></h3></div>')
    //        @set 'message', message
    setUserData: function (data) {
        var user_record;
        if (data.names) {
            user_record = {
                firstname: data.names[0].given_names[0],
                lastname: data.names[0].surnames.join(' '),
                birthdate: data.date_of_birth,
                taxnumber: data.gov_ids[0].gov_id,
                homecountry: data.residences != null ? data.residences[0].toLowerCase() : 'fi',
            };
            if (this.get('cardUidUsed')) {
                user_record.rfid_tag_id = this.get('cardUidUsed');
            }
            return this.set('user', user_record);
        }
    },
    checkIfUserExists: function (taxnumber) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            return this.get('capi')
                .request('/users?validation_only=taxnumber', {
                    method: 'POST',
                    data: {
                        user: {
                            taxnumber: taxnumber,
                        },
                    },
                })
                .then(
                    () => {
                        return resolve(false);
                    },
                    (reason) => {
                        if (
                            reason.payload.error_msg ===
                            'user with this ' + taxnumber + ' taxnumber already exists!'
                        ) {
                            return resolve(true);
                        } else {
                            return resolve(false);
                        }
                    },
                );
        });
    },
    queryVeroApi: function (number, birthday) {
        var isNumber, query;
        isNumber = /^\d+$/.test(number);
        if (!number || !isNumber || number.length !== 12) {
            return;
        }
        query = '/veroapi-veronumero?taxnumber=' + number;
        if (birthday) {
            query += '&birthday=' + birthday;
        }
        return $.ajax({
            url: this.get('cc').host + query,
            headers: {
                Authorization: 'Bearer ' + this.session.data.authenticated.access_token,
            },
            success: (data) => {
                this.set('veroApiInfoExists', data.InfoExists);
                if (data.InfoExists) {
                    this.set('veroApiUser', data);
                }
                if (data.InfoExists && birthday && !data.TaxNumberMatchBirthday) {
                    return this.set('fetchedWithWrongBirthday', true);
                }
            },
        });
    },
    getImage: function (field) {
        var emp_id, taxnumber;
        //        @set 'loading', true
        taxnumber = this.get('model.taxnumber');
        emp_id = this.get('model.employer.emp_id');
        return $.ajax({
            url: this.get('cc').host + config.APP.CC_TVCARDIMAGE,
            data: {
                taxnumber: taxnumber,
                emp_id: emp_id,
            },
            success: (data) => {
                //                @set 'loading', false
                if (data != null && !data.error) {
                    this.set('model.image', [
                        {
                            file: 'data:image/png;base64,' + data,
                            name: 'profile.png',
                        },
                    ]);
                    return this.set('fetchedImage', 'data:image/png;base64,' + data);
                }
            },
        });
    },
    actions: {
        setInitialValue: function () {
            if (!this.get('birthday')) {
                return this.set('birthday', '1980-06-01');
            }
        },
        fetchPersonFromTaxApi: function () {
            //            console.log(@get('taxnumber'), @get('birthday'))
            this.set('fetchedWithWrongBirthday', false);
            return this.queryVeroApi(this.get('taxnumber'), this.get('birthday'));
        },
        // fetch card taxnumber from tvpersonapiv3 integration and if card is found
        // replace the taxnumber and taxnumber search starts automatically
        fetchCardWithUID: function () {
            var promise, uid;
            this.set('loading', true);
            this.set('cardUidUsed', null);
            uid = this.get('taxnumber');
            promise = this.get('cc').request('tvpersonapiv3', {
                data: {
                    country: 'fi',
                    'id-country': 'fi',
                    'id-type': 'card_uid',
                    'id-value': uid,
                },
            });
            return promise
                .then((response) => {
                    if (response.length > 0 && response[0].taxnumber) {
                        this.set('cardUidUsed', uid);
                        return this.set('taxnumber', response[0].taxnumber);
                    } else {
                        return this.set('loading', false);
                    }
                })
                .catch(function (e) {
                    return this.set('loading', false);
                });
        },
        fetchTax: function () {
            var c, currentuserpromise, intl, self, userpromise, value, veroapipromise;
            c = this.get('Collector');
            intl = this.get('intl');
            self = this;
            this.set('empArr', Em.A([]));
            //            token = @get 'session.secure.access_token'
            // Fetching person info by tax number
            if (this.get('taxnumber') && this.get('taxnumber.length') === 12) {
                value = this.get('taxnumber');
                this.set('loading', true);
                currentuserpromise = this.get('store').query('user', {
                    id: this.get('session.currentUser.id'),
                    sideload: ['employer.emp_id'],
                });
                userpromise = self.store.query('user', {
                    taxnumber: value,
                });
                veroapipromise = self.queryVeroApi(value);
                return Ember.RSVP.Promise.all([currentuserpromise, userpromise])
                    .then((responses) => {
                        var message, users;
                        users = responses[1];
                        if (users.get('length') === 0) {
                            return this.checkIfUserExists(value).then((resp) => {
                                var promises;
                                if (resp) {
                                    self.set('loading', false);
                                    return this.set('userAlreadyExists', true);
                                } else {
                                    this.set('userAlreadyExists', false);
                                    promises = [];
                                    return $.ajax({
                                        url: this.get('cc').host + config.APP.CC_TVPERAPI,
                                        data: {
                                            country: 'fi',
                                            'id-country': 'fi',
                                            'id-type': 'tax_number',
                                            'id-value': value,
                                        },
                                        type: 'GET',
                                        success: (data) => {
                                            var accessError, message;
                                            if (data.message) {
                                                self.set('loading', false);
                                                self.set('messageStatus', 'alert-danger');
                                                message = Ember.String.htmlSafe(
                                                    '<div>' +
                                                        '<h3><b>' +
                                                        intl.t('user.add.status.notfound') +
                                                        '</b></h3></div>',
                                                );
                                                self.set('message', message);
                                            } else if (!data.error) {
                                                if (Em.isEmpty(data.employers)) {
                                                    self.set('loading', false);
                                                    self.set('messageStatus', 'alert-danger');
                                                    message = Ember.String.htmlSafe(
                                                        '<div>' +
                                                            '<h3><b>' +
                                                            intl.t('user.add.no_employers') +
                                                            '</b></h3></div>',
                                                    );
                                                    self.set('message', message);
                                                }
                                                self.setUserData(data);
                                                accessError = false;
                                                data.employers.forEach((emp) => {
                                                    var emp_id, found, not_found, tmpArr;
                                                    found = [];
                                                    not_found = [];
                                                    emp_id = emp.gov_org_ids[0].gov_org_id;
                                                    if (
                                                        emp.country !== 'FI' &&
                                                        emp.gov_org_ids[1]
                                                    ) {
                                                        emp_id = emp.gov_org_ids[1].gov_org_id;
                                                    }
                                                    if (c.testNeeds(['employer@access'])) {
                                                        promises.push(
                                                            new Em.RSVP.Promise((resolve) => {
                                                                return emp.gov_org_ids.forEach(
                                                                    (gov_ids) => {
                                                                        return this.get('store')
                                                                            .query('employer', {
                                                                                emp_id: gov_ids.gov_org_id,
                                                                            })
                                                                            .then((employers) => {
                                                                                if (
                                                                                    employers.get(
                                                                                        'length',
                                                                                    ) > 0
                                                                                ) {
                                                                                    //found_emps = self.get("found_employers")
                                                                                    found.pushObject(
                                                                                        gov_ids,
                                                                                    );
                                                                                } else {
                                                                                    not_found.pushObject(
                                                                                        gov_ids,
                                                                                    );
                                                                                }
                                                                                return resolve();
                                                                            });
                                                                    },
                                                                );
                                                            }),
                                                        );
                                                        tmpArr = {
                                                            employer: emp,
                                                            found: found,
                                                            not_found: not_found,
                                                        };
                                                        return this.get('empArr').pushObject(
                                                            tmpArr,
                                                        );
                                                    } else if (
                                                        self.get(
                                                            'session.currentUser.employer.emp_id',
                                                        ) === emp_id
                                                    ) {
                                                        emp.gov_org_id = emp_id;
                                                        tmpArr = {
                                                            employer: emp,
                                                            found: [
                                                                emp.gov_org_ids.find((item) => {
                                                                    return (
                                                                        item.gov_org_id === emp_id
                                                                    );
                                                                }),
                                                            ],
                                                            not_found: [],
                                                        };
                                                        return this.get('empArr').pushObject(
                                                            tmpArr,
                                                        );
                                                    } else {
                                                        //@get('empArr').pushObject self.get("session.currentUser.employer")
                                                        return (accessError = true);
                                                    }
                                                });
                                                if (
                                                    accessError &&
                                                    self.get('found_employers').length === 0
                                                ) {
                                                    self.set('messageStatus', 'alert-danger');
                                                    message = Ember.String.htmlSafe(
                                                        '<div>' +
                                                            '<h3><b>' +
                                                            intl.t('user.add.no_employer_access') +
                                                            '</b></h3></div>',
                                                    );
                                                    self.set('message', message);
                                                }
                                            } else {
                                                self.set('loading', false);
                                            }
                                            //self.setUserInfo(data, false)
                                            return Ember.RSVP.Promise.all(promises).then(() => {
                                                return self.set('loading', false);
                                            });
                                        },
                                        fail: function (data) {
                                            var message;
                                            //self.set('loading', false)
                                            self.set('messageStatus', 'alert-danger');
                                            message = Ember.String.htmlSafe(
                                                '<div>' +
                                                    '<h3><b>' +
                                                    intl.t('user.add.status.failed') +
                                                    '</b></h3></div>',
                                            );
                                            return self.set('message', message);
                                        },
                                    });
                                }
                            });
                        } else {
                            self.set('loading', false);
                            self.set('messageStatus', 'alert-danger');
                            message = Ember.String.htmlSafe(
                                '<div>' + '<h3><b>' + intl.t('user.add.exists') + '</b></h3></div>',
                            );
                            return self.set('message', message);
                        }
                    })
                    .catch(function (e) {
                        var message;
                        self.set('loading', false);
                        self.set('messageStatus', 'alert-danger');
                        message = Ember.String.htmlSafe(
                            '<div>' +
                                '<h3><b>' +
                                intl.t('user.add.status.failed') +
                                '</b></h3></div>',
                        );
                        return self.set('message', message);
                    });
            }
        },
        createUser: function (user, employer, found_employer) {
            var c, intl, self;
            self = this;
            if (
                user.homecountry !== 'fi' &&
                employer.employment_periods[0].address_in_home_country
            ) {
                user.address = employer.employment_periods[0].address_in_home_country.address[0];
                user.zip = employer.employment_periods[0].address_in_home_country.post_code;
                user.city = employer.employment_periods[0].address_in_home_country.post_area;
                user.contract_type = employer.employment_periods[0].contract_type;
                user.workpass = employer.employment_periods[0].documentation_of_sent_employee;
            }
            this.set('activeUser', user);
            this.set('users', null);
            this.set('message', null);
            intl = this.get('intl');
            c = this.get('Collector');
            if (c.testNeeds(['employer@access'])) {
                return self.store
                    .query('employer', {
                        emp_id: found_employer.gov_org_id,
                    })
                    .then(function (employers) {
                        var message;
                        if (employers.get('length') > 0) {
                            user.employer = employers.get('firstObject');
                            self.setModel(user, ['status', 'vatin']);
                            self.set('message', null);
                            self.set('users', null);
                            return self.transitionToRoute('users.add.index');
                        } else {
                            self.set('messageStatus', 'alert-warning');
                            self.set('noEmployer', true);
                            message = Ember.String.htmlSafe(
                                '<div>' +
                                    '<h3><b>' +
                                    intl.t('user.add.status.employer.notfound') +
                                    '</b></h3><h4>' +
                                    user.employer +
                                    ', ' +
                                    user.vatin +
                                    '</h4></div>',
                            );
                            return self.set('message', message);
                        }
                    });
            } else {
                user.employer = self.get('session.currentUser.employer');
                self.setModel(user, ['status', 'vatin']);
                self.set('message', null);
                self.set('users', null);
                return self.transitionToRoute('users.add.index');
            }
        },
        addEmployer: function (employer, id) {
            var emp, emp_obj, user;
            user = this.get('user');
            emp_obj = {
                emp_id: id.gov_org_id,
                name: employer.name,
                emp_firstname: employer.employers_contact_person.contact_first_name,
                emp_lastname: employer.employers_contact_person.contact_last_name,
                emp_phone: employer.employers_contact_person.contact_phone,
                emp_email: employer.employers_contact_person.contact_email,
            };
            if (employer.country !== 'FI') {
                //if employer.gov_org_ids[1]
                //    emp_obj.emp_id = employer.gov_org_ids[1].gov_org_id
                if (id.gov_org_id === 'vat_number') {
                    emp_obj.emp_id_type = '1';
                } else {
                    emp_obj.emp_id_type = '2';
                }
                emp_obj.emp_homecountry = employer.country.toLowerCase();
                if (employer.address) {
                    emp_obj.emp_address = employer.address.address.toString();
                    emp_obj.emp_zip = employer.address.post_code;
                    emp_obj.emp_city = employer.address.post_area;
                    emp_obj.emp_country = employer.address.country.toLowerCase();
                }
            }
            emp = this.store.createRecord('employer', emp_obj);
            Em.set(user, 'employer', emp);
            this.store.createRecord('user', user);
            this.set('addController.afterSave', 'users.add.index');
            this.transitionToRoute('employers.add');
            return this.set('noEmployer', false);
        },
        addUserToGroup: function (taxnumber) {
            this.set('loading', true);
            return this.get('ttapi')
                ._post('addusertogroup', {
                    taxnumber: taxnumber,
                })
                .then(() => {
                    this.set('loading', false);
                    return this.transitionToRoute('users.index');
                });
        },
        setUserTaxnumber: function (taxnumber) {
            var user;
            user = {
                taxnumber: taxnumber,
            };
            if (this.veroApiUser) {
                user.firstname = this.veroApiUser.FirstName;
                user.lastname = this.veroApiUser.LastName;
                user.birthdate = this.birthday;
            }
            this.setModel(user, ['status', 'vatin']);
            return this.transitionToRoute('users.add.index');
        },
    },
});

export default UsersAddBuilderController;
