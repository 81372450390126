/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/billing/route.coffee.old
// Generated by CoffeeScript 2.6.1
var Route;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

Route = Ember.Route.extend(AuthenticatedRouteMixin, {
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            return controller.set('group', null);
        }
    },
});

export default Route;
