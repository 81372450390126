/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/classes/localization.coffee.old
// Generated by CoffeeScript 2.6.1
var localization;

moment.updateLocale('fi', {
    weekdaysMin: 'Su_Ma_Ti_Ke_To_Pe_La'.split('_'),
    weekdays: 'Sunnuntai_Maanantai_Tiistai_Keskiviikko_Torstai_Perjantai_Lauantai'.split('_'),
    months: 'tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu'.split(
        '_',
    ),
    monthsShort: 'tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu'.split('_'),
    longDateFormat: {
        L: 'D.M.YYYY',
        LT: 'HH:mm',
        LL: 'D.M.YYYY HH:mm:ss',
        llll: 'D.M.YYYY HH:mm:ss',
        LTS: 'HH.mm.ss',
        LLL: 'Do MMMM[ta] YYYY, [klo] LT',
        LLLL: 'dddd, Do MMMM[ta] YYYY, [klo] LT',
        l: 'D.M.YYYY',
        ll: 'Do MMM YYYY',
        lll: 'Do MMM YYYY, [klo] LT',
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});

moment.updateLocale('sv', {
    weekdaysMin: 'Sö_Må_Ti_On_To_Fr_Lö'.split('_'),
    weekdays: 'Söndag_Måndag_Tisdag_Onsdag_Torsdag_Fredag_Lördag'.split('_'),
    months: 'januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december'.split(
        '_',
    ),
    monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
    longDateFormat: {
        L: 'YYYY-MM-DD',
        LT: 'HH:mm',
        LL: 'YYYY-MM-DD HH:mm:ss',
        llll: 'YYYY-MM-DD HH:mm:ss',
        LTS: 'LT:ss',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd D MMMM YYYY LT',
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});

localization = Ember.Object.create(
    {
        //    /**
        //     * Format float or "hh:mm" string to hours, if format specified then returns number as a string with the right separator
        //     * @param float hours variable or "hh:mm" string to be converted to hours
        //     * @return string given float as hours string
        //     */
        formatHours: function (hours, format) {
            var minutes, real_hours, timeArr;
            if (typeof hours === 'string' && hours.indexOf(':') > -1) {
                timeArr = hours.split(':');
                hours = parseInt(timeArr[0], 10) + parseInt(timeArr[1], 10) / 60;
            }
            if (format === 'excel') {
                return hours.toLocaleString(moment.locale());
            }
            if (format === 'serverlessexcel') {
                return hours;
            }
            // Either use ceil or floor depending if positive or negative number
            if (Math.sign(hours) === -1) {
                real_hours = Math.ceil(hours);
            } else {
                real_hours = Math.floor(hours);
            }
            minutes = Math.abs(Math.round((hours - Math.floor(real_hours)) * 60));
            if (minutes === 60) {
                real_hours++;
                minutes = 0;
            }
            return (
                (Object.is(real_hours, -0) ? '-' : '') +
                real_hours +
                ':' +
                (minutes < 10 ? '0' : '') +
                minutes +
                'h'
            );
        },
        /*
  if hours are over 24, put also days to string
  */
        formatDaysAndHours: function (hours) {
            var days, minutes, timeArr;
            if (typeof hours === 'string' && hours.indexOf(':') > -1) {
                timeArr = hours.split(':');
                hours = parseInt(timeArr[0], 10) + parseInt(timeArr[1], 10) / 60;
            }
            minutes = Math.round((hours - Math.floor(hours)) * 60);
            if (hours > 24) {
                days = Math.floor(hours / 24);
                hours = hours - days * 24;
                return (
                    days +
                    'd ' +
                    Math.floor(hours) +
                    ':' +
                    (minutes < 10 ? '0' : '') +
                    minutes +
                    'h'
                );
            }
            return Math.floor(hours) + ':' + (minutes < 10 ? '0' : '') + minutes + 'h';
        },
        //    // register formatHours as handlebar helper hours
    },
    Ember.Helper.helper('hours', function (hours) {
        return localization.formatHours(hours);
    }),
);

export default localization;
