/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/linker-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorLinkerCompComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import config from '../../../config/environment';

CollectorLinkerCompComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    capi: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    link: null,
    model: null,
    fields: null,
    didInsertElement: function () {
        return this.set('link_records', Em.A([]));
    },
    didReceiveAttrs: function () {
        Ember.assert("Link must be given to linker-comp, ex 'task'", this.get('link'));
        Ember.assert('Model must be given to linker-comp, ex project record', this.get('model'));
        Ember.assert(
            'Model must be an ember-data record, could not find modelName',
            this.get('model.constructor.modelName'),
        );
        Ember.assert('Model must have an id for linker-comp to work', this.get('model.id'));
        Ember.assert(
            "Fields to show in list must be given to linker-comp property show_fields, ex 'name,number' or ['name','number']",
            this.get('show_fields'),
        );
        return this.getLinker().then(() => {
            if (this.get('showLinks')) {
                this.set('records', this.get('store').findAll(this.get('link')));
                return this.getLinks();
            }
        });
    },
    target: Em.computed('model', function () {
        return this.get('model.constructor.modelName');
    }),
    linkIds: Ember.computed.mapBy('link_records', 'id'),
    link_records: Em.A([]),
    projField: Em.computed(function () {
        return this.get('Collector').field('worktime', 'project');
    }),
    getLinks: function () {
        var c, link, promise;
        c = this.get('Collector');
        link = this.get('link');
        promise = c.getLinks(this.get('model.id'), this.get('model.constructor.modelName'), link);
        return promise.then((records) => {
            var data, store_records;
            data = records[c.pluralize(link)];
            store_records = Em.A([]);
            if (data) {
                delete records.count;
                this.get('store').pushPayload(records);
                data.forEach((item) => {
                    var record;
                    record = this.get('store').peekRecord(link, item.id);
                    if (link === 'user' && parseInt(this.get('model.id')) === item.id) {
                        return;
                    }
                    if (
                        link === 'employer' &&
                        parseInt(this.get('model.employer.id')) === item.id
                    ) {
                        record.set('forced', true);
                        this.set('records', this.get('store').findAll(this.get('link')));
                    }
                    return store_records.push(record);
                });
            }
            this.set('original_links', store_records.mapBy('id'));
            return this.set('link_records', store_records);
        });
    },
    getLinker: function () {
        var promise;
        promise = this.Collector.ttapi({
            url: 'get_linker',
            data: {
                rowid: this.get('model.id'),
                form: this.get('link'),
                linkto: this.get('linkTo'),
            },
            method: 'get',
        });
        return promise.then((data) => {
            if (data['linker']) {
                return this.set('showLinks', true);
            } else {
                return this.set('showLinks', false);
            }
        });
    },
    showButtons: Ember.computed('original_links.[]', 'link_records.[]', function () {
        var orig, ret;
        if (this.get('link_records.length') === this.get('original_links.length')) {
            orig = this.get('original_links');
            ret = false;
            this.get('link_records').forEach(function (item) {
                var ref;
                if (((ref = item.get('id')), indexOf.call(orig, ref) < 0)) {
                    return (ret = true);
                }
            });
            return ret;
        }
        return true;
    }),
    //showButtons: true
    removeAllLinks: function () {
        var c, data, form, id, link_form, params;
        this.set('link_records', Em.A([]));
        this.set('records', this.get('store').findAll(this.get('link')));
        c = this.get('Collector');
        id = this.get('model.id');
        form = this.get('model.constructor.modelName');
        link_form = this.get('link');
        data = {};
        data[form] = {};
        data[form]['link.' + c.pluralize(link_form)] = 'clear';
        params = {
            url: c.pluralize(form) + '/' + id + '?validation=off',
            data: JSON.stringify(data),
            type: 'PUT',
        };
        return this.get('capi').request('/' + params.url, {
            data: params.data,
            type: params.type,
        });
    },
    search: function () {
        return this.get('store')
            .query(this.get('link'), {
                q: this.get('searchvalue'),
            })
            .then((recs) => {
                return this.set('records', recs);
            });
    },
    //@getLinks()
    actions: {
        toggle: function (value) {
            if (value) {
                this.set('showLinks', true);
                this.get('store')
                    .findAll(this.get('link'))
                    .then((data) => {
                        var emp_id;
                        //put users own employer to "selected" slot
                        if (this.get('link') === 'employer') {
                            emp_id = this.get('model.employer.id');
                            data.forEach((row) => {
                                if (emp_id === row.id) {
                                    row.forced = true;
                                    return this.get('link_records').unshiftObject(row);
                                }
                            });
                        }
                        this.set('records', data);
                        return this.send('save');
                    });
            } else {
                this.set('showLinks', false);
                this.removeAllLinks();
                this.set('searchvalue', null);
            }
            return false;
        },
        addLink: function (record) {
            return this.get('link_records').unshiftObject(record);
        },
        removeLink: function (record) {
            if (record.forced) {
                return;
            }
            return this.get('link_records').removeObject(record);
        },
        save: function () {
            var c, id;
            id = this.get('model.id');
            c = this.get('Collector');
            this.set('saving', true);
            return c
                .saveLinks(
                    id,
                    this.get('model.constructor.modelName'),
                    this.get('link'),
                    this.get('linkIds'),
                )
                .then(
                    (links) => {
                        this.getLinks();
                        this.set('saving', false);
                        return this.set('showLinks', true);
                    },
                    (error) => {
                        this.set('saving', false);
                        this.set('error', error);
                        return Em.run.later(
                            this,
                            function () {
                                if (this.get('isDestroyed')) {
                                    return;
                                }
                                return this.set('error', false);
                            },
                            5000,
                        );
                    },
                );
        },
        cancel: function () {
            return this.getLinks();
        },
        searchChanged: function (term) {
            if (!term) {
                this.set('searchvalue', null);
            }
            return this.search();
        },
        projectChanged: function (project) {
            var c, link_ids;
            if (Em.isEmpty(project)) {
                return;
            }
            c = this.get('Collector');
            this.set('saving', true);
            //first remove all links
            this.removeAllLinks();
            link_ids = [];
            //get project tasks
            return c.getLinks(project.id, this.get('target'), this.get('link')).then((links) => {
                var i, len, link, ref;
                ref = links.tasks;
                for (i = 0, len = ref.length; i < len; i++) {
                    link = ref[i];
                    link_ids.push(link.id);
                }
                return c
                    .saveLinks(this.get('model.id'), this.get('target'), this.get('link'), link_ids)
                    .then(
                        (saved_links) => {
                            this.getLinks();
                            this.set('saving', false);
                            return this.set('showLinks', true);
                        },
                        (error) => {
                            this.set('saving', false);
                            this.set('error', error);
                            return Em.run.later(
                                this,
                                function () {
                                    if (this.get('isDestroyed')) {
                                        return;
                                    }
                                    return this.set('error', false);
                                },
                                5000,
                            );
                        },
                    );
            });
        },
    },
});

export default CollectorLinkerCompComponent;
