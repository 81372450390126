/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/avi/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAviController;

import Ember from 'ember';

import pdfExport from '../../../mixins/pdf-export';

import csvExport from '../../../mixins/csv-export';

import avi from '../../../mixins/avi';

import initController from '../../../mixins/init-controller';

import $ from 'jquery';

OrientationsAviController = Ember.Controller.extend(pdfExport, csvExport, avi, initController, {
    intl: Ember.inject.service(),
    limit: 25,
    page: 1,
    project: null,
    country: null,
    queryParams: ['project', 'country', 'page', 'showWorkingNow'],
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                form: 'worktime',
                singleselect: 'true',
                value: this.project,
            }),
            Em.Object.create({
                field: 'homecountry',
                form: 'user',
                type: 'dropdown_country',
                value: this.country,
            }),
        ]);
    }),
    rowActions: [],
    pages: Em.computed('model.count', function () {
        if (this.get('showWorkingNow') === 'true') {
            return 1;
        } else {
            return Math.ceil(this.get('model.count') / this.get('limit'));
        }
    }),
    showColumns: [
        'firstname',
        'lastname',
        'birthdate',
        'taxnumber',
        'employer',
        'startdate',
        'enddate',
        'orientation_ori_date',
        'orientation_pass_end',
        'homecountry',
        'sent_contact_person',
        'sent_contact_address',
        'workpass',
    ],
    init_functions: function () {
        return this._super();
    },
    data: Em.computed('model.users.[]', function () {
        var data;
        data = this.get('model.users');
        if (!data) {
            return null;
        }
        return this.format_data(data);
    }),
    format_data: function (data) {
        var columns, formatted_data, intl;
        intl = this.get('intl');
        columns = this.get('showColumns');
        formatted_data = Em.A([]);
        data.forEach((unformatted_row) => {
            var row;
            row = Ember.Object.create();
            if (unformatted_row.id) {
                row.id = unformatted_row.id;
            }
            $.each(columns, (key, column) => {
                var cell;
                cell = unformatted_row[column];
                if (column === 'birthdate' || column === 'startdate' || column === 'enddate') {
                    if (cell != null) {
                        cell = moment(cell).format('L');
                    }
                }
                if (column === 'employer') {
                    if (cell) {
                        cell = cell.emp_id + ' ' + cell.name;
                    }
                }
                if (column === 'orientation_ori_date') {
                    if (unformatted_row.orientation != null) {
                        cell = moment(unformatted_row.orientation.ori_date).format('L');
                    }
                }
                if (column === 'orientation_pass_end') {
                    if (unformatted_row.orientation != null) {
                        cell = moment(unformatted_row.orientation.pass_end).format('L');
                    }
                }
                if (column === 'workpass') {
                    if (cell === false) {
                        cell = intl.t('general.no');
                    }
                    if (cell === true) {
                        cell = intl.t('general.yes');
                    }
                }
                return (row[column] = cell);
            });
            return formatted_data.pushObjects([row]);
        });
        return formatted_data;
    },
    // get translation for every column
    translated_columns: Em.computed('showColumns', function () {
        var c, columns, intl, translated_columns;
        c = this.get('Collector');
        intl = this.get('intl');
        columns = this.get('showColumns');
        translated_columns = [];
        $.each(columns, (key, item) => {
            return translated_columns.push(intl.t('report.avi.' + item));
        });
        return translated_columns;
    }),
    make_pdf_export: function (data) {
        var addHeader, element, intl, project;
        intl = this.get('intl');
        element = this.createPdfTableElement(
            this.get('showColumns'),
            data,
            this.get('translated_columns'),
        );
        project = this.get('store').peekRecord('project', this.get('project'));
        addHeader = intl.t('report.orientations.avi') + ' - ' + project.get('name');
        return this.createPdfFromElements([element], 'download', addHeader.toString(), 16).then(
            (file) => {
                if (typeof cordova !== 'undefined' && cordova !== null) {
                    return this.get('cordova').openFile(file, 'application/pdf');
                }
            },
        );
    },
    make_csv_export: function (data) {
        var element;
        element = this.createCsvElement(
            this.get('showColumns'),
            data,
            this.get('translated_columns'),
        );
        return this.createCsvFromElements(element, false);
    },
    actions: {
        catchActions: function (action, params) {
            if (action === 'columnClicked') {
                return;
            }
            return this.send(action, params);
        },
        filterChanged: function (value, fieldName) {
            if (fieldName === 'project') {
                if (value == null) {
                    this.set('project', '');
                } else {
                    this.set('project', value.id);
                }
            }
            if (fieldName === 'homecountry') {
                return this.set('country', value);
            }
        },
        showWorkingNowRows: function () {
            //stupid way to find out if its true, sometimes it's string and sometimes boolean
            if (this.get('showWorkingNow') === true || this.get('showWorkingNow') === 'true') {
                this.set('showWorkingNow', false);
            } else {
                this.set('showWorkingNow', true);
                this.set('page', 1);
            }
            return false;
        },
        export_csv: function () {
            var checked_data, data, params, use_checked_rows;
            data = this.get('data');
            use_checked_rows = false;
            checked_data = Em.A([]);
            data.forEach((row) => {
                if (row.checkedClass === 'checked') {
                    use_checked_rows = true;
                    return checked_data.push(row);
                }
            });
            if (use_checked_rows) {
                data = checked_data;
                return this.make_csv_export(data);
            } else {
                params = {
                    project: this.get('project'),
                    order: this.get('order'),
                    limit: 'all',
                };
                if (this.get('showWorkingNow')) {
                    params.showWorkingNow = 'true';
                }
                return this.getAviData(params).then((avidata) => {
                    data = this.format_data(avidata.users);
                    return this.make_csv_export(data);
                });
            }
        },
        export_pdf: function () {
            var checked_data, data, params, use_checked_rows;
            data = this.get('data');
            use_checked_rows = false;
            checked_data = Em.A([]);
            data.forEach((row) => {
                if (row.checkedClass === 'checked') {
                    use_checked_rows = true;
                    return checked_data.push(row);
                }
            });
            if (use_checked_rows) {
                data = checked_data;
                return this.make_pdf_export(data);
            } else {
                params = {
                    project: this.get('project'),
                    order: this.get('order'),
                    limit: 'all',
                };
                if (this.get('showWorkingNow')) {
                    params.showWorkingNow = 'true';
                }
                return this.getAviData(params).then((avidata) => {
                    data = this.format_data(avidata.users);
                    return this.make_pdf_export(data);
                });
            }
        },
    },
});

export default OrientationsAviController;
