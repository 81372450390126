import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import config from '../config/environment';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import { getOwner } from '@ember/application';
import { warn, assert } from '@ember/debug';
import * as LDClient from 'launchdarkly-js-client-sdk';
/* global cordova */

const NON_EXISTANT_FLAG_VALUE = 'LD_FLAG_NON_EXISTANT';

export default Service.extend({
    session: service(),
    collector: service('collector-service'),

    test: function (name) {
        if (window.overrideFlags && window.overrideFlags[name] !== undefined) {
            return window.overrideFlags[name];
        }

        const flags = [
            {
                name: 'test',
                filters: ['beta'],
            },
        ];

        const flag = flags.findBy('name', name);

        if (!flag) {
            return this.variation(name, false);
        }

        return this.testFlagFilters(flag);
    },

    testFlagFilters(flag) {
        if (!flag.filters) return true;

        let all_ok = true;
        flag.filters.forEach((filter) => {
            if (filter === 'beta') {
                if (!this.isBetaService()) all_ok = false;
            }
            if (filter === 'A/B') {
                if (!this.isABtesting()) all_ok = false;
            }
        });

        return all_ok;
    },

    isBetaService() {
        const betaServices = {
            dev: [30416],
            next: [30559, 30547],
            production: [100132],
        };

        if (betaServices[this.getENV()].includes(get(this, 'session.currentUser.partnerid')))
            return true;
        else return false;
    },

    /**
     * A/B testing service list in production
        100132  Pääurakoitsija
        102051	Maanrakennusliike Vähätalo Oy       20
        101940	Kaivinpalvelu J.Saarinen Oy         27
        101852	Netcontrol Oy                       37
        101759	Kymen STG Oy                        20
        101548	Suomen Meriittirakennus Oy	        28
        101100	Gles Lahti Oy                       21
        100925	Pähkinähoito Oy                     21
        100475	Maanrakennus Esa Aaltonen Oy	    32
        84285  	Ovitek Oy                           15
        86547	PSK Group Oy                        9
        30665	Ab Sparal Oy                        17
        83003	Tiiko Oy                            26
        102120	Maanrakennus P Örn Oy               20
        102033	Suomen kaasuenergia Oy	            15
        102013	Maanrakennus Kivelä Oy              16
        31620	Restart Oy                          25
        86022	Team Juntunen Oy                    19
        82776	Consti Korjausurakointi Oy          24
        86043	Itä-Suomen Julkisivumaalaus Oy      10
        30684	Tampereen Taloteko Oy               12
        82564	Wesain Oy                           19
        82313	Varsinaisbitumi Oy                  26
        82373	Rauno Saari Oy                      16
        31808	Rakennusliike Vaasan Rakennusk...   23
        31139	Veljet Mäkilä Oy                    16
     */
    isABtesting() {
        const ABServices = {
            dev: [],
            next: [],
            production: [
                100132, 102051, 101940, 101852, 101759, 101548, 101100, 100925, 100475, 84285,
                86547, 30665, 83003, 102120, 102033, 102013, 31620, 86022, 82776, 86043, 30684,
                82564, 82313, 82373, 31808, 31139,
            ],
        }[this.getENV()];

        if (ABServices.includes(get(this, 'session.currentUser.partnerid'))) return true;
        else return false;
    },

    getENV() {
        if (config.target === 'development') return 'dev';
        if (config.target === 'release-next' || config.target === 'next') return 'next';
        return 'production';
    },

    /* LaunchDarkly stuff from here, the above can be removed when old flags are not needed anymore */

    async initialize(data = {}) {
        let { clientSideId, streaming = false } = this._config();

        const NullClient = {
            initialize() {},
            allFlags() {
                return {};
            },
            variation(_, defaultValue) {
                if (defaultValue !== undefined) {
                    return defaultValue;
                }

                return false;
            },
        };

        if (!Array.isArray(data['userrow'].user) || !data['userrow'].user.length) {
            // no need to write logs full of these on tests
            //warn('data.userrow not specified. Defaulting all feature flags to "false"', false, { id: 'launch-darkly.user-data-not-specified' });

            set(this, '_client', NullClient);

            return RSVP.resolve();
        }
        // parse partnerid to int if it seems to be numeric
        const partnerid = isNaN(data.partnerid) ? data.partnerid : parseInt(data.partnerid, 10);
        const userrow = data['userrow'].user[0];
        const context = {
            kind: 'user',
            key: partnerid + '' + userrow.id,
            email: userrow.email,
            name: userrow.firstname + ' ' + userrow.lastname,
            firstName: userrow.firstname,
            lastName: userrow.lastname,
            country: data.country,
            partnerid: partnerid,
            serviceType: config.brand,
            userlevel: parseInt(userrow.userlevel, 10),
            application: window.cordova ? true : false,
            version: config.APP.version,
            serviceCreated: moment(data.company_info.row_info.created).valueOf(),
            products: this.collector.getProducts().join(','),
        };

        if (window.cordova) {
            context.custom = {
                appVersion: await cordova.getAppVersion.getVersionNumber(),
            };
        }

        assert(
            'ENV.launchDarkly.clientSideId must be specified in config/environment.js',
            clientSideId,
        );

        if (!clientSideId) {
            warn(
                'ENV.launchDarkly.clientSideId not specified. Defaulting all feature flags to "false"',
                false,
                { id: 'launch-darkly.client-id-not-specified' },
            );

            set(this, '_client', NullClient);

            return RSVP.resolve();
        }

        assert('user.key must be specified in initilize payload', context.key);

        if (!context.key) {
            warn(
                'user.key not specified in initialize payload. Defaulting all feature flags to "false"',
                false,
                { id: 'launch-darkly.user-key-not-specified' },
            );

            set(this, '_client', NullClient);

            return RSVP.resolve();
        }

        if (!LDClient) {
            warn(
                'Launch Darkly JS client not found. Defaulting all feature flags to "false"',
                false,
                { id: 'launch-darkly.client-not-found' },
            );

            set(this, '_client', NullClient);

            return RSVP.resolve();
        }

        if (sessionStorage.overrideFlags) {
            window.overrideFlags = JSON.parse(sessionStorage.overrideFlags);
        }

        return this._initialize(clientSideId, context, streaming);
    },

    _config() {
        let appConfig = getOwner(this).resolveRegistration('config:environment');

        return appConfig.launchDarkly || {};
    },

    _initialize(id, context) {
        return new RSVP.Promise((resolve, reject) => {
            let client = LDClient.initialize(id, context, {
                allAttributesPrivate: true,
                bootstrap: 'localStorage',
            });

            client.on('ready', () => {
                set(this, '_client', client);
                run(null, resolve);
            });

            // NOTE: Not following changes on flags currently, can be implemented later if we think we need them
            //   client.on('change', settings => {
            //     Object.keys(settings).forEach(key => {
            //         this.trigger(key);
            //     });
            //   });

            run.later(
                this,
                () => {
                    if (!get(this, '_client')) {
                        run(null, reject);
                    }
                },
                5000,
            );
        });
    },

    allFlags() {
        return get(this, '_client').allFlags();
    },

    variation(key, defaultValue = false) {
        if (!get(this, '_client')) {
            //warn(`client is not initialized. Defaulting feature flag to '${defaultValue}'`, false, { id: 'launch-darkly.client-not-initialized' });
            return defaultValue;
        }
        let nonExistantFlagValue = `${NON_EXISTANT_FLAG_VALUE}: ${key}`;
        let value = get(this, '_client').variation(key, nonExistantFlagValue);

        if (value === nonExistantFlagValue) {
            // no need to write logs full of these on tests
            //warn(`Feature flag with key '${key}' has not been defined. Returning default value of '${defaultValue}'`, false, { id: 'launch-darkly.feature-flag-not-defined' });

            return defaultValue;
        }

        return value;
    },
});
