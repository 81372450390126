/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/famoco-editor/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import config from '../../../config/environment';

import $ from 'jquery';

Component = Ember.Component.extend({
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    commandsUrl: config.APP.COMMANDS_URL,
    loading: true,
    token: null,
    translations: {
        debug: 'debug',
        reloadModules: 'reload modules',
        clearBuffer: 'clear buffer',
        reloadEmber: 'reload ember',
        getDebug: 'get debug',
        getLastToConsole: 'get last done to console',
        clearDevice: 'clear device and make available',
    },
    values: Ember.Object.create({
        current: Ember.Object.create(),
        initial: Ember.Object.create(),
    }),
    setValue: function (name, value) {
        return this.get('values').set('current.' + name, value);
    },
    getValue: function (name) {
        return this.get('values').get('current.' + name);
    },
    persistValues: function () {
        return Object.keys(this.get('values.current')).forEach((key) => {
            return this.get('values').set('initial.' + key, this.get('values.current.' + key));
        });
    },
    isChanged: function (name) {
        return this.get('values.current.' + name) !== this.get('values.initial.' + name);
    },
    tasksfield: Ember.computed(function () {
        var field;
        field = this.get('Collector').field('worktime', 'task');
        field.value = this.getValue('useTasks');
        field.prompt = this.get('intl').t('general.all');
        return field;
    }),
    didReceiveAttrs: function () {
        var environment;
        environment = config.environment;
        if (environment === 'development') {
            environment = 'localhost';
        }
        if (config.APP.COLLECTOR_API_URL.indexOf('/release-') !== -1) {
            environment = 'next';
        }
        return $.ajax({
            url: this.get('commandsUrl') + '/gettoken/' + environment,
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
        }).then((back) => {
            this.set('token', back.token);
            $.ajax({
                url: this.get('commandsUrl') + '/pending/famoco_' + this.get('imei'),
                headers: {
                    Authorization: this.get('token'),
                },
            }).then((pending) => {
                if (pending.length > 0) {
                    this.set('pending', true);
                    return this.set('loading', false);
                }
            });
            return this.getState();
        });
    },
    getState: function () {
        return $.ajax({
            url: this.get('commandsUrl') + '/state/famoco_' + this.get('imei'),
            headers: {
                Authorization: this.get('token'),
            },
        }).then((state) => {
            if (state[0] != null) {
                this.set('lastPingTime', moment(state[0].created).format('LLL'));
                this.setField(state[0].state.project);
                this.setValue('tasksOn', state[0].state.tasks ? true : false);
                this.setValue('useTasks', state[0].state.useTasks);
                this.setValue('logged', state[0].state.loggedin);
                this.persistValues();
            } else {
                this.setField(null);
            }
            return this.set('loading', false);
        });
    },
    setField: function (value) {
        var field, useform;
        this.setValue('project', value);
        useform = this.get('Collector').testNeeds(['worktime']) ? 'worktime' : 'presence';
        field = this.get('Collector').field(useform, 'project');
        field.value = value;
        return this.set('field', field);
    },
    getRefreshToken: function () {
        return this.get('Collector').ajax({
            url: 'createToken',
            method: 'POST',
            data: {
                clientid: 'famoco',
            },
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
        });
    },
    addCommand: function (action) {
        this.set('loading', true);
        return $.ajax({
            url: this.get('commandsUrl') + '/add/famoco_' + this.get('imei'),
            method: 'post',
            headers: {
                Authorization: this.get('token'),
            },
            data: JSON.stringify(action),
        });
    },
    login: function () {
        return new Ember.RSVP.Promise((resolve) => {
            if (this.getValue('logged')) {
                resolve();
                return;
            }
            return this.getRefreshToken().then((ret) => {
                return this.addCommand({
                    action: 'login',
                    refresh_token: ret['refresh_token'],
                    access_token: ret['access_token'],
                }).then(() => {
                    this.setValue('logged', true);
                    return resolve();
                });
            });
        });
    },
    actions: {
        tasksChanged: function (selectedtasks) {
            return this.setValue('useTasks', selectedtasks);
        },
        changeProject: function (project) {
            return this.setValue('project', project.get('id'));
        },
        clearStorage: function () {
            this.addCommand({
                action: 'clearStorage',
            });
            if (this.get('close')) {
                return this.close();
            }
        },
        toggleTasks: function (onoff) {
            return this.setValue('tasksOn', onoff);
        },
        sendCommands: function () {
            return this.login().then(() => {
                if (this.getValue('project') && this.isChanged('project')) {
                    this.addCommand({
                        action: 'setProject',
                        projectId: this.getValue('project'),
                    });
                }
                if (this.isChanged('tasksOn')) {
                    this.addCommand({
                        action: 'taskonoff',
                        turnOn: this.getValue('tasksOn'),
                    });
                }
                if (this.isChanged('useTasks')) {
                    this.addCommand({
                        action: 'settasksinuse',
                        tasks: this.getValue('useTasks'),
                    });
                }
                this.persistValues();
                if (this.get('close')) {
                    return this.close();
                }
            });
        },
        debug: function (action) {
            this.addCommand({
                action: action,
            });
            return this.set('loading', false);
        },
        getlast: function () {
            return $.ajax({
                url: this.get('commandsUrl') + '/last/famoco_' + this.get('imei'),
                headers: {
                    Authorization: this.get('token'),
                },
            }).then((back) => {
                return console.log('last command', back);
            });
        },
    },
});

export default Component;
