import { computed, set } from '@ember/object';
import { copy } from 'ember-copy';
import { isEmpty } from '@ember/utils';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

const WorktasksConfirmController = Controller.extend({
    Collector: service('collector-service'),
    gui: service(),

    imagesField: computed('model.photos.[]', function () {
        const field = this.Collector.field('worktask', 'photos');
        field.value = this.model.photos;
        return field;
    }),

    commentsField: computed(function () {
        return this.Collector.field('worktask', 'comments');
    }),

    signatureField: computed(function () {
        return this.Collector.field('worktask', 'signature');
    }),

    hoursField: computed('hours', function () {
        /* eslint-disable ember/no-side-effects */
        set(this, 'model.hours', this.model.est_hours);
        const field = this.Collector.field('worktask', 'hours');
        //field['value'] = @get 'hours'
        return field;
    }),

    hasSignature: computed('model.signature', 'signature', function () {
        if (!isEmpty(this.model.signature) || this.signature) {
            return true;
        }
        return false;
    }),

    ownFields: computed(function () {
        const c = this.Collector;
        return c.fieldArray('worktask').filter((field) => {
            return field.name.substring(0, 6) === 'addon_';
        });
    }),

    actions: {
        close() {
            set(this, 'showModal', false);
            return set(this, 'model.status', 'open');
        },

        setFixed() {
            const model = this.model;
            //model.set 'hours', @get('hours')
            set(model, 'status', 'fixed');
            if (this.ownFields.length > 0) {
                set(this, 'showModal', true);
                return false;
            } else {
                /* eslint-disable */
                return model.save().then(() => {
                    return this.send('cleanAndMoveOn');
                });
            }
        },

        cleanAndMoveOn() {
            set(this, 'showModal', false);
            set(this, 'comments', false);
            set(this, 'signature', false);
            return this.transitionToRoute('worktasks.list');
        },

        setImages(images) {
            return set(this, 'model.photos', images);
        },

        toggleComment() {
            this.toggleProperty('comments');
            later(
                this,
                () => document.getElementsByClassName('confirm-extra-comments-textarea')[0].focus(),
                500,
            );
            return false;
        },
        toggleSignature() {
            this.toggleProperty('signature');
            return false;
        },

        setComment(value) {
            return set(this, 'model.comments', value);
        },

        setSignature(value) {
            return set(this, 'model.signature', value);
        },
    },
    filterOwnFields(field) {
        if (field.name.substring(0, 6) !== 'addon_') {
            const features = copy(field.features);
            features.hide = true;
            field.features = features;
        }
        return field;
    },
});

export default WorktasksConfirmController;
