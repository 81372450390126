import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { next } from '@ember/runloop';
import config from 'tt4/config/environment';
export default class WizardBuilderBillingComponent extends Component {
    @service session;
    @service admintool;
    @service intl;

    @tracked header;
    @tracked savedErrors;
    @tracked validFields;
    @tracked billingtype;
    @tracked currentPage;
    @tracked result;
    @tracked isSaving;
    @tracked isCanceling;

    texts = { subscribe: 'subscribe' };

    operators = [
        { id: 'apix', name: 'Apix Messaging Oy (003723327487)' },
        { id: 'basware', name: 'BasWare Einvoices Oy (BAWCFI22)' },
        { id: 'basware_oyj', name: 'BasWare Oyj (003705925424)' },
        { id: 'cgi', name: 'CGI - Logica (003703575029)' },
        { id: 'danske_bank', name: 'Danske Bank Oyj (DABAFIHH)' },
        { id: 'enfo', name: 'Enfo Oy (003718062728)' },
        { id: 'enfo_oyj', name: 'Enfo Oyj (003714377140)' },
        { id: 'inex', name: 'InExchange Factorum AB (INEXCHANGE)' },
        { id: 'liaison', name: 'LIAISON Technologies Oy (003708599126)' },
        { id: 'maventa', name: 'Maventa Services (003721291126)' },
        { id: 'nordea', name: 'Nordea (NDEAFIHH)' },
        { id: 'notebeat', name: 'Notebeat Oy (003717203971)' },
        { id: 'op', name: 'OP-Pohjola-ryhmä (OKOYFIHH)' },
        { id: 'opus', name: 'OpusCapita Group Oy - Itella (003710948874)' },
        { id: 'pagero', name: 'Pagero (003723609900)' },
        { id: 'pop', name: 'POP Pankit (POPFFI22)' },
        { id: 's_pankki', name: 'S-Pankki Oy (SBANFIHH)' },
        { id: 'sampo', name: 'Sampo Pankki Oyj (PSPBFIHH)' },
        { id: 'handelsbank', name: 'Svenska HAndelsbanken AB (HANDFIHH)' },
        { id: 'saasto', name: 'Säästöpankit (ITELFIHH)' },
        { id: 'tapiola', name: 'Tapiola Pankki (TAPIFI22)' },
        { id: 'teliasonera', name: 'TeliaSonera Finland Oyj (003714756079)' },
        { id: 'tieto', name: 'Tieto Oyj (003701011385)' },
        { id: 'aland', name: 'Ålandsbanken Abp (AABAFI22)' },
        { id: 'opus_solutions', name: 'OpusCapita Solutions Oy (E204503)' },
    ];

    get currentPageComponent() {
        return 'wizard-builder/billing/page' + this.currentPage;
    }

    constructor() {
        super(...arguments);
        this.currentPage = 1;
        this.billingtype = 'card';
        this.productName = this.intl.t('products.mini_entry');
        this.savedErrors = A();
        this.validFields = A();
        this.isSaving = false;
        this.header = 'billing_info.set_billing_info';
    }

    get productSums() {
        return get(this, 'admintool').ajax({
            url: 'get_prices_for_mini_entry',
            headers: {
                Authorization: 'Bearer ' + get(this, 'session.session.authenticated.access_token'),
            },
            type: 'GET',
            data: {
                country: get(this, 'session.currentUser.company_info.country'),
            },
        });
    }

    @action
    validateName(name_on_card) {
        if (!name_on_card) {
            if (this.validFields.indexOf('name') > -1) this.validFields.removeObject('name');
            this.savedErrors.pushObject({
                id: 'name',
                text: this.intl.t('validation.error.mandatory'),
            });
        } else if (name_on_card) {
            if (this.validFields.indexOf('name') == -1) this.validFields.pushObject('name');
            var foundError = this.savedErrors.findBy('id', 'name');
            if (foundError) this.savedErrors.removeObject(foundError);
        }
    }

    get isValid() {
        if (this.billingtype == 'card') {
            return this.validFields.length == 4;
        }
        return true;
    }

    @action
    validateErrors(event) {
        if (event.error) {
            if (this.validFields.indexOf(event.elementType) > -1) {
                this.validFields.removeObject(event.elementType);
            }
            this.savedErrors.pushObject({ id: event.elementType, text: event.error.message });
        } else if (event.complete) {
            if (this.validFields.indexOf(event.elementType) == -1) {
                this.validFields.pushObject(event.elementType);
            }
            var foundError = this.savedErrors.findBy('id', event.elementType);
            if (foundError) this.savedErrors.removeObject(foundError);
        }
    }

    @action
    async save(billingData) {
        await get(this, 'admintool').ajax({
            url: 'billinginfo',
            headers: {
                Authorization: 'Bearer ' + get(this, 'session.session.authenticated.access_token'),
            },
            data: billingData,
            type: 'PUT',
        });
        this.isSaving = false;
    }

    @action
    transitionToNextPage() {
        this.header = 'billing_info.billing_ok';
        this.result = 'billing_ok';
        this.currentPage = 2;
    }

    @action
    closeAndShowNotice() {
        if (this.currentPage != 1) {
            this.close();
            return;
        }
        this.currentPage = 2;
        this.result = 'billing_not_ok';
        this.header = 'billing_info.not_ok_header';
    }
    @action
    close() {
        this.args.close();
        // need to do reloading 1ms after closing modal because then "wizard=billing" has been removed from url (otherwise reloading would just open billing-modal again)
        next(this, () => window.location.reload());
    }

    @action
    changeBillingType(type) {
        this.billingtype = type;
    }
    @action
    async closeAndSaveCustomer() {
        this.isCanceling = true;
        const data = {
            email: get(this, 'session.currentUser.email'),
            company_name: get(this, 'session.currentUser.company_info.company_name'),
        };
        await fetch(config.APP.BILLING_URL + '/subscribe', {
            headers: {
                Authorization: 'Bearer ' + get(this, 'session.session.authenticated.access_token'),
            },
            method: 'POST',
            body: JSON.stringify(data),
        });
        this.isCanceling = false;
        this.closeAndShowNotice();
    }
}
