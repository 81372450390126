import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import { computed, get, set } from '@ember/object';

export default Component.extend({
    ttapi: service(),
    collector: service('collector-service'),
    intl: service(),

    givenPassword: '',
    givenFullname: '',
    errorMessage: null,

    forms: computed(function () {
        const forms = get(this, 'whiteListForms').filter((form) => {
            return get(this, 'collector').testNeeds([form]);
        });

        const ret = forms.map((form) => {
            return { name: form, date: moment().subtract(5, 'years').format('YYYY-MM-DD') };
        });

        return ret;
    }),

    boldFullname: computed(function () {
        return htmlSafe(
            '<b>' + get(this, 'user.firstname') + ' ' + get(this, 'user.lastname') + '</b>',
        );
    }),

    init() {
        this._super(...arguments);
        this.whiteListForms = ['worktime', 'abcense', 'presence', 'trnotice'];
    },

    actions: {
        removeData() {
            set(this, 'errorMessage', null);
            const userid = get(this, 'user.id');

            set(this, 'loading', true);

            get(this, 'ttapi')
                .request('terminate/' + userid, {
                    method: 'post',
                    data: {
                        password: get(this, 'givenPassword'),
                        nametest: get(this, 'givenFullname'),
                        terminate: get(this, 'forms'),
                    },
                })
                .then(() => this._setHistory(userid))
                .catch((error) => this._setErrorMessage(error));
        },

        close() {
            this.close();
        },
    },

    addHistory: function (forms, userid) {
        const intl = get(this, 'intl');
        let formsAndDates = [];

        forms.forEach(function (form) {
            formsAndDates.push(
                intl.t('forms.' + form.name) +
                    intl.t('users.teminate_older_than') +
                    ': ' +
                    form.date,
            );
        });

        let text = intl.t('users.terminated_data_history', {
            formsAndDates: formsAndDates.join(', '),
        });

        get(this, 'Collector').ajax({
            method: 'put',
            url: 'users/' + userid,
            data: JSON.stringify({
                _history: text,
            }),
        });
    },
    _setHistory(userid) {
        this.addHistory(get(this, 'forms'), userid);
        set(this, 'loading', false);
        set(this, 'done', true);
    },

    _setErrorMessage(error) {
        set(this, 'loading', false);
        set(this, 'errorMessage', error.payload.message);
    },
});
