/* eslint-disable */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { get, set, getProperties } from '@ember/object';
import { action } from '@ember/object';
import { event } from 'jquery';

export default Component.extend({
  store: service(),
  session: service(),
  notifications: service(),
  intl: service(),
  Collector: service('collector-service'),
  loading: false,
  autoPauseOptions: ["15", "30", "45", "60"],
  pause_start_time: null,
  pause_length: null,
  min_workday_length: null,
  min_workday_length_hours: null,
  min_workday_length_minutes: null,
  isChanged: computed('pause_start_time', 'pause_length', 'min_workday_length', {
    get() {
      let hasChanged = false;
      const settings = get(this, 'settings');
      if (settings) {
        const {
          pause_start_time: oldPauseStartTime,
          pause_length: oldPauseLength,
          min_workday_length: oldMinWorkdayLength,
        } = settings;

        const {
          pause_start_time: newPauseStartTime,
          pause_length: newPauseLength,
          min_workday_length: newMinWorkdayLength,
        } = getProperties(this, 'pause_start_time', 'pause_length', 'min_workday_length');

        hasChanged = oldPauseStartTime !== newPauseStartTime ||
          oldPauseLength !== newPauseLength ||
          oldMinWorkdayLength !== newMinWorkdayLength;
      }

      return hasChanged;
    },
    set(key, value) {
      return value;
    }
  }),
  isValueCustom: computed('pause_length', 'autoPauseOptions', function() {
  // Ensure pause_length is treated as a string for comparison
  const pauseLengthAsString = `${this.pause_length}`;
  return !this.autoPauseOptions.includes(pauseLengthAsString);
  }),

  init() {
    this._super(...arguments);
    this.fetchPauseSettings();
  },

  async fetchPauseSettings() {
      set(this, 'loading', true);
      const partnerId = get(this, 'session.currentUser.partnerid');
      // If worktime group is not set, or is undefined or null, set it to 0 (laravel validation expects numerical value in request)
      const worktimegroup = get(this, 'worktimegroup') || 0;


      get(this, 'Collector').ttapi({
        url: `api/getPauseSettings?partnerid=${partnerId}&worktimegroup=${worktimegroup}`,
      }).then((response) => {
        let settings = response.length > 0 ? response[response.length - 1] : {};

        // Convert all relevant settings to string for consistent comparison
        settings.pause_length = settings.pause_length?.toString();
        settings.min_workday_length = settings.min_workday_length?.toString();

        // Set initial settings for comparison
        set(this, 'settings', settings);

        // Also update current component properties to reflect fetched settings
        this.setProperties({
          pause_start_time: settings.pause_start_time,
          pause_length: settings.pause_length,
          min_workday_length: settings.min_workday_length || '0',
          min_workday_length_hours: this.extractHours(settings.min_workday_length),
          min_workday_length_minutes: this.extractMinutes(settings.min_workday_length)
        });
      }).catch((error) => {
          console.error('Error fetching pause settings:', error);
          // Handle the error appropriately here
      }).finally(() => {
          set(this, 'loading', false);
          set(this, 'isChanged', false);
      });
    },

  extractHours(value) {
    if (!value) return '0';
    const hours = Math.floor(parseFloat(value));
    return hours < 10 ? '0' + hours : hours.toString();
  },

  extractMinutes(value) {
    if (!value) return '00';
    const minutes = Math.round((parseFloat(value) % 1) * 60);
    return minutes < 10 ? '0' + minutes : minutes.toString();
  },

  @action
  changeHours(property, event) {
    if (event) {
      let val = parseInt(event, 10);

      if (isNaN(val) || val < 0) val = 0;
      const value = val;
      const minutes = this.get(property + '_minutes') || '00';
      this.set(property, value + (parseInt(minutes) / 60));

      // Update min_workday_length if the property is min_workday_length_hours
      if (property === 'min_workday_length_hours') {
        this.set('min_workday_length', this.get('min_workday_length_hours') + (parseInt(this.get('min_workday_length_minutes')) / 60));
      }
    }
  },

  @action
  changeMinutes(property, event) {
    if (event) {
      let val = parseInt(event, 10);

      if (isNaN(val) || val < 0 || val > 59) val = 0;
      const value = val / 60;
      const hours = this.get(property + '_hours') || '00';
      this.set(property, parseInt(hours) + value);

      // Update min_workday_length if the property is min_workday_length_minutes
      if (property === 'min_workday_length_minutes') {
        this.set('min_workday_length', (parseInt(this.get('min_workday_length_hours')) + (val / 60)));
      }
    }
  },

@action
selectAll(event) {
    const target = event.target;
    target.select();
},

  @action
      async saveSetting() {
          set(this, 'loading', true);
          const worktimegroup = get(this, 'worktimegroup') === undefined ? 0 : get(this, 'worktimegroup');
          const partnerId = this.get('session.currentUser.partnerid');

          const data = {
          worktimegroup: worktimegroup,
          pause_start_time: this.pause_start_time,
          pause_length: parseInt(this.pause_length, 10),
          min_workday_length: parseFloat(this.min_workday_length)
          };

          let savePromise = get(this, 'Collector').ttapi({
              method: "post",
              url: `api/savePauseSettings?partnerid=${partnerId}`,
              data: data
          });

          return Ember.RSVP.Promise.all([savePromise]).then(() => {
            set(this, 'loading', true);
              if (get(this, 'settingSaved')) {
                  return this.settingSaved('products.autopause');
              }
          }).catch(error => {
              get(this, 'notifications').error('Failed to save settings. Please try again.');
              set(this, 'loading', false);
          }).finally(() => {
              // Fetch and update pause settings regardless of the result of savePromise
              this.fetchPauseSettings();
              get(this, 'isChanged');
              set(this, 'loading', false);
          });
      },

      @action
      changeValue(option, isCustom) {
      if (isCustom) {
          set(this, 'pause_length', option);
      }
      set(this, 'isChanged', true);
      },

      @action
      validateInput(property, event) {
          let value = parseInt(event.target.value, 10);

          if (value < 0) {
              this.set(property, '0');
          } else {
              this.set(property, value.toString());
          }
      },
  }
);
