/* eslint-disable ember/named-functions-in-promises */
import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { typeOf } from '@ember/utils';

export default Component.extend({
    Collector: service('collector-service'),
    session: service(),
    store: service(),
    intl: service(),
    initapp: service(),
    servicehandler: service(),
    tools: service(),
    notifications: service(),
    capi: service(),
    flags: service(),
    ttapi: service(),
    validationErrorTexts: null,

    settingType: computed('setting.category', function () {
        if (this.setting.category == 'salaries.overtime') return 'overtime';
        else if (this.setting.category == 'salaries.calculations') return 'compensations';
        return;
    }),
    //startdate: Em.computed ->
    //    moment().format('YYYY-MM-DD')

    customDayBased_saturday: computed('customDayBased.[]', function () {
        return get(this, 'customDayBased').filter((el) => el.name === '_saturday');
    }),
    customDayBased_sunday: computed('customDayBased.[]', function () {
        return get(this, 'customDayBased').filter((el) => el.name === '_sunday');
    }),

    customDayBased_normal: computed('customDayBased.[]', function () {
        return get(this, 'customDayBased').filter(
            (el) => !(el['name'] === '_saturday' || el['name'] === '_sunday'),
        );
    }),

    salarytypes: computed(function () {
        const fields = [];
        if (
            get(this, 'Collector').testNeeds(['products.salarytype||products.selectablesalarytype'])
        ) {
            return get(this, 'store')
                .query('salarytype', { type: '(empty)' })
                .then((salarytypes) => {
                    salarytypes.forEach((stype) => {
                        if (get(stype, 'id')) {
                            return fields.push({ id: get(stype, 'id'), text: get(stype, 'name') });
                        }
                    });
                    return fields;
                });
        } else {
            return fields;
        }
    }),

    fields: computed(function () {
        return get(this, 'Collector')
            .fieldArray('worktime')
            .filterBy('type', 'hours')
            .rejectBy('name', 'work_hours');
    }),
    // filter all the overtime_types with needs

    overtime_types: computed('worktimegroup', function () {
        const types = get(this, '_overtime_types');
        const keys = Object.keys(types);

        const ret = {};
        keys.forEach((key) => {
            // skip types that has needs but needs return false
            if (types[key].needs && !get(this, 'Collector').testNeeds(types[key].needs)) {
                return;
            }
            // all the other types goes to ret
            return (ret[key] = types[key]);
        });
        return ret;
    }),
    init() {
        this._super(...arguments);
        set(this, 'validationErrors', 0);
        set(this, 'validationErrorTexts', []);
        const useNewOvertimeSettings = this.Collector.testNeeds([
            'products.salarytype_v2||flag.new-overtime-settings',
        ]);
        const _overtime_types = EmberObject.create({
            daily_overtime: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.daily_overtime',
                in_use: false,
                settings: {
                    _50start: 8,
                    _100start: 10,
                    _50multiplier: 0.5,
                    _100multiplier: 1,
                    _50saldotype: false,
                    _100saldotype: false,
                },
                product: 'overtime_daily',
                product_50saldotype: 'overtime_daily_50_saldo',
                product_100saldotype: 'overtime_daily_100_saldo',
                rule_creator(settings) {
                    return [
                        {
                            name: '_50',
                            values: {
                                from: settings._50start,
                                to: settings._100start,
                                multiplier: settings._50multiplier,
                                saldotype: settings._50saldotype,
                            },
                        },
                        {
                            name: '_100',
                            values: {
                                from: settings._100start,
                                multiplier: settings._100multiplier,
                                saldotype: settings._100saldotype,
                            },
                        },
                    ];
                },
                rule_parser() {
                    return {
                        _50start: '_50.values.from',
                        _100start: '_100.values.from',
                        _50multiplier: '_50.values.multiplier',
                        _100multiplier: '_100.values.multiplier',
                        _50saldotype: '_50.values.saldotype',
                        _100saldotype: '_100.values.saldotype',
                    };
                },
            },

            weekly_overtime: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.weekly_overtime',
                in_use: false,
                settings: useNewOvertimeSettings
                    ? {
                          _week50start: 40,
                          _week100start: 48,
                          dailymax: 8,
                          _week50multiplier: 0.5,
                          _week100multiplier: 1,
                          _week50saldotype: false,
                          _week100saldotype: false,
                      }
                    : {
                          _week50start: 0,
                          _week100start: 8,
                          _week50multiplier: 0.5,
                          _week100multiplier: 1,
                          _week50saldotype: false,
                          _week100saldotype: false,
                      },
                product: 'overtime_weekly',
                product_week50saldotype: 'overtime_weekly_50_saldo',
                product_week100saldotype: 'overtime_weekly_100_saldo',
                rule_creator: useNewOvertimeSettings
                    ? function (settings) {
                          return [
                              {
                                  name: '_week50',
                                  values: {
                                      from: settings._week50start,
                                      to: settings._week100start,
                                      dailymax: settings.dailymax,
                                      weeklyOverTimeStartsFrom: settings._week50start,
                                      multiplier: settings._week50multiplier,
                                      saldotype: settings._week50saldotype,
                                  },
                              },
                              {
                                  name: '_week100',
                                  values: {
                                      from: settings._week100start,
                                      dailymax: settings.dailymax,
                                      weeklyOverTimeStartsFrom: settings._week50start,
                                      multiplier: settings._week100multiplier,
                                      saldotype: settings._week100saldotype,
                                  },
                              },
                          ];
                      }
                    : function (settings) {
                          return [
                              {
                                  name: '_week50',
                                  values: {
                                      from: settings._week50start,
                                      to: settings._week100start,
                                      multiplier: settings._week50multiplier,
                                      saldotype: settings._week50saldotype,
                                  },
                              },
                              {
                                  name: '_week100',
                                  values: {
                                      from: settings._week100start,
                                      multiplier: settings._week100multiplier,
                                      saldotype: settings._week100saldotype,
                                  },
                              },
                          ];
                      },
                rule_parser() {
                    return useNewOvertimeSettings
                        ? {
                              _week50start: '_week50.values.from',
                              _week100start: '_week100.values.from',
                              dailymax: '_week100.values.dailymax',
                              weeklyOverTimeStartsFrom: '_week50.values.from',
                              _week50multiplier: '_week50.values.multiplier',
                              _week100multiplier: '_week100.values.multiplier',
                              _week50saldotype: '_week50.values.saldotype',
                              _week100saldotype: '_week100.values.saldotype',
                          }
                        : {
                              _week50start: '_week50.values.from',
                              _week100start: '_week100.values.from',
                              _week50multiplier: '_week50.values.multiplier',
                              _week100multiplier: '_week100.values.multiplier',
                              _week50saldotype: '_week50.values.saldotype',
                              _week100saldotype: '_week100.values.saldotype',
                          };
                },
            },
            extrahours_overtime: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.extrahours',
                in_use: false,

                settings: useNewOvertimeSettings
                    ? {
                          _extrahours_start: 7.5,
                          _extrahours_end: 8,
                          _extrahours_week_max: 2.5,
                          multiplier: 1,
                          saldotype: false,
                      }
                    : {
                          _extrahours_start: 7.5,
                          _extrahours_end: 8,
                          multiplier: 1,
                          saldotype: false,
                      },

                product: 'overtime_extrahours',
                productsaldotype: 'overtime_extrahours_saldo',
                rule_creator: useNewOvertimeSettings
                    ? function (settings) {
                          return [
                              {
                                  name: '_extrahours',
                                  values: {
                                      from: settings._extrahours_start,
                                      to: settings._extrahours_end,
                                      week_max: settings._extrahours_week_max,
                                      multiplier: settings.multiplier,
                                      saldotype: settings.saldotype,
                                  },
                              },
                          ];
                      }
                    : function (settings) {
                          return [
                              {
                                  name: '_extrahours',
                                  values: {
                                      from: settings._extrahours_start,
                                      to: settings._extrahours_end,
                                      multiplier: settings.multiplier,
                                      saldotype: settings.saldotype,
                                  },
                              },
                          ];
                      },
                rule_parser() {
                    return useNewOvertimeSettings
                        ? {
                              _extrahours_start: '_extrahours.values.from',
                              _extrahours_end: '_extrahours.values.to',
                              _extrahours_week_max: '_extrahours.values.week_max',
                              multiplier: '_extrahours.values.multiplier',
                              saldotype: '_extrahours.values.saldotype',
                          }
                        : {
                              _extrahours_start: '_extrahours.values.from',
                              _extrahours_end: '_extrahours.values.to',
                              multiplier: '_extrahours.values.multiplier',
                              saldotype: '_extrahours.values.saldotype',
                          };
                },
            },
            time_based: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.time_based',
                in_use: false,
                settings: {
                    _evening_start: '18:00',
                    _evening_end: '22:00',
                    _night_start: '22:00',
                    _night_end: '06:00',
                    _eveningmultiplier: null,
                    _nightmultiplier: null,
                    _eveningsaldotype: false,
                    _nightsaldotype: false,
                },
                product: 'overtime_time_based',
                product_eveningsaldotype: 'overtime_time_based_evening_saldo',
                product_nightsaldotype: 'overtime_time_based_night_saldo',
                rule_creator(settings) {
                    return [
                        {
                            name: '_evening',
                            values: {
                                from: settings._evening_start,
                                to: settings._evening_end,
                                do_not_calculate_if_overtime: settings._evening_checked,
                                calculate_compensations_always:
                                    settings._evening_calculate_compensations_always,
                                multiplier: settings._eveningmultiplier,
                                saldotype: settings._eveningsaldotype,
                            },
                        },
                        {
                            name: '_night',
                            values: {
                                from: settings._night_start,
                                to: settings._night_end,
                                do_not_calculate_if_overtime: settings._night_checked,
                                calculate_compensations_always:
                                    settings._night_calculate_compensations_always,
                                multiplier: settings._nightmultiplier,
                                saldotype: settings._nightsaldotype,
                            },
                        },
                    ];
                },
                rule_parser() {
                    return {
                        _evening_start: '_evening.values.from',
                        _evening_end: '_evening.values.to',
                        _evening_checked: '_evening.values.do_not_calculate_if_overtime',
                        _evening_calculate_compensations_always:
                            '_evening.values.calculate_compensations_always',
                        _night_start: '_night.values.from',
                        _night_end: '_night.values.to',
                        _night_checked: '_night.values.do_not_calculate_if_overtime',
                        _night_calculate_compensations_always:
                            '_night.values.calculate_compensations_always',
                        //_calculate_compensations_always: '_night.values.calculate_compensations_always'
                        _eveningmultiplier: '_evening.values.multiplier',
                        _nightmultiplier: '_night.values.multiplier',
                        _eveningsaldotype: '_evening.values.saldotype',
                        _nightsaldotype: '_night.values.saldotype',
                    };
                },
            },
            time_based_se: {
                needs: ['serviceCountry@se'],
                name: 'overtime.time_based',
                in_use: false,
                settings: {},
                product: 'overtime_time_based_se',
                alwaysOn: true,
                rule_creator() {
                    return [];
                },
                rule_parser() {
                    return {};
                },
            },
            overtime_period: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.period_overtime',
                in_use: false,
                settings: {
                    _periodstart: '2020-01-01',
                    _periodlength: 14,
                    _period50calculate_after_hours: 80,
                    _period100calculate_after_hours: 120,
                    _period50multiplier: 0.5,
                    _period100multiplier: 1,
                    _period50saldotype: false,
                    _period100saldotype: false,
                },
                product: 'overtime_period',
                product_period50saldotype: 'overtime_period_50_saldo',
                product_period100saldotype: 'overtime_period_100_saldo',
                rule_creator(settings) {
                    return [
                        {
                            name: '_period50',
                            values: {
                                from: settings._periodstart,
                                to: settings._period100calculate_after_hours,
                                period_length: settings._periodlength,
                                calculate_after_hours: settings._period50calculate_after_hours,
                                multiplier: settings._period50multiplier,
                                saldotype: settings._period50saldotype,
                            },
                        },
                        {
                            name: '_period100',
                            values: {
                                from: settings._periodstart,
                                period_length: settings._periodlength,
                                calculate_after_hours: settings._period100calculate_after_hours,
                                multiplier: settings._period100multiplier,
                                saldotype: settings._period100saldotype,
                            },
                        },
                    ];
                },
                rule_parser() {
                    return {
                        _periodstart: '_period50.values.from',
                        _periodlength: '_period50.values.period_length',
                        _period50calculate_after_hours: '_period50.values.calculate_after_hours',
                        _period100calculate_after_hours: '_period100.values.calculate_after_hours',
                        _period50multiplier: '_period50.values._period50multiplier',
                        _period100multiplier: '_period100.values._period100multiplier',
                        _period50saldotype: '_period50.values.saldotype',
                        _period100saldotype: '_period100.values.saldotype',
                    };
                },
            },
            overtime_splitted: {
                needs: ['serviceCountry@fi'],
                name: 'overtime.split_overtimehours',
                in_use: false,
                settings: {
                    multiplier: 1,
                    saldotype: false,
                },
                product: 'overtime_splitted',
                productsaldotype: 'overtime_splitted_saldo',
                rule_creator(settings) {
                    return [
                        {
                            name: '_overtimehours',
                            values: {
                                multiplier: settings.multiplier,
                                saldotype: settings.saldotype,
                            },
                        },
                        { name: '_basichours', values: {} },
                    ];
                },
                rule_parser() {
                    return {
                        multiplier: '_overtimehours.values.multiplier',
                        saldotype: '_overtimehours.values.saldotype',
                    };
                },
            },
        });
        set(this, '_overtime_types', _overtime_types);

        set(this, 'days', [
            { id: 'mo', name: get(this, 'intl').t('workday.calendar.week.monday') },
            { id: 'tu', name: get(this, 'intl').t('workday.calendar.week.tuesday') },
            { id: 'we', name: get(this, 'intl').t('workday.calendar.week.wednesday') },
            { id: 'th', name: get(this, 'intl').t('workday.calendar.week.thursday') },
            { id: 'fr', name: get(this, 'intl').t('workday.calendar.week.friday') },
            { id: 'sa', name: get(this, 'intl').t('workday.calendar.week.saturday') },
            { id: 'su', name: get(this, 'intl').t('workday.calendar.week.sunday') },
        ]);

        set(this, 'period_lengths', [
            { id: '7', name: '1 ' + get(this, 'intl').t('workday.calendar.week') },
            { id: '14', name: '2 ' + get(this, 'intl').t('workday.calendar.week') },
            { id: '21', name: '3 ' + get(this, 'intl').t('workday.calendar.week') },
            { id: '28', name: '4 ' + get(this, 'intl').t('workday.calendar.week') },
            { id: '35', name: '5 ' + get(this, 'intl').t('workday.calendar.week') },
            { id: '42', name: '6 ' + get(this, 'intl').t('workday.calendar.week') },
        ]);
    },

    async didReceiveAttrs() {
        set(this, 'fieldsToBeRemoved', []);
        set(this, 'loadingData', true);

        //if (get(this,'overtime_types.time_based.settings._evening_start'))
        if (get(this, 'customrules')) {
            await this.setRules(this.parseIfString(get(this, 'customrules')));
        } else {
            await this.getGeneralRules();
        }

        this.setAllSettings();

        set(this, '_saturdayEnabled', get(this, 'customDayBased_saturday').length > 0);
        set(this, '_sundayEnabled', get(this, 'customDayBased_sunday').length > 0);

        set(this, 'loadingData', false);

        if (get(this, 'overtime_types.time_based.in_use')) {
            if (
                get(this, 'overtime_types.time_based.settings._evening_start') == '' &&
                get(this, 'overtime_types.time_based.settings._evening_end') == ''
            ) {
                set(this, 'eveningEnabled', false);
            } else {
                set(this, 'eveningEnabled', true);
            }

            if (
                get(this, 'overtime_types.time_based.settings._night_start') == '' &&
                get(this, 'overtime_types.time_based.settings._night_end') == ''
            ) {
                set(this, 'nightEnabled', false);
            } else {
                set(this, 'nightEnabled', true);
            }
        } else {
            set(this, 'nightEnabled', false);
            set(this, 'eveningEnabled', false);
        }
    },

    //_100start: get rules.findBy('name', '_100'), 'values.from'

    actions: {
        toggleWeekend(day, bool) {
            if (!bool) {
                set(
                    this,
                    'customDayBased',
                    get(this, 'customDayBased').filter((el) => el.name !== day),
                );
            } else {
                this['add' + day]();
            }

            set(this, day + 'Enabled', bool);
            return true;
        },

        toggleCompCheck(rulename, value) {
            if (rulename) {
                if (value) {
                    set(this, rulename, false);
                } else {
                    set(this, rulename, true);
                }
            } else {
                if (value) {
                    set(value, 'checked', false);
                } else {
                    set(value, 'checked', true);
                }
            }
        },
        toggleCustomCompCheck(row, value) {
            if (value) {
                set(row, 'checked', false);
            } else {
                set(row, 'checked', true);
            }
        },
        checkValues(value1, value2) {
            if (value1.toString() === value2.toString()) {
                set(this, 'disabled', true);
            } else {
                set(this, 'disabled', false);
            }
        },

        addTimeBased() {
            get(this, 'customTimeBased').pushObject({});
        },

        addDayBased() {
            get(this, 'customDayBased').pushObject({});
        },

        removeCustomTimeBased(row) {
            if (get(row, 'name') && this.onlyOneRuleForField(get(row, 'name'), 'customTimeBased')) {
                get(this, 'fieldsToBeRemoved').push(get(row, 'name'));
                if (row.saldotype) this.fieldsToBeRemoved.push(row.name + '_saldo');
            }
            get(this, 'customTimeBased').removeObject(row);
            set(this, 'validationErrors', false);
        },

        removeCustomDayBased(row) {
            if (get(row, 'name') && this.onlyOneRuleForField(get(row, 'name'), 'customDayBased')) {
                get(this, 'fieldsToBeRemoved').push(get(row, 'name'));
                if (row.saldotype) this.fieldsToBeRemoved.push(row.name + '_saldo');
            }
            get(this, 'customDayBased').removeObject(row);
            set(this, 'validationErrors', false);
        },

        async createAndSaveRules() {
            set(this, 'validationErrors', 0);
            set(this, 'validationErrorTexts', []);
            //console.info "types",Object.keys(@get('overtime_types')), @get 'overtime_types'

            let type;
            const rules = [];
            const needed_products = [];
            const unneeded_products = [];
            const overtime_types = get(this, 'overtime_types');

            const wtypes = [];
            let wtypesString = '';
            if (get(this, 'salarytype')) {
                for (let wt of Array.from(get(this, 'salarytype'))) {
                    wtypes.push(wt.id);
                }
                wtypesString = wtypes.join();
            }

            for (type of Array.from(Object.keys(overtime_types))) {
                const overtime_type = overtime_types[type];

                if (overtime_type.in_use) {
                    rules.pushObjects(overtime_type.rule_creator(overtime_type.settings));
                    needed_products.pushObject(overtime_type.product);
                } else {
                    unneeded_products.pushObject(overtime_type.product);
                }

                /*
                 * Validate overtime settings
                 */
                if (
                    this.Collector.testNeeds(['products.salarytype_v2||flag.new-overtime-settings'])
                ) {
                    if (type === 'weekly_overtime' && overtime_type.in_use) {
                        if (
                            parseFloat(overtime_type.settings._week50start) >
                            parseFloat(overtime_type.settings._week100start)
                        ) {
                            this.incrementProperty('validationErrors');
                            this.validationErrorTexts.push(
                                'overtime.weekly_overtime.validation_error',
                            );
                        }
                    }

                    if (type === 'daily_overtime' && overtime_type.in_use) {
                        if (
                            parseFloat(overtime_type.settings._50start) >
                            parseFloat(overtime_type.settings._100start)
                        ) {
                            this.incrementProperty('validationErrors');
                            this.validationErrorTexts.push(
                                'overtime.daily_overtime.validation_error',
                            );
                        }
                    }

                    if (type === 'extrahours_overtime' && overtime_type.in_use) {
                        if (
                            parseFloat(overtime_type.settings._extrahours_start) >
                            parseFloat(overtime_type.settings._extrahours_end)
                        ) {
                            this.incrementProperty('validationErrors');
                            this.validationErrorTexts.push(
                                'overtime.extrahours_overtime.validation_error',
                            );
                        }
                    }

                    if (type === 'overtime_period' && overtime_type.in_use) {
                        if (
                            parseFloat(overtime_type.settings._period50calculate_after_hours) >
                            parseFloat(overtime_type.settings._period100calculate_after_hours)
                        ) {
                            this.incrementProperty('validationErrors');
                            this.validationErrorTexts.push(
                                'overtime.overtime_period.validation_error',
                            );
                        }
                    }
                }
                let saldoProductsNeeds = this.checkSaldoProductsNeeds(overtime_type);

                if (saldoProductsNeeds.needed)
                    saldoProductsNeeds.needed.map((product) => needed_products.pushObject(product));
                if (saldoProductsNeeds.unneeded)
                    saldoProductsNeeds.unneeded.map((product) =>
                        unneeded_products.pushObject(product),
                    );
                /*
                if ((overtime_type.product === "overtime_time_based") && overtime_type.in_use) {
                    rules.forEach(rule => {
                        if ((rule.values.from === "") && (rule.values.to === "")) {
                            rules.removeObject(rule);
                        }
                    });
                }*/
            }

            for (let rule of Array.from(rules)) {
                rule['values']['salarytypes'] = wtypesString;
            }

            const fields = {};

            get(this, 'customTimeBased').forEach((row) => {
                row = this.validateInputs(row, 'time');
                if (!get(row, 'name') && get(row, 'text')) {
                    const sameField = this.searchFieldWithName(fields, get(row, 'text'));
                    if (sameField) {
                        set(row, 'name', sameField);
                    } else {
                        set(
                            row,
                            'name',
                            '_' +
                                get(this, 'tools').computerizeHumanInput(get(row, 'text')) +
                                Math.floor(1000 + Math.random() * 9000),
                        );
                    }
                }

                const field = get(row, 'name');

                fields['worktime.' + field] = { type: 'hours', name: get(row, 'text') };
                fields['worktime.' + field + '.@hide'] = 'true';

                if (row.saldotype) {
                    fields['worktime.' + field + '_saldo'] = {
                        type: 'hours',
                        name: get(row, 'text') + ' (saldo)',
                    };
                    fields['worktime.' + field + '_saldo' + '.@hide'] = 'true';
                }
                rules.pushObject({
                    name: 'hoursbetween',
                    field,
                    text: get(row, 'text'),
                    values: {
                        from: get(row, 'start'),
                        to: get(row, 'end'),
                        do_not_calculate_if_overtime:
                            get(row, 'checked') === undefined ? false : get(row, 'checked'),
                        calculate_compensations_always:
                            get(row, 'calculate_compensations_always') === undefined
                                ? false
                                : get(row, 'calculate_compensations_always'),
                        saldotype: row.saldotype === undefined ? false : row.saldotype,
                        multiplier: row.multiplier === undefined ? null : row.multiplier,
                    },
                });
            });

            get(this, 'customDayBased').forEach((row) => {
                row = this.validateInputs(row, 'day');
                if (!get(row, 'name') && get(row, 'text')) {
                    const sameField = this.searchFieldWithName(fields, get(row, 'text'));
                    if (sameField) {
                        set(row, 'name', sameField);
                    } else {
                        set(
                            row,
                            'name',
                            '_' +
                                get(this, 'tools').computerizeHumanInput(get(row, 'text')) +
                                Math.floor(1000 + Math.random() * 9000),
                        );
                    }
                }

                const field = get(row, 'name');

                fields['worktime.' + field] = { type: 'hours', name: get(row, 'text') };
                fields['worktime.' + field + '.@hide'] = 'true';

                if (row.saldotype) {
                    fields['worktime.' + field + '_saldo'] = {
                        type: 'hours',
                        name: get(row, 'text') + ' (saldo)',
                    };
                    fields['worktime.' + field + '_saldo' + '.@hide'] = 'true';
                }
                let customRule = {
                    name: 'custom_dayrule',
                    field,
                    text: get(row, 'text'),
                    values: {
                        from: get(row, 'start'),
                        to: get(row, 'end'),
                        days: get(row, 'start_day') + '-' + get(row, 'end_day'),
                        do_not_calculate_if_overtime:
                            get(row, 'checked') === undefined ? false : get(row, 'checked'),
                        //calculate_compensations_always: get(this, 'overtime_types.time_based.settings._calculate_compensations_always') === undefined ? 'false' : get(this, 'overtime_types.time_based.settings._calculate_compensations_always')
                        calculate_compensations_always:
                            get(row, 'calculate_compensations_always') === undefined
                                ? false
                                : get(row, 'calculate_compensations_always'),
                        saldotype: row.saldotype === undefined ? false : row.saldotype,
                        multiplier: row.multiplier === undefined ? null : row.multiplier,
                    },
                };
                if (wtypesString) customRule['values']['salarytypes'] = wtypesString;

                rules.pushObject(customRule);
            });
            let foundRule = null;
            if (!get(this, 'eveningEnabled')) {
                foundRule = rules.findBy('name', '_evening');
                if (foundRule) {
                    foundRule.values.from = '';
                    foundRule.values.to = '';
                }
            }
            if (!get(this, 'nightEnabled')) {
                foundRule = rules.findBy('name', '_night');
                if (foundRule) {
                    foundRule.values.from = '';
                    foundRule.values.to = '';
                }
            }

            get(this, 'fieldsToBeRemoved').forEach((item) => {
                fields['!worktime.' + item] = true;
            });
            set(this, 'fieldsToBeRemoved', []);

            if (get(this, 'validationErrors') > 0) {
                if (this.validationErrorTexts.length) {
                    get(this, 'notifications').error(
                        this.validationErrorTexts.map((el) => get(this, 'intl').t(el)).join(','),
                        {
                            autoClear: true,
                        },
                    );
                }
                return;
            }

            if (get(this, 'customrules')) {
                this.save(rules);
            } else {
                this.saveGeneralSettings(fields, rules);
            }

            // add overtime-setting also to needed_products (no worries, we check later if product.overtime is found already from products so this won't save duplicates!)
            if (needed_products.length > 0 || rules.length > 0) {
                needed_products.pushObject('overtime');
            } else {
                unneeded_products.pushObject('overtime');
            }

            this.updateNeededProducts(needed_products, unneeded_products);
        },
        validateInputsAction(row, type) {
            this.validateInputs(row, type);
        },
        disableComp(type) {
            this.toggleProperty(type + 'Enabled');
            if (this.nightEnabled || this.eveningEnabled) {
                set(this, 'overtime_types.time_based.in_use', true);
            } else if (!this.nightEnabled && !this.eveningEnabled) {
                set(this, 'overtime_types.time_based.in_use', false);
            }

            // remove validation-errors if turned off
            if (!get(this, type + 'Enabled')) {
                let settings = get(this, 'overtime_types.time_based.settings');

                if (settings['_' + type + '_start_validation_error']) {
                    delete settings['_' + type + '_start_validation_error'];
                    this.decrementProperty('validationErrors');
                }
                if (settings['_' + type + '_end_validation_error']) {
                    delete settings['_' + type + '_end_validation_error'];
                    this.decrementProperty('validationErrors');
                }
            } else {
                this.validateInputs(get(this, 'overtime_types.time_based.settings'), type);
            }
        },
    },

    async setAllSettings() {
        this.allSettings = await get(this, 'Collector').ttapi({
            url: 'overtime/allSettings',
            method: 'get',
        });
    },

    add_saturday() {
        get(this, 'customDayBased').pushObject({
            template: true,
            name: '_saturday',
            text: this.intl.t('overtime.time_based.saturday'),
            start: '00:00',
            end: '00:00',
            start_day: 'sa',
            end_day: 'su',
            checked: false,
            calculate_compensations_always: 'false',
            saldotype: false,
            multiplier: null,
        });
    },
    add_sunday() {
        get(this, 'customDayBased').pushObject({
            template: true,
            name: '_sunday',
            text: this.intl.t('overtime.time_based.sunday'),
            start: '00:00',
            end: '00:00',
            start_day: 'su',
            end_day: 'mo',
            checked: false,
            calculate_compensations_always: 'false',
            saldotype: false,
            multiplier: null,
        });
    },

    validateInputs(row, /*validationErrors,*/ type) {
        //let currentValidationErrors = false;
        let fields = [];
        if (type == 'day') {
            fields = ['text', 'start_day', 'end_day', 'start', 'end'];
        } else if (type == 'evening') {
            fields = ['_evening_start', '_evening_end'];
        } else if (type == 'night') {
            fields = ['_night_start', '_night_end'];
        } else {
            fields = ['text', 'start', 'end'];
        }
        for (var field of fields) {
            if (!get(row, field)) {
                if (!get(row, field + '_validation_error')) {
                    this.incrementProperty('validationErrors');
                    set(row, field + '_validation_error', true);
                }
            } else if (get(row, field)) {
                if (get(row, field + '_validation_error')) {
                    this.decrementProperty('validationErrors');
                    set(row, field + '_validation_error', false);
                }
            }
        }
        return row;
    },
    searchFieldWithName(fields, name) {
        const fieldKeys = Object.keys(fields);

        let found = null;
        fieldKeys.forEach((key) => {
            if (fields[key].name === name) {
                return (found = key);
            }
        });

        if (found) {
            return found.split('.')[1];
        } else {
            return null;
        }
    },

    onlyOneRuleForField(field) {
        if (!this.allSettings.filter((setting) => setting.field === field).length) return true;
        return this.allSettings.filter((setting) => setting.field === field).length < 2;
    },
    async saveGeneralSettings(fields, rules) {
        set(this, 'isLoading', true);

        await get(this, 'servicehandler').manipulateService(fields);

        const data = { rules: JSON.stringify(rules) };

        if (get(this, 'worktimegroup')) {
            data.worktimegroup = get(this, 'worktimegroup');
        }

        await get(this, 'Collector')
            .ttapi({
                url: 'overtime/settings',
                method: 'post',
                data,
            })
            .then(() => {
                get(this, 'initapp').clearInit();
                get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                    autoClear: true,
                });
                set(this, 'isLoading', false);
            });
        this.refreshNeeded();
        this.setAllSettings();
    },
    turnOn(type) {
        set(this, 'overtime_types.' + type + '.in_use', true);
    },

    turnOff(type) {
        set(this, 'overtime_types.' + type + '.in_use', false);
    },

    updateNeededProducts(needed_products, unneeded_products) {
        const c = get(this, 'Collector');

        needed_products.forEach((needed_product) => {
            const worktimegroup = get(this, 'worktimegroup') ? get(this, 'worktimegroup') : null;
            c.addProduct(needed_product, worktimegroup);
            this.refreshNeeded;
        });

        unneeded_products.forEach((unneeded_product) => {
            const worktimegroup = get(this, 'worktimegroup') ? get(this, 'worktimegroup') : null;
            c.removeProduct(unneeded_product, worktimegroup);
            this.refreshNeeded;
        });
    },
    parseIfString(mixed) {
        if (typeOf(mixed) === 'string') {
            return JSON.parse(mixed);
        }
        return mixed;
    },

    async getGeneralRules() {
        const data = {};

        if (get(this, 'worktimegroup')) {
            data.worktimegroup = get(this, 'worktimegroup');
        }

        let rules = await get(this, 'Collector').ttapi({
            url:
                'overtime/settings' +
                (this.flags.test('new-overtime-settings') ? '?migrate=true' : ''),
            method: 'get',
            data,
        });
        var i, len, rule, search_rule, stypes, types_arr;

        if (
            get(this, 'Collector').testNeeds(['products.salarytype||products.selectablesalarytype'])
        ) {
            search_rule = false;
            for (i = 0, len = rules.length; i < len; i++) {
                rule = rules[i];
                if (rule.values != null && rule.values.salarytypes) {
                    search_rule = rule;
                }
            }
            if (search_rule) {
                types_arr = search_rule.values.salarytypes.split(',');
                stypes = [];
                get(this, 'store')
                    .query('salarytype', {
                        id: search_rule.values.salarytypes,
                    })
                    .then(
                        (function (_this) {
                            return function (salarytypes) {
                                return salarytypes.forEach(function (stype) {
                                    if (types_arr.indexOf(get(stype, 'id')) > -1) {
                                        stypes.push({
                                            id: get(stype, 'id'),
                                            text: get(stype, 'name'),
                                        });
                                    }
                                    return set(_this, 'salarytype', stypes);
                                });
                            };
                        })(this),
                    );
            } else {
                set(this, 'salarytype', null);
            }
        }
        this.setRules(rules);
    },

    setRules(rules) {
        set(this, 'customTimeBased', A([]));
        set(this, 'customDayBased', A([]));
        let overtime_types = get(this, 'overtime_types');
        for (var type of Object.keys(overtime_types)) {
            if (overtime_types[type].alwaysOn === true) {
                this.turnOn(type);
            } else {
                this.setValuesForOvertimeType(type, rules, overtime_types[type].rule_parser(rules));
            }
        }
        this.getCustomFields('hoursbetween', rules);
        this.getCustomDayFields('custom_dayrule', rules);
    },

    getCustomFields(type, rules) {
        return rules.filterBy('name', type).forEach((item) => {
            get(this, 'customTimeBased').pushObject({
                name: get(item, 'field'),
                text: get(item, 'text'),
                start: get(item, 'values.from'),
                end: get(item, 'values.to'),
                checked:
                    get(item, 'values.do_not_calculate_if_overtime') === undefined ||
                    get(item, 'values.do_not_calculate_if_overtime') === true
                        ? true
                        : false,
                calculate_compensations_always: get(item, 'values.calculate_compensations_always'),
                saldotype: item.values.saldotype === undefined ? false : item.values.saldotype,
                multiplier: item.values.multiplier === undefined ? 1 : item.values.multiplier,
            });
        });
    },

    getCustomDayFields(type, rules) {
        return rules.filterBy('name', type).forEach((item) => {
            const daysArr = get(item, 'values.days').split('-');
            get(this, 'customDayBased').pushObject({
                name: get(item, 'field'),
                text: get(item, 'text'),
                start: get(item, 'values.from'),
                end: get(item, 'values.to'),
                start_day: daysArr[0],
                end_day: daysArr[1],
                checked:
                    get(item, 'values.do_not_calculate_if_overtime') === undefined ||
                    get(item, 'values.do_not_calculate_if_overtime') === true
                        ? true
                        : false,
                calculate_compensations_always: get(item, 'values.calculate_compensations_always'),
                saldotype: item.values.saldotype === undefined ? false : item.values.saldotype,
                multiplier: item.values.multiplier === undefined ? 1 : item.values.multiplier,
            });
        });
    },
    //checked: if get(item, 'values.do_not_calculate_if_overtime') == undefined || get(item, 'values.do_not_calculate_if_overtime') == true then true else false

    setValuesForOvertimeType(type, rules, paths) {
        const values = Object.keys(paths);

        const hasSplittedOvertimeRule = rules.find((rule) => rule.name === '_basichours')
            ? true
            : false;
        set(this, 'overtime_types.overtime_splitted.in_use', hasSplittedOvertimeRule);

        //let time_based_on = false;
        const timeBasedNoRules = [];
        for (let value_name of Array.from(values)) {
            const paths_splitted = paths[value_name].split('.');

            const rule = rules.findBy('name', paths_splitted[0]);
            if (!rule) {
                this.turnOff(type);
                if (type === 'time_based') {
                    timeBasedNoRules.push(value_name);
                }
                continue;
            }

            const path = paths_splitted.slice(1).join('.');
            const value = get(rule, path);

            if (value || (!value && type == 'time_based')) {
                this.turnOn(type);
                set(this, 'overtime_types.' + type + '.settings.' + value_name, value);

                //if (type === 'time_based') {
                //    time_based_on = true;
                //}
            }
        }
        // special check for checkboxes (because old customers do not have these checkboxes saved)
        //if value_name.indexOf("_checked") >= 0 && value == undefined
        //    @set 'overtime_types.' + type + '.settings.' + value_name, true
        //else if value_name.indexOf("_checked") >= 0 && value == false
        //    @set 'overtime_types.' + type + '.settings.' + value_name, false
        /*
        if (time_based_on) {
            console.log("set time on")
            this.turnOn('time_based');
            if (timeBasedNoRules.length > 0) {
                timeBasedNoRules.forEach(item => {
                    set(this, 'overtime_types.time_based.settings.' + item, null);
                });
            }
        }*/
    },

    checkSaldoProductsNeeds(overtime_type) {
        let needed = [];
        let unneeded = [];
        for (const [name, value] of Object.entries(overtime_type.settings)) {
            if (name.includes('saldotype')) {
                if (!overtime_type.in_use) {
                    unneeded.push(get(overtime_type, `product${name}`));
                } else {
                    value
                        ? needed.push(get(overtime_type, `product${name}`))
                        : unneeded.push(get(overtime_type, `product${name}`));
                }
            }
        }
        return { needed, unneeded };
    },
});
