/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/comp-report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var CompReportRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

import $ from 'jquery';

CompReportRoute = GeneralReportRoute.extend({
    localStorageWrapper: Ember.inject.service(),
    // add new query-params if needed
    queryParams: {
        user: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        worktime_page: {
            refreshModel: true,
        },
        absence_page: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        worktime_limit: {
            refreshModel: true,
        },
        absence_limit: {
            refreshModel: true,
        },
        aborder: {
            refreshModel: true,
        },
        row_info_status: {
            refreshModel: true,
        },
        ab_row_info_status: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var ab_params, general_params, param, worktime_params;
        params = this._super(params);
        //params.sideload = true
        //params.limit = 25
        general_params = {};
        // used by both reports
        //general_params.limit = params.limit
        //general_params.offset = params.offset
        if (params) {
            for (param in params) {
                if (params[param] === 'null' || params[param] === 'undefined') {
                    delete params[param];
                }
            }
        }
        worktime_params = {};
        ab_params = {};
        if (params.wage_period) {
            params.date = params.wage_period;
        }
        if (!params.worktime_limit) {
            params.worktime_limit = this.get('localStorageWrapper').getUserSetting(
                'comp-report.worktime_defaultLimit',
            );
        }
        if (Array.isArray(params.worktime_limit)) {
            params.worktime_limit = 25; // sometimes ls contains [] ... and it causes an error that offset is always 0
        }
        worktime_params.date = params.date;
        worktime_params.user = params.user;
        worktime_params.group = params.group;
        worktime_params.status = params.status;
        worktime_params.order = params.order;
        worktime_params.limit = params.worktime_limit || params.limit;
        if (params.employer) {
            worktime_params.employer = params.employer;
        }
        worktime_params.sideload = true;
        worktime_params.offset =
            (params.worktime_page - 1) * (params.worktime_limit || params.limit);
        if (params.project) {
            worktime_params.project = params.project;
        }
        //worktime_params.offset = true
        worktime_params.row_info_status = params.row_info_status;
        worktime_params = $.extend({}, worktime_params, general_params);
        if (!params.absence_limit) {
            params.absence_limit = this.get('localStorageWrapper').getUserSetting(
                'comp-report.absences_defaultLimit',
            );
        }
        if (Array.isArray(params.absence_limit)) {
            params.absence_limit = 25; // sometimes ls contains [] ... and it causes an error that offset is always 0
        }
        ab_params['startdate_enddate'] = params.date;
        ab_params.user = params.user;
        ab_params.sideload = true;
        ab_params.offset = (params.absence_page - 1) * (params.absence_limit || params.limit);
        ab_params.group = params.group;
        ab_params.status = params.status;
        ab_params.limit = params.absence_limit || params.limit;
        if (params.employer && this.get('Collector').fieldExists('abcense', 'employer')) {
            ab_params.employer = params.employer;
        }
        if (params.project && this.get('Collector').fieldExists('abcense', 'project')) {
            ab_params.project = params.project;
        }
        ab_params.order = params.aborder;
        ab_params.row_info_status = params.ab_row_info_status;
        ab_params = $.extend({}, ab_params, general_params);
        return Ember.RSVP.hash({
            worktimes: this.store.query('worktime', worktime_params),
            absences: this.store.query('abcense', ab_params),
        });
    },
    actions: {
        willTransition: function (transition) {
            if (
                transition.targetName === 'timetracker.edit' ||
                transition.targetName === 'abcenses.edit'
            ) {
                return this.transitionTo(transition.targetName).then((newRoute) => {
                    return newRoute.controller.set('afterSave', 'timetracker.comp-report');
                });
            }
        },
    },
});

export default CompReportRoute;
