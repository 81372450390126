import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {
    activate: function () {
        document.body.classList.add('dashboard-body');
    },
    deactivate: function () {
        document.body.classList.remove('dashboard-body');
    },
});
