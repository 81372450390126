/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/custom-inflector-rules.coffee.old
// Generated by CoffeeScript 2.6.1
var inflector;

import Inflector from 'ember-inflector';

inflector = Inflector.inflector;

inflector.irregular('drive', 'drives');

export default {};
