import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed, get, set } from '@ember/object';
import { later } from '@ember/runloop';
import { isPresent, typeOf } from '@ember/utils';
import Ember from 'ember';

const MachinesTransactionProjectController = Controller.extend({
    Collector: service('collector-service'),
    //    queryParams: ['statusSearch','more']
    status: null,
    init() {
        this._super(...arguments);
        const c = this.Collector;
        const filters = c.fieldArray('transaction').filter((item) => {
            set(item, 'value', get(this, 'item.name'));
            return (
                ['database', 'dropdown_values'].includes(item.type) &&
                !['project', 'status', 'tooltype'].includes(item.name)
            );
        });
        set(this, 'filters', filters);
        const qp = filters.mapBy('name');
        qp.push('statusSearch');
        set(this, 'queryParams', qp);
        return set(this, 'to', {});
    },

    content: alias('model'),
    tools: computed('project.id', 'model.project.id', function () {
        return this.model.transactions.sortBy('tooltype.type');
    }),

    showSaveButton: computed('project.id', 'model.project.id', function () {
        return get(this, 'project.id');
    }),

    fields: computed('model.project', function () {
        const fields = [];
        this.Collector.fieldArray('transaction').forEach((item) => {
            if (
                ['database', 'dropdown_values'].includes(item.type) &&
                !['project', 'status', 'tooltype'].includes(item.name)
            ) {
                item.filterOptions = { project: get(this.model, 'project.id') };
                return fields.push(item);
            }
        });
        return fields;
    }),

    to_fields: computed('fields', 'project', function () {
        return this.fields.map((item) => {
            const new_item = Object.assign({}, item);
            new_item.filterOptions = { project: this.project?.id };
            return new_item;
        });
    }),

    actions: {
        changeStatus(value) {
            return set(this, 'statusSearch', value);
        },

        valueChanged(value, tooltype, status, transactionid) {
            //Ember.set(this, 'amounts.'+Ember.get(tooltype,'id'), parseInt(value))
            const found = this.model.amounts.find(
                (item) =>
                    item.tooltype === get(tooltype, 'id') &&
                    item.status === status &&
                    item.id === transactionid,
            );
            if (found) {
                return (found.amount = parseInt(value));
            } else {
                return this.model.amounts.push({
                    id: transactionid,
                    tooltype: get(tooltype, 'id'),
                    status,
                    amount: parseInt(value),
                });
            }
        },

        async moveMachines() {
            set(this, 'saving', true);
            const params = {
                machines: [],
                from: {
                    project: get(this.model, 'project.id'),
                    status: this.statusSearch,
                },
                to: {
                    project: get(this, 'project.id'),
                    status: this.status,
                },
            };

            const fields = this.fields;
            if (fields.length > 0) {
                fields.forEach((field) => {
                    params['from'][field.name] = get(this, field.name);
                    return (params['to'][field.name] = get(this, 'to.' + field.name));
                });
            }

            this.model.amounts.forEach((item) => {
                if (item.amount < 1) {
                    return;
                }
                const ret = {
                    id: item.id,
                    tooltype: item.tooltype,
                    amount: item.amount,
                };
                fields.forEach((field) => {
                    if (isPresent(get(item, field.name))) {
                        return set(ret, field.name, get(item, field.name));
                    }
                });
                return params.machines.push(ret);
            });

            await this.Collector.ttapi({
                url: 'machines/move',
                method: 'post',
                data: JSON.stringify(params),
                contentType: 'application/json; charset=utf-8',
            });
            set(this, 'saved', true);
            if (!Ember.testing) {
                return later(
                    this,
                    () => {
                        set(this, 'saved', false);
                        set(this, 'saving', false);
                        return this.transitionToRoute('machines.transactions');
                    },
                    5000,
                );
            }
        },

        selectProject(value) {
            return set(this, 'project', value);
        },

        selectStatus(value) {
            return set(this, 'status', value);
        },

        changeValue(way, value, field) {
            if (way === 'to') {
                field = 'to.' + field;
            }
            if (typeOf(value) === 'instance') {
                value = value.id;
            }
            return set(this, field, value);
        },
    },
});

export default MachinesTransactionProjectController;
