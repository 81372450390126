/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

EmployersEditRoute = GeneralAddRoute.extend({
    modelName: 'employer',
    controllerName: 'employers/edit',
    edit: true,
    //    model: (params) ->
    //        @store.findRecord(@get('modelName'), params.id)
    afterSave: 'employers',
    resetController: function (controller, isExiting, transition) {
        this._super(controller, isExiting, transition);
        if (isExiting) {
            controller.set('tilaajavastuu_report', false);
            return controller.set('message', null);
        }
    },
});

export default EmployersEditRoute;
