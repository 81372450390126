/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/clickelsewhere.coffee.old
// Generated by CoffeeScript 2.6.1
var ClickElsewhereMixin;

import Ember from 'ember';

import $ from 'jquery';

ClickElsewhereMixin = Ember.Mixin.create({
    outsideClickHandler: function (e) {
        var inside;
        inside = $(e.target).closest(this.get('element')).length === 1;
        if (!inside && !this.get('isDestroyed')) {
            this.onClickElsewhere(e);
        }
        // must return true. otherwise some other click actions may fail
        return true;
    },
    onClickElsewhere: function () {},
    clickHandler: null,
    didInsertElement: function () {
        this._super.apply(this, arguments);
        this.set('clickHandler', Ember.run.bind(this, this.outsideClickHandler));
        return $(document).on('click touchend', this.get('clickHandler'));
    },
    willDestroyElement: function () {
        this._super.apply(this, arguments);
        return $(document).off('click touchend', this.get('clickHandler'));
    },
});

export default ClickElsewhereMixin;
