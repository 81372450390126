/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/form-add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

FormsFormAddRoute = GeneralAddRoute.extend({
    controllerName: 'forms/form-add',
    afterSave: Ember.computed('form', function () {
        return {
            route: 'forms.form-report',
            params: this.get('form'),
        };
    }),
    model: function (params) {
        this.set('modelName', params.form.dasherize());
        this.set('form', params.form);
        return this._super(params);
    },
});

export default FormsFormAddRoute;
