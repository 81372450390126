/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/customer/reports/route.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolCustomerReportsRoute;

import Ember from 'ember';

AdmintoolCustomerReportsRoute = Ember.Route.extend({
    model: function () {
        return this.modelFor('admintool.customer');
    },
});

export default AdmintoolCustomerReportsRoute;
