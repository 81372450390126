/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/forms/form/field/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsFormsFormFieldRoute;

import Ember from 'ember';

import ResetScrollMixin from '../../../../../mixins/reset-scroll';

SettingsFormsFormFieldRoute = Ember.Route.extend(ResetScrollMixin, {
    model: function (params) {
        var field, form, worktimegroups;
        form = this.modelFor('settings.forms.form').form;
        field = this.store
            .peekAll('form-field')
            .filterBy('external_type', params.field)
            .filterBy('form.id', form.id);
        if (this.Collector.testNeedsOne('products.worktimegroup')) {
            worktimegroups = this.store.findAll('worktimegroup');
        }
        return new Ember.RSVP.hash({
            settings: this.store.peekAll('form-field-setting'),
            field: Ember.get(field, 'firstObject'),
            worktimegroups: worktimegroups ? worktimegroups : null,
        });
    },
    setupController: function (controller, model) {
        var firstObject, firstOption, groups, options;
        this._super(controller, model);
        if (!Ember.get(model, 'field')) {
            this.transitionTo('settings.forms');
        }
        controller.set('fieldName', Ember.get(model, 'field.translatedName'));
        if (Ember.get(model, 'field.type') === 'database') {
            if (Ember.isEmpty(Ember.get(model, 'field.options'))) {
                controller.set('selectedForm', null);
            } else {
                options = Ember.get(model, 'field.options').split('\n');
                firstOption = options[0];
                firstObject = this.store.peekRecord('form-field', firstOption);
                if (firstObject) {
                    controller.set('selectedForm', firstObject.get('form.external_type'));
                }
            }
        }
        if (model.worktimegroups) {
            groups = [];
            model.worktimegroups.forEach((group) => {
                return groups.push({
                    id: group.id,
                    text: group.name,
                });
            });
            return controller.set('worktimegroups', groups);
        }
    },
});

export default SettingsFormsFormFieldRoute;
