/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxreportComponent;

import Ember from 'ember';

import ccrep from '../../../components/cc-report';

TaxreportComponent = ccrep.extend({
    gui: Ember.inject.service(),
    rowsOnPage: 25,
    errorPage: 1,
    dataPage: 1,
    _error: Ember.computed('error', function () {
        if (
            this.startswith(
                this.get('error_description'),
                "Exception: Contact person must be given in buffer['contact_person']",
            )
        ) {
            return 'contact_missing';
        }
        return this.get('error');
    }),
    startswith: function (str, startsas) {
        if (Ember.typeOf(str) !== 'string') {
            return false;
        }
        return str.substring(0, startsas.length) === startsas;
    },
    validations: Ember.computed('chartData', function () {
        var data;
        this.set('errorPage', 1);
        this.set('dataPage', 1);
        if (!this.get('chartData.validations')) {
            return;
        }
        data = this.get('chartData.validations');
        data.forEach(function (item) {
            item.message = JSON.parse(item.message);
            return (item.message.text = new Ember.String.htmlSafe(item.message.text));
        });
        return data;
    }),
    chartDataOnPage: Ember.computed('dataPage', 'chartData.data', function () {
        if (!this.get('chartData.data')) {
            return;
        }
        return this.get('chartData.data').slice(
            (this.get('dataPage') - 1) * this.get('rowsOnPage'),
            this.get('dataPage') * this.get('rowsOnPage'),
        );
    }),
    validationsOnPage: Ember.computed('errorPage', 'validations', function () {
        if (!this.get('validations')) {
            return;
        }
        return this.get('validations').slice(
            (this.get('errorPage') - 1) * this.get('rowsOnPage'),
            this.get('errorPage') * this.get('rowsOnPage'),
        );
    }),
    datapages: Ember.computed('chartData.data.length', function () {
        return Math.ceil(this.get('chartData.data.length') / this.get('rowsOnPage'));
    }),
    errorpages: Ember.computed('validations.length', function () {
        return Math.ceil(this.get('validations.length') / this.get('rowsOnPage'));
    }),
    tzOffset: Ember.computed.alias('gui.tzOffset'),
});

export default TaxreportComponent;
