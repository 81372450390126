/* eslint-disable ember/named-functions-in-promises */
import { debounce } from '@ember/runloop';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import recordComp from '../../../collector/record-custom-comp/component';
import { copy } from 'ember-copy';
import { isEmpty, isPresent } from '@ember/utils';

export default recordComp.extend({
    session: service(),
    intl: service(),

    layoutName: 'collector/record-comp',

    fields: computed('record', 'settings', {
        set(key, value) {
            this._value = value;
        },
        get() {
            if (this._value) {
                return this._value;
            }
            // Collector service
            const c = this.Collector;
            // Collector form
            const form = 'abcense';
            const record = get(this, 'record');
            if (!record) {
                return;
            }
            const skip = get(this, 'settings.skip');
            const mandatory = get(this, 'settings.mandatory');
            const fields = c.fieldArray(form);
            const retFields = [];
            let fetchmultiday = false;
            let fetchsingleday = false;

            // Fetch form fields with collector service
            fields.forEach((field) => {
                field.features = copy(field.features);
                if (Array.from(skip).includes(field.name)) {
                    return;
                }
                if (Array.from(mandatory).includes(field.name)) {
                    field.features = copy(field.features);
                    field.features.mandatory = true;
                }
                if (field.type === 'database' && get(record, field.name)) {
                    field.value = get(record, field.name + '.id');
                } else {
                    field.value = get(record, field.name);
                }
                if (field.name === 'abcensetype' && get(this, 'multipleDays')) {
                    field.filterOptions = {
                        type: 'both||days||(empty)',
                    };
                } else if (field.name === 'abcensetype' && !get(this, 'multipleDays')) {
                    field.filterOptions = {
                        type: 'both||hours||(empty)',
                    };
                }

                if (get(this, 'multipleDays') && ['startdate', 'enddate'].includes(field.name)) {
                    fetchmultiday = true;
                }

                if (!get(this, 'multipleDays') && field.name === 'startdate') {
                    fetchsingleday = true;
                }

                return retFields.push(copy(field));
            });

            if (fetchmultiday && !get(record, 'id')) {
                this.fetchAbsenceDaysForMultiday();
            }
            if (fetchsingleday && !get(record, 'id')) {
                this.fecthWorkhoursForSingleDay();
            }

            return retFields;
        },
    }),

    actions: {
        setValue(value, field, set) {
            if (set == null) {
                set = true;
            }
            if (
                get(this, 'multipleDays') &&
                ['startdate', 'enddate', 'abcensetype', 'user'].includes(field)
            ) {
                this.fetchAbsenceDaysForMultiday();
            }

            if (!get(this, 'multipleDays') && ['startdate', 'user'].includes(field)) {
                this.fecthWorkhoursForSingleDay();
            }

            return this._super(...arguments);
        },

        save() {
            const intl = get(this, 'intl');
            const record = get(this, 'record');
            let errors = false;
            const fields = get(this, 'fields');
            fields.forEach((field) => {
                if (
                    field.features.mandatory &&
                    (get(record, field.name) == null || get(record, field.name) === '')
                ) {
                    errors = true;
                    get(record, 'errors').add(field.name, intl.t('field.error.mandatory'));
                    return set(this, 'saving', false);
                }
            });

            if (!get(this, 'fields').findBy('name', 'enddate')) {
                set(record, 'enddate', get(record, 'startdate'));
            }

            if (!get(record, 'user')) {
                set(record, 'user', get(this, 'session.currentUser'));
            }

            if (!errors) {
                return this._super(...arguments);
            }
        },
    },

    fetchAbsenceDaysForMultiday() {
        return debounce(this, this._fetchAbsenceDaysForMultiday, 1000);
    },

    _fetchAbsenceDaysForMultiday() {
        if (
            !get(this, 'record.starttime') &&
            isPresent(get(this, 'record.startdate')) &&
            isPresent(get(this, 'record.enddate')) &&
            moment(get(this, 'record.startdate')).isValid() &&
            moment(get(this, 'record.enddate')).isValid()
        ) {
            const country =
                '&country=' +
                get(get(this, 'store').peekAll('company-info'), 'firstObject.country');

            let url =
                'absence/getDays?date=' +
                get(this, 'record.startdate') +
                '_' +
                get(this, 'record.enddate') +
                country;
            url +=
                '&userid=' +
                (get(this, 'record.user')
                    ? get(this, 'record.user.id')
                    : get(this, 'session.currentUser.id'));
            url += !isEmpty(get(this, 'record.abcensetype.id'))
                ? '&absencetype=' + get(this, 'record.abcensetype.id')
                : '';
            return this.Collector.ttapi({
                url,
            }).then((back) => {
                return set(this, 'record.dayamount', back.days);
            });
        }
    },

    fecthWorkhoursForSingleDay() {
        if (
            isPresent(get(this, 'record.startdate')) &&
            moment(get(this, 'record.startdate')).isValid()
        ) {
            return this.Collector.ttapi({
                url:
                    '/workdayCalendar/workingHours?date=' +
                    get(this, 'record.startdate') +
                    '&userid=' +
                    get(this, 'record.user.id') +
                    '&worktimegroup=' +
                    (get(this, 'record.user')
                        ? get(this, 'record.user.worktimegroup.id')
                        : get(this, 'session.currentUser.worktimegroup.id')),
            }).then((back) => {
                return set(this, 'record.absence_hours', back / 60);
            });
        }
    },
});
