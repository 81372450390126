import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
    // computed is needed because button disabled needs to be null when off.. disabled=false still renders the disabled
    isDisabled: computed('disabled', function () {
        if (this.disabled) return true;
        else return null;
    }),
});
