import GeneralReportController from '../../../../controllers/general-report';
import { inject as service } from '@ember/service';
import EmberObject, { set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default GeneralReportController.extend({
    collector: service('collector-service'),
    gui: service(),
    queryParams: ['worktime_page', 'absence_page', 'worktime_limit', 'absence_limit'],
    worktime_page: 1,
    absence_page: 1,
    worktime_limit: 25,
    absence_limit: 25,
    status: 'all',
    aggregatedrows: alias('model'),
    form_status: alias('status'),
    absence_order: alias('absence_settings.sortByColumn'),
    settings: EmberObject.create({
        massedit: true,
        hideExports: true,
        rowSettings: EmberObject.create({
            editRoute: 'timetracker.edit',
            historyRoute: 'timetracker.history',
        }),
    }),
    absence_settings: EmberObject.create({
        massedit: true,
        hideExports: true,
        rowSettings: EmberObject.create({
            editRoute: 'abcenses.edit',
            historyRoute: 'abcenses.history',
        }),
    }),
    actions: {
        setRowsPerPage(form, limit) {
            set(this, form + '_page', 1);
            set(this, form + '_limit', limit);
        },
    },
});
