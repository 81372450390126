/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/list/route.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesListRoute;

import Ember from 'ember';

import config from '../../../config/environment';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

MessagesListRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    ttapi: Ember.inject.service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
    },
    limit: 25,
    model: function (params) {
        var offset, page, url;
        page = params.page;
        offset = (page - 1) * this.get('limit');
        url = '/messages/all';
        url += '?limit=' + this.get('limit') + '&offset=' + offset;
        return new Em.RSVP.Promise((resolve, reject) => {
            return this.get('ttapi')
                .request(url, {
                    type: 'GET',
                })
                .then((data) => {
                    return resolve(data);
                })
                .catch(reject);
        });
    },
    actions: {
        refreshModel: function () {
            return this.refresh();
        },
    },
});

export default MessagesListRoute;
