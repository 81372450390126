/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/general-report-row.coffee.old
// Generated by CoffeeScript 2.6.1
var GeneralReportRowComponent;

import Ember from 'ember';

import collector from '../classes/collector';

import config from '../config/environment';

import { pluralize } from 'ember-inflector';

GeneralReportRowComponent = Ember.Component.extend({
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    analytics: Ember.inject.service(),
    moment: Ember.inject.service(),
    capi: Ember.inject.service(),
    tagName: 'tr',
    classNameBindings: ['showLock'],
    fieldSettings: Em.A({
        report: true,
        title: false,
    }),
    editModal: Ember.computed('form', 'model', function () {
        return [this.get('form'), this.get('model.id')];
    }),
    dynamicEditParams: Ember.computed('form', 'model', function () {
        var fields, params;
        fields = this.get('editRoute.fields');
        params = [this.get('editRoute.route')];
        fields.forEach((field) => {
            var value;
            value = this.get('model.' + field);
            if (Ember.typeOf(value) === 'instance') {
                value = value.get('id');
            }
            return params.push(value);
        });
        return params;
    }),
    historyModal: Ember.computed('form', 'model', function () {
        return [this.get('form'), this.get('model.id'), 'history'];
    }),
    click: function () {
        return this.sendAction('rowAction', this.get('model'));
    },
    showLock: Ember.computed('form', 'canEdit', function () {
        if (this.get('model.row_info.status') === 'locked' && this.get('canEdit') === false) {
            return true;
        } else {
            return false;
        }
    }),
    form: null,
    didInsertElement: function () {
        return this.set('form', this.get('model.constructor.modelName'));
    },
    //    edit: true
    canEdit: Ember.computed('form', 'canRestore', function () {
        var canEdit;
        if (this.get('canRestore')) {
            return false;
        }
        // a bit bubblegum, but this is needed because capi does not have this kind of privilege-thing
        if (
            this.get('settings.disableStatus') &&
            this.get('model.status') === this.get('settings.disableStatus')
        ) {
            return false;
        }
        canEdit = true;
        if (this.get('settings.hideEdit')) {
            canEdit = false;
        } else {
            canEdit =
                collector.getPrivilege(this.get('form'), 'edit') && this.get('model.isEditable');
        }
        return canEdit;
    }),
    canShow: Ember.computed('canEdit', function () {
        if (this.get('canEdit') === false) {
            return !this.get('settings.hideShow');
        }
        return true;
    }),
    canRemove: Ember.computed('form', 'canRestore', function () {
        if (this.get('canRestore')) {
            return false;
        }
        if (!this.get('settings.hideRemove') && !collector.hasErrors(this.get('form'))) {
            return (
                collector.getPrivilege(this.get('form'), 'remove') && this.get('model.isRemovable')
            );
        }
    }),
    canRestore: Ember.computed('form', function () {
        if (this.get('rowStatus') === 'removed' && !collector.hasErrors(this.get('form'))) {
            return true;
        }
    }),
    rowColumns: Ember.on(
        'init',
        Ember.observer('columns.[]', 'model', 'checked.[]', 'edit', function () {
            var col, cols, fields, i, len, ref, self;
            self = this;
            fields = Em.A([]);
            cols = this.get('columns');
            if (!cols) {
                return;
            }
            for (i = 0, len = cols.length; i < len; i++) {
                col = cols[i];
                Ember.set(col, 'obj.ext', col.ext);
                Ember.set(
                    col,
                    'obj.clickable',
                    (ref = col.obj.type) === 'multiselect' || ref === 'databasearray',
                );
                fields.pushObject(col.obj);
            }
            return this.set('fields', fields);
        }),
    ),
    checkedClass: 'unchecked',
    checked: function () {
        var self;
        self = this;
        this.set('isChecked', false);
        this.set('checkedClass', 'unchecked');
        if (!this.get('allChecked')) {
            return;
        }
        return this.get('allChecked').forEach(function (item) {
            if (item.get == null) {
                // shared application items are not ember objects
                item = Ember.Object.create(item);
            }
            if (item.get('id') === self.get('model.id')) {
                self.set('checkedClass', 'checked');
                return self.set('isChecked', true);
            }
        });
    }.observes('allChecked.[]'),
    editRoute: Ember.computed('settings.editRoute', function () {
        var settings;
        settings = this.get('settings');
        if (settings != null) {
            if (settings.get('editRoute')) {
                return settings.get('editRoute');
            }
        }
        return this.get('form') + 's.edit';
    }),
    historyRoute: Ember.computed('settings.historyRoute', function () {
        var settings;
        settings = this.get('settings');
        if (settings != null) {
            if (settings.get('historyRoute')) {
                return settings.get('historyRoute');
            }
        }
        return this.get('form') + 's.history';
    }),
    modified_class: Em.computed('model.row_info.modified', function () {
        var created_days, created_diff, days, diff;
        if (!this.get('model.row_info.modified')) {
            return false;
        }
        diff =
            moment.utc().startOf('day').unix() -
            moment.utc(this.get('model.row_info.modified')).startOf('day').unix();
        days = moment.duration(diff, 'seconds').asDays();
        //return false if edited in the same day
        created_diff =
            moment.utc(this.get('model.row_info.modified')).startOf('day').unix() -
            moment.utc(this.get('model.row_info.created')).startOf('day').unix();
        created_days = moment.duration(created_diff, 'seconds').asDays();
        if (created_days === 0) {
            if (days > 0) {
                return false;
            }
            return 'general-report-modified modified-since-today';
        }
        if (days > 14) {
            return false;
        }
        return 'general-report-modified modified-since-' + days;
    }),
    modified_tooltip_format: Em.computed('modified_class', function () {
        if (this.get('modified_class') === 'general-report-modified modified-since-today') {
            return 'LT';
        } else {
            return 'L';
        }
    }),
    actions: {
        clicked: function (field) {
            var ref;
            if (!((ref = field.type) === 'multiselect' || ref === 'databasearray')) {
                return;
            }
            return this.sendAction('bubbleTransition', {
                queryParams: {
                    modal: ['modalrows', this.get('form') + '.' + field.ext, this.get('model.id')],
                },
            });
        },
        openlock: function (row) {
            var form, form_plural, model;
            model = this.get('model');
            if (!model.get('row_info.privilege_openlock')) {
                return;
            }
            form = this.get('form');
            form_plural = pluralize(this.get('form')).camelize();
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_openlock'))
                .then((con) => {
                    var data;
                    if (con) {
                        data = {};
                        data[form] = {
                            locked: false,
                        };
                        return this.get('capi')
                            .request('/' + form_plural + '/' + row.get('id') + '?validation=off', {
                                dataType: 'json',
                                type: 'PUT',
                                data: JSON.stringify(data),
                            })
                            .then(() => {
                                model.set('export_salary', '');
                                model.set('export_absence', '');
                                model.set('row_info.privilege_edit', true);
                                model.set('row_info.privilege_remove', true);
                                model.set('row_info.status', 'normal');
                                model.set('row_info.modified', true);
                                return model.save();
                            });
                    }
                });
        },
        delete: function (row) {
            if (collector.hasErrors(this.get('form'))) {
                return;
            }
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return row.destroyRecord().then((ret) => {
                            row.unloadRecord();
                            if (this.isDestroyed || this.isDestroying) {
                                return;
                            }
                            return this.sendAction('bubbleAction', 'delete', row);
                        });
                    }
                });
        },
        //        show: ->
        //            self = @
        //            currentController = @get('parentController.currentController')
        //            #controller.set 'model', @get 'model'
        //            currentController.transitionToRoute("show", @get 'model').then (newRoute) ->
        //                if self.get('parentController.currentController.cancelRoute')
        //                    newRoute.controller.set 'cancelRoute', self.get('parentController.currentController.cancelRoute')
        restore: function (row) {
            var form, form_camelcase, form_plural;
            if (collector.hasErrors(this.get('form'))) {
                return;
            }
            form = this.get('form');
            form_plural = pluralize(this.get('form')).camelize();
            form_camelcase = this.get('form').camelize(); // form has to be camelcased if it has underscores
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_restore'))
                .then((con) => {
                    if (con) {
                        return this.get('capi')
                            .request('/' + form_plural + '/' + row.id + '?validation=off', {
                                dataType: 'json',
                                type: 'PUT',
                                data: '{"' + form_camelcase + '":{"row_info.status":"normal"}}',
                            })
                            .then(() => {
                                return row.unloadRecord();
                            });
                    }
                });
        },
        save: function () {
            var self;
            self = this;
            if (collector.hasErrors(this.get('form'))) {
                this.set('edit', false);
                this.get('model').rollbackAttributes();
                return;
            }
            return this.get('model')
                .save()
                .then(function () {
                    return self.set('edit', false);
                });
        },
        check: function () {
            if (this.get('isChecked')) {
                this.set('checkedClass', 'unchecked');
                return this.get('allChecked').removeObject(this.get('model'));
            } else {
                this.set('checkedClass', 'checked');
                return this.get('allChecked').pushObject(this.get('model'));
            }
        },
        clickRowAction: function (type) {
            return this.get('analytics').trackEvent({
                category: this.form + ' report row actions',
                action: type,
                label: 'General-report',
            });
        },
    },
});

export default GeneralReportRowComponent;
