/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/databasefield-options.coffee.old
// Generated by CoffeeScript 2.6.1
var formatOptionsHelper;

import Ember from 'ember';

formatOptionsHelper = Ember.Helper.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    compute: function (params, hash) {
        var firstObject, form, names, options;
        options = params[0];
        if (Ember.isEmpty(options)) {
            return '';
        }
        options = options.split('\n');
        if (options.length < 1) {
            return '';
        }
        firstObject = this.get('store').peekRecord('form-field', options[0]);
        if (!firstObject) {
            return '??';
        }
        form = firstObject.get('form');
        names = options.map((option) => {
            var fieldRecord, name;
            fieldRecord = this.get('store').peekRecord('form-field', option);
            if (!fieldRecord) {
                return 'notfound';
            }
            if (fieldRecord.get('name') === '$lang(10)') {
                name = this.get('intl').t(
                    form.get('external_type') + '.' + fieldRecord.get('external_type'),
                );
                if (name.string != null) {
                    name = name.string;
                }
            } else {
                name = fieldRecord.get('name');
            }
            // only truncate if name is longer than 20 characters
            if (name.length >= 20) {
                return name.substring(0, 20) + '...';
            } else {
                return name;
            }
        });
        return names.join(',');
    },
});

export default formatOptionsHelper;
