import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

/*
    Give this component:
        name(String), this will be used to determine the translation keys for the labels (name + ".on" / name + ".off")
                    -if no name is give/found, will fallback to general "general.on" and "general.off" translation key
                    -this will also become the x-toggle's input's name attribute
        showLabels(bool) whether labels (on/off text) will be show
        disabled(bool) whether the field is toggleable
        theme(String) One of 'light', 'ios', 'flat', 'flip', 'skewed', 'default'.
        size(String) One of 'small', 'medium', 'large'.

    some defaults are set below but you can override them by giving them to this component separetely
*/
export default Component.extend({
    intl: service(),

    showLabels: true,
    theme: 'material',
    size: 'large',

    onLabel: computed(function () {
        return get(this, 'form') + '.' + get(this, 'name') + '.on';
    }),

    offLabel: computed(function () {
        return get(this, 'form') + '.' + get(this, 'name') + '.off';
    }),

    fallbackOnLabel: computed('intl.primaryLocale', function () {
        if (this.getLocale() === 'xy') {
            return get(this, 'onLabel');
        } else {
            return 'general.yes';
        }
    }),

    fallbackOffLabel: computed('intl.primaryLocale', function () {
        if (this.getLocale() === 'xy') {
            return get(this, 'offLabel');
        } else {
            return 'general.no';
        }
    }),

    getLocale() {
        let intl = get(this, 'intl');
        return get(intl, 'primaryLocale');
    },
});
