import { allSettled } from 'rsvp';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import Route from '@ember/routing/route';
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import config from '../config';
import { get, set } from '@ember/object';

export default Route.extend(ApplicationRouteMixin, {
    analytics: service(),
    initapp: service(),
    session: service(),
    tools: service(),
    loadTranslations: service(),
    Collector: service('collector-service'),
    moment: service(),
    intl: service(),
    gps: service(),
    cordova: service('own-cordova-service'),
    customerSettings: service(),
    PushNotification: service(),
    localStorageWrapper: service(),
    router: service(),
    metaService: service('meta'),

    queryParams: {
        modal: {
            replace: true,
        },
    },

    alldone: false,

    init() {
        this._super(...arguments);

        //set last used page to ls on transition
        this.router.on('routeWillChange', (transition) => {
            if (!get(this, 'session.currentUser.id')) return;
            let { to: toRouteInfo } = transition;
            if (toRouteInfo) {
                if (!get(this, 'session.currentUser.id')) return;
                let page = toRouteInfo.name;
                if (Object.keys(toRouteInfo.params).length > 0) {
                    page = { page: page, param: Object.values(toRouteInfo.params).firstObject };
                }
                if (page && config.possibleFirstPages.indexOf(toRouteInfo.name) !== -1) {
                    get(this, 'localStorageWrapper').setUserSetting('last_page', page);
                }
                if (transition.targetName == 'forms.form-report') {
                    const self = this;
                    transition.finally(function () {
                        get(self, 'controller').notifyPropertyChange('path');
                    });
                }
            }
        });

        this.router.on('routeDidChange', () => {
            if (this.router.currentRouteName) {
                // Current route is redirecting from index and will be null on first load
                if (this.router.currentRouteName === 'login') {
                    // Translate the meta title and description in login route
                    get(this, 'metaService').translateMetaTags();
                } else {
                    // Overwrite Meta Description to empty and set title to brand name
                    get(this, 'metaService').updateDescription();
                    get(this, 'metaService').updateTitle();
                }
            }
        });
    },

    beforeModel: function (transition) {
        let cordova = get(this, 'cordova').setup();

        if (!Modernizr.localstorage) {
            this.transitionTo('error');
        }

        if (get(this, 'alldone') === false) {
            transition.abort();

            get(this, 'initapp')
                .start()
                .then(() => this._returnAllSettled(transition, cordova))
                .then(() => this._setThings(transition));
        }
    },

    afterModel: function () {
        if (document.querySelector('#first-time-loader') !== null) {
            document.querySelector('#first-time-loader').remove();
        }
        return set(this, 'intl.locale', moment.locale());
    },

    actions: {
        changeLanguage: function (lang) {
            var self = this;
            moment.locale(lang);
            localStorage['lang'] = lang;
            return get(this, 'loadTranslations')
                .fetch()
                .then(() => this._setLang(lang, self));
        },
        showModal: function (name, model, variable) {
            return this.render(name, {
                into: 'application',
                outlet: 'modal',
                model: {
                    object: model,
                    variable: variable,
                },
            });
        },
        removeModal: function () {
            return run(() =>
                this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application',
                }),
            );
        },
        bubbleTransition: function (to, params) {
            this.transitionTo(to, params);
        },
    },

    save_first_time_loader_text: function (firstname, lastname) {
        // while we are here let's save greetings for loading page

        let name = firstname || lastname;

        let str = get(this, 'intl').t('general.welcome');
        let aphorisms = get(this, 'intl').t('general.aphorisms');
        if (!str || !aphorisms || !name) {
            return;
        }
        let greeting_arr = str.split('\n');
        let aphorisms_arr = aphorisms.split('\n');

        localStorage['first-time-loader-greeting'] = JSON.stringify({
            greetings: greeting_arr,
            name: name,
            aphorism: aphorisms_arr,
        });
    },

    getUserServices: function () {
        let self = this;
        let c = get(this, 'Collector');
        let promise = c.ajax({
            url: 'me?linkings',
            headers: {
                Authorization: 'Bearer ' + get(self, 'session.session.authenticated.access_token'),
            },
        });
        promise.then(function (data) {
            set(self, 'session.currentUser.services', data);
        });
        return promise;
    },

    sessionInvalidated: function () {
        if (get(this, 'router.currentRouteName') === 'register.user.confirm') {
            this.transitionTo('register.user.sign-in');
        } else {
            this._super();
        }
    },
    sessionAuthenticated: function () {
        if (get(this, 'router.currentRouteName') === 'register.user.sign-in') {
            this.transitionTo('register.user.confirm');
        } else if (get(this, 'session.modelscreated')) {
            this._super();
        } else {
            this.transitionTo('index');
        }
    },

    _returnAllSettled(transition, cordova) {
        this._super(transition);

        return allSettled([cordova]);
    },
    _setThings(transition) {
        get(this, 'tools').initCurrentMySite();
        set(this, 'alldone', true);
        set(this, 'intl.locale', moment.locale());

        if (get(this, 'session.session.authenticated.access_token')) {
            this.save_first_time_loader_text(
                get(this, 'session.currentUser.firstname'),
                get(this, 'session.currentUser.lastname'),
            );
            //get(this, "initapp").firstPageTransition();
        }
        //else
        transition.retry();
    },
    _setLang(lang, self) {
        return set(get(self, 'intl'), 'locale', lang);
    },
});

//export default ApplicationRoute;
