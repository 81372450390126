import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';
import { later, next } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import fetch from 'fetch';
import { save_record } from 'tt4/ember-movenium/utils/save-the-children';

export default class DashBoardWidgetsTimecardComponent extends Component {
    @service store;
    @service session;
    @service evented;
    @service('collector-service') collector;
    @service() notifications;
    @service intl;
    @service error;
    @service gps;
    @service dialogs;
    @service router;
    @service localstorage;
    @service flags;
    @service analytics;

    @tracked worktimes;
    @tracked openWorktime;
    @tracked newWorktime;
    @tracked showNext;
    @tracked promise;
    @tracked showNext;
    @tracked nextContainerOnTop;
    @tracked siteTooFar;
    @tracked descriptionTooLong;
    @tracked dontShowPopupAgain;
    @tracked useNewVersion;
    @tracked popupText;
    @tracked showEdit;

    get styleNamespace() {
        return podNames['dash-board/widgets/timecard'];
    }

    get workHoursToday() {
        if (!this.worktimes) return '';
        return this.worktimes.reduce((total, worktime) => total + (worktime.work_hours || 0), 0);
    }

    get started() {
        if (!this.worktimes) return '';
        if (this.worktimes.length === 0) return '';
        let min = '24:00';
        this.worktimes.forEach((worktime) => {
            if (worktime.starttime < min) min = worktime.starttime;
        });
        return min;
    }

    get timer() {
        if (!this.openWorktime) return null;

        // on first minute seconds should start from current seconds so counter starts from 00:00:00
        const seconds =
            this.openWorktime.starttime === moment().format('HH:mm') ? moment().format('ss') : '00';
        return (
            moment(this.openWorktime.date + ' ' + this.openWorktime.starttime).format(
                'YYYY-MM-DD HH:mm:',
            ) + seconds
        );
    }

    // select fields that are shown on timecard start
    // filter fields that are alwaysVisible or mandatory but not in newerShow
    // return as string ie "field1,field2"
    get showFieldsOnStart() {
        const alwaysVisible = [
            'project',
            'task',
            'subtask',
            'subproject',
            'affects_overtime',
            'salarytype',
        ];
        const neverShow = ['user', 'date', 'starttime', 'endtime', 'location'];
        const fields = this.collector.fieldArray('worktime').filter((field) => {
            return (
                alwaysVisible.includes(field.name) ||
                (field.features.mandatory && !neverShow.includes(field.name))
            );
        });

        return fields.mapBy('name').join(',');
    }

    get showFieldsOnEdit() {
        const neverShow = ['user', 'date', 'starttime', 'endtime', 'location'];
        const fields = this.collector.fieldArray('worktime').filter((field) => {
            return !neverShow.includes(field.name);
        });

        return fields.mapBy('name').join(',');
    }

    constructor() {
        super(...arguments);
        this.betaInfo = this.store.findAll('beta-clockcard');
        this.initBetaToggle();
        this.setPopupText();
        this.form = 'worktime';

        if (this.collector.testNeedsOne('products.gps_force')) this.siteTooFar = true;
        this.getWorktimes();

        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumeEvent');
        this.gps.on('lockedPositionChanged', this, 'checkIfSiteTooFar');

        this.router.on('routeWillChange', (transition) => {
            if (!transition.to.find((route) => route.name === this.routeName)) {
                this.newWorktime?.rollbackAttributes();
                this.openWorktime?.rollbackAttributes();
            }
        });
    }

    willDestroy() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumeEvent');
        this.gps.off('lockedPositionChanged', this, 'checkIfSiteTooFar');
    }

    @action
    async start() {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Start worktime',
            label: 'Timecard widget',
        });
        const worktime = this.newWorktime?.isDeleted
            ? this.collector.copyRecord(this.newWorktime)
            : this.newWorktime;
        if (!worktime.date) worktime.date = moment().format('YYYY-MM-DD');
        if (!worktime.user) worktime.user = this.session.currentUser;

        worktime.starttime = moment().format('HH:mm');
        if ('location' in worktime) worktime.location = await this.getLocation();
        await worktime.validate();

        if (!worktime.isValid) return;

        // this.setDefaultPause(worktime)
        this.openWorktime = worktime;
        this.newWorktime = worktime;

        this.updateWorktimes();

        try {
            await save_record(worktime, this);
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            this.evented.storeEvent('insert', 'worktime', this.openWorktime);
            if (this.args.saveAction) this.args.saveAction();
        } catch (error) {
            this.dialogs.alert(await this.error.toStringAsyncHTML(error));
            worktime.starttime = null;
            this.newWorktime = worktime;
            this.openWorktime = null;
        }
    }

    @action
    async stop() {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Stop worktime',
            label: 'Timecard widget',
        });

        if (this.siteTooFar) return;

        const worktime = this.openWorktime;
        await worktime.validate();

        if (!worktime.isValid) return;

        worktime.endtime = moment().format('HH:mm');
        if ('end_location' in worktime) worktime.end_location = await this.getLocation();

        this.openWorktime = null;
        this.updateWorktimes();

        try {
            await worktime.save();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            this.evented.storeEvent('insert', 'worktime', this.openWorktime);
            if (this.args.saveAction) this.args.saveAction();
        } catch (error) {
            this.error.notify(error);
            worktime.endtime = null;
            this.openWorktime = worktime;
        }
    }

    @action
    async startNext() {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Start next worktime',
            label: 'Timecard widget',
        });

        if (this.siteTooFar) return;

        const oldWorktime = this.openWorktime;
        const newWorktime = this.newWorktime;

        await oldWorktime.validate();
        await newWorktime.validate();

        if (!oldWorktime.isValid) return;
        if (!newWorktime.isValid) return;

        oldWorktime.endtime = moment().format('HH:mm');
        if ('end_location' in oldWorktime) oldWorktime.end_location = await this.getLocation();
        newWorktime.starttime = moment().format('HH:mm');

        // hax to force render form again so new values of new record would be updated to fields... this wouldn't be needed
        // if fields would support ddau better .. when ddau works change to one line "this.openWorktime = newWorktime"
        this.openWorktime = null;
        next(this, () => {
            this.openWorktime = newWorktime;
        });

        this.newWorktime = this.createNewWorktime();

        // hide next worktime component
        this.closeNext();

        this.updateWorktimes();

        try {
            await oldWorktime.save();
            await newWorktime.save();

            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            this.evented.storeEvent('insert', 'worktime', oldWorktime);
        } catch (error) {
            this.error.notify(error);
            newWorktime.starttime = null;
            oldWorktime.endtime = null;
            this.newWorktime = newWorktime;
            this.openWorktime = oldWorktime;

            this.openNext(0);
        }
    }

    @action
    openNext(time = 301) {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Open next worktime view',
            label: 'Timecard widget',
        });

        this.showNext = true;

        // animation takes 300ms so after that we change nextContainerOnTop to true witch makes removes stop div to hidden
        // and makes startnew div to not absolute so it will change the size of the parent div
        later(
            this,
            () => {
                this.nextContainerOnTop = true;
            },
            time,
        );
    }

    @action
    async changeVersion() {
        let record = this.betaInfo?.firstObject;
        if (!record)
            await this.store
                .createRecord('beta-clockcard', { toggled: !this.useNewVersion })
                .save();
        else {
            record.toggled = !this.useNewVersion;
            await record.save();
        }
        this.localstorage.setItem('betaClockcard', !this.useNewVersion);
        this.useNewVersion = !this.useNewVersion;
        this.dontShowPopupAgain = true;
        this.analytics.trackEvent({
            category: 'Clockcard 25',
            action: this.useNewVersion ? 'on' : 'off',
            label: 'clockcard-25',
        });
    }

    @action
    closeNext() {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Close next worktime view',
            label: 'Timecard widget',
        });

        this.showNext = false;
        this.nextContainerOnTop = false;
    }

    @action
    showEditView() {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Open edit worktime view',
            label: 'Timecard widget',
        });
        this.showEdit = true;
    }

    @action onFieldChange(type, field, value) {
        if (type === 'open') this.openWorktime[field] = value;
        else if (type === 'new') this.newWorktime[field] = value;
        if (field === 'description') {
            this.descriptionTooLong =
                value.length > 256 ? this.intl.t('validation.too_long_error') : null;
        }
    }

    createNewWorktime() {
        let defaults = { date: moment().format('YYYY-MM-DD'), user: this.session.currentUser };
        if (this.collector.fieldExists('worktime', 'affects_overtime'))
            defaults.affects_overtime = 'off';
        return this.store.createRecord('worktime', defaults);
    }

    @action
    async declineBeta() {
        if (!this.betaInfo?.firstObject)
            await this.store.createRecord('beta-clockcard', { toggled: false }).save();
        this.dontShowPopupAgain = true;
    }

    onStoreEvent(params) {
        if (params.type === 'worktime') this.getWorktimes();
    }

    onResumeEvent(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        this.getWorktimes();
    }

    async getWorktimes() {
        this.promise = this.store.query('worktime', {
            date:
                moment().subtract(1, 'day').format('YYYY-MM-DD') +
                '_' +
                moment().format('YYYY-MM-DD'),
            user: this.session.currentUser.id,
            sideload: true,
        });
        const worktimes = await this.promise;

        this.updateWorktimes();

        const openWorktime = worktimes.find((worktime) => {
            return worktime.starttime && !worktime.endtime;
        });

        if (openWorktime) {
            this.setDefaultPause(openWorktime);
            this.openWorktime = openWorktime;
            this.checkIfSiteTooFar();
        } else this.openWorktime = null;
    }

    updateWorktimes() {
        this.worktimes = this.store.peekAll('worktime').filter((worktime) => {
            return (
                worktime.date === moment().format('YYYY-MM-DD') &&
                worktime.user == this.session.currentUser
            );
        });

        const records = this.store.peekAll('worktime').filterBy('isNew', true);
        if (records.length > 0) {
            this.newWorktime = records.firstObject;
            // when reusing unsaved worktime .. date must always be today so play starts worktime for today
            this.newWorktime.date = moment().format('YYYY-MM-DD');
            if (
                this.collector.fieldExists('worktime', 'affects_overtime') &&
                !this.newWorktime.affects_overtime
            )
                this.newWorktime.affects_overtime = 'off';
        } else {
            this.newWorktime = this.createNewWorktime();
        }
        this.checkIfSiteTooFar();
    }

    // check if pause is on .. and set pause for given worktime
    setDefaultPause(worktime) {
        const pauseField = this.collector
            .fieldArray('worktime')
            .find((item) => item.name === 'pause');
        let pause = null;
        // support for worktime-groups
        if (pauseField) {
            if (typeof get(pauseField, 'features.default_value') == 'object')
                pause = get(pauseField, 'features.default_value.value');
            else pause = get(pauseField, 'features.default_value');
        }

        if ((!worktime.pause || worktime.pause == null) && pause) worktime.pause = pause;
        // Need this hax also here because it overwrites somehow default pause in new clockcard 😩
        if (worktime.multipause?.length === 0) worktime.pause = null;
    }

    async getLocation() {
        if (this.gps.status != 'located') return null;

        let location = {
            latitude: await this.gps.lockedLatitude,
            longitude: await this.gps.lockedLongitude,
            accuracy: await this.gps.lockedAccuracy,
        };

        return JSON.stringify(location);
    }

    async checkIfSiteTooFar() {
        if (!this.collector.fieldExists('project', 'force_location'))
            return (this.siteTooFar = false);

        if (!this.openWorktime) return (this.siteTooFar = true);

        if (this.openWorktime.project.force_location == undefined)
            await this.store.findRecord('project', this.openWorktime.project.id, { reload: true });
        if (!this.openWorktime.project.force_location) return (this.siteTooFar = false);

        if (!this.gps.getLockedPosition) return (this.siteTooFar = true);

        let projectLocation = JSON.parse(this.openWorktime.project.location_map);
        if (
            !this.gps.withinAcceptedDistance(
                { lat: projectLocation.latitude, lon: projectLocation.longitude },
                {
                    lat: this.gps.getLockedPosition.latitude,
                    lon: this.gps.getLockedPosition.longitude,
                },
            )
        )
            return (this.siteTooFar = true);

        this.siteTooFar = false;
    }

    async setPopupText() {
        const key = 'clockcard-beta-info-' + moment.locale();
        const fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;

        let response = await fetch(fileUrl, { dataType: 'text' });
        if (!response.ok) return (this.popupText = `cannot find help for ${key}`);

        this.popupText = htmlSafe(await response.text());
    }

    async initBetaToggle() {
        if (this.flags.test('clockcard-25')) {
            this.useNewVersion = true;
            return (this.dontShowPopupAgain = true);
        } else if (this.localstorage.getItem('betaClockcard')) {
            this.dontShowPopupAgain = true;
            return (this.useNewVersion = this.localstorage.getItem('betaClockcard'));
        }
        const betaValues = await this.betaInfo;
        const savedValues = betaValues?.firstObject;
        if (savedValues) {
            this.dontShowPopupAgain = true;
            this.useNewVersion = savedValues.toggled;
        } else {
            this.dontShowPopupAgain = false;
            this.useNewVersion = false;
        }
    }
}
