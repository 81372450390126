import Component from '@ember/component';
import { set, get } from '@ember/object';

export default Component.extend({
    actions: {
        parentChanged(value) {
            const userid = get(this, 'user');
            if (!value) {
                set(this, 'parents.' + userid, null);
            } else {
                set(this, 'parents.' + userid, value);
            }
            this.parentSelected(userid, value);
        },
    },
});
