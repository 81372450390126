import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    session: service(),

    numberCheckPattern:
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/i,
    isInvalid: false,
    canBeEmpty: true,

    init() {
        this._super();
        if (this.session.currentUser.company_info.country === 'fi') this.send('setValue', '+358');
        if (this.session.currentUser.company_info.country === 'se') this.send('setValue', '+46');
    },

    actions: {
        validate(number) {
            let isValid = false;
            if (!number && get(this, 'canBeEmpty')) {
                set(this, 'validation_error', false);
                set(this, 'isInvalid', false);
                isValid = true;
            } else if (number) {
                if (this.numberCheckPattern.test(number)) {
                    set(this, 'validation_error', false);
                    set(this, 'isInvalid', false);
                    isValid = true;
                } else {
                    set(this, 'validation_error', true);
                    set(this, 'isInvalid', true);
                }
            }
            if (this.onValidate) {
                this.onValidate(isValid);
            }
        },
        setCountryCode(code) {
            set(this, 'value', code);
        },

        setValue(value) {
            let newValue = value;

            // If the number already has a country code, just change the country code
            if (this.numberCheckPattern.test(this.value))
                newValue = this.value.replace(
                    '+' + this.value.match(this.numberCheckPattern)[1],
                    value,
                );

            // if the number is set without a country code, add the country code to the beginning of the number
            if (this.value && !this.value.includes('+')) newValue = value + ' ' + this.value;

            set(this, 'value', newValue);
            this.send('validate', newValue);
        },
    },
});
