/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/export-modal/component.coffee.old
// Generated by CoffeeScript 2.6.1
var EmailSendModalComponent;

import Ember from 'ember';

import pdfmixin from '../../../mixins/worksitediary_pdf';

import { inject as service } from '@ember/service';

import { computed } from '@ember/object';

import { pluralize } from 'ember-inflector';

EmailSendModalComponent = Ember.Component.extend(pdfmixin, {
    analytics: Ember.inject.service(),
    Collector: service('collector-service'),
    localStorageWrapper: service(),
    intl: service(),
    store: service(),
    dialogs: service(),
    showApprovalLinkSelection: false,
    emailSent: false,
    count: 0,
    type: 'export_pdf',
    imgLocation: 'thumbnails',
    layoutOption: 'topHeaders',
    orientation: 'landscape',
    init: function () {
        var form;
        this._super();
        form = this.get('form');
        if (this.get('initType') === 'email') {
            this.set('modalTitle', this.get('intl').t('general.send_as_email'));
            this.set('includeEmail', true);
        } else {
            this.set('modalTitle', this.get('intl').t('export.modal.title'));
            this.set('type', this.get('initType'));
        }
        this.set('initType', null);
        this.set('includeApprovalLink', false);
        if (this.get('showApprovalLinkSelection')) {
            this.set('includeApprovalLink', true);
        }
        //@set 'emailReportModalText', false
        if (this.get('showApprovalLinkSelection')) {
            this.set(
                'emailContent',
                this.get('intl').t('general.email_content_start') +
                    ' ' +
                    this.get('intl').t('menu.' + form) +
                    '-' +
                    this.get('intl').t('general.email_content_end', {
                        sender:
                            this.get('session.currentUser.firstname') +
                            ' ' +
                            this.get('session.currentUser.lastname'),
                    }),
            );
        } else {
            this.set('emailContent', '');
        }
        this.set(
            'emailTitle',
            this.get('intl').t('menu.' + pluralize(form).camelize()) +
                '-' +
                this.get('intl').t('general.report'),
        );
        this.set('showEmailModal', true);
        if (!this.get('pdf_exports')) {
            this.set('pdf_exports', [
                {
                    id: 'export_pdf',
                    name: this.get('intl').t('report.actions.export_pdf'),
                },
                {
                    id: 'export_pdf_full',
                    name: this.get('intl').t('report.actions.export_pdf_full'),
                },
                {
                    id: 'export_excel',
                    name: this.get('intl').t('report.actions.export_excel'),
                },
            ]);
        }
        if (this.get('pdf_exports.length') === 1) {
            this.set('type', this.get('pdf_exports')[0]['id']);
        }
        this.set('imgLocations', [
            {
                id: 'showImages',
                name: this.get('intl').t('report.actions.include_images'),
            },
            {
                id: 'afterReport',
                name: this.get('intl').t('report.actions.include_images_after_report'),
            },
            {
                id: 'thumbnails',
                name: this.get('intl').t('report.actions.include_thumbnails'),
            },
            {
                id: 'hideImages',
                name: this.get('intl').t('report.actions.exclude_images'),
            },
        ]);
        return this.set('layoutOptions', [
            {
                id: 'topHeaders',
                name: this.get('intl').t('report.actions.pdf.headers_on_top'),
            },
            {
                id: 'sideHeaders',
                name: this.get('intl').t('report.actions.pdf.headers_on_side'),
            },
        ]);
    },
    validateEmail: function (email) {
        return !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
            email,
        );
    },
    hideTemplates: Ember.computed('form', 'type', function () {
        return this.get('type') === 'export_pdf_full' && ['diary'].includes(this.get('form'));
    }),
    refreshRoute: function () {
        var controller, route;
        controller = Ember.getOwner(this).lookup('controller:application');
        route = Ember.getOwner(this).lookup('route:' + Ember.get(controller, 'currentRouteName'));
        return route.refresh();
    },
    actions: {
        export: function () {
            return this.sendAction('exportActions', {
                type: this.get('type'),
                template: this.get('currentTemplate'),
                signature: this.get('signature'),
                dateAndPlace: this.get('datePlace'),
                statusInfo: this.get('showStatusinfo'),
                extraParams: this.get('extraData'),
                imgLocation: this.get('imgLocation'),
                layoutOption: this.get('type') === 'export_pdf' ? this.get('layoutOption') : null,
                orientation: this.get('orientation'),
                addSumRow: this.get('addSumRow'),
            });
        },
        sendEmail: function (title, content) {
            var recipients, useraddr, validRecipients;
            recipients = this.get('recipients');
            validRecipients = [];
            useraddr = this.get('session.currentUser.email');
            if (this.get('sendToMe')) {
                if (this.validateEmail(useraddr)) {
                    this.get('dialogs').alert(this.get('intl').t('email.sending.own_email_error'));
                } else {
                    if (recipients) {
                        recipients.push({
                            error: false,
                            tag: useraddr,
                        });
                    } else {
                        recipients = [
                            {
                                error: false,
                                tag: useraddr,
                            },
                        ];
                    }
                }
            }
            if (recipients) {
                // check that all emails are valid
                recipients.forEach((recipient, index) => {
                    if (!recipient.error) {
                        return validRecipients.push(recipient.tag);
                    }
                });
            } else {
                this.set('email_error', null);
                return;
            }
            Ember.get(this, 'analytics').trackEvent({
                category: 'Export-modal',
                action: 'Send email',
            });
            //Handle actual email sending from where the action was initiated (need to fetch pdf)
            return this.sendAction('sendEmailAction', validRecipients, title, content, {
                type: this.get('type'),
                template: this.get('currentTemplate'),
                signature: this.get('signature'),
                dateAndPlace: this.get('datePlace'),
                statusInfo: this.get('showStatusInfo'),
                approvalLink: this.get('includeApprovalLink'),
                orientation: this.get('orientation'),
                imgLocation: this.get('imgLocation'),
            });
        },
        recipientsChanged: function (recipients) {
            return this.set('recipients', recipients);
        },
        removeModal: function () {
            this.set('emailSent', false);
            this.set('action', null);
            this.set('showEmailModal', false);
            this.sendAction('removeModal');
            return this.refreshRoute();
        },
    },
});

export default EmailSendModalComponent;
