import Component from '@ember/component';

export default Component.extend({
    tagName: '',
    actions: {
        removeSetting(setting) {
            setting.deleteRecord();
        },
    },
});
