/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/events/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import localization from '../../../classes/localization';

WorksitediaryIndexController = GeneralReportController.extend({
    form: 'worksitediary',
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: 'worksitediary.edit',
            historyRoute: 'worksitediary.history',
        }),
        sortByColumn: 'date-',
        fullPdfSettings: {
            insertPageBreak: true,
            rotateImages: true,
        },
    }),
    showColumns: ['date', 'project', 'title', 'description', 'images', 'rating', 'weather'],
    queryParams: ['project', 'date', 'page', 'status'],
    status: 'all',
    date: moment().subtract(1, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    filters: Em.A([
        Em.Object.create({
            field: 'date',
        }),
        Em.Object.create({
            field: 'project',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
    },
});

export default WorksitediaryIndexController;
