import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NovaSettingsComponent extends Component {
    @service store;
    @service('collector-service') Collector;
    @service ttapi;

    @tracked nova_url;
    @tracked nova_hash;
    @tracked customerIntegration;
    @tracked materialIntegration;
    @tracked taskIntegration;
    @tracked projectIntegration;
    @tracked costcenter;
    @tracked isLoading;

    constructor() {
        super(...arguments);
        this.getSettings();
    }

    @action
    save() {
        this.store.peekAll('integrationsetting').save();
        // Add worktimes and absences nova integration to ccbutton separately
        this.ttapi.request('/api/addCustomIntegrationsToCcbutton', {
            method: 'POST',
            data: {
                key: '339783c5-df53-4fea-bfb4-e089d12775a6',
                tag: 'worktimesAndAbsences',
            },
        });
        this.args.close();
    }

    @action
    checked(item) {
        item.value = item.value == 'off' ? 'on' : 'off';

        if (item.key == 'costcenter') {
            item.value == 'on'
                ? this.Collector.addProduct('nova_costcenter')
                : this.Collector.removeProduct('nova_costcenter');
        }
    }

    async getSettings() {
        this.isLoading = true;
        let settings = await this.store.query('integrationsetting', {
            integration: 'nova_web_service',
        });

        this.nova_url = this.setRecord(settings, 'nova_url', null);
        this.nova_hash = this.setRecord(settings, 'nova_hash', null);
        this.customerIntegration = this.setRecord(settings, 'customerIntegration', 'off');
        this.materialIntegration = this.setRecord(settings, 'materialIntegration', 'off');
        this.taskIntegration = this.setRecord(settings, 'taskIntegration', 'off');
        this.projectIntegration = this.setRecord(settings, 'projectIntegration', 'off');
        this.costcenter = this.setRecord(settings, 'costcenter', 'off');
        this.isLoading = false;
    }

    setRecord(settings, key, value) {
        let record = settings.findBy('key', key);
        return record
            ? record
            : this.store.createRecord('integrationsetting', {
                  integration: 'nova_web_service',
                  key: key,
                  value: value,
              });
    }
}
