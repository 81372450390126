/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/linked-users/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsLinkedUsersController;

import Ember from 'ember';

ProjectsLinkedUsersController = Ember.Controller.extend({
    Collector: Em.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    intl: Em.inject.service(),
    applicationController: Ember.inject.controller('application'),
    rowActions: [
        {
            action: 'editRow',
            icon: 'move move-Pencil',
        },
    ],
    updatePath: function (model) {
        return this.set('path', 'linked_users_for?to=' + model.get('id'));
    },
    rows: 0,
    //init: ->
    //    @getRows()

    // these are copied from users/linked, but left here so that if someday we need to add filters etc to this controller, there is already some stuff ready
    /*
  getRows: ->
      url = @get('path')
      query = @get 'filterValues'

      if query
          query_str_arr = []
          $.each query, (key,item) =>
              return if not item.value
              query_str_arr.push item.name + "=" + item.value
          if query_str_arr.length == 0
              @set 'rows', 0
          url += "?" + query_str_arr.join("&")

      url += "&limit=1&out=(empty)"
      c = @get 'Collector'
      promise = c.ttapi
          url: url

      promise.then (data) =>
          @set 'rows', data.count

  */
    actions: {
        editRow: function (params) {
            return this.transitionToRoute('users.edit', params.id).then(function (newRoute) {
                return newRoute.controller.set('afterSave', 'projects.index');
            });
        },
        removeRow: function (params) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return this.get('store')
                            .findRecord('user', params.id)
                            .then((record) => {
                                return record.destroyRecord();
                            });
                    }
                });
        },
    },
});

export default ProjectsLinkedUsersController;
