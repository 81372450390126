import GeneralReportController from '../../../controllers/general-report';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { alias } from '@ember/object/computed';

export default GeneralReportController.extend({
    intl: service(),
    Collector: service('collector-service'),
    exportFile: service('export-file'),
    form: 'firecard',
    employer: null,
    user: null,

    init() {
        this._super(...arguments);
        this.showColumns = ['user_employer', 'user'];
        this.queryParams = ['page', 'user_employer', 'user'];
        return this.addPdfGenerate();
    },

    content: alias('model'),
    filters: A([EmberObject.create({ field: 'user_employer' })]),
    settings: EmberObject.create({
        rowActions: [],
        massedit: true,
    }),

    addPdfGenerate() {
        return this.settings.rowActions.push({
            text: this.intl.t('firecard.export_firecard_pdf'),
            action: async (self, items) => {
                const rowids = items.mapBy('id');

                const response = await this.Collector.ttapi({
                    url: 'pdfGenerate/firecard',
                    data: {
                        rowids,
                    },
                    method: 'post',
                    dataType: 'text',
                });

                return this.exportFile.exportFile(response, 'export_firecard', 'pdf', true);
            },
        });
    },
});
