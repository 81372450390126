/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/register/service.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterServiceController;

import Ember from 'ember';

import config from '../../config/environment';

import initmixin from 'tt4/mixins/init-controller';

RegisterServiceController = Ember.Controller.extend(initmixin, {
    Collector: Ember.inject.service('collector-service'),
    adddetailsController: Ember.inject.controller('register/service/adddetails'),
    productsController: Ember.inject.controller('register/service/products'),
    applicationController: Ember.inject.controller('application'),
    doneController: Ember.inject.controller('register/service/done'),
    registerController: Ember.inject.controller('register'),
    admintool: Ember.inject.service(),
    intl: Ember.inject.service(),
    cdn: Ember.inject.service(),
    userinfo: Ember.computed.alias('adddetailsController.values'),
    info: Ember.computed.alias('adddetailsController.model'),
    selectedProducts: null,
    init_functions: function () {
        this._super();
        return this.get('registerController').set('registerType', 'service');
    },
    nextPageText: Ember.on(
        'init',
        Ember.observer('currentPage', function () {
            var intl, nextPageText;
            intl = this.get('intl');
            nextPageText = function () {
                switch (this.get('currentPage')) {
                    case 1:
                        return intl.t('register.button_continue');
                    case 2:
                        return intl.t('register.button_register');
                    //when 3 then intl.t "register.button_register"
                    case 4:
                        return null;
                }
            }.call(this);
            return this.get('registerController').set('nextPageText', nextPageText);
        }),
    ),
    showBackButton: Ember.computed.equal('currentPage', 3),
    page_list: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return Em.A([
            intl.t('register.page1'),
            intl.t('register.page2'),
            //intl.t 'register.page3'
            intl.t('register.page4'),
        ]);
    }),
    observePages: Ember.on(
        'init',
        Ember.observer('currentPage', function () {
            var currentPage, i, index, len, page, page_list, ret;
            currentPage = this.get('currentPage');
            page_list = this.get('page_list');
            ret = Em.A([]);
            for (index = i = 0, len = page_list.length; i < len; index = ++i) {
                page = page_list[index];
                ret.pushObject(
                    Em.Object.create({
                        name: page,
                        active: currentPage - 1 >= index,
                        done: currentPage - 1 > index,
                        num: index + 1,
                    }),
                );
            }
            return this.get('registerController').set('pages', ret);
        }),
    ),
    products: Em.A([]),
    getProducts: Ember.on(
        'init',
        Ember.observer('userinfo.country', 'userinfo.source', function () {
            var cdn, params;
            if (!this.get('userinfo.country')) {
                return;
            }
            cdn = this.get('cdn');
            params = {
                data: {
                    country: this.get('userinfo.country'),
                    source: this.get('userinfo.source'),
                    lang: moment.locale(),
                },
            };
            return this.get('admintool')
                .get('products', params)
                .then((products) => {
                    products = products.map(function (item) {
                        if (item.image && item.image.substring(0, 5) === 'icon:') {
                            return (item.icon = item.image.substring(5));
                        } else {
                            item.image = item.image
                                ? cdn.url('images/products/' + item.image)
                                : void 0;
                            return Ember.Object.create(item);
                        }
                    });
                    return this.set('products', Em.A(products));
                });
        }),
    ),
    currentPage: Ember.computed('applicationController.path.[]', function () {
        var path;
        path = this.get('applicationController.path');
        if (!path) {
            return 1;
        }
        switch (path[2]) {
            case 'adddetails':
                return 1;
            case 'products':
                return 2;
            case 'amounts':
                return 3;
            case 'done':
                return 4;
            default:
                return 1;
        }
    }),
    //    createLead: Ember.observer 'adddetailsController.email_filled', 'adddetailsController.company_filled','userinfo.country', ->
    //        unless @get('userinfo.leadid')
    //            if @get('adddetailsController.email_filled') and @get('adddetailsController.company_filled') and @get('userinfo.country')
    //                @send('updateZoho', 'createLead')

    //    sendCredentials: Em.observer 'doneController.password','doneController.username', ->
    //        if @get('doneController.password') and @get('doneController.username')
    //            @send('updateZoho', 'sendCredentials')

    //    crmObserver: Ember.observer 'userinfo.email', 'userinfo.firstname','userinfo.lastname','userinfo.company','userinfo.vatin','userinfo.phone','userinfo.country','userinfo.promocode','doneController.username', ->
    //        Ember.run.debounce(@, 'sendCrmInfo',2000)

    //    sendCrmInfo: (serviceCreated = false) ->
    //        if config.environment == "production"
    //            info = @get('info')
    //            data = info.getProperties('email','firstname','lastname','company','vatin','phone','country','promocode','deal','org_id','person_id')
    //            if @get('doneController.username')
    //                data.username = @get('doneController.username')
    //                data.password = @get('doneController.password')
    //                data.products = @get('selectedProducts').mapBy('name')
    //            if info.get('company') && info.get('email') && info.get('country') is 'se'
    //                c = @get('Collector')
    //                type = 'POST'
    //                if info.get('deal')
    //                    type = 'PUT'
    //                c.ttapi(
    //                    url: 'crm/deals'
    //                    data: JSON.stringify({data: data})
    //                    type: type
    //                    contentType: 'application/json'
    //                    success: (ret) ->
    //                        if ret.person_id
    //                            info.set('person_id', ret.person_id.value)
    //                        if ret.org_id
    //                            info.set('org_id', ret.org_id.value)
    //                        if ret.id
    //                            info.set('deal', ret.id)
    //                        else
    //                            self.get('error-logger').captureMessage('CRM info: '+JSON.stringify(data)+', returned: '+JSON.stringify(ret), {level: 'info'});
    //                )

    //remove zapier-hooks from registration
    //if serviceCreated
    //    if info.get('country') is 'fi'
    //        url = 'https://hooks.zapier.com/hooks/catch/213559/6isv6c/'

    //    #send request to zapier hook only in sweden
    //    if info.get('country') is 'se'
    //        url = 'https://hooks.zapier.com/hooks/catch/213559/tbgmdi/'
    //        $.ajax({
    //            url: url
    //            method: 'POST'
    //            data: JSON.stringify({data: data})
    //        })
    actions: {
        back: function () {
            return this.transitionToRoute('register.products');
        },
        continue: function () {
            var addDetailsController, currentPage, selectedProducts, self;
            currentPage = this.get('currentPage');
            switch (currentPage) {
                case 1:
                    self = this;
                    addDetailsController = this.get('adddetailsController');
                    return addDetailsController.validate().then(function () {
                        var err, error, errors;
                        addDetailsController.setValidationErrors();
                        errors = addDetailsController.get('errors');
                        err = false;
                        for (error in errors) {
                            if (errors.hasOwnProperty(error)) {
                                if (!err) {
                                    err = true;
                                }
                            }
                        }
                        if (!err) {
                            return self.transitionToRoute('register.service.products');
                        }
                    });
                //                            self.send('updateZoho', 'convertLead')
                //                            self.sendCrmInfo()
                case 2:
                    selectedProducts = this.get('productsController.selectedProducts');
                    if (selectedProducts.length < 1) {
                        return;
                    }
                    this.set('selectedProducts', selectedProducts);
                    //                    @send('updateZoho', 'updatePotential')
                    //                    @send('updateZoho', 'createPotentials')
                    //    @transitionToRoute 'register.amounts'
                    //when 3
                    this.get('doneController')
                        .callServiceCreation()
                        .then((backFromServer) => {
                            return this.send('updateAdmintool', backFromServer);
                        });
                    return this.transitionToRoute('register.service.done');
                case 4:
                    return this.get('doneController').loginToService();
            }
        },
        updateAdmintool: function (backFromServer) {
            return this.get('admintool').ajax({
                url: 'customers',
                method: 'post',
                data: {
                    partnerid: backFromServer.partnerid,
                    name: this.get('userinfo.company'),
                    promocode: this.get('userinfo.promocode'),
                    username: backFromServer.username,
                    country: this.get('userinfo.country'),
                    vatin: this.get('userinfo.vatin'),
                    login_user_info_name:
                        this.get('userinfo.firstname') + ' ' + this.get('userinfo.lastname'),
                    login_user_info_phone: this.get('userinfo.phone'),
                    login_user_info_email: this.get('userinfo.email'),
                    no_trial_period: this.get('userinfo.skip_trial'),
                },
            });
        },
    },
});

//        updateZoho: (type) ->
//            return
//            self = @
//            c = @get 'Collector'
//            sendData = {type: type}
//            sendData.data = @get('userinfo').getProperties('company','firstname','lastname','vatin','email','phone','country','promocode','leadid','potentialid','accountid','contactid')
//            if type == 'createPotentials' || type == 'updatePotential'
//                sendData.data.products = []
//                @get('selectedProducts').forEach (obj) ->
//                    sendData.data.products.push {name: obj.name, zoho_id: obj.zoho_id}
//            if type == 'sendCredentials'
//                sendData.data.password = @get('doneController.password')
//                sendData.data.username = @get('doneController.username')

//            url = '/convert'
//            if config.environment == "production"
//                c.ttapi(
//                    url: url
//                    data: JSON.stringify(sendData)
//                    type: 'POST'
//                    contentType: 'application/json'
//                    success: (ret) ->
//                        if ret
//                            try
//                                ret = JSON.parse(ret)
//                            catch e
//                                throw new Error('Could not parse zoho string '+JSON.stringify(ret)+' we sent '+JSON.stringify(sendData))
//                            self.get('error-logger').captureMessage('Zoho info: '+JSON.stringify(sendData)+', returned: '+JSON.stringify(ret), {level: 'info'});
//                            if type == "createLead"
//                                self.set('adddetailsController.model.leadid', ret.id)
//                            else if type == "convertLead"
//                                self.set('adddetailsController.model.potentialid', ret.potential)
//                                self.set('adddetailsController.model.accountid', ret.account)
//                                self.set('adddetailsController.model.contactid', ret.contact)
//                        else
//                            self.get('error-logger').captureMessage('Zoho returned nothing, we sent '+JSON.stringify(sendData), {level: 'info'})
//                )
export default RegisterServiceController;
