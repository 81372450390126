import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Input\n    @type=\"text\"\n    @name={{@name}}\n    @value={{@value}}\n    @class={{@class}}\n    @disabled={{@disabled}}\n    @touchMove={{this.touchMoveAction}}\n    @touchEnd={{this.touchEndAction}}\n    {{on \"change\" (fn @onChange @value)}}\n    @placeholder={{@placeholder}}\n/>", {"contents":"<Input\n    @type=\"text\"\n    @name={{@name}}\n    @value={{@value}}\n    @class={{@class}}\n    @disabled={{@disabled}}\n    @touchMove={{this.touchMoveAction}}\n    @touchEnd={{this.touchEndAction}}\n    {{on \"change\" (fn @onChange @value)}}\n    @placeholder={{@placeholder}}\n/>","moduleName":"tt4/components/addon/fields/textbox.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/textbox.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';

interface ITextbox {
    value: string;
}

/**
    Textbox field
    @class FieldsTextBoxComponent
*/
/* istanbul ignore next */
export default class FieldsTextBoxComponent extends Component<ITextbox> {
    private _touchMoving: boolean = false;

    set touchMoving(moving: boolean) {
        this._touchMoving = moving;
    }

    get touchMoving(): boolean {
        return this._touchMoving;
    }

    @action
    protected touchMoveAction() {
        debounce(
            this,
            () => {
                this.touchMoving = true;
            },
            50,
        );
    }

    @action
    protected touchEndAction() {
        if (this.touchMoving) {
            this.touchMoving = false;
            (document.activeElement as HTMLElement).blur();
        }
    }
}
