import { helper } from '@ember/component/helper';
import { warn } from '@ember/debug';

// This function receives the params `params, hash`
export function currency_by_country(params) {
    const amount = params[0];
    const country = params[1];

    if (country == null) {
        return amount;
    }

    switch (country) {
        case 'fi':
            return `${amount} EUR`;
        case 'se':
            return `${amount} SEK`;
        default:
            warn(`Unknown country: ${country} `, false, { id: 'currency-needs-country' });
            return amount;
    }
}

export default helper(currency_by_country);
