/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/dev-helper.coffee.old
// Generated by CoffeeScript 2.6.1
var DevHelperHelper, devHelper;

import Ember from 'ember';

import config from '../config/environment';

devHelper = function () {
    return Ember.devModeOn === true;
};

DevHelperHelper = Ember.Helper.helper(devHelper);

export { devHelper };

export default DevHelperHelper;
