/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

PresenceEditRoute = GeneralAddRoute.extend({
    modelName: 'presence',
    controllerName: 'presences/edit',
    edit: true,
    //    model: (params) ->
    //        @store.findRecord(@get('modelName'), params.id)
    afterSave: 'presences.report',
});

export default PresenceEditRoute;
