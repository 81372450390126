import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class SettingsService extends Service {
    @service session;
    @service('collector-service') Collector;

    _refreshNeeded = false;

    async setProducts(products) {
        await Promise.all(this.Collector.setProducts(products));
        await this.clearCacheAndRefreshNeeded();
    }

    async clearCacheAndRefreshNeeded() {
        await this.Collector.ajax({
            url: 'system/clear_memcache?partnerid=' + this.session.currentUser.partnerid,
        });
        this._refreshNeeded = true;
    }

    /**
     * routes can ask is refresh needed or not by calling this method
     */
    refreshNeeded() {
        return this._refreshNeeded;
    }
}
