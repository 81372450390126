import baseField from '../text-field/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default baseField.extend({
    gui: service(),

    actions: {
        changeValue(event) {
            const value = event.target.value;
            set(this, 'value', value);
            if (this.bubbleChange) this.bubbleChange(value, get(this, 'field.name'));
        },

        timefy() {
            const value = this.tryToGuessTime(get(this, 'value'));
            set(this, 'value', value);
            if (this.bubbleChange) this.bubbleChange(value, get(this, 'field.name'));
        },

        select() {
            this.element.querySelector('input').select();
        },
    },

    tryToGuessTime(str) {
        let hours, mins;
        if (!str) return '';
        if (str.includes(':')) {
            const exploded = str.split(':');
            mins = parseInt(exploded[1]);
            hours = parseInt(exploded[0]);
        } else if (str.includes('.')) {
            const exploded = str.split('.');
            mins = parseInt(exploded[1]);
            hours = parseInt(exploded[0]);
        } else if (str.length >= 3) {
            mins = parseInt(str.substring(str.length - 2, str.length));
            hours = parseInt(str.substring(0, str.length - 2));
        } else if (str === '') {
            return '';
        } else {
            hours = parseInt(str, 10);
            mins = 0;
        }

        if (isNaN(hours)) hours = 0;
        if (isNaN(mins)) mins = 0;

        if (hours < 0) hours *= -1;
        if (hours > 23) hours = 23;
        if (mins < 0) mins = 0;
        if (mins > 60) mins = 59;

        if (hours < 10) hours = '0' + hours;
        if (mins < 10) mins = '0' + mins;

        return '' + hours + ':' + mins;
    },
});
