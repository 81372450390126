/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAddController;

import Ember from 'ember';

import collector from '../../../classes/collector';

OrientationsAddController = Ember.Controller.extend({
    //    needs: 'orientations/add/signatures'
    signaturesController: Em.inject.controller('orientations/add/signatures'),
    record: Ember.computed.alias('model.record'),
    checkbox_groups: Ember.computed.alias('model.checkbox_groups'),
    evented: Em.inject.service(),
    selected_parents: {},
    router: Em.inject.service(),
    users: Em.A([]),
    date: Ember.computed(function () {
        return moment().format('YYYY-MM-DD');
    }),
    init: function () {
        this._super();
        return this.evented.on('onResumed', this, 'updateDate');
    },
    willDestroyElement: function () {
        this._super();
        return this.evented.off('onResumed', this, 'updateDate');
    },
    updateDate: function () {
        return this.notifyPropertyChange('date');
    },
    place: null,
    disableLinks: Em.computed('model.record.ori_site', 'users', {
        get: function () {
            if (this._value) {
                return this._value;
            }
            if (this.get('model.record.ori_site') && this.get('users').length) {
                return false;
            } else {
                return true;
            }
        },
        set: function (key, value) {
            return (this._value = value);
        },
    }),
    projectObserver: function () {
        if (this.get('model.record.ori_site')) {
            this.set(
                'model.record.special_warning',
                this.get('model.record.ori_site.special_warnings'),
            );
            return this.set('place', this.get('model.record.ori_site.city'));
        }
    }.observes('model.record.ori_site'),
    projects: Ember.computed(function () {
        return this.store.findAll('project');
    }),
    projectHasLocks: Ember.computed('model.record.ori_site', {
        get: function () {
            var locksPromise;
            if (!this.Collector.testNeedsOne('products.worksite_areas')) {
                return false;
            }
            if (!this.get('model.record.ori_site')) {
                return false;
            }
            locksPromise = this.store.query('lockgroup', {
                project: this.get('model.record.ori_site.id'),
            });
            return locksPromise.then(this.hasLocks.bind(this));
        },
        set: function (key, value) {
            return value;
        },
    }),
    hasLocks: function (locks) {
        return locks.length > 0;
    },
    mandatoryArr: [],
    mandatory: function () {
        var arr, form, k, v;
        form = collector.forms.orientation.objects;
        arr = {};
        for (k in form) {
            v = form[k];
            if (v.features.mandatory) {
                arr[k] = true;
            }
        }
        return this.set('mandatoryArr', arr);
    }.observes('model'),
});

export default OrientationsAddController;
