/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/billing/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller;

import Ember from 'ember';

import config from '../../../config/environment';

Controller = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    excel: Ember.inject.service(),
    exportFile: Ember.inject.service(),
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
    columns: [
        'customer.name',
        'date',
        'product.name',
        'description',
        'amount',
        'price',
        'status',
        'salesman',
    ],
    group: null,
    actions: {
        fetchBills: function (type = null) {
            var group;
            if (this.get('loading')) {
                return;
            }
            this.set('loading', true);
            group = this.get('session.currentUser.group')
                ? this.get('session.currentUser.group.id')
                : this.get('group');
            return this.get('ajax')
                .request(config.APP.ADMINTOOLURL + 'api/visma/billing', {
                    headers: {
                        Authorization:
                            'Bearer ' + this.get('session.session.authenticated.access_token'),
                    },
                    data: {
                        date: this.get('start') + '_' + this.get('end'),
                        type: type,
                        group: group,
                    },
                })
                .then((data) => {
                    if (type === 'csv') {
                        return this.get('excel').export(data, {
                            fileName:
                                'movenium_bills_' +
                                this.get('start') +
                                '_' +
                                this.get('end') +
                                '.xlsx',
                        });
                    } else if (type === 'netvisor') {
                        const content = data.map((bill) => bill.join(';')).join('\n');
                        return this.exportFile.exportFile(content, 'partnerBills', 'csv');
                    } else {
                        return this.set('bills', data);
                    }
                })
                .finally(() => {
                    return this.set('loading', false);
                });
        },
        change: function (value, field) {
            if (Ember.isPresent(value)) {
                return this.set(field, value.get('id'));
            } else {
                return this.set(field, null);
            }
        },
    },
});

export default Controller;
