import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
export default class GanttComponent extends Component {
    get styleNamespace() {
        return podNames['dash-board/gantt'];
    }

    get dates() {
        const [start, end] = this.args.range.split('_');
        let dates = [];
        let date = start;
        while (moment(date) < moment(end)) {
            dates = [...dates, date];
            date = moment(date).add(1, 'days');
        }
        dates = [...dates, moment(end)];
        return dates;
    }

    get hideHeaders() {
        let containerWidth = document.getElementsByClassName('data-container')[0].offsetWidth;
        return (this.dates.length >= 14 && containerWidth < 320) || this.dates.length > 14
            ? true
            : false;
    }
}
