/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/base-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsBaseFieldComponent;

import Ember from 'ember';

import collectorField from '../../../../mixins/collector-field';

CollectorFieldsBaseFieldComponent = Ember.Component.extend(collectorField, {
    object: Ember.computed.alias('field.name'),
    addModelObs: Ember.on('init', function () {
        var modelfield;
        this.updateValueFromModel();
        modelfield = 'model.' + this.get('object');
        return this.addObserver(modelfield, this, this.modelChanged);
    }),
    modelChanged: function () {
        return Ember.run.once(this, this.updateValueFromModel);
    },
    updateValueFromModel: function () {
        var modelfieldid;
        if (this.get('model')) {
            modelfieldid = this.get('model.' + this.get('object'));
            if (modelfieldid) {
                this.set('value', modelfieldid);
                return this.set('field.value', modelfieldid);
            }
        } else if (this.get('field.value')) {
            return this.set('value', this.get('field.value'));
        }
    },
});

export default CollectorFieldsBaseFieldComponent;
