/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/export-salary/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerExportSalaryIndexController,
    indexOf = [].indexOf;

import Ember from 'ember';

import config from '../../../../config/environment';

import collector from '../../../../classes/collector';

TimetrackerExportSalaryIndexController = Ember.Controller.extend({
    dialogs: Ember.inject.service(),
    admintool: Ember.inject.service(),
    session: Em.inject.service(),
    initapp: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    capi: Em.inject.service(),
    intl: Em.inject.service(),
    _refreshNeeded: false,
    showIntegrationModal: false,
    rowActions: Ember.computed('settings.row_info_status', function () {
        var actions;
        if (this.get('settings.row_info_status')) {
            actions = [
                {
                    action: 'restoreRow',
                    icon: 'move move-ReuseFilled',
                },
            ];
        } else {
            actions = [
                {
                    action: 'removeRow',
                    icon: 'move move-Delete',
                },
                {
                    action: 'historyRow',
                    icon: 'move move-Search',
                },
            ];
        }
        return actions;
    }),
    settings: Em.Object.create({
        row_info_status: false,
        form: 'export_salary',
    }),
    showAd: Em.computed(function () {
        return !this.get('Collector').testNeedsOne('export_salary');
    }),
    showTheseProducts: [
        'fivaldi_salary_csv',
        'fivaldi_salary_soap',
        'lon600_salary_xml',
        'netvisor_salary_rest',
        'nova_salary_csv',
        'vismanet',
        'fondion',
    ],
    _products: Em.on('init', function () {
        var c, params;
        c = this.get('Collector');
        params = {
            data: {
                country: this.get('session.currentUser.company_info.country'),
                lang: moment.locale(),
            },
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
        };
        return this.get('admintool')
            .get('products', params)
            .then((products) => {
                var productsArr;
                productsArr = products.filter((product) => {
                    var ref;
                    return (
                        (ref = product.name), indexOf.call(this.get('showTheseProducts'), ref) >= 0
                    );
                });
                products = productsArr.map((item) => {
                    item = Ember.Object.create(item);
                    if (c.testNeeds(['products.' + item.get('name')])) {
                        item.set('selected', true);
                    }
                    return item;
                });
                return this.set('products', products);
            });
    }),
    isDirty: Em.computed('products.@each.selected', 'productsSaved', function () {
        var c, dirty, i, len, product, products;
        products = this.get('products');
        if (!products) {
            return false;
        }
        c = this.get('Collector');
        dirty = false;
        for (i = 0, len = products.length; i < len; i++) {
            product = products[i];
            if (product.get('selected') && !c.testNeeds(['products.' + product.get('name')])) {
                dirty = true;
            } else if (
                !product.get('selected') &&
                c.testNeeds(['products.' + product.get('name')])
            ) {
                dirty = true;
            }
        }
        return dirty;
    }),
    actions: {
        click: function (object) {
            if (object.get('disabled')) {
                return false;
            }
            if (object.get('selected')) {
                object.set('selected', false);
            } else {
                object.toggleProperty('selected');
                this.toggleProperty('showIntegrationModal');
            }
            return false;
        },
        save: function () {
            var c, i, len, needs, post_add_actions, product, products, promises;
            products = this.get('products');
            //@get('settingsController').refreshNeeded()
            this.set('_refreshNeeded', true);
            c = this.get('Collector');
            needs = [];
            post_add_actions = [];
            for (i = 0, len = products.length; i < len; i++) {
                product = products[i];
                if (product.get('selected')) {
                    if (
                        !c.productExists('products.' + product.get('name')) &&
                        typeof this['adding_' + product.get('name')] === 'function'
                    ) {
                        post_add_actions.push(product.get('name'));
                    }
                    needs.push('products.' + product.get('name'));
                } else {
                    needs.push('!products.' + product.get('name'));
                }
            }
            //@sendInfoToCustomerService(products)
            promises = c.setProducts(needs);
            return Em.RSVP.Promise.all(promises).then((data) => {
                this.set('productsSaved', true);
                this.get('initapp').clearInit();
                return this.get('dialogs')
                    .alert(this.get('intl').t('general.settings_refresh_needed'))
                    .then(function () {
                        return location.reload();
                    });
            });
        },
        ShowSettings: function () {
            if (this.get('Collector').productExists('products.netvisor_salary_rest')) {
                return this.set('showModal', 'netvisor');
            } else if (this.get('Collector').productExists('products.payroll_worktime_rest')) {
                return this.set('showModal', 'payroll');
            } else if (this.get('Collector').productExists('products.fivaldi_salary_soap')) {
                return this.set('showModal', 'fivaldi');
            } else if (this.get('Collector').productExists('products.vismanet')) {
                return this.set('showModal', 'vismanet');
            } else if (this.get('Collector').productExists('products.nova_web_service')) {
                return this.set('showModal', 'nova_web_service');
            } else if (this.get('Collector').productExists('products.fondion')) {
                return this.set('showModal', 'fondion');
            }
        },
        closeModal: function () {
            return this.set('showModal', false);
        },
        infoButton: function (product) {
            this.set('showIntegrationModal', true);
            return this.set('integrationName', product);
        },
    },
});

export default TimetrackerExportSalaryIndexController;
