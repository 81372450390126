/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/form-report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormReportRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

import initRoute from '../../../mixins/init-route';

FormsFormReportRoute = GeneralReportRoute.extend(initRoute, {
    Collector: Em.inject.service('collector-service'),
    store: Ember.inject.service(),
    queryParams: {
        page: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
            replace: true,
        },
        q: {
            refreshModel: true,
            replace: true,
        },
    },
    model: function (params) {
        var c, field, fields, form, i, len;
        params = this._super(params);
        params.sideload = true;
        form = params.form;
        this.set('form', form);
        delete params.form;
        c = this.get('Collector');
        fields = c.fieldArray(form);
        if (fields) {
            for (i = 0, len = fields.length; i < len; i++) {
                field = fields[i];
                if ('use_index' in field.features) {
                    if (!(field.name in params)) {
                        params[field.name] =
                            moment().startOf('month').format('YYYY-MM-DD') +
                            '_' +
                            moment().endOf('month').format('YYYY-MM-DD');
                    }
                }
            }
        }
        return this.get('store').query(form.underscore(), params);
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        return controller.set('formName', this.get('form'));
    },
    actions: {
        didTransition: function () {
            Ember.run.scheduleOnce('afterRender', this, function () {
                return this.controller.initColSelecter();
            });
            return true;
        },
    },
});

export default FormsFormReportRoute;
