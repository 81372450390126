import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

export default Component.extend({
    store: service(),
    dialogs: service(),
    intl: service(),
    evented: service(),

    localStorageKey: 'worksite_area_passes',

    filteredPermissions: computed('currentLockGroup', 'employer', 'showOnlyValid', function () {
        let ret = {};
        if (this.showOnlyValid) {
            ret['pass_start:pass_end'] =
                moment().format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD');
        }
        ret['lockgroupid'] = this.currentLockGroup;
        ret['employer'] = this.employer;
        return ret;
    }),

    lockgroups: computed('project', async function () {
        let params = {};
        params['sideload'] = true;
        if (this.project) {
            let project = this.project;
            if (this.project.indexOf(',') > -1) {
                project = this.project.split(',');
            }
            return get(this, 'store')
                .peekAll('lockgroup')
                .filter((row) => {
                    if (
                        (Array.isArray(project) && project.includes(row.project.id)) ||
                        row.project.id == project
                    ) {
                        return true;
                    }
                });
        }
        await get(this, 'store').query('lockgroup', params);
        return get(this, 'store').peekAll('lockgroup');
    }),

    filteredLocksPermission: computed('selectedProject', async function () {
        let locks = await get(this, 'store').query('lockgroup', {
            project: this.selectedProject.id,
        });
        let ret = [];
        locks.forEach((lock) => {
            ret.pushObject({ id: lock.id, name: lock.name });
        });
        return ret;
    }),

    init() {
        this._super();
        this.evented.on('storeEvent', this, 'onStoreEvent');
    },

    async didInsertElement() {
        set(this, 'showOnlyValid', true);
        set(this, 'selectedUsers', A());
        set(this, 'show_fields', ['firstname', 'lastname']);
    },
    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
    },
    actions: {
        async endPass(record) {
            const executeChanges = await get(this, 'dialogs').confirm(
                get(this, 'intl').t('report.worksite-areas.end_pass_question'),
            );
            if (executeChanges) {
                set(record, 'pass_end', moment().subtract(1, 'day').format('YYYY-MM-DD'));
                await record.save();
            }
        },

        toggleValidPasses() {
            this.toggleProperty('showOnlyValid');
            this.evented.storeEvent('update', 'lockgroupid', '');
        },
        async deleteLockGroup(lockgroup) {
            const executeChanges = await get(this, 'dialogs').confirm(
                get(this, 'intl').t('report.worksite-areas.delete_group_question'),
            );
            if (executeChanges) {
                let passesToEnd = await this.store.query('lockpermission', {
                    lockgroupid: lockgroup.id,
                });
                passesToEnd.forEach((record) => {
                    set(record, 'pass_end', moment().subtract(1, 'day').format('YYYY-MM-DD'));
                    record.save();
                });
                //set(lockgroup,'status', 'passive');
                await lockgroup.destroyRecord();
                lockgroup.unloadRecord();
            }
            return false;
        },
        async save() {
            let lock = await get(this, 'store').findRecord('lockgroup', this.lockgroupid);

            set(this, 'saving', true);

            for (var item of this.selectedUsers) {
                var record = get(this, 'store').createRecord('lockpermission', {
                    user: item,
                    lockgroupid: lock,
                    pass_start: this.pass_start,
                    pass_end: this.pass_end,
                });
                await record.save();
            }
            set(this, 'saving', false);
            this.evented.storeEvent('update', 'lockgroupid', '');
            set(this, 'showAddPermissionDialog', false);
        },
        filterChange(value, field) {
            //set(this,'employer', value);
            if (field) set(this, field, value);
        },
        PermissionsEditAction(users) {
            this.clearValues();
            set(this, 'selected_users', users);
            set(this, 'pass_end', null);
            set(this, 'pass_start', null);
            set(this, 'showEditPermissionDialog', true);
        },
        endPermissions() {
            set(this, 'pass_end', moment().format('YYYY-MM-DD'));
        },
        async editPermissions() {
            set(this, 'saving', true);
            for (var user of this.selected_users) {
                if (this.pass_start) set(user, 'pass_start', this.pass_start);
                if (this.pass_end) set(user, 'pass_end', this.pass_end);
                await user.save();
            }
            set(this, 'saving', false);
            set(this, 'showEditPermissionDialog', false);
            this.clearValues();
        },
        PermissionsAdderAction(lockgroup, project) {
            this.clearValues();
            if (lockgroup) {
                set(this, 'lockgroupid', lockgroup);
            }
            if (project) {
                set(this, 'selectedProject', project.id);
            }
            set(this, 'showAddPermissionDialog', true);
        },
        changeCurrentLockGroup(id) {
            if (this.currentLockGroup == id) {
                set(this, 'currentLockGroup', null);
            } else {
                set(this, 'currentLockGroup', id);
            }
        },
    },

    onStoreEvent(params) {
        if (params.type === 'lockgroupid') {
            this.notifyPropertyChange('lockgroups');
        }
    },

    clearValues() {
        set(this, 'selected_users', A());
        set(this, 'selectedUsers', A());
        set(this, 'selectedProject', null);
        set(this, 'selectedEmployer', null);
        set(this, 'pass_start', null);
        set(this, 'pass_end', null);
        set(this, 'lockgroupid', null);
    },
});
