import Component from '@glimmer/component';

export default class SettingsGeneralV2ComponentsSalarytypesSalariesModalComponent extends Component {
    /**
     * 1. when editing auto salarytype: disable all fields except key
     * 2. when worktimeGroupLocked disable worktimegroup (this used when worktimegroup is selected via settings)
     * 3. never allow editing worktimegroup
     */
    get disabledFields() {
        const ret = {};

        // 1.
        if (this.args.record.fieldtype === 'auto' && !this.args.record.isNew) {
            ret.name = true;
            ret.worktimegroup = true;
            ret.type = true;
        }

        // 2.
        if (this.args.worktimeGroupLocked) ret.worktimegroup = true;

        // 3.
        if (!this.args.record.isNew) ret.worktimegroup = true;

        return ret;
    }
}
