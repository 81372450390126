/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/inactive/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersInactiveController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

EmployersInactiveController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    form: 'employer',
    showColumns: ['emp_id', 'name'],
    queryParams: ['page', 'q'],
    data: Em.computed('model.[]', function () {
        var formatted_rows;
        if (!this.get('model')) {
            return;
        }
        formatted_rows = Em.A([]);
        this.get('model').forEach(function (row) {
            return formatted_rows.push(Ember.Object.create(row));
        });
        return formatted_rows;
    }),
    rowActions: Em.computed(function () {
        var arr;
        return (arr = [
            {
                action: 'removeRow',
                icon: 'move move-Delete',
                text: this.get('intl').t('report.delete_rows'),
            },
        ]);
    }),
    actions: {
        catchActions: function (action, params) {
            return this.send(action, params);
        },
        removeRow: function (row) {
            var self;
            self = this;
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    var emp;
                    if (con) {
                        this.get('data').removeObject(row);
                        emp = this.get('store').peekRecord('employer', row.id);
                        return emp.destroyRecord();
                    }
                });
        },
    },
});

export default EmployersInactiveController;
