/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

EmployersAddRoute = GeneralAddRoute.extend({
    modelName: 'employer',
    controllerName: 'employers.add',
    savedRoute: 'employers.saved',
    resetController: function (controller, isExiting, transition) {
        this._super(controller, isExiting, transition);
        if (isExiting) {
            controller.set('tilaajavastuu_report', false);
            return controller.set('message', null);
        }
    },
});

export default EmployersAddRoute;
