import Component from '@ember/component';
import { computed, get, set, setProperties } from '@ember/object';
import { next } from '@ember/runloop';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty, typeOf } from '@ember/utils';
import { A } from '@ember/array';
import filtermodel from 'tt4/mixins/filter-model';
import pdfExport from 'tt4/mixins/pdf-export';
import saveTheChildren from 'tt4/mixins/save-the-children';
import { defer } from 'rsvp';
import { pluralize } from 'ember-inflector';
import { dasherize, camelize } from '@ember/string';

/**
 *
 * report for collector form
 *
 *  {{#collector/form-report form="worktime" as |report|}}
        {{report.filters}}
        {{report.table}}
    {{/collector/form-report}}
 *
 * parameters:
 *      form: name of the form (mandatory), snake cased eg. export_salary
 *      filter: object containing list of filters that are added to every query.
 *              When used also created records will automatically have these set
 *      showColumns: List of columns to show on report. Also adding and editing form is affected by this.
 *      filterByValues: object containing default-values for filters
 *
 * objects:
 *      filters: filter bar for report.
 *          parameters:
 *              showColumns: list of columns to be shown
 *      add: if used plus button is added above the table
 *      table: the table it self
 *          parameters:
 *              rowActions: list of actions to show for rows separated with comma. default: edit
 *
 */

export default Component.extend(filtermodel, saveTheChildren, pdfExport, {
    store: service(),
    dialogs: service(),
    intl: service(),
    collector: service('collector-service'),
    notifications: service(),
    error: service(),
    analytics: service(),
    capi: service(),
    router: service(),
    localStorageWrapper: service(),
    localstorage: service(),
    cordova: service('own-cordova-service'),
    fileExports: service('file-exports'),
    evented: service(),

    filterByValues: null,

    rowsPerPage: 25,
    currentPage: 1,
    rowActions: 'edit,history,remove',

    metaRowCount: alias('data.content.meta.count'),

    pageCount: computed('metaRowCount', function () {
        if (!this.metaRowCount && !this.cachedRowCount) return 0;
        const count = this.metaRowCount ? this.metaRowCount : this.cachedRowCount;
        return Math.ceil(count / get(this, 'rowsPerPage'));
    }),

    queryParams: computed(
        'currentPage',
        'filterByValues',
        'filter',
        'sorts',
        'showRemoved',
        'jsonapi',
        'rowsPerPage',
        function () {
            let params = {};
            this.mergeFilters(params, get(this, 'filterByValues'));
            this.mergeFilters(params, get(this, 'filter'));
            if (this.jsonapi) {
                params = this.setJsonapiParams(params);
            }
            const sortKey = this.jsonapi ? 'sort' : 'order';
            if (this.sorts && this.sorts.length)
                params[sortKey] = this.createSortString(this.sorts, this.jsonapi);
            params.limit = get(this, 'rowsPerPage');
            params.offset = get(this, 'rowsPerPage') * (get(this, 'currentPage') - 1);
            params.sideload = true;
            if (this.showRemoved) {
                params.row_info_status = 'removed';
            }
            return params;
        },
    ),

    allColumns: computed('form', function () {
        const canEdit = this.collector.testNeeds([
            '!' + this.form + '@errors',
            this.form + '@edit',
        ]);
        let columns = this.collector.fieldArray(this.form).map((item) => {
            item['canEdit'] = canEdit;
            item['valuePath'] = item.name;
            item['external_type'] = item.name;
            return item;
        });

        if (this.showColumns)
            columns = columns.filter((column) =>
                this.showColumns.split(',').includes(column.external_type),
            );
        columns = columns.filter((column) => column.features && !column.features.hide_column);

        if (!this.hideCreatedColumn)
            columns.push({
                name: 'row_info.created',
                external_type: 'row_info.created',
                valuePath: 'row_info.created',
                translated_name: this.intl.t('general.row_info.created'),
            });

        return columns;
    }),

    statusTypes: computed('form', function () {
        let form = camelize(this.form);
        if (!this.form) return [];
        if (!this.Collector.fieldExists(form, 'status')) return [];

        let field = this.Collector.field(form, 'status');
        let fieldOptions = [];

        if (field.features.only_options) {
            fieldOptions = field.features.only_options.split(',');
        } else {
            fieldOptions = field.options;
        }
        let options = fieldOptions.map((option) => {
            if (this.intl.exists('status.' + option)) {
                return { id: option, name: this.intl.t('report.' + option + '_rows') };
            } else {
                return { id: option, name: this.intl.t('report.change_status') + option };
            }
        });

        return options;
    }),

    showApprovalLink: computed('statusTypes', 'selectedExportRows', function () {
        return this.statusTypes.mapBy('id').includes('approved') && this.selectedExportRows;
    }),

    init() {
        this._super();
        this.updateReport();
    },

    didReceiveAttrs() {
        this.selected = [];
        this.setDefaultLimit();
        this.setDefaultOrder();
        this.setVisibleColumns();

        if (!this.filterByValues) {
            set(this, 'filterByValues', {});
        }

        // updateReport is called only if this.updateOnDidReceiveAttrs is true .. it might be okay to call by default but let's do this
        // for now to prevent unwanted behaviour
        // this.updateReport needs to be called when form is changed .. otherwise we get weird errors when wrong fields are tried to be formatted
        // with the new form name
        if (this.updateOnDidReceiveAttrs) this.updateReport();
    },

    actions: {
        openGpsMap(selected) {
            if (this.showLocationMap) {
                set(this, 'showLocationMap', false);
                set(this, 'locationMarkers', []);
                set(this, 'startEndMarkers', []);
                return;
            }
            let rows = selected.length > 0 ? selected : this.data.toArray();

            if (!rows) return;

            let d = defer();
            let locs = [];
            let i = 0;
            let totalLength = rows.length;
            rows.forEach(async (row) => {
                if (!row.location && !row.end_location && !row.location_map) {
                    totalLength--;
                    return;
                }

                let locationMapInfo = await this.getLocationMapInfo(row);
                let loc = {};

                if (locationMapInfo) {
                    if (typeOf(locationMapInfo) === 'object') {
                        loc.project = JSON.parse(locationMapInfo.location_map);
                        loc.project.label = get(locationMapInfo, 'name');
                    } else {
                        loc.project = JSON.parse(locationMapInfo);
                        loc.project.label = row.name ? row.name : null;
                    }
                }
                if (row.location) loc.wStartLoc = JSON.parse(row.location);
                if (row.end_location) loc.wEndLoc = JSON.parse(row.end_location);
                loc.wLabel = row.user
                    ? get(row.user, 'lastname') + ' ' + get(row.user, 'firstname')
                    : null;

                locs.push(loc);

                if (++i === totalLength) {
                    if (row.end_location) {
                        set(this, 'startEndMarkers', locs);
                    } else {
                        set(this, 'locationMarkers', locs);
                    }

                    d.resolve();
                }
            });
            set(this, 'showLocationMap', true);
            return d;
        },

        updateSorts(sorts) {
            this.analytics.trackEvent({
                category: this.form + ' report sort by column',
                action: get(sorts, 'firstObject').valuePath,
                label: 'Form-report',
            });
            this.updateReport();
        },

        columnsChanged(columns) {
            set(this, 'columns', columns);

            // ugly hax to force ember-table rerender without loading data again
            const cacheData = get(this, 'data');
            set(this, 'data', null);
            next(this, () => set(this, 'data', cacheData));
        },

        filterChange(value, field) {
            if (field) {
                set(this, 'currentPage', 1);
                set(this, 'filterByValues.' + field, value);
                this.notifyPropertyChange('queryParams');
                next(this, () => this.updateReport()); // this needs to be inside next .. if not update is sometimes made before queryParams have changed
            }
        },
        clearFilters() {
            set(this, 'currentPage', 1);
            set(this, 'filterByValues', this.filterDefaultValues ? this.filterDefaultValues : {});
            this.notifyPropertyChange('queryParams');
            this.updateReport();
        },

        async actionClicked(action, row) {
            this.analytics.trackEvent({
                category: this.form + ' report row actions',
                action: action,
                label: 'Form-report',
            });
            set(this, 'needsUpdate', true);
            if (action == 'edit') set(this, 'showForm', row);
            if (action === 'history') set(this, 'showHistory', row);
            if (action == 'remove') this.removeRow(row);
            if (action === 'restore') {
                await this.restoreRow(row);
                this.updateReport();
            }
            if (action === 'copy') this.copyRow(row);
            if (action === 'unlock') this.lockOrUnlock(row, 'unlock');
        },

        add() {
            const convertFilterToDefaultValues = (values) => {
                const fields = this.Collector.fieldArray(get(this, 'form'));
                for (let field in values) {
                    const fieldObj = fields.findBy('name', field);
                    if (!fieldObj) continue;
                    if (fieldObj.type === 'multiselect') values[field] = [values[field]];
                }
                return { ...values };
            };

            const values = Object.assign({}, get(this, 'filter'));
            const defaultValues = convertFilterToDefaultValues(values);

            set(
                this,
                'showForm',
                get(this, 'store').createRecord(get(this, 'form'), defaultValues),
            );
        },

        reload() {
            this.analytics.trackEvent({
                category: this.form + ' report actions',
                action: 'Refresh report',
                label: 'Form-report',
            });
            this.updateReport();
        },

        pageChange(page) {
            set(this, 'currentPage', page);
            this.updateReport();
        },

        async exportPdf(selected, params = {}) {
            set(this, 'showLoader', true);
            this.analytics.trackEvent({
                category: this.form + ' report actions',
                action: 'Pdf export',
                label: 'Form-report',
            });
            try {
                if (params.layoutOption === 'sideHeaders') {
                    await this.fileExports.createPdfFull(
                        this.form,
                        this.selectedExportRows,
                        this.filterByValues,
                        this.columns.mapBy('external_type').join(','),
                        params,
                        null,
                    );
                } else {
                    await this.createPdf(selected, params, 'download');
                }
            } catch (error) {
                this.error.notify(error);
            } finally {
                set(this, 'showLoader', false);
            }
        },

        async exportPdfFull(params) {
            set(this, 'showLoader', true);
            this.analytics.trackEvent({
                category: this.form + ' report actions',
                action: 'Pdf big pictures export',
                label: 'Form-report',
            });
            try {
                await this.fileExports.createPdfFull(
                    this.form,
                    this.selectedExportRows,
                    this.filterByValues,
                    this.columns.mapBy('external_type').join(','),
                    params,
                    null,
                );
            } catch (error) {
                this.error.notify(error);
            } finally {
                set(this, 'showLoader', false);
            }
        },

        async exportCsv(selected) {
            set(this, 'showLoader', true);
            this.analytics.trackEvent({
                category: this.form + ' report actions',
                action: 'Csv export',
                label: 'Form-report',
            });
            try {
                let data = !isEmpty(selected) ? selected : await this.getExportData();
                let columns = this.columns.map((col) => {
                    return { name: col.translated_name, ext: col.name, obj: col };
                });
                await this.fileExports.createCsv(this.form, data, columns);
            } catch (error) {
                this.error.notify(error);
            } finally {
                set(this, 'showLoader', false);
            }
        },

        async exportExcel(params) {
            await this.createExcel(params);
        },

        handleExportActions(params) {
            switch (params.type) {
                case 'export_pdf':
                    this.send('exportPdf', this.selectedExportRows, params);
                    break;
                case 'export_pdf_full':
                    this.send('exportPdfFull', params);
                    break;
                case 'export_excel':
                    this.send('exportExcel', params);
                    break;
            }
        },

        saveRows() {
            this.saveDirtyRecords();
        },

        async toggleInlineEdit() {
            if (get(this, 'inlineEdit')) {
                this.analytics.trackEvent({
                    category: this.form + ' report inline edit',
                    action: 'disable',
                    label: 'Form-report',
                });
                //if we are closing inline edit, check for dirty/new/invalid records and prompt user if closing is okay
                let dirtyRecords = [];
                let reloadPromises = [];

                await get(this, 'data').forEach((record) => {
                    if (
                        get(record, 'hasDirtyAttributes') ||
                        get(record, 'isNew') ||
                        !get(record, 'isValid')
                    ) {
                        dirtyRecords.push(record);
                    }
                });

                //if we have dirty records we want to rollback to their previous state
                if (!isEmpty(dirtyRecords)) {
                    let confirm = await this.dialogs.confirm(this.intl.t('report.confirm_unsaved'));

                    if (!confirm) return;

                    dirtyRecords.forEach((record) => {
                        record.rollbackAttributes();
                        if (!get(record, 'isDeleted')) reloadPromises.push(record.reload());
                    });
                }

                await Promise.all(reloadPromises);

                //filter out removed records from data
                let newData = await get(this, 'data').filter((record) => {
                    if (get(record, 'isDeleted')) {
                        this.decrementProperty('cachedRowCount');
                        return false;
                    } else return true;
                });

                set(this, 'data', newData);

                this.toggleProperty('inlineEdit');
            } else {
                this.analytics.trackEvent({
                    category: this.form + ' report inline edit',
                    action: 'enable',
                    label: 'Form-report',
                });
                if (this.metaRowCount && !this.cachedRowCount)
                    set(this, 'cachedRowCount', this.metaRowCount);
                this.toggleProperty('inlineEdit');
            }
        },

        changeStatus(selected, status) {
            if (isEmpty(selected)) return;

            this.analytics.trackEvent({
                category: this.form + ' report more actions',
                action: 'Change status',
                label: 'Form-report',
            });

            if (this.metaRowCount > selected.length && selected.length === this.data.length) {
                this.setParamsForStatusChangeAllPages(selected, status, null);
            }

            selected.forEach(async (row) => {
                set(row, 'status', status);
                try {
                    await row.save();
                    row.reload();
                } catch (error) {
                    this.error.notify(error);
                    row.rollbackAttributes();
                }
            });
        },
        openExportModal(selected, type) {
            set(this, 'showExportModal', true);
            set(this, 'exportInitType', type);
            set(this, 'selectedExportRows', selected);
            set(this, 'showApprovalLinkSelection', this.showApprovalLink);
            set(this, 'emailSendingCount', selected.length);
        },

        handleLock(selected, action) {
            if (isEmpty(selected)) return;
            if (!this.Collector.testNeeds(['userlevel=5'])) return;

            this.analytics.trackEvent({
                category: this.form + ' report more actions',
                action: action + ' rows',
                label: 'Form-report',
            });

            if (this.metaRowCount > selected.length && selected.length === this.data.length) {
                this.setParamsForStatusChangeAllPages(selected, null, action);
            }

            selected.forEach((row) => {
                this.lockOrUnlock(row, action);
            });
        },

        closeShowChangeStatusForAllPages() {
            set(this, 'showChangeStatusForAllPages', false);
        },

        showOrHideRemoved() {
            if (this.showRemoved) {
                set(this, 'showRemoved', false);
            } else {
                set(this, 'showRemoved', true);
            }
            this.updateReport();
        },

        async handleRemove(selected, action) {
            if (isEmpty(selected)) return;
            if (action === 'remove') {
                let con = await this.dialogs.confirm(this.intl.t('report.confirm_delete'));
                if (con) {
                    for (let row of selected) {
                        await row.destroyRecord();
                        await row.unloadRecord();
                    }
                    this.updateReport();
                }
            } else {
                let con = await this.dialogs.confirm(this.intl.t('report.confirm_restore_rows'));
                if (con) {
                    let form_plural = pluralize(this.form).camelize();
                    selected.forEach(async (row) => {
                        await this.capi.request(
                            '/' + form_plural + '/' + row.id + '?validation=off',
                            {
                                dataType: 'json',
                                type: 'PUT',
                                data:
                                    '{"' + this.form.camelize() + '":{"row_info.status":"normal"}}',
                            },
                        );
                        await row.unloadRecord();
                        await this.updateReport();
                    });
                }
            }
        },
        async sendEmail(to, title, content, params) {
            set(this, 'showLoader', true);
            let rowids = this.selectedExportRows
                ? this.selectedExportRows.map((item) => get(item, 'id'))
                : [];
            try {
                let base64string = await this.getPdfAttachmentForEmail(params);
                await this.Collector.ttapi({
                    url: 'sendReportAsEmail',
                    data: {
                        to_address: to,
                        email: base64string,
                        content: content,
                        lang: this.intl.primaryLocale,
                        includeApproval: get(params, 'approvalLink'),
                        form_name: this.form,
                        rowids: rowids,
                        header: title,
                        type: get(params, 'type'),
                    },
                    method: 'post',
                });
                set(this, 'emailSent', true);
            } catch (err) {
                set(this, 'emailError', true);
            } finally {
                set(this, 'showLoader', false);
            }
        },

        setRowsPerPage(RowsPerPage) {
            this.analytics.trackEvent({
                category: this.form + ' report rows per page',
                action: RowsPerPage,
                label: 'Form-report',
            });
            set(this, 'currentPage', 1);
            set(this, 'rowsPerPage', RowsPerPage);
            this.localStorageWrapper.setUserSetting(this.defaultLimitKey, RowsPerPage);
            this.updateReport();
        },
    },

    async createExcel(params, base64 = false) {
        set(this, 'showLoader', true);
        this.analytics.trackEvent({ category: 'Form-report actions', action: 'Excel export' });
        try {
            let filters = this.addFiltersToPdf();
            let data = !isEmpty(this.selectedExportRows)
                ? this.selectedExportRows
                : await this.getExportData();
            let columns = this.columns.map((col) => {
                return { name: col.translated_name, ext: col.name, obj: col };
            });
            let excelJSON = this.fileExports.createExcelJSONFromForm(
                this.form,
                data,
                columns,
                filters,
                params.sumRow,
            );
            if (base64) return await this.fileExports.createBase64ExcelFromJSON(excelJSON);
            await this.fileExports.createExcelFromJSON(excelJSON);
        } catch (error) {
            this.error.notify(error);
        } finally {
            set(this, 'showLoader', false);
        }
    },

    async getProjectInfo(project) {
        let projects = await this.store.query('project', { id: project.id });
        return get(projects, 'firstObject');
    },

    getLocationMapInfo(row) {
        if (row.location_map) {
            return row.location_map;
        } else if (row.project) {
            return this.getProjectInfo(row.project);
        } else {
            return null;
        }
    },

    setParamsForStatusChangeAllPages(selected, status = null, action = null) {
        let visibleRowIds = selected.map((row) => get(row, 'id'));
        setProperties(this.filterByValues, { limit: 'all', sideload: true });
        setProperties(this.changeStatusForAllPagesParams, {
            visibleRowIds: visibleRowIds,
            requestParams: this.filterByValues,
            form: this.form,
            status: status,
            action: action,
            rowCount: this.metaRowCount,
        });
        set(this, 'showChangeStatusForAllPages', true);
    },

    async createPdf(selected, params, mode = 'download') {
        const columnsOnReport = this.columns.map((col) => col.external_type).join(',');
        let showColumns = params.template
            ? get(params, 'template.columns')
            : columnsOnReport
              ? columnsOnReport
              : null;
        let settings = { form: this.form, show_columns: showColumns };
        let element = await this.fileExports.createPdfElement(
            this.getExportData(),
            selected,
            settings,
            this.columns,
            params,
        );
        let elements = [element];
        let filters_body = this.addFiltersToPdf();
        if (filters_body.length > 0) {
            elements.unshift({
                table: { body: filters_body },
                layout: 'noBorders',
            });
        }
        let file = await this.createPdfFromElements(elements, mode, '', 8, params);
        if (window.cordova) {
            this.cordova.openFile(file, 'application/pdf');
        }
        return file;
    },

    addFiltersToPdf() {
        let filterKeys = Object.keys(this.filterByValues);
        if (!filterKeys) return [];
        let pdf_filters_body = filterKeys.map((key) => {
            let filterValues = this.filterByValues[key];
            if (!filterValues || !this.Collector.fieldExists(this.form, key)) return;
            let field = this.Collector.field(this.form, key);

            let filterNameInPdf = this.setFilterNameInPdf(key);
            let filterValueInPdf = '';

            // if filterValues is object, we just take its id as a filtervalue
            if (typeof filterValues == 'object') {
                filterValues = filterValues.id;
            }

            if (filterValues.includes(',')) {
                filterValues.split(',').forEach((value) => {
                    filterValueInPdf +=
                        this.Collector._format_(
                            field.type === 'database' ? parseInt(value) : value,
                            this.form,
                            key,
                            'pdf',
                            this.data,
                        ) + ', ';
                });
                filterValueInPdf = filterValueInPdf.slice(0, -2);
            } else {
                filterValues =
                    field.type === 'database' || field.type === 'multiselect'
                        ? parseInt(filterValues)
                        : filterValues;
                if (key.includes(':')) {
                    filterValueInPdf += this.Collector._format_(
                        filterValues,
                        this.form,
                        key.split(':')[0],
                        'pdf',
                        this.data,
                    );
                } else {
                    filterValueInPdf += this.Collector._format_(
                        filterValues,
                        this.form,
                        key,
                        'pdf',
                        this.data,
                    );
                }
            }
            return [filterNameInPdf + ': ' + filterValueInPdf];
        });

        return pdf_filters_body.filter((item) => item);
    },

    setFilterNameInPdf(key) {
        let field = this.Collector.fieldArray(this.form).findBy('name', key);

        if (this.intl.exists(this.form + '.' + key)) {
            return this.intl.t(this.form + '.' + key);
        } else if (field && field.translated_name) {
            return field.translated_name;
        } else if (key.includes(':')) {
            return (
                this.intl.t(this.form + '.' + key.split(':')[0]) +
                ' - ' +
                this.intl.t(this.form + '.' + key.split(':')[1])
            );
        } else {
            return key;
        }
    },

    async getPdfAttachmentForEmail(params) {
        switch (params.type) {
            case 'export_pdf':
                return await this.createPdf(this.selectedExportRows, params, 'base64');
            case 'export_pdf_full':
                return await this.fileExports.createPdfFull(
                    this.form,
                    this.selectedExportRows,
                    this.filterByValues,
                    this.showColumns,
                    params,
                    'base64',
                );
            case 'export_excel':
                return await this.createExcel(params, true);
        }
    },

    setJsonapiParams(params) {
        if (!params) return {};
        let filters = {};
        Object.entries(params).forEach((param) => {
            if (!param[1]) return; //if value is empty, continue
            let field = this.Collector.field(this.form, param[0]);
            if (field.type === 'database') {
                filters['filter[relationships.' + param[0] + '.data.id]'] = param[1];
            } else {
                filters['filter[attributes.' + param[0] + ']'] = param[1];
            }
        });
        return filters;
    },

    setVisibleColumns() {
        const visibleList = this.localstorage.getItem(this.form + '_defaultColumns');
        let columns = [...this.allColumns];
        if (visibleList) {
            columns = visibleList.reduce((acc, column) => {
                let columnObject = this.allColumns.find(
                    (object) => object.external_type === column,
                );
                if (columnObject) acc.push(columnObject);
                return acc;
            }, []);
        }
        set(this, 'columns', columns);
    },

    setDefaultLimit() {
        let key = this.localStorageKey
            ? this.localStorageKey + '_defaultLimit'
            : this.form + '_defaultLimit';
        if (key) set(this, 'defaultLimitKey', key);
        let limitfromLS = this.localStorageWrapper.getUserSetting(key);
        set(this, 'rowsPerPage', limitfromLS.length != 0 ? limitfromLS : this.rowsPerPage);
    },

    setDefaultOrder() {
        if (this.defaultSort) {
            set(this, 'sorts', this.createSortArray(this.defaultSort));
        }
        // TODO: Sort by first column on default
    },

    mergeFilters(filters, merge) {
        if (!merge) return;
        for (const key in merge) {
            const value = this.getIdifRecord(merge[key]);
            if (value) filters[key] = value;
        }
    },

    getIdifRecord(value) {
        return value && typeof value === 'object' ? get(value, 'id') : value;
    },

    removeRow(row) {
        get(this, 'dialogs')
            .confirm(get(this, 'intl').t('report.confirm_delete'))
            .then(this.removeOrNot.bind(this, row));
    },

    async restoreRow(row) {
        const form_plural = pluralize(this.form).camelize();

        await this.capi.request('/' + form_plural + '/' + row.id + '?validation=off', {
            dataType: 'json',
            type: 'PUT',
            data: '{"' + this.form.camelize() + '":{"row_info.status":"normal"}}',
        });
        this.evented.storeEvent('restore', get(this, 'form'), null);
    },

    async copyRow(row) {
        let newRecord = this.Collector.copyRecord(row);
        set(newRecord, 'row_info', { privilege_edit: true, privilege_remove: true }); //fake some privileges to enable editing and removing of new row
        let data = await get(this, 'data').toArray(); //toArray, because the result of a server query is immutable
        data.unshiftObject(newRecord);
        this.incrementProperty('cachedRowCount');
        set(this, 'data', data);
    },

    async removeOrNot(row, answer) {
        if (!answer) return;
        await row.destroyRecord();
        this.updateReport();
        this.evented.storeEvent('delete', get(this, 'form'), null);
    },

    async lockOrUnlock(row, action) {
        let form_plural = pluralize(this.form).camelize();
        let data = {};
        data[this.form] = action === 'lock' ? { locked: true } : { locked: false };
        try {
            await this.capi.request('/' + form_plural + '/' + get(row, 'id') + '?validation=off', {
                dataType: 'json',
                type: 'PUT',
                data: JSON.stringify(data),
            });
            row.reload();
        } catch (error) {
            this.error.notify(error);
            row.rollbackAttributes();
        } finally {
            this.updateReport();
        }
    },

    updateReport() {
        // this is needed to force queryParams to update
        this.notifyPropertyChange('queryParams');
        set(
            this,
            'data',
            get(this, 'store').query(dasherize(get(this, 'form')), get(this, 'queryParams')),
        );
    },

    createSortString(sorts, jsonapi = false) {
        if (jsonapi)
            return sorts
                .map((item) => (item.isAscending ? '-' : '') + 'attributes.' + item.valuePath)
                .join(',');
        return sorts
            .map(
                (item) =>
                    (item.valuePath == 'row_info.created' ? 'id' : item.valuePath) +
                    (item.isAscending ? '-' : ''),
            )
            .join(',');
    },

    createSortArray(sorts) {
        if (!sorts) return;
        return sorts.split(',').map((item) => {
            return item.slice(-1) == '-'
                ? { valuePath: item.slice(0, -1), isAscending: true }
                : { valuePath: item, isAscending: false };
        });
    },

    async saveDirtyRecords() {
        const data = await get(this, 'data');

        let savePromises = A([]);
        let errors = A([]);
        set(this, 'errors', errors);

        //push all rows that have dirty attributes into a promise array
        savePromises = data.map(async (record, index) => {
            if (get(record, 'hasDirtyAttributes')) {
                await record.validate(); //validate record. Do not send save request if it is invalid (in save function)
                return this.saveRecordAndCatchErrors(record, errors, index);
            }
        });

        //if promise array is empty display notification and return straight outta here, we don't need to do anyhting
        if (isEmpty(savePromises)) {
            this.notifications.warning(this.intl.t('general.no_saved_rows'), {
                autoClear: true,
            });
            return;
        }

        await Promise.all(savePromises);

        if (isEmpty(errors)) {
            this.notifications.success(this.intl.t('general.saved'), {
                autoClear: true,
            });
        } else {
            set(this, 'errors', errors);
            this.notifications.error(this.intl.t('general.no_saved_rows'), {
                autoClear: true,
            });
        }
    },

    getExportData() {
        let params = get(this, 'queryParams');
        params.limit = 'all';
        // params must be cloned because appliactions.js stupidly modifies the object
        return get(this, 'store').query(get(this, 'form'), { ...params });
    },

    async saveRecordAndCatchErrors(record, errors, index) {
        //do not send save request is record is invalid
        try {
            if (get(record, 'isValid')) {
                //save via save-the-children mixin
                return await this.save_record(record);
            } else {
                errors.pushObject({
                    index: index + 1,
                    message: get(record, 'errors.messages.firstObject'),
                });
            }
        } catch (saveError) {
            const err = this.error.toError(saveError);
            err['index'] = index + 1; //arrays start at 0 (╯°□°)╯︵ ┻━┻
            errors.pushObject(err);
            return err;
        }
    },
});
