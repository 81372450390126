/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/tasks/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditTasksRoute;

import Ember from 'ember';

ProjectsEditTasksRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Em.inject.service(),
    modelCreator: Ember.inject.service('model-creator'),
    model: function (params) {
        var c, field;
        c = this.get('Collector');
        if (c.fieldExists('worktime', 'task')) {
            field = c.field('worktime', 'task');
        } else {
            field = c.field('multitask', 'taskname');
        }
        return Ember.RSVP.hash({
            record: this.modelFor('projects.edit'),
            field: field.options.fields,
            tasks: this.modelFor('task'),
        });
    },
    actions: {
        //has to reload /forms to get reload setting to work
        willTransition: function (transition) {
            return this.get('modelCreator').createEmberModels();
        },
    },
});

export default ProjectsEditTasksRoute;
