/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/form-report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormReportController,
    indexOf1 = [].indexOf;

import Ember from 'ember';

import formReport from '../../collector/report-comp/component';

import pdfExport from '../../../mixins/pdf-export';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

import csvExport from '../../../mixins/csv-export';

import initController from '../../../mixins/init-controller';

import saveTheChildren from '../../../mixins/save-the-children';

import $ from 'jquery';

import { pluralize } from 'ember-inflector';

FormsFormReportController = Ember.Controller.extend(
    pdfExport,
    csvExport,
    initController,
    saveTheChildren,
    {
        Collector: Ember.inject.service('collector-service'),
        session: Ember.inject.service(),
        localStorageWrapper: Ember.inject.service(),
        sessionGlobals: Em.inject.service(),
        dialogs: Ember.inject.service(),
        tools: Ember.inject.service(),
        capi: Ember.inject.service(),
        exportFile: Ember.inject.service(),
        admintool: Ember.inject.service(),
        intl: Ember.inject.service(),
        limit: 25,
        page: 1,
        errors: Ember.A([]),
        translations: {
            twentyfive: 25,
            fifty: 50,
            hundred: 100,
        },
        init_functions: function () {
            this._super();
            return this.refreshQueries();
        },
        initColSelecter: function () {
            //close display-menu only if clicked outside of it
            $('.display-menu').on('click', function (event) {
                if (!$(this).hasClass('open')) {
                    $(this).addClass('open');
                }
            });
            return $('body').on('click touchend', function (e) {
                if (
                    !$('.display-menu').is(e.target) &&
                    $('.display-menu').has(e.target).length === 0 &&
                    $('.open').has(e.target).length === 0
                ) {
                    $('.display-menu').removeClass('open');
                }
            });
        },
        showMassEdit: Ember.computed('selectedRows', 'formName', function () {
            return (
                this.get('selectedRows') &&
                this.get('Collector').testNeeds([this.get('formName') + '@edit'])
            );
        }),
        settings: Ember.computed('formName', 'columnString', function () {
            var settings;
            if (!this.get('formName')) {
                return;
            }
            settings = Ember.Object.create({
                form: this.get('formName'),
                show_columns: this.get('columnString'),
                limit: this.get('limit'),
            });
            return settings;
        }),
        customQueryparams: Ember.computed(function () {
            var formFeatures, forms;
            forms = this.get('Collector').forms();
            if (forms[this.get('formName')].features) {
                formFeatures = forms[this.get('formName')].features;
            }
            if (formFeatures && Object.keys(formFeatures).includes('custom_queryparams')) {
                return Object.values(formFeatures)[0];
            }
            return false;
        }),
        rowActions: Ember.computed('formName', 'showStatus', function () {
            var actions, c, form;
            c = this.get('Collector');
            form = this.get('formName');
            if (this.get('showStatus') === 'removed' && c.privilege(form, 'remove')) {
                actions = [
                    {
                        action: 'restoreRow',
                        icon: 'move move-ReuseFilled',
                        text: this.get('intl').t('report.restore_rows'),
                    },
                    {
                        action: 'historyRow',
                        icon: 'move move-Search',
                        text: this.get('intl').t('report.customer.history'),
                    },
                ];
                if (c.privilege(form, 'edit')) {
                    actions.push({
                        action: 'editRow',
                        icon: 'move move-Pencil',
                        text: this.get('intl').t('general.edit'),
                    });
                }
            } else {
                // aika purkka, mut what you gonna do kun ei oo kauheen customoitava tämä
                if (form === 'promomessage') {
                    actions = [
                        {
                            action: 'historyRow',
                            icon: 'move move-Search',
                            text: this.get('intl').t('report.customer.history'),
                        },
                        {
                            action: 'previewRow',
                            icon: 'move move-Globe',
                            text: this.get('intl').t('report.diaryreport_other_note.review'),
                        },
                    ];
                } else {
                    actions = [
                        {
                            action: 'historyRow',
                            icon: 'move move-Search',
                            text: this.get('intl').t('report.customer.history'),
                        },
                        {
                            action: 'viewRow',
                            icon: 'move move-View',
                            text: this.get('intl').t('history.action.show_data'),
                        },
                    ];
                }
                if (c.privilege(form, 'edit')) {
                    actions.push({
                        action: 'editRow',
                        icon: 'move move-Pencil',
                        text: this.get('intl').t('general.edit'),
                    });
                }
                if (c.privilege(form, 'remove')) {
                    actions.push({
                        action: 'removeRow',
                        icon: 'move move-Delete',
                        text: this.get('intl').t('report.delete_rows'),
                    });
                }
                if (form === 'customer') {
                    actions.push({
                        action: 'loginToCustomer',
                        icon: 'move move-Key',
                        text: this.get('intl').t('general.login'),
                    });
                }
            }
            return actions;
        }),
        refreshQueries: Ember.observer('filterValues', 'columnString', function () {
            if (!this.get('columnString')) {
                return;
            }
            return this.refreshReport(this.get('settings'));
        }),
        refreshReport: function (report) {
            var filterValues, form, query, showFiltersArr;
            filterValues = this.get('filterValues');
            if (!filterValues) {
                return;
            }
            form = report.get('form');
            showFiltersArr = this.get('filters').split(',');
            query = [];
            showFiltersArr.forEach((item) => {
                var filter, value;
                filter = filterValues.findBy('name', item);
                if (this.get('customQueryparams')) {
                    item = this.get('customQueryparams');
                }
                if (!filter) {
                    return;
                }
                value = filter.value;
                if (filter.name === 'groupBy') {
                    return report.set('group', filter.value);
                } else {
                    if (!value) {
                        return;
                    }
                    return query.pushObject({
                        name: item,
                        value: value,
                    });
                }
            });
            return report.set('query', query);
        },
        filters: Ember.computed('formName', function () {
            var c, fields, filters, splitted;
            if (!this.get('formName')) {
                return;
            }
            c = this.get('Collector');
            filters = [];
            fields = c.fieldArray(this.get('formName'));
            fields.forEach((field) => {
                var ref;
                if (
                    (ref = field.type) === 'database' ||
                    ref === 'date' ||
                    ref === 'dropdown_options'
                ) {
                    return filters.push(field.name);
                }
            });
            if (this.get('customQueryparams')) {
                splitted = this.get('customQueryparams').split(':');
                filters.splice(filters.indexOf(splitted[1]), 1);
            }
            filters.push('q');
            filters.push('groupBy');
            //@set('settings.showStatus', @get('row_info_status'))

            //bind = Ember.Binding.from('row_info_status').to('settings.showStatus')
            //bind.connect(@)
            return filters.join(',');
        }),
        columns: Ember.computed('formName', 'session.currentUser', 'currentTemplate', function () {
            var c, columns, fields, ls_columns;
            c = this.get('Collector');
            if (!this.get('formName')) {
                return;
            }
            if (!this.get('session.currentUser')) {
                return;
            }
            ls_columns = this.get('localStorageWrapper').getUserSetting(
                this.get('formName') + '_defaultColumns',
            );
            fields = c.fieldArray(this.get('formName'));
            if (ls_columns.length === 0) {
                ls_columns = fields.mapBy('name');
            }
            if (this.get('currentTemplate')) {
                ls_columns = this.get('currentTemplate.columns').split(',');
            }
            columns = Ember.A([]);
            ls_columns.forEach(function (column) {
                var field, ref;
                if (column === 'id' || column === 'row_info.created') {
                    return;
                }
                field = fields.findBy('name', column);
                if (!field) {
                    return;
                }
                if (!field.features.hide_column) {
                    if (
                        ls_columns.length < 1 ||
                        ((ref = field.name), indexOf1.call(ls_columns, ref) >= 0)
                    ) {
                        return columns.pushObject({
                            ext: field.name,
                            name: field.translated_name,
                        });
                    }
                }
            });
            if (ls_columns.length < 1 || indexOf1.call(ls_columns, 'row_info.created') >= 0) {
                columns.pushObject({
                    name: this.get('intl').t('general.row_info.created'),
                    ext: 'row_info.created',
                });
            }
            this.saveColumnsToLS(columns);
            return columns;
        }),
        hiddenColumns: Ember.computed('formName', 'columns.[]', function () {
            var c, col_arr, fields, hidden_columns;
            if (!this.get('formName')) {
                return;
            }
            if (!this.get('columns')) {
                return;
            }
            c = this.get('Collector');
            fields = c.fieldArray(this.get('formName'));
            hidden_columns = Ember.A([]);
            col_arr = this.get('columns').mapBy('ext');
            fields.forEach(function (field) {
                var ref;
                if (
                    ((ref = field.name), indexOf1.call(col_arr, ref) < 0) &&
                    !field.features.hide_column
                ) {
                    return hidden_columns.pushObject({
                        ext: field.name,
                        name: field.translated_name,
                    });
                }
            });
            if (indexOf1.call(col_arr, 'row_info.created') < 0) {
                hidden_columns.pushObject({
                    name: this.get('intl').t('general.row_info.created'),
                    ext: 'row_info.created',
                });
            }
            return hidden_columns;
        }),
        columnString: Ember.computed('columns.[]', function () {
            if (!this.get('columns')) {
                return;
            }
            return this.get('columns').mapBy('ext').join(',');
        }),
        selectedRows: Ember.computed('_checkedRows.[]', function () {
            if (this.get('_checkedRows')) {
                return this.get('_checkedRows').mapBy('id').join(',');
            }
        }),
        moreLinksClass: Em.computed('_checkedRows.[]', function () {
            if (this.get('_checkedRows.length') === 0) {
                return 'disabled';
            }
        }),
        status: Em.computed(function () {
            return this.get('statusOptions')[0];
        }),
        statusOptions: Ember.computed('formName', function () {
            var field, intl, option, options;
            if (!this.get('formName')) {
                return [];
            }
            if (!this.get('Collector').fieldExists(this.get('formName'), 'status')) {
                return [];
            }
            intl = this.get('intl');
            field = this.get('Collector').field(this.get('formName'), 'status');
            options = (function () {
                var i, len, ref, results;
                ref = field.options;
                results = [];
                for (i = 0, len = ref.length; i < len; i++) {
                    option = ref[i];
                    if (intl.exists('status.' + option)) {
                        results.push({
                            id: option,
                            name: intl.t('status.' + option),
                        });
                    } else if (Ember.typeOf(option) === 'object' && option.value) {
                        results.push({
                            id: option.id,
                            name: option.value,
                        });
                    } else {
                        results.push({
                            id: option,
                            name: option,
                        });
                    }
                }
                return results;
            })();
            options.unshift({
                id: '',
                name: intl.t('status.all'),
            });
            return options;
        }),
        createComponent: function (settings) {
            return formReport.create({
                store: this.get('store'),
                Collector: this.get('Collector'),
                intl: this.get('intl'),
                sessionGlobals: this.get('sessionGlobals'),
                rowsPerPage: 'all',
                renderer: this,
                checked_rows: this.get('_checkedRows')
                    ? this.get('_checkedRows').map((item) => {
                          return item.get('id');
                      })
                    : void 0,
                status: this.get('status.id'),
                settings: settings,
            });
        },
        getPdfAttachmentForEmail: function (params) {
            if (Ember.get(params, 'type') === 'export_pdf_full') {
                return this.getTtapiPdf('base64', params);
            } else {
                return this._export_pdf(params, 'base64');
            }
        },
        actions: {
            handleExportActions: function (params) {
                switch (params.type) {
                    case 'export_pdf':
                        return this.send('export_pdf', params);
                    case 'export_pdf_full':
                        return this.send('export_pdf_full', params);
                    case 'export_excel':
                        return this.send('export_excel', params);
                }
            },
            export: function (type) {
                this.set('showEmailModal', true);
                return this.set('exportInitType', type);
            },
            exportJoinedATReport: function () {
                var c;
                c = this.get('Collector');
                return this.get('admintool')
                    .ajax({
                        url: 'joined_billinginfo',
                    })
                    .then((rows) => {
                        var billing_columns,
                            col,
                            customer_columns,
                            element,
                            joined_columns,
                            translated_columns;
                        customer_columns = c.fields('customer');
                        billing_columns = c.fields('billinginfo');
                        joined_columns = [];
                        translated_columns = [];
                        for (col in customer_columns) {
                            translated_columns.push(c.getTranslationForColumn('customer', col));
                            joined_columns.push(col);
                        }
                        for (col in billing_columns) {
                            translated_columns.push(c.getTranslationForColumn('billinginfo', col));
                            joined_columns.push(col);
                        }
                        element = this.createCsvElement(joined_columns, rows, translated_columns);
                        return this.createCsvFromElements(element, false);
                    });
            },
            deleteChecked: function () {
                if (this.get('_checkedRows.length') > 0) {
                    return this.get('dialogs')
                        .confirm(this.get('intl').t('report.confirm_delete'))
                        .then((con) => {
                            var show_error;
                            if (con) {
                                show_error = false;
                                this.get('_checkedRows').forEach((row) => {
                                    var record;
                                    record = this.get('store').peekRecord(
                                        this.get('formName'),
                                        row.id,
                                    );
                                    if (record) {
                                        return record.destroyRecord();
                                    } else {
                                        return this.get('store')
                                            .findRecord(this.get('formName'), row.id)
                                            .then(function (record) {
                                                return record.destroyRecord();
                                            });
                                    }
                                });
                                return this.set('_checkedRows', Em.A([]));
                            }
                        });
                }
            },
            restoreChecked: function () {
                if (this.get('_checkedRows.length') > 0) {
                    return this.get('dialogs')
                        .confirm(this.get('intl').t('report.confirm_restore_rows'))
                        .then((con) => {
                            var form, form_plural;
                            if (con) {
                                form = this.get('formName');
                                form_plural = pluralize(this.get('formName')).camelize();
                                this.get('_checkedRows').forEach((row) => {
                                    var record;
                                    record = this.get('store').peekRecord(
                                        this.get('formName'),
                                        row.id,
                                    );
                                    return this.get('capi')
                                        .request(
                                            '/' + form_plural + '/' + row.id + '?validation=off',
                                            {
                                                dataType: 'json',
                                                type: 'PUT',
                                                data:
                                                    '{"' + form + '":{"row_info.status":"normal"}}',
                                            },
                                        )
                                        .then(() => {
                                            return record.unloadRecord();
                                        });
                                });
                                return this.set('_checkedRows', Em.A([]));
                            }
                        });
                }
            },
            export_csv: function () {
                var component, csv_str;
                // pretty much copy & paste code from export_pdf
                csv_str = [];
                component = this.createComponent(this.get('settings'));
                return component.get('asCsvArray').then((csvArr) => {
                    var a, csvString, name;
                    csvArr.forEach((arr) => {
                        var i, indexOf, len, str;
                        //remove linebreaks from csv string to avoid invalid format
                        for (indexOf = i = 0, len = arr.length; i < len; indexOf = ++i) {
                            str = arr[indexOf];
                            if (typeof str === 'string') {
                                arr[indexOf] = str.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
                            }
                        }
                        return csv_str.push(arr.join(';'));
                    });
                    name = this.get('session.currentUser.company') || 'movenium_export';
                    if (typeof LocalFileSystem !== 'undefined' && LocalFileSystem !== null) {
                        return window.requestFileSystem(
                            LocalFileSystem.PERSISTENT,
                            0,
                            function (fileSystem) {
                                return fileSystem.root.getFile(
                                    name + '.csv',
                                    {
                                        create: true,
                                    },
                                    function (entry) {
                                        var fileEntry;
                                        fileEntry = entry;
                                        return entry.createWriter(function (writer) {
                                            //write file and open
                                            writer.onwrite = function (evt) {
                                                return cordova.plugins.fileOpener2.open(
                                                    fileEntry.toURL(),
                                                    'text/csv',
                                                );
                                            };
                                            return writer.write(csv_str);
                                        });
                                    },
                                );
                            },
                        );
                    } else {
                        //download csv for desktop
                        csvString = csv_str.join('\n');
                        a = document.createElement('a');
                        a.setAttribute(
                            'href',
                            'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvString),
                        );
                        a.download = name + '.csv';
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        return a.click();
                    }
                });
            },
            export_excel: function (params) {
                var component;
                component = this.createComponent(this.get('settings'));
                if (params.template) {
                    component.set('show_columns', Ember.get(params, 'template.columns'));
                }
                return component.get('asXlsArray').then((output) => {
                    var name;
                    name = this.get('session.currentUser.company' || 'movenium_export');
                    this.get('excel').export(output, {
                        fileName: name + '.xlsx',
                    });
                    return this.set('showEmailModal', false);
                });
            },
            export_pdf_full: function (params) {
                var promise;
                this.set('showLoader', true);
                promise = this.getTtapiPdf(null, params);
                if (promise) {
                    promise.then((content) => {
                        var data;
                        data = JSON.parse(content);
                        this.set('showLoader', false);
                        if (data.url) {
                            return window.open(data.url);
                        }
                    });
                    promise.catch((e) => {
                        return alert('pdf export failed: ' + JSON.stringify(e));
                    });
                    promise.finally(() => {
                        this.set('showLoader', false);
                        return this.set('showEmailModal', false);
                    });
                }
            },
            export_pdf: function (params = {}) {
                this.set('showLoader', true);
                return this._export_pdf(params)
                    .then((file) => {
                        if (typeof cordova !== 'undefined' && cordova !== null) {
                            return this.get('cordova').openFile(file, 'application/pdf');
                        }
                    })
                    .finally(() => {
                        this.set('showEmailModal', false);
                        return this.set('showLoader', false);
                    });
            },
            setStatus: function (status) {
                return this.set('status', status);
            },
            setRowsPerPage: function (limit) {
                this.set('page', 1);
                this.set('settings.limit', limit);
                return this.set('limit', limit);
            },
            editRow: function (row) {
                var ref, router;
                // dirrrrty haxx for admintool
                if (this.get('formName') === 'customer') {
                    return this.transitionToRoute('admintool.customer', row.get('id'));
                } else {
                    router = Ember.getOwner(this).lookup('router:main');
                    if (
                        ((ref = 'forms.' + this.get('formName') + '.edit'),
                        indexOf1.call(Object.keys(router._routerMicrolib.recognizer.names), ref) >=
                            0)
                    ) {
                        return this.transitionToRoute(
                            'forms.' + this.get('formName') + '.edit',
                            row.get('id'),
                        );
                    } else {
                        return this.transitionToRoute(
                            'forms.form-edit',
                            this.get('formName'),
                            row.get('id'),
                        );
                    }
                }
            },
            historyRow: function (row) {
                return this.transitionToRoute(
                    'forms.form-history',
                    this.get('formName'),
                    row.get('id'),
                );
            },
            viewRow: function (row) {
                var ref, router;
                router = Ember.getOwner(this).lookup('router:main');
                if (
                    ((ref = 'forms.' + this.get('formName') + '.view'),
                    indexOf1.call(Object.keys(router._routerMicrolib.recognizer.names), ref) >= 0)
                ) {
                    return this.transitionToRoute(
                        'forms.' + this.get('formName') + '.view',
                        row.get('id'),
                    );
                } else {
                    return this.transitionToRoute(
                        'forms.form-view',
                        this.get('formName'),
                        row.get('id'),
                    );
                }
            },
            previewRow: function (row) {
                this.set('modalContent', row);
                //@set 'title', row['title']
                //@set 'message', row['title']
                this.set('showModal', true);
            },
            closeModal: function () {
                return this.set('showModal', false);
            },
            restoreRow: function (row) {
                var form, form_plural;
                if (collector.hasErrors(this.get('formName'))) {
                    return;
                }
                form = this.get('formName');
                form_plural = pluralize(this.get('formName')).camelize();
                return this.get('dialogs')
                    .confirm(this.get('intl').t('report.confirm_restore'))
                    .then((con) => {
                        if (con) {
                            return this.get('capi')
                                .request('/' + form_plural + '/' + row.id + '?validation=off', {
                                    dataType: 'json',
                                    type: 'PUT',
                                    data: '{"' + form + '":{"row_info.status":"normal"}}',
                                })
                                .then(() => {
                                    row = this.get('store').peekRecord(form, row.id);
                                    return row.unloadRecord();
                                });
                        }
                    });
            },
            showColumn: function (col) {
                this.get('columns').pushObject(col);
                return this.saveColumnsToLS(this.get('columns'));
            },
            hideColumn: function (col) {
                this.get('columns').removeObject(col);
                return this.saveColumnsToLS(this.get('columns'));
            },
            moveCol: function (col, dir) {
                return this.moveElementInArray(this.get('columns'), col, dir);
            },
            sortDown: function (col) {
                return this.moveElementInArray(this.get('columns'), col, 1);
            },
            deactivateTemplate: function () {
                var lskey;
                lskey = this.get('formName') + '_defaultColumns';
                //_currentTemplate = @get('content.firstObject.constructor.modelName')+'_currentTemplate';
                //if @get('localStorageWrapper').getUserSetting(_currentTemplate).columns
                //    @get('localStorageWrapper').removeUserSetting(_currentTemplate)

                //if @get('localStorageKey')
                //    key = @get('localStorageKey')+'_defaultColumns'
                //else if @get('content.firstObject.constructor.modelName')
                //    key = @get('content.firstObject.constructor.modelName')+'_defaultColumns'
                if (lskey) {
                    this.get('localStorageWrapper').removeUserSetting(lskey);
                }
                return this.set('currentTemplate', null);
            },
            showRemovedRows: function () {
                this.set('page', 1);
                if (this.get('showStatus') === 'removed') {
                    this.set('showStatus', 'normal');
                } else {
                    this.set('showStatus', 'removed');
                }
                return this.set('settings.row_info_status', this.get('showStatus'));
            },
            showEmailDialog: function () {
                this.set('showEmailModal', true);
                this.set('exportInitType', 'email');
                return false;
            },
            removeModal: function () {
                this.set('emailSent', false);
                this.set('showEmailModal', false);
                return false;
            },
            sendEmail: function (to, title, content, params) {
                var formName, rowids;
                this.set('showLoader', true);
                formName = this.get('formName');
                rowids = this.get('_checkedRows')
                    ? this.get('_checkedRows').map((item) => {
                          return item.get('id');
                      })
                    : [];
                return this.getPdfAttachmentForEmail(params).then((pdf) => {
                    var promise;
                    promise = this.get('Collector').ttapi({
                        url: 'sendReportAsEmail',
                        data: {
                            to_address: to,
                            email: pdf,
                            content: content,
                            lang: this.get('intl.primaryLocale'),
                            includeApproval: Ember.get(params, 'approvalLink'),
                            form_name: formName,
                            rowids: rowids,
                            header:
                                this.get('intl').t('menu.' + formName) +
                                '-' +
                                this.get('intl').t('general.report'),
                        },
                        method: 'post',
                    });
                    promise.then(() => {
                        this.set('emailSent', true);
                        return this.set('showLoader', false);
                    });
                    return promise.catch(() => {
                        this.set('showLoader', false);
                        return this.set('emailError', true);
                    });
                });
            },
            loginToCustomer: function (row) {
                var url;
                url = null;
                if (row.campaign === 'visma' || row.campaign === 'entry_mini') {
                    url = config.APP.ENTRY_URL;
                }
                return $.ajax({
                    url:
                        config.APP.ADMINTOOLURL +
                        'getCustomerAdminUsers?partnerid=' +
                        row.partnerid,
                    type: 'GET',
                    headers: {
                        Authorization:
                            'Bearer ' + this.get('session.session.authenticated.access_token'),
                    },
                    success: (admins) => {
                        return this.get('tools').login(
                            {
                                username: admins['users'][0]['username'],
                                userid: admins['users'][0]['id'],
                                partnerid: row.partnerid,
                            },
                            url,
                        );
                    },
                });
            },
            checkedRows: function (rows) {
                this.set('_checkedRows', rows);
                this.set('thereIsCheckedRows', rows.length > 0);
            },
            toggleInlineEdit: function () {
                var promises;
                if (this.get('inlineEdit')) {
                    promises = [];
                    this.get('model').forEach(function (row) {
                        if (!row.get('isValid') || row.get('isError')) {
                            row.rollbackAttributes();
                            return promises.push(row.reload());
                        } else if (row.get('hasDirtyAttributes')) {
                            return row.rollbackAttributes();
                        }
                    });
                    return Ember.RSVP.Promise.all(promises).then(() => {
                        this.set('errors', Ember.A([]));
                        this.toggleProperty('inlineEdit');
                        this.set('timeout', true);
                        return Em.run.later(
                            this,
                            function () {
                                return this.set('timeout', false);
                            },
                            2000,
                        );
                    });
                } else {
                    this.toggleProperty('inlineEdit');
                    this.set('addRows', Ember.A([]));
                    this.set('timeout', true);
                    return Em.run.later(
                        this,
                        function () {
                            return this.set('timeout', false);
                        },
                        2000,
                    );
                }
            },
            saveRows: function () {
                var allRows, errors, last_promise;
                errors = Ember.A([]);
                last_promise = new Ember.RSVP.Promise((resolve) => {
                    return resolve();
                });
                allRows = this.get('addRows').concat(this.get('model').toArray());
                allRows.forEach((row, index) => {
                    if (!row.get('hasDirtyAttributes')) {
                        return;
                    }
                    ++index;
                    return (last_promise = new Ember.RSVP.Promise((resolve) => {
                        return last_promise.then(() => {
                            return row.validate().then(() => {
                                return this.save_record_and_catch_errors(
                                    index,
                                    row,
                                    errors,
                                    resolve,
                                );
                            });
                        });
                    }));
                });
                last_promise.then(() => {
                    if (Ember.get(errors, 'length') === 0) {
                        return this.toggleProperty('inlineEdit');
                    }
                });
                return this.set('errors', errors);
            },
            copyRow: function (row) {
                var c;
                c = this.get('Collector');
                return this.get('addRows').unshiftObject(c.copyRecord(row));
            },
        },
        save_record_and_catch_errors: function (index, row, errors, resolve) {
            var promise;
            if (row.get('isValid')) {
                promise = this.save_record(row);
                promise.then(() => {
                    return resolve();
                });
                return promise.catch(function (e) {
                    var error;
                    //                row.rollbackAttributes()
                    if (e && e.errors[0] && e.errors[0].error_msg) {
                        error = e.errors[0].error_msg;
                    } else if (e && e.errors[0] && e.errors[0].error) {
                        error = e.errors[0].error;
                    } else {
                        error = 'unknown';
                    }
                    errors.pushObject({
                        index: index,
                        row: row.get('id'),
                        error: error,
                    });
                    return resolve();
                });
            } else {
                return errors.pushObject({
                    index: index,
                    row: row.get('id'),
                    error: row.get('errors.messages.firstObject'),
                });
            }
        },
        _export_pdf: function (params, mode = 'download') {
            return new Ember.RSVP.Promise((resolve) => {
                var component, promise, report;
                report = this.get('settings');
                component = this.createComponent(report);
                component.set('pdfSettings', params);
                if (params.template) {
                    component.set('show_columns', Ember.get(params, 'template.columns'));
                }
                promise = component.get('asPdfElement');
                return promise.then((element) => {
                    return this.createPdfFromElements([element], mode, '', 8, params).then(
                        (back) => {
                            return resolve(back);
                        },
                    );
                });
            });
        },
        getRawFilters: function () {
            var params;
            params = {};
            if (this.get('filterValues')) {
                this.get('filterValues').forEach((filter) => {
                    var add;
                    if (
                        Ember.isArray(Ember.get(filter, 'value')) &&
                        Ember.get(filter, 'value').length === 0
                    ) {
                    } else if (Ember.get(filter, 'bindto')) {
                        return (params['q'] = Ember.get(filter, 'value'));
                    } else if (Ember.get(filter, 'value') && Ember.get(filter, 'subform')) {
                        add = {};
                        add[Ember.get(filter, 'name')] = Ember.get(filter, 'value');
                        return (params[Ember.get(filter, 'form')] = add);
                    } else if (
                        Ember.get(filter, 'value') &&
                        Ember.get(filter, 'name') !== 'status'
                    ) {
                        return (params[Ember.get(filter, 'name')] = Ember.get(filter, 'value'));
                    }
                });
            }
            return params;
        },
        getTtapiPdf: function (format = null, params) {
            var postParams, url;
            url = 'pdf/row';
            postParams = {
                form: this.get('formName'),
                lang: moment.locale(),
                filters: JSON.stringify(this.getRawFilters()),
            };
            if (this.get('thereIsCheckedRows')) {
                postParams.id = this.get('_checkedRows').mapBy('id').join(',');
            }
            if (params.template) {
                postParams.template = Ember.get(params, 'template.id');
            }
            if (format) {
                postParams.type = format;
            }
            return this.get('Collector').ttapi({
                dataType: 'text',
                url: url,
                data: postParams,
            });
        },
        thereIsCheckedRows: false,
        formContainsApprovedStatus: Ember.computed('formName', function () {
            var field, fields, found;
            fields = this.get('Collector').fields(this.get('formName'));
            found = false;
            for (field in fields) {
                if (
                    fields[field].type === 'status' &&
                    indexOf1.call(fields[field].options, 'approved') >= 0
                ) {
                    found = true;
                }
            }
            return found;
        }),
        showApprovalLinkSelection: Ember.computed.and(
            'thereIsCheckedRows',
            'formContainsApprovedStatus',
        ),
        moveElementInArray: function (obj, col, offset) {
            var index, newIndex;
            index = obj.indexOf(col);
            obj.removeObject(col);
            newIndex = index + offset;
            if (newIndex > obj.length) {
                newIndex = 0;
            } else if (newIndex < 0) {
                newIndex = obj.length;
            }
            obj.insertAt(newIndex, col);
            return this.saveColumnsToLS(obj);
        },
        saveColumnsToLS: function (columns) {
            var cols, ls, lskey;
            //return if @get('currentTemplate')
            cols = columns.mapBy('ext');
            ls = this.get('localStorageWrapper');
            lskey = this.get('formName') + '_defaultColumns';
            return ls.setUserSetting(lskey, cols);
        },
    },
);

export default FormsFormReportController;
