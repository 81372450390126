import Controller from '@ember/controller';
import EmberObject, { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { htmlSafe } from '@ember/string';
import config from 'tt4/config/environment';
import { isEmpty } from '@ember/utils';

export default Controller.extend({
    intl: service(),
    Collector: service('collector-service'),

    init() {
        this._super(...arguments);
        this.columnList = ['name', 'tv_status', 'date_checked', 'tv_pdf'];
    },
    content: alias('model.users'),
    statusFilter: alias('model.status'),

    translated_columns: computed(function () {
        return get(this, 'columnList').map((column) => {
            return get(this, 'intl').t('report.employer.' + column);
        });
    }),

    data: computed('content', function () {
        let model = get(this, 'content.employers');
        if (!model) return;

        if (this.statusFilter) {
            model = model.filter((row) => row.tv_status === this.statusFilter);
        }

        let formattedRows = A([]);

        model.forEach((row) => {
            row.tv_status = this.formatTvStatus(row.tv_status);
            row.tv_pdf = !isEmpty(row.tv_pdf)
                ? get(this, 'Collector')._format_(row.tv_pdf, 'employer', 'tv_pdf')
                : [];

            formattedRows.push(EmberObject.create(row));
        });

        return formattedRows;
    }),

    formatTvStatus(status) {
        if (!status) return;
        let formatted = get(this, 'intl').t('employer.tv_status.' + status);

        if (status !== 'ei_loydy') {
            return htmlSafe(
                '<img class="report-img" src="' +
                    config.APP.CDN_URL +
                    '/images/tv' +
                    status +
                    '.png" title="' +
                    formatted +
                    '">',
            );
        } else {
            return get(this, 'intl').t('employer.tv_status.' + status);
        }
    },
});
