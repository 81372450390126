/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditIndexRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

UsersEditIndexRoute = GeneralAddRoute.extend({
    edit: true,
    controllerName: 'users/edit/index',
    model: function () {
        return this.modelFor('users.edit');
    },
});

export default UsersEditIndexRoute;
