import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import config from 'tt4/config/environment';
import { inject as service } from '@ember/service';

export default Component.extend({
    gui: service(),
    error: service(),
    cc: service(),
    session: service(),

    capi_url: computed(function () {
        return config.environment === 'development'
            ? 'http://capi/'
            : config.APP.COLLECTOR_API_URL + '/';
    }),

    init() {
        this._super();
        this.syncFromVismanet().then(this.syncReady.bind(this), this.syncFailed.bind(this));
    },

    syncFromVismanet() {
        return get(this, 'cc').request('general_vismanet', {
            headers: {
                Authorization: 'Bearer ' + get(this, 'session.session.authenticated.access_token'),
            },
            data: {
                capi_url: get(this, 'capi_url'),
            },
        });
    },

    syncReady() {
        set(this, 'done', true);
    },

    syncFailed(reason) {
        set(this, 'errorMessage', get(this, 'error').toString(reason));
    },
});
