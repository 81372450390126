import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'tt4/config/environment';

export default Component.extend({
    ajax: service(),
    session: service(),
    show: false,

    info: computed('value', function () {
        if (get(this, 'value.success')) return get(this, 'value.success');
        return get(this, 'value.error');
    }),

    rowActions: computed('value', function () {
        if (get(this, 'value.error'))
            return { label: 'label-danger', value: get(this, 'value.error').substring(0, 100) };

        const stamps = get(this, 'value.success.stamps');
        if (get(this, 'value.success.imei_not_found'))
            return { label: 'label-danger', value: 'imei not found' };
        if (!stamps) return { label: 'label-danger', value: 'skipped' };

        const parnterids = Object.keys(stamps);

        const actions = [];

        parnterids.forEach((partnerid) => {
            const stamp = stamps[partnerid];
            if (get(stamp, 'worktime.worktime.id')) {
                actions.push(
                    get(stamp, 'worktime.worktime.endtime') ? 'worktime ended' : 'worktime started',
                );
            }
            if (get(stamp, 'presence.presence.id')) {
                actions.push(
                    get(stamp, 'presence.presence.out') ? 'presence ended' : 'presence started',
                );
            }
        });

        if (actions.length == 1) return { label: 'label-warning', value: actions[0] };
        if (actions.length > 1) return { label: 'label-warning', value: 'many actions' };

        return { label: 'label-danger', value: 'skipped' };
    }),

    actions: {
        sendStamp(message) {
            get(this, 'ajax')
                .request(config.APP.REALTTAPI + '/api/sendstamp', {
                    type: 'POST',
                    headers: {
                        Authorization:
                            'Bearer ' + get(this, 'session.session.authenticated.access_token'),
                    },
                    data: 'base64:' + btoa(message),
                })
                .then(this._showMessage.bind(this));
        },
    },
    _showMessage(resp) {
        set(this, 'responseMessage', resp.message);
    },
});
