/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/drop-down.coffee.old
// Generated by CoffeeScript 2.6.1
var DropDownComponent;

import Ember from 'ember';

DropDownComponent = Ember.Component.extend({
    sessionGlobals: Ember.inject.service(),

    // Properties

    // Value of the selected option
    value: null,
    // Prompt that is shown if nothing is selected
    prompt: null,
    autoclear: false,
    // Options to show in the dropdown
    // FORMAT: [
    //   {id: <string|number>, name: <string|number>}
    // ]
    options: null,
    // default class
    classes: 'form-control',
    optionGroups: null,
    disabled: false,
    didRender: function () {
        var select;
        // hax for ff .. https://stackoverflow.com/a/10096033
        select = this.element.getElementsByTagName('select')[0];
        return (select.value = this.get('value') ? this.get('value') : '');
    },
    showingRemoved: Em.computed('value', function () {
        var show_removed_arr;
        if (this.get('useAsFilter')) {
            show_removed_arr = this.get('sessionGlobals').get('show_removed');
            if (show_removed_arr && show_removed_arr[this.get('field.name')]) {
                return true;
            } else {
                return false;
            }
        }
    }),

    // Actions

    actions: {
        // Sets the selected ID to components property VALUE
        select: function (id) {
            // if we use this as a filter, do not update value as it can overwrite database-field defaultValueForNormalDropdown-computed
            if (this.get('useAsFilter')) {
                if (id === 'show_removed') {
                    this.set('showingRemoved', true);
                }
                if (id === 'hide_removed') {
                    this.set('showingRemoved', false);
                }
                this.sendAction('bubbleChange', id);
                return;
            }
            if (id !== 'show_removed' && id !== 'hide_removed') {
                this.set('value', id);
            }
            // very ugly hack but does it's thing
            if (this.get('autoclear')) {
                this.set('optionGroups', true);
                Em.run.later(
                    this,
                    () => {
                        this.set('value', null);
                        return this.set('optionGroups', false);
                    },
                    100,
                );
            }
            return this.sendAction('bubbleChange', id);
        },
    },
});

export default DropDownComponent;
