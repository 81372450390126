/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/billing/bill-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsBillingBillCompComponent;

import Ember from 'ember';

import exportFile from '../../../../mixins/export-file';

SettingsBillingBillCompComponent = Ember.Component.extend(exportFile, {
    intl: Ember.inject.service(),
    billrows: Em.A([]),
    showCurrency: null,
    month: Ember.computed(function () {
        return moment().format('YYYY-MM-DD');
    }),
    last_month: Em.computed('month', function () {
        return moment(this.get('month')).subtract(1, 'month').format('YYYY-MM-DD');
    }),
    billdate: Ember.computed('month', function () {
        return moment(this.get('month')).subtract(1, 'month').endOf('month').format('L');
    }),
    getBillRows: Em.on('init', function () {
        var promise;
        promise = this.get('Collector').ttapi({
            url: 'billing',
            data: {
                month: this.get('last_month'),
            },
        });
        return promise.then((bills) => {
            return this.set('billrows', bills);
        });
    }),
    fullPrice: Em.computed('billrows', function () {
        var currency, price;
        price = 0;
        currency = null;
        this.get('billrows').forEach((item) => {
            price += item.price * item.amount;
            return (currency = this.currencyByCountry(item.country));
        });
        if (currency) {
            this.set('showCurrency', currency);
        }
        return price;
    }),
    _billrows: Em.computed('billrows', function () {
        return this.get('billrows').map((row) => {
            row.currency = this.currencyByCountry(row.country);
            row.formatted_snapshot = this.formatSnapshot(row.snapshot);
            return row;
        });
    }),
    currencyByCountry: function (country) {
        switch (country) {
            case 'se':
                return 'sek';
            case 'fi':
                return 'eur';
            default:
                return 'eur';
        }
    },
    formatSnapshot: function (snapshot_str) {
        var days, intl, name, snapshot, str;
        intl = this.get('intl');
        snapshot = JSON.parse(snapshot_str);
        str = '';
        for (name in snapshot) {
            days = snapshot[name];
            if (!Ember.isArray(days)) {
                continue;
            }
            str +=
                '<b>' +
                decodeURIComponent(name) +
                '</b>: <code>' +
                days.join(',') +
                '</code>' +
                intl.t('settings.billing.total') +
                ': ' +
                days.length +
                '<br>';
        }
        return Ember.String.htmlSafe(str);
    },
    actions: {
        export: function (rows) {
            var csv;
            csv = 'product;description;price;currency;amount';
            rows.forEach((row) => {
                csv +=
                    '\n' +
                    this.get('intl').t('billing.products.title.' + row.customerproduct.name) +
                    ';';
                csv += row.description + ';';
                csv += row.price + ';';
                csv += row.currency + ';';
                //csv += (row.campaign or "") + ";"
                return (csv += row.amount);
            });
            return this.exportFile(csv, 'bill', 'csv');
        },
    },
});

export default SettingsBillingBillCompComponent;
