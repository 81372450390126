/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAddIndexRoute;

import Ember from 'ember';

OrientationsAddIndexRoute = Ember.Route.extend({
    session: Em.inject.service(),
    settings: Em.inject.service('customer-settings'),
    // when orientations are saved and user comes back to .add route create new orientation
    beforeModel: function () {
        var checkAll, checkAllBoxes, orientations, ref;
        orientations = this.controllerFor('orientations/add');
        if (!orientations.get('model')) {
            return;
        }
        if (!orientations.get('model.record')) {
            checkAll = this.get('settings.settings').filterBy('key', 'orientations_check_all');
            checkAllBoxes = false;
            if (checkAll.length > 0 && ((ref = checkAll[0].value) === 'on' || ref === 'true')) {
                checkAllBoxes = true;
            }
            // reset controller
            orientations.set('employer_signature', null);
            orientations.set('users', Ember.A([]));
            orientations.set('place', null);
            orientations.set('selected_parents', {});
            orientations.set('disableLinks', null);
            orientations.get('model.checkbox_groups').forEach(function (item) {
                return item.options.forEach(function (option) {
                    return option.set('checked', checkAllBoxes ? true : false);
                });
            });
            return orientations.set('model.record', this.store.createRecord('orientation'));
        }
    },
    afterModel: function (model) {
        var controller, orientations, users_oriented, users_oriented_obj;
        // if project is preselected change it also to the record
        if (this.controllerFor('orientations/add').projectRecord) {
            model.set('record.ori_site', this.controllerFor('orientations/add').projectRecord);
        }
        if (this.get('session.currentUser.currentMySite')) {
            controller = this.controllerFor('orientations/add/index');
            //console.log @get "session.currentUser.currentMySite"
            model.set('record.ori_site', this.get('session.currentUser.currentMySite'));
            //find users which have orientations to this project
            orientations = this.store.query('orientation', {
                ori_site: this.get('session.currentUser.currentMySite.id'),
                sideload: true,
            });
            users_oriented = [];
            users_oriented_obj = [];
            return orientations.then((oris) => {
                oris.forEach((ori) => {
                    users_oriented.push(ori.get('ori_user.id'));
                    return users_oriented_obj.push(ori.get('ori_user'));
                });
                controller.set('skip_users_obj', users_oriented_obj);
                return controller.set('skip_users', users_oriented);
            });
        }
    },
});

//controller.send 'changeSelectedProject', @get "session.currentUser.currentMySite"
export default OrientationsAddIndexRoute;
