/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/invite-users/route.coffee.old
// Generated by CoffeeScript 2.6.1
var InviteUsersRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import pdfMixin from '../../mixins/pdf-export';

InviteUsersRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    //activate: ->
    //    @set 'controller.field.value', null
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            this.set('controller.projectid', null);
            this.set('controller.magicKey', null);
            return this.set('controller.field.value', null);
        }
    },
});

export default InviteUsersRoute;
