import GeneralAddController from 'tt4/controllers/general-add';
import { A } from '@ember/array';

const WorktasksEditController = GeneralAddController.extend({
    edit: true,
    fieldSettings: A({
        edit: true,
    }),
});

export default WorktasksEditController;
