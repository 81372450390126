import Component from '@ember/component';
import { notEmpty } from '@ember/object/computed';
import { computed, get } from '@ember/object';

export default Component.extend({
    tagName: 'td',
    classNames: ['day-cell'],
    classNameBindings: ['hasRows:day-empty', 'isWeekend:weekend'],
    hasRows: notEmpty('rows'),

    isWeekend: computed(function () {
        const weekday = moment(get(this, 'date')).format('d');
        if (weekday == 0 || weekday == 6) return true;

        return false;
    }),
});
