import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),
    flags: service(),
    totalDaysLeft: 0,

    init() {
        this._super();
        this.headers = ['year', 'days', 'daysleft'];
        if (this.Collector.testNeedsOne('modules.holiday_pay') && this.flags.test('holidaypay_v2'))
            this.headers.push('holidayPays');
        this.form = 'holiday';
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    },

    didInsertElement() {
        set(this, 'data', this.getHolidays());
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    },

    onStoreEvent(params) {
        if (params.type === 'abcense') set(this, 'data', this.getHolidays());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'data', this.getHolidays());
    },

    async getHolidays() {
        if (this.flags.test('holiday-checker')) return this.newGetHolidays();

        if (!this.Collector.testNeedsOne('abcense')) return [];

        let holidaySeasonChangeDate = moment().startOf('year').add({ days: 1, months: 4 });
        let holidaySeason = moment().isSameOrAfter(holidaySeasonChangeDate, 'day')
            ? moment().year() + 1
            : moment().year();
        // If it's currently 1.1 - 1.4, dont't show newest holiday season yet
        const isStartOfYear = moment().isBetween(
            moment().startOf('year'),
            moment().startOf('year').add({ months: 3 }),
            'day',
        );

        let arr = [];
        let years = [];

        if (!isStartOfYear) years.pushObject(holidaySeason + '-05-02');
        years.pushObject(holidaySeason - 1 + '-05-02');
        years.pushObject(holidaySeason - 2 + '-05-02');
        years.pushObject(holidaySeason - 3 + '-05-02');
        years.pushObject(holidaySeason - 4 + '-05-02');

        arr = await this.getMultiSeasonHolidays(years);
        let filteredArr = arr.filter((data) => data.daysleft > 0 || data.year == holidaySeason - 1 || data.holidayPays > 0);

        set(
            this,
            'totalDaysLeft',
            filteredArr.reduce((sum, data) => sum + (data.daysleft || 0), 0),
        );

        filteredArr = arr.filter(
            (data) =>
                data.daysleft > 0 ||
                data.year == holidaySeason - 1 ||
                (data.holidayPays > 0 && this.Collector.testNeedsOne('modules.holiday_pay')),
        );
        const totalDays = filteredArr.reduce(
            (sum, data) =>
                sum +
                (data.daysleft || 0) +
                (this.Collector.testNeedsOne('modules.holiday_pay') ? data.holidayPays || 0 : 0),
            0,
        );
        set(this, 'totalDaysLeft', totalDays);

        return filteredArr.reverse();
    },

    async getMultiSeasonHolidays(years) {
        let params = {
            id: this.session.currentUser.id,
            years: years.join(','),
            country: this.session.currentUser.company_info.country,
        };

        const url = 'api/getMultipleSeasons';

        let holidays = await get(this, 'Collector').ttapi({
            url: url,
            data: params,
        });

        return holidays.users.map((row) => {
            return {
                year: row.holidayYear,
                days: row.allDays,
                daysleft: row.daysLeft,
                holidayPays: row.availableHolidayPays,
            };
        });
    },

    /**
     * holidays are fetched directly from ttapi route api/getUserHolidays
     * @returns
     */
    async newGetHolidays() {
        if (!this.Collector.testNeedsOne('abcense')) return [];

        let params = {
            id: this.session.currentUser.id,
            country: this.session.currentUser.company_info.country,
        };

        const url = 'api/getUserHolidays';

        let holidays = await get(this, 'Collector').ttapi({
            url: url,
            data: params,
        });

        set(this, 'totalDaysLeft', (holidays.sums.daysleft + holidays.sums.holidayPays));

        // current year (key === 0) is always shown
        const yearsWithDaysLeft = holidays.years.filter(
            (year, key) => year.daysleft > 0 || key === 0 || year.holidayPays > 0,
        );
        return yearsWithDaysLeft;
    },
});
