/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-anmalan/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportAddNotesAnmalanRoute;

import Ember from 'ember';

import direktivRoute from '../notes-direktiv/route';

WorksitediaryReportAddNotesAnmalanRoute = direktivRoute.extend();

export default WorksitediaryReportAddNotesAnmalanRoute;
