/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/table-comp.coffee.old
// Generated by CoffeeScript 2.6.1
var TableCompComponent;

import Ember from 'ember';

import collector from '../classes/collector';

import pdfExport from '../mixins/pdf-export';

import $ from 'jquery';

import { observer } from '@ember/object';

TableCompComponent = Ember.Component.extend(pdfExport, {
    intl: Ember.inject.service(),
    translationPrefix: '',
    checkClass: 'unchecked',
    checkedClass: 'unchecked',
    rowAction: null,
    checkable: false,
    //when status field in form, always checkable
    ch: observer('canChangeStatus', function () {
        return this.set('checkable', this.get('canChangeStatus'));
    }),
    // noRows flag is true if data is loaded but it contains zero rows
    noRows: Em.computed('data.length', function () {
        var data;
        data = this.get('data');
        if (data == null) {
            return true;
        }
        if (!Ember.isArray(data)) {
            return false;
        }
        return data.length === 0;
    }),
    // get translation for every column
    _translatedColumns: Em.computed('columns', function () {
        var columns, intl, translated_columns, translationPrefix;
        translationPrefix = this.get('translationPrefix');
        if (translationPrefix) {
            columns = this.get('columns');
            intl = this.get('intl');
            translated_columns = [];
            $.each(columns, (key, item) => {
                return translated_columns.push(intl.t(translationPrefix + '.' + item));
            });
            return translated_columns;
        }
        return this.get('translated_columns');
    }),
    lockedActions: Em.computed('rowActions', function () {
        var filteredActions;
        filteredActions = this.get('rowActions').filter((rowAction) => {
            return !['remove', 'edit', 'delete'].find((actionSubString) => {
                return rowAction.action.includes(actionSubString);
            });
        });
        filteredActions.push({
            icon: 'move move-Lock',
        });
        return filteredActions;
    }),
    notEditableActions: Em.computed('rowActions', function () {
        var filteredActions;
        filteredActions = this.get('rowActions').filter((rowAction) => {
            return !['remove', 'edit', 'delete'].find((actionSubString) => {
                return rowAction.action.includes(actionSubString);
            });
        });
        return filteredActions;
    }),
    // order property for showing by what column we are ordering
    order: null,
    checked: Em.computed('data.@each.checkedClass', function () {
        var chk;
        chk = [];
        if (this.get('data')) {
            this.get('data').forEach((row) => {
                if (row.checkedClass === 'checked') {
                    return chk.pushObject(row);
                }
            });
        }
        if (chk.length === 0 || chk.length !== this.get('data.length')) {
            this.set('checkClass', 'unchecked');
        }
        if (chk.length === this.get('data.length')) {
            this.set('checkClass', 'checked');
        }
        return chk;
    }),
    defaultChangeStatus: Em.computed(function () {
        var obj;
        obj = {};
        obj.action = 'approved';
        obj.btn_class = 'btn-primary';
        obj.text = this.get('intl').t('report.approve_rows');
        return obj;
    }),
    showDefaultChangeStatus: Ember.computed('canChangeStatus', 'statusTypes', function () {
        var approvingIsAllowed;
        if (!this.get('canChangeStatus')) {
            return false;
        }
        approvingIsAllowed = this.get('statusTypes').mapBy('id').indexOf('approved') !== -1;
        return approvingIsAllowed;
    }),
    defaultChangeStatusAction: 'approved',
    canChangeStatus: Em.computed(function () {
        var field;
        field = collector.getFormObject(this.get('form') + '.status');
        if (field) {
            if (!field.features.readonly) {
                return true;
            }
        }
        return false;
    }),
    statusTypes: Em.computed('form', function () {
        var c, field, fieldOptions, intl, option, options;
        if (!this.get('form')) {
            return [];
        }
        c = this.get('Collector');
        intl = this.get('intl');
        field = c.field(this.get('form'), 'status');
        if (field.features.only_options) {
            fieldOptions = field.features.only_options.split(',');
        } else {
            fieldOptions = field.options;
        }
        options = (function () {
            var i, len, results;
            results = [];
            for (i = 0, len = fieldOptions.length; i < len; i++) {
                option = fieldOptions[i];
                if (intl.exists('status.' + option)) {
                    results.push({
                        id: option,
                        name: intl.t('report.' + option + '_rows'),
                    });
                } else {
                    results.push({
                        id: option,
                        name: intl.t('report.change_status') + option,
                    });
                }
            }
            return results;
        })();
        return options;
    }),
    asPdfElement: Ember.computed(function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            return resolve(
                this.createPdfTableElement(
                    this.get('columnList'),
                    this.get('data'),
                    this.get('_translatedColumns'),
                ),
            );
        });
    }),
    columnList: Ember.computed('columns', function () {
        return this.get('columns').map((item) => {
            return this.columnKey(item);
        });
    }),
    columnKey: function (column) {
        var subfields, subform;
        [subform, subfields] = this.parseColumn(column);
        return subform + (subfields ? subfields[0] : '');
    },
    parseColumn: function (column) {
        var subfields, subform;
        if (column.indexOf('.') !== -1) {
            [subform, subfields] = column.split('.');
            subfields = [subfields];
        } else {
            subform = column;
            subfields = null;
        }
        return [subform, subfields];
    },
    canLock: Em.computed(function () {
        return this.get('Collector').testNeeds(['userlevel=5']);
    }),
    showMassedit: Ember.computed('settings.massedit', function () {
        if (this.get('Collector').testNeeds(['userlevel=3,5,6'])) {
            return true;
        }
    }),
    selectedRows: Ember.computed('checked.[]', function () {
        return this.get('checked').mapBy('id').join(',');
    }),
    getPdfFilters: function (rows) {
        var c, filter, filterName, filterValue, i, len, pdf_filters_body, ref, value;
        c = this.get('Collector');
        pdf_filters_body = [];
        ref = this.get('filters');
        for (i = 0, len = ref.length; i < len; i++) {
            filter = ref[i];
            if (filter.get('value') && filter.get('name') !== 'groupBy') {
                if (Ember.isArray(filter.get('value')) && filter.get('value.length') === 0) {
                    continue;
                }
                if (filter.get('type') === 'database') {
                    value = parseInt(filter.get('value'));
                } else {
                    value = filter.get('value');
                }
                if (filter.get('bindto') && !filter.get('field')) {
                    filterValue = value;
                    filterName = this.get('intl').t('filters.free_search');
                } else {
                    filterValue = c._format_(
                        value,
                        filter.get('form'),
                        filter.get('field'),
                        'pdf',
                        rows,
                    );
                    filterName = this.get('intl').t(filter.get('form') + '.' + filter.get('name'));
                }
                if (this.get('full_pdf_export')) {
                    pdf_filters_body.push([
                        {
                            text: filterName + ': ' + filterValue,
                            style: 'headertext',
                        },
                    ]);
                } else {
                    pdf_filters_body.push([filterName + ':', filterValue]);
                }
            }
        }
        return pdf_filters_body;
    },
    actions: {
        multisortColumns: function (key) {
            var c, obj;
            c = this.get('Collector');
            if (this.get('columns')[key] === 'row_info.created') {
                obj = {
                    form: this.get('form'),
                    type: 'row_info',
                    features: {},
                    ext: 'row_info.created',
                };
            } else {
                obj = c.field(this.get('form'), this.get('columns')[key]);
            }
            return this.sendAction('bubbleActions', 'columnClickedMulti', obj);
        },
        columnClicked: function (key) {
            if (this.get('multiSort')) {
                return this.send('multisortColumns', key);
            } else {
                return this.send('sortColumns', key);
            }
        },
        sortColumns: function (key) {
            var columns, currentOrder;
            columns = this.get('columns');
            currentOrder = this.get('order');
            if (currentOrder === key || currentOrder === null) {
                currentOrder = key + '-';
            } else {
                currentOrder = key;
            }
            this.set('order', currentOrder);
            return this.sendAction('bubbleActions', 'columnClicked', columns[key]);
        },
        check: function (row) {
            var params;
            if (Ember.get(row, 'checkedClass') === 'checked') {
                Ember.set(row, 'checkedClass', 'unchecked');
                params = {
                    id: Ember.get(row, 'id'),
                    action: 'remove',
                };
                this.sendAction('bubbleActions', 'checkRow', params);
            } else {
                Ember.set(row, 'checkedClass', 'checked');
                params = {
                    id: Ember.get(row, 'id'),
                    action: 'add',
                };
                this.sendAction('bubbleActions', 'checkRow', params);
            }
            return this.sendAction('bubbleActions', 'checkedRows', this.get('checked'));
        },
        checkAll: function () {
            this.toggleProperty('checkAll');
            this.get('data').forEach((row) => {
                var params;
                if (this.get('checkAll')) {
                    Ember.set(row, 'checkedClass', 'checked');
                    this.set('checkClass', 'checked');
                    params = {
                        id: Ember.get(row, 'id'),
                        action: 'add',
                    };
                    return this.sendAction('bubbleActions', 'checkRow', params);
                } else {
                    Ember.set(row, 'checkedClass', 'unchecked');
                    this.set('checkClass', 'unchecked');
                    params = {
                        id: Ember.get(row, 'id'),
                        action: 'remove',
                    };
                    return this.sendAction('bubbleActions', 'checkRow', params);
                }
            });
            return this.sendAction('bubbleActions', 'checkedRows', this.get('checked'));
        },
        changeStatus: function (status) {
            var params;
            params = {};
            params.rows = this.get('checked');
            params.status = status;
            return this.sendAction('bubbleActions', 'changeStatus', params);
        },
        rowAction: function (action, row) {
            if (Em.isNone(action)) {
                return;
            }
            return this.sendAction('bubbleActions', action, row);
        },
        checkedActionClicked: function (action) {
            return this.sendAction('bubbleActions', 'checkedRowsAction', {
                action: action,
                rows: this.get('checked'),
            });
        },
        mutateRows: function (mutation) {
            return this.sendAction('bubbleActions', mutation);
        },
        export_pdf: function () {
            return this.get('asPdfElement').then((element) => {
                var elements, pdf_filters_body;
                elements = [element];
                if (this.get('filters')) {
                    pdf_filters_body = this.getPdfFilters(this.get('data'));
                    if (pdf_filters_body.length > 0) {
                        elements.unshift({
                            table: {
                                body: pdf_filters_body,
                            },
                            layout: 'noBorders',
                        });
                    }
                }
                return this.createPdfFromElements(elements).then((file) => {
                    if (typeof cordova !== 'undefined' && cordova !== null) {
                        return this.get('cordova').openFile(file, 'application/pdf');
                    }
                });
            });
        },
        lockRows: function (act, row) {
            var params, rows;
            rows = row ? [row] : this.get('checked');
            params = { act, rows };
            return this.sendAction('bubbleActions', 'lockRows', params);
        },
    },
});

export default TableCompComponent;
