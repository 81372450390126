import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class HolidayComponent extends Component {
    @service ttapi;

    @tracked dirty = true;
    @tracked initvalue;
    @tracked startdate;
    @tracked isLoading = false;
    @tracked loadingData = true;
    @tracked isOn = false;

    constructor() {
        super(...arguments);

        this.load();
    }

    async load() {
        const response = await this.ttapi.request('/api/holidays/settings');

        this.startdate = this.initvalue = response.startdate;

        if (response.startdate) this.isOn = true;

        this.loadingData = false;
    }

    async saveStartdate(startdate) {
        return await this.ttapi.request('/api/holidays/settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({ startdate: startdate }),
        });
    }

    @action
    toggle() {
        this.isOn = !this.isOn;

        if (!this.isOn) {
            this.startdate = this.initvalue = null;
            this.saveStartdate(null);
        }
    }

    @action
    async saveSetting() {
        this.isLoading = true;
        await this.saveStartdate(this.startdate);

        this.initvalue = this.startdate;
        this.isLoading = false;
    }
}
