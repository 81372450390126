/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/inactive/route.coffee.old
// Generated by CoffeeScript 2.6.1
var EmployersInactiveRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

EmployersInactiveRoute = GeneralReportRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    queryParams: {
        page: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var c;
        c = this.get('Collector');
        return c
            .ttapi({
                url: 'inactive_employers',
            })
            .then((data) => {
                var records;
                this.store.pushPayload('employer', data);
                records = Ember.A([]);
                if (data.employers) {
                    data.employers.forEach((employer) => {
                        return records.pushObject(this.store.peekRecord('employer', employer.id));
                    });
                }
                return data.employers;
            });
    },
});

export default EmployersInactiveRoute;
