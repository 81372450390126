/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/gps-location-map.coffee.old
// Generated by CoffeeScript 2.6.1
var GpsLocationMapComponent;

import Ember from 'ember';

import { getImageFromUrl, resizeImage } from 'tt4/utils/file-util';

import $ from 'jquery';

GpsLocationMapComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    google: Ember.inject.service(),
    map: null,
    //tmp array for markers
    markers: [],
    allMarkers: Em.A([]),
    allCircles: Em.A([]),
    allPaths: Em.A([]),
    zoomLevel: 8,
    skipPositionUpdate: false,
    //initialize map
    insertMap: Em.on('didInsertElement', function () {
        var container, firstobj, lat, lng, loc, map, options, self, styleArray;
        container = $('.map-canvas', this.element)[0];
        styleArray = [
            {
                featureType: 'road',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#0c9d58',
                    },
                ],
            },
            {
                featureType: 'water',
                stylers: [
                    {
                        hue: '#00ccff',
                    },
                    {
                        saturation: -22,
                    },
                ],
            },
            {
                featureType: 'landscape',
                stylers: [
                    {
                        hue: '#00ff5e',
                    },
                    {
                        saturation: -56,
                    },
                    {
                        lightness: 23,
                    },
                ],
            },
            {
                stylers: [
                    {
                        lightness: 21,
                    },
                    {
                        saturation: -26,
                    },
                ],
            },
        ];
        self = this;
        if (this.get('value')) {
            loc = JSON.parse(this.get('value'));
            lat = loc.latitude;
            lng = loc.longitude;
        } else if (this.get('wtmarkers') && this.get('wtmarkers.firstObject.wStartLoc')) {
            firstobj = this.get('wtmarkers.firstObject.wStartLoc');
            lat = firstobj.latitude;
            lng = firstobj.longitude;
        } else if (this.get('wtmarkers') && this.get('wtmarkers.firstObject.wEndLoc')) {
            firstobj = this.get('wtmarkers.firstObject.wEndLoc');
            lat = firstobj.latitude;
            lng = firstobj.longitude;
        } else if (this.get('projectmarkers') && this.get('projectmarkers.firstObject.project')) {
            firstobj = this.get('projectmarkers.firstObject.project');
            lat = firstobj.latitude;
            lng = firstobj.longitude;
        } else {
            lat = 61.084857;
            lng = 26.087036;
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(function (location) {
                    var locs;
                    lat = location.coords.latitude;
                    lng = location.coords.longitude;
                    locs = new window.google.maps.LatLng(lat, lng);
                    map.panTo(locs);
                    return map.setZoom(15);
                });
            }
        }
        options = {
            center: new window.google.maps.LatLng(lat, lng),
            zoom: self.get('zoomLevel'),
            styles: styleArray,
            disableDefaultUI: true,
            zoomControl: true,
        };
        map = new window.google.maps.Map(container, options);
        if (this.get('value')) {
            self.placeMarkerAndPanTo(new window.google.maps.LatLng(lat, lng), map);
        }
        if (this.get('projectmode')) {
            map.addListener('click', function (e) {
                return self.placeMarkerAndPanTo(e.latLng, map);
            });
        }
        if (this.get('wtmarkers')) {
            this.addWtMarkers(map);
        }
        if (this.get('projectmarkers')) {
            this.addProjectMarkers(map);
        }
        return this.set('map', map);
    }),
    observeMarkers: Em.observer('wtmarkers.[]', function () {
        this.clearMarkers();
        this.clearCircles();
        this.clearPaths();
        return this.addWtMarkers(this.get('map'));
    }),
    observeProjectMarkers: Em.observer('projectmarkers.[]', function () {
        this.clearMarkers();
        this.clearPaths();
        return this.addProjectMarkers(this.get('map'));
    }),
    clearMarkers: function () {
        this.setMap(null, this.get('allMarkers'));
        return this.set('allMarkers', Em.A([]));
    },
    clearCircles: function () {
        this.setMap(null, this.get('allCircles'));
        return this.set('allCircles', Em.A([]));
    },
    clearPaths: function () {
        this.setMap(null, this.get('allPaths'));
        return this.set('allPaths', Em.A([]));
    },
    setMap: function (map, objs) {
        var i, len, obj, results1;
        results1 = [];
        for (i = 0, len = objs.length; i < len; i++) {
            obj = objs[i];
            results1.push(obj.setMap(map));
        }
        return results1;
    },
    //add only project markers
    addProjectMarkers: function (map) {
        var count, i, label, len, m, plat, platlng, plng, ref, results1, self;
        self = this;
        count = 1;
        ref = this.projectmarkers;
        results1 = [];
        for (i = 0, len = ref.length; i < len; i++) {
            m = ref[i];
            plat = m.project.latitude;
            plng = m.project.longitude;
            platlng = new window.google.maps.LatLng(plat, plng);
            label = m.project.label;
            self.placeMarkerWithDelay('project', platlng, map, count * 100, label);
            results1.push(count++);
        }
        return results1;
    },
    //add worktime markers
    addWtMarkers: function (map) {
        var coords,
            count,
            endCoords,
            i,
            len,
            m,
            plat,
            platlng,
            plng,
            radius,
            ref,
            results1,
            self,
            wEndLat,
            wEndLatLng,
            wEndLng,
            wStartLat,
            wStartLatLng,
            wStartLng;
        self = this;
        count = 1;
        ref = this.wtmarkers;
        results1 = [];
        for (i = 0, len = ref.length; i < len; i++) {
            m = ref[i];
            //worktime markers
            if (m.wStartLoc) {
                wStartLat = m.wStartLoc.latitude;
                wStartLng = m.wStartLoc.longitude;
                wStartLatLng = new window.google.maps.LatLng(wStartLat, wStartLng);
            }
            if (m.wEndLoc) {
                wEndLat = m.wEndLoc.latitude;
                wEndLng = m.wEndLoc.longitude;
                wEndLatLng = new window.google.maps.LatLng(wEndLat, wEndLng);
            }
            //if accuracy is bigger than 100m (and lower than 10km), show circle around marker
            radius = 0;
            if (m.wStartLoc && m.wStartLoc.accuracy < 10000 && m.wStartLoc.accuracy > 100) {
                radius = m.wStartLoc.accuracy;
            }
            if (m.wStartLoc && m.wEndLoc) {
                self.placeMarkerWithDelay('worktimeEnd', wEndLatLng, map, count * 100, m.wLabel);
                self.placeMarkerWithDelay(
                    'worktimeStart',
                    wStartLatLng,
                    map,
                    count * 100,
                    m.wLabel,
                    radius,
                );
            } else if (!m.wStartLoc && m.wEndLoc) {
                self.placeMarkerWithDelay('worktime', wEndLatLng, map, count * 100, m.wLabel);
            } else {
                self.placeMarkerWithDelay(
                    'worktime',
                    wStartLatLng,
                    map,
                    count * 100,
                    m.wLabel,
                    radius,
                );
            }
            count++;
            //add also project marker and line
            if (m.project) {
                //project markers
                plat = m.project.latitude;
                plng = m.project.longitude;
                platlng = new window.google.maps.LatLng(plat, plng);
                self.placeMarkerWithDelay('project', platlng, map, count * 100, m.project.label);
                coords = [wStartLatLng, platlng];
                self.placeLineWithDelay(coords, map, count * 100);
                endCoords = [wEndLatLng, platlng];
                self.placeLineWithDelay(endCoords, map, count * 100);
                results1.push(count++);
            } else {
                results1.push(void 0);
            }
        }
        return results1;
    },
    //add marker with animation and delay
    placeMarkerWithDelay: function (type, latLng, map, delay, label, radius = 0) {
        return Ember.run.later(() => {
            var icon, marker;
            if (type === 'worktime') {
                icon = 'img/gpsmap-user-icon.png';
            } else if (type === 'worktimeStart') {
                icon = {
                    url: 'img/Play.png',
                    anchor: new google.maps.Point(12, 12),
                };
            } else if (type === 'worktimeEnd') {
                icon = {
                    url: 'img/Stop.png',
                    anchor: new google.maps.Point(12, 12),
                };
            } else if (type === 'project') {
                icon = 'img/gpsmap-project-icon.png';
            }
            marker = new window.google.maps.Marker({
                position: latLng,
                map: map,
                icon: icon,
                animation: google.maps.Animation.DROP,
                title: label,
            });
            if (radius > 0) {
                radius = new google.maps.Circle({
                    strokeColor: '#24E268',
                    strokeOpacity: 0.5,
                    strokeWeight: 1,
                    fillColor: '#24E268',
                    fillOpacity: 0.2,
                    map: map,
                    center: latLng,
                    radius: radius,
                });
                this.get('allCircles').pushObject(radius);
            }
            return this.get('allMarkers').pushObject(marker);
        }, delay);
    },
    //add line with delay
    placeLineWithDelay: function (coords, map, delay) {
        return Ember.run.later(() => {
            var path;
            path = new google.maps.Polyline({
                path: coords,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });
            path.setMap(map);
            return this.get('allPaths').pushObject(path);
        }, delay);
    },
    //add marker by click and remove previous
    placeMarkerAndPanTo: function (latLng, map) {
        var i, len, loc, m, marker, ref;
        ref = this.get('markers');
        for (i = 0, len = ref.length; i < len; i++) {
            m = ref[i];
            m.setMap(null);
        }
        this.set('markers', []);
        marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
        });
        this.get('markers').push(marker);
        //set location to value
        loc = {};
        loc.latitude = marker.position.lat();
        loc.longitude = marker.position.lng();
        this.set('value', JSON.stringify(loc));
        return map.panTo(latLng);
    },
    updateLocation: true,
    //listen changes in address fields
    addressObserver: Ember.observer('model.address', 'model.zip', 'model.city', function () {
        //dont run every model change, only after 1.5s of last change
        if (this.get('updateLocation')) {
            return Ember.run.debounce(this, this.geocodeAddress, 1500);
        }
    }),
    updateCenterPoint: function () {
        var map, pos, value;
        map = this.get('map');
        value = JSON.parse(this.get('value'));
        pos = new window.google.maps.LatLng(value.latitude, value.longitude);
        map.setCenter(pos);
        this.placeMarkerAndPanTo(pos, map);
        map.setZoom(15);
    },
    //listen changes in value field and update address
    locationObserver: Ember.observer('value', function () {
        var geocoder, latlng, self, value;
        value = JSON.parse(this.get('value'));
        latlng = {
            lat: value.latitude,
            lng: value.longitude,
        };
        //if model not set, just update map
        if (!this.get('model')) {
            if (this.get('skipPositionUpdate') === false) {
                Em.run.debounce(this, this.updateCenterPoint, 1500);
            }
            return;
        }
        //get address
        geocoder = new window.google.maps.Geocoder();
        self = this;
        return geocoder.geocode(
            {
                location: latlng,
            },
            function (results, status) {
                var address, city, url, zip;
                if (status !== 'OK') {
                    return;
                }
                if (results[0].address_components.length === 4) {
                    address = results[0].address_components[0]['long_name'];
                    zip = results[0].address_components[3]['short_name'];
                    city = results[0].address_components[1]['short_name'];
                } else if (results[0].address_components.length === 5) {
                    address =
                        results[0].address_components[1]['long_name'] +
                        ' ' +
                        results[0].address_components[0]['short_name'];
                    //zip = results[0].address_components[4]['short_name']
                    zip = results[0].address_components[4]['short_name'];
                    city = results[0].address_components[2]['short_name'];
                } else if (results[0].address_components.length === 8) {
                    address =
                        results[0].address_components[1]['long_name'] +
                        ' ' +
                        results[0].address_components[0]['short_name'];
                    //zip = results[0].address_components[4]['short_name']
                    zip = results[0].address_components[7]['short_name'];
                    city = results[0].address_components[2]['short_name'];
                } else if (results[0].address_components.length === 6) {
                    address =
                        results[0].address_components[1]['long_name'] +
                        ' ' +
                        results[0].address_components[0]['short_name'];
                    zip = results[0].address_components[5]['short_name'];
                    city = results[0].address_components[2]['short_name'];
                    self.set('updateLocation', false);
                } else {
                    address =
                        results[0].address_components[1]['long_name'] +
                        ' ' +
                        results[0].address_components[0]['short_name'];
                    zip = results[0].address_components[4]['short_name'];
                    city = results[0].address_components[2]['short_name'];
                }
                self.set('updateLocation', false);
                if (self.get('model')) {
                    if (!(typeof self.get === 'function' ? self.get('model.image') : void 0)) {
                        url = self.get('google').streetViewUrl(value);
                        getImageFromUrl(url).then((file) => {
                            var file_data;
                            file_data = {};
                            file_data.name = 'file';
                            return resizeImage(file, 600, 300, 60).then((resized) => {
                                file_data.file = resized;
                                return resizeImage(resized, 200, 100, 60).then((resized) => {
                                    file_data.preview = resized;
                                    return self.set('model.image', [file_data]);
                                });
                            });
                        });
                    }
                    if (!(typeof self.get === 'function' ? self.get('model.address') : void 0)) {
                        self.set('model.address', address);
                    }
                    if (!(typeof self.get === 'function' ? self.get('model.zip') : void 0)) {
                        self.set('model.zip', zip);
                    }
                    if (!(typeof self.get === 'function' ? self.get('model.city') : void 0)) {
                        return self.set('model.city', city);
                    }
                }
            },
        );
    }),
    //geocode address
    geocodeAddress: function () {
        var address, geocoder, self;
        self = this;
        if (this.get('model.address') && this.get('model.city')) {
            address = this.get('model.address') + ', ' + this.get('model.city');
            geocoder = new window.google.maps.Geocoder();
            return geocoder.geocode(
                {
                    address: address,
                },
                function (results, status) {
                    var map;
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        map = self.get('map');
                        map.setCenter(results[0].geometry.location);
                        self.placeMarkerAndPanTo(results[0].geometry.location, map);
                        return map.setZoom(15);
                    }
                },
            );
        }
    },
});

export default GpsLocationMapComponent;
