import Component from '@glimmer/component';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class IntegrationsReportComponent extends Component {
    @service evented;

    refreshRate = 1000 * 60; // once every minute

    constructor() {
        super(...arguments);

        later(this, this.refresh, this.refreshRate);
    }

    refresh() {
        if (this.isDestroyed) return;
        this.evented.storeEvent('insert', 'ccbuttonexecution', null);
        later(this, this.refresh, this.refreshRate);
    }
}
