/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/customer/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsCustomerEditController;

import Ember from 'ember';

FormsCustomerEditController = Ember.Controller.extend({
    actions: {
        recordSaved: function (settings, row) {
            return this.transitionToRoute('saved').then(function (newRoute) {
                newRoute.controller.set('model', row);
                return newRoute.controller.set('afterSave', {
                    route: 'forms.form-report',
                    params: 'customer',
                });
            });
        },
        cancelClicked: function () {
            return this.transitionToRoute('forms.form-report', {
                form: 'customer',
            });
        },
    },
});

export default FormsCustomerEditController;
