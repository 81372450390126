/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/date-input.coffee.old
// Generated by CoffeeScript 2.6.1
var DateInputComponent;

import Ember from 'ember';

import $ from 'jquery';

DateInputComponent = Ember.TextField.extend({
    tagName: 'input',
    attributeBindings: ['type', 'min'],
    type: 'text',
    isValueNull: Ember.computed('value', function () {
        if (this.get('value') === null && !Modernizr.inputtypes.date) {
            return $(this.element).val('');
        }
    }),
    showNativeControl: Ember.computed('value', {
        get: function () {
            if (this._value) {
                return this._value;
            }
            if (window.navigator.userAgent.indexOf('Edge') > -1) {
                return false;
            }
            return Modernizr.inputtypes.date;
        },
        set: function (key, value) {
            return (this._value = value);
        },
    }),
    didInsertElement: function () {
        var nowDate, onChangeDate, self, startdate;
        if (!this.get('showNativeControl')) {
            //            @set 'type', 'text'
            self = this;
            onChangeDate = function (e) {
                return self.set('value', moment(e.date).format('YYYY-MM-DD'));
            };
            startdate = '';
            if (this.get('startDate')) {
                startdate = new Date(this.get('startDate'));
            } else {
                nowDate = new Date();
                startdate = new Date('1900', nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0); //ugly hack to get datepicker work
            }
            return $(this.element)
                .datepicker({
                    format: 'yyyy-mm-dd',
                    language: localStorage.lang,
                    autoclose: true,
                    todayHighlight: true,
                    startDate: startdate,
                    clearBtn: true,
                    disableTouchKeyboard: true,
                    orientation: 'bottom',
                })
                .on('changeDate', onChangeDate);
        }
    },
    didReceiveAttrs: function () {
        if (this.get('showNativeControl')) {
            this.set('type', 'date');
        }
        if (this.get('startDate')) {
            return this.set('min', this.get('startDate'));
        }
    },
});

export default DateInputComponent;
