import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Controller.extend({
    cc: service(),
    showInfo: false,
    row: null,

    project: alias('model'),

    lock: computed('row', function () {
        return get(this, 'cc').request('rollock-lock-status', {
            data: {
                serialNumber: get(this, 'row.serialnumber'),
                throwErrors: true,
            },
        });
    }),

    defaultValues: computed('project', function () {
        return { project: get(this, 'project') };
    }),

    actions: {
        rowAdded() {
            this.notifyPropertyChange('defaultValues');
        },
        toggleInfo(row) {
            if (row) {
                set(this, 'row', row);
            } else {
                set(this, 'row', null);
            }
            this.toggleProperty('showInfo');
        },
    },
});
