import Component from '@ember/component';
import { get } from '@ember/object';

export default Component.extend({
    init() {
        this._super();
        let settings = get(this, 'settings');

        this.splittedToggled = settings.overtimehours;
        this.dailyToggled = settings.dailyOvertime;
        this.weeklyToggled = settings.weeklyOvertime;

        //right
        this.timeBasedToggled = settings.overtimeTimebased;

        //left
        this.splitOvertimeHours = settings.splitOvertimehours;
        this.daily50 = settings.daily50;
        this.daily100 = settings.daily100;
        this.weeklyMaximum = settings.weeklyMaximum;

        //right
        this.eveningStart = settings.eveningStart;
        this.eveningEnd = settings.eveningEnd;
        this.calculateIfOvertimeEvening = settings.calculateIfOvertimeEvening;
        this.nightStart = settings.nightStart;
        this.nightEnd = settings.nightEnd;
        this.calculateIfOvertimeNight = settings.calculateIfOvertimeNight;
        this.calculateAlways = settings.overtimeCalculateAlways;
    },
});
