/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsAddController;

import Ember from 'ember';

ProjectsAddController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    intl: Em.inject.service(),
    projectField: Em.computed('model', function () {
        return this.get('Collector').field('skyddsrond', 'project');
    }),
    setDefaultValue: function (field) {
        if (field.name === 'project') {
            field.features.hide = true;
        }
        return field;
    },
    sendNoticeEmail: function (notification) {
        var c,
            email,
            emailContent,
            field,
            fields,
            file,
            i,
            len,
            linebreak,
            obj,
            pics,
            projectString,
            ref,
            tmp_file,
            userString,
            value;
        c = this.get('Collector');
        fields = c.fields('notice');
        pics = [];
        emailContent = [];
        emailContent.push(this.get('intl').t('site_safety.notice.email_content.content'));
        for (field in fields) {
            obj = fields[field];
            if (field === 'status') {
                continue;
            }
            if (obj['type'] === 'hdfile') {
                ref = notification.get(field);
                for (i = 0, len = ref.length; i < len; i++) {
                    file = ref[i];
                    console.log(file);
                    tmp_file = {};
                    tmp_file['name'] = file.name;
                    tmp_file['url'] = file.url;
                    tmp_file['s3url'] = file.s3url;
                    tmp_file['type'] = file.type;
                    pics.push(tmp_file);
                }
            } else {
                value = c._format_(notification.get(field), 'notice', field);
                emailContent.push(this.get('intl').t('report.notice.' + field) + ': ' + value);
            }
        }
        userString = '';
        if (this.get('session.currentUser.firstname')) {
            userString += this.get('session.currentUser.firstname') + ' ';
        }
        if (this.get('session.currentUser.lastname')) {
            userString += this.get('session.currentUser.lastname') + '\n';
        }
        if (this.get('session.currentUser.email')) {
            userString += this.get('session.currentUser.email') + '\n';
        }
        if (this.get('session.currentUser.phone')) {
            userString += this.get('session.currentUser.phone') + '\n';
        }
        projectString = '';
        if (notification.get('project.number')) {
            projectString += notification.get('project.number') + ' ';
        }
        projectString += notification.get('project.name');
        linebreak = '\n';
        email = emailContent.join(linebreak);
        // get email for assigned user
        return this.get('store')
            .findRecord('user', notification.get('user.id'), {
                reload: true,
            })
            .then((userRecord) => {
                var emailAddress;
                emailAddress = userRecord.get('email');
                return this.get('Collector').ttapi({
                    dataType: 'text',
                    url: 'sendEmailWithImages',
                    data: {
                        form: 'notice',
                        email: emailAddress,
                        title:
                            this.get('session.currentUser.company') +
                            ' - ' +
                            this.get('intl').t('site_safety.notice.email_content.title'),
                        content: email,
                        link_text: this.get('intl').t('site_safety.notice.fix_link')['string'],
                        attachments: JSON.stringify(pics),
                        rowid: notification.get('id'),
                        add_link: true,
                        userid: userRecord.get('id'),
                    },
                    method: 'post',
                });
            });
    },
    actions: {
        setValue: function (value, field) {
            return this.set('model.' + field, value);
        },
        addNotice: function (value, field) {
            var c, object;
            c = this.get('Collector');
            object = c.field('skyddsrond', field);
            if (object.type === 'radio' && value === '$med_anm') {
                this.set('openNotice', field);
                return this.set(
                    'noticeRecord',
                    this.get('store').createRecord('notice', {
                        project: this.get('model.project'),
                        creator: this.get('session.currentUser'),
                        fixbydate: moment().add(7, 'days').format('YYYY-MM-DD'),
                    }),
                );
            } else if (object.type === 'radio') {
                return this.send('closeNotice');
            }
        },
        addToNoticeArray: function (settings, record) {
            var field;
            field = this.get('model.' + this.get('openNotice') + '_notices');
            if (Ember.isArray(field)) {
                field.pushObject(record);
            } else {
                this.set('model.' + this.get('openNotice') + '_notices', Em.A([record]));
            }
            this.sendNoticeEmail(record);
            return this.send('closeNotice', false);
        },
        closeNotice: function (destroy = true) {
            this.set('openNotice', false);
            if (destroy && this.get('noticeRecord')) {
                this.get('noticeRecord').destroyRecord();
            }
            return this.set('noticeRecord', false);
        },
        cancel: function () {
            return this.transitionToRoute('skyddsrond.report');
        },
        saved: function (settings, record) {
            return this.send('transitionToSaved', record);
        },
        openInfo: function (field) {
            return this.get('dialogs').alert(this.get('intl').t('skyddsrond.info.' + field));
        },
    },
});

export default ProjectsAddController;
