/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/material/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryAddMaterialInfoController;

import Ember from 'ember';

WorksiteDiaryAddMaterialInfoController = Ember.Controller.extend({
    session: Ember.inject.service(),
    addController: Ember.inject.controller('worksitediary.report.add'),
    show_modal: false,
    ordered_material_list: Ember.computed.alias('addController.model.orders_ordered'),
    received_material_list: Ember.computed.alias('addController.model.orders_received'),
    event_type_options: null,
    material_settings: Ember.computed(function () {
        return {
            form: 'material',
            defaultValues: {
                order_date: moment().format('YYYY-MM-DD'),
            },
        };
    }),
    actions: {
        addMaterialToRecord: function (status, event_id) {
            var material;
            material = this.get('store').peekRecord('material', event_id);
            this.get('ordered_material_list').removeObject(
                this.get('ordered_material_list').findBy('id', material.get('id')),
            );
            this.get('received_material_list').removeObject(
                this.get('received_material_list').findBy('id', material.get('id')),
            );
            if (status !== 'returnToStore') {
                material.set('order_state', status);
                material.save();
                //console.log status
                if (status === 'ordered') {
                    return this.get('ordered_material_list').pushObject(material);
                } else if (status === 'received') {
                    return this.get('received_material_list').pushObject(material);
                }
            } else {
                return material.set('order_state', null);
            }
        },
        edit_event: function (event) {
            event.set('order_date', moment().format('YYYY-MM-DD'));
            this.set('record', event);
            this.set('show_modal', true);
            return false;
        },
        show_add_event_popup: function () {
            this.set('show_modal', true);
            return false;
        },
        closeModal: function (settings, record) {
            this.set('show_modal', false);
            return this.send('addMaterialToRecord', record.get('order_state'), record.get('id'));
        },
        removeField: function (id) {
            var mat;
            this.set('show_modal', false);
            this.set('record', null);
            mat = this.store.peekRecord('material', id);
            if (mat) {
                return mat.destroyRecord();
            }
        },
    },
});

export default WorksiteDiaryAddMaterialInfoController;
