import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import config from 'tt4/config/environment';

export default Component.extend({
    Collector: service('collector-service'),
    session: service(),
    intl: service(),

    errors: EmberObject.create({
        name: false,
        phone: false,
        email: false,
    }),

    showModal: computed('triggerShowModal', function () {
        if (get(this, 'triggerShowModal')) {
            this._setModalInfo();
            return true;
        }
        return false;
    }),

    // This computed is used to send info link to crowdin as param so it can be used inside translation
    // It has to be sent from code and not straight in crowdin, because of branding
    infoLink: computed('product', function () {

        const url = (config.brand === 'otta') ? get(this, 'intl').t('click.help_links_for_products.otta') :
            ('https://ohjeet.visma.fi/articles/#!visma-' +
            (config.brand === 'visma' ? 'entry' : 'movenium'));
            // eslint-disable-next-line no-irregular-whitespace
            // TODO: Remove in EP-11985

        const infoLinksForProducts = {
            nova_web_service: url + '/nova-web-service',
            nova_salary_csv: url + '/visma-nova',
            netvisor_salary_rest: url + '/visma-netvisor',
            fivaldi_salary_soap: url + '/visma-fivaldi',
            custom_export: url + '/vientity-kalu',
        };
        return infoLinksForProducts[this.product];
    }),

    actions: {
        infoButton() {
            this.toggleProperty('triggerShowModal');
        },
        sendModalData() {
            let hasError = false;
            let fields = ['name', 'phone', 'email'];
            fields.forEach((field) => {
                if (!get(this, 'modal_info.' + field) || get(this, 'modal_info.' + field) == '') {
                    set(this, 'errors.' + field, true);
                    hasError = true;
                } else {
                    set(this, 'errors.' + field, false);
                }
            });
            if (!hasError) {
                set(this, 'triggerShowModal', false);
                let intl = get(this, 'intl');

                let emailContent = [];
                emailContent.push([
                    intl.t('settings.products.rfid_company_name'),
                    get(this, 'session.currentUser.partnerid') +
                        ' ' +
                        get(this, 'modal_info.company_name'),
                ]);
                emailContent.push(['Integration type', get(this, 'product')]);
                emailContent.push([
                    intl.t('settings.products.rfid_name'),
                    get(this, 'modal_info.name'),
                ]);
                emailContent.push([
                    intl.t('settings.products.rfid_phone'),
                    get(this, 'modal_info.phone'),
                ]);
                emailContent.push([
                    intl.t('settings.products.rfid_email'),
                    get(this, 'modal_info.email'),
                ]);

                let address =
                    get(this, 'session.currentUser.company_info.country') == 'se'
                        ? 'customerservice_se'
                        : 'customerservice_fi';
                let basicContent = '\r\n';

                emailContent.forEach((contentRow) => {
                    basicContent += contentRow[0] + ': ' + contentRow[1] + '\r\n';
                });

                let emailContentForCustomer =
                    intl.t('settings.products.integration_order_received') + '\r\n' + basicContent;

                get(this, 'Collector').ttapi({
                    url: 'sendEmail',
                    data: {
                        email: address,
                        title:
                            get(this, 'session.currentUser.company') +
                            ' - ' +
                            intl.t('register.products.integration_info_order'),
                        content: basicContent,
                    },
                    method: 'post',
                });

                get(this, 'Collector').ttapi({
                    url: 'sendEmail',
                    data: {
                        email: get(this, 'modal_info.email'),
                        title: intl
                            .t('settings.products.integration_order_received_header')
                            .toString(),
                        content: emailContentForCustomer,
                    },
                    method: 'post',
                });
            }
        },
        change(field, value) {
            if (get(this, 'rfid_info.' + field)) {
                set(this, 'rfid_info.' + field, value);
            }
            set(this, 'errors.' + field, false);
        },
        closeModal() {
            set(this, 'triggerShowModal', false);
        },
    },
    _setModalInfo() {
        set(this, 'modal_info', {
            company_name: get(this, 'session.currentUser.company_info.company_name'),
            name:
                get(this, 'session.currentUser.firstname') +
                ' ' +
                get(this, 'session.currentUser.lastname'),
            phone: get(this, 'session.currentUser.phone'),
            email: get(this, 'session.currentUser.email'),
        });
    },
});
