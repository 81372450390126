/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerAddController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

TimetrackerAddController = GeneralAddController.extend({
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    flags: Ember.inject.service(),
    useFormAdd: Ember.computed(function () {
        return (
            this.get('flags').test('form-add') ||
            this.get('flags').test('ember-movenium-form-add-worktime')
        );
    }),
    useNewVersion: Ember.computed(function () {
        return (
            this.get('Collector').testNeeds(['products.multitasking']) ||
            this.get('flags').test('form-add') ||
            this.get('flags').test('ember-movenium-form-add-worktime')
        );
    }),
    init: function () {
        var cust, fields;
        this._super();
        fields = collector.getFormObjects('worktime');
        cust = {};
        if (
            !fields.starttime.features.hide &&
            !fields.endtime.features.hide &&
            !fields.work_hours.features.mandatory
        ) {
            cust = {
                starttime: {
                    defaultValue: '07:00',
                },
                endtime: {
                    defaultValue: moment().format('HH:mm'),
                },
                date: {
                    defaultValue: moment().format('YYYY-MM-DD'),
                },
                pause: {
                    minValue: 0,
                },
            };
            this.set('customization', cust);
        }
        if (fields.starttime.features.hide && fields.endtime.features.hide) {
            cust = {
                date: {
                    defaultValue: moment().format('YYYY-MM-DD'),
                },
            };
            this.set('customization', cust);
        }
        // IF SERVICE THROWS ERROR HERE ABOUT "Can't set project of undefined" THEN THE SERVICE IS B-R-O-K-E-N
        // Don't fix this code, rather check what is wrong with the service, usually work_hours is set to mandatory
        // when using clockcard or something else
        // It pretty much means that the above two ifs didn't trigger so something wrong with the fields starttime, endtime or work_hours
        //if collector.getFormObject("project.force_location") && @get('session.currentUser.userlevel') != "5"
        //    cust = @get "customization"
        //    cust.project =
        //        template: "timetracker/add/gpsprojects"
        //else
        cust = this.get('customization');
        cust.project = {
            template: 'timetracker/add/project',
        };
        if (this.get('Collector').testNeedsOne('products.timetracker_km_automatic')) {
            return (cust.km = {
                template: 'timetracker/add/km_auto',
            });
        }
    },
    addNewLink: 'timetracker.add',
    editNewLink: 'timetracker.edit',
    date_newer_warning: false,
    getStarttime: function () {
        // get starttime by using magic aka fuzzy logic
        return this.get('Collector').ttapi({
            method: 'get',
            dataType: 'text',
            url: 'get_starttime',
        });
    },
    model_observer: Em.observer('model', function () {
        var fields, records;
        if (this.get('useNewVersion')) {
            return;
        }
        fields = collector.getFormObjects('worktime');
        if (this.get('session.currentUser.currentMySite')) {
            this.set('model.project', this.get('session.currentUser.currentMySite'));
        }
        if (
            !fields.starttime.features.hide &&
            !fields.endtime.features.hide &&
            !fields.work_hours.features.mandatory &&
            this.get('model.isNew') &&
            !this.get('model.starttime')
        ) {
            records = this.store
                .peekAll('worktime')
                .filterBy('date', moment().format('YYYY-MM-DD'))
                .sortBy('endtime');
            if (records.get('lastObject.endtime')) {
                return this.set('model.starttime', records.get('lastObject.endtime'));
            } else {
                return this.getStarttime().then((start) => {
                    if (start) {
                        return this.set('model.starttime', start);
                    }
                });
            }
        }
    }),
    date_observer: Em.observer('model.date', function () {
        var date, next_month_date, selected_date;
        if (this.get('useNewVersion')) {
            return;
        }
        date = this.get('model.date');
        if (date == null) {
            return;
        }
        if (!moment(new Date(date)).isValid()) {
            return;
        }
        selected_date = moment(new Date(date)).format('YYYY-MM-DD');
        next_month_date = moment().add(1, 'months').format('YYYY-MM-DD');
        if (selected_date > next_month_date) {
            return this.set('date_newer_warning', true);
        } else {
            return this.set('date_newer_warning', false);
        }
    }),
    actions: {
        goBack: () => {
            return window.history.back(-1);
        },
    },
});

export default TimetrackerAddController;
