/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/measurement_custom/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsMeasurementCustomController;

import Ember from 'ember';

SettingsMeasurementCustomController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    settingsController: Em.inject.controller('settings'),
    actions: {
        saved: function () {
            this.get('settingsController').refreshNeeded();
            this.get('application').notifyPropertyChange('sideMenus');
            return false;
        },
        removedMeasurement: function () {
            this.get('settingsController').refreshNeeded();
            this.get('application').notifyPropertyChange('sideMenus');
            return false;
        },
    },
});

export default SettingsMeasurementCustomController;
