/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/weather-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsWeatherComponent;

import Ember from 'ember';

import config from '../../../config/environment';

CollectorFieldsWeatherComponent = Ember.Component.extend({
    weather: Ember.inject.service(),
    gps: Ember.inject.service(),
    init: function () {
        this._super(...arguments);
        return Ember.set(this, 'ownWeather', {});
    },
    fetchingWeather: false,
    store: Ember.inject.service(),
    translations: {
        temp: '°C',
        wspeed: 'm/s',
        percent: '%',
    },
    getWeather: Em.computed('project', 'date', function () {
        if (this.get('isNew') && this.get('project')) {
            return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
                promise: new Ember.RSVP.Promise((resolve) => {
                    var date, proj;
                    proj = this.get('project');
                    date = this.get('date');
                    if (proj.get('location_map')) {
                        return this.get('weather')
                            .fetchWeatherByProject(proj, date)
                            .then((weather) => {
                                Ember.run.next(this, () => {
                                    return this.set('value', weather);
                                });
                                return resolve(true);
                            });
                    } else {
                        return this.get('store')
                            .findRecord('project', proj.id, {
                                reload: true,
                            })
                            .then((project) => {
                                var projectLocation;
                                projectLocation = project.get('location_map');
                                if (projectLocation) {
                                    return this.get('weather')
                                        .fetchWeatherByProject(project, date)
                                        .then((weather) => {
                                            Ember.run.next(this, () => {
                                                return this.set('value', weather);
                                            });
                                            return resolve(this.get('gpsWeather'));
                                        });
                                } else {
                                    return resolve(this.get('gpsWeather'));
                                }
                            });
                    }
                }),
            });
        } else {
            return this.get('value');
        }
    }),
    gpsWeather: Ember.computed(
        'gps.lockedPosition',
        'gps.lockedLatitude',
        'gps.lockedLongitude',
        'gps.lockedAccuracy',
        'date',
        function () {
            var position;
            if (!this.get('gps.lockedPosition')) {
                Ember.run.next(this, () => {
                    return this.set('value', null);
                });
                return null;
            } else {
                position = {
                    latitude: this.get('gps.lockedLatitude'),
                    longitude: this.get('gps.lockedLongitude'),
                    accuracy: this.get('gps.lockedAccuracy'),
                };
                return this.get('weather')
                    .fetchByGPS(position, this.get('date'))
                    .then((weather) => {
                        Ember.run.next(this, () => {
                            return this.set('value', weather);
                        });
                        return true;
                    });
            }
        },
    ),
    /*
  i left these here if code above doesn't work for some reason and observers are needed..
   * I hate myself :'(
  dateObserver: Em.observer 'date', ->
      if @get('project')
          @set 'weatherNow', null
          console.log "date changed"
          @get('weather').fetchWeatherPosition(@get('project'),@get('date')).then (weather) =>
              @set 'value', weather
              @set 'weatherNow', weather

  projectObserver: Em.observer 'project', ->
      if @get('project')
          @set 'weatherNow', null
          console.log "project changed"
          @get('weather').fetchWeatherPosition(@get('project')).then (weather) =>
              @set 'value', weather
              @set 'weatherNow', weather
          #console.log @get 'project'
   */
    actions: {
        updateWeather: function () {
            var json;
            // if weather-datatype changes, change this also
            json = {};
            json.temperature = this.get('ownWeather.temperature');
            json.humidity = this.get('ownWeather.humidity');
            json.windspeed = this.get('ownWeather.windspeed');
            json.weather = this.get('ownWeather.weather');
            if (this.get('ownWeather.temperature') && this.get('ownWeather.humidity')) {
                json.dew_point = this.get('weather').calcDewPoint(json.temperature, json.humidity);
                this.set('ownWeather.dew_point', json.dew_point);
            }
            return this.set('value', json);
        },
        toggleOverrideWeather: function () {
            if (this.get('overrideWeather')) {
                this.get('weather').fetchWeatherPosition();
            } else if (this.get('weather.temperature')) {
                this.set('ownWeather', this.get('weather'));
            }
            this.toggleProperty('overrideWeather');
            return false;
        },
        changeWeatherType: function (type) {
            this.set('ownWeather.weather', type);
            return this.send('updateWeather');
        },
    },
});

export default CollectorFieldsWeatherComponent;
