/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/avi/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAviRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

import avi from '../../../mixins/avi';

OrientationsAviRoute = GeneralReportRoute.extend(avi, {
    queryParams: {
        project: {
            refreshModel: true,
            replace: true,
        },
        country: {
            refreshModel: true,
            replace: true,
        },
        page: {
            refreshModel: true,
        },
        showWorkingNow: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        return this.getAviData(params);
    },
});

export default OrientationsAviRoute;
