/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/thereport/page/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ThereportRoute;

import Ember from 'ember';

import $ from 'jquery';

ThereportRoute = Ember.Route.extend({
    model: function (params) {
        var pages;
        pages = this.modelFor('thereport');
        return new Ember.RSVP.hash({
            settings: params.page in pages ? pages[params.page].settings : [],
            pageid: params.page in pages ? pages[params.page].id : null,
        });
    },
    actions: {
        didTransition: function () {
            return Ember.run.schedule('afterRender', this, function () {
                $('.dropdown-toggler').on('click', function (event) {
                    $('input.checkbox-button').prop('checked', false);
                    return $(this).parent().toggleClass('open');
                });
                return $('body').on('click', function (e) {
                    if (
                        !$('.dropdown-toggler').is(e.target) &&
                        $('.dropdown-menu').has(e.target).length === 0 &&
                        $('.open').has(e.target).length === 0
                    ) {
                        $('input.checkbox-button').prop('checked', false);
                        return $('div.dropdown').removeClass('open');
                    }
                });
            });
        },
    },
});

export default ThereportRoute;
