import Component from '@ember/component';

/**
 * usage
 *
 *   <BootstrapField @text={{"Field name"}} @mandatory={{true}} @error={{someErrorText}} @name={{"Name of the field"}}>
 *       <Input @class="form-control" />
 *   </BootstrapField>
 */

export default Component.extend({});
