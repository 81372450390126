import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

const WorktasksConfirmRoute = Route.extend(AuthenticatedRouteMixin, {
    model(params) {
        if (params.id) {
            return this.store.findRecord('worktask', params.id, { include: true });
        } else {
            return this.transitionTo('worktask.list');
        }
    },
});

export default WorktasksConfirmRoute;
