/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/thereport/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ThereportRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

ThereportRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    model: function (params) {
        var c, report_prefix;
        c = this.get('Collector');
        report_prefix = this.get('intl').t('thereport.report_prefix');
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise;
            promise = c.ttapi({
                url: 'comboreports',
            });
            return promise.then((data) => {
                data.forEach((item, index) => {
                    if (!item.name) {
                        return (item.name = report_prefix + ' ' + (index + 1));
                    }
                });
                while (data.length < 4) {
                    data.pushObject({
                        name: report_prefix + ' ' + (data.length + 1),
                    });
                }
                return resolve(data);
            });
        });
    },
    redirect: function (model, transition) {
        if (transition.targetName === 'thereport.index') {
            return this.transitionTo('thereport.page', 0);
        }
    },
    actions: {
        willTransition: function () {
            return this.refresh();
        },
        reload: function () {
            return this.refresh();
        },
    },
});

export default ThereportRoute;
