/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/random-mysite-image.coffee.old
// Generated by CoffeeScript 2.6.1
var RandomMysiteImageHelper;

import Ember from 'ember';

import config from '../config/environment';

RandomMysiteImageHelper = Ember.Helper.extend({
    cdn: Ember.inject.service(),
    compute: function (params, hash) {
        var num, total;
        num = params[0];
        total = params[1];
        return this.get('cdn').url('images/placeholders/placeholder' + (num % total) + '.jpg');
    },
});

export default RandomMysiteImageHelper;
