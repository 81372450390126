/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserController;

import Ember from 'ember';

import initmixin from 'tt4/mixins/init-controller';

RegisterUserController = Ember.Controller.extend(initmixin, {
    intl: Em.inject.service(),
    registerController: Ember.inject.controller('register'),
    registerUserId06Controller: Ember.inject.controller('register.user.id06'),
    registerUserInfoController: Ember.inject.controller('register.user.info'),
    registerUserId06CompanyController: Ember.inject.controller('register.user.company'),
    applicationController: Ember.inject.controller('application'),
    queryParams: ['project', 'creator', 'employer_name', 'employer_id', 'employer_country', 'type'],
    init_functions: function () {
        this._super();
        return Ember.run.next(this, () => {
            this.get('registerController').set('registerType', 'user');
            return this.notifyPropertyChange('currentPage');
        });
    },
    nextPageText: Ember.on(
        'init',
        Ember.observer('currentPage', function () {
            var intl, nextPageText;
            intl = this.get('intl');
            nextPageText = function () {
                switch (this.get('currentPage')) {
                    case 3:
                        return intl.t('register.button_continue');
                    case 4:
                        return intl.t('register.button_continue');
                    case 5:
                        return null;
                }
            }.call(this);
            return this.get('registerController').set('nextPageText', nextPageText);
        }),
    ),
    observePages: Ember.observer('currentPage', function () {
        var currentPage, i, index, len, page, page_list, ret;
        currentPage = this.get('currentPage');
        if (currentPage === 0) {
            this.get('registerController').set('pages', Em.A([]));
            return;
        }
        page_list = this.get('page_list');
        ret = Em.A([]);
        for (index = i = 0, len = page_list.length; i < len; index = ++i) {
            page = page_list[index];
            ret.pushObject(
                Em.Object.create({
                    name: page,
                    active: currentPage - 1 >= index,
                    done: currentPage - 1 > index,
                    num: index + 1,
                }),
            );
        }
        return this.get('registerController').set('pages', ret);
    }),
    page_list: Ember.computed(function () {
        var intl;
        intl = this.get('intl');
        return Em.A([
            intl.t('register.user_page_sign_in'),
            intl.t('register.user_page_confirm'),
            intl.t('register.user_page_company_info'),
            intl.t('register.user_page1'),
            intl.t('register.user_page2'),
        ]);
    }),
    currentPage: Ember.computed('applicationController.path.[]', function () {
        var path;
        path = this.get('applicationController.path');
        if (!path) {
            return 1;
        }
        switch (path[2]) {
            case 'index':
                return 0;
            case 'sign-in':
                return 1;
            case 'confirm':
                return 2;
            case 'company':
                return 3;
            case 'id06':
            case 'info':
                return 4;
            case 'done':
                return 5;
            default:
                return 1;
        }
    }),
    actions: {
        continue: function () {
            var currentPage,
                promise,
                ref,
                registerUserId06CompanyController,
                registerUserId06Controller,
                registerUserInfoController;
            registerUserId06Controller = this.get('registerUserId06Controller');
            registerUserInfoController = this.get('registerUserInfoController');
            registerUserId06CompanyController = this.get('registerUserId06CompanyController');
            currentPage = this.get('currentPage');
            switch (currentPage) {
                case 3:
                    return this.transitionToRoute('register.user.id06');
                case 4:
                    if ((ref = this.get('type')) === 'valtti' || ref === 'normal') {
                        promise = registerUserInfoController.validate_all();
                    } else {
                        promise = registerUserId06Controller.validate_all();
                    }
                    return promise.then((allOk) => {
                        var ref1;
                        if (allOk === true) {
                            if ((ref1 = this.get('type')) === 'valtti' || ref1 === 'normal') {
                                return this.transitionToRoute('register.user.done');
                            } else {
                                return registerUserId06Controller.verifyUser().then((ok) => {
                                    if (ok) {
                                        return this.transitionToRoute('register.user.done');
                                    }
                                });
                            }
                        }
                    });
                case 5:
                    return this.get('doneController').loginToService();
            }
        },
    },
});

export default RegisterUserController;
