import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'tt4/config/environment';
import { htmlSafe } from '@ember/string';

export default class TrialPeriodIconComponent extends Component {
    @tracked modalValues;
    @tracked shouldShowModal;
    @tracked isCanceling;
    @service router;
    @service session;
    @service dialogs;
    @service intl;

    constructor() {
        super(...arguments);
        this.router.on('routeWillChange', () => {
            if (this.args.trialMode == 'locked') {
                this.shouldShowModal = true;
                this.modalValues = this.getModalValues(this.args.trialMode, this.args.daysLeft);
            }
        });
    }

    get showModal() {
        // this is because we need to show forced modal if mode is "locked"
        if (this.args.trialMode == 'locked' && this.shouldShowModal !== false) {
            this.modalValues = this.getModalValues(this.args.trialMode, this.args.daysLeft);
            return true;
        } else if (this.shouldShowModal) {
            return true;
        }
        return false;
    }

    get styleNamespace() {
        return podNames['trial-period-icon'];
    }

    get alertText() {
        if (this.args.trialMode == 'locked') return 'billing.trial_alert_service_locked';
        else if (this.args.trialMode == 'payment_failed' && this.args.daysLeft < 0)
            return 'billing.trial_alert_add_billing';
        return 'billing.trial_ok_title';
    }

    get showNumber() {
        // 30 - 34 + 30
        // -4 + 30 = 26
        if (this.args.daysLeft < 0) {
            let number = this.args.daysUntilLock + this.args.daysLeft;
            return number > 0 ? number : 0;
        }
        return this.args.daysLeft;
    }

    get trialEndText() {
        return htmlSafe(
            this.intl.t('billing.trial_subscription_canceled', {
                date: '<b>' + moment().add(this.showNumber, 'days').format('L') + '</b>',
            }),
        );
    }

    get showAlert() {
        return (
            this.args.trialMode == 'locked' ||
            (this.args.trialMode == 'payment_failed' && this.args.daysLeft < 0)
        );
    }

    getModalValues(type, days) {
        let values = [];
        values['mode'] = type;
        if (type == 'locked') {
            values['mode'] = 'locked';
            values['trialText'] = 'billing.service_locked_text';
            values['modalTitle'] = 'billing.service_locked_title';
        } else if (type == 'payment_failed' && days < 0) {
            values['mode'] = 'payment_failed';
            values['trialText1'] = 'billing.trial_end_text_title';
            values['trialText2'] = 'billing.trial_end_text';
            values['modalTitle'] = 'billing.trial_end_title';
        } else if (type == 'normal_trial' || days >= 0) {
            values['mode'] = 'normal_trial';
            values['trialText'] = 'billing.trial_ok_text';
            values['modalTitle'] = 'billing.trial_ok_title';
        }
        return values;
    }

    @action
    showModalAction() {
        this.modalValues = this.getModalValues(this.args.trialMode, this.args.daysLeft);
        this.shouldShowModal = true;
    }
    @action
    async cancelSubscription() {
        this.isCanceling = false;
        let con = await this.dialogs.confirm(this.intl.t('billing.subscription_cancel_question'));
        if (con) {
            this.isCanceling = true;
            await fetch(config.APP.BILLING_URL + '/cancel_subscription', {
                headers: {
                    Authorization:
                        'Bearer ' + get(this, 'session.session.authenticated.access_token'),
                },
                method: 'POST',
            });
            this.isCanceling = false;
            this.shouldShowModal = false;
            //this.canTrialBeCanceled = false;
            this.args.setTrialStatus('disabled');
        }
    }

    @action
    gotoProfile() {
        this.shouldShowModal = false;
        this.router.transitionTo('settings.general-v2', {
            queryParams: {
                main: 'companyProfile',
                sub: 'info',
            },
        });
    }
}
