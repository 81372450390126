import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from '@ember/object';

export default Route.extend({
    Collector: service('collector-service'),
    session: service(),
    store: service(),
    initapp: service(),
    model() {
        return hash({
            forms: get(this, 'Collector').ajax({
                url: 'forms',
                headers: {
                    Authorization: `Bearer ${get(this, 'session.session.authenticated.access_token')}`,
                },
            }),
            products: get(this, 'Collector').ajax({
                url: 'service/productList',
                headers: {
                    Authorization: `Bearer ${get(this, 'session.session.authenticated.access_token')}`,
                },
            }),
        });
    },

    actions: {
        reloadModel() {
            get(this, 'initapp').clearInit();
            return this.refresh();
        },
    },
});
