/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditIndexController;

import Ember from 'ember';

import GeneralAddController from '../../../../controllers/general-add';

ProjectsEditIndexController = GeneralAddController.extend({
    customization: Ember.computed('model', function () {
        return {
            owner: {
                filterOptions: {
                    userlevel: '!1',
                },
            },
        };
    }),
});

export default ProjectsEditIndexController;
