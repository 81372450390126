/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/addnotice/tr/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyAddnoticeTrRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

SiteSafetyAddnoticeTrRoute = GeneralAddRoute.extend({
    modelName: 'trnotice',
    controllerName: 'site_safety.addnotice.tr',
    savedRoute: 'site_safety.report.tr.notices',
});

export default SiteSafetyAddnoticeTrRoute;
