/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/add/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesAddRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

MessagesAddRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    queryParams: {
        q: {
            refreshModel: true,
        },
    },
});

export default MessagesAddRoute;
