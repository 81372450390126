/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport-contract/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportIndexRoute;

import Ember from 'ember';

TaxmanTaxreportIndexRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    actions: {
        getTaxmanFile: function (filters) {
            var date_between, date_start;
            date_between = filters.findBy('name', 'contract_month').value;
            date_start = date_between.split('_')[0];
            return this.transitionTo('taxman.taxreport-contract.add', date_start);
        },
    },
});

export default TaxmanTaxreportIndexRoute;
