import Controller from '@ember/controller';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { get, set, computed } from '@ember/object';
import { on } from '@ember/object/evented';
import { run } from '@ember/runloop';
import { isEmpty } from '@ember/utils';

function whenAvailable(name, callback) {
    var interval = 100; // ms
    window.setTimeout(function () {
        if (window[name]) {
            callback(window[name]);
        } else {
            whenAvailable(name, callback);
        }
    }, interval);
}

const DrivinglogToggleIndexController = Controller.extend(AuthenticatedRouteMixin, {
    notifications: service(),
    intl: service(),
    gps_value: '',
    zoomLevel: 15,
    km: 'km',
    refresh_available: false,
    markers: null,
    gps_error: '',
    directionsDisplay: null,
    directionsService: null,

    drivinglog: alias('model'),

    position: on('init', function () {
        this.markers = [];
        whenAvailable('google', () => {
            this.directionsDisplay = new window.google.maps.DirectionsRenderer();
            this.directionsService = new window.google.maps.DirectionsService();
        });

        if (navigator.geolocation) {
            return navigator.geolocation.watchPosition(
                (location) => {
                    const loc = {};
                    loc.latitude = location.coords.latitude;
                    loc.longitude = location.coords.longitude;
                    set(this, 'gps_value', JSON.stringify(loc));
                    return set(this, 'gps_error', '');
                },

                function (error) {
                    return set(
                        this,
                        'gps_error',
                        'PositionError (' + error.code + '): ' + error.message,
                    );
                },
            );
        }
    }),

    positionChanged: computed('gps_value', 'map', function () {
        if (isEmpty(get(this, 'distance'))) {
            this.calculateDistance();
            return false;
        } else {
            run.throttle(this, this.calculateDistance, 5000);
            return true;
        }
    }),

    showGauge: computed('openDrive.distance', function () {
        if (get(this, 'openDrive.gauge_start')) {
            const gauge_start = get(this, 'openDrive.gauge_start');
            const dist = get(this, 'openDrive.distance');
            let sum = dist * 1 + gauge_start * 1;
            sum = Math.round(sum);
            // eslint-disable-next-line ember/no-side-effects
            set(this, 'openDrive.gauge_end', sum);
            localStorage.setItem('drivinglog_gauge_last_end', sum);
        }

        return get(this, 'Collector').fieldExists('drive', 'gauge_start');
    }),

    gaugeField: computed('openDrive', 'openDrive.distance', function () {
        if (localStorage.getItem('drivinglog_gauge_last_end')) {
            // eslint-disable-next-line ember/no-side-effects
            set(this, 'openDrive.gauge_start', localStorage.getItem('drivinglog_gauge_last_end'));
        }
        return get(this, 'Collector').field('drive', 'gauge_start');
    }),

    showProject: computed(function () {
        return get(this, 'Collector').fieldExists('drive', 'project');
    }),

    showPrivate: computed(function () {
        return get(this, 'Collector').fieldExists('drive', 'private');
    }),

    licenseField: computed(function () {
        return get(this, 'Collector').field('drive', 'license_plate');
    }),

    projField: computed(function () {
        return get(this, 'Collector').field('drive', 'project');
    }),

    actions: {
        startDrive() {
            const row = get(this, 'openDrive');
            const date = moment().format('YYYY-MM-DD');
            const starttime = moment().format('HH:mm');

            set(row, 'starttime', starttime);
            set(row, 'date', date);
            set(row, 'validation', 'off');
            set(row, 'location_start', get(this, 'gps_value'));
            if (get(this, 'license_plate')) {
                set(row, 'license_plate', get(this, 'license_plate'));
            }

            // eslint-disable-next-line ember/named-functions-in-promises
            return row.save().then(() => {
                return get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                    autoClear: true,
                });
            });
        },

        confirmDrive() {
            if (!get(this, 'openDrive')) {
                return;
            }
            const row = get(this, 'openDrive');
            const endtime = moment().format('HH:mm');

            //some momentjs calculcation for drive hours
            const end_m = moment();
            const start_m = moment(get(row, 'date') + ' ' + get(row, 'starttime'));
            const duration = moment.duration(end_m.diff(start_m));

            set(row, 'endtime', endtime);
            set(row, 'validation', 'off');
            set(row, 'location_end', get(this, 'gps_value'));
            set(row, 'distance', get(this, 'distance'));
            set(row, 'drive_hours', duration.asHours());

            if (get(this, 'license_plate')) {
                set(row, 'license_plate', get(this, 'license_plate'));
            }

            if (get(this, 'route')) {
                set(row, 'route', get(this, 'route'));
            }

            return this.transitionToRoute('drivinglog.toggle.confirm');
        },

        endDrive() {
            if (!get(this, 'openDrive')) {
                return;
            }
            const row = get(this, 'openDrive');

            // eslint-disable-next-line ember/named-functions-in-promises
            return row.save().then(() => {
                get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                    autoClear: true,
                });

                return this.transitionToRoute('drivinglog.toggle');
            });
        },

        updateRoute() {
            return this.calculateDistance();
        },
    },
    calculateDistance() {
        const map = get(this, 'map');

        const start_location = JSON.parse(get(this, 'openDrive.location_start'));
        const start = new window.google.maps.LatLng(
            start_location.latitude,
            +start_location.longitude,
        );

        const end_location = JSON.parse(get(this, 'gps_value'));
        const end = new window.google.maps.LatLng(end_location.latitude, end_location.longitude);

        for (let m of Array.from(get(this, 'markers'))) {
            m.setMap(null);
        }
        set(this, 'markers', []);

        get(this, 'directionsDisplay').setMap(map);

        const request = {
            origin: start,
            destination: end,
            travelMode: 'DRIVING',
        };

        return get(this, 'directionsService').route(request, (response, status) => {
            if (status === 'OK') {
                get(this, 'directionsDisplay').setDirections(response);
                set(
                    this,
                    'route',
                    response.routes[0].legs[0].start_address +
                        ' - ' +
                        response.routes[0].legs[0].end_address,
                );
                set(
                    this,
                    'distance',
                    (response.routes[0].legs[0].distance.value / 1000).toFixed(1),
                );
            }
        });
    },
});

export default DrivinglogToggleIndexController;
