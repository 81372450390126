import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get, set } from '@ember/object';

export default Component.extend({
    session: service(),
    store: service(),
    Collector: service('collector-service'),

    totalPlaceholderImgs: 5,
    editing: false,

    showEditLink: computed(function () {
        return get(this, 'Collector').testNeeds(['project@add']);
    }),

    // if site is from current service and maincontactorname can be found return it
    // otherwise return companyname of the owner service
    companyName: computed(function () {
        if (
            get(this, 'Collector').testNeeds(['project.maincontractor']) &&
            get(this, 'mySite.partnerid') == get(this, 'session.currentUser.services.partnerid')
        ) {
            const project = get(this, 'store').peekRecord('project', get(this, 'mySite.id'));
            if (project) {
                const maincontractor = get(project, 'maincontractor.name');
                if (maincontractor) return maincontractor;
            }
        }

        return get(this, 'mySite.company');
    }),

    actions: {
        projectClicked(mySite) {
            this.projectClicked(mySite);
        },

        updateImage() {
            this.imageUpdated(get(this, 'mySite.id'));
            set(this, 'editing', false);
        },

        removeSiteFromMySites(mySite) {
            this.removeSiteFromMySites(mySite);
        },

        openOverview(mySite) {
            this.openOverview(mySite);
        },

        openFilesview(mySite) {
            this.openFilesview(mySite);
        },
    },
});
