import { helper } from '@ember/component/helper';
import { get } from '@ember/object';

export function randomizedColor(params, hash) {
    let str = '';

    if (hash && hash.record && hash.fields) {
        hash.fields.forEach((field) => {
            str += get(hash.record, field);
        });
    } else {
        str = params.join('');
    }

    hash = 0;

    for (let i in str) {
        if (str.hasOwnProperty(i)) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
    }

    let c = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
}

export default helper(randomizedColor);
