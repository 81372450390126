import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import podNames from 'ember-component-css/pod-names';
import { htmlSafe } from '@ember/string';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PromoAdComponent extends Component {
    @service session;
    @service intl;
    @service ajax;
    @service intl;
    @service ttapi;
    @service('collector-service') collector;

    @tracked email_message_sent;
    @tracked sending;
    @tracked showModal;
    @tracked more_info;
    @tracked sender;
    @tracked debug_email;

    constructor() {
        super(...arguments);
        //this.getOut();
        this.done = false;
        //console.log("products."+this.args.product);
        if (this.collector.testNeedsOne('products.' + this.args.product)) {
            this.done = true;
        }
        this.promoText = this.getPromoText();
    }
    get styleNamespace() {
        return podNames['promo-ad'];
    }
    async getPromoText() {
        let fileUrl = '';
        let text = '';
        let key = 'promo-texts-' + this.args.product + '-' + moment.locale();
        fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;
        try {
            text = htmlSafe(await this.ajax.request(fileUrl, { dataType: 'text' }));
        } catch (e) {
            text = `cannot find text for ${key}`;
        }
        return text;
    }
    @action
    async sendEmail() {
        this.sending = true;
        let mail = this.debug_email
            ? this.debug_email
            : this.intl.t('upgrade_service.send-to-email');

        await this.ttapi.request('/sendEmail', {
            method: 'post',
            data: {
                email: mail,
                title:
                    this.intl.t('upgrade_service.email-title') +
                    ' - ' +
                    this.session.currentUser.company +
                    ' (' +
                    this.session.currentUser.partnerid +
                    ')',
                content:
                    this.intl.t('upgrade_service.email_content') +
                    '\r\n' +
                    this.more_info +
                    '\r\n' +
                    this.sender,
            },
        });
        this.sending = false;
        this.email_message_sent = true;
    }

    @action
    closeModal() {
        this.showModal = false;
        this.sending = false;
        this.email_message_sent = false;
        this.more_info = '';
    }
}
