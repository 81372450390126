define("tt4/injections/services/ttapi", ["exports", "app/services/ttapi"], function (_exports, _ttapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ttapi.default;
    }
  });
});
