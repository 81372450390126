import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function sortIcon([columns, field]) {
    let sortClass = ' text-report-muted';
    if (columns && columns.includes(field + '-')) {
        sortClass = 'Down';
    } else if (columns && columns.includes(field)) {
        sortClass = 'Up';
    } else {
        return '';
    }

    return htmlSafe('<span class="move move-Sort' + sortClass + '"></span>');
}

export default helper(sortIcon);
