import { helper } from '@ember/component/helper';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';

/*
This helper is supposed to give field sums as CAPI formatted
 */
export function sumField(params, hash) {
    if (isEmpty(hash.rows)) {
        return;
    }
    switch (get(hash.field, 'type')) {
        case 'number':
        case 'hours':
            return sumNumbers(hash.field, hash.rows);
        case 'dropdown_options':
        case 'dropdown_values':
        case 'checkbox':
            return sumDropdownAndCheckbox(hash.field, hash.rows);
    }
}

function sumNumbers(field, rows) {
    return rows
        .reduce((a, b) => {
            return parseFloat(get(b, get(field, 'name'))) + parseFloat(a);
        }, 0)
        .toFixed(2);
}

function sumDropdownAndCheckbox(field, rows) {
    let options = rows.reduce((a, b) => {
        let value = get(b, get(field, 'name'));
        if (get(field, 'type') == 'checkbox') {
            if (value === false) {
                value = 'off';
            } else if (value === true) {
                value = 'on';
            }
        }
        if (!value) {
            return a;
        }
        if (isEmpty(get(a, value))) {
            a[value] = 1;
        } else {
            a[value] += 1;
        }
        return a;
    }, {});
    let str = '';
    Object.keys(options).forEach((k) => {
        str += `${k}:${options[k]},`;
    });
    return str.slice(0, -1);
}

export default helper(sumField);
