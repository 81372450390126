import moment from 'moment';
import config from 'tt4/config/environment';
// import fetch from 'fetch'

export async function fetchWeather(lat: string, lon: string, date?: string): Promise<any> {
    if (!date) date = moment().format('YYYYMMDD');

    let url: any = config.APP.WEATHER_HISTORY_API;

    if (date === moment().format('YYYYMMDD')) {
        url = config.APP.WEATHER_API;
    }

    url = url + '?lat=' + lat + '&lon=' + lon + '&date=' + date + '&hours=12';

    const req = await window.fetch(url);
    const response = (await req.json()) as any;

    const dewPoint = response.temperature - (100 - response.humidity) / 5;
    response.dew_point = Math.round(dewPoint * 100) / 100;

    return response;
}

export default { fetchWeather };
