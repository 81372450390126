/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/query-helper.coffee.old
// Generated by CoffeeScript 2.6.1
var QueryHelper;

import Ember from 'ember';

QueryHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    compute: function (params, hash) {
        var arr, i, item, len;
        arr = [];
        for (i = 0, len = params.length; i < len; i++) {
            item = params[i];
            arr.push(item);
        }
        return arr;
    },
});

export default QueryHelper;
