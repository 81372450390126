import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { Promise } from 'rsvp';
import { isPresent } from '@ember/utils';

export default class CollectorFormAddFormsDiaryComponent extends Component {
    @service flags;
    @service store;
    @service session;
    @service('collector-service') Collector;

    @tracked record;
    @tracked saving;
    @tracked date;
    @tracked project;

    constructor() {
        super(...arguments);
        if (!this.args.record && this.flags.test('ember-movenium-form-add-diary')) {
            this.record = this.store.createRecord('diary', { date: moment().format('YYYY-MM-DD') });
        } else this.record = this.args.record;
        this.date = this.record.date;
        this.project = this.record.project;
    }

    @action
    async onFieldChange(field, value) {
        if (field === 'date') this.date = value;
        if (field === 'project') this.project = value;
        if (field === 'project' || field === 'date') await this.setWorkdayStrength();
        this.record[field] = value;
    }

    @action
    async save() {
        this.saving = true;
        this.record['weather'] = JSON.stringify(this.record['weather']);
        await this.record.save();
        this.saving = false;
        this.args.close();
    }

    @action
    setValue(val) {
        this.record['event'] = val;
    }

    async setWorkdayStrength() {
        const rowsForDate = await this.store.query('diary', {
            date: this.date,
            project: this.project.id,
            sideload: true,
            order: 'date-',
        });
        if (rowsForDate.length > 0) {
            this.record = rowsForDate.firstObject;
        } else {
            const data = {};
            const strength = await this.getWorkdayStrength(this.date, this.project);
            setProperties(data, {
                date: this.date,
                project: this.project,
                workforce_foremen: strength.workforce_foremen ? strength.workforce_foremen : 0,
                workforce_subcontractor: strength.workforce_subcontractor
                    ? strength.workforce_subcontractor
                    : 0,
                workforce_workers: strength.workforce_workers ? strength.workforce_workers : 0,
            });
            this.record = this.store.createRecord('diary', data);
        }
    }

    async getWorkdayStrength(date, project) {
        let worktimes = A([]);
        let presences = A([]);

        if (this.Collector.formExists('worktime')) {
            worktimes = this.store.query('worktime', {
                date: date,
                project: project.id,
                limit: 'all',
                sideload: ['user.employer', 'user.userlevel', 'employer.name'],
            });
        }
        if (this.Collector.formExists('presence')) {
            presences = this.store.query('presence', {
                date: date,
                project: project.id,
                limit: 'all',
                sideload: ['user.employer', 'user.userlevel', 'employer.name'],
            });
        }
        const data = await Promise.all([worktimes, presences]);
        const rows = data[0].toArray().concat(data[1].toArray()).uniqBy('user');
        const employer = get(this.session.currentUser.employer, 'id');
        let own = null;

        if (isPresent(employer)) {
            if (this.Collector.testNeeds(['project.maincontractor'])) {
                const maincontractorId = project.maincontractor
                    ? get(project.maincontractor, 'id')
                    : null;
                own = rows.filterBy('employer.id', maincontractorId);
            } else own = rows.filterBy('employer.id', employer);
        } else {
            own = rows;
        }
        const foremen = own.filter((item) => [3, 5, 6].includes(parseInt(item.user.userlevel)));

        const subStrength = rows.length - own.length;
        const foremenStrength = foremen.length;
        const workerStrength = own.length - foremenStrength;

        return {
            workforce_workers: workerStrength,
            workforce_foremen: foremenStrength,
            workforce_subcontractor: subStrength,
        };
    }
}
