/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/observation/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyObservationEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

SiteSafetyObservationEditRoute = GeneralAddRoute.extend({
    modelName: 'site_safety_observation',
    controllerName: 'site_safety.observation.edit',
    //savedRoute: 'site_safety.observation.index'
    afterSave: 'site_safety.report.observation',
    edit: true,
});

export default SiteSafetyObservationEditRoute;
