/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/trnotices/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TrnoticesEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

TrnoticesEditRoute = GeneralAddRoute.extend({
    modelName: 'trnotice',
    controllerName: 'trnotices.edit',
    edit: true,
    afterModel: function (model, transition) {
        var page, type;
        type = model.get('type');
        if (type === 'tr') {
            page = 'site_safety.report.tr.notices';
        } else if (type === 'mvr') {
            page = 'site_safety.report.mvr.notices';
        } else {
            page = 'index';
        }
        return this.set('afterSave', page);
    },
});

export default TrnoticesEditRoute;
