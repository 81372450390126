/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/menu-bar.coffee.old
// Generated by CoffeeScript 2.6.1
var MenuComponent,
    indexOf = [].indexOf;

import collector from '../classes/collector';

import config from '../config/environment';

import $ from 'jquery';

Ember.LinkComponent.reopen({
    attributeBindings: ['role'],
});

MenuComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    layoutName: 'menu',
    tagName: 'ul',
    classNames: ['nav'],
    classNameBindings: ['sidemenu:nav-tabs:nav-sidebar'],
    attributeBindings: ['role'],
    refreshMenu: false,
    role: Ember.computed(function () {
        if (this.get('sidemenu')) {
            return 'tablist';
        }
    }),
    // go thru menu_settings and return all menus that has right privileges
    menus: Ember.computed(
        'active',
        'settings',
        'intl.primaryLocale',
        'refreshMenu',
        'session.currentUser',
        function () {
            var active_menu, c, menu_skeleton, menu_variables, self, temp;
            self = this;
            menu_skeleton = this.get('settings');
            menu_variables = this.get('variables');
            active_menu = this.get('active');
            c = this.get('Collector');
            temp = Ember.A([]);
            $.each(menu_skeleton, function (item) {
                var variables;
                if (menu_variables[item] && !menu_variables[item].show_only_add) {
                    variables = menu_variables[item];
                    variables.keyname = item;
                    if (
                        collector.getPrivilege(variables.form, variables.action) ||
                        config.APP.KIOSK
                    ) {
                        Ember.set(variables, 'active', item === active_menu);
                        if (!(variables.hide_leftmenu && self.get('hide_extra'))) {
                            return temp.push(variables);
                        }
                    }
                }
            });
            if (c.testNeeds(['worktime'])) {
                temp.push(menu_variables.comboreport);
            }
            if (!config.APP.KIOSK) {
                temp = this.customLinks(temp);
            }
            return temp;
        },
    ),
    customLinks: function (links) {
        var forms, hide_menus, self;
        self = this;
        forms = this.Collector.forms();
        if (!forms) {
            return links;
        }
        hide_menus = [];
        if (localStorage && localStorage.hide_menus) {
            hide_menus = JSON.parse(localStorage.hide_menus);
        }
        $.each(forms, function (key, item) {
            var hide, name;
            hide = false;
            if (indexOf.call(hide_menus, key) >= 0) {
                hide = true;
            }
            if (hide && self.get('hide_extra')) {
                return;
            }
            if (key.substring(0, 11) === 'measurement') {
                if (collector.getPrivilege(key, 'add')) {
                    name = key.substring(12).toLowerCase();
                    if (item.name != null) {
                        name = item.name;
                    }
                    return links.push(
                        Em.Object.create({
                            link: 'site_safety.measurement.graphs',
                            link_param: key.substring(12).toLowerCase(),
                            icon: 'move move-Length',
                            text: name,
                            hide_leftmenu: hide,
                            keyname: key,
                        }),
                    );
                }
            } else if (item.name != null) {
                name = item.name;
                return links.push(
                    Em.Object.create({
                        link: 'forms.form-report',
                        link_param: key,
                        icon: 'move move-Length',
                        text: name,
                        hide_leftmenu: hide,
                        keyname: key,
                    }),
                );
            }
        });
        return links;
    },
    //    setHideMenus: Em.on('init', ->
    //        if localStorage && localStorage.hide_menus
    //            @set('hide_menus', Em.A(JSON.parse(localStorage.hide_menus)))
    //    )
    hide_menus: Em.A([]),
    sidebar: Ember.computed.equal('type', 'sidebar'),
    sidr: Ember.computed.equal('type', 'sidr'),
    actions: {
        closeSidr: function () {
            this.set('editmode', false);
            return $.sidr('close');
        },
        editmode: function () {
            return this.toggleProperty('editmode');
        },
        invalidateSession: function () {
            delete localStorage['first-time-loader-greeting'];
            localStorage.removeItem('ember_simple_auth-session');
            return location.reload();
        },
        toggleMenuVisibility: function (item) {
            return item.toggleProperty('hide_leftmenu');
        },
        saveMenuState: function (items) {
            var a;
            a = items.filterBy('hide_leftmenu', true).mapBy('keyname');
            this.set('hide_menus', Em.A(a));
            localStorage.setItem('hide_menus', JSON.stringify(a));
            this.toggleProperty('refreshMenu');
            return this.send('closeSidr');
        },
    },
});

export default MenuComponent;
