/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/image-modal/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorImageModalComponent;

import Ember from 'ember';

import config from '../../../config/environment';

CollectorImageModalComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    _modal: Ember.computed('modal', function () {
        return this.get('modal').split(',');
    }),
    url: Em.computed('_modal', function () {
        var splittedUrl, whiteListUrls;
        if (this.get('_modal')[1].substr(0, 10) === 'data:image') {
            return this.get('_modal')[1] + ',' + this.get('_modal')[2];
        }
        // File urls for dev, next and production
        whiteListUrls = [
            'https://movdev-bucket',
            'https://next-customer-files',
            'https://production-customer-files',
        ];
        if (this.get('_modal')[1].startsWith('http')) {
            splittedUrl = this.get('_modal')[1].split('.');
            if (whiteListUrls.includes(splittedUrl[0])) {
                return this.get('_modal')[1];
            }
        } else {
            return config.APP.COLLECTOR_API_URL + '/' + this.get('_modal')[1];
        }
    }),
    actions: {
        removeModal: function () {
            window.history.back();
            return this.set('modal', []);
        },
    },
});

export default CollectorImageModalComponent;
