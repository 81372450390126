/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/messages/email-approvals/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var MessagesEmailApprovalsController;

import Ember from 'ember';

MessagesEmailApprovalsController = Ember.Controller.extend({
    queryParams: ['created_date'],
    show_columns: 'created_date,created_time,form,email,row_count,approved,comment,report',
    order: 'created_date-,created_time-',
    created_date: null,
    rowActions: [
        {
            action: 'rowHistory',
            icon: 'move move-Search',
        },
    ],
    filters: Em.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'created_date',
                type: 'date',
                form: 'email_approval',
                name: 'created_date',
            }),
        ]);
    }),
    mappedFilters: Em.computed('filters.@each.value', function () {
        return this.get('filters').map(function (filter) {
            return {
                name: filter.get('field'),
                value: filter.get('value'),
            };
        });
    }),
    actions: {
        filterChanged: function (value, fieldName) {
            if (value == null) {
                return this.set('created_date', '');
            } else {
                return this.set('created_date', value);
            }
        },
    },
});

export default MessagesEmailApprovalsController;
