/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/removed/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersRemovedController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

UsersRemovedController = GeneralReportController.extend({
    flags: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    form: 'user',
    showColumns: [
        'lastname',
        'firstname',
        'username',
        'taxnumber',
        'id06kort',
        'employer',
        'userlevel',
        'password',
        'email',
        'phone',
    ],
    queryParams: ['page', 'employer', 'q', 'group'],
    init_functions: function () {
        if (
            this.get('flags').test('form-report-users') ||
            this.get('flags').test('ember-movenium-form-report-users')
        ) {
            return null;
        } else {
            return this._super();
        }
    },
    filters: Em.A([
        Em.Object.create({
            field: 'employer',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    row_info_status: 'removed',
    employer: null,
    settings: Em.Object.create({
        showStatus: 'removed',
        rowActions: [],
    }),
    actions: {
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
    },
});

export default UsersRemovedController;
