import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BillingCompComponent extends Component {
    @service session;
    @service admintool;
    @service countryLists;
    @service() notifications;
    @service intl;

    @tracked invoicingAddress;
    @tracked billingType;
    @tracked loading;
    @tracked isSaving;

    errors = { street: false, zip: false, town: false, country: false };

    constructor() {
        super(...arguments);
        this.loading = true;
        this.setData();
    }

    eInvoicingOperators = [
        { id: 'apix', name: 'Apix Messaging Oy (003723327487)' },
        { id: 'basware', name: 'BasWare Einvoices Oy (BAWCFI22)' },
        { id: 'basware_oyj', name: 'BasWare Oyj (003705925424)' },
        { id: 'cgi', name: 'CGI - Logica (003703575029)' },
        { id: 'danske_bank', name: 'Danske Bank Oyj (DABAFIHH)' },
        { id: 'enfo', name: 'Enfo Oy (003718062728)' },
        { id: 'enfo_oyj', name: 'Enfo Oyj (003714377140)' },
        { id: 'inex', name: 'InExchange Factorum AB (INEXCHANGE)' },
        { id: 'liaison', name: 'LIAISON Technologies Oy (003708599126)' },
        { id: 'maventa', name: 'Maventa Services (003721291126)' },
        { id: 'nordea', name: 'Nordea (NDEAFIHH)' },
        { id: 'notebeat', name: 'Notebeat Oy (003717203971)' },
        { id: 'op', name: 'OP-Pohjola-ryhmä (OKOYFIHH)' },
        { id: 'opus', name: 'OpusCapita Group Oy - Itella (003710948874)' },
        { id: 'pagero', name: 'Pagero (003723609900)' },
        { id: 'pop', name: 'POP Pankit (POPFFI22)' },
        { id: 's_pankki', name: 'S-Pankki Oy (SBANFIHH)' },
        { id: 'sampo', name: 'Sampo Pankki Oyj (PSPBFIHH)' },
        { id: 'handelsbank', name: 'Svenska HAndelsbanken AB (HANDFIHH)' },
        { id: 'saasto', name: 'Säästöpankit (ITELFIHH)' },
        { id: 'tapiola', name: 'Tapiola Pankki (TAPIFI22)' },
        { id: 'teliasonera', name: 'TeliaSonera Finland Oyj (003714756079)' },
        { id: 'tieto', name: 'Tieto Oyj (003701011385)' },
        { id: 'aland', name: 'Ålandsbanken Abp (AABAFI22)' },
        { id: 'opus_solutions', name: 'OpusCapita Solutions Oy (E204503)' },
    ];

    @action
    copyFields() {
        set(
            this,
            'invoicingAddress.use_company_address',
            !this.invoicingAddress.use_company_address,
        );
        if (this.invoicingAddress.use_company_address) {
            set(this, 'invoicingAddress.zip', this.customerData.zip);
            set(this, 'invoicingAddress.town', this.customerData.town);
            set(this, 'invoicingAddress.country', this.customerData.country);
            set(this, 'invoicingAddress.street', this.customerData.street);
        }
    }

    @action
    async save() {
        this.isSaving = true;
        if (this.billingType == 'eInvoice') {
            this.clearFields(['email', 'zip', 'town', 'country', 'street']);
            if (this.checkMandatoryFields()) return;
        }
        if (this.billingType == 'email')
            this.clearFields([
                'zip',
                'town',
                'country',
                'street',
                'e_invoice_operator',
                'e_invoice_address',
            ]);
        if (this.billingType == 'paper')
            this.clearFields(['e_invoice_operator', 'e_invoice_address', 'email']);

        let data = this.getDataArray();
        let method = this.invoicingAddress ? 'PUT' : 'POST';

        await this.admintool.ajax({ url: 'billinginfo', method: method, data: data });

        if (this.args.popup) {
            await this.saveCompanyInfo();
            this.args.close();
        }

        if (this.args.close) this.args.close();
        this.isSaving = false;
        this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
    }

    async setData() {
        this.invoicingAddress = {};

        try {
            this.invoicingAddress = await this.admintool.ajax({
                url: 'billinginfo',
                method: 'get',
            });
        } catch (error) {
            if (error.statusText == 'Not Found') this.invoicingAddress = {};
        } finally {
            this.customerData = await this.admintool.ajax({ url: 'customer_info', method: 'get' });
            this.billingType =
                this.invoicingAddress && this.invoicingAddress.billingtype
                    ? this.invoicingAddress.billingtype
                    : 'email';
            this.loading = false;
        }
    }

    clearFields(fields) {
        fields.map((field) => set(this, `invoicingAddress.${field}`, null));
        if (this.billingType != 'paper') set(this, 'invoicingAddress.use_company_address', false);
    }

    getDataArray() {
        let addressInfo =
            this.invoicingAddress.use_company_address && this.billingType == 'paper'
                ? this.customerData
                : this.invoicingAddress;
        return {
            street: addressInfo.street,
            zip: addressInfo.zip,
            town: addressInfo.town,
            country: addressInfo.country,
            billingtype: this.billingType,
            email: this.invoicingAddress.email,
            e_invoice_operator: this.invoicingAddress.e_invoice_operator,
            e_invoice_address: this.invoicingAddress.e_invoice_address,
            invoice_ref: this.invoicingAddress.invoice_ref,
            use_company_address: this.invoicingAddress.use_company_address,
        };
    }

    checkMandatoryFields() {
        const mandatoryFields = ['street', 'zip', 'town', 'country'];
        let hasErrors = false;
        for (const field in this.customerData) {
            if (mandatoryFields.includes(field)) {
                if (!this.customerData[field] || this.customerData[field] == '') {
                    set(this, 'errors.' + field, true);
                    hasErrors = true;
                } else {
                    set(this, 'errors.' + field, false);
                }
            }
        }
        return hasErrors;
    }

    async saveCompanyInfo() {
        return await this.admintool.ajax({
            url: 'customer_info',
            method: 'PUT',
            data: {
                street: this.customerData.street,
                zip: this.customerData.zip,
                town: this.customerData.town,
                country: this.customerData.country,
                specifier: this.customerData.specifier,
            },
        });
    }
}
