import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

const MessageboardRoute = Route.extend(AuthenticatedRouteMixin, {
    ttapi: service(),

    queryParams: {
        showRemoved: {
            refreshModel: true,
        },
    },

    model(params) {
        let url = '/messageboard/all';
        if (params.showRemoved) {
            url += '?showRemoved=true';
        }
        return this.ttapi.request(url, { type: 'GET' });
    },

    actions: {
        rowAction() {},
        showRemovedRows() {},
    },
});

export default MessageboardRoute;
