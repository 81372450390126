/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils';

import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import GeneralReportRoute from '../../../routes/general-report';

const AbcensesIndexRoute = GeneralReportRoute.extend({
    capi: service(),
    flags: service(),

    queryParams: {
        startdate: {
            refreshModel: true,
        },
        status: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
    },

    form: 'abcense',

    model(params) {
        params = this._super(params);
        params.sideload = true;
        if (params.startdate && !this.Collector.testNeedsOne('tt3')) {
            params['startdate_enddate'] = params.startdate;
            delete params.startdate;
        }

        if (!this.flags.test('ember-movenium-form-report-absence')) {
            if (isPresent(params.groupBy)) {
                params.order = params.groupBy;
                return this.capi.findAll('abcense', params);
            } else {
                return this.store.query('abcense', params);
            }
        } else {
            if (params.page) delete params.page;
            set(this.controllerFor('abcenses.index'), 'qParams', params);
        }
    },
});

export default AbcensesIndexRoute;
