import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import GeneralReportRoute from '../../../routes/general-report';

const MachinesTransactionsRoute = GeneralReportRoute.extend({
    store: service(),
    flags: service(),

    queryParams: {
        starttime: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        tooltype: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },

    form: 'transaction',
    model(params) {
        params = this._super(params);

        if (params.page) {
            delete params.page;
        }
        return set(this.controllerFor('machines.transactions'), 'qParams', params);
    },
});

export default MachinesTransactionsRoute;
