import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const MachinesTransactionMoveRoute = Route.extend({
    store: service(),
    collector: service('collector-service'),
    model(params) {
        params.endtime = '(empty)';
        params.sideload = this.collector.getSideloads('transaction');
        params.sideload.push('tooltype.price');
        return this.store.query('transaction', params).then((objects) => objects.objectAt(0));
    },
});

export default MachinesTransactionMoveRoute;
