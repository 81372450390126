/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/row-info.coffee.old
// Generated by CoffeeScript 2.6.1
var RowInfo;

import DS from 'ember-data';

RowInfo = DS.Model.extend({
    created: DS.attr('date'),
    creatorid: DS.attr('number'),
    last_logged: DS.attr('date'),
    modified: DS.attr('date'),
    privilege_edit: DS.attr('boolean'),
    privilege_remove: DS.attr('boolean'),
    privilege_openlock: DS.attr('boolean'),
    source: DS.attr('string'),
    status: DS.attr('string'),
    trimmed_query: DS.attr('boolean'),
});

export default RowInfo;
