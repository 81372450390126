/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/saved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSavedRoute;

import Ember from 'ember';

import SavedRoute from '../../../routes/saved';

UsersSavedRoute = SavedRoute.extend({
    templateName: 'employers.saved',
});

export default UsersSavedRoute;
