/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/customer/record-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsCustomerRecordCompComponent;

import Ember from 'ember';

import recordComp from '../../../collector/record-comp/component';

FormsCustomerRecordCompComponent = recordComp.extend({
    layoutName: 'collector/record-comp',
    returnField: function (field) {
        if (field.name === 'campaign') {
            field.type = 'dropdown_options';
            field.options = Em.A([]);
            this.get('store')
                .findAll('campaign')
                .then((campaigns) => {
                    var camp, i, len, ref, results;
                    ref = campaigns.mapBy('name').uniq();
                    results = [];
                    for (i = 0, len = ref.length; i < len; i++) {
                        camp = ref[i];
                        if (camp === 'default') {
                            continue;
                        }
                        results.push(
                            field.options.pushObject({
                                id: camp,
                                value: camp,
                            }),
                        );
                    }
                    return results;
                });
        }
        return field;
    },
});

export default FormsCustomerRecordCompComponent;
