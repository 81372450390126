/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/addnotice/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyAddNoticeIndexRoute;

import Ember from 'ember';

SiteSafetyAddNoticeIndexRoute = Ember.Route.extend({
    Collector: Ember.inject.service('collector-service'),
    beforeModel: function () {
        var c;
        c = this.get('Collector');
        if (c.testNeedsOne('tr@add') && !c.testNeedsOne('mvr@add')) {
            this.transitionTo('site_safety.addnotice.tr');
        }
        if (c.testNeedsOne('mvr@add') && !c.testNeedsOne('tr@add')) {
            this.transitionTo('site_safety.addnotice.mvr');
        }
        if (!c.testNeedsOne('tr') && c.testNeedsOne('mvr')) {
            this.transitionTo('site_safety.addnotice.mvr');
        }
        if (!c.testNeedsOne('mvr') && c.testNeedsOne('tr')) {
            return this.transitionTo('site_safety.addnotice.tr');
        }
    },
});

export default SiteSafetyAddNoticeIndexRoute;
