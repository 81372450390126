/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/signatures/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAddSignaturesController;

import Ember from 'ember';

import collector from '../../../../classes/collector';

import { alias } from '@ember/object/computed';

OrientationsAddSignaturesController = Ember.Controller.extend({
    //    needs: ['application','orientations/add']
    session: Em.inject.service(),
    intl: Em.inject.service(),
    addController: Ember.inject.controller('orientations/add'),
    applicationController: Ember.inject.controller('application'),
    orientations: Ember.computed.alias('addController.orientations'),
    content: alias('model'),
    showSignatureModal: false,
    checkBoxes: Ember.computed('addController.orientations', function () {
        var arr, groups, intl, orientation;
        orientation = this.get('addController.orientations.firstObject');
        intl = this.get('intl');
        if (!orientation) {
            return;
        }
        groups = this.get('content.checkbox_groups');
        arr = [];
        groups.forEach(function (obj) {
            var i, subArr;
            subArr = [];
            i = 0;
            obj.options.forEach(function (item) {
                var name;
                if (item && item.get('checked')) {
                    name =
                        item.get('text.string') && item.get('text.string').substring(0, 1) === '$'
                            ? intl.t('orientations.' + obj.key + '.' + item.get('key'))
                            : item.get('text');
                    return subArr.push({
                        name: name,
                    });
                }
            });
            if (subArr.length > 0) {
                return arr.push({
                    title: obj.title,
                    checkboxes: subArr,
                    length: obj.options.length,
                });
            }
        });
        return arr;
    }),
    extraFields: Ember.computed(function () {
        var extras, extras_tmp, self;
        extras_tmp = collector.forms.orientation.objects;
        extras = [];
        self = this;
        $.each(extras_tmp, function (index, obj) {
            if (obj.type !== 'checkboxgroup') {
                if (index.indexOf('addon_') >= 0) {
                    return extras.push({
                        name: obj.name,
                        value: self.get('addController.orientations.firstObject.' + index),
                    });
                }
            }
        });
        return extras;
    }),
    actions: {
        validate: function () {
            var data;
            data = this.get('addController.place');
            if (!data || data === null || data === '') {
                this.set('error', this.get('intl').t('field.error.mandatory'));
                this.set('errors', true);
            } else {
                this.set('error', null);
                this.set('errors', false);
            }
            return false;
        },
        addPhoto: function (user, image) {
            user.set('image', image);
            return user.save();
        },
        openSignatureModal: function (model, field) {
            var signature;
            this.set('signatureModel', model);
            this.set('signatureField', field);
            signature = Ember.get(model, field);
            this.set('selectedSignature', signature);
            return this.set('showSignatureModal', true);
        },
        changeSignature: function (value) {
            var field, model;
            model = this.get('signatureModel');
            field = this.get('signatureField');
            Ember.set(model, field, value);
            return this.set('showSignatureModal', false);
        },
    },
});

// @signatureModel[@signatureField] = value
// Ember.set(@get 'signatureModel', @get 'signatureField', value)
export default OrientationsAddSignaturesController;
