/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/plus-menu.coffee.old
// Generated by CoffeeScript 2.6.1
var PlusMenuComponent;

import Ember from 'ember';

import menu from '../mixins/menu';

PlusMenuComponent = Ember.Component.extend(menu, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    gui: Ember.inject.service(),
    didInsertElement: function () {
        //close display-menu only if clicked outside of it
        return $('body').on('click touchend', (e) => {
            $('#add-menu-desktop').attr('data-anchor', 'navigation-top-plus');

            if (
                !$('#add-menu-desktop').is(e.target) &&
                $('#add-menu-desktop').has(e.target).length === 0
            ) {
                $('#add-menu-desktop').addClass('plusClose');

                if (this.get('isDestroyed')) {
                    return;
                }
                this.set('plusState', false);
            }
        });
    },
    plusState: false,
    isAddPage: Ember.computed('path', 'session.currentUser', function () {
        var i, len, link, links, path;
        if (!this.get('session.currentUser')) {
            return;
        }
        path = this.get('path');
        if (!path) {
            return;
        }
        if (path.slice(-6) === '.index') {
            path = path.slice(0, -6);
        }
        if (path === 'loading' || path === 'error') {
            return false;
        }
        links = this.getAddLinks();
        for (i = 0, len = links.length; i < len; i++) {
            link = links[i];
            if (path.indexOf('saved') !== -1) {
                return true;
            }
            if (Ember.isArray(link.link) && path.indexOf(link.link[0]) !== -1) {
                return true;
            }
        }
        return false;
    }),
    addLinks: Ember.computed(
        'session.currentUser',
        'intl.primaryLocale',
        'refreshMenus',
        function () {
            if (!this.get('session.currentUser')) {
                return;
            }
            return this.getAddLinks();
        },
    ),
    plusLinks: Ember.computed(
        'session.currentUser',
        'intl.primaryLocale',
        'refreshMenus',
        function () {
            var even, links, odd, plusarray, v;
            if (!this.get('session.currentUser')) {
                return;
            }
            links = this.getAddLinks();
            if (links.length < 10) {
                return false;
            } else {
                odd = (function () {
                    var i, len, results;
                    results = [];
                    for (i = 0, len = links.length; i < len; i += 2) {
                        v = links[i];
                        results.push(v);
                    }
                    return results;
                })();
                even = (function () {
                    var i, len, ref, results;
                    ref = links.slice(1);
                    results = [];
                    for (i = 0, len = ref.length; i < len; i += 2) {
                        v = ref[i];
                        results.push(v);
                    }
                    return results;
                })();
                plusarray = [odd, even];
                return plusarray;
            }
        },
    ),
    addLinksReversed: Ember.computed(
        'session.currentUser',
        'intl.primaryLocale',
        'refreshMenus',
        function () {
            var links;
            if (!this.get('session.currentUser')) {
                return;
            }
            links = this.getAddLinks();
            links.reverse();
            return links;
        },
    ),
    actions: {
        toggleplusMenu: function () {
            if (this.get('isDestroyed')) {
                return;
            }
            this.toggleProperty('plusState');
            return false;
        },
        closeplusMenu: function () {
            this.toggleProperty('plusmobileState');
            return false;
        },
        toggleScroll: function () {
            Ember.run.later(
                this,
                function () {
                    var elmt;
                    elmt = $(this.get('element')).find('.mobile-button-inner')[0];
                    if (!elmt) {
                        return this.send('toggleScroll');
                    }
                    return (elmt.scrollTop = elmt.scrollHeight);
                },
                50,
            );
            return false;
        },
        goToSkvLink: function (link) {
            return window.open(link, '_blank');
        },
    },
});

export default PlusMenuComponent;
