import { A } from '@ember/array';
import EmberObject from '@ember/object';
import GeneralReportController from '../../../controllers/general-report';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default GeneralReportController.extend({
    intl: service(),
    Collector: service('collector-service'),
    dialogs: service(),
    queryParams: ['page', 'date', 'user', 'q'],
    form: 'drive',
    showColumns: null,
    init: function () {
        this._super(...arguments);
        this.showColumns = [
            'date',
            'user',
            'route',
            'distance',
            'starttime',
            'endtime',
            'drive_hours',
            'private_drive',
            'license_plate',
        ];
    },
    drivinglog: alias('model'),
    filters: A([
        EmberObject.create({ field: 'date' }),
        EmberObject.create({ type: 'searchbox', bindto: 'q' }),
        EmberObject.create({ field: 'user' }),
    ]),
    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            editRoute: 'drivinglog.edit',
            historyRoute: 'drivinglog.history',
        }),
        massedit: true,
        hideColumns: ['location_start', 'location_end'],
    }),
});
