/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/history-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorHistoryCompComponent;

import Ember from 'ember';

import collector from '../../../classes/collector';

import { sanitize } from 'dom-purify';

import { pluralize } from 'ember-inflector';

import { isArray } from '@ember/array';

CollectorHistoryCompComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    capi: Ember.inject.service(),
    intl: Ember.inject.service(),

    // Component properties

    // Rows that contain the history data
    // format: [{
    //   "user": "Tauno Tulimies",
    //   "date": "2015-11-27 11:20:47",
    //   "columns": [
    //       {
    //        "column": "status",
    //        "old": null,
    //        "new": "approved",
    //        "action": "edited",
    //        "schemaid": "1953491"
    //       }
    //   ]
    // }, { ... }]
    rows: null,
    // Collector form external type that we are working with
    form: null,
    // Collector row id
    row: null,

    // Component methods

    translations: {
        famoco: 'Famoco',
        timeclean: 'Timeclean',
    },
    // If no given rows we fetch them by ourselves because we are cool!
    getRows: Em.on('init', function () {
        var form, id, url;
        this.set('changesArr', Em.A([]));
        if (this.get('rows')) {
            return;
        }
        form = this.get('form');
        id = this.get('row');
        Em.assert('If no rows are given you need to pass row id to history component', id);
        Em.assert('Must always pass form external type to history component', form);
        url = '/' + pluralize(form) + '/' + id + '/history';
        this.set('loading', true);
        return this.get('capi')
            .request(url)
            .then((data) => {
                if (!data) {
                    this.set('error', true);
                } else {
                    if (data['meta']) delete data['meta'];
                    if (!isArray(data)) data = Object.values(data);

                    this.set('rows', data);
                }
                return this.set('loading', false);
            })
            .catch((err) => {
                this.set('error', err);
                return this.set('loading', false);
            });
    }),
    // Format given rows to be pretty
    formatted_data: Em.computed('rows', function () {
        var c,
            crow,
            field,
            form,
            formatted_rows,
            i,
            j,
            k,
            len,
            len1,
            parser,
            ref,
            row,
            rows,
            user_agent,
            user_agent_obj;
        parser = new UAParser(); // used for parsing user-agent data
        rows = this.get('rows');
        c = this.get('Collector');
        form = this.get('form');
        if (!rows) {
            return;
        }
        Em.assert('Must always pass form external type to history component', form);
        formatted_rows = [];
        for (i = 0, len = rows.length; i < len; i++) {
            row = rows[i];
            user_agent_obj = parser.setUA(row.user_agent).getResult();
            // parsing user-agent (may work or may not)
            user_agent = [];
            if (user_agent_obj.browser.name) {
                user_agent['browser'] =
                    sanitize(user_agent_obj.browser.name) +
                    ' ' +
                    sanitize(user_agent_obj.browser.version);
                user_agent['engine'] =
                    sanitize(user_agent_obj.engine.name) +
                    ' ' +
                    sanitize(user_agent_obj.engine.version);
                user_agent['os'] =
                    sanitize(user_agent_obj.os.name) + ' ' + sanitize(user_agent_obj.os.version);
                if (user_agent_obj.device.model != null) {
                    user_agent['device'] =
                        sanitize(user_agent_obj.device.model) +
                        ' ' +
                        sanitize(user_agent_obj.device.vendor);
                }
                user_agent['raw'] = row.user_agent;
            }
            //else
            // lets assume that if browser is undefined, it is nfc-device
            //    user_agent['browser'] = @get('intl').t('rowhistory.nfc_device')
            //    user_agent['raw'] = "NFC"
            row.user_agent = user_agent;
            if (row.columns) {
                row.columns.forEach((column) => {
                    var splittedMessage;
                    if (column.message && column.message.substring(0, 7) === 'creator') {
                        splittedMessage = column.message.split(':');
                        return (row.user = splittedMessage[1]);
                    }
                });
                ref = row.columns;
                for (k = j = 0, len1 = ref.length; j < len1; k = ++j) {
                    crow = ref[k];
                    if (crow.action) {
                        row.action = crow.action;
                    }
                    if (crow.message) {
                        row.message = crow.message;
                    }
                    if (crow.source) {
                        row.source = crow.source;
                    }
                    if (!crow.column) {
                        continue;
                    }
                    field = c.field(form, crow.column);
                    field.ext = field.name;
                    row.columns[k].translated_name = field.translated_name;
                    row.columns[k].old = crow.old
                        ? c.format(crow.old, field, null, null, null, this.get('form'))
                        : crow.old;
                    row.columns[k].new = crow.new
                        ? c.format(crow.new, field, null, null, null, this.get('form'))
                        : crow.new;
                }
            }
            if (!row.action && row.changes) {
                row.action = 'edited';
            }
            formatted_rows.push(row);
        }
        return formatted_rows;
    }),
    actions: {
        toggleChanges: function (index) {
            var arr;
            arr = this.get('changesArr');
            if (arr.indexOf(index) === -1) {
                arr.pushObject(index);
            } else {
                arr.removeObject(index);
            }
            return this.notifyPropertyChange('changesArr');
        },
    },
});

export default CollectorHistoryCompComponent;
