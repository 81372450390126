/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/project-files/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsEditProjectFilesRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

ProjectsEditProjectFilesRoute = GeneralReportRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    gui: Ember.inject.service(),
    beforeModel: function () {
        this._super(...arguments);
        return this.set('gui.hideMobileHeader', false);
    },
    queryParams: {
        ori_date: {
            refreshModel: true,
            replace: true,
        },
        page: {
            refreshModel: true,
        },
        ori_site: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var project;
        params = this._super(params);
        params.ori_date = params.date;
        delete params.date;
        if (params.project) {
            if (params.project !== 'null') {
                params.ori_site = params.project;
            }
        }
        delete params.project;
        params.sideload = true;
        project = this.modelFor('projects.edit');
        //console.log project.get('id')
        params['project'] = project.get('id');
        return this.store.query('projectfile', params);
    },
});

export default ProjectsEditProjectFilesRoute;
