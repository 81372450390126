/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/salary/export-abcense/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var AbcensesExportAbcenseAddController;

import Ember from 'ember';

AbcensesExportAbcenseAddController = Ember.Controller.extend({
    selectableIntegrations: Ember.computed.gt('model.length', 1),
    selectedIntegration: Ember.computed('_selectedIntegration', function () {
        return this.get('_selectedIntegration') || this.get('model.firstObject.key');
    }),
});

export default AbcensesExportAbcenseAddController;
