import Controller from '@ember/controller';
import { set, computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import moment from 'moment';
import EmberObject from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

export default Controller.extend({
    gui: service(),
    queryParams: ['date', 'user', 'page', 'group', 'employer', 'worktimegroup', 'project'],
    form: 'worktask',
    user: null,
    limit: 25,
    page: 1,
    group: null,
    employer: null,
    worktimegroup: null,
    project: null,
    localStorageKey: 'worktaskganttcalendar_defaultColumns',
    users: alias('model.users'),
    unFilteredModel: alias('model.worktasks'),
    calendarRows: computed('model.worktasks.@each.{date,est_hours,project}', function () {
        if (get(this, 'model.users') && get(this, 'model.users').length !== 0) {
            let mapped = this.mapTasks(get(this, 'model.worktasks'), get(this, 'model.users'));
            return mapped.tasks;
        }
        return null;
    }),
    pageCount: computed('users', 'limit', function () {
        return Math.ceil(get(this, 'users.meta.count') / get(this, 'limit'));
    }),

    filters: computed(function () {
        return A([
            EmberObject.create({
                field: 'date',
                type: 'date',
                form: 'worktask',
                name: 'date',
                value: get(this, 'date'),
            }),
            EmberObject.create({
                field: 'user',
                type: 'database',
                form: 'worktask',
                name: 'user',
                value: get(this, 'user'),
            }),
            EmberObject.create({
                field: 'project',
                type: 'database',
                form: 'worktask',
                name: 'project',
                value: get(this, 'project'),
            }),

            EmberObject.create({
                field: 'group',
                type: 'database',
                form: 'user',
                name: 'group',
                value: get(this, 'group'),
                extra: true,
            }),
            EmberObject.create({
                field: 'employer',
                type: 'database',
                form: 'user',
                name: 'employer',
                value: get(this, 'employer'),
                extra: true,
            }),
            EmberObject.create({
                field: 'worktimegroup',
                type: 'database',
                form: 'user',
                name: 'worktimegroup',
                value: get(this, 'worktimegroup'),
                extra: true,
            }),
        ]);
    }),
    actions: {
        filterChanged(value, field) {
            if (['group', 'employer', 'worktimegroup'].includes(field)) {
                if (value) {
                    value = get(value, 'id');
                } else {
                    value = null;
                }
            }
            set(this, field, value);
        },

        catchActions(action, params) {
            var c, cols, fields;
            if (action === 'fieldsUpdate') {
                c = get(this, 'Collector');
                cols = [];
                params.forEach(function (col) {
                    return cols.push(col.ext);
                });
                fields = c.fieldArray(get(this, 'form'), cols);
                return set(this, 'ganttFields', fields);
            }
        },

        openWorktaskModal(day, user) {
            let params = { date: day, user: user };
            this.store.createRecord('worktask', params);
            this.transitionToRoute({ queryParams: { modal: ['worktask', 'new'] } });
        },

        openEditor(row) {
            this.transitionToRoute({ queryParams: { modal: ['worktask', get(row, 'id')] } });
        },
    },

    mapTasks(tasks, users) {
        let ret = A([]);
        users.forEach((user) => {
            //console.log(user);
            let rows = this.perDate(tasks.filterBy('user.id', get(user, 'id')));
            ret.push(
                EmberObject.create({
                    main: user,
                    rows: rows,
                }),
            );
        });
        return { tasks: ret, users: users };
    },

    perDate(rows) {
        let perDate = {};
        rows.forEach((item) => {
            const date = get(item, 'date');
            if (!perDate[date]) {
                perDate[date] = [];
            }
            if (get(item, 'enddate')) {
                let i = 1;
                const moment_date = moment(date);
                const diff = moment(get(item, 'enddate')).diff(moment_date, 'day');
                perDate[date].push(item);
                while (i <= diff) {
                    const new_date = moment_date.add(1, 'day').format('YYYY-MM-DD');
                    if (!perDate[new_date]) {
                        perDate[new_date] = [];
                    }
                    perDate[new_date].push(item);
                    i++;
                }
            } else {
                perDate[date].push(item);
            }
        });
        return perDate;
    },

    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().startOf('week').add(8, 'weeks').format('YYYY-MM-DD'),
});
