import Component from '@ember/component';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),

    columns: computed(async function () {
        let retBanks = A();

        const banks = await this.store.findAll('bank');
        banks.forEach((bank) => {
            const { id, name } = bank;
            retBanks.pushObject({ id, name });
        });

        return retBanks;
    }),

    didReceiveAttrs() {
        if (this.checkedRows) {
            this.record.user = this.checkedRows[0];
        }
    },

    actions: {
        fieldChanged(value) {
            const bank = this.store.peekRecord('bank', value);
            let column = bank.column;

            if (column === null || column === '') {
                column = 'bank_' + bank.id;
            }
            this.record.column = column;
            // this.record.hours = 4
        },
    },
});
