/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/employers/edit/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var EditController;

import Ember from 'ember';

import AddController from '../add/controller';

import collector from '../../../classes/collector';

EditController = AddController.extend({
    setNotChecked: null,
    Collector: Ember.inject.service('collector-service'),
    fieldSettings: Em.A({
        edit: true,
    }),
    edit: true,
    subMenus: Ember.computed(function () {
        var c, subMenus;
        c = this.get('Collector');
        subMenus = [
            {
                link: 'employers.edit.index',
                text: 'menu.edit_employer',
            },
            {
                link: 'employers.edit.employer-files',
                text: 'menu.employer-files',
                needs: ['employerfile'],
            },
        ];
        return subMenus.filter(function (item) {
            if (item.needs) {
                return c.testNeeds(item.needs);
            }
            return true;
        });
    }),
});

export default EditController;
