/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/skyddsronds/notice-array/component.coffee.old
// Generated by CoffeeScript 2.6.1
var SkyddsrondsNoticeArrayComponent;

import Ember from 'ember';

SkyddsrondsNoticeArrayComponent = Ember.Component.extend({
    notices: Em.computed('model', 'refresh', function () {
        var field, model;
        model = this.get('model');
        field = this.get('field');
        return model.get(field.name + '_notices');
    }),
    columns: Ember.computed('form', function () {
        var c, form;
        c = this.get('Collector');
        form = c.field(this.get('field.form'), this.get('field.name') + '_notices').options.form;
        return c.fieldArray(form).mapBy('name');
    }),
    showNotices: false,
    actions: {
        add: function () {
            if (this.get('addNotice')) {
                return this.addNotice('$med_anm', this.get('field.name'));
            }
        },
        showNotices: function () {
            return this.toggleProperty('showNotices');
        },
    },
});

export default SkyddsrondsNoticeArrayComponent;
