import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

const CollectorAddModalAbsenceComponent = Component.extend({
    type: 'multi',
    layoutName: computed('record', function () {
        return 'collector/record-comp';
    }),

    multipleDays: computed('type', function () {
        if (get(this, 'type') === 'single') return false;
        return true;
    }),

    settings: computed('record', 'type', function () {
        if (this.type === 'multi') {
            return {
                form: 'abcense',
                skip: ['starttime', 'endtime', 'status', 'absence_hours'],
                mandatory: ['startdate', 'enddate', 'dayamount'],
            };
        } else {
            // if hour-type adding
            let ret;
            if (this.Collector.testNeedsOne('products.absences_hours')) {
                ret = {
                    form: 'abcense',
                    skip: ['dayamount', 'status', 'enddate'],
                    mandatory: ['startdate', 'absence_hours'],
                };
            } else {
                ret = {
                    form: 'abcense',
                    skip: ['dayamount', 'status', 'enddate'],
                    mandatory: ['starttime', 'endtime', 'startdate'],
                };
            }
            return ret;
        }
    }),

    actions: {
        changeType(type) {
            set(this, 'type', type);
            if (type === 'multi') {
                set(this, 'record.starttime', null);
                set(this, 'record.endtime', null);
                return set(this, 'record.absence_hours', null);
            } else {
                set(this, 'record.enddate', null);
                return set(this, 'record.dayamount', null);
            }
        },

        didInsertElement() {
            this.setRightType();
        },

        removeModal(settings) {
            if (this.cancelClicked) {
                return this.cancelClicked(settings, false);
            }
        },

        modalRecordSaved(settings, record) {
            if (this.recordSaved) {
                return this.recordSaved(settings, record);
            }
        },

        setRightType() {
            let type = 'single';
            const record = this.record;
            if (!record || get(record, 'isNew')) {
                type = 'multi';
            }
            if ((record && get(record, 'starttime') === '') || !get(record, 'starttime')) {
                type = 'multi';
            } else {
                type = 'single';
            }
            set(this, 'type', type);
        },
    },
});

export default CollectorAddModalAbsenceComponent;
