/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/own-cordova-service.coffee.old
// Generated by CoffeeScript 2.6.1
var OwnCordovaService;

import Ember from 'ember';

import config from '../config/environment';

//import cordova from 'ember-cli-cordova/services/cordova'
OwnCordovaService = Ember.Service.extend({
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    moment: Ember.inject.service(),
    ajax: Ember.inject.service(),
    hasBeenCalled: false,
    setup: function () {
        return new Ember.RSVP.Promise((resolve, reject) => {
            if (window.cordova) {
                if (this.get('hasBeenCalled')) {
                    resolve();
                    return;
                }
                this.set('hasBeenCalled', true);
                return document.addEventListener(
                    'deviceready',
                    () => {
                        //                    cordova.plugins.Keyboard.disableScroll(true);
                        //                    cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true);

                        //                    window.addEventListener('native.keyboardshow', keyboardShowHandler);
                        //                    window.addEventListener('native.keyboardhide', keyboardHideHandler);
                        StatusBar.backgroundColorByHexString(config.statusBarColor);
                        StatusBar.overlaysWebView(false);
                        StatusBar.styleLightContent();
                        StatusBar.show();
                        this.checkAppVersion();
                        this.createFolder('Movenium');
                        window.open = cordova.InAppBrowser.open;

                        // Catch links that are supposed to open in system browser
                        // and explicitly open them in the system browser using JS
                        $ = jQuery;
                        $(document).on('click', 'a[target=_system]', function (event) {
                            event.preventDefault();
                            return window.open($(this).attr('href'), '_system');
                        });

                        return resolve();
                    },
                    false,
                );
            } else {
                return resolve();
            }
        });
    },
    createFolder: function (name) {
        return window.requestFileSystem(
            LocalFileSystem.PERSISTENT,
            1,
            (fileSys) => {
                return fileSys.root.getDirectory(
                    name,
                    {
                        create: true,
                        exclusive: false,
                    },
                    (directory) => {
                        return this.set('folder', directory);
                    },
                    (e) => {
                        console.log(e);
                        return this.get('dialogs').alert(
                            this.get('intl').t('cordova.error.folder_create_failed'),
                        );
                    },
                );
            },
            (e) => {
                console.log(e);
                return this.get('dialogs').alert(
                    this.get('intl').t('cordova.error.folder_create_failed'),
                );
            },
        );
    },
    // Takes binary data and a name
    saveFile: function (data, name, type) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var appDir;
            appDir = this.get('folder');
            name = (name + ' ' + moment().format('YYYYMMDDhmmss') + '.' + type).replace(
                new RegExp(' ', 'gi'),
                '_',
            );
            return appDir.getFile(
                name,
                {
                    create: true,
                    exclusive: false,
                },
                (fileEntry) => {
                    return fileEntry.createWriter(
                        (writer) => {
                            return this.fileWrite(writer, data).then(function (retWriter) {
                                return resolve(fileEntry.toURL());
                            });
                        },
                        (e) => {
                            reject(e);
                            this.get('dialogs').alert(
                                this.get('intl').t('cordova.error.file_create_failed'),
                            );
                            return console.log(e);
                        },
                    );
                },
                (e) => {
                    reject(e);
                    this.get('dialogs').alert(
                        this.get('intl').t('cordova.error.file_create_failed'),
                    );
                    return console.log(e);
                },
            );
        });
    },
    fileWrite: function (writer, data) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            writer.onwriteend = function (evt) {
                if (writer.length === 0) {
                    writer.write(data);
                } else {
                    resolve(writer);
                }
                return writer;
            };
            writer.onerror = (e) => {
                this.get('dialogs').alert(this.get('intl').t('cordova.error.file_create_failed'));
                return reject(e);
            };
            return writer.truncate(0);
        });
    },
    openFile: function (url, mime) {
        var self;
        self = this;
        return cordova.plugins.fileOpener2.open(url, mime, {
            error: (e) => {
                self.get('dialogs').alert(self.get('intl').t('cordova.error.file_open_failed'));
                return console.log(e);
            },
            success: () => {
                return console.log('opening file url');
            },
        });
    },
    checkAppVersion: function () {
        if (device.platform === 'Android') {
            return cordova.getAppVersion.getVersionNumber().then((version) => {
                return this.get('ajax')
                    .request(config.APP.ADMINTOOLURL + 'app_version', {
                        'Content-Type': 'application/json',
                    })
                    .then((data) => {
                        var conf, intl;
                        if (data && data[0] && data[0].version && data[0].version > version) {
                            intl = this.get('intl');
                            conf = config.mobile_links.Android;
                            return this.get('dialogs').alert(
                                '<h4>' +
                                    intl.t('cordova.old_version_text') +
                                    '</h4><br><a href="https://play.google.com/store/apps/details?id=fi.movenium.app">' +
                                    intl.t('cordova.download_app') +
                                    '<br><img class="app-icon app-icon-popup" alt=' +
                                    conf.img_alt +
                                    ' src=' +
                                    conf.img +
                                    ' ></a>',
                            );
                        }
                    });
            });
        }
    },
});

export default OwnCordovaService;
