import Component from '@ember/component';
import { get, set, computed, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),
    ttapi: service(),
    error: service(),
    intl: service(),
    localstorage: service(),
    analytics: service(),
    Collector: service('collector-service'),

    user: null,
    project: null,
    worktimegroup: null,

    formattedSalaryData: computed('salaryData', async function () {
        let salarytypes = await this.getSalarytypes();
        let data = await this.salaryData;
        if (isEmpty(data)) return [];
        let arr = [];

        //First calculate total work hours and push to array (% is always 100 if there are work hours)
        let total_hours = await this.calculateTotalHours();
        if (total_hours > 0) {
            arr.pushObject({
                salarytype: this.intl.t('report.worktime.work_hours'),
                accrual: get(this, 'Collector')._format_(
                    total_hours,
                    'worktime',
                    'work_hours',
                    'salarycategory',
                ),
                percent: '100.0',
            });
        }

        //Then loop through salarytypes and calculate accruals based on salarydata and % based on work hours
        salarytypes.forEach(async (type) => {
            let accrual = data.reduce((sum, row) => sum + (Number(row[type.name]) || 0), 0);
            if (accrual === 0) return;
            let percent =
                type.fieldType === 'work_hours' && total_hours > 0
                    ? ((accrual / total_hours) * 100).toFixed(1)
                    : '-';

            if (type.fieldType) {
                let fieldType =
                    type.fieldType === 'no_percent_hours' ? 'work_hours' : type.fieldType;
                accrual = get(this, 'Collector')._format_(
                    accrual,
                    'worktime',
                    fieldType,
                    'salarycategory',
                );
            }

            let salarytype = type.translatedName ? type.translatedName : type.name;
            arr.pushObject({
                salarytype: salarytype,
                accrual:
                    type.fieldType === 'comp'
                        ? accrual + ' ' + this.intl.t('general.amount')
                        : accrual,
                percent: percent >= 0 ? percent : '-',
            });
        });

        let sortedArr = arr.sort((a, b) => {
            let percentA = !isNaN(parseFloat(a.percent)) ? parseFloat(a.percent) : 0;
            let percentB = !isNaN(parseFloat(b.percent)) ? parseFloat(b.percent) : 0;
            if (percentA === 0 && typeof a.accrual === 'string' && !a.accrual.indexOf('h') > -1) {
                percentA = 0.01;
            }
            return percentB - percentA;
        });

        return sortedArr;
    }),

    tableLayout: computed(function () {
        if (this.localstorage.getItem('sumwidget-layout')) {
            return this.localstorage.getItem('sumwidget-layout');
        } else {
            return 'report';
        }
    }),

    projectField: computed('project', function () {
        let field = this.Collector.field('worktime', 'project');
        if (field) {
            field.prompt = 'general.all_projects';
        }
        return field;
    }),

    userField: computed('user', function () {
        let field = this.Collector.field('worktime', 'user');
        if (field) {
            field.prompt = 'general.all_users';
        }
        return field;
    }),

    /*worktimegroupField: computed('worktimegroup', function() {
        let field = this.Collector.field('user', 'worktimegroup')
        if (field) {
            field.value = this.worktimegroup
            field.prompt = "general.all_worktimegroups"
        }
        return field
    }),*/

    init() {
        this._super();
        this.headers = ['salarytype', 'accrual'];
        if (this.session.currentUser.userlevel !== '1') this.headers.push('percent');
        this.form = 'salarytype';
        this.useSalarytypeV2 = this.Collector.testNeeds(['products.salarytype_v2']);
        this.salarytypes = this.store.query(this.useSalarytypeV2 ? 'salarytypev2' : 'salarytype', {
            sideload: true,
            limit: 'all',
        });
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumeEvent');
    },

    async didReceiveAttrs() {
        set(this, 'periods', await this.getPeriods());
        setProperties(this, {
            date: this.periods[0],
            user: this.session.currentUser.id,
            project: this.project,
            worktimegroup: null,
        });
        set(this, 'salaryData', this.getSalaryData());
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumeEvent');
    },

    actions: {
        filterChange(value, field) {
            if (field && typeof field === 'string') {
                this.analytics.trackEvent({
                    category: 'Dashboard',
                    action: `change ${field} filter`,
                    label: 'salary-sum widget',
                });
                if (!value) set(this, field, null);
                else set(this, field, value.id);
            }
            set(this, 'salaryData', this.getSalaryData());
        },

        changeDate(value) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `change date filter`,
                label: 'salary-sum widget',
            });
            if (value) set(this, 'date', value);
            set(this, 'salaryData', this.getSalaryData());
        },

        changeLayout(layout) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Use ${layout} layout`,
                label: 'salary-sum widget',
            });

            this.localstorage.setItem('sumwidget-layout', layout);
            this.notifyPropertyChange('tableLayout');
        },
    },

    async getPeriods() {
        if (!this.currentSalaryPeriod) await this.setCurrentPeriod();
        return [
            {
                key: 'this_week',
                id:
                    moment().startOf('isoWeek').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('isoWeek').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.this_week'),
            },
            {
                key: 'last_week',
                id:
                    moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD') +
                    '_' +
                    moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.last_week'),
            },
            {
                key: 'this_month',
                id:
                    moment().startOf('month').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('month').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.this_month'),
            },
            {
                key: 'last_month',
                id:
                    moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') +
                    '_' +
                    moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.last_month'),
            },
            {
                key: 'this_year',
                id:
                    moment().startOf('year').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('year').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.this_year'),
            },
            {
                key: 'last_year',
                id:
                    moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD') +
                    '_' +
                    moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.last_year'),
            },
            {
                key: 'this_salaryperiod',
                id: this.currentSalaryPeriod,
                name: this.intl.t('general.period.this_salaryperiod'),
            },
        ];
    },

    async setCurrentPeriod() {
        let salaryPeriods = this.store.peekAll('wage-period');
        if (isEmpty(salaryPeriods)) salaryPeriods = await this.store.findAll('wage-period');
        if (isEmpty(salaryPeriods))
            return (this.currentSalaryPeriod =
                moment().startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('month').format('YYYY-MM-DD'));

        const currentDate = moment().format('YYYY-MM-DD');
        const currentPeriods = salaryPeriods.filter((period) => {
            return period.period_start_date <= currentDate && period.period_end_date >= currentDate;
        });
        if (isEmpty(currentPeriods))
            return (this.currentSalaryPeriod =
                moment().startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('month').format('YYYY-MM-DD'));
        // Just set first that is found
        this.currentSalaryPeriod =
            currentPeriods[0].period_start_date + '_' + currentPeriods[0].period_end_date;
    },

    onStoreEvent(params) {
        if (params.type === 'worktime' || params.type === 'abcense') {
            set(this, 'salaryData', this.getSalaryData());
        }
    },

    async onResumeEvent(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        if (this.currentSalaryPeriod) {
            // If dashboard is open for a long time, we have to update current period if its end date is past current date
            const splitCurrent = this.currentSalaryPeriod.split('_');
            if (splitCurrent[1] < moment().format('YYYY-MM-DD')) await this.setCurrentPeriod();
        }
        set(this, 'periods', await this.getPeriods());
        if (this.date.key) set(this, 'date', this.periods.findBy('key', this.date.key));
        else set(this, 'date', this.periods[0]);
        set(this, 'salaryData', this.getSalaryData());
    },

    async getSalaryData() {
        let data = {
            date: this.date?.id
                ? this.date.id
                : moment().startOf('isoWeek').format('YYYY-MM-DD') +
                  '_' +
                  moment().endOf('isoWeek').format('YYYY-MM-DD'),
            user: this.user,
            project: this.project ? this.project : null,
            allowEmptySalaryCodes: true,
            useSalarytypeV2: this.useSalarytypeV2,
        };

        /*if (this.worktimegroup) {
            data.worktimegroup = this.worktimegroup
            data.user = null
        }*/
        return this.ttapi.request('api/salary-categories', {
            method: 'GET',
            data: data,
        });
    },

    async getSalarytypes() {
        let data = await this.salaryData;
        if (!data) return [];
        let types = [];
        data.forEach((row) => {
            types = types.concat(
                Object.keys(row).reject((item) => ['date', 'hours'].includes(item)),
            );
        });
        // only unique ones sorted
        types = types.filter((v, i, a) => a.indexOf(v) === i).sort();

        let stypes = await this.salarytypes;
        let formFields = await this.Collector.fieldArray('worktime');
        let temptypes = [];
        types.forEach((row) => {
            let fieldType = this.getFieldType(row, stypes, formFields);
            if (['pause', 'saldo'].includes(row)) {
                let translatedName = this.intl.t('salarycategory.' + row);
                temptypes.push({ name: row, fieldType, translatedName });
            } else {
                temptypes.push({ name: row, fieldType });
            }
        });
        return temptypes;
    },

    getFieldType(type, salary, fields) {
        let typeName = type.split(/\((.*)\)/);
        let stype = salary.find((item) => item.name == typeName[0].trim());
        if (!stype) return 'no_percent_hours';
        if (stype.control === 'work_hours;salarytype=$this') return 'work_hours';
        if (stype.control === '_midweek_holiday_pay') return 'no_percent_hours';
        let fieldExt = this.useSalarytypeV2 ? stype.type : stype.option;
        if (!stype.option && stype.control) {
            let control = stype.control.split(/;|=/);
            fieldExt = control[1];
        }

        // compensations and other dropdowns have : have in them (ie meal:full) but the field external type is only
        // the first part.. ie meal:full -> meal
        if (fieldExt.includes(':')) fieldExt = fieldExt.split(':')[0];

        let field = fields.find((item) => item.name == fieldExt);
        if (!field || field.type === 'hours') return 'work_hours';
        return field.name;
    },

    handleComp(data, type) {
        let dropdown = [];
        data.forEach((row) => {
            if (!row[type.name]) return;
            dropdown[row[type.name]] = (dropdown[row[type.name]] || 0) + 1;
        });
        return dropdown;
    },

    async calculateTotalHours() {
        let worktimeHours = await this.store.query('worktime', {
            user: this.user,
            date: this.date.id,
            project: this.project,
            cols: 'starttime,endtime,work_hours,user',
        });
        return worktimeHours.reduce((sum, row) => sum + (row['work_hours'] || 0), 0);
    },
});
