/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/export-file.coffee.old
// Generated by CoffeeScript 2.6.1
var ExportFileMixin;

import Ember from 'ember';

ExportFileMixin = Ember.Mixin.create({
    exportFile: function (content, filename = 'export', type = 'txt') {
        var fullFileName;
        fullFileName = filename + '.' + type;
        if (typeof LocalFileSystem !== 'undefined' && LocalFileSystem !== null) {
            return this._exportLocalFile(content, fullFileName, 'text/' + type);
        } else {
            //download file for desktop
            return this._createExportLink(content, fullFileName, 'attachment/' + type);
        }
    },
    _exportLocalFile: function (content, fullFileName, mime) {
        return window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fileSystem) {
            return fileSystem.root.getFile(
                fullFileName,
                {
                    create: true,
                },
                function (entry) {
                    var fileEntry;
                    fileEntry = entry;
                    return entry.createWriter(function (writer) {
                        //write file and open
                        writer.onwrite = function (evt) {
                            return cordova.plugins.fileOpener2.open(fileEntry.toURL(), mime);
                        };
                        return writer.write(encodeURIComponent(content));
                    });
                },
            );
        });
    },
    _createExportLink: function (content, fullFileName, mime) {
        var a;
        a = document.createElement('a');
        a.href = 'data:' + mime + ',' + encodeURIComponent(content);
        a.target = '_blank';
        a.download = fullFileName;
        document.body.appendChild(a);
        return a.click();
    },
});

export default ExportFileMixin;
