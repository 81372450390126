import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default Helper.extend({
    // makes the first line in SVG card. See helper inline-text-2.js
    Collector: service('collector-service'),

    compute(params, hash) {
        let field = hash.field;
        let form = hash.form;
        let value = hash.value;
        let owner = hash.owner;
        let splitText = [];
        let recordValue = '';

        // set recordValue based on record owner
        recordValue =
            owner == 'taitorekisteri' ? value : this.Collector._format_(value, form, field);

        // The lenght of 20 is based on average lenght of the first word in custom headers
        if (recordValue.length > 20) {
            if (recordValue.includes('–')) {
                splitText = recordValue.split('–');
                return splitText[1];
            } else if (recordValue.lastIndexOf(' ')) {
                const i = recordValue.lastIndexOf(' ');
                return recordValue.substring(i);
            }
        } else {
            return;
        }
    },
});
