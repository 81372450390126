import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import fetch from 'fetch';
import $ from 'jquery';
import config from 'tt4/config/environment';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';
import { captureException } from '@sentry/browser';
import { scheduleOnce } from '@ember/runloop';

export default class WizardBuilderBillingPage1Component extends Component {
    @service session;
    @service admintool;
    @service intl;

    @tracked type;
    @tracked header;
    @tracked savedErrors;
    @tracked disabled;
    @tracked validFields;
    @tracked billingtype;
    @tracked isSaving;
    @tracked card_error;

    texts = { subscribe: 'subscribe' };

    constructor() {
        super(...arguments);
        this.load();
    }

    get styleNamespace() {
        return podNames['wizard-builder/billing'];
    }

    get isValid() {
        if (this.billingtype == 'card') {
            return this.validFields.length == 4;
        }
        return true;
    }

    async load() {
        // we have to load and save all stripe-stuff here, not in the parent-component
        // because we cannot send card-data from this component to any other component
        // this is a bit against DDAU, but what you gonna do?
        this.loaded = false;
        if (typeof Stripe === 'undefined') {
            await $.getScript('https://js.stripe.com/v3/'); // eslint-disable-line
        }

        this.stripe = Stripe(config.APP.STRIPE_KEY);
        let elements = this.stripe.elements();

        var elementStyles = {
            base: {
                color: '#212121',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#737373',
                },
                ':-webkit-autofill': {
                    color: '#737373',
                },
            },
            invalid: {
                color: '#FF0000',

                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };

        this.cardElement = elements.create('cardNumber', { style: elementStyles });
        this.cardExpiryElement = elements.create('cardExpiry', { style: elementStyles });
        this.cardCVCElement = elements.create('cardCvc', { style: elementStyles });

        // we mount card elements only after rendering is done
        scheduleOnce('afterRender', this, 'mountCardElements');
    }

    mountCardElements() {
        this.cardElement.mount('#card-number');
        this.cardExpiryElement.mount('#card-expiry');
        this.cardCVCElement.mount('#card-cvc');

        let self = this;

        [this.cardElement, this.cardExpiryElement, this.cardCVCElement].forEach(function (element) {
            element.on('change', function (event) {
                self.args.validateErrors(event);
            });
        });
    }

    @action
    async save() {
        let billingData = {};
        billingData['billingtype'] = this.args.billingtype;
        if (this.args.billingtype == 'einvoice') {
            billingData['e_invoice_operator'] = this.e_invoice_operator;
            billingData['e_invoice_address'] = this.e_invoice_address;
            billingData['invoice_ref'] = this.invoice_ref;
            await this.args.save(billingData);
        } else if (this.args.billingtype == 'card') {
            await this.args.save(billingData);
            await this.saveToStripeSecure(); // we have to do this here because we cannot send card values to parent-component because of stripe
        }
        if (!this.card_error) this.args.transitionToNextPage();
    }

    /**
     * Handles creating new and updating old subscriptions
     */
    async createOrUpdateSubscription(paymentMethodId = false) {
        const data = {
            email: get(this, 'session.currentUser.email'),
            company_name: get(this, 'session.currentUser.company_info.company_name'),
            name_on_card: this.name_on_card,
            paymentMethodId: paymentMethodId ? paymentMethodId : null,
        };
        const response = await fetch(config.APP.BILLING_URL + '/subscribe', {
            headers: {
                Authorization: 'Bearer ' + get(this, 'session.session.authenticated.access_token'),
            },
            method: 'POST',
            body: JSON.stringify(data),
        });
        const subscription = await response.json();
        //console.log(subscription);
        return subscription;
    }

    async updatePaymentMethod(clientSecret) {
        const result = await this.stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: this.cardElement,
                billing_details: {
                    name: this.name_on_card,
                },
            },
        });
        try {
            let subscription = await this.createOrUpdateSubscription(
                result.setupIntent.payment_method,
            );

            // we have to confirm card payment also if invoice is in "requires_confirmation" state
            if (
                typeof subscription.attributes.subscription.latest_invoice != 'string' &&
                subscription.attributes.subscription.latest_invoice.payment_intent.status ==
                    'requires_confirmation'
            ) {
                await this.stripe.confirmCardPayment(
                    subscription.attributes.subscription.latest_invoice.payment_intent
                        .client_secret,
                    {
                        payment_method:
                            subscription.attributes.subscription.latest_invoice.payment_intent
                                .payment_method,
                    },
                );
            }
            this.card_error = false;
        } catch (error) {
            captureException(error); // try to catch possible errors in sentry
            if (error.error)
                this.card_error = this.intl.exists('billing_info.' + error.error)
                    ? this.intl.t('billing_info.' + error.error)
                    : this.intl.t('billing_info.card_error');
            else this.card_error = this.intl.t('billing_info.card_error');
        }
        return result;
    }

    async saveToStripeSecure() {
        this.isSaving = true;
        try {
            let subscription = await this.createOrUpdateSubscription();
            let result;

            if (subscription.clientSecret && subscription.clientSecret.indexOf('seti_') > -1) {
                result = await this.updatePaymentMethod(subscription.clientSecret);
                if (result.error) {
                    this.card_error = this.intl.t('billing_info.card_error');
                } else {
                    this.card_error = false;
                }
            }
        } catch (error) {
            captureException(error); // try to catch possible errors in sentry
            if (error.error)
                this.card_error = this.intl.exists('billing_info.' + error.error)
                    ? this.intl.t('billing_info.' + error.error)
                    : this.intl.t('billing_info.card_error');
            else this.card_error = this.intl.t('billing_info.card_error');
        }
        this.isSaving = false;
    }
}
