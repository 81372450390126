import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import Ember from 'ember';

export default Service.extend({
    namespace: 'tt4',
    session: service(),

    setItem(key, value) {
        localStorage.setItem(this.createKey(key), JSON.stringify(value));
    },

    getItem(key) {
        const str = localStorage.getItem(this.createKey(key));
        if (str === 'undefined') return null;
        return JSON.parse(str);
    },

    removeItem(key) {
        localStorage.removeItem(this.createKey(key));
    },

    createKey(str) {
        const currentuser = Ember.testing ? 'testid' : get(this, 'session.currentUser.id');
        if (!currentuser)
            throw new Error('Cannot use localstorage service because currentUser is not set');
        return this.namespace + '.' + currentuser + '.' + str;
    },
});
