/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/classes/validations.coffee.old
// Generated by CoffeeScript 2.6.1
var Validator,
    indexOf = [].indexOf;

import Ember from 'ember';

import { isEmpty } from '@ember/utils';

import config from '../config/environment';

import $ from 'jquery';

import { getOwner } from '@ember/application';
import fetch from 'fetch';
import { pluralize } from 'ember-inflector';

Validator = Ember.Object.create({
    /*
  validate_mandatory: (model, value, options) ->
      d = new $.Deferred()
      if typeof options == 'object'
          for obj in options
              for k, v of obj
                  v = v.toString()
                  v_arr = v.split(',')
                  compareVal = model.get(k)

                  #fix for checkbox when checkbox is not set in init
                  if v is "true" or v is "false"
                      if compareVal == undefined
                          compareVal = false

                  if (compareVal is null || compareVal is "" || compareVal == undefined)
                      d.resolve(true)
                      continue
                  compareVal = compareVal.toString()
                  if v.substring(0,1) == "!"
                      if compareVal != v.substring(1)
                          mandatory = true
                  else
                      if compareVal in v_arr
                          mandatory = true
              if mandatory then d.resolve((value is null || value is "" || value is undefined)) else d.resolve()
      else
          d.resolve((value is null || value is "" || value is undefined))

      return d
   */
    validate_mandatory: function (model, value, options) {
        return new Ember.RSVP.Promise((resolve) => {
            if (!this._is_visible(model, options)) {
                resolve(false);
            }
            if (value instanceof Ember.ObjectProxy || value instanceof Ember.ArrayProxy) {
                value = value.get('content');
            }
            return resolve(Ember.isEmpty(value));
        });
    },
    _is_visible: function (model, options) {
        var i, is_visible, len, test, test_field, test_values;
        if (!(typeof options === 'object')) {
            return true;
        }
        if (!model) {
            return true;
        }
        is_visible = false;
        for (i = 0, len = options.length; i < len; i++) {
            test = options[i];
            for (test_field in test) {
                test_values = test[test_field];
                if (this._value_is_in_list(model.get(test_field), test_values)) {
                    is_visible = true;
                }
            }
        }
        return is_visible;
    },
    _value_is_in_list: function (value, list_string) {
        var found, list, negation;
        // make sure that list_string is string
        list_string = list_string.toString();
        // checkbox
        if (list_string === 'true') {
            if (value) {
                return true;
            } else {
                return false;
            }
        }
        if (list_string === 'false') {
            if (value) {
                return false;
            } else {
                return true;
            }
        }
        negation = false;
        if (list_string.substring(0, 1) === '!') {
            list_string = list_string.substring(1);
            negation = true;
        }
        list = list_string.split(',');
        found = indexOf.call(list, value) >= 0;
        if (indexOf.call(list, '(empty)') >= 0) {
            found = isEmpty(value);
        }
        if (negation) {
            return !found;
        } else {
            return found;
        }
    },
    // Validate length of value

    // value: |string|number|array| to validate length on, if array we validate each row of array
    // options: |number| wanted length
    validate_length: function (model, value, options) {
        var d, error, i, item, len, parsed;
        d = new $.Deferred();
        error = false;
        if (!value) {
            error = false;
        } else if (this.isJSON(value)) {
            parsed = JSON.parse(value);
            if (Array.isArray(parsed)) {
                for (i = 0, len = parsed.length; i < len; i++) {
                    item = parsed[i];
                    if (item.toString().length !== options) {
                        error = true;
                    }
                }
            }
        } else if (value.length !== options) {
            error = true;
        }
        if (error) {
            d.resolve({
                length: options,
            });
        } else {
            d.resolve();
        }
        return d;
    },
    validate_minLength: function (model, value, options) {
        var d;
        d = new $.Deferred();
        if (value.length < options) {
            d.resolve({
                length: options,
            });
        } else {
            d.resolve();
        }
        return d;
    },
    validate_maxLength: function (model, value, options) {
        var d;
        d = new $.Deferred();
        if (value && value.length > options) {
            d.resolve({
                length: options,
            });
        } else {
            d.resolve();
        }
        return d;
    },
    validate_number: function (model, value, options) {
        var d;
        d = new $.Deferred();
        if (isNaN(value)) {
            d.resolve(true);
        } else {
            d.resolve();
        }
        return d;
    },
    validate_forced_pause: function (model, value, options) {
        return new Ember.RSVP.Promise((resolve) => {
            var minutes, split;
            if (!model.get('pause')) {
                resolve();
            }
            // check for start-end or work_hours
            if (model.get('endtime')) {
                minutes = moment(model.get('date') + ' ' + model.get('endtime')).diff(
                    moment(model.get('date') + ' ' + model.get('starttime')),
                    'minutes',
                );
                if (minutes < 0) {
                    minutes += 1440;
                }
            } else {
                split = model.get('work_hours').split(':');
                minutes = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
            }
            if (minutes > 360 && model.get('pause') < 30) {
                return resolve(true);
            }
            return resolve();
        });
    },
    validate_multiPause: function (model, value, options) {
        return new Ember.RSVP.Promise((resolve) => {
            // If both starttime and endtime are empty, we don't need to validate multipauses (😁)
            if (!model.get('endtime') && !model.get('starttime')) resolve(false);
            // If no multipauses at all, no need to validate anything
            if (isEmpty(model.get('multipause'))) resolve(false);

            // Begin validating worktime multipauses one by one
            model.get('multipause').forEach((multipause) => {
                if (!multipause.start) return; // continue loop if multipause doesn't have start
                let multiPauseStart = moment('01-01-01 ' + multipause.start, 'YY-MM-DD HH:mm');
                let worktimeStart = moment('01-01-01 ' + model.get('starttime'), 'YY-MM-DD HH:mm');

                if (model.get('endtime')) {
                    let worktimeEnd = moment('01-01-01 ' + model.get('endtime'), 'YY-MM-DD HH:mm');
                    const worktimeGoesOvernight = worktimeEnd.isBefore(worktimeStart);
                    if (worktimeGoesOvernight) {
                        if (
                            multiPauseStart.isBefore(worktimeStart) &&
                            multiPauseStart.isBefore(worktimeEnd)
                        )
                            multiPauseStart = moment(
                                '01-01-02 ' + multipause.start,
                                'YY-MM-DD HH:mm',
                            );
                        worktimeEnd = moment('01-01-02 ' + model.get('endtime'), 'YY-MM-DD HH:mm');
                    }

                    if (!multiPauseStart.isBetween(worktimeStart, worktimeEnd)) resolve(true);
                    if (multipause.end) {
                        let multiPauseEnd = moment('01-01-01 ' + multipause.end, 'YY-MM-DD HH:mm');
                        if (
                            worktimeGoesOvernight &&
                            multiPauseEnd.isBefore(worktimeStart) &&
                            multiPauseEnd.isBefore(
                                moment('01-01-01 ' + model.get('endtime'), 'YY-MM-DD HH:mm'),
                            )
                        ) {
                            multiPauseEnd = moment('01-01-02 ' + multipause.end, 'YY-MM-DD HH:mm');
                        }
                        if (!multiPauseEnd.isBetween(worktimeStart, worktimeEnd)) resolve(true);
                    }
                } else if (multiPauseStart.isBefore(worktimeStart)) {
                    resolve(true);
                }
            });
            return resolve(false);
        });
    },
    validate_multipleHours: function (model, value, options) {
        return new Ember.RSVP.Promise((resolve) => {
            var formatMinutes, minutes, sameAs;
            if (!model.get('endtime')) {
                resolve();
            }
            minutes = moment(model.get('date') + ' ' + model.get('endtime')).diff(
                moment(model.get('date') + ' ' + model.get('starttime')),
                'minutes',
            );
            if (model.get('pause')) {
                minutes -= model.get('pause');
            }
            if (minutes < 0) {
                minutes += 1440;
            }
            sameAs = 0;
            value.forEach((row) => {
                var split;
                if (typeof row.get('hours') === 'string') {
                    split = row.get('hours').split(':');
                    return (sameAs += parseInt(split[0], 10) * 60 + parseInt(split[1], 10));
                } else if (typeof row.get('hours') === 'number') {
                    return (sameAs += row.get('hours') * 60);
                }
            });
            formatMinutes = (minutes) => {
                var formatted, leftover;
                if (!minutes) {
                    return 0;
                }
                formatted = Math.floor(minutes / 60);
                leftover = Math.round(minutes - formatted * 60);
                formatted += ':' + (leftover < 10 ? '0' : '') + leftover;
                return formatted;
            };
            minutes = Math.round(minutes);
            sameAs = Math.round(sameAs);
            if (!minutes || sameAs === minutes) {
                return resolve();
            } else {
                return resolve({
                    hours: formatMinutes(minutes),
                    sameas: formatMinutes(sameAs),
                });
            }
        });
    },
    validate_email: function (model, value, options) {
        var d;
        d = new $.Deferred();
        if (
            value &&
            !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
                value,
            )
        ) {
            d.resolve(true);
        } else {
            d.resolve();
        }
        return d;
    },
    //    if validator && validator.birthdate && moment(@get('value')).isAfter(moment().subtract(10, 'years'))
    //        @set('error', @get('intl').t("field.error.birthdate"))
    validate_birthdate: function (model, value, options) {
        if (moment(value).isAfter(moment().subtract(10, 'years'))) {
            return this.returnAsPromise(true);
        } else {
            return this.returnAsPromise();
        }
    },
    validate_remote_email: function (model, value, options) {
        var d, data, url;
        d = new $.Deferred();
        if (typeof model.changedAttributes === 'function') {
            if (Ember.isEmpty(model.changedAttributes().username)) {
                d.resolve();
                return d;
            }
        }
        url = config.APP.COLLECTOR_API_URL + '/createService';
        data = {
            validate: true,
        };
        data['email'] = value;
        $.ajax({
            url: url,
            data: JSON.stringify(data),
            type: 'POST',
            contentType: 'application/json',
        }).then(
            function () {
                return d.resolve();
            },
            function (error) {
                return d.resolve(true);
            },
        );
        return d;
    },
    validate_match: function (model, value, options) {
        var d;
        d = new $.Deferred();
        if (model.get(options.match) !== value) {
            d.resolve(true);
        } else {
            d.resolve();
        }
        return d;
    },
    validate_digits: function (model, value, options) {
        var d, error, i, item, len, ref;
        d = new $.Deferred();
        error = false;
        if (value && Array.isArray(JSON.parse(value))) {
            ref = JSON.parse(value);
            for (i = 0, len = ref.length; i < len; i++) {
                item = ref[i];
                if (Number(item) !== parseInt(item, 10)) {
                    error = true;
                }
            }
        } else if (value && Number(value) !== parseInt(value, 10)) {
            error = true;
        }
        if (error) {
            d.resolve(true);
        } else {
            d.resolve();
        }
        return d;
    },
    validate_person_country: function (model, value, options) {
        if (value === null || value === '' || value === void 0 || value === 'null') {
            return this.returnAsPromise(true);
        } else {
            return this.returnAsPromise();
        }
    },
    validate_employer_country: function (model, value, options) {
        if (value === null || value === '' || value === void 0 || value === 'null') {
            return this.returnAsPromise(true);
        } else {
            return this.returnAsPromise();
        }
    },
    validate_PIN: function (model, value, options) {
        var nationality;
        nationality = model.get('homecountry');
        // If PIN is empty then we say its valid
        if (!value) {
            return this.returnAsPromise();
        }
        // If we don't have the nationality we can't validate PIN
        if (nationality) {
            nationality = nationality.toUpperCase();
        } else {
            return this.returnAsPromise();
        }
        if (nationality === 'SE') {
            if (value.length !== 13 && value.length !== 11) {
                return this.returnAsPromise({
                    country: 'SE',
                });
            }
            if (
                !/^(([\d]{2})|([1-2][0|9][\d]{2}))(0?[\d]|1[012])(0[\d]|[12]\d|3[01])-[\d]{3}[A-Z\d]$/i.test(
                    value,
                )
            ) {
                return this.returnAsPromise({
                    country: 'SE',
                });
            }
        } else if (nationality === 'FI') {
            if (!/^((0[\d]|[12]\d|3[01])(0?[\d]|1[012])[\d]{2}-[\d]{3}[a-z\d])$/i.test(value)) {
                return this.returnAsPromise({
                    country: 'FI',
                });
            }
        }
        return this.returnAsPromise();
    },
    validate_id06_number: function (model, value, options) {
        // If value is empty then we say its valid
        if (!value) {
            return this.returnAsPromise();
        }
        if (!/^[a-z]{2}-[\d]{2}(0?[\d]|1[012])-[\d]{6}$/i.test(value)) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    validate_username: function (model, value, options) {
        // If value is empty then we say its valid
        if (!value) {
            return this.returnAsPromise();
        }
        if (!/^[a-z0-9_+@.-]+$/i.test(value)) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    check_username: function (model, value, resolve) {
        var promise;
        promise = this.validate_remote_email(model, value, null);
        return promise.then((back) => {
            return resolve(back);
        });
    },
    validate_username_free: function (model, value, options) {
        if (!value) {
            return this.returnAsPromise();
        }
        if (
            !model.get('isNew') &&
            !model.get('hasDirtyAttributes') &&
            value === model.get('username')
        ) {
            return this.returnAsPromise();
        }
        return new Em.RSVP.Promise((resolve, reject) => {
            //@resolve = resolve
            //@model = model
            //@value = value
            //console.log "tryin"
            return this.check_username(model, value, resolve);
        });
    },
    returnAsPromise: function (value) {
        var d;
        d = new $.Deferred();
        d.resolve(value);
        return d;
    },
    isJSON: function (value) {
        var e;
        if (!isNaN(value)) {
            return false;
        }
        try {
            JSON.parse(value);
        } catch (error1) {
            e = error1;
            return false;
        }
        return true;
    },
    validate_password: function (model, value, options) {
        var attribute, changed, hasChanged;
        if (value === 'secured') {
            return this.returnAsPromise();
        }
        if (model.isNew && value.length === 6) {
            return this.returnAsPromise();
        }
        changed = model.changedAttributes();
        hasChanged = false;
        for (attribute in changed) {
            if (attribute === 'password') {
                hasChanged = true;
                continue;
            }
        }
        if (hasChanged) {
            return this.validate_minLength(model, value, 8);
        } else {
            return this.returnAsPromise();
        }
    },
    validate_positiveNumber: function (model, value, options) {
        if (value < 0) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    validate_date_not_past: function (model, value, options) {
        if (!model.get('isNew')) {
            return this.returnAsPromise();
        }
        if (moment().isAfter(value, 'day')) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    validate_date_after: function (model, value, options) {
        if (model.get(options) > value) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    validate_phoneNumber: function (model, value, options) {
        if (
            !/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[^0]\d{1,14}$/i.test(
                value,
            )
        ) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    validate_skv_number: function (model, value, options) {
        if (!/^([a-z\d]{13})$/i.test(value)) {
            return this.returnAsPromise(true);
        }
        return this.returnAsPromise();
    },
    validate_PIN: function (model, value, options) {
        if (options === 'SE') {
            if (value.length !== 13 && value.length !== 11) {
                return this.returnAsPromise(true);
            }
            if (
                !/^(([\d]{2})|([1-2][0|9][\d]{2}))(0[\d]|1[012])(0[\d]|[1-9]\d)-[\d]{3}[A-Z\d]$/i.test(
                    value,
                )
            ) {
                return this.returnAsPromise(true);
            }
        } else if (options === 'FI') {
            if (!/^((0[\d]|[12]\d|3[01])(0?[\d]|1[012])[\d]{2}-[\d]{3}[a-z\d])$/i.test(value)) {
                return this.returnAsPromise(true);
            }
        }
        return this.returnAsPromise();
    },
    validate_project_number: function (model, value, options) {
        if (isFinite(value)) {
            if (parseInt(value, 10) === 0) {
                return this.returnAsPromise(true);
            }
        }
        return this.returnAsPromise();
    },
    validate_unique: async function (model, value, options, field, owner) {
        if (!value) return false;

        const collector = getOwner(owner).lookup('service:collectorService'); // get collector service
        const session = getOwner(owner).lookup('service:session'); // get session service (needed for auth)

        const form = model.constructor.modelName;
        const form_plural = pluralize(form).camelize();
        // query for rows with the same value as the model we are validating
        // use fetch instead of ember data because we don't want to update the store
        const req = await fetch(
            `${config.APP.COLLECTOR_API_URL}/${form_plural}?${field}=${value}`,
            {
                headers: {
                    Authorization: 'Bearer ' + session.data.authenticated.access_token,
                },
            },
        );
        const rows = await req.json();
        // if we have more than one row and the first row is not the same as the model we are validating then we have a duplicate value
        if (rows.count > 0 && Number(rows[form_plural][0].id) !== Number(model.id)) {
            const name = collector.getTranslationForColumn(form, field);
            return { field: name }; // retun error with field name so we can show it in error message
        }

        return false;
    },
    // validate that hours and minutes are not more than 2 digits in time input
    validate_hoursAndMinutes: function (model, value, options) {
        // only validate value if it is a string and contains a colon
        if (value && value.toString().includes(':')) {
            // split value to hours and minutes
            const split = value.split(':');
            const hours = split[0];
            const minutes = split[1];
            // if hours or minutes have more than 2 digits it is a validation error
            if (minutes.length > 2 || hours.length > 2) {
                return this.returnAsPromise(true);
            }
        }

        return this.returnAsPromise(false);
    },
});

export default Validator;
