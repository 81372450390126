/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/select-comp.coffee.old
// Generated by CoffeeScript 2.6.1
var SelectCompComponent;

import Ember from 'ember';

import collector from '../classes/collector';

import $ from 'jquery';

SelectCompComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    content: [],
    value: null,
    init: function () {
        this._super(...arguments);
        if (this.get('promptTranslation')) {
            this.set('prompt', this.get('intl').t(this.get('promptTranslation')));
        }
        if (!this.get('value') && !this.get('prompt')) {
            return this.set('value', this.get('content.firstObject.id'));
        }
    },
    options: Ember.computed(function () {
        var field;
        field = collector.getFormObject(this.get('form') + '.' + this.get('field'));
        if (field && field.options.fields != null) {
            return field.options.fields;
        }
        return ['name'];
    }),
    actions: {
        change: function () {
            var content, selectedEl, selectedIndex, selectedValue;
            selectedEl = $('select', this.element)[0];
            selectedIndex = selectedEl.selectedIndex;
            if (this.get('prompt')) {
                selectedIndex--;
            }
            if (typeof content === 'undefined' || content === null) {
                //            content = @get 'content.content.content'
                content = this.get('content');
            }
            selectedValue = content.objectAt(selectedIndex);
            if (!selectedValue) {
                return this.set('value', null);
            } else if (this.get('relation')) {
                if (selectedValue.record) {
                    return this.set('value', selectedValue.record);
                } else {
                    return this.set('value', selectedValue);
                }
            } else {
                return this.set('value', selectedValue.get('id'));
            }
        },
    },
});

export default SelectCompComponent;
