/* eslint-disable ember/named-functions-in-promises */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import statusUtil from 'tt4/utils/status';

export default Component.extend({
    ttapi: service(),
    error: service(),
    store: service(),
    capi: service(),
    notifications: service(),
    intl: service(),
    Collector: service('collector-service'),
    evented: service(),
    localStorageWrapper: service(),
    surveys: service(),

    date: null,
    user: null,
    localStorageKey: 'salary-category',

    noSalarytypes: computed('salarytypes', async function () {
        let stypes = await this.salarytypes;
        return stypes.length < 1;
    }),

    approveBtnDisabled: computed('weeks.@each.isChecked', function () {
        return get(this, 'weeks').filterBy('isChecked', true).length < 1;
    }),

    filterValues: computed('date', 'user', function () {
        return { user: get(this, 'user'), date: get(this, 'date') };
    }),

    weeks: computed('date', function () {
        const splitted = get(this, 'date').split('_');

        let start = moment(splitted[0]).startOf('isoWeek');
        const end = moment(splitted[1]).endOf('isoWeek');

        const weeks = [];

        while (moment(start).isBefore(end)) {
            weeks.push({
                isOpen: false,
                week: moment(start).format('YYYY-MM-DD'),
                isChecked: false,
            });

            start = start.add(1, 'week');
        }

        return weeks.reverse();
    }),

    hiddenSalarytypes: computed('defaultSalarytypes.[]', async function () {
        if (!this.defaultSalarytypes) return [];
        let types = this.defaultSalarytypes.map((item) => item.name);
        let stypes = await this.getSalaryTypes();
        return stypes.filter((stype) => !types.includes(stype.name));
    }),

    init() {
        this._super();
        //set the first user for the user-selector if this the first load
        this.useSalarytypeV2 = this.Collector.testNeeds(['products.salarytype_v2']);
        if (!get(this, 'user')) {
            let params = {
                form: 'worktime',
                field: 'user',
                order: 'lastname,firstname',
                limit: 1,
            };

            get(this, 'store')
                .query('user', params)
                .then((user) => {
                    set(this, 'user', get(user, 'firstObject.id'));
                });
        }
        set(this, 'salarytypes', this.getSalarytypes());
        this.evented.on('storeEvent', this, 'onStoreEvent');
    },

    didReceiveAttrs() {
        this.getSalaryData();
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
    },

    actions: {
        expandAll(value) {
            get(this, 'weeks').forEach((week) => {
                set(week, 'isOpen', value);
            });
        },
        checkAll(value) {
            get(this, 'weeks').forEach((week) => {
                set(week, 'isChecked', value);
            });
        },

        filterChange(value, field) {
            if (field) set(this, field, typeof value === 'object' ? get(value, 'id') : value);
        },

        changeStatus(status) {
            set(this, 'statusUtil', statusUtil.create());
            let acceptedRows = [];
            let rejectedRows = [];
            let promises = [];

            get(this, 'weeks')
                .filterBy('isChecked', true)
                .forEach((week) => {
                    let date = week.week;
                    promises.push(
                        get(this, 'store')
                            .query('worktime', {
                                date: date + '_' + moment(date).add(7, 'days').format('YYYY-MM-DD'),
                                user: get(this, 'user'),
                            })
                            .then((data) => {
                                const field = get(this, 'Collector').field(
                                    get(data, 'modelName'),
                                    'status',
                                );
                                let res = get(this, 'statusUtil').canChangeStatus(
                                    data,
                                    status,
                                    field,
                                );
                                res.accepted.forEach((row) => {
                                    acceptedRows.pushObject(row.id);
                                });
                                res.rejected.forEach((row) => {
                                    rejectedRows.pushObject(row.id);
                                });
                            }),
                    );
                });
            Promise.all(promises).then(() => {
                if (acceptedRows.length > 0) {
                    let data = {
                        worktime: {
                            id: acceptedRows,
                            status: status,
                            meta: { insert_history: 'true' },
                        },
                    };
                    get(this, 'capi')
                        .request('/worktimes', {
                            dataType: 'json',
                            type: 'PUT',
                            data: JSON.stringify(data),
                        })
                        .then(
                            get(this, 'notifications').success(
                                get(this, 'intl').t('salary_category.approve_success', {
                                    amount: acceptedRows.length,
                                }),
                                {
                                    autoClear: true,
                                },
                            ),
                        );
                }
                if (rejectedRows.length > 0) {
                    get(this, 'notifications').error(
                        get(this, 'intl').t('salary_category.approve_error', {
                            amount: rejectedRows.length,
                        }),
                        {
                            autoClear: true,
                        },
                    );
                }

                if (status === 'approved') {
                    this.surveys.initializeAndRun('row_approved'); // show wootric CES-survey after row approved
                }
            });
        },

        userChanged(userid) {
            set(this, 'user', userid);
        },

        hideSalarytype(type) {
            this.defaultSalarytypes.removeObject(type);
            this.setLSsalarytypes(this.defaultSalarytypes);
        },
        showSalarytype(type) {
            this.defaultSalarytypes.pushObject(type);
            this.setLSsalarytypes(this.defaultSalarytypes);
        },
        salarytypeDropped(target, dropped) {
            parseInt(dropped);
            if (target == dropped) return;
            let obj = this.defaultSalarytypes;
            this.moveElementInArray(obj, obj[dropped], target - dropped);
        },
        moveSalarytype(type, dir) {
            this.moveElementInArray(this.defaultSalarytypes, type, dir);
        },
    },

    moveElementInArray(obj, col, offset) {
        let index = obj.indexOf(col);
        obj.removeObject(col);
        let newIndex = index + offset;
        newIndex = newIndex > obj.length ? 0 : newIndex < 0 ? obj.length : newIndex;
        obj.insertAt(newIndex, col);
        this.setLSsalarytypes(this.defaultSalarytypes);
    },

    getSalarytypesFromLS() {
        let key = this.localStorageKey + '_defaultSalarytypes';
        let typesFromLS = this.localStorageWrapper.getUserSetting(key);
        if (typesFromLS && typesFromLS.length > 0 && key) return typesFromLS;
        return false;
    },

    setLSsalarytypes(newTypes) {
        let key = this.localStorageKey + '_defaultSalarytypes';
        let types = newTypes.map((type) => type.name).filter((item) => item);
        if (key) this.localStorageWrapper.setUserSetting(key, types);
    },

    onStoreEvent(params) {
        if (params.type === 'worktime' || params.type === 'abcense') {
            this.getSalaryData();
        }
    },

    notifyError(reason) {
        get(this, 'error').notify(reason);
    },

    async getSalarytypes() {
        return get(this, 'store').query(this.useSalarytypeV2 ? 'salarytypev2' : 'salarytype', {
            sideload: true,
            limit: 'all',
        });
    },

    async getFormFields() {
        return get(this, 'Collector').fieldArray('worktime');
    },

    getFieldType(type, salary, fields) {
        let typeName = type.split(/\((.*)\)/);
        let stype = salary.find((item) => item.name == typeName[0].trim());
        if (!stype || stype.control == 'work_hours;salarytype=$this') return 'work_hours';
        let fieldExt = this.useSalarytypeV2 ? stype.type : stype.option;
        if (!stype.option && stype.control) {
            let control = stype.control.split(/;|=/);
            fieldExt = control[1];
        }

        // compensations and other dropdowns have : have in them (ie meal:full) but the field external type is only
        // the first part.. ie meal:full -> meal
        if (fieldExt.includes(':')) fieldExt = fieldExt.split(':')[0];

        let field = fields.find((item) => item.name == fieldExt);
        if (!field) return 'work_hours';
        return field.name;
    },

    async setDefaultSalarytypes() {
        let stypes = await this.getSalaryTypes();
        if (this.getSalarytypesFromLS()) {
            stypes = this.getSalarytypesFromLS()
                .map((item) => stypes.filter((stype) => stype.name.trim() == item.trim())[0])
                .filter(Boolean);
        }
        set(this, 'defaultSalarytypes', stypes);
    },

    async getSalaryData() {
        set(this, 'loading', true);
        if (!get(this, 'user') || !get(this, 'date')) return;
        let data = await this.getData();
        await set(this, 'salaryData', data);
        await this.setDefaultSalarytypes();
        set(this, 'loading', false);
    },

    async getData() {
        let data = {
            date: this.date,
            user: this.user,
            allowEmptySalaryCodes: true,
            useSalarytypeV2: this.useSalarytypeV2,
        };

        return this.ttapi
            .request('api/salary-categories', {
                method: 'GET',
                data: data,
            })
            .catch(this.notifyError.bind(this));
    },

    async getSalaryTypes() {
        if (!get(this, 'salaryData')) return [];
        let types = [];
        get(this, 'salaryData').forEach((row) => {
            types = types.concat(
                Object.keys(row).reject((item) => ['date', 'hours'].includes(item)),
            );
        });
        // only unique ones sorted
        types = types.filter((v, i, a) => a.indexOf(v) === i).sort();

        let stypes = await this.salarytypes;
        let formFields = await this.getFormFields();
        let selectorTypes = await this.getSalaryTypesForSelector();
        let reportTypes = types.reduce((typesList, row) => {
            let fieldType = this.getFieldType(row, stypes, formFields);
            if (['pause', 'saldo', 'comp'].includes(row)) {
                let translatedName = this.intl.t('salarycategory.' + row);
                typesList.push({ name: row, fieldType, translatedName });
            } else {
                typesList.push({ name: row, fieldType });
            }
            let obj = selectorTypes.findBy('name', row.trim());
            if (obj) selectorTypes.removeObject(obj);
            return typesList;
        }, []);

        return [...reportTypes, ...selectorTypes];
    },

    async getSalaryTypesForSelector() {
        let stypes = await this.salarytypes;
        let selectorTypes = stypes.reduce((newList, type) => {
            newList.push({ name: type.name });
            return newList;
        }, []);
        if (this.Collector.testNeedsOne('products.saldo'))
            selectorTypes.push({
                name: 'saldo',
                translatedName: this.intl.t('salarycategory.saldo'),
            });
        if (this.Collector.testNeedsOne('products.pause'))
            selectorTypes.push({
                name: 'pause',
                translatedName: this.intl.t('salarycategory.pause'),
            });
        if (
            this.Collector.testNeeds([
                'products.timetracker_perdiem||products.timetracker_perdiem_meal',
            ])
        )
            selectorTypes.push({
                name: 'comp',
                translatedName: this.intl.t('salarycategory.comp'),
            });
        return selectorTypes;
    },
});
