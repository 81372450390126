import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import pdfExport from 'tt4/mixins/pdf-export';

export default Component.extend(pdfExport, {
    intl: service(),
    cordova: service('own-cordova-service'),
    error: service(),

    actions: {
        async exportPdf() {
            set(this, 'showLoader', true);
            try {
                await this.createPdf('download');
            } catch (error) {
                this.error.notify(error);
            } finally {
                set(this, 'showLoader', false);
            }
        },
    },

    async createPdf(mode = 'download') {
        let showColumns = this.headers;
        let element = await this.createPdfTableElement(
            showColumns,
            this.data,
            this.translated_headers,
        );
        let elements = [element];
        let file = await this.createPdfFromElements(elements, mode, '', 8);

        if (window.cordova) {
            this.cordova.openFile(file, 'application/pdf');
        }
        return file;
    },
});
