import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default Helper.extend({
    intl: service(),
    Collector: service('collector-service'),

    // used for saldo-insights field translations, because saldo-fields have custom translations
    // also if no translation was found from saldo_insights, we determine it is custom field and use its own name as a translation
    compute(params) {
        let form = params[0];
        let field = params[1];
        if (this.intl.exists('saldo_insights.fields.' + field)) {
            return this.intl.t('saldo_insights.fields.' + field);
        } else {
            let formfield = this.Collector.formField(form, field);
            if (formfield && formfield.name) return formfield.name;
            return field ? 'saldo_insights.fields.' + field : '-';
        }
    },
});
