/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/general-v2/components/saldo-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompSaldoSettingComponent;

import Ember from 'ember';

import collector from '../../../../../classes/collector';

import config from '../../../../../config/environment';

import { isArray } from '@ember/array';

CollectorFieldSettingCompSaldoSettingComponent = Ember.Component.extend({
    Collector: Em.inject.service('collector-service'),
    session: Em.inject.service(),
    store: Em.inject.service(),
    intl: Em.inject.service(),
    dialogs: Ember.inject.service(),
    //startdate: Em.computed ->
    //    moment().format('YYYY-MM-DD')
    didReceiveAttrs: function () {
        this.set('loading', true);
        return this.get('Collector')
            .ttapi({
                url: 'saldo/saldoSettings',
            })
            .then((back) => {
                var skip_worktimegroups;
                // these are the default-values if no saldo-settings are found
                if (back.length === 0) {
                    back = {};
                    back.startdate = moment().format('YYYY-MM-DD');
                    back.minimum = 20 * 60;
                    back.maximum = 40 * 60;
                    back.dailymaximum = 4 * 60;
                    this.send('saveSetting');
                }
                skip_worktimegroups = back.skip_worktimegroups;
                // sooooo ugly :(
                this.set('startdate', back.startdate);
                this.set('minimum_saldo', back.minimum / 60);
                this.set('maximum_saldo', back.maximum / 60);
                this.set('dailymaximum_saldo', back.dailymaximum / 60);
                this.set('old_startdate', back.startdate);
                this.set('old_minimum_saldo', back.minimum / 60);
                this.set('old_maximum_saldo', back.maximum / 60);
                this.set('old_dailymaximum_saldo', back.dailymaximum / 60);
                this.set('saldo_absence_id', back.saldo_absence_id);
                this.set('autocut_saldo', back.auto_cut);
                this.set('old_autocut_saldo', this.get('autocut_saldo'));
                this.set('skip_worktimegroups', skip_worktimegroups);
                this.set('old_skip_worktimegroups', skip_worktimegroups);
                this.set('split_basichours', back.split_basichours);
                this.set('old_split_basichours', this.get('split_basichours'));
                return this.set('loading', false);
            });
    },
    isFiService: Ember.computed('session.currentUser', function () {
        return false;
        if (this.get('session.currentUser.company_info.country') === 'fi') {
            return true;
        }
    }),
    dirty: Ember.computed(
        'startdate',
        'old_startdate',
        'minimum_saldo',
        'old_minimum_saldo',
        'maximum_saldo',
        'old_maximum_saldo',
        'dailymaximum_saldo',
        'old_dailymaximum_saldo',
        'autocut_saldo',
        'old_autocut_saldo',
        'skip_worktimegroups',
        'old_skip_worktimegroups',
        'old_split_basichours',
        'split_basichours',
        function () {
            return (
                this.get('startdate') !== this.get('old_startdate') ||
                this.get('minimum_saldo') !== this.get('old_minimum_saldo') ||
                this.get('maximum_saldo') !== this.get('old_maximum_saldo') ||
                this.get('dailymaximum_saldo') !== this.get('old_dailymaximum_saldo') ||
                this.get('autocut_saldo') !== this.get('old_autocut_saldo') ||
                this.get('skip_worktimegroups') !== this.get('old_skip_worktimegroups') ||
                this.get('split_basichours') !== this.get('old_split_basichours')
            );
        },
    ),
    worktimegroupField: Ember.computed('skip_worktimegroups', function () {
        var field;
        field = this.get('Collector').field('user', 'worktimegroup');
        field.type = 'multiselect';
        return field;
    }),
    showAddSaldoAbsenceButton: Ember.computed('saldo_absence_id', function () {
        if (!this.get('Collector').testNeeds(['abcense'])) {
            return false;
        }
        if (!this.get('startdate')) {
            return false;
        }
        if (this.get('Collector').testNeeds(['products.salarytype_v2'])) {
            return false;
        }
        if (!this.get('saldo_absence_id')) {
            return true;
        }
        if (!this.get('store').peekRecord('abcensetype', this.get('saldo_absence_id'))) {
            return true;
        }
    }),
    createSaldoAbsence: function () {
        return new Ember.RSVP.Promise((resolve) => {
            var record;
            if (!this.get('Collector').testNeeds(['abcense'])) {
                resolve(null);
            }
            if (this.get('Collector').testNeeds(['products.salarytype_v2'])) {
                return resolve(null);
            }
            if (!this.get('saldo_absence_id')) {
                // create new absence type
                record = this.get('store').createRecord('abcensetype', {
                    name: this.get('intl').t('saldo.saldo_absence'),
                });
                return record.save().then(function (saved) {
                    return resolve(saved.get('id'));
                });
            } else if (!this.get('store').peekRecord('abcensetype', this.get('saldo_absence_id'))) {
                return this.get('store')
                    .query('abcensetype', {
                        id: this.get('saldo_absence_id'),
                    })
                    .then((rows) => {
                        if (Ember.isEmpty(rows)) {
                            // create new absence type
                            record = this.get('store').createRecord('abcensetype', {
                                name: this.get('intl').t('saldo.saldo_absence'),
                            });
                            return record.save().then(function (saved) {
                                return resolve(saved.get('id'));
                            });
                        } else {
                            return resolve(this.get('saldo_absence_id'));
                        }
                    });
            } else {
                return resolve(this.get('saldo_absence_id'));
            }
        });
    },
    recalculateOldSaldos: function (between) {
        var startdate;
        startdate = between.split('_')[0];
        return this.get('dialogs')
            .confirm(
                this.get('intl').t('saldo.calculateHistorySaldo', {
                    startdate: moment(startdate).format('L'),
                }),
            )
            .then((con) => {
                if (con) {
                    return this.get('Collector').ttapi({
                        method: 'get',
                        url: 'saldo/calculateSaldos?date=' + between + '&userid=all',
                    });
                }
            });
    },
    actions: {
        moreInfo: function () {
            return window.open(config.APP.CDN_URL + '/documents/Saldot+ohje.pdf', '_blank');
        },
        changeWorktimegroups: function (value) {
            return this.set('skip_worktimegroups', value);
        },
        saveSetting: function () {
            return this.createSaldoAbsence().then((saldo_absence_id) => {
                var data, old_startdate, recalculationNeeded;
                data = {
                    startdate: this.get('startdate'),
                    minimum: parseInt(this.get('minimum_saldo'), 10) * 60,
                    maximum: parseInt(this.get('maximum_saldo'), 10) * 60,
                    dailymaximum: parseFloat(this.get('dailymaximum_saldo'), 10) * 60,
                    saldo_absence_id: saldo_absence_id,
                };
                if (this.get('skip_worktimegroups')) {
                    data.skip_worktimegroups = isArray(this.get('skip_worktimegroups'))
                        ? this.get('skip_worktimegroups').join(',')
                        : this.get('skip_worktimegroups');
                } else {
                    data.skip_worktimegroups = '';
                }
                if (this.get('autocut_saldo')) {
                    data.auto_cut = true;
                } else {
                    data.auto_cut = '';
                }
                if (this.get('split_basichours')) {
                    data.split_basichours = true;
                    this.get('Collector').addProduct('saldo_splitted');
                } else {
                    data.split_basichours = '';
                    this.get('Collector').removeProduct('saldo_splitted');
                }
                return this.get('Collector')
                    .ttapi(
                        {
                            method: 'post',
                            url: 'saldo/saldoSettings',
                            data: data,
                        },
                        (old_startdate =
                            this.get('old_startdate') || moment().format('YYYY-MM-DD')),
                        this.get('startdate') < old_startdate
                            ? (recalculationNeeded =
                                  this.get('startdate') +
                                  '_' +
                                  moment(old_startdate).subtract(1, 'day').format('YYYY-MM-DD'))
                            : (recalculationNeeded = false),
                    )
                    .then((back) => {
                        this.set('old_startdate', this.get('startdate'));
                        this.set('old_minimum_saldo', this.get('minimum_saldo'));
                        this.set('old_maximum_saldo', this.get('maximum_saldo'));
                        this.set('old_dailymaximum_saldo', this.get('dailymaximum_saldo'));
                        this.set('old_autocut_saldo', this.get('autocut_saldo'));
                        this.set('old_skip_worktimegroups', this.get('skip_worktimegroups'));
                        this.set('old_split_basichours', this.get('split_basichours'));
                        if (this.get('saldo_absence_id') === saldo_absence_id) {
                            this.notifyPropertyChange('saldo_absence_id');
                        } else {
                            this.set('saldo_absence_id', saldo_absence_id);
                        }
                        if (this.get('saveCalled')) {
                            this.saveCalled();
                        }
                        if (recalculationNeeded) {
                            return this.recalculateOldSaldos(recalculationNeeded);
                        }
                    });
            });
        },
        changeSaldotype: function (type) {
            return this.set('saldotype', type);
        },
    },
});

export default CollectorFieldSettingCompSaldoSettingComponent;
