/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/sum-report/component.coffee.old
// Generated by CoffeeScript 2.6.1
var SumReportComponent;

import Ember from 'ember';

import { pluralize } from 'ember-inflector';

SumReportComponent = Ember.Component.extend({
    checkProps: function () {
        Em.assert('model prop of type object is required', this.get('model'));
        Em.assert('columns prop is required', this.get('columns'));
        Em.assert('mainForm prop is required', this.get('mainForm'));
        Em.assert('idField prop is required', this.get('idField'));
        Em.assert('summableFields prop is required', this.get('summableFields'));
        return Object.keys(this.get('summableFields')).forEach((form) => {
            return Em.assert(
                'Form ' + pluralize(form) + 'not found in model',
                this.get('model.' + pluralize(form)),
            );
        });
    },
    didReceiveAttrs: function () {
        this.checkProps();
        return this.notifyPropertyChange('recalculateSums');
    },
    summedRows: Ember.computed('recalculateSums', function () {
        var field, form, ref, rows;
        if (this.get('hasRequiredFilters') === false) {
            return [];
        }
        rows = Em.A([]);
        ref = this.get('summableFields');
        for (form in ref) {
            field = ref[form];
            this.get('model.' + pluralize(form)).forEach((item) => {
                var row, rowObj;
                row = rows.find((_row) => {
                    return _row.get(this.get('idField')) === item.get(this.get('idField'));
                });
                if (row) {
                    return (row[field] += item.get(field));
                } else if (form === this.get('mainForm')) {
                    rowObj = {};
                    this.get('columns').forEach((column) => {
                        return (rowObj[column.field] =
                            column.form === form ? item.get(column.field) : 0);
                    });
                    return rows.pushObject(Em.Object.create(rowObj));
                }
            });
        }
        return rows;
    }),
    summedRowsTotals: Ember.computed('recalculateSums', function () {
        var field, form, index, ref, ref1, row, totals;
        if (!this.get('summedRows')) {
            return {};
        }
        totals = {};
        ref = this.get('summedRows');
        for (index in ref) {
            row = ref[index];
            ref1 = this.get('summableFields');
            for (form in ref1) {
                field = ref1[form];
                if (!totals[field]) {
                    totals[field] = 0;
                }
                totals[field] += row[field] ? row[field] : 0;
            }
        }
        return totals;
    }),
    actions: {
        sortByColumn: function (columnField) {
            if (this.get('sortByColumn') === columnField) {
                this.set('sortByColumn', columnField + '-');
                return this.set('summedRows', this.get('summedRows').sortBy(columnField).reverse());
            } else {
                this.set('sortByColumn', columnField);
                return this.set('summedRows', this.get('summedRows').sortBy(columnField));
            }
        },
    },
});

export default SumReportComponent;
