import Component from '@ember/component';
import { computed, set, get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default Component.extend({
    store: service(),
    session: service(),
    intl: service(),
    notifications: service(),
    error: service(),
    evented: service(),
    analytics: service(),
    classNameBindings: ['isDashed:dashed'],
    confirmPage: false,
    pausePage: false,
    commentPage: false,
    selectNew: false,
    loading: false,
    unit: 'min',

    isDashed: computed('project', function () {
        return !this.project;
    }),

    projectField: computed(function () {
        let field = this.Collector.field('worktime', 'project');
        if (field) {
            field.prompt = 'my-sites.add_site';
            field.skipForcedGPS = true;
        }
        return field;
    }),

    worktimeOn: computed('openWorktime', 'project', function () {
        if (!this.openWorktime || !this.project) return false;

        let openWorktimeProject = get(this.openWorktime, 'project.id');
        let siteCardProject = get(this.project, 'project.id');

        if (openWorktimeProject === siteCardProject) {
            return true;
        } else {
            return false;
        }
    }),

    startDateAndTime: computed('openWorktime', function () {
        return this.openWorktime.date + ' ' + this.openWorktime.starttime;
    }),

    actions: {
        async startWork(mysite) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Worktime startted',
                label: 'my-sites widget',
            });

            let proj = await get(mysite, 'project');
            try {
                set(this, 'loading', true);
                let record = this.createWorktime(proj);
                await record.save();
                set(this, 'worktimeStartMoment', moment());
                this.notifications.success(this.intl.t('timetracker.timecard.started'), {
                    autoClear: true,
                });
                set(this, 'openWorktime', record);
            } catch (error) {
                if (
                    error.errors &&
                    error.errors.firstObject &&
                    error.errors.firstObject.error === 'overlap' &&
                    this.openWorktime
                ) {
                    //Ugly try catch to handle absences on project change...should be made better in EP-6663
                    try {
                        if (error.errors.firstObject.errorMeta.hits === 'worktime') {
                            this.setEndtime();
                            await this.openWorktime.save();
                            this.notifications.success(this.intl.t('general.saved'), {
                                autoClear: true,
                            });
                            get(this, 'evented').storeEvent(
                                'insert',
                                'worktime',
                                this.openWorktime,
                            );
                            set(this, 'openWorktime', null);

                            let record = this.createWorktime(proj);
                            await record.save();
                            set(this, 'worktimeStartMoment', moment());
                            this.notifications.success(
                                this.intl.t('timetracker.timecard.started'),
                                {
                                    autoClear: true,
                                },
                            );
                            set(this, 'openWorktime', record);
                            set(this, 'pausePage', false);
                            set(this, 'commentPage', false);
                        }
                    } catch (error) {
                        this.error.notify(error);
                    }
                } else {
                    this.error.notify(error);
                }
            } finally {
                set(this, 'loading', false);
            }
        },

        async endWork() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Worktime ended',
                label: 'my-sites widget',
            });

            try {
                set(this, 'loading', true);
                this.setEndtime();
                await this.openWorktime.save();
                this.notifications.success(this.intl.t('general.saved'), {
                    autoClear: true,
                });
                get(this, 'evented').storeEvent('insert', 'worktime', this.openWorktime);
                set(this, 'openWorktime', null);
            } catch (error) {
                this.error.notify(error);
            } finally {
                set(this, 'loading', false);
            }
        },

        showPausePage() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Show pause page',
                label: 'my-sites widget',
            });

            set(this, 'pausePage', true);
            set(this, 'pause', this.openWorktime.pause ? this.openWorktime.pause : null);
            set(this, 'commentPage', false);
        },

        addPauseToWorktime(value) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Pause added',
                label: 'my-sites widget',
            });

            set(this.openWorktime, 'pause', value);
            this.openWorktime.save();
            this.notifications.success(this.intl.t('general.saved'), {
                autoClear: true,
            });
            set(this, 'pausePage', false);
        },

        showCommentPage() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Show comment page',
                label: 'my-sites widget',
            });

            set(this, 'commentPage', true);
            set(
                this,
                'comment',
                this.openWorktime.description ? this.openWorktime.description : null,
            );
            set(this, 'pausePage', false);
        },

        addCommentToWorktime(value) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Comment added',
                label: 'my-sites widget',
            });

            set(this.openWorktime, 'description', value);
            this.openWorktime.save();
            this.notifications.success(this.intl.t('general.saved'), {
                autoClear: true,
            });
            set(this, 'commentPage', false);
        },
        async addMySite(project) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'My site Added',
                label: 'my-sites widget',
            });
            if (!project || (project && !project.data)) return; //stupid jquery event
            if (this.siteCardAlreadyAdded(project.id)) {
                this.notifications.info(this.intl.t('widget.my-sites.sitecard_already_added'), {
                    autoClear: true,
                });
                return;
            }
            await this.store.createRecord('my-site', { project }).save();
            set(this, 'selectNew', false);
            this.notifySiteCards();
        },

        async removeMySite(project) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'My site Removed',
                label: 'my-sites widget',
            });
            await project.destroyRecord();
            set(this, 'project', null);
            set(this, 'confirmPage', false);
            set(this, 'selectNew', false);
            this.setRemovableIndexNull(project);
        },
    },

    createWorktime(proj) {
        let record = this.store.createRecord('worktime');

        setProperties(record, {
            date: moment().format('YYYY-MM-DD'),
            user: this.session.currentUser.id,
            project: proj,
            starttime: moment().format('HH:mm'),
            validation: 'off',
        });

        return record;
    },

    setEndtime() {
        if (
            get(this.openWorktime, 'date') === moment().format('YYYY-MM-DD') &&
            get(this.openWorktime, 'starttime') > moment().format('HH:mm')
        ) {
            set(this.openWorktime, 'endtime', get(this.openWorktime, 'starttime'));
        } else {
            set(this.openWorktime, 'endtime', moment().format('HH:mm'));
        }
    },

    siteCardAlreadyAdded(project) {
        let siteCards = this.store.peekAll('my-site');
        let mapped = siteCards.map((site) => {
            if (site) {
                let cardData = get(site, 'project');
                return get(cardData, 'id');
            }
        });
        return mapped.includes(project);
    },
});
