import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class WorktimeGroupSettingsComponent extends Component {
    @service evented;
    @service store;
    @service analytics;
    @service capi;
    @service('collector-service') Collector;
    @tracked showRemovingModal;
    @tracked usersStillInGroup;
    @tracked showPassive;
    @tracked selectedWorktimegroup;

    @action
    toggleRemoved() {
        this.showPassive = !this.showPassive;
        this.evented.storeEvent('toggleRemoved', 'worktimegroup', null);
    }

    @action
    tryToRemoveRow(row) {
        let users = this.store.query('user', { worktimegroup: row.id, limit: 10 });
        this.selectedWorktimegroup = row;
        this.usersStillInGroup = users;
        this.showRemovingModal = true;
        this.analytics.trackEvent({ category: 'delete row', action: 'worktimegroup' });
    }

    @action
    closeModal() {
        this.showRemovingModal = false;
    }

    @action
    async removeWorktimegroup(row) {
        await this.removeWorktimegroupProducts(row.id);
        await row.destroyRecord();
        await row.unloadRecord();
        this.showRemovingModal = false;
        this.evented.storeEvent('update', null, null); // updates ALL form-reports on this page but lucky for us, there is no other reports on this page
    }

    @action
    restoreRow(row) {
        this.evented.storeEvent('restore', 'worktimegroup', row);
    }

    async removeWorktimegroupProducts(worktimegroup) {
        const worktimegroupProducts = await this.capi.request(
            'service/productsByWorktimegroup?only=true&worktimegroups=' + worktimegroup,
        );
        const products = worktimegroupProducts?.products;
        if (!products) return;

        if (isPresent(products[worktimegroup])) {
            products[worktimegroup].forEach((product) => {
                this.Collector.removeProduct(product, worktimegroup);
            });
        }
    }
}
