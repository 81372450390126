import { isEmpty } from '@ember/utils';
import { htmlSafe } from '@ember/string';
import { computed, get, set } from '@ember/object';
import { next, later, debounce } from '@ember/runloop';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
    intl: service(),
    classes: 'form-control',

    searchBarWidth: 0,

    placeholder: computed(function () {
        return get(this, 'intl').t('general.search');
    }),

    progressStyles: computed('searchBarWidth', function () {
        return htmlSafe(`width: ${get(this, 'searchBarWidth')}%`);
    }),

    init() {
        this._super(...arguments);
        if (get(this, 'tmpvalue')) {
            next(this, function () {
                set(this, 'value', get(this, 'tmpvalue'));
            });
        }
    },

    actions: {
        removeSearch: function () {
            set(this, 'value', '');
            this.doSearch();
        },
        triggerSearchAction: function () {
            if (isEmpty(get(this, 'value'))) {
                this.doSearch();
            } else {
                if (get(this, 'searchBarWidth') > 0) {
                    set(this, 'searchBarWidth', 0.1);
                } else {
                    this.incrementLoader();
                }
                debounce(this, this.doSearch, 1500);
            }
        },
    },

    incrementLoader() {
        if (get(this, 'isDestroyed')) return;
        let currentValue = get(this, 'searchBarWidth');
        set(this, 'searchBarWidth', currentValue + 3.5);
        if (currentValue < 100) {
            later(this, 'incrementLoader', 50);
        }
    },

    doSearch() {
        if (get(this, 'isDestroyed')) return;
        set(this, 'searchBarWidth', 0);
        set(this, 'tmpvalue', get(this, 'value'));
        this.change(get(this, 'value'), 'q');
    },
});
