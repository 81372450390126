/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/utils/custom-reports.coffee.old
// Generated by CoffeeScript 2.6.1
var reports;

reports = {
    invoices: {
        host: 'ADMINTOOLURL',
        path: 'invoice_export',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
    comissions: {
        host: 'ADMINTOOLURL',
        path: 'comissions_export',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
    activity: {
        host: 'ADMINTOOLURL',
        path: 'BI/activity',
        filters: 'customer.country,customer.status,customer.salesman',
        form: 'customer',
        paginate: true,
    },
    billinginfomissing: {
        host: 'ADMINTOOLURL',
        path: 'BI/missing_billing_info',
        filters: 'customer.createddate,customer.country,customer.status,customer.salesman',
        form: 'customer',
        paginate: true,
    },
    tos: {
        path: '/customers/tosList',
        form: 'customer',
        paginate: false,
    },
    invoicesat2Netvisor: {
        host: 'ADMINTOOLURL',
        path: 'invoice_export?type=vismaNetvisor&billingversion=at2',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
    invoicesat2: {
        host: 'ADMINTOOLURL',
        path: 'invoice_export?type=visma&billingversion=at2',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
    invoicesat1: {
        host: 'ADMINTOOLURL',
        path: 'invoice_export?type=visma&billingversion=at1',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
    invoicesat2legacy: {
        host: 'ADMINTOOLURL',
        path: 'invoice_export?type=visma&billingversion=at2&legacy=true',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
    invoicesat1legacy: {
        host: 'ADMINTOOLURL',
        path: 'invoice_export?type=visma&billingversion=at1&legacy=true',
        filters: 'bill.date,customer.country,customer.status',
        form: 'bill',
        paginate: false,
    },
};

export default reports;
