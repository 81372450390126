/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/week-report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerWeekReportController;

import Ember from 'ember';

import filterModel from '../../../mixins/filter-model';

TimetrackerWeekReportController = Ember.Controller.extend(filterModel, {
    intl: Em.inject.service(),
    queryParams: ['date'],
    startDate: moment().startOf('isoWeek'),
    week: Em.computed('date', function () {
        try {
            return moment(this.get('date').substr(0, 10)).isoWeek();
        } catch (error) {
            return moment(this.get('startDate')).isoWeek();
        }
    }),
    actions: {
        changeWeek: function (value) {
            var date, queryParam;
            date = this.get('date')
                ? moment(this.get('date').substr(0, 10))
                : this.get('startDate');
            if (value === 'plus') {
                this.set('startDate', date.add(1, 'week').startOf('isoWeek'));
            } else {
                this.set('startDate', date.subtract(1, 'week').startOf('isoWeek'));
            }
            queryParam =
                date.format('YYYY-MM-DD') + '_' + date.endOf('isoWeek').format('YYYY-MM-DD');
            return this.set('date', queryParam);
        },
        rowClicked: function (row) {
            if (row.abcensetype) {
                return this.transitionToRoute('abcenses.edit', row.id);
            } else {
                return this.transitionToRoute('timetracker.edit', row.id);
            }
        },
    },
});

export default TimetrackerWeekReportController;
