import Controller from '@ember/controller';
import { filterBy, alias } from '@ember/object/computed';
import { set, get } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
    formsController: controller('settings.forms'),
    dialogs: service(),
    intl: service(),
    removedField: alias('model'),
    adminMode: alias('formsController.adminMode'),
    fields: filterBy('model', 'removed', true),
    productFields: filterBy('fields', 'editable', true),
    customerFields: filterBy('fields', 'editable', false),
    actions: {
        restoreField(field, markNotEditable = false) {
            get(this, 'dialogs')
                .confirm(get(this, 'intl').t('form.editor.removed.confirm'))
                .then((con) => this._restore(con, field, markNotEditable));
        },
    },

    _restore(con, field, markNotEditable) {
        if (con) {
            if (markNotEditable) {
                set(field, 'editable', 0);
            }
            set(field, 'removed', 0);
        }
    },
});
