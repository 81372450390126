/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/custom-report/route.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsFormCustomReportRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import report from '../../../utils/custom-reports';

import config from '../../../config/environment';

FormsFormCustomReportRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    model: function (params) {
        var setting;
        setting = report[params.form];
        return {
            host: setting.host,
            url: setting.path,
            form: setting.form,
            filters: setting.filters,
            report: params.form,
            nopaginate: !setting.paginate,
        };
    },
});

export default FormsFormCustomReportRoute;
