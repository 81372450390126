import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"draggable-item\" role=\"button\" data-anchor={{@anchorName}}\n{{on \"dragstart\" this.dragHasStarted}}\n{{on \"drop\" this.drop}}\n{{on \"dragover\" this.dragOver}}\n{{on \"dragleave\" this.dragLeave}}\n{{on \"dragenter\" this.dragEnter}}\ndraggable={{@draggable}}>\n    <div class={{this.dragClass}}></div>\n    {{yield}}\n</div>", {"contents":"<div class=\"draggable-item\" role=\"button\" data-anchor={{@anchorName}}\n{{on \"dragstart\" this.dragHasStarted}}\n{{on \"drop\" this.drop}}\n{{on \"dragover\" this.dragOver}}\n{{on \"dragleave\" this.dragLeave}}\n{{on \"dragenter\" this.dragEnter}}\ndraggable={{@draggable}}>\n    <div class={{this.dragClass}}></div>\n    {{yield}}\n</div>","moduleName":"tt4/components/addon/report-template-wizard/draggable-item.hbs","parseOptions":{"srcName":"tt4/components/addon/report-template-wizard/draggable-item.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
/* istanbul ignore next */
export default class ReportTemplateWizardDraggableItemComponent extends Component {
    @tracked draggable;
    @tracked dragClass;

    constructor() {
        super(...arguments);
        this.draggableName = this.args.draggablename;
        this.dragClass = 'disabled';
    }

    @action
    dragHasStarted(event) {
        event.dataTransfer.setData('text/data', this.draggableName);
    }

    @action
    drop(event) {
        this.dragClass = 'inactive';
        const data = event.dataTransfer.getData('text/data');
        if (!data || data == this.draggableName) return;
        this.args.dropped(this.draggableName, data, event);
    }

    @action
    dragLeave(event) {
        event.preventDefault();
        this.dragClass = 'inactive';
    }

    @action
    dragOver(event) {
        event.preventDefault();
        this.dragClass = 'active';
    }

    @action
    dragEnter(event) {
        event.preventDefault();
    }
}
