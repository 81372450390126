import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';

// eslint-disable-next-line ember/alias-model-in-controller
export default Controller.extend({
    gps: service('gps'),

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    translations: { lat: 'Lat', lon: 'Lon', acc: 'Accuracy' },

    errorState: computed('gps.status', function () {
        if (get(this, 'gps.status') === 'error') {
            return true;
        }
    }),

    searching: computed('gps.status', function () {
        if (get(this, 'gps.status') === 'searching') {
            return true;
        }
    }),

    accuracy: computed('gps.currentAccuracy', function () {
        const accuracy = get(this, 'gps.currentAccuracy');
        if (accuracy > 1000) {
            return Math.round(accuracy / 10) / 100 + ' km';
        } else {
            return Math.round(accuracy) + ' m';
        }
    }),

    latlng: computed('gps.{currentLatitude,currentLongitude,currentAccuracy}', function () {
        return {
            lat: get(this, 'gps.currentLatitude'),
            lng: get(this, 'gps.currentLongitude'),
            accuracy: get(this, 'gps.currentAccuracy'),
        };
    }),

    actions: {
        retry() {
            //@get('gpsService').start()
            return window.location.reload();
        },
    },
});
