import { helper } from '@ember/component/helper';

// This function receives the params `params, hash`
export function hasPermission(params) {
    const userlevel = params[0];
    const check_levels = params[1];
    if (!check_levels || check_levels.indexOf(parseInt(userlevel)) > -1) {
        return true;
    } else {
        return false;
    }
}

export default helper(hasPermission);
