import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import podNames from 'ember-component-css/pod-names';

export default class TvStatusComponent extends Component {
    @service ttapi;
    @service evented;
    @service intl;
    @service store;
    @service router;
    @service localstorage;
    @service analytics;
    @service('collector-service') Collector;

    @tracked pieData;
    @tracked project;

    get styleNamespace() {
        return podNames['dash-board/widgets/tv-status'];
    }

    get projectField() {
        let field = {};
        if (this.Collector.fieldExists('worktime', 'project')) {
            field = this.Collector.field('worktime', 'project');
        } else {
            field = this.Collector.field('presence', 'project');
        }
        if (field) {
            field.prompt = this.intl.t('widget.tv-status.select_project');
            field.value = this.project;
        }
        return field;
    }

    async getPieData() {
        const pieData = await this.ttapi.request('api/project/users', {
            data: {
                project: this.project,
                sideload: ['employer.name', 'employer.tv_status'],
                limit: 'all',
            },
        });
        let tv_status_pie = A([
            [this.intl.t('submenu.project.tv_status'), this.intl.t('submenu.project.tv_status')],
        ]);

        if (!pieData.employers) return [];

        let sums = [];
        // Remove duplicates from employers array using id
        pieData.employers = pieData.employers.filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id),
        );
        pieData.employers.forEach((item) => {
            if (!item.tv_status) item.tv_status = 'missing';
            sums[item.tv_status] = (sums[item.tv_status] || 0) + 1;
        });
        set(this, 'statuses', Object.keys(sums));

        Object.entries(sums).forEach((val) => {
            // vastuu group status might have nordic characters so we need to replace them with english ones
            val[0] = val[0].replace(/ä/g, 'a').replace(/ö/g, 'o');
            tv_status_pie.push([this.intl.t('employer.tv_status.' + val[0]), val[1]]);
        });

        return tv_status_pie;
    }

    constructor() {
        super(...arguments);
        this.project = this.localstorage.getItem('widget-tv-status-filter')
            ? this.localstorage.getItem('widget-tv-status-filter')
            : null;
        this.project = this.args.project ? this.args.project : this.project;
        this.pieData = this.getPieData();
        this.pieOptions = {
            height: 220,
            width: 400,
            pieSliceTextStyle: {
                color: 'black',
            },
        };
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
        this.evented.on('dashboardProjectChanged', this, 'dashboardProjectChanged');
    }

    @action
    filterChange(value, field) {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Change project',
            label: 'Tv-status widget',
        });
        if (field) {
            if (!value) set(this, field, null);
            else set(this, field, value.id);
        }
        this.localstorage.setItem('widget-tv-status-filter', this.project);
        this.pieData = this.getPieData();
    }

    @action
    onSelect(value) {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Pie clicked',
            label: 'Tv-status widget',
        });

        this.router.transitionTo('projects.edit.tv-status', this.project, {
            queryParams: {
                status: this.statuses[value],
            },
        });
    }

    willDestroy() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
        this.evented.off('dashboardProjectChanged', this, 'dashboardProjectChanged');
    }

    onStoreEvent(params) {
        if (params.type === 'worktime') this.pieData = this.getPieData();
    }

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        this.pieData = this.getPieData();
    }

    dashboardProjectChanged() {
        this.project = this.args.project ? this.args.project : null;
        this.pieData = this.getPieData();
    }
}
