import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isMobile}}\n    <Input @type=\"time\" @value={{@value}} @disabled={{@disabled}} {{on 'change' (fn @onChange @value)}} />\n{{else}}\n    <Input\n        @class=\"max-width-5em\"\n        @type=\"text\"\n        @autocomplete=\"off\"\n        @value={{@value}}\n        @disabled={{@disabled}}\n        @focus-in={{this.select}}\n        @focus-out={{fn this.timefy @value}}\n        @enter={{fn this.timefy @value}}\n    />\n{{/if}}", {"contents":"{{#if this.isMobile}}\n    <Input @type=\"time\" @value={{@value}} @disabled={{@disabled}} {{on 'change' (fn @onChange @value)}} />\n{{else}}\n    <Input\n        @class=\"max-width-5em\"\n        @type=\"text\"\n        @autocomplete=\"off\"\n        @value={{@value}}\n        @disabled={{@disabled}}\n        @focus-in={{this.select}}\n        @focus-out={{fn this.timefy @value}}\n        @enter={{fn this.timefy @value}}\n    />\n{{/if}}","moduleName":"tt4/components/addon/fields/time.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/time.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isMobile } from 'tt4/ember-movenium/utils/gui';

/**
    Time field
    @class FieldsTimeComponent
*/

interface ITime {
    onChange(args0: string): void;
}

export default class FieldsTimeComponent extends Component<ITime> {
    @action
    protected select() {
        return;
    }

    @action
    protected timefy(value: string): void {
        this.args.onChange(this.tryToGuessTime(value));
    }

    get isMobile(): boolean {
        return isMobile();
    }

    /**
     * This function receives a string, which it will try to parse into a time of format HH:mm
     * For example if this function receives "12" it will return "12:00"
     * If it receives "1230" it will return "12:30" and so on..
     * @method tryToGuessTime
     * @param {String} str - The string from which this function will try to guess the time
     * @return {String}
     *
     */
    private tryToGuessTime(str: string): string {
        let hours;
        let mins;

        if (str.includes(':')) {
            const exploded = str.split(':');
            mins = parseInt(exploded[1], 10);
            hours = parseInt(exploded[0], 10);
        } else if (str.includes('.')) {
            const exploded = str.split('.');
            mins = parseInt(exploded[1], 10);
            hours = parseInt(exploded[0], 10);
        } else if (str.length >= 3) {
            mins = parseInt(str.substring(str.length - 2, str.length), 10);
            hours = parseInt(str.substring(0, str.length - 2), 10);
        } else if (str === '') {
            return '';
        } else {
            hours = parseInt(str, 10);
            mins = 0;
        }

        if (isNaN(hours)) hours = 0;
        if (isNaN(mins)) mins = 0;

        if (hours < 0) hours *= -1;
        if (hours > 23) hours = 23;
        if (mins < 0) mins = 0;
        if (mins > 60) mins = 59;

        if (hours < 10) hours = '0' + hours;
        if (mins < 10) mins = '0' + mins;

        return '' + hours + ':' + mins;
    }
}
