import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';

export default Component.extend({
    store: service(),
    intl: service(),
    gui: service(),
    form: 'user',

    userlevelInfo: computed(function () {
        if (this.intl.primaryLocale !== 'fi') return null;

        const linkToCommunityMovenium =
            'https://ohjeet.visma.fi/articles/#!visma-movenium/k-ytt-j-roolit';
        const linkToCommunityEntry = 'https://ohjeet.visma.fi/articles/#!visma-entry/k-ytt-j-rooli';
        const linkToCommunityOtta = get(this, 'intl').t('click.help_link.to.community_otta');

        let linkToCommunity;
        const brand = get(this, 'gui').getBrand();

        // eslint-disable-next-line no-irregular-whitespace
        // TODO: Remove in EP-11985

        if (brand === 'visma') {
            linkToCommunity = linkToCommunityEntry;
        } else if (brand === 'otta') {
            linkToCommunity = linkToCommunityOtta;
        } else {
            linkToCommunity = linkToCommunityMovenium;
        }


        return htmlSafe(
            this.intl.t('user.userlevel.info', {
                link:
                    "<a href='" +
                    linkToCommunity +
                    "'>" +
                    this.intl.t('user.userlevel.info.link') +
                    '<span class="move move-external-link"></span></a>',
            }),
        );
    }),

    init() {
        this._super();
        set(this, 'changeFieldTypeToDate', ['ea1card', 'ea2card', 'firecard', 'safetycard']);
    },

    didReceiveAttrs() {
        if (!this.record) set(this, 'userBuilder', true);
        if (
            this.Collector.fieldExists('user', 'taxnumber') &&
            !this.record.taxnumber &&
            this.record.isNew
        ) {
            set(this, 'userBuilder', true);
        } else {
            set(this, 'userBuilder', false);
        }
    },

    actions: {
        addUser(userInfo) {
            set(this, 'defaults', this.addDefaults(userInfo));
            if (userInfo && get(userInfo, 'id')) set(this, 'record', userInfo);
            this.addUser();
            set(this, 'userBuilder', false);
        },

        async fieldChanged(value, field) {
            if (!field) return;
            if (field === 'firstname' || field === 'lastname' || field === 'employer') {
                if (!this.record.isNew) return;
                set(this, 'record.username', await this.generateUsername(this.record));
            }
        },
    },
    async getEmployerSuffix(employer) {
        if (get(employer, 'domain')) return get(employer, 'domain');

        const employerData = await this.store.findRecord('employer', get(employer, 'id'), {
            reload: true,
        });
        if (get(employerData, 'domain')) return get(employerData, 'domain');
        return get(employer, 'name');
    },

    async addDefaults(record) {
        return {
            userlevel: '1',
            emp_relation: '1',
            password: this.generatePassword(),
            username: await this.generateUsername(record),
            firstname: record.firstname,
            lastname: record.lastname,
            taxnumber: record.taxnumber,
            employer: record.employer,
            birthdate: record.birthdate,
        };
    },

    generatePassword() {
        const chars = '23456789abcdefghkmnpqrstuvwxyz';
        const string_length = 6;
        let generated_password = '';
        let i = 0;
        while (i < string_length) {
            let rnum = Math.floor(Math.random() * chars.length);
            generated_password += chars.substring(rnum, rnum + 1);
            i++;
        }
        return generated_password;
    },

    async generateUsername(record) {
        let generatedName = '';

        if (record.firstname && record.lastname && record.employer && get(record.employer, 'id')) {
            let employerSuffix = await this.getEmployerSuffix(record.employer);
            generatedName = record.firstname + '.' + record.lastname + '@' + employerSuffix;
        } else if (record.firstname && record.lastname) {
            generatedName = record.firstname + '.' + record.lastname;
        } else {
            generatedName = record.firstname;
        }

        if (generatedName) {
            generatedName = generatedName.replace(/\s+/g, '');
            generatedName = generatedName.replace(/[&]+/g, '');
            return generatedName.toLowerCase();
        } else {
            return undefined;
        }
    },
});
