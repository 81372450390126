/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/console-log.coffee.old
// Generated by CoffeeScript 2.6.1
var ConsoleLogHelper, consoleLog;

import Ember from 'ember';

// This function receives the params `params, hash`
consoleLog = function (params) {
    return true;
};

ConsoleLogHelper = Ember.Helper.helper(consoleLog);

export { consoleLog };

export default ConsoleLogHelper;
