/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/customer-settings.coffee.old
// Generated by CoffeeScript 2.6.1
var CustomerSettingsService;

import Ember from 'ember';

import config from '../config/environment';

CustomerSettingsService = Ember.Service.extend({
    ttapi: Ember.inject.service(),
    settings: Em.A([]),
    getSettings: function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            return this.get('ttapi')
                ._get('/settings')
                .then((data) => {
                    this.set('settings', data);
                    return resolve(data);
                })
                .catch((reason) => {
                    return reject(reason);
                });
        });
    },
    /*
  return new Em.RSVP.Promise( (resolve, reject) =>
      url = config.APP.TTAPI+'/settings'
      $.ajax(
          url: url
          type: 'GET'

          success: (data) =>
              @set "settings", data
              resolve(data)

          error: (reason) ->
              reject(reason)
      )
  )
  */
    checkSetting: function (name) {
        if (!this.get('settings')) {
            return false;
        }
        if (Em.isEmpty(this.get('settings'))) {
            return false;
        }
        if (!this.get('settings').findBy('key', name)) {
            return false;
        }
        if (this.get('settings').findBy('key', name).value === 'on') {
            return true;
        } else if (
            this.get('settings').findBy('key', name).value &&
            this.get('settings').findBy('key', name).value !== 'off'
        ) {
            return this.get('settings').findBy('key', name).value;
        } else {
            return false;
        }
    },
    setSetting: function (name, value, worktimegroup) {
        var obj, settingsArr, url;
        // do not change sessions settings if changing state of worktimegroup specific setting
        if (!worktimegroup) {
            if (this.get('settings') && !Em.isEmpty(this.get('settings'))) {
                if (this.get('settings').findBy('key', name)) {
                    //save new value and push to settings property
                    obj = this.get('settings').findBy('key', name);
                    obj.value = value;
                    this.get('settings').removeObject(this.get('settings').findBy('key', name));
                    this.get('settings').pushObject(obj);
                } else {
                    this.get('settings').pushObject({
                        key: name,
                        value: value,
                    });
                }
            } else {
                settingsArr = Em.A([]);
                settingsArr.pushObject({
                    key: name,
                    value: value,
                });
                this.set('settings', settingsArr);
            }
        }
        //save setting to the ttapi
        url = '/settings/save';
        return this.get('ttapi').request(url, {
            type: 'POST',
            dataType: 'text',
            data: {
                key: name,
                value: value,
                worktimegroup: worktimegroup,
            },
        });
    },
});

export default CustomerSettingsService;
