import Component from '@ember/component';
import { get } from '@ember/object';

export default Component.extend({
    actions: {
        addNew(user) {
            this.onAdd(moment(get(this, 'date')).format('YYYY-MM-DD'), user);
        },
    },
});
