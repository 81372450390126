import Service, { inject as service } from '@ember/service';

export default Service.extend({
    admintool: service(),
    flags: service(),

    async getMessages(data) {
        const messages = await this.admintool.ajax({
            url: 'promoMessages',
            method: 'get',
            data: data,
        });

        messages.data = messages.data.filter((message) => {
            return this.testFlags(message.flags);
        });
        messages.count = messages.data.length;

        return messages;
    },

    //check that user has all the same flags as the message
    testFlags(msgFlags) {
        if (!msgFlags) return true;
        return msgFlags.split(',').every((flag) => {
            flag = flag.replace(/\s/g, ''); // remove all whitespace
            const negation = flag.substring(0, 1) == '!';
            if (negation) flag = flag.substring(1);
            return negation ? !this.flags.test(flag) : this.flags.test(flag);
        });
    },
});
