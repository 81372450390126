/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/inline-edit-row/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import recordComp from '../../collector/record-comp/component';

Component = recordComp.extend({
    collector: Ember.inject.service('collector-service'),
    tagName: 'tr',
    classNameBindings: ['record.isNew:new-record', 'record.hasDirtyAttributes:edited-record'],
    canEdit: Ember.computed('record', function () {
        var collector, form;
        collector = this.get('collector');
        form = this.get('form');
        return (
            collector.testNeeds(['!' + form + '@errors', form + '@edit']) &&
            (this.get('record.isNew') || this.get('record.isEditable'))
        );
    }),
    canAdd: Ember.computed('record', function () {
        var collector, form;
        collector = this.get('collector');
        form = this.get('form');
        return collector.testNeeds(['!' + form + '@errors', form + '@add']);
    }),
    hasError: Ember.computed('record.errors.messages.[]', 'record.isValid', function () {
        return this.get('record.errors.messages.length') > 0 && !this.get('record.isValid');
    }),
    recordSetter: null,
    mouseEnter: function () {
        return this.set('hoverActive', true);
    },
    mouseLeave: function () {
        return this.set('hoverActive', false);
    },
    sortedFields: Ember.computed('showFields', 'columns', function () {
        var c, cols, cols2, cols2_ext, cols_ext, form, length, ret, shown, stashed;
        c = Ember.get(this, 'collector');
        form = Ember.get(this, 'form');
        shown = this.get('showFields');
        stashed = this.get('stashed_fields');
        shown = shown.concat(stashed);
        if (!shown) {
            return;
        }
        cols = this.get('columns');
        cols_ext = cols.mapBy('ext');
        length = Ember.get(cols, 'length');
        cols2 = this.get('extraColumns');
        cols2_ext = cols2.mapBy('ext');
        ret = cols.concat(cols2).map(function (item) {
            if (['row_info.created'].includes(Ember.get(item, 'ext'))) {
                return;
            }
            return {
                name: c.field(form, item.ext),
                show: false,
            };
        });
        shown.forEach(function (field) {
            var index, index2;
            if (['textarea', 'textarea_varchar'].includes(Ember.get(field, 'type'))) {
                return;
            }
            index = cols_ext.indexOf(Ember.get(field, 'name'));
            index2 = cols2_ext.indexOf(Ember.get(field, 'name'));
            if (index > -1) {
                return (ret[index] = {
                    name: field,
                    show: true,
                });
            } else if (index2 > -1) {
                return (ret[length + index2] = {
                    name: field,
                    show: true,
                });
            }
        });
        return ret;
    }),
    actions: {
        copyRow: function () {
            return this.sendAction('copyRow', this.get('record'));
        },
    },
});

export default Component;
