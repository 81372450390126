import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
    tagName: 'tr',

    toDay: computed(function () {
        return moment().format('YYYY-MM-DD');
    }),
});
