import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Route.extend({
    gui: service(),
    AuthenticatedRouteMixin,

    beforeModel(transition) {
        if (transition.targetName === 'worktask.list' && !get(this, 'gui.isBelowMd')) {
            transition.abort();
            this.transitionTo('worktask.calendar');
        }
    },
});
