import { Promise as EmberPromise } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Service.extend({
    ajax: service(),
    capi: service(),

    sendFile(filename, contentType, content) {
        return new EmberPromise((resolve) => {
            get(this, 'capi')
                .request('/awsurl?filename=' + filename + '&contentType=' + contentType)
                .then((url) => this._ajaxRequest(url, contentType, content))
                .then(() => resolve());
        });
    },

    _ajaxRequest(url, contentType, content) {
        get(this, 'ajax').request(url, {
            type: 'put',
            // Content type must much with the parameter you signed your URL with
            contentType: contentType,
            // this flag is important, if not set, it will try to send data as a form
            processData: false,
            // the actual file is sent raw
            data: content,
            dataType: 'text',
            beforeSend: function (jqXHR) {
                jqXHR.overrideMimeType('text/csv;charset=iso-8859-1');
            },
        });
    },
});
