/* eslint-disable no-console */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

const DevelopmentIndexRoute = Route.extend({
    Collector: service('collector-service'),
    ttapi: service('ttapi'),
    session: service(),
    moment: service(),
    store: service(),
    sessionhelper: service(),

    actions: {
        getForms() {
            return this.consoleLog('forms', "Service's /forms", 'GET');
        },

        getMe() {
            return this.consoleLog('me', 'me', 'GET');
        },

        capiFlags() {
            return this.consoleLog('service/flags', 'Capi flags', 'GET');
        },

        getNeededSchema() {
            return this.consoleLog('update?show=needed', "Service's needed schema");
        },

        getCurrentSchema() {
            return this.consoleLog('update?show=current', "Service's current schema");
        },

        getCurrentUser() {
            const currentUser = this.session.currentUser;
            window.$E = currentUser;
            return console.info('currentUser ($E)', currentUser);
        },

        storeToConsole() {
            const store = this.store;
            window.$E = store;
            return console.info('store saved to $E');
        },

        sessionToConsole() {
            const session = this.moment;
            window.$E = session;
            return console.info('session saved to $E');
        },

        sessionhelperToConsole() {
            window.$E = this.sessionhelper;
            return console.info('sessionhelper saved to $E');
        },

        collectorFormsObject() {
            const forms = this.Collector.forms();
            window.$E = forms;
            return console.info('collectorFormsObject ($E)', forms);
        },

        async clearMemcache() {
            const c = this.Collector;
            const back = await c.ajax({ url: 'system/clear_memcache' });
            return console.log('cache cleared', back.message);
        },

        async clearPartnerMemcache() {
            const c = this.Collector;
            const back = await c.ajax({
                url: 'system/clear_memcache?partnerid=' + this.session.currentUser.partnerid,
            });
            return console.log('partner cache cleared', back.message);
        },

        async clearUserMemcache() {
            const c = this.Collector;
            const back = await c.ajax({
                url:
                    'system/clear_memcache?partnerid=' +
                    this.session.currentUser.partnerid +
                    '&username=' +
                    this.session.currentUser.username,
            });
            return console.log('user cache cleared', back.message);
        },

        async redisInfo() {
            if (this.controller.clearConsole) {
                console.clear();
            }

            const c = this.Collector;
            const back = await c.ajax({ url: 'system/redisInfo' });
            return console.log('redis', back.message);
        },

        async redisDumpUser() {
            if (this.controller.clearConsole) {
                console.clear();
            }

            const c = this.Collector;
            const back = await c.ajax({ url: 'system/redisInfo?dump_user' });
            return console.log('dump', back.message);
        },

        async redisDump() {
            if (this.controller.clearConsole) {
                console.clear();
            }

            const c = this.Collector;
            const back = await c.ajax({ url: 'system/redisInfo?dump' });

            return console.log('dump', back.message);
        },

        testAlarms() {
            this.ttapi._get('alarms/test');
            return console.info('testAlarms called');
        },

        async getFcmid() {
            if (this.controller.clearConsole) {
                console.clear();
            }
            const back = await this.ttapi._post('user_fcmid', null);
            return console.log(back.fcmid);
        },
    },
    consoleLog(url, text, method) {
        if (text == null) {
            text = '';
        }
        if (method == null) {
            method = 'POST';
        }
        if (this.controller.clearConsole) {
            console.clear();
        }

        const c = this.Collector;
        const promise = c.ajax({
            method,
            url,
            headers: {
                Authorization: 'Bearer ' + get(this.session, 'session.authenticated.access_token'),
            },
        });
        return promise.then((back) => console.info(text, back));
    },
});

export default DevelopmentIndexRoute;
