import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { get } from '@ember/object';

export default Route.extend({
    Collector: service('collector-service'),
    session: service(),

    queryParams: {
        searchTerm: {
            refreshModel: true,
        },
    },

    model(params) {
        // if subprojectsmulti is not we are just using form-report
        if (!get(this, 'Collector').testNeeds(['products.subprojectsmulti']))
            return { project: this.modelFor('projects.edit') };

        let subprojectparams = {};

        if (get(this, 'Collector').testNeeds(['products.subprojects'])) {
            subprojectparams.project = get(this.modelFor('projects.edit'), 'id');
        }

        if (params.searchTerm) {
            subprojectparams.q = params.searchTerm;
        }

        return hash({
            record: this.modelFor('projects.edit'),
            recordid: parseInt(get(this.modelFor('projects.edit'), 'id'), 10),
            subprojects: this.store.query('subproject', subprojectparams),
            allSubProjects: this.store.peekAll('subproject'),
        });
    },

    actions: {
        refreshModel() {
            this.refresh();
        },
    },
});
