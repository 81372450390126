/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/collector-field-name.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldNameHelper;

import Ember from 'ember';

CollectorFieldNameHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    compute: function (params, hash) {
        var c, field, form;
        if (hash.field === 'id') {
            return this.get('intl').t('general.row_info.created');
        }
        c = this.get('Collector');
        field = hash.field;
        form = hash.form;
        return c.getTranslationForColumn(form, field, hash.removed);
    },
});

export default CollectorFieldNameHelper;
