import Controller, { inject as controller } from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';
import { Promise as EmberPromise } from 'rsvp';
import { htmlSafe } from '@ember/template';
import { copy } from '@ember/object/internals';

const ImportCreateController = Controller.extend({
    mainController: controller('development.import'),
    Collector: service('collector-service'),

    dontValidate: false,

    content: alias('model'),
    file: alias('mainController.file'),
    mappers: alias('mainController.mappers'),
    form: alias('mainController.form'),
    hasHeaderRow: alias('mainController.hasHeaderRow'),

    fields: computed('form', function () {
        return this.Collector.fieldArray(this.form);
    }),
    records: computed('file', function () {
        const c = this.Collector;
        const store = this.store;
        const form = this.form;
        const records = A([]);
        const mappers = this.mappers;

        const pkMapper = mappers.filterBy('pk')?.firstObject;
        if (this.hasHeaderRow) {
            this.file.shiftObject();
        }
        console.log(this.get('file')); //eslint-disable-line
        if (!this.file) {
            return;
        }
        this.file.forEach((file) => {
            let q, record;
            const arr = {};
            if (pkMapper) {
                q = { sideload: true };
                q[pkMapper.to] = file[pkMapper.from];
                record = store.query(form, q);
            }
            const promises = [];
            mappers.forEach(async function (mapper) {
                console.log(mapper); // eslint-disable-line
                if (isEmpty(mapper.to) || isEmpty(mapper.from)) {
                    return;
                }
                const field = c.field(form, mapper.to);
                if (field.type === 'database') {
                    if (file[mapper.from]) {
                        const rq = { sideload: true, q: file[mapper.from], limit: 1 };
                        //
                        //                    split = file[mapper.from].split(' ')
                        //                    console.log field.options.fields
                        //                    console.log split
                        //                    for ofield,k in field.options.fields
                        //                        console.log ofield
                        //                        console.log k
                        //                        rq[ofield] = split[k]
                        promises.push(
                            store
                                .query(field.options.form, rq)
                                .then(this.returnPromise.bind(this, arr, mapper)),
                        );
                    } else {
                        return (arr[mapper.to] = null);
                    }
                } else if (
                    [
                        'dropdown_values',
                        'dropdown_userlevel',
                        'status',
                        'dropdown_options',
                    ].includes(field.type) &&
                    mapper.maps
                ) {
                    const value = file[mapper.from];
                    return mapper.maps.forEach(function (item) {
                        if (mapper.options.from[item.from] === value) {
                            return (arr[mapper.to] = item.to);
                        }
                    });
                } else {
                    return (arr[mapper.to] = file[mapper.from]);
                }
            });

            return EmberPromise.all(promises).then(
                this.returnRecords.bind(this, record, form, arr, records),
            );
        });
        return records;
    }),
    progress: computed('numericalProgress', function () {
        return this.numericalProgress + ' %';
    }),
    progressStyle: computed('progress', function () {
        return htmlSafe('width: ' + this.numericalProgress + '%');
    }),

    actions: {
        saveRecords() {
            set(this, 'executing', true);
            set(this, 'numericalProgress', 0);
            const records = this.records;
            return this.execute_one(copy(records), records.length);
        },

        validateRecords() {
            return this.records.invoke('validate');
        },
    },
    returnPromise(arr, mapper, data) {
        if (data.length > 0) {
            return (arr[mapper.to] = data.firstObject);
        }
    },

    async returnRecords(record, form, arr, records) {
        if (record) {
            const data = await record;
            let model;
            if (data.length > 0) {
                model = this.setRecordValues(data.firstObject, arr);
            } else {
                model = this.store.createRecord(form, arr);
            }
            if (!this.dontValidate && model.hasDirtyAttributes) {
                await model.validate();
                if (!model.errors.isEmpty) {
                    let errorstr = '';
                    model.errors.forEach(function (error) {
                        errorstr +=
                            '<b>' + this.Collector.field(form, error.attribute).translated_name;
                        return (errorstr += ':</b> ' + error.message + '<br>');
                    });
                    return set(model, 'errorString', errorstr);
                }
            }
            return records.pushObject(model);
        } else {
            const model = this.store.createRecord(form, arr);
            if (!this.dontValidate) {
                model.validate();
            }
            return records.pushObject(model);
        }
    },

    setRecordValues(record, arr) {
        for (let key in arr) {
            const item = arr[key];
            set(record, key, item);
        }
        return record;
    },

    execute_one(heap, count) {
        const record = heap.shiftObject();
        if (!record) {
            return;
        }
        if (this.dontValidate) {
            set(record, 'validation', 'off');
        }
        const promise = record.save();

        return promise.then(
            () => {
                return this.goNext(heap, count);
            },
            () => {
                return this.goNext(heap, count);
            },
        );
    },

    goNext(heap, count) {
        set(this, 'numericalProgress', Math.floor(((count - heap.length) / count) * 1000) / 10);

        if (heap.length === 0) {
            set(this, 'executing', false);
        }

        return this.execute_one(heap, count);
    },
});

export default ImportCreateController;
