/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/tr/data/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyTrDataRoute;

import Ember from 'ember';

import GeneralReportRoute from 'tt4/routes/general-report';

import { inject as service } from '@ember/service';

SiteSafetyTrDataRoute = GeneralReportRoute.extend({
    form: 'tr',
    Collector: Ember.inject.service('collector-service'),
    capi: service(),
    flags: service(),
    beforeModel: function (transition) {
        var c;
        this._super(transition);
        c = this.get('Collector');
        if (transition.targetName !== 'site_safety.tr.notices' && !c.testNeedsOne('tr@add')) {
            transition.abort();
            return this.transitionTo('site_safety.tr.notices');
        }
    },
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            params.sideload = true;
            if (Ember.isPresent(params.groupBy)) {
                params.sideload = true;
                // This can be moved to mixin when wanted in other places also
                params.order = params.groupBy;
                return this.get('capi').findAll('tr', params);
            } else {
                //        params.order = "date-"
                return this.store.query('tr', params);
            }
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('site-safety.report.tr.data').set('qParams', params);
        }
    },
});

export default SiteSafetyTrDataRoute;
