import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
    placeholderIcon: computed('icon', function () {
        if (get(this, 'icon')) {
            return get(this, 'icon');
        }
        return null;
    }),
});
