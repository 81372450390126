/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/form-wizard/component.coffee.old
// Generated by CoffeeScript 2.6.1

/*
    this is done totally wrong!!!!!! tries to be component for wizards but in reality only support own measurement!!
*/
var FormWizardComponent;

import Ember from 'ember';

FormWizardComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    collector: Ember.inject.service('collector-service'),
    customMeasurements: Em.A([]),
    didReceiveAttrs: function () {
        if (this.customMeasurements.length > 0) {
            return;
        }
        return this.get('store')
            .findAll('form')
            .then((forms) => {
                forms.forEach((form) => {
                    if (form.get('external_type').includes(this.get('form'))) {
                        return this.customMeasurements.pushObject(form);
                    }
                });
                if (this.customMeasurements.length === 0) {
                    return this.customMeasurements.pushObject(this.createBaseForm());
                }
            });
    },
    createBaseForm: function () {
        var base_fields, fields, form, number;
        // number = @customMeasurements.length + 1
        number = this.getMeasurementNumber();
        form = this.get('store').createRecord('form', {
            external_type: 'measurement_custom' + number,
        });
        fields = form.get('fields');
        base_fields = [
            {
                type: 'date',
                external_type: 'date',
                name: '$lang(10)',
            },
            {
                type: 'database',
                external_type: 'project',
                name: '$lang(10)',
                options:
                    this.getSchemaidFor('project', 'number') +
                    '\n' +
                    this.getSchemaidFor('project', 'name'),
            },
            {
                type: 'database',
                external_type: 'user',
                name: '$lang(10)',
                options:
                    this.getSchemaidFor('user', 'lastname') +
                    '\n' +
                    this.getSchemaidFor('user', 'firstname'),
            },
            {
                type: 'number',
                external_type: 'tr_rating',
                name: '$lang(10)',
            },
            {
                type: 'textarea',
                external_type: 'description',
                name: '$lang(10)',
            },
        ];
        Em.A(base_fields).forEach((fieldValues) => {
            return fields.pushObject(this.get('store').createRecord('form-field', fieldValues));
        });
        return form;
    },
    getMeasurementNumber: function () {
        var max, min, number;
        min = 1;
        max = 99;
        number = Math.floor(Math.random() * (max - min + 1)) + min;
        if (this.get('collector').formExists('measurement_custom' + number)) {
            number = this.getMeasurementNumber();
        }
        return number;
    },
    getSchemaidFor: function (form, field) {
        var field_record, form_record;
        form_record = this.get('store').peekAll('form').findBy('external_type', form);
        if (!form_record) {
            Ember.assert('getSchemaidFor failed because form (' + form + ') not found');
        }
        field_record = form_record.get('fields').findBy('external_type', field);
        if (!form_record) {
            Ember.assert(
                'getSchemaidFor failed because field (' + field + ') not found from form ' + form,
            );
        }
        return field_record.get('id');
    },
    actions: {
        saved: function () {
            if (this.get('saved')) {
                return this.saved();
            }
        },
        removedMeasurement: function () {
            if (this.get('removedMeasurement')) {
                return this.removedMeasurement();
            }
        },
        addMeasurement: function () {
            return this.get('customMeasurements').pushObject(this.createBaseForm());
        },
        extClash: function (model) {
            var number;
            number = this.getMeasurementNumber();
            return model.set('external_type', 'measurement_custom' + number);
        },
    },
});

export default FormWizardComponent;
