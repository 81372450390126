/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/supervisor-notes-report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SupervisorNotesIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

SupervisorNotesIndexController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    form: 'diaryreport_supervisor_note',
    showColumns: ['phase', 'date', 'status', 'description', 'image', 'responsible'],
    status: 'all',
    queryParams: ['date', 'page'],
    date: moment().subtract(1, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    filters: Em.A([
        Em.Object.create({
            field: 'date',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            hideActions: true,
        }),
    }),
});

export default SupervisorNotesIndexController;
