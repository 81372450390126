/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalIndexRoute;

import Ember from 'ember';

TimetrackerApprovalIndexRoute = Ember.Route.extend();

//    model: (params) ->
//        params.status = 'open'
//        params.sideload = true
//#        params.date = moment().startOf('week').format('YYYY-MM-DD')+'_'+moment().endOf('week').format('YYYY-MM-DD')
//        @store.query('worktime', params)
export default TimetrackerApprovalIndexRoute;
