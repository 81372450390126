import { getProperties, get, set } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    session: service(),
    intl: service(),
    Collector: service('collector-service'),
    notifications: service(),
    customerSettings: service(),
    gps: service(),
    didReceiveAttrs() {
        set(this, 'address_error', false);
        const worktimegroup = get(this, 'worktimegroup');
        let coordsString = null;

        if (isPresent(worktimegroup)) {
            coordsString = get(this, 'customerSettings').checkSetting(
                `autokm_address_${worktimegroup}`,
            );
        } else {
            coordsString = get(this, 'customerSettings').checkSetting(`autokm_address`);
        }
        if (coordsString) {
            set(this, 'active', true);
            const coords = JSON.parse(coordsString);

            if (coords.no_back_forth) {
                set(this, 'backForthActive', false);
            } else {
                set(this, 'backForthActive', true);
            }
            set(this, 'loading', true);

            get(this, 'gps')
                .GetLocationByLatLng({ lat: coords.latitude, lng: coords.longitude })
                .then((resp) => this._setAddress(resp));
        }
    },

    actions: {
        saveSetting() {
            set(this, 'loading', true);
            const full_address = getProperties(this, 'address', 'postal', 'city');
            const gps = get(this, 'gps');
            gps.GetFullObjectByAddress(Object.values(full_address).join(' ')).then(
                (resp) => this._checkAndSave(resp),
                (resp) => this._showError(resp),
            );
        },
        checkboxChanged() {
            this.toggleProperty('active');
            const checked = get(this, 'active');
            if (!checked) {
                let settingstr = 'autokm_address';
                if (isPresent(get(this, 'worktimegroup'))) {
                    settingstr += `_${get(this, 'worktimegroup')}`;
                }
                get(this, 'customerSettings').setSetting(settingstr, 'off');
                get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                    autoClear: true,
                });
            }
        },
        checkboxChangedBackForth() {
            this.toggleProperty('backForthActive');
            const checked = get(this, 'backForthActive');
            var setting = get(this, 'customerSettings').checkSetting('autokm_address');
            var settingObj = JSON.parse(setting);
            let settingstr = 'autokm_address';
            if (isPresent(get(this, 'worktimegroup'))) {
                settingstr += `_${get(this, 'worktimegroup')}`;
            }
            let promise = null;
            if (!checked) {
                settingObj.no_back_forth = true;
                promise = get(this, 'customerSettings').setSetting(
                    settingstr,
                    JSON.stringify(settingObj),
                );
            } else {
                delete settingObj.no_back_forth;
                promise = get(this, 'customerSettings').setSetting(
                    settingstr,
                    JSON.stringify(settingObj),
                );
            }
            promise.then(() => {
                get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
                    autoClear: true,
                });
            });
        },
    },

    _setAddress(resp) {
        const split1 = resp.split(', ');
        const split2 = split1[1].split(' ');
        set(this, 'address', split1[0]);
        set(this, 'postal', split2[0]);
        set(this, 'city', split2[1]);
        set(this, 'loading', false);
    },

    _checkAndSave(resp) {
        let address = resp.formatted_address.split(', ');
        if (address.length == 2) {
            this._showError();
        } else {
            set(this, 'address_error', false);
            this._saveSettings({
                latitude: resp.geometry.location.lat(),
                longitude: resp.geometry.location.lng(),
            });
        }
    },
    _saveSettings(resp) {
        let settingstr = 'autokm_address';
        if (isPresent(get(this, 'worktimegroup'))) {
            settingstr += `_${get(this, 'worktimegroup')}`;
        }
        if (!get(this, 'backForthActive')) {
            resp.no_back_forth = true;
        }
        get(this, 'customerSettings')
            .setSetting(settingstr, JSON.stringify(resp))
            .then(() => this._success());
    },

    _success() {
        set(this, 'loading', false);
        this.settingSaved();
    },
    _showError() {
        set(this, 'loading', false);
        set(this, 'address_error', true);
        get(this, 'notifications').error(get(this, 'intl').t('settings.autokm.address_error'), {
            autoClear: true,
        });
    },
});
