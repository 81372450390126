/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryEditRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

WorksitediaryEditRoute = GeneralAddRoute.extend({
    modelName: 'worksitediary',
    controllerName: 'worksitediary/edit',
    edit: true,
    afterSave: 'worksitediary',
    hideFromSaved: 'weather',
    actions: {
        save: function (row) {
            var json_weather;
            row = this.get('controller.model');
            json_weather = row.get('weather');
            row.set('weather', JSON.stringify(json_weather));
            if (this.get('Collector').formExists('diary')) {
                this.set('afterSave', 'diary.events');
            }
            return this._super();
        },
    },
});

export default WorksitediaryEditRoute;
