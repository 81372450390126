/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-report-add/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarReportAddComponent;

import Ember from 'ember';

CalendarReportAddComponent = Ember.Component.extend({
    classNames: 'calendar-report-add',
});

export default CalendarReportAddComponent;
