import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { set } from '@ember/object';
import fetch from 'fetch';
import { htmlSafe } from '@ember/string';
import { tracked } from '@glimmer/tracking';

export default class ExportInfoWizardComponent extends Component {
    @tracked currentPage = 1;
    @tracked isLoading = true;
    @tracked pageCount = 0;

    texts = { notfound: 'no pages found for ' };

    constructor() {
        super(...arguments);
        this.load();
    }

    get currentPageIndex() {
        return this.currentPage - 1;
    }

    get styleNamespace() {
        return podNames['wizard-builder/help-wizard'];
    }

    async load() {
        const pages = [];

        for (let i = 1; i <= 15; i++) {
            const key = `${this.args.name}-${i}-${moment.locale()}`;
            const fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;

            const response = await fetch(fileUrl);
            const html = await response.text();
            if (response.status === 403) break;
            pages.push(htmlSafe(html));
        }

        set(this, 'pages', pages);
        set(this, 'pageCount', pages.length);
        this.isLoading = false;
    }
}
