import Helper from '@ember/component/helper';
import moment from 'moment-timezone';
import { later } from '@ember/runloop';

export default Helper.extend({
    compute(params /*, hash*/) {
        const now = moment();
        const then = moment(params[0], 'YYYY-MM-DD HH:mm:ss');
        later(() => this.recompute(), 500);
        return now.isBefore(then)
            ? '-' + moment.utc(then.diff(now)).format('HH:mm:ss')
            : moment.utc(now.diff(then)).format('HH:mm:ss');
    },
});
