/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/field-setting-comp/push-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorSettingCompPushSettingComponent;

import Ember from 'ember';

import collector from '../../../../classes/collector';

import moment from 'moment';

CollectorSettingCompPushSettingComponent = Ember.Component.extend({
    store: Em.inject.service(),
    session: Em.inject.service(),
    moment: Em.inject.service(),
    intl: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    notifications: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    flags: Ember.inject.service(),
    setting_default: '16:00',
    use_missing_hours: Em.computed(function () {
        if (
            this.get('customerSettings').checkSetting(
                'push_notification_time_hours_' +
                    this.get('session.currentUser.company_info.country'),
            ) &&
            !this.get('flags').test('notification-settings-v2')
        ) {
            return true;
        } else {
            return false;
        }
    }),
    time: Em.computed(function () {
        var date, utctime;
        utctime = this.get('customerSettings').checkSetting(this.get('configs.name'));
        date = moment().format('YYYY-MM-DD');
        return (
            this.get('moment')
                .moment(date + ' ' + utctime + ':00Z')
                .format('HH:mm') || this.setting_default
        );
    }),
    /*
  is_dirty: Em.computed 'setting', ->
      if @get('setting')  != @get('setting_default')
          return true
      else
          return false
  */
    actions: {
        saveSetting: function () {
            var promises, utctime;
            promises = [];
            utctime = this.get('moment')
                .moment(moment().format('YYYY-MM-DD') + ' ' + this.get('time') + ':00')
                .utc()
                .format('HH:mm');
            if (this.get('use_missing_hours')) {
                promises.push(
                    this.get('customerSettings').setSetting(
                        'push_notification_time_hours_' +
                            this.get('session.currentUser.company_info.country'),
                        utctime,
                    ),
                );
            } else if (
                this.get('customerSettings').checkSetting(
                    'push_notification_time_hours_' +
                        this.get('session.currentUser.company_info.country'),
                )
            ) {
                promises.push(
                    this.get('customerSettings').setSetting(
                        'push_notification_time_hours_' +
                            this.get('session.currentUser.company_info.country'),
                        'off',
                    ),
                );
            }
            promises.push(
                this.get('customerSettings').setSetting(this.get('configs.name'), utctime),
            );
            return Ember.RSVP.Promise.all(promises).then(() => {
                return this.get('notifications').success(this.get('intl').t('general.saved'), {
                    autoClear: true,
                });
            });
        },
    },
});

export default CollectorSettingCompPushSettingComponent;
