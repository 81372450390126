import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
    classNames: ['settings-table-div'],
    classNameBindings: ['showScroll:div-overflow'],

    showScroll: computed('rows', function () {
        let rows = get(this, 'rows');
        return rows.length >= 20;
    }),
});
