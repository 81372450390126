/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/basic-info/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryAddBasicInfoController;

import Ember from 'ember';

WorksiteDiaryAddBasicInfoController = Ember.Controller.extend({
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    addController: Ember.inject.controller('worksitediary.report.add'),
    store: Ember.inject.service(),
    page: 1,
    changeThings: function () {
        var c, i, item, len, link_from_last, model, project;
        model = this.get('model');
        project = model.get('project');
        c = this.get('Collector');
        link_from_last = [
            'review_and_inspection',
            'completion_rate',
            'orders_ordered',
            'events_started',
            'events_interrupted',
            'events_work_in_progress',
        ];
        if (project == null) {
            this.set('model.worksite_number', null);
            this.set('model.work_week', null);
            this.set('model.status', 'open');
            for (i = 0, len = link_from_last.length; i < len; i++) {
                item = link_from_last[i];
                if (c.fieldExists('diaryreport', item)) {
                    model.set(item, []);
                }
            }
            return;
        }
        return this.get('store')
            .findRecord('project', project.get('id'), {
                reload: true,
            })
            .then(() => {
                if (this.get('model.isNew')) {
                    this.get('store')
                        .query('diaryreport', {
                            order: 'diary_date-',
                            limit: 1,
                            project: project.get('id'),
                        })
                        .then((ret) => {
                            var j, last, last_day, len1, values;
                            last = ret.get('firstObject');
                            if (last) {
                                for (j = 0, len1 = link_from_last.length; j < len1; j++) {
                                    item = link_from_last[j];
                                    if (!c.fieldExists('diaryreport', item)) {
                                        continue;
                                    }
                                    values = last.get(item);
                                    if (values) {
                                        model.set(item, values);
                                    } else {
                                        model.set(item, []);
                                    }
                                }
                                if (
                                    c.fieldExists('diaryreport', 'work_day') &&
                                    last.get('work_day')
                                ) {
                                    last_day = parseInt(last.get('work_day'), 10);
                                    model.set('work_day', ++last_day);
                                }
                            }
                            // If we want this done also when editing we can move it to diary-events route
                            return this.store
                                .query('worksitediary', {
                                    sideload: true,
                                    project: project.get('id'),
                                    date: model.get('diary_date'),
                                })
                                .then(function (events) {
                                    model
                                        .get('events_started')
                                        .pushObjects(events.filterBy('state', 'initiated'));
                                    if (c.testNeedsOne('diaryreport.events_work_in_progress')) {
                                        model
                                            .get('events_work_in_progress')
                                            .pushObjects(events.filterBy('state', 'started'));
                                    }
                                    model
                                        .get('events_finished')
                                        .pushObjects(events.filterBy('state', 'finished'));
                                    model
                                        .get('events_interrupted')
                                        .pushObjects(events.filterBy('state', 'interrupted'));
                                    return model
                                        .get('events_altered')
                                        .pushObjects(events.filterBy('state', 'altered'));
                                });
                        });
                }
                if (project.get('presence_project') && c.testNeeds(['diaryreport.isID06'])) {
                    model.set('isID06', true);
                }
                if (project.get('number')) {
                    this.set('model.worksite_number', project.get('number'));
                }
                this.set('model.work_week', project.get('work_week'));
                if (!this.get('model.work_week')) {
                    this.set('model.work_week', 1);
                }
                if (project.get('hasDirtyAttributes')) {
                    return project.save();
                }
            });
    },
    actions: {
        updateField: function (value, field) {
            var project;
            project = this.get('model.project');
            if (field === 'project') {
                this.changeThings();
            }
            if (project) {
                if (field === 'work_week') {
                    project.set('work_week', value);
                }
                if (field === 'diary_date') {
                    project.set('diary_date', value);
                }
                if (project.get('hasDirtyAttributes')) {
                    return project.save();
                }
            }
        },
    },
});

export default WorksiteDiaryAddBasicInfoController;
