import Controller from '@ember/controller';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';

const DevelopmentIndexController = Controller.extend({
    content: alias('model'),
    products: computed('model', function () {
        return this.model.forms.updater.products;
    }),

    error: computed('model', function () {
        return this.model.forms.updater.error;
    }),

    actions: {
        async addproduct() {
            set(this, 'loading', true);
            const productname = this.addproductname;

            if (!productname || productname === 'Choose') {
                alert('Product must have some name');
                return;
            }

            set(this, 'addproductname', '');
            await this.Collector.addProduct(productname);

            return this.send('reloadModel');
        },

        async removeproduct(productname) {
            await this.Collector.removeProduct(productname);
            return this.send('reloadModel');
        },

        selectProduct(product) {
            return set(this, 'addproductname', product);
        },
    },
});
export default DevelopmentIndexController;
