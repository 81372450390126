import { typeOf } from '@ember/utils';
import GeneralReportController from '../../../controllers/general-report';
import EmberObject, { computed, set } from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';

const MachinesTransactionsController = GeneralReportController.extend({
    form: 'transaction',
    limit: 25,
    init() {
        this._super(...arguments);
        this.showColumns = ['project', 'tooltype', 'amount', 'price', 'starttime'];
        this.queryParams = ['starttime', 'project', 'tooltype', 'q'];
    },
    content: alias('model'),

    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            hideEdit: true,
            hideRemove: true,
        }),
        massedit: true,
        sortByColumn: 'starttime-',
    }),

    filters: computed(function () {
        return A([
            EmberObject.create(
                { field: 'starttime', type: 'date' },
                {
                    start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                    end: moment().endOf('week').format('YYYY-MM-DD'),
                },
            ),
            EmberObject.create({ field: 'project' }),
            EmberObject.create({ field: 'tooltype' }),
            EmberObject.create({ field: 'endtime', value: '(empty)' }),
        ]);
    }),

    queryLimitFull: computed('model', function () {
        if (this.model.meta.count === 1000) {
            return true;
        } else {
            return false;
        }
    }),

    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) === 'instance') {
                return set(this, field, value.id);
            } else {
                return set(this, field, value);
            }
        },
    },
    starttime:
        moment().subtract(1, 'month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
});

export default MachinesTransactionsController;
