/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/calendar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryCalendarController;

import Ember from 'ember';

WorksitediaryCalendarController = Ember.Controller.extend({
    queryParams: ['diary_date', 'project'],
    project: null,
    diary_date:
        moment().startOf('month').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('month').format('YYYY-MM-DD'),
    actions: {
        projectChanged: function (value) {
            return this.set('project', value.get('id'));
        },
    },
});

export default WorksitediaryCalendarController;
