/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/application.coffee.old
// Generated by CoffeeScript 2.6.1
var ApplicationController,
    indexOf = [].indexOf;

import SettingsAdapter from '../adapters/settings';

import collector from '../classes/collector';

import config from '../config/environment';

import { t } from 'ember-intl';

import menu from '../mixins/menu';

import configurations from '../config';

import { captureException } from '@sentry/browser';

import { getOwner } from '@ember/application';

import { getCommunityLink } from 'tt4/utils/communityLink-util';

import { action } from '@ember/object';

// login controller has logic for authentication actions
ApplicationController = Ember.Controller.extend(menu, {
    session: Ember.inject.service(),
    sessionhelper: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    admintool: Ember.inject.service(),
    gui: Ember.inject.service(),
    PushNotification: Ember.inject.service(),
    gps: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    router: Ember.inject.service(),
    initapp: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    analytics: Ember.inject.service(),
    evented: Ember.inject.service(),
    welcomeTour: Ember.inject.service(),
    loadTranslations: Ember.inject.service(),
    moment: Ember.inject.service(),
    notifications: Ember.inject.service(),
    trialClock: Ember.inject.service('trial-clock'),
    queryParams: ['modal', 'wizard'],
    modal: null,
    trialLength: 14,
    flags: Ember.inject.service(),
    useFormAdd: Ember.computed('modal', function () {
        var form, type;
        type = this.get('modal').split(',')[2];
        form = this.get('modal').split(',')[0];
        if (type === 'history') {
            return false;
        }
        if (
            form !== 'worktime' &&
            form !== 'project' &&
            form !== 'diary' &&
            form !== 'employer' &&
            form !== 'worksitediary' &&
            form !== 'worktask' &&
            form !== 'presence' &&
            form !== 'site_safety_observation' &&
            form !== 'tooltype' &&
            form !== 'abcense'
        ) {
            return false;
        }
        if (form == 'abcense')
            return (
                this.get('flags').test('form-add') ||
                this.get('flags').test(`ember-movenium-form-add-absence`)
            );
        return this.get('flags').test('form-add');
    }),
    sessionOutDated: Ember.computed.alias('session.session.authenticated.offline'),
    isSeasonTheme: Em.computed(function () {
        if (
            (moment().format('YYYY-MM-DD') >= '2018-12-17' &&
                moment().format('YYYY-MM-DD') <= '2018-12-26') ||
            localStorage.getItem('season-theme')
        ) {
            return true;
        }
        return false;
    }),
    modaltype: Ember.computed('modal', function () {
        if (typeof this.get('modal') === 'string') {
            return this.get('modal').split(',')[0];
        }
        return false;
    }),
    sideMenus: Ember.computed(
        'session.currentUser',
        'intl.primaryLocale',
        'Ember.devModeOn',
        'active_menu',
        'refreshMenus',
        function () {
            if (!this.get('session.currentUser')) {
                return;
            }
            return this.getMenus();
        },
    ),
    sidemenuCheck: Ember.computed(
        'session.currentUser',
        'intl.primaryLocale',
        'Ember.devModeOn',
        'refreshMenus',
        'active_menu',
        function () {
            var hidden, ls, menucount, menus;
            if (!this.get('session.currentUser')) {
                return;
            }
            ls = this.get('localStorageWrapper');
            hidden = ls.getUserSetting('hidden_menus');
            menus = this.getMenus();
            menucount = menus.length - hidden.length;
            if (menucount <= 6) {
                return true;
            }
            return false;
        },
    ),
    subMenus: Ember.computed('path', 'session.currentUser', function () {
        if (!this.get('session.currentUser')) {
            return;
        }
        return this.getSubMenus(this.get('path'));
    }),
    priceList: Em.computed('session.currentUser.company_info', function () {
        var base, company, country, full_url, url;
        company = this.get('session.currentUser.company_info');
        if (!company) {
            return false;
        }
        country = company.get('country');
        full_url = '';
        url = (function () {
            switch (country) {
                case 'se':
                    base = config.APP.CDN_URL;
                    url = 'se/movenium-pris.pdf';
                    if (
                        base.substring(base.length - 1, base.length) !== '/' &&
                        url.substring(url.length - 1, url.length) !== '/'
                    ) {
                        base += '/';
                    }
                    return (full_url = base + url);
            }
        })();
        if (this.get('session.currentUser.userlevel') !== '5') {
            // only admin can see price list
            return false;
        } else {
            if (full_url.length > 0) {
                return full_url;
            } else {
                return false;
            }
        }
    }),
    sidebar: Em.computed.alias('gui.sidebar'),
    communityLink: Em.computed('session.currentUser.company_info', function () {
        var company;
        company = this.get('session.currentUser.company_info');
        return getCommunityLink(config.brand, company);
    }),
    init: function () {
        //@get('gps').start()
        this.initOnResume();
        this._super();
        this.trialClock;
        return this.showAppVersionWarning();
    },
    //@initRequestNotificator()
    initRequestNotificator: function () {
        var $, TRANSFORM_TYPES;
        $ = jQuery;
        TRANSFORM_TYPES = ['PUT', 'POST', 'DELETE'];
        $.activeTransforms = 0;
        $(document).ajaxSend(function (e, xhr, settings) {
            var ref;
            if (((ref = settings.type), indexOf.call(TRANSFORM_TYPES, ref) < 0)) {
                return;
            }
            return ($.activeTransforms += 1);
        });
        $(document).ajaxComplete(function (e, xhr, settings) {
            var ref;
            if (((ref = settings.type), indexOf.call(TRANSFORM_TYPES, ref) < 0)) {
                return;
            }
            return ($.activeTransforms -= 1);
        });
        return (
            window.onbeforeunload ||
            (window.onbeforeunload = function () {
                if ($.activeTransforms) {
                    return true;
                }
            })
        );
    },
    initOnResume: function () {
        if (typeof cordova === 'undefined' || cordova === null) {
            window.onfocus = (event) => {
                if (event.target === window) {
                    return this.onresume();
                }
            };
        }
        return document.addEventListener(
            'deviceready',
            () => {
                return document.addEventListener(
                    'resume',
                    () => {
                        return this.onresume();
                    },
                    false,
                );
            },
            false,
        );
    },
    onresume: function () {
        var promise, session;
        session = this.get('session');
        if (
            session.get('currentUser.id') &&
            session.get('data.authenticated.user_id') &&
            parseInt(session.get('currentUser.id')) !== session.get('data.authenticated.user_id')
        ) {
            location.reload();
        }
        this.showAppVersionWarning();
        // if token is about to expirein 5 mins, create new
        if (this.get('sessionhelper').tokenWillExpireIn(5)) {
            console.log('application onresume tokenHasExpired');
            this.set('gui.overlay_loader', true);
            promise = this.get('sessionhelper').refresh();
            return promise.then(
                () => {
                    console.log('application onresume refreshed token'); // this is to see if there were any requests while we were refreshing token
                    this.set('gui.overlay_loader', false);
                    return this.get('sessionhelper').resumed();
                },
                (error) => {
                    captureException(error);
                    return this.set('gui.overlay_loader', false);
                },
            );
        } else {
            this.get('sessionhelper').resumed();
            return this.get('evented').resumed();
        }
    },
    hide_sidebar: Ember.computed('router.currentRouteName', function () {
        if (!this.get('router.currentRouteName')) {
            return;
        }
        if (this.get('router.currentRouteName').substring(0, 13) === 'register.user') {
            return true;
        }
        return false;
    }),
    hide_topbar: Ember.computed('router.currentRouteName', function () {
        if (!this.get('router.currentRouteName')) {
            return;
        }
        if (
            this.get('router.currentRouteName') &&
            this.get('router.currentRouteName').substring(0, 13) === 'register.user'
        ) {
            return true;
        }
        return false;
    }),
    userImage: Em.computed('session.currentUser.image', function () {
        var img, ret_img;
        img = this.get('session.currentUser.image');
        ret_img = '';
        if (img != null && img.length > 0) {
            if (img[0].name === 'naama.png') {
                img[0].thumb = img[0].url;
            }
            if (img[0].thumb) {
                ret_img = config.APP.COLLECTOR_API_URL + '/' + img[0].thumb;
            } else if (img[0].file) {
                ret_img = img[0].file;
            } else {
                ret_img = 'img/user-profile-lg.png';
            }
        } else {
            ret_img = 'img/user-profile-lg.png';
        }
        return Ember.String.htmlSafe('background-image:url(' + ret_img + ')');
    }),
    actions: {
        qrcoderead: function (code, action) {
            if (action.type === 'transition') {
                this.set('modal', null);
                // needs to have this delay it makes sure queryParameters are really emptyed and going back in history won't open popup again
                return Ember.run.later(() => {
                    return this.get('router').transitionTo(action.path);
                }, 100);
            }
        },
        buttonClicked: function () {
            this.toggleProperty('plusAnimate');
            return false;
        },
        toggleSidebar: function () {
            var state;
            state = this.get('sidebar');
            if (state) {
                localStorage.setItem('sidebar', 'open');
            } else {
                localStorage.setItem('sidebar', 'collapsed');
            }
            this.toggleProperty('sidebar');
            return false;
        },
        toggleHamburger: function () {
            this.toggleProperty('hamburgerMenu');
            return false;
        },
        closeHamburger: function () {
            this.set('hamburgerMenu', false);
            this.toggleProperty('refreshMenus');
            return false;
        },
        closeBillingModal: function () {
            return this.set('showBillingTypeSelection', false);
        },
        transition: function (link) {
            if (Em.isArray(link)) {
                if (link.length > 1) {
                    return this.transitionToRoute(link[0], link[1]);
                } else {
                    return this.transitionToRoute(link[0]);
                }
            } else {
                return this.transitionToRoute(link);
            }
        },
        invalidateSession: function () {
            return this.transitionToRoute('logout');
        },
        bubbleTransition: function (to, params) {
            return this.transitionToRoute(to, params);
        },
        guiAction: function (action) {
            if (action === 'abort') {
                return this.get('gui').abort();
            } else if (action === 'close') {
                return this.get('gui').set('showStickyNote', false);
            }
        },
        reloadApp: function () {
            this.set('gui.overlay_loader', true);
            this.transitionToRoute('index');
            return Ember.run.later(
                this,
                function () {
                    return location.reload();
                },
                200,
            );
        },
        removeTourModal: function () {
            return this.get('gui').set('mobileWelcomeTour', false);
        },
        removeFeedbackModal: function () {
            this.set('showFeedbackModal', false);
            return this.set('email_message_sent', false);
        },
        sendFeedback: function () {
            var send;
            this.set('emailSending', true);
            send = this.Collector.ttapi({
                url: 'sendEmail',
                data: {
                    type: 'feedback',
                    country: this.get('session.currentUser.company_info.country'),
                    content: this.get('feedback_text'),
                    email:
                        'customerservice_' + this.get('session.currentUser.company_info.country'),
                    sender: this.get('session.currentUser.email'),
                },
                method: 'post',
            });
            return send.then((resp) => {
                if (resp.error != null) {
                    this.set('error_view', true);
                    this.set('emailSending', false);
                    return this.set('error', resp.error_msg);
                } else {
                    this.set('email_message_sent', true);
                    this.set('emailSending', false);
                    return this.set('feedback_text', null);
                }
            });
        },
        closeAndRefresh: function () {
            this.send('refreshReport');
            return this.set('modal', null);
        },
        refreshReport: function () {
            var controller, route, routeName;
            controller = getOwner(this).lookup('controller:application');
            routeName = this.get('router.currentRouteName');
            if (routeName.includes('worktime-list')) {
                route = getOwner(this).lookup('route:' + controller.get('currentRouteName'));
                return route.refresh();
            }
        },
        changeLanguage: function (lang) {
            moment.locale(lang);
            localStorage['lang'] = lang;

            // save selected lang to ttapi
            this.Collector.ttapi({
                url: 'api/setUserLang?lang=' + lang,
                method: 'post',
            });

            return this.loadTranslations.fetch().then(() => {
                return (this.intl.locale = lang);
            });
        },
        setTrialStatus: function (status) {
            return this.set('trialStatus', status);
        },
        copyPartnerId: function () {
            navigator.clipboard.writeText(this.get('session.currentUser.partnerid'));
            var intl = this.get('intl');
            this.notifications.info(intl.t('header.actions.profile.copyPartnerId.notification'), {
                autoClear: true,
            });
        },
    },
    isLoading: Em.computed('Tt4.isLoading', function () {
        return Tt4.get('isLoading');
    }),

    last_error: Em.computed('Tt4.last_error', function () {
        return Tt4.get('last_error');
    }),
    spinIsLoading: false,
    buttonText: 'submit',
    showMySitesView: Ember.computed('session.currentUser.userlevel', function () {
        var ref;
        return (
            (ref = this.get('session.currentUser.userlevel')) === '1' || ref === '3' || ref === '4'
        );
    }),
    sublinks: Em.A([]),
    active_menu: Ember.computed('path', function () {
        var custom, split, toppath;
        if (!this.get('path')) {
            return;
        }
        toppath = this.get('path')[0];
        if (location.href.includes('custom')) {
            custom = location.href.split('/').filter((item) => {
                return item.includes('custom');
            });
            return custom[0];
        }
        if (toppath === 'workday') {
            return 'worktimes';
        }
        if (toppath === 'site_safety') {
            return this.get('path')[1];
        }
        if (toppath === 'forms') {
            split = location.href.split('/');
            return split[split.indexOf('forms') + 1];
        } else {
            return toppath;
        }
    }),
    path: Ember.computed('router.currentRouteName', function () {
        var path;
        path = this.get('router.currentRouteName');
        if (path) {
            return path.split('.');
        }
    }),
    additionalMainViewClasses: Ember.computed('router.currentRouteName', function () {
        var path;
        path = this.get('router.currentRouteName');
        if (path === 'settings.general-v2') {
            return 'main-view-settings-v2';
        }
        // if path contains ".edit" or ".add" we want to add class to main-view
        if (path.includes('.edit') || path.includes('.add')) {
            return 'main-view-form-open';
        }
        return '';
    }),
    headerText: Ember.computed('path', function () {
        var c, form, formName, intl, name, path, routeObject, split;
        path = this.get('path');
        intl = this.get('intl');
        c = this.get('Collector');
        if (!path) {
            return;
        }
        if (path[0].slice(-8) === '_loading') {
            path[0] = path[0].slice(0, -8);
        }
        routeObject = this.get('routing.currentState.routerJsState.params');
        if (path[0] === 'forms') {
            if (routeObject['forms.form-report'] != null) {
                formName = routeObject['forms.form-report']['form'];
            } else if (routeObject['forms.form-add'] != null) {
                formName = routeObject['forms.form-add']['form'];
            } else if (routeObject['forms.form-edit'] != null) {
                formName = routeObject['forms.form-edit']['form'];
            }
        }
        if (formName && path[0] === 'forms' && c.formExists(formName)) {
            form = c.forms()[formName];
            if (Ember.isPresent(form.name)) {
                return form.name;
            }
        }
        if (path[0] === 'site_safety') {
            split = location.href.split('/');
            name = split[split.indexOf('measurement') + 1];
            if (c.formExists('measurement_' + name)) {
                form = c.forms()['measurement_' + name];
                if (Ember.isPresent(form.name)) {
                    return form.name;
                }
            }
            if (path[1] === 'report') {
                return intl.t('header.path.' + path[2]);
            } else {
                return intl.t('header.path.' + path[1]);
            }
        } else if (!intl.exists('header.path.' + path[0]) && intl.exists('menu.' + path[0])) {
            return intl.t('menu.' + path[0]);
        } else if (path) {
            return intl.t('header.path.' + path[0]);
        }
    }),
    showBillingTypeSelection: false,
    // check if client has billing-info and if not, ask user to add it
    billingtypeObs: Em.on(
        'init',
        Ember.observer('session.currentUser.partnerid', function () {
            var c, created, promise, self, test_passed;
            created = this.get('session.currentUser.company_info.row_info.created');
            if (created && created > '2016-04-20') {
                if (moment().diff(moment(created), 'days') > 7) {
                    self = this;
                    c = this.get('Collector');
                    test_passed = false;
                    // show this only if tt or pl is active
                    if (
                        c.testNeedsOne('products.personalliggareandid06') ||
                        c.testNeedsOne('products.timetracker') ||
                        c.testNeedsOne('products.personalliggare')
                    ) {
                        test_passed = true;
                    }
                    if (
                        this.get('session.currentUser.partnerid') &&
                        parseInt(this.get('session.currentUser.userlevel')) === 5 &&
                        test_passed
                    ) {
                        promise = this.get('admintool').ajax({
                            url: 'billinginfo',
                            method: 'get',
                        });
                        return promise.then(
                            function (data) {
                                // billing-info was found for this client, do not show billing-dialog
                                return self.set('showBillingTypeSelection', false);
                            },
                            (reason) => {
                                // billing-info not found, show dialog
                                if (reason.status === 404) {
                                    // get address-data
                                    promise = this.get('admintool').ajax({
                                        url: 'customer_info',
                                        method: 'get',
                                    });
                                    return promise.then((data) => {
                                        if (data['status'] !== 'demo') {
                                            this.set('customerData', data);
                                            return self.set('showBillingTypeSelection', true);
                                        }
                                    });
                                }
                            },
                        );
                    }
                }
            }
        }),
    ),
    startTour: Em.on('init', function () {
        if (localStorage.getItem('welcome-tour')) {
            localStorage.removeItem('welcome-tour');
            return Em.run.later(
                this,
                () => {
                    return this.get('welcomeTour').start();
                },
                100,
            );
        }
    }),
    impersonate: Em.computed(function () {
        if (this.get('session.session.content.authenticated.scope')) {
            return true;
        }
    }),
    trialDaysLeft: Em.computed(function () {
        var created, days;
        created = this.get('session.currentUser.company_info.row_info.created');
        days = moment().diff(moment(created), 'days');
        return this.get('trialLength') - days;
    }),
    billingInfo: function () {
        var promise;
        promise = this.get('admintool').ajax({
            url: 'billinginfo',
            method: 'get',
        });
        return promise.then(
            (res) => {
                return res;
            },
            (reason) => {
                return false; // billinginfo returned 404 - no billing info found
            },
        );
    },
    trialMode: Em.computed('session.currentUser', 'trialClock.time', function () {
        if (!this.get('Collector').testNeedsOne('products.mini_entry')) {
            return false;
        }
        return this.billingInfo().then((binfo) => {
            var mode;
            if (this.get('Collector').testNeedsOne('products.readonly')) {
                mode = 'locked';
            } else if (binfo['payment_status'] === 'failed' && this.get('trialDaysLeft') <= 0) {
                mode = 'payment_failed';
            } else if (!binfo && this.get('trialDaysLeft') <= 0) {
                mode = 'payment_failed';
            } else if (this.get('trialDaysLeft') >= 0) {
                mode = 'normal_trial';
            }
            return mode;
        });
    }),
    canSubscriptionBeCanceled: Em.computed('trialStatus', function () {
        if (this.get('trialStatus') === 'disabled') {
            return false;
        }
        return this.billingInfo().then((binfo) => {
            return binfo['is_trial_active'];
        });
    }),
    showAppVersionWarning: function () {
        var currentVersionTimeStamp, status, versions;
        if (
            !this.get('session.currentUser') ||
            typeof cordova === 'undefined' ||
            cordova === null
        ) {
            return false;
        }
        versions = this.get('flags').test('versions');
        if (!versions || versions['force-update'] === void 0) {
            return false;
        }
        currentVersionTimeStamp = this.get('gui').version_timestamp();
        if (
            moment(currentVersionTimeStamp)
                .add(versions['force-update'], 'days')
                .diff(moment(), 'days') === 0
        ) {
            status = 'expired';
        } else if (
            moment(currentVersionTimeStamp).add(versions['force-update'], 'days').isBefore(moment())
        ) {
            status = 'expired';
        } else if (
            moment(currentVersionTimeStamp)
                .add(versions['suggest-update'], 'days')
                .isBefore(moment())
        ) {
            status = 'deprecated';
        } else {
            status = false;
        }
        this.set('showAppWarning', true);
        return this.set('appVersion', {
            status: status,
            version: this.get('gui').version(),
            days_until_expires: moment(currentVersionTimeStamp)
                .add(versions['force-update'], 'days')
                .diff(moment(), 'days'),
        });
    },
    brandLogo: Em.computed('session.currentUser', function () {
        if (this.gui.getBrand() === 'otta') {
            return config.APP.CDN_URL + '/images/otta/otta_logo_white_rgb.svg';
        }
        return false;
    }),
});

export default ApplicationController;
