/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/combo/route.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryComboRoute, service;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

({
    inject: { service },
} = Ember);

DiaryComboRoute = GeneralReportRoute.extend({
    store: service(),
    Collector: service('collector-service'),
    flags: service(),
    evented: Ember.inject.service(),
    form: 'diary',
    queryParams: {
        wtPage: {
            refreshModel: true,
        },
        prPage: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        user: {
            refreshModel: true,
        },
    },
    limit: 100,
    model: function (params) {
        var c, dorder, dpar, f, i, len, o, obj, param, porder, ppar, ref, value, worder, wpar;
        params = this._super(params);
        c = this.get('Collector');
        wpar = {
            sideload: true,
            limit: params.limit,
        };
        dpar = {
            sideload: true,
            limit: params.limit,
        };
        ppar = {
            sideload: true,
            limit: params.limit,
        };
        for (param in params) {
            value = params[param];
            if (param === 'wtPage' && value) {
                wpar.offset = (value - 1) * params.limit;
            }
            if (param === 'prPage' && value) {
                ppar.offset = (value - 1) * params.limit;
            }
            if (c.fieldExists('diary', param)) {
                dpar[param] = value;
            }
            if (c.fieldExists('worktime', param)) {
                wpar[param] = value;
            }
            if (c.fieldExists('presence', param)) {
                ppar[param] = value;
            }
            if (param === 'order' && value) {
                worder = [];
                dorder = [];
                porder = [];
                ref = value.split(',');
                for (i = 0, len = ref.length; i < len; i++) {
                    o = ref[i];
                    f = o;
                    if (f.slice(-1) === '-') {
                        f = f.slice(0, -1);
                    }
                    if (c.fieldExists('diary', f)) {
                        dorder.push(o);
                    }
                    if (c.fieldExists('worktime', f)) {
                        worder.push(o);
                    }
                    if (c.fieldExists('presence', f)) {
                        porder.push(o);
                    }
                }
                if (dorder.length > 0) {
                    dpar.order = dorder.join(',');
                }
                if (worder.length > 0) {
                    wpar.order = worder.join(',');
                }
                if (porder.length > 0) {
                    ppar.order = porder.join(',');
                }
            }
        }
        if (Em.isPresent(params.project) && Em.isPresent(params.date)) {
            if (!this.get('flags').test('ember-movenium-form-report-diary')) {
                obj = {
                    diary: this.get('store').query('diary', dpar),
                    worktime: this.get('store').query('worktime', wpar),
                };
                if (c.testNeedsOne('presence')) {
                    obj.presence = this.get('store').query('presence', ppar);
                }
                return Ember.RSVP.hash(obj);
            } else {
                this.controllerFor('diary.combo').set(
                    'diary',
                    this.get('store').query('diary', dpar),
                );
                this.controllerFor('diary.combo').set('qParamsW', wpar);
                if (c.testNeedsOne('presence')) {
                    this.controllerFor('diary.combo').set('qParamsP', ppar);
                }
                this.evented.storeEvent('update', null, null);
                return true;
            }
        }
        return null;
    },
});

export default DiaryComboRoute;
