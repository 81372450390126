import Service, { inject as service } from '@ember/service';
export default class AnalyticsService extends Service {
    @service private metrics: any;
    @service private flags: any;

    public trackEvent(event: TrackingEvent) {
        if (!this.flags.test('analytics-enabled')) return;
        this.metrics.trackEvent(event);
        if (typeof window.snowplow === 'function' && this.flags.test('use-snowplow')) {
            window.snowplow('trackStructEvent', event);
        }
    }

    public trackPage(event: object) {
        if (!this.flags.test('analytics-enabled')) return;
        this.metrics.trackPage(event);
    }
}
