/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/forms/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsFormsController,
    indexOf = [].indexOf;

import Ember from 'ember';

import exportFile from '../../../mixins/export-file';

import collector from '../../../classes/collector';

import $ from 'jquery';

SettingsFormsController = Ember.Controller.extend(exportFile, {
    queryParams: ['adminMode'],
    formJson: Ember.inject.service(),
    session: Ember.inject.service(),
    tools: Ember.inject.service(),
    notifications: Ember.inject.service(),
    settingsController: Em.inject.controller('settings'),
    settingsFormsFormController: Em.inject.controller('settings.forms.form'),
    application: Em.inject.controller(),
    dialogs: Ember.inject.service(),
    ttapi2: Ember.inject.service(),
    intl: Em.inject.service(),
    modelCreator: Ember.inject.service(),
    adminMode: false,
    tt3Service: Ember.computed(function () {
        return collector.get('tt3flag');
    }),
    dropdown_options: Em.computed('model', 'model.[]', 'adminMode', function () {
        var acceptedForms, ret;
        ret = [];
        acceptedForms = [
            'user',
            'project',
            'employer',
            'worktime',
            'task',
            'abcense',
            'group',
            'orientation',
            'projectfile',
            'diary',
            'worktask',
            'site_safety_observation',
            'trnotice',
            'tooltype',
        ];
        this.get('model').forEach((form) => {
            var ref;
            if (
                ((ref = form.get('external_type')), indexOf.call(acceptedForms, ref) >= 0) ||
                this.get('adminMode')
            ) {
                return ret.push({
                    id: form.get('id'),
                    name:
                        form.get('translatedName') +
                        (this.get('adminMode') ? ' (' + form.get('external_type') + ')' : ''),
                });
            }
        });
        return ret;
    }),
    showAdminMode: Em.computed('application.impersonate', 'Ember.devModeOn', function () {
        if (Ember.get(Ember, 'devModeOn')) {
            return true;
        }
        if (this.get('application.impersonate')) {
            return true;
        }
        return false;
    }),
    callImportForm: function (json) {
        return this.get('formJson')
            .import(JSON.parse(json))
            .then((something_was_added) => {
                if (!something_was_added) {
                    return;
                }
                this.get('modelCreator')
                    .createEmberModels()
                    .then(() => {
                        return this.get('application').notifyPropertyChange('sideMenus');
                    });
                return this.get('settingsController').refreshNeeded();
            });
    },
    logs: Ember.computed('adminMode', 'form_id', function () {
        if (this.get('adminMode')) {
            return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
                promise: new Ember.RSVP.Promise((resolve) => {
                    return this.get('ttapi2')
                        ._get('getFormActivity', {
                            date:
                                moment().subtract(1, 'year').format('YYYY-MM-DD') +
                                '_' +
                                moment().format('YYYY-MM-DD'),
                            form: this.get('form_id'),
                        })
                        .then((back) => {
                            return resolve(back);
                        });
                }),
            });
        }
    }),
    actions: {
        formChanged: function (formid) {
            if (!formid) {
                return this.transitionToRoute('settings.forms');
            } else {
                return this.transitionToRoute('settings.forms.form', formid);
            }
        },
        exportForm: function () {
            var form, json_string;
            form = this.store.peekRecord('form', this.get('form_id'));
            json_string = this.get('formJson').exportForm(form.get('external_type'));
            if (json_string) {
                return this.exportFile(JSON.stringify(json_string), 'export', 'json');
            }
        },
        importForm: function () {
            return $('#importFormFileInput').click();
        },
        createForm: function () {
            var form, name;
            name = prompt('Please enter form name');
            if (name) {
                form = this.store.createRecord('form', {
                    name: name,
                    external_type: this.get('tools').computerizeHumanInput(name),
                });
                return form.save().then(() => {
                    this.set('model', this.store.peekAll('form'));
                    return this.notifyPropertyChange('dropdown_options');
                });
            }
        },
        //@transitionToRoute 'settings.forms.form',
        //    form: id
        removeForm: function () {
            return this.get('dialogs')
                .confirm(this.get('intl').t('settings.forms.confirm_remove'))
                .then((con) => {
                    var form;
                    if (con) {
                        form = this.get('store').peekRecord('form', this.get('form_id'));
                        return form
                            .destroyRecord()
                            .then(() => {
                                this.get('settingsController').refreshNeeded();
                                this.get('notifications').success(
                                    this.get('intl').t('settings.forms.remove_success'),
                                    {
                                        autoClear: true,
                                    },
                                );
                                this.set('form_id', null);
                                return this.transitionToRoute('settings.forms');
                            })
                            .catch(e)(() => {
                            return this.get('notifications').error(
                                this.get('intl').t('settings.forms.remove_fail'),
                                {
                                    autoClear: false,
                                },
                            );
                        });
                    }
                });
        },
        upload: function (evt) {
            var input, reader;
            input = evt.target;
            if (input.files && input.files[0]) {
                reader = new FileReader();
                reader.onload = (e) => {
                    var data;
                    data = e.target.result;
                    this.callImportForm(data);
                    return this.set('selectedFile', null);
                };
                return reader.readAsText(input.files[0]);
            }
        },
    },
});

export default SettingsFormsController;
