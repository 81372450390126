import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import fetch from 'fetch';

export default class ClockcardEndComponent extends Component {
    @tracked showWorktimeRoundedPopup = false;
    @tracked worktimeRoundedInfoText;

    constructor() {
        super(...arguments);
        this.setInfoText();
    }

    @action toggleWorktimeRoundedPopup() {
        this.showWorktimeRoundedPopup = !this.showWorktimeRoundedPopup;
    }

    async setInfoText() {
        const key = 'worktime-rounded-' + moment.locale();
        const fileUrl = `https://cloud-writer-prod.s3.amazonaws.com/${key}.html`;

        let response = await fetch(fileUrl, { dataType: 'text' });
        if (!response.ok) return (this.worktimeRoundedInfoText = `cannot find help for ${key}`);

        this.worktimeRoundedInfoText = htmlSafe(await response.text());
    }
}
