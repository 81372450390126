/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldo/show-date/component.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldoShowDateComponent;

import Ember from 'ember';

UsersEditSaldoShowDateComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    bankDeducerColumns: Ember.computed('userid', 'date', function () {
        var arr, promise;
        arr = Ember.A([]);
        if (this.get('Collector').testNeedsOne('products.worktimebank')) {
            promise = this.get('store').query('bank', {
                skipsaldo: 'on',
            });
            promise.then((banks) => {
                return banks.forEach((bank) => {
                    return arr.pushObject(bank.get('column'));
                });
            });
        }
        return arr;
    }),
    userid: Ember.computed('user', function () {
        if (Ember.typeOf(this.get('user')) === 'instance') {
            return this.get('user.id');
        } else {
            return this.get('user');
        }
    }),
    deducters: Ember.computed('userid', 'date', function () {
        return this.get('store').query('saldodeducter', {
            date: this.get('date'),
            user: this.get('userid'),
            _v: 2,
        });
    }),
    worktimes: Ember.computed('userid', 'date', function () {
        var params;
        params = {
            date: this.get('date'),
            user: this.get('userid'),
            sideload: true,
        };
        //if @get('Collector').fieldExists('worktime', 'affects_overtime')
        //   params['affects_overtime'] = "off";
        return this.get('store').query('worktime', params);
    }),
    absences: Ember.computed('userid', 'date', 'saldo_settings', function () {
        var params;
        if (!this.get('Collector').testNeeds(['abcense'])) {
            return [];
        }
        params = {
            startdate:
                moment(this.get('date')).subtract(35, 'days').format('YYYY-MM-DD') +
                '_' +
                this.get('date'),
            user: this.get('userid'),
            sideload: ['abcensetype.name', 'abcensetype.usesholiday'],
        };
        return this.get('store').query('abcense', params);
    }),
    absences_for_date: Ember.computed('date', 'absences.[]', function () {
        var date, saldo_absence_id;
        date = this.get('date');
        saldo_absence_id = this.get('saldo_settings.saldo_absence_id');
        return this.get('absences').filter((absence) => {
            return (
                absence.get('abcensetype.id') !== saldo_absence_id &&
                absence.get('abcensetype.usesholiday') !== 'saldo' &&
                absence.get('startdate') <= date &&
                absence.get('enddate') >= date
            );
        });
    }),
    fullday_absences: Ember.computed('absences_for_date.[]', function () {
        return this.get('absences_for_date').filter((absence) => {
            return absence.get('dayamount');
        });
    }),
    halfday_absences: Ember.computed('absences_for_date.[]', function () {
        return this.get('absences_for_date').filter((absence) => {
            return !absence.get('dayamount');
        });
    }),
    saldo_absences: Ember.computed('date', 'absences.[]', function () {
        var date, saldo_absence_id;
        date = this.get('date');
        saldo_absence_id = this.get('saldo_settings.saldo_absence_id');
        return this.get('absences').filter((absence) => {
            return (
                (absence.get('abcensetype.id') === saldo_absence_id ||
                    absence.get('abcensetype.usesholiday') === 'saldo') &&
                absence.get('startdate') <= date &&
                absence.get('enddate') >= date
            );
        });
    }),
    hourSum: Ember.computed(
        'worktimes.[]',
        'deducters.[]',
        'fullday_absences.[]',
        'halfday_absences.[]',
        'workday_length',
        'bankDeducerColumns.[]',
        function () {
            var bankDeductionsSum,
                col,
                i,
                len,
                mark_sum_zero,
                max_saldo_over,
                ref,
                sum,
                tmp_sum,
                totalsum;
            sum = 0;
            bankDeductionsSum = 0;
            totalsum = 0;
            max_saldo_over = false;
            mark_sum_zero = false;
            if (this.get('workday_length')) {
                this.get('worktimes').forEach((row) => {
                    if (row.get('typeofwork') === 'piece') {
                        return;
                    }
                    if (row.get('affects_overtime') && row.get('affects_overtime') !== 'off') {
                        mark_sum_zero = true;
                    }
                    return (sum += row.get('work_hours'));
                });
                this.get('deducters').forEach((row) => {
                    return (sum = row.get('addhours')
                        ? sum + row.get('hours')
                        : sum - row.get('hours'));
                });
                this.get('fullday_absences').forEach((row) => {
                    return (sum += this.get('workday_length'));
                });
                this.get('halfday_absences').forEach((row) => {
                    return (sum += row.get('absence_hours'));
                });
                ref = this.get('bankDeducerColumns');
                for (i = 0, len = ref.length; i < len; i++) {
                    col = ref[i];
                    this.get('worktimes').forEach((worktime) => {
                        if (worktime.get(col)) {
                            sum -= worktime.get(col);
                            console.log('summing ', worktime.get(col));
                            return (bankDeductionsSum += worktime.get(col));
                        }
                    });
                }
                tmp_sum = sum - this.get('workday_length');
                if (mark_sum_zero && tmp_sum >= 0) {
                    totalsum = 0;
                } else if (mark_sum_zero && tmp_sum <= 0) {
                    totalsum = sum - this.get('workday_length');
                    mark_sum_zero = false;
                } else {
                    totalsum = sum - this.get('workday_length');
                }
                if (
                    this.get('saldo_settings.auto_cut') &&
                    this.get('saldo_settings.dailymaximum') > 0 &&
                    totalsum > this.get('saldo_settings.dailymaximum') / 60
                ) {
                    totalsum = this.get('saldo_settings.dailymaximum') / 60;
                    max_saldo_over = true;
                }
            }
            return {
                sum: totalsum,
                bank_deductions: bankDeductionsSum,
                max_saldo_over: max_saldo_over,
                affects_overtime_info: mark_sum_zero,
            };
        },
    ),
    plus: Ember.computed('hourSum', function () {
        if (this.get('hourSum') > 0) {
            return '+';
        }
    }),
    didReceiveAttrs: function () {
        if (this.get('workday_list')) {
            return this.set(
                'workday_length',
                this.get('workday_list')[this.get('date')].workday_length / 60,
            );
        } else {
            return this.get('Collector')
                .ttapi({
                    url:
                        'saldo/get_saldo_list?startdate=' +
                        this.get('date') +
                        '&enddate=' +
                        this.get('date') +
                        '&userid=' +
                        this.get('user.id'),
                })
                .then((back) => {
                    return this.set('workday_length', back[this.get('date')].workday_length / 60);
                });
        }
    },
});

export default UsersEditSaldoShowDateComponent;
