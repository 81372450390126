import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { bind } from '@ember/runloop';
import { set } from '@ember/object';
let initialMouseX = 0;
let initialMouseY = 0;
let initialElementX = 0;
let initialElementY = 0;

export default Component.extend({
    gui: service(),
    analytics: service(),
    scrollable: true,
    shouldShowHeaderOptions: false,
    actions: {
        toggleHeaderOptions() {
            set(this, 'shouldShowHeaderOptions', !this.shouldShowHeaderOptions);
        },

        removeModal() {
            if (this.close) this.close();
        },

        grabModal(event) {
            initialMouseX = event.pageX;
            initialMouseY = event.pageY;

            initialMouseX = event.pageX;
            initialMouseY = event.pageY;

            this.draggedElement = this.element.querySelector('.modal-popup');
            initialElementX = this.draggedElement.offsetLeft;
            initialElementY = this.draggedElement.offsetTop;

            this.bindedHandleMouseMove = bind(this, this.handleMouseMove);
            window.addEventListener('mousemove', this.bindedHandleMouseMove);
        },

        releaseModal() {
            window.removeEventListener('mousemove', this.bindedHandleMouseMove);
        },
    },

    handleMouseMove(event) {
        if (event.buttons === 0) {
            this.send('releaseModal');
            return;
        }

        this.draggedElement.style.position = 'absolute';
        this.draggedElement.style.left = initialElementX + event.pageX - initialMouseX + 'px';
        this.draggedElement.style.top = initialElementY + event.pageY - initialMouseY + 'px';
    },
});
