import DS from 'ember-data';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import config from 'tt4/config/environment';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default DS.JSONAPIAdapter.extend(DataAdapterMixin, {
    sessionhelper: service(),
    flags: service(),

    headers: computed('session.data.authenticated.access_token', function () {
        let headers = {};
        if (this.session.isAuthenticated) {
            // OAuth 2
            headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
        }
        return headers;
    }),

    host: config.APP.CAPI3,

    ensureResponseAuthorized(status, headers, payload, requestData) {
        if (status === 401 && payload.error === 'jwt expired') {
            this.sessionhelper.expiredRecovery(`${requestData.method} ${requestData.url}`);
        } else {
            return this._super(...arguments);
        }
    },
});
