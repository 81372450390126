import { helper } from '@ember/component/helper';
import { isArray } from '@ember/array';
import { assert } from '@ember/debug';

// This function receives the params `params` and checks if params[0] is in array params[1]
export function inArray(params) {
    if (params[0] == null) {
        return false;
    }
    // Check if the param we are searching with is a string or number
    if ((typeof params[0] !== 'string' && typeof params[0] !== 'number') || !params[0]) {
        assert('You must pass a string as the first argument to the in-array helper');
        return false;
    }
    // Check that we got an array to work with
    if (!isArray(params[1])) {
        assert('You must pass an array as the second argument to the in-array helper');
        return false;
    }
    // See if what we are searching for is in the array
    if (params[1].includes(params[0])) {
        return true;
    } else return false;
}

export default helper(inArray);
