import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    dialogs: service(),
    intl: service(),
    evented: service(),

    didInsertElement() {
        this.store.findAll('rollocklog');
    },
    actions: {
        showOnlyErrorRows() {
            //set(this,'filters', {
            //    eventCode: "250,230,210"
            //})
        },
    },

    /*
    ROLLOCK EVENTCODES

    100 NFC key rejected
    101 NFC key accepted

    204 open authorized
    205 key not found
    210 key expired

    522 Remote open command accepted
    523 Remote open command failed

    1403 BLE open accepted with e-key
    1404 BLE open rejected

    1500 door locked
    1512 unlocked door not opened
    1515 door closed
    */
});
