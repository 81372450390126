/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/error-popup.coffee.old
// Generated by CoffeeScript 2.6.1
var ErrorPopupComponent;

import Ember from 'ember';

import $ from 'jquery';

ErrorPopupComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    didInsertElement: function () {
        return $('.error_msg').hide();
    },
    error_msg: Ember.computed('error', function () {
        var message, msg, translated;
        $('.error_msg').show();
        msg = this.get('error.responseText');
        // go to top of the page
        $(document).scrollTop(0);
        translated = this.get('intl').t('errors.' + msg);
        if (translated.string && translated.string.substring(0, 19) !== 'Missing translation') {
            message = translated.string;
        } else {
            message =
                msg + ' ' + this.get('error.statusText') + ' (' + this.get('error.status') + ')';
        }
        // fadeout with delay
        setTimeout(function () {
            return $('.error_msg').fadeOut('slow');
        }, 4000);
        return message;
    }),
});

export default ErrorPopupComponent;
