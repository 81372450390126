/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/promo-component.coffee.old
// Generated by CoffeeScript 2.6.1
var PromoComponentComponent;

import Ember from 'ember';

PromoComponentComponent = Ember.Component.extend({
    showMessage: Em.computed(function () {
        if (
            this.get('start') &&
            this.get('end') &&
            moment().format('YYYY-MM-DD') >= this.get('start') &&
            moment().format('YYYY-MM-DD') <= this.get('end')
        ) {
            return true;
        }
        return false;
    }),
});

export default PromoComponentComponent;
