/* eslint-disable ember/named-functions-in-promises */
import Service, { inject as service } from '@ember/service';
import { set, get } from '@ember/object';
import formReport from '../pods/collector/report-comp/component';
import { getSums } from 'tt4/utils/sumrow-util';

/*
This service transforms json to excel via ttapi2

for createExcelFromJSON(json), the JSON should be like this:
note: only ROWS are required

{
"filters":[
        ["project:", "test project"]
        ["date", "Ke, 1.5.2019 - Ti, 10.9.2019"]
    ],
"columns":[
        { "name": "Name", "key": "name"},
        { "name": "Other", "key": "key_other"}
    ],
"rows":[
        { "name": "John Doe", "key_other": "other info"},
        { "name": "Harry Barry", "key_other": "extra info"}
    ],
"name": "file name .xlsx extension is optional"
}

*/

export default Service.extend({
    ttapi2: service(),
    collector: service('collector-service'),
    session: service(),
    sessionGlobals: service(),
    store: service(),
    intl: service(),
    flags: service(),
    dialogs: service(),

    createExcelJSONFromForm(form, model, columns, filters = [], sumRow = false) {
        let excelJSON = {};
        const formatAsNumbers = ['hours'];

        let excelColumns = columns.map((column) => {
            const ret = {
                name: get(column, 'name.string') || get(column, 'name'),
                key: get(column, 'ext'),
            };
            if (
                formatAsNumbers.includes(column.obj.type) &&
                this.flags.test('excel-hours-with-decimals')
            )
                ret.numFmt = '0.000';
            return ret;
        });

        let rows = this.formatModelToJSON(form, model, columns);

        excelJSON['filters'] = filters;
        excelJSON['columns'] = excelColumns;
        excelJSON['rows'] = rows;
        if (sumRow) {
            excelJSON['sumrow'] = sumRow;
        }

        return excelJSON;
    },

    /*
        Give this function the form, the model, and the columns/fields on the form (@get('defaultcolumns') or similar
        It will format this information to be displayed properly in an excel, and return it as json
    */
    formatModelToJSON(form, model, columns) {
        let formattedJSON = model.map((row) => {
            let formattedRow = {};
            columns.forEach((column) => {
                let ext = get(column, 'ext');
                let obj = get(column, 'obj');
                let cell = get(row, ext);
                let formatted = get(this, 'collector').format(
                    cell,
                    obj,
                    null,
                    row,
                    'serverlessexcel',
                    form,
                );

                // do not convert numbers to strings
                let exportFormatted = null;
                if (typeof formatted === 'number') {
                    exportFormatted = formatted;
                } else if (
                    ['row_info.created', 'row_info.modified'].includes(ext) &&
                    row.row_info
                ) {
                    exportFormatted = formatted
                        ? moment(formatted).format('YYYY-MM-DD HH:mm')
                        : null;
                } else {
                    exportFormatted = get(this, 'collector').format_export(formatted);
                }

                formattedRow[ext] = exportFormatted;
            });
            return formattedRow;
        });

        return formattedJSON;
    },

    /*
        Give this function some json and an optional file name, it will call ttapi2 and return a presigned s3 url for the file
        See json examples from the top
        Now returns promise so we can close excel-modal when exporting is done
    */
    createExcelFromJSON(json, fileName = null) {
        return new Promise((resolve, reject) => {
            if (!fileName) {
                let name = get(this, 'session.currentUser.company') || 'movenium_export';
                json['name'] = name;
            }

            get(this, 'ttapi2')
                .request('export?type=excel', {
                    data: JSON.stringify(json),
                    dataType: 'json',
                    contentType: 'application/json',
                    method: 'POST',
                })
                .then((res) => {
                    this.handleResponse(res);
                    resolve();
                })
                .catch((res) => {
                    this.handleError(res);
                    reject();
                });
        });
    },

    async createBase64ExcelFromJSON(json, fileName = null) {
        if (!fileName) {
            let name = get(this, 'session.currentUser.company') || 'movenium_export';
            json['name'] = name;
        }
        try {
            const response = await get(this, 'ttapi2').request('export?type=excel', {
                data: JSON.stringify(json),
                dataType: 'json',
                contentType: 'application/json',
                method: 'POST',
            });
            if (!response.url) throw new Error('Url not found from response');
            return await this.getBase64(response);
        } catch (e) {
            this.handleError(e);
        }
    },

    async getBase64(response) {
        const resp = await fetch(response.url);
        const blob = await resp.blob();
        const reader = new FileReader();
        const base64 = await new Promise((resolve) => {
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
        return base64.split(',')[1];
    },

    handleResponse(res) {
        if (res.url) {
            const target = window.cordova ? '_system' : undefined;
            window.open(res.url, target);
        }
    },

    handleError(e) {
        this.dialogs.alert(e.message || e);
    },

    async createCsv(form, data, columns) {
        let json = this.createExcelJSONFromForm(form, data, columns);
        let name = this.session.currentUser.company || 'movenium_export';
        json['name'] = name;

        try {
            let res = await this.ttapi2.request('export?type=csv', {
                data: JSON.stringify(json),
                dataType: 'json',
                contentType: 'application/json',
                method: 'POST',
            });
            if (res.url) window.open(res.url);
        } catch (e) {
            this.dialogs.alert(e.message || e);
        }
    },

    async createPdfElement(data, selected, settings, columns, params = {}, includeAllRows = false) {
        const rows = selected.length > 0 && !includeAllRows ? selected : await data;
        let component = this.createComponent(rows, settings);
        if (params.statusInfo)
            await set(
                component,
                'statusInfoRows',
                await this.getStatusInfoRows(settings.form, await data),
            );
        set(component, 'data', data);
        set(component, 'pdfSettings', params);
        let formattedSumrow = getSums(
            rows,
            columns,
            settings.form,
            this.collector,
            'sumrow',
        ).firstObject;
        set(component, 'pdf_formatted_sumRow', formattedSumrow);
        return await get(component, 'asPdfElement');
    },

    async createPdfFull(form, selected, filters, showColumns, params = {}, format = null) {
        let type = form === 'diary' ? 'diary' : 'row';
        // This is used to create a new stylish pdf with headers on side
        if (params.layoutOption === 'sideHeaders') type = 'horizontal';

        let url = 'pdf/' + type;

        let postParams = {
            form: form,
            lang: moment.locale(),
            filters: JSON.stringify(filters),
        };

        if (selected.length !== 0) {
            postParams.id = selected.mapBy('id').join(',');
        }

        if (showColumns) {
            postParams.showColumns = showColumns;
        }

        if (params.template) {
            postParams.template = get(params, 'template.id');
        }

        if (params.signature) {
            postParams.signature = params.signature;
        }

        if (params.dateAndPlace) {
            postParams.dateAndPlace = params.dateAndPlace;
        }

        if (format) {
            postParams.type = format;
        }

        let request = await this.collector.ttapi({
            dataType: 'text',
            url: url,
            data: postParams,
        });

        if (format === 'base64') {
            return request;
        } else {
            let data = JSON.parse(request);
            if (data.url) {
                if (window.cordova) {
                    window.open(
                        data.url,
                        '_system',
                        'location=yes,enableViewportScale=yes,hidden=no,usewkwebview=yes',
                    );
                } else {
                    window.open(data.url);
                }
            }
        }
    },

    createComponent(selected, settings) {
        return formReport.create({
            store: this.store,
            intl: this.intl,
            Collector: this.collector,
            sessionGlobals: this.sessionGlobals,
            rowsPerPage: 'all',
            renderer: this,
            checked_rows: selected ? selected.map((item) => get(item, 'id')) : [],
            settings: settings,
        });
    },

    async getStatusInfoRows(form, data) {
        const row_ids = data.mapBy('id');
        return this.collector.ttapi({
            url: 'get_status_info',
            method: 'post',
            data: {
                ids: row_ids,
                form: form,
            },
        });
    },
});
