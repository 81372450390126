import { isPresent } from '@ember/utils';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default Controller.extend({
    collectorService: service(),
    transaction: alias('model'),

    subMenus: computed(function () {
        const c = get(this, 'collectorService');
        const subMenus = [
            { link: 'machines.transaction.project', text: 'machines.transaction.select_project' },
            // {link: 'machines.transaction.tooltype', text:'machines.transaction.select_tooltype'},
            {
                link: 'machines.transaction.add',
                text: 'machines.transaction.add_tools',
                needs: ['tooltype@add'],
            },
        ];

        return subMenus.filter(function (item) {
            if (isPresent(item.needs)) {
                return c.testNeeds(item.needs);
            }
            return true;
        });
    }),
});
