import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import GeneralReportRoute from '../../../routes/general-report';

const MachinesMachineRoute = GeneralReportRoute.extend({
    store: service(),
    flags: service(),

    queryParams: {
        q: {
            refreshModel: true,
        },
    },

    form: 'tooltype',

    model(params) {
        params = this._super(params);

        if (params.page) {
            delete params.page;
        }
        return set(this.controllerFor('machines.machine'), 'qParams', params);
    },
});

export default MachinesMachineRoute;
