/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/edit/passes/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsPassesRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

ProjectsPassesRoute = GeneralReportRoute.extend({
    form: 'orientation',
    queryParams: {
        ori_site: {
            refreshModel: true,
            replace: true,
        },
        date: {
            refreshModel: true,
            replace: true,
        },
        page: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var project;
        params = this._super(params);
        params.ori_date = params.date;
        delete params.date;
        if (params.project) {
            if (params.project !== 'null') {
                params.ori_site = params.project;
            }
        }
        delete params.project;
        params.sideload = true;
        params.pass_end =
            moment().format('YYYY-MM-DD') + '_' + moment().add(7, 'day').format('YYYY-MM-DD');
        project = this.modelFor('projects.edit');
        params.ori_site = project.get('id');
        return this.store.query('orientation', params);
    },
});

export default ProjectsPassesRoute;
