/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/employer-sums/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerEmployerSumsRoute;

import Ember from 'ember';

TimetrackerEmployerSumsRoute = Ember.Route.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
            replace: true,
        },
        date: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var self;
        //params = @._super(params)
        self = this;
        //params.sideload = true
        //params.user='self' # get only own worktimes
        delete params.page;
        if (params.project === null) {
            delete params.project;
        }
        //        params.date =
        params.groupBy = 'employer';
        return this.Collector.cget('worktime', params, true);
    },
});

export default TimetrackerEmployerSumsRoute;
