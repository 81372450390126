/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAddIndexController;

import Ember from 'ember';

OrientationsAddIndexController = Ember.Controller.extend({
    //    needs: 'orientations/add'
    addController: Ember.inject.controller('orientations/add'),
    record: Ember.computed.alias('addController.record'),
    dialogs: Em.inject.service(),
    intl: Em.inject.service(),
    flags: Em.inject.service(),
    initapp: Ember.inject.service(),
    showButtonNext: Em.computed(
        'record.ori_site',
        'addController.users',
        'addController.users.length',
        'loading',
        'addController.userRecords.length',
        'addController.projectRecord',
        function () {
            if (
                this.get('addController.userRecords') &&
                this.get('addController.userRecords').length &&
                this.get('addController.projectRecord') &&
                this.get('addController.users').indexOf(null) < 0
            ) {
                return this.get('loading') === false;
            } else {
                return false;
            }
        },
    ),
    loading: false,
    skip_users: Em.A([]),
    /*
  overlapArr: Em.A([])

  overlap: (->
      self = @
      users = @get('addController.users')
      if users && @get('record.ori_site')
          @set("overlapArr", Em.A([]))
          users.forEach( (user)->
              orientations = self.store.query('orientation', {"ori_site": self.get('record.ori_site.id'), "ori_user": user})
              orientations.then( ->
                  if orientations.get('length') > 0
                      self.set('addController.disableLinks', true)
                      user = self.store.findRecord('user', user)
                      user.then(
                          self.get('overlapArr').pushObject(user)
                      )
              )
          )
  ).observes('addController.users', 'addController.model.record.ori_site')

  */
    actions: {
        projectChanged: function (project) {
            return this.get('record').set('ori_site', project);
        },
        updateUsers: function (users) {
            return this.set('addController.users', users.mapBy('id'));
        },
        activateCardRegister: function () {
            var productname;
            this.set('savingCardRegister', 'start');
            productname = 'cardregister';
            return this.get('Collector')
                .addProduct(productname)
                .then(() => {
                    this.get('initapp').clearInit();
                    this.set('savingCardRegister', 'done');
                    return this.get('dialogs')
                        .alert(this.get('intl').t('general.settings_refresh_needed'))
                        .then(function () {
                            return location.reload();
                        });
                });
        },
        usersChanged: function (users) {
            return this.set('addController.users', users.mapBy('id'));
        },
        userSelected: function (id) {
            var orientations;
            if (!this.get('record.ori_site')) {
                this.get('dialogs')
                    .alert(this.get('intl').t('orientations.select_project_first'))
                    .then(() => {
                        return this.set('addController.users', Em.A([]));
                    });
                return;
            }
            this.set('loading', true);
            orientations = this.store.query('orientation', {
                ori_site: this.get('record.ori_site.id'),
                ori_user: id,
            });
            return orientations.then((found) => {
                this.set('loading', false);
                if (found.get('length') > 0) {
                    return this.get('dialogs')
                        .alert(this.get('intl').t('orientations.already_orientated'))
                        .then(() => {
                            var filtered;
                            filtered = this.get('addController.users').reject(function (item) {
                                return item === id;
                            });
                            return this.set('addController.users', filtered);
                        });
                }
            });
        },
        userAlreadyAdded: function (user) {
            return this.get('dialogs').alert(this.get('intl').t('orientations.user_already_added'));
        },
        changeSelectedProject: function (record) {
            var orientations, users_oriented, users_oriented_obj;
            this.set('record.ori_site', record);
            this.set('addController.users', Em.A([]));
            //find users which have orientations to this project
            orientations = this.store.query('orientation', {
                ori_site: this.get('record.ori_site.id'),
                sideload: true,
            });
            users_oriented = [];
            users_oriented_obj = [];
            return orientations.then((oris) => {
                oris.forEach((ori) => {
                    users_oriented.push(ori.get('ori_user.id'));
                    return users_oriented_obj.push(ori.get('ori_user'));
                });
                this.set('skip_users_obj', users_oriented_obj);
                return this.set('skip_users', users_oriented);
            });
        },
    },
});

export default OrientationsAddIndexController;
