import Component from '@ember/component';
import EmberObject, { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';

/*
{{#workday-summary user=user day=day date=date addForms=forms as |table|}}
    {{table.edit}}
    {{table.add}}
{{/workday-summary}}
*/

export default Component.extend({
    store: service(),
    error: service(),
    Collector: service('collector-service'),
    ttapi: service(),

    needsForForms: EmberObject.create({
        worktime: [
            'worktime',
            'worktime@add',
            '!products.timetracker_clockcard||!userlevel=1?',
            'modulecontrol.worktimes',
        ],
        abcense: ['abcense', 'abcense@add', 'worktime', 'modulecontrol.worktimes'],
    }),

    permittedAddForms: computed('addForms', function () {
        if (!this.addForms) return [];
        return this.addForms.filter((form) => this.Collector.testNeeds(this.needsForForms[form]));
    }),

    popupData: computed(function () {
        let wtparams = {
            date: get(this, 'day'),
            user: get(this, 'user'),
            order: 'starttime,endtime',
            sideload: true,
        };

        let startdate = get(this, 'date').split('_')[0];
        let enddate = get(this, 'date').split('_')[1];

        let abparams = {
            startdate: startdate,
            enddate: enddate,
            order: 'starttime,endtime',
            user: get(this, 'user'),
            sideload: ['abcensetype.name'],
        };

        let promises = [];

        if (get(this, 'Collector').testNeedsOne('worktime')) {
            promises.push(
                new EmberPromise((resolve, reject) => {
                    get(this, 'store')
                        .query('worktime', wtparams)
                        .then(this._resolveData.bind(this, resolve))
                        .catch(this._rejectData.bind(this, reject));
                }),
            );
        }
        if (get(this, 'Collector').testNeedsOne('abcense')) {
            promises.push(
                new EmberPromise((resolve, reject) => {
                    get(this, 'ttapi')
                        ._get('/absence/split', abparams)
                        .then(this._resolveData.bind(this, resolve))
                        .catch(this._rejectData.bind(this, reject));
                }),
            );
        }
        return EmberPromise.all(promises)
            .then(this._returnData.bind(this))
            .catch(this._notifyError.bind(this));
    }),

    didReceiveAttrs: function () {
        assert("'day' must be given to workday-summary-comp", get(this, 'day'));
    },

    actions: {
        updateData() {
            this.notifyPropertyChange('popupData');
        },

        rowAdded() {
            if (this.close && this.autoclose) this.close();
        },
    },

    _resolveData(resolve, data) {
        resolve(data);
    },
    _rejectData(reject, reason) {
        reject(reason);
    },
    _returnData(data) {
        let arr = [];
        let worktimes = data[0];
        let absences = data[1];

        if (isPresent(worktimes)) {
            worktimes.forEach((item) => {
                arr.push(item);
            });
        }
        if (isPresent(absences)) {
            absences.filterBy('date', get(this, 'day')).forEach((item) => {
                arr.push(item);
            });
        }
        return arr;
    },
    _notifyError(reason) {
        get(this, 'error').notify(reason);
    },
});
