import Component from '@ember/component';
import { computed, set, get } from '@ember/object';

export default Component.extend({
    tagName: 'div',
    classNames: ['day-cell-bar'],
    startDateField: 'date',
    endDateField: 'enddate',
    colorString: computed('colorFields', function () {
        const row = get(this, 'cellRows.firstObject');
        let string = '';
        get(this, 'colorFields').forEach((field) => {
            string += get(row, field);
        });
        return string;
    }),

    init() {
        this._super(...arguments);
        if (!this.colorFields) {
            set(this, 'colorFields', ['user.id', 'id']);
        }
    },
    //classNameBindings: ['hasRows:day-empty'],
    //hasRows: notEmpty('rows')
});
