/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalIndexController;

import Ember from 'ember';

TimetrackerApprovalIndexController = Ember.Controller.extend({
    approvalController: Em.inject.controller('timetracker/approval'),
    model: Em.computed.alias('approvalController.model'),
    date: Em.computed.alias('approvalController.date'),
    dateinterval: Em.computed('date', function () {
        var dates, format;
        dates = this.get('date').split('_');
        format = moment.localeData().longDateFormat('L');
        return moment(dates[0]).format(format) + ' - ' + moment(dates[1]).format(format);
    }),
    sites: Ember.computed('model.[]', function () {
        var k, ret, site, sites, worktimes;
        worktimes = this.get('model');
        sites = {};
        worktimes.forEach(function (time) {
            var project_id;
            project_id = time.get('project.id');
            if (sites[project_id]) {
                return sites[project_id].amount++;
            } else {
                return (sites[project_id] = {
                    id: project_id,
                    amount: 1,
                    name: time.get('project.name'),
                });
            }
        });
        ret = Em.A([]);
        for (k in sites) {
            site = sites[k];
            ret.pushObject(site);
        }
        return ret;
    }),
});

export default TimetrackerApprovalIndexController;
