/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-card/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarCardComponent;

import Ember from 'ember';

CalendarCardComponent = Ember.Component.extend({
    didReceiveAttrs: function () {
        return this.setData();
    },
    setData: function () {
        var formatted_data, rows;
        formatted_data = this.get('formatted_data');
        // first_column_is_date = false
        if (formatted_data) {
            rows = formatted_data.filterBy('cal_date_value', this.get('day'));
            return this.set('currentDayData', rows);
        }
    },
});

export default CalendarCardComponent;
