import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { isArray } from '@ember/array';
import { htmlSafe } from '@ember/template';

export default Service.extend({
    intl: service(),
    notifications: service(),
    store: service(),

    toString(error) {
        // try to get message first from .message and if not found check also .error
        return this.toError(error).message
            ? this.toError(error).message
            : this.toError(error).error;
    },

    /**
     * Same as toString but async so this is able to add meta.hits info also to response string
     * return string as safeHtml
     * @param {*} error
     */
    async toStringAsyncHTML(error) {
        const errorObj = this.toError(error);

        await this.addPossibleHitsToMessage(errorObj);

        // try to get message first from .message and if not found check also .error
        const message = errorObj.message ? errorObj.message : errorObj.error;

        return htmlSafe(message.replace(/\n/g, '<br>'));
    },

    toError(error) {
        if (this._capiError(error)) return this._capiError(error);
        if (this._networkError(error)) return this._networkError(error);

        let message = JSON.stringify(error);
        if (get(error, 'payload.message')) message = get(error, 'payload.message');
        else if (error.message) message = error.message;

        return {
            type: 'unknown',
            error: error,
            message: this.tryToTranslateMessage(message),
            meta: null,
        };
    },

    /**
     * Use notifications to show user to error. Can be used directly in promise fail
     * EXAMPLE: promise.then(..., (reason) => get(this, "error").notify(reason)))
     * @param {*} error
     */
    async notify(error) {
        const message = await this.toStringAsyncHTML(error);
        get(this, 'notifications').error(message, { autoClear: true });
    },

    tryToTranslateMessage(message) {
        if (typeof message !== 'string') return message;
        const stripped_message = message.toLowerCase().replace(/ /g, '_');
        if (get(this, 'intl').exists('error.' + stripped_message))
            return get(this, 'intl').t('error.' + stripped_message);
        return message;
    },

    /**
     * FORMAT EXAMPLE
     *  if application adapter has normalized error it looks like this
     *  {
     *      message: "Request was rejected because user is not permitted to perform this operation."
     *      name: "Error"
     *      payload: {
     *          error: "overlap",
     *          error_msg: "two or more rows have overlapping dates"
     *          meta: {hits: "worktime", ids: Array(1)
     *      }
     *      stack: ....
     *  }
     *
     *  OR
     *  if application adapter has not normalized error it looks like this
     *  {
     *      errors: [
     *          {
     *              error: "overlap"
     *              errorMeta: {hits: "absence", ids: Array(1)}
     *              error_msg: "two or more rows have overlapping dates"
     *          }
     *      ]
     *      message: "Ember Data Request POST http://localhost:30001/abcenses returned a 403 .....
     *      name: "Error"
     */
    _capiError(error) {
        if (typeof error !== 'object') return null;
        let ret = null;
        let errors = 'errors';
        if (isArray(get(error, 'errors'))) {
            errors = 'errors.0';
        }
        if (get(error, 'payload.error')) {
            ret = {
                type: 'capi',
                error: get(error, 'payload.error'),
                message: get(error, 'payload.error_msg'),
                meta: get(error, 'payload.meta'),
            };
        } else if (get(error, errors + '.error')) {
            ret = {
                type: 'capi',
                error: get(error, errors + '.error'),
                message: get(error, errors + '.error_msg'),
                meta: get(error, errors + '.errorMeta'),
            };
        } else {
            return null;
        }

        switch (ret.error) {
            case 'overlap':
                if (ret.meta.hits == 'absence')
                    ret.message = get(this, 'intl').t('validation.absence_overlap');
                else if (ret.meta.hits == 'comp')
                    ret.message = get(this, 'intl').t('validation.comp_overlap');
                else ret.message = get(this, 'intl').t('validation.worktime_overlap');
                break;
            case 'not_only_row':
                ret.message = get(this, 'intl').t('validation.worktime_not_only_row');
                break;
            case 'not_found':
                ret.message = get(this, 'intl').t('validation.not_found.general');
                break;
            case 'validation_error':
                ret.message = this.intl.exists('validation.' + ret.message)
                    ? this.intl.t('validation.' + ret.message)
                    : ret.message;
                break;
            case 'form_field_exists':
                ret.message = get(this, 'intl').t('formeditor.field_exists');
                break;
        }

        return ret;
    },

    /**
     * FORMAT EXAMPLE
     *  {
     *      message: "The ajax operation was aborted"
     *      name: "Error"
     *      payload: null
     *      stack: ....
     */
    _networkError(error) {
        if (typeof error !== 'object') return null;
        if (error.message !== 'The ajax operation was aborted') return null;

        return {
            type: 'network',
            error: error.message,
            message: get(this, 'intl').t('validation.network_error'),
            meta: null,
        };
    },

    async addPossibleHitsToMessage(error) {
        if (!error.meta) return;

        if (error.meta.hits === 'absence') {
            const absences = await this.store.query('abcense', {
                id: error.meta.ids.join(','),
                sideload: true,
            });
            absences.forEach((item) => {
                error.message += `\n - ${get(item, 'abcensetype.name')} ${moment(get(item, 'startdate')).format('L')} - ${moment(get(item, 'enddate')).format('L')}`;
            });
        }

        if (error.meta.hits === 'worktime') {
            const worktimes = await this.store.query('worktime', {
                id: error.meta.ids.join(','),
                sideload: true,
            });
            worktimes.forEach((item) => {
                error.message += `\n - ${moment(get(item, 'date')).format('L')} ${get(item, 'project.name')} ${get(item, 'starttime')} - ${get(item, 'endtime')}`;
            });
        }

        if (error.meta.hits === 'comp') {
            const worktimes = await this.store.query('worktime', {
                id: error.meta.ids.join(','),
                sideload: true,
            });
            worktimes.forEach((item) => {
                error.message += `\n - ${get(item, 'project.name')} ${moment(get(item, 'date')).format('L')}`;
            });
        }
    },
});
