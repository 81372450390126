/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/saved.coffee.old
// Generated by CoffeeScript 2.6.1
var SavedController,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from '../classes/collector';

import configurations from '../config';

import pdf_mixin from '../mixins/pdf-export';

import $ from 'jquery';

import { isArray } from '@ember/array';

const { getOwner } = Ember;

SavedController = Ember.Controller.extend(pdf_mixin, {
    Collector: Em.inject.service('collector-service'),
    intl: Ember.inject.service(),
    queryParams: ['action'],
    action: '',
    form: Em.computed('model', function () {
        return this.get('model.constructor.modelName');
    }),
    emailSent: false,
    email_content: Em.computed(function () {
        return this.get('intl').t('saved.email.content.default', {
            sender:
                this.get('session.currentUser.firstname') +
                ' ' +
                this.get('session.currentUser.lastname'),
        });
    }),
    fields: Ember.computed('model', function () {
        var c, customization, customizations, fields, form, model, objects, self;
        self = this;
        form = this.get('model.constructor.modelName');
        if (!form) {
            objects = this.get('model.objects');
        } else {
            objects = this.Collector.fields(form);
        }
        if (!objects) {
            objects = this.get('objects');
        }
        model = this.get('model');
        if (!model) {
            Ember.run.next(this, () => {
                return this.transitionToRoute('index');
            });
        }
        fields = [];
        if (!objects) {
            return fields;
        }
        if (form) {
            customizations = configurations.savedCustomization;
            if (customizations && customizations[form]) {
                customization = customizations[form];
                c = this.get('Collector');
            }
        }
        $.each(objects, function (index, obj2) {
            var arr, json, obj, value;
            //copy to new real object, so we dont affect collector objects
            obj = JSON.parse(JSON.stringify(obj2));
            if (customization && customization[obj.ext]) {
                obj = c.customizeField(obj, customization[obj.ext]);
            }
            if (self.get('hideFields') && self.get('hideFields').indexOf(obj.ext) !== -1) {
                return;
            }
            obj.ext = index;
            //            obj.features.mandatory = self.Collector.isMandatory(obj, model)
            //            obj.features.hide = self.Collector.isHidden(obj, model)
            if (model.get(obj.ext)) {
                if (Array.isArray(model.get(obj.ext)) && model.get(obj.ext + '.length') < 1) {
                    return;
                }
                if (!(self.Collector.isHidden(obj, model) && obj.ext !== 'work_hours')) {
                    if (form === 'tr' || form === 'mvr') {
                        delete obj.name;
                    }
                    arr = (function () {
                        switch (obj.ext) {
                            case 'weather':
                                if (model.get('weather')) {
                                    if (Ember.typeOf(model.get('weather')) === 'string') {
                                        json = JSON.parse(model.get('weather'));
                                    } else {
                                        json = model.get('weather');
                                    }
                                    value = new Ember.String.htmlSafe(
                                        "<span class='weather-icon move move-" +
                                            json.weather +
                                            "'></span> " +
                                            json.temperature +
                                            '&deg;C, ' +
                                            json.windspeed +
                                            'm/s, ' +
                                            json.humidity +
                                            '% ' +
                                            self.get('intl').t('worksitediary.abbr_rel_hum') +
                                            ', ' +
                                            self.get('intl').t('worksitediary.abbr_dew_point') +
                                            ': ' +
                                            json.dew_point +
                                            '&deg;C',
                                    );
                                } else {
                                    value = '';
                                }
                                return {
                                    value: value,
                                };
                            default:
                                return {
                                    value: self.Collector.format(
                                        model.get(obj.ext),
                                        obj,
                                        self,
                                        model,
                                        false,
                                        form,
                                    ),
                                };
                        }
                    })();
                    if (!arr.title) {
                        arr.title = self.Collector.getFieldName(form, Em.Object.create(obj));
                    }
                    if (arr.value) {
                        return fields.push(arr);
                    }
                }
            }
        });
        return fields;
    }),
    link: Em.computed('model', 'afterSave', function () {
        var afterSave, form;
        afterSave = this.get('afterSave');
        if (afterSave) {
            if (typeof afterSave === 'object') {
                return afterSave.route;
            } else {
                return afterSave;
            }
        } else if (this.get('model')) {
            form = this.get('model.constructor.modelName').underscore();
            return form + 's.index';
        } else {
            return 'index';
        }
    }),
    addPage: Em.computed('model', function () {
        var routeName, routes;
        if (this.get('model')) {
            routes = getOwner(this).lookup('router:main')._routerMicrolib.recognizer.names;
            routeName = this.get('model.constructor.modelName') + 's.add';
            if (routes[routeName] != null) {
                return routeName;
            } else if (this.get('addPageLink')) {
                return this.get('addPageLink');
            }
        }
        return false;
    }),
    editRoute: Em.computed('model', function () {
        var routeName, routes;
        if (this.get('model')) {
            routes = getOwner(this).lookup('router:main')._routerMicrolib.recognizer.names;
            routeName = this.get('model.constructor.modelName') + 's.edit';
            if (routes[routeName] != null) {
                return routeName;
            } else if (this.get('editPageLink')) {
                return this.get('editPageLink');
            }
        }
        return false;
    }),
    linkParam: Em.computed('model', 'afterSave', function () {
        var afterSave;
        afterSave = this.get('afterSave');
        if (afterSave && typeof afterSave === 'object') {
            return afterSave.params;
        } else {
            return null;
        }
    }),
    showApprovalLinkSelection: Ember.computed(function () {
        var field, fields, found;
        fields = this.get('Collector').fields(this.get('form'));
        found = false;
        for (field in fields) {
            if (
                fields[field].type === 'status' &&
                !fields[field].features.readonly &&
                indexOf.call(fields[field].options, 'approved') >= 0
            ) {
                found = true;
            }
        }
        return found;
    }),
    getTtapiPdf: function (format = null, params) {
        var postParams, url;
        url = 'pdf/row';
        postParams = {
            form: this.get('form').underscore(),
            lang: moment.locale(),
            id: this.get('model.id'),
            filters: JSON.stringify({
                id: this.get('model.id'),
            }),
        };
        if (params.template) {
            postParams.template = Ember.get(params, 'template.id');
        }
        if (format) {
            postParams.type = format;
        }
        return this.get('Collector').ttapi({
            dataType: 'text',
            url: url,
            data: postParams,
        });
    },
    pdfExports: Ember.computed(function () {
        return [
            {
                id: 'export_pdf_full',
                name: this.get('intl').t('report.actions.export_pdf_full'),
            },
        ];
    }),
    actions: {
        addSame: function () {
            var addPage, c;
            c = this.get('Collector');
            c.copyRecord(this.get('model'));
            if (this.get('addPage')) {
                addPage = this.get('addPage');
                return this.transitionToRoute(addPage);
            }
        },
        send_email: function (recipient, title, content, params) {
            var c, columns, emails, export_fields, field, fields, form, name, shownColumns, titles;
            emails = isArray(recipient) ? recipient : recipient.split(',');
            if (!emails) {
                return this.set('email_error', this.get('intl').t('validation.errors.mandatory'));
            } else {
                emails.forEach((email) => {
                    if (
                        !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
                            email,
                        )
                    ) {
                        return this.set(
                            'email_error',
                            this.get('intl').t('validation.errors.email'),
                        );
                    }
                });
                c = this.get('Collector');
                this.set('loading', true);
                form = this.get('model.constructor.modelName');
                fields = c.fields(form);
                titles = [];
                columns = [];
                export_fields = [];
                if (this.get('currentTemplate')) {
                    shownColumns = this.get('currentTemplate.columns').split(',');
                } else {
                    shownColumns = [];
                }
                for (name in fields) {
                    field = fields[name];
                    if (shownColumns.length > 0 && shownColumns.indexOf(name) === -1) {
                        continue;
                    }
                    Ember.set(field, 'ext', name);
                    if (field.features && field.features.hide_column) {
                        continue;
                    }
                    titles.push(c.getTranslationForColumn(form, field.ext));
                    columns.push(field.ext);
                    export_fields.push(field);
                }
                return this.getTtapiPdf('base64', params).then((pdf) => {
                    return c
                        .ttapi({
                            method: 'post',
                            url: 'sendReportAsEmail',
                            data: {
                                to_address: emails,
                                email: pdf,
                                header: title,
                                content: content,
                                includeApproval: params.approvalLink,
                                form: this.get('form').underscore(),
                                rowids: [this.get('model.id')],
                            },
                        })
                        .then(() => {
                            this.set('loading', false);
                            return this.set('emailSent', true);
                        });
                });
            }
        },
        removeModal: function () {
            return this.set('action', '');
        },
    },
});

export default SavedController;
