import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    dialogs: service(),
    intl: service(),
    evented: service(),

    showActionList: computed('record', 'showHistory', function () {
        if (get(this, 'showHistory')) return false;
        if (get(this, 'record') && !get(this, 'record.isNew')) return true;
        return false;
    }),

    actions: {
        async remove() {
            if (!(await this.dialogs.confirm(this.intl.t('general.remove_row')))) return;
            set(this, 'loading', true);
            set(this, 'error', null);
            try {
                await this.record.destroyRecord();
            } catch (reason) {
                set(this, 'loading', false);
                set(this, 'error', reason);
                return;
            }
            this.evented.storeEvent('delete', this.form, this.record);

            if (this.rowEdited) this.rowEdited();
            if (this.close) this.close();
        },

        closeClicked() {
            if (this.showHistory) set(this, 'showHistory', false);
            else if (this.close) this.close();
        },
    },
});
