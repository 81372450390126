/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/own-orientation/route.coffee.old
// Generated by CoffeeScript 2.6.1
var signaturesRoute;

import Ember from 'ember';

import addRoute from '../add/route';

import config from '../../../config/environment';

signaturesRoute = addRoute.extend({
    session: Em.inject.service(),
    checkAll: true,
    model: function (params) {
        var project;
        project = this.get('store').findRecord('project', params.id, {
            reload: true,
        });
        return project.then((proj) => {
            return Ember.RSVP.hash({
                checkbox_groups: this.checkbox_groups(),
                record: this.get('store').createRecord('orientation', {
                    ori_site: proj,
                    ori_place: proj.get('city'),
                    ori_date: moment().format('YYYY-MM-DD'),
                    ori_user: this.get('session.currentUser'),
                    pass_start: moment().format('YYYY-MM-DD'),
                    pass_end: proj.get('proj_end'),
                    special_warning: proj.get('special_warnings'),
                    file: proj.get('file'),
                }),
            });
        });
    },
    toDataUrl: function (url, callback) {
        var img_url, xhr;
        img_url = config.APP.COLLECTOR_API_URL + '/' + url;
        xhr = new XMLHttpRequest();
        if (window.XMLHttpRequest) {
            xhr = new XMLHttpRequest();
        } else {
            xhr = new ActiveXObject('Microsoft.XMLHTTP');
        }
        xhr.onload = function () {
            var reader;
            reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', img_url);
        xhr.responseType = 'blob';
        return xhr.send();
    },
    actions: {
        save: function (row, model) {
            var controller, i, item, j, key, len, len1, options, ref, ref1, tmp;
            controller = this.get('controller');
            controller.send('validate');
            if (controller.get('has_errors')) {
                return;
            }
            ref = model['checkbox_groups'];
            for (i = 0, len = ref.length; i < len; i++) {
                item = ref[i];
                tmp = [];
                ref1 = item.options;
                for (j = 0, len1 = ref1.length; j < len1; j++) {
                    options = ref1[j];
                    tmp.push(options.key);
                }
                key = item.key;
                row.set(key, tmp);
            }
            return row.save().then(() => {
                return this.transitionTo('timetracker.worktime-list');
            });
        },
    },
});

export default signaturesRoute;
