/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/forms/information/route.coffee.old
// Generated by CoffeeScript 2.6.1
var FormsInformationRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

FormsInformationRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    intl: Em.inject.service(),
    model: function () {
        var arr, form, forms, name;
        forms = this.Collector.forms();
        arr = [];
        for (name in forms) {
            form = forms[name];
            form = Em.Object.create(form);
            form.set('ext', name);
            if (form.get('name') == null) {
                form.set('name', this.get('intl').t('menu.' + name + 's'));
            }
            arr.push(form);
        }
        return arr;
    },
    actions: {
        toggleShowFields: function (form) {
            return form.toggleProperty('showFields');
        },
    },
});

export default FormsInformationRoute;
