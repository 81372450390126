import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default Route.extend({
    store: service(),

    model() {
        const store = get(this, 'store');
        const customer = this.modelFor('admintool.customer');
        return hash({
            customer: customer,
            customercampaign: store.query('customercampaign', {
                customer: get(customer, 'id'),
                row_info_status: 'all',
                sideload: true,
            }),
            fixedprice: store.query('fixedprice', {
                customer: get(customer, 'id'),
                row_info_status: 'all',
                sideload: true,
            }),
            fixedlimit: store.query('fixedlimit', {
                customer: get(customer, 'id'),
                row_info_status: 'all',
                sideload: true,
            }),
            rcbill: store.query('rcbill', {
                customer: get(customer, 'id'),
                row_info_status: 'all',
                sideload: true,
            }),
        });
    },

    setupController(controller, model) {
        set(controller, 'history', model);
    },
});
