/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetySavedController,
    indexOf = [].indexOf;

import Ember from 'ember';

import pdf_measurement_mixin from '../../../mixins/sitesafety-export';

import formatted_pdf_measurement_mixin from '../../../mixins/sitesafety-formatted-export';

SiteSafetySavedController = Ember.Controller.extend(
    pdf_measurement_mixin,
    formatted_pdf_measurement_mixin,
    {
        Collector: Ember.inject.service('collector-service'),
        intl: Ember.inject.service(),
        queryParams: ['email'],
        email: false,
        form: Ember.computed('model', function () {
            return this.get('model.constructor.modelName');
        }),
        fields: Ember.computed('model', function () {
            var fields;
            fields = this.get('Collector').fieldArray(this.get('model.constructor.modelName'));
            return fields.reject(function (item) {
                item.ext = item.name;
                if (item.name.slice(-6) === '_minus') {
                    return true;
                }
            });
        }),
        afterSave: Ember.computed('model', function () {
            if (['tr', 'mvr'].includes(this.get('model.constructor.modelName'))) {
                return 'site_safety.report.' + this.get('model.constructor.modelName') + '.graphs';
            }
            return [
                'site_safety.measurement.graphs',
                this.get('model.constructor.modelName').substring(12).toLowerCase(),
            ];
        }),
        showApprovalLinkSelection: Ember.computed(function () {
            var field, fields, found;
            fields = this.get('fields');
            found = false;
            for (field in fields) {
                if (
                    fields[field].type === 'status' &&
                    !fields[field].features.readonly &&
                    indexOf.call(fields[field].options, 'approved') >= 0
                ) {
                    found = true;
                }
            }
            return found;
        }),
        actions: {
            //        setEmail: (value) ->
            //            @get('store').findRecord('user', value.get('id'), {reload: true}).then (user) =>
            //                user.validate(['email']).then =>
            //                    error = user.get('errors').errorsFor('email')
            //                    email = user.get('email')
            //                    if error.length > 0
            //                        @set 'email_error', error
            //                    else if email
            //                        @set 'email', email
            send_email: function (recipient, title, content, params) {
                var fields;
                fields = this.get('Collector').fields(this.get('model.constructor.modelName'));
                this.set('loading', true);
                return this.createMeasurementPdf(fields, [this.get('model')], 'base64').then(
                    (pdf) => {
                        var c, intl;
                        c = this.get('Collector');
                        intl = this.get('intl');
                        return c
                            .ttapi({
                                method: 'post',
                                url: 'sendReportAsEmail',
                                data: {
                                    to_address: recipient,
                                    email: pdf,
                                    header: title,
                                    content: content,
                                    lang: intl.primaryLocale,
                                    includeApproval: params.approvalLink,
                                    form: this.get('form'),
                                    form_name: 'tr',
                                    rowids: [this.get('model.id')],
                                },
                            })
                            .then((ret) => {
                                this.set('emailSent', true);
                                return this.set('loading', false);
                            })
                            .catch((e) => {
                                this.set('email', false);
                                this.set('loading', false);
                                throw new Ember.Error(e);
                            });
                    },
                );
            },
            removeModal: function () {
                return this.set('email', false);
            },
        },
    },
);

export default SiteSafetySavedController;
