/* eslint-disable ember/no-side-effects */
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import settingsJSON from './settings';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import { Promise } from 'rsvp';
import { htmlSafe } from '@ember/string';

export default Component.extend({
    customerSettings: service(),
    intl: service(),
    ttapi: service(),
    session: service(),
    store: service(),
    notifications: service(),
    router: service(),
    autopauseChecker: service(),
    isHelpContainerVisible: false,
    shouldHideHelp: computed('worktimegroup', 'currentSettingsPage', function () {
        return ['notifications.sending'].includes(this.currentSettingsPage);
    }),
    settingGroups: computed('worktimegroup', function () {
        let removedSettings = [];
        let filteredGroups = [];
        let filteredSettings = [];
        let currentSettings = [];
        let mainGroups = [
            {
                name: 'general',
                subsettings: ['settings'],
                icon: 'Settings',
                translation: get(this, 'intl').t('settings.general-settings'),
            },
            {
                name: 'notifications',
                subsettings: ['settings', 'sending'],
                icon: 'Comments',
                translation: get(this, 'intl').t('settings.notifications'),
            },
            {
                name: 'salaries',
                subsettings: [
                    'settings',
                    'calculations',
                    'salarytypes',
                    'salaryperiods',
                    /*"worktimebanks",*/ 'overtime',
                    'saldo_banks',
                ],
                icon: 'Money',
                translation: get(this, 'intl').t('settings.salaries'),
            },
            {
                name: 'absences',
                subsettings: ['settings'],
                icon: 'Cup',
                translation: get(this, 'intl').t('settings.absences'),
            },
            {
                name: 'project-management',
                subsettings: [
                    'settings',
                    'customers',
                    'projects',
                    'materials',
                    'measurements',
                    'orientations',
                    'construction_notices',
                    'groups',
                    'tasks',
                    'worktasks',
                ],
                icon: 'Route',
                translation: get(this, 'intl').t('settings.project-management'),
            },
            {
                name: 'worktimes',
                subsettings: ['settings', 'limitations'],
                icon: 'Time',
                translation: get(this, 'intl').t('settings.worktimes'),
            },
            {
                name: 'drivinglog',
                subsettings: ['settings'],
                icon: 'Mileage',
                translation: get(this, 'intl').t('settings.drivinglog'),
                needs: ['products.drivinglog'],
            },
            {
                name: 'companyProfile',
                subsettings: ['info'],
                icon: 'AdministratorFilled',
                translation: get(this, 'intl').t('settings.company-profile'),
                needs: ['userlevel=5'],
            },
        ];
        if (this.worktimegroup) {
            filteredSettings = settingsJSON.settings.filterBy('groupEditable');
            //console.log(filteredSettings);
        } else {
            filteredSettings = settingsJSON.settings;
        }
        for (var group of mainGroups) {
            if (group.needs && !this.Collector.testNeeds(group.needs)) continue;
            var addGroup = {
                name: group.name,
                subsettings: [],
                icon: group.icon,
                translation: group.translation,
                subsettingTranslations: [],
            };
            for (var subgroupIndex of group.subsettings) {
                currentSettings = filteredSettings.filterBy(
                    'category',
                    group.name + '.' + subgroupIndex,
                );

                for (var setting of currentSettings) {
                    if (setting.needs && !this.Collector.testNeeds(setting.needs)) {
                        removedSettings.pushObject(setting);
                    }
                }

                let difference = currentSettings.filter((x) => !removedSettings.includes(x));

                if (
                    difference.length > 0 &&
                    (addGroup.subsettings.indexOf(subgroupIndex) == -1 ||
                        !addGroup.subsettings.indexOf(subgroupIndex))
                )
                    addGroup.subsettings.pushObject(subgroupIndex);
            }

            if (addGroup.subsettings.length > 0) {
                filteredGroups.pushObject(addGroup);
            }
        }

        for (var maingroup of filteredGroups) {
            maingroup['subsettingTranslations'] = [];
            for (var subsetting of maingroup.subsettings) {
                maingroup['subsettingTranslations'].pushObject({
                    name: subsetting,
                    translation: get(this, 'intl').t('settings.' + subsetting),
                });
            }
            maingroup['subsettingTranslations'] =
                maingroup['subsettingTranslations'].sortBy('translation');
        }
        //console.log(filteredGroups);
        return filteredGroups;
    }),
    settingsPageSettings: computed('currentSettingsPage', 'worktimegroup', function () {
        if (this.currentSettingsPage) {
            let allSettingsForThisPage = settingsJSON.settings.filterBy(
                'category',
                this.currentSettingsPage,
            );
            if (this.worktimegroup)
                allSettingsForThisPage = allSettingsForThisPage.filterBy('groupEditable');

            let settings = allSettingsForThisPage.filter((setting) => {
                var isActive = this.checkIfSettingIsOn(setting);
                set(setting, 'active', isActive);
                if (this.Collector.testNeeds(setting.needs)) {
                    if (
                        setting.disable &&
                        setting.disable.length > 0 &&
                        !this.isThisSettingAvailable(setting)
                    )
                        set(setting, 'disabled', true);
                    else set(setting, 'disabled', false);
                    set(setting, 'editingDisabled', this.isEditingDisabled(setting));
                    return true;
                }
            });
            return settings;
        }
        return [];
    }),
    translatedSettings: computed(function () {
        let retArr = [];
        let cat = [];
        for (var setting of settingsJSON.settings) {
            if (
                setting.category &&
                setting.category != 'no-category' &&
                this.Collector.testNeeds(setting.needs)
            ) {
                cat = setting.category.split('.');
                retArr.pushObject({
                    category: setting.category,
                    title: setting.title,
                    translation:
                        this.intl.t('settings.' + cat[0]) +
                        ' > ' +
                        this.intl.t('settings.' + cat[1]) +
                        ' > ' +
                        this.intl.t(setting.title),
                });
            }
        }
        return retArr;
    }),
    neededHelpTexts: computed('settingsPageSettings', function () {
        return this.settingsPageSettings.mapBy('title');
    }),

    worktimeGroupsOn: computed('settingsPageSettings', function () {
        return this.Collector.testNeedsOne('worktimegroup');
    }),

    init() {
        this._super(...arguments);
        this.disableOnlyText = {
            key: '!products.mini_entry',
            text: htmlSafe(this.intl.t('settings.mini_entry_disable_text')),
        }; // +" <a href=''>"+this.intl.t("settings.mini_entry_disable_link")+"</a>"
        if (this.queryParams) {
            set(this, 'currentSettingsPage', this.queryParams);
            this.send('gotoSetting', this.queryParams);
        } else {
            this.send(
                'gotoSetting',
                this.settingGroups[0]['name'] + '.' + this.settingGroups[0]['subsettings'][0],
            );
            set(this, 'openSettings', [this.settingGroups[0]['name']]);
        }
    },
    didUpdateAttrs() {
        if (this.queryParams) {
            set(this, 'currentSettingsPage', this.queryParams);
        }
    },

    actions: {
        gotoSetting(setting) {
            let arr = setting.split('.');
            set(this, 'openSettings', [arr[0]]);
            set(this, 'currentSettingsPage', setting);
        },
        toggleHelpContainerVisibility(value) {
            if (value === 'showHelpContainer') {
                set(this, 'isHelpContainerVisible', true);
            } else if (value === 'hideHelpContainer') {
                set(this, 'isHelpContainerVisible', false);
            }
        },
        refreshNeeded() {
            this.refreshSettings();
        },
        saveCalled() {
            this.showSavedNotice();
            this.refreshSettings();
        },
        async toggleActive(setting) {
            let active = false;

            set(setting, 'loading', true);
            var products, value;
            if (setting.active) {
                active = false;
            } else {
                active = true;
            }
            set(setting, 'active', active);
            if (get(setting, 'productsOn')) {
                if (get(setting, 'productsOn').includes('products.midweek_holiday_pay'))
                    this.handleMidweekHolidayPaySalarytype(setting.active);
                products = active ? get(setting, 'productsOn') : get(setting, 'productsOff');
                let promises = await this.setSetting(
                    setting,
                    EmberObject.create({ products: products }),
                    true,
                );
                await Promise.all(promises);
            } else {
                value = active ? 'on' : 'off';
                if (get(setting, 'secondary_setting') && value === 'off') {
                    await get(this, 'customerSettings').setSetting(
                        get(setting, 'secondary_setting'),
                        value,
                    );
                }
                await get(this, 'customerSettings').setSetting(
                    get(setting, 'name'),
                    value,
                    get(this, 'worktimegroup'),
                );
            }
            // basic support for setting multiple secondary products on/off
            if (get(setting, 'secondary_setting_on') && active) {
                await this.Collector.setProducts(get(setting, 'secondary_setting_on'));
            }
            await this.refreshSettings();

            // Call automatic salarytypes if saldo setting is toggled to create/remove saldo absences for worktimegroups
            if (
                get(setting, 'productsOn')?.includes('products.saldo') ||
                get(setting, 'productsOff')?.includes('!products.saldo')
            )
                this.callAutomaticSalarytypes();

            // When enabling autopause setting for worktimegroup, sets old pause field to 0.
            get(setting, 'productsOn')?.includes('products.autopause')
                ? this.autopauseChecker.checkAndSetAutopause()
                : null;

            this.notifyPropertyChange('settingsPageSettings');
            this.showSavedNotice();
            set(setting, 'loading', false);
        },

        async setSetting(setting, option, donotRefreshAgain) {
            return this.setSetting(setting, option, donotRefreshAgain);
        },

        searchSettings(term) {
            let results = [];
            if (!term) set(this, 'searchResults', null);
            else if (typeof term == 'string') {
                let matches = this.translatedSettings.filter((setting) => {
                    if (setting.translation.toLowerCase().indexOf(term.toLowerCase()) != -1) {
                        return true;
                    }
                });
                for (var match of matches) {
                    results.pushObject({
                        category: match.category,
                        translation: match.translation,
                    });
                }
                if (results.length == 0) set(this, 'searchResults', 'noresults');
                else set(this, 'searchResults', results);
            }
        },
        setOpenSettingsPage(name) {
            //this.setQParams(name);
            set(this, 'currentSettingsPage', name);
        },
        async worktimegroupChanged(group) {
            set(this, 'settingsloading', true);
            if (group && group != 'default') {
                set(this, 'worktimegroup', group);
                let worktimegroupsettings = await get(this, 'ttapi')._get(
                    '/settings?worktimegroup=' + group,
                );
                //await this.loadForms(group);
                set(this, 'worktimegroupCustomerSettings', worktimegroupsettings);
            } else {
                set(this, 'worktimegroup', null);
                set(this, 'worktimegroupforms', null);
                set(this, 'worktimegroupCustomerSettings', null);
                await this.refreshSettings();
                return set(this, 'settingsloading', false);
            }
            await this.refreshSettings();
            this.notifyPropertyChange('settingsPageSettings');
            set(this, 'settingsloading', false);
            this.send(
                'gotoSetting',
                this.settingGroups[0]['name'] + '.' + this.settingGroups[0]['subsettings'][0],
            );
            set(this, 'openSettings', [this.settingGroups[0]['name']]);
        },
        setCustomerSetting(setting, value, worktimegroup) {
            get(this, 'customerSettings').setSetting(setting, value, worktimegroup);
        },
    },

    checkIfSettingIsOn(setting) {
        if (get(setting, 'dummySetting')) return true;
        if (get(setting, 'name')) {
            return this.isCustomerSettingOn(setting.name);
        } else if (setting.productsOn) {
            return this.testNeeds(setting.productsOn);
        } else if (setting.options) {
            let options = [];
            //Loop through options and mark options that have all needs satisfied as active
            for (var option of get(setting, 'options')) {
                option = EmberObject.create(option);
                set(option, 'active', this.testNeeds(get(option, 'products')));
                options.push(option);
            }

            set(setting, 'options', options);
            return true;
        } else if (setting.needs) return this.testNeeds(setting.needs);
    },
    isThisSettingAvailable(setting) {
        let available = true;
        if (setting.disable) {
            for (var disable of setting.disable) {
                if (!this.Collector.testNeeds([disable.key])) {
                    available = false;
                    break;
                }
            }
        }
        return available;
    },

    isCustomerSettingOn(settingName) {
        if (get(this, 'worktimegroupCustomerSettings')) {
            let setting = get(this, 'worktimegroupCustomerSettings').findBy('key', settingName);
            if (!setting) return false;
            if (!setting.worktimegroups) return false;
            return true;
        }
        let active = false;
        try {
            active = JSON.parse(get(this, 'customerSettings').checkSetting(settingName));
        } catch {
            active = !!get(this, 'customerSettings').checkSetting(settingName);
        }
        return active;
    },
    testNeeds(needs) {
        if (get(this, 'worktimegroupforms')) {
            return this.customTestNeeds(needs);
        } else {
            return get(this, 'Collector').testNeeds(needs);
        }
    },
    async loadForms(worktimegroup) {
        let forms = await get(this, 'Collector').ajax({
            url: 'forms?user.worktimegroup=' + worktimegroup,
        });
        set(this, 'worktimegroupforms', forms);
    },
    showSavedNotice() {
        get(this, 'notifications').success(get(this, 'intl').t('general.saved'), {
            autoClear: true,
        });
    },
    async setSetting(setting, option, donotRefreshAgain) {
        set(setting, 'loading', true);
        if (setting.options) {
            for (var opt of setting.options) {
                set(opt, 'active', false);
            }
            set(option, 'active', true);
        }
        let is_addition_type = false;
        if (setting.addition && get(this, 'worktimegroup')) is_addition_type = true;
        else is_addition_type = false;
        let promises = get(this, 'Collector').setProducts(
            option.products,
            get(this, 'worktimegroup'),
            true,
            is_addition_type,
        );

        if (!donotRefreshAgain) {
            await Promise.all(promises);
            await this.refreshSettings();
            this.notifyPropertyChange('settingsPageSettings');
            this.showSavedNotice();
            set(setting, 'loading', false);
        } else {
            return promises;
        }
    },
    customTestNeeds(needs) {
        let forms = get(this, 'worktimegroupforms');
        let allok = true;
        let negation = false;
        let isok = false;
        needs.forEach((need) => {
            negation = false;
            if (need.startsWith('!')) {
                need = need.substring(1);
                negation = true;
            }
            isok = true;
            if (need.startsWith('products.')) {
                if (forms.updater.products.indexOf(need.substring(9)) == -1) isok = false;
            } else {
                if (!get(this, 'Collector').testNeeds([need])) isok = false;
            }
            if (negation) isok = !isok;
            if (isok === false) allok = false;
        });
        return allok;
    },
    async refreshSettings() {
        await this.clearMemcache();
        if (this.worktimegroup) {
            let worktimegroupsettings = await get(this, 'ttapi')._get(
                '/settings?worktimegroup=' + this.worktimegroup,
            );
            set(this, 'worktimegroupCustomerSettings', worktimegroupsettings);
            await this.loadForms(this.worktimegroup);
        }
        return await this.refreshAll(this.worktimegroup);
        //this.notifyPropertyChange('settingsPageSettings');
    },
    async clearMemcache() {
        await this.Collector.ajax({
            url: 'system/clear_memcache?partnerid=' + get(this, 'session.currentUser.partnerid'),
        });
    },
    async handleMidweekHolidayPaySalarytype(active) {
        let salarytype = await this.store.query('salarytype', { control: '_midweek_holiday_pay' });
        salarytype = get(salarytype, 'firstObject');
        if (active && salarytype) return; //we are turning setting on but we also already have the salary type, so do nothing
        //if we are activating the setting, create the salarytype
        if (!salarytype && active) {
            this.store
                .createRecord('salarytype', {
                    name: get(this, 'intl').t('worktime.midweek_holiday_pay'),
                    control: '_midweek_holiday_pay',
                    type: 'product',
                    inuse: true,
                })
                .save();
        } else if (salarytype && !active) salarytype.destroyRecord(); //if deactivating destroy it..

        // If salarytypes_v2 in use handle Mid week holiday pay salarytype by calling ttapi/api/salary/automaticSalarytypes
        if (!this.Collector.testNeeds(['products.salarytype_v2'])) return;
        this.ttapi.post('api/salary/automaticSalarytypes');
    },

    callAutomaticSalarytypes() {
        if (!this.Collector.testNeeds(['products.salarytype_v2'])) return;
        this.ttapi.post('api/salary/automaticSalarytypes');
    },

    isEditingDisabled(setting) {
        let disabled = false;
        if (setting.editingDisabledRules) {
            for (var rule of setting.editingDisabledRules) {
                if (!this.Collector.testNeeds([rule.key])) {
                    disabled = true;
                    break;
                }
            }
        }
        return disabled;
    },
});
