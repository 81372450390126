/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/general-report.coffee.old
// Generated by CoffeeScript 2.6.1
var GeneralReportController,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from '../classes/collector';

import reportmixin from '../mixins/general-report';

import initController from '../mixins/init-controller';

GeneralReportController = Ember.Controller.extend(reportmixin, initController, {
    application: Ember.inject.controller(),
    intl: Ember.inject.service(),
    filterMode: false,
    form: Ember.computed.alias('model.firstObject.constructor.modelName'),
    order: Ember.computed.alias('settings.sortByColumn'),
    row_info_status: Ember.computed.alias('settings.showStatus'),
    settings: Em.Object.create,
    setFilters: function () {
        if (this.get('settings')) {
            this.set('settings.filters', this.get('filters'));
            return this.set('settings.limit', this.get('limit'));
        } else {
            return this.set(
                'settings',
                Em.Object.create({
                    filters: this.get('filters'),
                }),
            );
        }
    },
    init_functions: function () {
        if (this.get('settings')) {
            this.set('settings.defaultOrder', this.get('settings.sortByColumn'));
        }
        this.setFilters();
        return this.getFilterData();
    },
    page: 1,
    limit: 25,
    getFilterData: function () {
        var blackListFieldNames,
            c,
            fields,
            filterArr,
            filters,
            form,
            intl,
            queryParams,
            self,
            whitelistMores;
        self = this;
        if (!this.get('filters')) {
            return;
        }
        filters = this.get('filters');
        c = this.get('Collector');
        intl = this.get('intl');
        form = this.get('form');
        if (c.formExists(form)) {
            fields = c.fieldArray(form);
            queryParams = this.get('queryParams');
            filterArr = filters.mapBy('field');
            whitelistMores = {
                user: ['worktimegroup', 'employer', 'group'],
                project: ['owner'],
            };
            blackListFieldNames = [
                '*.superior',
                '*.project_owner',
                'worktime.project',
                'orientation.pass_start',
                'orientation.pass_end',
                'orientation.ori_date',
                'presence.date',
                'diary.event',
            ];
            fields.forEach((field) => {
                var ref, ref1, ref2, ref3;
                if (
                    ((ref = field.type) === 'date' ||
                        ref === 'database' ||
                        ref === 'databasearray' ||
                        ref === 'multiselect' ||
                        ref === 'option_values' ||
                        ref === 'dropdown_options' ||
                        ref === 'dropdown_values' ||
                        ref === 'dropdown_userlevel' ||
                        ref === 'checkbox') &&
                    ((ref1 = field.name), indexOf.call(filterArr, ref1) < 0)
                ) {
                    if (this.get('hideMoreFilters') && !queryParams.includes(field.name)) {
                        return;
                    }
                    if (
                        ((ref2 = field.form + '.' + field.name),
                        indexOf.call(blackListFieldNames, ref2) >= 0)
                    ) {
                        return;
                    }
                    if (((ref3 = field.options) != null ? ref3.form : void 0) === 'trnotice') {
                        return;
                    }
                    filters.push(
                        Em.Object.create({
                            field: field.name,
                            extra: true,
                        }),
                    );
                }
                if (
                    field.type === 'database' &&
                    Ember.isPresent(whitelistMores[field.options.form])
                ) {
                    if (this.get('hideMoreFilters') && !queryParams.includes(field.name)) {
                        return;
                    }
                    return whitelistMores[field.options.form].forEach((f) => {
                        var ref4, ref5, ref6;
                        // Check that
                        // - Other forms field is not in filters already
                        // - Field is not in filters already
                        // - Check that this form doesn't have the "subfield", the field that we are trying to filter by from other form
                        // - That the original field exists on this form checked from field options
                        if (
                            ((ref4 = field.options.form + '-' + f),
                            indexOf.call(filterArr, ref4) < 0) &&
                            indexOf.call(filterArr, f) < 0 &&
                            !c.fieldExists(form, f) &&
                            c.fieldExists(form, field.options.form)
                        ) {
                            if (
                                ((ref5 = '*.' + field.name),
                                indexOf.call(blackListFieldNames, ref5) >= 0)
                            ) {
                                return;
                            }
                            if (
                                ((ref6 = field.form + '.' + field.name),
                                indexOf.call(blackListFieldNames, ref6) >= 0)
                            ) {
                                return;
                            }
                            return filters.push(
                                Em.Object.create({
                                    field: f,
                                    extra: true,
                                    form: field.options.form,
                                    subform: true,
                                }),
                            );
                        }
                    });
                }
            });
        }
        return filters.forEach(function (item, index) {
            var arr, field, ref;
            if (item.type === 'searchbox') {
                Em.defineProperty(
                    self,
                    item.get('bindto'),
                    Em.computed.alias('filters.' + index + '.tmpvalue'),
                );
                return;
            }
            //if item.type == 'groupByFilter'
            //    Em.defineProperty(self, "_" + item.get("field"), Em.computed.alias("filters."+index+".value"))
            if (item.get('field').includes('-')) {
                arr = item.get('field').split('-');
                item.set('field', arr[1]);
                item.set('form', arr[0]);
                item.set('subform', true);
            }
            form = self.get('form');
            if (item.get('form')) {
                form = item.get('form');
            } else {
                item.set('form', form);
            }
            field = collector.getFormObject(form + '.' + item.get('field'));
            if (!item.get('type')) {
                item.set('type', field.type);
            }
            if (!field) {
                return;
            }
            if (field.features.hide_filter) {
                item.set('hide_filter', true);
            }
            if (!item.get('prompt') && field.options) {
                if (field.name) {
                    item.set(
                        'prompt',
                        intl.t('general.select_with_fieldname') +
                            ' ' +
                            field.name.toLowerCase() +
                            '...',
                    );
                } else if (field.options.form) {
                    item.set('prompt', 'general.select.' + field.options.form);
                }
            }
            // Find contents for dropdown
            //            if field.type == 'database' or item.get('type') == 'database'
            //                item.set('data', self.Collector.findFormRelations(form, item.get('field')))
            if (
                ((ref = field.type) === 'dropdown_options' ||
                    ref === 'dropdown_values' ||
                    ref === 'dropdown_userlevel') &&
                field.options
            ) {
                item.set('options', field.options);
            }
            if (field.type === 'date') {
                item.set('type', 'date');
                if (!item.get('start')) {
                    item.set('start', moment().startOf('month').format('YYYY-MM-DD'));
                }
                if (!item.get('end')) {
                    item.set('end', moment().endOf('month').format('YYYY-MM-DD'));
                }
                item.set('value', self.get(item.get('field')));
            }
            if (field.type === 'status') {
                item.set('value', self.get('status'));
            }
            if (Ember.isPresent(field.features.reload)) {
                item.set('reload', field.features.reload);
            }
            if (Ember.isPresent(item.get('subform'))) {
                return Em.defineProperty(
                    self,
                    item.get('form') + '-' + item.get('field'),
                    Em.computed.alias('filters.' + index + '.value'),
                );
            } else {
                return Em.defineProperty(
                    self,
                    item.get('field'),
                    Em.computed.alias('filters.' + index + '.value'),
                );
            }
        });
    },
    dates: Ember.computed('model.content', function () {
        var dates, item, k, model, ret;
        if (this.get('sortByDates')) {
            model = this.get('model').sortBy('date');
            dates = Em.A({});
            model.forEach(function (item) {
                var date;
                date = item.get('date');
                if (dates[date]) {
                    return dates[date].rows.pushObject(item);
                } else {
                    return (dates[date] = {
                        date: item.get('date'),
                        rows: [item],
                    });
                }
            });
            ret = Em.A([]);
            for (k in dates) {
                item = dates[k];
                ret.pushObject(item);
            }
            return ret;
        }
    }),
    setAdditionalParamsForModel: function (field, value) {
        var additionalParamsForModel, foundItem, moreArr, thisFilter;
        if (!field) {
            return;
        }
        if (indexOf.call(this.get('queryParams'), field) >= 0) {
            return;
        }
        // if this is special-field do not use it in additional params
        thisFilter = this.get('filters').filterBy('field', field);
        if (thisFilter.get('firstObject.bindto')) {
            return;
        }
        additionalParamsForModel = Em.A([]);
        if (this.get('more')) {
            this.get('more')
                .split('|')
                .forEach(function (param) {
                    var splitted;
                    splitted = param.split(':');
                    return additionalParamsForModel.pushObject({
                        field: splitted[0],
                        value: splitted[1],
                    });
                });
        }
        foundItem = additionalParamsForModel.findBy('field', field);
        if (!value) {
            if (foundItem) {
                additionalParamsForModel.removeObject(foundItem);
            }
        } else {
            if (value.get != null && value.get('id')) {
                value = value.get('id');
            }
            if (foundItem) {
                foundItem.value = value;
            } else {
                additionalParamsForModel.pushObject({
                    field: field,
                    value: value,
                });
            }
        }
        moreArr = [];
        additionalParamsForModel.forEach(function (item) {
            return moreArr.push(item.field + ':' + item.value);
        });
        if (moreArr.length > 0) {
            return this.set('more', moreArr.join('|'));
        } else {
            return this.set('more', void 0);
        }
    },
    actions: {
        filterChanged: function (value, fieldName) {
            var filter, obj, sideload_field;
            if (fieldName === 'groupBy') {
                this.set('groupBy', value);
            }
            this.setAdditionalParamsForModel(fieldName, value);
            sideload_field = this.get('filters').filter((filter) => {
                if (filter.get('field') === fieldName && filter.get('sideload')) {
                    return true;
                }
            });
            if (sideload_field.length > 0) {
                filter = sideload_field.get('firstObject');
                obj = {};
                if (!value) {
                    this.set('user', null);
                } else if (filter['bindto']) {
                    obj[filter['bindto']] = value;
                } else {
                    obj[fieldName] = value;
                }
                this.set('user', obj);
            }
            return this.set('page', 1);
        },
        bubbleTransition: function (to, params) {
            if (Ember.typeOf(to) === 'object') {
                return this.transitionToRoute(to);
            } else {
                return this.transitionToRoute(to, params);
            }
        },
    },
});

export default GeneralReportController;
