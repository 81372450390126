import EmberObject, { computed, set } from '@ember/object';
import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';

const MachinesMapController = Controller.extend({
    queryParams: ['tooltype', 'status'],

    tooltype: null,
    status: null,
    content: alias('model'),
    filters: computed(function () {
        return A([
            EmberObject.create({
                field: 'tooltype',
                type: 'database',
                form: 'transaction',
                name: 'tooltype',
            }),
            EmberObject.create({
                field: 'status',
                type: 'database',
                form: 'transaction',
                name: 'status',
            }),
        ]);
    }),

    actions: {
        filterChange(value, field) {
            if (field === 'tooltype') {
                return set('tooltype', value ? value.id : null);
            } else {
                return set(this, field, value);
            }
        },
    },
});

export default MachinesMapController;
