import Controller from '@ember/controller';
import EmberObject, { set } from '@ember/object';
import { alias } from '@ember/object/computed';

const MateriallogGroupsController = Controller.extend({
    content: alias('model'),
    report: EmberObject.create({
        form: 'materialgroup',
        refreshRows: true,
    }),

    actions: {
        showAdd(onoff) {
            set(this, 'record_id', null);
            set(this, 'showAddPopup', onoff);
            return false;
        },

        editRow(row) {
            set(this, 'record_id', row.id);
            set(this, 'showAddPopup', true);
            return false;
        },

        rowAdded() {
            this.send('showAdd', false);
            return this.report.notifyPropertyChange('refreshRows');
        },
    },
});

export default MateriallogGroupsController;
