import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

const MachinesTransactionTooltypeRoute = Route.extend({
    store: service(),
    model(params) {
        params.endtime = '(empty)';
        params.sideload = true;
        params.order = 'project';
        return hash({
            transactions: this.store.query('transaction', params),
            tooltype: this.store.findRecord('tooltype', params.tooltype),
        });
    },
});

export default MachinesTransactionTooltypeRoute;
