/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/company-info/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

import config from '../../../config/environment';

Component = Ember.Component.extend({
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    countries: Em.computed(function () {
        return [
            {
                id: 'se',
                name: 'se',
            },
            {
                id: 'fi',
                name: 'fi',
            },
        ];
    }),
    errors: Em.Object.create({
        company_vatin: false,
        company_name: false,
    }),
    showAdminMode: Em.computed('application.impersonate', 'Ember.devModeOn', function () {
        if (Ember.get(Ember, 'devModeOn')) {
            return true;
        }
        if (this.get('application.impersonate')) {
            return true;
        }
    }),
    actions: {
        save: function (row) {
            var field, hasError, i, len, ref;
            ref = ['company_vatin', 'company_name'];
            for (i = 0, len = ref.length; i < len; i++) {
                field = ref[i];
                if (!this.get('model.' + field) || this.get('model.' + field) === '') {
                    this.set('errors.' + field, true);
                    hasError = true;
                } else {
                    this.set('errors.' + field, false);
                }
            }
            if (!hasError) {
                return row.save().then(() => {
                    if (this.get('refreshNeeded')) {
                        this.refreshNeeded();
                    }
                    return this.get('notifications').success(
                        this.get('intl').t('billing_info.company_info_saved'),
                        {
                            autoClear: true,
                        },
                    );
                });
            }
        },
    },
});

export default Component;
