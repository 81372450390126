import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AddressCompComponent extends Component {
    @service admintool;
    @service() notifications;
    @service intl;

    @tracked companyData;
    @tracked validate;
    @tracked isLoading;

    constructor() {
        super(...arguments);
        this.setData();
    }

    @action
    async save() {
        this.isLoading = true;
        this.validate = false;

        if (this.validationErrorsFound()) {
            this.validate = true;
            return (this.isLoading = false);
        }

        await this.saveData('customer_info', {
            street: this.companyData.street,
            zip: this.companyData.zip,
            town: this.companyData.town,
            country: this.companyData.country,
            specifier: this.companyData.specifier,
        });

        if (this.billingData.use_company_address && this.billingData.billingtype == 'paper') {
            await this.saveData('billinginfo', {
                street: this.companyData.street,
                zip: this.companyData.zip,
                town: this.companyData.town,
                country: this.companyData.country,
                billingtype: 'paper',
            });
        }

        this.isLoading = false;
        this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
    }
    async setData() {
        this.companyData = this.args.popup
            ? this.args.companyData
            : await this.getdata('customer_info');
        try {
            this.billingData = await this.getdata('billinginfo');
        } catch (error) {
            if (error.statusText == 'Not Found') this.billingData = [];
        }
    }

    async getdata(url) {
        return await this.admintool.ajax({
            url: url,
            method: 'get',
        });
    }

    validationErrorsFound() {
        const mandatoryFields = ['street', 'zip', 'town', 'country'];
        for (const field in this.companyData) {
            if (mandatoryFields.includes(field)) {
                if (!this.companyData[field] || this.companyData[field] == '') return true;
            }
        }
    }

    async saveData(url, data) {
        return await this.admintool.ajax({
            url: url,
            method: 'PUT',
            data: data,
        });
    }
}
