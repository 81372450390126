import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

export default class SettingsGeneralV2ComponentsWorktimeGroupModalComponent extends Component {
    get moreUsers() {
        return this.args.users.meta.count - this.args.users.length;
    }
    get styleNamespace() {
        return podNames['settings/general-v2/components/worktimegroups/modal'];
    }
}
