import Service, { inject as service } from '@ember/service';
import FormsService from './forms';
import DS from 'ember-data';
import { IRecord } from 'tt4/ember-movenium/interfaces/record';
import { assert } from '@ember/debug';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export default class CollectorService extends Service {
    @service private intl!: any;
    @service private forms!: FormsService;
    @service private store!: DS.Store;

    constructor() {
        super(...arguments);
    }

    public getTranslationForColumn(form: string, fieldName: string, removed: boolean = false) {
        if (fieldName === 'id') return 'id';

        if (fieldName.substring(0, 9) === 'row_info.') return this.intl.t('general.' + fieldName);

        if (removed) return this.getBestTranslation(form, fieldName);

        const field = this.forms.fields(form)[fieldName];

        if (!field) assert("Field '" + fieldName + "' not found for form '" + form + "'");

        if (field.hasOwnProperty('name')) return field.name;
        else return this.getBestTranslation(form, fieldName);
    }

    public getBestTranslation(form: string, fieldName: string) {
        const testKeys: string[] = [
            'report.' + form + '.' + fieldName,
            'defaults.field.' + fieldName,
        ];

        const foundKey: string | undefined = testKeys.find((key) => {
            return this.intl.exists(key);
        });

        if (foundKey) return this.intl.t(foundKey);
        else return testKeys[0];
    }

    public copyRecord(record: IRecord) {
        const form = get(record, 'constructor.modelName');
        const jsonRecord: any = record.toJSON();
        for (const field of ['status']) {
            if (!isEmpty(jsonRecord[field])) {
                jsonRecord[field] = null;
            }
        }

        record.eachRelationship((key: string, relationship: { kind: string }) => {
            const relKind = relationship.kind;
            if (
                (relKind === 'belongsTo' || relKind === 'hasMany') &&
                !['export_salary', 'export_absence', 'row_info'].includes(key)
            ) {
                if (this.forms.field(form, key).type === 'databasearray') {
                    const hasManyArr: any = [];
                    record[key].forEach((hasManyRecord: IRecord) => {
                        hasManyArr.push(this.copyRecord(hasManyRecord));
                    });
                    jsonRecord[key] = hasManyArr;
                } else {
                    jsonRecord[key] = record[key];
                }
            }
        });

        return this.store.createRecord(form, jsonRecord);
    }
}
