/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/company-profile/route.coffee.old
// Generated by CoffeeScript 2.6.1
var CompanyProfileRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

CompanyProfileRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    model: function () {
        return this.get('store').findAll('company-info');
    },
    activate: function () {
        var c, controller;
        c = this.get('Collector');
        controller = this.controllerFor('settings/company-profile');
        return this.get('store')
            .findAll('logo')
            .then((logo) => {
                var currentLogo;
                controller.set('logoField', {
                    form: 'logo',
                    name: 'logo',
                    type: 'hdfile',
                    features: {
                        is_image: true,
                    },
                });
                if (logo.get('firstObject')) {
                    currentLogo = logo.get('firstObject');
                    controller.set('logoImage', currentLogo.get('logo.thumb'));
                    controller.set('logo', currentLogo);
                    return (controller.get('logoField')['value'] = currentLogo.get('logo'));
                } else {
                    return controller.set('logo', this.get('store').createRecord('logo'));
                }
            });
    },
});

export default CompanyProfileRoute;
