import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Route.extend({
    collector: service('collector-service'),
    intl: service(),
    store: service(),
    dialogs: service(),
    flags: service(),

    async model() {
        const integrations = await this.store.query('integration', { type: 'export_salary' });

        if (integrations.length === 0) {
            const integrations = this.getStandardIntegrations();
            const customs = await this.store.query('custom-export', {});

            customs.forEach((custom) =>
                integrations.push({ name: custom.name, key: 'custom-' + custom.id }),
            );

            return integrations;
        } else {
            return integrations;
        }
    },

    actions: {
        fileCreated() {
            this.transitionTo('salary.export-salary');
        },
        cancel() {
            this.transitionTo('salary.export-salary');
        },

        async close() {
            const con = await this.dialogs.confirm(
                this.intl.t('export-salary.settings.confirm_close'),
            );
            if (con) set('this', 'controller.showModal', false);
        },
    },

    getStandardIntegrations() {
        let products = {
            fivaldi_salary_csv:
                'general-fivaldi-salary-csv-v2?_v=' + this.flags.test('general-fivaldi-salary-csv'),
            lon600_salary_xml:
                'general-lon-600-salary-xml-v2?_v=' + this.flags.test('general-lon-600-salary-xml'),
            nova_salary_csv:
                'general-nova-salary-csv-v4?_v=' + this.flags.test('general-nova-salary-csv'),
            fivaldi_salary_soap:
                'general-fivaldi-salary-soap-v4?_v=' +
                this.flags.test('general-fivaldi-salary-soap'),
            netvisor_salary_rest:
                'general-netvisor-salary-rest-v4?_v=' +
                this.flags.test('general-netvisor-salary-rest'),
            payroll_worktime_rest: 'payroll_worktime_rest',
            payroll_worktime_csv:
                'general-payroll-worktime-csv?_v=' +
                this.flags.test('general-payroll-worktime-csv'),
            payroll_absence_csv:
                'general-payroll-absence-csv?_v=' + this.flags.test('general-payroll-absence-csv'),
            vismanet: 'vismanet',
        };

        if (this.collector.testNeedsOne('products.salarytype_v2')) {
            products.fivaldi_salary_soap =
                'general-fivaldi-salary-soap-salarytypesv2?_v=' +
                this.flags.test('general-fivaldi-salary-soap-salarytypesv2');
            products.payroll_worktime_csv =
                'general-payroll-worktime-csv-salarytypesv2?_v=' +
                this.flags.test('general-payroll-worktime-csv-salarytypesv2');
            products.nova_salary_csv =
                'general-nova-salary-csv-salarytypesv2?_v=' +
                this.flags.test('general-nova-salary-csv-salarytypesv2');
            products.netvisor_salary_rest =
                'general-netvisor-salary-rest-salarytypesv2?_v=' +
                this.flags.test('general-netvisor-salary-rest-salarytypesv2');
            products.vismanet = 'vismanet-salarytypesv2';
        }

        const integrations = [];
        Object.keys(products).forEach((product) => {
            const key = products[product];
            if (this.collector.testNeedsOne('products.' + product)) {
                integrations.pushObject({
                    name: this.intl.t('integrations.export.' + product),
                    key: key,
                });
            }
        });

        return integrations;
    },
});
