/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/init-route.coffee.old
// Generated by CoffeeScript 2.6.1
import Mixin from '@ember/object/mixin';

export default Mixin.create({
    setupController: function (controller, model) {
        this._super(controller, model);
        controller._init_functions();
        return controller.set('init_already_run', true);
    },
});
