/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/classes/collector.coffee.old
// Generated by CoffeeScript 2.6.1
var collector,
    indexOf = [].indexOf;

import config from '../config/environment';

import DS from 'ember-data';

import CollectorModel from '../models/collector';

import localization from '../classes/localization';

import CollectorValidationMixin from '../mixins/collector-validation';

import $ from 'jquery';

collector = Ember.Object.create({
    //this.api_url = 'http://p.movenium.com/collector-4.0_dev';
    // testing url for new api version
    api_url: function () {
        return config.APP.COLLECTOR_API_URL;
    },
    forms_url: '/forms',
    getFormSettings: function () {
        var formSettings, jsonParse;
        formSettings = '[{"date_interval":"2"}]';
        jsonParse = JSON.parse(formSettings);
        return jsonParse;
    },

    //    This method should create all the needed models for ember.

    //    this is done when application initialization is ready
    //    basically we get json-formatted data of forms
    //    and create ember DS models from those

    createEmberModels: function (access_token) {
        var d, headers, self;
        if (this.testing) {
            return this.createTestModels();
        }
        //this function returns promise
        d = new $.Deferred();
        self = this;
        headers = null;
        if (access_token) {
            headers = {
                Authorization: 'Bearer ' + access_token,
            };
        }
        $.ajax({
            url: self.api_url() + self.forms_url,
            datatype: 'json',
            headers: headers,
            //if something failed
            tryCount: 0,
            retryLimit: 1,
            //error: (response, textStatus, errorThrown) ->
            success: function (data) {
                //put data to localstorage
                //            localStorage.forms = JSON.stringify(data);
                data = self.addCommonForms(data);
                self.create_models(data, self);
                return d.resolve();
            },
            error: function (reason) {
                localStorage.removeItem('ember_simple_auth-session');
                location.reload();
                return d.reject(reason);
            },
        });
        return d.promise();
    },
    // change dropdown options from object to array .. handlebers each requires this
    reparse_dropdown_options: function (forms) {
        $.each(forms, function (form_index, form) {
            return $.each(form.objects, function (obj_index, object) {
                var is_array, temp;
                if (
                    (object.type === 'dropdown_values' || object.type === 'dropdown_options') &&
                    typeof object.options === 'object'
                ) {
                    temp = new Array(0);
                    is_array = Object.prototype.toString.call(object.options) === '[object Array]';
                    $.each(object.options, function (opt_index, option) {
                        var id;
                        if (opt_index === 0 && option === '') {
                            return (forms[form_index].objects[obj_index].empty_option = true);
                        } else {
                            id = is_array ? option : opt_index;
                            return temp.push({
                                id: id,
                                value: option,
                            });
                        }
                    });
                    return (forms[form_index].objects[obj_index].options = temp);
                }
            });
        });
        return forms;
    },
    create_models: function (data, self) {
        var all_attributes, async_relations, rel_objects;
        self = this;
        self.set('forms', self.reparse_dropdown_options(data.forms));
        if (data.updater != null) {
            self.set('products', data.updater.products);
        }
        if (data.updater != null) {
            self.set('modules', data.updater.modules);
        }
        self.set('tt3flag', data.tt3flag);
        //object for storing all model information
        //because models can be defined only once, so we do that in the
        //end of this script
        all_attributes = {};
        rel_objects = {};
        async_relations = [
            'materiallog.type',
            'multitask.taskname',
            'user.employer',
            'urakkalog.type',
            'customlog.type',
            'worktask.project',
            'holiday.user',
        ];
        //iterate every form
        $.each(data.forms, function (index, value) {
            var form_name, model_attributes;
            //form name must be "classified",
            //because ember model names are like that
            //e.g. wage_periods => WagePeriods
            form_name = index.classify();
            model_attributes = {};
            //iterate every attribute of the form
            $.each(value.objects, function (attribute_name, attributes_arr) {
                var attr, ref, ref1, relation, relation_fields;
                //put attribute to the object
                attr = null;
                relation_fields = ['database', 'databasearray', 'timerlink'];
                if (
                    ((ref = attributes_arr.type), indexOf.call(relation_fields, ref) >= 0) &&
                    attributes_arr.options
                ) {
                    relation = attributes_arr.options.form;
                    //if array does not exist, create empty array so that push method works
                    if (typeof rel_objects[relation] === 'undefined') {
                        rel_objects[relation] = [];
                    }
                    //put relations to helper array
                    rel_objects[relation].push(form_name);
                    if (attributes_arr.type === 'databasearray') {
                        attr = DS.hasMany(relation, {
                            async: true,
                        });
                    } else if (attribute_name === 'parent') {
                        attr = DS.belongsTo(relation, {
                            inverse: null,
                            async: false,
                        });
                    } else if (attribute_name === 'superior') {
                        attr = DS.belongsTo(relation, {
                            inverse: null,
                            async: false,
                        });
                    } else if (attributes_arr.async != null && attributes_arr.async) {
                        attr = DS.belongsTo(relation, {
                            async: true,
                        });
                    } else if (
                        ((ref1 = form_name.toLowerCase() + '.' + attribute_name),
                        indexOf.call(async_relations, ref1) >= 0)
                    ) {
                        attr = DS.belongsTo(relation, {
                            async: true,
                        });
                    } else {
                        attr = DS.belongsTo(relation, {
                            async: false,
                        });
                    }
                } else {
                    //otherwise just create normal attribute
                    attr = DS.attr();
                }
                //create the attribute object
                return (model_attributes[attribute_name] = attr);
            });
            //put model attributes to helper object
            return (all_attributes[form_name] = model_attributes);
        });
        all_attributes = this.createWorkdayModels(all_attributes, data.forms);
        //iterate over relations array
        $.each(rel_objects, function (form_name, models) {
            var attributes;
            form_name = form_name.classify();
            if (!all_attributes[form_name]) {
                //create only name field for empty model
                attributes = {};
                attributes.name = DS.attr();
                return (all_attributes[form_name] = attributes);
            }
        });
        //create hasMany-relations
        // do we really need this? commented by veikko 14.8.2015 so parent relations could work
        //            $.each models, (index, value) ->
        //                attributes = {}
        //                value = value.decamelize()
        //                attributes[value] = DS.hasMany(value)
        //                $.extend(all_attributes[form_name], attributes)
        //iterate attributes helper object and create Ember Models from there
        return $.each(all_attributes, function (form_name, attributes) {
            return (Tt4[form_name] = CollectorModel.extend(CollectorValidationMixin, attributes));
        });
    },
    deleteModels: function (store) {
        //iterate every form
        return $.each(this.forms, function (form_name, value) {
            //unload model data
            return store.unloadAll(form_name);
        });
    },
    //delete Tt4[form_name]
    createWorkdayModels: function (all_attributes, forms) {
        all_attributes['WorkdayDay'] = {
            date: DS.attr('string'),
            minutes: DS.attr('number'),
            own: DS.attr('boolean'),
            desc: DS.attr('string'),
            country: DS.attr('string'),
            absence_minutes: DS.attr('number'),
        };
        all_attributes['WorkdayWeek'] = {
            day: DS.attr('string'),
            starttime: DS.attr('string'),
            endtime: DS.attr('string'),
            minutes: DS.attr('number'),
            own: DS.attr('boolean'),
            overtimerule: DS.belongsTo('WorkdayOvertimeRule'),
            max_minutes: DS.attr('number'),
            absence_minutes: DS.attr('number'),
        };
        all_attributes['WorkdayOvertimeRule'] = {
            name: DS.attr('string'),
            rules: DS.attr('string'),
            workdayWeeks: DS.hasMany('WorkdayWeek'),
        };
        if (forms.worktimegroup) {
            all_attributes['WorkdayDay'].worktimegroup = DS.belongsTo('worktimegroup');
            all_attributes['WorkdayWeek'].worktimegroup = DS.belongsTo('worktimegroup');
        }
        return all_attributes;
    },
    addCommonForms: function (data) {
        var commonForms, form, key;
        // This is a bit stupid because we can't give privileges depending on userlevel
        commonForms = this.get('commonForms');
        for (key in commonForms) {
            form = commonForms[key];
            data['forms'][key] = form;
        }
        return data;
    },
    commonForms: {
        rounding_rule: {
            objects: {
                time_from: {
                    type: 'time_new',
                    features: {
                        mandatory: true,
                    },
                },
                time_to: {
                    type: 'time_new',
                    features: {
                        mandatory: true,
                    },
                },
                time_result: {
                    type: 'time_new',
                    features: {
                        mandatory: true,
                    },
                },
            },
            privileges: {
                add: true,
                edit: true,
                remove: true,
            },
        },
        logo: {
            objects: {
                logo: {
                    type: 'hdfile',
                    features: {
                        is_image: true,
                        public: true,
                        single_image: true,
                    },
                },
            },
        },
    },
    createTestModels: function () {
        var d, data;
        d = new $.Deferred();
        data = '';
        this.create_models(JSON.parse(data), this);
        //mark as resolved
        d.resolve();
        return d;
    },
    getPrivilege: function (form, privilege) {
        if (!this.forms) {
            return false;
        }
        if (privilege === 'show') {
            if (this.forms[form]) {
                return true;
            } else {
                return false;
            }
        }
        if (!this.forms[form]) {
            return false;
        }
        return this.forms[form].privileges[privilege];
    },
    getFormObjects: function (form) {
        form = form.replace('-', '_');
        if (!this.forms) {
            return false;
        }
        if (!this.forms[form]) {
            return false;
        }
        if (!this.forms[form].objects) {
            return false;
        }
        return this.forms[form].objects;
    },
    getFormObject: function (obj) {
        var arr, form, obj_name;
        arr = obj.split('.');
        form = arr[0].replace('-', '_');
        obj_name = arr[1];
        if (!this.forms) {
            return false;
        }
        if (!this.forms[form]) {
            return false;
        }
        if (!this.forms[form].objects) {
            return false;
        }
        if (!this.forms[form].objects[obj_name]) {
            return false;
        }
        return this.forms[form].objects[obj_name];
    },
    hasErrors: function (form) {
        if (!this.forms) {
            return false;
        }
        if (!this.forms[form]) {
            return false;
        }
        if (this.forms[form].errors) {
            return true;
        }
        return false;
    },
    getErrors: function (form) {
        if (!this.forms) {
            return false;
        }
        if (!this.forms[form]) {
            return false;
        }
        if (this.forms[form].errors) {
            return this.forms[form].errors;
        }
        return false;
    },
});

export default collector;
