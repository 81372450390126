/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

import config from '../../../config/environment';

TimetrackerAddRoute = GeneralAddRoute.extend({
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    modelName: 'worktime',
    controllerName: 'timetracker/add',
    afterSave: Em.computed(function () {
        if (this.get('Collector').testNeeds(['mobileView', 'userlevel=1'])) {
            return 'timetracker.week-report';
        } else {
            return 'timetracker.worktime-list';
        }
    }),
    savedRoute: 'timetracker.addsaved',
    rowSaved: function (row) {
        var project, user;
        // save worktime adding to ttapi
        this.get('ttapi').request('/worktimeAdded', {
            method: 'post',
            data: {
                username: row.get('user.username'),
                userid: row.get('user.id'),
                starttime: row.get('starttime'),
                date: row.get('date'),
            },
        });
        // Alert for missing orientation or outdated pass
        if (
            this.get('Collector').testNeedsOne('modules.orientations') &&
            this.get('session.currentUser.userlevel') === '1' &&
            !this.get('session.currentUser.no_orientation')
        ) {
            project = row.get('project.id');
            if (this.get('Collector').testNeedsOne('products.rakentajanpaketti')) {
                if (row.get('project.proj_type') === '2' || row.get('project.proj_type') === null) {
                    return;
                }
            }
            user = row.get('user.id');
            return this.get('store')
                .query('orientation', {
                    ori_site: project,
                    ori_user: user,
                    limit: 1,
                })
                .then((data) => {
                    if (
                        this.get('Collector').testNeedsOne('products.orientations_self') &&
                        data.get('meta.count') === 0
                    ) {
                        return this.get('dialogs')
                            .alert(
                                this.get('intl').t(
                                    'worktime.after_save.orientation.self_orientation',
                                ),
                            )
                            .then(() => {
                                return this.transitionTo(
                                    'orientations.own-orientation',
                                    row.get('project.id'),
                                );
                            });
                    } else if (data.get('meta.count') === 0) {
                        return this.get('dialogs').alert(
                            this.get('intl').t('worktime.after_save.orientation.not_found'),
                        );
                    } else if (
                        data.get('firstObject.pass_end') &&
                        moment().isAfter(moment(data.get('firstObject.pass_end')))
                    ) {
                        return this.get('dialogs').alert(
                            this.get('intl').t('worktime.after_save.orientation.outdated'),
                        );
                    }
                });
        }
    },
    actions: {
        afterSave: function (settings, record) {
            return this.transitionTo('timetracker.addsaved').then((newRoute) => {
                newRoute.controller.set('model', record);
                newRoute.controller.set('afterSave', this.get('afterSave'));
                newRoute.controller.set('addPageLink', this.get('controller.addNewLink'));
                return newRoute.controller.set('editPageLink', this.get('controller.editNewLink'));
            });
        },
        cancelClicked: function () {
            return this.get('dialogs')
                .confirm(this.get('intl').t('general.confirm.cancel'))
                .then((con) => {
                    if (con) {
                        this.get('controller.model').rollbackAttributes();
                        return window.history.back(-1);
                    }
                });
        },
    },
});

export default TimetrackerAddRoute;
