import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { configureScope, lastEventId } from '@sentry/browser';
import { computed, set, get } from '@ember/object';
import Ember from 'ember';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isArray } from '@ember/array';

export default Controller.extend({
    intl: service(),
    errorService: service('error'),
    init() {
        this._super();

        if (!Modernizr.localstorage) {
            set(this, 'localStorageError', true);
        }
        this.getEventId();
    },
    error: alias('model'),
    parsedError: computed('model', function () {
        const error = get(this, 'error');
        const intl = get(this, 'intl');
        if (get(error, 'errors.error')) {
            if (intl.exists('general.error.translated.' + get(error, 'errors.error'))) {
                return intl.t('general.error.translated.' + get(error, 'errors.error'));
            }
        }
        return null;
    }),

    technicalErrorString: computed('error', function () {
        return this.errorService.toString(this.error);
    }),

    technicalError: computed('error', function () {
        const error = this.error;
        if (error.errors && isArray(error.errors))
            return JSON.stringify(this.error.errors.firstObject);
        else if (error.errors) return JSON.stringify(error.errors);
        else return JSON.stringify(error);
    }),

    insertLabel() {
        configureScope((scope) => {
            scope.setTag('fatal_error', 'true');
        });
    },

    getEventId() {
        const id = lastEventId();
        this.insertLabel();
        if (!id && !Ember.testing) {
            later(this, 'getEventId', 100);
            return;
        }
        set(this, 'eventId', id);
    },
});
