import Component from '@glimmer/component';
import SignaturePad from 'signature_pad';
import { action } from '@ember/object';

export default class SignatureModalComponent extends Component {
    signaturePad = null;

    constructor() {
        super(...arguments);
        const canvas = document.createElement('canvas');
        canvas.width = 500;
        this.signaturePad = new SignaturePad(canvas);
        this.canvas = canvas;
    }

    @action
    signaturePadFromSrc(src) {
        this.signaturePad.clear();
        if (!src) return;
        this.signaturePad.fromDataURL(src);
    }

    @action
    save() {
        if (this.signaturePad.isEmpty()) this.args.onChange(null);
        else this.args.onChange(this.signaturePad.toDataURL());
        this.showModal = false;
    }

    @action
    clear() {
        this.signaturePad.clear();
    }
}
