/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/done/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserDoneController;

import Ember from 'ember';

import initmixin from 'tt4/mixins/init-controller';

RegisterUserDoneController = Ember.Controller.extend(initmixin, {
    registerUserController: Ember.inject.controller('register/user'),
    applicationIndexController: Ember.inject.controller('index'),
    application: Ember.inject.controller(),
    Collector: Ember.inject.service('collector-service'),
    session: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    mySites: Ember.inject.service(),
    forwardingStraightToService: true,
    initapp: Ember.inject.service(),
    init_functions: function () {
        var c, projectid, promise, registerUserController, userinfo;
        this._super();
        registerUserController = this.get('registerUserController');
        if (!registerUserController.get('model.creator')) {
            alert('creator not set');
            return;
        }
        c = this.get('Collector');
        userinfo = registerUserController.get('userinfo');
        projectid = userinfo.project;
        promise = c.ttapi({
            url: 'registerUser',
            method: 'post',
            data: {
                creator: registerUserController.get('model.creator'),
                userinfo: JSON.parse(JSON.stringify(registerUserController.get('userinfo'))),
            },
        });
        return promise.then((back) => {
            var ls;
            this.set('username', back.username);
            ls = this.get('localStorageWrapper');
            ls.setSavedUsername(back.username);
            this.set('password', back.password);
            this.set('done', true);
            // if user has logged in, do not show this page, just login
            if (this.get('session.currentUser')) {
                return this.loginToService(projectid);
            } else {
                return this.set('forwardingStraightToService', false);
            }
        });
    },
    loginToService: function (projectid) {
        var passwd, promise, self, username;
        username = this.get('username');
        passwd = this.get('password');
        self = this;
        if (!passwd) {
            this.transitionToRoute('index');
            return;
        }
        promise = this.get('session').authenticate(
            'authenticator:collector-auth',
            username,
            passwd,
        );
        return promise.then(() => {
            this.get('mySites').addSiteWithJustId(projectid);
            this.get('applicationIndexController').set('defaultPageTransitionDone', true);
            //@get('application').firstPageTransition()
            this.transitionToRoute('index');
            return Em.run.later(
                this,
                function () {
                    this.get('initapp').clearInit();
                    return location.reload();
                },
                1000,
            );
        });
    },
    actions: {
        login: function () {
            return this.loginToService();
        },
    },
});

export default RegisterUserDoneController;
