import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {
    session: service(),
    initapp: service(),
    analytics: service(),
    PushNotification: service(),

    afterModel() {
        return new Promise(async (resolve) => {
            this.analytics.trackEvent({ category: 'Logout', action: 'Logout' });

            // send request to ttapi for push notification removal
            // await it because this is a callback, and if the session is invalidated before this is complete,
            // ttapi will respond with an error because it does not get the access token in the request
            await this.PushNotification.unregister();
            delete localStorage['first-time-loader-greeting'];
            this.initapp.clearInit();
            this.session.invalidate();
            resolve();
        });
    },
});
