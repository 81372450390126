import { typeOf } from '@ember/utils';
import GeneralReportController from 'tt4/controllers/general-report';
import EmberObject from '@ember/object';
import { computed, set } from '@ember/object';
import { A } from '@ember/array';

const WorktaskReportController = GeneralReportController.extend({
    queryParams: ['project', 'user', 'date', 'q'],

    form: 'worktask',
    status: 'all',
    showColumns: null,

    init() {
        this._super(...arguments);
        this.showColumns = ['date', 'project', 'user', 'est_hours', 'hours', 'notes', 'files'];
    },

    settings: EmberObject.create({
        rowSettings: EmberObject.create({
            //            hideActions: true
            editRoute: {
                modal: true,
            },
        }),
        massedit: true,
        sortByColumn: 'date-',
    }),

    filters: computed(function () {
        return A([
            EmberObject.create({ field: 'date' }),
            EmberObject.create({ field: 'project' }),
            EmberObject.create({ field: 'user' }),
            EmberObject.create({ type: 'searchbox', bindto: 'q' }),
        ]);
    }),

    actions: {
        filterChangeAction(value, field) {
            if (typeOf(value) === 'object' || typeOf(value) === 'instance') {
                return set(this, field, value.id);
            } else {
                return set(this, field, value);
            }
        },
    },
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
});

export default WorktaskReportController;
