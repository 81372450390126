import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    router: service(),
    collector: service('collector-service'),
    componentName: computed('name', function () {
        if (get(this, 'name').substring(0, 5) === 'help-') return 'wizard-builder/help-wizard';
        return 'wizard-builder/' + get(this, 'name');
    }),
    actions: {
        transitionToOnboarding() {
            if (this.collector.testNeeds(['modules.timetracker', 'modules.absences'])) {
                get(this, 'router').transitionTo({ queryParams: { wizard: 'onboarding-wizard' } });
            } else {
                this.close();
            }
        },
        closeAndTransitionToRoute(route) {
            get(this, 'router').transitionTo(route);
        },
    },
});
