/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/routes/general-add.coffee.old
// Generated by CoffeeScript 2.6.1
var GeneralAddRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import config from '../config/environment';

import collector from '../classes/collector';

import toTop from '../mixins/reset-scroll';

import jQuery from 'jquery';

GeneralAddRoute = Ember.Route.extend(AuthenticatedRouteMixin, toTop, {
    dialogs: Ember.inject.service(),
    gui: Ember.inject.service(),
    controllerName: 'general-add',
    savedRoute: 'saved',
    additional_fields: Em.A([]),
    CollectorService: Ember.inject.service('collector-service'),
    scroller: Ember.inject.service(),
    intl: Ember.inject.service(),
    beforeModel: function () {
        this._super(...arguments);
        return this.set('gui.hideMobileHeader', true);
    },
    model: function (params) {
        var records;
        if (!this.get('modelName')) {
            return Ember.Object.create({
                objects: this.get('objects'),
                values: {},
                saveAction: this.get('saveAction'),
            });
        }
        if (this.get('edit')) {
            return this.store.findRecord(this.get('modelName'), params.id, {
                include: true,
            });
        } else {
            records = this.store.peekAll(this.get('modelName')).filterBy('isNew', true);
            if (records.get('length') > 0) {
                return records.get('firstObject');
            }
            return this.store.createRecord(this.get('modelName'));
        }
    },
    setupController: function (controller, model) {
        this._super(controller, model);
        // Reset controller properties'
        controller.set('errors', Em.A([]));
        controller.set('spinIsLoading', false);
        return controller.set('submitted', false);
    },
    //        controller.set('fieldSettings.title', true)
    rowSaved: function (row) {},
    save_record: function (row) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var children, field, fields, name, obj, promises;
            promises = [];
            fields = this.get('Collector').fields(this.get('controller.form'));
            children = [];
            for (name in fields) {
                field = fields[name];
                if (field.type === 'databasearray') {
                    obj = Ember.Object.extend({
                        fieldsInCommon: Ember.computed.intersect('parentFields', 'childFields'),
                    }).create({
                        parentFields: Object.keys(fields),
                        childFields: Object.keys(this.get('Collector').fields(field.options.form)),
                    });
                    row.get(name).forEach((child) => {
                        obj.get('fieldsInCommon').forEach(function (commonfield) {
                            return child.set(commonfield, row.get(commonfield));
                        });
                        return children.push(child);
                    });
                }
            }
            if (children.length > 0) {
                return this.get('CollectorService')
                    .checkRowValidation(this.get('controller.form'), row, this.get('edit'))
                    .then(
                        (resp) => {
                            promises = children.map(function (child) {
                                return child.save();
                            });
                            return Ember.RSVP.Promise.all(promises).then(() => {
                                return row.save().then(resolve, reject);
                            }, reject);
                        },
                        (e) => {
                            this.set('controller.validationMessageData', e.payload);
                            return reject(e);
                        },
                    );
            } else {
                return row.save().then(resolve, reject);
            }
        });
    },
    scrollIfErrors: function () {
        return Em.run.next(this, function () {
            if (document.querySelector('.has-error')) {
                return this.get('scroller').scrollVertical(document.querySelector('.has-error'), {
                    offset: -70,
                });
            }
        });
    },
    actions: {
        save: function () {
            var promise, row, self;
            this.scrollIfErrors();
            row = this.get('controller.model');
            if (collector.hasErrors(row.get('constructor.modelName'))) {
                this.set('controller.spinIsLoading', false);
                return;
            }
            //            if row.saveAction?
            //                row.saveAction @, row
            //#                @transitionTo('index')
            //                return
            this.set('controller.submitted', false);
            self = this;
            if (!this.get('controller.errors') || this.get('controller.errors.length') < 1) {
                this.set('controller.submitted', true);
                if (row.saveAction != null) {
                    row.saveAction(this, row);
                    return;
                }
                //                @transitionTo('index')
                promise = this.save_record(row);
                return promise.then(
                    function () {
                        var after_save;
                        self.set('controller.validationMessage', null);
                        self.rowSaved(row);
                        after_save = self.get('controller.afterSave');
                        if (!after_save) {
                            after_save = self.get('afterSave');
                        }
                        if (config.APP.KIOSK) {
                            after_save = self.get('sharedAfterSave');
                        }
                        self.set('controller.spinIsLoading', false);
                        //savedController = self.get 'savedController'

                        //savedController.set 'model', row
                        //savedController.set 'afterSave', after_save
                        return self.transitionTo(self.get('savedRoute')).then(function (newRoute) {
                            if (self.get('hideFromSaved')) {
                                newRoute.controller.set('hideFields', self.get('hideFromSaved'));
                            }
                            newRoute.controller.set('model', row);
                            newRoute.controller.set(
                                'additional_fields',
                                self.get('additional_fields'),
                            );
                            newRoute.controller.set('afterSave', after_save);
                            if (self.get('controller.addNewLink')) {
                                newRoute.controller.set(
                                    'addPageLink',
                                    self.get('controller.addNewLink'),
                                );
                            }
                            if (self.get('controller.editNewLink')) {
                                newRoute.controller.set(
                                    'editPageLink',
                                    self.get('controller.editNewLink'),
                                );
                            }
                            if (self.get('controller.hideSavedAddLinks')) {
                                return newRoute.controller.set('hideAddLinks', true);
                            }
                        });
                    },
                    (back) => {
                        this.set('controller.submitted', true);
                        this.set('controller.spinIsLoading', false);
                        return this.set('controller.validationMessageData', back.errors);
                    },
                );
            } else {
                self.set('controller.submitted', true);
                //put button to normal state after error
                return self.set('controller.spinIsLoading', false);
            }
        },
        cancel: function () {
            var self;
            self = this;
            return this.get('dialogs')
                .confirm(this.get('intl').t('general.confirm.cancel'))
                .then((con) => {
                    if (con) {
                        this.get('controller.model').rollbackAttributes();
                        return window.history.back(-1);
                    }
                });
        },
        //                if self.get('afterSave') then self.transitionTo(self.get('afterSave')) else self.transitionTo('index')
        willTransition: function (transition) {
            if (
                transition.targetName !== 'users.add.index' &&
                transition.targetName !== 'register.service.products' &&
                transition.targetName.substring(0, 9) !== 'users.add'
            ) {
                if (
                    this.get('controller.model') &&
                    typeof this.get('controller.model').rollbackAttributes === 'function'
                ) {
                    this.get('controller.model').rollbackAttributes();
                }
                return true;
            }
        },
    },
    resetController: function (controller, isExiting, transition) {
        if (isExiting) {
            controller.set('message', null);
            controller.set('validationMessage', null);
            controller.set('showMessageButton', false);
            this.set('gui.hideMobileHeader', false);
        }
        return this._super(controller, isExiting, transition);
    },
});

export default GeneralAddRoute;
