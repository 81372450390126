/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/withoutori/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsWithoutoriRoute;

import Ember from 'ember';

import config from '../../../config/environment';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

OrientationsWithoutoriRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    ttapi: Ember.inject.service(),
    queryParams: {
        ori_site: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
    },
    model: function (params) {
        if (Em.isEmpty(params.ori_site)) {
            delete params.ori_site;
        }
        if (Em.isEmpty(params.employer)) {
            delete params.employer;
        }
        params.limit = 25;
        params.offset = 0;
        if (params.page) {
            params.offset = params.page * params.limit - params.limit;
            delete params.page;
        }
        return this.get('ttapi').request('/orientations/users_without_ori', {
            type: 'GET',
            data: params,
        });
    },
});

export default OrientationsWithoutoriRoute;
