import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import jsQR from 'jsqr';

export default Component.extend({
    router: service(),

    video: null,
    stream: null,

    init() {
        this._super(...arguments);

        const video = document.createElement('video');
        set(this, 'video', video);

        navigator.mediaDevices
            .getUserMedia({ video: { facingMode: 'environment' } })
            .then((stream) => this._setStream(stream, video));
    },

    willDestroyElement() {
        this.stopCamera();
    },

    drawLine(canvas, begin, end, color) {
        canvas.beginPath();
        canvas.moveTo(begin.x, begin.y);
        canvas.lineTo(end.x, end.y);
        canvas.lineWidth = 4;
        canvas.strokeStyle = color;
        canvas.stroke();
    },

    tick() {
        if (this.isDestroyed || this.isDestroying) return;

        const video = get(this, 'video');
        var canvasElement = document.getElementById('canvas');
        var canvas = canvasElement.getContext('2d');

        if (video.readyState === video.HAVE_ENOUGH_DATA) {
            canvasElement.hidden = false;

            const imageBoxSize = canvasElement.width;

            canvasElement.height = imageBoxSize;
            const ratio = video.videoWidth / video.videoHeight;

            if (ratio > 1) {
                const crop = ratio * imageBoxSize - imageBoxSize;
                canvas.drawImage(
                    video,
                    (-1 * crop) / 2,
                    0,
                    canvasElement.width + crop / 2,
                    imageBoxSize,
                );
            } else {
                const crop = (1 / ratio) * imageBoxSize - imageBoxSize;
                canvas.drawImage(
                    video,
                    0,
                    (-1 * crop) / 2,
                    imageBoxSize,
                    canvasElement.height + crop / 2,
                );
            }

            var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);

            const code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: 'dontInvert',
            });

            if (code) {
                if (this.parseCode(code.data)) {
                    this.drawARbox(canvas, code);

                    set(this, 'url', code.data);
                    later(this, this.codeFound, 1000);
                } else {
                    if ((new Date().getTime() % 800) / 800 > 0.5)
                        this.drawARbox(canvas, code, '#cfc848');
                    requestAnimationFrame(this.tick.bind(this));
                }
            } else {
                this.drawScanner(canvas, canvasElement.width, canvasElement.height);
                requestAnimationFrame(this.tick.bind(this));
            }
        } else {
            requestAnimationFrame(this.tick.bind(this));
        }
    },

    drawScanner(canvas, width, height) {
        const color = '#FF3B58';
        const speed = 10000;

        const d = new Date();
        const n = ((d.getTime() % speed) / speed) * 2;
        const y = height * (n > 1 ? 2 - n : n);

        this.drawLine(canvas, { x: 0, y: y }, { x: width, y: y }, color);
    },

    drawARbox(canvas, code, color = 'green') {
        this.drawLine(canvas, code.location.topLeftCorner, code.location.topRightCorner, color);
        this.drawLine(canvas, code.location.topRightCorner, code.location.bottomRightCorner, color);
        this.drawLine(
            canvas,
            code.location.bottomRightCorner,
            code.location.bottomLeftCorner,
            color,
        );
        this.drawLine(canvas, code.location.bottomLeftCorner, code.location.topLeftCorner, color);

        const middleTop = this.getMiddlePoint(
            code.location.topLeftCorner,
            code.location.topRightCorner,
        );
        const middleBottom = this.getMiddlePoint(
            code.location.bottomLeftCorner,
            code.location.bottomRightCorner,
        );
        const shortLineTop = this.changeLength(middleTop, middleBottom, -0.1);
        this.drawLine(canvas, shortLineTop.p1, shortLineTop.p2, color);

        const shortLineBottom = this.changeLength(middleBottom, middleTop, -0.1);
        this.drawLine(canvas, shortLineBottom.p1, shortLineBottom.p2, color);

        const middleLeft = this.getMiddlePoint(
            code.location.topLeftCorner,
            code.location.bottomLeftCorner,
        );
        const middleRight = this.getMiddlePoint(
            code.location.topRightCorner,
            code.location.bottomRightCorner,
        );
        const shortLineLeft = this.changeLength(middleLeft, middleRight, -0.1);
        this.drawLine(canvas, shortLineLeft.p1, shortLineLeft.p2, color);

        const shortLineRight = this.changeLength(middleRight, middleLeft, -0.1);
        this.drawLine(canvas, shortLineRight.p1, shortLineRight.p2, color);
    },

    getMiddlePoint(p1, p2) {
        return { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 };
    },

    changeLength(p1, p2, multiplier) {
        return {
            p1: p1,
            p2: { x: p1.x + (p2.x - p1.x) * multiplier, y: p1.y + (p2.y - p1.y) * multiplier },
        };
    },

    stopCamera() {
        if (get(this, 'video')) {
            get(this, 'video').pause();
            get(this, 'video').srcObject = null;
        }

        if (get(this, 'stream')) get(this, 'stream').getTracks()[0].stop();
    },

    codeFound() {
        this.stopCamera();

        const code = get(this, 'url');
        const parsed = this.parseCode(code);

        if (parsed === null) {
            set(this, 'unrecogniced', true);
            return;
        }

        if (get(this, 'autotransition') && parsed.type === 'transition') {
            get(this, 'router').transitionTo(parsed.path);
        }

        get(this, 'onSuccess')(code, parsed);
    },

    parseCode(code) {
        let path = null;

        if (code.startsWith('https://tt.movenium.com/')) {
            path = code.substring(25);
        } else if (code.startsWith('https://app.movenium.com/')) {
            path = code.substring(26);
        }

        if (path) {
            return { type: 'transition', path: path };
        }

        return null;
    },

    _setStream(stream, video) {
        set(this, 'stream', stream);
        video.srcObject = stream;
        video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
        video.play();
        requestAnimationFrame(this.tick.bind(this));
    },
});
