import Service, { inject as service } from '@ember/service';

interface ILocalStorage {
    setItem(key: string, value: any): void;
    getItem(key: string): null | JSON;
    removeItem(key: string): void;
}

export default class LocalstorageService extends Service implements ILocalStorage {
    @service private session!: any;

    private namespace: string = 'tt4';

    public setItem(key: string, value: any): void {
        localStorage.setItem(this.createKey(key), JSON.stringify(value));
    }

    public getItem(key: string) {
        const str = localStorage.getItem(this.createKey(key)) as string;
        return JSON.parse(str);
    }

    public removeItem(key: string) {
        localStorage.removeItem(this.createKey(key));
    }

    public createKey(str: string) {
        const currentuser = this.session.currentUser?.id;
        if (!currentuser)
            throw new Error('Cannot use localstorage service because currentUser is not set');
        return this.namespace + '.' + currentuser + '.' + str;
    }
}
