import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    collector: service('collector-service'),

    actionList: null,
    selectedActions: null,

    jsonstr: '',

    hook: computed(
        'url',
        'long',
        'delay',
        'randomdelay',
        'delayTime',
        'selectedActions.[]',
        'sideloads',
        'sideloadstr',
        function () {
            const settings = [];
            if (get(this, 'delay'))
                settings.push(
                    (get(this, 'randomdelay') ? 'randomdelay:' : 'delay:') + get(this, 'delayTime'),
                );
            if (get(this, 'long')) settings.push('long');

            const hook = {
                url: get(this, 'url'),
                actions: get(this, 'selectedActions'),
                settings: settings,
            };

            if (get(this, 'sideloads'))
                hook.sideloads = get(this, 'sideloadstr')
                    ? get(this, 'sideloadstr').split(',')
                    : [];

            hook.v = 2;

            return hook;
        },
    ),

    json: computed('hook', function () {
        return JSON.stringify(get(this, 'hook'));
    }),

    init() {
        this._super();

        set(this, 'texts', {
            url: 'URL',
            actions: 'Actions',
            long: 'Long',
            long_help: 'If webhook execution can take over 6s this should be on.',
            delay: 'Delay',
            delay_help: 'Wait some amount of time before executing webhook.',
            delay_seconds: 'Give delay time in seconds:',
            delay_randomize: 'Randomize:',
            sideloads: 'Sideloads',
        });

        if (get(this, 'jsonstr')) this.load_hook(JSON.parse(get(this, 'jsonstr')));
    },

    didReceiveAttrs() {
        const actionList = ['insert', 'delete', 'edit'];
        const fields = get(this, 'collector').fieldArray(get(this, 'form'));
        fields.forEach((item) => actionList.push('edit.' + item.name));

        fields.forEach((item) => actionList.push('insert.' + item.name));

        set(this, 'actionList', actionList);
    },

    actions: {
        save() {
            this.save(get(this, 'json'));
        },

        createOnEnter(select, e) {
            if (e.keyCode === 13 && select.isOpen && !select.highlighted && select.searchText) {
                if (!get(this, 'selectedActions').includes(select.searchText)) {
                    get(this, 'actionList').pushObject(select.searchText);
                    select.actions.choose(select.searchText);
                }
            }
        },
    },

    load_hook(hook) {
        set(this, 'selectedActions', hook.actions);
        set(this, 'url', hook.url);

        if (hook.settings.includes('long')) set(this, 'long', true);

        if (hook.sideloads) {
            set(this, 'sideloads', true);
            set(this, 'sideloadstr', hook.sideloads.join(','));
        }

        const delay = hook.settings.find((item) => item.startsWith('delay:'));
        const randomdelay = hook.settings.find((item) => item.startsWith('randomdelay:'));

        if (randomdelay) {
            set(this, 'delay', true);
            set(this, 'randomdelay', true);
            set(this, 'delayTime', randomdelay.substring(12));
        } else if (delay) {
            set(this, 'delay', true);
            set(this, 'delayTime', delay.substring(6));
        }
    },
});
