import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    session: service(),
    evented: service(),
    ttapi: service(),
    intl: service(),
    collector: service('collector-service'),
    flags: service(),

    init() {
        this._super(...arguments);
        this.headers = ['name', 'hours'];
        this.holidaypay =
            this.Collector.testNeedsOne('modules.holiday_pay') && !this.flags.test('holidaypay_v2');
        if (this.holidaypay) this.headers.push('days');
        this.form = 'bank';
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    },

    didInsertElement() {
        set(this, 'data', this.getData());
    },

    didDestroyElement() {
        this._super(...arguments);
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    },

    onStoreEvent(params) {
        if (params.type === 'abcense' || params.type === 'worktime')
            set(this, 'data', this.getData());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'data', this.getData());
    },
    async getData() {
        let data = await this.ttapi.request(
            '/api/getBankHours?user=' + this.session.currentUser.id,
        );
        if (this.holidaypay) data.pushObject(await this.getHolidayPay());
        return data;
    },

    async getHolidayPay() {
        let year = moment().year() - 2;
        let params = {
            id: this.session.currentUser.id,
            start: year + '-05-02',
            country: this.session.currentUser.company_info.country,
        };
        const url = 'absence/getUsersWithHolidays';
        let holidays = await this.collector.ttapi({
            url: url,
            data: params,
        });
        let name = this.intl.t('worktimebank.comp.holidays.extra_pay');
        return { name: name, days: holidays.users.firstObject.holidayPays };
    },
});
