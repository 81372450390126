/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddController;

import Ember from 'ember';

UsersAddController = Ember.Controller.extend({
    session: Ember.inject.service(),
    saved: Ember.inject.controller(),
});

export default UsersAddController;
