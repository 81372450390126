import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    gui: service(),
    store: service(),

    currentPage: 1,

    currentPageComponent: computed('currentPage', function () {
        return 'wizard-builder/vismanet-wizard/page' + get(this, 'currentPage');
    }),

    init() {
        this._super();
        if (get(this, 'gui.saveVismanetCode')) set(this, 'currentPage', 2);

        this.checkNormalHoursSalarytypeIsOn();
    },

    checkNormalHoursSalarytypeIsOn() {
        get(this, 'store')
            .query('salarytype', { shortcut: 'hours_hourly' })
            .then(this.turnOnNormalHoursSalaryTypeIfNotOn.bind(this));
    },

    turnOnNormalHoursSalaryTypeIfNotOn(rows) {
        if (!get(rows, 'firstObject')) this.turnOnNormalHoursSalaryType();
    },

    turnOnNormalHoursSalaryType() {
        get(this, 'store')
            .createRecord('salarytype', {
                control: 'work_hours;salarytype=$this',
                inuse: true,
                key: 'VT',
                name: 'Tuntityö',
                shortcut: 'hours_hourly',
            })
            .save();
    },
});
