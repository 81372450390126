/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/notes-anmalan/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryNotesAnmalanController;

import Ember from 'ember';

import direktivController from '../notes-direktiv/controller';

WorksiteDiaryNotesAnmalanController = direktivController.extend({
    type: 'anmalan',
});

export default WorksiteDiaryNotesAnmalanController;
