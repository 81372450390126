import { warn } from '@ember/debug';
import Ember from 'ember';

export default function missingMessage(key, locales) {
    if (key && typeof key === 'string' && key.includes('.') && !Ember.testing && Ember.devModeOn) {
        warn(`Missing translation for key "${key}" in locale "${locales}"`, {
            id: 'missing-translation',
        });
    }
    return key;
}
