/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/ordered-changes/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksiteDiaryReviewController;

import Ember from 'ember';

WorksiteDiaryReviewController = Ember.Controller.extend({
    session: Ember.inject.service(),
    addController: Ember.inject.controller('worksitediary.report.add'),
    store: Ember.inject.service(),
    ordered_changes_list: Ember.computed.alias('addController.model.diaryreport_misc'),
    show_modal: false,
    actions: {
        updateField: function (value, field) {
            return this.set('model.' + field, value);
        },
        edit_event: function (event) {
            this.set('record', event);
            this.set('show_modal', true);
            return false;
        },
        removeField: function (id) {
            var mat;
            this.set('show_modal', false);
            this.set('record', null);
            mat = this.store.peekRecord('diaryreport_misc', id);
            if (mat) {
                return mat.destroyRecord();
            }
        },
        show_add_event_popup: function () {
            var record;
            record = this.store.createRecord('diaryreport_misc');
            this.set('record', record);
            this.set('show_modal', true);
            return false;
        },
        closeModal: function (settings, record) {
            this.get('ordered_changes_list').pushObject(record);
            return this.set('show_modal', false);
        },
    },
});

export default WorksiteDiaryReviewController;
