import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<TagInput\n  @tags={{@value}}\n  @addTag={{fn this.addTag}}\n  @removeTagAtIndex={{fn this.removeTagAtIndex}}\n  @onKeyUp={{fn this.queryForUser}}\n  as |tag|>\n    <span class={{if tag.error \"error\"}}>{{tag.tagName}}</span>\n</TagInput>\n{{#if this.showDropdown}}\n    {{#if this.options.length}}\n        <div class=\"ajax-select\">\n            <div class=\"options\" id=\"tag-list-dropdown\">\n                <ul>\n                    {{#each this.options as |opt|}}\n                        <li role=\"button\" {{on \"click\" (fn this.addTag opt)}}>\n                            {{opt}}\n                        </li>\n                    {{/each}}\n                </ul>\n            </div>\n        </div>\n    {{/if}}\n{{/if}}", {"contents":"<TagInput\n  @tags={{@value}}\n  @addTag={{fn this.addTag}}\n  @removeTagAtIndex={{fn this.removeTagAtIndex}}\n  @onKeyUp={{fn this.queryForUser}}\n  as |tag|>\n    <span class={{if tag.error \"error\"}}>{{tag.tagName}}</span>\n</TagInput>\n{{#if this.showDropdown}}\n    {{#if this.options.length}}\n        <div class=\"ajax-select\">\n            <div class=\"options\" id=\"tag-list-dropdown\">\n                <ul>\n                    {{#each this.options as |opt|}}\n                        <li role=\"button\" {{on \"click\" (fn this.addTag opt)}}>\n                            {{opt}}\n                        </li>\n                    {{/each}}\n                </ul>\n            </div>\n        </div>\n    {{/if}}\n{{/if}}","moduleName":"tt4/components/addon/fields/tag-input.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/tag-input.hbs"}});
import Component from '@glimmer/component';
import { validateEmail } from 'tt4/ember-movenium/utils/validations';
import DS from 'ember-data';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import { A } from '@ember/array';
import MutableArray from '@ember/array/mutable';

interface ITagInput {
    form: string;
    field: string;
    name: string;
    value?: string[];
    onChange(arg0: MutableArray<ITag | string> | string): void;
}

interface ITag {
    tagName: string;
    error: boolean;
    removeAt(args0: number): void;
}

export default class FieldsTagInputComponent extends Component<ITagInput> implements ITagInput {
    @tracked public showDropdown: boolean = false;
    @tracked public tags: MutableArray<ITag | string> = A([]);
    @tracked public options: MutableArray<string> = A([]);
    @service declare store: DS.Store;

    constructor(owner: unknown, args: ITagInput) {
        super(owner, args);
    }

    get form() {
        return this.args.form;
    }

    get field() {
        return this.args.field;
    }

    get name() {
        return this.args.name;
    }

    @action public onChange(tags: MutableArray<ITag | string>) {
        return this.args.onChange(tags);
    }

    @action public addTag(tag: string) {
        debounce(this, this.addTagToList, tag, 120);
    }

    @action public removeTagAtIndex(index: number) {
        this.tags.removeAt(index);
        this.args.onChange(this.tags as MutableArray<ITag>);
    }

    @action public queryForUser(currentInputValue: string) {
        if (currentInputValue === '') return;
        debounce(this, this.queryBackend, currentInputValue, 700);
    }

    protected addTagToList(tag: string) {
        const tagObj = {
            tagName: tag,
            error: !validateEmail(tag),
        } as ITag;
        this.showDropdown = false;
        this.tags.pushObject(tagObj as ITag);
        this.onChange(this.tags as MutableArray<ITag>);
    }

    private async queryBackend(currentInputValue: string) {
        this.showDropdown = false;
        if (!this.form) return;
        this.options = A([]);
        const query = await this.store.query(this.form, { q: currentInputValue });
        this.showDropdown = true;
        query.forEach((result) => {
            if (result[this.field]) {
                this.options.pushObject(result[this.field]);
            }
        });
    }
}
