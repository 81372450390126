/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/projects/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var ProjectsAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

ProjectsAddRoute = GeneralAddRoute.extend({
    modelName: 'project',
    controllerName: 'projects.add',
    sharedAfterSave: 'shared.project',
    savedRoute: 'projects.saved',
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
        save: function (isPromiseResolved = false) {
            var address, city, ctrl, isUniqueAddress, m, zip;
            ctrl = this.controllerFor('projects.add');
            if (isPromiseResolved) {
                this._super();
                return ctrl.get('errors').removeObject('projectExists');
            } else {
                isUniqueAddress = true;
                m = this.currentModel;
                address = m.get('address');
                zip = m.get('zip');
                city = m.get('city');
                return this.store
                    .query('project', {
                        address: address,
                    })
                    .then((projects) => {
                        projects.every((project) => {
                            if (
                                (project.get('address') === address &&
                                    address &&
                                    project.get('city') === city &&
                                    city) ||
                                (project.get('zip') === zip && zip)
                            ) {
                                isUniqueAddress = false;
                                this.get('dialogs')
                                    .confirm(this.get('intl').t('project.add.project_exists_error'))
                                    .then((con) => {
                                        if (con) {
                                            return this.send('save', true);
                                        } else {
                                            ctrl.get('errors').pushObject('projectExists');
                                            return this.send('save', true);
                                        }
                                    });
                            }
                            return isUniqueAddress; //stop loop when address is found
                        });
                        if (isUniqueAddress) {
                            return this.send('save', true);
                        }
                    });
            }
        },
    },
});

export default ProjectsAddRoute;
