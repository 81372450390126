/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/signature-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsSignatureFieldComponent;

import Ember from 'ember';

import baseField from '../base-field/component';

import config from '../../../../config/environment';

import SignaturePad from 'signature_pad';

import $ from 'jquery';

CollectorFieldsSignatureFieldComponent = baseField.extend({
    intl: Ember.inject.service(),
    signature_pad: null,
    title: Ember.computed(function () {
        return this.get('intl').t('modal.signature.title');
    }),
    sig: null,
    signatureSrc: Ember.computed('value', function () {
        if (typeof this.get('value') === 'string') {
            return this.get('value');
        }
        if (Ember.isArray(this.get('value'))) {
            return config.APP.COLLECTOR_API_URL + '/' + this.get('value')[0].url;
        }
        return config.APP.COLLECTOR_API_URL + '/' + this.get('value').url;
    }),
    placeholder: Em.computed(function () {
        if (this.get('placeholderText')) {
            return this.get('placeholderText');
        } else {
            return this.get('intl').t('field.signature.placeholder');
        }
    }),
    actions: {
        showSignModal: function () {
            return this.set('showModal', true);
        },
        save: function () {
            var signature_pad;
            signature_pad = this.get('signature_pad');
            if (signature_pad.isEmpty()) {
                this.set('sig', null);
                this.sendAction('bubbleChange', null, this.get('field.name'));
            } else {
                this.set('sig', signature_pad.toDataURL());
                this.sendAction('bubbleChange', signature_pad.toDataURL(), this.get('field.name'));
            }
            return this.set('showModal', false);
        },
        clear: function () {
            var signature_pad;
            signature_pad = this.get('signature_pad');
            return signature_pad.clear();
        },
        didinsert: function () {
            var canvas, signature_pad;
            canvas = $('canvas').get(0);
            signature_pad = new SignaturePad(canvas);
            if (this.get('sig')) {
                signature_pad.fromDataURL(this.get('sig'));
            }
            return this.set('signature_pad', signature_pad);
        },
        removeModal: function () {
            if (this.get('isDestroyed')) {
                return;
            }
            return this.set('showModal', false);
        },
    },
});

export default CollectorFieldsSignatureFieldComponent;
