/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-card-v2/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarCardComponent;

import Ember from 'ember';

CalendarCardComponent = Ember.Component.extend({
    Collector: Ember.inject.service('collector-service'),
    classNames: ['calendar-card'],
    _data: Ember.computed('day', 'data.@each.date', function () {
        return this.getData();
    }),
    _fields: Ember.computed('fields', function () {
        var boldArray, fields;
        fields = this.get('fields');
        boldArray = this.get('boldFields') ? this.get('boldFields').split(' ') : [];
        this.set('boldArray', boldArray);
        if (Ember.typeOf(fields) === 'string') {
            Em.assert(
                'You need to provide the form if giving fields to calendar-card as string',
                this.get('form'),
            );
            fields = this.get('Collector').fieldArray(this.get('form'), fields.split(','));
        }
        fields.forEach((item, i, arr) => {
            if (boldArray.includes(item.name)) {
                return arr.push(arr.splice(i, 1)[0]);
            }
        });
        return fields;
    }),
    getData: function () {
        var data;
        data = this.get('data');
        if (data) {
            return data.filterBy(this.get('date_field'), this.get('day'));
        }
    },
    actions: {
        click: function (row) {
            if (this.get('onclick')) {
                return this.onclick(row);
            }
        },
        removeRecord: function (row) {
            if (this.get('remove')) {
                return this.remove(row);
            }
        },
    },
});

export default CalendarCardComponent;
