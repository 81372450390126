/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/models/messageboard.coffee.old
// Generated by CoffeeScript 2.6.1
var Messageboard;

import DS from 'ember-data';

Messageboard = DS.Model.extend({
    notify: DS.attr('string'),
    header: DS.attr('string'),
    content: DS.attr('string'),
    forced: DS.attr('string'),
    multirowlink: DS.attr('string'),
});

export default Messageboard;
