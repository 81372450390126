/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/collector-validation.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorValidationMixin;

import Ember from 'ember';

import collector from '../classes/collector';

import BaseValidator from '../mixins/base-validation';

import $ from 'jquery';

const { getOwner } = Ember;

CollectorValidationMixin = Ember.Mixin.create(BaseValidator, {
    // Properties

    // Collector form for fields that we are validating
    form: null,
    defaultValidations: {
        username: {
            mandatory: true,
            username_free: true,
            username: true,
        },
        password: {
            mandatory: true,
            password: true,
        },
    },

    // Methods

    // Validate method that we can call to initialize a validation
    validate: function (fields = []) {
        var c, collectorFields, form, validateThese;
        // Only fetch validations if they are not set already
        if (!this.get('validations')) {
            // Get needed properties
            form = this.get('constructor.modelName');
            c = getOwner(this).lookup('service:collectorService');
            // We need to check if "modelName" is set on the object, without this we cannot do anything
            Em.assert('You need to have "modelName" on the object for validations to work', form);
            // Get the fields and their settings from collector service
            collectorFields = c.fieldArray(form);
            validateThese = this.getFieldsToValidate(collectorFields);
            this.set('validations', validateThese);
        }
        return this._super(fields);
    },
    // Check what fields have some kind of validation need and skip the rest
    getFieldsToValidate: function (fields) {
        var defaultValidations, ret;
        ret = {};
        defaultValidations = this.get('defaultValidations');
        fields.forEach((options) => {
            var formatted, method, ref, results, value;
            if (options.features.hide === true) {
                return;
            }
            if (!$.isEmptyObject(options.features)) {
                formatted = this.convertValidations(options.features);
                if (formatted) {
                    ret[options.name] = formatted;
                }
            }
            if (options.type in defaultValidations) {
                if (!(options.name in ret)) {
                    ret[options.name] = {};
                }
                ref = defaultValidations[options.type];
                results = [];
                for (method in ref) {
                    value = ref[method];
                    results.push((ret[options.name][method] = value));
                }
                return results;
            }
        });
        return ret;
    },
    // Format collector validations to a more generic style, ie. make validate_check:minLength:15 to better format
    convertValidations: function (options) {
        var i, item, item_arr, len, ref, validator;
        if (!options.validate_check && !options.mandatory && !options.unique) {
            return;
        }
        validator = {};
        if (options.validate_check) {
            if (Array.isArray(options.validate_check)) {
                ref = options.validate_check;
                for (i = 0, len = ref.length; i < len; i++) {
                    item = ref[i];
                    if (item.substring(0, 10) === 'exact_len:') {
                        item_arr = item.split(':');
                        validator.length = parseInt(item_arr[1], 10);
                    }
                    if (item.substring(0, 9) === 'minLength') {
                        item_arr = item.split(':');
                        validator.minLength = parseInt(item_arr[1], 10);
                    }
                    if (item.substring(0, 9) === 'maxLength') {
                        item_arr = item.split(':');
                        validator.maxLength = parseInt(item_arr[1], 10);
                    }
                    if (item === 'number') {
                        validator.number = true;
                    }
                    if (item === 'email') {
                        validator.email = true;
                    }
                    if (item === 'multipleHours') {
                        validator.multipleHours = true;
                    }
                    if (item === 'birthdate') {
                        validator.birthdate = true;
                    }
                    if (item.substring(0, 6) === 'remote') {
                        item_arr = item.split(':');
                        validator[item_arr[1]] = true;
                    }
                    if (item.substring(0, 5) === 'match') {
                        item_arr = item.split(':');
                        validator.match = item_arr[1];
                    }
                    if (item === 'digits') {
                        validator.digits = true;
                    }
                    if (item.substring(0, 3) === 'PIN') {
                        item_arr = item.split(':');
                    }
                    if (item.substring(0, 10) === 'date_after') {
                        item_arr = item.split(':');
                        validator.date_after = item_arr[1];
                    }
                    if (item === 'hoursAndMinutes') {
                        validator.hoursAndMinutes = true;
                    }
                }
            } else if (options.validate_check.indexOf(':') > -1) {
                item_arr = options.validate_check.split(':');
                validator[item_arr[0]] = item_arr[1];
            } else {
                validator[options.validate_check] = true;
            }
        }
        if (options.mandatory) {
            validator.mandatory = options.mandatory;
        }
        if (options.unique) {
            validator.unique = options.unique;
        }
        return validator;
    },
    // Set the errors
    setError: function (field, message) {
        //# We can't set error on a deleted model
        if (this.get('currentState.stateName') === 'root.deleted.saved') {
            return;
        }
        //# Set to dirty state because if validation errors found on record
        //# that has no changes we get error
        if (this.get('currentState.stateName') === 'root.loaded.saved') {
            this.send('becomeDirty');
        }
        return this.get('errors').add(field, message);
    },
    // Clear the errors
    clearErrors: function (fields) {
        var errors, field, i, len, results;
        if (fields.length > 0) {
            results = [];
            for (i = 0, len = fields.length; i < len; i++) {
                field = fields[i];
                errors = this.get('errors.' + field);
                if (errors) {
                    results.push(errors.clear());
                } else {
                    results.push(void 0);
                }
            }
            return results;
        } else {
            return this.get('errors').clear();
        }
    },
});

export default CollectorValidationMixin;
