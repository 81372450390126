import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import podNames from 'ember-component-css/pod-names';
import runAutomaticSalarytypes from '../runAutomaticSalarytypes';

export default class SalarytypesSalariesComponent extends Component {
    @service evented;
    @service store;
    @service intl;
    @service('collector-service') Collector;
    @service ttapi;
    @service notifications;
    @tracked showPassive;
    @tracked record;
    @tracked saving;
    @tracked showTooltip;
    @tracked error;
    @tracked selectedWorktimegroups;
    @tracked worktimegroupError;
    @tracked salaryTypeNameError;
    @tracked salarytypev2;
    @tracked showReport = true;

    constructor() {
        super(...arguments);
        this.filters = this.args.worktimegroup ? { worktimegroup: this.args.worktimegroup } : {};
        this.showPassive = false;
        this.showTooltip = {};
        this.createTypes();
        this.init();
    }

    async init() {
        this.showReport = false;
        await runAutomaticSalarytypes(this.ttapi);
        this.showReport = true;
    }

    get worktimeGroupLocked() {
        return isPresent(this.args.worktimegroup);
    }

    get styleNamespace() {
        return podNames['settings/general-v2/components/salarytypes/salaries'];
    }

    async createTypes() {
        const showTypes = ['hours', 'dropdown_values', 'checkbox', 'number'];
        const fields = this.Collector.fieldArray('worktime');
        const cond = (item) => item.name === 'work_hours' || item.name === '_basichours';

        let hours = [];
        let options = [];
        for (let item of fields) {
            if (cond(item)) {
                hours.push(item);
            } else {
                options.push(item);
            }
        }

        // always hide all fields that we know shouldn't be visible .. but also fields that has fieldtype: auto (this is to hide custom compensations)
        const autoSalaryTypes = await this.getAutoSalarytypes();
        const staticBlackList = [
            'comp',
            '_50',
            '_100',
            '_period50',
            '_period100',
            '_evening',
            '_night',
            '_week50',
            '_week100',
            '_extrahours',
            '_overtimehours',
            'km',
            '_saturday',
            '_sunday',
            '_50_saldo',
            '_100_saldo',
            '_extrahours_saldo',
            '_period50_saldo',
            '_period100_saldo',
            '_overtimehours_saldo',
            '_evening_saldo',
            '_night_saldo',
            '_week50_saldo',
            '_week100_saldo',
            '_saturday_saldo',
            '_sunday_saldo',
        ];
        const blackList = staticBlackList.concat(autoSalaryTypes);
        options = options
            .filter((item) => !blackList.includes(item.name))
            .filter((item) => showTypes.includes(item.type));

        hours = hours.map((el) => {
            if (el.name === 'work_hours') {
                el.translated_name = this.intl.t('salarytypev2.fieldtype.hours.own');
            }
            return el;
        });

        let subcategory = [
            {
                translated_name: this.intl.t('salarytypev2.fieldtype.allfields'),
                options,
            },
        ];

        this.types = {
            hours,
            subcategory,
        };
    }
    async getAutoSalarytypes() {
        let typesWithSaldo = [];
        let types = this.store.peekAll('salarytypev2')?.filterBy('fieldtype', 'auto');
        if (isEmpty(types))
            types = await this.store.query('salarytypev2', { fieldtype: 'auto', limit: 'all' });
        const mappedTypes = types.mapBy('type');
        // We also need to check that custom compensation's saldo fields are not added to type dropdown (eg. _paivalisa_saldo)
        if (!isEmpty(mappedTypes)) {
            mappedTypes.forEach((type) => {
                typesWithSaldo.push(type);
                if (!['work_hours', '_basichours'].includes(type))
                    typesWithSaldo.push(type + '_saldo');
            });
            return typesWithSaldo;
        }
        return mappedTypes;
    }

    async runAutomaticSalarytypes() {
        this.showReport = false;
        await this.ttapi.post('/api/salary/automaticSalarytypes');
        this.showReport = true;
    }

    @action getReportValue(field, value, unformatted, row) {
        if (field === 'fieldtype') {
            if (!row.type) return null; // if row has no type at all show user as empty fieldtype
            // if own custom field
            if (unformatted === 'hours.own' && !['work_hours', '_basichours'].includes(row.type)) {
                return this.intl.t('salarytypev2.fieldtype.custom_own_field');
                // if work hours auto-field
            } else if (row.type === 'work_hours' && unformatted === 'auto') {
                return this.intl.t('salarytypev2.fieldtype.auto.hours');
                // if basichours auto-field
            } else if (row.type === '_basichours' && unformatted === 'auto') {
                return this.intl.t('salarytypev2.fieldtype.auto.basic_hours');
            } else {
                if (!value) return this.intl.t('salarytypev2.fieldtype.manual');
                else if (row.type === '_basichours') return this.intl.t('worktime._basichours');
                else return value;
            }
        } else return value;
    }

    @action
    toggleRemoved() {
        this.showPassive = !this.showPassive;
        this.evented.storeEvent('toggleRemoved', 'salarytypev2', null);
    }

    @action
    closeModal() {
        if (this.record && this.record.isNew) this.record.deleteRecord();
        else if (this.record) this.record.rollbackAttributes();
        this.evented.storeEvent('insert', 'salarytypev2', null);
        this.record = null;
        this.salaryTypeNameError = null;
    }

    @action
    onFieldChange(field, value) {
        if (field === 'worktimegroup' && this.record.isNew) {
            this.worktimegroupError = null;
            this.selectedWorktimegroups = value;
            this.selectedWorktimegroups = this.selectedWorktimegroups;
        } else this.record[field] = value;
    }

    @action
    async save() {
        this.saving = true;
        try {
            if (!(await this.isValidRecord())) return (this.saving = false);

            if (this.record.isNew) {
                for (let wtgroup of this.selectedWorktimegroups) {
                    const newRecord = this.Collector.copyRecord(this.record);
                    newRecord['worktimegroup'] = wtgroup;
                    newRecord['fieldtype'] = 'hours.own';
                    await newRecord.save();
                }
            } else await this.record.save();
            this.closeModal();
        } catch (e) {
            this.error = e;
        } finally {
            this.saving = false;
        }
    }

    @action
    addSalarytype() {
        this.error = null;
        this.selectedWorktimegroups = this.args.worktimegroup
            ? [this.store.peekRecord('worktimegroup', this.args.worktimegroup)]
            : [];
        this.record = this.store.createRecord('salarytypev2');
    }

    @action
    editSalarytype(row) {
        this.error = null;
        this.selectedWorktimegroups = row['worktimegroup'] ? [row['worktimegroup']] : [];
        this.record = row;
    }

    @action
    removeSalarytype(row) {
        if (row.fieldtype === 'auto.hours')
            return this.notifications.warning(this.intl.t('salarytypes.warning_remove_automatic'), {
                autoClear: true,
            });
        this.evented.storeEvent('remove', 'salarytypev2', row);
    }

    @action
    restoreSalarytype(row) {
        this.evented.storeEvent('restore', 'salarytypev2', row);
    }

    @action
    togglePopover(id) {
        if (!isPresent(this.showTooltip[id])) this.showTooltip[id] = true;
        else this.showTooltip[id] = !this.showTooltip[id];
        this.showTooltip = this.showTooltip;
    }

    async isValidRecord() {
        this.salaryTypeNameError = '';

        if (this.record.isNew) {
            const p1 = await this.store.query('salarytypev2', { name: this.record.name });

            if (p1?.length > 0) {
                let wortimeGroups = this.selectedWorktimegroups.map((item) => Number(item.id));

                for (let item of p1.toArray()) {
                    if (wortimeGroups.includes(Number(item.worktimegroup.id))) {
                        const wg = this.store.peekRecord('worktimegroup', item.worktimegroup.id);
                        this.salaryTypeNameError +=
                            this.intl.t('salarytypev2.error_exists_for_worktimegoup', {
                                worktimegroup: wg.name,
                                salarytype: this.record.name,
                            }) + ' ';
                    }
                }
                if (this.salaryTypeNameError !== '') return false;
            }

            await this.record.validate(['name', 'type', 'key']);
            if (isEmpty(this.selectedWorktimegroups)) {
                this.worktimegroupError = this.intl.t('field.error.mandatory');
                return false;
            }
        } else await this.record.validate();

        if (!this.record.isValid) return false;

        return true;
    }
}
