import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment';

export default class CopyComponent extends Component {
    @tracked showAdd;
    @tracked record;
    @tracked canCopy;
    @tracked showCopy;

    @service router;
    @service store;
    @service notifications;
    @service('collector-service') collector;
    @service intl;

    newestWorktime = null;

    constructor() {
        super(...arguments);
        this.findPreviousWorktime();
    }

    /**
     * first we check user has rights to add worktime to this day
     * then we try to find last worktime with peekAll so we could so the copying button sychronically
     * and the last resort is to fetch the previous worktime from capi
     */
    async findPreviousWorktime() {
        if (!this.collector.testNeeds(['worktime'])) return; // disable if no worktime form found
        if (!this.collector.canAddWorktime(this.args.day)) return; // disable if user cannot add worktime for this day
        if (this.collector.testNeeds(['products.timetracker_clockcard', 'userlevel=1?'])) return; // disable if user has clockcard in use

        this.showCopy = true;

        const worktimes = this.store.peekAll('worktime');
        const ownSavedWorktimes = worktimes.filter(
            (worktime) => worktime.user.id === this.args.user && !worktime.isNew,
        );

        ownSavedWorktimes.forEach((worktime) => {
            if (worktime.date > this.args.day) return; // skip newer worktimes than current day
            if (
                this.newestWorktime &&
                (this.newestWorktime.date > worktime.date ||
                    this.newestWorktime.starttime > worktime.starttime)
            )
                return; // newestWorktime already is newer
            this.newestWorktime = worktime;
        });

        // if newestWorktime hasn't been found with peekAll we try to fetch it from capi
        if (!this.newestWorktime) {
            const worktimesArray = await this.store.query('worktime', {
                user: this.user,
                date:
                    moment(this.args.day).subtract(14, 'days').format('YYYY-MM-DD') +
                    '_' +
                    moment(this.args.day).format('YYYY-MM-DD'),
                order: 'date-,starttime-',
                limit: 1,
            });
            if (worktimesArray.length > 0) this.newestWorktime = worktimesArray.firstObject;
        }

        if (this.newestWorktime) this.canCopy = true;
    }

    @action
    notifySaved() {
        this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        this.args.rowAdded();
    }

    @action
    copy() {
        this.record = this.collector.copyRecord(this.newestWorktime);
        this.record.date = this.args.day;
        this.showAdd = true;
    }
}
