/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/helpers/user-icon.coffee.old
// Generated by CoffeeScript 2.6.1
var UserIconHelper;

import Ember from 'ember';

import config from '../config/environment';

UserIconHelper = Ember.Helper.extend({
    Collector: Ember.inject.service('collector-service'),
    // This function receives the params `params, hash`
    compute: function (params, hash) {
        var c, color, image, inline, ret, rndColor, size;
        c = this.get('Collector');
        // params[0] = image
        // params[1] = alternative text
        // params[2] = color
        size = hash.size ? 'icon-size-' + hash.size : '';
        inline = hash.inline ? 'display: inline-block; ' : '';
        if (params[0]) {
            if (params[0].substring(0, 4) === 'http') {
                image = params[0];
            }
            image = config.APP.COLLECTOR_API_URL + '/' + params[0];
            ret =
                "<div class='user-icon img-circle " +
                size +
                "' style='" +
                inline +
                'background-image:url("' +
                image +
                '")\'>&nbsp;</div>';
        } else {
            if (!hash.text || hash.text === ' ') {
                return '';
            }
            color = hash.color;
            if (!color) {
                rndColor = c.randomizeColor(hash.text);
                color = rndColor;
            }
            ret =
                "<div class='user-icon img-circle " +
                size +
                "' style='" +
                inline +
                'background-color:' +
                color +
                "'>" +
                c.shortFormat(hash.text, 2) +
                '</div>';
        }
        return new Ember.String.htmlSafe(ret);
    },
});

export default UserIconHelper;
