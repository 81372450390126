/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/servicehandler.coffee.old
// Generated by CoffeeScript 2.6.1
var Service;

import Ember from 'ember';

Service = Ember.Service.extend({
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    /*
  Manipulate service to have or to remove fields

  Adding example:
  {"worktime.field": {type: "hours", name: "ownhoursfield"}}

  Removing example:
  {"!worktime.field": {}}

  */
    manipulateService: function (fieldList) {
        console.log('manipulaitn', fieldList);
        return this.get('store')
            .findAll('form')
            .then(() => {
                var keyList;
                keyList = Object.keys(fieldList);
                return this.traverseList(keyList, fieldList);
            });
    },
    traverseList: function (keyList, fieldList) {
        var firstKey, promise;
        if (keyList.length < 1) {
            return;
        }
        firstKey = keyList.shift();
        promise = this._modify(firstKey, fieldList[firstKey]);
        if (promise) {
            return promise.then(() => {
                return this.traverseList(keyList, fieldList);
            });
        } else {
            return this.traverseList(keyList, fieldList);
        }
    },
    _getKeyType: function (key) {
        var splitted;
        splitted = key.split('.');
        if (splitted.length === 1) {
            return 'form';
        } else if (splitted.length === 2) {
            return 'field';
        } else if (splitted.length === 3) {
            return 'fieldSetting';
        }
    },
    _parseKey: function (key) {
        var splitted;
        key = this._cleanKey(key);
        splitted = key.split('.');
        switch (this._getKeyType(key)) {
            case 'field':
                return {
                    form: splitted[0],
                    field: splitted[1],
                };
            case 'fieldSetting':
                return {
                    form: splitted[0],
                    field: splitted[1],
                    setting: splitted[2].substring(1),
                };
        }
    },
    _isKeyNegation: function (key) {
        return key.substring(0, 1) === '!';
    },
    _cleanKey: function (key) {
        if (this._isKeyNegation(key)) {
            return key.substring(1);
        } else {
            return key;
        }
    },
    _modify: function (key, fieldobj) {
        var isKeyOn;
        switch (this._getKeyType(key)) {
            case 'fieldSetting':
                isKeyOn = this.isFieldSettingOn(this._cleanKey(key));
                break;
            default:
                isKeyOn = this.get('Collector').testNeeds([this._cleanKey(key)]);
        }
        if (!isKeyOn && !this._isKeyNegation(key)) {
            // needs to be turned on
            return this._turnOn(this._cleanKey(key), fieldobj);
        } else if (isKeyOn && this._isKeyNegation(key)) {
            return this._turnOff(this._cleanKey(key), fieldobj);
        }
    },
    _turnOn: function (key, fieldobj) {
        var func;
        console.log('turn on', key);
        func = '_turnOn_' + this._getKeyType(key);
        return this[func](key, fieldobj);
    },
    _turnOff: function (key, fieldobj) {
        var func;
        console.log('turn off', key);
        func = '_turnOff_' + this._getKeyType(key);
        return this[func](key, fieldobj);
    },
    _turnOn_field: function (key, fieldobj) {
        var field, form, nro, parsed;
        parsed = this._parseKey(key);
        form = this.get('store').peekAll('form').findBy('external_type', parsed.form);
        Ember.assert("Form id for form '" + parsed.form + "' not found", form.get('id'));
        nro =
            this.get('store')
                .peekAll('form-field')
                .filterBy('form.id', form.get('id'))
                .sortBy('nro')
                .get('lastObject.nro') + 1;
        field = this.get('store').createRecord('form-field', {
            form: form,
            nro: nro,
            type: fieldobj.type,
            external_type: parsed.field,
            name: fieldobj.name,
        });
        return field.save();
    },
    _turnOff_field: function (key, fieldobj) {
        var field, form, parsed;
        parsed = this._parseKey(key);
        form = this.get('store').peekAll('form').findBy('external_type', parsed.form);
        Ember.assert("Form id for form '" + parsed.form + "' not found", form.get('id'));
        field = this.get('store')
            .peekAll('form-field')
            .filterBy('form.id', form.get('id'))
            .findBy('external_type', parsed.field);
        return field.destroyRecord();
    },
    _turnOn_fieldSetting: function (key, fieldobj) {
        var field, fieldSetting, form, parsed, promise;
        parsed = this._parseKey(key);
        form = this.get('store').peekAll('form').findBy('external_type', parsed.form);
        Ember.assert("Form id for form '" + parsed.form + "' not found", form.get('id'));
        field = this.get('store')
            .peekAll('form-field')
            .filterBy('form.id', form.get('id'))
            .findBy('external_type', parsed.field);
        Ember.assert("Field id for field '" + parsed.field + "' not found", field.get('id'));
        fieldSetting = this.get('store').createRecord('form-field-setting', {
            field: field,
            name: parsed.setting,
            value: fieldobj,
        });
        promise = fieldSetting.save();
        return promise;
    },
    _turnOff_fieldSetting: function (key, fieldobj) {
        var field, fieldSetting, form, parsed;
        parsed = this._parseKey(key);
        form = this.get('store').peekAll('form').findBy('external_type', parsed.form);
        Ember.assert("Form id for form '" + parsed.form + "' not found", form.get('id'));
        field = this.get('store')
            .peekAll('form-field')
            .filterBy('form.id', form.get('id'))
            .findBy('external_type', parsed.field);
        Ember.assert("Field id for field '" + parsed.field + "' not found", field.get('id'));
        fieldSetting = this.get('store')
            .peekAll('form-field-setting')
            .filterBy('field.id', field.get('id'))
            .findBy('name', parsed.setting);
        return fieldSetting.destroyRecord();
    },
    isFieldSettingOn: function (key) {
        var field, fieldSetting, form, parsed;
        parsed = this._parseKey(key);
        form = this.get('store').peekAll('form').findBy('external_type', parsed.form);
        if (!form.get('id')) {
            return false;
        }
        field = this.get('store')
            .peekAll('form-field')
            .filterBy('form.id', form.get('id'))
            .findBy('external_type', parsed.field);
        if (!field.get('id')) {
            return false;
        }
        fieldSetting = this.get('store')
            .peekAll('form-field-setting')
            .filterBy('field.id', field.get('id'))
            .findBy('name', parsed.setting);
        return fieldSetting != null;
    },
});

export default Service;
