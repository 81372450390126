import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class UsersInactiveController extends Controller {
    @service ttapi;

    days = 7;

    constructor() {
        super(...arguments);
        this.endpoint = this.ttapi.host + '/inactive_users_v2';
    }
}
