import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Route.extend({
    ttapi: service(),

    model() {
        return get(this, 'ttapi')._get('notification/categories');
    },
});
