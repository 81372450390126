/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/row-component/component.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyRowComponent;

import Ember from 'ember';

SiteSafetyRowComponent = Ember.Component.extend({
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    didReceiveAttrs: function () {
        if (this.get('model.' + this.get('name') + '_plus')) {
            this.set('plus', this.get('model.' + this.get('name') + '_plus'));
        }
        if (this.get('model.' + this.get('name') + '_minus')) {
            return this.set('minus', this.get('model.' + this.get('name') + '_minus'));
        }
    },
    hasInfo: Em.computed('name', function () {
        return this.get('intl').exists('measurement.info.' + this.get('name'));
    }),
    plus: 0,
    minus: 0,
    minusObserver: function () {
        var value;
        this.set('model.' + this.get('name') + '_minus', this.get('minus'));
        // HTML input type="number" component is poorly implemented so: Limit value to max 9999 && do not accept decimal values && clean number..
        if (this.get('minus').length > 4) {
            this.set('minus', this.get('minus').substring(0, 4));
        }
        if (this.get('minus') > 9999) {
            this.set('minus', 9999);
        }
        value = this.get('minus').toString();
        if (value.indexOf('.') > -1) {
            this.set('minus', 0);
        }
        value = parseInt(this.get('minus'), 10);
        if (isNaN(value)) {
            return this.set('minus', 0);
        } else {
            return this.set('minus', value);
        }
    }.observes('minus'),
    plusObserver: function () {
        var value;
        this.set('model.' + this.get('name') + '_plus', this.get('plus'));
        // HTML input type="number" component is poorly implemented so: Limit value to max 9999 && do not accept decimal values && clean number..
        if (this.get('plus').length > 4) {
            this.set('plus', this.get('plus').substring(0, 4));
        }
        if (this.get('plus') > 9999) {
            this.set('plus', 9999);
        }
        value = this.get('plus').toString();
        if (value.indexOf('.') > -1) {
            this.set('plus', 0);
        }
        value = parseInt(this.get('plus'), 10);
        if (isNaN(value)) {
            return this.set('plus', 0);
        } else {
            return this.set('plus', value);
        }
    }.observes('plus'),
    actions: {
        toggleNotice: function () {
            if (this.get('edit') || this.get('notice')) {
                this.set('edit', false);
                return this.set('notice', false);
            } else {
                this.set('notice', true);
                this.set('edit', true);
                return this.set('show_add_notice', true);
            }
        },
        plus: function () {
            return this.set('plus', 1 + this.get('plus'));
        },
        minus: function () {
            if (!this.get('notice')) {
                this.set('unsavedNotices', true);
                this.set('minus', 1 + this.get('minus'));
                this.set('model.' + this.get('name') + '_minus', this.get('minus'));
                if (this.get('notices_on')) {
                    this.send('addNotice');
                    return this.set('notice', true);
                }
            } else {
                return this.get('notifications').warning(
                    this.get('intl').t('site_safety.notice.unsaved_notification'),
                    {
                        autoClear: true,
                    },
                );
            }
        },
        addNotice: function () {
            var add, row, tmpRows;
            add = true;
            this.get('noticesArr').forEach(function (ntc) {
                if (ntc.get('isDirty')) {
                    return (add = false);
                }
            });
            if (add) {
                this.set('show_add_notice', false);
                row = this.get('store').createRecord('trnotice', {
                    date: moment().add(3, 'days').format('YYYY-MM-DD'),
                    name: this.get('name'),
                });
                // noticesArr is query-result from server, so we cannot add directly to it..
                // this is a bit ugly piece of code, but it works
                if (this.get('noticesArr')) {
                    tmpRows = [];
                    tmpRows.push(row);
                    this.get('noticesArr').forEach((notice) => {
                        return tmpRows.push(notice);
                    });
                    return this.set('noticesArr', tmpRows);
                } else {
                    //@get('noticesArr').pushObject(row)
                    return this.get('noticesArr').pushObject(row);
                }
            }
        },
        openInfo: function (row) {
            return this.get('dialogs').alert(this.get('intl').t('measurement.info.' + row));
        },
    },
});

export default SiteSafetyRowComponent;
