/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/report/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceReportController;

import Ember from 'ember';

import config from '../../../config/environment';

import GeneralReportController from '../../../controllers/general-report';

PresenceReportController = GeneralReportController.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    Collector: Em.inject.service('collector-service'),
    dialogs: Em.inject.service(),
    showColumns: ['date', 'project', 'user', 'employer', 'in', 'out', 'hours', 'status'],
    summableFieldTypes: ['hours'],
    form: 'presence',
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                value: this.get('project'),
            }),
            Em.Object.create({
                field: 'employer',
            }),
            Em.Object.create({
                field: 'user',
            }),
            Em.Object.create({
                field: 'date',
            }),
        ]);
    }),
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: {
                modal: true,
            },
        }),
        sortByColumn: 'date-,in-',
    }),
    ownExports: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                action: 'export_skatteverket',
                text: this.get('intl').t('report.presence.export_skatteverket'),
            }),
        ]);
    }),
    queryParams: ['project', 'date', 'user', 'employer', 'status'],
    project: null,
    user: null,
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    status: 'all',
    actions: {
        closeEditModal: function () {
            this.editRecord.rollbackAttributes();
            return this.set('editRecord', null);
        },
        ownExportAction: function (action) {
            return this.send(action);
        },
        export_skatteverket: function () {
            var c, promise, self;
            self = this;
            if (!this.get('project')) {
                this.get('dialogs').alert(
                    this.get('intl').t('report.presence.export_skatteverket_select_project'),
                );
                return;
            }
            c = this.get('Collector');
            promise = c.ccapi({
                url: 'skatteverketv4?date=' + this.get('date') + '&project=' + this.get('project'),
            });
            return promise.then((data) => {
                var a, company, name;
                if (data.error) {
                    throw new Ember.Error(
                        'skatteverket export (' +
                            data.integration +
                            ') failed: ' +
                            data.error +
                            ', code:' +
                            data.code,
                    );
                    return;
                }
                name = 'movenium_export';
                company = self.get('session.currentUser.company');
                if (company) {
                    name = company;
                }
                a = document.createElement('a');
                a.setAttribute(
                    'href',
                    'data:text/xml;charset=utf-8,\uFEFF' + encodeURIComponent(data),
                );
                //a.href = 'data:attachment/csv,' + encodeURIComponent(csvString);
                a.target = '_blank';
                a.download = name + '_skatteverket.xml';
                document.body.appendChild(a);
                return a.click();
            });
        },
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        exportShare: function () {
            var params, promise;
            params = {
                limit: 'all',
            };
            if (this.qParams) {
                Object.entries(this.qParams).forEach(function (filter) {
                    if (filter[1] && filter[0] !== 'status') {
                        return (params[filter[0]] = filter[1]);
                    }
                });
            }
            if (this.qParams['order']) {
                params.order = this.qParams['order'];
            }
            this.set('showShareModal', true);
            promise = this.get('Collector').ttapi({
                url: 'share/link',
                method: 'post',
                data: {
                    params: params,
                    values: {
                        form: this.get('form'),
                        lang: this.get('intl.primaryLocale'),
                    },
                },
            });
            return promise.then((back) => {
                return this.set(
                    'shareUrl',
                    this.get('Collector').ttapi_url() + '/share?k=' + back.link,
                );
            });
        },
    },
});

export default PresenceReportController;
