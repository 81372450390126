/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/components/bill-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var AdmintoolBillComponent;

import Ember from 'ember';

import SettingsBillingBillCompComponent from '../../../settings/billing/bill-comp/component';

AdmintoolBillComponent = SettingsBillingBillCompComponent.extend({
    admintool: Ember.inject.service(),
    getBillRows: Em.on('init', function () {
        var promise;
        promise = this.get('admintool').ajax({
            url: 'billrows',
            method: 'get',
            data: {
                month: this.get('last_month'),
                customerid: this.get('customerid'),
            },
        });
        return promise.then((bills) => {
            return this.set('billrows', bills);
        });
    }),
});

export default AdmintoolBillComponent;
