/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/reset-scroll.coffee.old
// Generated by CoffeeScript 2.6.1
var ResetScrollMixin;

import Ember from 'ember';

ResetScrollMixin = Ember.Mixin.create({
    activate: function () {
        this._super();
        return window.scrollTo(0, 0);
    },
});

export default ResetScrollMixin;
