/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/init-controller.coffee.old
// Generated by CoffeeScript 2.6.1
import Mixin from '@ember/object/mixin';

export default Mixin.create({
    init_already_run: false,
    _init_functions: function () {
        if (this.get('init_already_run')) {
            return;
        }
        return this.init_functions();
    },
});
