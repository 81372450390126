import { isPresent } from '@ember/utils';
import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { get } from '@ember/object';

export default Controller.extend({
    transaction: alias('model'),

    actions: {
        typeSelected(value, field) {
            if (isPresent(value) && get(value, 'id')) {
                this.transitionToRoute(`machines.transaction.${field}.selected`, get(value, 'id'));
            } else {
                this.transitionToRoute(`machines.transaction.${field}`);
            }
        },
    },
});
