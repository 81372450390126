import GeneralAddRoute from 'tt4/routes/general-add';

const WorktasksEditRoute = GeneralAddRoute.extend({
    controllerName: 'worktasks.edit',
    modelName: 'worktask',
    edit: true,
    afterSave: 'worktasks.index',
});

export default WorktasksEditRoute;
