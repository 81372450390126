/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/saved/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SavedRoute;

import Ember from 'ember';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

SavedRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    actions: {
        willTransition: function () {
            this.controllerFor('timetracker/timecard').set('isTogglerVisible', false);
            this.controllerFor('timetracker/timecard').set('started_worktime', false);
            return this.controllerFor('timetracker/timecard').set('worktimeDuration', '00:00:00');
        },
    },
});

export default SavedRoute;
