/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/tools.coffee.old
// Generated by CoffeeScript 2.6.1
var ToolsService;

import Ember from 'ember';

import config from '../config/environment';

ToolsService = Ember.Service.extend({
    routing: Ember.inject.service('-routing'),
    Collector: Ember.inject.service('collector-service'),
    localStorageWrapper: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    admintool: Ember.inject.service(),
    change_service: function (username, url) {
        var c, params;
        c = this.get('Collector');
        params = {
            url: 'login',
            type: 'POST',
            data: {
                change_user: username,
            },
        };
        if (url) {
            url = window.location.href.split('#')[0] + url;
        } else {
            url = window.location.href;
        }
        return c.ajax(params).then((data) => {
            return this.get('routing').transitionTo('token', [data.refresh_token], {
                url: url,
            });
        });
    },
    initCurrentMySite: function () {
        var ls, siteid;
        ls = this.get('localStorageWrapper');
        siteid = ls.getUserSetting('currentMySite')[0];
        if (!siteid) {
            return;
        }
        console.log(siteid);
        return this.get('store')
            .findRecord('project', siteid)
            .then((record) => {
                return this.set('session.currentUser.currentMySite', record);
            });
    },
    login: function (params, url = null) {
        var promise;
        promise = this.get('admintool').ajax({
            url: 'createToken',
            method: 'POST',
            data: {
                username: params.username,
                userid: params.userid,
                partnerid: params.partnerid,
            },
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
        });
        return promise.then((token) => {
            var win;
            if (!url) {
                url = config.APP.OWN_URL;
            }
            if (url.slice(-1) === '/') {
                win = window.open(url + '#/token/' + token['token'], '_blank');
            } else {
                win = window.open(url + '/#/token/' + token['token'], '_blank');
            }
            return win.focus();
        });
    },
    computerizeHumanInput: function (string, minLength = 8, maxLength = 24) {
        var ref;
        string = string.toLowerCase();
        string = string.replace(/ä/g, 'a');
        string = string.replace(/å/g, 'a');
        string = string.replace(/ö/g, 'o');
        string = string.replace(/ü/g, 'u');
        string = string.replace(/[^a-z0-9]+/g, '');
        if (string.length < minLength) {
            string =
                string +
                Math.random()
                    .toString(36)
                    .substring(2, 2 + minLength - string.length);
        }
        if (string.length > maxLength) {
            string = string.substring(0, maxLength);
        }
        if ((ref = string.substring(string.length - 1, 1)) === 's' || ref === 'y') {
            string = string.substring(0, string.length - 1) + 'k';
        }
        return string;
    },
    cleanUnneededQueryParams: function (params) {
        return Object.keys(params).forEach(function (key) {
            if (Ember.typeOf(params[key]) === 'undefined' || key === 'page' || key === 'more') {
                return delete params[key];
            }
        });
    },
});

export default ToolsService;
