import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { get, set } from '@ember/object';

export default Route.extend(AuthenticatedRouteMixin, {
    create_new: false,
    model() {
        this.create_new = true;
        return this.store
            .query('drive', { user: 'self', location_end: '(empty)' })
            .then(this.onDriveQueried.bind(this));
    },

    setupController(controller, model) {
        if (get(this, 'openDrive')) {
            set(controller, 'openDrive', get(this, 'openDrive'));
        }
        if (get(this, 'newDrive')) {
            set(controller, 'openDrive', get(this, 'newDrive'));
        }
        return set(controller, 'model', model);
    },

    resetController(controller, isExiting) {
        this._super.apply(this, arguments);
        if (isExiting) {
            return set(controller, 'started_drive', false);
        }
    },
    onDriveQueried(data) {
        if (get(data, 'firstObject')) {
            this.create_new = false;
            set(this, 'openDrive', get(data, 'firstObject'));
        }
        if (this.create_new) {
            const drive = this.store.createRecord('drive', {});
            set(this, 'newDrive', drive);
            get(data, 'content').push(drive);
        }
        return data;
    },
});
