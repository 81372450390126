import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    classNames: 'report-order',
    isDefaultOrder: computed('showDefaultOrder', 'defaultOrder', 'order', function () {
        return !get(this, 'showDefaultOrder') && get(this, 'defaultOrder') == get(this, 'order');
    }),
    orderFields: computed('order', 'showDefaultOrder', function () {
        const order = get(this, 'order');
        if (isEmpty(order)) return [];
        if (!get(this, 'showDefaultOrder') && order == get(this, 'defaultOrder')) return [];
        const fieldArr = order.split(',');
        const normalizedFields = fieldArr.map((item) => {
            return item.endsWith('-') ? item.slice(0, -1) : item;
        });

        return normalizedFields;
    }),
    actions: {
        remove(field) {
            get(this, 'onRemove')(field);
        },
    },
});
