import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SalarytypesComponent extends Component {
    @service store;
    @service('collector-service') Collector;

    @tracked disabled = false;
    @tracked loading = false;
    @tracked preventUsage = false;

    constructor(owner, args) {
        super(owner, args);
        if (!this.args.setting.active) this.checkOldSalarytypes();
    }

    async checkOldSalarytypes() {
        // new salarytypes can always be used if salarytypes_v2 is on
        if (this.Collector.testNeeds(['flag.salarytypes_v2'])) return;

        // if salarytype form is not in use no need to check rows from it
        if (!this.Collector.testNeeds(['salarytype'])) return;

        this.loading = true;

        const oldSalaryTypes = await this.store.query('salarytype', {});

        if (oldSalaryTypes.length > 0) this.preventUsage = true;

        this.loading = false;
    }
}
