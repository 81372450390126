/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/archive/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportListController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import pdfmixin from '../../../mixins/worksitediary_pdf';

TaxmanTaxreportListController = GeneralReportController.extend(pdfmixin, {
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    mappedFilters: Em.computed('filter_value', function () {
        var arr;
        arr = [];
        arr.push({
            name: 'export_month',
            value: this.get('filter_value'),
        });
        return arr;
    }),
    //get 36 previous months
    months: Ember.computed(function () {
        var count, montharr, obj;
        count = 0;
        montharr = [];
        while (count < 36) {
            obj = {
                id: moment().subtract(count, 'months').format('YYYY-MM'),
                name: moment().subtract(count++, 'months').format('MMMM YYYY'),
            };
            montharr.push(obj);
        }
        return montharr;
    }),
});

export default TaxmanTaxreportListController;
