/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/approval/site/route.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerApprovalSiteRoute;

import Ember from 'ember';

TimetrackerApprovalSiteRoute = Ember.Route.extend({
    model: function (params) {
        //        params.status = 'open'
        params.sideload = true;
        params.date = this.controllerFor('timetracker.approval').get('date');
        params.employer = this.controllerFor('timetracker.approval').get('employer');
        if (!this.get('Collector').testNeedsOne('worktime.employer')) {
            delete params.employer;
        }
        return Em.RSVP.hash({
            project: this.store.findRecord('project', params.project),
            worktimes: this.store.query('worktime', params),
        });
    },
    afterModel: function (model, transition) {
        var first, worktimes;
        worktimes = model.worktimes;
        if (worktimes.get('length') > 0) {
            first = worktimes.get('firstObject.user.id');
            return this.transitionTo('timetracker.approval.site.user', first);
        }
    },
});

export default TimetrackerApprovalSiteRoute;
