import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    router: service(),
    tagName: '',

    selectedComponent: computed('form', function () {
        const customForms = {
            worktime: 'collector/form-add/forms/worktime',
            project: 'collector/form-add/forms/project',
            employer: 'collector/form-add/forms/employer',
            abcense: 'collector/form-add/forms/absence',
            wage_period: 'collector/form-add/forms/wageperiod',
            diary: 'collector/form-add/forms/diary',
            worktask: 'collector/form-add/forms/worktask',
            worksitediary: 'collector/form-add/forms/worksitediary',
            presence: 'collector/form-add/forms/presence',
            site_safety_observation: 'collector/form-add/forms/siteSafetyObservation',
            tooltype: 'collector/form-add/forms/tooltype',
        };

        if (Object.keys(customForms).includes(get(this, 'form')))
            return customForms[get(this, 'form')];
        else return 'collector/form-add';
    }),

    preserveRecord: computed('form', function () {
        return this.router.currentRouteName === 'dashboard' && this.form === 'worktime';
    }),
});
