/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/filter-model.coffee.old
// Generated by CoffeeScript 2.6.1
var FilterModelMixin,
    indexOf = [].indexOf;

import Ember from 'ember';

FilterModelMixin = Ember.Mixin.create({
    Collector: Ember.inject.service('collector-service'),
    skip_params: ['page', 'order'],
    filteredModel: Ember.computed('model.[]', 'filters.@each.value', function () {
        var filtered;
        filtered = this.get('model');
        return this._filterThese(filtered, this.get('skip_params'));
    }),
    _filterThese: function (filtered, skip_params) {
        var filterBy;
        filterBy = this.get('queryParams').filter(function (item) {
            return !(indexOf.call(skip_params, item) >= 0);
        });
        filtered = filtered.filter((record) => {
            var all_ok;
            all_ok = true;
            filterBy.forEach((param) => {
                var field,
                    fieldObj,
                    form,
                    i,
                    len,
                    moreArr,
                    moreParam,
                    moreTestParam,
                    recordValue,
                    ref,
                    results,
                    testValue,
                    value;
                value = this.get(param);
                if (!value) {
                    return;
                }
                // test more-filters
                if (param.indexOf('more') > -1) {
                    if (value.indexOf('|') > -1) {
                        moreArr = value.split('|');
                    } else {
                        moreArr = value.split(',');
                    }
                    results = [];
                    for (i = 0, len = moreArr.length; i < len; i++) {
                        moreParam = moreArr[i];
                        moreTestParam = moreParam.split(':');
                        // if filter is type of form-field we change it to form.field
                        // so we can record.get('form.field')
                        if (moreTestParam[0].indexOf('-') > -1) {
                            form = moreTestParam[0].slice(0, moreTestParam[0].indexOf('-'));
                            field = moreTestParam[0].slice(moreTestParam[0].indexOf('-') + 1);
                            if (Ember.isPresent(Ember.get(this, form))) {
                                continue;
                            }
                            testValue = moreTestParam[0].replace('-', '.');
                            fieldObj = this.get('Collector').formField(form, field);
                            // This way we don't get error for testing out not loaded relations
                            if (fieldObj.type === 'multiselect') {
                                recordValue = record.get(form + '.' + field);
                            } else if (
                                ((ref = record.get(form)) != null
                                    ? ref.belongsTo(field).remoteType()
                                    : void 0) === 'id'
                            ) {
                                recordValue = record.get(form).belongsTo(field).id();
                            } else {
                                recordValue = record.get(testValue);
                            }
                        } else {
                            testValue = moreTestParam[0];
                            recordValue = record.get(testValue);
                        }
                        if (!this._filterModelTest(recordValue, moreTestParam[1])) {
                            results.push((all_ok = false));
                        } else {
                            results.push(void 0);
                        }
                    }
                    return results;
                } else if (!this._filterModelTest(record.get(param), value)) {
                    return (all_ok = false);
                }
            });
            return all_ok;
        });
        return filtered;
    },
    _filterModelTest: function (value, test) {
        var splitted_date;
        // is it date interval
        if (test.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}_[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) {
            splitted_date = test.split('_');
            return moment(value).isBetween(splitted_date[0], splitted_date[1], null, '[]');
            // is it store record
        } else if (Ember.typeOf(value) === 'instance') {
            if (test.indexOf(',') > -1) {
                return test.split(',').includes(value.get('id'));
            } else {
                if (!value.get('id')) {
                    return !!value.findBy('id', test);
                } else {
                    return value.get('id') === test;
                }
            }
        } else if (Ember.typeOf(value) === 'array') {
            return value.indexOf(parseInt(test, 10)) !== -1;
        } else {
            // stupid checkboxes need special care..
            if (test === 'on') {
                return value === true;
            } else if (test === 'off') {
                return value === false;
            } else {
                return value === test;
            }
        }
    },
});

export default FilterModelMixin;
