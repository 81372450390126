import GeneralAddController from 'tt4/controllers/general-add';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FormsFormEditController extends GeneralAddController {
    @service router;

    @action goBack() {
        this.router.transitionTo('forms.form-report', this.form.underscore());
    }

    @action onFieldChange(field, value) {
        this.model[field] = value;
    }
}
