/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var savedController;

import Ember from 'ember';

import collector from '../../../../classes/collector';

import pdfExport from '../../../../mixins/pdf-export';

import { alias } from '@ember/object/computed';

savedController = Ember.Controller.extend(pdfExport, {
    //    needs: ['application','orientations/add']
    addController: Ember.inject.controller('orientations/add'),
    application: Ember.inject.controller(),
    exportFile: Ember.inject.service(),
    content: alias('model'),
    showColumns: ['project', 'special_warning', 'place', 'date', 'pass_valid'],
    checked_amount: Ember.computed('addController.orientations.firstObject', function () {
        var arr, groups, orientation;
        orientation = this.get('addController.orientations.firstObject');
        if (!orientation) {
            return;
        }
        groups = this.get('content.checkbox_groups');
        arr = [];
        groups.forEach(function (obj) {
            var i, ori;
            ori = orientation.get(obj.key);
            if (!ori) {
                arr.push({
                    title: obj.title,
                    amount: 0,
                    length: obj.options.length,
                });
                return;
            }
            i = 0;
            ori.forEach(function (item) {
                if (item) {
                    return i++;
                }
            });
            return arr.push({
                title: obj.title,
                amount: i,
                length: obj.options.length,
            });
        });
        return arr;
    }),
    extraFields: Ember.computed(function () {
        var extras, extras_tmp, self;
        extras_tmp = collector.forms.orientation.objects;
        extras = [];
        self = this;
        $.each(extras_tmp, function (index, obj) {
            if (obj.type !== 'checkboxgroup') {
                if (index.indexOf('addon_') >= 0) {
                    return extras.push({
                        name: obj.name,
                        value: self.get('addController.orientations.firstObject.' + index),
                    });
                }
            }
        });
        return extras;
    }),
    export_special_pdf: function (data) {
        var filters, ids, postParams, url;
        url = 'pdf/orientation';
        ids = [];
        filters = {};
        data.forEach((row) => {
            ids.push(row.id);
            return (filters['ori_date'] = row.get('ori_date'));
        });
        postParams = {
            filters: JSON.stringify(filters),
            form: 'orientation',
            lang: moment.locale(),
            type: 'base64',
            id: ids.join(','),
        };
        return this.get('Collector').ttapi({
            dataType: 'text',
            url: url,
            data: postParams,
        });
    },
    actions: {
        pdf_export: function () {
            var data, promise;
            this.set('showLoader', true);
            data = this.get('addController.orientations');
            promise = this.export_special_pdf(data);
            if (promise) {
                promise.then((content) => {
                    this.set('showLoader', false);
                    return this.get('exportFile').exportFile(content, 'export', 'pdf', true);
                });
                return promise.catch((e) => {
                    alert('pdf export failed: ' + JSON.stringify(e));
                    return this.set('showLoader', false);
                });
            }
        },
    },
});

export default savedController;
