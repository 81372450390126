/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/collector-field.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldMixin,
    get,
    indexOf = [].indexOf;

import Ember from 'ember';

({ get } = Ember);

CollectorFieldMixin = Ember.Mixin.create({
    form: Ember.computed.alias('field.form'),
    object: Ember.computed.alias('field.name'),
    disabled: false,
    isDisabled: function () {
        var condition, i, k, len, ref, ref1, set, v;
        if (this.get('useAsFilter') && this.get('field.features.disabled')) {
            return false;
        }
        if (
            get(this, 'model') &&
            !get(this, 'model.isNew') &&
            get(this, 'field.features.lock_value')
        ) {
            ref = get(this, 'field.features.lock_value');
            for (i = 0, len = ref.length; i < len; i++) {
                set = ref[i];
                for (k in set) {
                    v = set[k];
                    if (((ref1 = get(this, 'model.' + k)), indexOf.call(v.split(','), ref1) >= 0)) {
                        return true;
                    }
                }
            }
        }
        if (typeof this.get('field.features.disabled') === 'object') {
            if (!this.get('model')) {
                return false;
            }
            condition = this.get('field.features.disabled.condition');
            if (
                condition &&
                condition.indexOf('$editing') >= 0 &&
                this.get('field.features.disabled.value') === true &&
                !this.get('model.isNew') === true &&
                (condition.indexOf('$wap') === -1 || this.get('object') === 'starttime')
            ) {
                return true;
            } else if (
                this.get('field.features.disabled.value') === true &&
                !this.get('field.features.disabled.condition')
            ) {
                return true;
            }
        } else if (
            this.get('field.form') === 'worktime' &&
            this.get('field.options.form') === 'project'
        ) {
            return false;
        } else if (this.get('field.features.disabled')) {
            return true;
        }
        return false;
    },
    didRender: function () {
        return this.set('disabled', this.isDisabled());
    },
});

export default CollectorFieldMixin;
