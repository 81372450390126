import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { isPresent } from '@ember/utils';
import { Promise as EmberPromise } from 'rsvp';
import { later } from '@ember/runloop';
import Ember from 'ember';

const MachinesTransactionController = Controller.extend({
    store: service(),
    init() {
        this._super(...arguments);
        this.pojo = {};
    },

    content: alias('model'),
    actions: {
        typeSelected(value, field) {
            if (value) {
                return this.transitionToRoute('machines.transaction.' + field, value.id);
            }
        },

        changeAddValue(value, field) {
            return set(this, 'pojo.' + field, value);
        },

        async savePojo() {
            set(this, 'saving', true);
            const pojo = this.pojo;
            const store = this.store;
            const project = isPresent(pojo.project) ? pojo.project : null;
            const tooltypeRecord = await store.findRecord('tooltype', get(pojo, 'tooltype.id'), {
                reload: true,
            });
            const price = tooltypeRecord.price;
            const data = await store.query('transaction', {
                tooltype: get(pojo, 'tooltype.id'),
                project: get(project, 'id') || '(empty)',
                endtime: '(empty)',
            });
            const save_these = [];
            if (data.length > 0) {
                const old_row = data.firstObject;
                set(old_row, 'endtime', moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'));
                save_these.push(old_row.save());
                const new_amount = parseInt(old_row.amount, 10) + parseInt(pojo.amount, 10);
                save_these.push(
                    store
                        .createRecord('transaction', {
                            tooltype: pojo.tooltype,
                            price,
                            starttime: moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
                            amount: new_amount,
                            project,
                        })
                        .save(),
                );
            } else {
                save_these.push(
                    store
                        .createRecord('transaction', {
                            tooltype: pojo.tooltype,
                            price,
                            starttime: moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
                            amount: pojo.amount,
                            project,
                        })
                        .save(),
                );
            }

            await EmberPromise.all(save_these);
            set(this, 'saved', true);
            if (!Ember.testing) {
                return later(
                    this,
                    () => {
                        set(this, 'saved', false);
                        set(pojo, 'amount', null);
                        set(pojo, 'tooltype', null);
                        set(pojo, 'project', null);
                        set(this, 'saving', false);
                        return this.transitionToRoute('machines.transactions');
                    },
                    5000,
                );
            }
        },
    },
});
export default MachinesTransactionController;
