/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/mvr/graphs/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMvrGraphsRoute;

import Ember from 'ember';

SiteSafetyMvrGraphsRoute = Ember.Route.extend({
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    beforeModel: function (transition) {
        var c;
        this._super(transition);
        c = this.get('Collector');
        if (transition.targetName !== 'site_safety.mvr.notices' && !c.testNeedsOne('mvr@add')) {
            transition.abort();
            return this.transitionTo('site_safety.report.mvr.notices');
        }
    },
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        group: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var date, monthAgo, store;
        if (params.project === 'undefined') {
            delete params.project;
        }
        if (!params.group) {
            delete params.group;
        }
        params.sideload = true;
        params.order = 'date';
        store = this.get('store');
        monthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD_');
        date = moment().format('YYYY-MM-DD');
        return Ember.RSVP.hash({
            mvr: store.query('mvr', params),
            noticesSelected: store.query('trnotice', {
                type: 'mvr',
                limit: 100,
                created: monthAgo.concat(date),
                sideload: params.sideload,
                project: params.project,
                group: params.group,
            }),
            notices: store.query('trnotice', {
                type: 'mvr',
                limit: 1,
                created: params.date,
                project: params.project,
                group: params.group,
            }),
            not_solved: store.query('trnotice', {
                type: 'mvr',
                limit: 1,
                created: params.date,
                status: 'open',
                project: params.project,
                group: params.group,
            }),
            overdue: store.query('trnotice', {
                type: 'mvr',
                created: params.date,
                date: params.date,
                status: 'open',
                limit: 1,
                project: params.project,
                group: params.group,
            }),
        });
    },
});

export default SiteSafetyMvrGraphsRoute;
