import { assert } from '@ember/debug';
import { computed, get } from '@ember/object';
import Component from '@ember/component';
import { defineProperty } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    filteredModel: computed('model', function () {
        const date = get(this, 'date');
        const fields = get(this, 'fields');
        const model = get(this, 'model');
        if (isEmpty(model)) {
            return [];
        }
        return model.filterBy(fields.date, date);
    }),

    init() {
        this._super(...arguments);
        const date = get(this, 'date');
        const fields = get(this, 'fields');
        assert('Date must be given for what day we are filtering sums', date);
        assert('Fields must be given and contain date and hour field names', fields);
        assert('Date field name must be given', fields.date);
        assert('Hours field name must be given from where we calculate hours', fields.hours);
        defineProperty(
            this,
            'sum',
            computed('filteredModel', 'model.@each.' + fields.hours, function () {
                const model = get(this, 'filteredModel');
                const date = get(this, 'date');
                const fields = get(this, 'fields');
                assert(
                    'Model must be given that has the rows from where sums should be calculated',
                    model,
                );
                let sum = null;

                model.filterBy(fields.date, date).forEach((item) => {
                    if (sum === null) sum = 0;
                    sum += get(item, fields.hours);
                });

                if (sum) {
                    sum = Number(sum);
                    if (!isNaN(sum)) {
                        sum = sum.toFixed(2);
                    }
                }
                return sum;
            }),
        );
    },
});
