/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/linked/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var LinkedUsersController;

import Ember from 'ember';

import collector from '../../../classes/collector';

LinkedUsersController = Ember.Controller.extend({
    Collector: Em.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    rowActions: [
        {
            action: 'editRow',
            icon: 'move move-Pencil',
        },
    ],
    checkedActions: Ember.computed(function () {
        return [
            {
                name: 'unlinkUsers',
                func: function (users) {
                    var callQueue, clearThese, data, form, queue;
                    form = 'user';
                    clearThese = ['project', 'user', 'employer'].filter((link_form) => {
                        return this.get('Collector').testNeeds([link_form]);
                    });
                    data = {};
                    data[form] = {};
                    clearThese.forEach((link_form) => {
                        return (data[form]['link.' + this.get('Collector').pluralize(link_form)] =
                            'clear');
                    });
                    queue = users.map(function (user) {
                        return Ember.get(user, 'id');
                    });
                    // requests must be done in queue .. or capi cache makes only first call to work
                    callQueue = function (self, data, queue, caller) {
                        return new Ember.RSVP.Promise((resolve) => {
                            var promise, userid;
                            userid = queue.pop();
                            if (!userid) {
                                resolve();
                                return;
                            }
                            promise = self.get('Collector').ajax({
                                method: 'put',
                                url: 'users/' + userid,
                                data: JSON.stringify(data),
                            });
                            return promise.then(function () {
                                return caller(self, data, queue, caller).then(resolve);
                            });
                        });
                    };
                    return callQueue(this, data, queue, callQueue).then(() => {
                        return this.initData();
                    });
                },
            },
        ];
    }),
    path: 'linked_users',
    rows: 0,
    getRows: Em.observer('filterValues', function () {
        var c, promise, query, query_str_arr, url;
        url = this.get('path');
        query = this.get('filterValues');
        if (query) {
            query_str_arr = [];
            $.each(query, (key, item) => {
                if (!item.value) {
                    return;
                }
                return query_str_arr.push(item.name + '=' + item.value);
            });
            if (query_str_arr.length === 0) {
                this.set('rows', 0);
            }
            url += '?' + query_str_arr.join('&');
        }
        url += '&limit=1&out=(empty)';
        c = this.get('Collector');
        promise = c.ttapi({
            url: url,
        });
        return promise.then((data) => {
            return this.set('rows', data.count);
        });
    }),
    actions: {
        editRow: function (params) {
            return this.transitionToRoute('users.edit', params.id).then(function (newRoute) {
                return newRoute.controller.set('afterSave', 'users.linked');
            });
        },
        removeRow: function (params) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return this.get('store')
                            .findRecord('user', params.id)
                            .then((record) => {
                                return record.destroyRecord();
                            });
                    }
                });
        },
    },
});

export default LinkedUsersController;
