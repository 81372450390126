/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/report-comp/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FormReportComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import pdfExport from '../../../mixins/pdf-export';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

import statusUtil from 'tt4/utils/status';

import $ from 'jquery';

import { pluralize } from 'ember-inflector';

FormReportComponent = Ember.Component.extend(pdfExport, {
    store: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    sessionGlobals: Em.inject.service(),
    dialogs: Ember.inject.service(),
    capi: Ember.inject.service(),
    notifications: Ember.inject.service(),
    checked_rows: null,
    //# Set the form which data will be fetched
    form: null,
    //# Query parameters for fetching data from api. format [{name: "param1", value: "value1"},...]
    query: null,
    //# List of columns to be hidden. Format is string "column1,column2"
    hide_columns: null,
    //# List of columns to be shown. Format is string "column1,column2".
    // This overcomes hide_columns setting if used
    show_columns: null,
    //# Data can be grouped by columns. Format is string "column1,column2"
    groupBy: null,
    //# Data can be ordered by columns. Format is string "column1(-),column2(-)"
    order: null,
    //# Header over the table
    header: null,
    imagePromises: [],
    //# Row action buttons like edit and remove
    rowActions: [
        {
            action: 'editRow',
            icon: 'move move-Pencil',
        },
        {
            action: 'removeRow',
            icon: 'move move-Delete',
        },
    ],
    //# PRIVATE PARAMETERS

    //# Rows per page
    rowsPerPage: Em.computed('settings.rowsPerPage', function () {
        return this.get('settings.rowsPerPage') || 25;
    }),
    currentPage: 1,
    //# summable field type list
    summableTypes: ['hours', 'number', 'price', 'big_price', 'checkbox'],
    groupableColumnList: [
        'date',
        'hours',
        'number',
        'price',
        'big_price',
        'dropdown_values',
        'dropdown_options',
    ],
    /*

  METHODS FOR INITIALIZING SETTINGS AND OBSERVING settings PROPERTY

  */
    refreshSettings: Ember.on(
        'init',
        Ember.observer(
            'settings.form',
            'settings.group',
            'settings.query',
            'settings.order',
            'settings.row_info_status',
            function () {
                var getSettings, self, settings;
                getSettings = [
                    'form',
                    'group',
                    'query',
                    'header',
                    'hide_columns',
                    'show_columns',
                    'order',
                    'row_info_status',
                ];
                settings = this.get('settings');
                if (!settings) {
                    return;
                }
                self = this;
                return $.each(getSettings, function (key, item) {
                    var new_value, old_value;
                    if ((!item) in settings) {
                        return;
                    }
                    old_value = self.get(item);
                    new_value = settings[item];
                    if (old_value !== new_value) {
                        return self.set(item, new_value);
                    }
                });
            },
        ),
    ),
    /*

  PROPERTIES AND METHODS FOR DATA

  */
    didInsertElement: function () {
        return this.set('checked_rows', Em.A([]));
    },
    // querying form server is done here
    data: Ember.computed(
        'form',
        'group',
        'query',
        'currentPage',
        'order',
        'statusChanged',
        'row_info_status',
        'rowsPerPage',
        'status',
        'settings.refreshRows',
        function () {
            var c,
                data,
                datefieldname,
                end,
                field,
                form,
                group,
                order,
                param,
                params,
                query,
                settings,
                start,
                status,
                use_default_even_without_index;
            ({ form, query, group, order, status } = this.getProperties(
                'form',
                'query',
                'group',
                'order',
                'status',
            ));
            if (!form) {
                return null;
            }
            if (!this.get('checked_rows')) {
                this.set('checked_rows', Em.A([]));
            }
            params = {};
            settings = this.get('settings');
            if (this.get('settings.calendar') === true) {
                this.set('rowsPerPage', 100);
            }
            if (query) {
                query.forEach((item) => {
                    if (item.name === 'date') {
                        Ember.run.next(this, function () {
                            return this.set('dateRange', item.value);
                        });
                    }
                    if (item.value !== 'null') {
                        return (params[item.name] = item.value);
                    }
                });
            } else {
                // this is not the correct way to do this but only way I figured out :|
                // form-filters should be able to update these automatically - Veikko
                if (form) {
                    params = this.get('sessionGlobals').get('filters_' + form) || null;
                }
                // try also fetching from global filters if form specifics not found
                if (params === null) {
                    params = this.get('sessionGlobals').get('filters') || {};
                }
                c = this.get('Collector');
                use_default_even_without_index = false;
                if (c.fieldExists(form, 'date')) {
                    datefieldname = 'date';
                } else if (c.fieldExists(form, 'startdate') && form === 'abcense') {
                    use_default_even_without_index = true;
                    datefieldname = 'startdate';
                } else {
                    datefieldname = null;
                }
                if (params[datefieldname] == null && datefieldname) {
                    field = c.field(form, datefieldname);
                    if (field) {
                        if (use_default_even_without_index || 'use_index' in field.features) {
                            start = params.date
                                ? params.date.split('_')[0]
                                : moment().startOf('week').format('YYYY-MM-DD');
                            end = params.date
                                ? params.date.split('_')[1]
                                : moment().endOf('week').format('YYYY-MM-DD');
                            params[datefieldname] = start + '_' + end;
                        }
                    }
                }
            }
            for (param in params) {
                if (param.includes(':')) {
                    continue;
                }
                if (param === 'q') {
                    continue;
                }
                if (params[param] === null) {
                    delete params[param];
                }
                if (param === 'groupBy') {
                    continue;
                }
                if (!this.testFieldExistance(form, param)) {
                    delete params[param];
                }
            }
            params = this.transformQuery(params);
            params = this.special_queryParams(params, form);
            params.sideload = this.getSideload();
            if (this.get('settings.row_info_status')) {
                params.row_info_status = this.get('settings.row_info_status');
            }
            if (group) {
                params.groupBy = group;
            }
            if (order) {
                params.order = order;
            }
            if (status) {
                params.status = status;
            }
            params = this.add_limits(params);
            data = this.get('store').query(form, params);
            data.then((data) => {
                if (data.get('meta.count') > 100 && this.get('settings.calendar') === true) {
                    return this.get('dialogs').alert(this.get('intl').t('report.too_many'));
                }
            });
            return data;
        },
    ),
    transformQuery: function (params) {
        var pArr, param, value;
        for (param in params) {
            value = params[param];
            if (param.indexOf('.') > -1) {
                pArr = param.split('.');
                delete params[param];
                params[pArr[0] + '[' + pArr[1] + ']'] = value;
            }
        }
        return params;
    },
    special_queryParams: function(params, form) {
        
        if (!this.get('useSpecialQueryParams')) return params;
        
        // absense form has startdate and enddate fields, but we want to use them as one field so all absences are returned
        if (form === 'abcense' && Object.keys(params).includes('startdate')) {
            const newParams = {startdate_enddate: params.startdate, ...params}
            delete newParams.startdate
            return newParams
        }
        return params
    },
    getSideload: function () {
        var column, columns, i, len, ret, subfields, subform;
        columns = this.get('columns');
        ret = [];
        for (i = 0, len = columns.length; i < len; i++) {
            column = columns[i];
            [subform, subfields] = this.parseColumn(column);
            if (subfields) {
                ret.push(subform + '.' + subfields[0]);
            }
        }
        if (ret.length > 0) {
            return ret;
        } else {
            return true;
        }
    },
    // all data is formatted here before rendering
    formatted_data: Ember.computed('data.[]', function () {
        return this.formatData('html');
    }),
    pdf_formatted_data: Ember.computed('data.[]', 'checked_rows.[]', function () {
        return this.formatData('pdf');
    }),
    csv_formatted_data: Ember.computed('data.[]', function () {
        return this.formatData('csv');
    }),
    xls_formatted_data: Ember.computed('data.[]', function () {
        return this.formatData('excel');
    }),
    calendar_formatted_data: Ember.computed('data.[]', function () {
        return this.formatData('calendar');
    }),
    asCsvArray: Ember.computed(function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise, rows;
            promise = this.get('data');
            rows = [];
            return promise.then(() => {
                var column, columns, csv_data, i, j, len, len1, newrow, row;
                rows.push(
                    this.get('translated_columns').map(function (item) {
                        if (Ember.String.isHTMLSafe(item)) {
                            return item.string;
                        }
                        return item;
                    }),
                );
                csv_data = this.get('csv_formatted_data');
                columns = this.get('columnList');
                for (i = 0, len = csv_data.length; i < len; i++) {
                    row = csv_data[i];
                    newrow = [];
                    for (j = 0, len1 = columns.length; j < len1; j++) {
                        column = columns[j];
                        newrow.push(row[column]);
                    }
                    rows.push(newrow);
                }
                return resolve(rows);
            });
        });
    }),
    //        header: @get 'settings.header'
    asXlsArray: Ember.computed(function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise, rows;
            promise = this.get('data');
            rows = [];
            return promise.then(() => {
                var column, columns, csv_data, i, j, len, len1, newrow, row;
                rows.push(
                    this.get('translated_columns').map(function (item) {
                        if (Ember.String.isHTMLSafe(item)) {
                            return item.string;
                        }
                        return item;
                    }),
                );
                csv_data = this.get('xls_formatted_data');
                columns = this.get('columnList');
                for (i = 0, len = csv_data.length; i < len; i++) {
                    row = csv_data[i];
                    newrow = [];
                    for (j = 0, len1 = columns.length; j < len1; j++) {
                        column = columns[j];
                        newrow.push(row[column]);
                    }
                    rows.push(newrow);
                }
                return resolve(rows);
            });
        });
    }),
    asPdfElement: Ember.computed('checked_rows.[]', function () {
        var pdf_data_with_column_names;
        pdf_data_with_column_names = [];
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise;
            promise = this.get('data');
            return promise.then(() => {
                var promises;
                promises = Em.RSVP.Promise.all(
                    this.get('pdf_formatted_data').map(function (innerPromiseArray) {
                        return Em.RSVP.Promise.all(innerPromiseArray);
                    }),
                );
                return promises.then((resolved_pdf_arr) => {
                    var field, i, ind, j, len, len1, pdfRow, row;
                    for (i = 0, len = resolved_pdf_arr.length; i < len; i++) {
                        row = resolved_pdf_arr[i];
                        pdfRow = Ember.Object.create();
                        for (ind = j = 0, len1 = row.length; j < len1; ind = ++j) {
                            field = row[ind];
                            pdfRow[this.get('columnList')[ind]] = field;
                        }
                        pdf_data_with_column_names.push(pdfRow);
                    }
                    // wait for all images to be loaded before resolving
                    return Em.RSVP.Promise.all(this.imagePromises).then(() => {
                        this.imagePromises = [];
                        return resolve(
                            this.createPdfTableElement(
                                this.get('columnList'),
                                pdf_data_with_column_names,
                                this.get('translated_columns'),
                                this.get('pdf_formatted_sumRow'),
                                this.get('pdfSettings'),
                            ),
                        );
                    });
                });
            });
        });
    }),
    asPdfElementFull: Ember.computed(function () {
        var pdf_data_with_column_names;
        pdf_data_with_column_names = [];
        return new Em.RSVP.Promise((resolve, reject) => {
            var promise;
            promise = this.get('data');
            return promise.then(() => {
                var promises;
                promises = Em.RSVP.Promise.all(
                    this.get('pdf_formatted_data').map(function (innerPromiseArray) {
                        return Em.RSVP.Promise.all(innerPromiseArray);
                    }),
                );
                return promises.then((resolved_pdf_arr) => {
                    var field, i, ind, j, len, len1, pdfRow, row;
                    for (i = 0, len = resolved_pdf_arr.length; i < len; i++) {
                        row = resolved_pdf_arr[i];
                        pdfRow = Ember.Object.create();
                        for (ind = j = 0, len1 = row.length; j < len1; ind = ++j) {
                            field = row[ind];
                            pdfRow[this.get('columnList')[ind]] = field;
                        }
                        pdf_data_with_column_names.push(pdfRow);
                    }
                    // wait for all images to be loaded before resolving
                    return Em.RSVP.Promise.all(this.imagePromises).then(() => {
                        this.imagePromises = [];
                        return resolve(
                            this.createPdfTableElementRows(
                                this.get('columnList'),
                                pdf_data_with_column_names,
                                this.get('translated_columns'),
                                this.get('pdf_formatted_sumRow'),
                            ),
                        );
                    });
                });
            });
        });
    }),
    formatData: function (format) {
        var c, calendar_formatted_data, columns, data, form, formatted_data, pdfRows;
        data = this.get('data');
        if (!data) {
            return null;
        }
        if (!data.isFulfilled) {
            return null;
        }
        columns = this.get('columns');
        c = this.get('Collector');
        form = this.get('form');
        //        fields = c.fields form
        formatted_data = Em.A([]);
        calendar_formatted_data = [];
        pdfRows = [];
        data.forEach((unformatted_row) => {
            var date_is_set, pdfRow, row;
            row = Ember.Object.create();
            date_is_set = false;
            pdfRow = [];
            //do some magic if there are rows checked
            if (this.get('checked_rows').length > 0) {
                //put row as checked (if you go to another route and come back)
                if (this.get('checked_rows').indexOf(unformatted_row.id) !== -1) {
                    row.checkedClass = 'checked';
                }
                //skip row this is not, only in exports
                if (format === 'pdf' || format === 'csv' || format === 'excel') {
                    if (this.get('checked_rows').indexOf(unformatted_row.id) === -1) {
                        return;
                    }
                }
            }
            if (unformatted_row.id) {
                row.id = unformatted_row.id;
            }
            $.each(columns, (key, column) => {
                var cell,
                    field_obj,
                    formObj,
                    formattedImages,
                    formatted_cell,
                    i,
                    img,
                    len,
                    ref,
                    subfields,
                    subform;
                [subform, subfields] = this.parseColumn(column);
                cell = unformatted_row.get(subform);
                formObj = c.formField(form, subform);
                if (this.get('formReport') || this.get('settings.useRowInfo')) {
                    row.row_info = unformatted_row.get('row_info');
                }
                if (Ember.isPresent(unformatted_row.get('isEditable'))) {
                    row.isEditable = unformatted_row.get('isEditable');
                }
                if (column === 'id') {
                    formatted_cell = cell;
                } else if (subfields) {
                    formatted_cell = c.database_value(cell, subform, subfields);
                } else {
                    // it should be easy to add images to pdfs as well
                    // check also that file is image
                    if (
                        formObj &&
                        ((ref = formObj['type']) === 'signature' || ref === 'hdfile') &&
                        format === 'pdf' &&
                        cell[0] &&
                        cell[0].type &&
                        cell[0].type.indexOf('image') > -1
                    ) {
                        formattedImages = [];
                        // lets get all images, not just first one!
                        for (i = 0, len = cell.length; i < len; i++) {
                            img = cell[i];
                            // add images to promise array so that we can wait for them to be loaded when creating pdf
                            const imgPromise = this.formatImageToPdf(
                                img,
                                'row',
                                false,
                                false,
                                this.get('pdfSettings.imgLocation'),
                            ).then((img) => {
                                formattedImages.push(img);
                            });
                            if (format === 'pdf') {
                                this.imagePromises.push(imgPromise);
                            }
                        }
                        formatted_cell = formattedImages;
                    } else {
                        formatted_cell = c._format_(cell, form, subform, format);
                    }
                }
                if (column === 'row_info.created' && row.row_info) {
                    Ember.set(row, 'row_info.created', formatted_cell);
                } else if (column === 'status_last_changed') {
                    formatted_cell = this.getStatusChangedInfo(unformatted_row.id);
                } else {
                    if (format === 'excel' && Ember.String.isHTMLSafe(formatted_cell)) {
                        formatted_cell = formatted_cell.string;
                    }
                    row[this.columnKey(column)] = formatted_cell;
                }
                pdfRow.push(formatted_cell);
                // if calendar-format, add unformatted date so it can be used in calendar
                if (format === 'calendar' && c.fieldExists(form, subform)) {
                    field_obj = c.formField(form, subform);
                    if (field_obj['type'] === 'date' && !date_is_set) {
                        date_is_set = true;
                        row['cal_date_value'] = cell;
                        return this.set('cal_date_field', column);
                    }
                }
            });
            formatted_data.pushObject(row);
            return pdfRows.push(pdfRow);
        });
        // if formatting is pdf, return data in different format so it can be resolved
        if (format === 'pdf') {
            //    console.log pdfRows
            //    console.log formatted_data
            //    return
            return pdfRows;
        }
        //else
        return formatted_data;
    },
    sumRow: Ember.computed('data.[]', function () {
        var c, columns, data, fields, form, sumRow, summableTypes;
        data = this.get('data');
        if (!data) {
            return null;
        }
        if (!data.isFulfilled) {
            return null;
        }
        columns = this.get('columns');
        summableTypes = this.get('summableTypes');
        c = this.get('Collector');
        form = this.get('form');
        fields = c.fields(form);
        sumRow = Ember.Object.create();
        $.each(columns, (key, column) => {
            var sum, type;
            if (!(column in fields)) {
                return;
            }
            type = fields[column].type;
            if (!(indexOf.call(summableTypes, type) >= 0)) {
                return;
            }
            sum = 0;
            data.forEach((unformatted_row) => {
                if (this.get('checked_rows').length > 0) {
                    if (this.get('checked_rows').indexOf(unformatted_row.id) === -1) {
                        return;
                    }
                }
                if (fields[column].type === 'checkbox') {
                    if (!sumRow[column]) {
                        sumRow[column] = [0, 0];
                    }
                    if (unformatted_row.get(column)) {
                        return sumRow[column][0]++;
                    } else {
                        return sumRow[column][1]++;
                    }
                } else {
                    sum += unformatted_row.get(column);
                    return (sumRow[column] = sum);
                }
            });
            if (fields[column].type !== 'checkbox') {
                return (sumRow[column] = Math.round(sumRow[column] * 100) / 100);
            }
        });
        return sumRow;
    }),
    // all data is formatted here before rendering
    formatted_sumRow: Ember.computed('sumRow', function () {
        return this.formatSumRow();
    }),
    pdf_formatted_sumRow: Ember.computed('sumRow', function () {
        return this.formatSumRow('pdf');
    }),
    formatSumRow: function (format) {
        var c, columns, fields, form, formatted_sumRow, sumRow;
        sumRow = this.get('sumRow');
        if (!sumRow) {
            return null;
        }
        columns = this.get('columns');
        c = this.get('Collector');
        form = this.get('form');
        fields = c.fields(form);
        formatted_sumRow = Ember.Object.create();
        $.each(columns, function (key, column) {
            var cell;
            cell = sumRow.get(column);
            if (column === 'id') {
                return (formatted_sumRow[column] = cell);
            } else if (fields[column] && fields[column].type === 'checkbox') {
                if (Ember.isArray(cell)) {
                    return (formatted_sumRow[column] =
                        c._format_(true, form, column, format) +
                        ': ' +
                        cell[0] +
                        ' / ' +
                        c._format_(false, form, column, format) +
                        ': ' +
                        cell[1]);
                }
            } else {
                return (formatted_sumRow[column] = c._format_(cell, form, column, format));
            }
        });
        return formatted_sumRow;
    },
    /*

  PROPERTIES AND METHODS FOR COLUMNS

  */
    // list all columns from collector form
    columns: Ember.computed('form', 'hide_columns', 'show_columns', 'group', function () {
        var c,
            column,
            field_arr,
            fields,
            form,
            hide_columns,
            hide_columns_arr,
            i,
            len,
            show_columns,
            show_columns_arr;
        c = this.get('Collector');
        form = this.get('form');
        if (!form || !c.formExists(form)) {
            return [];
        }
        fields = c.fields(form);
        hide_columns = this.get('hide_columns');
        hide_columns_arr = hide_columns ? hide_columns.split(',') : [];
        show_columns = this.get('show_columns');
        show_columns_arr = show_columns ? show_columns.split(',') : null;
        field_arr = [];
        if (show_columns === 'empty') {
            field_arr = [];
        } else if (show_columns) {
            for (i = 0, len = show_columns_arr.length; i < len; i++) {
                column = show_columns_arr[i];
                if (
                    column === 'id' ||
                    column === 'row_info.created' ||
                    column === 'row_info.modified' ||
                    this.testFieldExistance(form, column)
                ) {
                    field_arr.push(column);
                }
                if (column === 'status' && this.pdfSettings?.statusInfo) {
                    field_arr.push('status_last_changed');
                }
            }
        } else {
            // show fields that aren't hidden
            $.each(fields, (key, item) => {
                if (indexOf.call(hide_columns_arr, key) >= 0) {
                    return;
                }
                if (this.testFieldExistance(form, key)) {
                    return field_arr.push(key);
                }
            });
        }
        if (this.get('group')) {
            return this.groupableColumns(field_arr, fields);
        } else {
            return field_arr;
        }
    }),
    testFieldExistance: function (form, column) {
        var c;
        c = this.get('Collector');
        if (column.indexOf('.') !== -1) {
            [form, column] = column.split('.');
        }
        return c.fieldExists(form, column);
    },
    columnList: Ember.computed('columns', function () {
        return this.get('columns').map((item) => {
            return this.columnKey(item);
        });
    }),
    // skip all the other columns but those that are shown on grouped reports
    groupableColumns: function (columns, fields) {
        var group, groupableColumnsList, ret_arr;
        groupableColumnsList = this.get('groupableColumnList');
        group = this.get('group');
        ret_arr = group.split(',');
        ret_arr = ret_arr.reject(function (item) {
            return item === 'all';
        });
        $.each(columns, function (key, item) {
            var type;
            if (fields[item]) {
                type = fields[item].type;
                if (
                    indexOf.call(groupableColumnsList, type) >= 0 &&
                    !(indexOf.call(group.split(','), item) >= 0)
                ) {
                    return ret_arr.push(item);
                }
            }
        });
        return ret_arr;
    },
    // get translation for every column
    translated_columns: Ember.computed('columns', function () {
        var c, columns, form, translated_columns;
        c = this.get('Collector');
        ({ columns, form } = this.getProperties('columns', 'form'));
        form = this.get('form');
        translated_columns = [];
        $.each(columns, (key, item) => {
            var subform, subitem;
            if (item.indexOf('.') !== -1 && item.substring(0, 9) !== 'row_info.') {
                [subform, subitem] = item.split('.');
                if (subitem !== 'name') {
                    item = subitem;
                } else {
                    item = subform;
                    subform = form;
                }
            } else {
                subform = form;
            }
            if (item === 'status_last_changed') {
                return translated_columns.push(this.get('intl').t('export.pdf.status_last_change'));
            }
            return translated_columns.push(c.getTranslationForColumn(subform, item));
        });
        return translated_columns;
    }),
    columnKey: function (column) {
        var subfields, subform;
        [subform, subfields] = this.parseColumn(column);
        return subform + (subfields ? subfields[0] : '');
    },
    parseColumn: function (column) {
        var subfields, subform;
        if (column.indexOf('.') !== -1 && column.substring(0, 9) !== 'row_info.') {
            [subform, subfields] = column.split('.');
            subfields = [subfields];
        } else {
            subform = column;
            subfields = null;
        }
        return [subform, subfields];
    },
    formatSubfield: function (subform, subfields) {
        return subform + '.' + subfields[0];
    },
    pageCount: Ember.computed('data.content.meta.count', 'rowsPerPage', function () {
        if (this.get('rowsPerPage') === 'all') {
            return 1;
        } else {
            return Math.ceil(this.get('data.content.meta.count') / this.get('rowsPerPage'));
        }
    }),
    //# query limits by pagination
    add_limits: function (params) {
        var currentPage, rowsPerPage;
        rowsPerPage = this.get('rowsPerPage');
        currentPage = this.get('currentPage');
        if (rowsPerPage === 'all') {
            params.limit = 'all';
        } else {
            params.limit = rowsPerPage;
            params.offset = rowsPerPage * (currentPage - 1);
        }
        return params;
    },
    //array for checked rows
    //checked_rows: Em.A([])

    // default date-range for calendar
    dateRange: Em.computed(function () {
        return (
            moment().startOf('week').format('YYYY-MM-DD') +
            '_' +
            moment().endOf('week').format('YYYY-MM-DD')
        );
    }),

    getStatusChangedInfo: function (rowId) {
        const statusrow = this.statusInfoRows[rowId];
        return statusrow
            ? moment(statusrow.datetime).format('L') +
                  ' ' +
                  moment(statusrow.datetime).format('LT') +
                  '\n' +
                  statusrow.user
            : '';
    },

    actions: {
        catchActions: function (action, params) {
            if (
                action === 'columnClicked' ||
                action === 'removeRow' ||
                action === 'changeStatus' ||
                action === 'checkRow' ||
                action === 'rowHistory' ||
                action === 'lockRows' ||
                action === 'deleteChecked' ||
                action === 'restoreChecked' ||
                action === 'columnClickedMulti'
            ) {
                return this.send(action, params);
            } else {
                return this.sendAction(action, params, this.get('settings'));
            }
        },
        deleteChecked: function () {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    if (con) {
                        return this.checked_rows.forEach((row) => {
                            row = this.get('store').peekRecord(this.get('form'), row);
                            if (row.get('row_info.privilege_remove') === false) {
                                this.get('dialogs').alert(
                                    this.get('intl').t('general_report.mass_delete.not_allowed'),
                                );
                                return;
                            }
                            return row.destroyRecord();
                        });
                    }
                });
        },
        restoreChecked: function () {
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_restore_rows'))
                .then((con) => {
                    var form, form_plural;
                    if (con) {
                        form = this.get('form');
                        form_plural = pluralize(form).camelize();
                        return this.checked_rows.forEach((row) => {
                            row = this.get('store').peekRecord(this.get('form'), row);
                            return this.get('capi')
                                .request('/' + form_plural + '/' + row.id + '?validation=off', {
                                    dataType: 'json',
                                    type: 'PUT',
                                    data:
                                        '{"' + form.camelize() + '":{"row_info.status":"normal"}}',
                                })
                                .then(() => {
                                    return row.unloadRecord();
                                });
                        });
                    }
                });
        },
        lockRows: function ({ act, rows }) {
            var form, form_plural, store;
            if (!this.get('Collector').testNeeds(['userlevel=5'])) {
                return;
            }
            form = this.get('form');
            form_plural = pluralize(this.get('form')).camelize();
            store = this.get('store');
            return rows.forEach((row) => {
                var data;
                data = {};
                if (act === 'lock') {
                    data[form] = {
                        locked: true,
                    };
                }
                if (act === 'unlock') {
                    data[form] = {
                        locked: false,
                    };
                }
                return this.get('capi')
                    .request('/' + form_plural + '/' + row.get('id') + '?validation=off', {
                        dataType: 'json',
                        type: 'PUT',
                        data: JSON.stringify(data),
                    })
                    .then((data) => {
                        var record;
                        // don't need to save row again, just reload it and it will be updated
                        record = store.peekRecord(form, row.id);
                        // if form has export_salary field empty it when opening lock
                        if (this.get('Collector').testNeeds([form + '.export_salary'])) {
                            record.set('export_salary', '');
                            return record.save().then(() => {
                                return this.notifyPropertyChange('data');
                            });
                        } else {
                            return record.reload();
                        }
                    });
            });
        },
        export_pdf: function () {
            return this.get('asPdfElement').then((element) => {
                return this.createPdfFromElements([element]).then((file) => {
                    if (typeof cordova !== 'undefined' && cordova !== null) {
                        return this.get('cordova').openFile(file, 'application/pdf');
                    }
                });
            });
        },
        export_pdf_full: function () {
            return this.get('asPdfElementFull').then((element) => {
                return this.createPdfFromElements([element]).then((file) => {
                    if (typeof cordova !== 'undefined' && cordova !== null) {
                        return this.get('cordova').openFile(file, 'application/pdf');
                    }
                });
            });
        },
        // This action sets the sort order of the report
        columnClicked: function (order) {
            var currentOrder;
            currentOrder = this.get('order');
            if (currentOrder === order || currentOrder === null) {
                currentOrder = order + '-';
            } else {
                currentOrder = order;
            }
            //console.log currentOrder
            this.set('order', currentOrder);
            if (this.get('settings')) {
                return this.set('settings.order', currentOrder);
            }
        },
        columnClickedMulti: function (obj) {
            var col, colArr, sortCol;
            col = obj.name;
            //return if obj? and obj.type == "row_info"
            if (obj.ext === 'row_info.created') {
                col = 'id';
            }
            sortCol = this.get('settings.order');
            if (sortCol === this.get('settings.defaultOrder') && !this.get('manuallyOrdered')) {
                this.set('settings.order', col);
                this.set('manuallyOrdered', true);
                this.set('order', col);
                return;
            }
            if (sortCol.includes(col + '-')) {
                sortCol = sortCol.replace(col + '-', col);
            } else if (sortCol.includes(col) && (sortCol = sortCol.replace(col, col + '-'))) {
            } else if (Ember.isEmpty(sortCol)) {
                sortCol = col;
            } else {
                sortCol += ',' + col;
            }
            colArr = sortCol.split(',');
            if (colArr.length > 4) {
                // Only use the 4 last options in the sortcol array
                sortCol = colArr.splice(-4).join(',');
            }
            this.set('manuallyOrdered', true);
            this.set('order', sortCol);
            return this.set('settings.order', sortCol);
        },
        removeFieldFromOrder: function (field) {
            var cols;
            cols = this.get('settings.order').split(',');
            if (cols.length === 1) {
                this.set('settings.order', this.get('settings.defaultOrder'));
                this.set('manuallyOrdered', false);
                this.set('order', this.get('settings.defaultOrder'));
                return;
            }
            if (cols.includes(field + '-')) {
                field = field + '-';
            }
            cols.splice(cols.indexOf(field), 1);
            if (cols.length > 0) {
                this.set('manuallyOrdered', true);
                this.set('order', cols.join(','));
                return this.set('settings.order', cols.join(','));
            }
        },
        removeRow: function (row) {
            var form, store;
            store = this.get('store');
            form = this.get('form');
            return this.get('dialogs')
                .confirm(this.get('intl').t('report.confirm_delete'))
                .then((con) => {
                    var record;
                    if (con) {
                        // first try to peek record from store .. if just findRecord is used error occurs if record is in store..
                        // this is because DELETE request is called before GET is ready
                        record = store.peekRecord(form, row.id);
                        if (record) {
                            return record.destroyRecord();
                        } else {
                            return store.findRecord(form, row.id).then(function (record) {
                                return record.destroyRecord();
                            });
                        }
                    }
                });
        },
        rowHistory: function (row) {
            return this.set('showHistoryForRow', row.get('id'));
        },
        removeHistoryModal: function () {
            return this.set('showHistoryForRow', null);
        },
        changeStatus: function (params) {
            var form, intl, promises, rows, statusField, statusUtilRes, store;
            this.set('statusUtil', statusUtil.create());
            store = this.get('store');
            form = this.get('form');
            rows = params.rows;
            intl = this.get('intl');
            promises = [];
            statusField = this.get('Collector').field(form, 'status');
            statusUtilRes = this.get('statusUtil').canChangeStatus(
                rows,
                params.status,
                statusField,
            );
            statusUtilRes.accepted.forEach((row) => {
                var record;
                return promises.push(
                    (record = store.peekRecord(form, row.id)),
                    record
                        ? (record.set('status', params.status), record.save())
                        : store.findRecord(form, row.id).then(function (record) {
                              record.set('status', params.status);
                              return record.save();
                          }),
                );
            });
            Promise.all(promises).then(() => {
                if (statusUtilRes.accepted.length > 0) {
                    return this.get('notifications').success(
                        intl.t('general.status_change__success', {
                            amount: statusUtilRes.accepted.length,
                        }),
                        {
                            autoClear: true,
                        },
                    );
                }
            });
            if (statusUtilRes.rejected.length > 0) {
                this.get('notifications').error(
                    intl.t('general.status_change_error', {
                        amount: statusUtilRes.rejected.length,
                    }),
                    {
                        autoClear: true,
                    },
                );
            }
            return this.toggleProperty('statusChanged');
        },
        checkRow: function (params) {
            if (params.action === 'add') {
                if (this.get('checked_rows').indexOf(params.id) === -1) {
                    this.get('checked_rows').pushObject(params.id);
                }
            } else {
                if (this.get('checked_rows').indexOf(params.id) !== -1) {
                    this.get('checked_rows').removeObject(params.id);
                }
            }
            return this.sendAction('checkedRowsChanged', this.get('checked_rows'));
        },
    },
});

export default FormReportComponent;
