/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../routes/general-add';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

PresenceAddRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    gui: Ember.inject.service(),
    modelName: 'presence',
    controllerName: 'presences.add',
    savedRoute: 'presences.saved',
    beforeModel: function () {
        this._super(...arguments);
        return this.set('gui.hideMobileHeader', true);
    },
    model: function (params) {
        var date_range;
        params.sideload = true;
        //get presences from today and yesterday
        date_range =
            moment().subtract(1, 'weeks').format('YYYY-MM-DD') +
            '_' +
            moment().format('YYYY-MM-DD');
        return this.store
            .query('presence', {
                date: date_range,
                user: 'self',
                sideload: true,
                out: '(empty)',
                status: 'open',
            })
            .then((data) => {
                var firstObject;
                firstObject = data.get('firstObject');
                this.set('currentPresence', firstObject != null);
                if (firstObject) {
                    return firstObject;
                } else {
                    return this.store.createRecord('presence', {
                        date: moment().format('YYYY-MM-DD'),
                    });
                }
            });
    },
    setupController: function (controller, model) {
        if (this.get('currentPresence')) {
            controller.set('inActive', true);
            controller.set('outActive', false);
        } else {
            controller.set('outActive', true);
            controller.set('inActive', false);
        }
        controller.set('model', model);
        //check if presence is over 16h old
        return controller.checkPresenceOver16h();
    },
    resetController: function (controller, isExiting, transition) {
        this._super.apply(this, arguments);
        if (isExiting) {
            return this.set('gui.hideMobileHeader', false);
        }
    },
});

export default PresenceAddRoute;
