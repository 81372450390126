/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/card/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersCardRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../routes/general-report';

UsersCardRoute = GeneralReportRoute.extend({
    queryParams: {
        page: {
            refreshModel: true,
        },
        employer: {
            refreshModel: true,
        },
        id06valid: {
            refreshModel: true,
        },
    },
    model: function (params) {
        params = this._super(params);
        params.sideload = true;
        return this.store.query('user', params);
    },
});

export default UsersCardRoute;
