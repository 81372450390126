import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set, computed } from '@ember/object';

export default Component.extend({
    store: service(),
    collector: service('collector-service'),
    customerSettings: service(),
    dialogs: service(),
    intl: service(),

    saldoAbsenceId: null,

    absenceTypes: computed('saldoToggled', async function () {
        let toggled = get(this, 'saldoToggled');

        let absenceTypes = get(this, 'store').peekAll('abcensetype');

        let saldoAbsenceId = get(this, 'saldoAbsenceId');
        let saldoAbsence = null;

        //we check if saldo absence already exists in absencetypes
        if (saldoAbsenceId) {
            saldoAbsence = absenceTypes.filter((type) => type.id == saldoAbsenceId);
        }

        //we just turned saldos on and we don't have an existing saldo absence, we need to create one
        if (toggled) {
            //if we have a saldo absence id but we do not have a saldo absence in absence types
            if (saldoAbsenceId && !saldoAbsence) {
                let record = get(this, 'store').createRecord('abcensetype', {
                    name: get(this, 'intl').t('saldo.saldo_absence'),
                });
                await record.save();
            }
        }

        return absenceTypes;
    }),

    init() {
        this._super();
        let columns = ['name', 'usesholiday'];
        this.columns = columns;
        this.rowActions = [
            { action: 'removeRow', icon: 'move move-Delete' },
            { action: 'editRow', icon: 'move move-Pencil' },
        ];

        //fetch all possible absencetypes to store
        get(this, 'store').findAll('abcensetype');

        //check if we already have saldos on (if rerunning wizard)
        /*if(get(this,'collector').testNeedsOne('products.saldo')){
            get(this, 'collector').ttapi({
                "url": "saldo/saldoSettings"
            }).then(this.setSaldoSettings.bind(this))
        }*/
    },

    actions: {
        openAbsenceModal() {
            set(this, 'absenceRecord', get(this, 'store').createRecord('abcensetype', {}));
            set(this, 'showAbsenceAddModal', true);
        },

        rowActions(action, row) {
            if (action == 'removeRow') {
                get(this, 'dialogs')
                    .confirm(get(this, 'intl').t('general.remove_row'))
                    .then(this._removeRowIfcon.bind(this, row));
            }

            if (action == 'editRow') {
                set(this, 'absenceRecord', row);
                set(this, 'showAbsenceAddModal', true);
            }
        },
    },

    setSaldoSettings(saldo) {
        if (saldo.length === 0) return;

        set(this, 'settings.saldoStart', saldo.startDate);
        set(this, 'settings.saldoMinimum', saldo.minimum / 60);
        set(this, 'settings.saldoMaximum', saldo.maximum / 60);
        set(this, 'settings.saldoDailymaximum ', saldo.dailymaximum / 60);
        set(this, 'settings.autoCutter', saldo.auto_cut);

        set(this, 'settings.saldoAbsenceId', saldo.saldo_absence_id);

        //toggle saldos on
        this.toggleProperty('saldoToggled');
    },

    checkIfSaldoAbsenceExists() {
        let saldoAbsenceId = get(this, 'saldoAbsenceId');

        if (!get(this, 'absenceTypes')) return;
        let saldoAbsence = get(this, 'absenceTypes').filter((type) => type.id == saldoAbsenceId);

        if (saldoAbsence) return true;
        return false;
    },

    _removeRowIfcon(row, con) {
        if (con) {
            row.destroyRecord();
        }
    },
});
