import Service, { inject as service } from '@ember/service';
import { isArray } from '@ember/array';

export default Service.extend({
    collector: service('collector-service'),
    test(need) {
        if (!isArray(need)) {
            need = [need];
        }
        return this.collector.testNeeds(need);
    },
});
