/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/general-report/component.coffee.old
// Generated by CoffeeScript 2.6.1
var GeneralReportComponent,
    indexOf1 = [].indexOf;

import Ember from 'ember';

import collector from '../../../classes/collector';

import config from '../../../config/environment';

import pdfmixin from '../../../mixins/worksitediary_pdf';

import saveTheChildren from '../../../mixins/save-the-children';

import { htmlSafe } from '@ember/string';

import { sanitize } from 'dom-purify';

import $ from 'jquery';

import { pluralize } from 'ember-inflector';

import { getAssetFolder, getScriptsInOrder } from 'tt4/utils/getScript';

GeneralReportComponent = Ember.Component.extend(pdfmixin, saveTheChildren, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    cordova: Ember.inject.service('own-cordova-service'),
    dialogs: Ember.inject.service(),
    gui: Ember.inject.service(),
    localStorageWrapper: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    exportFile: Ember.inject.service(),
    capi: Ember.inject.service(),
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    flags: Ember.inject.service(),
    fileExports: Ember.inject.service('file-exports'),
    surveys: Ember.inject.service(),
    summableFieldTypes: [
        'date',
        'hours',
        'number',
        'checkbox',
        'decimal',
        'price',
        'big_price',
        'minutes',
        'dropdown_values',
    ],
    imageTypes: ['image/jpeg'],
    showEmailModal: false,
    errors: Ember.A([]),
    loading: false,
    emailSent: false,
    translations: {
        twentyfive: 25,
        fifty: 50,
        hundred: 100,
    },
    isMobile: Em.computed(function () {
        return typeof cordova !== 'undefined' && cordova !== null;
    }),
    statusName: Ember.computed('intl.locale', 'status', function () {
        if (this.get('intl').exists('status.' + this.get('status'))) {
            return this.get('intl').t('status.' + this.get('status'));
        } else {
            return this.get('status');
        }
    }),
    selectStatus: Em.computed('form', function () {
        var c, field, intl, option, options;
        if (!this.get('form')) {
            return [];
        }
        c = this.get('Collector');
        intl = this.get('intl');
        field = c.field(this.get('form'), 'status');
        options = (function () {
            var j, len, ref, results;
            ref = field.options;
            results = [];
            for (j = 0, len = ref.length; j < len; j++) {
                option = ref[j];
                if (intl.exists('status.' + option)) {
                    results.push({
                        id: option,
                        name: intl.t('status.' + option),
                    });
                } else {
                    results.push({
                        id: option,
                        name: option,
                    });
                }
            }
            return results;
        })();
        return options;
    }),
    didInsertElement: function () {
        //close display-menu only if clicked outside of it
        //        $('.display-menu').on 'click', (event) ->
        //            if !$(this).hasClass 'open'
        //                $(this).addClass 'open'
        //            return
        //        $('body').on 'click touchend', (e) ->
        //            if !$('.display-menu').is(e.target) and $('.display-menu').has(e.target).length == 0 and $('.open').has(e.target).length == 0
        //                $('.display-menu').removeClass 'open'
        //            return
        $('.dropdown-toggler').on('click', function (event) {
            $('input.checkbox-button').prop('checked', false);
            return $(this).toggleClass('open');
        });
        $('input.checkbox-button').on('click', function (event) {
            return $('.dropdown-toggler').parent().addClass('open');
        });
        return $('li.dropdown-submenu ul.dropdown-menu li input').on('click', function (event) {
            return $('.dropdown-toggler').parent().addClass('open');
        });
    },
    didReceiveAttrs: function () {
        var isEmptyArray, key, valuefromLS;
        if (
            this.get('settings.filters') &&
            this.get('settings.filters').find((filter) => {
                return filter.name === 'groupBy' && Ember.isPresent(filter.value);
            })
        ) {
            this.set('groupBy', true);
        } else {
            this.set('groupBy', false);
        }
        if (this.get('settings.localStorageKey')) {
            this.set('localStorageKey', this.get('settings.localStorageKey'));
        }
        if (this.get('localStorageKey')) {
            key = this.get('localStorageKey') + '_defaultLimit';
        } else if (this.get('content.firstObject.constructor.modelName')) {
            key = this.get('content.firstObject.constructor.modelName') + '_defaultLimit';
        }
        this.set('defaultLimitKey', key);
        valuefromLS = this.get('localStorageWrapper').getUserSetting(key);
        // support for stupid getUserSetting function .. it returns empty array if value is not found at all
        isEmptyArray = Ember.typeOf(valuefromLS) === 'array' && valuefromLS.length === 0;
        if (valuefromLS && !isEmptyArray) {
            this.set('limit', valuefromLS);
        } else {
            if (!this.get('limit')) {
                Ember.run.next(this, function () {
                    return this.set('limit', '25');
                });
            }
        }
        if (this.get('defaultColumns')) {
            return this.send('sendColumns', this.get('defaultColumns'));
        }
    },
    moveElementInArray: function (obj, col, offset) {
        var index, newIndex;
        index = obj.indexOf(col);
        obj.removeObject(col);
        newIndex = index + offset;
        if (newIndex > obj.length) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = obj.length;
        }
        return obj.insertAt(newIndex, col);
    },
    createExcel: function (exportParams, base64 = false) {
        var tmp_rows;
        Ember.get(this, 'analytics').trackEvent({
            category: this.form + ' report actions',
            action: 'Excel-export',
            label: 'General-report',
        });
        tmp_rows = new Em.RSVP.Promise((resolve, reject) => {
            var content, params;
            if (this.get('checked').length > 0) {
                content = this.get('checked');
                return resolve(content);
            } else {
                params = {
                    limit: 'all',
                    sideload: true,
                };
                if (this.get('settings.filters')) {
                    this.get('settings.filters').forEach((filter) => {
                        var add;
                        // haxx to set q-parameter correctly
                        if (filter.get('bindto')) {
                            return (params['q'] = filter.get('value'));
                        } else if (
                            this.get('customQueryparams') &&
                            this.get('customQueryparamsKey') === filter.get('field')
                        ) {
                            return (params[this.get('customQueryparams')] = filter.get('value'));
                        } else if (filter.get('value') && filter.get('subform')) {
                            add = {};
                            add[filter.get('field')] = filter.get('value');
                            return (params[filter.get('form')] = add);
                        } else if (filter.get('value') && filter.get('field') !== 'status') {
                            return (params[filter.get('field')] = filter.get('value'));
                        }
                    });
                }
                if (this.get('settings.showStatus') === 'removed') {
                    params.row_info_status = 'removed';
                }
                if (this.get('settings.sortByColumn')) {
                    params.order = this.get('settings.sortByColumn');
                }
                if (this.get('status') !== 'all') {
                    params.status = this.get('status');
                }
                return this.get('store')
                    .query(this.get('form'), params)
                    .then((rows) => {
                        return resolve(rows);
                    });
            }
        });
        return tmp_rows.then((rows) => {
            var columns, filteredColumns, sumRow;
            filteredColumns = [];
            columns = [];
            if (exportParams.template) {
                filteredColumns = exportParams.template.get('columns').split(',');
            }
            sumRow = false;
            this.set('showLoader', true);
            return this.possiblyCalculateSumRow(
                rows,
                false,
                exportParams['addSumRow'],
                this.get('settings.rowSettings.hideCheckbox'),
            ).then((unformatted_sumrow) => {
                var excelJSON, filters, form;
                form = this.get('form');
                this.get('defaultColumns')
                    .concat(this.get('extraColumns'))
                    .forEach((row) => {
                        if (filteredColumns.length > 0 && filteredColumns.indexOf(row.ext) === -1) {
                            return;
                        }
                        return columns.push(row);
                    });
                filters = this.getPdfFilters();
                sumRow = unformatted_sumrow;
                excelJSON = this.get('fileExports').createExcelJSONFromForm(
                    form,
                    rows,
                    columns,
                    filters,
                    sumRow,
                );
                if (base64) {
                    return this.get('fileExports').createBase64ExcelFromJSON(excelJSON);
                } else {
                    return this.get('fileExports')
                        .createExcelFromJSON(excelJSON)
                        .then(() => {
                            this.set('showEmailModal', false);
                            return this.set('showLoader', false);
                        });
                }
            });
        });
    },
    customQueryparams: Ember.computed(function () {
        var formFeatures, forms;
        forms = this.get('Collector').forms();
        // some form have dash in their name so we need to change them to underscore
        formFeatures = forms[this.get('form').underscore()].features;
        if (formFeatures != null && formFeatures.custom_queryparams) {
            return formFeatures.custom_queryparams;
        }
        return false;
    }),
    customQueryparamsKey: Ember.computed('customQueryparams', function () {
        if (!this.get('customQueryparams')) {
            return;
        }
        //Some forms have custom_queryparams divided by : for example pass_start:pass_end
        if (this.get('customQueryparams').includes(':')) {
            return this.get('customQueryparams').split(':')[0];
        } else {
            return this.get('customQueryparams').split('_')[0];
        }
    }),
    showShareLink: Em.computed('session.currentUser', function () {
        if (!this.get('session.currentUser')) {
            return;
        }
        return this.get('form') === 'presence' && this.get('Collector').testNeeds(['userlevel=5']);
    }),
    layoutName: Ember.computed(function () {
        if (this.get('templatename')) {
            return this.get('templatename');
        } else {
            return 'components/general-report';
        }
    }),
    showPdfExport: true,
    showPdfExportFull: true,
    filterMode: false,
    //    date: moment().format('YYYY-MM-DD')
    defaultChangeStatus: Em.computed(function () {
        var obj;
        obj = {};
        obj.action = 'approved';
        obj.btn_class = 'btn-primary';
        obj.text = this.get('intl').t('report.approve_rows');
        return obj;
    }),
    showDefaultChangeStatus: Ember.computed('canChangeStatus', 'statusTypes', function () {
        var approvingIsAllowed;
        if (!this.get('canChangeStatus')) {
            return false;
        }
        approvingIsAllowed = this.get('statusTypes').mapBy('id').indexOf('approved') !== -1;
        return approvingIsAllowed;
    }),
    defaultChangeStatusAction: 'approved',
    canChangeStatus: Ember.computed(function () {
        var field;
        field = collector.getFormObject(this.get('form') + '.status');
        if (this.get('status') && field) {
            if (!field.features.readonly) {
                return true;
            }
        }
        return false;
    }),
    statusTypes: Em.computed('form', function () {
        var c, field, fieldOptions, intl, option, options;
        if (!this.get('form')) {
            return [];
        }
        c = this.get('Collector');
        intl = this.get('intl');
        field = c.field(this.get('form'), 'status');
        if (field.features.only_options) {
            fieldOptions = field.features.only_options.split(',');
        } else {
            fieldOptions = field.options;
        }
        options = (function () {
            var j, len, results;
            results = [];
            for (j = 0, len = fieldOptions.length; j < len; j++) {
                option = fieldOptions[j];
                if (intl.exists('status.' + option)) {
                    results.push({
                        id: option,
                        name: intl.t('report.' + option + '_rows'),
                    });
                } else {
                    results.push({
                        id: option,
                        name: intl.t('report.change_status') + option,
                    });
                }
            }
            return results;
        })();
        return options;
    }),
    offset: null,
    didInsertElement: function () {
        return this.set('offset', this.get('limit') * this.get('page'));
    },
    page: 1,
    pageObserver: function () {
        if (this.get('page') <= 0) {
            //            @set 'currentController.page',1
            return this.set('page', 1);
        }
    }.observes('page'),
    pageCount: Ember.computed('content.meta.count', 'limit', 'count', function () {
        var count;
        count = Ember.isPresent(this.get('count'))
            ? this.get('count')
            : this.get('content.meta.count');
        return Math.ceil(count / this.get('limit'));
    }),
    pages: Ember.computed('limit', 'content', function () {
        var count, limit, pages;
        count = Ember.isPresent(this.get('count'))
            ? this.get('count')
            : this.get('content.meta.count');
        limit = this.get('limit');
        pages = Math.ceil(count / limit);
        if (pages > 0) {
            return pages;
        }
        return false;
    }),
    columns: Ember.on(
        'init',
        Ember.observer(
            'form',
            'content.[]',
            'intl.locale',
            'groupBy',
            'currentTemplate',
            function () {
                var _currentTemplate,
                    column,
                    columns,
                    defaultColumns,
                    extraColumns,
                    field,
                    form,
                    form_objects,
                    globalHide,
                    i,
                    intl,
                    j,
                    k,
                    key,
                    len,
                    name,
                    obj,
                    objects,
                    orderable,
                    ref,
                    showColumnsFromLS,
                    shownColumns,
                    static_fields,
                    summableFieldTypes,
                    summableFields,
                    supportForOldFormat,
                    tmpTemplate,
                    translatedName,
                    uses_mongo;
                form = this.get('form');
                shownColumns = [];
                uses_mongo = this.get('Collector').uses_mongo(form);
                // TODO: Hide some globally stupid fields that probably shouldn't even come from api
                globalHide = ['send_sms', 'linker', 'user_linker', 'device'];
                if (this.get('form_objects')) {
                    objects = this.get('form_objects');
                } else {
                    objects = collector.getFormObjects(form);
                }
                if (this.get('type') === 'group') {
                    objects = this.groupObjects(objects);
                }
                if (!objects) {
                    return;
                }
                if (this.get('settings.localStorageKey')) {
                    this.set('localStorageKey', this.get('settings.localStorageKey'));
                }
                if (this.get('localStorageKey')) {
                    key = this.get('localStorageKey') + '_defaultColumns';
                } else if (this.get('content.firstObject.constructor.modelName')) {
                    key = this.get('content.firstObject.constructor.modelName') + '_defaultColumns';
                }
                supportForOldFormat = [];
                if (localStorage.getItem(key)) {
                    supportForOldFormat = JSON.parse(localStorage.getItem(key));
                }
                showColumnsFromLS = this.get('localStorageWrapper').getUserSetting(
                    key,
                    supportForOldFormat,
                );
                if (showColumnsFromLS && showColumnsFromLS.length > 0 && key) {
                    shownColumns = showColumnsFromLS;
                }
                // skip default showColumns (and show all) if there are no columns defined in localstorage
                //else if @get 'showColumns'
                //    shownColumns = @get 'showColumns'

                // save currentTemplate to localstorage so we can use it
                _currentTemplate =
                    this.get('content.firstObject.constructor.modelName') + '_currentTemplate';
                if (this.get('currentTemplate')) {
                    this.get('localStorageWrapper').setUserSetting(_currentTemplate, {
                        columns: this.get('currentTemplate.columns'),
                        name: this.get('currentTemplate.name'),
                    });
                    shownColumns = this.get('currentTemplate.columns').split(',');
                }
                // if currentTemplate is saved to localstorage, use it
                if (this.get('localStorageWrapper').getUserSetting(_currentTemplate).columns) {
                    tmpTemplate = this.get('localStorageWrapper').getUserSetting(_currentTemplate);
                    shownColumns = tmpTemplate.columns.split(',');
                }
                if (shownColumns.length <= 0) {
                    shownColumns = [];
                    i = 0;
                    for (k in objects) {
                        obj = objects[k];
                        i++;
                        shownColumns.push(k);
                    }
                }
                //@set('showColumns', shownColumns)
                columns = Em.A([]);
                defaultColumns = Em.A([]);
                extraColumns = Em.A([]);
                summableFieldTypes = this.get('summableFieldTypes');
                summableFields = Em.A([]);
                for (name in objects) {
                    obj = objects[name];
                    if (
                        obj.features.hide_column ||
                        obj.features.hide_tt4 ||
                        name.slice(-6) === '_minus'
                    ) {
                        continue;
                    }
                    if (indexOf1.call(globalHide, name) >= 0) {
                        continue;
                    }
                    if (this.get('settings.hideColumns')) {
                        if (indexOf1.call(this.get('settings.hideColumns'), name) >= 0) {
                            continue;
                        }
                    }
                    translatedName = obj.name || this.get('intl').t('report.' + form + '.' + name);
                    if (indexOf1.call(shownColumns, name) >= 0) {
                    } else {
                        //do not put defaultColumns here
                        //defaultColumns.pushObject {name: translatedName, ext: name, obj: obj}
                        if (
                            !(
                                name.indexOf('_minus', name.length - 6) !== -1 ||
                                obj.features.hide_column
                            )
                        ) {
                            if (obj.type !== 'multiselect_tmp') {
                                if (!obj.ext) {
                                    obj.ext = name;
                                }
                                extraColumns.pushObject({
                                    name: translatedName,
                                    ext: name,
                                    obj: obj,
                                });
                            }
                        }
                    }
                    columns.pushObject({
                        name: translatedName,
                        ext: name,
                    });
                    if (
                        summableFieldTypes &&
                        ((ref = obj.type), indexOf1.call(summableFieldTypes, ref) >= 0)
                    ) {
                        summableFields.pushObject({
                            ext: name,
                            type: obj.type,
                        });
                    }
                }
                // Add static columns here, like row_info stuff that is on every record
                intl = this.get('intl');
                static_fields = {
                    'row_info.created': {
                        name: intl.t('general.row_info.created'),
                        ext: 'row_info.created',
                        obj: {
                            type: 'row_info',
                            features: {},
                            ext: 'row_info.created',
                        },
                        orderable: true,
                    },
                };
                for (key in static_fields) {
                    field = static_fields[key];
                    if (indexOf1.call(shownColumns, key) >= 0) {
                        continue;
                    }
                    extraColumns.pushObject(field);
                    columns.pushObject(field);
                }
                // new way of showing columns
                for (j = 0, len = shownColumns.length; j < len; j++) {
                    column = shownColumns[j];
                    if (static_fields[column] != null) {
                        defaultColumns.pushObject(static_fields[column]);
                        columns.pushObject(field);
                        continue;
                    }
                    if (indexOf1.call(globalHide, column) >= 0) {
                        continue;
                    }
                    if (this.get('settings.hideColumns')) {
                        if (indexOf1.call(this.get('settings.hideColumns'), column) >= 0) {
                            continue;
                        }
                    }
                    // if form_objects are given as parameter, use them
                    if (this.get('form_objects')) {
                        form_objects = this.get('form_objects');
                        obj = form_objects[column];
                        translatedName = this.get('intl').t('report.' + form + '.' + column);
                        if (obj.translation) {
                            translatedName = this.get('intl').t(obj.translation);
                        }
                    } else if (column.indexOf('.') !== -1) {
                        obj = collector.getFormObject(column);
                        translatedName = this.get('intl').t('report.' + column);
                    } else {
                        obj = collector.getFormObject(form + '.' + column);
                        translatedName = obj.name || this.getTranslation(form, column);
                    }
                    if (!obj) {
                        continue;
                    }
                    if (
                        obj.features.hide_column ||
                        obj.features.hide_tt4 ||
                        column.slice(-6) === '_minus'
                    ) {
                        continue;
                    }
                    if (uses_mongo && obj.type === 'database') {
                        orderable = false;
                    } else {
                        orderable = true;
                    }
                    if (obj) {
                        if (!obj.ext) {
                            obj.ext = column;
                        }
                        defaultColumns.pushObject({
                            name: htmlSafe(sanitize(translatedName)),
                            ext: column,
                            obj: obj,
                            orderable: orderable,
                        });
                        columns.pushObject({
                            name: htmlSafe(sanitize(translatedName)),
                            ext: column,
                        });
                    }
                }
                if (this.get('groupBy')) {
                    [defaultColumns, extraColumns] = this.getGroupColumns(
                        defaultColumns,
                        extraColumns,
                    );
                }
                this.set('defaultColumns', defaultColumns);
                this.set('extraColumns', extraColumns);
                this.set('summableFields', summableFields);
                return columns;
            },
        ),
    ),
    getGroupColumns: function (defaultColumns, extraColumns) {
        var columnsToExtra, columnsToShow, defaultColumnsToShow;
        if (!this.get('groupBy') || !this.get('content.firstObject')) {
            return [defaultColumns, extraColumns];
        }
        defaultColumnsToShow = Object.keys(this.get('content.firstObject'));
        columnsToShow = defaultColumnsToShow.map((ext) => {
            return defaultColumns.find((column) => {
                return ext === column.ext;
            });
        });
        columnsToExtra = defaultColumnsToShow.map((ext) => {
            return extraColumns.find((column) => {
                return ext === column.ext;
            });
        });
        columnsToShow = columnsToShow.compact().uniq();
        columnsToExtra = columnsToExtra.compact().uniq();
        return [columnsToShow, columnsToExtra];
    },
    getTranslation: function (form, column) {
        if (form.substring(0, 18) === 'measurement-custom') {
            // little hack to support measurement-custom form headers
            form = 'tr';
        }
        //return @get('intl').t 'report.'+form+'.'+column
        return this.get('Collector').getBestTranslation(form, column);
    },
    groupObjects: function (all_objects) {
        var content, first_row, ret;
        content = this.get('content');
        if (content.length < 1) {
            return null;
        }
        first_row = content[0];
        ret = {};
        $.each(all_objects, function (key, item) {
            if (key in first_row) {
                return (ret[key] = item);
            }
        });
        return ret;
    },
    setLocalStorageColumns: function () {
        var columns, key;
        if (this.get('groupBy')) {
            //If groupBy then dont set defaultColumns to local storage so they can be restored back when groupBy filter is removed
            return;
        }
        //return if @get('currentTemplate') #Don't save template columns to LS so we can return to old state
        columns = [];
        if (this.get('localStorageKey')) {
            key = this.get('localStorageKey') + '_defaultColumns';
        } else if (this.get('content.firstObject.constructor.modelName')) {
            key = this.get('content.firstObject.constructor.modelName') + '_defaultColumns';
        }
        this.get('defaultColumns').forEach(function (obj) {
            return columns.push(obj.ext);
        });
        if (key) {
            return this.get('localStorageWrapper').setUserSetting(key, columns);
        }
    }.observes('defaultColumns.[]'),
    dates: Ember.computed('content', function () {
        var dates, item, k, model, ret;
        if (this.get('sortByDates')) {
            model = this.get('content').sortBy('date');
            dates = Em.A({});
            model.forEach(function (item) {
                var date;
                date = item.get('date');
                if (dates[date]) {
                    return dates[date].rows.pushObject(item);
                } else {
                    return (dates[date] = {
                        date: item.get('date'),
                        rows: [item],
                    });
                }
            });
            ret = Em.A([]);
            for (k in dates) {
                item = dates[k];
                ret.pushObject(item);
            }
            return ret;
        }
    }),
    sumRow: Em.computed('content.[]', 'defaultColumns.[]', 'checked.[]', function () {
        var columns, model, self, sortByDate, sumRow, summableFields, sums, unformatted_sumRow;
        self = this;
        sortByDate = null;
        if (this.get('form') === 'abcense') {
            sortByDate = this.get('content').sortBy('startdate');
        } else {
            sortByDate = this.get('content').sortBy('date');
        }
        if (this.get('checked.length') > 0) {
            model = this.get('checked');
        } else {
            model = Ember.isPresent(this.get('content')) ? sortByDate : [];
        }
        columns = this.get('defaultColumns');
        // TODO Should rewrite avi report to not use this general-report component
        if (this.get('form') === 'avi') {
            return;
        }
        if (!columns) {
            return;
        }
        if (Ember.get(model, 'length') === 0) {
            return;
        }
        summableFields = this.get('summableFields');
        sumRow = Em.A([]);
        unformatted_sumRow = Em.A([]);
        if (!this.get('settings.rowSettings.hideCheckbox')) {
            sumRow.pushObject('');
            unformatted_sumRow.pushObject('');
        }
        sums = {};
        model.forEach(function (item) {
            return summableFields.forEach(function (field) {
                var cell;
                if (!(field.ext in sums)) {
                    sums[field.ext] = 0;
                }
                if (item.get == null) {
                    return;
                }
                cell = item.get(field.ext);
                if (field.type === 'checkbox') {
                    if (!sums[field.ext]) {
                        sums[field.ext] = [0, 0];
                    }
                    if (item.get(field.ext)) {
                        return sums[field.ext][0]++;
                    } else {
                        return sums[field.ext][1]++;
                    }
                } else if (field.type === 'dropdown_values') {
                    if (!sums[field.ext]) {
                        sums[field.ext] = [];
                    }
                    if (cell) {
                        return sums[field.ext].push(cell);
                    }
                } else if (field.type === 'date') {
                    if (Ember.typeOf(cell) === 'string' && cell.substring(0, 6) === 'count:') {
                        return (sums[field.ext] += parseInt(cell.substring(6)));
                    } else {
                        sums[field.ext] = '';
                    }
                } else {
                    return (sums[field.ext] += cell);
                }
            });
        });
        columns.forEach(function (column) {
            var dropdownSums, field, sumToFixed;
            field = column.ext;
            dropdownSums = [];
            if (column.obj.type === 'checkbox') {
                sumRow.pushObject(
                    self.Collector.format(true, column.obj, null, model) +
                        ': ' +
                        sums[field][0] +
                        ' / ' +
                        self.Collector.format(false, column.obj, null, model) +
                        ': ' +
                        sums[field][1],
                );
                return unformatted_sumRow.pushObject(
                    self.Collector.format(true, column.obj, null, model) +
                        ': ' +
                        sums[field][0] +
                        ' / ' +
                        self.Collector.format(false, column.obj, null, model) +
                        ': ' +
                        sums[field][1],
                );
            } else if (field in sums) {
                if (
                    column.obj.type === 'number' &&
                    !isNaN(sums[field]) &&
                    !Number.isInteger(sums[field])
                ) {
                    sumToFixed = sums[field].toFixed(1);
                    sumRow.pushObject(self.Collector.format(sumToFixed, column.obj, null, model));
                    //unformatted_sumRow.pushObject self.Collector.format(sums[field], column.obj, null, model, 'sumrow').toFixed(1)
                    //unformatted_sumRow.pushObject sums[field]
                    return unformatted_sumRow.pushObject(
                        self.Collector.format(sumToFixed, column.obj, null, model, 'sumrow'),
                    );
                } else if (column.obj.type === 'dropdown_values') {
                    sums[field].forEach(function (opt) {
                        return (dropdownSums[opt] = (dropdownSums[opt] || 0) + 1);
                    });
                    sumRow.pushObject(
                        self.Collector.format(
                            dropdownSums,
                            column.obj,
                            null,
                            model,
                            false,
                            self.get('form'),
                        ),
                    );
                    return unformatted_sumRow.pushObject(
                        self.Collector.format(
                            dropdownSums,
                            column.obj,
                            null,
                            model,
                            'sumrow',
                            self.get('form'),
                        ),
                    );
                } else {
                    sumRow.pushObject(self.Collector.format(sums[field], column.obj, null, model));
                    //unformatted_sumRow.pushObject self.Collector.format(sums[field], column.obj, null, model, 'sumrow')
                    return unformatted_sumRow.pushObject(sums[field]);
                }
            } else {
                sumRow.pushObject('');
                return unformatted_sumRow.pushObject('');
            }
        });
        this.set('unformatted_sumRow', unformatted_sumRow);
        return sumRow;
    }),
    possiblyCalculateSumRow: function (
        content,
        format,
        calculateSumRow = false,
        hideFirstCheckbox = false,
    ) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var columns, model, self, sortByDate, sumRow, summableFields, sums, unformatted_sumRow;
            if (!calculateSumRow) {
                resolve(false);
            }
            self = this;
            sortByDate = null;
            if (this.get('form') === 'abcense') {
                sortByDate = content.sortBy('startdate');
            } else {
                sortByDate = content.sortBy('date');
            }
            if (this.get('checked.length') > 0) {
                model = this.get('checked');
            } else {
                model = Ember.isPresent(content) ? sortByDate : [];
            }
            columns = this.get('defaultColumns');
            // TODO Should rewrite avi report to not use this general-report component
            if (this.get('form') === 'avi') {
                return;
            }
            if (!columns) {
                return;
            }
            if (Ember.get(model, 'length') === 0) {
                return;
            }
            summableFields = this.get('summableFields');
            sumRow = Em.A([]);
            unformatted_sumRow = Em.A([]);
            if (!hideFirstCheckbox) {
                sumRow.pushObject('');
                unformatted_sumRow.pushObject('');
            }
            sums = {};
            model.forEach(function (item) {
                return summableFields.forEach(function (field) {
                    var cell;
                    if (!(field.ext in sums)) {
                        sums[field.ext] = 0;
                    }
                    if (item.get == null) {
                        return;
                    }
                    cell = item.get(field.ext);
                    if (field.type === 'checkbox') {
                        if (!sums[field.ext]) {
                            sums[field.ext] = [0, 0];
                        }
                        if (item.get(field.ext)) {
                            return sums[field.ext][0]++;
                        } else {
                            return sums[field.ext][1]++;
                        }
                    } else if (field.type === 'dropdown_values') {
                        if (!sums[field.ext]) {
                            sums[field.ext] = [];
                        }
                        if (cell) {
                            return sums[field.ext].push(cell);
                        }
                    } else if (field.type === 'date') {
                        if (Ember.typeOf(cell) === 'string' && cell.substring(0, 6) === 'count:') {
                            return (sums[field.ext] += parseInt(cell.substring(6)));
                        } else {
                            sums[field.ext] = '';
                        }
                    } else {
                        return (sums[field.ext] += cell);
                    }
                });
            });
            columns.forEach(function (column) {
                var dropdownSums, field;
                field = column.ext;
                dropdownSums = [];
                if (column.obj.type === 'checkbox') {
                    sumRow.pushObject(
                        self.Collector.format(true, column.obj, null, model) +
                            ': ' +
                            sums[field][0] +
                            ' / ' +
                            self.Collector.format(false, column.obj, null, model) +
                            ': ' +
                            sums[field][1],
                    );
                    return unformatted_sumRow.pushObject(
                        self.Collector.format(true, column.obj, null, model) +
                            ': ' +
                            sums[field][0] +
                            ' / ' +
                            self.Collector.format(false, column.obj, null, model) +
                            ': ' +
                            sums[field][1],
                    );
                } else if (field in sums) {
                    if (
                        column.obj.type === 'number' &&
                        !isNaN(sums[field]) &&
                        !Number.isInteger(sums[field])
                    ) {
                        sumRow.pushObject(
                            parseFloat(
                                self.Collector.format(sums[field], column.obj, null, model),
                            ).toFixed(1),
                        );
                        //unformatted_sumRow.pushObject self.Collector.format(sums[field], column.obj, null, model, 'sumrow').toFixed(1)
                        //unformatted_sumRow.pushObject sums[field]
                        return unformatted_sumRow.pushObject(
                            parseFloat(
                                self.Collector.format(
                                    sums[field],
                                    column.obj,
                                    null,
                                    model,
                                    'sumrow',
                                ),
                            ).toFixed(1),
                        );
                    } else if (column.obj.type === 'dropdown_values') {
                        sums[field].forEach(function (opt) {
                            return (dropdownSums[opt] = (dropdownSums[opt] || 0) + 1);
                        });
                        sumRow.pushObject(
                            self.Collector.format(
                                dropdownSums,
                                column.obj,
                                null,
                                model,
                                false,
                                self.get('form'),
                            ),
                        );
                        return unformatted_sumRow.pushObject(
                            self.Collector.format(
                                dropdownSums,
                                column.obj,
                                null,
                                model,
                                'sumrow',
                                self.get('form'),
                            ),
                        );
                    } else {
                        sumRow.pushObject(
                            self.Collector.format(sums[field], column.obj, null, model),
                        );
                        //unformatted_sumRow.pushObject self.Collector.format(sums[field], column.obj, null, model, 'sumrow')
                        return unformatted_sumRow.pushObject(sums[field]);
                    }
                } else {
                    sumRow.pushObject('');
                    return unformatted_sumRow.pushObject('');
                }
            });
            if (format) {
                return resolve(sumRow);
            } else {
                console.log(unformatted_sumRow);
                return resolve(unformatted_sumRow);
            }
        });
    },
    hasPreviousPage: Ember.computed('offset', 'page', 'content', 'limit', function () {
        return this.get('offset') !== 0;
    }),
    hasNextPage: Ember.computed('offset', 'limit', 'page', 'content', function () {
        return this.get('offset') + this.get('limit') < this.get('content.meta.count');
    }),
    checked: Em.on('init', function () {
        return this.set('checked', Em.A([]));
    }),
    checkClass: Em.computed('checked.[]', function () {
        if (this.get('checked.length') === 0) {
            return 'unchecked';
        } else if (this.get('checked.length') === this.get('content.length')) {
            return 'checked';
        } else {
            return 'unchecked';
        }
    }),
    typeObserver: function () {
        if (this.get('type') === 'group') {
            return this.set('showCheckbox', false);
        }
    }.observes('type'),
    getExportCols: function (row, cols) {
        var col, j, len, obj;
        obj = Em.Object.create();
        for (j = 0, len = cols.length; j < len; j++) {
            col = cols[j];
            obj.set(col, row[col]);
        }
        return obj;
    },
    showGpsButton: Ember.computed('_showGpsButton', 'defaultColumns.[]', function () {
        var defaultfields, extrafields, gpsfound;
        if (this.get('_showGpsButton')) {
            return true;
        }
        gpsfound = false;
        defaultfields = this.get('defaultColumns');
        if (!defaultfields) {
            return;
        }
        if (this.get('form') === !'worktime') {
            return;
        }
        defaultfields.forEach(function (field) {
            if (field.ext === 'location' || field.ext === 'end_location') {
                return (gpsfound = true);
            }
        });
        extrafields = this.get('extraColumns');
        extrafields.forEach(function (field) {
            if (field.ext === 'location' || field.ext === 'end_location') {
                return (gpsfound = true);
            }
        });
        return gpsfound;
    }),
    selectedRows: Ember.computed('checked.[]', function () {
        return this.get('checked').mapBy('id').join(',');
    }),
    updateMarkers: Em.observer('checked.[]', function () {
        if (this.get('showGpsMap')) {
            return this.setMapMarkers();
        }
    }),
    setProjectMarkers: function (projects) {
        var d, locs;
        d = new $.Deferred();
        locs = [];
        projects.forEach((project) => {
            var id, loc, projLoc;
            if (project.get('location_map')) {
                id = project.get('id');
                projLoc = project.get('location_map');
                loc = {};
                loc.project = JSON.parse(projLoc);
                loc.project.label = project.get('name');
                return locs.push(loc);
            }
        });
        this.set('projectmarkers', locs);
        d.resolve();
        return d;
    },
    setWorktimeMarkers: function (rows) {
        var d, i, locs, totalLength;
        d = new $.Deferred();
        locs = [];
        i = 0;
        totalLength = rows.get('length');
        rows.forEach((row) => {
            var creator, name, project, user;
            if (!row.get('location') && !row.get('end_location') && !row.get('location_map')) {
                totalLength--;
                return;
            }
            project = row.get('project');
            user = row.get('user');
            if (user) {
                name = user.get('lastname') + ' ' + user.get('firstname');
            } else {
                creator = row.get('creator');
                name = creator.get('lastname') + ' ' + creator.get('firstname');
            }
            return this.get('store')
                .query('project', {
                    id: project.get('id'),
                })
                .then((projects) => {
                    var loc, projLoc, record;
                    record = projects.get('firstObject');
                    projLoc = record.get('location_map');
                    loc = {};
                    if (row.get('location')) {
                        loc.wStartLoc = JSON.parse(row.get('location'));
                    }
                    loc.wLabel = name;
                    if (row.get('end_location')) {
                        loc.wEndLoc = JSON.parse(row.get('end_location'));
                    }
                    if (projLoc) {
                        loc.project = JSON.parse(projLoc);
                        loc.project.label = project.get('name');
                    }
                    locs.push(loc);
                    if (++i === totalLength) {
                        this.set('wtmarkers', locs);
                        return d.resolve();
                    }
                });
        });
        return d;
    },
    setMapMarkers: function () {
        var rows;
        //get data from checked (if there is checked)
        if (this.get('checked').length > 0) {
            rows = this.get('checked');
        } else {
            rows = this.get('content');
        }
        if (rows.get('firstObject.project')) {
            //timetracker list route
            return this.setWorktimeMarkers(rows); //projects route
        } else {
            return this.setProjectMarkers(rows);
        }
    },
    moreLinksClass: Em.computed('checked.[]', function () {
        if (this.get('checked.length') === 0) {
            return 'disabled';
        }
    }),
    canLock: Em.computed(function () {
        return this.get('Collector').testNeeds(['userlevel=5']);
    }),
    getPdfFilters: function (rows) {
        var c, filter, filterName, filterValue, j, len, pdf_filters_body, ref, value, values;
        c = this.get('Collector');
        if (Em.isEmpty(this.get('settings.filters'))) {
            return [];
        }
        pdf_filters_body = [];
        ref = this.get('settings.filters');
        for (j = 0, len = ref.length; j < len; j++) {
            filter = ref[j];
            if (filter.get('value') && filter.get('name') !== 'groupBy') {
                if (Ember.isArray(filter.get('value')) && filter.get('value.length') === 0) {
                    continue;
                }
                if (filter.get('type') === 'database') {
                    if (typeof filter.get('value') !== 'string') {
                        value = [];
                    } else {
                        value = filter.get('value').split(',');
                    }
                } else {
                    value = filter.get('value');
                }
                if (filter.get('bindto') && !filter.get('field')) {
                    filterValue = value;
                    filterName = this.get('intl').t('filters.free_search');
                } else {
                    if (Ember.isArray(value)) {
                        values = '';
                        value.forEach(function (id, index) {
                            id = parseInt(id);
                            values += c._format_(
                                id,
                                filter.get('form'),
                                filter.get('field'),
                                'pdf',
                                rows,
                            );
                            if (index + 1 < value.length) {
                                return (values += ', ');
                            }
                        });
                        filterValue = values;
                    } else {
                        filterValue = c._format_(
                            value,
                            filter.get('form'),
                            filter.get('field'),
                            'pdf',
                            rows,
                        );
                    }
                    filterName = this.get('intl').t(filter.get('form') + '.' + filter.get('name'));
                }
                if (filterValue != null && filterValue) {
                    filterValue = filterValue;
                }
                //if empty name (own fields), get name from collector service
                if (Em.isEmpty(filterName)) {
                    filterName = c.field(filter.get('form'), filter.get('field')).translated_name;
                }
                if (this.get('full_pdf_export')) {
                    pdf_filters_body.push([
                        {
                            text: filterName + ': ' + filterValue,
                            style: 'headertext',
                        },
                    ]);
                } else {
                    pdf_filters_body.push([filterName + ':', filterValue]);
                }
            }
        }
        if (pdf_filters_body.length && this.get('full_pdf_export')) {
            //add some margin between filters and rest of the pdf
            pdf_filters_body.push([
                {
                    text: '',
                    style: 'marginTop',
                },
            ]);
        }
        return pdf_filters_body;
    },
    littleBitUglyPdfExportMethod: function (
        format = null,
        includeAllPages = false,
        topHeader = false,
        exportParams,
    ) {
        return new Ember.RSVP.Promise((resolve) => {
            var getImageUri, pdf_filters, self, store;
            self = this;
            store = this.get('store');
            this.set('full_pdf_export', false);
            pdf_filters = null;
            getImageUri = function (value, index) {
                var d, img;
                d = new $.Deferred();
                img = new Image();
                img.onload = function () {
                    var canvas, ctx, dataUrl;
                    canvas = document.createElement('canvas');
                    canvas.width = this.width;
                    canvas.height = this.height;
                    ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    dataUrl = canvas.toDataURL();
                    return d.resolve({
                        data: dataUrl,
                        index: index,
                    });
                };
                img.setAttribute('crossOrigin', 'anonymous');
                img.src = config.APP.COLLECTOR_API_URL + '/' + value.thumb;
                return d;
            };
            return this.Collector.getLogo().then((logo) => {
                var columns,
                    company,
                    d,
                    filteredColumns,
                    get_status_info,
                    pdf_output,
                    pdf_output_last,
                    report_name_text,
                    row_ids,
                    templateColumnsNotInDefault,
                    title_row,
                    tmp_rows,
                    widths;
                company = self.get('session.currentUser.company');
                if (company == null) {
                    company = '';
                }
                report_name_text = company;
                pdf_output = [];
                pdf_output_last = [];
                //title row
                title_row = [];
                columns = [];
                widths = [];
                row_ids = [];
                filteredColumns = [];
                get_status_info = false;
                if (exportParams.template) {
                    filteredColumns = exportParams.template.get('columns').split(',');
                }
                templateColumnsNotInDefault = this.get('extraColumns').filter((column) => {
                    return filteredColumns.includes(Ember.get(column, 'ext'));
                });
                this.get('defaultColumns')
                    .concat(templateColumnsNotInDefault)
                    .forEach(function (row) {
                        var ref, status_last_changed, title_text;
                        if (filteredColumns.length > 0 && filteredColumns.indexOf(row.ext) === -1) {
                            return;
                        }
                        columns.push(row.obj);
                        if (
                            (ref = Em.get(row, 'obj.type')) === 'textbox' ||
                            ref === 'textarea' ||
                            ref === 'textarea_varchar'
                        ) {
                            widths.push(80);
                        } else {
                            widths.push('auto');
                        }
                        if (row.name.string != null) {
                            title_row.push(row.name.string);
                        } else {
                            title_row.push(row.name);
                        }
                        if (row.obj.type === 'status' && Ember.get(exportParams, 'statusInfo')) {
                            get_status_info = true;
                            status_last_changed = {
                                type: 'textbox',
                                ext: 'status_last_changed',
                                clickable: false,
                            };
                            columns.push(status_last_changed);
                            widths.push('auto');
                            title_text = self.get('intl').t('export.pdf.status_last_change');
                            return title_row.push(title_text.toString());
                        }
                    });
                pdf_output.push(title_row);
                d = $.Deferred();
                tmp_rows = new Em.RSVP.Promise((resolve, reject) => {
                    var key, param, params, ref;
                    //d = $.Deferred();
                    if (self.get('checked').length > 0 && !includeAllPages) {
                        return resolve(self.get('checked'));
                    } else {
                        params = {
                            limit: 'all',
                            sideload: true,
                        };
                        // support for additional query-params
                        if (exportParams.extraParams) {
                            ref = exportParams.extraParams;
                            for (key in ref) {
                                param = ref[key];
                                params[key] = param;
                            }
                        }
                        if (self.get('settings.filters')) {
                            self.get('settings.filters').forEach(function (filter) {
                                if (filter.get('value') && filter.get('field') !== 'status') {
                                    if (filter.get('bindto') && !filter.get('field')) {
                                        return (params[filter.get('bindto')] = filter.get('value'));
                                    } else if (
                                        filter.get('form') !== self.get('form').underscore()
                                    ) {
                                        return (params[
                                            filter.get('form').concat('[', filter.get('field'), ']')
                                        ] = filter.get('value'));
                                    } else if (
                                        self.get('customQueryparams') &&
                                        self.get('customQueryparamsKey') === filter.get('field')
                                    ) {
                                        return (params[self.get('customQueryparams')] =
                                            filter.get('value'));
                                    } else {
                                        return (params[filter.get('field')] = filter.get('value'));
                                    }
                                }
                            });
                        }
                        if (self.get('settings.sortByColumn')) {
                            params.order = self.get('settings.sortByColumn');
                        }
                        // when data is grouped always use the grouping as order .. copied from app\pods\timetracker\worktime-list\route.coffee
                        if (params.groupBy) {
                            params.order = params.groupBy;
                        }
                        if (self.get('status') !== 'all') {
                            params.status = self.get('status');
                        }
                        return self
                            .get('store')
                            .query(self.get('form'), params)
                            .then((rows) => {
                                return resolve(rows);
                            });
                    }
                });
                tmp_rows.then(
                    (rows) => {
                        var statuspromise;
                        if (get_status_info) {
                            row_ids = rows.mapBy('id');
                            statuspromise = self.get('Collector').ttapi({
                                url: 'get_status_info',
                                method: 'post',
                                data: {
                                    ids: row_ids,
                                    form: self.get('form'),
                                },
                            });
                        } else {
                            statuspromise = new Em.RSVP.Promise((resolve) => {
                                return resolve();
                            });
                        }
                        return statuspromise.then((statusrows) => {
                            var pdf_filters_body;
                            // add filters to pdf-page
                            pdf_filters_body = self.getPdfFilters(rows);
                            if (pdf_filters_body.length) {
                                pdf_filters = {
                                    table: {
                                        body: pdf_filters_body,
                                    },
                                    layout: 'noBorders',
                                };
                            }
                            rows.forEach(function (row) {
                                var statusrow;
                                if (typeof row.getProperties !== 'function') {
                                    row = Em.Object.create(row);
                                }
                                if (get_status_info) {
                                    statusrow = statusrows[row.id];
                                    if (statusrow) {
                                        row.set(
                                            'status_last_changed',
                                            moment(statusrow.datetime).format('L') +
                                                ' ' +
                                                moment(statusrow.datetime).format('LT') +
                                                '\n' +
                                                statusrow.user,
                                        );
                                    } else {
                                        row.set('status_last_changed', '');
                                    }
                                }
                                if (
                                    exportParams.imgLocation != null &&
                                    exportParams.imgLocation === 'afterReport'
                                ) {
                                    pdf_output.push(
                                        self.formatCollectorFieldsToPdfArray(
                                            columns,
                                            row,
                                            'row',
                                            false,
                                            false,
                                            false,
                                            'thumbnails',
                                        ),
                                    );
                                    return pdf_output_last.push(
                                        self.formatImagesToPdf(columns, row, true),
                                    );
                                } else if (exportParams.imgLocation != null) {
                                    return pdf_output.push(
                                        self.formatCollectorFieldsToPdfArray(
                                            columns,
                                            row,
                                            'row',
                                            false,
                                            false,
                                            false,
                                            exportParams.imgLocation,
                                        ),
                                    );
                                } else {
                                    return pdf_output.push(
                                        self.formatCollectorFieldsToPdfArray(
                                            columns,
                                            row,
                                            'row',
                                            false,
                                            false,
                                            false,
                                            'showImages',
                                        ),
                                    );
                                }
                            });
                            return Em.RSVP.Promise.all(pdf_output).then(function (output) {
                                if (pdf_output_last) {
                                    return Em.RSVP.Promise.all(pdf_output_last).then(
                                        function (output_last) {
                                            var element, img, imgTable, j, l, len, len1;
                                            imgTable = [];
                                            for (j = 0, len = output_last.length; j < len; j++) {
                                                element = output_last[j];
                                                for (l = 0, len1 = element.length; l < len1; l++) {
                                                    img = element[l];
                                                    imgTable.push(img['value']);
                                                }
                                            }
                                            //table: {
                                            //    body: [
                                            //        [img["name"], img["value"]]
                                            //    ]
                                            //}
                                            //pageBreak: "before"
                                            return d.resolve(
                                                output,
                                                rows,
                                                includeAllPages,
                                                imgTable,
                                            );
                                        },
                                    );
                                } else {
                                    return d.resolve(output, rows, includeAllPages);
                                }
                            });
                        });
                    },
                    function () {
                        return d.reject();
                    },
                );
                return d.then(function (
                    pdf_output,
                    allRowsForExport,
                    includeAllPages = false,
                    output_last = [],
                ) {
                    var assetsfolder,
                        checkedRows,
                        docDefinition,
                        dropdownSums,
                        first,
                        hideCheckbox,
                        orientation,
                        pdf_content,
                        rowidList,
                        sumRow,
                        sumTheseRows,
                        sumrows,
                        sums;
                    //return
                    // add sum row to pdf
                    sumRow = [];
                    first = false;
                    hideCheckbox = self.get('settings.rowSettings.hideCheckbox');
                    sums = {};
                    checkedRows = self.get('checked');
                    //console.log checkedRows
                    sumTheseRows =
                        checkedRows.length > 0 && !includeAllPages ? checkedRows : allRowsForExport;
                    rowidList = [];
                    sumTheseRows.forEach(function (item) {
                        rowidList.push(item.get('id'));
                        return self.get('summableFields').forEach(function (field) {
                            var cell;
                            if (!(field.ext in sums)) {
                                sums[field.ext] = 0;
                            }
                            cell = item.get(field.ext);
                            if (field.type === 'checkbox') {
                                if (!sums[field.ext]) {
                                    sums[field.ext] = [0, 0];
                                }
                                if (item.get(field.ext)) {
                                    return sums[field.ext][0]++;
                                } else {
                                    return sums[field.ext][1]++;
                                }
                            } else if (field.type === 'dropdown_values') {
                                if (!sums[field.ext]) {
                                    sums[field.ext] = [];
                                }
                                if (cell) {
                                    return sums[field.ext].push(cell);
                                }
                            } else if (field.type === 'date') {
                                if (
                                    Ember.typeOf(cell) === 'string' &&
                                    cell.substring(0, 6) === 'count:'
                                ) {
                                    return (sums[field.ext] += parseInt(cell.substring(6)));
                                } else {
                                    sums[field.ext] = '';
                                }
                            } else {
                                return (sums[field.ext] += cell);
                            }
                        });
                    });
                    sumrows = Em.A([]);
                    dropdownSums = [];
                    columns.forEach(function (column) {
                        var field;
                        field = column.ext;
                        if (column.type === 'checkbox') {
                            return sumrows.pushObject(
                                self.Collector.format(true, column, null, checkedRows) +
                                    ': ' +
                                    sums[field][0] +
                                    ' / ' +
                                    self.Collector.format(false, column, null, checkedRows) +
                                    ': ' +
                                    sums[field][1],
                            );
                        } else if (column.type === 'dropdown_values') {
                            sums[field].forEach(function (opt) {
                                return (dropdownSums[opt] = (dropdownSums[opt] || 0) + 1);
                            });
                            return sumrows.pushObject(
                                self.Collector.format(
                                    dropdownSums,
                                    column,
                                    null,
                                    checkedRows,
                                    'sumrow',
                                    self.get('form'),
                                ),
                            );
                        } else if (field in sums) {
                            return sumrows.pushObject(
                                self.Collector.format(
                                    sums[field],
                                    column,
                                    null,
                                    checkedRows,
                                    'sumrow',
                                ),
                            );
                        } else {
                            return sumrows.pushObject('');
                        }
                    });
                    sumrows.forEach(function (str) {
                        if (!first || self.get('type') === 'group') {
                            return sumRow.push('' + str);
                        }
                    });
                    pdf_output.push(sumRow);
                    pdf_content = [];
                    if (!logo) {
                        logo = self.get('moveniumLogo');
                    }
                    pdf_content.push({
                        columns: [
                            {
                                fit: [120, 40],
                                image: logo,
                                margin: [0, 0, 0, 20],
                            },
                            {
                                width: '*',
                                text: report_name_text,
                                alignment: 'right',
                                fontSize: 14,
                                margin: [0, 0, 0, 10],
                            },
                        ],
                    });
                    if (topHeader) {
                        pdf_content.push({
                            text: topHeader,
                            fontSize: 20,
                        });
                    }
                    if (pdf_filters) {
                        pdf_content.push(pdf_filters);
                        pdf_content.push({
                            text: ' ', // add one empty row
                        });
                    }
                    //table
                    pdf_content.push({
                        table: {
                            headerRows: 1,
                            widths: widths,
                            body: pdf_output,
                        },
                        layout: 'lightHorizontalLines',
                        fontSize: 10,
                    });
                    if (Ember.get(exportParams, 'signature')) {
                        pdf_content.push({
                            style: 'marginTop',
                            table: {
                                widths: ['auto', 140, 30, 'auto', 140],
                                body: [
                                    [
                                        {
                                            border: [false, true, false, false],
                                            text:
                                                self.get('intl').t('report.worktask.signature') +
                                                ':',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: '',
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: '',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: self.get('intl').t('thereport.print_name') + ':',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: '',
                                        },
                                    ],
                                ],
                            },
                        });
                    }
                    if (Ember.get(exportParams, 'dateAndPlace')) {
                        pdf_content.push({
                            style: 'marginTop',
                            table: {
                                widths: ['auto', 140, 55, 'auto', 140],
                                body: [
                                    [
                                        {
                                            border: [false, true, false, false],
                                            text: self.get('intl').t('thereport.date') + ':',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: '',
                                        },
                                        {
                                            border: [false, false, false, false],
                                            text: '',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: self.get('intl').t('thereport.place') + ':',
                                        },
                                        {
                                            border: [false, true, false, false],
                                            text: '',
                                        },
                                    ],
                                ],
                            },
                        });
                    }
                    orientation = Ember.get(exportParams, 'orientation')
                        ? Ember.get(exportParams, 'orientation')
                        : 'landscape';
                    pdf_content.push(output_last);
                    docDefinition = {
                        footer: (currentPage, pageCount) => {
                            return self.getFooter(currentPage, pageCount);
                        },
                        content: pdf_content,
                        pageSize: 'A4',
                        pageOrientation: orientation,
                        pageMargins: [15, 15, 15, 15],
                        styles: {
                            footer: {
                                fontSize: 8,
                                color: 'grey',
                            },
                            marginTop: {
                                margin: [0, 40, 0, 0],
                            },
                        },
                    };
                    //console.log(JSON.stringify(docDefinition, null, 4))
                    //return
                    if (format === 'base64') {
                        assetsfolder = getAssetFolder('assets');
                        return getScriptsInOrder([
                            assetsfolder + 'pdfmake_v0.1.68.min.js',
                            assetsfolder + 'vfs_fonts_v0.1.68.min.js',
                        ]).then(() => {
                            return createPdf(docDefinition)._createDoc({}, () => {
                                var item, j, len, pageSize, ref;
                                ref = docDefinition.content;
                                for (j = 0, len = ref.length; j < len; j++) {
                                    item = ref[j];
                                    if (item.table != null && item._maxWidth > 842) {
                                        pageSize = {
                                            height: item._maxWidth,
                                            width: item._maxWidth / Math.sqrt(2),
                                        };
                                        continue;
                                    }
                                }
                                docDefinition.pageSize = pageSize;
                                return createPdf(docDefinition).getBase64(function (cb) {
                                    if (includeAllPages) {
                                        return resolve({
                                            pdf: cb,
                                            rowids: rowidList,
                                        });
                                    } else {
                                        return resolve(cb);
                                    }
                                });
                            });
                        });
                    } else {
                        return resolve(docDefinition);
                    }
                });
            });
        });
    },
    getPdfAttachmentForEmail: function (includeAllPages, params) {
        if (
            this.get('form') === 'diary' &&
            this.get('checked.length') > 0 &&
            Ember.get(params, 'type') === 'export_pdf_full'
        ) {
            return this.export_special_pdf('diary', moment.locale(), params);
        } else if (this.get('form') === 'diaryreport' && this.get('checked.length') > 0) {
            return this.createWorksitediaryPdf(this.get('checked'), 'base64');
        } else if (Ember.get(params, 'type') === 'export_pdf_full') {
            return this.getTtapiPdf('base64', params);
        } else if (Ember.get(params, 'type') === 'export_excel') {
            return this.createExcel(params, true);
        } else {
            return this.littleBitUglyPdfExportMethod(
                'base64',
                includeAllPages,
                this.get('emailTitle'),
                params,
            );
        }
    },
    thereIsCheckedRows: Ember.computed.gt('checked.length', 0),
    formContainsApprovedStatus: Ember.computed('form', function () {
        var field, fields, found;
        fields = this.get('Collector').fields(this.get('form'));
        found = false;
        for (field in fields) {
            if (
                fields[field].type === 'status' &&
                !fields[field].features.readonly &&
                indexOf1.call(fields[field].options, 'approved') >= 0
            ) {
                found = true;
            }
        }
        return found;
    }),
    statusFieldIsVisible: Ember.computed('defaultColumns.[]', function () {
        if (this.get('defaultColumns') && this.get('defaultColumns').findBy('ext', 'status')) {
            return true;
        }
    }),
    showApprovalLinkSelection: Ember.computed('thereIsCheckedRows', function () {
        if (this.get('thereIsCheckedRows') && this.get('formContainsApprovedStatus')) {
            return true;
        } else if (this.get('customerSettings').checkSetting('email_approval_for_all')) {
            return true;
        }
        return false;
    }),
    showMassedit: Ember.computed('settings.massedit', function () {
        if (!this.get('Collector').testNeeds(['userlevel=3,5,6'])) {
            return false;
        }
        return this.get('settings.massedit');
    }),
    _addHistoryForSentRows: function (email, form, rowids) {
        var text;
        text = this.get('intl').t('general.history_approval_link_sent', {
            email: email,
        });
        return rowids.forEach((rowid) => {
            return this.get('Collector').ajax({
                method: 'put',
                url: form + 's/' + rowid,
                data: JSON.stringify({
                    _history: text,
                }),
            });
        });
    },
    _getParamsForFetchingRows: function (exclude = []) {
        var params;
        params = {
            sideload: true,
        };
        if (!(indexOf1.call(exclude, 'limit') >= 0)) {
            params.limit = 'all';
        }
        if (this.get('settings.filters')) {
            this.get('settings.filters').forEach((filter) => {
                var key;
                if (filter.get('value') && filter.get('field') !== 'status') {
                    if (filter.get('bindto') && !filter.get('field')) {
                        return (params[filter.get('bindto')] = filter.get('value'));
                    } else if (filter.get('form') !== this.get('form')) {
                        key = filter.get('form') + '[' + filter.get('field') + ']';
                        return (params[key] = filter.get('value'));
                    } else {
                        return (params[filter.get('field')] = filter.get('value'));
                    }
                }
            });
        }
        if (this.get('settings.sortByColumn') && !(indexOf1.call(exclude, 'sort') >= 0)) {
            params.order = this.get('settings.sortByColumn');
        }
        if (this.get('status') !== 'all') {
            params.status = this.get('status');
        }
        return params;
    },
    allPagesSelected: Ember.computed(
        'content.meta.count',
        'checked.length',
        'content.length',
        function () {
            return (
                this.get('content.meta.count') > this.get('checked.length') &&
                this.get('checked.length') === this.get('content.length')
            );
        },
    ),
    emailSendingCount: Ember.computed(
        'allPagesSelected',
        'content.meta.count',
        'checked.length',
        function () {
            if (this.get('allPagesSelected')) {
                return this.get('content.meta.count');
            } else {
                return this.get('checked.length');
            }
        },
    ),
    validateEmail: function (email) {
        return !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
            email,
        );
    },
    export_special_pdf: function (name, lang = 'fi', params) {
        var postParams, url;
        url = 'pdf/' + name;
        postParams = {
            form: this.get('form'),
            lang: lang,
            filters: JSON.stringify(this.getRawFilters()),
            type: 'base64',
        };
        if (this.get('checked').length > 0) {
            postParams.id = this.get('checked').mapBy('id').join(',');
        }
        if (params.signature) {
            postParams.signature = params.signature;
        }
        if (params.dateAndPlace) {
            postParams.dateAndPlace = params.dateAndPlace;
        }
        return this.get('Collector').ttapi({
            dataType: 'text',
            url: url,
            data: postParams,
        });
    },
    getRawFilters: function () {
        var params;
        params = {};
        if (this.get('settings.filters')) {
            this.get('settings.filters').forEach((filter) => {
                var add;
                if (Ember.isArray(filter.get('value')) && filter.get('value').length === 0) {
                } else if (filter.get('bindto')) {
                    return (params['q'] = filter.get('value'));
                } else if (filter.get('value') && filter.get('subform')) {
                    add = {};
                    add[filter.get('field')] = filter.get('value');
                    return (params[filter.get('form')] = add);
                } else if (filter.get('value') && filter.get('field') !== 'status') {
                    return (params[filter.get('field')] = filter.get('value'));
                }
            });
        }
        return params;
    },
    getTtapiPdf: function (format = null, params = {}, type = 'row') {
        var filters, postParams, rawFilters, ref, url;
        url = 'pdf/' + type;
        if (params.extraParams) {
            rawFilters = this.getRawFilters();
            if ((ref = params.extraParams.type) === 'tr' || ref === 'mvr') {
                if (rawFilters.date) {
                    delete rawFilters.date;
                }
            }
            filters = Object.assign(rawFilters, params.extraParams);
        } else {
            filters = this.getRawFilters();
        }
        postParams = {
            form: this.form.includes('measurement-custom') ? this.form.underscore() : this.form,
            lang: moment.locale(),
            filters: JSON.stringify(filters),
        };
        if (this.get('checked').length !== 0) {
            postParams.id = this.get('checked').mapBy('id').join(',');
        }
        if (params.template) {
            postParams.template = Ember.get(params, 'template.id');
        }
        if (params.signature) {
            postParams.signature = params.signature;
        }
        if (params.dateAndPlace) {
            postParams.dateAndPlace = params.dateAndPlace;
        }
        if (format) {
            postParams.type = format;
        }
        return this.get('Collector').ttapi({
            dataType: 'text',
            url: url,
            data: postParams,
        });
    },
    statusChanged: function (toStatus) {
        if (
            toStatus === 'approved' &&
            this.get('Collector').testNeeds(['products.vismanet||products.severa']) &&
            this.form === 'worktime'
        ) {
            return this.set('showFastExporter', true);
        }

        let surveyTheseForms = ['worktime', 'abcense'];
        if (toStatus === 'approved' && surveyTheseForms.includes(this.form))
            this.surveys.initializeAndRun('row_approved'); // show wootric CES-survey after row approved
    },
    setDatesForFastExporter: function (date) {
        if (date === 'clear') {
            this.set('datesForFastExporter', {
                from: null,
                to: null,
            });
            return;
        }
        if (
            !this.get('datesForFastExporter.from') ||
            moment(date).isBefore(this.get('datesForFastExporter.from'))
        ) {
            this.set('datesForFastExporter.from', date);
        }
        if (
            !this.get('datesForFastExporter.to') ||
            moment(date).isAfter(this.get('datesForFastExporter.to'))
        ) {
            return this.set('datesForFastExporter.to', date);
        }
    },
    actions: {
        handleExportActions: function (params) {
            switch (params.type) {
                case 'export_pdf':
                    return this.send('export_pdf', params);
                case 'export_pdf_full':
                    return this.send('export_pdf_full', params);
                case 'export_excel':
                    return this.send('export_excel', params);
            }
        },
        closeShowChangeStatusForAllPages: function () {
            return this.set('showChangeStatusForAllPages', false);
        },
        fullScreen: function () {
            if (this.get('fullscreenmode') === 'custom') {
                return this.get('gui').toggleCustomFullScreen();
            } else {
                this.get('gui').togglefullScreen();
                return Ember.get(this, 'analytics').trackEvent({
                    category: this.form + ' report actions',
                    action: 'Fullscreen',
                    label: 'General-report',
                });
            }
        },
        bubbleAction: function (action, row) {
            return this.sendAction('bubbleAction', action, row);
        },
        action: function (func) {
            if (this.get('checked.length') > 0) {
                return func(this, this.get('checked'));
            }
        },
        checkAll: function () {
            var checked;
            if (this.get('checked.length') === this.get('content.length')) {
                return this.set('checked', Em.A([]));
            } else {
                this.set('checked', Em.A([]));
                checked = this.get('checked');
                return this.get('content').forEach(function (obj) {
                    return checked.pushObject(obj);
                });
            }
        },
        refreshReport: function () {
            var controller, route;
            this.analytics.trackEvent({
                category: this.form + ' report actions',
                action: 'Refresh report',
                label: 'General-report',
            });
            controller = Ember.getOwner(this).lookup('controller:application');
            route = Ember.getOwner(this).lookup(
                'route:' + Ember.get(controller, 'currentRouteName'),
            );
            return route.refresh();
        },
        changeStatus: function (status) {
            var intl, promise_arr, self, visibleRowIds;
            this.analytics.trackEvent({
                category: this.form + ' report more actions',
                action: 'Change status',
                label: 'General-report',
            });
            self = this;
            intl = this.get('intl');
            if (this.get('allPagesSelected')) {
                visibleRowIds = this.get('checked').map(function (row) {
                    return row.get('id');
                });
                this.set('changeStatusForAllPagesParams', {
                    visibleRowIds: visibleRowIds,
                    requestParams: this._getParamsForFetchingRows(['order']),
                    status: status,
                    form: this.get('form'),
                    rowCount: this.get('content.meta.count'),
                });
                this.set('showChangeStatusForAllPages', true);
            }
            promise_arr = [];
            this.setDatesForFastExporter('clear');
            this.get('checked').forEach((row) => {
                var promise;
                this.setDatesForFastExporter(row.get('date'));
                row.set('status', status);
                Ember.set(row, 'row_info.modified', moment().format('YYYY-MM-DD'));
                promise = row.save();
                promise_arr.push(promise);
                return promise.catch(function (e) {
                    var err, error_string;
                    if (row.get('isError')) {
                        if (Ember.isArray(e.errors)) {
                            err = e.errors[0];
                        } else {
                            err = e.errors;
                        }
                        error_string = err.error_msg;
                        if (
                            intl.exists('validation_error.' + Ember.String.underscore(error_string))
                        ) {
                            error_string = intl.t(
                                'validation_error.' + Ember.String.underscore(error_string),
                            );
                        } else if (intl.exists('validation_error.' + err.error)) {
                            error_string = intl.t('validation_error.' + err.error);
                        }
                        self.get('notifications').error(error_string, {
                            autoClear: true,
                        });
                        return row.rollbackAttributes();
                    }
                });
            });
            self.set('checked', Em.A([]));
            return Ember.RSVP.Promise.all(promise_arr).then(() => {
                return this.statusChanged(status, this.get('checked'));
            });
        },
        massedit: function () {
            if (this.get('checked.length') > 0) {
                return this.sendAction('massedit', 'worktime', this.get('checked'));
            }
        },
        ownExportAction: function (action) {
            return this.sendAction('ownExportAction', action);
        },
        deleteChecked: function () {
            if (this.get('checked.length') > 0) {
                this.analytics.trackEvent({
                    category: this.form + ' report more actions',
                    action: 'Delete selected',
                    label: 'General-report',
                });
                return this.get('dialogs')
                    .confirm(this.get('intl').t('report.confirm_delete'))
                    .then((con) => {
                        var show_error;
                        if (con) {
                            show_error = false;
                            this.get('checked').forEach((row) => {
                                if (row.get('row_info.privilege_remove') === false) {
                                    show_error = true;
                                    return;
                                }
                                return row.destroyRecord().then((ret) => {
                                    row.unloadRecord();
                                    return this.sendAction('bubbleAction', 'delete', row);
                                });
                            });
                            if (show_error) {
                                this.get('dialogs').alert(
                                    this.get('intl').t('general_report.mass_delete.not_allowed'),
                                );
                            }
                            return this.set('checked', Em.A([]));
                        }
                    });
            }
        },
        restoreChecked: function () {
            if (this.get('checked.length') > 0) {
                this.analytics.trackEvent({
                    category: this.form + ' report more actions',
                    action: 'Restore selected',
                    label: 'General-report',
                });
                return this.get('dialogs')
                    .confirm(this.get('intl').t('report.confirm_restore_rows'))
                    .then((con) => {
                        var form, form_plural;
                        if (con) {
                            form = this.get('form');
                            form_plural = pluralize(form).camelize();
                            this.get('checked').forEach((row) => {
                                return this.get('capi')
                                    .request('/' + form_plural + '/' + row.id + '?validation=off', {
                                        dataType: 'json',
                                        type: 'PUT',
                                        data:
                                            '{"' +
                                            form.camelize() +
                                            '":{"row_info.status":"normal"}}',
                                    })
                                    .then(() => {
                                        return row.unloadRecord();
                                    });
                            });
                            return this.set('checked', Em.A([]));
                        }
                    });
            }
        },
        status: function (status) {
            this.set('status', status);
            return this.analytics.trackEvent({
                category: this.form + ' report actions change status',
                action: status,
                label: 'General-report',
            });
        },
        sendColumns: function (columns) {
            return this.sendAction('showColumnsChanged', columns, this.get('form'));
        },
        showColumn: function (col) {
            var _currentTemplate, current, extra;
            this.analytics.trackEvent({
                category: this.form + ' report show column',
                action: col,
                label: 'General-report',
            });
            current = this.get('defaultColumns');
            extra = this.get('extraColumns');
            current.pushObject(col);
            extra.removeObject(col);
            this.set('defaultColumns', current);
            _currentTemplate =
                this.get('content.firstObject.constructor.modelName') + '_currentTemplate';
            if (this.get('localStorageWrapper').getUserSetting(_currentTemplate).columns) {
                this.get('localStorageWrapper').removeUserSetting(_currentTemplate);
            }
            return this.send('sendColumns', current);
        },
        hideColumn: function (col) {
            var _currentTemplate, current, extra;
            this.analytics.trackEvent({
                category: this.form + ' report hide column',
                action: col,
                label: 'General-report',
            });
            current = this.get('defaultColumns');
            extra = this.get('extraColumns');
            current.removeObject(col);
            extra.pushObject(col);
            _currentTemplate =
                this.get('content.firstObject.constructor.modelName') + '_currentTemplate';
            if (this.get('localStorageWrapper').getUserSetting(_currentTemplate).columns) {
                this.get('localStorageWrapper').removeUserSetting(_currentTemplate);
            }
            return this.send('sendColumns', current);
        },
        moveCol: function (col, dir) {
            return this.moveElementInArray(this.get('defaultColumns'), col, dir);
        },
        sortDown: function (col) {
            return this.moveElementInArray(this.get('defaultColumns'), col, 1);
        },
        columnDropped: function (target, dropped) {
            var columns;
            dropped = parseInt(dropped);
            if (target === dropped) {
                return;
            }
            columns = this.get('defaultColumns');
            return this.moveElementInArray(
                this.get('defaultColumns'),
                columns[dropped],
                target - dropped,
            );
        },
        sortByColumn: function (col, obj = false) {
            var colArr, sortCol;
            this.analytics.trackEvent({
                category: this.form + ' report sort by column',
                action: col,
                label: 'General-report',
            });
            //return if obj? and obj.type == "row_info"
            if (obj.ext === 'row_info.created') {
                col = 'id';
            }
            sortCol = this.get('settings.sortByColumn');
            if (sortCol === this.get('settings.defaultOrder') && !this.get('manuallyOrdered')) {
                this.set('settings.sortByColumn', col);
                this.set('manuallyOrdered', true);
                return;
            }
            if (sortCol.includes(col + '-')) {
                sortCol = sortCol.replace(col + '-', col);
            } else if (sortCol.includes(col) && (sortCol = sortCol.replace(col, col + '-'))) {
            } else if (Ember.isEmpty(sortCol)) {
                sortCol = col;
            } else {
                sortCol += ',' + col;
            }
            colArr = sortCol.split(',');
            if (colArr.length > 4) {
                // Only use the 4 last options in the sortcol array
                sortCol = colArr.splice(-4).join(',');
            }
            this.set('manuallyOrdered', true);
            return this.set('settings.sortByColumn', sortCol);
        },
        removeFieldFromOrder: function (field) {
            var cols;
            cols = this.get('settings.sortByColumn').split(',');
            if (cols.length === 1) {
                this.set('settings.sortByColumn', this.get('settings.defaultOrder'));
                this.set('manuallyOrdered', false);
                return;
            }
            if (cols.includes(field + '-')) {
                field = field + '-';
            }
            cols.splice(cols.indexOf(field), 1);
            if (cols.length > 0) {
                this.set('manuallyOrdered', true);
                return this.set('settings.sortByColumn', cols.join(','));
            }
        },
        setRowsPerPage: function (limit) {
            var key;
            this.analytics.trackEvent({
                category: this.form + ' report rows per page',
                action: limit,
                label: 'General-report',
            });
            key = this.get('defaultLimitKey');
            this.set('page', 1);
            this.set('limit', limit);
            this.get('localStorageWrapper').setUserSetting(key, limit);
            return this.sendAction('bubbleSetRowsPerPage', limit);
        },
        export_excel: function (exportParams) {
            return this.createExcel(exportParams);
        },
        //exports report data to the csv file including header row
        export_csv: function () {
            var columns, csv_output, d, obj_columns, self, title_row, tmp_rows;
            this.set('showLoader', true);
            self = this;
            csv_output = [];
            Ember.get(this, 'analytics').trackEvent({
                category: this.form + ' report actions',
                action: 'Csv-export',
                label: 'General-report',
            });
            //title row
            title_row = [];
            columns = [];
            obj_columns = [];
            this.get('defaultColumns').forEach((row) => {
                var minus_field, obj;
                columns.push(row.ext);
                obj_columns.push(row.obj);
                title_row.push(row.name);
                if (row.ext.includes('_plus')) {
                    columns.push(row.ext.slice(0, -5) + '_minus');
                    obj = Object.assign({}, row.obj);
                    Ember.set(obj, 'ext', row.ext.slice(0, -5) + '_minus');
                    obj_columns.push(obj);
                    minus_field = this.get('Collector').field(
                        this.get('form'),
                        row.ext.slice(0, -5) + '_minus',
                    );
                    return title_row.push(minus_field.translated_name);
                }
            });
            csv_output.push(title_row);
            d = new $.Deferred();
            tmp_rows = new Em.RSVP.Promise((resolve, reject) => {
                var j, len, output_row, params, ref, row;
                //get data from checked (if there is checked)
                if (this.get('checked').length > 0) {
                    ref = this.get('checked');
                    for (j = 0, len = ref.length; j < len; j++) {
                        row = ref[j];
                        if (config.APP.KIOSK) {
                            row = self.getExportCols(row, columns);
                        } else {
                            if (typeof row.getProperties !== 'function') {
                                row = Em.Object.create(row);
                            }
                        }
                        output_row = [];
                        $.each(obj_columns, function (key, column) {
                            var cell, formatted;
                            column = Em.Object.create(column);
                            cell = row.get(column.get('ext'));
                            formatted = self.Collector.format(cell, column, self, row, 'csv');
                            return output_row.push(self.Collector.format_export(formatted));
                        });
                        csv_output.push(output_row);
                    }
                    return d.resolve();
                } else {
                    params = {
                        limit: 'all',
                        sideload: true,
                    };
                    if (self.get('settings.filters')) {
                        self.get('settings.filters').forEach(function (filter) {
                            var add;
                            // haxx to set q-parameter correctly
                            if (filter.get('bindto')) {
                                return (params['q'] = filter.get('value'));
                            } else if (
                                self.get('customQueryparams') &&
                                self.get('customQueryparamsKey') === filter.get('field')
                            ) {
                                return (params[self.get('customQueryparams')] =
                                    filter.get('value'));
                            } else if (filter.get('value') && filter.get('subform')) {
                                add = {};
                                add[filter.get('field')] = filter.get('value');
                                return (params[filter.get('form')] = add);
                            } else if (filter.get('value') && filter.get('field') !== 'status') {
                                return (params[filter.get('field')] = filter.get('value'));
                            }
                        });
                    }
                    if (self.get('settings.sortByColumn')) {
                        params.order = self.get('settings.sortByColumn');
                    }
                    if (this.get('status') !== 'all') {
                        params.status = this.get('status');
                    }
                    return self
                        .get('store')
                        .query(self.get('form'), params)
                        .then((rows) => {
                            return resolve(rows);
                        });
                }
            });
            tmp_rows.then(
                (rows) => {
                    rows.forEach(function (row) {
                        var output_row;
                        if (config.APP.KIOSK) {
                            row = self.getExportCols(row, columns);
                        } else if (typeof row.getProperties !== 'function') {
                            row = Em.Object.create(row);
                        }
                        output_row = [];
                        $.each(obj_columns, function (key, column) {
                            var cell, formatted;
                            column = Em.Object.create(column);
                            cell = row.get(column.get('ext'));
                            formatted = self.Collector.format(
                                cell,
                                column,
                                self,
                                row,
                                'csv',
                                self.get('form'),
                            );
                            /*
            if key in columns && value?
                index = columns.indexOf(key)
                field = self.get('form')+'.'+key
                if self.get('staticForm')
                    field = self.get('Collector').getFormObject(field)
                formatted = if config.APP.KIOSK then value else self.Collector.format(value,field, self, row, true)
                #cell, column, null, model, true
             */
                            return output_row.push(self.Collector.format_export(formatted));
                        });
                        return csv_output.push(output_row);
                    });
                    return d.resolve();
                },
                function () {
                    return d.reject();
                },
            );
            return d.then(function () {
                var a,
                    blobObj,
                    company,
                    csvString,
                    csv_str,
                    indexOf,
                    j,
                    l,
                    len,
                    len1,
                    name,
                    row,
                    str;
                //generate csv string
                csv_str = [];
                for (j = 0, len = csv_output.length; j < len; j++) {
                    row = csv_output[j];
                    //remove linebreaks from csv string to avoid invalid format
                    for (indexOf = l = 0, len1 = row.length; l < len1; indexOf = ++l) {
                        str = row[indexOf];
                        if (typeof str === 'string') {
                            row[indexOf] = str.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
                        }
                    }
                    csv_str.push(row.join(';'));
                }
                //for column in row
                //   if column is null || column is undefined
                //       column = ""
                //   csv_str.push(column.join(';'));
                //csv_str += column + ";"
                //remove last char
                //csv_str = csv_str.substring(0, csv_str.length - 1);
                //put line break at the end
                //csv_str += "%0A"
                name = 'movenium_export';
                company = self.get('session.currentUser.company');
                if (company) {
                    name = company;
                }
                //create download for android app
                if (typeof LocalFileSystem !== 'undefined' && LocalFileSystem !== null) {
                    window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fileSystem) {
                        return fileSystem.root.getFile(
                            name + '.csv',
                            {
                                create: true,
                            },
                            function (entry) {
                                var fileEntry;
                                fileEntry = entry;
                                return entry.createWriter(function (writer) {
                                    var csvString;
                                    //write file and open
                                    writer.onwrite = function (evt) {
                                        return cordova.plugins.fileOpener2.open(
                                            fileEntry.toURL(),
                                            'text/csv',
                                        );
                                    };
                                    csvString = csv_str.join('\n');
                                    return writer.write(encodeURIComponent(csvString));
                                });
                            },
                        );
                    });
                } else {
                    //download csv for desktop
                    csvString = csv_str.join('\n');
                    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
                        blobObj = new Blob(['\uFEFF' + csvString], {
                            type: 'text/csv',
                        });
                        window.navigator.msSaveBlob(blobObj, name + '.csv');
                    } else {
                        a = document.createElement('a');
                        a.setAttribute(
                            'href',
                            'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvString),
                        );
                        a.setAttribute('download', name + '.csv');
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                    }
                }
                return self.set('showLoader', false);
            });
        },
        export_pdf_full: function (params) {
            var promise;
            this.set('showLoader', true);
            Ember.get(this, 'analytics').trackEvent({
                category: this.form + ' report actions',
                action: 'Pdf-full export',
                label: 'General-report',
            });
            if (this.get('form') === 'orientation') {
                promise = this.export_special_pdf(this.get('form'), moment.locale(), params);
                if (promise) {
                    promise.then((content) => {
                        this.set('showLoader', false);
                        return this.get('exportFile').exportFile(content, 'export', 'pdf', true);
                    });
                    promise.catch((e) => {
                        alert('pdf export failed: ' + JSON.stringify(e));
                        this.set('showLoader', false);
                        return this.set('showEmailModal', false);
                    });
                }
            } else {
                promise = this.getTtapiPdf(
                    null,
                    params,
                    this.get('form') === 'diary' ? 'diary' : 'row',
                );
                if (promise) {
                    promise.then((back) => {
                        var data;
                        data = JSON.parse(back);
                        if (data.url) {
                            if (typeof cordova !== 'undefined' && cordova !== null) {
                                return window.open(
                                    data.url,
                                    '_system',
                                    'location=yes,enableViewportScale=yes,hidden=no,usewkwebview=yes',
                                );
                            } else {
                                return window.open(data.url);
                            }
                        }
                    });
                    promise.catch((e) => {
                        return alert('pdf export failed: ' + JSON.stringify(e));
                    });
                    promise.finally(() => {
                        this.set('showLoader', false);
                        return this.set('showEmailModal', false);
                    });
                }
            }
        },
        export_pdf: function (params = {}) {
            this.set('showLoader', true);
            Ember.get(this, 'analytics').trackEvent({
                category: this.form + ' report actions',
                action: 'Pdf-normal export',
                label: 'General-report',
            });
            return this.littleBitUglyPdfExportMethod(null, false, false, params).then(
                (docDefinition) => {
                    var assetsfolder, company, pageSize;
                    // if you want to see decoded pdf, uncomment these rows
                    // you can also paste result to pdfmake.org playground (http://pdfmake.org/playground.html)
                    //console.log(JSON.stringify(docDefinition, null, 4))
                    //return
                    company = this.get('session.currentUser.company' || '');
                    //if self.get 'showEmailModal'
                    pageSize = 'A4';
                    assetsfolder = getAssetFolder('assets');
                    return getScriptsInOrder([
                        assetsfolder + 'pdfmake_v0.1.68.min.js',
                        assetsfolder + 'vfs_fonts_v0.1.68.min.js',
                    ]).then(() => {
                        return createPdf(docDefinition)._createDoc({}, () => {
                            var item, j, len, ref;
                            ref = docDefinition.content;
                            //console.log(JSON.stringify(docDefinition, null, 4))
                            //return
                            for (j = 0, len = ref.length; j < len; j++) {
                                item = ref[j];
                                if (item.table != null && item._maxWidth > 842) {
                                    pageSize = {
                                        height: item._maxWidth,
                                        width: item._maxWidth / Math.sqrt(2),
                                    };
                                    continue;
                                }
                            }
                            docDefinition.pageSize = pageSize;
                            if (typeof cordova !== 'undefined' && cordova !== null) {
                                createPdf(docDefinition).getBuffer((buffer) => {
                                    var binaryArray, cordova, promise, utf8;
                                    utf8 = new Uint8Array(buffer);
                                    binaryArray = utf8.buffer;
                                    cordova = this.get('cordova');
                                    promise = cordova.saveFile(binaryArray, company, 'pdf');
                                    return promise.then(function (url) {
                                        return cordova.openFile(url, 'application/pdf');
                                    });
                                });
                            } else {
                                createPdf(docDefinition).download(company + '.pdf');
                            }
                            this.set('showLoader', false);
                            return this.set('showEmailModal', false);
                        });
                    });
                },
            );
        },
        export: function (type) {
            this.set('showEmailModal', true);
            return this.set('exportInitType', type);
        },
        export_share: function () {
            var params, promise;
            params = {
                limit: 'all',
            };
            if (this.get('settings.filters')) {
                this.get('settings.filters').forEach(function (filter) {
                    if (filter.get('value') && filter.get('field') !== 'status') {
                        return (params[filter.get('field')] = filter.get('value'));
                    }
                });
            }
            if (this.get('settings.sortByColumn')) {
                params.order = this.get('settings.sortByColumn');
            }
            this.set('showShareModal', true);
            promise = this.get('Collector').ttapi({
                url: 'share/link',
                method: 'post',
                data: {
                    params: params,
                    values: {
                        form: this.get('form'),
                        lang: this.get('intl.primaryLocale'),
                    },
                },
            });
            return promise.then((back) => {
                return this.set(
                    'shareUrl',
                    this.get('Collector').ttapi_url() + '/share?k=' + back.link,
                );
            });
        },
        rowAction: function (row) {
            return this.sendAction('rowAction', row);
        },
        getImageUri: function (value) {
            var canvas, ctx, d, img;
            d = new $.Deferred();
            canvas = document.createElement('canvas');
            ctx = canvas.getContext('2d');
            img = new Image();
            img.onload = function () {
                var dataUrl, formattedData;
                ctx.drawImage(img, 0, 0);
                dataUrl = canvas.toDataUrl('image/png');
                formattedData = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
                return d.resolve(formattedData);
            };
            img.src = value.thumb;
            return d;
        },
        openGpsMap: function () {
            var promise, self;
            self = this;
            if (!this.get('showGpsMap')) {
                promise = self.setMapMarkers();
                return promise.then(() => {
                    return self.set('showGpsMap', true);
                });
            } else {
                return self.set('showGpsMap', false);
            }
        },
        showRemovedRows: function () {
            this.set('page', 1);
            if (this.get('settings.showStatus') === 'removed') {
                return this.set('settings.showStatus', void 0);
            } else {
                return this.set('settings.showStatus', 'removed');
            }
        },
        showLockedRows: function () {
            this.set('page', 1);
            if (this.get('settings.showStatus') === 'locked') {
                return this.set('settings.showStatus', void 0);
            } else {
                return this.set('settings.showStatus', 'locked');
            }
        },
        showNormalRows: function () {
            this.set('page', 1);
            return this.set('settings.showStatus', 'normal');
        },
        showEmailDialog: function () {
            var form;
            //@sendAction 'bubbleTransition', "users.edit", @get 'session.currentUser.id'
            //return
            form = 'general.' + this.get('form');
            this.set('includeApprovalLink', false);
            if (this.get('showApprovalLinkSelection')) {
                this.set('includeApprovalLink', true);
            }
            this.set('emailReportModalText', false);
            this.set('emailModalTitle', this.get('intl').t('general.send_as_email'));
            if (this.get('showApprovalLinkSelection')) {
                this.set(
                    'emailContent',
                    this.get('intl').t('general.email_content_start') +
                        ' ' +
                        this.get('intl').t(form) +
                        ' ' +
                        this.get('intl').t('general.email_content_end', {
                            sender:
                                this.get('session.currentUser.firstname') +
                                ' ' +
                                this.get('session.currentUser.lastname'),
                        }),
                );
            } else {
                this.set('emailContent', '');
            }
            this.set(
                'emailTitle',
                this.get('intl').t('menu.' + pluralize(this.get('form')).camelize()) +
                    '-' +
                    this.get('intl').t('general.report'),
            );
            // get last sent email-adress for this user
            if (this.get('localStorageWrapper').getUserSetting('last_sent_email_address')) {
                this.set(
                    'address',
                    this.get('localStorageWrapper').getUserSetting('last_sent_email_address')[0],
                );
            } else {
                this.set('address', this.get('session.currentUser.email'));
            }
            this.set('showEmailModal', true);
            return this.set('exportInitType', 'email');
        },
        sendEmail: function (to, title, content, params) {
            var filters, filtersString;
            Ember.get(this, 'analytics').trackEvent({
                category: this.form + ' report actions',
                action: 'Send email',
                label: 'General-report',
            });
            filters = this.getPdfFilters();
            filtersString = '';
            filters.forEach(function (filter) {
                return (filtersString += filter[0] + filter[1] + ' ');
            });
            this.set('showLoader', true);
            return this.getPdfAttachmentForEmail(this.get('allPagesSelected'), params).then(
                (pdf) => {
                    var form, form_name, promise, rowids;
                    if (Ember.typeOf(pdf) === 'object') {
                        rowids = pdf.rowids;
                        pdf = pdf.pdf;
                    } else {
                        rowids = this.get('checked').map((item) => {
                            return item.get('id');
                        });
                    }
                    if (Ember.get(params, 'approvalLink')) {
                        this._addHistoryForSentRows(to, this.get('form'), rowids);
                        form_name = this.get('intl')
                            .t('form.' + this.get('form'))
                            .toString();
                    }
                    form = pluralize(this.get('form')).camelize();
                    //self.$('.modal').modal('hide')
                    promise = this.Collector.ttapi({
                        url: 'sendReportAsEmail',
                        data: {
                            to_address: to,
                            email: pdf,
                            filters: filtersString,
                            content: content,
                            form: this.get('form'),
                            form_name: form_name,
                            rowids: rowids,
                            lang: this.get('intl.primaryLocale'),
                            includeApproval: Ember.get(params, 'approvalLink'),
                            header: title,
                            type: Ember.get(params, 'type'),
                            dontAttachImages: Ember.get(params, 'imgLocation') === 'hideImages',
                        },
                        method: 'post',
                    });
                    promise.then(() => {
                        this.set('emailSent', true);
                        this.set('showLoader', false);
                        return this.get('localStorageWrapper').setUserSetting(
                            'last_sent_email_address',
                            to,
                        );
                    });
                    return promise.catch(() => {
                        this.set('showLoader', false);
                        return this.set('emailError', true);
                    });
                },
            );
        },
        removeModal: function () {
            this.set('emailSent', false);
            if ($('.modal', this.element)) {
                this.set('showEmailModal', false);
                return this.set('showShareModal', false);
            }
        },
        bubbleTransition: function (to, params) {
            return this.sendAction('bubbleTransition', to, params);
        },
        shareInputFocus: function () {
            return $('.share-input', this.element).select();
        },
        copy_success: function () {
            return this.get('notifications').success(this.get('intl').t('general.text_copied'), {
                autoClear: true,
            });
        },
        copy_error: function () {
            return this.get('notifications').error(
                this.get('intl').t('general.error_copying_text'),
                {
                    autoClear: false,
                },
            );
        },
        lockRows: function (act) {
            var form, form_plural, visibleRowIds;
            if (Em.isEmpty(this.get('checked'))) {
                return;
            }
            if (!this.get('Collector').testNeeds(['userlevel=5'])) {
                return;
            }
            form = this.get('form');
            form_plural = pluralize(this.get('form')).camelize();
            this.analytics.trackEvent({
                category: form + ' report more actions',
                action: act + ' rows',
                label: 'General-report',
            });
            if (this.get('allPagesSelected')) {
                visibleRowIds = this.get('checked').map(function (row) {
                    return row.get('id');
                });
                this.set('changeStatusForAllPagesParams', {
                    visibleRowIds: visibleRowIds,
                    requestParams: this._getParamsForFetchingRows(['order']),
                    form: form,
                    action: act,
                    rowCount: this.get('content.meta.count'),
                });
                this.set('showChangeStatusForAllPages', true);
            }
            this.get('checked').forEach((row) => {
                var data;
                data = {};
                if (act === 'lock') {
                    data[form] = {
                        locked: true,
                    };
                }
                if (act === 'unlock') {
                    data[form] = {
                        locked: false,
                    };
                }
                return this.get('capi')
                    .request('/' + form_plural + '/' + row.get('id') + '?validation=off', {
                        dataType: 'json',
                        type: 'PUT',
                        data: JSON.stringify(data),
                    })
                    .then((data) => {
                        // don't need to save row again, just reload it and it will be updated
                        return row.reload();
                    });
            });
            return this.set('checked', Em.A([]));
        },
        toggleInlineEdit: function () {
            var promises, unsaved;
            if (this.get('inlineEdit')) {
                Ember.get(this, 'analytics').trackEvent({
                    category: this.form + ' report inline edit',
                    action: 'disable',
                    label: 'General-report',
                });
                promises = [];
                unsaved = [];
                this.get('content').forEach(function (row) {
                    if (
                        !row.get('isValid') ||
                        row.get('isError') ||
                        row.get('hasDirtyAttributes')
                    ) {
                        return unsaved.push(row);
                    }
                });
                if (unsaved.length > 0) {
                    return this.get('dialogs')
                        .confirm(this.get('intl').t('report.confirm_unsaved'))
                        .then((con) => {
                            if (!con) {
                            } else {
                                unsaved.forEach(function (row) {
                                    if (
                                        !row.get('isValid') ||
                                        row.get('isError') ||
                                        row.get('hasDirtyAttributes')
                                    ) {
                                        row.rollbackAttributes();
                                        return promises.push(row.reload());
                                    }
                                });
                                return Ember.RSVP.Promise.all(promises).then(() => {
                                    this.set('errors', Ember.A([]));
                                    this.toggleProperty('inlineEdit');
                                    this.set('timeout', true);
                                    return Em.run.later(
                                        this,
                                        function () {
                                            return this.set('timeout', false);
                                        },
                                        2000,
                                    );
                                });
                            }
                        });
                } else {
                    return Ember.RSVP.Promise.all(promises).then(() => {
                        // add new rows to content so that exiting of edit-mode doesn't hide those new rows
                        if (this.get('addRows').length > 0) {
                            this.set(
                                'content',
                                this.get('addRows').concat(this.get('content').toArray()),
                            );
                        }
                        this.set('errors', Ember.A([]));
                        this.toggleProperty('inlineEdit');
                        this.set('timeout', true);
                        return Em.run.later(
                            this,
                            function () {
                                return this.set('timeout', false);
                            },
                            2000,
                        );
                    });
                }
            } else {
                Ember.get(this, 'analytics').trackEvent({
                    category: this.form + ' report inline edit',
                    action: 'enable',
                    label: 'General-report',
                });
                this.toggleProperty('inlineEdit');
                this.set('addRows', Ember.A([]));
                this.set('timeout', true);
                return Em.run.later(
                    this,
                    function () {
                        return this.set('timeout', false);
                    },
                    2000,
                );
            }
        },
        saveRows: function () {
            var allRows, errors, last_promise, thereWereChangedRows;
            Ember.get(this, 'analytics').trackEvent({
                category: this.form + ' report inline edit',
                action: 'save rows',
                label: 'General-report',
            });
            errors = Ember.A([]);
            thereWereChangedRows = false;
            last_promise = new Ember.RSVP.Promise((resolve) => {
                return resolve();
            });
            allRows = this.get('addRows').concat(this.get('content').toArray());
            allRows.forEach((row, index) => {
                if (!row.get('hasDirtyAttributes')) {
                    return;
                }
                if (!thereWereChangedRows) {
                    thereWereChangedRows = true;
                }
                ++index;
                return (last_promise = new Ember.RSVP.Promise((resolve) => {
                    return last_promise.then(() => {
                        return row.validate().then(() => {
                            return this.save_record_and_catch_errors(index, row, errors, resolve);
                        });
                    });
                }));
            });
            last_promise.then(() => {
                if (thereWereChangedRows && errors.length === 0) {
                    this.notifyPropertyChange('sumRow');
                    return this.get('notifications').success(this.get('intl').t('general.saved'), {
                        autoClear: true,
                    });
                } else {
                    return this.get('notifications').warning(
                        this.get('intl').t('general.no_saved_rows'),
                        {
                            autoClear: true,
                        },
                    );
                }
            });
            return this.set('errors', errors);
        },
        copyRow: function (row) {
            var c, new_record;
            c = this.get('Collector');
            new_record = c.copyRecord(row);
            Ember.set(new_record, 'row_info', {
                privilege_edit: true,
                privilege_remove: true, // fake some privileges to enable editing and removing of new row
            });
            return this.get('addRows').unshiftObject(new_record);
        },
        deactivateTemplate: function () {
            var _currentTemplate, key;
            _currentTemplate =
                this.get('content.firstObject.constructor.modelName') + '_currentTemplate';
            if (this.get('localStorageWrapper').getUserSetting(_currentTemplate).columns) {
                this.get('localStorageWrapper').removeUserSetting(_currentTemplate);
            }
            if (this.get('localStorageKey')) {
                key = this.get('localStorageKey') + '_defaultColumns';
            } else if (this.get('content.firstObject.constructor.modelName')) {
                key = this.get('content.firstObject.constructor.modelName') + '_defaultColumns';
            }
            if (key) {
                this.get('localStorageWrapper').removeUserSetting(key);
            }
            return this.set('currentTemplate', null);
        },
    },
    save_record_and_catch_errors: function (index, row, errors, resolve) {
        var promise;
        if (row.get('isValid')) {
            promise = this.save_record(row);
            promise.then(() => {
                return resolve();
            });
            return promise.catch((e) => {
                var error;
                //                row.rollbackAttributes()
                if (e.errors && e.errors[0]) {
                    if (e.errors[0].error_msg) {
                        error = e.errors[0].error_msg;
                    } else if (e.errors[0].error) {
                        error = e.errors[0].error;
                    }
                } else if (e.payload) {
                    error = e.payload.error_msg;
                } else {
                    error = 'unknown';
                }
                errors.pushObject({
                    index: index,
                    row: row.get('id'),
                    error: error,
                });
                return resolve();
            });
        } else {
            return errors.pushObject({
                index: index,
                row: row.get('id'),
                error: row.get('errors.messages.firstObject'),
            });
        }
    },
    showQrCodes: function (codes) {
        this.set('qrCodes', codes);
        return this.set('showQrModal', true);
    },
    //empty checked array every time component is used
    emptyChecked: function () {
        return this.set('checked', Em.A([]));
    }.observes('content'),
});

export default GeneralReportComponent;
