/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/password/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var PasswordIndexRoute;

import Ember from 'ember';

import initRoute from '../../../mixins/init-route';

PasswordIndexRoute = Ember.Route.extend(initRoute);

export default PasswordIndexRoute;
