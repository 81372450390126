/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/id06/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserId06Controller,
    indexOf = [].indexOf;

import Ember from 'ember';

import validator from '../../../../mixins/base-validation';

import config from '../../../../config/environment';

RegisterUserId06Controller = Ember.Controller.extend(validator, {
    validationObject: 'model',
    registerUserController: Ember.inject.controller('register/user'),
    Collector: Ember.inject.service('collector-service'),
    countryLists: Ember.inject.service(),
    customEmployer: false,
    validations: {
        lastname: {
            mandatory: true,
        },
        employer_id: {
            mandatory: true,
        },
        employer_name: {
            mandatory: true,
        },
        employer_country: {
            mandatory: true,
            employer_country: true,
        },
        id06card: {
            mandatory: true,
            id06_number: true,
        },
        person_code: {
            mandatory: true,
        },
        //PIN: true
        person_country: {
            mandatory: true,
            person_country: true,
        },
    },
    validationstate: Ember.Object.create(),
    errors: Ember.Object.create(),
    setUserCountryByLang: Em.on('init', function () {
        return Ember.run.schedule('afterRender', this, function () {
            var countryByLang;
            if (this.get('model') && !this.get('model.person_country')) {
                countryByLang = moment.locale() === 'fi' ? 'fi' : 'se';
                return this.set('model.person_country', countryByLang);
            }
        });
    }),
    setValidationErrors: function () {
        var field, results, validations;
        validations = this.get('validations');
        validations = this.get('validations');
        results = [];
        for (field in validations) {
            results.push(this.setValidationError(field));
        }
        return results;
    },
    setValidationError: function (field) {
        var error, k, rule;
        if (!this.get('validationstate.' + field)) {
            this.set('validationstate.' + field, Ember.Object.create());
        }
        error = this.get('errors.' + field);
        if (!error) {
            this.set('validationstate.' + field + '.ok', true);
            this.set('validationstate.' + field + '.error', false);
        }
        for (k in error) {
            rule = error[k];
            this.set('validationstate.' + field + '.ok', false);
            this.set('validationstate.' + field + '.error', rule);
            return;
        }
    },
    setParams: Em.observer('project', 'creator', function () {
        var controller;
        controller = this.get('registerUserController');
        controller.set('projectid', this.get('project'));
        controller.set('creator', this.get('creator'));
        controller.set('employer_name', this.get('employer_name'));
        controller.set('employer_id', this.get('employer_id'));
        return controller.set('employer_country', this.get('employer_country'));
    }),
    //    setDefaultEmployer: Em.observer 'employer_id','model', ->
    //        console.log "empo_id", @get('model'),@get "employer_id"
    //        return if not @get 'model'
    //        @set 'model.employer_id', @get 'employer_id'
    //        @set 'model.employer_country', @get 'employer_country'
    //        @set 'model.person_country', @get 'employer_country'
    //        @set 'model.employer_name', @get 'employer_name'
    verifyFromApi: function (data) {
        return new Em.RSVP.Promise((resolve, reject) => {
            var c, promise;
            c = this.get('Collector');
            promise = c.ttapi({
                url: 'checkCard',
                method: 'post',
                dataType: 'text',
                data: {
                    card_id: data.get('id06card'),
                    emp_id: data.get('employer_id'),
                    creator: data.get('creator'),
                },
            });
            return promise.then((back) => {
                var emp_nationality, nationality;
                if (back === 'user_found') {
                    resolve('user_found');
                }
                c = this.get('Collector');
                nationality = null;
                if (this.get('model.person_country')) {
                    nationality = this.get('model.person_country').toUpperCase();
                }
                emp_nationality = null;
                if (this.get('model.employer_country')) {
                    emp_nationality = this.get('model.employer_country').toUpperCase();
                }
                promise = c.ccapi({
                    url: config.APP.ID06_VERIFY_URL,
                    data: {
                        card: this.get('model.id06card'),
                        personid: this.get('model.person_code'),
                        emp_id: this.get('model.employer_id'),
                        nationality: nationality,
                        emp_nationality: emp_nationality,
                    },
                });
                return promise.then(
                    function (back) {
                        return resolve(JSON.parse(back).reason);
                    },
                    function () {
                        return resolve('failed');
                    },
                );
            });
        });
    },
    verifyUser: function () {
        return new Em.RSVP.Promise((resolve, reject) => {
            var model, promise;
            model = this.get('model');
            this.set('loading', true);
            promise = this.verifyFromApi(model);
            return promise.then((result) => {
                if (result === 'verified') {
                    this.set('verifyOk', true);
                    this.set('verifyFailed', false);
                    this.set('model.status', 'ok');
                    resolve(true);
                } else if (result === 'timeout') {
                    this.set('verifyOk', true);
                    this.set('verifyFailed', false);
                    this.set('model.status', 'not_verified');
                    resolve(true);
                } else if (result === 'user_found') {
                    this.set('verifyOk', false);
                    this.set('userFound', true);
                    resolve(false);
                } else {
                    this.set('verifyOk', false);
                    this.set('verifyFailed', true);
                    resolve(false);
                }
                this.get('registerUserController').set('userinfo', model);
                return this.set('loading', false);
            });
        });
    },
    fetchCard: function (card) {
        var c, promise;
        c = this.get('Collector');
        promise = c.ccapi({
            url: config.APP.ID06_CARD_URL,
            data: {
                id: card,
            },
        });
        return promise.then((data) => {
            var ref, ref1;
            data = JSON.parse(data);
            if (data.status_code === '202') {
                if (!this.get('validationstate.id06card')) {
                    this.set('validationstate.id06card', Ember.Object.create());
                }
                this.set('validationstate.id06card.ok', false);
                return this.set(
                    'validationstate.id06card.error',
                    'validation.error.id06_not_found',
                );
            } else if ((ref = data.status_code) !== '500' && ref !== '504') {
                if (
                    ((ref1 = data.card_holder_nationality),
                    indexOf.call(this.get('countryLists.shortList').mapBy('id'), ref1) < 0)
                ) {
                    this.set('hideCountry', true);
                }
                this.set('model.person_country', data.card_holder_nationality);
                return this.set('model.employer_country', data.company_nationality);
            }
        });
    },
    validate_all: function () {
        return this.validate().then(() => {
            var error, errors;
            this.setValidationErrors();
            errors = this.get('errors');
            for (error in errors) {
                if (errors.hasOwnProperty(error)) {
                    this.set('loading', false);
                    return false;
                } else {
                    return true;
                }
            }
        });
    },
    actions: {
        valueChangedPersonCountry: function (field) {
            return this.send('change', 'person_country');
        },
        valueChangedCompanyCountry: function (field) {
            return this.send('change', 'employer_country');
        },
        // this is called when ever some field is modified
        change: function (field, value) {
            var promise, self;
            self = this;
            promise = this.validate([field]);
            return promise.then(() => {
                this.setValidationError(field);
                if (field === 'id06card' && !this.get('errors.' + field)) {
                    this.set('hideCountry', false);
                    return this.fetchCard(value);
                }
            });
        },
        setFilled: function (field) {
            return this.set(field + '_filled', true);
        },
        clearErrors: function (field) {
            if (this.get('validationstate.' + field)) {
                return this.set('validationstate.' + field + '.error', false);
            }
        },
        changeEmployer: function () {
            this.set('customEmployer', true);
            this.set('model.employer_id', '');
            this.set('model.employer_country', null);
            return this.set('model.employer_name', '');
        },
        autofill: function () {
            this.set('model.lastname', 'Registered');
            this.set('model.firstname', 'User');
            this.set('model.id06card', 'AA-1202-943291');
            this.set('model.person_code', '19090510-3610');
            this.set('model.person_country', 'se');
            this.set('customEmployer', true);
            this.set('model.employer_name', 'Auto Company Ltd');
            this.set('model.employer_id', '644150-5489');
            return this.set('model.employer_country', 'se');
        },
    },
});

export default RegisterUserId06Controller;
