/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/general-v2/components/edit-time-setting/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldSettingCompComponent;

import Ember from 'ember';

CollectorFieldSettingCompComponent = Ember.Component.extend({
    store: Em.inject.service(),
    intl: Em.inject.service(),
    notifications: Ember.inject.service(),
    date_interval_setting: Ember.computed.equal('configs.setting.name', 'date_interval'),
    showSaveButton: Ember.computed(
        'internalsetting.value',
        'internalsetting.hasDirtyAttributes',
        'date_interval_future',
        'date_interval_future_check',
        'isValueCustom',
        function () {
            if (
                this.get('isValueCustom') &&
                this.get('internalsetting.value') !== '' &&
                (this.get('internalsetting.value') >= 0 ||
                    this.get('internalsetting.value') === 'locked')
            ) {
                return true;
            }
            if (
                this.get('date_interval_future_check') &&
                (this.get('date_interval_future') === '' ||
                    this.get('date_interval_future') === null)
            ) {
                return false;
            } else if (
                this.get('internalsetting.value') !== '' &&
                this.get('internalsetting.value') !== null &&
                (this.get('internalsetting.value') >= 0 ||
                    this.get('internalsetting.value') === 'locked') &&
                (this.get('internalsetting.hasDirtyAttributes') || this.get('date_interval_future'))
            ) {
                return true;
            }
            return false;
        },
    ),
    options: [
        {
            value: 'locked',
            text: 'wizard.onboarding.timetracker.edit_time.same_day',
        },
        {
            value: '2',
            text: 'wizard.onboarding.timetracker.edit_time.tomorrow',
        },
        {
            value: '3',
            text: 'wizard.onboarding.timetracker.edit_time.two_days',
        },
    ],
    didReceiveAttrs: function () {
        var configs;
        this._super();
        configs = this.get('configs');
        this.set('loading', true);
        return this.get('store')
            .findAll('form', {
                reload: true,
            })
            .then((forms) => {
                var arr, field, firstValue, form, ref, secondValue, setting;
                form = forms.filterBy('external_type', configs.form);
                field = form.get('firstObject.fields').filterBy('external_type', configs.field);
                setting = field.get('firstObject.settings').filterBy('name', configs.setting.name);
                setting.get('firstObject').rollbackAttributes(); // revert all possible changes
                if (setting.get('firstObject')) {
                    this.set('internalsetting', setting.get('firstObject'));
                } else {
                    configs.setting.field = field.get('firstObject');
                    this.set(
                        'internalsetting',
                        this.get('store').createRecord('form-field-setting', configs.setting),
                    );
                }
                if (this.get('internalsetting').get('value').split(',').length > 1) {
                    arr = this.get('internalsetting').get('value').split(',');
                    firstValue = arr[0];
                    secondValue = arr[1];
                } else {
                    firstValue = this.get('internalsetting').get('value');
                }
                if (
                    firstValue !== 'locked' &&
                    (ref = parseInt(firstValue)) !== 0 &&
                    ref !== 2 &&
                    ref !== 3
                ) {
                    firstValue--;
                    this.set('isValueCustom', true);
                }
                this.get('internalsetting').set('value', firstValue);
                if (secondValue) {
                    this.set('date_interval_future', secondValue);
                    this.set('date_interval_future_check', true);
                }
                //if @get('internalsetting').get('value') is "locked"
                //    @get('internalsetting').set('value', 1)
                return this.set('loading', false);
            });
    },
    description: Em.computed('configs', function () {
        var string;
        string = `settings.${this.get('configs.form')}.${this.get('configs.field')}.${this.get('configs.setting')}`;
        if (
            this.get('intl').exists(
                `settings.${this.get('configs.form')}.${this.get('configs.field')}.${this.get('configs.setting')}`,
            )
        ) {
            return this.get('intl').t(
                `settings.${this.get('configs.form')}.${this.get('configs.field')}.${this.get('configs.setting')}`,
            );
        }
    }),
    actions: {
        changeValue: function (value, isCustom) {
            if (isCustom && this.get('isValueCustom')) {
                return;
            }
            this.set('isValueCustom', isCustom);
            if (!this.get('isValueCustom') && !isCustom) {
                return this.set('internalsetting.value', value);
            } else {
                return this.set('internalsetting.value', null);
            }
        },
        save: function () {
            var old_value, setting, tmp_value, val;
            setting = this.get('internalsetting');
            if (setting.get('value')) {
                this.set('saving', true);
                if (this.get('isValueCustom')) {
                    this.set('placeholderValue', setting.get('value'));
                    setting.set('value', parseInt(setting.get('value')) + 1);
                } else {
                    this.set('placeholderValue', null);
                }
                old_value = setting.get('value');
                tmp_value = setting.get('value');
                if (this.get('configs').setting.name === 'date_interval') {
                    if (setting.get('value') === '0' || setting.get('value') === '1') {
                        tmp_value = 'locked';
                    }
                    if (
                        this.get('date_interval_future') &&
                        this.get('date_interval_future_check')
                    ) {
                        val = Math.abs(this.get('date_interval_future'));
                        tmp_value += ',' + val;
                    }
                }
                setting.set('value', tmp_value);
                return setting.save().then(() => {
                    if (this.get('isValueCustom')) {
                        old_value--;
                    }
                    setting.set('value', old_value);
                    this.set('saving', false);
                    if (this.get('settingSaved')) {
                        return this.settingSaved();
                    }
                });
            }
        },
    },
});

export default CollectorFieldSettingCompComponent;
