/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/confirm/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UserLinkConfirmRoute;

import Ember from 'ember';

UserLinkConfirmRoute = Ember.Route.extend({
    session: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    beforeModel: function (transition) {
        if (!this.get('session.isAuthenticated')) {
            return this.replaceWith('register.user.sign-in');
        }
    },
    setPersonInfo: function (model) {
        model.set('lastname', this.get('session.currentUser.lastname'));
        model.set('firstname', this.get('session.currentUser.firstname'));
        model.set('id06card', this.get('session.currentUser.id06kort'));
        model.set('person_code', this.get('session.currentUser.personid'));
        model.set('person_country', this.get('session.currentUser.homecountry'));
        model.set('homecountry', this.get('session.currentUser.homecountry'));
        model.set('taxnumber', this.get('session.currentUser.taxnumber'));
        return model.set('birthdate', this.get('session.currentUser.birthdate'));
    },
    actions: {
        toSignIn: function () {
            var model;
            model = this.get('controller.registerUser.model').getProperties(
                'project',
                'creator',
                'employer_name',
                'employer_id',
                'employer_country',
                'type',
            );
            localStorage.removeItem('ember_simple_auth-session');
            return this.replaceWith('register.user.sign-in', {
                queryParams: model,
            });
        },
        toID06: function () {
            var destination, model, ref;
            model = this.get('controller.registerUser.model');
            destination = 'register.user.company';
            if ((ref = this.get('controller.registerUser.type')) === 'valtti' || ref === 'normal') {
                destination = 'register.user.info';
            }
            if (this.get('session.currentUser.employer')) {
                this.store
                    .findRecord('employer', this.get('session.currentUser.employer.id'), {
                        reload: true,
                    })
                    .then((employer) => {
                        this.setPersonInfo(model);
                        model.set('employer_id', employer.get('emp_id'));
                        model.set('employer_name', employer.get('name'));
                        return model.set('employer_country', employer.get('country'));
                    });
                return this.replaceWith(destination);
            } else {
                this.setPersonInfo(model);
                return this.replaceWith(destination);
            }
        },
    },
});

export default UserLinkConfirmRoute;
