import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { inject as service } from '@ember/service';

const WorktaskIndexRoute = Route.extend(AuthenticatedRouteMixin, {
    session: service(),
    model(params) {
        params.sideload = true;
        params.user = this.session.currentUser.id;
        params.order = 'date';
        if (this.Collector.fieldExists('worktask', 'starttime')) {
            params.order = 'date,starttime';
        }
        return this.store.query('worktask', params);
    },
});

export default WorktaskIndexRoute;
