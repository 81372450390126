import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';

/*eslint-disable no-console*/
/*global FirebasePlugin*/

export default Service.extend({
    ttapi: service(),
    router: service(),
    session: service(),

    permission: false,

    setupPermission() {
        if (!window.cordova || !FirebasePlugin) return;

        FirebasePlugin.hasPermission((permission) => {
            if (permission) {
                this.permission = true;
            } else {
                FirebasePlugin.grantPermission((permission) => {
                    this.permission = permission;
                    // Try to register immediately after obtaining permission
                    // May fail if the user has not yet logged in
                    if (permission) {
                        this.register();
                    }
                });
            }
        });
    },

    register() {
        if (!window.cordova) return;
        if (!FirebasePlugin) return;

        get(this, 'ttapi').request('/user', {
            method: 'post',
            data: {
                lang: moment.locale(),
            },
        });

        FirebasePlugin.onTokenRefresh(
            (token) => {
                let currentAcceesToken = get(this, 'session.session.authenticated.access_token');
                if (!currentAcceesToken) return; // Not logged in

                get(this, 'ttapi').request('/saveFCMid', {
                    method: 'post',
                    data: {
                        id: token,
                    },
                });
            },
            (error) => {
                console.error(error);
            },
        );

        //register the messageReceived listener
        FirebasePlugin.onMessageReceived(
            (notification) => {
                //If the notification is tapped and it has redirection info, we need to redirect
                if (notification.tap && notification.redirectRoute) {
                    let route = notification.redirectRoute;
                    let params = notification.redirectParams;
                    if (params) {
                        get(this, 'router').transitionTo(route, {
                            queryParams: JSON.parse(params),
                        });
                    } else {
                        get(this, 'router').transitionTo(route);
                    }
                }
            },
            (error) => {
                console.error(error);
            },
        );
    },

    unregister() {
        return new Promise((resolve) => {
            if (!window.cordova) resolve();
            if (!FirebasePlugin) resolve();

            FirebasePlugin.getToken(
                (token) => {
                    console.log('Sending token to ttapi for unregistering');
                    get(this, 'ttapi').request('/removeFCMid', {
                        method: 'post',
                        data: {
                            id: token,
                        },
                    });
                    FirebasePlugin.unregister();
                    localStorage.removeItem('tt4-push-token');
                    resolve();
                },
                (error) => {
                    console.error(error);
                    resolve();
                },
            );
        });
    },
});
