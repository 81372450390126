import Component from '@ember/component';
import EmberObject, { get, computed, set } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

export default Component.extend({
    intl: service(),
    store: service(),

    amount: 2,
    period: 'month',
    from: moment().startOf('month').format('YYYY-MM-DD'),

    amount_options: computed(function () {
        return A([
            EmberObject.create({
                id: 2,
                name: get(this, 'intl').t('salary_period.adder.amount.2'),
            }),
            EmberObject.create({
                id: 4,
                name: get(this, 'intl').t('salary_period.adder.amount.4'),
            }),
            EmberObject.create({
                id: 6,
                name: get(this, 'intl').t('salary_period.adder.amount.6'),
            }),
            EmberObject.create({
                id: 8,
                name: get(this, 'intl').t('salary_period.adder.amount.8'),
            }),
            EmberObject.create({
                id: 12,
                name: get(this, 'intl').t('salary_period.adder.amount.12'),
            }),
        ]);
    }),

    period_options: computed(function () {
        return A([
            EmberObject.create({
                id: 'month',
                name: get(this, 'intl').t('salary_period.adder.period.month'),
            }),
            EmberObject.create({
                id: 'week2',
                name: get(this, 'intl').t('salary_period.adder.period.week2'),
            }),
            EmberObject.create({
                id: 'week4',
                name: get(this, 'intl').t('salary_period.adder.period.week4'),
            }),
        ]);
    }),

    actions: {
        create() {
            set(this, 'saving', true);
            set(this, 'disabled', true);
            let store = get(this, 'store');
            let amount = get(this, 'amount');
            let period = get(this, 'period');
            let from = get(this, 'from');
            let records = [];
            for (let i = 0; i < amount; i++) {
                let end = '';
                switch (period) {
                    case 'month':
                        end = moment(from).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
                        break;
                    case 'week2':
                        end = moment(from).add(2, 'week').subtract(1, 'day').format('YYYY-MM-DD');
                        break;
                    case 'week4':
                        end = moment(from).add(4, 'week').subtract(1, 'day').format('YYYY-MM-DD');
                }
                records.push(
                    store.createRecord('wage_period', {
                        period_name: moment(from).format('L') + ' - ' + moment(end).format('L'),
                        period_start_date: from,
                        period_end_date: end,
                    }),
                );
                switch (period) {
                    case 'month':
                        from = moment(from).add(1, 'month').format('YYYY-MM-DD');
                        break;
                    case 'week2':
                        from = moment(from).add(2, 'week').format('YYYY-MM-DD');
                        break;
                    case 'week4':
                        from = moment(from).add(4, 'week').format('YYYY-MM-DD');
                }
            }
            let promises = records.invoke('save');
            Promise.all(promises).then(() => this._PeriodsSaved());
        },
    },

    _PeriodsSaved() {
        set(this, 'saving', true);
        set(this, 'disabled', true);
        this.saved();
    },
});
