/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/file-picker/component.coffee.old
// Generated by CoffeeScript 2.6.1
var FilePickerComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import { resizeImage } from 'tt4/utils/file-util';

import $ from 'jquery';

FilePickerComponent = Ember.Component.extend({
    change: function (e) {
        return this.readFile(e.target.files[0]).then((data) => {
            return this.sendAction('onChange', data);
        });
    },
    readFile: function (file) {
        return new Ember.RSVP.Promise((resolve) => {
            var acceptedTypes, file_data, reader, ref;
            acceptedTypes = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'];
            if (((ref = file.type), indexOf.call(acceptedTypes, ref) >= 0)) {
                file_data = {};
                file_data.name = file.name;
                return resizeImage(file, 960, 960, 80).then((resize_data) => {
                    file_data.file = resize_data;
                    return resizeImage(file, 200, 200, 80).then((resize_data) => {
                        file_data.preview = resize_data;
                        return resolve([file_data]);
                    });
                });
            } else {
                reader = new FileReader();
                reader.onloadend = function (e) {
                    var api_format, dataURL, parse_file;
                    dataURL = e.target.result;
                    parse_file = dataURL.split(',');
                    api_format = {
                        data: parse_file[1],
                        name: file.name,
                    };
                    return resolve(api_format);
                };
                return reader.readAsDataURL(file);
            }
        });
    },
    actions: {
        addFile: function () {
            return $('.file_input', this.element).click();
        },
    },
});

export default FilePickerComponent;
