/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMeasurementRoute;

import Ember from 'ember';

SiteSafetyMeasurementRoute = Ember.Route.extend({
    dialogs: Ember.inject.service(),
    model: function (params) {
        return 'measurement_' + params.measurement;
    },
    actions: {
        delete: function (row) {
            return this.get('dialogs')
                .confirm('Haluatko varmasti poistaa rivin')
                .then((con) => {
                    if (con) {
                        return row.destroyRecord();
                    }
                });
        },
        edit: function (row) {
            row.set('editClass', 'TrEditText');
            return row.set('edit', true);
        },
        save: function (row) {
            var self;
            self = this;
            //            row.set('user', @get('controller.controllers.application.current_user'))
            //            row.set('date', moment().format('YYYY-MM-DD'))
            return row.save().then(function () {
                row.set('edit', false);
                return row.set('editClass', '');
            });
        },
        //                self.get('controller').dataSetter()
        cancel: function (row) {
            row.rollbackAttributes();
            row.set('edit', false);
            return row.set('editClass', '');
        },
        didTransition: function () {
            return this.set('controller.statusSelector', false);
        },
    },
});

export default SiteSafetyMeasurementRoute;
