/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/weather-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
import Ember from 'ember';

import base from '../base-field/component';

import { inject as service } from '@ember/service';

import config from '../../../../config/environment';

export default base.extend({
    gps: service(),
    weather: service(),
    weatherLoading: true,
    didInsertElement: function () {
        return this.get('gps').start();
    },
    translations: {
        temp: '°C',
        wspeed: 'm/s',
        percent: '%',
    },

    // Methods

    setValue: Em.on('init', function () {
        return Ember.run.next(this, () => {
            if (this.get('field.value') && !this.get('model.' + this.get('field.name'))) {
                return this.set('value', this.get('field.value'));
            }
        });
    }),
    // Send the change action
    valueObserver: Em.observer('value', function () {
        if (this.get('value') === this.get('field.value')) {
            return;
        }
        return this.sendAction('bubbleChange', this.get('value'), this.get('field.name'));
    }),
    gpsWeather: Ember.computed(
        'gps.lockedPosition',
        'gps.lockedLatitude',
        'gps.lockedLongitude',
        'gps.lockedAccuracy',
        function () {
            var position;
            this.set('weatherLoading', true);
            if (!this.get('gps.lockedPosition')) {
                Ember.run.next(this, () => {
                    this.set('value', null);
                    return this.set('weatherLoading', false);
                });
                return false;
            } else {
                position = {
                    latitude: this.get('gps.lockedLatitude'),
                    longitude: this.get('gps.lockedLongitude'),
                    accuracy: this.get('gps.lockedAccuracy'),
                };
                return this.get('weather')
                    .fetchByGPS(position)
                    .then((weather) => {
                        Ember.run.next(this, () => {
                            this.set('value', weather);
                            return this.set('weatherLoading', false);
                        });
                        return true;
                    });
            }
        },
    ),
});
