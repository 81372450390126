/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/tr/graphs/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyTrGraphsController;

import Ember from 'ember';

import formattedMixin from 'tt4/mixins/sitesafety-formatted-export';

SiteSafetyTrGraphsController = Ember.Controller.extend(formattedMixin, {
    trController: Ember.inject.controller('site_safety/report/tr'),
    queryParams: ['project', 'date'],
    project: Ember.computed.alias('trController.project'),
    date: Ember.computed.alias('trController.date'),
    group: Ember.computed.alias('trController.group'),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
                form: 'tr',
                type: 'database',
                value: this.get('project'),
                singleselect: true,
            }),
            Em.Object.create({
                field: 'date',
                form: 'tr',
                type: 'date',
                value: this.get('date'),
            }),
            Em.Object.create({
                field: 'group',
                form: 'tr',
                type: 'database',
                value: this.get('group'),
                singleselect: true,
            }),
        ]);
    }),
    pie_options: {
        pieHole: 0.9,
        chartArea: {
            width: '90%',
            height: '95%',
        },
        height: 200,
        width: 350,
        pieSliceTextStyle: {
            color: 'black',
            fontSize: 20,
        },
    },
    //        slices: {
    //            1: {offset: 0.02}
    //            2: {offset: 0.02}
    //            3: {offset: 0.02}
    //        }
    dataSetter: function () {
        var amount,
            average_rating,
            data,
            data_rating,
            data_rating_pie,
            field_rating,
            fields,
            pie_over90under95,
            pie_over95,
            pie_under90,
            self,
            total,
            totrating,
            trs;
        self = this;
        data = Em.A([
            [
                '',
                this.get('intl').t('tr.title.working'),
                this.get('intl').t('tr.title.scaffolding'),
                this.get('intl').t('tr.title.tools'),
                this.get('intl').t('tr.title.fall'),
                this.get('intl').t('tr.title.electricity'),
                this.get('intl').t('tr.title.garbage'),
                this.get('intl').t('tr.title.dust'),
            ],
        ]);
        data_rating = Em.A([[this.get('intl').t('date'), this.get('intl').t('tr.rating')]]);
        data_rating_pie = Em.A([
            [this.get('intl').t('tr.rating'), this.get('intl').t('tr.rating')],
        ]);
        pie_under90 = 0;
        pie_over90under95 = 0;
        pie_over95 = 0;
        average_rating = 0;
        amount = 0;
        total = 0;
        trs = this.get('model.tr');
        if (!trs) {
            return;
        }
        field_rating = [0, 0, 0, 0, 0, 0, 0];
        fields = ['working', 'scaffolding', 'tools', 'fall', 'electricity', 'garbage', 'dust'];
        trs.forEach(function (tr, idx) {
            var field, i, index, len, minus, plus, row, tr_minus, tr_plus;
            if (moment(tr.get('date')).isAfter(moment().subtract(1, 'years'))) {
                row = [moment(tr.get('date')).format('L')];
                tr_plus = tr.getProperties(
                    'working_plus',
                    'scaffolding_plus',
                    'tools_plus',
                    'fall_plus',
                    'electricity_plus',
                    'garbage_plus',
                    'dust_plus',
                );
                tr_minus = tr.getProperties(
                    'working_minus',
                    'scaffolding_minus',
                    'tools_minus',
                    'fall_minus',
                    'electricity_minus',
                    'garbage_minus',
                    'dust_minus',
                );
                for (index = i = 0, len = fields.length; i < len; index = ++i) {
                    field = fields[index];
                    plus = 0;
                    minus = 0;
                    if (tr_plus[field + '_plus']) {
                        plus = parseInt(tr_plus[field + '_plus'], 10);
                    }
                    if (tr_minus[field + '_minus']) {
                        minus = parseInt(tr_minus[field + '_minus'], 10);
                    }
                    if (!isNaN((plus / (plus + minus)) * 100)) {
                        field_rating[index] += parseFloat((plus / (plus + minus)) * 100);
                    }
                    row.push(parseFloat(((plus / (plus + minus)) * 100).toFixed(2)));
                }
                if (!isNaN(tr.get('tr_rating')) && tr.get('tr_rating') !== null) {
                    data_rating.push([
                        moment(tr.get('date')).format('L'),
                        parseFloat(tr.get('tr_rating')),
                    ]);
                    total += parseFloat(tr.get('tr_rating'));
                    amount++;
                    if (tr.get('tr_rating') < 90) {
                        pie_under90++;
                    }
                    if (tr.get('tr_rating') >= 90 && tr.get('tr_rating') < 95) {
                        pie_over90under95++;
                    }
                    if (tr.get('tr_rating') >= 95) {
                        return pie_over95++;
                    }
                } else {
                    return data_rating.push([moment(tr.get('date')).format('L'), 0]);
                }
            }
        });
        totrating = [''];
        field_rating.forEach((rating) => {
            return totrating.push(Math.round(rating / trs.get('length'), 2));
        });
        data.push(totrating);
        average_rating = Math.round(total / amount) + '%';
        data_rating_pie.push([this.get('intl').t('tr_rating_pie_under90'), pie_under90]);
        data_rating_pie.push([
            this.get('intl').t('tr_rating_pie_over90under95'),
            pie_over90under95,
        ]);
        data_rating_pie.push([this.get('intl').t('tr_rating_pie_over95'), pie_over95]);
        self.set('data', data);
        self.set('data_rating', data_rating);
        self.set('data_rating_pie', data_rating_pie);
        return self.set('average_rating', average_rating);
    }.observes('model.tr.[]', 'intl.primaryLocale', 'selectedIndex'),
    actions: {
        handleSelection: function (sel) {
            if (sel.length > 0 && sel[0].row !== null) {
                if (this.get('selectedIndex') === sel[0].row) {
                    return this.set('selectedIndex', null);
                } else {
                    return this.set('selectedIndex', sel[0].row);
                }
            }
        },
        exportNewestPdf: function (project) {
            var charts, fields, newest;
            // get newest tr record
            newest = this.store
                .peekAll('tr')
                .filter((record) => {
                    return record.get('project.id') === project;
                })
                .sortBy('date')
                .reverse()
                .get('firstObject');
            if (!newest) {
                this.get('dialogs').alert(
                    this.get('intl').t('tr.chart.export_newest_pdf_not_found'),
                );
            }
            // export as measurement pdf
            fields = this.get('Collector').fields('tr');
            charts = [
                {
                    url: this.get('piechart').getImageURI(),
                    width: 300,
                    title: this.get('intl').t('tr.chart.tr_rating_pie'),
                },
                {
                    url: this.get('datachart').getImageURI(),
                    title: this.get('intl').t('tr.chart.measurement_ratings'),
                },
                {
                    url: this.get('dataratingchart').getImageURI(),
                    title: this.get('intl').t('tr.chart.tr_rating'),
                },
            ];
            return this.createMeasurementPdf(fields, [newest], 'download', charts);
        },
        filterChanged: function (value, field) {
            if (value == null) {
                return this.set(field, '');
            } else if (field === 'date') {
                return this.set(field, value);
            } else {
                return this.set(field, value.id);
            }
        },
    },
});

export default SiteSafetyTrGraphsController;
