import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default function settingsDateRange(page) {
    return {
        intl: service(),
        store: service(),
        analytics: service(),

        // 7pv, 14,pv, 30pv, 60pv, 90pv
        dateRanges: computed(function () {
            if (page === 'abcense') {
                return [
                    { id: '1', name: '1 ' + this.intl.t('general.months') },
                    { id: '2', name: '2 ' + this.intl.t('general.months') },
                    { id: '3', name: '3 ' + this.intl.t('general.months') },
                    { id: '6', name: '6 ' + this.intl.t('general.months') },
                    { id: '12', name: '12 ' + this.intl.t('general.months') },
                ];
            }

            return [
                { id: '7', name: '7 ' + this.intl.t('general.days') },
                { id: '14', name: '14 ' + this.intl.t('general.days') },
                { id: '30', name: '30 ' + this.intl.t('general.days') },
                { id: '60', name: '60 ' + this.intl.t('general.days') },
                { id: '90', name: '90 ' + this.intl.t('general.days') },
            ];
        }),
        init() {
            this._super();
            let record = this.store.peekAll('widget-anomalies-setting').findBy('name', page);
            if (!record) {
                let storeValue = {
                    name: page,
                };

                if (page === 'abcense') {
                    storeValue.value = { selectedDateRange: 1 };
                } else {
                    storeValue.value = { selectedDateRange: 7 };
                }
                record = this.store.createRecord('widget-anomalies-setting', storeValue);
            }
            set(this, 'record', record);
            set(this, 'selectedDateRange', this.record.value.selectedDateRange);
        },
        actions: {
            save(selectedDateRange) {
                this.analytics.trackEvent({
                    category: 'Dashboard',
                    action: `Change date range setting for ${page} to ${selectedDateRange}`,
                    label: 'Anomalities widget',
                });
                set(this, 'record.value.selectedDateRange', selectedDateRange);
                this.record.save();
                this.cancel();
            },
            cancel() {
                this.cancel();
            },
        },
    };
}
