import { helper } from '@ember/component/helper';
import { warn } from '@ember/debug';

// This function receives the params `params, hash`
export function currency(params) {
    const amount = params[0];
    const currency = params[1];

    if (currency == null) {
        return amount;
    }

    switch (currency) {
        case 'eur':
            return `${amount} €`;
        case 'sek':
            return `${amount} kr`;
        case 'gbp':
            return `£${amount}`;
        case 'usd':
            return `$${amount}`;
        default:
            warn(`Unknown currency: ${currency} ${amount}`, { id: 'currency-needs' });
    }
}

export default helper(currency);
