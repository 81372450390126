/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/off-line/component.coffee.old
// Generated by CoffeeScript 2.6.1
var Component;

import Ember from 'ember';

Component = Ember.Component.extend({
    session: Ember.inject.service(),
    seconds: 3,
    tryAgain: 5,
    reconnecting: false,
    didReceiveAttrs: function () {
        return this.setTimer();
    },
    setTimer: function () {
        if (this.get('runLater')) {
            Ember.run.cancel(this.get('runLater'));
        }
        return this.set(
            'runLater',
            Ember.run.later(
                this,
                () => {
                    return this.reduceSeconds();
                },
                1000,
            ),
        );
    },
    reduceSeconds: function () {
        var seconds;
        if (this.get('isDestroyed')) {
            return;
        }
        seconds = this.get('seconds');
        this.set('seconds', seconds - 1);
        if (seconds === 1) {
            this.reconnect();
            return;
        }
        return this.setTimer();
    },
    reconnect: function (increaseTryAgain = true) {
        this.set('reconnecting', true);
        return this.get('session.session')
            .restore()
            .then(() => {
                if (this.get('isDestroyed')) {
                    return;
                }
                this.set('reconnecting', false);
                if (this.get('session.session.authenticated.offline')) {
                    if (increaseTryAgain && this.get('tryAgain') < 1000) {
                        this.set('tryAgain', this.get('tryAgain') * 3);
                    }
                    this.set('seconds', this.get('tryAgain'));
                    return this.setTimer();
                }
            });
    },
    willDestroyElement: function () {
        if (!this.get('session.currentUser') && this.get('reload')) {
            return this.reload();
        }
    },
    actions: {
        reconnectNow: function () {
            Ember.run.cancel(this.get('runLater'));
            this.set('seconds', 0);
            return this.reconnect(false);
        },
    },
});

export default Component;
