import { helper } from '@ember/component/helper';
import { htmlSafe as Safe } from '@ember/string';
import { sanitize } from 'dom-purify';

export function showLinefeeds(params) {
    if (params[0].string) {
        return new Safe(sanitize(params[0].string.replace(/\n/g, '<br>')));
    } else {
        return new Safe(sanitize(params[0].replace(/\n/g, '<br>')));
    }
}

export default helper(showLinefeeds);
