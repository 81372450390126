import Service from '@ember/service';
import DS from 'ember-data';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { getOwner } from '@ember/application';
import { assert } from '@ember/debug';
import EmberError from '@ember/error';
import { Promise as EmberPromise } from 'rsvp';

import CollectorModel from 'tt4/models/collector';
import CollectorValidationMixin from 'tt4/mixins/collector-validation';

//importing the old collector class for now because of legacy support (need to replace everywhere in tt4 otherwise)
//should move all the functionality from it into this service, but it requires a lot of work in tt4
import Collector from 'tt4/classes/collector';
// import Promise from 'rsvp';

export default Service.extend({
    store: service(),
    capi: service(),
    forms: service(),
    collector: service('collector-service'),

    createEmberModels() {
        //does capi request to get forms and then call createModels to create models
        return new EmberPromise(async (resolve, reject) => {
            try {
                const forms = await get(this, 'capi').request('/forms');
                this.createModels(forms);
                resolve();
            } catch (e) {
                localStorage.removeItem('ember_simple_auth-session');
                location.reload();
                reject(e);
            }
        });
    },

    createModels(data) {
        assert('Data must have a forms object: data.forms', data.forms);

        //need to set some stuff in collector class because tt4 depends on them,
        //should move collector class functionality into this service and make tt4 use this instead
        set(Collector, 'forms', Collector.reparse_dropdown_options(data.forms));
        if (data.updater) {
            set(Collector, 'products', data.updater.products);
            set(Collector, 'modules', data.updater.modules);
        }
        set(Collector, 'tt3flag', data.tt3flag);

        const relationFields = ['database', 'databasearray', 'timerlink'];
        const asyncRelations = [
            'materiallog.type',
            'multitask.taskname',
            'user.employer',
            'urakkalog.type',
            'customlog.type',
            'worktask.project',
            'holiday.user',
            'user.worktimegroup',
            'ccbuttonexecution.ccbutton',
            'user.superior',
        ];
        const attributeNamesWithoutInverse = ['parent', 'superior', 'contact_user'];

        let allAttributes = {};
        let relationObjects = {};

        const forms = data.forms;

        //iterate every form and make it into an ember model
        // forms is json object like
        for (let form in forms) {
            //form name must be "classified"
            //because ember model names are like that
            //e.g. wage_periods => WagePeriods
            let formName = form.classify();
            //create new object for the attributes
            let modelAttributes = {};

            //get form objects
            let formObjects = forms[form].objects;
            //iterate every object
            for (let attributeName in formObjects) {
                let type = formObjects[attributeName].type;
                let attr = null;
                //check if attribute has a type that indicates a relation to another model
                if (relationFields.includes(type) && formObjects[attributeName].options) {
                    //the other model that this relation is related to
                    let relation = formObjects[attributeName].options.form;

                    //if array does not exist, create empty array so that push method works
                    if (typeof relationObjects[relation] === 'undefined') {
                        relationObjects[relation] = [];
                    }
                    //put relation to helper array
                    relationObjects[relation].push = formName;

                    // make sure async is always boolean ..
                    const async =
                        asyncRelations.includes(formName.toLowerCase() + '.' + attributeName) ||
                        (formObjects[attributeName].async ? true : false);

                    //add belongs-to relation to attibute
                    if (type === 'databasearray') attr = DS.hasMany(relation, { async: true });
                    else if (attributeNamesWithoutInverse.includes(attributeName))
                        attr = DS.belongsTo(relation, { inverse: null, async: async });
                    else attr = DS.belongsTo(relation, { async: async });
                } else {
                    //otherwise just create a normal attribute
                    attr = DS.attr();
                }
                //put the attribute into the modelAttributes object
                modelAttributes[attributeName] = attr;
            }
            //put model attributes object into the allattributes object
            allAttributes[formName] = modelAttributes;
        }

        //add workday models to allattributes
        allAttributes = this.createWorkdayModels(allAttributes, forms);

        //iterate over relations
        for (let relation in relationObjects) {
            let formName = relation.classify();
            //create a dummy model if not already found (has only name field and relations)
            if (!allAttributes[formName]) {
                //create only name field for empty model
                let attributes = {};
                attributes.name = DS.attr();
                allAttributes[formName] = attributes;
            }
        }

        //iterate over all attribute objects and create Ember Data Models from them
        for (let object in allAttributes) {
            let model = 'model:' + object;

            let modelAttributes = allAttributes[object];
            try {
                getOwner(get(this, 'store')).register(
                    model,
                    CollectorModel.extend(CollectorValidationMixin, modelAttributes),
                );
            } catch (e) {
                if (e.message && e.message.includes('Cannot re-register: ')) continue;

                let error = e.message || e;
                throw new EmberError(error);
            }
        }

        // ember-movenium addon
        // this needs to be redone always when models are recreated ... initapp also sets this.forms.forms but this is the only place where this
        // set should be needed to be done
        this.forms.forms = data.forms;
    },

    createWorkdayModels(allAttributes, forms) {
        allAttributes['WorkdayDay'] = {
            date: DS.attr('string'),
            minutes: DS.attr('number'),
            own: DS.attr('boolean'),
            desc: DS.attr('string'),
            country: DS.attr('string'),
            absence_minutes: DS.attr('number'),
        };

        allAttributes['WorkdayWeek'] = {
            day: DS.attr('string'),
            starttime: DS.attr('string'),
            endtime: DS.attr('string'),
            minutes: DS.attr('number'),
            own: DS.attr('boolean'),
            overtimerule: DS.belongsTo('WorkdayOvertimeRule'),
            max_minutes: DS.attr('number'),
            absence_minutes: DS.attr('number'),
        };

        allAttributes['WorkdayOvertimeRule'] = {
            name: DS.attr('string'),
            rules: DS.attr('string'),
            workdayWeeks: DS.hasMany('WorkdayWeek'),
        };

        if (forms.worktimegroup) {
            (allAttributes['WorkdayDay'].worktimegroup = DS.belongsTo('worktimegroup')),
                (allAttributes['WorkdayWeek'].worktimegroup = DS.belongsTo('worktimegroup'));
        }

        if (this.collector.testNeedsOne('products.midweek_holiday_pay')) {
            allAttributes['WorkdayDay'].midweek_holiday_pay = DS.attr('boolean');
            allAttributes['WorkdayDay'].midweek_holiday_length = DS.attr('number');
        }

        return allAttributes;
    },
});
