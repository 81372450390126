import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { action, get, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';

export default class MaponController extends Controller.extend(AuthenticatedRouteMixin, {
    content: alias('model'),
}) {
    @service('collector-service') Collector;

    @tracked map_visible = false;
    @tracked user;
    @tracked gps_value;

    constructor() {
        super(...arguments);
        this.queryParams = ['date', 'user'];
        this.translations = {
            maponPoC: 'Mapon proof-of-concept',
            unitInfo: 'Unit information',
            since: 'since',
            lastUpdate: 'Last update',
            lastPosition: 'Last position',
            state: 'State',
        };
    }

    rangeStart = moment().startOf('week').format('YYYY-MM-DD');

    rangeEnd = moment().endOf('week').format('YYYY-MM-DD');

    @computed
    get mapon_users() {
        return this.store.query('user', { mapon_unit_id: '!(empty)' });
    }

    get mapon_unit_data() {
        if (!this.user) return [];
        const unit_id = get(this.store.peekRecord('user', this.user), 'mapon_unit_id');
        const url = 'mapon_unit?unit_id=' + unit_id;
        return this.Collector.ccapi({ url }).then(this.getUnitData.bind(this));
    }

    get mapon_data_routes() {
        if (!this.model) return [];

        this.model.forEach((route) => {
            if (route.type !== 'route') return;

            if (route.start) {
                route.start.time = moment(route.start.time).format('llll');
            }
            if (route.end) {
                route.end.time = moment(route.end.time).format('llll');
            }
            if (route.distance) {
                return (route.distance = (route.distance / 1000).toFixed(2));
            }
            return route;
        });

        return this.model;
    }

    @action
    toggle_gps_map() {
        if (this.map_visible) {
            this.map_visible = false;
        } else {
            this.map_visible = true;
        }
    }

    getUnitData(data) {
        const unitdata = data.data.units[0];

        if (unitdata.mileage) {
            unitdata.mileage = Math.round(unitdata.mileage / 1000) + 'km';
        }

        if (unitdata.last_update) {
            unitdata.last_update = moment(unitdata.last_update).format('llll');
        }

        if (unitdata.state.start) {
            unitdata.state.start = moment(unitdata.state.start).format('llll');
        }

        this.gps_value = '{"latitude": ' + unitdata.lat + ', "longitude": ' + unitdata.lng + '}';
        return data.data.units[0];
    }
}
