/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/calendar/diaryreport-calendar-item/component.coffee.old
// Generated by CoffeeScript 2.6.1
var DiaryreportCalendarItemComponent;

import Ember from 'ember';

DiaryreportCalendarItemComponent = Ember.Component.extend({
    rows: Ember.computed('day', 'model.[]', function () {
        return this.get('model').filterBy('diary_date', this.get('day'));
    }),
});

export default DiaryreportCalendarItemComponent;
