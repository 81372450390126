/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/address-info.coffee.old
// Generated by CoffeeScript 2.6.1
var AddressInfoComponent;

import Ember from 'ember';

import config from '../config/environment';

AddressInfoComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    admintool: Ember.inject.service(),
    countryLists: Ember.inject.service(),
    notifications: Ember.inject.service(),
    errors: Em.Object.create({
        street: false,
        zip: false,
        town: false,
        country: false,
    }),
    actions: {
        change: function (field, value) {
            if (this.get('billingData.use_company_address')) {
                this.set('billingData.' + field, value);
            }
            return this.set('errors.' + field, false);
        },
        save: function () {
            var field, hasError, i, len, promise, ref;
            hasError = false;
            ref = ['street', 'zip', 'town', 'country'];
            for (i = 0, len = ref.length; i < len; i++) {
                field = ref[i];
                if (!this.get('data.' + field) || this.get('data.' + field) === '') {
                    this.set('errors.' + field, true);
                    hasError = true;
                } else {
                    this.set('errors.' + field, false);
                }
            }
            if (!hasError) {
                promise = this.get('admintool').ajax({
                    url: 'customer_info',
                    method: 'PUT',
                    data: {
                        street: this.get('data.street'),
                        zip: this.get('data.zip'),
                        town: this.get('data.town'),
                        country: this.get('data.country'),
                        specifier: this.get('data.specifier'),
                    },
                });
                // first save address-info
                return promise.then(() => {
                    // then if "use company address" is checked in billing-info, save address to billing-info also
                    if (
                        this.get('billingData.use_company_address') &&
                        this.get('billingtype') === 'paper'
                    ) {
                        promise = this.get('admintool').ajax({
                            url: 'billinginfo',
                            method: 'PUT',
                            data: {
                                street: this.get('data.street'),
                                zip: this.get('data.zip'),
                                town: this.get('data.town'),
                                country: this.get('data.country'),
                                //invoice_ref: @get 'billingData.invoice_ref'
                                billingtype: 'paper',
                            },
                        });
                        return promise.then(() => {
                            return this.get('notifications').success(
                                this.get('intl').t('general.saved'),
                                {
                                    autoClear: true,
                                },
                            );
                        });
                    } else {
                        return this.get('notifications').success(
                            this.get('intl').t('general.saved'),
                            {
                                autoClear: true,
                            },
                        );
                    }
                });
            }
        },
    },
});

export default AddressInfoComponent;
