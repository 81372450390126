/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/measurement/data/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyTrDataRoute;

import Ember from 'ember';

import GeneralReportRoute from '../../../../routes/general-report';

import { inject as service } from '@ember/service';

SiteSafetyTrDataRoute = GeneralReportRoute.extend({
    capi: service(),
    flags: service(),
    queryParams: {
        status: {
            refreshModel: true,
        },
        project: {
            refreshModel: true,
        },
        date: {
            refreshModel: true,
        },
        page: {
            refreshModel: true,
        },
        groupBy: {
            refreshModel: true,
        },
        q: {
            refreshModel: true,
        },
    },
    model: function (params) {
        var form;
        form = this.modelFor('site-safety.measurement');
        this.set('form', form);
        params = this._super(params);
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            params.sideload = true;
            if (Ember.isPresent(params.groupBy)) {
                // This can be moved to mixin when wanted in other places also
                params.order = params.groupBy;
                return this.get('capi').findAll(form, params);
            } else {
                return this.store.query(form, params);
            }
        } else {
            if (params.page) {
                delete params.page;
            }
            return this.controllerFor('site-safety.measurement.data').set('qParams', params);
        }
    },
});

//    setupController: (controller, model) ->
//#        controller.set 'form', @get 'form'
//        @_super controller, model
export default SiteSafetyTrDataRoute;
