/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/admintool/createservice/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var Controller;

import Ember from 'ember';

import config from '../../../config/environment';

import { inject as service } from '@ember/service';

Controller = Ember.Controller.extend({
    flags: service(),
    session: service(),
    loading: false,
    country: '',
    products: [],
    products_fi: [
        {
            name: 'products.base',
            product: 'base',
        },
        {
            name: 'products.basic',
            product: 'basic',
        },
        {
            name: 'products.contractor',
            product: 'contractor',
        },
    ],
    products_se: [
        {
            name: 'products.basic',
            product: 'basic',
        },
        {
            name: 'products.personalliggare',
            product: 'personalliggare',
        },
        {
            name: 'products.contractor',
            product: 'contractor',
        },
        {
            name: 'products.plentry',
            product: 'entry',
        },
        {
            name: 'products.pro_se',
            product: 'pro_se',
        },
    ],
    url: Ember.computed(function () {
        return config.APP.MAIN_TTAPI + '/createService';
    }),
    token: Ember.computed.alias('session.session.authenticated.access_token'),
    init: function () {
        this._super(...arguments);
        if (this.get('flags').test('mini-entry-at2')) {
            this.get('products_fi').pushObject({
                name: 'products.mini_entry',
                product: 'mini_entry',
            });
            return this.get('products_se').pushObject({
                name: 'products.mini_entry',
                product: 'mini_entry',
            });
        }
    },
    actions: {
        showLoading: function () {
            return this.set('loading', true);
        },
        setProducts: function (value) {
            this.set('country', value);
            if (value === 'fi') {
                return this.set('products', this.get('products_fi'));
            } else if (value === 'se') {
                return this.set('products', this.get('products_se'));
            } else {
                return this.set('products', []);
            }
        },
    },
});

export default Controller;
