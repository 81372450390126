import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
    worktimeColumns: computed(function () {
        return get(this, 'collector').fieldArray('worktime').rejectBy('features', 'hide_column');
    }),
    absenceColumns: computed(function () {
        return get(this, 'collector').fieldArray('abcense').rejectBy('features', 'hide_column');
    }),

    actions: {
        setRowsPerPage(form, limit) {
            this.setRowsPerPage(form, limit);
        },
    },
});
