/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/saldohistory/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditSaldohistoryController;

import Ember from 'ember';

import { computed } from '@ember/object';

UsersEditSaldohistoryController = Ember.Controller.extend({
    reportFilter: computed('model', function () {
        return [
            {
                name: 'user',
                value: this.get('model.id'),
            },
        ];
    }),
    rowActions: [
        {
            action: 'rowHistory',
            icon: 'move move-Search',
        },
    ],
});

export default UsersEditSaldohistoryController;
