/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/route.coffee.old
// Generated by CoffeeScript 2.6.1
var OrientationsAddRoute;

import Ember from 'ember';

import collector from '../../../classes/collector';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

OrientationsAddRoute = Ember.Route.extend(AuthenticatedRouteMixin, {
    dialogs: Ember.inject.service(),
    settings: Ember.inject.service('customer-settings'),
    intl: Em.inject.service(),
    router: Em.inject.service(),
    checkbox_groups: function () {
        var checkAll, checkAllBoxes, ref, self, temp;
        self = this;
        temp = Ember.A([]);
        checkAll = this.get('settings.settings').filterBy('key', 'orientations_check_all');
        checkAllBoxes = false;
        if (
            (checkAll.length > 0 && ((ref = checkAll[0].value) === 'on' || ref === 'true')) ||
            this.get('checkAll')
        ) {
            checkAllBoxes = true;
        }
        $.each(collector.forms.orientation.objects, function (title) {
            var options_arr;
            if (this.type !== 'checkboxgroup') {
                return;
            }
            if (!this.options) {
                return;
            }
            options_arr = Ember.A([]);
            $.each(this.options, function (key) {
                var text;
                text =
                    this.substring(0, 1) === '$'
                        ? self.get('intl').t('orientations.' + title + '.' + key)
                        : this;
                return options_arr.pushObject(
                    Ember.Object.create({
                        checked: checkAllBoxes ? true : null,
                        key: key,
                        text: text,
                    }),
                );
            });
            return temp.pushObject({
                title: this.name ? this.name : self.get('intl').t('orientations.' + title),
                key: title,
                options: options_arr,
            });
        });
        return temp;
    },
    model: function () {
        return Ember.Object.create({
            record: this.store.createRecord('orientation'),
            checkbox_groups: this.checkbox_groups(),
        });
    },
    continueSaving: function (signature, orientations) {
        var passPromises, promises, self;
        this.set('controller.signaturesController.saving', true);
        self = this;
        promises = [];
        passPromises = [];
        orientations.forEach(function (obj) {
            obj.set('ori_place', self.get('controller.place'));
            obj.set('ori_date', self.get('controller.date'));
            if (signature) {
                obj.set('sign_emp_img', signature);
            }
            if (self.get('controller.passesToBeSaved')) {
                passPromises = self.savePasses(self.get('controller.passesToBeSaved'));
                self.set('controller.passesToBeSaved', null);
            }
            return promises.push(
                obj.save().then(function () {
                    self.transitionTo('orientations.add.saved');
                    self.get('controller').set('model.record', null);
                    self.get('controller').set('users', null);
                    self.get('controller').set('projectRecord', null);
                    self.get('controller').set('userRecords', null);
                    return self.get('controller').set('orientations_was_saved', true);
                }),
            );
        });
        return Ember.RSVP.Promise.all(promises, passPromises).then(
            () => {
                return this.set('controller.signaturesController.saving', false);
            },
            function () {
                return this.set('controller.signaturesController.saving', false);
            },
        );
    },
    savePasses: function (passes) {
        var promises;
        promises = [];
        passes.forEach((pass) => {
            return promises.push(pass.save());
        });
        return promises;
    },
    actions: {
        willTransition: function (transition) {
            var oris, target, unsavedRecords;
            unsavedRecords = false;
            target = transition.targetName.split('.');
            oris = this.store.peekAll('orientation');
            oris.forEach(function (orientation) {
                if (orientation.get('isNew') === true) {
                    return (unsavedRecords = true);
                }
            });
            if (this.get('router').get('currentRouteName') === 'orientations.add.index') {
                if (target[0] !== 'orientations') {
                    if (this.get('controller.orientations')) {
                        this.get('controller.orientations').forEach(function (item) {
                            return item.rollbackAttributes();
                        });
                    } else {
                        this.get('controller.model.record').rollbackAttributes();
                    }
                    this.set('controller.users', Em.A([]));
                    return true;
                }
            }
            if (target[0] === 'orientations' || unsavedRecords === false) {
                return true;
            }
            if (!this.get('allowTransition')) {
                transition.abort();
            } else {
                return true;
            }
            return this.get('dialogs')
                .confirm(this.get('intl').t('orientations.move'))
                .then((con) => {
                    if (!con) {
                        return transition.abort();
                    } else {
                        this.get('controller').set('users', null);
                        this.get('controller').set('projectRecord', null);
                        this.get('controller').set('userRecords', null);
                        if (this.get('controller.orientations')) {
                            this.get('controller.orientations').forEach(function (item) {
                                return item.rollbackAttributes();
                            });
                        } else {
                            this.get('controller.model.record').rollbackAttributes();
                        }
                        this.set('controller.users', Em.A([]));
                        this.set('allowTransition', true);
                        return transition.retry();
                    }
                });
        },
        save: function () {
            var mandatory, orientations, sigController, signature, signaturesMissing;
            sigController = this.get('controller.signaturesController');
            sigController.send('validate');
            if (sigController.get('errors')) {
                return;
            }
            signaturesMissing = false;
            orientations = this.get('controller.orientations');
            signature = sigController.get('model.employer_signature');
            orientations.forEach(function (obj) {
                if (!obj.get('sign_per_img')) {
                    return (signaturesMissing = true);
                }
            });
            if (!signature || signaturesMissing) {
                mandatory = this.get('controller.mandatoryArr');
                if (
                    (!signature && mandatory['sign_emp_img']) ||
                    (signaturesMissing && mandatory['sign_per_img'])
                ) {
                    return this.get('dialogs').alert(
                        this.get('intl').t('orientations.mandatory_signatures_missing'),
                    );
                } else {
                    return this.get('dialogs')
                        .confirm(this.get('intl').t('orientations.signatures_missing'))
                        .then((continueSaving) => {
                            //if !confirm(@get('intl').t("orientations.signatures_missing"))
                            //    continueSaving = false
                            if (continueSaving) {
                                return this.continueSaving(signature, orientations);
                            }
                        });
                }
            } else {
                return this.continueSaving(signature, orientations);
            }
        },
    },
});

export default OrientationsAddRoute;
