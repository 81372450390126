/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/form-json.coffee.old
// Generated by CoffeeScript 2.6.1
var FormJsonService;

import Ember from 'ember';

FormJsonService = Ember.Service.extend({
    store: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    _validateErrors: [],
    /*
  export form as json .. format example

  {
    "formname": {
      "objects": {
        "fieldname": {
          "type": "date",
          "features": {
            "mandatory": true
          }
        },
          "user": {
              "type": "database",
              "options": {
                "form": "user",
                "fields": [
                  "lastname",
                  "firstname",
                  "number"
                ]
              },
      },
      "features": {
          "mandatory": true
      }
    }

  */
    exportForm: function (form) {
        var form_array, json_array;
        form_array = this._getForm(form);
        if (form_array) {
            json_array = {};
            json_array[form] = form_array;
            return json_array;
        }
    },
    /*
  import form or multiple forms from json array
  */
    import: function (json) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var form, form_array, promises;
            promises = [];
            for (form in json) {
                form_array = json[form];
                promises.push(this._importForm(form, form_array));
            }
            return Ember.RSVP.Promise.all(promises).then((results) => {
                var saved;
                saved = results.filter(function (item) {
                    return item === 'saved';
                });
                return resolve(saved.length > 0);
            }, reject);
        });
    },
    /*
  Find changes that are needed and add all fields and settings that aren't found yet
  */
    _importForm: function (form, form_array) {
        return new Ember.RSVP.Promise((resolve) => {
            var changes, existing_form_array, message, this_is_a_new_form;
            this.set('_validateErrors', []);
            existing_form_array = this._getForm(form);
            changes = this._getNeededChanges(form_array, existing_form_array);
            this_is_a_new_form = existing_form_array === null;
            if (!this_is_a_new_form && changes.add.length < 1) {
                this.get('dialogs').alert('file did not contain anything new');
                resolve('no_changes');
                return;
            }
            if (this.get('_validateErrors.length') > 0) {
                this.get('dialogs').alert(
                    '<b>Importing cannot be done because of these errors:</b><br><br>' +
                        this.get('_validateErrors').join('<br>'),
                );
                resolve('errors');
                return;
            }
            message = this._getConfirmMessage(this_is_a_new_form, form, changes.add);
            return this.get('dialogs')
                .confirm(message)
                .then((ret) => {
                    var form_name, form_record;
                    if (!ret) {
                        resolve('cancelled');
                        return;
                    }
                    console.info('Importing', form);
                    // form does not exist .. let's create it
                    if (this_is_a_new_form) {
                        form_name = form_array.name != null ? form_array.name : '$lang(10)';
                        form_record = this.get('store').createRecord('form', {
                            name: form_name,
                            external_type: form,
                        });
                    } else {
                        form_record = this.get('store')
                            .peekAll('form')
                            .findBy('external_type', form);
                    }
                    this._addNeededRecords(
                        form_record,
                        changes.add,
                        this._normalizeForm(form_array),
                    );
                    return this._saveAllDirtyFormRecords().then(() => {
                        this.get('dialogs').alert('Form ' + form + ' added and saved to backend');
                        return resolve('saved');
                    });
                });
        });
    },
    // when called all dirty form, form-setting,form-field and form-field-detting records are saved.
    // records are saved in given order by calling this function recursively
    _saveAllDirtyFormRecords: function (type = 'form') {
        return new Ember.RSVP.Promise((resolve) => {
            var dirtyRecords, promises, save_order;
            save_order = ['form', 'form-setting', 'form-field', 'form-field-setting'];
            promises = [];
            dirtyRecords = this.get('store')
                .peekAll(type)
                .filter(function (record) {
                    return record.get('hasDirtyAttributes');
                });
            dirtyRecords.forEach(function (record) {
                return promises.push(record.save());
            });
            return Ember.RSVP.Promise.all(promises).then(() => {
                var save_next_index;
                save_next_index = save_order.indexOf(type) + 1;
                if (save_next_index >= save_order.length) {
                    resolve();
                    return;
                }
                return this._saveAllDirtyFormRecords(save_order[save_next_index]).then(function () {
                    return resolve();
                });
            });
        });
    },
    // make confirmation message for user to decide whether to import given file or not
    _getConfirmMessage: function (new_form, form, addChanges) {
        var message, new_features, new_fields;
        if (!new_form) {
            message = 'Add these to exiting form <b>' + form + '</b>?';
        } else {
            message = 'Add new form named <b>' + form + '</b>?';
        }
        new_fields = addChanges.filter((item) => {
            return this._testType(item) === 'form-field';
        });
        if (new_fields.length > 0) {
            message += '<br><br><b>Fields:</b> <br>' + new_fields.join('<br>');
        }
        new_features = addChanges.filter((item) => {
            return this._testType(item) === 'form-setting';
        });
        new_features = new_features.map(function (item) {
            return item.split('@')[1].split(':')[0];
        });
        if (new_features.length > 0) {
            message += '<br><br><b>Settings:</b> <br>' + new_features.join('<br>');
        }
        return message;
    },
    // go through every needed add and create correct type record for it
    _addNeededRecords: function (form_record, adds, values) {
        var add, j, len, results1, type;
        results1 = [];
        for (j = 0, len = adds.length; j < len; j++) {
            add = adds[j];
            type = this._testType(add);
            results1.push(
                this.get('store').createRecord(
                    type,
                    this._createRecordParams(form_record, add, values[add]),
                ),
            );
        }
        return results1;
    },
    // from key test what is the type of object: form-setting, form-field-setting or form-field
    _testType: function (key) {
        if (key.substring(0, 1) === '@') {
            return 'form-setting';
        } else if (key.indexOf('@') !== -1) {
            return 'form-field-setting';
        } else {
            return 'form-field';
        }
    },
    // get all the needed parameters to create a record of needed type
    _createRecordParams: function (form_record, key, values) {
        var feature_name, field_name, field_record, form_fields, type;
        type = this._testType(key);
        if (type === 'form-field') {
            field_name = values.name || '$lang(10)';
            return {
                form: form_record,
                type: values.type,
                name: field_name,
                external_type: key,
                options: values.options,
                nro: values.nro,
            };
        }
        if (type === 'form-field-setting') {
            field_name = key.split('@')[0];
            feature_name = key.split('@')[1].split(':')[0];
            form_fields = this.get('store')
                .peekAll('form-field')
                .filter(function (item) {
                    return item.get('form.id') === form_record.get('id');
                });
            field_record = form_fields.findBy('external_type', field_name);
            return {
                field: field_record,
                name: feature_name,
                value: values.value,
                userlevel: values.userlevel,
            };
        }
        if (type === 'form-setting') {
            feature_name = key.split('@')[1].split(':')[0];
            return {
                form: form_record,
                name: feature_name,
                value: values.value,
                userlevel: values.userlevel,
            };
        }
    },
    // normalize current forms and needed forms and diff those to get what needs to added and removed
    _getNeededChanges: function (new_form, old_form) {
        var add, normalizedCurrent, normalizedNeeded, remove;
        if (old_form) {
            normalizedCurrent = this._normalizeForm(old_form);
        } else {
            normalizedCurrent = {};
        }
        normalizedNeeded = this._normalizeForm(new_form);
        add = this._array_diff(Object.keys(normalizedNeeded), Object.keys(normalizedCurrent));
        remove = this._array_diff(Object.keys(normalizedCurrent), Object.keys(normalizedNeeded));
        return {
            add: add,
            remove: remove,
        };
    },
    _array_diff: function (arr1, arr2) {
        return arr1.filter(function (i) {
            return arr2.indexOf(i) < 0;
        });
    },
    // get json array and normalize it to array format:
    // field: <values>
    // field2: <values>
    // field2@mandatory: <values>
    // @somefature: <values>
    _normalizeForm: function (form_array) {
        var feature, field_name, field_object, key, normalized, options, ref, ref1, ref2, values;
        normalized = {};
        ref = form_array.objects;
        for (field_name in ref) {
            field_object = ref[field_name];
            key = field_name;
            options = this._encodeOptions(field_object.type, field_object.options, field_name);
            normalized[key] = {
                type: field_object.type,
                name: field_object.name,
                options: options,
                nro: field_object.nro,
            };
            ref1 = field_object.features;
            for (feature in ref1) {
                values = ref1[feature];
                key =
                    field_name + '@' + feature + ':' + 'u' + values.userlevel + 'v' + values.value;
                normalized[key] = values;
            }
        }
        ref2 = form_array.features;
        for (feature in ref2) {
            values = ref2[feature];
            key = '@' + feature + ':' + 'u' + values.userlevel + 'v' + values.value;
            normalized[key] = values;
        }
        return normalized;
    },
    // fetch form from store and return it as array
    _getForm: function (form) {
        var formRecord, form_array;
        formRecord = this.get('store').peekAll('form').findBy('external_type', form);
        if (!formRecord) {
            return null;
        }
        form_array = {};
        if (formRecord.get('name') !== '$lang(10)') {
            form_array.name = formRecord.get('name');
        }
        form_array.objects = this._getFormFields(formRecord.get('id'));
        form_array.features = this._getFormFeatures(formRecord.get('id'));
        return form_array;
    },
    _getFormFields: function (formid) {
        var field_json, formFields, store;
        store = this.get('store');
        formFields = store.peekAll('form-field').filter(function (item) {
            return item.get('form.id') === formid;
        });
        field_json = {};
        formFields.forEach((formField) => {
            var formname;
            formname = formField.get('external_type');
            field_json[formname] = {
                type: formField.get('type'),
                features: this._getFormFieldFeatures(formField),
                options: this._decodeOptions(formField.get('type'), formField.get('options')),
                nro: formField.get('nro'),
            };
            if (formField.get('name') !== '$lang(10)') {
                return (field_json[formname].name = formField.get('name'));
            }
        });
        return field_json;
    },
    // decode field options to form and fields. options are list of ids in database but we want external_types
    _decodeOptions: function (type, options) {
        var fields, id, j, len, options_arr, parsed_options;
        if (type !== 'database') {
            return options;
        }
        options_arr = options.split(/\r\n|\r|\n/);
        if (options_arr.lenght < 1) {
            return '';
        }
        fields = [];
        for (j = 0, len = options_arr.length; j < len; j++) {
            id = options_arr[j];
            fields.push(this.get('store').peekRecord('form-field', id));
        }
        parsed_options = {
            form: fields[0].get('form.external_type'),
            fields: fields.map(function (item) {
                return item.get('external_type');
            }),
        };
        return parsed_options;
    },
    // encode external_type options back to list of object ids. Add errors to _validateErrors if some linkings cannot
    // be satisfied
    _encodeOptions: function (type, options, field_name = '') {
        var all_form_fields, field, fieldRecord, field_ids, j, len, ref;
        if (type !== 'database') {
            return options;
        }
        if (!options || options.form == null) {
            this.get('_validateErrors').push('Field ' + field_name + " doesn't have link options");
            return '';
        }
        all_form_fields = this.get('store')
            .peekAll('form-field')
            .filter(function (item) {
                return item.get('form.external_type') === options.form;
            });
        if (all_form_fields.length < 1) {
            this.get('_validateErrors').push(
                'Field ' +
                    field_name +
                    ' has link to form ' +
                    options.form +
                    ' which does not exist',
            );
            return '';
        }
        field_ids = [];
        ref = options.fields;
        for (j = 0, len = ref.length; j < len; j++) {
            field = ref[j];
            fieldRecord = all_form_fields.findBy('external_type', field);
            if (!fieldRecord) {
                this.get('_validateErrors').push(
                    'Field ' +
                        field_name +
                        ' has link to form field ' +
                        options.form +
                        '.' +
                        field +
                        ' which does not exist',
                );
            } else {
                field_ids.push(fieldRecord.get('id'));
            }
        }
        return field_ids.join('\n');
    },
    _getFormFeatures: function (formid) {
        var feature_json, formFeatures;
        formFeatures = this.get('store')
            .peekAll('form-setting')
            .filter(function (item) {
                return item.get('form.id') === formid;
            });
        feature_json = {};
        formFeatures.forEach((formFeature) => {
            return (feature_json[formFeature.get('name')] = {
                value: formFeature.get('value'),
                userlevel: formFeature.get('userlevel'),
            });
        });
        return feature_json;
    },
    _getFormFieldFeatures: function (field) {
        var feature_json, formFieldFeatures;
        formFieldFeatures = this.get('store')
            .peekAll('form-field-setting')
            .filter(function (item) {
                return item.get('field.id') === field.get('id');
            });
        feature_json = {};
        formFieldFeatures.forEach((formFieldFeature) => {
            return (feature_json[formFieldFeature.get('name')] = {
                value: formFieldFeature.get('value'),
                userlevel: formFieldFeature.get('userlevel'),
            });
        });
        return feature_json;
    },
});

export default FormJsonService;
