import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

const DevelopmentCronController = Controller.extend({
    ttapi: service(),

    init() {
        this._super(...arguments);
        this.scripts = ['alarms', 'unapprovedWorktimes', 'postpopulateWorktimes', 'vismanet'];
    },

    content: alias('model'),
    actions: {
        async execute(script) {
            console.log('execute', script); // eslint-disable-line
            set(this, 'loading', true);
            try {
                const back = await this.ttapi.request('artisan/' + script, {
                    dataType: 'html',
                    method: 'POST',
                });
                set(this, 'loading', false);
                return set(this, 'response', back);
            } catch (error) {
                set(this, 'loading', false);
                return set(this, 'response', error);
            }
        },
    },
});

export default DevelopmentCronController;
