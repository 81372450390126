/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/presences/add/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var PresenceAddController;

import Ember from 'ember';

import GeneralAddController from '../../../controllers/general-add';
import { get } from '@ember/object';

import collector from '../../../classes/collector';

import $ from 'jquery';

import { captureException } from '@sentry/browser';

PresenceAddController = Ember.Controller.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    dialogs: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    location: Ember.computed(function () {
        return collector.getFormObject('presence.location');
    }),
    projectObs: function () {
        return this.set('selectedProject', this.get('model.project'));
    }.observes('model.project.name'),
    projectField: Em.computed('model', function () {
        var field;
        field = this.get('Collector').fieldArray('presence', ['project'])[0];
        field.value = this.get('model.project');
        field.filterOptions = {
            presence_project: 'on',
        };
        return field;
    }),
    mySiteSelected: Em.computed.and('session.currentUser.currentMySite', 'outActive'),
    setCurrentMySite: Em.on(
        'init',
        Em.observer('session.currentUser.currentMySite', 'outActive', 'model', function () {
            var site;
            if (!this.get('outActive') || !this.get('model')) {
                return;
            }
            site = this.get('session.currentUser.currentMySite');
            if (site) {
                return this.set('model.project', site);
            }
        }),
    ),
    projectDisabled: Ember.computed('inActive', function () {
        if (this.get('inActive')) {
            return 'disabled';
        } else {
            return false;
        }
    }),
    force_location: Ember.computed(function () {
        if (
            collector.getFormObject('project.force_location') &&
            this.get('session.currentUser.userlevel') !== '5'
        ) {
            return true;
        }
    }),
    custom_projects: Em.A([]),
    inoutLoading: false,
    getIn: function (row) {
        var self;
        self = this;
        if (!this.get('inActive')) {
            return this.get('store')
                .findRecord('project', this.get('selectedProject.id'), {
                    reload: true,
                })
                .then((project) => {
                    if (!project.get('presence_project')) {
                        this.get('dialogs').alert(this.get('intl').t('presence.not_an_pl_project'));
                        this.set('inActive', false);
                        this.set('outActive', true);
                        this.set('inoutLoading', false);
                        return;
                    }
                    row.set('project', project);
                    return this.saveRow(row, 'in').then(function () {
                        self.set('inActive', true);
                        return self.set('outActive', false);
                    });
                });
        }
    },
    getOut: function (row) {
        var self;
        self = this;
        if (!this.get('outActive')) {
            return this.saveRow(row, 'out').then(function () {
                self.set('inActive', false);
                return self.set('outActive', true);
            });
        }
    },
    saveRow: function (row, status) {
        var self;
        self = this;
        return new Ember.RSVP.Promise((resolve, reject) => {
            this.set('apiError', null);
            if (status === 'out') {
                row.set('in', void 0);
                row.set('out', moment().format('HH:mm'));
            } else if (status === 'in') {
                row.set('in', moment().format('HH:mm'));
                row.set('date', moment().format('YYYY-MM-DD'));
            } else if (status === 'error') {
                //                row.set 'out', moment().format('HH:mm')
                row.set('in', void 0);
                row.set('status', 'error_out');
            }
            return row.save().then(
                function () {
                    var newrow, store;
                    if (status === 'out' || status === 'error') {
                        store = self.get('store');
                        newrow = store.createRecord('presence', {
                            date: moment().format('YYYY-MM-DD'),
                            project: self.get('selectedProject'),
                            in: moment().format('HH:mm'),
                        });
                        self.set('model', newrow);
                    }
                    self.set('inoutLoading', false);
                    return resolve();
                },
                (error) => {
                    captureException(error);
                    const errorCode = get(error, 'errors.0.errorMeta.message');
                    let message = JSON.stringify(error.errors != null ? error.errors : error);

                    if (errorCode === 'not found') {
                        message = this.get('intl').t('validation.error.id06_not_found');
                    } else if (errorCode) {
                        const translationKey = `user.action.checkid06.reason_${errorCode}`;
                        if (this.get('intl').exists(translationKey))
                            message = this.get('intl').t(translationKey);
                    }

                    this.set('apiError', message);

                    self.set('inoutLoading', false);
                    return reject(error);
                },
            );
        });
    },
    //time out counter 10 to 0
    seconds: 0,
    updateSecs: function () {
        if (this.get('seconds') === 0) {
            return;
        }
        return Ember.run.later(() => {
            this.decrementProperty('seconds');
            return this.updateSecs();
        }, 1000);
    },
    //presence over 16h
    checkPresenceOver16h: function () {
        var diff, in_datetime, now, self;
        //only when checking out
        if (this.get('outActive')) {
            return;
        }
        self = this;
        now = moment();
        in_datetime = moment(this.get('model.date') + ' ' + this.get('model.in'));
        diff = now.diff(in_datetime, 'hours');
        this.set('hoursActive', diff);
        if (diff >= 16) {
            //show alert
            return this.get('dialogs')
                .alert(this.get('intl').t('presence.over16h_automatic_checkout'))
                .then((con) => {
                    var row;
                    row = this.get('model');
                    return this.saveRow(row, 'error').then(function () {
                        self.set('inActive', false);
                        self.set('outActive', true);
                        return self.set('hoursActive', null);
                    });
                });
        }
    },
    showEndLocation: Em.computed(function () {
        if (collector.getFormObject('presence.end_location')) {
            return true;
        }
    }),
    actions: {
        changeValue: function (value, fieldName) {
            var self, store;
            self = this;
            store = this.get('store');
            return store.find('project', value).then(function (project) {
                self.set('selectedProject', project);
                return self.set('model.project', project);
            });
        },
        setProject: function (value) {
            return this.set('selectedProject', value);
        },
        worksitePresence: function (value) {
            var row;
            // If button is timed out we just return and do nothing
            if (this.get('timeout')) {
                //animate on click when timeout
                $('.big-icon').addClass('anim-no-project');
                setTimeout(function () {
                    return $('.big-icon').removeClass('anim-no-project');
                }, 500);
                return;
            }
            row = this.get('model');
            if (this.get('selectedProject')) {
                //set loading indicator
                this.set('inoutLoading', true);
                // Set button to timed out so we can't spamclick it
                this.set('timeout', true);
                this.set('seconds', 5);
                this.updateSecs();
                // Remove the timeout after x amount of time
                Em.run.later(
                    this,
                    function () {
                        return this.set('timeout', false);
                    },
                    5000,
                );
                // Add animation class
                $('.big-icon').addClass('anim-check-in');
                // Remove the animation class after it has run
                Em.run.later(
                    this,
                    function () {
                        return $('.big-icon').removeClass('anim-check-in');
                    },
                    500,
                );
                // Stamp in or out just before the animation has finished
                return Em.run.later(
                    this,
                    function () {
                        // started new presence
                        if (value === 'in') {
                            this.getIn(row);
                        }
                        // ended presence
                        if (value === 'out') {
                            return this.getOut(row);
                        }
                    },
                    300,
                );
            } else {
                //animate on click when no project (should be done with liquidfire)
                $('.big-icon').addClass('anim-no-project');
                return setTimeout(function () {
                    return $('.big-icon').removeClass('anim-no-project');
                }, 500);
            }
        },
    },
});

export default PresenceAddController;
