import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Route.extend({
    session: service(),
    model() {
        return this.modelFor('site-safety.measurement.add');
    },
    setupController(controller) {
        this._super(...arguments);
        if (get(this, 'session.currentUser.currentMySite')) {
            set(controller, 'project', get(this, 'session.currentUser.currentMySite'));
        }
    },
});
