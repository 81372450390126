/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/forms/form/route.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsFormsFormRoute;

import Ember from 'ember';

SettingsFormsFormRoute = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function (params) {
        return new Ember.RSVP.hash({
            form: this.store.peekRecord('form', params.form),
            settings: this.store.peekAll('form-setting'),
            fields: this.store.peekAll('form-field'),
            fieldSettings: this.store.peekAll('form-field-setting'),
        });
    },
    actions: {
        willTransition: function (transition) {
            if (transition.targetName.substring(0, 14) === 'settings.forms') {
                return;
            }
            if (this.get('controller.unsavedChanges')) {
                if (!confirm(this.get('intl').t('settings.forms.move'))) {
                    transition.abort();
                }
            }
            return true;
        },
    },
});

export default SettingsFormsFormRoute;
