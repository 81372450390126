/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/collector/fields/label-field/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsLabelFieldComponent;

import Ember from 'ember';

import base from '../base-field/component';

CollectorFieldsLabelFieldComponent = base.extend({
    // Methods

    _labels: Ember.computed('value', function () {
        if (this.get('value')) {
            return this.get('value').split(',');
        }
        return [];
    }),
    actions: {
        removeLabel: function (label) {
            var value;
            value = this.get('_labels');
            if (value.indexOf(label) !== -1) {
                if (value.length === 1) {
                    value = [];
                } else {
                    value.splice(value.indexOf(label), 1);
                }
                return this.sendAction('bubbleChange', value.join(','), this.get('field.name'));
            }
        },
        addLabel: function (label) {
            var value;
            if (label) {
                value = this.get('_labels');
                value.push(label);
                this.sendAction('bubbleChange', value.join(','), this.get('field.name'));
                return this.set('label', '');
            }
        },
    },
});

export default CollectorFieldsLabelFieldComponent;
