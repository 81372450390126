import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import { later } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import podNames from 'ember-component-css/pod-names';

export default class WorkingNowComponent extends Component {
    @service session;
    @service evented;
    @service('collector-service') Collector;
    @service intl;
    @service localStorageWrapper;
    @service analytics;
    @service('promo-messages') promoMessages;

    @tracked data;
    @tracked messageModalcontent;
    @tracked showMessageModal;
    @tracked selectedType;

    get styleNamespace() {
        return podNames['dash-board/widgets/promo-messages'];
    }

    constructor() {
        super(...arguments);
        this.types = ['release_note', 'tips', 'new_feature', 'future_webinar', 'failure_report'];
        this.headers = ['subject', 'startdate'];
        this.form = 'promomessage';
        this.key = 'messageWidgetFilters';
        this.subtle_key = 'promomessages_subtle';
        this.selectedType = this.getFiltersFromLS();
        this.getUnreadMessages();
        this.data = this.getMessages();

        if (!Ember.testing) {
            later(
                this,
                () => {
                    this.getUnreadMessages();
                    this.data = this.getMessages();
                },
                10000,
            );
        }

        this.evented.on('onResumed', this, 'onResumed');
    }

    @action
    showMessage(row) {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Show message',
            label: 'Promo messages widget',
        });

        this.markMessageAsRead(row);
        this.messageModalcontent = row;
        this.showMessageModal = true;
    }

    @action
    closeMessage() {
        this.messageModalcontent = null;
        this.showMessageModal = false;
    }

    @action
    typeChange(type) {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: `Message type change to ${type}`,
            label: 'Promo messages widget',
        });

        this.selectedType = type;
        this.setFiltersToLS(this.selectedType);
        this.data = this.getMessages();
    }

    willDestroy() {
        this.evented.off('onResumed', this, 'onResumed');
    }

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        this.data = this.getMessages();
    }

    async getMessages() {
        let products = this.Collector.products;
        let country = this.session.currentUser.company_info.country;
        let userlevel = this.session.currentUser.userlevel;

        if (!products || !country || !userlevel) return [];

        const messages = await this.promoMessages.getMessages({
            country: country,
            userlevel: userlevel,
            prods: products,
            new: true,
            type: this.selectedType,
        });
        // Sort by startdate
        let data = messages.data.sort((el1, el2) => (el1.startdate < el2.startdate ? 1 : -1));
        return this.formatMessageData(data);
    }

    formatMessageData(data) {
        let badgeText = this.intl.t('widget.promomessage.unread');
        let formattedData = data.map((item) => {
            item.subject = item.type
                ? this.intl.t('promomessage.type.' + item.type) + ': ' + item.title
                : item.title;
            item.startdate = moment(item.startdate).format('DD.MM.YYYY');
            if (this.unReadMessages && this.unReadMessages.findBy('id', item.id)) {
                item.subject = htmlSafe(
                    item.subject +
                        ' <span class="badge badge-pill badge-unread">' +
                        badgeText +
                        '</span>',
                );
                item.class = 'unread';
            }
            return item;
        });
        return formattedData;
    }

    setFiltersToLS(filters) {
        if (this.key) this.localStorageWrapper.setUserSetting(this.key, filters);
    }

    getFiltersFromLS() {
        let LSfilters = this.localStorageWrapper.getUserSetting(this.key);
        if (LSfilters && LSfilters.length > 0 && this.key) return LSfilters;
        return null;
    }

    getUnreadMessages() {
        let messages = this.localStorageWrapper.getUserSetting(this.subtle_key);
        if (messages && messages.length > 0) this.unReadMessages = messages;
    }

    markMessageAsRead(item) {
        if (!this.unReadMessages) return;
        this.unReadMessages = this.unReadMessages.rejectBy('id', item.id);
        this.localStorageWrapper.setUserSetting(this.subtle_key, this.unReadMessages);

        let readMessages = this.localStorageWrapper.getUserSetting('promomessages');
        readMessages.push(item.id);
        this.localStorageWrapper.setUserSetting('promomessages', [...new Set(readMessages)]);
        this.data = this.getMessages();
    }
}
