import { set } from '@ember/object';
import recordComp from '../../record-comp/component';
import { inject as service } from '@ember/service';

export default recordComp.extend({
    Collector: service('collector-service'),
    layoutName: 'collector/record-comp',

    returnField(field) {
        if (
            field.name == 'description' &&
            this.Collector.testNeedsOne('products.personalliggareandid06')
        ) {
            set(field, 'features.mandatory', true);
        }
        return field;
    },
});
