/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/pl/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsPlController;

import Ember from 'ember';

import productSettings from '../../../config/pl_settings';

SettingsPlController = Ember.Controller.extend({
    Collector: Em.inject.service('collector-service'),
    session: Em.inject.service(),
    intl: Em.inject.service(),
    application: Em.inject.controller(),
    settingsController: Em.inject.controller('settings'),
    modelCreator: Ember.inject.service('model-creator'),
    record: null,
    // Settings that have somekind of settings form to add records to
    formSettings: Em.computed('allSettings', function () {
        var all_settings;
        all_settings = this.get('allSettings');
        if (!all_settings) {
            return;
        }
        return this.get('allSettings').filterBy('form');
    }),
    // All settings fetched from config/settings and parsed to needed formats
    allSettings: Em.computed('session.currentUser', 'updateSettings', function () {
        var c, i, j, len, len1, option, options, pl_settings, ref, service_has_products, setting;
        c = this.get('Collector');
        pl_settings = [];
        service_has_products = c.testNeeds(['products']);
        if (!this.get('session.currentUser')) {
            return;
        }
        // Loop trough the settings from config/settings
        for (i = 0, len = productSettings.length; i < len; i++) {
            setting = productSettings[i];
            // Need to make them an ember object or updating won't work
            setting = Em.Object.create(setting);
            // We save the last opened setting in own variable so
            // we can set the right setting open when settings are refreshed
            if (this.get('openSetting') === setting.get('name')) {
                // If they match, set the setting to open
                setting.set('show', true);
            }
            // We test if the service have everything that is in it's needs array
            // If not then we won't push it into settings
            if (c.testNeeds(setting.get('needs'))) {
                options = [];
                if (setting.get('name') && this.get('intl').exists(setting.get('name') + '.desc')) {
                    setting.set('desc', setting.get('name') + '.desc');
                }
                // If we don't have options the setting is always on
                if (!setting.get('options') || !service_has_products) {
                    setting.set('options', null);
                    setting.set('active', true);
                } else {
                    ref = setting.get('options');
                    // Loop through options and mark options that have all needs satisfied as active
                    for (j = 0, len1 = ref.length; j < len1; j++) {
                        option = ref[j];
                        option = Em.Object.create(option);
                        if (c.testNeeds(option.get('products'))) {
                            option.set('active', true);
                            // If settings have form and option value is "on" we mark the setting as active
                            if (option.get('value') === 'on' && setting.get('form')) {
                                setting.set('active', true);
                            }
                        }
                        options.push(option);
                    }
                    setting.set('options', options);
                }
                pl_settings.push(setting);
            }
        }
        return pl_settings;
    }),
    // All settings that don't have somekind of form
    settings: Em.computed('allSettings', function () {
        var all_settings;
        all_settings = this.get('allSettings');
        if (!all_settings) {
            return;
        }
        return this.get('allSettings').filterBy('form', void 0);
    }),
    actions: {
        addRecord: function (report) {
            report.set('editorMode', true);
            return false;
        },
        closeEditor: function (report) {
            report.set('editorMode', false);
            if (this.get('record')) {
                this.set('record', null);
            }
            return false;
        },
        editRow: function (row, report) {
            Em.assert('editRow action needs row id', row.id);
            this.set('record', row.id);
            report.set('editorMode', true);
            return false;
        },
        removeRow: function (row, report) {
            Em.assert('removeRow action needs row id', row.id);
            return this.store.findRecord(report.get('form'), row.id).then(function (record) {
                return record.destroyRecord();
            });
        },
        // Add the options products and update the settings
        setSetting: function (setting, option) {
            var c, products, promises;
            c = this.get('Collector');
            // If we have a form we inverse the products else we just return the products
            // The inversing needs to be done because forms only toggle on and off - could maybe fix in template
            products = setting.get('form') ? c.inverseNeeds(option.products) : option.products;
            this.get('settingsController').refreshNeeded();
            // Use collector service to add the products to service
            promises = c.setProducts(products);
            return Em.RSVP.Promise.all(promises).then((data) => {
                return this.send('saveCalled');
            });
        },
        // Toggle setting in accordion to be open or closed
        toggleSettingShow: function (clicked) {
            var i, len, ref, results, setting;
            ref = this.get('settings');
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
                setting = ref[i];
                if (setting.get('name') === clicked.get('name')) {
                    setting.toggleProperty('show');
                    results.push(this.set('openSetting', setting.get('name')));
                } else {
                    results.push(void 0);
                }
            }
            return results;
        },
        saveCalled: function () {
            // Get new /forms and update models
            return this.get('modelCreator')
                .createEmberModels()
                .then(() => {
                    this.toggleProperty('application.refreshMenus');
                    // Force settings to update
                    return this.toggleProperty('updateSettings');
                });
        },
    },
});

export default SettingsPlController;
