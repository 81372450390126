/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/products/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsProductsController;

import Ember from 'ember';

import { t } from 'ember-intl';

import config from 'tt4/config/environment';

import tt4config from '../../../config';

import productPostAddMixin from '../../../mixins/product-post-add';

import { htmlSafe } from '@ember/string';

SettingsProductsController = Ember.Controller.extend(productPostAddMixin, {
    intl: Ember.inject.service(),
    admintool: Ember.inject.service(),
    cdn: Ember.inject.service(),
    session: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    application: Em.inject.controller(),
    settings: Em.inject.controller(),
    dialogs: Em.inject.service(),
    modelCreator: Ember.inject.service('model-creator'),
    notifications: Ember.inject.service(),
    errors: Em.Object.create({
        number_of_boxes: false,
        delivery_street: false,
        delivery_city: false,
        delivery_zip: false,
        name: false,
        phone: false,
        email: false,
    }),
    orderButtonArray: ['rfid', 'famoco'],
    integrations: [
        'fivaldi_salary_soap',
        'lon600_salary_xml',
        'netvisor_salary_rest',
        'nova_salary_csv',
        'nova_web_service',
        'custom_export',
    ],
    buyrent_options_rfid: Ember.computed(function () {
        return [
            {
                id: 'buy',
                name: this.get('intl').t('settings.products.order_modal.buy'),
            },
            {
                id: 'rent',
                name: this.get('intl').t('settings.products.order_modal.rent'),
            },
        ];
    }),
    sim_options_rfid: Ember.computed(function () {
        return [
            {
                id: 'preinstalled',
                name: this.get('intl').t('settings.products.order_modal.preinstalled'),
            },
            {
                id: 'none',
                name: this.get('intl').t('settings.products.order_modal.none'),
            },
        ];
    }),
    buyrent_options_famoco: Ember.computed(function () {
        return [
            {
                id: 'famocobuy',
                name: this.get('intl').t('settings.products.order_modal.famocobuy'),
            },
            {
                id: 'famocorent',
                name: this.get('intl').t('settings.products.order_modal.famocorent'),
            },
        ];
    }),
    sim_options_famoco: Ember.computed(function () {
        return [
            {
                id: 'famocopreinstalled',
                name: this.get('intl').t('settings.products.order_modal.famocopreinstalled'),
            },
            {
                id: 'famoconone',
                name: this.get('intl').t('settings.products.order_modal.famoconone'),
            },
        ];
    }),
    levels: Ember.computed('intl', function () {
        var country, intl;
        intl = this.get('intl');
        country = this.get('store').peekAll('companyInfo').get('firstObject.country');
        return Em.A([
            {
                name: 'base_basic',
                header: intl.t('products.header.basic'),
                text: intl.t('products.text.basic'),
                image: config.APP.CDN_URL + '/images/produts/basic.png',
                price: intl.t('products.price.basic_' + country),
                currency: intl.t('products.currency.basic_' + country),
                pricetype_text: intl.t('products.pricetype.basic_' + country),
                needs: 'products.base_basic',
            },
            {
                name: 'base_pro',
                header: intl.t('products.header.pro'),
                text: intl.t('products.text.pro'),
                image: config.APP.CDN_URL + '/images/produts/pro.png',
                price: intl.t('products.price.pro_' + country),
                currency: intl.t('products.currency.pro_' + country),
                pricetype_text: intl.t('products.pricetype.pro_' + country),
                needs: 'products.base_basic||products.base_pro',
            },
        ]);
    }),
    //{
    //        name: "base_enterprise"
    //        header: intl.t("products.header.enterprise")
    //        text: intl.t("products.text.enterprise")
    //        image: config.APP.CDN_URL+"/images/produts/enterprise.png"
    //        price: intl.t("products.price.enterprise_"+country)
    //        currency: intl.t("products.currency.enterprise_"+country)
    //        pricetype_text: intl.t("products.pricetype.enterprise_"+country)
    //        needs: "products.base_basic||products.base_pro||products.base_enterprise"
    //}
    activeLevel: Ember.computed(function () {
        var i, len, product, ref, version;
        ref = this.get('Collector.products');
        for (i = 0, len = ref.length; i < len; i++) {
            product = ref[i];
            if (
                product === 'base_basic' ||
                product === 'base_pro' ||
                product === 'base_enterprise'
            ) {
                version = product;
                break;
            }
        }
        return this.get('levels').filterBy('name', version).get('firstObject');
    }),
    isSweService: Ember.computed('session.currentUser.company_info.country', function () {
        if (this.get('session.currentUser.company_info.country') === 'se') {
            return true;
        } else {
            return false;
        }
    }),
    _products: Ember.on(
        'init',
        Ember.observer('session.currentUser.company_info.country', function () {
            var c, cdn, intl, params, servicetype;
            if (!this.get('session.currentUser.company_info.country')) {
                return;
            }
            intl = this.get('intl');
            cdn = this.get('cdn');
            c = this.get('Collector');
            if (c.testNeedsOne('products.mini_entry')) {
                servicetype = 'mini_entry';
            } else if (c.testNeedsOne('products.generic_timetracker')) {
                servicetype = 'entry';
            } else {
                servicetype = 'movenium';
            }
            params = {
                data: {
                    country: this.get('session.currentUser.company_info.country'),
                    lang: moment.locale(),
                    servicetype: servicetype,
                },
                headers: {
                    Authorization:
                        'Bearer ' + this.get('session.session.authenticated.access_token'),
                },
            };
            return this.get('admintool')
                .get('products', params)
                .then((products) => {
                    products = products.map((item) => {
                        item.pricetype_text = (function () {
                            switch (item.pricetype) {
                                case 'peruser':
                                    return intl.t('register.price_type_peruser');
                                case 'perproject':
                                    return intl.t('register.price_type_perproject');
                                case 'permonth':
                                    return intl.t('register.price_type_permonth');
                                case 'monthlyperproject':
                                    return intl.t('register.price_type_monthlyperproject');
                            }
                        })();
                        item.image = item.image ? cdn.url('images/products/' + item.image) : void 0;
                        item = Ember.Object.create(item);
                        // if client has old xml-version of this product
                        // select it but disable it also so user cannot unselect it
                        if (this.get('productNeedsArr')[item.name]) {
                            if (c.testNeeds(this.get('productNeedsArr')[item.name])) {
                                item.set('disabled_and_selected', true);
                            }
                        }

                        // if product has disabled needs set disabled
                        const productCanBeTurnedOnNeeds = this.get(
                            'productCanBeTurnedOn.' + item.name,
                        );
                        const allProductNeedsAreMet =
                            !productCanBeTurnedOnNeeds ||
                            c.testNeeds(productCanBeTurnedOnNeeds.map((a) => a.key));

                        item.set(
                            'disabled',
                            allProductNeedsAreMet ? false : productCanBeTurnedOnNeeds,
                        );

                        //@set 'old_products_found', true
                        //item.set('selected', true)
                        if (c.testNeeds(['products.' + item.get('name')])) {
                            item.set('selected', true);
                        }
                        return item;
                    });
                    return this.set('products', products);
                });
        }),
    ),
    // list here all products that cannot be turned on/off when customer has old xml-packet for that product
    productNeedsArr: {
        timetracker: ['worktime', '!products.timetracker', 'task'],
        orientations: ['orientation', '!modules.orientations'],
        rakentajanpaketti: [
            'urakkaraportointi',
            '!products.rakentajanpaketti',
            '!products.timetracker',
        ],
        rfid: ['project.rfid_device_id', '!products.rfid'],
    },

    productCanBeTurnedOn: {
        fondion: [
            {
                key: 'products.salarytype_v2',
                text: 'settings.salarytypes.salarytypesv2',
            },
            {
                key: '!products.timetracker_hours',
                text: 'settings.timetracker.startandend',
            },
        ],
    },

    // i didn't add these because if client has site-safety xml it also has tr and mvr json-products so there is nothing to compare
    // measurement_tr: ['tr']
    // measurement_mvr: ['mvr']
    isDirty: Em.computed('products.@each.selected', 'productsSaved', function () {
        var c, dirty, i, len, product, products;
        products = this.get('products');
        if (!products) {
            return false;
        }
        c = this.get('Collector');
        dirty = false;
        for (i = 0, len = products.length; i < len; i++) {
            product = products[i];
            if (product.get('selected') && !c.testNeeds(['products.' + product.get('name')])) {
                dirty = true;
            } else if (
                !product.get('selected') &&
                c.testNeeds(['products.' + product.get('name')])
            ) {
                dirty = true;
            }
        }
        return dirty;
    }),
    rfid_modal_title: Em.computed(function () {
        return htmlSafe(this.get('intl').t('register.products.rfid_order_title'));
    }),
    showRfidModal: false,
    showIntegrationModal: false,
    sendInfoToCustomerService: function (needs) {
        var c, content, products;
        if (this.get('session.currentUser.company_info.country') !== 'se') {
            return;
        }
        c = this.get('Collector');
        products = needs.filter(function (item) {
            return item.get('selected') && !c.productExists('products.' + item.get('name'));
        });
        if (Ember.get(products, 'length') > 0) {
            content =
                'Customer ' +
                this.get('session.currentUser.company_info.company_name') +
                ' ' +
                this.get('session.currentUser.partnerid') +
                ' has added products to their service<br>';
            content += 'Products: <br>' + products.mapBy('header').join('<br>');
            return this.Collector.ttapi({
                url: 'sendEmail',
                data: {
                    email: 'customerservice_se',
                    title:
                        'Customer ' +
                        this.get('session.currentUser.company_info.company_name') +
                        ' ' +
                        this.get('session.currentUser.partnerid') +
                        ' added products',
                    content: content,
                },
                method: 'post',
            });
        }
    },
    updateExternal: function (needs) {
        var c, data, i, len, product, products, results, url;
        if (
            !config.APP.PRODUCT_UPDATES_URL ||
            this.get('session.session.content.authenticated.scope')
        ) {
            return;
        }
        c = this.get('Collector');
        products = needs.filter(function (item) {
            return item.get('selected') && !c.productExists('products.' + item.get('name'));
        });
        url = config.APP.PRODUCT_UPDATES_URL;
        results = [];
        for (i = 0, len = products.length; i < len; i++) {
            product = products[i];
            data = {
                date: moment().format('YYYY-MM-DD'),
                product: product['name'],
                name:
                    this.get('session.currentUser.firstname') +
                    ' ' +
                    this.get('session.currentUser.lastname'),
                email: this.get('session.currentUser.email'),
                phone: this.get('session.currentUser.phone'),
                company: this.get('session.currentUser.company_info.company_name'),
                businessid: this.get('session.currentUser.company_info.company_vatin'),
                serviceid: this.get('session.currentUser.partnerid'),
                userid: this.get('session.currentUser.id'),
                lang: this.get('intl.primaryLocale'),
                country: this.get('session.currentUser.company_info.country'),
            };
            results.push(
                $.ajax({
                    url: url,
                    data: JSON.stringify({
                        data: data,
                    }),
                    method: 'POST',
                }),
            );
        }
        return results;
    },
    actions: {
        change: function (field, value) {
            if (this.get('rfid_info.' + field)) {
                this.set('rfid_info.' + field, value);
            }
            return this.set('errors.' + field, false);
        },
        click: function (object) {
            var self;
            self = this;
            if (object.get('disabled')) {
                return false;
            }
            if (object.get('selected')) {
                this.get('dialogs')
                    .confirm(this.get('intl').t('settings.confirm.remove_product'))
                    .then(function (ret) {
                        if (ret) {
                            object.set('selected', false);
                            return self.send('save');
                        }
                    });
            } else {
                object.toggleProperty('selected');
                if (this.get('integrations').includes(object.name)) {
                    this.set('showIntegrationModal', true);
                    this.set('integrationName', object.name);
                }
                this.send('save');
            }
            return false;
        },
        moreInfo: function (link) {
            return window.open(link, '_blank');
        },
        orderButton: function (product) {
            return this.get('admintool')
                .ajax({
                    url: 'customer_info',
                })
                .then((c_info) => {
                    this.set('rfid_info', {
                        company_name: this.get('session.currentUser.company_info.company_name'),
                        name:
                            this.get('session.currentUser.firstname') +
                            ' ' +
                            this.get('session.currentUser.lastname'),
                        phone: this.get('session.currentUser.phone'),
                        email: this.get('session.currentUser.email'),
                        delivery_street: c_info['street'],
                        delivery_city: c_info['town'],
                        delivery_zip: c_info['zip'],
                        number_of_boxes: 1,
                        vatin: c_info['vatin'],
                        partnerid: c_info['partnerid'],
                        country: c_info['country'],
                        rental: product === 'famoco' ? 'famocobuy' : 'buy',
                        preinstalled_simcard:
                            product === 'famoco' ? 'famocopreinstalled' : 'preinstalled',
                    });
                    this.set('showRfidModal', product);
                    return false;
                });
        },
        closeModal: function () {
            return this.set('showRfidModal', false);
        },
        saveRfidData: function () {
            var address,
                basicContent,
                contentRow,
                customer_address,
                emailContent,
                emailContentForCustomer,
                field,
                hasError,
                i,
                j,
                len,
                len1,
                ref,
                self,
                type;
            hasError = false;
            ref = [
                'number_of_boxes',
                'delivery_street',
                'delivery_city',
                'delivery_zip',
                'name',
                'phone',
                'email',
            ];
            for (i = 0, len = ref.length; i < len; i++) {
                field = ref[i];
                if (!this.get('rfid_info.' + field) || this.get('rfid_info.' + field) === '') {
                    this.set('errors.' + field, true);
                    hasError = true;
                } else {
                    this.set('errors.' + field, false);
                }
            }
            if (!hasError) {
                type = this.get('showRfidModal');
                this.set('showRfidModal', false);
                self = this;
                emailContent = [];
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_company_name'),
                    this.get('session.currentUser.partnerid') +
                        ' ' +
                        this.get('rfid_info.company_name'),
                ]);
                emailContent.push(['Product type:', type]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_number_of_boxes'),
                    this.get('rfid_info.number_of_boxes'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_rental'),
                    this.get('intl').t(
                        'settings.products.order_modal.' + this.get('rfid_info.rental'),
                    ),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_number_of_tags'),
                    this.get('rfid_info.number_of_tags') > 0
                        ? this.get('rfid_info.number_of_tags')
                        : 0,
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_delivery_street'),
                    this.get('rfid_info.delivery_street'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_delivery_city'),
                    this.get('rfid_info.delivery_city'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_delivery_zip'),
                    this.get('rfid_info.delivery_zip'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_name'),
                    this.get('rfid_info.name'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_phone'),
                    this.get('rfid_info.phone'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_email'),
                    this.get('rfid_info.email'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_vatin'),
                    this.get('rfid_info.vatin'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.rfid_partnerid'),
                    this.get('rfid_info.partnerid'),
                ]);
                emailContent.push([
                    this.get('intl').t('settings.products.preinstalled_simcard'),
                    this.get('intl').t(
                        'settings.products.order_modal.' +
                            this.get('rfid_info.preinstalled_simcard'),
                    ),
                ]);
                if (this.get('rfid_info.order_ref')) {
                    emailContent.push([
                        this.get('intl').t('settings.products.rfid_order_ref'),
                        this.get('rfid_info.order_ref'),
                    ]);
                }
                //emailContent = "<hr><br/><center>"+"<table>"
                //emailContent += "<tr><td>"+@get('intl').t('settings.products.rfid_company_name')+":</td><td> "+@get('session.currentUser.partnerid')+" "+@get('rfid_info.company_name')+"</td></tr>"
                //emailContent += "<tr><td>"+"Product type:</td><td> "+type+"</td></tr>"
                //emailContent += "<tr><td>"+@get('intl').t('settings.products.rfid_number_of_boxes')+":</td><td> "+@get('rfid_info.number_of_boxes')+"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"+</td></tr>"
                //emailContent += "<tr><td>"+@get('intl').t('settings.products.rfid_delivery_street')+":</td><td> "+@get('rfid_info.delivery_street')+"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //emailContent += "<tr><td>"++"</td></tr>"
                // why was this here and below also? Should this be only inside if?
                // emailContent += @get('intl').t('settings.products.order_ref')+": "+@get('rfid_info.order_ref')+"<br>"
                //emailContent += "<tr><td>"++"</td></tr>"
                //if @get('rfid_info.order_ref')
                //    emailContent += "<tr><td>"++"</td></tr>"

                //emailContent += "</table></center>"
                address = 'customerservice_fi';
                if (this.get('rfid_info.country') === 'se') {
                    address = 'customerservice_se';
                }
                // send this information also to customer (in html-format)
                if (
                    this.get('rfid_info.email') !==
                        this.get('session.currentUser.company_info.company_email') &&
                    this.get('session.currentUser.company_info.company_email')
                ) {
                    customer_address = [];
                    customer_address.push(this.get('rfid_info.email'));
                    customer_address.push(
                        this.get('session.currentUser.company_info.company_email'),
                    );
                } else {
                    customer_address = this.get('rfid_info.email');
                }
                basicContent = '\r\n';
                for (j = 0, len1 = emailContent.length; j < len1; j++) {
                    contentRow = emailContent[j];
                    //console.log contentRow
                    basicContent += contentRow[0] + ': ' + contentRow[1] + '\r\n';
                }
                emailContentForCustomer =
                    this.get('intl').t('settings.products.rfid_order_received') +
                    '\r\n' +
                    basicContent;
                this.Collector.ttapi({
                    url: 'sendEmail',
                    data: {
                        email: address,
                        title:
                            self.get('session.currentUser.company') +
                            ' - ' +
                            self.get('intl').t('register.products.rfid_info'),
                        content: basicContent,
                    },
                    method: 'post',
                });
                return this.Collector.ttapi({
                    url: 'sendEmail',
                    data: {
                        email: customer_address,
                        title: this.get('intl')
                            .t('settings.products.rfid_order_received_header')
                            .toString(),
                        content: emailContentForCustomer,
                    },
                    method: 'post',
                });
            }
        },
        selectLevel: function (level) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('settings.products.confirm.upgrade'))
                .then((con) => {
                    var needs, promises;
                    if (con) {
                        needs = [];
                        this.get('levels').forEach(function (item) {
                            if (item.name === level.name) {
                                return needs.push('products.' + level.name);
                            } else {
                                return needs.push('!products.' + item.name);
                            }
                        });
                        promises = this.get('Collector').setProducts(needs);
                        return Em.RSVP.Promise.all(promises).then(() => {
                            return location.reload();
                        });
                    }
                });
        },
        save: function () {
            var c,
                i,
                len,
                needs,
                post_add_actions,
                product,
                products,
                promises,
                selectedProducts,
                self,
                some_old_xml_products,
                type;
            self = this;
            products = this.get('products');
            some_old_xml_products = false;
            selectedProducts = products.filterBy('selected');
            // check if user has some old xml-products
            // if has, then it is ok to remove all products added from tt4
            if (
                selectedProducts.get('length') === 0 &&
                this.get('Collector').testNeedsOne('products.generic_timetracker') === false
            ) {
                this.get('dialogs').alert(
                    this.get('intl').t('settings.products.no_products_selected'),
                );
                return;
            }
            this.get('settings').refreshNeeded();
            c = this.get('Collector');
            needs = [];
            post_add_actions = [];
            for (i = 0, len = products.length; i < len; i++) {
                product = products[i];
                if (product.get('selected')) {
                    if (
                        !c.productExists('products.' + product.get('name')) &&
                        typeof this['adding_' + product.get('name')] === 'function'
                    ) {
                        post_add_actions.push(product.get('name'));
                    }
                    if (
                        !c.productExists('products.' + product.get('name')) &&
                        (product.get('name') === 'rfid' || product.get('name') === 'famoco')
                    ) {
                        type = product.get('name');
                        this.get('admintool')
                            .ajax({
                                url: 'customer_info',
                            })
                            .then((c_info) => {
                                this.set('rfid_info', {
                                    company_name: this.get(
                                        'session.currentUser.company_info.company_name',
                                    ),
                                    name:
                                        this.get('session.currentUser.firstname') +
                                        ' ' +
                                        this.get('session.currentUser.lastname'),
                                    phone: this.get('session.currentUser.phone'),
                                    email: this.get('session.currentUser.email'),
                                    delivery_street: c_info['street'],
                                    delivery_city: c_info['town'],
                                    delivery_zip: c_info['zip'],
                                    number_of_boxes: 1,
                                    vatin: c_info['vatin'],
                                    partnerid: c_info['partnerid'],
                                    country: c_info['country'],
                                });
                                return self.set('showRfidModal', type);
                            });
                    }
                    needs.push('products.' + product.get('name'));
                } else {
                    needs.push('!products.' + product.get('name'));
                }
            }
            this.sendInfoToCustomerService(products);
            this.updateExternal(products);
            promises = c.setProducts(needs);
            return Em.RSVP.Promise.all(promises).then((data) => {
                this.get('notifications').success(
                    this.get('intl').t('settings.products.productsSaved'),
                    {
                        autoClear: true,
                    },
                );
                // Get new /forms and update models
                return this.get('modelCreator')
                    .createEmberModels()
                    .then(() => {
                        // check if we need to do some post-adding stuff
                        post_add_actions.forEach((action) => {
                            if (c.productExists('products.' + action)) {
                                return this['adding_' + action]();
                            }
                        });
                        this.toggleProperty('application.refreshMenus');
                        return this.get('application').notifyPropertyChange('subMenus');
                    });
            });
        },
        infoButton: function (product) {
            this.set('showIntegrationModal', true);
            return this.set('integrationName', product);
        },
    },
});

export default SettingsProductsController;
