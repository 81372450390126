/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/spin-button.coffee.old
// Generated by CoffeeScript 2.6.1
var SpinButtonComponent;

import Ember from 'ember';

SpinButtonComponent = Ember.Component.extend({
    classNames: ['button'],
    classes: 'btn-warning',
    spinIsLoading: false,
    saveButtonText: null,
    showDisable: Ember.computed('spinIsLoading', 'disabled', function () {
        if (this.get('disabled')) {
            return true;
        } else if (this.get('spinIsLoading')) {
            return true;
        }
        return false;
    }),
    actions: {
        showLoading: function () {
            if (!this.get('spinIsLoading')) {
                this.set('spinIsLoading', true);
                if (this.get('action')) {
                    this.action();
                }
            }
        },
    },
});

export default SpinButtonComponent;
