import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    ttapi: service(),
    store: service(),
    intl: service(),
    tagName: 'table',

    cleanLogs: computed(async function () {
        let logs = await this.getLogsFromTtapi();
        if (!logs) return [];
        let arr = [];
        logs.forEach(async (log) => {
            let user = await this.store.findRecord('user', log.userid);
            arr.pushObject({
                datetime: moment(log.datetime).format('DD.MM.YYYY HH:mm:ss'),
                user: user.lastname + ' ' + user.firstname,
                message: log.message,
                channel: log.channel
                    .split(',')
                    .map((ch) => this.intl.t('general.log_channel.' + ch))
                    .join(','),
            });
        });
        return arr;
    }),

    async getLogsFromTtapi() {
        return await this.ttapi.request('/notification/logs');
    },
});
