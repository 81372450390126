/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/index/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddRoute;

import Ember from 'ember';

import GeneralAddRoute from '../../../../routes/general-add';

//import collector from '../../../classes/collector'
UsersAddRoute = GeneralAddRoute.extend({
    modelName: 'user',
    controllerName: 'users.add.index',
    savedRoute: 'users.saved',
    afterModel: function (model, transition) {
        var controller;
        controller = this.controllerFor('users/add/index');
        return model.set('password', controller.generatePassword());
    },
});

export default UsersAddRoute;
