/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/mixins/base-export.coffee.old
// Generated by CoffeeScript 2.6.1
var BaseExportMixin;

import Ember from 'ember';

BaseExportMixin = Ember.Mixin.create({
    getTable: function (columns, rows, translated_columns, sumRow) {
        var add, array, cell, column, headers, i, j, k, l, len, len1, len2, len3, row;
        array = [];
        headers = [];
        for (i = 0, len = translated_columns.length; i < len; i++) {
            column = translated_columns[i];
            headers.push('' + column);
        }
        array.push(headers);
        //console.log rows
        for (j = 0, len1 = rows.length; j < len1; j++) {
            row = rows[j];
            add = [];
            for (k = 0, len2 = columns.length; k < len2; k++) {
                column = columns[k];
                cell = row[column];
                add.push(
                    (function () {
                        switch (Ember.typeOf(cell)) {
                            case 'number':
                                return cell.toString();
                            case 'object':
                                if (cell.string != null) {
                                    return cell.toString();
                                } else {
                                    return cell;
                                }
                                break;
                            case 'string':
                                return cell;
                            case 'array':
                                return cell;
                            default:
                                return '';
                        }
                    })(),
                );
            }
            array.push(add);
        }
        if (sumRow) {
            add = [];
            for (l = 0, len3 = columns.length; l < len3; l++) {
                column = columns[l];
                cell = sumRow[column];
                add.push(
                    (function () {
                        switch (Ember.typeOf(cell)) {
                            case 'number':
                                return cell.toString();
                            case 'object':
                                if (cell.string != null) {
                                    return cell.toString();
                                } else {
                                    return cell;
                                }
                                break;
                            case 'string':
                                return cell;
                            default:
                                return '';
                        }
                    })(),
                );
            }
            array.push(add);
        }
        return array;
    },
    // returns data in different (one row per page) format
    getTableFullFormat: function (columns, rows, translated_columns, sumRow) {
        var add,
            array,
            cell,
            col_ind,
            column,
            field,
            field_ind,
            headers,
            i,
            j,
            k,
            l,
            len,
            len1,
            len2,
            len3,
            row,
            tmpcell;
        //console.log translated_columns
        headers = [];
        array = [];
        for (i = 0, len = translated_columns.length; i < len; i++) {
            column = translated_columns[i];
            headers.push('' + column);
        }
        //array.push headers
        for (j = 0, len1 = rows.length; j < len1; j++) {
            row = rows[j];
            add = [];
            if (Ember.typeOf(row) === 'array') {
                for (field_ind = k = 0, len2 = row.length; k < len2; field_ind = ++k) {
                    field = row[field_ind];
                    cell = (function () {
                        switch (Ember.typeOf(field)) {
                            case 'number':
                                return field.toString();
                            case 'object':
                                if (field.string != null) {
                                    return field.toString();
                                } else {
                                    return field;
                                }
                                break;
                            case 'string':
                                return field;
                            case 'array':
                                return field;
                            default:
                                return '';
                        }
                    })();
                    add.push([headers[field_ind], cell]);
                }
                array.push(add);
            } else {
                for (col_ind = l = 0, len3 = columns.length; l < len3; col_ind = ++l) {
                    column = columns[col_ind];
                    cell = row[column];
                    tmpcell = (function () {
                        switch (Ember.typeOf(cell)) {
                            case 'number':
                                return cell.toString();
                            case 'object':
                                if (cell.string != null) {
                                    return cell.toString();
                                } else {
                                    return cell;
                                }
                                break;
                            case 'string':
                                return cell;
                            default:
                                return '';
                        }
                    })();
                    add.push([headers[col_ind], tmpcell]);
                }
                array.push(add);
            }
        }
        return array;
    },
});

export default BaseExportMixin;
