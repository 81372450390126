import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    ttapi: service(),
    intl: service(),

    captchaResolved: false,

    actions: {
        onCaptchaResolved(reCaptchaResponse) {
            set(this, 'reCaptchaResponse', reCaptchaResponse);
        },

        onCaptchaExpired() {
            set(this, 'captchaResolved', false);
        },

        resetCaptcha() {
            get(this, 'gRecaptcha').resetReCaptcha();
        },
    },

    _verifyRecaptchaResponse(response) {
        if (response.success) {
            set(this, 'captchaResolved', true);
        } else {
            set(
                this,
                'captchaError',
                get(this, 'intl').t('login.recaptcha.captcha_validation_error'),
            );
            get(this, 'gRecaptcha').resetReCaptcha();
        }
    },
});
