import { sanitize } from 'dom-purify';
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';

interface IMessAgesTypesMessageboardComponent {
    message: any;
}

export default class MessAgesTypesMessageboardComponent extends Component<IMessAgesTypesMessageboardComponent> {
    get content() {
        //convert line breaks to html
        const content: string = this.args.message?.content;
        if (isPresent(content)) {
            return htmlSafe(sanitize(content.replace(/\n/g, '<br>')));
        }
        return '';
    }
}
