/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/worksitediary/report/add/required-extra/route.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryReportRequiredAddExtrasRoute;

import Ember from 'ember';

import baseRoute from '../base-route/route';

WorksitediaryReportRequiredAddExtrasRoute = baseRoute.extend({
    Collector: Em.inject.service('collector-service'),
    session: Em.inject.service(),
});

export default WorksitediaryReportRequiredAddExtrasRoute;
