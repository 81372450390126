export function getAssetFolder(name) {
    if (window.cordova) {
        return window.cordova.file.applicationDirectory + 'www/' + name + '/';
    }

    return '/' + name + '/';
}

export default function getScript(source, attributes) {
    let promises = [];
    window.Tt4.loadedScripts = window.Tt4.loadedScripts || [];
    let sources = source.split(',');
    sources.forEach((element) => {
        promises.push(
            new Promise((resolve, reject) => {
                if (window.Tt4.loadedScripts.includes(element)) {
                    // we already have element
                    resolve();
                    return;
                }

                try {
                    const el = document.createElement('script');
                    if (attributes) {
                        Object.keys(attributes).forEach((key) => {
                            el.setAttribute(key, attributes[key]);
                        });
                    }
                    el.onload = resolve;
                    el.src = element;
                    window.Tt4.loadedScripts.push(element);
                    document.head.appendChild(el);
                } catch (error) {
                    reject(error);
                }
            }),
        );
    });
    return Promise.all(promises);
}

/*
 * If we have to prevent race condition
 * this makes sure, scripts are loaded one
 * at a time
 */
export function getScriptsInOrder(array) {
    return new Promise(async function (resolve) {
        for (let script of array) {
            await getScript(script);
        }
        resolve();
    });
}
