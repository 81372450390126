import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Helper.extend({
    intl: service(),

    compute(params) {
        const key = 'form.' + params[0];
        if (get(this, 'intl').exists(key)) {
            if (params[1])
                return (
                    get(this, 'intl').t(key).charAt(0).toUpperCase() +
                    get(this, 'intl').t(key).slice(1)
                );
            else return get(this, 'intl').t(key).toLowerCase();
        } else return params[0];
    },
});
